import DiagnosisForm from './DiagnosisForm';
import {EditDiagnosis} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
let header = {
  title: 'Edit Diagnosis',
  actions: [cancelButton(), save()],
};

export default DiagnosisForm({uri: EditDiagnosis, header});
