import React from 'react';

import { GradientButton } from '../../app-components/buttons/Buttons';
import TabNavigator from '../../app-components/tab/TabNavigator';
import ScreenedPatientList from './ScreenedPatientList';
import Fts from '../../app-components/filter/Fts';
import { h16_Regular } from '../../theme/fonts';
import { roles } from '../../Lib/constants';
import { colors } from '../../theme/colors';
import { getUser } from '../../AppServices';
import OrderList from './OrderList';
import Patients from './Patients';
import userList from './userList';
import DeviceList from './SearchDevice/SearchDevice';
import CustomHeaderDate from '../../app-components/CustomHeaderDates/CustomHeaderDate';
import {
  FilterVillageByBlock,
  FilterVillageByDistrict,
  flagFilter,
  MemberGraphYearFilter,
  TestResultOrderFilter,
  VillageFilterOnCitizenList,
} from '../../app-components/filter/MayaMDFilters';
import CareProgrammeList from './RPMProgramLibrary/RPMProgramLibrary';
import MasterDataTab from '../masterData/MasterDataTab';
import { download } from '../../app-components/action/DownloadAction';
import mayaTestResult from './MayaTestResult/mayaTestResult';
import { View } from '../../app-components';
import assignedMemberList from './AssignedMembers/assignedMemberList';
import MemberGraphScreen from './MemberGraph/MemberGraphScreen';
import VillageSettingTab from './VillageSetting/tab';
import labCategories from './LabCategories/labCategories';
import ShowIframe from './MemberGraph/ShowIframe';
import inferences from './inferences';

const { DateFilter } = CustomHeaderDate;

const { themeColor, textColor70 } = colors;
const headerTextContainerStyle = {
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    padding: 18,
    fontFamily: 'AvenirNext-Medium',
  },
  containerStyle: { backgroundColor: 'rgb(231, 238, 245)' },
};
export const tabTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 70,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: '#fff',
    },
    tabRowStyle: {
      flex: 1,
    },
  },
  tabActionStyle: {
    backgroundColor: 'red',
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      marginLeft: 15,
      marginRight: 15,
      marginTop: 14,
      marginBottom: 14,
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeBorderStyle: {
      height: 3,
      borderRadius: 2,
      width: 42,
      backgroundColor: themeColor,
      position: 'absolute',
      bottom: 0,
    },
    itemContainerStyle: { flexDirection: 'row', alignItems: 'center' },
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...h16_Regular,
      color: textColor70,
    },
    activeTextStyle: {
      ...h16_Regular,
      color: themeColor,
    },
    countStyle: {
      marginLeft: 3,
      ...h16_Regular,
      color: textColor70,
    },
    activeCountStyle: {
      color: themeColor,
    },
  },
};

export default TabNavigator({
  theme: tabTheme,
  reloadTabOnNavigationChange: true,
  tabs: {
    UserManagement: {
      label: 'User Management',
      screen: userList,
      visible: () => {
        const { userType = '' } = getUser() || {};
        return userType === roles.SUPERADMIN || userType === roles.ADMIN;
      },
      fontFamily: 'AvenirNext-Regular',
      screenProps: {
        header: {
          title: '',
          ...headerTextContainerStyle,
          actions: [
            {
              render: () => {
                return <GradientButton label={'Add User'} varient="curved" />;
              },
              type: 'link',
              link: {
                view: 'addUser',
              },
            },
          ],
        },
      },
    },
    VillageSetting: {
      label: 'Village Setting',
      screen: VillageSettingTab,
      visible: () => {
        const { userType = '' } = getUser() || {};
        return userType === roles.SUPERADMIN || userType === roles.ADMIN;
      },
      screenProps: {
        header: {
          ...headerTextContainerStyle,
        },
      },
      fontFamily: 'AvenirNext-Regular',
    },
    MayaTestMapping: {
      label: 'Maya Test Mapping',
      screen: mayaTestResult,
      fontFamily: 'AvenirNext-Regular',
      visible: () => {
        const { userType = '' } = getUser() || {};
        return userType === roles.SUPERADMIN;
      },
      screenProps: {
        header: {
          ...headerTextContainerStyle,
          actions: [
            <Fts
              action={{
                exp: ['krsnaaTestName', 'mayaTestName', 'mayaLabId'],
              }}
              placeholder={'Enter Test Name...'}
            />,
          ],
        },
      },
    },
    LabTestCategories: {
      label: 'Lab Test Categories',
      screen: labCategories,
      fontFamily: 'AvenirNext-Regular',
      visible: () => {
        const { userType = '' } = getUser() || {};
        return userType === roles.SUPERADMIN;
      },
      screenProps: {
        header: {
          ...headerTextContainerStyle,
          actions: [
            {
              render: () => {
                return (
                  <GradientButton label={'Add Category'} varient="curved" />
                );
              },
              type: 'link',
              link: {
                view: 'addLabCategory',
                modal: true,
                modalProps: {
                  autoHide: true,
                  screenCenterStyle: {
                    borderRadius: 10,
                    width: '35%',
                  },
                  style: {},
                },
              },
            },
          ],
        },
      },
    },
    Inferences: {
      label: 'Inferences',
      screen: inferences,
      visible: () => {
        const { userType = '' } = getUser() || {};
        return userType === roles.SUPERADMIN;
      },
      screenProps: {
        header: {
          ...headerTextContainerStyle,
          actions: [
            <Fts
              action={{
                exp: ['inferenceId', 'inferenceName'],
              }}
              placeholder={'Enter Inference Id or Inference Name...'}
            />,
          ],
        },
      },
      fontFamily: 'AvenirNext-Regular',
      },
    Patients: {
      label: 'Citizen List',
      screen: Patients,
      visible: () => {
        const { userType = '' } = getUser() || {};
        return userType !== roles.LT;
      },
      fontFamily: 'AvenirNext-Regular',
      screenProps: {
        header: {
          title: 'Citizens',
          ...headerTextContainerStyle,
          actions: [
            <Fts
              action={{
                exp: [
                  'first_name',
                  'last_name',
                  'familyId',
                  'full_name',
                  'patientId',
                ],
              }}
              placeholder={
                'Enter Member name or Family Id or Member Id here...'
              }
            />,
            (props) => {
              let { navigation, screenState } = props;
              let { setScreenState } = navigation;
              return (
                VillageFilterOnCitizenList({
                  screenState,
                  setScreenState,
                  fieldName: 'village._id',
                })
              );
            },
            (props) => {
              let { navigation, screenState } = props;
              let { setScreenState } = navigation;
              return (
                <DateFilter
                  screenState={screenState}
                  setScreenState={setScreenState}
                // defaultFilter={'Year'}
                />
              );
            },
            {
              render: () => {
                return <GradientButton label={'Add Member'} varient="curved" />;
              },
              type: 'link',
              link: {
                view: 'PatientForm',
              },
            },
            {
              render: () => {
                return <GradientButton label={'Export All'} varient="curved" />;
              },
              onPress: async (props) => {
                const { navigation } = props;
                navigation.push({
                  view: 'ExportCitizenList',
                  params: {
                    id: 'downloadPatientExcelSheet',
                    model: 'Patients',
                    queryId: 'patientExcel',
                    dataParams: props?.screenState?.dataParams,
                  },
                  modal: true,
                  modalProps: {
                    autoHide: true,
                    screenCenterStyle: {
                      borderRadius: 10,
                    },
                    style: {},
                  },
                });
              },
            },
          ],
          selectableActions: [
            download({
              file: 'memberListSheet',
              service: {
                paramValue: {
                  model: 'patients',
                  queryId: 'patientExcel',
                },
                id: 'downloadPatientExcelSheet',
              },
              render: () => {
                return (
                  <GradientButton label={'Export Selected'} varient="curved" />
                );
              },
            }),
          ],
        },
      },
    },
    assignedMember: {
      label: 'Assigned List',
      screen: assignedMemberList,
      fontFamily: 'AvenirNext-Regular',
      visible: () => {
        const { userType = '', _id } = getUser() || {};
        return userType === roles.ANM || userType === roles.DOCTOR;
      },
      screenProps: {
        header: {
          title: 'Assigned Members',
          ...headerTextContainerStyle,
          actions: [
            <Fts
              action={{
                exp: [
                  'first_name',
                  'last_name',
                  'familyId',
                  'full_name',
                  'patientId',
                ],
              }}
              placeholder={
                'Enter Member name or Family Id or Member Id here...'
              }
            />,
            {
              render: () => {
                return <GradientButton label={'Export All'} varient="curved" />;
              },
              onPress: async (props) => {
                const { userType = '', _id } = getUser() || {};
                const { navigation } = props;
                navigation.push({
                  view: 'ExportCitizenList',
                  params: {
                    id: 'downloadPatientExcelSheet',
                    model: 'Patients',
                    queryId: 'patientExcel',
                    dataParams: { userId: _id, ...props?.screenState?.dataParams },
                    userType,
                    fromAssignedMember: true,
                  },
                  modal: true,
                  modalProps: {
                    autoHide: true,
                    screenCenterStyle: {
                      borderRadius: 10,
                    },
                    style: {},
                  },
                });
              },
            },
          ],
          selectableActions: [
            download({
              file: 'memberListSheet',
              service: {
                paramValue: {
                  model: 'patients',
                  queryId: 'patientExcel',
                },
                id: 'downloadPatientExcelSheet',
              },
              render: () => {
                return (
                  <GradientButton label={'Export Selected'} varient="curved" />
                );
              },
            }),
          ],
        },
      },
    },
    ScreenPatients: {
      label: 'Pending Orders',
      screen: ScreenedPatientList,
      fontFamily: 'AvenirNext-Regular',
      visible: () => {
        const { userType = '' } = getUser() || {};
        return (
          userType === roles.MO ||
          userType === roles.LT ||
          userType === roles.SUPERADMIN ||
          userType === roles.ADMIN
        );
      },
      screenProps: {
        header: {
          title: 'Members',
          ...headerTextContainerStyle,
          actions: [
            <Fts
              action={{
                exp: [
                  'first_name',
                  'last_name',
                  'familyId',
                  'full_name',
                  'patientId',
                ],
              }}
              placeholder={
                'Enter Member name or Family Id or Member Id here...'
              }
            />,
            {
              render: () => {
                return <GradientButton label={'Export All'} varient="curved" />;
              },
              onPress: async (props) => {
                const { navigation } = props;
                navigation.push({
                  view: 'ExportCitizenList',
                  params: {
                    id: 'downloadPatientExcelSheet',
                    model: 'Patients',
                    queryId: 'patientExcel',
                    dataParams: props?.screenState?.dataParams,
                    fromPendingOrders: true,
                  },
                  modal: true,
                  modalProps: {
                    autoHide: true,
                    screenCenterStyle: {
                      borderRadius: 10,
                    },
                    style: {},
                  },
                });
              },
            },
          ],
          selectableActions: [
            download({
              file: 'memberListSheet',
              service: {
                paramValue: {
                  model: 'patients',
                  queryId: 'pendingPatientExcel',
                },
                id: 'downloadPatientExcelSheet',
              },
              render: () => {
                return (
                  <GradientButton label={'Export Selected'} varient="curved" />
                );
              },
            }),
          ],
        },
      },
    },
    Orders: {
      label: 'Screening completed',
      screen: OrderList,
      fontFamily: 'AvenirNext-Regular',
      visible: () => {
        const { userType = '' } = getUser() || {};
        return (
          userType === roles.MO ||
          userType === roles.LT ||
          userType === roles.SUPERADMIN ||
          userType === roles.ADMIN ||
          userType === roles.ANM ||
          userType === roles.DOCTOR
        );
      },
      screenProps: {
        header: {
          title: 'Orders',
          ...headerTextContainerStyle,
          actions: [
            <Fts
              action={{
                exp: [
                  'patient.first_name',
                  'patient.last_name',
                  'patient.familyId',
                  'patient.full_name',
                  'patient.patientId',
                ],
              }}
              placeholder={
                'Enter Member name or Family Id or Member Id here...'
              }
            />,
            (props) => {
              let { navigation, screenState } = props;
              let { setScreenState } = navigation;
              return (
                flagFilter({
                  screenState,
                  setScreenState,
                  fieldName: 'patient.notesFlag',
                })
              );
            },
            (props) => {
              let { navigation, screenState } = props;
              let { setScreenState } = navigation;
              return (
                VillageFilterOnCitizenList({
                  screenState,
                  setScreenState,
                  fieldName: 'patient.village._id',
                })
              );
            },
            TestResultOrderFilter,
            (props) => {
              let { navigation, screenState } = props;
              let { setScreenState } = navigation;
              return (
                <DateFilter
                  screenState={screenState}
                  setScreenState={setScreenState}
                // defaultFilter={'Year'}
                />
              );
            },
            {
              render: () => {
                return <GradientButton label={'Export All'} varient="curved" />;
              },
              onPress: async (props) => {
                const { navigation } = props;
                navigation.push({
                  view: 'exportModal',
                  params: {
                    id: 'downloadOrderExcelSheet',
                    model: 'Orders',
                    queryId: 'excelDetails',
                    dataParams: props?.screenState?.dataParams,
                  },
                  modal: true,
                  modalProps: {
                    autoHide: true,
                    screenCenterStyle: {
                      borderRadius: 10,
                      width: '30%',
                    },
                    style: {},
                  },
                });
              },
            },
          ],
          selectableActions: [
            {
              render: () => {
                return <GradientButton label={'Export Selected'} varient="curved" />;
              },
              onPress: async (props) => {
                const { navigation } = props;
                navigation.push({
                  view: 'ExportSelectedModal',
                  params: {
                    id: 'downloadOrderExcelSheet',
                    model: 'Orders',
                    queryId: 'excelDetails',
                    dataParams: props?.screenState?.selectedIds,
                  },
                  modal: true,
                  modalProps: {
                    autoHide: true,
                    screenCenterStyle: {
                      borderRadius: 10,
                      width: '30%',
                    },
                    style: {},
                  },
                });
              }
            }
          ],
        },
      },
    },
    Statistics: {
      label: 'Statistics',
      screen: MemberGraphScreen,
      visible: () => {
        const { userType = '' } = getUser() || {};
        return userType == roles.SUPERADMIN;
      },
      fontFamily: 'AvenirNext-Regular',
    },
    analysisPanel: {
      label: 'Dynamic Analysis Panel',
      screen: ShowIframe,
      visible: () => {
        const { userType = '', _id = "" } = getUser() || {};
        const isVisible = _id === "63e6251ea24c9c0021bca101" || _id === "63e624dea24c9c0021bca0f5" || _id === "63e6246ca24c9c0021bca0e9" || _id === "635a174925fe9e00211fec88" || _id === "61fe538fc1c66a00218f4e79" || _id === "61f7b85be6876c00212219b4" || _id === "642fdbfba24c9c0021bca786" || _id === "642fdbd1a24c9c0021bca77a";
        return userType === roles.SUPERADMIN || userType === roles.ADMIN || isVisible;
      },
      fontFamily: 'AvenirNext-Regular',
    },

    // manageCareProgramme: {
    //   label: 'Manage Care Programme',
    //   screen: CareProgrammeList,
    //   fontFamily: 'AvenirNext-Regular',
    //   visible: () => {
    //     const {userType = ''} = getUser() || {};
    //     return userType === roles.SUPERADMIN || userType === roles.ANM;
    //   },
    //   screenProps: {
    //     header: {
    //       title: 'Care Programmes',
    //       ...headerTextContainerStyle,
    //       actions: [
    //         {
    //           render: () => {
    //             return (
    //               <GradientButton label={'Add New Program'} varient="curved" />
    //             );
    //           },
    //           type: 'link',
    //           link: {
    //             view: 'addRpmProgramForm',
    //           },
    //         },
    //       ],
    //     },
    //   },
    // },
    // systemSetting: {
    //   label: 'System Settings',
    //   screen: MasterDataTab,
    //   fontFamily: 'AvenirNext-Regular',
    //   visible: () => {
    //     const {userType = ''} = getUser() || {};
    //     return userType === roles.SUPERADMIN;
    //   },
    // },
    // manageDevice: {
    //   label: 'Manage Device',
    //   screen: DeviceList,
    //   fontFamily: 'AvenirNext-Regular',
    //   visible: () => {
    //     const {userType = ''} = getUser() || {};
    //     return userType === roles.SUPERADMIN;
    //   },
    //   screenProps: {
    //     header: {
    //       ...headerTextContainerStyle,
    //       actions: [
    //         {
    //           render: () => {
    //             return <GradientButton label={'Add Device'} varient="curved" />;
    //           },
    //           type: 'link',
    //           link: {
    //             view: 'searchDeviceForm',
    //           },
    //         },
    //       ],
    //     },
    //   },
    // },
  },
});
