import React from 'react';
import {View, Text, Image, TouchableOpacity} from '../app-components';
import {h15_AvenirNext_500, h13_Medium} from '../theme/fonts';

import {navigationBarStyle} from '../theme/navigationBarTheme';
import {colors} from '../theme/colors';
import arLocale from 'moment/locale/ar';
import esLocale from 'moment/locale/es';
import enLocale from 'moment/locale/en-au';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

const {themeColor} = colors;
const LanguageChange = (props) => {
  const {t, i18n} = useTranslation();
  const {frameStyle, navigation = {}, hideModal = () => {}, userData = {}} =
    props || {};
  const {modalTextStyle} = navigationBarStyle;
  return (
    <View
      style={{
        ...frameStyle,
        // marginTop: 12,
        padding: 5,
        borderRadius: 8,

        backgroundColor: '#fff',
      }}>
      {/* <Text
        style={{
          ...h15_AvenirNext_500,
          fontWeight: 900,
          color: themeColor,
          alignSelf: 'center',
          marginTop: 10,
          marginBottom: 10,
        }}>
        Choose Language
      </Text> */}
      {[
        {label: 'English', value: 'en', locale: enLocale},
        {label: 'عربي', value: 'ar', locale: arLocale},
        {label: 'Español', value: 'es', locale: esLocale},
      ].map((item) => (
        <TouchableOpacity
          onPress={() => {
            i18n.changeLanguage(item.value);
            moment.locale(item.value, item.locale);
            hideModal();
          }}
          style={{
            marginBottom: 5,
            backgroundColor: item.value === i18n.language ? themeColor : '#fff',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: 'white',
            cursor: 'pointer',
            minHeight: item.value === i18n.language ? 30 : 20,
            borderRadius: 15,
          }}>
          <Text
            style={{
              color: item.value === i18n.language ? '#fff' : themeColor,
              ...h13_Medium,
              alignSelf: 'center',
            }}>
            {item.label}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default LanguageChange;
