import countrywiseSpecialityFeeForm from './countrywiseSpecialityFeeForm';
import {countryWiseFee} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Speciality',
  actions: [cancelButton(), save()],
};
export default countrywiseSpecialityFeeForm({uri: countryWiseFee, header});
