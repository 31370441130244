import React, {useState, useEffect} from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  Dimensions,
  isMobile,
} from '../../app-components';
import {
  BackgroundImage,
  MayaAiGirl,
  mayaMdLogo,
  MotivHealthLogo,
  mayaMdDarkLogo,
  StewardLogo,
} from '../../images';
import SignUp from './SignUp';
import Login from './Login';
import {BRAND_NAME} from '../../Config';
import {brandImg, brandStyle} from '../../IconStyleBrand';

const Home = (props) => {
  const {height, width} = Dimensions.get('window');
  console.log('>>>>>>>>>>>', height, width);
  const {navigation} = props;
  const [selectedTab, setSelectedTab] = useState('signin');
  const [isForgot, setForgot] = useState(false);
  const {state: {params: {type = null}} = {}} = navigation;
  useEffect(() => {
    if (!type) {
      navigation.reset('choose-user');
    }
  }, []);
  return (
    <View
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flex: 1,
        flexDirection: 'row',
      }}>
      {/* Login signup */}
      <View
        style={{
          // backgroundColor: 'red',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          className={'pulse'}
          style={{
            backgroundColor: '#fff',
            boxShadow: 'rgba(0,0,0,0.4) 5px 5px 10px 0px',
            marginBottom: 20,
            padding: 8,
            borderRadius: 20,
          }}>
          <Image
            // source={StewardLogo}
            source={brandImg[BRAND_NAME][0]}
            style={brandStyle[BRAND_NAME]['patient-login']}
          />
        </View>

        <View
          style={{
            backgroundColor: 'rgba(1, 59, 86, 0.35)',
            maxWidth: isMobile ? 320 : 400,
            minWidth: isMobile ? 320 : 400,
            minHeight: 150,
            borderRadius: 50,
            padding: 20,
          }}>
          <View
            style={{
              flex: 1,
              // backgroundColor: '#fff',
              maxHeight: 45,
              minHeight: 45,
              flexDirection: 'row',
              justifyContent: 'center',
            }}>
            {[
              {label: 'Sign Up', value: 'signup', visible: !isForgot},
              {label: 'Sign In', value: 'signin', visible: !isForgot},
            ].map((item, index) => {
              return item?.visible ? (
                <TouchableOpacity
                  onPress={() => {
                    setSelectedTab(item.value);
                  }}
                  style={{
                    backgroundColor:
                      selectedTab === item.value
                        ? 'rgba(255, 255, 255, 0.06)'
                        : '',
                    width: isMobile ? 120 : 160,
                    marginRight: index === 0 ? 10 : 0,
                    borderRadius: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Text
                    style={{
                      color:
                        selectedTab === item.value
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.6)',
                    }}>
                    {item?.label}
                  </Text>
                </TouchableOpacity>
              ) : (
                void 0
              );
            })}
            {isForgot ? (
              <>
                <TouchableOpacity
                  onPress={() => {
                    setForgot(false);
                  }}
                  style={{
                    // backgroundColor: true ? 'rgba(255, 255, 255, 0.06)' : '',
                    width: 80,
                    // marginRight: index === 0 ? 10 : 0,
                    borderRadius: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Text style={{color: '#fff'}}>{`< Back`}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: true ? 'rgba(255, 255, 255, 0.06)' : '',
                    width: 160,
                    // marginRight: index === 0 ? 10 : 0,
                    borderRadius: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Text
                    style={{
                      color: true ? '#fff' : 'rgba(255, 255, 255, 0.6)',
                    }}>
                    Reset Password
                  </Text>
                </TouchableOpacity>
              </>
            ) : (
              void 0
            )}
          </View>

          {selectedTab === 'signup' ? (
            <SignUp {...props} setSelectedTab={setSelectedTab} />
          ) : (
            void 0
          )}
          {selectedTab === 'signin' ? (
            <Login
              {...props}
              setSelectedTab={setSelectedTab}
              isForgot={isForgot}
              setForgot={setForgot}
            />
          ) : (
            void 0
          )}
        </View>
      </View>
      {isMobile ? (
        void 0
      ) : (
        <View style={{flex: 1, justifyContent: 'flex-end'}}>
          <Image
            source={MayaAiGirl}
            style={{width: '100%', objectFit: 'contain'}}
          />
        </View>
      )}
    </View>
  );
};

export default Home;
