import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import EditUserForm from './EditUserScreen';

let header = {
  title: 'Edit User',
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },

  actions: [cancelButton(), save()],
};

let editable = true;
export default EditUserForm({
  header,
  editable,
  closeView: 1,
  formType: 'edit',
});
