import React from 'react';
import Form from '../../app-components/form/Form';
import {rescheduleIcon} from '../../images';
import {close} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {
  autoSuggestInput,
  multiSearchInput,
  searchInput,
} from '../../autoSuggestions';
const header = {
  title: 'Add Progress',
  subTitle: 'Form For Demo',
  actions: [close],
};

const footer = {
  actions: [save({label: 'Done'})],
};
const FormDemo = Form({
  closeView: 1,

  reloadEvent: 'reloadProgress',
  // uri,
  header,
  footer,
  mandatory: {date: 1, achievement: 1},

  md: {
    formGroups: [
      {
        columnsPerRow: 2,
        fieldVariant: 'filled',
        fieldContainer: 'leftIcon',
        groups: [
          {
            columns: [
              {
                type: 'date',
                field: 'date',
                placeholder: 'Date',
                mandatory: true,
                icon: rescheduleIcon,
              },
            ],
          },
          {
            columnsPerRow: 1,
            columns: [
              searchInput({
                icon: rescheduleIcon,
                field: 'unit',
                query: 'units',
                model: 'MasterUnit',
                placeholder: 'Search Input',
                idField: '_id',
                searchField: 'name',
                suggestionField: 'name',
                valueField: 'name',
                container: 'leftIcon',
                mandatory: true,
              }),
              autoSuggestInput({
                icon: rescheduleIcon,
                field: 'check',
                query: 'classLists',
                model: 'Classes',
                placeholder: 'Search Input',
                idField: '_id',
                searchField: 'name',
                suggestionField: 'name',
                valueField: 'name',
                container: 'leftIcon',
                mandatory: true,
              }),
              multiSearchInput({
                icon: rescheduleIcon,
                field: 'class_id',
                query: 'classLists',
                model: 'Classes',
                placeholder: 'Search Input',
                idField: '_id',
                searchField: 'name',
                suggestionField: 'name',
                valueField: 'name',
                container: 'leftIcon',
                mandatory: true,
              }),
            ],
          },

          {
            columns: [
              {
                type: 'number',
                placeholder: 'Number Input Demo',
                field: 'number',
                icon: rescheduleIcon,
              },
              {
                type: 'time',
                field: 'effort',
                icon: rescheduleIcon,
              },
              {
                type: 'progressSlider',
                field: 'totalPercentage',
                variant: false,
              },
              ,
              {
                type: 'textArea',
                field: 'next_action',
                placeholder: 'Next Action',
                variant: 'standard',
                icon: rescheduleIcon,
              },
              {
                type: 'date',
                field: 'next_action_date',
                placeholder: 'Next Action',
              },
            ],
          },
          {
            columnsPerRow: 1,
            columns: [
              {
                icon: rescheduleIcon,
                type: 'file',
                field: 'attachments',
              },
            ],
          },
        ],
      },
    ],
  },
  sm: {
    formGroups: [
      {
        columnsPerRow: 1,
        fieldVariant: 'filled',
        fieldContainer: 'leftIcon',
        groups: [
          {
            columns: [
              {
                type: 'date',
                field: 'date',
                placeholder: 'Date',
                mandatory: true,
                icon: rescheduleIcon,
              },
            ],
          },
          {
            columns: [
              searchInput({
                icon: rescheduleIcon,
                field: 'class_id',
                query: 'classLists',
                model: 'Classes',
                placeholder: 'Search Input',
                idField: '_id',
                searchField: 'name',
                suggestionField: 'name',
                valueField: 'name',
                container: 'leftIcon',
                mandatory: true,
              }),
              autoSuggestInput({
                icon: rescheduleIcon,
                field: 'class_id',
                query: 'classLists',
                model: 'Classes',
                placeholder: 'Search Input',
                idField: '_id',
                searchField: 'name',
                suggestionField: 'name',
                valueField: 'name',
                container: 'leftIcon',
                mandatory: true,
              }),
              multiSearchInput({
                icon: rescheduleIcon,
                field: 'class_id',
                query: 'classLists',
                model: 'Classes',
                placeholder: 'Search Input',
                idField: '_id',
                searchField: 'name',
                suggestionField: 'name',
                valueField: 'name',
                container: 'leftIcon',
                mandatory: true,
              }),
            ],
          },

          {
            columns: [
              {
                type: 'number',
                placeholder: 'Number Input Demo',
                field: 'number',
                icon: rescheduleIcon,
              },
              {
                type: 'time',
                field: 'effort',
                icon: rescheduleIcon,
              },
              {
                type: 'progressSlider',
                field: 'totalPercentage',
                variant: false,
              },
              ,
              {
                type: 'textArea',
                field: 'next_action',
                placeholder: 'Next Action',
                variant: 'standard',
                icon: rescheduleIcon,
              },
              {
                type: 'date',
                field: 'next_action_date',
                placeholder: 'Next Action',
              },
            ],
          },
          {
            columns: [
              {
                icon: rescheduleIcon,
                type: 'file',
                field: 'attachments',
              },
            ],
          },
        ],
      },
    ],
  },
});

const FormView = (props) => (
  // <ScrollView style={{flex: 1, alignSelf: 'center'}}>
  <FormDemo {...props} />
  // </ScrollView>
);
export default FormView;
