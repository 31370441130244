import React from 'react';
import {View, Text} from '../../app-components';
import TabNavigator from '../../app-components/tab/BottomTabNavigator';

export default TabNavigator({
  tabs: {
    tab1: {
      label: 'Tab1',
      screen: () => (
        <View>
          <Text>This is Tab1</Text>
        </View>
      ),
    },
    tab2: {
      label: 'Tab2',
      screen: () => (
        <View>
          <Text>This is Tab2</Text>
        </View>
      ),
    },
    tab3: {
      label: 'Tab3',
      screen: () => (
        <View>
          <Text>This is Tab3</Text>
        </View>
      ),
    },
  },
});
