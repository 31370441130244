const loadData = ({fetch, uri, beforeFetch, afterFetch, state}) => {
  let beforeState;
  if (beforeFetch) {
    let resp = beforeFetch({
      state,
      uri,
    });

    if (resp) {
      if (resp.uri) {
        uri = resp.uri;
      }
      if (resp.state) {
        beforeState = resp.state;
      }
    }
  }

  return {
    beforeState,
    afterState: () => {
      return fetch({uri})
        .then(async result => {
          if (afterFetch) {
            let resultState = await afterFetch({
              state,
              result,
              uri,
            });
            if (resultState && resultState.state) {
              return resultState.state;
            }
          } else if (result) {
            return result;
          }
        })
        .catch(e => {
          console.error('Error in DataLoader while loadData', e.message);
          // ToDo handle error
        });
    },
  };
};

export {loadData};
