import OrganisationSettingForm from './OrganisationSettingForm';
import {EditOrganisationSetting} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Organisation Setting',
  actions: [cancelButton(), save()],
};

export default OrganisationSettingForm({uri: EditOrganisationSetting, header});
