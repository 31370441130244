import React from 'react';
import {Stack, Platform, isMobile, ToolBar, Snackbar} from '..';
import {setLocationView} from './Location';
import {
  stackTheme,
  stackMobileTheme,
  mayaMdStackTheme,
  mayaMdStackMobileTheme,
} from '../../theme/stackTheme';
import {getActiveMQ, getResolvedMQProps} from '../../BreakPoints';
import {
  stackFooterTheme,
  stackHeaderTheme,
  stackModalFooterTheme,
  stackModalHeaderTheme,
} from '../../theme/toolBarTheme';

let theme = isMobile ? stackMobileTheme : stackTheme;

const showBackPressMessage = () => {
  Snackbar.show({
    text: 'Double Press to Exit!',
    duration: Snackbar.LENGTH_SHORT,
  });
};

export default (views, stackOptions) => {
  class StackNavigator extends React.Component {
    onRouteChange = ({views}) => {
      if (views && views.length) {
        let view = views[0];
        if (view) {
          const {
            routeName,
            navigation: {action},
          } = view;
          if (Platform.OS === 'web') {
            setLocationView({view: routeName, action});
          }
        }
      }
    };
    renderHeader = (props) => {
      const {modal, header, ...rest} = props;
      if (header) {
        return (
          <ToolBar
            {...(modal ? stackModalHeaderTheme : stackHeaderTheme)}
            {...header}
            {...rest}
          />
        );
      } else {
        return null;
      }
    };
    renderFooter = (props) => {
      const {modal, footer, ...rest} = props;
      if (footer) {
        return (
          <ToolBar
            {...(modal ? stackModalFooterTheme : stackFooterTheme)}
            {...rest}
            {...footer}
          />
        );
      } else {
        return null;
      }
    };

    render() {
      for (let key in views) {
        let value = views[key];
        if (value?.patient) {
          theme = isMobile ? mayaMdStackMobileTheme : mayaMdStackTheme;
        }
      }
      return (
        <Stack
          getActiveMQ={getActiveMQ}
          getResolvedMQProps={getResolvedMQProps}
          {...theme}
          {...stackOptions}
          {...this.props}
          routes={views}
          onRouteChange={this.onRouteChange}
          renderHeader={this.renderHeader}
          renderFooter={this.renderFooter}
          showBackPressMessage={showBackPressMessage}
        />
      );
    }
  }
  return StackNavigator;
};
