import axios from 'axios';
import firebase, {messaging} from './FireBaseConfiguration';
import {Storage, GpsStore, Snackbar} from './app-components';
import {BASE_URL, GPS_SOCKET_URL, ENCRYPTION} from './Config';
import {isJSONObject} from './app-components/UtilityFunctions';
import uuid from 'uuid/v4';
import {getDefaultLocationView} from './app-components/stack/Location';
import {getUserViewsMap, getUserWiseDefaultView} from './ViewConstant';
import {publicRoutes} from './app-components/stack/Routes';
import {getEncryptedParams} from './Utility/encryption/Encryption';
import {getDecryptedParams} from './Utility/decryption/Decryption';
import moment from 'moment';
import Toast from './npms/react-toast';
import {resolvePatientName} from './app-components/patientUtility';
const globalProps = {
  timezoneOffset: new Date().getTimezoneOffset(),
  platform: 'web',
};
// const goolge_place_key="AIzaSyBVpN48FtFrg5-iJ87VoJFSeGAQ6dRImsU"
const sessionToken = uuid();
let user = void 0;
let token = void 0;
let baseUrl = BASE_URL;
let notificationToken = void 0;
let userViews = void 0;
let channelName = void 0;
let locUser = void 0;
let dependentPatient = [];
let selectedLoginType = void 0;
export const gpsStore = new GpsStore({url: GPS_SOCKET_URL});
const modifyUri = (props) => {
  let {
    uri: {skipData, fetchCount, query, model, id = '_find', subscribe, ...rest},
  } = props;
  if (typeof query === 'string') {
    query = {id: query};
  }
  let limit = query.limit;
  let skip = query.skip;
  if (!limit) {
    limit = 20;
    query = {...query, limit};
  }
  if (!skip) {
    skip = 0;
    query = {...query, skip};
  }

  if (fetchCount) {
    query = {...query, skipData: true};
  } else {
    query = {...query, skipAggregates: false};
  }

  if (subscribe) {
    query = {...query, metadata: true};
  }

  let uri = {
    id,
    props: {
      query,
      model,
      subscribe,
    },
  };
  return {uri, ...rest};
};
const modifyUriData = (props) => {
  let {skipData, fetchCount, query, model, id = '_find', subscribe} = props;
  if (typeof query === 'string') {
    query = {id: query};
  }
  let limit = query.limit;
  let skip = query.skip;
  if (!limit) {
    limit = 20;
    query = {...query, limit};
  }
  if (!skip) {
    skip = 0;
    query = {...query, skip};
  }

  if (fetchCount) {
    query = {...query, skipData: true};
  } else {
    query = {...query, skipAggregates: true};
  }

  if (subscribe) {
    query = {...query, metadata: true};
  }

  let uri = {
    id,
    props: {
      query,
      model,
      subscribe,
    },
  };
  return {uri, subscribe};
};
export const fetchQuery = (props) => {
  let token = getUserToken();
  let {uri, method, url, onResult} = modifyUri(props);
  let subscribe = props && props.uri && props.uri.subscribe;
  if (method && url) {
    if (method === 'get') {
      if (typeof url === 'function') {
        url = url(uri);
      }
      return axios
        .get(`${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let resp;
          // parseResponse({response: res});
          if (res.data.response) {
            let {result, ...rest} = res.data.response;
            resp = {
              data: result,
              ...rest,
            };
          } else {
            resp = res;
          }
          if (onResult) {
            resp = onResult(resp, props);
          }
          return resp;
        })
        .catch((e) => {
          handleError(e);
        });
    }
  } else {
    return fetch({
      uri,
    }).then((resp) => {
      if (onResult) {
        resp = onResult(resp, props);
      }
      return resp;
    });
  }
};

export const getLandingView = ({user} = {}) => {
  user = user || getUser();
  let defaultView = user && getUserWiseDefaultView({user});
  if (!defaultView) {
    let path = window.location.pathname;
    if (path === '/authenticator') {
      defaultView = 'superAdmin';
      // defaultView = 'clinic-signup';
    } else {
      if (!user && !publicRoutes.includes(path)) {
        defaultView = 'superAdmin';
        // defaultView = 'clinic-signup';
      } else {
        defaultView = path.split('/')[1];
      }
    }
  }
  return {
    ...getDefaultLocationView({view: defaultView}),
  };
};

export const canAccess = ({view}) => {
  return userViews && userViews[view];
};
export const fetchQueryData = (props) => () => {
  let {uri} = modifyUriData(props);

  return fetch({
    uri,
  }).then((resp) => {
    return resp;
  });
};
export const settingData = async ({id, model}) => {
  let data = await fetch({
    uri: {
      props: {
        query: {
          id: id,
        },
        model: model,
      },
    },
  });
  return data;
};
export const notificationCount = () => {
  return fetch({
    uri: {
      props: {
        query: {
          id: 'appNotificationList',
          addOnFilter: {isRead: {$exists: false}, user: {_id: user._id}},
          metadata: false,
          skipData: true,
        },
        model: 'App_Notification',
      },
    },
  });
};

export const markRead = () => {
  return fetch({
    uri: {
      id: 'markReadAppNotification',
      props: {
        query: {addOnFilter: {user: {_id: user._id}}},
      },
    },
  });
};
export const notificationData = () => {
  return fetch({
    uri: {
      props: {
        query: {
          id: 'appNotificationList',
          addOnFilter: {user: {_id: user._id}, isRead: {$exists: false}},
          metadata: false,
          skipData: false,
          limit: 500,
        },
        model: 'App_Notification',
      },
    },
  });
};

export const checkUserExist = async (
  mobile,
  type,
  sendOTP = false,
  forgotPassword = false,
) => {
  if (!mobile) {
    return Promise.reject(new Error('*Please Enter Value'));
  }
  let params = {};
  mobile = mobile.trim();
  let id = '_isUserExist';
  if (forgotPassword) {
    id = 'unsetOtp';
  }
  if (typeof mobile === 'string' && mobile.indexOf('@') > 0) {
    params.email = mobile;
  } else {
    if (typeof mobile === 'string' && mobile.startsWith('+')) {
      params.mobile = mobile;
    } else {
      params.uniqueId = mobile;
    }
  }
  if (sendOTP) {
    params.sendOTP = true;
  }
  let props = {
    paramValue: {...params, userType: type},
    id,
    token,
  };
  return await axios.post(`${baseUrl}/invoke`, props).then((user) => {
    let {result} = user && user.data && user.data.response;
    let error = void 0;
    if (!result) {
      error = `*${mobile} is not registered`;
    }
    if (error) {
      throw new Error(error);
    }

    return result;
  });
};

export const resendOtp = async ({
  mobile,
  email,
  userType = 'Patient',
  sendOTP = true,
  resendOtp = true,
}) => {
  if (!mobile) {
    return Promise.reject(new Error('*Please Enter Value'));
  }
  let params = {userType};
  mobile = mobile.trim();
  email = email.trim();
  let id = '_isUserExist';
  if (typeof email === 'string' && email.indexOf('@') > 0) {
    params.email = email;
  }
  if (typeof mobile === 'string' && mobile.startsWith('+')) {
    params.mobile = mobile;
  } else {
    params.uniqueId = mobile;
  }

  if (sendOTP) {
    params.sendOTP = true;
  }
  if (resendOtp) {
    params.resendOtp = true;
  }
  let props = {
    paramValue: {...params},
    id,
    token,
  };
  return await axios.post(`${baseUrl}/invoke`, props).then((user) => {
    let {result} = user && user.data && user.data.response;
    let error = void 0;
    if (!result) {
      error = `*${mobile} is not registered`;
    }
    if (error) {
      throw new Error(error);
    }

    return result;
  });
};
// function showPosition(position) {
//   console.log("position",position);
// }

// const getLocation=()=> {
//    console.log("get location called");
//   if (window.navigator.geolocation) {
//     window.navigator.geolocation.getCurrentPosition(showPosition);
//   } else {
//     console.log("err in getting location")
//   }
// }

const registerNotificationToken = ({type, notificationToken}) => {
  // const notificationToken=await Storage.getItem("notificationToken")
  if (!notificationToken) {
    return;
  }
  try {
    return invoke({
      paramValue: {
        device: notificationToken,
        // apn : apnToken,
        type,
      },
      id: '_notificationRegistration',
    });
  } catch (err) {
    console.log('errr', err);
  }
};

const removeFireBaseToken = ({type, notificationToken}) => {
  // if (!notificationToken) {
  //   return;
  // }
  try {
    return invoke({
      paramValue: {
        fcmToken: notificationToken,
        // apn : apnToken,
        token,
        type,
      },
      id: 'removeFcmToken',
    });
  } catch (err) {
    console.log('errr', err);
  }
};

const getFireBaseToken = () => {
  // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>: getFireBaseToken -> getFireBaseToken")
  if (firebase.messaging.isSupported()) {
    messaging
      .requestPermission()
      .then((res) => {
        return messaging.getToken();
      })
      .then((notificationTokenFireBase) => {
        notificationToken = notificationTokenFireBase;
        Storage.setItem('notificationToken', notificationTokenFireBase);

        registerNotificationToken({
          type: 'web',
          notificationToken: notificationTokenFireBase,
        })
          .then((res) => {
            console.log('sever res', res);
          })
          .catch((err) => {
            console.log('error in sending firebase token to sever', err);
          });
      })
      .catch((err) => {
        console.log('error in getting token from firebase', err);
      });
  } else {
    console.log('browser not supported');
  }
};

export const mayamd_authenticate = async ({mobile, password, type}) => {
  if (!mobile) {
    return Promise.reject(new Error('*Please Enter mob'));
  }
  if (!password) {
    return Promise.reject(new Error('*Please Enter Password'));
  }
  let params = {
    password: password.trim(),
  };
  mobile = mobile.trim();
  if (typeof mobile === 'string' && mobile.indexOf('@') > 0) {
    params.email = mobile;
  } else {
    if (typeof mobile === 'string' && mobile.startsWith('+')) {
      params.mobile = mobile;
    } else {
      params.uniqueId = mobile;
    }
  }
  if (type) {
    params.userType = type;
  }
  let props = {
    paramValue: {...params},
    id: '_authenticateUser',
    token: void 0,
  };
  return await axios
    .post(`${baseUrl}/invoke`, props)
    /* .then(res => {
 let result = res && res.data && res.data.response && res.data.response.result;
 let error = void 0;
 if (!result) {
 error = `*${email} is not registered`;
 }
 if (error) {
 throw new Error(error);
 }
 return Storage.setItem('token', token);
 return result;
 }) */
    .then(async (res) => {
      res = res.data.response;
      if (res && res.result && res.result.token) {
        // getFireBaseToken();
        token = res.result.token;
        user = res.result.user;
        // if (user.userType === 'Patient') {
        //   // console.log("kkkkk",user);
        //   const {patient = {}} = user;
        //   const {dependents = []} = patient || {};
        //   if (Array.isArray(dependents) && dependents.length) {
        //     dependentPatient = dependents.map((item) => {
        //       const {dependent_patient = {}} = item;
        //       return dependent_patient?._id;
        //     });
        //   }
        // }
        userViews = getUserViewsMap({user});
        // console.log('@@@@@user>>>>>>>>>>>', user);
        return Storage.setItem('token', token);
      } else {
        let error = new Error('user/incorrect_user_pwd');
        error.response = {
          data: {
            response: {
              error: {
                code: 'user/incorrect_user_pwd',
                message: 'user/incorrect_user_pwd',
              },
            },
          },
        };
        throw error;
      }
    })
    .catch((e) => {
      let errorResponse =
        e.response && e.response.data && e.response.data.response;
      if (errorResponse) {
        if (errorResponse && errorResponse.error && errorResponse.error.code) {
          errorResponse = errorResponse.error.code;
        } else {
          errorResponse = JSON.stringify(errorResponse);
        }
      } else {
        errorResponse = e.message;
      }
      showMessage({message: errorResponse});
      // Snackbar.show({
      //   text: `${errorResponse}`,
      //   duration: Snackbar.LENGTH_LONG,
      // });
      if (errorResponse && errorResponse.error) {
        let {
          message = 'user/provide_credential',
          code = 'user/provide_credential',
        } = errorResponse.error;
        let modifiedError = new Error(message);
        modifiedError.code = code;
        // throw modifiedError;
      } else {
        // throw e;
      }
      throw errorResponse;
    })
    .then(() => {
      return {user, token};
    })
    .catch((e) => {
      throw e;
    });
};

const handleError = (e) => {
  let errorResponse = e.response && e.response.data && e.response.data.response;
  if (errorResponse && errorResponse.error) {
    let {message = void 0, code = void 0} = errorResponse.error;
    let modifiedError = new Error(message);
    modifiedError.code = code;
    throw modifiedError;
  } else {
    throw e;
  }
};
const ConnectServices = ({
  fetchUrl,
  postUrl,
  authenticateUrl,
  uploadUrl,
  downloadUrl,
}) => {
  const upload = async (file, options = {}) => {
    let {throwError} = options;
    let name = file.name.replace(/[&\/\\#,^@!+()$~%" "'":*?<>{}-]/g, '_');
    file = new File([file], name, {type: file.type});
    let {multiPart = true, uri = '/upload', s3 = true} = options;
    if (multiPart) {
      let formData = new FormData();
      formData.append('', file);
      formData.append('token', token);
      formData.append('timezoneOffset', globalProps.timezoneOffset);
      formData.append('platform', globalProps.platform);
      if (s3) {
        formData.append('s3', true);
      }
      return axios
        .post(`${uploadUrl}${uri}`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((res) => {
          let result = res.data;
          result = result.response && result.response;
          result = result && result.length ? result[0] : result;
          return result;
        })
        .catch((e) => {
          handleError(e);
        });
    } else {
      console.warn(new Error('Upload not supported without multiPart'));
    }
  };
  const parseQuery = ({uriProps}) => {
    if (user && user.encKeys && user.encKeys.p21 && ENCRYPTION) {
      uriProps.encrypted = true;
      uriProps = getEncryptedParams(uriProps, user.encKeys.p21);
    }
    return uriProps;
  };
  const fetch = ({uri}) => {
    let {url, id, props, fetchCount} = uri; //remove fetchCount after data get from server
    url = url || fetchUrl;
    if (!id) {
      id = '_find';
    }
    let paramValue = {...props};
    let uriProps = {
      id,
      paramValue,
      token,
    };
    uriProps = parseQuery({uriProps});
    return axios
      .post(`${url}`, uriProps)
      .then((res) => {
        parseResponse({response: res});
        let {result, ...rest} = res.data.response;
        return {
          data: result,
          ...rest,
        };
      })
      .catch((e) => {
        handleError(e);
      });
  };
  const parseResponse = ({response}) => {
    if (
      response &&
      response.data &&
      response.data.response &&
      user &&
      user.encKeys &&
      user.encKeys.p32 &&
      ENCRYPTION
    ) {
      response.data.response = getDecryptedParams(
        response.data.response,
        user.encKeys.p32,
      );
    }
    return response;
  };

  const post = ({
    url = postUrl,
    id = '_batchSave',
    data,
    updates,
    batchUpdates,
    model,
  }) => {
    if (!batchUpdates) {
      let _id = data._id;

      let op = void 0;
      if (!_id || (typeof _id === 'string' && _id.startsWith('new_'))) {
        op = {insert: updates};
      } else {
        op = {update: {changes: updates, _id}};
      }
      batchUpdates = [{updates: op, model}];
    }

    let uriProps = {
      timezoneOffset: new Date().getTimezoneOffset(),
      platform: 'web',
      token,
      paramValue: {
        updates: batchUpdates,
      },
      id,
    };
    uriProps = parseQuery({uriProps});
    return axios
      .post(`${url}`, uriProps)
      .then((res) => {
        parseResponse({response: res});
        res = res.data.response;
        return res;
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const getInvokeUriProps = (props) => {
    let {selectedIds, allPageSelected = false, dataParams, uri = {}} = props;
    const {query, model, args} = uri;
    let {limit, skip, ...restQueryParams} = query || {};
    if (
      !allPageSelected &&
      selectedIds &&
      selectedIds.length &&
      restQueryParams
    ) {
      let paramsAddOnFilter = restQueryParams.addOnFilter;
      restQueryParams.addOnFilter = {...paramsAddOnFilter} || {};
      restQueryParams.addOnFilter.$and = restQueryParams.addOnFilter.$and
        ? [...restQueryParams.addOnFilter.$and]
        : [];
      restQueryParams.addOnFilter.$and.push({_id: {$in: selectedIds}});
      restQueryParams.dataParams = dataParams;
    }

    const uriProps = {
      ...globalProps,
      token,
      ...args,
      paramValue: {
        _selectedIds_: allPageSelected ? [] : selectedIds,
        _allPageSelected: allPageSelected,
        _query: restQueryParams,
        _model: model,
      },
    };
    return uriProps;
  };

  const authenticate = ({data = {}}) => {
    let {email, mobile, ...restData} = data;
    if (mobile && typeof mobile === 'string' && mobile.indexOf('@') > 0) {
      restData.email = mobile.trim();
    } else if (email) {
      restData.email = email.trim();
    } else if (mobile) {
      restData.mobile = mobile.trim();
    }

    let uriProps = {
      paramValue: {
        ...restData,
      },
      id: '_authenticateUser',
    };

    return axios
      .post(`${authenticateUrl}`, uriProps)
      .then(async (res) => {
        res = res.data.response;
        if (res && res.result && res.result.token) {
          token = res.result.token;
          user = res.result.user;
          return Storage.setItem('token', token);
        } else {
          let error = new Error('user/incorrect_user_pwd');
          error.response = {
            data: {
              response: {
                error: {
                  code: 'user/incorrect_user_pwd',
                  message: 'user/incorrect_user_pwd',
                },
              },
            },
          };

          throw error;
        }
      })
      .catch((e) => {
        let errorResponse =
          e.response && e.response.data && e.response.data.response;
        if (errorResponse) {
          if (
            errorResponse &&
            errorResponse.error &&
            errorResponse.error.code
          ) {
            errorResponse = errorResponse.error.code;
          } else {
            errorResponse = JSON.stringify(errorResponse);
          }
        } else {
          errorResponse = e.message;
        }
        Snackbar.show({
          text: `${errorResponse}`,
          duration: Snackbar.LENGTH_LONG,
        });
        if (errorResponse && errorResponse.error) {
          let {
            message = 'user/provide_credential',
            code = 'user/provide_credential',
          } = errorResponse.error;
          let modifiedError = new Error(message);
          modifiedError.code = code;
          // throw modifiedError;
        } else {
          // throw e;
        }
      })
      .then(() => {
        return {user, token};
      })
      .catch((e) => {
        // console.error('error in Todofetch', e);

        throw e;
      });
  };

  const getUser = () => {
    return user;
  };

  const logout = async () => {
    dependentPatient = [];
    // const notificationTokenFireBase = await Storage.getItem(
    //   'notificationToken',
    // );
    // if (!notificationTokenFireBase) {
    //   return Storage.clear().then(() => {
    //     token = void 0;
    //     user = void 0;
    //     userViews = void 0;
    //     notificationToken = void 0;
    //   });
    // }
    await removeFireBaseToken({
      type: 'web',
      // notificationToken: notificationTokenFireBase,
    });
    return Storage.clear().then(() => {
      token = void 0;
      user = void 0;
      userViews = void 0;
      notificationToken = void 0;
    });
  };

  const initUser = () => {
    if (user && token) {
      if (user?.userType === 'Patient') {
        logout();
        return;
      }
      return {user};
    }
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((data) => {
        locUser = {data, Allowed: true};
      });
    } else {
      locUser = {Allowed: false};
    }

    return Storage.getItem('token').then((_token) => {
      if (_token) {
        token = _token;
        let uriProps = {
          timezoneOffset: new Date().getTimezoneOffset(),
          platform: 'web',
          paramValue: {token},

          id: '_getAuthenticatedUser',
        };
        uriProps = parseQuery({uriProps});
        // return;
        return axios
          .post(`${authenticateUrl}`, uriProps)
          .then(async (res) => {
            parseResponse({response: res});
            res = res.data.response;
            if (res && res.result && res.result) {
              user = res.result;
              if (user.userType === 'Patient') {
                logout();
                return;
                // console.log("kkkkk",user);
                const {patient = {}} = user;
                const {dependents = []} = patient || {};
                if (Array.isArray(dependents) && dependents.length) {
                  dependentPatient = dependents.map((item) => {
                    const {dependent_patient = {}} = item;
                    return dependent_patient?._id;
                  });
                  // console.log(">>>>>>>>>>",dependentPatient);
                }
              }
              userViews = getUserViewsMap({user});
              console.log('ooooooooooooo', userViews);
              // getFireBaseToken();
              return Storage.setItem('token', token).then(() => {
                return {user};
              });
            } else {
              token = void 0;
              user = void 0;
              return {user: null};
            }
          })
          .catch((e) => {
            handleError(e);
          });
      } else {
        return {user: null};
      }
    });
  };

  const getDownloadUrl = ({key, name, openInNewTab}) => {
    return key
      ? `${downloadUrl}/download/s3?${
          openInNewTab ? 'inline' : 'download'
        }=true&token=${token}&fileKey=${key}&fileName=${key}`
      : void 0;
  };

  const getImageUrl1 = (params) => {
    if (typeof params === 'string') {
      return params;
    }
    const {key, name, url, uri, file} = params || {};
    if (file) {
      return file;
    }
    if (uri) {
      return uri;
    } else if (url) {
      return url;
    } else if (downloadUrl && key && name) {
      return `${downloadUrl}/download?inline=true&token=${token}&fileKey=${key}&fileName=${name}`;
    } else {
      return void 0;
    }
  };
  const getImageUrl = (file) => {
    if (!file) {
      return;
    }
    return `${BASE_URL}/download/gcs?token=${token}&fileName=${file}`;
  };
  const getProfileUrl = (file) => {
    return getImageUrl(file);
    // if (!file) {
    //   return;
    // }
    // return `${BASE_URL}/download/profile?fileName=${file}`;
  };
  const invoke = ({url, paramValue, id}) => {
    let uriProps = {
      id,
      paramValue,
      token,
    };
    uriProps = parseQuery({uriProps});
    return axios
      .post(url || postUrl, uriProps)
      .then((res) => {
        parseResponse({response: res});
        res = res.data.response;
        return res;
      })
      .catch((e) => {
        let errorResponse =
          e.response && e.response.data && e.response.data.response;
        if (errorResponse) {
          if (typeof errorResponse === 'object') {
            errorResponse = JSON.stringify(errorResponse);
          }
          e = new Error(errorResponse);
        }
        console.warn('error', e);
        throw e;
      });
  };
  const getExportColumns = (columns) => {
    if (!Array.isArray(columns)) {
      return columns;
    }
    let exportColumns = {};
    for (let item of columns) {
      exportColumns[item.header] = item.field;
    }
    return exportColumns;
  };

  const downloadData = (props = {}) => {
    let {data, selectedData, service, selectedIds, file, columns} = props;
    let uriProps = getInvokeUriProps(props);
    if (columns) {
      columns = getExportColumns(columns);
    }
    uriProps.paramValue = {
      ...uriProps.paramValue,
      file,
      ...{column: columns},
    };
    if (typeof service === 'function') {
      service = service({
        data,
        selectedData,
        selectedIds,
        uriProps,
      });
    }
    service = service || {};
    uriProps.id = service.id || '_downloadExcel';
    if (service.paramValue) {
      uriProps.paramValue = {
        ...uriProps.paramValue,
        ...service.paramValue,
      };
    }
    return new Promise((resolve) => {
      createFormAndSubmit({uri: '/invoke', uriProps});
      resolve();
    });
  };

  return {
    fetch,
    post,
    authenticate,
    initUser,
    getUser,
    logout,
    upload,
    getDownloadUrl,
    getImageUrl,
    invoke,
    getProfileUrl,
    downloadData,
  };
};

const {
  fetch: _fetch,
  post: postData,
  authenticate: authenticateData,
  getUser: getUserData,
  initUser: initUserData,
  logout: logoutData,
  downloadData: _downloadData,
  upload: uploadFile,
  getDownloadUrl: _getDownloadUrl,
  getImageUrl: _getImageUrl,
  getProfileUrl: _getProfileUrl,
  invoke: _invoke,
} = ConnectServices({
  fetchUrl: `${baseUrl}/invoke`,
  postUrl: `${baseUrl}/invoke`,
  authenticateUrl: `${baseUrl}/invoke`,
  uploadUrl: `${baseUrl}`,
  downloadUrl: `${baseUrl}`,
});

export const fetch = _fetch;
export const post = postData;
export const authenticate = authenticateData;
export const getUser = getUserData;
export const logout = logoutData;
export const initUser = initUserData;
export const upload = uploadFile;
export const getDownloadUrl = _getDownloadUrl;
export const getImageUrl = _getImageUrl;
export const getProfileUrl = _getProfileUrl;
export const invoke = _invoke;
export const downloadData = _downloadData;
export const resetOTP = ({data, eventDispatcher}) => {
  let {_id} = data;
  if (!_id) {
    return;
  }
  return invoke({
    paramValue: {_id},
    id: 'resetDefaultOTP',
  })
    .then(({result}) => {
      Snackbar.show({
        text: 'reset OTP Successfully',
        duration: Snackbar.LENGTH_LONG,
      });
      eventDispatcher && eventDispatcher.notify('reloadusers');
      return result && result.length ? result[0] : result;
    })
    .catch((e) => {
      Snackbar.show({
        text: e,
        duration: Snackbar.LENGTH_LONG,
      });
    });
};

const createFormAndSubmit = ({uri, uriProps}) => {
  let form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', `${baseUrl}${uri}`);
  for (var paramName in uriProps) {
    let paramValue = uriProps[paramName];
    let inputElm = document.createElement('input');
    inputElm.setAttribute('name', paramName);
    inputElm.setAttribute(
      'value',
      isJSONObject(paramValue) ? JSON.stringify(paramValue) : paramValue,
    );
    inputElm.setAttribute('type', 'hidden');
    form.appendChild(inputElm);
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export const downloadExcelData = (props = {}) => {
  let {uriProps} = props;
  if (!uriProps || !token) {
    return;
  }

  uriProps = {
    ...uriProps,
    token: token,
  };
  return new Promise((resolve) => {
    createFormAndSubmit({uri: '/invoke', uriProps});
    resolve();
  });
};

export const autosuggestFetch = ({
  id,
  fields,
  relationValue,
  paramValue,
  addOnFilter,
  model,
  search,
  limit = 20,
  addSearchValueIfNoData,
  service = '_find',
  modifyResult,
}) => {
  return (props) => {
    const {searchValue: searchTerm} = props;
    let searchValue = searchTerm && searchTerm.replace('+', '[+]');
    let searchFilter = {};

    if (searchValue) {
      searchFilter[search] = {
        $regex: `^${searchValue}|(?<= )${searchValue}`,
        $options: 'i',
      };
    }

    let _addOnFilter = addOnFilter;
    let _paramValue = paramValue;
    if (typeof _addOnFilter === 'function') {
      _addOnFilter = _addOnFilter({
        ...props,
        user: getUser(),
      });
    }
    if (searchValue) {
      const searchFilter = {};
      searchFilter[search] = {
        $regex: `^${searchValue}|(?<= )${searchValue}`,
        $options: 'i',
      };
      _addOnFilter = {..._addOnFilter, ...searchFilter};
    }

    if (typeof _paramValue === 'function') {
      _paramValue = _paramValue({
        ...props,
        user: getUser(),
      });
    }
    if (model) {
      return fetch({
        uri: {
          id: service,
          props: {
            query: {
              id,
              fields,
              limit,
              skip: 0,
              addOnFilter: _addOnFilter,
              paramValue: _paramValue,
              relationValue,
              skipAggregates: true,
              metadata: false,
            },
            model,
          },
        },
      }).then((result) => {
        let {data} = result;
        if (Array.isArray(data)) {
          data.sort((element1, element2) => {
            const elem1 =
              typeof element1?.[search] === 'string'
                ? element1?.[search].toLowerCase()
                : '';
            const elem2 =
              typeof element2?.[search] === 'string'
                ? element2?.[search].toLowerCase()
                : '';
            const inputStr =
              typeof searchValue === 'string' ? searchValue.toLowerCase() : '';
            if (!(elem2 && elem1 && inputStr)) {
              return 0;
            }
            if (elem1.indexOf(inputStr) < elem2.indexOf(inputStr)) {
              return -1;
            } else if (elem2.indexOf(inputStr) < elem1.indexOf(inputStr)) {
              return 1;
            } else {
              return 0;
            }
          });
        }
        if (modifyResult) {
          const modifiedResult = modifyResult(result);
          result = {
            ...result,
            ...modifiedResult,
          };
        }
        // let {data} = result;
        if (addSearchValueIfNoData && (!data || !data.length)) {
          data = [{[search]: searchValue}];
          result = {...result, data};
        }

        return result;
      });
    } else {
      return fetch({
        uri: {
          id,
          props: {
            query: {
              id,
              fields,
              limit,
              skip: 0,
              addOnFilter: _addOnFilter,
              paramValue: _paramValue,
              relationValue,
              skipAggregates: true,
              metadata: false,
            },
          },
        },
      }).then((result) => {
        if (modifyResult) {
          const modifiedResult = modifyResult(result);
          result = {
            ...result,
            ...modifiedResult,
          };
        }

        let {data} = result;
        if (addSearchValueIfNoData && (!data || !data.length)) {
          data = [{[search]: searchValue}];
          result = {...result, data};
        }

        return result;
      });
    }
  };
};

export const gettingData = async ({model, query, organization_id}) => {
  let data = await fetch({
    uri: {
      props: {
        query: {
          id: query,
          addOnFilter: {organization_id},
        },
        model: model,
      },
    },
  });
  return data;
};

export const getData = async ({model, id, filter, paramValue}) => {
  let data = await fetch({
    uri: {
      props: {
        query: {
          id: id,
          addOnFilter: filter,
          paramValue: paramValue,
        },
        model: model,
      },
    },
  });
  return data;
};

export const getDataByService = async ({id, paramValue = {}}) => {
  // let data = {
  //   appointment: _id,
  // };
  let result = await invoke({
    paramValue: paramValue,
    id,
  });
  return result;
};

export const submit = ({
  model,
  updateUser = false,
  skipFields,
  lowerCaseFields,
  id,
  validate,
}) => {
  return (props) => {
    let {data, updates, remove} = props;

    if (validate) {
      validate(props);
    }
    if (remove) {
      return post({data, model, remove});
    } else {
      data = removeFields(data, skipFields, lowerCaseFields);

      updates = removeFields(updates, skipFields, lowerCaseFields);
      console.log('data', data, updates);
      return post({data, updates, model, id}).then(({result}) => {
        if (updateUser) {
          let newUser = result && result.length ? result[0] : result;
          newUser = newUser?.result || newUser;
          user.photo = newUser?.profile_picture || user.photo;
          user.name = newUser?.name || user.name;
          user.email = newUser?.email || user.email;
          user.patient.gender = newUser?.gender || null;
          user.patient.name = newUser?.name || null;
        }
        return result && result.length ? result[0] : result;
      });
    }
  };
};

export const submitByQuery = ({query_id}) => {
  return (props) => {
    let {data, updates, remove} = props;
    invoke({
      paramValue: {data},
      id: query_id,
    }).then(({result}) => {
      return result;
    });
  };
};

const removeFields = (data, skipFields, lowerCaseFields) => {
  if (skipFields) {
    let newData = {};
    for (let key in data) {
      if (skipFields.indexOf(key) < 0) {
        newData[key] = data[key];
      }
    }
    data = newData;
  }
  if (lowerCaseFields) {
    let newData = {};
    for (let key in data) {
      if (lowerCaseFields.indexOf(key) < 0) {
        newData[key] = data[key];
      } else {
        let newValue = data[key];
        if (newValue && typeof newValue === 'string') {
          newValue = newValue.toLowerCase().trim();
        }
        newData[key] = newValue;
      }
    }
    data = newData;
  }
  return data;
};

export const googlePlaceFetch = ({searchValue = ''}) => {
  searchValue = searchValue.toLowerCase();
  let path = `/maps/api/place/autocomplete/json?input=${encodeURI(
    searchValue,
  )}&inputtype=textquery&sessiontoken=${sessionToken}&key=`;
  return fetch({
    uri: {
      id: '_getGooglePlace',
      props: {
        query: {
          addOnFilter: {path},
        },
      },
    },
  }).then((result) => {
    let predictions = (result && result.data && result.data.predictions) || [];
    return {data: predictions};
  });
};

const populateGoogleAddressLevels = async (result) => {
  let address_components = result && result.address_components;
  if (!address_components) {
    return;
  }
  let level = 0;
  let address_levels = void 0;
  let addressLevelsToManage = [];

  for (let i = address_components.length - 1; i >= 0; i--) {
    var locationValue = address_components[i];
    addressLevelsToManage.push(locationValue.types[0]);
  }
  for (var j = address_components.length - 1; j >= 0; j--) {
    var locationValue = address_components[j];
    var levelValue = addressLevelsToManage[level];
    if (locationValue.types && locationValue.types.indexOf(levelValue) !== -1) {
      address_levels = address_levels || {};
      if (levelValue === 'country') {
        address_levels.country_code = locationValue.short_name;
      }
      address_levels[levelValue] = locationValue.long_name;
      level += 1;
    }
    if (level === addressLevelsToManage.length) {
      break;
    }
  }
  return address_levels;
};

export const removeData = async ({item, model}) => {
  let updates = {
    isDeleted: true,
    _id: item._id,
  };
  let {_id, ...rest} = updates;
  await post({
    updates: rest,
    data: updates,
    model,
  });
};

export const removeHardData = async ({item, model}) => {
  let updates = {
    isDeleted: true,
    _id: item._id,
  };
  let {_id, ...rest} = updates;
  const batchUpdates = [
    {
      updates: {remove: {_id}},
      model,
    },
  ];

  await post({batchUpdates});
};

export const getGooglePlaceDetail = (value) => {
  let {place_id, description} = value;
  let path = `/maps/api/place/details/json?placeid=${place_id}&sessiontoken=${sessionToken}&key=`;
  return fetch({
    uri: {
      id: '_getGooglePlace',
      props: {
        query: {
          addOnFilter: {path},
        },
      },
    },
  })
    .then(async (resp) => {
      let {
        data: {result},
      } = resp;

      result = result || {};
      if (result) {
        let value = void 0;
        value = await populateGoogleAddressLevels(result);
        if (value) {
          if (value.administrative_area_level_1) {
            value.state = value.administrative_area_level_1;
          }
          if (value.administrative_area_level_2) {
            value.city = value.administrative_area_level_2;
          }
          if (value.postal_code) {
            value.pin_code = value.postal_code;
          }

          result = {...result, ...value};
        }
      }

      if (result.formatted_address && result.id) {
        result._id = result.id + result.formatted_address;
      }
      let {
        _id,
        country,
        city,
        state,
        pin_code,
        geometry: {location: {lat, lng} = {}} = {},
        country_code,
      } = result || {};
      let resultToSave = {
        _id,
        place_id,
        country,
        city,
        state,
        pin_code,
        description,
        latitude: lat,
        longitude: lng,
        country_code,
      };
      return resultToSave;
    })
    .catch((e) => {
      throw e;
    });
};

export const getOrganizationData = async ({id}) => {
  let data = await fetch({
    uri: {
      props: {
        query: {
          id: 'organizationLists',
          addOnFilter: {
            _id: id,
          },
        },

        model: 'Organizations',
      },
    },
  });
  return data;
};

export const getPatientProfile = async ({_id}) => {
  let data = await fetch({
    uri: {
      props: {
        query: {
          id: 'patientLists',
          addOnFilter: {
            _id,
          },
        },
        model: 'Patients',
      },
    },
  });
  return data;
};

export const getDoctorProfile = async ({_id}) => {
  let data = await fetch({
    uri: {
      props: {
        query: {
          id: 'doctorLists',
          addOnFilter: {
            _id,
          },
        },

        model: 'Doctor',
      },
    },
  });
  return data;
};

export const actionImport = ({data}) => {
  let {_id} = data || {};
  if (!_id) {
    return;
  }
  return invoke({
    paramValue: {
      _selectedIds_: [_id],
      _allPageSelected: false,
      _id,
      _query: {
        id: 'importHistoryLogList',
        metadata: false,
        dataParams: {},
        addOnFilter: {
          $and: [
            {
              _id: {
                $in: [_id],
              },
            },
          ],
        },
      },
      _model: 'ImportHistoryLogs',
    },
    id: '_importExcel',
  })
    .then(({result}) => {
      Snackbar.show({
        text: 'import success',
        duration: Snackbar.LENGTH_LONG,
      });
      return result;
    })
    .catch((err) => {
      Snackbar.show({
        text: `${err}`,
        duration: Snackbar.LENGTH_LONG,
      });
      return err;
    });
};

export const checkDob = (commingDOB) => {
  return new Date(commingDOB) < new Date();
};

export const mayaAISession = async ({mobile}) => {
  const {patient = {}, _id} = user || {};
  const {gender = null, dob = null, dependents = []} = patient || {};
  let name = resolvePatientName(patient);
  let previousData = {};
  let dependentPatientList = [];
  const response = await getData({
    model: 'PatientNotes',
    id: 'recentPatientNote',
    paramValue: {patient: {_id}},
  });
  const {data} = response;
  previousData = {...data};
  const {input = {}} = previousData || {};
  if (Array.isArray(dependents) && dependents.length) {
    dependentPatientList = dependents.map((item) => {
      const {dependent_patient = {}} = item;
      const {
        gender: dependentPatientGender = '',
        birthDate = null,
        _id: id,
        email,
      } = dependent_patient;
      return {
        name: resolvePatientName(dependent_patient),
        dob: birthDate ? moment(birthDate).format('MM/DD/YYYY') : null,
        gende: dependentPatientGender,
        id,
        email,
      };
    });
  }
  return axios
    .post('https://chatbot-daffodil.mayamd.ai/initSession', {
      apiKey: '605c50af7af3aa0010129787',
      // apiKey: '60407d20ba0b3100110dafde',
      apiSecret: 'c/TUl8G%sVWFl?Becg~aY~j',
      // apiSecret: 'reFTpu(i$-$YOKd>2?&.wL',
      id: mobile,
      name,
      input,
      sex: gender ? gender.toLowerCase() : null,
      dob: dob ? moment(dob).format('MM/DD/YYYY') : null,
      dependents: dependentPatientList,
    })
    .then((res) => {
      if (res && res.data) {
        const {data: {sessionId = ''} = {}} = res || {};
        return sessionId;
      }
    })
    .catch((e) => {
      // handleError(e);
    });
};
export const importFile = ({model, skipFields, id}) => {
  return async (props) => {
    let {data, updates} = props;
    if (updates && updates.model === 'Drugs') {
      updates = {...updates, source: 'MasterDrug'};
    } else {
      updates = {...updates, source: updates.model};
    }
    return submit({model, skipFields, id})({...props, updates});
  };
};
export const setUser = (updatedUser = {}) => {
  user = updatedUser;
};
let doctor = 'Select Doctor';
let isLogged = false;

export const getSelectedDoctor = () => {
  return doctor;
};

export const setSelectedDoctor = (doctorName) => {
  doctor = doctorName;
};

export const setInitiallyLogged = () => {
  const user = getUser();
  if (user) {
    isLogged = true;
  } else {
    isLogged = false;
  }
};

export const getinitiallyLogged = () => {
  return isLogged;
};

export const setChannelName = (channel) => {
  channelName = channel;
};

export const getChannelName = () => {
  return channelName;
};

export const getCurrency = (item = 'USD') => {
  // console.log(`>@>Puru ~ file: AppServices.js ~ line 1567 ~ item`, item)
  const currencies = {
    INR: '₹',
    USD: '$',
    KWD: '%',
  };
  return currencies[item] || '$';
};

export const getUserLocation = () => {
  return locUser;
};
export const getDependentPatient = () => {
  return dependentPatient;
};
export const setDependentPatient = ({id = ''}) => {
  if (Array.isArray(dependentPatient) && !dependentPatient.includes(id)) {
    dependentPatient = [...(dependentPatient ? dependentPatient : []), id];
  }
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateMobile = (mobile) => {
  const INMOBILE = /^\+91 [0-9]{5} [0-9]{5}$/;
  const USMOBILE = /^\+1 [0-9]{3} [0-9]{3} [0-9]{4}$/;
  return INMOBILE.test(mobile) || USMOBILE.test(mobile);
};

export const getImageStyle = ({imageType}) => {
  if (imageType === 'landscape') {
    return {
      objectFit: 'cover',
      width: 'auto',
      height: '100%',
    };
  }
  return {
    width: '100%',
    height: 'auto',
  };
};
export const waitingRoom = (value) => {
  const {doctor = {}} = user;
  return post({
    data: {_id: doctor?._id},
    updates: {waiting_room_available: value},
    model: 'Doctor',
  })
    .then((res) => {
      let tempDoctor = {...doctor, waiting_room_available: value};
      user = {...user, doctor: tempDoctor};
    })
    .catch((err) => {
      console.log('>@>Puru ~ file: AppServices.js ~ line 1636 ~ err', err);
    });
};

export const showMessage = ({message, duration = null}) => {
  if (duration) {
    Toast.show(message, Snackbar.LENGTH_LONG * duration);
  } else {
    Toast.show(message, Snackbar.LENGTH_LONG);
  }
};

export const calculateTime = (time, reverse = false) => {
  let CurrentTime = moment(new Date());
  let GivenTime = moment(time);

  return reverse
    ? GivenTime.diff(CurrentTime, 'seconds')
    : CurrentTime.diff(GivenTime, 'seconds');
};

export const setSelectedLoginType = (type) => {
  selectedLoginType = type;
};
export const getSelectedLoginType = () => {
  return selectedLoginType || '';
};

export const getUserToken = () => {
  return token;
};

const getPrefix = (num) => {
  if (num < 10 && num !== 0) {
    return '0' + num;
  }
  return num;
};
export const secondsToHms = (d, short = true) => {
  d = Number(d);

  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? getPrefix(h) + (h == 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay =
    m > 0 ? getPrefix(m) + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  var sDisplay = s > 0 ? getPrefix(s) + (s == 1 ? ' second' : ' seconds') : '';

  if (short) {
    var shortHDisplay = h > 0 ? getPrefix(h) + (h == 1 ? ' hr ' : ' hrs ') : '';
    var shortMDisplay =
      m > 0 ? getPrefix(m) + (m == 1 ? ' min ' : ' mins ') : '';
    var shortSDisplay = s > 0 ? getPrefix(s) + (s == 1 ? ' sec' : ' sec') : '';
    if (!shortHDisplay && !shortMDisplay && !shortSDisplay) {
      return '0 sec';
    }
    return shortHDisplay + shortMDisplay + shortSDisplay;
  }
  if (!hDisplay && !mDisplay && !sDisplay) {
    return '0 second';
  }
  return hDisplay + mDisplay + sDisplay;
};
