import {LoginTemplate} from './index';
import {
  initUser,
  mayamd_authenticate,
  checkUserExist,
  getLandingView,
} from '../../AppServices';
import webTheme from '../../theme/loginTheme';
import mobileTheme from '../../theme/loginMobileTheme';
import {authenticatorTheme} from '../../theme/authenticatorTheme';
import {
  loginLogo,
  login_background,
  login_background_web,
  classRoom_logo,
  helpIcon,
  otpLogo,
  back,
  hospitalLiveLogo,
  mayaMdLogo,
  SVMLogo,
} from '../../images';
import {isMobile} from '../../app-components';

let bgImage = isMobile ? login_background : login_background_web;
let theme = isMobile ? mobileTheme : webTheme;
const {loginTheme, otpTheme} = theme;

const {Login, OTP, Authenticator, SuperAdminLogin} = LoginTemplate({
  login: {
    theme: loginTheme,
    checkUserExist,
    images: {
      bgImage,
      helpIcon,
      loginLogo,
      hospitalLiveLogo,
      classRoom_logo,
      SVMLogo,
    },
  },
  otp: {
    authenticate: mayamd_authenticate,
    theme: otpTheme,
    images: {
      bgImage,
      helpIcon,
      otpLogo,
      classRoom_logo,
      back,
      mayaMdLogo,
      SVMLogo,
    },
  },
  authenticator: {
    initUser,
    theme: authenticatorTheme,
    landingView: getLandingView,
  },
});

export {Authenticator, Login, OTP, SuperAdminLogin};
