import React from 'react';
import {DataFetch as DataFetchComponent} from '../../npms/react-datafetch';
import {beforeFetch, afterFetch} from './DataFetchFunctions';
import {fetchQuery, getUser} from '../../AppServices';

let DataFetchHoc = (props) => {
  return (
    <DataFetchComponent
      fetch={fetchQuery}
      reloadOnNavigationChange={true}
      reloadOnDataParamsChange={true}
      user={getUser()}
      beforeFetch={beforeFetch}
      afterFetch={afterFetch}
      {...props}
    />
  );
};

export const DataFetch = DataFetchHoc;
