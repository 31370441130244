import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from '../../../app-components';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import {h12_SemiBold, h14_AvenirNext, h16_Regular} from '../../../theme/fonts';
import {TextInput} from '../../../npms/react-text-input';
import {getUser, invoke} from '../../../AppServices';
import {resolvePatientName} from '../../../app-components/patientUtility';
import {TextAreaInput} from '../../../app-components/input-components/Editors';
const getUserId = () => {
  const {doctor, userType, medicalAssistant, nurse} = getUser();
  switch (userType) {
    case 'Doctor':
      return doctor;
    case 'MedicalAssistant':
      return medicalAssistant;
    case 'Nurse':
      return nurse;
    default:
      return null;
  }
};
function AddManualTime(props) {
  const {navigation = {},eventDispatcher} = props;
  const {state: {params: {item} = {}} = {}} = navigation;
  console.log(`>@>Puru ~ file: AddManualTime.js ~ line 25 ~ props`, props)
  const {patient = {}} = item || {};
  const [data, setData] = useState({
    reason: '',
    min: '',
    sec: '',
  });
  const [error, setError] = useState('');
  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: 500,
        padding: 20,
      }}>
      <Text style={{marginBottom: 10, marginTop: 10, ...h16_Regular}}>
        {`Add time for `}
        <strong>{`${resolvePatientName(patient)}`}</strong>
      </Text>
      <Text style={{marginBottom: 10, marginTop: 10, ...h14_AvenirNext}}>
        Duration
      </Text>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          // marginTop: 10,
          maxWidth: 420,
        }}>
        <TextInput
          value={data?.min}
          onChangeText={(value) => {
            setError("")
            setData({...data, min: value});
          }}
          variant="filled"
          placeholder={'Minutes'}
          inputProps={{
            type: 'number',
          }}
        />
        <TextInput
          value={data?.sec}
          onChangeText={(value) => {
            setError("")
            setData({...data, sec: value});
          }}
          variant="filled"
          placeholder={'Seconds'}
          inputProps={{
            type: 'number',
          }}
        />
      </View>
      <Text style={{marginBottom: 10, marginTop: 10, ...h14_AvenirNext}}>
        Reason
      </Text>
      <TextAreaInput
        variant="filled"
        value={data?.reason}
        onChangeText={(value) => {
          setError("")
          setData({...data, reason: value});
        }}
      />

      <TouchableOpacity
        style={{
          cursor: 'pointer',
          marginTop: 30,
          width: 300,
          alignSelf: 'center',
        }}
        onPress={() => {
          const seconds = Number(data?.min) * 60 + Number(data?.sec);
          const {reason} =data
          if (!seconds) {
            setError('Please provide time!');
          } else if (!reason.trim()) {
            setError('Please provide reason!');
          } else {
            invoke({
              id: 'updateTimerRecord',
              paramValue: {
                type: 'manual',
                patientId: patient?._id,
                seconds,
                memberId: getUserId()?._id,
                timeZoneOffset: new Date().getTimezoneOffset(),
                reason: reason,
              },
            })
              .then((res) => {
                navigation.pop();
                eventDispatcher.notify("reloadTimer")
              })
              .catch((err) => {
                const {error: {message = {}} = {}} =
                  JSON.parse(err?.message) || {};
                setError(message);
                console.log(
                  `>@>Puru ~ file: Timer.js ~ line 53 ~ err`,
                  message,
                );
              });
          }
        }}>
        {error ? (
          <Text style={{textAlign: 'center', color: 'red', ...h12_SemiBold,marginBottom:3}}>
            {error}
          </Text>
        ) : (
          ''
        )}
        <GradientButton label="Submit" />
      </TouchableOpacity>
    </View>
  );
}

export default AddManualTime;
