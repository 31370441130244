import React from 'react';
import Table from '../../app-components/table/Table';
import {Text, View} from '../../app-components';
import {edit_icon} from '../../images';
import {listUri} from '../../Queries';
import {h14_AvenirNext, h14_Medium} from '../../theme/fonts';

let uri = () => {
  return listUri({model: 'Settings', id: 'settingLists'});
};

const tableData = {
  columns: [
    {width: 50},
    {
      header: 'Joining Time',
      render: ({item}) => {
        return (
          <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
            {item?.joining_time_limit + ' mins'}
          </Text>
        );
      },
      width: 200,
    },
    {
      header: 'Chat Time',
      render: ({item}) => {
        return (
          <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
            {item?.chat_time_limit + ' days'}
          </Text>
        );
      },
    },
  ],
  hoverActions: [
    {
      value: 'Edit',
      textStyle: {
        color: '#9a9a9a',
        fontWeight: 500,
        ...h14_AvenirNext,
      },
      action: ({item}) => {
        return {
          type: 'link',
          link: {
            view: 'edit-setting',
            params: {item},
          },
        };
      },
    },
  ],
};

export default Table({
  uri,
  ...tableData,
  reloadEvent: 'reloadSetting',
});
