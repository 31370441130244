import React, { useCallback } from 'react';
import moment from 'moment';

import Table from '../../app-components/table/Table';
import PatientDetails from './PatientDetails';
import {Text, View} from '../../app-components';
import { get } from 'lodash';

const processTextData = (data) => {
  return (
    <Text
      style={{
        color: '#000',
        fontSize: 14,
        marginLeft: 10,
        fontFamily: 'AvenirNext-Regular',
      }}>
      {data}
    </Text>
  );
};

const uri = () => {
  return {
    query: {
      id: 'orderDetails',
      sort: {_id: -1},
    },
    model: 'Orders',
  };
};

export default Table({
  uri,
  reloadEvent: 'reloadOrderDetails',
  reloadOnChangeEvent: 'reloadOrderDetails',
  action: ({item}) => {
    return {
      type: 'link',
      link: {
        view: 'detailOrderForm',
        params: {item},
      },
    };
  },
  columns: [
    {type: 'selection', field: '_id'},
    {
      width: 15,
      render: ({ item }) => {
        const { notesFlag } = get(item, 'patient', {});

        const getBorderColor = () => {
          let color = '';
          if (notesFlag === 'Red') {
            color = "#FF0000";
          } else if (notesFlag === 'Orange') {
            color = "#ff8c00";
          } else if (notesFlag === "Green") {
            color = "#76ff03";
          } else {
            color = '#A9A9A9';
          }
          return color;
        };
        return (
          <View style={{ borderLeftWidth: 5, borderLeftColor: getBorderColor(), height: 50 }} />
        )
      }
    },
    {
      render: ({item}) => {
        const items = item?.patient;
        return <PatientDetails item={items} showTitle />;
      },
      header: 'Member Details',
    },
    {
      header: 'Family Id',
      type: 'text',
      render: ({item}) => {
        return processTextData(item?.patient?.familyId);
      },
      sortable: 'familyId',
      reverseSort: true,
    },
    {
      header: 'Member Id',
      type: 'text',
      render: ({item}) => {
        return processTextData(item?.patient?.patientId);
      },
      sortable: 'patientId',
      reverseSort: true,
    },
    {
      header: 'Arrived On',
      type: 'date',
      field: '_createdOn',
    },
    {
      header: 'Result Status',
      type: 'text',
      render: ({item}) => {
        const resultStatus = item?.testResult;
        const testResultArriveDate = item?.testResultArriveDate;
        if (resultStatus) {
          return processTextData(
            'Arrived On ' + moment(testResultArriveDate).format('DD MMM YYYY'),
          );
        } else {
          return processTextData('Pending');
        }
      },
    },
  ],
});
