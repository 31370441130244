import React from 'react';
import {View, Text} from '../../app-components';
import {getUser, invoke, secondsToHms} from '../../AppServices';
import {h16_Medium} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import CryptoJS from 'crypto-js';

const {themeColor} = colors;

const key = `A5O9u10AAP9XBeW6lzGOLx7zHH9AsUDUTb2OggYGMzd0P3ulJ2AfvQ4RtCZBbGlj
pFgEXEcE6RYJKwYBBAHaRw8BAQdArjWw23AqyiFbFBKT4TzXcVBqPTB3gUmzlC/U
sAcCBhUKCQgLAgQWAgMBAh4BAheAFiEW64W7X6M6deFelE5j8jFVDE9H444FAl2l
4SBMb3ZlbGFj==Krishna==ZSA8YWxpY2VAb3BlbnBncC5leGFtcGxlPoiQBBMWCAA4AhsDBQsJ
LzoACgkQ8jFVDE9H447pKwD6A5xwUqIDprBzrHfahrImaYEZzncqb25vkLV2arYf
dEGXVQEFAQEHQEL/BiGtq0k84Km1wqQw2DIikVYrQrMttN8d7BPfnr4iAwEIBwAA
78aA/R3AwtLQvjxwLDuzkartik4dUtUwvUYibL2sAHwj2kGaHnfICnF0EXEcE6RIKKwYB
b7O1u10AAP9XBeW6lzGOLx7zHH9ACBBYCAwECHgECF4AWIQTrhbtfozp1MBAh4ah
eAFiEE64W7X4/xFPG6U17rhTuq+07gmEvaFYKfxRB6sgAYiW6TMTpQEK6IeAQYFg
AIBYhBOuF hcGs1O0RkWNQWbUzQ8nUOeD9wNbjE3zR+Mu88DsEw22jKl52lkqMcx
hcGs1O0RkWNQWbUzQ8nUOeD9wNbjE3zR+yfRAQDbYqvtWQKN4AQLTxVJN5X5AWyb
up+jOnXhXpROY/IxVQxPR+OOBQJcRwTpAhsMAAoJEPuruIxVQxPR+OOWdABAMUdSzpM
Pjn+We1aTBhaGa86AQ==
=t8OM`;
const inActiveTime = 10;
let listnerTime = 0;
const invokeFire = true;
class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {seconds: 0};
  }
  getUserId = () => {
    const {doctor, userType, medicalAssistant, nurse} = getUser();
    switch (userType) {
      case 'Doctor':
        return doctor;
      case 'MedicalAssistant':
        return medicalAssistant;
      case 'Nurse':
        return nurse;
      default:
        return null;
    }
  };
  mouseMoveListener = (e) => {
    listnerTime = this.state.seconds;
  };
  keyPressListener = (e) => {
    listnerTime = this.state.seconds;
  };
  fetchLocalData = () => {
    if (localStorage.getItem('timer')) {
      const data = localStorage.getItem('timer');
      const bytes = CryptoJS.AES.decrypt(data, key);
      if (bytes.toString(CryptoJS.enc.Utf8)) {
        const {type, patientId, seconds, memberId, timeZoneOffset} = JSON.parse(
          bytes.toString(CryptoJS.enc.Utf8),
        );
        if (type && patientId && seconds && memberId && timeZoneOffset) {
          invokeFire &&
            invoke({
              id: 'updateTimerRecord',
              paramValue: {type, patientId, seconds, memberId, timeZoneOffset},
            })
              .then((res) => {
                console.log(`>@>Puru ~ file: Timer.js ~ line 53 ~ res`, res);
              })
              .catch((err) => {
                console.log(`>@>Puru ~ file: Timer.js ~ line 53 ~ err`, err);
              });
        }
      }
    }
  };
  componentDidMount = async () => {
    this.rootElement = document.getElementById('root');
    this.rootElement.addEventListener('mousemove', this.mouseMoveListener);
    const {type = ''} = this.props || {};
    if (type === 'chat') {
      this.rootElement.addEventListener('keypress', this.keyPressListener);
    }
    this.fetchLocalData();
    this.timerID = setInterval(() => this.tick(), 1000);
  };

  componentWillUnmount() {
    const {seconds} = this.state;
    if (seconds > 1) {
      this.sendData();
    }
    const {type = ''} = this.props || {};
    if (type === 'chat') {
      this.rootElement.removeEventListener('keypress', this.keyPressListener);
    }
    this.rootElement.removeEventListener('mousemove', this.mouseMoveListener);
    clearInterval(this.timerID);
  }
  tick = () => {
    const {seconds} = this.state;
    const {type = '', patientId = ''} = this.props || {};
    if (seconds - listnerTime != inActiveTime) {
      this.setState({seconds: seconds + 1});
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify({
          seconds: seconds + 1,
          type,
          patientId,
          memberId: this.getUserId()?._id,
          timeZoneOffset: new Date().getTimezoneOffset(),
        }),
        key,
      ).toString();
      localStorage.setItem('timer', ciphertext);
    }
  };

  sendData = async () => {
    const {type = '', patientId = ''} = this.props || {};
    console.log(`>@>Puru ~ file: Timer.js ~ line 119 ~ this.props`, this.props);
    const {seconds} = this.state || {};
    localStorage.removeItem('timer');
    invokeFire &&
      invoke({
        id: 'updateTimerRecord',
        paramValue: {
          type,
          patientId,
          seconds,
          memberId: this.getUserId()?._id,
          timeZoneOffset: new Date().getTimezoneOffset(),
        },
      })
        .then((res) => {
          console.log(`>@>Puru ~ file: Timer.js ~ line 53 ~ res`, res);
        })
        .catch((err) => {
          console.log(`>@>Puru ~ file: Timer.js ~ line 53 ~ err`, err);
        });
  };

  render() {
    const {seconds} = this.state;
    const {timerStyle = {}} = this.props;
    const d = Number(seconds);
    const h = Math.floor(d / 3600);
    return (
      <View style={{justifyContent: 'center'}}>
        <Text
          style={[
            {
              color: 'rgb(39, 93, 173)' || themeColor || '#383838',
              ...h16_Medium,
            },
            timerStyle,
          ]}>
          {/* {secondsToHms(seconds)} */}
          {`Elapsed Time - ${
            h
              ? new Date(seconds * 1000).toISOString().substr(11, 8)
              : new Date(seconds * 1000).toISOString().substr(14, 5)
          }`}
          {/* {new Date(seconds * 1000).toISOString().substr(11, 8)} */}
          {/* {new Date(SECONDS * 1000).toISOString().substr(14, 5)} // if MM:SS */}
          {/* newwd fdfdgfb */}
        </Text>
      </View>
    );
  }
}
export default Timer;
