import React from 'react';
import {fetch, submit} from '../../AppServices';
import Form from '../../app-components/form/Form';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import {searchInput} from '../../autoSuggestions';
import {Table} from '../../app-components/table/Table';
import {isEmpty} from 'lodash';
import {serialNumber} from './rpmUtils';

export default Form({
  type: 'standardShadow',
  reloadEvent: 'reloadPatient',
  closeView: 1,
  mandatory: {
    care_program_speciality: 1,
  },
  onSubmit: submit({
    model: 'PatientProgramHistory',
  }),
  fieldVariant: 'filled',
  fieldContainer: 'topLabel',
  header: {
    title: 'Add Data',
    titleTextStyle: {
      color: 'rgba(56, 56, 56, 0.87)',
      fontSize: 20,
      marginLeft: 10,
      fontFamily: 'AvenirNext-Regular',
    },
    actions: [cancelButton(), save({label: 'Add Data'})],
  },
  beforeSubmit: ({updates}) => {
    delete updates.careProgram;
    delete updates.pastData;
    return {
      updates: {
        ...updates,
      },
    };
  },
  computations: {
    self: {
      default_Value: {
        compute: async (props) => {
          const {patient_id, care_program_speciality} = props || {};
          let pastDataRes;
          try {
            pastDataRes = await fetch({
              uri: {
                props: {
                  query: {
                    id: 'programHistoryList',
                    addOnFilter: {
                      patient_id,
                      care_program_speciality,
                    },
                    limit: 5,
                    sort: {_id: -1},
                  },
                  model: 'PatientProgramHistory',
                },
              },
            });
          } catch (err) {
            console.log(`AddPatientDataFrom.js~line 66 ~ err`, err);
            pastDataRes = {};
          }
          const {data = []} = pastDataRes || {};
          return {
            set: {
              gfr: '',
              ckd: '',
              diet_plan: '',
              chf: '',
              ef: '',
              albumin: '',
              etiology: '',
              creatinine: '',
              ...(Array.isArray(data) && data.length ? {pastData: data} : {}),
            },
          };
        },
        onChange: ['care_program_speciality', 'care_program_speciality._id'],
      },
    },
  },
  defaultValues: ({
    navigation: {
      state: {params: {item = {}} = {}},
    },
  }) => {
    const {_id = ''} = item;
    return {
      patient_id: {_id},
    };
  },
  formGroups: [
    {
      groups: [
        {
          columnsPerRow: 2,
          columns: [
            searchInput(
              {
                label: 'Care Program Speciality',
                placeholder: 'Choose Care Program Speciality',
                field: 'care_program_speciality',
                query: 'careProgramSpecialityList',
                idField: '_id',
                model: 'CareProgramSpeciality',
                valueField: 'name',
                suggestionField: 'name',
                searchField: 'name',
              },
              {
                modifyResult: ({data}) => {
                  const newData = data.map((item) => ({
                    ...item,
                    name: item?.speciality?.name,
                  }));
                  return {data: newData};
                },
              },
            ),
            {
              type: 'custom',
              render: () => {
                return [];
              },
            },
            {
              type: 'text',
              label: 'CKD',
              field: 'ckd',
              visible: (props) => {
                const {data = {}} = props;
                return !!data?.care_program_speciality?.CKD;
              },
            },
            {
              type: 'text',
              label: 'Diet Plan',
              field: 'diet_plan',
              visible: (props) => {
                const {data = {}} = props;
                return !!data?.care_program_speciality?.DietPlan;
              },
            },
            {
              type: 'text',
              label: 'Albumin',
              field: 'albumin',
              visible: (props) => {
                const {data = {}} = props;
                return !!data?.care_program_speciality?.Albumin;
              },
            },
            {
              type: 'text',
              label: 'Etiology',
              field: 'etiology',
              visible: (props) => {
                const {data = {}} = props;
                return !!data?.care_program_speciality?.Etiology;
              },
            },
            {
              type: 'text',
              label: 'Creatinine',
              field: 'creatinine',
              visible: (props) => {
                const {data = {}} = props;
                return !!data?.care_program_speciality?.Creatinine;
              },
            },
            {
              type: 'text',
              label: 'GFR',
              field: 'gfr',
              visible: (props) => {
                const {data = {}} = props;
                return !!data?.care_program_speciality?.GFR;
              },
            },
            {
              type: 'text',
              label: 'EF',
              field: 'ef',
              visible: (props) => {
                const {data = {}} = props;
                return !!data?.care_program_speciality?.EF;
              },
            },
            {
              type: 'text',
              label: 'CHF',
              field: 'chf',
              visible: (props) => {
                const {data = {}} = props;
                return !!data?.care_program_speciality?.CHF;
              },
            },
          ],
        },
        {
          columnsPerRow: 1,
          columns: [
            {
              type: 'custom',
              render: (props) => {
                const {
                  data: {pastData = [], care_program_speciality = {}} = {},
                } = props || {};
                
                if (isEmpty(pastData) || isEmpty(care_program_speciality)) {
                  return [];
                }
                let columns = [
                  {
                    render: (props) => serialNumber(props),
                    header: 'S.No.',
                  },
                ];
                for (let [key, value] of Object.entries(
                  care_program_speciality,
                )) {
                  if (value === true) {
                    columns.push({
                      header: key,
                      type: 'text',
                      field: key.toLowerCase(),
                    });
                  }
                }
                if (columns.length > 1) {
                  columns.push({
                    header:"Date",
                    field:"_createdOn",
                    type:'date',
                    format:"MMM DD,YYYY hh:mm A"
                  })
                  return (
                    <Table
                      {...props}
                      data={props.data.pastData || []}
                      columns={columns}
                    />
                  );
                } else {
                  return [];
                }
              },
            },
          ],
        },
      ],
    },
  ],
});
