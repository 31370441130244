import React, {useState, useEffect} from 'react';

import {getImageUrl, getUserLocation, invoke} from '../../AppServices';
import {Carousel} from 'react-responsive-carousel';
// import {Linking} from '../../app-components';
import {DefaultAdds} from '../../images';
import './carousel.css';

const styles = {
  div: {backgroundColor: '#F2FFFC'},
  img: {objectFit: 'contain', height: '96.2vh'},
};
const staticData = {
  title: 'static',
  default: true,
  link: 'https://www.mayamd.ai/',
};
const AdvertisementBeforeLogin = (props) => {
  const [data, setData] = useState([staticData]);
  const getDatas = async () => {
    try {
      const {Allowed = false, data: coordsData = {}} = getUserLocation() || {};
      const res = await invoke({
        id: 'getAdvertisement',
        paramValue: {
          data: {
            location: {
              latitude: Allowed ? coordsData.coords.latitude : null,
              longitude: Allowed ? coordsData?.coords?.longitude : null,
            },
            toDisplay: 'before_login',
          },
        },
      });
      const {result = []} = res || {};
      if (Array.isArray(result) && result.length) {
        setData((current) => [...current, ...result]);
      }
    } catch (err) {
      console.log(`>@>Puru ~ file: Advertisement.js ~ line 29 ~ err`, err);
    }
  };
  useEffect(() => {
    getDatas();
  }, []);
  return (
    <Carousel
      autoPlay
      key={Array.isArray(data) ? data.length : 1}
      showThumbs={false}
      showStatus={false}
      infiniteLoop>
      {/* onClickItem={(index) => {
        const tempItem = data[index] || [];
        const {link = ''} = tempItem;
        Linking.openURL(link, '_blank');
      }} */}
      {data.length &&
        data.map((item) => {
          return (
            <div style={styles.div}>
              <img
                style={styles.img}
                alt={item.title}
                src={`${
                  item?.default ? DefaultAdds : getImageUrl(item?.image?.file)
                }`}
              />
              {/* <p className="legend">{item.description}</p> */}
              {/* <p className="legend1">{item.title}</p> */}
            </div>
          );
        })}
    </Carousel>
  );
};

export default AdvertisementBeforeLogin;
