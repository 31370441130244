import React, {useState, useEffect} from 'react';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from '../../../app-components';
import {h16_Regular} from '../../../theme/fonts';
import {invoke} from '../../../AppServices';
import {colors} from '../../../theme/colors';

const {themeColor} = colors;
const SavedCards = (props) => {
  const {setSelectedSavedCard = () => {}} = props || {};
  const {t = () => {}} = props || {};
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(true);
  const [savedCardData, setSavedCardData] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const result = await invoke({
          id: 'getCardList',
        });
        const {data: {data = []} = {}} = result || {};
        if (Array.isArray(data) && data.length) {
          setLoading(false);
          setSavedCardData(data);
          setSelectedSavedCard(data[0]);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log('Error in getting Data');
      }
    })();
    return () => {
      setSelectedSavedCard(null);
    };
  }, []);
  return (
    <>
      <Text
        style={{
          ...h16_Regular,
          letterSpacing: 1,
          marginTop: 10,
          color: '#fff',
        }}>
        {t('Cards')}
      </Text>
      <ScrollView
        style={{flex: 1, marginTop: 10, maxHeight: 210, paddingRight: 10}}>
        {Array.isArray(savedCardData) && savedCardData.length ? (
          savedCardData.map((cards, index) => {
            const {card: item = {}} = cards || {};
            return (
              <TouchableOpacity
                onPress={() => {
                  setSelected(index);
                  setSelectedSavedCard(cards);
                }}
                style={{
                  cursor: 'pointer',
                  backgroundColor: index === selected ? themeColor : '#fff',
                  flex: 1,
                  minHeight: 40,
                  padding: 5,
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: 8,
                  borderWidth: 1,
                  borderColor:
                    index === selected ? themeColor : 'rgba(56, 56, 56, 0.37)',
                  borderRadius: 8,
                }}>
                <View
                  style={{
                    paddingLeft: 5,
                    flex: 6,
                    minHeight: '100%',
                    justifyContent: 'center',
                  }}>
                  <Text
                    style={{
                      ...h16_Regular,
                      letterSpacing: 2,
                      color:
                        selected === index ? '#fff' : 'rgba(56, 56, 56, 0.37)',
                    }}>
                    {`XXXX XXXX XXXX ${item.last4}`}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 3,
                    minHeight: '100%',
                    justifyContent: 'center',
                  }}>
                  <Text
                    style={{
                      ...h16_Regular,
                      letterSpacing: 2,
                      color:
                        selected === index ? '#fff' : 'rgba(56, 56, 56, 0.37)',
                    }}>
                    {`${
                      item.exp_month < 10
                        ? '0' + item.exp_month
                        : '' + item.exp_month
                    }/${item.exp_year}`}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    minHeight: '100%',
                    justifyContent: 'center',
                  }}>
                  <Text
                    style={{
                      ...h16_Regular,
                      letterSpacing: 2,
                      color:
                        selected === index ? '#fff' : 'rgba(56, 56, 56, 0.37)',
                    }}>
                    {`${item.brand.toUpperCase()}`}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })
        ) : loading ? (
          <View
            style={{
              flex: 1,
              minHeight: 200,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <ActivityIndicator size={'small'} />
          </View>
        ) : (
          <Text style={{...h16_Regular, color: '#fff'}}>No data found</Text>
        )}
      </ScrollView>
    </>
  );
};

export default SavedCards;
