import React from 'react';
import {colors} from '../../theme/colors';
import moment from 'moment';
import {
  Text,
  TouchableWithoutFeedback,
  View,
  isMobile,
} from '../../app-components';
import CustomDateFiter from '../../app-components/DateFilter/DateFilter';
import MySchedule from './MySchedule';
import MyScheduleMobile from './MyScheduleMobile';
import {
  h12_Regular,
  h14_AvenirNext,
  h20_Regular,
  h24_Regular,
  h24_AvenirNext_500,
} from '../../theme/fonts';
import {invoke} from '../../AppServices';

const {themeColor} = colors;
const {DateFilter} = CustomDateFiter;
const styles = {
  headerContainer: {
    backgroundColor: '#e7eef5',
    flexDirection: isMobile ? 'column' : 'row',
    paddingTop: 32,
    paddingBottom: 32,
    justifyContent: 'space-evenly',
    ...(isMobile
      ? {
          alignItems: 'center',
        }
      : {}),
  },
  countViewContainer: {
    width: 90,
    borderRadius: 15.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerColumnContainer: {
    backgroundColor: '#ffff',
    padding: 10,
    borderRadius: 8,
  },
};
const countView = (bgColor, textColor, value) => {
  return (
    <TouchableWithoutFeedback>
      <View style={{...styles.countViewContainer, backgroundColor: bgColor}}>
        <Text
          style={{
            ...h12_Regular,
            color: textColor,
          }}>
          {value}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};
const headerColumn = ({text1, text2, countViewValues, color = '#73c5a7'}) => {
  return (
    <View
      style={{
        ...styles.headerColumnContainer,
        width: countViewValues ? 350 : 200,
        margin: isMobile ? 5 : 0,
      }}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <Text
          style={{
            ...h14_AvenirNext,
            lineHeight: 31,
            letterSpacing: 0.5,
            color: 'rgba(56, 56, 56, 0.77)',
          }}>
          {text1}
        </Text>
        {countViewValues
          ? countView(
              countViewValues?.bgColor,
              countViewValues?.textColor,
              countViewValues?.value,
            )
          : null}
      </View>
      <View>
        <Text
          style={{...h24_Regular, lineHeight: 31, letterSpacing: 0.86, color}}>
          {text2}
        </Text>
      </View>
    </View>
  );
};

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date: void 0};
  }
  componentDidMount = async () => {
    this.timerID = setInterval(() => this.tick(), 1000);
  };
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  tick = () => {
    this.setState({});
  };
  render() {
    return (
      <View style={{justifyContent: 'center'}}>
        <Text style={{...h20_Regular, color: '#383838'}}>
          {moment(new Date()).format('dddd')}
        </Text>
        <Text style={{...h20_Regular, color: themeColor}}>
          {moment(new Date()).format('MMM DD | hh:mm:ss A')}
        </Text>
      </View>
    );
  }
}
class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {cardData: void 0};
  }
  fetchData = async (value) => {
    let paramValue = {
      period: {
        from: value?.from,
        to: value?.to,
      },
    };
    let result = await invoke({
      paramValue,
      id: 'appointmentStatusWiseData',
    });
    this.setState({cardData: result});
  };
  componentDidUpdate = (prev, current) => {
    if (prev !== this.props) {
      let {screenState} = this.props;
      const {
        dataParams: {filters: {period: {value} = {}} = {}} = {},
      } = screenState;
      this.fetchData(value);
    }
  };

  render() {
    const {cardData} = this.state;
    return (
      <>
        {headerColumn({
          text1: 'Completed ',
          text2: cardData?.num_of_completed || '0',
          color: '#73c5a7',
        })}
        {headerColumn({
          text1: 'Cancelled',
          text2: `${cardData?.num_of_cancelled || '0'}`,
          color: 'red',
        })}
        {headerColumn({
          text1: 'Total Appointments',
          text2: cardData?.total || '0',
          colors: '#383838',
        })}
      </>
    );
  }
}

class ScheduleHeader extends React.Component {
  render() {
    let {screenState} = this.props;
    const {
      dataParams: {filters: {period: {value} = {}} = {}} = {},
    } = screenState;
    return (
      <View style={styles.headerContainer}>
        {/* <Clock /> */}
        <View style={{justifyContent: 'center', margin: isMobile ? 5 : 0,}}>
          <Text style={{...h24_AvenirNext_500, color: themeColor}}>
            {`${moment(value?.from).format('DD')}-${moment(value?.to).format(
              'MMM DD',
            )}'s`}
          </Text>
          <Text style={{...h14_AvenirNext, color: '#383838'}}>
            Appointment Summary
          </Text>
        </View>
        {isMobile ? void 0 : <Cards {...this.props} />}
        {
          <DateFilter
            parent={'History'}
            defaultFilter={'Week'}
            {...this.props}
            defaultStyle={{backgroundColor: '#fff', color: themeColor}}
          />
        }
      </View>
    );
  }
}
export default class DoctorSchedule extends React.Component {
  render() {
    return (
      <View style={{flex: 1, overflow: 'hidden'}}>
        <ScheduleHeader {...this.props} key={this.props.state.uid} />
        {isMobile ? (
          <MyScheduleMobile {...this.props} key={this.props.state.uid} />
        ) : (
          <MySchedule {...this.props} key={this.props.state.uid} />
        )}
      </View>
    );
  }
}
