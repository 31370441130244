import React, {Component} from 'react';
import {TouchableOpacity} from '../../react-core-components';

export default class AnimatedLabel extends Component {
  render() {
    let {
      className,
      style,
      floatingStyle,
      labelPosition,
      labelStyle,
      label,
    } = this.props;
    if (labelPosition === 'floating') {
      style = {
        ...style,
        ...floatingStyle,
      };
    }
    return (
      <TouchableOpacity
        className={className}
        style={{
          ...style,
          ...labelStyle,
          position: 'absolute',
          transition: 'all 200ms',
          transitionTimingFunction: 'linear',
          // transitionProperty: 'color, transform',
          // transitionDuration: '200ms, 200ms',
          // transitionTimingFunction:
          //   'cubic-bezier(0.0, 0, 0.2, 1), cubic-bezier(0.0, 0, 0.2, 1)',
          // transitionDelay: '0ms, 0ms',
          // zIndex: 1,
          pointerEvents: 'none',
        }}>
        {label}
      </TouchableOpacity>
    );
  }
}
