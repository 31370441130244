import React from 'react';
import {View, Text, Image, TouchableOpacity} from '.';

class Box extends React.Component {
  getComponent = (doc, {data: pData, textProps} = {}) => {
    if (!doc) {
      return null;
    }
    if (doc.Component) {
      const {Component, children, row, col, image, text, ...props} = doc;
      if (!children || !children.length) {
        return <Component {...props} />;
      }
      return (
        <Component {...props}>
          <>
            {children.map(child => {
              return this.getComponent(child);
            })}
          </>
        </Component>
      );
    } else if (doc.text || doc.exp) {
      const {formatters} = this.props;
      let {
        text,
        data,
        exp,
        style,
        color,
        bg,
        font,
        numberOfLines,
        formatter,
        defaultValue,
        ...rest
      } = doc;
      let value;

      data = data || pData;
      if (text) {
        value = text;
      } else if (exp) {
        value = data && formatters && formatters['resolveExp'](data, exp);
      }

      let {
        style: pStyle,
        color: pColor,
        bg: pBg,
        font: pFont,
        numberOfLines: pNumberOfLines,
        formatter: pFormatter,
        defaultValue: pDefaultValue,
      } = textProps || {};
      style = style || pStyle;
      color = color || pColor;
      bg = bg || pBg;
      font = font || pFont;
      numberOfLines = numberOfLines || pNumberOfLines;
      formatter = formatter || pFormatter;
      defaultValue = defaultValue || pDefaultValue;
      if (formatter && value) {
        value = formatters[formatter](value);
      }
      if (!value && defaultValue) {
        value = defaultValue;
      }
      if (!value) {
        value = '';
      }

      const textStyle = {...style, color, backgroundColor: bg, ...font};

      if (numberOfLines) {
        rest['numberOfLines'] = numberOfLines;
      }
      return <Text {...rest} style={textStyle} children={value} />;
    } else if (doc.source) {
      return <Image {...doc} />;
    } else if (doc.image) {
      return <Image {...doc.image} />;
    } else if (doc.onPress) {
      const {children = [], ...rest} = doc;
      return (
        <TouchableOpacity {...rest}>
          <>
            {children.map(child => {
              return this.getComponent(child);
            })}
          </>
        </TouchableOpacity>
      );
    } else {
      let {children = [], style, row, col, data, textProps, ...rest} = doc;

      if (row) {
        style = {...style, flexDirection: 'row'};
        children = row;
      } else if (col) {
        style = {...style, flexDirection: 'column'};
        children = col;
      }

      return (
        <View {...rest} style={style}>
          <>
            {children.map(child => {
              return this.getComponent(child, {data, textProps});
            })}
          </>
        </View>
      );
    }
  };

  render() {
    const {template} = this.props;
    return this.getComponent(template);
  }
}

export default Box;
