import React from 'react';
import DoctorFormHoc from './DoctorForm';
import {cancel} from '../../../../app-components/action/CancelAction';
import {save} from '../../../../app-components/action/SaveAction';
import {EditDoctor} from '../../../../Queries';
import CustomHeaderLabel from '../../../../app-components/CustomHeaderLabel';
import {resolveDoctorName} from '../../../../app-components/DoctorUtility';
import {doctorDefaultValuesForDoctor} from '../../../../app-components/DoctorUtility';

const header = {
  hideBackIcon: true,
  title: ({navigation}) => {
    const {state: {params: {item} = {}} = {}} = navigation;
    return (
      <CustomHeaderLabel
        seperator={true}
        preLabel={'Edit User Detail'}
        postLabel={resolveDoctorName(item)}
      />
    );
  },
  actions: [cancel(), save()],
};

export default DoctorFormHoc({
  uri: EditDoctor,
  header,
  disabled: true,
  defaultValues: doctorDefaultValuesForDoctor,
});
