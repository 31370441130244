import ProcedureForm from './ProcedureForm';
import {EditProcedure} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Procedure',
  actions: [cancelButton(), save()],
};

export default ProcedureForm({uri: EditProcedure, header});
