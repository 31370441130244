import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import careProgramForm from './careProgramForm';

let header = {
  title: 'Add Care Program Speciality',
  actions: [cancelButton(), save()],
};

export default careProgramForm({header});
