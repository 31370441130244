import {cancelButton} from '../../app-components/action/CloseAction';
import {PLACEHOLDER} from '../../app-components/utils';
import {save} from '../../app-components/action/SaveAction';
import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';

export default ({uri, header}) => {
return  Form({
    type: 'standardShadow',
    header,
    onSubmit: submit({
      model: 'CcmCheckList',
    }),
    uri,
    reloadEvent: 'reloadCCM',
    closeView: 1,
    mandatory: {question: 1},
    lg: {
      formGroups: [
        {
          columnsPerRow: 2,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'textArea',
                  field: 'question',
                  label: 'CCM Question',
                  mandatory: true,
                  placeholder: PLACEHOLDER,
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'textArea',
                  field: 'question',
                  label: 'CCM Question',
                  mandatory: true,
                  placeholder: PLACEHOLDER,
                },
              ],
            },
          ],
        },
      ],
    },
  });
};
