import React from 'react';
import Table from '../../app-components/table/Table';
import {TouchableOpacity, Text, View} from '../../app-components';
import {removeData} from '../../AppServices';
import {listUri} from '../../Queries';
import {colors} from '../../theme/colors';
import {
  h12_Regular,
  h14_AvenirNext,
  h14_Medium,
  h14_Regular,
} from '../../theme/fonts';
const {backdropColor70} = colors;

let uri = () => {
  return listUri({model: 'Specialities', id: 'specialityLists'});
};

const tableData = {
  lg: {
    columns: [
      {
        width: 50,
      },
      {
        render: (props) => {
          return <Text style={{...h12_Regular}}>{props.index + 1}</Text>;
        },
        header: 'S.No.',
        width: 150,
      },
      {
        type: 'text',
        field: 'name',
        header: 'Speciality Name',
        width: 300,
      },
      // {
      //   header: 'Procedures',
      //   render: ({item}) => {
      //     const {procedures = []} = item || {};
      //     let data = Array.isArray(procedures)
      //       ? procedures.map((item) => item.name)
      //       : procedures;
      //     let firstThreeItems = data.slice(0, 2);
      //     // console.log('first', firstThreeItems);
      //     return (
      //       <View style={{flexDirection: 'row'}}>
      //         <Text style={{...h14_Regular}}>{`${firstThreeItems.length?firstThreeItems+", ":""}`}</Text>
      //         <TouchableOpacity
      //           // style={{color: 'black'}}
      //           title={data.map((item) => item && item.name)}>
      //           {firstThreeItems.length?<Text>...</Text>:void 0} 
      //         </TouchableOpacity>
      //       </View>
      //     );
      //   },
      // },
    ],
    hoverActions: [
      {
        value: 'Edit',
        align: 'right',
        textStyle: {
          color: '#9a9a9a',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'edit-speciality',
              params: {item},
            },
          };
        },
      },
      {
        value: 'Delete',
        position: 'center',
        align: 'right',
        textStyle: {
          color: 'rgb(241,79,79, 0.87)',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: {
          type: 'link',
          link: (props) => {
            let {item} = props;
            return {
              view: 'delete-masterData',
              modal: true,
              params: {
                item,
                label: 'Speciality',
                deleteAction: () => removeData({item, model: 'Specialities'}),
                reloadEvent: 'reloadSpeciality',
              },
              modalProps: {
                autoHide: true,
                height: 250,
                backdropColor: backdropColor70,
              },
            };
          },
        },
      },
    ],
  },
  sm: {
    columns: [
      {
        render: (props) => {
          return <Text style={{...h12_Regular}}>{props.index + 1}</Text>;
        },
        header: 'S.No.',
        width: 50,
      },
      {
        type: 'text',
        field: 'name',
        header: 'Speciality Name',
        width: 100,
      },
      // {
      //   header: 'Procedures',
      //   render: ({item}) => {
      //     const {procedures = []} = item || {};
      //     let data = Array.isArray(procedures)
      //       ? procedures.map((item) => item.name)
      //       : procedures;
      //     let firstThreeItems = data.slice(0, 2);
      //     // console.log('first', firstThreeItems);
      //     return (
      //       <View style={{flexDirection: 'row'}}>
      //         <Text style={{...h14_Regular}}>{firstThreeItems + ', '}</Text>
      //         <TouchableOpacity
      //           // style={{color: 'black'}}
      //           title={data.map((item) => item && item.name)}>
      //           ...
      //         </TouchableOpacity>
      //       </View>
      //     );
      //   },
      // },
      {
        type: 'moreActions',
        width: 50,
        actions: [
          {
            text: 'Edit',
            textStyle: {
              color: '#9a9a9a',
              fontWeight: 500,
              ...h14_AvenirNext,
            },
            action: ({item}) => {
              return {
                type: 'link',
                link: {
                  view: 'edit-speciality',
                  params: {item},
                },
              };
            },
          },
          {
            text: 'Delete',
            textStyle: {
              color: 'rgb(241,79,79, 0.87)',
              fontWeight: 500,
              ...h14_AvenirNext,
            },
            action: {
              type: 'link',
              link: (props) => {
                let {item} = props;
                return {
                  view: 'delete-masterData',
                  modal: true,
                  params: {
                    item,
                    label: 'Speciality',
                    deleteAction: () =>
                      removeData({item, model: 'Specialities'}),
                    reloadEvent: 'reloadSpeciality',
                  },
                  modalProps: {
                    autoHide: true,
                    height: 250,
                    backdropColor: backdropColor70,
                  },
                };
              },
            },
          },
        ],
      },
    ],
  },
};

export default Table({
  uri,
  ...tableData,
  reloadEvent: 'reloadSpeciality',
});
