import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {getCurrency, invoke, post} from '../../AppServices';
import {h15_Medium, h16_Medium} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import moment from 'moment';
import {RAZORPAY_DEV_KEY, RAZORPAY_PROD_KEY, BRAND_NAME} from '../../Config';
const {themeColor, themeTextColor, themeContainer} = colors;

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const __DEV__ = document.domain === 'localhost';

const PaymentRazorpay = (props) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const {navigation = {}} = props;
  const {state: {params = {}} = {}} = navigation || {};
  const {
    item = {},
    selectedTimeSlot = {},
    userId = '',
    appointmentData,
    selectdDate,
    data: PatientData,
  } = params;
  // console.log(`>@>Puru ~ file: ams`, PatientData);
  const patientId = userId;
  const createPaymentIntent = async ({payment_method_id = null}) => {
    try {
      const res = await invoke({
        id: 'createPaymentIntent',
        paramValue: {
          data: {
            doctorId: item?._id ?? '',
            payment_method_id,
          },
        },
      });
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  const SucessHandler = ({createAppointmentResponse, paymentIntent}) => {
    return invoke({
      id: 'successHandler',
      paramValue: {
        paymentIntent: paymentIntent,
        appointment_id: createAppointmentResponse?._id || '',
        // payment_gateway: 'RazorPay',
        appointment_offset: new Date().getTimezoneOffset(),
      },
    });
    // // const {client_secret} = res||{};
    // return res;
  };
  const onGettingError = async ({appointmentId}) => {
    try {
      const res = await invoke({
        id: 'failureHandler',
        paramValue: {
          appointmentId,
        },
      });
      return res;
    } catch (err) {
      console.log('Errr in seding', err);
    }
  };
  const paymentSucess = ({createAppointmentResponse, paymentResponse = {}}) => {
    post({
      data: {_id: createAppointmentResponse?._id},
      updates: {
        status: 'scheduled',
        appointment_timezone_offset: new Date().getTimezoneOffset(),
      },
      model: 'Appointments',
    })
      .then((res) => {
        const {result = []} = res || {};
        if (result.length) {
          // const {payment_intent = {}} = result[0].result || {};
          // setLoading(false);
          // navigation.push({
          //   view: 'booking-confirm',
          //   params: {
          //     ...params,
          //     res: result[0]?.result,
          //   },
          // });
          SucessHandler({
            createAppointmentResponse: createAppointmentResponse,
            paymentIntent: paymentResponse,
          })
            .then((resp) => {
              setLoading(false);
              navigation.push({
                view: 'booking-confirm',
                params: {
                  ...params,
                  res: result[0]?.result,
                },
              });
            })
            .catch((err) => {
              setLoading(false);
              console.log('err', err);
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createAppointment = async ({clientSecret}) => {
    const {
      cost_of_booking,
      _id,
      organization,
      appointment_type,
      mayaAISessionId = null,
    } = item || {};
    try {
      const res = await post({
        data: {},
        updates: {
          doctor_id: {_id},
          organization_id: {_id: organization?._id},
          booking_fee: cost_of_booking,
          date: selectdDate,
          time_slot: selectedTimeSlot,
          time: selectedTimeSlot?.start_time,
          appointment_type,
          sid: mayaAISessionId,
          patient_id: {_id: patientId},
          clientSecret,
          payment_gateway: 'RazorPay',
          ...appointmentData,
        },
        model: 'Appointments',
      });

      const {result = []} = res || {};
      if (Array.isArray(result) && result.length) {
        return result[0]?.result;
      }
    } catch (err) {
      setLoading(false);
      console.log('error in create apppintement', err);
    }
  };
  async function displayRazorpay() {
    setLoading(true);
    setVisible(false);
    setLoader(true);
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js',
    );

    if (!res) {
      setLoading(false);
      setLoader(false);
      navigation.push({
        view: 'payment_failed',
        params: {message: 'Error in connection', setVisible},
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
        },
        setVisible: setVisible(),
      });

      // alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const data = await createPaymentIntent({payment_method_id: null});
    console.log(data);
    if (!data) {
      setLoader(false);
      setLoading(false);
      navigation.push({
        view: 'payment_failed',
        params: {message: 'Error in connection'},
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
          setVisible: setVisible(),
        },
      });
      return;
    }
    const createAppointmentResponse = await createAppointment({
      clientSecret: data?.id || '',
    });
    if (!createAppointmentResponse) {
      navigation.push({
        view: 'payment_failed',
        params: {
          message:
            'Selected Slot Booked by someone else, please try for another slot',
          isNavigate: true,
        },
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
        },
      });
      setLoading(false);
      setLoader(false);
      return null;
    }

    const options = {
      key: __DEV__ ? RAZORPAY_DEV_KEY : RAZORPAY_PROD_KEY,
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: BRAND_NAME,
      // description: 'Thank you for nothing. Please give us some money',
      // image: 'http://localhost:300/MotivHealthLogo.png',
      handler: function (response) {
        setLoader(true);
        paymentSucess({
          createAppointmentResponse: createAppointmentResponse,
          paymentResponse: response,
        });
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: PatientData?.first_name || '',
        email: PatientData?.email || '',
        phone_number: PatientData?.mobile || '',
      },
      notes: {
        summary: `Appointment with ${item?.name} at ${moment(
          selectedTimeSlot?.start_time,
        ).format('hh:mm | DD MMMM YYYY  A')}`,
      },
      theme: {
        color: '#0095C9',
      },
      modal: {
        ondismiss: async function () {
          const error = await onGettingError({
            appointmentId: createAppointmentResponse?._id,
          });
          setLoading(false);
          setVisible(true);
          // window.location.replace('//put your redirect URL');
        },
      },
    };
    const paymentObject = new window.Razorpay(options);
    // paymentObject.on('payment.failed', function (response) {
    //   console.log(`>@>Puru ~ file: PaymentRazorpay.js ~ line 260 ~ response`, response)
    //   // alert(response.error.code + 'hhhh');
    //   // alert(response.error.description);
    //   // alert(response.error.source);
    //   // alert(response.error.step);
    //   // alert(response.error.reason);
    //   // alert(response.error.metadata.order_id);
    //   // alert(response.error.metadata.payment_id);
    // });
    setLoader(false);
    paymentObject.open();
  }
  useEffect(() => {
    displayRazorpay();
  }, []);
  // if (true) {
  //   return loading ? <ActivityIndicator size="large" color={'#fff'} /> : null;
  // }
  if (!visible) {
    if (loader) {
      return <ActivityIndicator size="large" color={'#fff'} />;
    }
    return null;
  }
  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
          <Text
            style={{
              ...h16_Medium,
              color: themeTextColor,
            }}>{`Appointment with ${item?.name} at ${moment(
            selectedTimeSlot?.start_time,
          ).format('hh:mm A')} | ${moment(selectedTimeSlot?.start_time).format(
            'DD MMMM YYYY',
          )}`}</Text>
          <Text
            style={{
              ...h15_Medium,
              color: themeTextColor,
              marginTop: 8,
            }}>{`${props.t('Consultation Fee')} : ${getCurrency(item)} ${
            item?.cost_of_booking
          }`}</Text>
        </View>
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            displayRazorpay();
          }}>
          <GradientButton
            label="Pay"
            loading={loading}
            indicatorColor={'#fff'}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = {
  mainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
    flex: 1,
  },
  container: {
    minWidth: 300,
    minHeight: 150,
    backgroundImage: 'linear-gradient(180deg, rgba(0,0,0,0.2), transparent)',
    // backgroundColor: themeContainer,
    borderRadius: 8,
    // alignItems: 'space-between',
    justifyContent: 'space-between',
    padding: 10,
  },
};
export default PaymentRazorpay;
