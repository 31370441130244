import React from 'react';

import {
  View,
  Text,
  TouchableOpacity,
  BasicTextInput,
  FlatList,
  Image,
  Keyboard,
  Platform,
} from '../../react-core-components';
import {ActivityIndicator} from '../../react-activity-indicator';
class AutoSuggestWithSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchSequence = 0;
  }
  componentDidMount() {
    this.fetchSuggestion();
  }

  _onRequestClose = () => {
    const {onDropdownWillHide, buttonInputRef, hideModal} = this.props;
    if (!onDropdownWillHide || onDropdownWillHide() !== false) {
      hideModal();
    }
    Keyboard && Keyboard.dismiss();
    buttonInputRef && buttonInputRef.blur && buttonInputRef.blur();
  };

  _onRowPress = ({item, index}) => {
    const {onItemSelect, multiSelect} = this.props;
    if (onItemSelect) {
      let value = onItemSelect({item, index, searchValue: this.typing});
      if (value !== undefined) {
        this.setButtonText(value);
      }
    }
    !multiSelect && this._onRequestClose();
  };

  _renderRow = ({item, index}) => {
    const {
      renderRow,
      dropdownTextStyle,
      dropdownTextHighlightStyle,
      accessible,
      sugestionField,
      isHighlighted,
    } = this.props;
    let highlighted = isHighlighted && isHighlighted({item: item, index});

    const row = !renderRow ? (
      <Text
        style={[dropdownTextStyle, highlighted && dropdownTextHighlightStyle]}>
        {sugestionField ? item[sugestionField] : item}
      </Text>
    ) : (
      renderRow({item, index, highlighted, props: this.props})
    );

    let preservedProps;
    if (Platform.OS === 'web') {
      preservedProps = {
        accessible,
        onMouseDown: e => {
          e.preventDefault();
          this._onRowPress({item, index});
        },
      };
    } else {
      preservedProps = {
        accessible,
        onPress: e => {
          this._onRowPress({item, index});
        },
      };
    }

    return <TouchableOpacity {...preservedProps}>{row}</TouchableOpacity>;
  };
  state = {};
  setButtonText = value => {
    this.setState({buttonText: value});
  };
  updateSuggestions = data => {
    this.setState({data});
  };

  _renderSeparator = rowID => {
    let {separatorStyle} = this.props;
    return <View key={`spr_${rowID}`} style={separatorStyle} />;
  };

  fetchSuggestion = searchValue => {
    const {fetch, options} = this.props;
    this.lastFetchSequence++;
    let sequence = this.lastFetchSequence;
    this.setState({toggled: !this.state.toggled});
    let result;
    if (options && Array.isArray(options)) {
      result = options;
    } else if (fetch) {
      this.fetching = true;
      result = fetch({
        searchValue,
      });
    } else {
      console.warn(
        'Autosuggest input neither fetch provided nor options (in array )',
      );
    }

    if (result && result instanceof Promise) {
      result
        .then(data => {
          if (this.lastFetchSequence === sequence) {
            this.fetching = false;
            this.updateSuggestions(data);
          }
        })
        .catch(e => {
          this.fetching = false;
          // console.error('Error in fetching in autosuggest input', e);
        });
    } else if (result) {
      this.fetching = false;
      this.updateSuggestions(result);
    }
  };

  onChangeText = text => {
    this.typing = text;
    let {minChar, onChangeText} = this.props;
    onChangeText && onChangeText(text);
    this.setButtonText(text);
    if (!minChar || (text && text.length >= minChar)) {
      this.fetchSuggestion(text);
    } else {
      this.setState({});
    }
  };
  _renderLoading = () => {
    return <ActivityIndicator size="small" color="#999999" />;
  };
  render() {
    const {
      renderSeparator,
      showsVerticalScrollIndicator,
      keyboardShouldPersistTaps = 'always',
      keyExtractor,
      value,
      placeholder,
      autoSuggestModalTheme,
      searching,
      renderLoading,
      buttonInputRef,
      frameStyle,
      reset,
      onApply,
      hideModal,
      showHeader,
      showButton = false,
    } = this.props;
    const {
      crossIcon,
      searchIcon,
      headerContainer,
      headerTextAndIconContainer,
      headerStyle,
      crossIconStyle,
      searchContainer,
      inputStyle,
      searchIconStyle,
      flatlistStyle,
      dropdownStyle,
      bottomButtonContainer,
      resetButtonStyle,
      resetTextStyle,
      submitButtonStyle,
      submitTextStyle,
    } = autoSuggestModalTheme;
    const {data, buttonText} = this.state;
    return (
      <TouchableOpacity
        style={[dropdownStyle, frameStyle]}
        activeOpacity={1}
        onPress={() => {}}>
        <View style={headerContainer}>
          {showHeader && (
            <View style={headerTextAndIconContainer}>
              <Text style={headerStyle}>{placeholder || ''}</Text>
              <TouchableOpacity
                onPress={() => {
                  hideModal && hideModal();
                  buttonInputRef &&
                    buttonInputRef.blur &&
                    buttonInputRef.blur();
                }}>
                <Image source={crossIcon} style={crossIconStyle} />
              </TouchableOpacity>
            </View>
          )}
          {searching && (
            <View style={searchContainer}>
              <BasicTextInput
                style={inputStyle}
                value={buttonText || ''}
                placeholder="Search"
                onChangeText={this.onChangeText}
              />
              <Image source={searchIcon} style={searchIconStyle} />
            </View>
          )}
        </View>
        {this.fetching && !renderLoading ? this._renderLoading() : void 0}
        <FlatList
          ref={index => (this.FlatList = index)}
          style={{flex: 1, ...flatlistStyle}}
          data={data}
          renderItem={this._renderRow}
          ItemSeparatorComponent={renderSeparator || this._renderSeparator}
          keyExtractor={keyExtractor}
          showsHorizontalScrollIndicator={showsVerticalScrollIndicator}
          keyboardShouldPersistTaps={keyboardShouldPersistTaps}
        />
        {showButton && (
          <View style={bottomButtonContainer}>
            <TouchableOpacity
              style={resetButtonStyle}
              onPress={() => {
                reset && reset();
                hideModal && hideModal();
              }}>
              <Text style={resetTextStyle}>Reset</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={submitButtonStyle}
              onPress={() => {
                onApply && onApply();
                this.props.hideModal && this.props.hideModal();
              }}>
              <Text style={submitTextStyle}>Apply</Text>
            </TouchableOpacity>
          </View>
        )}
      </TouchableOpacity>
    );
  }
}

export default AutoSuggestWithSearchInput;
