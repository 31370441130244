import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import advertisementForm from './advertisementForm';

let header = {
  title: 'Add Advertisement',
  actions: [cancelButton(), save()],
};

export default advertisementForm({header});
