import React from 'react';
import RPMTab from './RPMTab';
import EditPatientForm from './EditPatientForm';
import AddTeamMember from './AssignTeamMember';
import DetailRpmLibrary from './RPMProgramLibrary/DetailRpmLibrary';
import DetailPatient from './DetailPatient';
import AddSearchDeviceForm from './SearchDevice/AddSearchDevice';
import AddRpmLibrary from './RPMProgramLibrary/AddRpmLibrary';
import EditRpmLibrary from './RPMProgramLibrary/EditRpmLibrary';
import EditSearchDevice from './SearchDevice/EditSearchDevice';
import AssignProgramForm from './AssignProgramForm';
import DetailSearchDevice from './SearchDevice/DetailSearchDevice';
import AddPatient from './AddPatient';
import PatientForm from './PatientForm';
import DataAnalysis from './PatientDashBoard/DataAnalysis';
import {backIcon} from '../../images';
import {Text, Image, TouchableOpacity, View} from '../../app-components';
import CustomHeaderDate from '../../app-components/CustomHeaderDates/CustomHeaderDate';
import AddPatientDataFrom from './AddPatientDataFrom';
import AddPatientDevice from './AddPatientDevice';
import AddManualTime from './Timer/AddManualTime';
import Notes from './Notes';
import CopyRpmLibrary from './RPMProgramLibrary/CopyRpmLibrary';
import DetailTimeTracking from './Timer/DetailTimeTracking';
import {resolvePatientName} from '../../app-components/patientUtility';
import EditPatientCloseView from './EditPatientCloseView';
import AddComment from './PatientDashBoard/AddComment';
import AddUser from './AddUser';
import userForm from './userForm';
import userDetailForm from './userDetailForm';
import editUSerForm from './editUSerForm';
import EditUserScreen from './EditUserScreen';
import EditMemberForm from './EditMemberForm';
import EditMemberScreen from './EditMemberScreen';
import OrderDetails from './OrderDetails';
import ScreenedPatientDetail from './ScreenedPatientDetail';
import CreateOrderModal from './CreateOrderModal';
import ImmediateTakeaways from './ImmediateTakeaways';
import RemoteConfiguration from './RemoteConfiguration/remoteConfiguration';
import VillageSettingDetail from './VillageSetting/villageSetting';
import villageSettingForm from './VillageSetting/villageSettingForm';
import LanguageSelectModal from './LanguageSelectModal';
import ExportModal from './ExportModal';
import ExportSelectedModal from './ExportSelectedModal';
import editVillageForm from './VillageSetting/editVillageForm';
import editVillageScreen from './VillageSetting/editVillageScreen';
import AssignModal from './AssignModal';
import AssignCareProgram from './AssignCareProgram';
import ViewNoteSelectLanguageModal from './ViewNoteLanguageSelectModal';
import ImmediateTakeAwayLanguageModal from './ImmediateTakeAwayLanguageModal';
import ShowPopupImage from './ShowPopupImage';
import EditTestResultForm from './EditTestResultForm';
import MayaTestResult from './MayaTestResult/mayaTestResult';
import EditMasterTestResult from './MayaTestResult/editMasterTestResult';
import AddVillageSubCenter from './VillageSetting/addVillageSubCenter';
import EditVillageSubCenter from './VillageSetting/editVillageSubCenter';
import AddLabCategory from './addLabCategory';
import EditLabCategory from './LabCategories/editLabCategory';
import EditInferences from './EditInferences';
import ExportCitizenList from './ExportExcel/ExportCitizenList';

let {DateFilter} = CustomHeaderDate;

const headerTextContainerStyle = {
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    padding: 18,
    fontFamily: 'AvenirNext-Regular',
  },
  containerStyle: {
    backgroundColor: 'rgb(231, 238, 245)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 18,
  },
};

export default {
  LanguageSelectModal: {screen: LanguageSelectModal, expanded: true},
  searchDeviceForm: {screen: AddSearchDeviceForm, expanded: true},
  createOrderModal: {screen: CreateOrderModal, expanded: true},
  addRpmProgramForm: {screen: AddRpmLibrary, expanded: true},
  copyRpmProgramForm: {screen: CopyRpmLibrary, expanded: true},
  editRpmProgramForm: {screen: EditRpmLibrary, expanded: true},
  editSearchForm: {screen: EditSearchDevice, expanded: true},
  assignProgramForm: {screen: AssignProgramForm, expanded: true},
  detailSearchForm: {screen: DetailSearchDevice, expanded: true},
  detailRpmProgramForm: {screen: DetailRpmLibrary, expanded: true},
  detailPatientForm: {screen: DetailPatient, expanded: true},
  detailOrderForm: {screen: OrderDetails, expanded: true},
  detailScreenPatientForm: {screen: ScreenedPatientDetail, expanded: true},
  addPatientDataForm: {screen: AddPatientDataFrom, expanded: true},
  addUser: {screen: userForm, expanded: true},
  addUserForm: {screen: AddUser, expanded: true},
  userDetailForm: {screen: userDetailForm, expanded: true},
  addPatientsForm: {screen: AddPatient, expanded: true},
  PatientForm: {screen: PatientForm, expanded: true},
  editUserDetail: {screen: editUSerForm, expanded: true},
  editUserDetailForm: {screen: EditUserScreen, expanded: true},
  editMemberDetail: {screen: EditMemberForm, expanded: true},
  editMemberDetailForm: {screen: EditMemberScreen, expanded: true},
  editVillageDetail: {screen: editVillageForm, expanded: true},
  editVillageDetailForm: {screen: editVillageScreen, expanded: true},
  addVillageSubCenterModal: {screen: AddVillageSubCenter, expanded: true},
  remoteConfig: {screen: RemoteConfiguration, expanded: true},
  villageSettingDetail: {
    screen: VillageSettingDetail,
    expanded: true,
  },
  editInferences:{screen:EditInferences,expanded:true},
  DataAnalysisDeshboard: {
    screen: DataAnalysis,
    expanded: true,
    renderHeader: (props) => {
      let backIconVisible = true;
      let {navigation, screenState} = props;
      const {state: {params: {item = {}} = {}} = {}} = navigation;
      let {setScreenState} = navigation;
      return (
        <View style={headerTextContainerStyle.containerStyle}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            {backIconVisible && (
              <TouchableOpacity
                style={{cursor: 'pointer'}}
                onPress={() => {
                  navigation.pop();
                }}>
                <Image
                  source={backIcon}
                  style={{height: 35, width: 50, paddingLeft: 10}}
                />
              </TouchableOpacity>
            )}
            <Text style={headerTextContainerStyle.titleTextStyle}>
              {`${item?.first_name ? item?.first_name + "'s" : ''} Vitals`}
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <DateFilter
              screenState={screenState}
              setScreenState={setScreenState}
              defaultFilter={'Week'}
              graphFilter
            />
          </View>
        </View>
      );
    },
  },
  editPatientForm: {screen: EditPatientForm, expanded: true},
  editPatientFormClose: {screen: EditPatientCloseView, expanded: true},
  addTeamMember: {screen: AddTeamMember, expanded: true},
  notes: {screen: Notes},
  addPatientDevice: {screen: AddPatientDevice},
  addComment: {screen: AddComment},
  addManualTime: {screen: AddManualTime},
  detailTimeTracking: {
    screen: DetailTimeTracking,
    expanded: true,
    renderHeader: (props) => {
      let backIconVisible = true;
      let {navigation, screenState} = props;
      let {setScreenState} = navigation;
      const {state: {params: {item = {}} = {}} = {}} = navigation;
      const {patient} = item;
      return (
        <View style={headerTextContainerStyle.containerStyle}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            {backIconVisible && (
              <TouchableOpacity
                style={{cursor: 'pointer'}}
                onPress={() => {
                  navigation.pop();
                }}>
                <Image
                  source={backIcon}
                  style={{height: 35, width: 50, paddingLeft: 10}}
                />
              </TouchableOpacity>
            )}
            <Text
              style={
                headerTextContainerStyle.titleTextStyle
              }>{`Time Analysis (${resolvePatientName(patient)})`}</Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <DateFilter
              screenState={screenState}
              setScreenState={setScreenState}
              defaultFilter={'Week'}
              // graphFilter
            />
          </View>
        </View>
      );
    },
  },
  RPM: {
    expanded: true,
    screen: RPMTab,
  },
  immediateTakeaways: {screen: ImmediateTakeaways},
  exportModal: {screen: ExportModal},
  assignModal: {screen: AssignModal, expanded: true},
  assignCareProgramme: {screen: AssignCareProgram, expanded: true},
  viewNoteSelectLanguageModal: {
    screen: ViewNoteSelectLanguageModal,
    expanded: true,
  },
  immediateTakeAwayLanguageModal: {
    screen: ImmediateTakeAwayLanguageModal,
    expanded: true,
  },
  showPopupImage: {screen: ShowPopupImage, expanded: true},
  editTestResultForm: {screen: EditTestResultForm, expanded: true},
  mayaTestResult: {screen: MayaTestResult, expanded: true},
  editMasterTestResult: {screen: EditMasterTestResult, expanded: true},
  editVillageSubCenter: {screen: EditVillageSubCenter, expanded: true},
  addLabCategory: {screen: AddLabCategory},
  EditLabCategory:{screen:EditLabCategory},
  ExportSelectedModal: { screen: ExportSelectedModal },
  ExportCitizenList: { screen: ExportCitizenList },
};
