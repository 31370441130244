import React from 'react';
import {cancelButton} from '../../../app-components/action/CloseAction';
import {save} from '../../../app-components/action/SaveAction';
import Table from '../../../app-components/table/Table';
import {View, Text} from '../../../app-components';

const uri = () => {
  return {
    query: {
      id: 'villageList',
      sort: {_id: -1},
    },
    model: 'Villages',
  };
};

let titleTextStyle = {
  color: 'rgba(56, 56, 56, 0.87)',
  fontSize: 20,
  marginLeft: 7,
  fontFamily: 'AvenirNext-Regular',
};

export default Table({
  uri,
  reloadEvent: 'reloadVillage',
  reloadOnChangeEvent: 'reloadVillage',
  action: ({item}) => {
    let header = {
      titleTextStyle: {
        color: 'rgba(56, 56, 56, 0.87)',
        fontSize: 20,
        fontFamily: 'AvenirNext-Regular',
      },
      title: () => (
        <View style={{flexDirection: 'row'}}>
          <Text style={titleTextStyle}>Village:</Text>
          <Text style={{...titleTextStyle, color: '#000'}}>
            {`${item?.name},`}
          </Text>
          <Text style={titleTextStyle}>Block:</Text>
          <Text style={{...titleTextStyle, color: '#000'}}>
            {`${item?.block?.name},`}
          </Text>
          <Text style={titleTextStyle}>District:</Text>
          <Text style={{...titleTextStyle, color: '#000'}}>
            {item?.block?.district?.name}
          </Text>
        </View>
      ),
      actions: [save(), cancelButton({label: ' Back '})],
    };
    return {
      type: 'link',
      link: {
        view: 'villageSettingDetail',
        params: {item, header},
      },
    };
  },
  columns: [
    {
      header: 'District',
      type: 'text',
      field: 'block.district.name',
    },
    {
      header: 'Block',
      type: 'text',
      field: 'block.name',
    },
    {
      header: 'Village',
      type: 'text',
      field: 'name',
    },
    {
      type: 'moreActions',
      visible: false,
      actions: [
        {
          text: 'Edit Details',
          onPress: async (props) => {
            const {item, navigation} = props;
            navigation.push({
              view: 'editVillageDetail',
              params: {
                villageId: item?._id,
              },
            });
          },
        },
      ],
    },
  ],
});
