import React from 'react';
import { View, Text } from '../../app-components'
import { colors } from '../../theme/colors';
import { actionImport } from '../../AppServices';
import contactSupport from '../contact-support';
let { deActiveDateColor } = colors;
let action = ({ item }) => {
  return (
    <View
      style={{
        backgroundColor: deActiveDateColor,
        borderRadius: 5,
        width: 100,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Text
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: 12,
        }}>
        Import Data
      </Text>
    </View>
  );
};
export const ImportCard = {
  action: ({ item, navigation }) => ({
    type: 'link',
    link: {
      view: 'importDetail',
      params: { import: item },
    },
  }),
  columns: [
    {
      type: 'selection',
    },
    {
      type: 'text',
      header: 'Model',
      field: 'model',
    },
    {
      type: 'text',
      header: 'Source',
      field: 'source',
    },
    {
      type: 'text',
      header: 'File',
      field: 'file.name',
    },
    {
      type: 'text',
      header: 'Status',
      field: 'status',
    },

    {
      header: 'Action',
      type: 'custom',
      action: ({ item, navigation }) => {
        return {
          type: 'link',
          link: () => {
            actionImport && actionImport({ data: item });
            return null;
          },
        };
      },
      render: action,
    },
  ],
};
