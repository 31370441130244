import {mayaMdLogo, mayaMdLogoWhite} from './images';

export const brandImg = {
  MayaMD: [mayaMdLogo, mayaMdLogoWhite],
};
export const brandStyle = {
  MayaMD: {
    'choose-user': {
      objectFit: 'contain',
      marginBottom: 45,
      marginTop: 15,
      // width: 240,
      height:55,
    },
    'patient-login': {width: 220},
    'patient-sidebar': {
      padding: 8,
      width: 180,
      objectFit: 'contain',
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: 'rgba(0,0,0,0.4) 5px 5px 10px 0px',
    },
  },
};
