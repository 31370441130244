import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  // ActivityIndicator,
} from '../../app-components';
import {TextInput} from '../../npms/react-text-input';
import {h12_Regular, h14_Medium} from '../../theme/fonts';
import {
  DateInput,
  ChipsToggleInput,
  MultiAutoSuggestInput,
  MultiTextInput,
  CheckBox,
} from '../../app-components/input-components/Editors';

import {
  getUser,
  post,
  setUser,
  checkDob,
  autosuggestFetch,
} from '../../AppServices';
import {Form} from '../../app-components/form/Form';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {colors} from '../../theme/colors';
import {isEqual} from 'lodash';
import {roundCross} from '../../images';
import {
  OtherRenderSelector,
  RenderNoData,
} from '../../app-components/input-components/autoSuggest/renderRowCard';

const {themeColor} = colors;
let themeTextColor = 'black';
const PatientDetailsForm = (props) => {
  let {navigation, t, item: appointmentData = {}, isClinicalView} = props;
  const {patient_id: {_id} = {}} = appointmentData || {};
  // const {state: {params = {}} = {}} = navigation;
  const [loading, setLoading] = useState(false);
  let {
    form_state: {data = {}, loaded} = {},
    form_context: {setValue} = {},
  } = props;

  // useEffect(() => {
  //   if (data.name) {
  //     if (data.name[0].family) {
  //       setValue({
  //         field: 'last_name',
  //         value: data.name[0].family,
  //         data,
  //       });
  //     }
  //     setTimeout(function () {
  //       //we need to find a better solution
  //       if (data.name[0].given) {
  //         setValue({
  //           field: 'first_name',
  //           value: data.name[0]?.given.join(' '),
  //           data,
  //         });
  //       }
  //     }, 0);
  //     setTimeout(function () {
  //       if (data.telecom) {
  //         setValue({
  //           field: 'email',
  //           value: data?.telecom[1]?.value,
  //           data,
  //         });
  //       }
  //     }, 0);
  //     setTimeout(function () {
  //       if (data.telecom) {
  //         setValue({
  //           field: 'mobile',
  //           value: data?.telecom[0]?.value,
  //           data,
  //         });
  //       }
  //     }, 0);
  //   }
  // }, [data.name]);

  const [error, setError] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [other, setOther] = useState({
    medical_history: false,
    surgical_history: false,
    allergies: false,
    medications: false,
    social_history: false,
    family_history: false,
  });
  const formField = ({
    title = '',
    placeholder = 'Enter your info',
    field = '',
    isChip = false,
    otherField = '',
    model,
    id,
    valueField,
    searchField,
  }) => {
    searchField = searchField || valueField;

    return (
      <View style={{marginTop: 15}}>
        <View style={{flexDirection: 'row'}}>
          <Text
            style={{
              ...h14_Medium,
              color: themeTextColor,
              // color: 'rgba(56, 56, 56)',
              marginBottom: 8,
            }}>
            {title}
          </Text>
        </View>
        {isChip ? (
          <>
            <MultiAutoSuggestInput
              data={data}
              onChangeValue={(value) => {
                setValue({
                  field,
                  value,
                  data,
                });
              }}
              showArrow={true}
              removeChipIcon={roundCross}
              renderSelector={(props) =>
                OtherRenderSelector({
                  otherValue: data[otherField] || other[field],
                  onChangeOtherValue: (value, clearText) => {
                    setValue({
                      field: otherField,
                      value: [
                        ...(data[otherField] ? data[otherField] : []),
                        value,
                      ],
                      data,
                    });
                    clearText && clearText();
                  },
                  ...props,
                })
              }
              fetch={autosuggestFetch({
                model,
                id,
                search: searchField,
                showOther: true,
              })}
              variant="filled"
              value={data[field]}
              valueField={valueField}
              idField={'_id'}
              field={valueField}
              sugestionField={valueField}
              searching={true}
              textColor="#383838"
              placeholder={placeholder}
            />
          </>
        ) : (
          <TextInput
            data={data}
            value={data[field]}
            field={field}
            onChangeText={(value) => setValue({field, value, data})}
            variant="filled"
            placeholder={placeholder}
          />
        )}
        {/* <br></br> */}
        {data[otherField] || other[field] ? (
          <MultiTextInput
            data={data}
            value={data[otherField]}
            field={otherField}
            disableTextInput
            onChangeValue={(value) => {
              setValue({field: otherField, value, data});
            }}
            variant="filled"
            textColor="#383838"
            placeholder={placeholder}
          />
        ) : (
          void 0
        )}
      </View>
    );
  };
  useEffect(() => {
    setUpdatedData(data);
  }, [loaded]);
  const UpdatePatient = () => {
    const {first_name = '', name = '', email = '', birthDate = ''} = data;
    if (!first_name.length) {
      setError({type: 'first_name', value: t('Please enter value')});
    } else if (!birthDate) {
      setError({type: 'birthDate', value: t('Please enter value')});
    } else if (!email.length) {
      setError({type: 'email', value: t('Please enter value')});
    } else {
      setLoading(true);
      // let family = data?.last_name;
      // let given = [data?.first_name||""];
      // let _id = (data && data.name && data?.name[0]?._id) || '';
      // let newNameArray = [{given, family, ...(_id ? {_id} : {})}];
      // let mobile = '';
      // const {telecom: dataTelecom = []} = data || {};
      // let email = {}
      // for ( let value of dataTelecom) {
      //   if (value?.system === 'email') {
      //     email = value;
      //   } else if (value?.system == 'phone') {
      //     mobile = value;
      //   }
      // }
      // email = {
      //   ...email,
      //   system: 'email',
      //   value: data.email || '',
      // };
      // let telecom = [];
      // if (mobile) {
      //   telecom.push(mobile);
      // }
      // if (email) {
      //   telecom.push(email);
      // }
      // data = {
      //   ...data,
      //   // name: newNameArray,
      //   // telecom: telecom,
      // };
      delete data._id;
      post({
        data: {...data},
        updates: {...data},
        model: 'Patients',
      })
        .then((res) => {
          setUpdatedData({...data});
          // let response = res.result[0].result || {};
          // let user = getUser();
          // user['name'] = response?.name || user.name;
          // setUser(user);
          // navigation.replace({
          //   // view: 'patient-payment',
          //   view:"patient-appointment-details",
          //   params: {
          //     ...params,
          //     data,
          //   },
          // });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <ScrollView
        style={{
          padding: 5,
          flex: 1,
        }}>
        {isClinicalView ? (
          void 0
        ) : (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            {/* Name */}
            <View style={{flex: 2.5, marginRight: 15}}>
              <View style={{flexDirection: 'row'}}>
                <Text
                  style={{
                    ...h14_Medium,
                    color: themeTextColor,
                    // color: 'rgba(56, 56, 56)',
                    marginBottom: 8,
                  }}>
                  {t('First Name')}
                </Text>
                <Text style={{...h14_Medium, color: '#f14f4f', paddingLeft: 3}}>
                  *
                </Text>
              </View>
              <TextInput
                data={data}
                editable={false}
                field={'first_name'}
                value={data.first_name}
                onChangeText={(value) =>
                  setValue({field: 'first_name', value, data})
                }
                variant="filled"
                placeholder={`${t('Enter your')} ${t('First Name')}`} //"Enter Your First Name"
              />
              {error.type === 'first_name' && (
                <Text style={{...h12_Regular, color: 'red'}}>
                  {error.value}
                </Text>
              )}
            </View>

            <View style={{flex: 2.5, marginRight: 15}}>
              <View style={{flexDirection: 'row'}}>
                <Text
                  style={{
                    ...h14_Medium,
                    color: themeTextColor,
                    // color: 'rgba(56, 56, 56)',
                    marginBottom: 8,
                  }}>
                  {t('Last Name')}
                </Text>
              </View>
              <TextInput
                data={data}
                field={'last_name'}
                value={data.last_name}
                editable={false}
                onChangeText={(value) =>
                  setValue({field: 'last_name', value, data})
                }
                variant="filled"
                placeholder={t('Enter Your Last Name')}
              />
            </View>
          </View>
        )}
        {/* date and gender */}
        {isClinicalView ? (
          void 0
        ) : (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 10,
              marginBottom: 10,
            }}>
            {/* Date */}
            <View style={{flex: 1, marginRight: 15}}>
              <View style={{flexDirection: 'row'}}>
                <Text
                  style={{
                    ...h14_Medium,
                    color: themeTextColor,
                    // color: 'rgba(56, 56, 56)',
                    marginBottom: 8,
                  }}>
                  {t('DOB')}
                </Text>
                <Text style={{...h14_Medium, color: '#f14f4f', paddingLeft: 3}}>
                  *
                </Text>
              </View>
              <DateInput
                format={'MM/DD/YYYY'}
                data={data}
                field={'birthDate'}
                editable={false}
                // value={t('dob', {dob: new Date(data.dob)})}
                value={data.birthDate}
                onChangeValue={(value) => {
                  if (checkDob(value)) {
                    setValue({field: 'birthDate', value, data});
                    setError({});
                  } else {
                    setError({type: 'birthDate', value: t('invalidDOB')});
                  }
                }}
                variant="filled"
                placeholder={t('Enter Your DOB')}
              />
              {error.type === 'dob' && (
                <Text style={{...h12_Regular, color: 'red'}}>
                  {error.value}
                </Text>
              )}
            </View>
            {/* Gender*/}
            <View style={{flex: 1}}>
              <Text
                style={{
                  ...h14_Medium,
                  color: themeTextColor,
                  // color: 'rgba(56, 56, 56)',
                  marginBottom: 8,
                }}>
                {t('Gender')}
              </Text>
              <ChipsToggleInput
                // onlyFirstLetter={true}
                data={data}
                editable={false}
                field={'gender'}
                value={data.gender}
                onChangeValue={(value) =>
                  setValue({field: 'gender', value, data})
                }
                selectedItemStyle={{
                  borderColor: themeColor,
                  backgroundColor: themeColor,
                }}
                optionStyle={{
                  ...h14_Medium,
                  color: '#383838',
                }}
                selectedOptionStyle={{
                  color: '#fff',
                  ...h14_Medium,
                }}
                itemStyle={{
                  paddingTop: 9,
                  paddingBottom: 8,
                  paddingLeft: 18,
                  paddingRight: 18,
                  borderWidth: 1,
                  borderRadius: 8,
                  marginRight: 8,
                  borderColor: '#fff',
                  backgroundColor: '#fff',
                  cursor: 'pointer',
                }}
                variant="filled"
                options={[
                  {value: 'male', label: 'Male'},
                  {value: 'female', label: 'Female'},
                  {value: 'other', label: t('Other')},
                ]}
              />
            </View>
          </View>
        )}
        {isClinicalView ? (
          void 0
        ) : (
          <View>
            <View style={{flexDirection: 'row'}}>
              <Text
                style={{
                  ...h14_Medium,
                  color: themeTextColor,
                  // color: 'rgba(56, 56, 56)',
                  marginBottom: 8,
                }}>
                {t('Email Address')}
              </Text>
              <Text style={{...h14_Medium, color: '#f14f4f', paddingLeft: 3}}>
                *
              </Text>
            </View>
            <TextInput
              data={data}
              value={data.email}
              field={'email'}
              onChangeText={(value) => setValue({field: 'email', value, data})}
              variant="filled"
              placeholder={t('Enter email address')}
              editable={false}
            />
            {error.type === 'email' && (
              <Text style={{...h12_Regular, color: 'red'}}>{error.value}</Text>
            )}
          </View>
        )}
        {/* Past medical History */}
        {formField({
          title: t('Past medical history'),
          placeholder: t('Add medical history here') + '...',
          field: 'medical_history',
          isChip: true,
          id: 'medicalHistoryLists',
          model: 'MedicalHistories',
          valueField: 'name_en',
          otherField: 'medical_history_other',
        })}
        {/* past surgical history */}
        {formField({
          title: t('Past surgical history'),
          placeholder: t('Add surgical history here') + '...',
          field: 'surgical_history',
          isChip: true,
          id: 'surgicalHistoryLists',
          model: 'SurgicalHistories',
          valueField: 'name',
          otherField: 'surgical_history_other',
        })}
        {/* medications */}
        {formField({
          title: t('Medications'),
          placeholder: t('Add medications here') + '...',
          field: 'medications',
          isChip: true,
          id: 'drugLists',
          model: 'Drugs',
          valueField: 'name',
          otherField: 'medications_other',
        })}
        {/* allergies */}
        {formField({
          title: t('Allergies'),
          placeholder: t('Add allergies here') + '...',
          field: 'allergies',
          isChip: true,
          id: 'allergyLists',
          model: 'Allergies',
          valueField: 'name',
          otherField: 'allergies_other',
        })}
        {/* family  history  */}
        {formField({
          title: t('Family history'),
          placeholder: `${t('Enter your')} ${t('Family history')}`, //t('Enter your family history'),
          field: 'family_history',
          id: 'medicalHistoryLists',
          model: 'MedicalHistories',
          valueField: 'name_en',
          otherField: 'family_history_other',
          isChip: true,
        })}
        {/* social history  */}
        {formField({
          title: t('Social history'),
          placeholder: t('Enter your social history'),
          field: 'social_history',
          isChip: true,
          id: 'socialHistoryLists',
          model: 'SocialHistories',
          valueField: 'name',
          otherField: 'social_history_other',
        })}
      </ScrollView>
      {isEqual(data, updatedData) ? (
        void 0
      ) : (
        // <View
        //   style={{
        //   }}>
        <TouchableOpacity
          style={{
            display: isClinicalView ? 'grid' : 'flex',
            justifyContent: isClinicalView ? 'flex-end' : undefined,
            paddingLeft: 2,
            paddingRight: 2,
            paddingBottom: 10,
            paddingTop: 10,
            cursor: 'pointer',
          }}
          onPress={() => {
            if (!loading) {
              UpdatePatient();
            }
          }}>
          <GradientButton
            label={t('Update Details')}
            loading={loading}
            buttonStyle={{flex: 1}}
            indicatorColor={'#fff'}
          />
        </TouchableOpacity>
        // </View>
      )}
    </>
  );
};

const PatientDetails = (props) => {
  let {navigation = {}, item = {}, isClinicalView = false} = props || {};
  let {t} = props;
  const {patient_id: {_id} = {}} = item || {};

  const uri = {
    query: {
      id: 'patientLists',
      relationValue: {
        model: 'Patients',
        _id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Patients',
  };
  return (
    <Form
      uri={uri}
      mandatory={{name: 1}}
      containerStyle={{
        paddingTop: isClinicalView ? 0 : 10,
        // paddingBottom: 20,
        paddingLeft: isClinicalView ? 0 : 12,
        paddingRight: isClinicalView ? 0 : 12,
        // display:props?.selected='patient_profile'
        // backgroundColor: surface_white,
      }}>
      {({form_context, form_state}) => {
        return (
          <PatientDetailsForm
            t={t}
            navigation={navigation}
            form_context={form_context}
            form_state={form_state}
            item={item}
            isClinicalView={isClinicalView}
          />
        );
      }}
    </Form>
  );
};

export default PatientDetails;
