import React, {useState} from 'react';
import Format from '../GenericComponent/ClinicSignupFormat';
import {
  View,
  TouchableOpacity,
  Text,
  isMobile,
  Dimensions,
  Image,
} from '../../app-components';
import {UploadImage} from '../../app-components/input-components/Editors';
import {TextInput} from '../../app-components/input-components/Editors';
import {h11_Medium, h14_AvenirNext} from '../../theme/fonts';
import {GradientButton} from '../../app-components/buttons/Buttons';
import MobileInput from '../../app-components/input-components/MobileInputForm';
import {getImageUrl, invoke} from '../../AppServices';
import {attachmentIcon, crossPdf, pdf} from '../../images';
import {getFileExtension} from '../../app-components/UtilityFunctions';
const {height, width} = Dimensions.get('window');
const ClinicAdminDetails = (props) => {
  const {navigation = {}} = props || {};
  const {state: {params: {data = {}, type = 'Doctor'} = {}} = {}} =
  navigation || {};
  const [adminName, setAdminName] = useState('');
  const [error, setError] = useState({});
  const [adminMobile, setAdminMobile] = useState('');
  const [file, setFile] = useState([]);
  const [doc, setDoc] = useState([]);
  const [loading, setLoading] = useState(false);
  const setValueMobile = ({value, ...props}) => {
    // console.log(">>>>>>>>>>>>>>>>>>",props,value)
    setAdminMobile(value);
  };
  let medical_Report_Slice = doc;
  let medical_Report_Slice_Second = [];
  const IconLength = isMobile ? 3 : 4;
  if (doc.length > IconLength) {
    medical_Report_Slice = doc.slice(0, IconLength);
    medical_Report_Slice_Second = doc.slice(IconLength, doc.length);
  }
  const imageView = () => {
    navigation.push({
      view: 'pdfReader',
      params: {
        dataArr: doc,
      },
      modal: true,

      modalProps: {
        autoHide: true,
        minHeight: 180,
        height: isMobile ? height * 0.75 : 600,
        width: isMobile ? 300 : 700,
        position: 'screenCenter',
        screenCenterStyle: {
          flex: 1,
          overflow: 'hidden',
          borderRadius: 8,
          maxHeight: '90%',
          maxWidth: '90%',
          zIndex: 12,
        },
      },
    });
  };
  const removeElement = (fileIndex) => {
    const fileCopy = [...doc];
    const updatedFile = fileCopy.filter((item, index) => index !== fileIndex);
    // console.log(updatedFile);
    // setFile([...updatedFile]);
    setDoc([...updatedFile]);
  };
  const createUser = async () => {
    if (!adminName.length) {
      setError({type: 'name', value: 'Please enter name'});
    } else if (!adminMobile.length) {
      setError({type: 'mobile', value: 'Please enter mobile'});
    } else {
      setLoading(true);
      const sendingData = {
        organization_type: 'clinic',
        organization_name: data?.organizationName,
        organization_mobile: data?.organizationMobile,
        email: data?.organizationEmail,
        organization_logo: data?.organizationImage,
        name: adminName,
        mobile: adminMobile,
        organization_address: data?.organizationAddress,
        documents: doc || [],
        profile_picture:file && file.length && file[0]?.file||{}
      };
      await invoke({
        id: 'organizationWithFirstAdmin',
        paramValue: {
          data: {
            ...sendingData,
            individually_registered: true,
          },
          selfDoctor: type === 'Doctor' ? true : false,
          selfClinicAdmin: type !== 'Doctor' ? true : false,
        },
      })
        .then((res) => {
          setLoading(false);
          navigation.push({view: 'signup-otp', params: {...sendingData}});
        })
        .catch((err) => {
          setLoading(false);
          console.log('error in creating', err);
        });
    }
  };
  return (
    <View
      style={{
        minWidth: isMobile ? width * 0.75 : 600,
        // maxWidth: isMobile ? width * 0.75 : 600,
        minHeight: isMobile ? height * 0.65 : 400,
        justifyContent: 'space-between',
      }}>
      <View>
        {/* <Text style={{...h22_Medium}}>Enter Your Details</Text> */}
        <View style={{flexDirection: isMobile ? 'column' : 'row'}}>
          <View style={{marginRight: 25}}>
            <Text style={{...h14_AvenirNext, marginTop: 20, marginBottom: 15}}>
              Profile picture
            </Text>
            <UploadImage
              // imageOnly={false}
              imageWidth={isMobile ? 100 : 200}
              imageHeight={isMobile ? 100 : 200}
              // minHeight={28}
              // setValue={setValue}
              // onChangeValue={(value) => {
              //   setFile([{file: value}]);
              //   console.log('>>>>>>>>>>>>>>>.Hwllo', value);
              // }}
              setValue={({value}) => {
                setFile([{file: value}]);
                // console.log(">>>>>>>>>>>>>>",value);
              }}
              public_upload={true}
              value={file}
              data={file && file.length && file[0]}
              field="file"
              // defaultImage={attachmentIcon}
              uploadOptions={{s3: true}}
              // multiple={true}
            />
          </View>

          <View>
            <Text style={{...h14_AvenirNext, marginTop: 20, marginBottom: 15}}>
              Name
            </Text>
            <TextInput
              value={adminName}
              onChangeText={(value) => {
                setAdminName(value);
              }}
              variant="filled"
              placeholder={'Enter name'}
            />
            {error && error?.type === 'name' ? (
              <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
            ) : (
              void 0
            )}
            {/* <Text style={{...h14_AvenirNext, marginTop: 20, marginBottom: 15}}>
              Email
            </Text>
            <TextInput
              value={adminEmail}
              onChangeText={(value) => {
                setAdminEmail(value);
              }}
              variant="filled"
              placeholder={'Enter Organization Email'}
            /> */}

            <Text style={{...h14_AvenirNext, marginTop: 15, marginBottom: 15}}>
              Mobile
            </Text>
            <MobileInput
              value={adminMobile}
              setValue={setValueMobile}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  // sendOTP();
                }
              }}
              mobileInputStyle={{flex:1}}
            />
            {error && error?.type === 'mobile' ? (
              <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
            ) : (
              void 0
            )}

            {type === 'Doctor' ? (
              <>
                <Text
                  style={{...h14_AvenirNext, marginTop: 15, marginBottom: 15}}>
                  Attachment
                </Text>
                <View style={{alignItems: 'flex-start'}}>
                  <UploadImage
                    imageOnly={false}
                    imageWidth={25}
                    imageHeight={25}
                    minHeight={28}
                    // setValue={setValue}
                    onChangeValue={(value) => {
                      setDoc([...doc,...value]);
                      // setFile([{file: value}]);
                      // console.log('>>>>>>>>>>>>>>>.Hwllo', value);
                    }}
                    // value={file}
                    data={doc}
                    // field="file"
                    defaultImage={attachmentIcon}
                    uploadOptions={{s3: true}}
                    multiple={true}
                    public_upload={true}
                  />
                </View>
                <View style={{flexDirection: 'row', marginLeft: 40}}>
                  {Array.isArray(medical_Report_Slice)
                    ? medical_Report_Slice.map((item, index) => (
                        <TouchableOpacity
                          style={{
                            marginRight: 19,
                          }}
                          onPress={() => imageView()}>
                          {getFileExtension(item.name) === 'pdf' ? (
                            <Image
                              source={pdf}
                              style={{
                                height: 49,
                                width: 50,
                              }}
                            />
                          ) : (
                            <Image
                              source={getImageUrl(item?.thumbnail)}
                              style={{
                                height: 49,
                                width: 50,
                              }}
                            />
                          )}
                          <TouchableOpacity
                            style={{
                              zIndex: 9,
                              top: -6,
                              right: -6,
                              position: 'absolute',
                            }}
                            onPress={(e) => {
                              e.stopPropagation();
                              removeElement(index);
                            }}>
                            <Image
                              source={crossPdf}
                              style={{
                                height: 16,
                                width: 16,
                              }}
                            />
                          </TouchableOpacity>
                        </TouchableOpacity>
                      ))
                    : null}
                  {Array.isArray(medical_Report_Slice_Second) &&
                  medical_Report_Slice_Second.length ? (
                    <TouchableOpacity
                      style={{
                        height: 49,
                        width: 50,
                        marginLeft: 5,
                        borderRadius: 4,
                        justifyContent: 'center',
                        backgroundColor: '#383838',
                        alignItems: 'center',
                      }}
                      onPress={() => imageView()}>
                      <Text style={{color: '#fff'}}>
                        {'+' + medical_Report_Slice_Second.length}
                      </Text>
                    </TouchableOpacity>
                  ) : null}
                </View>
              </>
            ) : (
              void 0
            )}
          </View>
        </View>
      </View>
      <View
        style={{alignItems: isMobile ? 'center' : 'flex-end', marginTop: 10}}>
        <TouchableOpacity
          onPress={() => {
            if (!loading) {
              createUser();
            }
          }}
          style={{width: 120, cursor: 'pointer'}}>
          <GradientButton
            label="Next"
            loading={loading}
            indicatorColor={'#fff'}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (props) => (
  <Format {...props} title={'Enter Your Details'}>
    <ClinicAdminDetails {...props} />
  </Format>
);
