export const resolveDoctorName = (data) => {
  let fullName = '';
  if (data) {
    let {name} = data;
    if (Array.isArray(name)) {
      fullName = name[0].given.join(' ') + ' ' + name[0].family;
    }
  }
  return fullName;
};
