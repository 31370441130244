import React from 'react';
import {Image, Text, View} from '../../app-components';
import Table from '../../app-components/table/Table';
import {getImageUrl, getUser} from '../../AppServices';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {doctorAppointment} from '../../images';
import {TouchableOpacity} from '../login/components';
import {h14_Regular, h14_Medium} from '../../theme/fonts';
import {colors} from '../../theme/colors';
let {textColor87, themeColor} = colors;

let uri = () => {
  const {patient} = getUser();
  return {
    query: {
      id: 'appointmentLists',
      addOnFilter: {
        patient_id: {_id: patient?._id},
        $or: [{status: 'scheduled'}, {status: 'completed'}],
      },
      sort: {date: -1},
      // paramValue: {
      //   period: {
      //     from: moment(new Date()).startOf('day').toDate(),
      //     to: moment(new Date()).endOf('day').toDate(),
      //   },
      // },
    },
    model: 'Appointments',
  };
};

const ChatHistory = () => {
  return (
    <View>
      <Text
        style={{
          ...h14_Medium,
          color: themeColor,
          opacity: 0.76,
        }}>
        Chat History
      </Text>
    </View>
  );
};

const startVideoSessionBtn = ({navigation, item}) => {
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.push({
          view: 'videoSession',
          params: {item},
        });
      }}>
      <GradientButton label="Join Call" />
    </TouchableOpacity>
  );
};

let tableItems = {
  action: ({item}) => {
    return {
      type: 'link',
      link: {
        view: 'appointmentDetails',
        params: {item},
      },
    };
  },
  lg: {
    columns: [
      {
        width: 30,
      },
      {
        render: ({item}) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Image
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: 25,
                  marginRight: 10,
                }}
                source={
                  getImageUrl(item.doctor_id?.profile_picture?.file) ||
                  doctorAppointment
                }
              />
              <Text
                style={{...h14_Regular, color: textColor87, numberOfLines: 1}}>
                {item.doctor_id.name}
              </Text>
            </View>
          );
        },
        header: 'Doctor',
        width: 200,
      },
      {
        type: 'date',
        field: 'time_slot.start_time',
        header: 'Date | Time',
        format: 'DD MMM, YYYY | hh:mm A',
        width: 250,
      },

      {
        type: 'text',
        field: 'booking_fee',
        header: 'Booking Fee',
        width: 150,
      },

      {
        type: 'text',
        field: 'duration',
        header: 'Duration',
        width: 150,
      },
      {
        type: 'text',
        field: 'organization_id.organization_name',
        header: 'Organization',
        // width: 200,
      },
      {
        type: 'text',
        field: 'status',
        header: 'Status',
        width: 150,
      },
      {
        width: 150,
        render: ({item}) => {
          const {status} = item;
          if (status === 'cancelled') {
            return null;
          }
          return ChatHistory();
        },
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'chatHistory',
              params: {item: {...item, isList: true}},
            },
          };
        },
      },
    ],
  },
  md: {
    columns: [
      {
        width: 30,
      },
      {
        render: ({item}) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Image
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: 25,
                  marginRight: 10,
                }}
                source={
                  getImageUrl(item.doctor_id?.profile_picture?.file) ||
                  doctorAppointment
                }
              />
              <Text
                style={{...h14_Regular, color: textColor87, numberOfLines: 1}}>
                {item.doctor_id.name}
              </Text>
            </View>
          );
        },
        header: 'Doctor',
        width: 200,
      },
      {
        type: 'text',
        field: 'booking_fee',
        header: 'Booking Fee',
        width: 150,
      },
      {
        type: 'text',
        field: 'duration',
        header: 'Duration',
        width: 150,
      },
      {
        type: 'text',
        field: 'organization_id.organization_name',
        header: 'Organization',
        width: 200,
      },
    ],
  },
  sm: {
    columns: [
      {
        width: 30,
      },
      {
        render: ({item}) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Image
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: 25,
                  marginRight: 10,
                }}
                source={
                  getImageUrl(item.doctor_id?.profile_picture?.file) ||
                  doctorAppointment
                }
              />
              <Text
                style={{...h14_Regular, color: textColor87, numberOfLines: 1}}>
                {item.doctor_id.name}
              </Text>
            </View>
          );
        },
        header: 'Doctor',
        width: 200,
      },
      {
        type: 'text',
        field: 'booking_fee',
        header: 'Booking Fee',
        width: 200,
      },
      {
        type: 'text',
        field: 'organization_id.organization_name',
        header: 'Organization',
        width: 200,
      },
    ],
  },
};
export default Table({uri, ...tableItems, reloadEvent: 'reloadAddApointment'});
