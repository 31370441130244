import React from 'react';
import {Button} from '../../npms/react-button';
import {ActivityIndicator, Text, Image, TouchableOpacity} from '..';
import {manazeV5Colors} from '../../theme/manazeV5Colors';
import {Headline8} from '../../theme/Headlines';
import {h14_AvenirNext, h16_Regular} from '../../theme/fonts';
import {gradiantButtonShadow} from '../../theme/shadows';

const {
  NegativeAction_300,
  NegativeAction_400,
  secondary_300_main,
  secondary_400,
  Surface,
} = manazeV5Colors;

const negativeButtonStyle = {
  color: NegativeAction_300,
  hoverColor: NegativeAction_400,
};

const positiveButtonStyle = {
  color: secondary_300_main,
  hoverColor: secondary_400,
};

const buttonLabelStyle = {
  labelStyle: {
    ...Headline8,
    color: Surface,
  },
};

export const ButtonLoader = () => {
  return (
    <ActivityIndicator
      size={18}
      color={Surface}
      width={1}
      position={'relative'}
    />
  );
};

export const StandardButton = (props) => {
  return <Button {...buttonLabelStyle} {...props} />;
};

export const CompactButton = (props) => {
  return <Button size="sm" {...buttonLabelStyle} {...props} />;
};

export const LargeButton = (props) => {
  return <Button size="lg" {...buttonLabelStyle} {...props} />;
};

export const OutlineButton = (props) => {
  return <Button outline {...buttonLabelStyle} {...props} />;
};

export const NegativeStandardButton = (props) => {
  return <Button {...negativeButtonStyle} {...buttonLabelStyle} {...props} />;
};

export const NegativeCompactButton = (props) => {
  return (
    <Button
      size="sm"
      {...negativeButtonStyle}
      {...buttonLabelStyle}
      {...props}
    />
  );
};

export const PositiveStandardButton = (props) => {
  return <Button {...positiveButtonStyle} {...buttonLabelStyle} {...props} />;
};

export const PositiveCompactButton = (props) => {
  return (
    <Button
      size="sm"
      {...positiveButtonStyle}
      {...buttonLabelStyle}
      {...props}
    />
  );
};

export const ActionButton = (props) => {
  const {theme = {}, label, submitting = false} = props;
  const {
    containerStyle,
    labelStyle,
    indicatorSize = 'small',
    indicatorColor = 'white',
  } = theme;

  return (
    <TouchableOpacity style={containerStyle}>
      {submitting ? (
        <ActivityIndicator size={indicatorSize} color={indicatorColor} />
      ) : (
        <Text style={labelStyle}>{label}</Text>
      )}
    </TouchableOpacity>
  );
};
export const GradientButton = (props) => {
  const {buttonStyle = {}, indicatorColor = null, varient = ''} = props || {};
  return (
    <TouchableOpacity
      style={{
        minWidth: 125,
        minHeight: varient === 'curved' ? 33 : 38,
        backgroundImage: props.color
          ? ''
          : 'linear-gradient(to right,#0095C9 ,#112E45)',
        borderRadius: varient === 'curved' ? 20 : 4,
        paddingLeft: varient === 'curved' ? 8 : 10,
        paddingRight: varient === 'curved' ? 8 : 10,
        paddingTop: 3,
        paddingBottom: 3,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: props.color ? props.color : '',
        ...gradiantButtonShadow,
        ...buttonStyle,
      }}>
      {props.loading ? (
        <ActivityIndicator size="small" color={indicatorColor} />
      ) : (
        <Text style={{color: 'white', ...h14_AvenirNext, textAlign: 'center'}}>
          {props.label}
        </Text>
      )}
      {props?.image ? (
        <Image
          source={props?.image}
          style={props?.imageStyle ? props?.imageStyle : {}}
        />
      ) : (
        void 0
      )}
    </TouchableOpacity>
  );
};

export const StartVideoCallButton = (props) => {
  return (
    <TouchableOpacity
      style={{
        minWidth: 172,
        minHeight: 42,
        backgroundImage: 'linear-gradient(to right, #55cfea , #5c98f0)',
        borderRadius: 8,
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 3,
        paddingBottom: 3,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
        ...gradiantButtonShadow,
      }}>
      <Text style={{color: 'white', ...h16_Regular, textAlign: 'center'}}>
        {props.label}
      </Text>
      {props?.image ? (
        <Image
          source={props?.image}
          style={props?.imageStyle ? props?.imageStyle : {}}
        />
      ) : (
        void 0
      )}
    </TouchableOpacity>
  );
};

export const buttonMappings = {
  standard: StandardButton,
  outline: OutlineButton,
};
