import React from 'react';
import Form  from '../../app-components/form/Form';
import { save } from '../../app-components/action/SaveAction';
import { cancelButton } from '../../app-components/action/CloseAction';
import { submit } from '../../AppServices';

const EditInferences = (props) => {
  const MyForm = Form({
    ...props,
    closeView: 1,
    reloadEvent: 'reloadLabInference',
    reloadOnChangeEvent: 'reloadLabInference',
    fieldVariant: 'filled',
    footer: {
      containerStyle: {
        margin: 0,
        borderTopWidth: 0,
      },
      actions: [cancelButton(), save({label: 'Save'})],
    },
    onSubmit: submit({
      model: 'InferenceHistory',
    }),
    mandatory: {
        inferenceName:'Lab inference Name is mandatory'
    },
  
    defaultValues: ({navigation}) => {
      const {
        state: {
          params: {item = {}},
        },
      } = navigation;

      return {
        inferenceId: item?.inferenceId,
        inferenceName: item?.inferenceName,
        _id: item?._id,
      };
    },
    header: {
      title: 'Edit Lab Inferences',
      titleTextStyle: {
        color: 'rgba(56, 56, 56, 0.87)',
        fontSize: 20,
        marginLeft: 10,
        fontFamily: 'AvenirNext-Regular',
      },
    },
    formGroups: [
      {
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columns: [
              {
                type: 'text',
                label: 'Inference ID',
                field: 'inferenceId',
                editable: false,
              },
              {
                type: 'textArea',
                label: 'Inference Name',
                field: 'inferenceName',
                maxHeight: 200,
              },
            ],
          },
        ],
      },
    ],
  });
  return <MyForm props={props}/>;
};

export default EditInferences;