import React from 'react';

import {getAgeFromDob} from '../../app-components/UtilityFunctions';
import Table from '../../app-components/table/Table';
import PatientDetails from './PatientDetails';

const uri = (props) => {
  const {navigation} = props;
  const {state: {params: {item = {}} = {}} = {}} = navigation;
  let addOnFilter = {};
  if (item) {
    addOnFilter = {
      isNoteGenerated: true,
      isOrderGeneratedForThisPatient: {$ne: true},
      isBloodGivenForTest: { $ne: false },
      _id: {$nin: [item?._id]},
    };
  }
  return {
    query: {
      id: 'patientLists',
      addOnFilter: addOnFilter,
      sort: {_id: -1},
    },
    model: 'Patients',
  };
};
export default Table({
  uri,
  reloadEvent: 'reloadPatient',
  reloadOnChangeEvent: 'reloadPatient',
  action: ({item}) => {
    return {
      type: 'link',
      link: {
        view: 'detailScreenPatientForm',
        params: {item},
      },
    };
  },
  columns: [
    {type: 'selection', field: '_id'},
    {
      render: ({item}) => {
        return <PatientDetails item={item} showTitle />;
      },
      header: 'Member Details',
    },
    {
      header: 'Age',
      type: 'text',
      field: (data) => {
        let {birthDate} = data || {};
        return birthDate ? getAgeFromDob(birthDate) : '';
      },
      width: 100,
      sortable: 'birthDate',
      reverseSort: true,
    },
    {
      header: 'Family Id',
      type: 'text',
      field: 'familyId',
      sortable: 'familyId',
      reverseSort: true,
    },
    {
      header: 'Member Id',
      type: 'text',
      field: 'patientId',
      sortable: 'patientId',
      reverseSort: true,
    },
    {
      header: 'Registered On',
      type: 'date',
      field: '_createdOn',
    },
    {
      type: 'moreActions',
      actions: [
        {
          text: 'View Notes',
          onPress: async (props) => {
            const {item, navigation} = props;
            navigation.push({
              view: 'notes',
              params: {
                careProgram: true,
                patientItem: {patient_id: {_id: item?._id}},
                modalWidth: 580,
              },
              modal: true,
              modalProps: {
                autoHide: true,
                minHeight: 180,
                position: 'screenLeft',
                style: {
                  height: '100%',
                  width: 580,
                },
              },
            });
          },
        },
      ],
    },
  ],
});
