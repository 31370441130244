import React from 'react';
import {View, Text, ScrollView, Platform} from '../../react-core-components';
import ReactForm from './ReactForm';

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

class JSONReactForm extends React.Component {
  renderChildren = (props) => {
    let {
      formGroups,
      tabs,
      tabProps,
      renderTab,
      navigation,
      eventDispatcher,
    } = this.props;
    if (tabs) {
      if (!renderTab) {
        return (
          <View style={{padding: 12, backgroundColor: 'red'}}>
            <Text style={{color: '#ffffff'}}>
              {'renderTab is not defined in JSONReactForm'}
            </Text>
          </View>
        );
      }
      return renderTab({
        navigation,
        eventDispatcher,
        tabs,
        renderFormGroups: (_props) => {
          return this.renderFormGroups({...props, ..._props});
        },
        ...tabProps,
      });
    } else {
      return this.renderFormGroups({...props, formGroups});
    }
  };
  renderFormGroups = ({form_context, form_state, formGroups}) => {
    let {
      fieldVariant,
      fieldContainer,
      formGroupType,
      renderFormGroup,
      scrollable,
      editable,
      resolveVisible,
    } = this.props;

    let {data = {}} = form_state || {};
    let {setValue, navigation, eventDispatcher, user, getUser} =
      form_context || {};
    user = user || (getUser && getUser());

    if (!formGroups || !formGroups.length) {
      return (
        <View style={{padding: 12, backgroundColor: 'red'}}>
          <Text style={{color: '#ffffff'}}>
            {'Either formGroups or children must be defined in JSONReactForm'}
          </Text>
        </View>
      );
    }

    if (!renderFormGroup) {
      return (
        <View style={{padding: 12, backgroundColor: 'red'}}>
          <Text style={{color: '#ffffff'}}>
            {'renderFormGroup is not defined in JSONReactForm'}
          </Text>
        </View>
      );
    }

    let component = formGroups.map((formGroup, index) => {
      return renderFormGroup({
        user,
        resolveVisible,
        editable,
        data,
        setValue,
        navigation,
        eventDispatcher,
        fieldVariant,
        fieldContainer,
        type: formGroupType,
        separator: index < formGroups.length - 1,
        ...formGroup,
      });
    });
    if (scrollable !== false) {
      let flexStyle = {};
      if (Platform.OS === 'web') {
        flexStyle.flex = 1;
      }
      component = <ScrollView style={flexStyle}>{component}</ScrollView>;
    }
    return component;
  };

  render() {
    let {
      children,
      formGroups,
      formGroupType,
      renderFormGroup,
      scrollable,
      renderLoading,
      renderHeader,
      renderFooter,
      renderChildren,
      renderForm,
      containerStyle,
      ...restProps
    } = this.props;
    return (
      <ReactForm {...restProps}>
        {({form_context, form_state}) => {
          let {navigation, eventDispatcher} = form_context || {};
          let formProps = {
            form_context,
            form_state,
            navigation,
            eventDispatcher,
          };
          if (!children) {
            children = renderForm || this.renderChildren;
          }
          let flexStyle = {};
          if (scrollable !== false) {
            flexStyle = {flex: 1, overflow: 'hidden'};
          }
          return (
            <View
              style={{
                ...flexStyle,
                ...containerStyle,
              }}>
              {getRenderComponent(renderLoading, formProps)}
              {getRenderComponent(renderHeader, formProps)}
              {getRenderComponent(children, formProps)}
              {getRenderComponent(renderFooter, formProps)}
            </View>
          );
        }}
      </ReactForm>
    );
  }
}

export default JSONReactForm;
