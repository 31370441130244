import React, {useEffect, useState} from 'react';
import {View, Text} from '../../../app-components';
import {h18_AvenirNext_500} from '../../../theme/fonts';
import AnalyticsCard from './AnalyticsCard';

const BottomCard = ({getData, timeFilter}) => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData({id: 'getCancelledAnalysis', setResponse, setLoading});
  }, [timeFilter]);

  const data = [
    {
      header: 'New',
      title:
        response?.cancelled_new != undefined && response?.cancelled_new != null
          ? response?.cancelled_new
          : 'N/A',
      titleColor: 'red',
    },
    {
      header: 'Repeat',
      title: '50',
      title:
        response?.cancelled_follow_up != undefined &&
        response?.cancelled_follow_up != null
          ? response?.cancelled_follow_up
          : 'N/A',
      titleColor: 'red',
    },
  ];

  return (
    <View
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        alignItems: 'flex-start',
      }}>
      <Text style={{...h18_AvenirNext_500}}>Drop-off : Cancelled Booking</Text>
      <AnalyticsCard
        cardContent={data}
        cardHeaderText={'Total Cancelled Booking'}
        loading={loading}
      />
    </View>
  );
};

export default BottomCard;
