const PLACEHOLDER = 'Type here ...';

const getParamsFromNav=(props)=>{
const {navigation={}}=props||{};
 return navigation?.state?.params||{}



}

export {PLACEHOLDER,getParamsFromNav};
