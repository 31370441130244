import React from 'react';
import {EditSearchDevice} from '../../../Queries';
import {cancelButton} from '../../../app-components/action/CloseAction';
import SearchDeviceForm from './SearchDeviceForm';
import {getUser} from '../../../AppServices';
import {GradientButton} from '../../../app-components/buttons/Buttons';

let header = {
  title: 'Device Detail',
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },
  actions: [
    cancelButton({label: '< Back '}),

    {
      visible: (props) => {
        let {userType} = getUser();
        return userType === 'SuperAdmin' ? true : false;
      },
      render: (props) => {
        return (
          <GradientButton
            buttonStyle={{minHeight: 38, minWidth: 100, borderRadius: 3}}
            color="#0095c9"
            label={'Edit'}
          />
        );
      },
      type: 'link',
      link: ({navigation}) => {
        let {
          state: {
            params: {item},
          },
        } = navigation;

        return {
          view: 'editSearchForm',
          params: {item},
        };
      },
    },
  ],
};

const editable = false;

let iconEditable = false;

export default SearchDeviceForm({
  uri: EditSearchDevice,
  header,
  editable,
  iconEditable,
});
