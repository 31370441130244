import React, {useEffect, useState} from 'react';
import {
  View,
  ScrollView,
  ActivityIndicator,
  Text,
  isMobile,
} from '../../app-components';
import LineChart from './LineChart';
import {patientWatingRoomCard} from '../../theme/shadows';
import {
  h22_Regular,
  h20_AvenirNext_500,
  h14_AvenirNext,
} from '../../theme/fonts';
import TransactionTable from './TransactionTable';
import HeaderCard from './HeaderCard';
import {getUser, invoke} from '../../AppServices';

const PaymentAnalytics = (props) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  let {
    screenState: {
      dataParams: {
        filters: {
          period: {value: timeFilter} = {},
          organization: filterOrgainzation = {},
        } = {},
      } = {},
    } = {},
  } = props;
  let paramValue = {
    period: {
      ...timeFilter,
    },
  };
  let filter = {};
  const type = getUser()?.userType;
  let currencyType = 'USD';
  if (type === 'ClinicAdmin') {
    let {
      organization: {_id: organizationId, currency = 'USD'} = {},
    } = getUser();
    currencyType = currency;
    filter = {
      organization: {_id: organizationId},
    };
  }
  if (type === 'Doctor') {
    let {
      doctor: {_id: doctorId} = {},
      organization: {currency = 'USD'} = {},
    } = getUser();
    currencyType = currency;
    filter = {
      doctor: {_id: doctorId},
    };
  }
  if (type === 'SuperAdmin') {
    filter = {};
    // if(filterOrgainzation && filterOrgainzation.value&&filterOrgainzation.value._id){
    // filter = {organization:{_id:filterOrgainzation?.value?._id}};
    // }
  }

  // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>: PaymentAnalytics -> organization", filterOrgainzation)
  const getChartData = async () => {
    try {
      setLoading(true);
      let res = void 0;
      if (timeFilter) {
        res = await invoke({
          id: 'getGraphData',
          paramValue,
        });
      }
      if (res && res?.result && Array.isArray(res?.result)) {
        setChartData(res?.result.reverse());
      } else {
        console.log('err');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChartData();
  }, [timeFilter]);

  return (
    <ScrollView style={{flex: 1}}>
      <View style={{backgroundColor: '#F1F3F9', padding: isMobile ? 10 : 20}}>
        <HeaderCard
          {...props}
          filter={filter}
          timeFilter={timeFilter}
          currency={currencyType}
        />
        <View
          style={{
            flex: 1,
            padding: isMobile ? 0 : 20,
            border: '1px solid rgba(56, 56, 56, 0.37)',
            backgroundColor: '#fff',
            borderRadius: 10,
            ...patientWatingRoomCard,
          }}>
          <View style={{padding: 15, paddingTop:isMobile?5: 0}}>
            <Text style={{...h20_AvenirNext_500}}>Payment</Text>
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.37)'}}>
              in {currencyType}
            </Text>
          </View>
          {!loading ? (
            <LineChart data={chartData} currencyType={currencyType} />
          ) : (
            <View style={{flex: 1, minHeight: 500}}>
              <ActivityIndicator size={'small'} />
            </View>
          )}
        </View>
      </View>
      <View style={{minHeight: 350, padding: 20}}>
        <Text style={{...h22_Regular, marginBottom: 20}}>
          Transaction Activity
        </Text>
        <TransactionTable {...props} filter={filter} />
      </View>
    </ScrollView>
  );
};

export default PaymentAnalytics;
