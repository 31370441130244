import React from 'react';

import {Text, TouchableOpacity} from '../../npms/react-core-components';
import {Avatar} from '../../npms/react-avatar';
import Table from '../../app-components/table/Table';
import {checkSelectedIcon, userIcon} from '../../images';
const data = [
  {
    name: 'Sachin',
    firstName: 'Sachin',
    lastName: 'Bansal',
    age: 30,
    dob: '1989-12-02T10:15:49.860Z',
    gender: 'M',
  },
  {
    name: 'Vishal',
    firstName: 'Vishal',
    lastName: 'Joshi',
    age: 25,
    dob: '1989-12-02T10:15:49.860Z',
    gender: 'M',
  },
  {
    name: 'Ayush',
    firstName: 'Ayush',
    lastName: 'Pandey',
    age: 22,
    dob: '1989-12-02T10:15:49.860Z',
    gender: 'M',
  },
];

let tableItems = {
  lg: {
    columns: [
      {type: 'selection', width: 50},
      {
        header: 'Avatar',
        type: 'custom',
        width: 80,
        render: () => <Avatar icon={userIcon} />,
      },
      {type: 'text', field: 'name', header: 'Name', width: 150},
      {
        render: ({item}) => {
          return (
            <Text>
              {item.firstName || ''} {item.lastName || ''}
            </Text>
          );
        },
        header: 'Full Name',
        width: 200,
      },
      {type: 'number', field: 'age', header: 'Age', width: 150},
      {type: 'date', field: 'dob', header: 'DOB', width: 100},
      {type: 'text', field: 'gender', header: 'Gender', width: 100},
      {
        type: 'custom',
        width: 100,
        render: (props) => (
          <TouchableOpacity onPress={() => props.navigation.push('card-view')}>
            <Text style={{fontSize: 18}}>Click Me</Text>
          </TouchableOpacity>
        ),
      },
      {
        type: 'moreActions',
        width: 100,
        actions: [
          {
            text: 'Open Form',
            action: ({item, navigation}) => {
              return navigation.push({
                view: 'form-view',
                params: {
                  TaskWorkFlow: item,
                },
                modal: true,
                //autoHide: false,
              });
            },
          },
        ],
      },
    ],
    hoverActions: {
      actions: [
        {
          // align: 'right',
          icon: checkSelectedIcon,
          iconStyle: {height: 16, width: 16},
          action: ({item}) => {
            alert('HoverActions Clicked');
          },
        },
      ],
    },
  },
  md: {
    columns: [
      {type: 'selection', width: 100},
      {
        header: 'Avatar',
        type: 'custom',
        width: 100,
        render: () => <Avatar icon={userIcon} />,
      },
      {
        render: ({item}) => {
          return (
            <Text>
              {item.firstName || ''} {item.lastName || ''}
            </Text>
          );
        },
        header: 'Full Name',
        width: 150,
      },
      {type: 'number', field: 'age', header: 'Age', width: 100},
      {
        type: 'custom',
        width: 100,
        render: (props) => (
          <TouchableOpacity onPress={() => props.navigation.push('card-view')}>
            <Text style={{fontSize: 18}}>Click Me</Text>
          </TouchableOpacity>
        ),
      },
      {
        type: 'moreActions',
        width: 100,
        actions: [
          {
            text: 'Open Form',
            action: ({item, navigation}) => {
              return navigation.push({
                view: 'form-view',
                params: {
                  TaskWorkFlow: item,
                },
                modal: true,
                //autoHide: false,
              });
            },
          },
        ],
      },
    ],
    hoverActions: {
      actions: [
        {
          // align: 'right',
          icon: checkSelectedIcon,
          iconStyle: {height: 16, width: 16},
          action: ({item}) => {
            alert('HoverActions Clicked');
          },
        },
      ],
    },
  },
  sm: {
    columns: [
      {type: 'selection', width: 50},
      {
        header: 'Avatar',
        type: 'custom',
        width: 50,
        render: () => <Avatar icon={userIcon} />,
      },
      {
        render: ({item}) => {
          return (
            <Text>
              {item.firstName || ''} {item.lastName || ''}
            </Text>
          );
        },
        header: 'Full Name',
        width: 150,
      },
      {
        type: 'custom',
        width: 50,
        render: (props) => (
          <TouchableOpacity onPress={() => props.navigation.push('card-view')}>
            <Text style={{fontSize: 10}}>Click</Text>
          </TouchableOpacity>
        ),
      },
      {
        type: 'moreActions',
        width: 100,
        actions: [
          {
            text: 'Open Form',
            action: ({item, navigation}) => {
              return navigation.push({
                view: 'form-view',
                params: {
                  TaskWorkFlow: item,
                },
                modal: true,
                //autoHide: false,
              });
            },
          },
        ],
      },
    ],
    hoverActions: {
      actions: [
        {
          // align: 'right',
          icon: checkSelectedIcon,
          iconStyle: {height: 16, width: 16},
          action: ({item}) => {
            alert('HoverActions Clicked');
          },
        },
      ],
    },
  },
};
// export default Table({uri, ...tableItems});
export default Table({data, ...tableItems});
