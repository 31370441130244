import React from 'react';
import {notificationView} from './NotificationCard';
export const NotificationListCard = {
  columns: [
    {
      type: 'custom',
      flex: 1,
      render: ({item,navigation,hideModal, eventDispatcher, classroomStore}) =>
        notificationView({item,navigation,hideModal, eventDispatcher, closeAction: false, classroomStore}),
    },
  ],
};
