import React from 'react';
import {clinicFilter} from '../../app-components/filter/MayaMDFilters';
import Analytics from './Analytics';
import {backIconNew} from '../../images';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  isMobile,
} from '../../app-components';
import {h24_AvenirNext_500} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import CustomHeaderDate from '../../app-components/CustomHeaderDates/CustomHeaderDate';
import {getUser} from '../../AppServices';
let {textColor87} = colors;
let {DateFilter} = CustomHeaderDate;

const styles = {
  headerContainer: {
    flexDirection: 'row',
    paddingTop: isMobile ? 10 : 16,
    paddingBottom: isMobile ? 10 : 16,
    backgroundColor: '#f1f4f9',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
  },
  headerText: {
    paddingLeft: isMobile ? 17 : 27,
    ...h24_AvenirNext_500,
    color: textColor87,
  },
};
export default {
  analytics: {
    screen: Analytics,
    expanded: true,
    renderHeader: (props) => {
      let backIconVisible = false;
      let {navigation, screenState} = props;
      let {setScreenState} = navigation;
      const routes = navigation.getActiveRoutes();
      let firstRoute = routes[0] || [];
      if (
        firstRoute !== 'analytics' &&
        routes?.length > 1 &&
        routes?.indexOf('analytics') !== -1
      ) {
        backIconVisible = false;
      } else {
        backIconVisible = true;
      }
      // let {userType} = getUser();
      return (
        <View style={styles.headerContainer}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            {!backIconVisible && (
              <TouchableOpacity
                style={{cursor: 'pointer'}}
                onPress={() => {
                  navigation.pop();
                }}>
                <Image
                  source={backIconNew}
                  style={{height: 35, width: 40, paddingLeft: 10}}
                />
              </TouchableOpacity>
            )}
            <Text style={styles.headerText}>Analytics</Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            {/* {userType === 'SuperAdmin'
              ? clinicFilter({setScreenState, screenState})
            : void 0} */}
            <DateFilter
              screenState={screenState}
              setScreenState={setScreenState}
              defaultFilter={'Week'}
              showDateToggle={!isMobile}
            />
          </View>
        </View>
      );
    },
  },
};
