import Table from '../../app-components/table/Table';
import {removeData} from '../../AppServices';
import {listUri} from '../../Queries';
import {colors} from '../../theme/colors';
import {h14_Medium} from '../../theme/fonts';
import {serialNumber, tableText} from '../RPM/rpmUtils';
import {startCase} from 'lodash';

const {backdropColor70} = colors;
let uri = () => {
  return listUri({
    model: 'EducationContents',
    id: 'EducationContentList',
  });
};

const tableData = {
  lg: {
    columns: [
      {
        width: 50,
      },
      {
        render: serialNumber,
        header: 'S.No.',
        width: 150,
      },
      {
        type: 'text',
        field: 'title',
        header: 'Title',
        // width: 250,
      },
      {
        typoe: 'text',
        header: 'Education Category',
        field: 'educationCategory.name',
      },
      {
        header: 'Content Type',
        render: ({item: {contentType = ''} = {}}) => {
          return tableText(startCase(contentType));
        },
      },
      {
        header: 'Published/Not Published',
        render: ({item: {isPublished = false} = {}}) => {
          return tableText(isPublished ? 'Published' : 'Not Published');
        },
      },
    ],
    hoverActions: [
      {
        value: 'Edit',
        align: 'right',
        textStyle: {
          color: '#9a9a9a',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'edit-education-content',
              params: {item},
            },
          };
        },
      },
      {
        value: 'Delete',
        position: 'center',
        align: 'right',
        textStyle: {
          color: 'rgb(241,79,79, 0.87)',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: {
          type: 'link',
          link: (props) => {
            let {item} = props;
            return {
              view: 'delete-masterData',
              modal: true,
              params: {
                item,
                label: 'Education Content',
                deleteAction: () =>
                  removeData({item, model: 'EducationContents'}),
                reloadEvent: 'reloadEducationContent',
              },
              modalProps: {
                autoHide: true,
                height: 250,
                backdropColor: backdropColor70,
              },
            };
          },
        },
      },
    ],
  },
  sm: {
    columns: [
      {
        width: 50,
      },
      {
        render: serialNumber,
        header: 'S.No.',
        width: 150,
      },
      {
        type: 'text',
        field: 'title',
        header: 'Title',
        width: 300,
      },
    ],
  },
};

export default Table({
  uri,
  ...tableData,
  reloadEvent: 'reloadEducationContent',
});
