import React from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  Image,
  isMobile,
} from '../../../app-components';
import {getFileExtension} from '../../../app-components/UtilityFunctions';
import {getImageUrl} from '../../../AppServices';
import {pdf} from '../../../images';

const MayaMdNote = (props) => {
  const {item = {}} = props;
  const {mayamd_notes = []} = item;
  const IconLength = 4;

  let mayamd_Notes_Slice = mayamd_notes;
  let mayamd_Notes_Slice_Second = [];
  if (mayamd_notes.length > IconLength) {
    mayamd_Notes_Slice = mayamd_notes.slice(0, IconLength);
    mayamd_Notes_Slice_Second = mayamd_notes.slice(
      IconLength,
      mayamd_notes.length,
    );
  }
  return (
    <>
      <View
        style={{
          backgroundColor: '#f9f9f9',
          marginTop: 10,
          padding: 10,
          borderRadius: 8,
        }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            // marginTop: 15,
          }}>
          <View>
            <View style={{flexDirection: 'row'}}>
              {Array.isArray(mayamd_Notes_Slice)
                ? mayamd_Notes_Slice.map((item) => (
                    <TouchableOpacity
                      style={{
                        height: 49,
                        width: 50,
                        marginLeft: 5,
                        borderRadius: 4,
                        cursor: 'pointer',
                      }}
                      onPress={() => {
                        const {navigation} = props;
                        navigation.push({
                          view: 'pdfReader',
                          params: {
                            dataArr: mayamd_notes,
                          },
                          modal: true,

                          modalProps: {
                            autoHide: true,
                            minHeight: 180,
                            position: 'screenRight',
                            style: {
                              height: '100%',
                              width: isMobile
                                ? '100%'
                                : Dimensions.get('window').width -
                                  props.publisherViewWidth,
                              zIndex: 999,
                            },
                            backdropStyle: {},
                            // ...confirmModalProps,
                          },
                        });
                      }}>
                      {getFileExtension(item.name) === 'pdf' ? (
                        <Image source={pdf} style={{height: 49, width: 50}} />
                      ) : (
                        <Image
                          source={getImageUrl(item?.thumbnail)}
                          style={{height: 49, width: 50}}
                        />
                      )}
                    </TouchableOpacity>
                  ))
                : null}
              {Array.isArray(mayamd_Notes_Slice_Second) &&
              mayamd_Notes_Slice_Second.length ? (
                <TouchableOpacity
                  style={{
                    height: 49,
                    width: 50,
                    marginLeft: 5,
                    borderRadius: 4,
                    justifyContent: 'center',
                    backgroundColor: '#383838',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    const {navigation} = props;
                    navigation.push({
                      view: 'pdfReader',
                      params: {
                        dataArr: mayamd_notes,
                      },
                      modal: true,
                      modalProps: {
                        autoHide: true,
                        minHeight: 180,
                        position: 'screenRight',
                        style: {
                          height: '100%',
                          width: isMobile
                            ? '100%'
                            : Dimensions.get('window').width -
                              props.publisherViewWidth,
                          zIndex: 999,
                        },
                        backdropStyle: {},
                        // ...confirmModalProps,
                      },
                      // modalProps: {
                      //   autoHide: true,
                      //   minHeight: 180,
                      //   height: 600,
                      //   width: isMobile ? 300 : 700,
                      //   position: 'screenCenter',
                      //   // ...confirmModalProps,
                      // },
                    });
                  }}>
                  <Text style={{color: '#fff'}}>
                    {'+' + mayamd_Notes_Slice_Second.length}
                  </Text>
                </TouchableOpacity>
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default MayaMdNote;
