import React from 'react';
import Form from '../../../../app-components/form/Form';
import {submit, getUser} from '../../../../AppServices';
import {
  multiAutoSuggestOptionsInput,
  searchInput,
  googlePlaceInput,
  autoSuggestOptionsInput,
} from '../../../../autoSuggestions';
import ct from 'countries-and-timezones';
import {PLACEHOLDER} from '../../../../app-components/utils';
import {Image, TouchableOpacity} from '../../../../app-components';
import {backCrossIcon} from '../../../../images';
import {cancelButton} from '../../../../app-components/action/CloseAction';
import {save} from '../../../../app-components/action/SaveAction';
import {
  resolveDoctorAddress,
  resolveDoctorEmail,
  resolveDoctorFirstName,
  resolveDoctorLastName,
  resolveDoctorMobile,
  resolveDoctornpi,
  updateDoctorDataInForm,
} from '../../../../app-components/DoctorUtility';

export default ({uri, header, disabled = false, defaultValues}) => {
  return Form({
    closeView: 1,
    beforeSubmit: updateDoctorDataInForm,
    onSubmit: submit({
      model: 'Doctor',
      lowerCaseFields: ['email'],
    }),
    reloadEvent: 'reloadDoctor',
    defaultValues: defaultValues,
    computations: {
      self: {
        specialities_procedures_name: {
          compute: (data = {}) => {
            let specialities_procedures = data.specialities_procedures || [];
            let specialities_procedures_name = [];
            for (let item of specialities_procedures) {
              if (item.speciality && item.speciality.name) {
                specialities_procedures_name.push(item.speciality.name);
              }
              if (item.procedures) {
                for (let procedure of item.procedures) {
                  if (procedure && procedure.name) {
                    specialities_procedures_name.push(procedure.name);
                  }
                }
              }
            }
            return {
              set: {
                specialities_procedures_name,
              },
            };
          },
          onChange: [
            'specialities_procedures._id',
            'specialities_procedures.speciality',
            'specialities_procedures.procedures',
          ],
        },
        // address:{
        //   compute:(data)=>{
        //     console.log(">>>>>>>>>>>>>>>")
        //     return {
        //       time_zone: void 0
        //     }
        //   },
        //   onChange:[
        //     'address'
        //   ]
        // }
      },
      children: {
        specialities_procedures: {
          self: {
            procedures: {
              compute: (data) => {
                return {
                  set: {
                    procedures: [],
                  },
                };
              },
              onChange: ['speciality'],
            },
          },
        },
      },
    },
    fieldVariant: 'filled',
    fieldContainer: 'topLabel',
    mandatory: {time_zone: 1, consultation_duration: 1},
    lg: {
      header,
      formGroups: [
        {
          label: 'General Information',
          direction: 'row',
          groups: [
            {
              width: 220,
              columns: [
                {
                  type: 'uploadImage',
                  field: 'profile_picture',
                  label: 'Profile Picture',
                  uploadOptions: {s3: true},
                  description:
                    '*File should be image (Jpeg or png). Profile image should be square in shape.',
                },
              ],
            },
            {
              columnsPerRow: 2,
              columns: [
                {
                  type: 'text',
                  field: 'Fname',
                  label: 'First Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'Lname',
                  label: 'Last Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'email',
                  label: 'Email',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },

                {
                  type: 'mobile',
                  field: 'mobile',
                  label: 'Mobile',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  disabled,
                },
                {
                  type: 'text',
                  field: 'npi',
                  label: 'NPI',
                  placeholder: PLACEHOLDER,
                  // mandatory: true,
                },
                {
                  type: 'chipsToggle',
                  field: 'gender',
                  label: 'Gender',
                  options: [
                    {value: 'Male', label: 'Male'},
                    {value: 'Female', label: 'Female'},
                    {value: 'Other', label: 'Other'},
                  ],
                },
                {
                  type: 'number',
                  field: 'experience_in_years',
                  label: 'Experience in Years',
                  placeholder: PLACEHOLDER,
                  // mandatory: true,
                },
                autoSuggestOptionsInput({
                  field: 'time_zone',
                  placeholder: 'Select Time Zone',
                  searchField: 'name',
                  suggestionField: 'name',
                  valueField: 'name',
                  keyField: 'name',
                  options: ({data}) => {
                    let {country_code} = data.address || {};
                    let timeZonData = ct.getTimezonesForCountry(country_code);
                    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>> ~ file: DoctorForm.js ~ line 210 ~ timeZonData", timeZonData)
                    console.log(ct.getTimezone('America/Adak'));
                    return timeZonData || [];
                  },
                  mandatory: true,
                  label: 'Time Zone',
                }),
                {
                  type: 'textArea',
                  field: 'qualification_description',
                  label: 'Qualification Description',
                  placeholder: PLACEHOLDER,
                  // mandatory: true,
                },
                {
                  type: 'textArea',
                  field: 'award_and_achievement_description',
                  placeholder: PLACEHOLDER,
                  label: 'Awards and Achievements Description',
                  // mandatory: true,
                },

                {
                  type: 'textArea',
                  field: 'about_me_description',
                  placeholder: PLACEHOLDER,
                  label: 'About me',
                  // mandatory: true,
                },
                {
                  type: 'number',
                  field: 'cost_of_booking',
                  placeholder: PLACEHOLDER,
                  label: 'Booking Fee',
                  mandatory: true,
                },
                {
                  type: 'number',
                  field: 'consultation_duration',
                  label: 'Booking Duration (in minutes)',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'checkBox',
                  field: 'isActive',
                  placeholder: PLACEHOLDER,
                  label: 'Active',
                  // mandatory: true,
                },
              ],
            },
            {
              width: 300,
              columns: [
                googlePlaceInput({
                  field: 'address',
                  label: 'Address',
                  mapHeight: 250,
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  isSpace: true,
                }),
              ],
            },
          ],
        },
        {
          columns: [
            {
              type: 'nestedList',
              field: 'specialities_procedures',
              header: {
                label: 'Specialities And Procedures',
                addLabel: 'ADD',
              },
              formGroups: [
                {
                  columnsPerRow: 3,
                  fieldVariant: 'filled',
                  groups: [
                    {
                      columns: [
                        searchInput({
                          width: 300,
                          field: 'speciality',
                          query: 'specialityLists',
                          model: 'Specialities',
                          placeholder: 'Select Speciality',
                          idField: '_id',
                          searchField: 'name',
                          suggestionField: 'name',
                          valueField: 'name',
                        }),
                        multiAutoSuggestOptionsInput({
                          field: 'procedures',
                          placeholder: 'Select Procedures',
                          idField: '_id',
                          searchField: 'name',
                          suggestionField: 'name',
                          valueField: 'name',
                          options: ({data}) => {
                            return data?.speciality?.procedures || [];
                          },
                        }),
                        {
                          width: 70,
                          render: (props) => {
                            let {item, index, removeRow} = props;
                            return (
                              <TouchableOpacity
                                onPress={() => removeRow({item, index})}
                                style={{
                                  cursor: 'pointer',
                                  height: 38,
                                  justifyContent: 'center',
                                }}>
                                <Image
                                  source={backCrossIcon}
                                  style={{width: 20, height: 20}}
                                />
                              </TouchableOpacity>
                            );
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header,
      formGroups: [
        {
          label: 'General Information',
          direction: 'row',
          groups: [
            {
              width: 220,
              columns: [
                {
                  type: 'uploadImage',
                  field: 'profile_picture',
                  label: 'Profile Picture',
                  uploadOptions: {s3: true},
                  description:
                    '*File should be image (Jpeg or png). Profile image should be square in shape.',
                },
              ],
            },
            {
              columnsPerRow: 1,
              columns: [
                {
                  type: 'text',
                  field: 'Fname',
                  label: 'First Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'Lname',
                  label: 'Last Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'email',
                  label: 'Email',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },

                {
                  type: 'mobile',
                  field: 'mobile',
                  label: 'Mobile',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'npi',
                  label: 'NPI',
                  placeholder: PLACEHOLDER,
                  // mandatory: true,
                },
                {
                  type: 'chipsToggle',
                  field: 'gender',
                  label: 'Gender',
                  options: [
                    {value: 'Male', label: 'Male'},
                    {value: 'Female', label: 'Female'},
                    {value: 'Other', label: 'Other'},
                  ],
                },
                {
                  type: 'number',
                  field: 'experience_in_years',
                  label: 'Experience in Years',
                  placeholder: PLACEHOLDER,
                  // mandatory: true,
                },
                autoSuggestOptionsInput({
                  field: 'time_zone',
                  placeholder: 'Select Time Zone',
                  searchField: 'name',
                  suggestionField: 'name',
                  valueField: 'name',
                  keyField: 'name',
                  options: ({data}) => {
                    let {country_code} = data.address || {};
                    let timeZonData = ct.getTimezonesForCountry(country_code);
                    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>> ~ file: DoctorForm.js ~ line 210 ~ timeZonData", timeZonData)
                    return timeZonData || [];
                  },
                  label: 'Time Zone',
                }),
                {
                  type: 'textArea',
                  field: 'qualification_description',
                  label: 'Qualification Description',
                  placeholder: PLACEHOLDER,
                  // mandatory: true,
                },
                {
                  type: 'textArea',
                  field: 'award_and_achievement_description',
                  placeholder: PLACEHOLDER,
                  label: 'Awards and Achievements Description',
                  // mandatory: true,
                },

                {
                  type: 'textArea',
                  field: 'about_me_description',
                  placeholder: PLACEHOLDER,
                  label: 'About me',
                  // mandatory: true,
                },
              ],
            },
            {
              width: 300,
              columns: [
                googlePlaceInput({
                  field: 'address',
                  label: 'Address',
                  mapHeight: 250,
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  isSpace: true,
                }),
              ],
            },
          ],
        },
        {
          columns: [
            {
              type: 'nestedList',
              field: 'specialities_procedures',
              header: {
                label: 'Specialities And Procedures',
                addLabel: 'ADD',
              },
              formGroups: [
                {
                  columnsPerRow: 1,
                  fieldVariant: 'filled',
                  groups: [
                    {
                      columns: [
                        searchInput({
                          width: 300,
                          field: 'speciality',
                          query: 'specialityLists',
                          model: 'Specialities',
                          placeholder: 'Select Speciality',
                          idField: '_id',
                          searchField: 'name',
                          suggestionField: 'name',
                          valueField: 'name',
                        }),
                        multiAutoSuggestOptionsInput({
                          field: 'procedures',
                          placeholder: 'Select Procedures',
                          idField: '_id',
                          searchField: 'name',
                          suggestionField: 'name',
                          valueField: 'name',
                          options: ({data}) => {
                            return data?.speciality?.procedures || [];
                          },
                        }),
                        {
                          width: 70,
                          render: (props) => {
                            let {item, index, removeRow} = props;
                            return (
                              <TouchableOpacity
                                onPress={() => removeRow({item, index})}
                                style={{
                                  cursor: 'pointer',
                                  height: 38,
                                  justifyContent: 'center',
                                }}>
                                <Image
                                  source={backCrossIcon}
                                  style={{width: 20, height: 20}}
                                />
                              </TouchableOpacity>
                            );
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  });
};
