import React from 'react';
import {View, TouchableOpacity, Text} from '../../react-core-components';
import {WithInputWrapper} from '../../react-input-wrapper';

class Toggle extends React.Component {
  render() {
    let {
      className,
      itemClassName,
      selectedItemClassName,
      style,
      itemStyle,
      selectedItemStyle,
      optionStyle,
      selectedOptionStyle,
      value,
      defaultValue,
      options,
      onChangeValue,
      onlyFirstLetter = false,
      editable = true,
    } = this.props;
    if (!options || !options.length) {
      return null;
    }
    value = value || defaultValue;
    if (value && typeof value !== 'string') {
      value = value.value;
    }
    return (
      <View
        className={className}
        style={{
          flexDirection: 'row',
          ...style,
        }}>
        {options.map((option) => {
          if (!option) {
            return;
          }
          let optionValue = option;
          let optionLabel = option;
          if (typeof option !== 'string') {
            optionValue = option.value;
            optionLabel = option.label || option.value;
          }
          let isSelected = value === optionValue;
          return (
            <TouchableOpacity
              key={optionValue}
              className={(isSelected && selectedItemClassName) || itemClassName}
              style={{
                justifyContent: 'center',
                ...itemStyle,
                cursor: editable ? 'pointer' : undefined,
                ...(isSelected && selectedItemStyle),
              }}
              onPress={(e) => {
                if (editable) {
                  onChangeValue && onChangeValue(optionValue, e);
                }
              }}>
              <Text
                style={{
                  ...optionStyle,
                  ...(isSelected && selectedOptionStyle),
                }}>
                {onlyFirstLetter ? optionLabel[0] : optionLabel}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }
}

Toggle.defaultProps = {
  style: {
    borderRadius: 4,
    backgroundColor: '#f6f6f6',
  },
  itemStyle: {
    minWidth: 100,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 16,
    paddingRight: 16,
    borderWidth: 1,
    borderColor: 'transparent',
    cursor: 'pointer',
  },
  selectedItemStyle: {
    borderRadius: 4,
    borderColor: '#1ed69e',
    backgroundColor: '#fbfbfb',
  },
  optionStyle: {
    textAlign: 'center',
    color: '#a2a2a2',
  },
  selectedOptionStyle: {
    color: '#1ed69e',
  },
};

export default WithInputWrapper(Toggle);
