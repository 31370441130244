import React, {useEffect, useState} from 'react';
import VideoCallMobile from './videoCallMobile';
import VideoCall from './videoCall';
import PatientQueueList from './PatientQueueList';
import InstantQueueList from './InstantQueueList';
import moment from 'moment';
import {
  Text,
  TouchableWithoutFeedback,
  View,
  Image,
  isMobile,
} from '../../app-components';
import ViewDetail from './ViewPatientDetails';
import ImageViewer from './ImageViewer';

import {
  h12_Regular,
  h14_AvenirNext,
  h20_Regular,
  h22_SemiBold,
  h24_Regular,
} from '../../theme/fonts';
import {getData, getUser, getCurrency} from '../../AppServices';
import SidePanel from './SidePanel';
import {bgs, colors} from '../../theme/colors';
import AppointmentContext from '../../AppointmentContext';
import PastNotes from './PastNotes';
import {brandImg} from '../../IconStyleBrand';
import {BRAND_NAME} from '../../Config';
import {isTimerVisible} from '../RPM/rpmUtils';
import Timer from '../GenericComponent/Timer';

let {borderColor} = bgs;
const {themeColor} = colors;

const styles = {
  headerContainer: {
    backgroundColor: '#e7eef5',
    flexDirection: isMobile ? 'column' : 'row',
    paddingTop: 32,
    paddingBottom: 32,
    justifyContent: 'space-evenly',
  },
  countViewContainer: {
    width: 90,
    borderRadius: 15.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerColumnContainer: {
    backgroundColor: '#ffff',
    padding: 10,
    borderRadius: 8,
  },
};

const countView = (bgColor, textColor, value) => {
  return (
    <TouchableWithoutFeedback>
      <View style={{...styles.countViewContainer, backgroundColor: bgColor}}>
        <Text
          style={{
            ...h12_Regular,
            color: textColor,
          }}>
          {value}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

const headerColumn = (text1, text2, countViewValues) => {
  return (
    <View
      style={{
        ...styles.headerColumnContainer,
        width: countViewValues ? 350 : 267,
        ...(isMobile ? {alignItems: 'center', margin: 5} : {}),
      }}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <Text style={{...h14_AvenirNext, lineHeight: 31, letterSpacing: 0.5}}>
          {text1}
        </Text>
        {countViewValues
          ? countView(
              countViewValues?.bgColor,
              countViewValues?.textColor,
              countViewValues?.value,
            )
          : null}
      </View>
      <View>
        <Text style={{...h24_Regular, lineHeight: 31, letterSpacing: 0.86}}>
          {text2}
        </Text>
      </View>
    </View>
  );
};

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date: void 0};
  }
  componentDidMount = async () => {
    let date = moment();
    this.timerID = setInterval(() => this.tick(), 1000);
    this.setState({date});
  };
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  tick = () => {
    let {date} = this.state;
    var t = new Date(date);
    t.setSeconds(t.getSeconds() + 1);
    this.setState({date: t});
  };
  render() {
    return (
      <View style={{justifyContent: 'center'}}>
        <Text style={{...h20_Regular, color: '#383838'}}>
          {moment(new Date()).format('dddd')}
        </Text>
        <Text style={{...h20_Regular, color: themeColor}}>
          {moment(new Date()).format('MMM DD | hh:mm A')}
        </Text>
      </View>
    );
  }
}

let Cards = (props) => {
  const {appointmentStore, eventDispatcher} = props;
  let key = void 0;
  if (appointmentStore) {
    const {newData: {_id} = {}} = appointmentStore || {};
    key = _id;
  }

  const [cardData, setCardData] = useState(void 0);
  useEffect(() => {
    reloadData();
  }, [key]);
  const reloadData = async () => {
    const {doctor = {}} = getUser() || {};
    const apiData = await getData({
      model: 'Appointments',
      id: 'doctorAppointments',
      filter: {
        doctor_id: {_id: doctor?._id},
        $or: [{status: 'scheduled'}, {status: 'completed'}],
      },
      paramValue: {
        period: {
          from: moment(new Date()).startOf('day').toDate(),
          to: moment(new Date()).endOf('day').toDate(),
        },
      },
    });
    const {data} = apiData;
    setCardData(data[0]);
  };

  eventDispatcher.listen('reloadAddApointment', reloadData);
  const {organization: {currency = 'USD'} = {}} = getUser() || {};

  return (
    <>
      {headerColumn('Patients for today', `${cardData?._count || '0'}`)}
      {headerColumn(
        'Scheduled hours',
        `${
          cardData?.total_duration
            ? (cardData?.total_duration / 60).toFixed(2)
            : '0'
        } hrs`,
      )}
      {headerColumn(
        'Today’s Revenue',
        `${getCurrency(currency)} ${cardData?.total_booking_fee || '0'}`,
      )}
    </>
  );
};

Cards = AppointmentContext(Cards);

export default {
  'doctor-patient-queue': {
    screen: PatientQueueList,
    renderHeader: ({navigation, eventDispatcher}) => {
      const routes = navigation.getActiveRoutes();
      let firstRoute = routes[0] || [];
      return (firstRoute !== 'doctor-patient-queue' && routes?.length > 1) ||
        (routes?.length > 1 &&
          routes?.indexOf('doctor-patient-queue') !== -1) ? (
        void 0
      ) : (
        <View
          style={{
            ...styles.headerContainer,
            ...(isMobile ? {alignItems: 'center', padding: 5} : {}),
          }}>
          {isMobile ? void 0 : <Clock />}
          <Cards eventDispatcher={eventDispatcher} />
        </View>
      );
    },
  },
  'instant-patient-queue': {
    screen: InstantQueueList,
    renderHeader: ({navigation, eventDispatcher}) => {
      const routes = navigation.getActiveRoutes();
      let firstRoute = routes[0] || [];
      return (firstRoute !== 'doctor-patient-queue' && routes?.length > 1) ||
        (routes?.length > 1 &&
          routes?.indexOf('doctor-patient-queue') !== -1) ? (
        void 0
      ) : (
        <View
          style={{
            ...styles.headerContainer,
            ...(isMobile
              ? {}
              : {
                  justifyContent: 'flex-start',
                  paddingLeft: 100,
                }),

            alignItems: 'center',
          }}>
          {isMobile ? void 0 : <Clock />}
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              marginRight: isMobile ? 0 : 250,
            }}>
            <Text style={{...h22_SemiBold, color: themeColor}}>
              Instant Patient
            </Text>
          </View>
          {/* <Cards eventDispatcher={eventDispatcher} /> */}
        </View>
      );
    },
  },
  'view-detail': {
    screen: ViewDetail,
  },
  videoSession: {
    screen: VideoCall,
    expanded: true,
    renderHeader: ({navigation}) => {
      const {
        state: {params: {item: {patient_id = {}, care_program} = {}} = {}} = {},
      } = navigation;
      if (getUser()?.userType === 'Patient') {
        return null;
      }
      return (
        <View
          style={{
            backgroundColor: 'white',
            alignItems: 'center',
            flexDirection: 'row',
            padding: 10,
            paddingLeft: 30,
            borderBottomWidth: 0.5,
            borderColor: borderColor,
            paddingRight: 30,
            justifyContent: 'space-between',
          }}>
          <Image
            source={brandImg[BRAND_NAME][0]}
            style={{width: 150, objectFit: 'contain'}}
          />
          {isTimerVisible() && care_program ? (
            <Timer type="consultation" patientId={patient_id?._id} />
          ) : (
            <View />
          )}
        </View>
      );
    },
  },
  videoSessionMobile: {
    screen: VideoCallMobile,
    expanded: true,
  },
  'sidePanel-view': {
    screen: SidePanel,
  },
  'past-notes': {
    screen: PastNotes,
  },
  'show-file': {
    screen: ImageViewer,
  },
};
