import React from 'react';

import './MemberGraphScreenHeader.css';

const MemberGraphScreenHeader = (props) => {
  // const {selectedYear, setSelectedYear= () => {}} = props;
  // let yearData = getLastYears();
  // yearData = [{label: 'All', value: 'All'}, ...yearData];
  // const onChangeValue = useCallback((value) => {
  //   setSelectedYear(value);
  // }, []);
  return (
    <div className="member-graph-header">
      <div>
        {/* <CustomDropDown selectedValue={selectedYear} onChangeValue={onChangeValue} data={yearData} /> */}
      </div>
    </div>
  );
};



export default MemberGraphScreenHeader;
