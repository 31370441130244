import React from 'react';
import Table from '../../app-components/table/Table';
import {TouchableOpacity, Text, View} from '../../app-components';
import {removeData} from '../../AppServices';
import {listUri} from '../../Queries';
import {colors} from '../../theme/colors';
import {
  h12_Regular,
  h14_AvenirNext,
  h14_Medium,
} from '../../theme/fonts';
const {backdropColor70} = colors;
let uri = () => {
  return listUri({model: 'specialityFee', id: 'feeQuery'});
};

const tableData = {
  lg: {
    columns: [
      {
        width: 50,
      },
      {
        render: (props) => {
          return <Text style={{...h12_Regular}}>{props.index + 1}</Text>;
        },
        header: 'S.No.',
        width: 150,
      },
      {
        type: 'text',
        field: 'name',
        header: 'Speciality Name',
        width: 300,
      },
      {
        type: 'text',
        field: 'country',
        header: 'Country',
        width: 300,
      },
      {type: 'text', field: 'fee', header: 'Fee', width: 300},
    ],
    hoverActions: [
      {
        value: 'Edit',
        align: 'right',
        textStyle: {
          color: '#9a9a9a',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'edit-countrywise-speciality-fee',
              params: {item},
            },
          };
        },
      },
      {
        value: 'Delete',
        position: 'center',
        align: 'right',
        textStyle: {
          color: 'rgb(241,79,79, 0.87)',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: {
          type: 'link',
          link: (props) => {
            let {item} = props;
            return {
              view: 'delete-masterData',
              modal: true,
              params: {
                item,
                label: 'Speciality',
                deleteAction: () => removeData({item, model: 'specialityFee'}),
                reloadEvent: 'reloadSpecialityFee',
              },
              modalProps: {
                autoHide: true,
                height: 250,
                backdropColor: backdropColor70,
              },
            };
          },
        },
      },
    ],
  },
  sm: {
    columns: [
      {
        render: (props) => {
          return <Text style={{...h12_Regular}}>{props.index + 1}</Text>;
        },
        header: 'S.No.',
        width: 50,
      },
      {
        type: 'text',
        field: 'name',
        header: 'Speciality',
      },
      {type: 'text', field: 'fee', header: 'Fee', width: 100},
      {
        type: 'moreActions',
        actions: [
          {
            text: 'Edit',
            textStyle: {
              color: '#9a9a9a',
              fontWeight: 500,
              ...h14_AvenirNext,
            },
            action: ({item}) => {
              return {
                type: 'link',
                link: {
                  view: 'edit-speciality',
                  params: {item},
                },
              };
            },
          },
          {
            text: 'Delete',
            textStyle: {
              color: 'rgb(241,79,79, 0.87)',
              fontWeight: 500,
              ...h14_AvenirNext,
            },
            action: {
              type: 'link',
              link: (props) => {
                let {item} = props;
                return {
                  view: 'delete-masterData',
                  modal: true,
                  params: {
                    item,
                    label: 'Speciality',
                    deleteAction: () =>
                      removeData({item, model: 'specialityFee'}),
                    reloadEvent: 'reloadSpecialityFee',
                  },
                  modalProps: {
                    autoHide: true,
                    height: 250,
                    backdropColor: backdropColor70,
                  },
                };
              },
            },
          },
        ],
      },
    ],
  },
};

export default Table({
  uri,
  ...tableData,
  reloadEvent: 'reloadSpecialityFee',
});
