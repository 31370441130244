import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  isMobile,
  Dimensions,
} from '../app-components';
import {
  Admin,
  FrontDesk,
  Doctor,
  Patient,
  Lab,
  nurse,
  dialysis,
  nurseLogin,
} from '../images';
import {h22_Regular} from '../theme/fonts';
import {BRAND_NAME} from '../Config';
import {brandImg, brandStyle} from '../IconStyleBrand';
import {setSelectedLoginType} from '../AppServices';
import {snakeCase} from 'lodash'
const {width, height} = Dimensions.get('window');
const Show = ({
  image,
  text,
  style,
  navigation,
  view,
  type,
  imageStyle = {},
}) => {
  return (
    <TouchableOpacity
      id={snakeCase(type)}
      onPress={() => {
        setSelectedLoginType(type);
        view &&
          navigation &&
          navigation.replace({
            view,
            params: {
              type,
            },
          });
      }}
      style={{
        alignItems: 'center',
        cursor: 'pointer',
        ...style,
      }}>
      <Image
        // id={type + 'Image'}
        style={{
          width: isMobile ? width * 0.8 : 220,
          // width: 250,
          height: isMobile ? height * 0.25 : 143,
          // height: 160,
          borderRadius: 20,
          borderWidth: 1,
          ...imageStyle,
        }}
        source={image}
      />
      <Text 
        id={snakeCase(type) + '_label'}
      style={{...h22_Regular, paddingTop: 5, color: '#939393'}}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const BeginerPage = ({navigation}) => {
  setSelectedLoginType('');
  return (
    <ScrollView
      style={{
        flex: 1,
      }}>
      <View
        style={{
          alignItems: 'center',
          justifyContent: isMobile ? undefined : 'center',
          paddingTop: 10,
          flex: 1,
        }}>
        <Image
          style={brandStyle[BRAND_NAME]['choose-user']}
          // source={hospitalLiveLogo}
          // source={StewardLogo}
          id="brand_logo"
          source={brandImg[BRAND_NAME][0]}
        />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginBottom: 20,
            flexWrap: 'wrap',
          }}>
          {Show({
            image: Admin,
            text: 'Clinic Admin',
            navigation,
            view: 'login',
            type: 'ClinicAdmin',
          })}
          {Show({
            image: FrontDesk,
            text: 'FrontDesk',
            navigation,
            view: 'login',
            style: {paddingLeft: isMobile ? undefined : 80},
            type: 'FrontDesk',
          })}
          {Show({
            image: Lab,
            text: 'Lab ',
            navigation,
            view: 'login',
            style: {paddingLeft: isMobile ? undefined : 80},
            // imageStyle:{height:180},
            type: 'LabAdmin',
          })}
          {Show({
            image: dialysis,
            text: 'Dialysis Admin',
            navigation,
            view: 'login',
            style: {paddingLeft: isMobile ? undefined : 80},
            // imageStyle:{height:180},
            type: 'DialysisAdmin',
          })}
        </View>
        {/* {Show({
          image: Lab,
          text: 'Lab ',
          navigation,
          view: 'login',
          // style: {paddingLeft: isMobile ? undefined : 80},
          // imageStyle:{height:180},
          type: 'FrontDesk',
        })} */}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            // paddingBottom: 40,
            flexWrap: 'wrap',
          }}>
          {Show({
            image: Doctor,
            text: 'Doctor',
            navigation,
            view: 'login',
            type: 'Doctor',
          })}
          {/* {Show({
            image: Patient,
            text: 'Patient',
            navigation,
            view: 'patient-signin',
            style: {paddingLeft: isMobile ? undefined : 80},
            type: 'Patient',
          })} */}
          {Show({
            image: nurse,
            text: 'Medical Assistant',
            navigation,
            view: 'login',
            style: {paddingLeft: isMobile ? undefined : 80},
            type: 'MedicalAssistant',
          })}
          {Show({
            image: nurseLogin,
            text: 'Nurse',
            navigation,
            view: 'login',
            style: {paddingLeft: isMobile ? undefined : 80},
            type: 'Nurse',
          })}
        </View>
      </View>
    </ScrollView>
    // </View>
  );
};

export default BeginerPage;
