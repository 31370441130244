import React from 'react';
import {
  View,
  Text,
  Linking,
  TouchableOpacity,
  Image,
} from '../../npms/react-core-components';
import Card from '../../npms/react-card';
import { Headline9 } from '../../theme/Headlines';
import { manazeV6Colors } from '../../theme/manazeV6Colors';
import { Avatar } from '../../npms/react-avatar';
import { resolveExp } from '../../npms/react-utility-functions';
import { DataCell, HeaderCell, SortableHeaderCell } from '../../npms/react-table';
import {
  textCellTheme,
  cardCellTheme,
  fileCellTheme,
  numberCellTheme,
  textHeaderCellTheme,
  numberHeaderCellTheme,
  customHeaderCellTheme,
} from '../../theme/tableTheme';
import { getDownloadUrl } from '../../AppServices';
import typeCast from './TypeCast';
import { OpenActions } from '../action/OpenActions';
import {
  checkSelectedIcon,
  moreOptionsIcon,
  unCheckboxIcon,
} from '../../images';
import { RowSelector, HeaderSelector } from './selector/Selector';
import { Switch } from '../../npms/react-toggle';

const { primary_2 } = manazeV6Colors;

export const AvatarRender = (props) => {
  let { column, item } = props;
  let {
    field,
    avatarField,
    size = 32,
    avatarIcon,
    value,
    resolveIcon,
    avatarTextStyle,
  } = column;
  if (avatarField === undefined) {
    avatarField = field;
  }
  if (typeof value === 'function') {
    value = value(props);
  } else if (value === undefined && avatarField) {
    value = resolveExp(item, avatarField);
  }
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  avatarTextStyle = {
    ...Headline9,
    color: primary_2,
    ...avatarTextStyle,
  };
  return (
    <Avatar
      size={size}
      icon={avatarIcon ? resolveExp(item, avatarIcon) : void 0}
      value={value || ''}
      resolveIcon={resolveIcon}
      textStyle={avatarTextStyle}
    />
  );
};

export const AvatarTextRender = (props) => {
  let { column: { avatarWidth = 60 } = {} } = props;
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View
        style={{
          alignItems: 'center',
          width: avatarWidth,
        }}>
        <AvatarRender {...props} />
      </View>
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <DataCell {...textCellTheme} {...props} />
      </View>
    </View>
  );
};

export const CardRender = (props) => {
  let { column = {}, ...restProps } = props;
  let { header, ...restColumn } = column;
  return <Card {...cardCellTheme} {...restColumn} {...restProps} />;
};

export const FileRender = (props) => {
  let { column = {}, item, ...restProps } = props;
  let { field } = column;
  let value = resolveExp(item, field);
  let { name, key } = value || {};
  let downloadUrl = getDownloadUrl({ name, key, openInNewTab: true });
  let { linkTextStyle } = fileCellTheme;

  return (
    <TouchableOpacity
      style={{ cursor: 'pointer' }}
      onPress={() => Linking.openURL(downloadUrl)}
      onClick={(e) => e && e.stopPropagation && e.stopPropagation()}>
      {<Text style={linkTextStyle}>{name || key || ''}</Text>}
    </TouchableOpacity>
  );
};

export const checkBoxRender = (props) => {
  let { column = {}, item } = props;
  let { field } = column;
  let value = resolveExp(item, field);
  return (
    <Image
      source={value ? checkSelectedIcon : unCheckboxIcon}
      style={{ width: 20, height: 20 }}
    />
  );
};

export const TextRender = (props) => {
  return DataCell({ ...textCellTheme, typeCast, ...props });
};

export const NumberRender = (props) => {
  return DataCell({ ...numberCellTheme, typeCast, ...props });
};

export const TextAreaRender = (props) => {
  return DataCell({ ...numberCellTheme, typeCast, html: true, ...props });
};

export const OpenActionsRender = (props) => {
  let { column, ...restProps } = props;
  return OpenActions({
    ...column,
    ...restProps,
    renderAction: (action) => {
      return <TextRender {...restProps} column={action} />;
    },
  });
};

export const MoreActionsRender = (props) => {
  let {
    column: { icon, iconStyle },
  } = props || {};
  iconStyle = iconStyle || { padding: 6, objectFit: 'contain' };
  return <Image source={icon || moreOptionsIcon} style={iconStyle} />;
};

export const CustomRender = (props) => {
  const { column: { render } = {} } = props;
  return render ? render(props) : null;
};

export const SelectionRender = (props) => {
  const { item, column } = props;
  const { field } = column || {};

  return (
    <RowSelector
      data={item}
      field={field}
      {...props}
      selectedIcon={checkSelectedIcon}
      unCheckboxIcon={unCheckboxIcon}
    />
  );
};

export const HeaderSelectionRender = (props) => {
  const { column: { field } = {} } = props || {};
  return (
    <HeaderSelector
      {...props}
      field={field}
      selectedIcon={checkSelectedIcon}
      unCheckboxIcon={unCheckboxIcon}
    />
  );
};

export const HeaderTextRender = (props) => {
  return HeaderCell({ ...textHeaderCellTheme, ...props });
};

export const HeaderNumberRender = (props) => {
  return HeaderCell({ ...numberHeaderCellTheme, ...props });
};

export const HeaderCustomRender = (props) => {
  return HeaderCell({ ...customHeaderCellTheme, ...props });
};
export const SwitchRender = (props) => {
  const { item } = props;
  return <Switch data={item} {...textCellTheme} {...props} />;
};
