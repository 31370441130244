import React from 'react';
import MainApp from './screens';
import {
  ReactEventDispatcher,
  AppKeyboardListener,
  ReactModalViewContextProvider,
  ReactModalView,
  isMobile,
} from './app-components';
import {GpsContext} from './withGpsContext';
import {gpsStore} from './AppServices';
import Toast from './app-components/toast/Toast';
import AppLoader from './app-components/loader/AppLoader';

export default class App extends React.Component {
  render() {
    let visibleViews = isMobile ? 1 : 2;
    return (
      <GpsContext.Provider value={gpsStore}>
        <ReactEventDispatcher>
          <AppKeyboardListener>
            <ReactModalViewContextProvider>
              <MainApp visibleViews={visibleViews} />
              <ReactModalView />
              <AppLoader />
              <Toast />
            </ReactModalViewContextProvider>
          </AppKeyboardListener>
        </ReactEventDispatcher>
      </GpsContext.Provider>
    );
  }
}
