import moment from 'moment';
import React from 'react';
import {
  View,
  TouchableOpacity,
  ScrollView,
  Image,
  Text,
  Dimensions,
  isMobile,
} from '../../app-components';
import {getFileExtension} from '../../app-components/UtilityFunctions';
import {getImageUrl} from '../../AppServices';
import {closeIcon, pdf} from '../../images';
import {h14_AvenirNext_Bold, h22_Medium} from '../../theme/fonts';

const PastNotes = (props) => {
  const {navigation} = props || {};
  const {state: {params: {dataArr = [], publisherViewWidth} = {}} = {}} =
    navigation || {};
  return (
    <ScrollView style={{flex: 1}}>
      <View style={{position: 'relative'}}>
        <TouchableOpacity
          style={{
            position: 'absolute',
            zIndex: 9,
            marginLeft: 10,
            cursor: 'pointer',
            top: 5,
            right: 5,
          }}
          onPress={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const {navigation = {}} = props;
            navigation.pop();
          }}>
          <Image source={closeIcon} style={{height: 25, width: 25}} />
        </TouchableOpacity>
        <Text style={{...h22_Medium, marginLeft: 10}}>Past Records</Text>
      </View>
      <View
        style={{
          padding: 10,
          flexDirection: 'row',
          flex: 1,
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        }}>
        {dataArr.map((item, index) => {
          return (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                padding: 4,
              }}>
              <TouchableOpacity
                style={{
                  height: 49,
                  width: 50,
                  marginLeft: 5,
                  borderRadius: 4,
                  cursor: 'pointer',
                }}
                title={moment(item?.date).format('MM/DD/YY')}
                onPress={(e) => {
                  console.log('vaaaaaaa');
                  e.stopPropagation();
                  e.preventDefault();
                  const {navigation} = props;

                  navigation.push({
                    view: 'pdfReader',
                    params: {
                      dataArr: [...dataArr],
                      prevNote: true,
                      clickedIndex: index,
                    },
                    modal: true,

                    modalProps: {
                      autoHide: true,
                      minHeight: 180,
                      position: 'screenRight',
                      style: {
                        height: '100%',
                        width: isMobile
                          ? '100%'
                          : Dimensions.get('window').width - publisherViewWidth,
                        zIndex: 9999,
                      },
                      backdropStyle: {},
                      // ...confirmModalProps,
                    },
                  });
                }}>
                {getFileExtension(item?.mayamd_note?.name) === 'pdf' ? (
                  <Image source={pdf} style={{height: 49, width: 50}} />
                ) : (
                  <Image
                    source={getImageUrl(item?.thumbnail)}
                    style={{height: 49, width: 50}}
                  />
                )}
              </TouchableOpacity>
              <Text
                style={{...h14_AvenirNext_Bold, marginLeft: 5, marginRight: 5}}>
                {moment(item?.date).format('MM/DD/YY')}
              </Text>
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default PastNotes;
