import React, {useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  isMobile,
  Image,
} from '../../app-components';
import {Multiselect} from 'multiselect-react-dropdown';
import {h14_AvenirNext, h15_Medium, h22_Medium} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import Health_in_General from './StaticData/Health_in_General.json';
import Ear_nose_mouth from './StaticData/Ear_nose_mouth.json';
import Heart_blood from './StaticData/Heart_blood.json';
import Stomach_intestines from './StaticData/Stomach_intestines.json';
import Kidney_bladder from './StaticData/Kidney_bladder.json';
import Muscles_bones from './StaticData/Muscles_bones.json';
import Respiratory from './StaticData/Lungs_breating.json';
import Skin_hair from './StaticData/Skin_hair.json';
import Neurologic from './StaticData/Neurologic.json';
import Psychiatric from './StaticData/Psychiatric.json';
import Endocrinologic from './StaticData/Endocrinologic.json';
import Hematologic from './StaticData/Hematologic.json';
import Allergic from './StaticData/Allergic.json';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {attachmentIcon, crossPdf, pdf} from '../../images';
import {getFileExtension} from '../../app-components/UtilityFunctions';
import {getImageUrl} from '../../AppServices';

import {
  TextAreaInput,
  UploadImage,
} from '../../app-components/input-components/Editors';

import MayaAiSymptomNonEditable from './MayaAiSymptomNonEditable';

const {themeTextColor, themeContainer, themeColor} = colors;
const PatientAppointmentDetails = (props) => {
  const {
    navigation = {},
    t = (text) => text,
    isDoctorView = false,
    data = {},
    setValue: setValueContext = () => {},
  } = props || {};
  const {state: {params = {}} = {}} = navigation || {};
  const {selectdDate, selectedTimeSlot, item} = params;

  const [file, setFile] = useState([]);
  const [illness_description, setIllness_description] = useState('');
  const [appointmentData, setAppointmentData] = useState({
    general_health: [],
    ears_mouth: [],
    heart_blood: [],
    stomach_intestines: [],
    kidney_bladder: [],
    muscles_bones: [],
    respiratory: [],
    skin_hair: [],
    neurologic: [],
    psychiatric: [],
    endocrinologic: [],
    hematologic: [],
    allergic: [],
  });

  let medical_Report_Slice = isDoctorView ? data['medical_report'] : file;
  let medical_Report_Slice_Second = [];
  const IconLength = 4;
  if (isDoctorView) {
    if (
      Array.isArray(data['medical_report']) &&
      data['medical_report'].length > IconLength
    ) {
      medical_Report_Slice = data['medical_report'].slice(0, IconLength);
      medical_Report_Slice_Second = data['medical_report'].slice(
        IconLength,
        file.length,
      );
    }
  } else {
    if (file.length > IconLength) {
      medical_Report_Slice = file.slice(0, IconLength);
      medical_Report_Slice_Second = file.slice(IconLength, file.length);
    }
  }
  const imageView = () => {
    navigation.push({
      view: 'pdfReader',
      params: {
        dataArr: isDoctorView ? data['medical_report'] : file,
      },
      modal: true,

      modalProps: {
        autoHide: true,
        minHeight: 180,
        height: 600,
        width: isMobile ? 300 : 700,
        position: 'screenCenter',
        screenCenterStyle: {
          flex: 1,
          overflow: 'hidden',
          borderRadius: 8,
          maxHeight: '90%',
          maxWidth: '90%',
          zIndex: 12,
        },
      },
    });
  };

  const setValue = (value) => {
    setFile(value);
  };

  const removeElement = (fileIndex) => {
    const fileCopy = [...file];
    const updatedFile = fileCopy.filter((item, index) => index !== fileIndex);
    // console.log(updatedFile);
    setFile([...updatedFile]);
  };
  return (
    <View
      style={{
        flex: 1,
        marginTop: isDoctorView ? 0 : 12,
        paddingLeft: isDoctorView ? 10 : undefined,
        overflow: 'hidden',
      }}>
      <ScrollView
        style={{
          borderRadius: 10,
          backgroundColor: isDoctorView ? undefined : themeContainer,
          padding: 10,
          marginBottom: 5,
          flex: 1,
          paddingTop: isDoctorView ? 0 : 10,
        }}>
        {isDoctorView ? (
          void 0
        ) : (
          <>
            <TouchableOpacity
              style={{cursor: 'pointer'}}
              onPress={() => {
                props.navigation.push({
                  view: 'after-booking-slot',
                  params: {selectdDate, selectedTimeSlot, item},
                });
              }}>
              <Text
                style={{
                  ...h14_AvenirNext,
                  lineHeight: 21,
                  letterSpacing: 0.5,
                  // color: '#383838',
                  color: themeTextColor,
                  // opacity: 0.54,
                }}>
                {'< '} {t('Back')}
              </Text>
            </TouchableOpacity>
            <Text style={{...h22_Medium, color: '#fff'}}>Review of System</Text>
          </>
        )}
        {/* <Multiselect /> */}
        {isDoctorView ? (
          void 0
        ) : (
          <MayaAiSymptomNonEditable
            mayaAISessionId={item?.mayaAISessionId || null}
          />
        )}
        {[
          {
            lable: 'Health in General',
            data: Health_in_General,
            key: 'general_health',
          },
          {
            lable: 'Ears, Nose, Mouth & Throat',
            data: Ear_nose_mouth,
            key: 'ears_mouth',
          },
          {
            lable: 'Heart & Blood Vessels',
            data: Heart_blood,
            key: 'heart_blood',
          },
          {
            data: Stomach_intestines,
            lable: 'Stomach & Intestines',
            key: 'stomach_intestines',
          },
          {
            data: Kidney_bladder,
            lable: 'Kidney & Bladder',
            key: 'kidney_bladder',
          },
          {
            key: 'muscles_bones',
            data: Muscles_bones,
            lable: 'Muscles, Bones, Joints',
          },
          {
            key: 'respiratory',
            data: Respiratory,
            lable: 'Lungs & Breathing',
          },
          {
            key: 'skin_hair',
            data: Skin_hair,
            lable: 'Skin, Hair & Breast',
          },
          {
            key: 'neurologic',
            data: Neurologic,
            lable: 'Neurologic (Brain & Nerves)',
          },
          {
            key: 'psychiatric',
            data: Psychiatric,
            lable: 'Psychiatric (Mood & Thinking)',
          },
          {
            key: 'endocrinologic',
            data: Endocrinologic,
            lable: 'Endocrinologic (Glands)',
          },
          {
            key: 'hematologic',
            data: Hematologic,
            lable: 'Hematologic (Blood/Lymph)',
          },
          {
            key: 'allergic',
            data: Allergic,
            lable: 'Allergic/Immunologic',
          },
        ].map((item) => {
          return (
            <View>
              <Text
                style={{
                  ...h15_Medium,
                  marginTop: 10,
                  marginBottom: 10,
                  color: isDoctorView ? '#383838' : '#fff',
                }}>
                {item?.lable}
              </Text>
              <Multiselect
                options={item?.data} // Options to display in the dropdown
                selectedValues={
                  isDoctorView
                    ? data && data[item?.key]
                      ? data[item?.key]
                      : []
                    : appointmentData[item?.key]
                } // Preselected value to persist in dropdown
                // showCheckbox={true}
                style={{
                  searchBox: {
                    backgroundColor: '#fff',
                  },
                  option: {
                    // display: "flex"
                  },
                }}
                avoidHighlightFirstOption={true}
                isObject={false}
                closeOnSelect={false}
                onSelect={(value, tempValue) => {
                  if (isDoctorView) {
                    // let tempValue =
                    //   Array.isArray(value) && value[value.length - 1];
                    if (tempValue === 'None of the above') {
                      setValueContext({
                        field: item?.key,
                        data,
                        value: ['None of the above'],
                      });
                    } else if (
                      data &&
                      data[item.key] &&
                      Array.isArray(data[item?.key]) &&
                      data[item?.key].includes('None of the above')
                    ) {
                      setValueContext({
                        field: item?.key,
                        data,
                        value: [tempValue],
                      });
                    } else {
                      setValueContext({field: item?.key, data, value});
                    }
                  } else {
                    let a = {...appointmentData};
                    // let tempValue =
                    //   Array.isArray(value) && value[value.length - 1];
                    if (tempValue === 'None of the above') {
                      a[item?.key] = ['None of the above'];
                    } else if (a[item?.key].includes('None of the above')) {
                      // a[item?.key] = [];
                      a[item?.key] = [tempValue];
                    } else {
                      a[item?.key] = value;
                    }
                    setAppointmentData(a);
                  }
                }} // Function will trigger on select event
                onRemove={(value) => {
                  if (isDoctorView) {
                    setValueContext({field: item?.key, data, value});
                  } else {
                    let a = {...appointmentData};
                    a[item?.key] = value;
                    setAppointmentData(a);
                  }
                }} // Function will trigger on remove event
                // displayValue="name"
              />
            </View>
          );
        })}
        <Text
          style={{
            ...h15_Medium,
            marginTop: 10,
            marginBottom: 10,
            color: isDoctorView ? '#383838' : '#fff',
          }}>
          Additional information
        </Text>
        <View
          style={{
            borderWidth: 1,
            borderColor: isDoctorView ? 'gray' : '#5ccfe9',
            borderRadius: 4,
            // marginTop: 15,
            backgroundColor: '#fff',
          }}>
          <TextAreaInput
            style={{
              ...h14_AvenirNext,
              color: 'rgba(56, 56, 56, 0.7)',
              marginTop: 15,
              marginBottom: 20,
            }}
            minHeight={50}
            maxHeight={50}
            variant={'filled'}
            placeholder={t('additionalInfo')} //"Additional information for clinician"
            value={
              isDoctorView ? data['illness_description'] : illness_description
            }
            onChangeText={(value) => {
              if (isDoctorView) {
                setValueContext({field: 'illness_description', data, value});
              } else {
                setIllness_description(value);
              }
            }}
          />
        </View>
        {isDoctorView ? (
          void 0
        ) : (
          <View
            style={{
              marginTop: 15,
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}>
            <Text
              style={{
                ...h14_AvenirNext,
                color: '#51cdc0',
              }}>
              {t('Add Attachments')}
            </Text>
            <UploadImage
              imageOnly={false}
              imageWidth={25}
              imageHeight={25}
              minHeight={28}
              onChangeValue={setValue}
              value={file}
              defaultImage={attachmentIcon}
              uploadOptions={{s3: true}}
              multiple={true}
            />
          </View>
        )}
        <View
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}>
          <View
            style={{
              flexDirection: 'row',
              // backgroundColor: '#fff',
            }}>
            <View>
              <View style={{flexDirection: 'row'}}>
                {Array.isArray(medical_Report_Slice)
                  ? medical_Report_Slice.map((item, index) => (
                      <TouchableOpacity
                        style={{
                          marginRight: 19,
                        }}
                        onPress={() => imageView()}>
                        {getFileExtension(item.name) === 'pdf' ? (
                          <Image source={pdf} />
                        ) : (
                          <Image
                            source={getImageUrl(item?.thumbnail)}
                            style={{
                              height: 49,
                              width: 50,
                            }}
                          />
                        )}
                        <TouchableOpacity
                          style={{
                            zIndex: 9,
                            top: -6,
                            right: -6,
                            position: 'absolute',
                          }}
                          onPress={(e) => {
                            e.stopPropagation();
                            removeElement(index);
                          }}>
                          <Image
                            source={crossPdf}
                            style={{
                              height: 16,
                              width: 16,
                            }}
                          />
                        </TouchableOpacity>
                      </TouchableOpacity>
                    ))
                  : null}
                {Array.isArray(medical_Report_Slice_Second) &&
                medical_Report_Slice_Second.length ? (
                  <TouchableOpacity
                    style={{
                      height: 49,
                      width: 50,
                      marginLeft: 5,
                      borderRadius: 4,
                      justifyContent: 'center',
                      backgroundColor: '#383838',
                      alignItems: 'center',
                    }}
                    onPress={() => imageView()}>
                    <Text style={{color: '#fff'}}>
                      {'+' + medical_Report_Slice_Second.length}
                    </Text>
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      {isDoctorView ? (
        void 0
      ) : (
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            navigation.replace({
              view: 'patient-payment',
              // view:"patient-appointment-details",
              params: {
                ...params,
                appointmentData: {
                  ...appointmentData,
                  medical_report: file,
                  illness_description,
                },
                // data,
              },
            });
          }}>
          <GradientButton label={t('Next')} />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default PatientAppointmentDetails;
