import {save} from '../../../app-components/action/SaveAction';
import {cancelButton} from '../../../app-components/action/CloseAction';
import RpmLibraryForm from './RpmLibraryForm';
let header = {
  title: 'Copy Care Program',
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },
  actions: [cancelButton(), save()],
};
let editable = true;
let columnsPerRow = 2;
export default RpmLibraryForm({
  header,
  editable,
  columnsPerRow,
  closeView:2,
  openProgramDetails: true,
});
