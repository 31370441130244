import React from 'react';
import {Text, TouchableOpacity} from '../../../app-components';
import Table from '../../../app-components/table/Table';
import {resolvePatientName} from '../../../app-components/patientUtility';
import {getUser, secondsToHms} from '../../../AppServices';
import {serialNumber} from '../rpmUtils';
const getUserId = () => {
  const {doctor, userType, medicalAssistant, nurse} = getUser();
  switch (userType) {
    case 'Doctor':
      return {doctor};
    case 'MedicalAssistant':
      return {medicalAssistant};
    case 'Nurse':
      return {nurse};
    default:
      return null;
  }
};
const uri = ({navigation}) => {
  const {state: {params: {item = {}} = {}} = {}} = navigation;
  const {patient} = item;
  return {
    query: {
      id: 'timerLogLists',
      addOnFilter: {
        ...getUserId(),
        patient,
      },
    },
    model: 'AnalysisTimerLog',
  };
};
export default Table({
  uri,
  reloadEvent: 'reloadTimer',
  reloadOnChangeEvent: 'reloadTimer',
  columns: [
    {
      render: serialNumber,
      header: 'S.No.',
      width: 150,
    },
    // {
    //   header: 'Patient Name',
    //   type: 'text',
    //   field: (data) => {
    //     return resolvePatientName(data?.patient);
    //   },
    //   // width: 200,
    // },
    // {
    //   header: 'Date Of Birth',
    //   type: 'date',
    //   field: 'patient.birthDate',
    //   format: 'DD MMM YYYY',

    // },
    {
      type: 'text',
      header: 'Tele Consultation',
      field: (data) => {
        return secondsToHms(data?.consultationTime);
      },
      visible: () => {
        const {userType = ''} = getUser() || {};
        if (userType === 'Nurse' || userType == 'MedicalAssistant')
          return false;
        return true;
      },
    },
    {
      type: 'text',
      header: 'Chat',
      field: (data) => {
        return secondsToHms(data?.chatDuration);
      },
    },
    {
      type: 'text',
      header: 'Data Analysis',
      field: (data) => {
        return secondsToHms(data?.patientAnalysisTime);
      },
    },
    {
      type: 'text',
      header: 'Manual',
      field: (data) => {
        return secondsToHms(data?.manualDuration);
      },
    },
    {
      type: 'text',
      header: 'Reason',
      field: (data) => {
        const {
          patientAnalysisTime = 0,
          chatDuration = 0,
          consultationTime = 0,
        } = data || {};

        if (patientAnalysisTime) {
          return 'Data Analysis';
        } else if (chatDuration) {
          return 'Chat';
        } else if (consultationTime) {
          return 'Tele Consultation';
        } else {
          return data?.reason || '';
        }
      },
    },
    // {
    //   type: 'text',
    //   header: 'Total',
    //   field: (data) => {
    //     const {
    //       patientAnalysisTime = 0,
    //       chatDuration = 0,
    //       manualDuration = 0,
    //       consultationTime = 0,
    //     } = data || {};
    //     const seconds =
    //       patientAnalysisTime +
    //       chatDuration +
    //       consultationTime +
    //       manualDuration;
    //     return secondsToHms(seconds);
    //   },
    // },
  ],
});
