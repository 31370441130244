import DrugForm from './DrugForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

const header = {
  title: 'Add Drug',
  actions: [cancelButton(), save()],
};

export default DrugForm({header});
