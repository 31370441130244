import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import ProcedureForm from './ProcedureForm';

let header = {
  title: 'Add Procedure',
  actions: [cancelButton(), save()],
};

export default ProcedureForm({header});
