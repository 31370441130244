import TabNavigator from '../../app-components/tab/TabNavigator';
import UpcommingSchedule from './UpcommingSchedule';
import HistorySchedule from './HistorySchedule';

import {colors} from '../../theme/colors';
import {h16_Regular} from '../../theme/fonts';

const {themeColor, textColor70} = colors;

export const tabTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 70,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: '#fff',
    },
    tabRowStyle: {
      flex: 1,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      marginLeft: 15,
      marginRight: 15,
      marginTop: 14,
      marginBottom: 14,
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeBorderStyle: {
      height: 3,
      borderRadius: 2,
      width: 42,
      backgroundColor: themeColor,
      position: 'absolute',
      bottom: 0,
    },
    itemContainerStyle: {flexDirection: 'row', alignItems: 'center'},
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...h16_Regular,
      color: textColor70,
    },
    activeTextStyle: {
      ...h16_Regular,
      color: themeColor,
    },
    countStyle: {
      marginLeft: 3,
      ...h16_Regular,
      color: textColor70,
    },
    activeCountStyle: {
      color: themeColor,
    },
  },
};

export default TabNavigator({
  theme: tabTheme,
  tabs: {
    upcoming: {
      label: 'Upcoming',
      screen: UpcommingSchedule,
    },
    history: {
      label: 'History',
      screen: HistorySchedule,
    },
  },
});
