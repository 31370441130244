import React from 'react';
import {View, Text, TouchableOpacity, isMobile} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {h16_Regular} from '../../theme/fonts';

function SucessModal(props) {
  const {navigation} = props;
  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: isMobile ? '100%' : 500,
        padding: 20,
        alignItems: 'center',
      }}>
      <Text style={{marginBottom: 40, marginTop: 20, ...h16_Regular}}>
        Follow-up request send successfully
      </Text>
      <TouchableOpacity
        style={{cursor: 'pointer', width: 250}}
        onPress={() => {
          navigation.pop();
        }}>
        <GradientButton label="OK" />
      </TouchableOpacity>
    </View>
  );
}

export default SucessModal;
