import SettingForm from './SettingForm';
import {EditSettingDetail} from '../../Queries';

import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
let header = {
  title: 'Edit Setting',
  actions: [cancelButton(), save()],
};

export default SettingForm({uri: EditSettingDetail, header});
