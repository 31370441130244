import Form from '../../../app-components/form/Form';
import {submit} from '../../../AppServices';

const uri = (props) => {
  const {navigation} = props;
  const {state: {params: {villageId = {}} = {}} = {}} = navigation;

  let addOnFilter = {};
  if (villageId) {
    addOnFilter = {
      _id: villageId,
    };
  }
  return {
    query: {
      id: 'villageList',
      addOnFilter: addOnFilter,
      sort: {_id: -1},
    },
    model: 'Villages',
  };
};

export default ({header, editable, closeView}) => {
  return Form({
    uri,
    reloadEvent: 'reloadPatient',
    reloadOnChangeEvent: 'reloadPatient',
    expandSingleFormgroup: true,
    type: 'standardShadow',
    closeView: closeView,
    fieldVariant: 'filled',
    onSubmit: submit({
      model: 'Villages',
      recordActivity: true,
    }),
    mandatory: {
      name: 'Name is mandatory',
    },
    header: header,
    formGroups: [
      {
        editable: editable,
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'text',
                label: 'Name',
                field: 'name',
              },
              {
                type: 'text',
                label: 'Code',
                field: 'code',
                editable: false,
              },
            ],
          },
        ],
      },
    ],
  });
};
