import React from 'react';
export default () => {
  class CustomFormGroup extends React.Component {
    render() {
      const {render, ...rest} = this.props;
      return render(rest);
    }
  }
  return CustomFormGroup;
};
