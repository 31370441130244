import React from 'react';
import TabNavigator from '../../app-components/tab/TabNavigator';
import EnrolledClinics from './EnrolledClinics';
import {GradientButton} from '../../app-components/buttons/Buttons';
import PendingRequests from './PendingRequests';
import {countUri} from '../../Queries';
import {organisationNameFilter} from '../../app-components/filter/MayaMDFilters';
// countUri: countUri({
//   model: 'Procedures',
//   id: 'procedureLists',
// })

export default TabNavigator({
  actions: [
    {
      render: () => {
        return <GradientButton label={'Add Clinic'} />;
      },
      type: 'link',
      link: {
        view: 'add-clinic',
      },
    },
  ],
  tabs: {
    'pending-request': {
      label: 'Pending Request',
      screen: PendingRequests,
      reloadEvent: 'reloadClinic',

      countUri: countUri({
        model: 'Organizations',
        id: 'organizationLists',
        filter: {status: 'pending'},
      }),
    },
    'enrolled-clinics': {
      label: 'Enrolled Clinics',
      reloadEvent: 'reloadClinic',

      countUri: countUri({
        model: 'Organizations',
        id: 'organizationLists',
        filter: {status: 'approved'},
      }),
      // count: true,
      screen: EnrolledClinics,
      screenProps: {
        header: {
          actions: [{render: organisationNameFilter}],
        },
      },
    },
  },
});
