import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from '../../../app-components';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import {h16_Regular} from '../../../theme/fonts';
import {TextInput} from '../../../npms/react-text-input';
import {post} from '../../../AppServices';

function RazorpayModal(props) {
  const {navigation = {}} = props;
  const {state: {params = {}} = {}} = navigation;
  // console.log(`>@>Puru ~ file: RazorpayModal.js ~ line 10 ~ params`, params);
  const [account, setAccount] = useState('');
  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: 500,
        padding: 20,
        // alignItems: 'center',
      }}>
      <Text style={{marginBottom: 10, marginTop: 10, ...h16_Regular}}>
        Enter Your Account Id
      </Text>
      <TextInput
        value={account}
        // renderInputError={
        //   error?.type === 'old'
        //     ? () => {
        //         return <View></View>;
        //       }
        //     : false
        // }
        // error={error?.type === 'old' ? error?.value : ''}
        // inputProps={{
        //   type: 'password',
        // }}
        onChangeText={(value) => {
          // setError({});
          setAccount(value);
        }}
        variant="filled"
        placeholder={'Enter Account Id'}
      />
      <TouchableOpacity
        style={{
          cursor: 'pointer',
          marginTop: 30,
          width: 300,
          alignSelf: 'center',
        }}
        onPress={() => {
          post({
            data: {...params},
            updates: {
              account_id: account,
              account_status: 'linked',
            },
            model: 'Organizations',
          }).then((res) => {
            navigation.replace({
              view: 'clinic-detail',
              params: {account_status: true, item: {...params}},
            });
          });
        }}>
        <GradientButton label="OK" />
      </TouchableOpacity>
    </View>
  );
}

export default RazorpayModal;
