import React, {Component} from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableHighlight,
  ActivityIndicator,
  TextInput,
  FlatList,
  Image,
  Keyboard,
} from '../../npms/react-core-components';
import PropTypes from 'prop-types';
import {newSearchIcon} from '../../images';

const TOUCHABLE_ELEMENTS = [
  'TouchableHighlight',
  'TouchableOpacity',
  'TouchableWithoutFeedback',
  'TouchableNativeFeedback',
];

/**
 * Structure
 * 
View(style,theme.container)
	TouchableOpacity(theme.button)
		{children} ||
		View (theme.buttonContainer}
			View(theme.buttonTextView)
				Text (theme.buttonText)
				TextInput(theme.buttonTextInput)
			{loading && <ActivityIndicator size="small" />}
			showArrow && <View style={theme.iconViewStyle}>
					<Image source={this.props.arrowDownIcon}
                  		style={theme.iconStyle} />
                  		</View>


DropDown
	when no view
	View {theme.noDataView}
	FlatList{theme.list}		
		Row -> <TouchableOpacity><Text>(theme.rowText, highlighted && theme.highlightedRowText)

    Separator - <View style={this.props.theme.separator} key={key} />;
    

 */
export default class AutosuggestInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,

    placeholder: PropTypes.string,
    valueField: PropTypes.string,
    sugestionField: PropTypes.string,
    options: PropTypes.array,
    field: PropTypes.string,
    minChar: PropTypes.number,

    accessible: PropTypes.bool,
    searching: PropTypes.bool,
    animated: PropTypes.bool,
    showsVerticalScrollIndicator: PropTypes.bool,
    keyboardShouldPersistTaps: PropTypes.string,

    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    theme: PropTypes.oneOfType([PropTypes.object]),

    adjustFrame: PropTypes.func,
    renderRow: PropTypes.func,
    renderSeparator: PropTypes.func,
    renderButtonText: PropTypes.func,
    renderNoData: PropTypes.func,
    navigation: PropTypes.object,

    onDropdownWillShow: PropTypes.func,
    onDropdownWillHide: PropTypes.func,
    onItemSelect: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    placeholder: 'Please select...',
    options: null,
    animated: true,
    showsVerticalScrollIndicator: true,
    keyboardShouldPersistTaps: 'always',
    field: 'null',
    searching: false,
    minChar: 0,
  };

  constructor(props) {
    super(props);
    this.typing = '';
    this._button = null;
    this._buttonFrame = null;
    this.state = this.getDefaultState(props);
  }
  getDefaultState = (props) => {
    return {
      accessible: !!props.accessible,
      loading: !props.options,
      buttonText: props.placeholder,
      renderOptions: null,
      data: null,
    };
  };

  componentDidMount() {
    const {options} = this.props;
    this.setState({loading: !options});
    this.props.fetchOnMount && this.fetchSuggestion();
  }

  updateSuggestions = (data) => {
    this.setState({data});
  };
  fetchSuggestion = (searchValue) => {
    const {fetch, options} = this.props;

    this.setState({toggled: !this.state.toggled});
    let result;
    if (options && Array.isArray(options)) {
      result = options;
    } else if (fetch) {
      this.fetching = true;
      result = fetch({
        searchValue,
      });
    } else {
      console.warn(
        'Autosuggest input neither fetch provided nor options (in array )',
      );
    }

    if (result && result instanceof Promise) {
      result
        .then((data) => {
          this.fetching = false;
          this.updateSuggestions(data);
        })
        .catch((e) => {
          this.fetching = false;
          console.error('Error in fetching in autosuggest input', e);
        });
    } else if (result) {
      this.fetching = false;
      this.updateSuggestions(result);
    }

    return;
  };

  _renderSearchInput() {
    const {placeholder, theme} = this.props;

    return (
      <TouchableOpacity style={[theme.buttonContainer]} onPress={() => {}}>
        <View style={[theme.buttonTextView]}>
          <TextInput
            style={[theme.buttonTextInput]}
            value={this.typing || ''}
            placeholder={placeholder}
            onChangeText={(text) => {
              this._dropdown_search_textchange(text);
            }}
            onBlur={() => (this.typing = '')}
          />
          <View style={[theme.iconViewStyle]}>
            <Image source={newSearchIcon} style={theme.iconStyle} />
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  _renderLoading = () => {
    return <ActivityIndicator size="small" />;
  };

  _renderDropdown() {
    const {
      renderSeparator,
      showsVerticalScrollIndicator,
      renderNoData,
      navigation,
      keyExtractor,
      theme,
    } = this.props;
    let {keyboardShouldPersistTaps} = this.props;
    if (keyboardShouldPersistTaps === undefined) {
      keyboardShouldPersistTaps = 'always';
    }

    let {data} = this.state;
    if (!data || !data.length) {
      if (this.loading) {
        return null;
      }

      if (renderNoData && this.typing.length) {
        return (
          <View style={theme.noDataView}>
            {renderNoData({
              navigation,
              searchValue: this.typing,
            })}
          </View>
        );
      }
    } else {
      return (
        <FlatList
          ref={(index) => (this.FlatList = index)}
          style={[theme.list]}
          data={data}
          renderItem={this._renderRow}
          ItemSeparatorComponent={renderSeparator || this._renderSeparator}
          keyExtractor={keyExtractor}
          showsHorizontalScrollIndicator={showsVerticalScrollIndicator}
          keyboardShouldPersistTaps={keyboardShouldPersistTaps}
        />
      );
    }
  }

  _renderRow = ({item, index}) => {
    const {
      renderRow,
      accessible,
      sugestionField,
      isHighlighted,
      theme,
      showAvatar,
    } = this.props;

    let highlighted = isHighlighted && isHighlighted({item: item, index});

    const row = !renderRow ? (
      <View style={{flex: 1, flexDirection: 'row', marginLeft: 5}}>
        <Text style={[theme.rowText, highlighted && theme.highlightedRowText]}>
          {sugestionField ? item[sugestionField] : item}
        </Text>
      </View>
    ) : (
      renderRow({item, index, highlighted})
    );
    const preservedProps = {
      accessible,
      onPress: () => this._onRowPress({item, index}),
    };
    if (TOUCHABLE_ELEMENTS.find((name) => name == row.type.displayName)) {
      const props = {...row.props};
      props.key = preservedProps.key;
      props.onPress = preservedProps.onPress;
      const {children} = row.props;
      switch (row.type.displayName) {
        case 'TouchableHighlight': {
          return <TouchableHighlight {...props}>{children}</TouchableHighlight>;
        }
        case 'TouchableOpacity': {
          return <TouchableOpacity {...props}>{children}</TouchableOpacity>;
        }
        case 'TouchableWithoutFeedback': {
          return (
            <TouchableWithoutFeedback {...props}>
              {children}
            </TouchableWithoutFeedback>
          );
        }
        case 'TouchableNativeFeedback': {
          return (
            <TouchableNativeFeedback {...props}>
              {children}
            </TouchableNativeFeedback>
          );
        }
        default:
          break;
      }
    }

    return <TouchableHighlight {...preservedProps}>{row}</TouchableHighlight>;
  };

  _dropdown_search_textchange = (text) => {
    this.typing = text;
    let {minChar} = this.props;
    if (!minChar || (text && text.length >= minChar)) {
      this.fetchSuggestion(text);
    } else {
      this.setState({});
    }
  };
  _onRowPress({item, index}) {
    const {onItemSelect} = this.props;
    if (onItemSelect) {
      let value = onItemSelect({item, index, searchValue: this.typing});
      if (value) {
        this.setState({});
      }
    }
    Keyboard && Keyboard.dismiss();
  }

  _renderSeparator = (rowID) => {
    const key = `spr_${rowID}`;
    return <View style={this.props.theme.separator} key={key} />;
  };

  render() {
    const {style, theme, searching} = this.props;
    return (
      <View style={[theme.container, style]}>
        {searching && this._renderSearchInput()}
        {this._renderDropdown()}
      </View>
    );
  }
}
