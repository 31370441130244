import React, {useCallback} from 'react';
import {isEmpty} from 'lodash';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from '../../../../app-components';
import {h15_SemiBold, h16_Medium} from '../../../../theme/fonts';
import {colors} from '../../../../theme/colors';

const SymptomCheckerHistory = (props) => {
  const {symptomChecker = []} = props || {};

  const RenderCard = useCallback((cardProps) => {
    const {title = '', data = []} = cardProps || {};
    if (isEmpty(data)) {
      return null;
    }
    return (
      <View style={styles.cardContainer}>
        <TouchableOpacity style={styles.cardLabelContainer}>
          <Text style={styles.cardTitle}>{title}</Text>
        </TouchableOpacity>
        <RenderItem data={data} />
      </View>
    );
  }, []);

  return (
    <View style={{flex: 1}}>
      <ScrollView style={{maxHeight: 300}}>
        {!isEmpty(symptomChecker)
          ? symptomChecker?.map((item, index) => (
              <View key={index}>
                <RenderCard title={item?.title} data={item?.data} />
              </View>
            ))
          : null}
      </ScrollView>
    </View>
  );
};

const RenderItem = ({data = []}) => {
  return (
    <View>
      {!isEmpty(data) &&
        data?.map((items, index) => {
          return (
            <View style={styles.sectionItemContainer}>
              <View
                style={{
                  flexDirection: 'row',
                  paddingHorizontal: 10,
                }}
                key={index.toString()}>
                {items?.name ? (
                  <>
                    <Text style={{...styles.text, flex: 1}}>
                      {items?.name || ''}
                    </Text>
                    <Text style={{...styles.text, flex: 1}}>
                      {items?.value || ''}
                    </Text>
                  </>
                ) : (
                  <Text style={{...styles.text, flex: 1}}>
                    {items?.value || ''}
                  </Text>
                )}
              </View>
            </View>
          );
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  sectionItemContainer: {padding: 8},
  text: {
    ...h16_Medium,
    color: colors.blackColor,
    textTransform: 'capitalize',
    opacity: 0.8,
  },
  cardContainer: {
    flexDirection: 'column',
    borderRadius: 10,
    margin: 15,
    backgroundColor: colors.sectionBodyBgColor,
    shadowColor: colors.grayColor,
  },
  cardLabelContainer: {
    flex: 1,
    backgroundColor: '#CFEBFC',
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  cardTitle: {
    padding: 14,
    ...h15_SemiBold,
    color: colors.sectionTitleTextColor,
    width: '90%',
  },
});

export default SymptomCheckerHistory;
