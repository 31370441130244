import React from 'react';
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  isMobile,
  Dimensions,
} from '../../app-components';
import Table from '../../app-components/table/Table';
import {
  h11_Medium,
  h13_Regular,
  h14_Regular,
  h14_AvenirNext,
  h14_SemiBold,
} from '../../theme/fonts';
import moment from 'moment';
import {femaleSign, maleSign, userIcon} from '../../images';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {getImageUrl, post} from '../../AppServices';
import {getAge} from '../../app-components/UtilityFunctions';
import {isEmpty} from 'lodash';
import {FileInput} from '../../app-components/input-components/Editors';
import { resolvePatientName } from '../../app-components/patientUtility';
const {width} = Dimensions.get('window');

export default ({uri}) => {
  const appointmentTiming = ({item}) => {
    return (
      <View>
        <Text style={{...h13_Regular, lineHeight: 21, letterSpacing: 0.46}}>
          {moment(item?.time_slot?.start_time).format('MMM DD | hh:mm A')}
        </Text>
      </View>
    );
  };

  const patientName = ({item}) => {
    let imageUrl = item?.patient_id?.profile_picture;
    imageUrl = imageUrl && getImageUrl(imageUrl?.thumbnail);
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <View
          style={{
            alignItems: 'center',
            position: 'relative',
          }}>
          <Image
            source={imageUrl || userIcon}
            style={{
              height: isMobile ? 25 : 40,
              width: isMobile ? 25 : 40,
              borderRadius: 20,
              // objectFit:"contain"
            }}
          />
          {/* Availablity Button */}
        </View>

        <View style={{padding: 10}}>
          <Text
            style={{
              ...h14_AvenirNext,
              lineHeight: 21,
              letterSpacing: 0.57,
              numberOfLines: 1,
            }}
            numberOfLines={1}>
            {resolvePatientName(item?.patient_id)}
          </Text>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            {item?.patient_id?.gender === 'male' ? (
              <Image
                source={maleSign}
                style={{marginRight: 5}}
                height={15}
                width={15}
              />
            ) : item?.patient_id?.gender === 'female' ? (
              <Image
                source={femaleSign}
                style={{marginRight: 5}}
                height={15}
                width={15}
              />
            ) : null}

            <Text style={{...h13_Regular, lineHeight: 21, letterSpacing: 0.57}}>
              {item?.patient_id?.gender &&
                item?.patient_id?.gender[0].toUpperCase()}
              {item?.patient_id?.birthDate && ' | ' + getAge(item?.patient_id?.birthDate)}
            </Text>
          </View>
          <Text
            style={{
              ...h14_AvenirNext,
              lineHeight: 21,
              letterSpacing: 0.57,
              numberOfLines: 1,
            }}
            numberOfLines={1}>
            {item?.patient_id?.mobile || ''}
          </Text>
        </View>
      </View>
    );
  };

  const patientQuery = ({item, navigation}) => {
    const {
      lab_eprescription: e_prescription_report = [],
      pre_appointment_questionnaire = {},
    } = item;
    return (
      <View>
        <View>
          <Text
            numberOfLines={2}
            style={{...h14_Regular, lineHeight: 21, letterSpacing: 0.5}}>
            {item.illness_description}
          </Text>
        </View>
        <View style={{flexDirection: 'row'}}>
          {!isEmpty(e_prescription_report) ? (
            <TouchableOpacity
              style={{flexDirection: 'row', cursor: 'pointer'}}
              onPress={() => {
                navigation.push({
                  view: 'pdfReader',
                  params: {
                    dataArr: [e_prescription_report],
                  },
                  modal: true,
                  modalProps: {
                    autoHide: true,
                    minHeight: 200,
                    height: 600,
                    width: isMobile ? 300 : 650,
                    position: 'screenCenter',
                  },
                });
              }}>
              <Text style={{...h14_SemiBold, color: '#275dad'}}>
                {'E-prescription' + ' '}
              </Text>
            </TouchableOpacity>
          ) : !isEmpty(pre_appointment_questionnaire) ? (
            <TouchableOpacity
              style={{flexDirection: 'row', cursor: 'pointer'}}
              onPress={() => {
                navigation.push({
                  view: 'pdfReader',
                  params: {
                    dataArr: [pre_appointment_questionnaire],
                  },
                  modal: true,
                  modalProps: {
                    autoHide: true,
                    minHeight: 200,
                    height: 600,
                    width: isMobile ? 300 : 650,
                    position: 'screenCenter',
                  },
                });
              }}>
              <Text style={{...h11_Medium, color: '#275dad'}}>
                {'Pre Appointment Questionnaire' + ' '}
              </Text>
            </TouchableOpacity>
          ) : null}
          {/* <TouchableOpacity
            style={{flexDirection: 'row', cursor: 'pointer'}}
            onPress={() =>
              navigation.push({
                view: 'view-detail',
                params: {item},
              })
            }>
            <Text style={{...h11_Medium, color: '#275dad'}}>
              {'View Details' + ' '}
            </Text>
          </TouchableOpacity> */}

          {/* {Array.isArray(e_prescription_report) && e_prescription_report.length ? (
            <TouchableOpacity
              style={{flexDirection: 'row', cursor: 'pointer'}}
              onPress={() => {
                navigation.push({
                  view: 'pdfReader',
                  params: {
                    dataArr: e_prescription_report,
                  },
                  modal: true,
  
                  modalProps: {
                    autoHide: true,
                    minHeight: 200,
                    height: 600,
                    width: isMobile ? 300 : 650,
                    position: 'screenCenter',
                    // ...confirmModalProps,
                  },
                });
              }}>
              <Text style={{...h11_Medium, color: '#275dad'}}>
                {Array.isArray(e_prescription_report) && e_prescription_report.length
                  ? ' | ' + 'Attachments'
                  : 'Attachments'}
              </Text>
            </TouchableOpacity>
          ) : null} */}
        </View>
      </View>
    );
  };

  const uploadedReports = ({item, navigation}) => {
    const {lab_result = []} = item;
    return (
      <View>
        <View style={{flexDirection: 'row'}}>
          {Array.isArray(lab_result) && lab_result.length ? (
            <TouchableOpacity
              style={{flexDirection: 'row', cursor: 'pointer'}}
              onPress={() => {
                navigation.push({
                  view: 'pdfReader',
                  params: {
                    dataArr: lab_result,
                  },
                  modal: true,

                  modalProps: {
                    autoHide: true,
                    minHeight: 200,
                    height: 600,
                    width: isMobile ? 300 : 650,
                    position: 'screenCenter',
                    // ...confirmModalProps,
                  },
                });
              }}>
              <Text style={{...h14_SemiBold, color: '#275dad'}}>Reports</Text>
            </TouchableOpacity>
          ) : null}
        </View>
      </View>
    );
  };
  const uploadReport = ({eventDispatcher, item}) => {
    const {lab_result = []} = item;
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <FileInput
          onChangeValue={async (value, e) => {
            await post({
              data: {...item},
              updates: {
                lab_result: [...lab_result, ...value],
              },
              model: 'Appointments',
            }).then(() => {
              eventDispatcher.notify('reloadLabAppointment');
            });
          }}
          multiple={true}
          renderInputComponent={
            <GradientButton
              label={
                Array.isArray(lab_result) && lab_result.length
                  ? 'Upload More Reports'
                  : 'Upload Report'
              }
              buttonStyle={{
                flex: 1,
                marginLeft: isMobile ? 10 : 20,
                marginRight: isMobile ? 0 : 20,
              }}
            />
          }
        />
      </View>
    );
  };

  let tableItems = {
    lg: {
      columns: [
        {
          header: 'Appt. timings',
          width: 200,
          render: (item) => appointmentTiming(item),
        },
        {
          header: 'Patient Name',
          width: 250,
          render: (item) => patientName(item),
        },
        {
          header: 'Unique Id',
          field: 'patient_id.uniqueId',
          width: 200,
        },
        {
          header: 'Patient’s Query',
          width: 250,
          render: (item) => patientQuery(item),
        },
        {
          render: (item) => uploadedReports(item),
        },
        {
          render: (item) => uploadReport(item),
          width: 250,
        },
      ],
    },
    md: {
      columns: [
        {
          header: 'Patient Name',
          width: 200,
          render: (item) => patientName(item),
        },
        {
          header: 'Patient’s Query',
          render: (item) => patientQuery(item),
        },

        {
          render: (item) => uploadReport(item),
        },
      ],
    },
    sm: {
      columns: [
        {
          header: 'Patient Name',
          width: width * 0.5,
          render: (item) => patientName(item),
        },
        {
          render: (item) => uploadReport(item),
          width: width * 0.5,
        },
      ],
    },
  };

  let TablesData = Table({
    uri,
    ...tableItems,
    reloadEvent: 'reloadLabAppointment',
  });
  return TablesData;
};
