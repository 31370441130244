import React from 'react';
import { h16_SemiBold } from '../../../theme/fonts';
import {
  Text,
  Image,
  TouchableOpacity,
  View,
} from '../../react-core-components';

class TabItem extends React.Component {
  highlighted = ({item}) => {
    let {selectedTab} = this.props;
    return item && selectedTab === item.tabKey;
  };

  renderGroupTab = ({selected}) => {
    let {
      tab,
      renderGroupItems,
      selectedTab,
      navigation,
      eventDispatcher,
      uid,
    } = this.props;
    if (!tab.length || !renderGroupItems) {
      return null;
    }
    let groupTab = selected
      ? tab.find(item => item.tabKey === selectedTab)
      : this.groupTab || tab[0];
    this.groupTab = groupTab;

    let groupItemsProps = {
      uid,
      navigation,
      eventDispatcher,
      items: tab,
      highlighted: this.highlighted,
      onSelect: this.onSelectTab,
      children: this.renderItem({
        group: true,
        tab: groupTab.tab,
        tabKey: groupTab.tabKey,
        selected,
      }),
    };

    if (typeof renderGroupItems === 'function') {
      renderGroupItems = renderGroupItems(groupItemsProps);
    } else if (React.isValidElement(renderGroupItems)) {
      renderGroupItems = React.cloneElement(renderGroupItems, groupItemsProps);
    }
    return renderGroupItems;
  };

  isSelected = () => {
    let {tab, tabKey, selectedTab} = this.props;
    if (Array.isArray(tab)) {
      for (let tabInfo of tab) {
        if (tabInfo && tabInfo.tabKey === selectedTab) {
          return true;
        }
      }
    } else {
      return tabKey === selectedTab;
    }
  };

  onSelectTab = ({tab, tabKey} = {}) => {
    this.handleOnPress({tab, tabKey});
  };

  handleOnPress = ({ tab, tabKey }) => {
    let { navigation } = this.props;
    let { onPress, params, action } = tab;
    if (typeof onPress === 'function') {
      return onPress({ navigation });
    }
    if (!navigation) {
      return;
    }
    navigation.navigate && navigation.navigate(tabKey, params, action);
  };

  renderItem = ({group, tab, tabKey, selected}) => {
    let {
      user,
      containerStyle,
      activeContainerStyle,
      textStyle,
      activeTextStyle,
      activeBorderStyle,
      iconStyle,
      groupIcon,
      activeGroupIcon,
      groupIconContainerStyle,
      groupIconStyle,
      activeGroupIconStyle,
      itemContainerStyle,
      countStyle,
      activeCountStyle,
      navigation,
      eventDispatcher,
      DataFetch,
      resolveCount,
      dataFetchProps,
    } = this.props;
    if (!tab) {
      return null;
    }

    let {
      label,
      icon,
      activeIcon,
      countUri,
      dataFetchProps: tabDataFetchProps,
      reloadEvent,
    } = tab;
    if (countUri && typeof countUri === 'function') {
      countUri = countUri({navigation});
    }
    dataFetchProps = tabDataFetchProps || dataFetchProps;
    if (dataFetchProps && typeof dataFetchProps === 'function') {
      dataFetchProps = dataFetchProps({
        navigation,
      });
    }
    if (typeof label === 'function') {
      label = label({navigation});
    }
    if (selected) {
      icon = activeIcon || icon;
      groupIcon = activeGroupIcon || groupIcon;
      groupIconStyle = activeGroupIconStyle || groupIconStyle;
      containerStyle = {
        ...containerStyle,
        ...activeContainerStyle,
      };
      textStyle = {
        ...textStyle,
        ...activeTextStyle,
        ...h16_SemiBold
      };
      countStyle = {
        ...countStyle,
        ...activeCountStyle,
        ...h16_SemiBold
      };
    }
    let itemComponent = (
      <View style={itemContainerStyle}>
        {icon ? (
          <Image
            resizeMode={'contain'}
            source={icon}
            style={{
              objectFit: 'contain',
              ...iconStyle,
            }}
          />
        ) : null}
        {label ? <Text style={textStyle}>{label}</Text> : null}
        {countUri && DataFetch ? (
          <DataFetch
            screenName={`Tab-DataFetch-Count-${tabKey}`}
            navigation={navigation}
            eventDispatcher={eventDispatcher}
            reloadEvent={reloadEvent}
            uri={countUri}
            {...dataFetchProps}>
            {result => {
              let Count = resolveCount ? resolveCount({...result}) : null;
              if (
                Count === undefined ||
                Count === null ||
                React.isValidElement(Count)
              ) {
                return Count;
              }
              if (typeof Count !== 'string' && Count.toString) {
                Count = Count.toString();
              }
              return <Text style={countStyle}>{Count}</Text>;
            }}
          </DataFetch>
        ) : null}
      </View>
    );
    if (group && groupIcon) {
      itemComponent = (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {itemComponent}
          <View style={groupIconContainerStyle}>
            <Image
              resizeMode={'contain'}
              source={groupIcon}
              style={{
                objectFit: 'contain',
                ...groupIconStyle,
              }}
            />
          </View>
        </View>
      );
    }
    return (
      <View style={{flex: 1, ...containerStyle}}>
        {itemComponent}
        {selected && activeBorderStyle && <View style={activeBorderStyle} />}
      </View>
    );
  };
  render() {
    let {tab, tabKey} = this.props;
    const selected = this.isSelected();
    if (Array.isArray(tab)) {
      return this.renderGroupTab({selected});
    }
    return (
      <TouchableOpacity
        key={tabKey}
        style={{cursor: 'pointer'}}
        onPress={() => this.handleOnPress({tab, tabKey})}>
        {this.renderItem({
          tab,
          tabKey,
          selected,
        })}
      </TouchableOpacity>
    );
  }
}

export default TabItem;
