import React, {useEffect} from 'react';
import {Text, TouchableOpacity, View, Image} from '../../app-components';
import {LessThan, lessThanWhite, MoreThan, moreThanWhite} from '../../images';
import {h14_AvenirNext} from '../../theme/fonts';
import moment from 'moment';
import {colors} from '../../theme/colors';
const {themeColor, themeTextColor} = colors;
const monthMap = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'July',
  7: 'Aug',
  8: 'Sept',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};
const weekMap = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};
let allArray = [];
let weekNumber = 0;
const DateRange = (props) => {
  const {
    setSelectedDate,
    selectdDate,
    checkToday = () => {},
    switchToNextWeek = 0,
    checkSelectedDate = () => {},
  } = props;

  const getCurrentWeek = (time) => {
    var currentDate = moment(time);
    var weekStart = currentDate.clone().startOf('isoWeek');
    var days = [];
    let i;
    for (i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').toDate());
    }
    return days;
  };

  useEffect(() => {
    (async () => {
      let firstDate = new Date();
      allArray = [];
      const arr = getCurrentWeek(firstDate);
      const day = firstDate.getDay();
      if (day === 0) {
        setSelectedDate(arr[6]);
      } else {
        setSelectedDate(arr[day - 1]);
      }
      allArray = arr;
      weekNumber = moment(allArray[0]).isoWeek();
      if (switchToNextWeek) {
        updateDate(switchToNextWeek);
      }
    })();
  }, [switchToNextWeek]);

  let updateDate = (switchToNextWeek) => {
    new Array(switchToNextWeek).fill(0).forEach(async () => {
      await nextDate();
      weekNumber = moment(allArray[0]).isoWeek();
    });
  };

  const nextDate = async () => {
    let next = new Date(allArray[6]);
    next.setDate(next.getDate() + 1);
    const arr = getCurrentWeek(next);
    allArray = arr;
    setSelectedDate(arr[0]);
  };

  const prevDate = () => {
    let prev = new Date(allArray[0]);
    prev.setDate(prev.getDate() - 1);
    const arr = getCurrentWeek(prev);
    allArray = arr;
    const day = new Date().getDay();
    if (checkToday(arr[day - 1])) {
      setSelectedDate(arr[day - 1]);
    } else {
      setSelectedDate(arr[0]);
    }
  };

  const visiblePrev = (dateArr) => {
    if (dateArr.length) {
      let currentWeek = moment(dateArr[0]).isoWeek();
      if (!weekNumber && !currentWeek) {
        return false;
      }
      if (weekNumber === currentWeek) {
        return false;
      }
      return true;
    }
  };

  const passedDay = (date_) => {
    const today = new Date();
    var givenDay = new Date(date_);
    var year = today.getFullYear() - givenDay.getFullYear();
    var month = today.getMonth() - givenDay.getMonth();
    var date = today.getDate() - givenDay.getDate();
    if (year > 0 || (year === 0 && month > 0) || (month === 0 && date > 0)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <View style={{}}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {visiblePrev(allArray) ? (
          <TouchableOpacity
            style={{cursor: 'pointer'}}
            onPress={() => prevDate()}>
            <Image source={lessThanWhite} style={{width: 20, height: 20}} />
          </TouchableOpacity>
        ) : (
          <View style={{width: 24, height: 24}} />
        )}
        {allArray.map((item, index) => (
          <TouchableOpacity
            style={{
              height: 79,
              backgroundColor: checkSelectedDate(item, selectdDate)
                ? themeColor
                : 'transparent',
              borderRadius: 8,
              margin: 1,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              cursor: passedDay(item) ? '' : 'pointer',
              paddingTop: 9,
              // paddingRight: 20,
              // paddingLeft: 20,
              paddingBottom: 10,
            }}
            key={index.toString()}
            onPress={() => {
              if (!passedDay(item)) {
                setSelectedDate(item);
              }
            }}>
            <View style={{textAlign: 'center'}}>
              <Text
                style={{
                  ...h14_AvenirNext,
                  color: checkSelectedDate(item, selectdDate)
                    ? themeTextColor
                    : themeTextColor,
                  width: 'min-content',
                }}>
                {checkToday(item) ? 'Today  ' : null}
                {/* {weekMap[item.getDay()]} */}
                {moment(item).format('ddd')}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  color: themeTextColor,
                  ...h14_AvenirNext,
                  fontWeight: 600,
                }}>
                {/* {`${monthMap[item.getMonth()]} ${item.getDate()}`} */}
                {moment(item).format('MMM  D')}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            nextDate();
          }}>
          <Image source={moreThanWhite} style={{width: 20, height: 20}} />
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default DateRange;
