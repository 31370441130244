import React from 'react';
import {getUser, post, getImageUrl} from '../../AppServices';
import {Text, View, Image,Dimensions} from '../../app-components';
import Table from '../../app-components/table/Table';
import {h14_Medium, h14_Regular} from '../../theme/fonts';
import {femaleSign, maleSign, userIcon} from '../../images';
import {GradientButton} from '../../app-components/buttons/Buttons';
import moment from 'moment';
import {listUri} from '../../Queries';
import {colors} from '../../theme/colors'
import { resolvePatientName } from '../../app-components/patientUtility';
const {themeColor}=colors
const {height, width}=Dimensions.get('window');
let uri = () => {
  const {doctor} = getUser();
  return listUri({
    id: 'patientStatLists',
    model: 'PatientStats',
    filter: {doctor},
  });
};

const patientName = (item) => {
  let imageUrl = item?.profile_picture?.thumbnail;
  imageUrl = imageUrl && getImageUrl(imageUrl);
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <Image
        source={imageUrl || userIcon}
        style={{
          height: 50,
          width: 50,
          borderRadius: 50,
        }}
      />
      <View style={{padding: 10}}>
        <Text style={{...h14_Regular, lineHeight: 21, letterSpacing: 0.57}}>
          {resolvePatientName(item)}
        </Text>
        <Text style={{...h14_Regular, lineHeight: 21, letterSpacing: 0.57}}>
          {item?.mobile}
        </Text>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {item?.gender === 'male' ? (
            <Image
              source={maleSign}
              style={{marginRight: 5}}
              height={15}
              width={15}
            />
          ) : item?.gender === 'female' ? (
            <Image
              source={femaleSign}
              style={{marginRight: 5}}
              height={15}
              width={15}
            />
          ) : null}

          <Text style={{...h14_Regular, lineHeight: 21, letterSpacing: 0.57}}>
            {item?.gender && item.gender[0].toUpperCase()}
            {item?.gender && item?.age && '|'}
            {item?.age}
          </Text>
        </View>
      </View>
    </View>
  );
};

const lastAppointment = (item) => {
  return (
    <View style={{width: '65%', justifyContent: 'center'}}>
      <View>
        <Text style={{...h14_Regular}}>
          {moment(item?.last_appointment_date).format('MMM DD | hh:mm A')}
        </Text>
      </View>
      {/* <View style={{alignItems: 'flex-end'}}>
        <Text style={{...h11_Regular}}>15 mins</Text>
      </View> */}
    </View>
  );
};

const ViewHistory = () => {
  return (
    <View>
      <Text
        style={{
          ...h14_Medium,
          color: themeColor,
          opacity: 0.76,
        }}>
        View History
      </Text>
    </View>
  );
};
const submitFollowUp = async (item, navigation) => {
  const {patient = {}} = item || {};
  const {organization = {}, doctor = {}} = getUser() || {};
  post({
    data: {},
    updates: {
      patient: {_id: patient?._id},
      doctor: {_id: doctor?._id},
      organization: {_id: organization?._id},
    },
    model: 'followups',
  })
    .then((res) => {
      if (res) {
        navigation.push({
          view: 'sucessModal',
          modal: true,
          modalProps: {
            autoHide: true,
            screenCenterStyle: {
              borderRadius: 10,
            },
            style: {},
          },
        });
      }
    })
    .catch((err) => {
      console.log('Error in submitting follow up', err);
    });
};

let tableItems = {
  lg: {
    columns: [
      {
        width: 50,
      },
      {
        header: 'Patient',
        width: 250,
        render: ({item}) => patientName(item?.patient),
      },
      {
        header: 'Last appointment',
        width: 200,
        render: ({item}) => lastAppointment(item),
      },
      // {
      //   header: 'Mobile',
      //   width: 200,
      //   render: ({item}) => {
      //     return (
      //       <View>
      //         <Text style={{...h14_Regular}}>{item?.patient?.mobile}</Text>
      //       </View>
      //     );
      //   },
      // },
      // {
      //   header: 'Num Of Appointments',
      //   width: 200,
      //   render: ({item}) => {
      //     return (
      //       <View>
      //         <Text style={{...h14_Regular}}>{item?.num_of_appointments}</Text>
      //       </View>
      //     );
      //   },
      // },
      {
        width: 150,
        render: () => ViewHistory(),
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'patient-detail',
              params: {item},
            },
          };
        },
      },
      {
        width: 200,
        render: () => GradientButton({label: 'Schedule Follow-up'}),
        action: ({item, navigation}) => {
          return {
            type: 'invoke',
            invoke: () => submitFollowUp(item, navigation),
          };
        },
      },
    ],
  },
  md: {
    columns: [
      {
        header: 'Patient',
        width: 250,
        render: ({item}) => patientName(item?.patient),
      },
      {
        header: 'Last appointment',
        width: 200,
        render: ({item}) => lastAppointment(item),
      },
      {
        width: 150,
        render: () => GradientButton({label: 'Schedule Follow-up'}),
        action: ({item, navigation}) => {
          return {
            type: 'invoke',
            invoke: () => submitFollowUp(item, navigation),
          };
        },
      },
    ],
  },
  sm: {
    action: ({item}) => {
      return {
        type: 'link',
        link: {
          view: 'patient-detail',
          params: {item},
        },
      };
    },
    columns: [
      {
        header: 'Patient',
        // width: width * 0.45,
        render: ({item}) => patientName(item?.patient),
      },
      // {
      //   header: 'Last appointment',
      //   width: 200,
      //   render: ({item}) => lastAppointment(item),
      // },
      // {
      //   width: 80,
      //   render: () => ViewHistory(),
        // action: ({item}) => {
        //   return {
        //     type: 'link',
        //     link: {
        //       view: 'patient-detail',
        //       params: {item},
        //     },
        //   };
        // },
      // },
      {
        width: width * 0.45,
        render: () => GradientButton({label: 'Schedule Follow-up'}),
        action: ({item, navigation}) => {
          return {
            type: 'invoke',
            invoke: () => submitFollowUp(item, navigation),
          };
        },
      },
    ],
  },
};
export default Table({uri, ...tableItems});
