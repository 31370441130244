import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Snackbar,
} from '../../../../app-components';
// import {AutoSuggestInput} from '../../../../app-components/input-components/Editors'
import {addScheduleStyle} from '../../../../theme/addScheduleTheme';
import {dropDownShadow} from '../../../../theme/shadows';
import {
  h12_Regular,
  h14_Medium,
  h18_Medium,
  h14_SansSemiBold,
  h24_Medium,
  h16_Medium,
} from '../../../../theme/fonts';
import {colors, bgs} from '../../../../theme/colors';
import {
  newDeleteIcon,
  backIconNew,
  arrowDownIcon,
  circleUnSelected,
  selectedCircle,
} from '../../../../images';
import {showError} from '../../../../app-components/UtilityFunctions';
import uuid from 'uuid/v4';
import {
  getUser,
  getDownloadUrl,
  post,
  getData,
  getImageUrl,
} from '../../../../AppServices';
import CustomHeaderLabel from '../../../../app-components/CustomHeaderLabel';
import {WithModal} from '../../../../npms/react-with-modal';
import TimeSelector from '../../../../app-components/TimeSelector';
import Toast from '../../../../npms/react-toast';
import { resolveDoctorName } from '../../../../app-components/DoctorUtility';
import { resolvePatientName } from '../../../../app-components/patientUtility';
let {
  radioButtonColor,
  textWhite,
  darkTextColor,
  textColor54,
  textColor87,
  lightgreenTextColor,
  themeColor,
} = colors;
let {lightGrayColor} = bgs;
class SelectComponent extends React.Component {
  setData = ({field, value}) => {
    // console.log('props');
    let {hideModal, scheduleData, day, setValue} = this.props;
    value = value;
    setValue &&
      setValue({
        day,
        scheduleData,
        mulitpleField: [field],
        multipleValue: [value],
      });
    hideModal && hideModal();
  };
  render() {
    const {data} = this.props;
    return (
      <>
        <TouchableOpacity
          onPress={() => {
            this.setData({field: 'isAvailable', value: true});
          }}
          style={{cursor: 'pointer', flexDirection: 'row', paddingBottom: 20}}>
          <Image
            source={data?.isAvailable ? selectedCircle : circleUnSelected}
            style={{width: 18, height: 18}}
          />
          <Text style={{paddingLeft: 20, color: themeColor}}>Available</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            this.setData({field: 'isAvailable', value: false});
          }}
          style={{cursor: 'pointer', flexDirection: 'row'}}>
          <Image
            source={!data?.isAvailable ? selectedCircle : circleUnSelected}
            style={{width: 18, height: 18}}
          />
          <Text style={{paddingLeft: 20, color: themeColor}}>Unavailable</Text>
        </TouchableOpacity>
      </>
    );
  }
}
class ScheduleDayRow extends React.Component {
  oldValue = {};
  getClassInput = ({
    day,
    scheduleData,
    setValue,
    index,
    groupBySchedules,
    dayByKeys,
  }) => {
    let {key} = day;
    let {value} = scheduleData[key] || {};
    return (
      <View
        style={{
          flex: 1,
          // alignItems: 'center',
          borderRightWidth: index === dayByKeys.length - 1 ? 0 : 1,
          borderRightColor: 'rgba(217, 217, 224, 0.37)',
          // paddingRight: 13,
          // paddingLeft: 13,
          paddingTop: 5,
        }}>
        <View style={{marginTop: 10, paddingLeft: 10, paddingRight: 10}}>
          <DropDown
            data={value}
            scheduleData={scheduleData}
            day={key}
            groupBySchedules={groupBySchedules}
            setValue={setValue}
            {...this.props}
          />
        </View>
      </View>
    );
  };

  render() {
    let {dayByKeys = [], scheduleData, setValue, groupBySchedules} =
      this.props || {};
    return (
      <View style={{flexDirection: 'row', justifyContent: 'center', flex: 1}}>
        {dayByKeys.map((day, index) => {
          return this.getClassInput({
            day,
            scheduleData,
            setValue,
            index,
            groupBySchedules,
            dayByKeys,
          });
        })}
      </View>
    );
  }
}

class DropDown extends React.Component {
  lessonComponent = ({name}) => {
    return (
      <View
        style={{
          paddingTop: 14,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: name ? 'white' : '#f7f8fa',
          borderRadius: 4,
          height: 72,
          // minWidth: 118,
          paddingBottom: 10,
          boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.06)',
          borderWidth: name ? 0 : 1,
          borderColor: '#edeef0',
        }}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer',
          }}>
          <Text
            title={name}
            style={{
              ...h14_Medium,
              color: name ? themeColor : textColor54,
              minWidth: 98,
              textAlign: 'center',
              numberOfLines: 1,
              paddingRight: 10,
              width: 98,
            }}>
            {name ? 'Available' : 'Unavailable' || 'Not Selected'}
          </Text>
          <Image
            source={arrowDownIcon}
            style={{
              resizeMode: 'contain',
              height: 6,
              justifyContent: 'flex-end',
              // paddingRight: 10,
            }}
          />
        </View>
      </View>
    );
  };
  renderModal = ({frameStyle, hideModal, name, NewData}) => {
    return (
      <View
        style={{
          ...frameStyle,
          backgroundColor: '#E6EDF6',
          height: 120,
          width: 200,
        }}>
        <View
          style={{
            backgroundColor: '',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            color: themeColor,
          }}>
          Status
        </View>
        <View
          style={{
            backgroundColor: '#fff',

            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            flex: 1,
          }}>
          <SelectComponent {...this.props} hideModal={hideModal} />
        </View>
      </View>
    );
  };
  render() {
    const {data} = this.props;
    return (
      <View>
        <WithModal
          dropdownStyle={{height: 150}}
          renderModal={({frameStyle, hideModal}) =>
            this.renderModal({frameStyle, hideModal})
          }
          autoHide={true}>
          {this.lessonComponent({name: data?.isAvailable})}
        </WithModal>
        {/* <SelectClass /> */}
      </View>
    );
  }
}

class ScheduleRow extends React.Component {
  render() {
    let {scheduleData, setValue} = this.props || {};
    let {inputStyle, timeSelectorStyle} = addScheduleStyle;
    return (
      <View
        style={{
          // marginTop: 10,
          // marginLeft: 8,
          borderRightWidth: 1,
          borderRightColor: 'rgba(247, 248, 250, 0.6)',
        }}>
        <TimeSelector
          data={scheduleData}
          field="start_time"
          style={timeSelectorStyle}
          inputStyle={inputStyle}
          placeholder="Start Time"
          setValue={setValue}
        />
        <TimeSelector
          data={scheduleData}
          field="end_time"
          placeholder="End Time"
          inputStyle={inputStyle}
          filterField={'start_time'}
          style={timeSelectorStyle}
          setValue={setValue}
        />
      </View>
    );
  }
}
class AddShedule extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {schedules: []};
    this.header = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    this.dayByKeys = [
      {key: 'Monday', value: 'MON'},
      {key: 'Tuesday', value: 'TUE'},
      {key: 'Wednesday', value: 'WED'},
      {key: 'Thursday', value: 'THU'},
      {key: 'Friday', value: 'FRI'},
      {key: 'Saturday', value: 'SAT'},
      {key: 'Sunday', value: 'SUN'},
    ];
    this.insertArray = [];
    this.removeArray = [];
    this.updateArray = [];
    this.submit = true;
  }
  checkMultipleDay = (value) => {
    let {
      Sunday,
      Monday,
      Tuesday,
      Wednesday,
      Thursday,
      Friday,
      Saturday,
    } = value;
    let days = [];
    if (Sunday) {
      days.push({Sunday});
    }
    if (Monday) {
      days.push({Monday});
    }
    if (Tuesday) {
      days.push({Tuesday});
    }
    if (Wednesday) {
      days.push({Wednesday});
    }
    if (Thursday) {
      days.push({Thursday});
    }
    if (Friday) {
      days.push({Friday});
    }
    if (Saturday) {
      days.push({Saturday});
    }
    return days;
  };
  checkDay = (result) => {
    let dayBySchedule = {};
    let timeBySchedule = [];
    // console.log('asdfghjkasdfghjkl>>>>>>>>', result);
    // let meeting_with = getUserMeetingWith();
    result.forEach((value) => {
      let dayByKey = void 0;
      let timeByKey = void 0;
      let {start_time, end_time, day, isAvailable, _id} = value;
      let checkMultipleSchedule = this.checkMultipleDay(value);
      let timeByScheduleValue = {start_time, end_time};
      if (checkMultipleSchedule && checkMultipleSchedule.length > 1) {
        timeByScheduleValue = {
          ...timeByScheduleValue,
          checkMultipleSchedule,
          main_id: _id,
        };
      }
      timeByKey = `${start_time}_${end_time}`;
      if (!timeBySchedule[timeByKey]) {
        timeBySchedule[timeByKey] = timeByScheduleValue;
      }

      if (day === 'Sunday') {
        //throw error if multiple value
        dayByKey = `${start_time}_${end_time}_Sunday`;
        dayBySchedule[dayByKey] = value;
      } else if (day === 'Monday') {
        dayByKey = `${start_time}_${end_time}_Monday`;
        dayBySchedule[dayByKey] = value;
      } else if (day === 'Tuesday') {
        dayByKey = `${start_time}_${end_time}_Tuesday`;
        dayBySchedule[dayByKey] = value;
      } else if (day === 'Wednesday') {
        dayByKey = `${start_time}_${end_time}_Wednesday`;
        dayBySchedule[dayByKey] = value;
      } else if (day === 'Thursday') {
        dayByKey = `${start_time}_${end_time}_Thursday`;
        dayBySchedule[dayByKey] = value;
      } else if (day === 'Friday') {
        dayByKey = `${start_time}_${end_time}_Friday`;
        dayBySchedule[dayByKey] = value;
      } else if (day === 'Saturday') {
        dayByKey = `${start_time}_${end_time}_Saturday`;
        dayBySchedule[dayByKey] = value;
      }
    });

    let groupBySchedules = [];
    let scheduleTimes = Object.keys(timeBySchedule);
    scheduleTimes.forEach((scheduleKey) => {
      let {
        start_time,
        end_time,
        checkMultipleSchedule,
        main_id,
      } = timeBySchedule[scheduleKey];
      let mainId = `old_${uuid()}`;
      let groupByScheduleRow = {
        _id: mainId,
        start_time,
        end_time,
      };
      if (checkMultipleSchedule && main_id) {
        groupByScheduleRow = {
          ...groupByScheduleRow,
          checkMultipleSchedule,
          main_id,
        };
      }
      this.dayByKeys.forEach(({key}) => {
        let dayByScheduleKey = start_time + '_' + end_time + '_' + key;
        let dayValue = dayBySchedule[dayByScheduleKey];
        if (dayValue) {
          let oldDayValueId = dayValue._id;
          dayValue = {...dayValue, _id: mainId + ':' + oldDayValueId};
        } else {
          dayValue = {_id: mainId + ':' + 'new_' + uuid()};
          // if (meeting_with) {
          //   dayValue = {
          //     ...dayValue,
          //     meeting_with,
          //   };
          // }
        }
        groupByScheduleRow[key] = {
          value: dayValue,
        };
      });
      groupBySchedules.push(groupByScheduleRow);
    });

    this.setState({groupBySchedules});
  };

  refreshData = async () => {
    let {item} = this.props.navigation.state.params || {};
    // console.log('asdfghjk', item);
    this.setState({dataLoading: true});
    let result = await getData({
      id: 'doctorScheduleLists',
      model: 'DoctorSchedules',
      filter: {doctor: {_id: item?._id}},
    });
    // console.log('asdfghjk', result);
    this.setState({dataLoading: false});

    const {data} = result;
    if (data && data) {
      this.checkDay(data);
    }
  };
  componentDidMount = async () => {
    this.refreshData();
  };

  insertNewData = ({start_time, end_time, value, status}) => {
    const {navigation: {state: {params: {item} = {}} = {}} = {}} = this.props;
    this.dayByKeys.map(({key}) => {
      let {value: {_id, isAvailable} = {}} = value[key] || {};
      let newValue = '';
      if (_id.split('_')[0] === 'new') {
        newValue = _id.split('_')[0];
      } else if (_id.split('_')[0] === 'old') {
        newValue = _id.split(':')[1];
        newValue = newValue.split('_')[0];
      }
      if (newValue === 'new' && status !== 'removed') {
        if (isAvailable) {
          let data = {
            day: key,
            start_time,
            end_time,
            // timezoneOffset: new Date().getTimezoneOffset() * -1,
            isDeleted: false,
            doctor: {_id: item?._id || ''},
            isAvailable,
          };

          this.insertArray.push(data);
        }
      }
    });
  };
  removeData = ({value, status}) => {
    this.dayByKeys.map(({key}) => {
      let {value: {_id, resetValue} = {}} = value[key] || {};
      if (resetValue || status === 'removed') {
        _id = _id.split(':')[1];
        let data = {
          _id,
        };
        if (_id.split('_')[0] != 'new') {
          this.removeArray.push(data);
        }
      }
    });
  };
  updateData = ({value, start_time, end_time, status}) => {
    this.dayByKeys.map(({key}) => {
      let {value: {_id, status: newStatus, isAvailable} = {}} =
        value[key] || {};
      _id = _id.split(':')[1];
      let newValue = _id.split('_')[0];
      let data = {
        _id,
        day: key,
        isAvailable,
      };
      if (newStatus === 'update' && newValue != 'new') {
        data = {
          ...data,
        };
        this.updateArray.push(data);
      } else if (status === 'update' && newValue != 'new') {
        data = {
          ...data,
          start_time,
          end_time,
        };
        this.updateArray.push(data);
      }
    });
  };
  showMessage = (message, duration = null) => {
    if (duration) {
      Toast.show(message, Snackbar.LENGTH_LONG * duration);
    } else {
      Toast.show(message, Snackbar.LENGTH_LONG);
    }
    // Snackbar.show({
    //   text: message,
    //   duration: Snackbar.LENGTH_LONG,
    // });
  };

  save = async () => {
    let {groupBySchedules} = this.state || {};
    let executeChanges = true;
    if (!this.submit) {
      groupBySchedules = [...groupBySchedules];
      for (let value of groupBySchedules) {
        let {_id, start_time, end_time, status} = value || {};
        // console.log(start_time, end_time, status);
        let newValue = _id.split('_')[0];
        if (newValue === 'old') {
          this.removeData({value, status});
        }
        if (start_time && end_time) {
          this.insertNewData({status, start_time, end_time, value});
        } else {
          if (!status || status !== 'removed') {
            executeChanges = false;
            this.showMessage('Enter Start and End time', 1.5);
            break;
          }
        }
        if (newValue === 'old') {
          this.updateData({value, start_time, end_time, status});
        }
      }
      if (!executeChanges) {
        return;
      }
      let insertData = this.insertArray.map((doc) => {
        return {
          updates: {insert: doc},
          model: 'DoctorSchedules',
        };
      });

      let removedData = this.removeArray.map((doc) => {
        let {_id} = doc || {};
        return {
          updates: {update: {changes: {isDeleted: true}, _id}},
          model: 'DoctorSchedules',
        };
      });
      let updateData = this.updateArray.map((doc) => {
        let {_id, ...rest} = doc || {};
        return {
          updates: {update: {changes: rest, _id}},
          model: 'DoctorSchedules',
        };
      });
      console.log(removedData, insertData, updateData);
      let batchUpdates = [...removedData, ...updateData, ...insertData];
      // console.log('batchUpdates', JSON.stringify(batchUpdates));
      if (batchUpdates && batchUpdates.length) {
        this.setState({loading: true});
        await post({batchUpdates})
          .then((res) => {
            this.refreshData();
            this.setState({loading: false});
            if (
              !updateData.length &&
              !insertData.length &&
              removedData.length
            ) {
              this.showMessage('Remove Successfully');
            } else if (
              !updateData.length &&
              !removedData.length &&
              insertData.length
            ) {
              this.showMessage('Add Successfully');
            } else {
              this.showMessage('Data Updates');
            }

            this.insertArray = [];
            this.removeArray = [];
            this.updateArray = [];
            return res;
          })
          .catch((err) => {
            this.setState({loading: false});
            this.refreshData();
            this.insertArray = [];
            this.removeArray = [];
            this.updateArray = [];
            this.showMessage(err?.message);
          });
      } else {
        this.showMessage('No Updates');
      }
    } else {
      this.showMessage('No Updates');
    }
    this.submit = true;
  };
  cancel = () => {
    let {navigation, eventDispatcher} = this.props || {};
    eventDispatcher && eventDispatcher.notify('reloadlecture');
    navigation.pop(1);
  };
  timeConvert = (time) => {
    let hours = time / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    minutes = Math.floor(minutes);
    let text = '';
    if (rhours && minutes) {
      text = `${rhours} Hr ${minutes} Min `;
    } else if (minutes) {
      text = `${minutes} Min`;
    } else if (rhours) {
      text = `${rhours} Hr`;
    }
    return text;
  };

  timeMessage = () => {
    let {groupBySchedules = []} = this.state || {};
    let time = 0;
    groupBySchedules.map((value) => {
      let {start_time, end_time} = value || {};
      let aggregateStartTime = void 0;
      let aggregateEndTime = void 0;
      if (start_time && end_time) {
        let _start_time = start_time.split(':');
        let _end_time = end_time.split(':');
        let start_time_h = parseInt(_start_time[0]);
        let start_time_m = parseInt(_start_time[1]);
        aggregateStartTime = start_time_h * 60 + start_time_m;
        let end_time_h = parseInt(_end_time[0]);
        let end_time_m = parseInt(_end_time[1]);
        aggregateEndTime = end_time_h * 60 + end_time_m;
        aggregateEndTime = aggregateEndTime - aggregateStartTime;
      }
      if (start_time && end_time) {
        this.dayByKeys.map(({key}) => {
          let {value: newValue} = value[key] || {};
          let {class_id, section, subject} = newValue || {};
          if (class_id && section && subject) {
            time = time + aggregateEndTime;
          }
        });
      }
    });
    return time;
  };
  showName = (name) => {
    if (!name) {
      return;
    }
    name = name && name.split(' ');
    if (name.length === 2) {
      name =
        (name[0] && name[0].substring(0, 1).toUpperCase()) +
        (name[1] && name[1].substring(0, 1).toUpperCase());
    } else {
      name = name[0] && name[0].substring(0, 1).toUpperCase();
    }

    return name;
  };

  getTopHeader = ({groupBySchedules}) => {
    let {item} = this.props.navigation.state.params || {};
    let time = this.timeMessage();
    let message = this.timeConvert(time);
    let {name,mobile, profile_picture, email} = item || {};
    let Fname = resolvePatientName({name});
    let {loading} = this.state;
    let {
      renderContainerTop,
      addScheduleTextStyle,
      buttonCancelStyle,
      buttonSaveStyle,
      teacherViewStyle,
      nameTextStyle,
      weekStyle,
      weekTextStyle,
      planedTextStyle,
      teacherTextStyle,
      buttonSheduleStyle,
    } = addScheduleStyle;
    // image = image ? getDownloadUrl(image) : void 0;
    return (
      <View
        style={{
          ...renderContainerTop,
          paddingLeft: 24,
          paddingRight: 24,
          paddingBottom: 21,
          paddingTop: 20,
        }}>
        <View style={{flexDirection: 'row'}}>
          {profile_picture ? (
            <Image
              source={getImageUrl(profile_picture?.file)}
              style={{width: 48, height: 48, borderRadius: 30}}
            />
          ) : (
            <View
              style={{
                width: 48,
                height: 48,
                borderRadius: 25,
                backgroundColor: radioButtonColor,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Text style={{...h18_Medium, color: 'white'}}>
              {this.showName(Fname) || ''}
              </Text>
            </View>
          )}
          <View style={{paddingLeft: 17}}>
            <Text style={{color: darkTextColor, ...h18_Medium}}>{Fname}</Text>
            <Text
              style={{
                color: textColor54,
                ...h14_Medium,
                paddingTop: 4,
                width: 150,
                numberOfLines: 1,
              }}>
              {mobile || email}
            </Text>
          </View>
        </View>
        {/* <View style={{alignItems: 'center'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={weekStyle}>{message || '00 Hr 00 Min '}</Text>
            <Text style={weekStyle}> /week</Text>
          </View>
          <Text style={planedTextStyle}>Planned</Text>
        </View> */}
        <View style={{alignItems: 'center', flexDirection: 'row'}}>
          <TouchableOpacity
            style={buttonSheduleStyle}
            onPress={() => this.addSlot({groupBySchedules})}>
            <Text style={{...h16_Medium, color: textWhite}}>
              + Add Schedule
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  getCenterHeader = () => {
    let {centerHeaderStyle, centerHeaderTextStyle} = addScheduleStyle;
    return (
      <View
        style={{
          ...centerHeaderStyle,
          backgroundColor: 'white',
          borderTopWidth: 1,
          borderTopColor: 'rgba(246, 248, 249, 0.7)',
        }}>
        <View
          style={{
            ...centerHeaderTextStyle,
            paddingLeft: 35,
            paddingBottom: 32,
            paddingTop: 30,
            paddingRight: 6,
            minWidth: 150,
          }}>
          SESSION TIME
        </View>
        {this.header.map((text, index) => {
          return (
            <View
              style={{
                ...centerHeaderTextStyle,
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 32,
                paddingTop: 30,
                borderRightWidth: 1,
                borderLeftWidth: index == 0 ? 1 : 0,
                borderRightColor: 'rgba(217, 217, 224, 0.37)',
                borderLeftColor: 'rgba(217, 217, 224, 0.37)',
                flex: 1,
              }}>
              {text.toUpperCase()}
            </View>
          );
        })}
        <View style={{...centerHeaderTextStyle, width: 50}} />
      </View>
    );
  };

  removeRow = ({scheduleData}) => {
    let {groupBySchedules} = this.state;
    groupBySchedules = [...groupBySchedules];
    groupBySchedules = groupBySchedules.map((value) => {
      let {_id} = value || {};
      if (_id === scheduleData._id) {
        this.submit = false;
        value = {...value, status: 'removed'};
        return value;
      }
      return value;
    });
    this.setState({groupBySchedules});
  };
  removeRenderRow = ({scheduleData}) => {
    let {removeRowStyle, removeTextStyle} = addScheduleStyle;
    return (
      <TouchableOpacity
        style={{...removeRowStyle, paddingLeft: 5}}
        onPress={() => this.removeRow({scheduleData})}>
        <Image
          style={{width: 28, height: 28, opacity: 0.54}}
          source={newDeleteIcon}
        />
      </TouchableOpacity>
    );
  };

  inactiveRenderRow = ({scheduleData}) => {
    let {removeRowStyle} = addScheduleStyle;
    return (
      <View style={{...removeRowStyle, paddingLeft: 5}}>
        {this.action({item: scheduleData})}
      </View>
    );
  };
  addSlot = ({groupBySchedules}) => {
    let value = {
      _id: `new_ ${uuid()}`,
      Sunday: {value: {_id: `new_ ${uuid()}`, isAvailable: false}},
      Monday: {value: {_id: `new_ ${uuid()}`, isAvailable: false}},
      Tuesday: {value: {_id: `new_ ${uuid()}`, isAvailable: false}},
      Wednesday: {value: {_id: `new_ ${uuid()}`, isAvailable: false}},
      Thursday: {value: {_id: `new_ ${uuid()}`, isAvailable: false}},
      Friday: {value: {_id: `new_ ${uuid()}`, isAvailable: false}},
      Saturday: {value: {_id: `new_ ${uuid()}`, isAvailable: false}},
    };
    groupBySchedules = [...groupBySchedules];
    groupBySchedules.push(value);
    this.setState({groupBySchedules});
    if (this._containerRef) {
      let scrollHeight = this._containerRef.scrollHeight;
      setTimeout((_) => (this._containerRef.scrollTop = scrollHeight), 0);
    }
  };
  getMessage = (checkMultipleSchedule) => {
    let multipleScheduleMessage = '';
    for (let i = 0; i < checkMultipleSchedule.length; i++) {
      let value = checkMultipleSchedule[i];
      let key = Object.keys(value);
      if (key && key.length) {
        key = key[0];
      }
      if (checkMultipleSchedule.length - 1 != i) {
        multipleScheduleMessage = multipleScheduleMessage + `${key} ,`;
      } else {
        multipleScheduleMessage = multipleScheduleMessage + `${key} `;
      }
    }
    return multipleScheduleMessage;
  };
  getRow = ({scheduleData, groupBySchedules}) => {
    let {getRowContainerStyle} = addScheduleStyle;
    let {checkMultipleSchedule = []} = scheduleData || {};
    let message = '';
    if (checkMultipleSchedule && checkMultipleSchedule.length) {
      message = this.getMessage(checkMultipleSchedule);
    }
    return (
      <View style={{flexDirection: 'row'}}>
        <View
          style={{
            flexDirection: 'row',
            borderBottomColor: 'rgba(247, 248, 250, 0.6)',
            borderBottomWidth: 1,
            padding: 10,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}>
          {/* <Image
 source={dublicateIcon}
 style={{width: 24, height: 24, opacity: 0.54, marginTop: 10}}
 /> */}
          <ScheduleRow scheduleData={scheduleData} setValue={this.setValue} />
        </View>
        {checkMultipleSchedule.length ? (
          <>
            <View
              style={{
                ...getRowContainerStyle,
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Text style={{color: textColor87, ...h14_SansSemiBold}}>
                {message}
              </Text>
            </View>
            <View
              style={{
                backgroundColor: 'white',
                paddingLeft: 17,
                paddingRight: 17,
                justifyContent: 'center',
                borderBottomColor: 'rgba(247, 248, 250, 0.6)',
                borderBottomWidth: 1,
              }}>
              {this.inactiveRenderRow({scheduleData})}
            </View>
          </>
        ) : (
          <>
            <View
              style={{
                ...getRowContainerStyle,
                flex: 1,
              }}>
              <ScheduleDayRow
                dayByKeys={this.dayByKeys}
                scheduleData={scheduleData}
                setValue={this.setValue}
                groupBySchedules={groupBySchedules}
                reset={this.resetData}
                {...this.props}
              />
            </View>
            <View
              style={{
                backgroundColor: 'white',
                paddingLeft: 17,
                paddingRight: 17,
                justifyContent: 'center',
                borderBottomColor: 'rgba(247, 248, 250, 0.6)',
                borderBottomWidth: 1,
              }}>
              {this.removeRenderRow({scheduleData})}
            </View>
          </>
        )}
      </View>
    );
  };

  resetData = ({groupBySchedules}) => {
    this.submit = false;
    this.setState({groupBySchedules});
  };

  setValue = ({
    scheduleData,
    day,
    field,
    value,
    mulitpleField,
    multipleValue,
  }) => {
    // console.log("props")
    let {_id} = scheduleData || {};
    let {groupBySchedules} = this.state;
    let status = _id.split('_')[0];
    this.submit = false;
    let newData = groupBySchedules.map((groupBySchedule) => {
      if (groupBySchedule._id === _id) {
        let newData = {...groupBySchedule};

        if (day) {
          let dayValue = newData[day].value;
          if (mulitpleField && multipleValue) {
            for (let i = 0; i < mulitpleField.length; i++) {
              if (status === 'old') {
                dayValue = {
                  ...dayValue,
                  status: 'update',
                  [mulitpleField[i]]: multipleValue[i],
                };
                newData[day] = {value: dayValue};
              } else {
                dayValue = {
                  ...dayValue,
                  [mulitpleField[i]]: multipleValue[i],
                };
                newData[day] = {value: dayValue};
              }
            }
          } else {
            dayValue = {...dayValue, [field]: value};
            newData[day] = {value: dayValue};
          }
        } else {
          newData[field] = value;
          if (status === 'old') {
            newData.status = 'update';
          }
        }
        return newData;
      } else {
        return groupBySchedule;
      }
    });
    this.setState({groupBySchedules: newData});
  };
  Header = () => {
    let {buttonCancelStyle, buttonSaveStyle} = addScheduleStyle;
    let {loading} = this.state;
    let {item} = this.props.navigation.state.params || {};
    let Fname = resolvePatientName(item)
    return (
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          paddingBottom: 15,
          paddingTop: 15,
          paddingRight: 24,
          paddingLeft: 24,
          borderBottomWidth: 1,
          borderBottomColor: 'rgba(246, 248, 249, 0.7)',
          backgroundColor: 'white',
        }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <TouchableOpacity onPress={this.cancel} style={{cursor: 'pointer'}}>
            <Image source={backIconNew} style={{height: 26, width: 28}} />
          </TouchableOpacity>

          <CustomHeaderLabel
            seperator={true}
            preLabel={' Add Weekly Schedule'}
            postLabel={Fname}
          />
          {/* <Text style={{...h24_Medium, color: textColor87, paddingLeft: 16}}>
            Add Weekly Schedule
          </Text> */}
        </View>
        <View style={{flexDirection: 'row'}}>
          <TouchableOpacity style={buttonCancelStyle} onPress={this.cancel}>
            Cancel
          </TouchableOpacity>
          <TouchableOpacity style={{...buttonSaveStyle}} onPress={this.save}>
            {loading ? (
              <ActivityIndicator size="small" color={'#fff'} />
            ) : (
              <Text style={{...h14_SansSemiBold, color: 'white'}}>Save</Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  render() {
    let {containerStyle} = addScheduleStyle;
    let {groupBySchedules = [], dataLoading} = this.state || {};

    return (
      <View
        style={{
          ...containerStyle,
          overflow: 'auto',
          backgroundColor: 'rgba(246, 248, 249, 0.7)',
        }}>
        {this.Header()}
        {this.getTopHeader({groupBySchedules})}
        <View
          style={{
            flex: 1,
            overflow: 'auto',
            marginLeft: 24,
            marginRight: 24,
            borderRadius: 4,
            boxShadow: '0 0 10px 0 rgba(53, 53, 53, 0.08)',
          }}>
          {this.getCenterHeader()}
          <View
            style={{
              flex: 1,
              // overflow: 'auto',
              backgroundColor: 'rgba(246, 248, 249, 0.7)',
              borderWidth: 1,
              borderColor: 'rgba(246, 248, 249, 0.7)',
              borderBottomRadius: 4,
              marginBottom: 5,
            }}
            getRef={(_) => (this._containerRef = _)}>
            {groupBySchedules.length ? (
              groupBySchedules.map((scheduleData, index) => {
                let {status} = scheduleData || {};
                return (
                  status != 'removed' &&
                  this.getRow({scheduleData, groupBySchedules})
                );
              })
            ) : dataLoading ? (
              <ActivityIndicator size="small" />
            ) : (
              // <ActivityIndicator />
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 20,
                }}>
                <Text>No Schedule</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

export default AddShedule;
