import Form from '../../app-components/form/Form';
import {submit, getUser} from '../../AppServices';
import {PLACEHOLDER} from '../../app-components/utils';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';

let header = {
  title: 'Create Invite link',
  actions: [cancelButton(), save({label: 'Send'})],
};

export default Form({
  type: 'standardShadow',
  onSubmit: submit({
    model: 'Invites',
  }),
  closeView: 1,
  reloadEvent: 'reloadInviteLinks',
  mandatory: {name: 1, mobile: 1},
  defaultValues: () => {
    const {organization = {}} = getUser();
    return {
      organization,
    };
  },
  lg: {
    header,
    formGroups: [
      {
        columnsPerRow: 1,
        fieldVariant: 'filled',
        fieldContainer: 'topLabel',
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'name',
                label: 'Name of patient',
                placeholder: PLACEHOLDER,
                width: '50%',
                mandatory: true,
              },
              {
                type: 'mobile',
                field: 'mobile',
                label: 'Mobile number',
                placeholder: PLACEHOLDER,
                width: '50%',
                mandatory: true,
              },
            ],
          },
        ],
      },
    ],
  },
  sm: {
    header: {
      title: 'Create Invite link',
    },
    footer: {
      actions: [cancelButton(), save()],
    },
    formGroups: [
      {
        columnsPerRow: 1,
        fieldVariant: 'filled',
        fieldContainer: 'topLabel',
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'name',
                label: 'Name of patient',
                placeholder: PLACEHOLDER,
                mandatory: true,
              },
              {
                type: 'mobile',
                field: 'mobile',
                label: 'Mobile number',
                placeholder: PLACEHOLDER,
                mandatory: true,
              },
              // {
              //   type: 'text',
              //   field: 'link',
              //   label: 'Generate Link',
              //   placeholder: 'link',
              //   mandatory: true,
              // },
            ],
          },
        ],
      },
    ],
  },
});
