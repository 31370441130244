import React from 'react';
import {JSONReactForm} from '../../npms/react-form';
import {
  formTheme,
  formMobileTheme,
  formHeaderTheme,
  formHeaderMobileTheme,
  compactFormHeaderTheme,
  formFooterTheme,
  formFooterMobileTheme,
  compactFormFooterTheme,
  compactFormTheme,
  standardShadowFormTheme,
  standardShadowFormThemeMobile,
} from '../../theme/formTheme';
import {fetchQuery, getUser} from '../../AppServices';
import {
  beforeFetchForm,
  afterFetchForm,
} from '../data-fetch/DataFetchFunctions';
import {getRenderComponent, isMobile} from '../UtilityFunctions';
import {ActivityIndicator} from '../loader/Loader';
import ToolBar from '../toolbar/ToolBar';
import {renderFormGroup} from './FormGroup';
import {FormTab} from './FormTab';
import {getResolvedGroups, getResolvedProps} from './Utility';
import Toast from '../../npms/react-toast';
import startCase from 'lodash/startCase'
export const FormHeader = (props) => {
  let {form_context, form_state, theme, ...restProps} = props;
  let {uid} = form_state || {};
  return <ToolBar {...theme} uid={uid} state={form_state} {...restProps} />;
};

const renderFormHeader = (props) => {
  let {header, formType, ...restProps} = props;
  header = getResolvedProps(header, restProps);
  let {type = formType, ...restHeader} = header;
  let headerTheme = isMobile ? formHeaderMobileTheme : formHeaderTheme;
  if (type === 'compact') {
    headerTheme = compactFormHeaderTheme;
  }
  return <FormHeader theme={headerTheme} {...restProps} {...restHeader} />;
};

export const FormFooter = (props) => {
  let {form_context, form_state, theme, ...restProps} = props;
  let {uid} = form_state || {};
  return <ToolBar {...theme} uid={uid} state={form_state} {...restProps} />;
};

const renderFormFooter = (props) => {
  let {footer, formType, ...restProps} = props;
  footer = getResolvedProps(footer, restProps);
  let {type = formType, ...restFooter} = footer;
  let footerTheme = isMobile ? formFooterMobileTheme : formFooterTheme;
  if (type === 'compact') {
    footerTheme = compactFormFooterTheme;
  }
  return <FormFooter theme={footerTheme} {...restProps} {...restFooter} />;
};

const typeWiseFormTheme = {
  standard: isMobile ? formMobileTheme : formTheme,
  standardShadow: isMobile
    ? standardShadowFormThemeMobile
    : standardShadowFormTheme,
  compact: compactFormTheme,
};

const getMandatoryMessage = ({field}) => {
  return `Please enter ${field ? startCase(field) : "value"}`;
};
export class Form extends React.Component {
  renderTab = (props) => {
    return <FormTab {...props} />;
  };

  onFormGroupExpand = (callback) => {
    if (this.lastFormGroupExpanded && this.lastFormGroupExpanded !== callback) {
      this.lastFormGroupExpanded();
    }
    this.lastFormGroupExpanded = callback;
  };

  renderFormGroup = ({type}) => (props) => {
    const {removeRow, index, error, expandSingleFormgroup} = this.props;
    return renderFormGroup({
      ...props,
      onFormGroupExpand: expandSingleFormgroup
        ? this.onFormGroupExpand
        : void 0,
      formType: type,
      removeRow,
      index,
      error,
    });
  };

  renderHeader = ({renderHeader, header, type}) => (props) => {
    if (renderHeader) {
      return getRenderComponent(renderHeader, {...props});
    } else if (header) {
      return renderFormHeader({...props, header, formType: type});
    } else {
      return null;
    }
  };
  renderFooter = ({renderFooter, footer, type}) => (props) => {
    if (renderFooter) {
      return getRenderComponent(renderFooter, {...props});
    } else if (footer) {
      return renderFormFooter({...props, footer, formType: type});
    } else {
      return null;
    }
  };
  resolveVisible = ({visible, ...restProps}) => {
    if (typeof visible === 'function') {
      let {user, data, navigation} = restProps;
      visible = visible({
        user,
        data,
        navigation,
      });
    }
    return visible;
  };

  onSubmitSuccess = () => {
    let {submitMessage} = this.props;
    if (!submitMessage) {
      return null;
    }
    Toast.show(submitMessage);
  };

  onSubmitError = (err) => {
    Toast.error(err.message || err);
  };

  renderLoading = ({form_state} = {}) => {
    let {loading, submitting} = form_state || {};
    if (loading || submitting) {
      return <ActivityIndicator key="loading_form" />;
    }
  };

  render() {
    let user = getUser && getUser();
    let {
      type = 'standard',
      computeProps,
      renderHeader,
      renderFooter,
      header,
      footer,
      formGroups,
      ...restProps
    } = getResolvedProps(this.props);
    if (formGroups) {
      formGroups = getResolvedGroups(formGroups, restProps);
    }
    return (
      <JSONReactForm
        {...typeWiseFormTheme[type]}
        fetch={fetchQuery}
        getUser={getUser}
        user={user}
        beforeFetch={beforeFetchForm}
        afterFetch={afterFetchForm}
        reloadOnNavigationChange={true}
        validateOnBlur={true}
        mandatoryMessage={getMandatoryMessage}
        renderTab={this.renderTab}
        renderFormGroup={this.renderFormGroup({type})}
        renderHeader={this.renderHeader({renderHeader, header, type})}
        renderFooter={this.renderFooter({renderFooter, footer, type})}
        resolveVisible={this.resolveVisible}
        renderLoading={this.renderLoading}
        onSubmitSuccess={this.onSubmitSuccess}
        onSubmitError={this.onSubmitError}
        computeProps={{
          user,
          fetch: fetchQuery,
          ...computeProps,
        }}
        formGroups={formGroups}
        {...restProps}
      />
    );
  }
}

export class NestedForm extends React.Component {
  render() {
    return (
      <Form
        useState={true}
        scrollable={false}
        type={'nested'}
        {...this.props}
      />
    );
  }
}

export const NestedFormHoc = (def) => {
  class FormComponent extends React.Component {
    render() {
      return <NestedForm {...def} {...this.props} />;
    }
  }
  return FormComponent;
};
const FormHoc = (def) => {
  class FormComponent extends React.Component {
    render() {
      return <Form {...def} {...this.props} />;
    }
  }
  return FormComponent;
};

export default FormHoc;
