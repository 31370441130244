import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
} from '../../app-components';
import {Form} from '../../app-components/form/Form';
import {invoke, fetch} from '../../AppServices';

import {
  h14_AvenirNext,
  h16_Medium,
  h20_AvenirNext_500,
  h14_AvenirNext_Bold,
  h12_Regular,
} from '../../theme/fonts';
import {
  DateInput,
  ChipsToggleInput,
  AutoSuggestInput,
  TextInput,
} from '../../app-components/input-components/Editors';
import {getUser, checkDob} from '../../AppServices';
import {dropDownShadow, moreActionsShadow} from '../../theme/shadows';
import {HospitalLOGO, backgroundImage1} from '../../images';
import {ActivityIndicator} from '../../app-components';
import {BASE_URL} from '../../Config';
import MobileInput from '../../app-components/input-components/MobileInput';
import {colors} from '../../theme/colors';
const countryCode = 'US';
const {themeColor}=colors
const PatientForm = (props) => {
  let {navigation} = props;
  const {} = navigation;
  let {form_state: {data = {}} = {}, form_context: {setValue} = {}} = props;
  const {} = getUser() || {};
  const [hoverSignIn, setHoverSignIn] = useState(false);
  const [hoverSignUp, setHoverSignUp] = useState(true);
  const [loading, setLoading] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [dobError, setDobError] = useState('');

  const [error, setError] = useState('');
  useEffect(() => {
    fetch({
      uri: {
        url: `${BASE_URL}/states`,
        props: {
          country_code: countryCode,
        },
      },
    }).then((res) => {
      console.log(res);
      setStateData(res.data);
    });
    // axios({url: 'http://192.168.100.170:7000/cities', method: 'GET'})
    //   .then((res) => console.log('kkkk', res))
    //   .catch((err) => console.log('err', JSON.stringify(err)));
  }, []);

  const submitInfo = async () => {
    const isMobileLengthError =
      data && data.mobile && data.mobile.length ? true : false;
    if (!isMobileLengthError) {
      setError('Mobile number is required');
    } else if (!data.dob) {
      setDobError('Please enter value');
    } else {
      setLoading(true);
      await invoke({
        id: 'patientSignup',
        paramValue: {
          data: {...data, country: countryCode, userType: 'Patient'},
        },
      })
        .then(() => {
          navigation.replace('patient-signIn');
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError('Mobile is already registered!');
          console.log('err', err);
        });
      setLoading(false);
    }
  };

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundImage: `url(${backgroundImage1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 30,
          paddingRight: 30,
          height: 79,
        }}>
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            navigation.reset('front-page');
          }}>
          <Image source={HospitalLOGO} style={{height:70,width:200,objectFit:"contain"}}/>
        </TouchableOpacity>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            // flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <TouchableOpacity
            style={{
              paddingRight: 50,
              cursor: 'pointer',
              backgroundColor: !hoverSignIn ? '#fff' : themeColor,
              borderRadius: 16,
              paddingLeft: 16,
              paddingTop: 6,
              paddingRight: 15,
              paddingBottom: 7,
              marginRight: 16,
            }}
            onPress={() => {
              navigation.replace('patient-signIn');
            }}
            onMouseEnter={() => setHoverSignIn(true)}
            onMouseLeave={() => setHoverSignIn(false)}>
            <Text
              style={{
                ...h14_AvenirNext_Bold,
                color: hoverSignIn ? '#fff' : 'rgba(56, 56, 56, 0.7)',
                // opacity: 0.7,
              }}>
              Sign In
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              paddingRight: 50,
              cursor: 'pointer',
              backgroundColor: !hoverSignUp ? '#fff' : themeColor,
              borderRadius: 16,
              paddingLeft: 16,
              paddingTop: 6,
              paddingRight: 15,
              paddingBottom: 7,
            }}
            onPress={() => {
              // navigation.reset('patient-signUp');
            }}
            onMouseEnter={() => setHoverSignUp(true)}
            onMouseLeave={() => setHoverSignUp(true)}>
            <Text
              style={{
                ...h14_AvenirNext_Bold,
                color: hoverSignUp ? '#fff' : 'rgba(56, 56, 56, 0.7)',
                // opacity: 0.7,
              }}>
              Sign Up
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={{justifyContent: 'center', alignItems: 'center'}}>
        <View
          style={{
            width: '50%',
            ...dropDownShadow,
            borderRadius: 10,
            backgroundColor: 'white',
          }}>
          <View style={{padding: 30}}>
            <View style={{marginBottom: 15}}>
              <Text
                style={{
                  ...h20_AvenirNext_500,
                  color: '#2e6ecd',
                  fontWeight: '700',
                }}>
                Enter Your Details
              </Text>
            </View>
            {/* First name and last name */}
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              {/* First name */}

              <View style={{flex: 1, marginRight: 15}}>
                <Text
                  style={{
                    ...h16_Medium,
                    color: 'rgba(56, 56, 56, 0.37)',
                    marginBottom: 10,
                  }}>
                  First name
                </Text>
                <TextInput
                  data={data}
                  value={data.first_name}
                  field={'first_name'}
                  onChangeText={(value) =>
                    setValue({field: 'first_name', value, data})
                  }
                  variant="filled"
                  placeholder="Enter Your first name"
                />
              </View>
              {/*Last name*/}
              <View style={{flex: 1}}>
                <Text
                  style={{
                    ...h16_Medium,
                    color: 'rgba(56, 56, 56, 0.37)',
                    marginBottom: 10,
                  }}>
                  Last name
                </Text>
                <TextInput
                  data={data}
                  value={data.last_name}
                  field={'last_name'}
                  onChangeText={(value) =>
                    setValue({field: 'last_name', value, data})
                  }
                  variant="filled"
                  placeholder="Enter your last name"
                />
              </View>
            </View>

            {/* MOBILE  and blood */}
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              {/* Mobile */}

              <View style={{flex: 1, marginRight: 15}}>
                <MobileInput
                  label="Mobile"
                  value={data.mobile}
                  setValue={setValue}
                  fieldName="mobile"
                  data={data}
                  error={error}
                />
              </View>

              {/* Blood Group */}
              {/* <View style={{flex: 1}}>
                <Text
                  style={{
                    ...h16_Medium,
                    color: 'rgba(56, 56, 56, 0.37)',
                    marginBottom: 10,
                  }}>
                  Blood Type
                </Text>
                <AutoSuggestInput
                  data={data}
                  onItemSelect={({item}) => {
                    setValue({
                      field: 'blood_group',
                      value: item,
                      data,
                    });
                  }}
                  showArrow={true}
                  renderSelector={true}
                  variant="filled"
                  value={data.blood_group}
                  valueField={'value'}
                  keyField={'value'}
                  field={'value'}
                  sugestionField={'value'}
                  options={[
                    {label: 'A+', value: 'A+'},
                    {label: 'A-', value: 'A-'},
                    {label: 'B+', value: 'B+'},
                    {label: 'B-', value: 'B-'},
                    {label: 'O+', value: 'O+'},
                    {label: 'O-', value: 'O-'},
                    {label: 'AB+', value: 'AB+'},
                    {label: 'AB-', value: 'AB-'},
                  ]}
                  moreInputStyle={{
                    minHeight: 22,
                  }}
                  dropdownInputTheme={{
                    ...h14_AvenirNext,
                  }}
                  dropdownStyle={{
                    height: 150,
                    borderRadius: 8,
                    backgroundColor: '#ffffff',
                    ...moreActionsShadow,
                  }}
                />
              </View>
             */}
            </View>

            {/*Mailing  Address */}
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              <View style={{flex: 1}}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 10,
                  }}>
                  {/* Mailing Address */}

                  <View style={{flex: 1, marginRight: 15}}>
                    <Text
                      style={{
                        ...h16_Medium,
                        color: 'rgba(56, 56, 56, 0.37)',
                        marginBottom: 10,
                      }}>
                      Mailing Address
                    </Text>
                    <TextInput
                      data={data}
                      value={data.address}
                      field={'address'}
                      onChangeText={(value) =>
                        setValue({field: 'address', value, data})
                      }
                      variant="filled"
                      placeholder="Address"
                    />
                  </View>
                  {/*Last name*/}
                  <View style={{flex: 1}}>
                    <Text
                      style={{
                        ...h16_Medium,
                        color: 'rgba(56, 56, 56, 0.37)',
                        marginBottom: 10,
                      }}>
                      Zip Code
                    </Text>
                    <TextInput
                      data={data}
                      value={data.zipcode}
                      field={'zipcode'}
                      onChangeText={(value) =>
                        setValue({field: 'zipcode', value, data})
                      }
                      variant="filled"
                      placeholder="Zip Code"
                    />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 10,
                  }}>
                  {/* State name */}

                  <View style={{flex: 1, marginRight: 15}}>
                    <Text
                      style={{
                        ...h16_Medium,
                        color: 'rgba(56, 56, 56, 0.37)',
                        marginBottom: 10,
                      }}>
                      State
                    </Text>
                    {stateData.length ? (
                      <AutoSuggestInput
                        data={data}
                        onItemSelect={({item}) => {
                          fetch({
                            uri: {
                              url: `${BASE_URL}/cities`,
                              props: {
                                country_code: countryCode,
                                state_code: item.isoCode,
                              },
                            },
                          }).then((res) => {
                            setCityData(res.data);
                          });
                          setValue({
                            field: 'state',
                            value: item.name,
                            data,
                          });
                        }}
                        onChangeValue={(value) =>
                          setValue({
                            field: 'state',
                            value,
                            data,
                          })
                        }
                        showArrow={true}
                        renderSelector={true}
                        variant="filled"
                        value={data.state}
                        valueField={'name'}
                        // keyField={'isoCode'}
                        field={'state'}
                        sugestionField={'name'}
                        options={stateData}
                        moreInputStyle={{
                          minHeight: 22,
                        }}
                        dropdownInputTheme={{
                          ...h14_AvenirNext,
                        }}
                        dropdownStyle={{
                          height: 150,
                          borderRadius: 8,
                          backgroundColor: '#ffffff',
                          ...moreActionsShadow,
                        }}
                      />
                    ) : (
                      <TextInput
                        data={data}
                        value={data.state}
                        field={'state'}
                        onChangeText={(value) =>
                          setValue({field: 'state', value, data})
                        }
                        variant="filled"
                        placeholder="Enter Your state"
                      />
                    )}
                  </View>
                  <View style={{flex: 1}}>
                    <Text
                      style={{
                        ...h16_Medium,
                        color: 'rgba(56, 56, 56, 0.37)',
                        marginBottom: 10,
                      }}>
                      City
                    </Text>

                    {cityData?.length ? (
                      <AutoSuggestInput
                        data={data}
                        onItemSelect={({item}) => {
                          setValue({
                            field: 'city',
                            value: item,
                            data,
                          });
                          console.log('@@2data', data);
                        }}
                        onChangeValue={(value) =>
                          setValue({
                            field: 'city',
                            value,
                            data,
                          })
                        }
                        showArrow={true}
                        renderSelector={true}
                        variant="filled"
                        value={data.city}
                        valueField={'name'}
                        keyField={'name'}
                        field={'city'}
                        sugestionField={'name'}
                        options={cityData}
                        disabled={cityData.length ? false : true}
                        moreInputStyle={{
                          minHeight: 22,
                        }}
                        dropdownInputTheme={{
                          ...h14_AvenirNext,
                        }}
                        dropdownStyle={{
                          height: 150,
                          borderRadius: 8,
                          backgroundColor: '#ffffff',
                          ...moreActionsShadow,
                        }}
                      />
                    ) : (
                      <TextInput
                        data={data}
                        value={data.city}
                        field={'city'}
                        onChangeText={(value) =>
                          setValue({field: 'city', value, data})
                        }
                        variant="filled"
                        placeholder="Enter Your city"
                      />
                    )}
                  </View>
                </View>
              </View>
            </View>

            {/* date and gender */}
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              {/* Date */}
              <View style={{flex: 1, marginRight: 15}}>
                <Text
                  style={{
                    ...h16_Medium,
                    color: 'rgba(56, 56, 56, 0.37)',
                    marginBottom: 10,
                  }}>
                  Date
                </Text>
                <DateInput
                  data={data}
                  field={'dob'}
                  value={data.dob}
                  onChangeValue={(value) => {
                    if (checkDob(value)) {
                      setValue({field: 'dob', value, data});
                      setDobError('');
                    } else {
                      setDobError('Please enter valid dob');
                    }
                  }}
                  variant="filled"
                  placeholder="Enter Your DOB"
                />
                {dobError && (
                  <Text
                    style={{...h12_Regular, color: 'red',}}
                    >
                    {dobError}
                  </Text>
                )}
              </View>
              {/* Gender*/}
              <View style={{flex: 1}}>
                <Text
                  style={{
                    ...h16_Medium,
                    color: 'rgba(56, 56, 56, 0.37)',
                    marginBottom: 10,
                  }}>
                  Gender
                </Text>
                <ChipsToggleInput
                  data={data}
                  field={'gender'}
                  value={data.gender}
                  onChangeValue={(value) =>
                    setValue({field: 'gender', value, data})
                  }
                  variant="filled"
                  options={[
                    {value: 'male', label: 'Male'},
                    {value: 'female', label: 'Female'},
                    {value: 'other', label: 'Other'},
                  ]}
                />
              </View>
            </View>

            <View style={{paddingRight: 50, paddingLeft: 50, paddingTop: 25}}>
              <TouchableOpacity
                style={{
                  backgroundColor: themeColor,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: 10,
                  borderRadius: 8,
                  height: 40,
                  cursor: 'pointer',
                }}
                onPress={() => {
                  !loading && submitInfo();
                  // UpdatePatient();
                  // navigation.push('send-OTP');
                }}>
                {loading ? (
                  <ActivityIndicator size="small" color={"#fff"} />
                ) : (
                  <Text style={{...h14_AvenirNext, color: '#fff'}}>
                    Confirm
                  </Text>
                )}
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const PatientSignUP = ({navigation}) => {
  return (
    <Form
    // uri={uri}
    >
      {({form_context, form_state}) => {
        return (
          <PatientForm
            navigation={navigation}
            form_context={form_context}
            form_state={form_state}
          />
        );
      }}
    </Form>
  );
};

export default PatientSignUP;
