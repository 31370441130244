import React, {useState, useEffect} from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  Image,
  ScrollView,
  isMobile,
} from '../../app-components';
import {
  h14_Medium,
  h20_AvenirNext_500,
  h18_Medium,
  h14_AvenirNext,
  h13_Regular,
  h12_Regular,
} from '../../theme/fonts';
import {
  AddressIcon,
  calenderIconBlue,
  Checkmark,
  tickIcon,
  clockIcon,
  ExperienceIcon,
  profile,
  TrophyIcon,
  mapMarker,
} from '../../images';
import {colors} from '../../theme/colors';
import {
  getCurrency,
  getProfileUrl,
  setInitiallyLogged,
  setSelectedDoctor,
} from '../../AppServices';
import {doctorProfileContainerShadow} from '../../theme/shadows';
import moment from 'moment';
import { resolveDoctorName } from '../../app-components/DoctorUtility';

const {themeColor, stackThemeColor, themeContainer, themeTextColor} = colors;

const DoctorProfile = ({navigation, t = () => {}, i18n}) => {
  // const {navigation = {}, t} = props || {};
  // console.log('navigation', navigation);
  const {state: {params = {}} = {}} = navigation || {};
  const {item = {}, index, openBooking, selectedTimeSlot, selectdDate} = params;
  const {organization: {currency = 'USD', account_status} = {}} = item || {};
  useEffect(() => {
    if (openBooking) {
      navigation.push({
        view: 'booking',
        params: {item, index, openBooking: true},
      });
    }
  }, []);

  const [showProfile, setShowProfile] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isDateAndTimeSelected, setIsDateAndTimeSelected] = useState(
    selectdDate || selectedTimeSlot ? true : false,
  );

  const awards = item?.award_and_achievement_description
    ? Array(item?.award_and_achievement_description)
    : [];

  const tabList = [
    {
      label: t('About'),
      visible: true,
    },
    {
      label: t('Specialization'),
      visible: true,
    },
    {
      label: t('Awards'),
      visible: true,
    },
  ];
  return (
    <ScrollView
      style={{
        flex: 1,
        // padding: 0,
        // backgroundColor: stackThemeColor,
      }}>
      {openBooking || selectedTimeSlot ? null : (
        <TouchableOpacity
          style={{
            zIndex: 10,
            cursor: 'pointer',
            marginLeft: 10,
          }}
          onPress={() => navigation.pop()}>
          <Text
            style={{
              ...h14_AvenirNext,
              lineHeight: 21,
              letterSpacing: 0.5,
              color: themeTextColor,
              // opacity: 0.54,
            }}>
            {'< '}
            {/* Back */}
            {t('Back')}
          </Text>
        </TouchableOpacity>
      )}
      <View
        style={{
          borderRadius: 16,
          margin: 10,
          ...doctorProfileContainerShadow,
          backgroundColor: themeContainer,
        }}>
        <View
          style={{
            paddingTop: isMobile ? 10 : 20,
            paddingBottom: 20,
            color: 'teal',
            alignItems: isMobile ? 'center' : undefined,
          }}>
          {isMobile ? (
            <View style={{marginRight: 15, marginLeft: 15}}>
              <Image
                source={getProfileUrl(item?.profile_picture?.file) || profile}
                style={{height: 100, width: 100, borderRadius: 50}}
              />
            </View>
          ) : (
            void 0
          )}
          <View
            style={{flexDirection: 'row', padding: isMobile ? 10 : undefined}}>
            {isMobile ? (
              void 0
            ) : (
              <View style={{marginRight: 15, marginLeft: 15}}>
                <Image
                  source={getProfileUrl(item?.profile_picture?.file) || profile}
                  style={{height: 100, width: 100, borderRadius: 50}}
                />
              </View>
            )}
            <View style={{flex: 1}}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // marginLeft: 15,
                  // marginRight: 15,
                }}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text
                    style={{
                      ...h20_AvenirNext_500,
                      fontWeight: 700,
                      lineHeight: 18,
                      color: themeTextColor,
                    }}>
                    {resolveDoctorName(item)}
                  </Text>
                  <Image
                    source={tickIcon}
                    style={{
                      height: 20,
                      width: 20,
                      marginLeft: 6,
                      marginRight: 6,
                    }}
                  />
                </View>
                <Text
                  style={{
                    ...h18_Medium,
                    color: themeTextColor,
                    paddingRight: 15,
                    paddingLeft: 15,
                  }}>
                  {item?.cost_of_booking!=undefined
                    ? getCurrency(currency) + ' ' + item?.cost_of_booking
                    : ''}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                }}>
                <View style={{flex: 1}}>
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      color: themeTextColor,
                      opacity: 0.87,
                      // marginLeft: 20,
                      // marginRight: 20,
                    }}>
                    {item?.specialities_procedures?.map(
                      (item) => item?.speciality?.name + ' ',
                    )}
                  </Text>
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      color: themeTextColor,
                      opacity: 0.87,
                    }}>
                    {item?.qualification_description}
                  </Text>
                </View>
                <Text
                  style={{
                    ...h14_AvenirNext,
                    color: themeTextColor,
                    opacity: 0.5,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}>
                  {item?.consultation_duration || ''} {t('min')}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 20,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 0,
                    marginLeft: 0,
                    backgroundColor: themeTextColor,
                    padding: 8,
                    borderRadius: 8,
                  }}>
                  <Image
                    source={ExperienceIcon}
                    style={{marginRight: 5, marginLeft: 5}}
                  />
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      color: themeColor,
                      // opacity: 0.5,
                    }}>
                    {/* 33 {t('yrs exp')} */}
                    {item?.experience_in_years || ''} {t('yrs exp')}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: themeTextColor,
                    padding: 8,
                    borderRadius: 8,
                    marginRight: 15,
                    marginLeft: 15,
                  }}>
                  <Image
                    source={AddressIcon}
                    style={{marginRight: 5, marginLeft: 5}}
                  />
                  <Text style={{...h14_AvenirNext, color: themeColor}}>
                    {item?.organization?.organization_name}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  backgroundColor: themeTextColor,
                  padding: 5,
                  borderRadius: 8,
                  marginRight: 15,
                  marginTop: 10,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Text style={{...h14_AvenirNext, color: themeColor}}>
                  {/* <span style={{fontWeight: 700}}>{t('Address')} : </span> */}
                  {item?.address?.description}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    navigation.push({
                      view: 'map-modal',
                      params: {item, t},
                      modal: true,
                      height: 700,
                      modalProps: {
                        autoHide: true,
                        position: 'screenCenter',
                      },
                    });
                  }}
                  style={{cursor: 'pointer'}}>
                  <Image
                    source={mapMarker}
                    style={{height: 22, width: 22}}></Image>
                </TouchableOpacity>
              </View>
              {isDateAndTimeSelected ? null : (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 30,
                  }}>
                  <View>
                    {navigation?.getActiveRoutes().includes('booking') ||
                    item?.cost_of_booking == undefined ||
                    account_status !== 'linked' ||
                    !item?.consultation_duration ? (
                      void 0
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          const routes = navigation?.getActiveRoutes();
                          if (!routes.includes('booking')) {
                            setSelectedDoctor(item.name);
                            setInitiallyLogged();
                            navigation.push({
                              view: 'doctor-profile',
                              params: {
                                item: {
                                  ...item,
                                  appointment_type: 'new',
                                  mayaAISessionId: item?.mayaAISessionId,
                                },
                                index,
                                openBooking: true,
                              },
                            });
                          }
                          console.log(item, navigation.getActiveRoutes());
                        }}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: '#fff',
                          // padding: 10,
                          minHeight: 35,
                          paddingTop: 5,
                          paddingBottom: 5,
                          borderRadius: 8,
                          minWidth: 100,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Text
                          style={{
                            color: themeColor,

                            fontWeight: 400,
                            ...h14_Medium,
                          }}>
                          Book
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                  {/* <View
                    style={{
                      backgroundColor: 'rgba(115, 197, 167, 0.1)',
                      borderRadius: 15,
                    }}>
                    <Text
                      style={{
                        ...h13_Regular,
                        color: '#57ccbf',
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingRight: 15,
                        paddingLeft: 15,
                      }}>
                      Available Today, 11:00 AM
                    </Text>
                  </View> */}
                  <TouchableOpacity
                    style={{cursor: 'pointer'}}
                    onPress={() => setShowProfile(!showProfile)}>
                    <Text
                      style={{
                        ...h14_Medium,
                        color: themeTextColor,
                        marginLeft: 10,
                        marginRight: 10,
                      }}>
                      {showProfile ? t('Hide Profile') : t('Show Profile')}
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        </View>
        {isDateAndTimeSelected ? (
          <View
            style={{
              // backgroundColor: 'rgba(171, 197, 235, 0.1)',
              flex: 1,
              padding: 30,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            }}>
            <View style={{flexDirection: 'row'}}>
              <View style={{flex: 1.25}}>
                <Text
                  style={{
                    ...h14_AvenirNext,
                    lineHeight: 21,
                    letterSpacing: 0.5,
                    color: themeTextColor,
                    // opacity: 0.7,
                  }}>
                  {/* Video Consultation Timings: */}
                  {t('Video Consultation Timings')}:
                </Text>
              </View>
              <View style={{flex: 1, marginLeft: 10}}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 5,
                    backgroundColor: themeTextColor,
                    padding: 5,
                    borderRadius: 8,
                  }}>
                  <Image
                    style={{
                      height: 16,
                      width: 16,
                      marginRight: 10,
                    }}
                    source={calenderIconBlue}
                  />
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      lineHeight: 21,
                      letterSpacing: 0.5,
                      color: themeColor,
                    }}>
                    {t('date', {date: new Date(selectdDate)})}
                    {/* {moment(selectdDate)?.format('MMM, DD YYYY')} */}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 5,
                    backgroundColor: themeTextColor,
                    // padding: 3,
                    paddingLeft: 3,
                    borderRadius: 8,
                    alignItems: 'center',
                  }}>
                  <Image
                    style={{
                      height: 16,
                      width: 16,
                      marginRight: 10,
                    }}
                    source={clockIcon}
                  />
                  <View style={{padding: 3}}>
                    <Text
                      style={{
                        //...h14_AvenirNext,
                        lineHeight: 21,
                        letterSpacing: 0.3,
                        color: themeColor,
                        display: 'flex',
                        ...h12_Regular,
                      }}>
                      {/* {`${moment(time_slot?.start_time).format('HH:mm A')} - ${moment(
                time_slot?.end_time,
              ).format('HH:mm A')}`} */}
                      {moment(selectedTimeSlot?.start_time)?.format('hh:mm a')}
                    </Text>
                    <Text
                      style={{
                        //...h14_AvenirNext,
                        lineHeight: 21,
                        letterSpacing: 0.3,
                        color: themeColor,
                        display: 'flex',
                        ...h12_Regular,
                      }}>
                      {item?.consultation_duration || ''} {t('min')}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{flex: 1}}>
                <TouchableOpacity
                  style={{
                    cursor: 'pointer',
                    alignSelf: 'flex-end',
                  }}
                  onPress={() => navigation.pop(2)}>
                  <Text
                    style={{
                      ...h12_Regular,
                      color: themeTextColor,
                      textAlign: 'right',
                    }}>
                    {/* Change date & time */}
                    {t('Change date & time')}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : (
          <View>
            {showProfile ? (
              <>
                <View
                  style={{
                    borderStyle: 'groove',
                    opacity: 0.2,
                    borderWidth: 1,
                    height: 1,
                    margin: 20,
                  }}
                />

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginRight: 20,
                    marginBottom: 20,
                    marginLeft: 20,
                    // borderBottom: 'inset',
                    // borderBottomWidth: 1.5,
                  }}>
                  {tabList?.map((tab, index) => {
                    const {label, visible} = tab;
                    {
                      if (visible) {
                        return (
                          <TouchableOpacity
                            style={{
                              cursor: 'pointer',
                              padding: 20,
                              borderBottom: 'solid',
                              borderColor:
                                selectedTab === index + 1
                                  ? themeTextColor
                                  : 'transparent',
                              borderBottomWidth: 3,
                            }}
                            onPress={() => setSelectedTab(index + 1)}>
                            <Text
                              style={{
                                ...h14_Medium,
                                color:
                                  selectedTab === index + 1
                                    ? themeTextColor
                                    : themeTextColor,
                                opacity: selectedTab === index + 1 ? 1 : 0.54,
                              }}>
                              {label}
                            </Text>
                          </TouchableOpacity>
                        );
                      } else {
                        return null;
                      }
                    }
                  })}
                </View>

                {selectedTab === 1 ? (
                  <View
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingBottom: 40,
                    }}>
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        color: themeTextColor,
                        opacity: 0.87,
                      }}>
                      {item?.about_me_description}
                    </Text>
                  </View>
                ) : null}

                {selectedTab === 2 ? (
                  <View
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingBottom: 40,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}>
                    {item?.specialities_procedures_name?.map((speciality) => {
                      return (
                        <View
                          style={{
                            backgroundColor: themeTextColor,
                            margin: 7,
                            borderRadius: 15,
                          }}>
                          <Text
                            style={{
                              ...h13_Regular,
                              color: themeColor,
                              opacity: 0.87,
                              paddingTop: 6,
                              paddingRight: 15,
                              paddingBottom: 6,
                              paddingLeft: 15,
                            }}>
                            {speciality}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                ) : null}

                {selectedTab === 3 ? (
                  <View
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingBottom: 40,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}>
                    {awards?.map((award) => {
                      return (
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: themeTextColor,
                            padding: 3,
                            borderRadius: 5,
                            width: 'fit-content',
                          }}>
                          <Image
                            source={TrophyIcon}
                            style={{height: 30, marginRight: 10}}
                          />
                          <Text
                            style={{
                              ...h13_Regular,
                              color: themeColor,
                              // opacity: 0.87,
                            }}>
                            {award}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                ) : null}
              </>
            ) : null}
          </View>
        )}
      </View>
      {isDateAndTimeSelected ? (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}>
          <Text
            style={{
              ...h12_Regular,
              color: '#fff',
              opacity: 0.7,
            }}>
            {t('consultAnotherDoc')}
            {/* Want to consult another doctor? */}
          </Text>
          <TouchableOpacity
            style={{
              marginLeft: 5,
              cursor: 'pointer',
            }}
            onPress={() => {
              navigation.reset({view: 'doctor-search'});
            }}>
            <Text
              style={{
                ...h12_Regular,
                color: '#fff',
              }}>
              {t('toSearchResult')}
              {/* Take me back to search results */}
            </Text>
          </TouchableOpacity>
        </View>
      ) : null}
    </ScrollView>
  );
};
export default DoctorProfile;
