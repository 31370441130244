import React, {useEffect, useState, memo} from 'react';
import {View, Text} from '../../../app-components';
import {h18_AvenirNext_500} from '../../../theme/fonts';
import AnalyticsCard from './AnalyticsCard';

const RevenueCard = memo(({getData, timeFilter}) => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData({id: 'getRevenueAnalysis', setResponse, setLoading});
  }, [timeFilter]);

  const data = [
    {
      header: 'Avg. Revenue per Patient',
      title: response?.avg_revenue_per_patient,

      // subTitle: '4%',
    },
  ];
  const data2 = [
    {
      header: 'Revenue per month',
      title: response?.revenue_per_month,
      // subTitle: '4%',
    },
  ];

  return (
    <View style={{flex: 1}}>
      <Text style={{...h18_AvenirNext_500}}>Revenue</Text>
      <AnalyticsCard cardContent={data} loading={loading} />
      <AnalyticsCard cardContent={data2} loading={loading} />
    </View>
  );
});

export default RevenueCard;
