import React, {useState} from 'react';
import {
  ActivityIndicator,
  TouchableOpacity,
  View,
  Text,
  Image,
} from '../../app-components';
import {colors, bgs} from '../../theme/colors';
import {closeIcon, delete_Image} from '../../images';
import {
  h14_AvenirNext,
  h14_Medium,
  h24_AvenirNext_500,
} from '../../theme/fonts';
import {removeData, downloadExcelData} from '../../AppServices';
const {textColor70,themeColor} = colors;

const Delete = (props) => {
  const [loading, setLoading] = useState(false);
  const {navigation = {}} = props || {};
  const {state: {params} = {}} = navigation;
  const {item, label, infoMessage = '', deleteAction, reloadEvent,modalWidth} = params;

  const deleteCall = async() => {
    let {eventDispatcher} = props || [];
    setLoading(true);
    await deleteAction();
    setLoading(false);
    eventDispatcher.notify(reloadEvent);
    navigation && navigation.pop();
  };
  const cancel = () => {
    navigation && navigation.pop();
  };

  return (
    <TouchableOpacity
      style={{
        height: 30,
        paddingLeft: 20,
        paddingTop: 20,
        paddingRight: 10,
        paddingBottom: 35,
      }}
      // style={deleteContainerViewStyle}
      onPress={(event) => event.stopPropagation()}>
      <TouchableOpacity
        onPress={cancel}
        style={{
          alignSelf: 'flex-end',
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Image source={closeIcon} style={{objectFit: 'contains'}} />
      </TouchableOpacity>

      <View
        style={{
          paddingLeft: 20,
          justifyContent: 'center',
          paddingRight: 20,
        }}>
        <View style={{flexDirection: 'row'}}>
          <View style={{maxWidth: modalWidth||193}}>
            <Text
              style={{
                color: '#f14f4f',
                ...h24_AvenirNext_500,
                opacity: 0.87,
                paddingBottom: 10,
              }}>
              {`Delete ${label}`}
            </Text>
            <Text
              style={{
                color: textColor70,
                ...h14_Medium,
                fontFamily: 'AvenirNext-Medium',
              }}>
              {`Are you sure you want to delete this ${label}?`}
            </Text>
          </View>
          <Image
            source={delete_Image}
            style={{objectFit: 'contains', height: 120, widthL: 120}}
          />
        </View>
        <View style={{borderWidth: 1, borderColor: '#ebebeb', bottom: 5}} />
        <View style={{flexDirection: 'row'}}>
          <TouchableOpacity
            style={{
              // ...cancelButtonStyle,
              // width: 120,
              cursor: 'pointer',
              marginTop: 20,
              marginLeft: 0,
              borderRadius: 4,
              paddingLeft: 49,
              paddingRight: 49,
              paddingTop: 11,
              paddingBottom: 11,
              backgroundColor: '#e7eef5',
            }}
            onPress={() => {
              cancel();
            }}>
            <View
              style={{
                alignItems: 'center',
              }}>
              <Text
                style={{
                  ...h14_AvenirNext,
                  color: 'black',
                  fontWeight: 500,
                  opacity: 0.54,
                }}>
                Cancel
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              // ...cancelButtonStyle,
              // width: 120,
              cursor: 'pointer',
              marginTop: 20,
              marginLeft: 29,
              borderRadius: 4,
              paddingLeft: 49,
              paddingRight: 49,
              paddingTop: 11,
              paddingBottom: 11,
              backgroundColor: themeColor,
            }}
            onPress={deleteCall}>
            {loading && <ActivityIndicator size="small" />}
            <View
              style={{
                alignItems: 'center',
                // padding: 5,
              }}>
              <Text
                style={{
                  ...h14_AvenirNext,
                  color: '#ffffff',
                  fontWeight: 500,
                  // opacity: 0.54,
                }}>
                Delete
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default Delete;
