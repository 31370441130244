import React from 'react';
import TabNavigator from '../../app-components/tab/TabNavigator';
import PatientData from './ContactList';
import {getUser} from '../../AppServices';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {organisationNameFilter} from '../../app-components/filter/MayaMDFilters';

export default TabNavigator({
  tabs: {
    tab1: {
      actions: [
        {
          visible: () => {
            let {userType} = getUser();
            return userType === 'ClinicAdmin' ? true : false;
          },
          render: () => {
            return <GradientButton label={'Add Message'} />;
          },
          type: 'link',
          link: {
            view: 'contact-support-form',
          },
        },
        {
          visible: () => {
            let {userType} = getUser();
            return userType === 'SuperAdmin' ? true : false;
          },
          render: organisationNameFilter,
        },
      ],
      label: 'Messages',
      reloadEvent: 'reloadSpeciality',
      screen: PatientData,
    },
  },
});
