import React, {useState} from 'react';
import {View, TextInput,ScrollView ,Text} from '../../app-components';
import {h14_Medium, h16_Regular} from '../../theme/fonts';
import {standardInputTheme} from '../../theme/inputTheme';
import moment from 'moment';
import { getAge } from '../../app-components/UtilityFunctions';
import { resolvePatientName } from '../../app-components/patientUtility';

const PatientProfile = (props) => {
  let {item: {patient_id: profileInfo = {}} = {}} = props;
 
  return (
    <ScrollView
    style={{
      flex: 1,
      margin: 15,
      padding: 10,
      // boxShadow: '0 2px 9px 0 rgba(0, 0, 0, 0.14)',
      // style={{backgroundColor: '#ffff', padding: 20}}
      // backgroundColor: 'rgb(248, 248, 248)',
    }} >
      <View style={{marginTop: 10}}>
        <View
          style={{
            marginLeft: 10,
            // alignItems: 'center',
            // justifyContent: 'center',
          }}>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 20,
            }}>
            <View style={{width: 150}}>
              <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                Name
              </Text>
            </View>
            <View>
              <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                {resolvePatientName(profileInfo) || 'N/A'}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 20,
              flex: 1,
            }}>
            <View style={{width: 150}}>
              <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                E-mail
              </Text>
            </View>
            <View>
              <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                {profileInfo?.email || 'N/A'}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 20,
            }}>
            <View style={{width: 150}}>
              <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                Mobile no.
              </Text>
            </View>
            <View>
              <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                {profileInfo?.mobile || 'N/A'}
              </Text>
            </View>
          </View>

          {profileInfo?.about_me_description && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  About me
                </Text>
              </View>
              <View style={{width: 200}}>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo?.about_me_description || 'N/A'}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.address && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  Physical Address
                </Text>
              </View>
              <View style={{width: 200}}>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo?.address?.description || 'N/A'}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.birthDate && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  Age
                </Text>
              </View>
              <View>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {getAge(profileInfo.birthDate)}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.blood_group && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  Blood Group
                </Text>
              </View>
              <View>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo.blood_group || 'N/A'}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.gender && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  Gender
                </Text>
              </View>
              <View>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo.gender || 'N/A'}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.surgical_history && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  Surgical History
                </Text>
              </View>
              <View style={{flex: 1, maxWidth: 'fit-content'}}>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo?.surgical_history
                    ? profileInfo?.surgical_history?.map(
                        (item, index, array) => {
                          const {name} = item;
                          if (array?.length === index + 1) return name + ' ';
                          return name + ', ';
                        },
                      )
                    : 'N/A '}
                  {profileInfo?.surgical_history_other &&
                    profileInfo?.surgical_history_other?.map(
                      (item, index, array) => {
                        if (array?.length === index + 1) return item;
                        return item + ', ';
                      },
                    )}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.allergies && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  Allergies
                </Text>
              </View>
              <View style={{flex: 1, maxWidth: 'fit-content'}}>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo?.allergies
                    ? profileInfo?.allergies?.map((item, index, array) => {
                        const {name} = item;
                        if (array?.length === index + 1) return name + ' ';
                        return name + ', ';
                      })
                    : 'N/A '}
                  {profileInfo?.allergies_other &&
                    profileInfo?.allergies_other?.map((item, index, array) => {
                      if (array?.length === index + 1) return item;
                      return item + ', ';
                    })}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.medications && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  Medications
                </Text>
              </View>
              <View style={{flex: 1, maxWidth: 'fit-content'}}>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo?.medications
                    ? profileInfo?.medications?.map((item, index, array) => {
                        const {name} = item;
                        if (array?.length === index + 1) return name;
                        return name + ', ';
                      })
                    : 'N/A '}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.medical_history && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                 Past Medical History
                </Text>
              </View>
              <View style={{flex: 1, maxWidth: 'fit-content'}}>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo?.medical_history
                    ? profileInfo?.medical_history?.map(
                        (item, index, array) => {
                          const {name_en:name} = item;
                          if (array?.length === index + 1) return name + ' ';
                          return name + ', ';
                        },
                      )
                    : 'N/A '}
                  {profileInfo?.medical_history_other &&
                    profileInfo?.medical_history_other?.map(
                      (item, index, array) => {
                        if (array?.length === index + 1) return item;
                        return item + ', ';
                      },
                    )}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.social_history && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  Social History
                </Text>
              </View>
              <View style={{flex: 1, maxWidth: 'fit-content'}}>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo?.social_history
                    ? profileInfo?.social_history?.map((item, index, array) => {
                        const {name} = item;
                        if (array?.length === index + 1) return name + ' ';
                        return name + ', ';
                      })
                    : 'N/A '}
                  {profileInfo?.social_history_other &&
                    profileInfo?.social_history_other?.map(
                      (item, index, array) => {
                        if (array?.length === index + 1) return item;
                        return item + ', ';
                      },
                    )}
                </Text>
              </View>
            </View>
          )}

          {profileInfo?.family_history && (
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <View style={{width: 150}}>
                <Text style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                  Family History
                </Text>
              </View>
              <View style={{flex: 1, maxWidth: 'fit-content'}}>
                <Text style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                  {profileInfo?.family_history
                    ? profileInfo?.family_history?.map((item, index, array) => {
                        const {name} = item;
                        if (array?.length === index + 1) return name + ' ';
                        return name + ', ';
                      })
                    : 'N/A '}

                  {profileInfo?.family_history_other &&
                    profileInfo?.family_history_other?.map(
                      (item, index, array) => {
                        if (array?.length === index + 1) return item;
                        return item + ', ';
                      },
                    )}
                </Text>
              </View>
            </View>
          )}
        </View>

        {/* {Object.entries(data).map(([key, value]) => {
          return (
            <View
              style={{
                marginTop: 15,
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <View style={{width: 200}}>
                <Text style={{...h14_Medium, color: '#9a9a9a'}}>{key}</Text>
              </View>
              <View style={{width: 200}}>
                {value ? (
                  <Text style={{...h14_Medium, color: 'rgba(0,0,0,0.7)'}}>
                    {value}
                  </Text>
                ) : (
                  <TextInput
                    {...standardInputTheme}
                    value={email}
                    onChangeValue={(value) => {
                      setEmail(value);
                    }}
                  />
                )}
              </View>
            </View>
          );
        })} */}
      </View>
    </ScrollView>
  );
};

export default PatientProfile;
