import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {multiSearchInput} from '../../autoSuggestions';
import {PLACEHOLDER} from '../../app-components/utils';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';

export default ({uri, header}) => {
  const Specialityform = Form({
    type: 'standardShadow',
    uri,
    onSubmit: submit({
      model: 'Specialities',
    }),
    reloadEvent: 'reloadSpeciality',
    closeView: 1,
    mandatory: {name: 1},
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 2,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'Speciality Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  // width: '50%',
                },
                {
                  type: 'text',
                  field: 'specialization',
                  label: 'Specialization Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  // width: '50%',
                },
                // multiSearchInput({
                //   field: 'procedures',
                //   query: 'procedureLists',
                //   model: 'Procedures',
                //   placeholder: 'Search Procedures',
                //   idField: '_id',
                //   searchField: 'name',
                //   suggestionField: 'name',
                //   valueField: 'name',
                //   // mandatory: true,
                //   label: 'Procedure',
                // }),
                {
                  field: 'logo',
                  placeholder: 'File',
                  label: 'Speciality Icon',
                  uploadOptions: {s3: false},
                  type: 'file',
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'Speciality Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                // multiSearchInput({
                //   field: 'procedures',
                //   query: 'procedureLists',
                //   model: 'Procedures',
                //   placeholder: 'Search Procedures',
                //   idField: '_id',
                //   searchField: 'name',
                //   suggestionField: 'name',
                //   valueField: 'name',
                //   // mandatory: true,
                //   label: 'Procedure',
                // }),
                {
                  field: 'logo',
                  placeholder: 'File',
                  label: 'Speciality Icon',
                  uploadOptions: {s3: false},
                  type: 'file',
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return Specialityform;
};
