import {findById} from './Utility';

const isMandatoryRequired = (data, field) => {
  let fieldValue = data && data[field];
  if (
    fieldValue === void 0 ||
    fieldValue === null ||
    (Array.isArray(fieldValue) && !fieldValue.length) ||
    (typeof fieldValue === 'string' && fieldValue.trim().length == 0)
  ) {
    return true;
  }
};

const populateMandatoryErrors = ({
  data,
  field,
  mandatory,
  mandatoryErrors,
  defaultMandatoryMessage,
}) => {
  if (mandatory && mandatory.hasOwnProperty(field)) {
    let mandatoryFieldInfo = mandatory[field];
    if (typeof mandatoryFieldInfo === 'function') {
      mandatoryFieldInfo = mandatoryFieldInfo({data, field});
    }
    if (mandatoryFieldInfo) {
      let mandatoryRequired = isMandatoryRequired(data, field);
      if (mandatoryRequired) {
        let message = mandatoryFieldInfo;
        if (
          (mandatoryFieldInfo === 1 || mandatoryFieldInfo === true) &&
          defaultMandatoryMessage
        ) {
          if (typeof defaultMandatoryMessage === 'function') {
            defaultMandatoryMessage = defaultMandatoryMessage({data, field});
          }
          message = defaultMandatoryMessage;
        }
        mandatoryErrors[field] = message;
      } else {
        delete mandatoryErrors[field];
      }
    }
  }
};

const populateValidationErrors = ({
  data,
  field,
  validations,
  validationErrors,
}) => {
  let validation = validations && validations[field];
  if (validation) {
    let validationKeyError = validation({data});
    if (validationKeyError) {
      validationErrors[field] = validationKeyError;
    } else {
      delete validationErrors[field];
    }
  }
};

const resolveAllMandatoryErrors = (props) => {
  let {mandatory} = props;
  for (let _field in mandatory) {
    let mandatoryInfo = mandatory[_field];
    if (typeof mandatoryInfo === 'object') {
      populateNestedMandatoryErrors({
        ...props,
        nestedField: _field,
      });
    } else {
      populateMandatoryErrors({
        ...props,
        field: _field,
      });
    }
  }
};

const resolveAllValidationErrors = (props) => {
  let {validations} = props;
  for (let _field in validations) {
    let validationInfo = validations[_field];
    if (typeof validationInfo === 'object') {
      populateNestedValidationErrors({
        ...props,
        nestedField: _field,
      });
    } else {
      populateValidationErrors({
        ...props,
        field: _field,
      });
    }
  }
};

const populateNestedMandatoryErrors = (props) => {
  let {
    data,
    field,
    mandatory,
    mandatoryErrors,
    defaultMandatoryMessage,
    nestedField,
    nestedId,
    innerNestedField,
    innerNestedId,
  } = props;
  let nestedFieldMandatory = mandatory ? mandatory[nestedField] : void 0;
  if (!nestedFieldMandatory) {
    return;
  }
  let nestedFieldErrors = {
    ...mandatoryErrors[nestedField],
  };
  let nestedFieldValue = data ? data[nestedField] : void 0;
  if (nestedFieldValue) {
    for (let nestedDoc of nestedFieldValue) {
      let _nestedId = nestedDoc._id;
      if (nestedId && nestedId !== _nestedId) {
        continue;
      }

      let nestedFieldIdErrors = {
        ...nestedFieldErrors[_nestedId],
      };

      let nestedErrorProps = {
        data: {
          ...nestedDoc,
          _parent: data,
        },
        mandatory: nestedFieldMandatory,
        mandatoryErrors: nestedFieldIdErrors,
        defaultMandatoryMessage,
      };
      if (field) {
        if (innerNestedField) {
          populateNestedMandatoryErrors({
            ...nestedErrorProps,
            field,
            nestedField: innerNestedField,
            nestedId: innerNestedId,
          });
        } else {
          populateMandatoryErrors({
            ...nestedErrorProps,
            field,
          });
        }
      } else {
        resolveAllMandatoryErrors(nestedErrorProps);
      }
      if (nestedFieldIdErrors && Object.keys(nestedFieldIdErrors).length) {
        nestedFieldErrors[_nestedId] = nestedFieldIdErrors;
      } else {
        delete nestedFieldErrors[_nestedId];
      }
    }
  }
  if (!field) {
    for (let _nestedId in nestedFieldErrors) {
      if (!findById(nestedFieldValue || [], '_id', {_id: _nestedId})) {
        delete nestedFieldErrors[_nestedId];
      }
    }
  }
  if (nestedFieldErrors && Object.keys(nestedFieldErrors).length) {
    mandatoryErrors[nestedField] = nestedFieldErrors;
  } else {
    delete mandatoryErrors[nestedField];
  }
};

const populateNestedValidationErrors = (props) => {
  let {
    data,
    field,
    validations,
    validationErrors,
    nestedField,
    nestedId,
    innerNestedField,
    innerNestedId,
  } = props;
  let nestedFieldValidations = validations ? validations[nestedField] : void 0;
  if (!nestedFieldValidations) {
    return;
  }
  let nestedFieldErrors = {
    ...validationErrors[nestedField],
  };
  let nestedFieldValue = data ? data[nestedField] : void 0;
  if (nestedFieldValue) {
    for (let nestedDoc of nestedFieldValue) {
      let _nestedId = nestedDoc._id;
      if (nestedId && nestedId !== _nestedId) {
        continue;
      }

      let nestedFieldIdErrors = {
        ...nestedFieldErrors[_nestedId],
      };

      let nestedErrorProps = {
        data: {
          ...nestedDoc,
          _parent: data,
        },
        validations: nestedFieldValidations,
        validationErrors: nestedFieldIdErrors,
      };
      if (field) {
        if (innerNestedField && innerNestedId) {
          populateNestedValidationErrors({
            ...nestedErrorProps,
            field,
            nestedField: innerNestedField,
            nestedId: innerNestedId,
          });
        } else {
          populateValidationErrors({
            ...nestedErrorProps,
            field,
          });
        }
      } else {
        resolveAllValidationErrors(nestedErrorProps);
      }
      if (nestedFieldIdErrors && Object.keys(nestedFieldIdErrors).length) {
        nestedFieldErrors[_nestedId] = nestedFieldIdErrors;
      } else {
        delete nestedFieldErrors[_nestedId];
      }
    }
  }
  if (!field) {
    for (let _nestedId in nestedFieldErrors) {
      if (!findById(nestedFieldValue || [], '_id', {_id: _nestedId})) {
        delete nestedFieldErrors[_nestedId];
      }
    }
  }
  if (nestedFieldErrors && Object.keys(nestedFieldErrors).length) {
    validationErrors[nestedField] = nestedFieldErrors;
  } else {
    delete validationErrors[nestedField];
  }
};

const validate = (
  state,
  {mandatory, validations, field, mandatoryMessage, path},
) => {
  let {mandatoryErrors = {}, validationErrors = {}, data} = state;
  mandatoryErrors = {...mandatoryErrors};
  validationErrors = {...validationErrors};
  let mandatoryProps = {
    data,
    mandatory,
    mandatoryErrors,
    defaultMandatoryMessage: mandatoryMessage,
  };
  let validationProps = {
    data,
    validations,
    validationErrors,
  };
  if (field) {
    mandatoryProps.field = field;
    validationProps.field = field;
    if (path && path.length) {
      let {_id: nestedId, field: nestedField} = path[0];
      let nestedProps = {
        nestedField,
        nestedId,
      };
      if (path.length > 1) {
        let {_id: innerNestedId, field: innerNestedField} = path[1];
        nestedProps.innerNestedField = innerNestedField;
        nestedProps.innerNestedId = innerNestedId;
      }
      populateNestedMandatoryErrors({
        ...mandatoryProps,
        ...nestedProps,
      });
      populateNestedValidationErrors({
        ...validationProps,
        ...nestedProps,
      });
    } else {
      populateMandatoryErrors(mandatoryProps);
      populateValidationErrors(validationProps);
    }
  } else {
    resolveAllMandatoryErrors(mandatoryProps);
    resolveAllValidationErrors(validationProps);
  }

  return {
    ...state,
    mandatoryErrors,
    validationErrors,
    hasMandatoryError: Object.keys(mandatoryErrors).length > 0,
    hasValidationError: Object.keys(validationErrors).length > 0,
  };
};

export {validate};
