import Form from '../../app-components/form/Form';
import {save} from '../../app-components/action/SaveAction';
import {submit, getUser} from '../../AppServices';
import {cancel} from '../../app-components/action/CancelAction';

const header = {
  title: 'Contact Support',
  actions: [cancel(), save({label: 'Save'})],
};

export default Form({
  type: 'standardShadow',
  closeView: 1,
  // uri,
  reloadEvent:"ContactListUpdate",
  onSubmit: submit({
    model: 'Contacts',
    lowerCaseFields: ['email'],
  }),
  defaultValues: () => {
    const {organization = {}} = getUser();
    return {
      organization_name: {_id: organization?._id},
    };
  },

  header,
  mandatory: {
    // email: 1,
    subject: 1,
    message: 1,
  },
  formGroups: [
    {
      columnsPerRow: 2,
      fieldVariant: 'filled',
      groups: [
        {
          columns: [
            // {
            //   type: 'text',
            //   field: 'email',
            //   placeholder: 'Email',
            //   mandatory: true,
            // },
            {
              type: 'text',
              field: 'subject',
              placeholder: 'subject',
              mandatory: true,
            },
            {
              type: 'textArea',
              // minWidth: '100%',
              minHeight: 70,
              field: 'message',
              placeholder: 'Message',
              mandatory: true,
            },
            {
              field: 'attachments',
              placeholder: 'File',
              label: 'File',
              uploadOptions: {s3: true},
              type: 'file',
              multiple: true,
            },
          ],
        },
      ],
    },
  ],
});
