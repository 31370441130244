import React, { useCallback, useMemo, useState } from 'react';
import { View, TouchableOpacity, Text, Snackbar } from '../../app-components';

import { GradientButton } from '../../app-components/buttons/Buttons';
import { invoke } from '../../AppServices';
import { h22_SemiBold } from '../../theme/fonts';

const ImmediateTakeaways = (props) => {
  let { navigation = {}, eventDispatcher = {} } = props || {};
  const {
    navigation: {
      state: {
        params: { immediateTakeawaysDefaultValue, patientItem, language },
      },
    },
  } = props;
  
  const [immediateTakeaways, setImmediateTakeaways] = useState(immediateTakeawaysDefaultValue.replace(/.*(?<=\/\/)/g,'') || '');
  const [isLoading, setLoading] = useState(false);

  const handleOnSubmit = useCallback(() => {
    setLoading(true);
    if (immediateTakeaways) {
      invoke({
        id: 'updateImmediateTakeaways',
        paramValue: {
          _id: patientItem,
          immediateTakeaways,
          language: language,
        },
      })
        .then((res) => {
          setLoading(false);
          Snackbar.show({
            text: res?.result,
            duration: Snackbar.LENGTH_LONG,
          });
          eventDispatcher.notify('reloadOrderDetails');
          navigation.pop(2);
        })
        .catch((err) => {
          setLoading(false);
          Snackbar.show({
            text: err,
            duration: Snackbar.LENGTH_LONG,
          });
          navigation.pop(2);
        });
    } else {
      setLoading(false);
      Snackbar.show({
        text: 'Please fill Immediate Takeaways details',
        duration: Snackbar.LENGTH_LONG,
      });
    }
  }, [eventDispatcher, immediateTakeaways, navigation, patientItem]);

  return (
    <View
      style={{
        backgroundColor: '#f9f9f9',
        padding: 20,
        borderRadius: 8,
        width: 500,
      }}>
      <View
        style={{
          marginTop: 10,
          fontSize: '40px',
          marginBottom: 20,
        }}>
        <Text style={{fontSize: '45px', color: '#275dad', ...h22_SemiBold}}>
          Immediate Takeaways
        </Text>
      </View>
      <View style={{marginBottom: 15}}>
        <textarea
          name="immediateTakeaways"
          value={immediateTakeaways}
          onChange={(e) => setImmediateTakeaways(e.target.value)}
          rows="15"
          autoFocus={true}
        />
      </View>
      <View style={{justifyContent: 'flex-end', flexDirection: 'row'}}>
        <TouchableOpacity
          onPress={() => {
            navigation.pop();
          }}
          style={{
            alignItems: 'flex-end',
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 10,
            cursor: 'pointer',
            marginRight: '5%',
          }}>
          <GradientButton label={'Cancel'} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={handleOnSubmit}
          style={{
            alignItems: 'flex-end',
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 10,
            cursor: 'pointer',
          }}>
          <GradientButton loading={isLoading} label={'Submit'} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ImmediateTakeaways;
