import React, {useState, useEffect} from 'react';
import {backgroundImage1} from '../../images';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
} from '../../app-components';
import {TextInput} from '../../app-components/input-components/Editors';
import {navigationBarStyle} from '../../theme/navigationBarTheme';
import {
  h14_AvenirNext,
  h14_Regular,
  h20_AvenirNext_500,
  h12_Regular,
} from '../../theme/fonts';
import {HospitalLOGO} from '../../images';
import {dropDownShadow} from '../../theme/shadows';
import {invoke, mayamd_authenticate} from '../../AppServices';
import {ActivityIndicator} from '../../app-components';
import {colors} from '../../theme/colors';
const {themeColor}=colors

const PatientOTP = (props) => {
  const {navigation} = props;
  const mobile = navigation.getParam('mobile');
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState('');
  const [otpError, setOtpError] = useState('');
  const setValue = (text) => {
    setOTP(text);
  };
  useEffect(() => {
    if (!mobile) {
      navigation.reset('patient-signIn');
    }
  }, []);
  const verifyOTP = async () => {
    if (!otp.length) {
      setOtpError('OTP is required');
    } else {
      setLoading(true);
      mayamd_authenticate({
        mobile,
        password: otp,
      })
        .then((res) => {
          if (res?.user) {
            navigation.navigate('authenticator');
            // navigation.reset('doctor-search');
          } else {
            setOtpError('Wrong OTP');
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <ScrollView
      style={{
        backgroundImage: `url(${backgroundImage1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flex: 1,
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 30,
          paddingRight: 30,
          height: 79,
        }}>
        <Image source={HospitalLOGO} style={{height:70,width:200,objectFit:"contain"}}/>
      </View>

      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <View
          style={{
            ...dropDownShadow,
            backgroundColor: 'white',
            padding: 40,
            borderRadius: 15,
            width: '40%',
          }}>
          <View>
            <Text
              style={{
                ...h20_AvenirNext_500,
                color: '#2e6ecd',
                fontWeight: '700',
              }}>
              Enter OTP
            </Text>
          </View>
          <View style={{marginTop: 10, marginBottom: 16}}>
            <TextInput
              data={otp}
              field={'otp'}
              value={otp}
              onChangeText={(value) => setOTP(value)}
              onSubmitEditing={(event) => {
                if (event.key === 'Enter') {
                  !loading && verifyOTP && verifyOTP();
                }
              }}
              inputProps={{
                type: 'number',
              }}
              variant="filled"
              placeholder="6 digit OTP"
            />
            {otpError && (
              <Text style={{...h12_Regular, color: 'red'}}>{otpError}</Text>
            )}
          </View>

          <View>
            <Text
              style={{
                ...h14_Regular,
                letterHeight: 21,
                letterSpacing: 0.5,
                color: '#000',
                opacity: 0.54,
              }}>
              We have sent OTP on {mobile}
            </Text>
          </View>

          <View style={{paddingRight: 50, paddingLeft: 50, paddingTop: 65}}>
            <TouchableOpacity
              style={{
                backgroundColor: themeColor,
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: 10,
                borderRadius: 8,
                height: 40,
                cursor: 'pointer',
              }}
              onPress={() => {
                !loading && verifyOTP();
              }}>
              {loading ? (
                <ActivityIndicator size="small" color={"#fff"} />
              ) : (
                <Text style={{...h14_AvenirNext, color: '#fff'}}>Verify </Text>
              )}
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default PatientOTP;
