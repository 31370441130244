import TabView from './TabView';
import BottomTabView from './BottomTabView';
import CardView from './CardView';
import TableView from './TableView';
import FormView from './FormView';
import {close} from '../../app-components/action/CloseAction';
import ExpandAction from '../../app-components/action/ExpandAction';
import FormWorking from './FormWorking';
import CardViewWorking from './CardViewWorking';

export default {
  'tab-view': {
    screen: TabView,
  },
  'bottom-tab-view': {
    screen: BottomTabView,
  },
  'table-view': {
    screen: TableView,
  },
  'card-view': {
    screen: CardViewWorking,
    header: {
      title: 'Card',
      actions: [ExpandAction, close],
    },
  },
  'form-view': {
    screen: FormView,
  },
  'modal-view': {
    screen: FormWorking,
  },
};
