import {
  Image,
  View,
  Text,
  TextInput,
  NumberInput,
  ScrollView,
  TouchableOpacity,
  Platform,
  Linking,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableWithoutFeedback,
} from '../npms/react-core-components';

import {TabPanel} from '../npms/react-tab-panel-v1';
import ToolBar from './toolbar/ToolBar';

import {DataFetch} from '../npms/react-datafetch';
import {Stack} from '../npms/react-stack-navigator-v1';
import {AsyncStorage as Storage} from '../npms/react-async-storage';
import {
  ReactModalView,
  ReactModalViewComponentContext,
  ReactModalViewListenerContext,
  ReactModalViewContextProvider,
} from '../npms/react-modal-view';
import {progressIndicatorStyle} from '../theme/progressIndicatorTheme';
import {
  AppKeyboardListener,
  AppKeyboardListenerContext,
} from '../npms/react-app-keyboard-listener';
import {ReactEventDispatcher} from '../npms/react-event-dispatcher';
import {ProgressIndicator as ProgressIndicatorHoc} from '../npms/react-activity-indicator';
import Box from './Box';
import {ActivityIndicator} from '../npms/react-activity-indicator';
import {WithModal as WithModalButton} from '../npms/react-with-modal';
import {Snackbar} from '../npms/react-snack-bar';
import {DatePicker} from '../npms/react-date-input';
import {GpsStore} from '../npms/react-gps-store';
const detectMob = () => {
  if (
    Platform.OS !== 'web' ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

const isMobile = detectMob();
const isLocalHost = document.domain === 'localhost';

const ProgressIndicator = ProgressIndicatorHoc({
  View,
  theme: progressIndicatorStyle,
});
export {
  GpsStore,
  Snackbar,
  Dimensions,
  Box,
  View,
  Text,
  Image,
  TextInput,
  NumberInput,
  ScrollView,
  TabPanel,
  DataFetch,
  Stack,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Platform,
  Linking,
  Storage,
  ToolBar,
  ReactModalView,
  ReactModalViewComponentContext,
  ReactModalViewListenerContext,
  ReactModalViewContextProvider,
  AppKeyboardListener,
  AppKeyboardListenerContext,
  ReactEventDispatcher,
  ActivityIndicator,
  ProgressIndicator,
  WithModalButton,
  FlatList,
  isMobile,
  detectMob,
  DatePicker,
  StyleSheet,
  isLocalHost
};
