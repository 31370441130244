import React from 'react';
import {
  TouchableOpacity,
  View,
  Text,
  Image,
  Platform,
} from '../../react-core-components';

class Button extends React.Component {
  state = {};

  onMouseOver = e => {
    this.setState({
      hover: true,
    });
  };

  onMouseOut = e => {
    this.setState({
      hover: false,
    });
  };

  render() {
    let {
      className,
      size,
      outline,
      gap,
      icon,
      iconStyle,
      rightIcon,
      rightIconStyle,
      style,
      loading,
      loadingStyle,
      rightLoading,
      rightLoadingStyle,
      loaderComponent,
      label,
      loadingLabel,
      disabled,
      color,
      hoverColor,
      disabledColor,
      onClick,
      onPress,
      hoverable = true,
      labelStyle,
      hoverLabelStyle,
      disabledLabelStyle,
      borderWidth,
      height,
      width,
      minWidth,
      maxWidth,
      borderRadius,
      link,
      children,
    } = this.props;

    if (size && this.props[`style-${size}`]) {
      style = this.props[`style-${size}`];
    }
    let {hover} = this.state;
    let extraStyle = {};
    if (!link) {
      if (disabled) {
        if (disabledColor) {
          color = disabledColor;
        } else {
          extraStyle.opacity = 0.3;
        }
      } else if (hover && hoverColor) {
        color = hoverColor;
      }
      if (!outline || hover) {
        extraStyle.backgroundColor = color;
      }
      extraStyle.borderColor = color;
      extraStyle.borderWidth = borderWidth || 1;
      if (height) {
        extraStyle.height = height;
      }
      if (width) {
        extraStyle.width = width;
      }
      if (minWidth) {
        extraStyle.minWidth = minWidth;
      }
      if (maxWidth) {
        extraStyle.maxWidth = maxWidth;
      }
      if (borderRadius) {
        extraStyle.borderRadius = borderRadius;
      }
    }
    let renderComponent = children;
    if (!renderComponent) {
      if (label && loading && loadingLabel) {
        label = loadingLabel;
      }
      if (icon || rightIcon || label) {
        renderComponent = (
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}>
            {loading ? (
              <View style={loadingStyle}>{loaderComponent}</View>
            ) : (
              void 0
            )}
            {icon ? <Image style={iconStyle} source={icon} /> : void 0}
            {gap && icon && label ? <View style={{width: gap}} /> : void 0}
            {label ? (
              <Text
                style={{
                  ...labelStyle,
                  ...(disabled
                    ? disabledLabelStyle
                    : hover
                    ? hoverLabelStyle
                    : outline
                    ? {color}
                    : void 0),
                }}>
                {label}
              </Text>
            ) : (
              void 0
            )}
            {gap && rightIcon && label ? <View style={{width: gap}} /> : void 0}
            {rightIcon ? (
              <Image style={rightIconStyle} source={rightIcon} />
            ) : (
              void 0
            )}
            {rightLoading ? (
              <View style={rightLoadingStyle}>{loaderComponent}</View>
            ) : (
              void 0
            )}
          </View>
        );
      }
    }
    if (!renderComponent) {
      return null;
    }
    let containerProps = {
      style: {
        ...extraStyle,
        ...style,
      },
    };
    if (Platform.OS === 'web') {
      containerProps.className = className;
      containerProps.style.cursor = disabled ? 'not-allowed' : 'pointer';
      if (hoverable && !disabled) {
        containerProps.onMouseOver = this.onMouseOver;
        containerProps.onMouseOut = this.onMouseOut;
      }
    }
    if (onClick) {
      onPress = onClick;
    }
    if (onPress) {
      return (
        <TouchableOpacity {...containerProps} onPress={onPress}>
          {renderComponent}
        </TouchableOpacity>
      );
    } else {
      return <View {...containerProps}>{renderComponent}</View>;
    }
  }
}

Button.defaultProps = {
  style: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  'style-sm': {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
  'style-lg': {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
  iconStyle: {height: 16, width: 16},
  rightIconStyle: {height: 16, width: 16},
  loadingStyle: {
    paddingRight: 8,
  },
  rightLoadingStyle: {
    paddingLeft: 8,
  },
  gap: 8,
  color: '#6d91f0',
  hoverColor: '#4276ec',
  disabledColor: '#f0f0f0',
  labelStyle: {
    color: '#ffffff',
    numberOfLines: 1,
  },
  disabledLabelStyle: {
    color: '#c1c1c1',
  },
};

export default Button;
