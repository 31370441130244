export const knowAboutPPHMData = [
  {
    label: 'Headman',
    value: 'headman',
  },
  {
    label: 'ASHA',
    value: 'asha',
  },
  {
    label: 'Anganwadi',
    value: 'anganwadi',
  },
  {
    label: 'Announcement',
    value: 'announcement',
  },
  {
    label: 'Whatsapp forwards',
    value: 'whatsapp_forwards',
  },
  {
    label: 'Word of mouth',
    value: 'word_of_mouth',
  },
  {
    label: 'Poster',
    value: 'poster',
  },
  {
    label: 'YouTube Influencers',
    value: 'youTube_influencers',
  },
  {
    label: 'Others',
    value: 'other',
  },
];
