import React from 'react';

import Table from '../../app-components/table/Table';
import moment from 'moment';
import {
  resolvePatientMrn,
  resolvePatientName,
} from '../../app-components/patientUtility';
import { getUser, post, removeData, resendOtp } from '../../AppServices';
import { View, Image } from '../../app-components';
import {
  redCloseIcon,
  tickIcon,
  weighingMachine,
  glucose,
  Bp,
  redWeighingMachine,
  redGlucose,
  redBp,
} from '../../images';
import PatientDetails from './PatientDetails';
import { ToggleInput } from '../../app-components/input-components/Editors';
import { ToggleSwitchInput } from '../../app-components/input-components/Editors';
import { colors } from '../../theme/colors';
import { getAge, getAgeFromDob } from '../../app-components/UtilityFunctions';
const imgMap = {
  'Tenovi Scale': {
    active: weighingMachine,
    err: redWeighingMachine,
    name: 'Weighing Scale',
  },
  'Tenovi Glucometer': { active: glucose, err: redGlucose, name: 'Glucometer' },
  'Tenovi BPM': { active: Bp, err: redBp, name: 'Blood Pressure Monitor' },
};
const uri = (props) => {
  const { navigation } = props;
  const { state: { params: { item = {} } = {} } = {} } = navigation;

  let addOnFilter = {};
  if (item) {
    addOnFilter = {
      userType: {$nin:['Patient','SuperAdmin']},
    };
  }
  return {
    query: {
      id: 'userLists',
      addOnFilter: addOnFilter,
      sort: { _id: -1 },
    },
    model: 'User',
  };
};
export default Table({
  uri,
  reloadEvent: 'reloadPatient',
  reloadOnChangeEvent: 'reloadPatient',
  action: ({ item }) => {
    return {
      type: 'link',
      link: {
        view: 'userDetailForm',
        params: { item },
      },
    };
  },
  columns: [
    {
      header: 'Name',
      type: 'text',
      field: 'name',
    },
    {
      header: 'Email',
      type: 'text',
      field: 'email',
    },
    {
      header: 'Phone No.',
      type: 'text',
      field: 'mobile',
    },
    {
      header: 'Role',
      type: 'text',
      field: 'userType',
      },
      {
        type: 'moreActions',
        actions: [
          {
            text: 'Edit Details',
            onPress: async (props) => {
              const { item, navigation } = props;
              navigation.push({
                view: 'editUserDetail',
               params: {
                  patientId: item?._id,
                },
              });
            },
          }
        ]
      },
  ],
});
