import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from '../../app-components';
import {getUser, mayaAISession} from '../../AppServices';
import uuid from 'uuid/v4';
import {getData, gpsStore} from '../../AppServices';
import {colors} from '../../theme/colors';
import {h14_Medium, h20_AvenirNext_600} from '../../theme/fonts';
import {isString} from 'lodash';
import {graphImage} from '../../images';
import {whiteCrossIcon} from '../../images';
import PatientGraph from '../RPM/PatientDashBoard/DataAnalysis';
const {themeColor} = colors;

let socketUid = uuid();

const Assistant = (props) => {
  const {navigation = {}} = props || {};
  const [socketData, setSocketData] = useState({});
  const [sessionId, setSessionId] = useState('');
  const [toggleIframe, setToggleIframe] = useState(true);
  const {mobile} = getUser() || {};

  const getSessionId = async () => {
    const sessionId = await mayaAISession({mobile});
    setSessionId(sessionId);
  };
  useEffect(() => {
    if (toggleIframe) {
      getSessionId();
    }
  }, [toggleIframe]);

  useEffect(() => {
    socketSubscription();
    return () => {
      unsubscribSocket();
    };
  }, []);

  const callbackAppointmentUpdate = (data) => {
    setTimeout(() => {
      setSocketData(data);
      setToggleIframe(false);
    }, 8000);
    console.log('@@@@@>>WaitingRoom->callbackAppointmentUpdate->data', data);
  };
  const subscribeMeetingCallback = (data) => {
    console.log('maya>>>ai>>socket>>connected>>sucessfully');
  };

  const socketSubscription = () => {
    let user_id = '';
    let {patient: {_id: patientId} = {}} = getUser();
    user_id = patientId;
    gpsStore.subscribeEvent({
      event: 'triageEvent',
      callback: callbackAppointmentUpdate,
    });
    gpsStore.emitDataOnConnect({
      uid: socketUid,
      event: 'subscribeGroup',
      data: {
        groupId: `${user_id}__new`,
        sessionId: socketUid,
        source: 'web',
      },
      callback: subscribeMeetingCallback,
    });
  };

  const unsubscribSocket = () => {
    if (gpsStore && gpsStore.removeEmitDataOnConnect) {
      gpsStore.removeEmitDataOnConnect({uid: socketUid});
      gpsStore.unsubscribeEvent({
        event: 'triageEvent',
        callback: callbackAppointmentUpdate,
      });
    }
  };

  const getButtonText = () => {
    // console.log('>>>>>>>', socketData);
    const {searchTerm = ''} = socketData || {};
    if (isString(searchTerm) && searchTerm.toLowerCase() === 'urgent care') {
      return {label: 'Waiting Room', navigationTo: 'WaitingRoom'};
    } else if (
      isString(searchTerm) &&
      searchTerm.toLowerCase() === 'emergency room'
    ) {
      return {label: 'Emergency', navigationTo: 'EmergencyRoom'};
    } else {
      return {label: 'Schedule an appointment', navigationTo: 'doctor-search'};
    }
  };
  const {searchTerm = ''} = socketData || {};

  const defaultScreenState = ({navigation}) => {
    let {state: {params: {searchData: {type, value}} = {}} = {}} = navigation;
    let dataParams = {};
    if (!value.name) {
      return void 0;
    }
    dataParams.filters = {
      [type]: {
        value,
        filter: {
          filterType: 'nameAutoSuggest',
          value,
        },
      },
    };
    return {dataParams};
  };
  const navigateToDoctor = () => {
    navigation.reset({
      view: 'doctor-search',
      params: {
        mayaAISessionId: sessionId,
        searchData: {
          type: 'speciality',
          value: {name: searchTerm || '', _id: '12232'},
        },
      },
      routeOptions: {
        expanded: true,
        defaultScreenState,
      },
    });
  };

  return (
    <View
      style={{
        width: '100%',
        height: '96vh',
        justifyContent: 'center',
        // position: 'relative',
        flexDirection: 'row',
        // backgroundColor: 'red',
      }}>
      <TouchableOpacity
        onPress={() => {
          navigation.pop();
        }}
        style={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}}>
        <Image source={whiteCrossIcon} style={{height: 15, width: 15}}></Image>
      </TouchableOpacity>
      {/* {toggleIframe ? (
        
        <Image source={whiteCrossIcon} style={{height: 15, width: 15}} />
      </TouchableOpacity>
      {/* <Image source={graphImage} style={{width: '90%', height: '90%'}} /> */}
      <View
        style={{
          backgroundColor: 'rgba(1, 59, 86, 0.35)',
          width: '100%',
          borderRadius: 20,
          padding: 10,
        }}
      >
      <PatientGraph />
      </View>
      {/* {toggleIframe ? (

      ) : (
        void 0
      )} */}
      <View
        style={{
          backgroundColor: '#fff',
          width: 350,
          display: !toggleIframe ? 'flex' : 'none',
          borderRadius: 8,
          padding: 10,
          position: 'absolute',
          bottom: 20,
          left: 20,
        }}>
        <Text style={{...h20_AvenirNext_600, color: '#383838'}}>
          <span style={{color: 'black'}}>Thank you, </span>
          your session is completed.
        </Text>

        <View style={{flexDirection: 'row', marginTop: 10}}>
          <TouchableOpacity
            onPress={() => {
              setToggleIframe(true);
              setSocketData({});
            }}
            style={{
              minWidth: 80,
              maxWidth: 150,
              backgroundColor: '#275DAF' || themeColor,
              height: 40,
              display: 'flex',
              borderRadius: 8,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginRight: 10,
            }}>
            <Text style={{...h14_Medium, color: '#fff'}}>Restart</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              const {label, navigationTo} = getButtonText();
              if (navigationTo === 'doctor-search') {
                navigateToDoctor();
              }
              if (navigationTo === 'WaitingRoom') {
                navigation.push('WaitingRoom');
              }
              if (navigationTo === 'EmergencyRoom') {
                navigation.push('EmergencyRoom');
              }
            }}
            style={{
              minWidth: 100,
              // maxWidth: 190,
              paddingLeft: 10,
              paddingRight: 10,
              backgroundColor: '#275DAF' || themeColor,
              height: 40,
              display: searchTerm !== '' ? 'flex' : 'none',
              borderRadius: 8,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <Text style={{...h14_Medium, color: '#fff'}}>
              {getButtonText().label}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default Assistant;
