import React, { useCallback } from 'react';
import { Image, ScrollView, TouchableOpacity, View } from '../../app-components';
import { getImageUrl } from '../../AppServices';
import { downloadIconFile, redCloseIcon } from '../../images';

const ShowPopupImage = (props) => {
  const { navigation = {} } = props;
  const {
    state: {
      params: { dataArr = {} },
    },
  } = navigation;

  const download = useCallback(() => {
    const { key = '' } = dataArr || {};
    const image = getImageUrl(key);
    fetch(`${image}`).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = key;
        a.click();
      });
    });
  }, [dataArr]);

  return (
    <View style={{ flex: 1, overflow: 'hidden', }}>
      <TouchableOpacity
        style={{
          position: 'absolute',
          zIndex: 99999,
          marginLeft: 10,
          cursor: 'pointer',
          top: 8,
          right: 15,
        }}
        onPress={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const { navigation = {} } = props;
          navigation.pop();
        }}>
        <Image source={redCloseIcon} style={{ height: 25, width: 25 }} />
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          position: 'absolute',
          zIndex: 99999,
          marginLeft: 10,
          cursor: 'pointer',
          top: 8,
          // left: 5,
        }}
        onPress={(e) => {
          e.stopPropagation();
          e.preventDefault();
          download();
        }}>
        <Image source={downloadIconFile} style={{ height: 25, width: 25 }} />
      </TouchableOpacity>
      <ScrollView style={{ flex: 1, margin: '10%' }}>
        <Image
          source={{ uri: getImageUrl(dataArr?.key) }}
          style={{ width: '100%' }}
        />
      </ScrollView>
    </View>
  );
};

export default ShowPopupImage;
