import React from 'react';
import ClinicDetail from './ClinicDetail';
import ClinicPerformance from './ClinicPerformance';
import ClinicSummary from './ClinicSummary';
import ClinicSummaryForm from './ClinicSummaryForm';
import ClinicTeam from './team/index';
import AddClinicAdmin from './team/AddClinicAdmin';
import AddDoctors from './team/AddDoctors';
import EditDoctor from './team/EditDoctor';
import AddFrontDesk from './team/AddFrontDesk';
import ThankYou from './ThankYou';
import EditClinicAdmin from './team/EditClinicAdmin';
import EditFrontDesk from './team/EditFrontDesk ';
// import {backIcon} from '../../../images';
import DoctorSchedule from './team/DoctorScheduleForm';
import RazorpayModal from './RazorpayModal';
import CustomHeaderLabel from '../../../app-components/CustomHeaderLabel';
import {getUser} from '../../../AppServices';
import {back} from '../../../app-components/action/CloseAction';
import AddLabMember from './team/AddLabMember';
import EditLabMember from './team/EditLabMember';
import AddMedicalAssistant from './team/AddMedicalAssistant';
import EditMedicalAssistant from './team/EditMedicalAssistant';
import EditDialysisAdmin from './team/EditDialysisAdmin';
import AddDialysisAdmin from './team/AddDialysisAdmin';
import AddNurse from './team/AddNurse';
import EditNurse from './team/EditNurse';
import AddLocation from './clinicLoaction/AddLocation';
import EditLocation from './clinicLoaction/EditLocation';

export default {
  'clinic-detail': {
    screen: ClinicDetail,
    header: {
      leftAction: {
        ...back,
        visible: () => {
          let user = getUser();
          if (user?.userType === 'ClinicAdmin') {
            return false;
          } else {
            return true;
          }
        },
      },
      title: ({navigation}) => {
        let {state: {params: {item} = {}} = {}} = navigation;
        const {organization = {}} = getUser();
        if (!item) {
          item = organization;
        }
        return (
          <CustomHeaderLabel
            seperator={true}
            preLabel={'Clinic Details'}
            postLabel={item?.organization_name}
          />
        );
      },
    },
    expanded: true,
  },
  'clinic-summary': {
    screen: ClinicSummary,
  },
  'clinic-team': {
    screen: ClinicTeam,
  },
  'clinic-performance': {
    screen: ClinicPerformance,
  },

  'clinic-summary-form': {
    screen: ClinicSummaryForm,
    expanded: true,
  },
  accountId: {
    screen: RazorpayModal,
  },
  'add-clinic-admin': {
    screen: AddClinicAdmin,
    expanded: true,
  },
  'add-front-desk': {
    screen: AddFrontDesk,
    expanded: true,
  },
  'add-doctors': {
    screen: AddDoctors,
    expanded: true,
  },
  'edit-doctors': {
    screen: EditDoctor,
    expanded: true,
  },
  'edit-ClinicAdmin': {
    screen: EditClinicAdmin,
    expanded: true,
  },
  'edit-FrontDesk': {
    screen: EditFrontDesk,
    expanded: true,
  },
  'add-doctor-schedule': {
    screen: DoctorSchedule,
    expanded: true,
  },
  'add-lab-member': {
    screen: AddLabMember,
    expanded: true,
  },
  'edit-lab-member': {
    screen: EditLabMember,
    expanded: true,
  },
  'add-medical-assistant': {
    screen: AddMedicalAssistant,
    expanded: true,
  },
  'edit-medical-assistant': {
    screen: EditMedicalAssistant,
    expanded: true,
  },
  thankYouStripe: {
    screen: ThankYou,
    expanded: true,
  },
  'add-dialysis-admin': {
    expanded: true,
    screen:AddDialysisAdmin,
  },
  'edit-dialysis-admin': {
    expanded: true,
    screen:EditDialysisAdmin,
  },
  'add-nurse': {
    expanded: true,
    screen:AddNurse,
  },
  'edit-nurse': {
    expanded: true,
    screen:EditNurse,
  },
  'add-location':{
    expanded: true,
    screen:AddLocation,
  },
  'edit-location':{
    expanded: true,
    screen:EditLocation,
  }

};
