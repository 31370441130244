import React from 'react';
import {View, Image, TouchableOpacity} from '../../react-core-components';
import {getRenderComponent} from '../../react-input-wrapper';

const NumberSelector = (props) => {
  let {
    onInc,
    onDec,
    incIcon,
    decIcon,
    renderInputSelector,
    inputSelectorContainerStyle,
    inputSelectorIconStyle,
  } = props;
  if (renderInputSelector) {
    return getRenderComponent(renderInputSelector, props);
  }
  if (!incIcon && !decIcon) {
    return null;
  }
  return (
    <View style={inputSelectorContainerStyle}>
      <TouchableOpacity onPress={onInc}>
        <Image style={inputSelectorIconStyle} source={incIcon} />
      </TouchableOpacity>
      <TouchableOpacity onPress={onDec}>
        <Image style={inputSelectorIconStyle} source={decIcon} />
      </TouchableOpacity>
    </View>
  );
};

NumberSelector.defaultProps = {
  inputSelectorContainerStyle: {
    paddingLeft: 8,
    paddingRight: 8,
    justifyContent: 'center',
  },
};

export default NumberSelector;
