import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import OrganisationSettingForm from './OrganisationSettingForm';

let header = {
  title: 'Add Organisation Setting',
  actions: [cancelButton(), save()],
};

export default OrganisationSettingForm({header});
