import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import educationContentForm from './educationContentForm';

let header = {
  title: 'Add Eduction Content',
  actions: [cancelButton(), save()],
};

export default educationContentForm({header});
