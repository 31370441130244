import React from 'react';
import {View, Image} from '../../react-core-components';

class LeftIcon extends React.Component {
  render() {
    let {
      icon,
      leftIconContainerStyle,
      leftIconStyle,
      disabled,
      disabledStyle,
      children,
    } = this.props;
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          ...(disabled && disabledStyle),
        }}>
        <View style={leftIconContainerStyle}>
          {icon && <Image style={leftIconStyle} source={icon} />}
        </View>
        <View style={{flex: 1, overflow: 'hidden'}}>
          {React.cloneElement(children, {
            icon: void 0,
          })}
        </View>
      </View>
    );
  }
}

LeftIcon.defaultProps = {
  leftIconContainerStyle: {
    height: 44,
    width: 38,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
};

export default LeftIcon;
