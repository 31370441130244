import {manazeV5Colors} from './manazeV5Colors';
import {manazeV6Colors} from './manazeV6Colors';

import {
  Headline3,
  Headline4,
  Headline10,
  Headline11,
  Headline9,
  Headline8,
  Headline5,
  Headline7,
} from './Headlines';
import {toolBarTheme} from './toolBarTheme';

const {neutral_400_low_background} = manazeV5Colors;
const {
  neutral_1,
  neutral_2,
  neutral_3,
  neutral_8,
  surface_white,
  neutral_10,
  primary_6,
} = manazeV6Colors;

export const nonEditableformGroupHeaderStyle = {
  style: {
    marginLeft: 6,
    marginRight: 6,
    paddingTop: 4,
    paddingBottom: 4,
  },
  iconStyle: {
    marginRight: 6,
  },
  labelStyle: {
    ...Headline9,
    neutral_3,
  },
};

export const formGroupHeaderStyle = {
  style: {
    marginLeft: 24,
    marginRight: 24,
    paddingTop: 4,
    paddingBottom: 4,
  },
  iconStyle: {
    marginRight: 6,
  },
  labelStyle: {
    // ...Headline9,
    ...Headline5,
    color: neutral_2,
  },
};

export const formGroupStyle = {
  style: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 8,
  },
  groupContainerStyle: {},
  separatorStyle: {
    marginLeft: 24,
    marginRight: 32,
    borderBottomWidth: 0.4,
    borderColor: neutral_400_low_background,
    opacity: 0.8,
  },
  columnContainerStyle: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
    paddingBottom: 12,
  },
};

export const compactFormGroupStyle = {
  style: {
    padding: 12,
  },
  columnContainerStyle: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
};

export const nonEditableFormGroupStyle = {
  style: {
    padding: 6,
  },
  groupContainerStyle: {},
  separatorStyle: {
    marginLeft: 12,
    marginRight: 12,
    borderBottomWidth: 0.3,
    borderColor: neutral_400_low_background,
    opacity: 0.2,
  },
  columnContainerStyle: {
    marginLeft: 6,
    marginRight: 6,
    marginTop: 16,
    marginBottom: 16,
  },
};

export const sectionFormGroupStyle = {
  style: {
    paddingTop: 28,
    paddingBottom: 20,
    paddingRight: 8,
  },
  groupContainerStyle: {},
  separatorStyle: {
    marginLeft: 24,
    marginRight: 24,
    borderWidth: 0.3,
    borderColor: neutral_400_low_background,
    opacity: 0.2,
  },
  columnContainerStyle: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
    paddingBottom: 12,
  },
};

export const nestedFormGroupStyle = {
  style: {
    // paddingTop: 6,
    // paddingBottom: 6,
  },
  columnContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 6,
    paddingBottom: 6,
  },
};

export const expandStyle = {
  style: {
    cursor: 'pointer',
    paddingRight: 8,
    paddingLeft: 8,
  },
};
export const expandIconStyle = {
  style: {height: 20, width: 20},
};

export const formGroupMobileStyle = {
  style: {},
  separatorStyle: {
    marginLeft: 16,
    marginRight: 16,
    borderBottomWidth: 0.3,
    borderColor: neutral_400_low_background,
    opacity: 0.2,
  },
  columnContainerStyle: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
};

export const formHeaderTheme = {
  ...toolBarTheme,
  bottomSeparator: true,
  containerStyle: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: surface_white,
  },
  separatorStyle: {
    height: 2,
    backgroundColor: neutral_8,
  },
  titleTextStyle: {
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline3,
    color: neutral_1,
  },
  subTitleTextStyle: {
    paddingTop: 3,
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline11,
    color: neutral_3,
  },
  actionStyle: {
    containerStyle: {
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      flexDirection: 'row',
    },
    iconStyle: {height: 24, width: 24, objectFit: 'contain'},
  },
};

export const formHeaderMobileTheme = {
  containerStyle: {
    padding: 12,
    backgroundColor: primary_6,
  },
  itemsStyle: {
    flexDirection: 'row',
  },
  titleContainerStyle: {
    padding: 4,
  },
  titleTextStyle: {
    ...Headline4,
    color: neutral_1,
  },
  subTitleTextStyle: {
    paddingTop: 3,
    ...Headline10,
    color: neutral_2,
  },
  actionContainerStyle: {flexDirection: 'row'},
  actionStyle: {
    containerStyle: {
      flexDirection: 'row',
      padding: 4,
    },
    iconStyle: {height: 20, width: 20, objectFit: 'contain'},
    textStyle: {...Headline11, color: neutral_2},
  },
};

export const compactFormHeaderTheme = {
  ...formHeaderTheme,
};

export const formFooterTheme = {
  ...toolBarTheme,
  topSeparator: true,
  containerStyle: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: surface_white,
  },
  separatorStyle: {
    height: 1,
    backgroundColor: neutral_8,
  },
};

export const formFooterMobileTheme = {
  containerStyle: {
    padding: 8,
    backgroundColor: primary_6,
  },
  titleTextStyle: {
    padding: 8,
    color: neutral_1,
    ...Headline9,
  },
  actionStyle: {
    containerStyle: {
      padding: 8,
    },
    iconStyle: {
      marginRight: 12,
      height: 20,
      width: 20,
    },
    textStyle: {...Headline11, color: neutral_2},
  },
};
export const compactFormFooterTheme = {
  ...toolBarTheme,
  containerStyle: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: surface_white,
  },
};

export const formTheme = {
  containerStyle: {
    backgroundColor: '#ffffff',
  },
};

export const formMobileTheme = {
  containerStyle: {
    backgroundColor: primary_6,
  },
};

export const compactFormTheme = {
  containerStyle: {
    backgroundColor: '#ffffff',
  },
};

export const standardShadowFormTheme = {
  containerStyle: {
    marginTop: 29,
    marginBottom: 29,
    marginLeft: 37,
    marginRight: 37,
    borderRadius: 8,
    maxHeight: 'content-fit',
    boxShadow: ' 0 2px 14px 0 rgba(56, 56, 56, 0.1)',
    backgroundColor: '#ffffff',
  },
};
export const standardShadowFormThemeMobile = {
  containerStyle: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    maxHeight: 'content-fit',
    boxShadow: ' 0 2px 14px 0 rgba(56, 56, 56, 0.1)',
    backgroundColor: '#fff',
  },
};
