import {monthLabels} from '../../Lib/constants';
export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth();
export const getLastYears = (val = 10) => {
  const yearData = [];
  for (var i = 0; i < val; i++)
    yearData.push({label: currentYear - i, value: currentYear - i});
  return yearData;
};
export const getLastTwelveMonthsLabels = () => {
  return [
    ...monthLabels.slice(currentMonth + 1),
    ...monthLabels.slice(0, currentMonth + 1),
  ];
};
