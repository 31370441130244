import MedicalHistoryForm from './MedicalHistoryForm';
import {EditMedicalHistory} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Medical History',
  actions: [cancelButton(), save()],
};

export default MedicalHistoryForm({uri: EditMedicalHistory, header});
