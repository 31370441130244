import React from 'react';
import {getUser} from './AppServices';
import withGpsContext from './withGpsContext';
import uuid from 'uuid/v4';

export const InstantAppointmentContext = React.createContext({});

class InstantAppointmentContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unReadCount: void 0,
      getAllChatThreads: {},
      callBackReceived: false,
    };
  }

  callback = (data) => {
    // console.log(`>@>Puru ~ file: InstantAppointmentContext.js ~ line 19 ~ data`, data)
    this.setState({newData: {_id: data?._id + '__' + uuid()}});
  };
  subscribeMeetingCallback = (data) => {};

  socketSubscription = () => {
    let {gpsStore} = this.props;
    let {doctor: {_id: doctorId} = {}, userType} = getUser();
    this.socketUid = uuid();
    gpsStore.subscribeEvent({
      event: 'newInstantAppointment',
      callback: this.callback,
    });
    gpsStore.emitDataOnConnect({
      uid: this.socketUid,
      event: 'subscribeGroup',
      data: {
        groupId: `${doctorId}__new`,
        sessionId: this.socketUid,
        source: 'web',
      },
      callback: this.subscribeMeetingCallback,
    });
  };

  unsubscribSocket = () => {
    let {gpsStore} = this.props;
    if (gpsStore && gpsStore.removeEmitDataOnConnect) {
      gpsStore.removeEmitDataOnConnect({uid: this.socketUid});
      gpsStore.unsubscribeEvent({
        event: 'newInstantAppointment',
        callback: this.callback,
      });
    }
  };

  componentDidMount() {
    console.log('Hello');
    this.socketSubscription();
  }

  componentWillUnmount() {
    this.unsubscribSocket();
  }

  render() {
    return (
      <InstantAppointmentContext.Provider value={{...this.state}}>
        {this.props.children}
      </InstantAppointmentContext.Provider>
    );
  }
}

const InstantAppointmentContextProviderHoc = withGpsContext(
  InstantAppointmentContextProvider,
);

export {InstantAppointmentContextProviderHoc};

export default (Component) => (props) => (
  <InstantAppointmentContext.Consumer>
    {(value) => {
      return <Component appointmentStore={value} {...props} />;
    }}
  </InstantAppointmentContext.Consumer>
);
