import React from 'react';
import CardTitleBar from './CardTitleBar';
import CardContentLayout from './CardContentLayout';
import {View} from '../../react-core-components';

const CardContent = props => {
  let {
    contentContainerStyle,
    titleGap,
    contentItems,
    title,
    ...restProps
  } = props;
  let itemsComponent = void 0;
  let containerStyle = contentContainerStyle;
  if (contentItems) {
    itemsComponent = (
      <CardContentLayout
        {...restProps}
        content={contentItems}
        source={'content'}
      />
    );
    if (titleGap && title && itemsComponent) {
      itemsComponent = (
        <>
          <View style={{height: titleGap}} />
          {itemsComponent}
        </>
      );
    }
  }

  return (
    <View style={containerStyle}>
      {title ? <CardTitleBar title={title} {...restProps} /> : void 0}
      {itemsComponent}
    </View>
  );
};

CardContent.defaultProps = {
  titleGap: 8,
};

export default CardContent;
