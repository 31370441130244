import React, {useState} from 'react';
import {View, Text, TouchableOpacity, Image} from '../../app-components';
import {h14_Regular} from '../../theme/fonts';
import {closeIcon} from '../../images';
import {shadow} from '../../theme/shadows';
import {invoke} from '../../AppServices';
import {TextInput} from '../../app-components/input-components/Editors';
import {ActivityIndicator} from '../../app-components';

import {colors, bgs} from '../../theme/colors';
let {textColor70} = colors;
let {actionBgColor} = bgs;

const SendEPrescriptionModalView = (props) => {
  let {data, setToast, frameStyle, hideModal, setValue} = props;
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState(data?.patient_id?.email || '');
  const sendEmail = async (props) => {
    const {hideModal, completionText, email} = props;
    setLoader(true);
    await invoke({
      id: 'ePrescription',
      paramValue: {
        query: {
          paramValue: {},
          addOnFilter: {
            appointment: {_id: data._id},
            email,
            update_email: true,
          },
        },
      },
    }).then(() => {
      setLoader(false);
      hideModal && hideModal();
      setValue({value: email, field: 'patient_id.email', data});
      setToast({completionText});
    });
    setLoader(false);
    hideModal && hideModal();

    setTimeout(() => {
      setToast(void 0);
    }, 2000);
  };
  return (
    <View
      style={{
        ...frameStyle,
        ...shadow,
        backgroundColor: '#fff',
        width: 400,
        borderRadius: 30,
        paddingBottom: 40,
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 20,
        justifyContent: 'space-between',
        zIndex: 99,
      }}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10,
        }}>
        <Text style={{...h14_Regular, color: textColor70}}>
          Are you sure you want to send e-prescription to
        </Text>
        <TouchableOpacity onPress={hideModal} style={{alignItems: 'flex-end'}}>
          <Image source={closeIcon} style={{height: 14, width: 14}} />
        </TouchableOpacity>
      </View>
      <TextInput
        data={data}
        value={email}
        onChangeText={(value) => setEmail(value)}
        onSubmitEditing={() => {
          sendEmail({
            hideModal,
            completionText: 'Shared With Patient',
            email: email,
          });
        }}
        // variant="filled"
        placeholder="Enter Patient's Email"
      />
      <TouchableOpacity
        style={{
          backgroundColor: actionBgColor,
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          height: 40,
          width: 170,
          marginTop: 20,
          borderRadius: 10,
        }}
        onPress={() => {
          sendEmail({
            hideModal,
            completionText: 'Shared With Patient',
            email: email,
          });
        }}>
        {loader ? (
          <ActivityIndicator size="small" color={'#fff'} />
        ) : (
          <Text style={{...h14_Regular, color: 'white'}}>
            Send E-Prescreption
          </Text>
        )}
      </TouchableOpacity>
    </View>
  );
};
export default SendEPrescriptionModalView;
