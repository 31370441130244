import React, {useState, useCallback, useRef, useMemo, useEffect} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Linking,
  ScrollView,
} from '../../app-components';
import times from 'lodash/times';
import {
  leftArrowPdf,
  rightArrowPdf,
  redCloseIcon,
  downloadIconFile,
  AdForTemp,
} from '../../images';
import isEmpty from 'lodash/isEmpty';
import {patientWatingRoomCard} from '../../theme/shadows';
import {h14_Medium} from '../../theme/fonts';
import {ActivityIndicator, isMobile} from '../../app-components/index';
import {getFileExtension} from '../../app-components/UtilityFunctions';
import {getImageUrl, post} from '../../AppServices';
import moment from 'moment';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfPreview = (props) => {
  const {onDocumentLoad} = props;
  const {navigation: {state: {params} = {}} = {}} = props;
  const dataArr = params?.dataArr || [];
  const {care_program = false} = params;
  const prevNote = params?.prevNote || false;
  const [numPages, setNumPages] = useState(0);
  const [index, setIndex] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [pageDimensions, setPageDimensions] = useState({});
  const pdfRef = useRef(null);
  const documentContainer = useRef(null);
  const container = useRef(null);
  const downloadFile = (all = false) => {
    if (all && Array.isArray(dataArr) && dataArr.length) {
      dataArr.forEach(function (value, idx) {
        let item = prevNote ? dataArr[idx]?.mayamd_note : dataArr[idx];
        setTimeout(() => {
          download(item);
        }, idx * 10);
      });
    } else {
      let item =
        dataArr && dataArr[index] && prevNote
          ? dataArr[index]?.mayamd_note
          : dataArr[index];
      download(item);
    }
  };

  const download = (item) => {
    const {file = '', name = '', key = ''} = item || {};
    const image = getImageUrl(key);
    fetch(`${image}`).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
      });
    });
  };

  useEffect(() => {
    if (Array.isArray(dataArr) && dataArr.length) {
      if (params?.clickedIndex) {
        setIndex(params?.clickedIndex);
      } else {
        setIndex(0);
      }
    }
  }, [dataArr, params.clickedIndex]);

  useEffect(() => {
    const careProgramItem =
      Array.isArray(dataArr) && dataArr.length ? dataArr[index] : {};
    if (
      care_program &&
      careProgramItem &&
      careProgramItem?._id &&
      careProgramItem?.seen_status == false
    ) {
      (async () => {
        try {
          const {forceUpdate} = params || {};
          let result = await post({
            data: {_id: careProgramItem?._id},
            updates: {
              seen_status: true,
            },
            model: 'PatientNotes',
          });
          forceUpdate && forceUpdate(new Date());
        } catch (err) {
          console.log('file: PdfReader.js ~ line 135 ~ err', err);
        }
      })();
    }
  }, [care_program, dataArr, index, params]);
  const prev = () => {
    setIndex(index - 1);
  };
  const next = () => {
    setIndex(index + 1);
  };
  const definePageDimensions = useCallback(
    (page) => {
      const {height, width} = page;
      if (!Object.keys(pageDimensions).length && page) {
        setPageDimensions({height, width});
      }
    },
    [pageDimensions],
  );
  const renderPages = useMemo(
    () =>
      times(numPages, (index) => (
        <Page
          key={`page_${index + 1}`}
          className="pdf-page"
          pageNumber={index + 1}
          scale={scale}
          rotate={rotation}
          onLoadSuccess={definePageDimensions}
        />
      )),
    [numPages, scale, rotation, definePageDimensions],
  );
  const onDocumentLoadSuccess = useCallback(
    (document) => {
      const {numPages: pagesNum} = document;
      setNumPages(pagesNum);
      onDocumentLoad(document);
    },
    [onDocumentLoad],
  );
  return (
    <>
      {dataArr?.[index]?.flag ? (
        <TouchableOpacity
          onPress={() => {
            Linking.openURL(
              'https://www.renalandurologynews.com/home/conference-highlights/kidney-week-annual-meeting/kidney-week-2019/novel-drug-eases-uremic-pruritus/',
              '_blank',
            );
          }}
          style={{
            alignItems: 'center',
          }}>
          <Image style={{width: 500}} source={AdForTemp} />
        </TouchableOpacity>
      ) : null}
      {/* {dataArr[index]?.date ? (
        <View style={{margin: 5}}>
          <Text style={{fontSize: 20, fontWeight: 600}}>
            Date: {moment(dataArr[index]?.date).format('MMM Do YYYY, h:mm A')}
          </Text>
        </View>
      ) : (
        void 0
      )} */}

      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          ...patientWatingRoomCard,
        }}>
        {dataArr.length > 1 && index !== 0 && (
          <TouchableOpacity
            style={{
              position: 'absolute',
              zIndex: 9999,
              // backgroundColor: 'black',
              marginLeft: 10,
              cursor: 'pointer',
              top: 0,
              bottom: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              if (index !== 0) {
                prev();
              }
            }}>
            <Image source={leftArrowPdf} style={{height: 30, width: 30}} />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{
            position: 'absolute',
            zIndex: 99999,
            marginLeft: 10,
            cursor: 'pointer',
            top: 8,
            right: 15,
          }}
          onPress={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const {navigation = {}} = props;
            navigation.pop();
          }}>
          <Image source={redCloseIcon} style={{height: 25, width: 25}} />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            position: 'absolute',
            zIndex: 99999,
            marginLeft: 10,
            cursor: 'pointer',
            top: 8,
            // left: 5,
          }}
          onPress={(e) => {
            e.stopPropagation();
            e.preventDefault();
            downloadFile();
          }}>
          <Image source={downloadIconFile} style={{height: 25, width: 25}} />
        </TouchableOpacity>
        {Array.isArray(dataArr) && dataArr.length > 1 && (
          <TouchableOpacity
            style={{
              position: 'absolute',
              zIndex: 99999,
              marginLeft: 10,
              cursor: 'pointer',
              top: 11,
              left: 38,
            }}
            onPress={(e) => {
              e.stopPropagation();
              e.preventDefault();
              downloadFile(true);
            }}>
            <Text style={{color: '#0095C9'}}>Download All</Text>
          </TouchableOpacity>
        )}
        {dataArr.length > 1 && index !== dataArr.length - 1 && (
          <TouchableOpacity
            style={{
              position: 'absolute',
              zIndex: 9999,
              marginRight: 10,
              // backgroundColor: 'black',
              cursor: 'pointer',
              top: 0,
              bottom: 0,
              right: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              if (index !== dataArr.length - 1) {
                next();
              }
            }}>
            <Image source={rightArrowPdf} style={{height: 30, width: 30}} />
          </TouchableOpacity>
        )}
        <ScrollView style={{flex: 1, marginTop: 25}}>
          {!isEmpty(dataArr) &&
            dataArr?.map((item, idx) => {
              return (
                <View
                  style={{
                    display: idx === index ? 'flex' : 'none',
                    flex: 1,
                  }}>
                  {item &&
                  getFileExtension(
                    prevNote ? item?.mayamd_note?.key : item.key,
                  ) === 'pdf' ? (
                    <View
                      ref={container}
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        // display: idx === index ? 'flex' : 'none',
                      }}>
                      <View ref={documentContainer} style={{flex: 1}}>
                        <Document
                          file={
                            item &&
                            getImageUrl(
                              prevNote ? item?.mayamd_note?.key : item?.key,
                            )
                          }
                          onLoadSuccess={onDocumentLoadSuccess}
                          ref={pdfRef}
                          loading={<ActivityIndicator size="small" />}>
                          {renderPages}
                        </Document>
                      </View>
                    </View>
                  ) : (
                    <ScrollView
                      style={{
                        flex: 1,
                      }}
                      key={index && index.toString()}>
                      <View
                        style={{
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <Text style={{...h14_Medium, color: '#275dad'}}>
                          No Data
                        </Text>
                      </View>
                    </ScrollView>
                  )}
                </View>
              );
            })}
          {/* {dataArr &&
          dataArr[index] &&
          getFileExtension(
            prevNote ? dataArr[index]?.mayamd_note.name : dataArr[index].name,
          ) === 'pdf' ? (
            <View
              ref={container}
              style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
              <View ref={documentContainer} style={{flex: 1}}>
                <Document
                  file={
                    dataArr &&
                    dataArr[index] &&
                    getImageUrl(
                      prevNote
                        ? dataArr[index]?.mayamd_note?.file
                        : dataArr[index]?.file,
                    )
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                  ref={pdfRef}
                  loading={<ActivityIndicator size="small" />}>
                  {renderPages}
                </Document>
              </View>
            </View>
          ) : (
            <ScrollView style={{flex: 1}} key={index && index.toString()}>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image
                  source={
                    dataArr &&
                    dataArr[index] &&
                    getImageUrl(dataArr[index]?.file)
                  }
                  style={{maxHeight: 500, maxWidth: isMobile ? 200 : 500}}
                />
              </View>
            </ScrollView>
          )} */}
        </ScrollView>
      </View>
    </>
  );
};
PdfPreview.defaultProps = {
  id: 'PDF_VIEWER', // change ids for multiple viewers on the same page
  onDocumentLoad: () => {},
  showRotation: false,
};
export default PdfPreview;
