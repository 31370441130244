import {isMobile} from '../index';
import {roundedTabTheme, roundedTabMobileTheme} from '../../theme/tabBarTheme';
import TabNavigator from './TabNavigator';

export default defs => {
  return TabNavigator({
    key: 'roundedTab',
    theme: isMobile ? roundedTabMobileTheme : roundedTabTheme,
    ...defs,
  });
};
