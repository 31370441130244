import React from 'react';
import {View, TouchableOpacity, Platform} from '../../react-core-components';
export default class RadioButton extends React.Component {
  state = {};

  onMouseOver = () => {
    this.setState({
      hover: true,
    });
  };

  onMouseOut = () => {
    this.setState({
      hover: false,
    });
  };

  render() {
    let {
      size = 20,
      hoverSize = 40,
      innerSize,
      borderWidth = 1,
      color,
      activeColor,
      editable,
      disabled,
      hoverColor,
      activeHoverColor,
      hoverable = true,
      selected,
      onChangeValue,
      disabledStyle,
      activeDisabledStyle,
      disabledColor,
      activeDisabledColor,
    } = this.props;

    if (!innerSize) {
      innerSize = size / 2;
    }
    if (!hoverable) {
      hoverSize = size;
    }
    if (disabled) {
      if (disabledColor) {
        disabledStyle = {
          borderColor: (selected && activeDisabledColor) || disabledColor,
        };
      } else {
        disabledStyle = {
          ...disabledStyle,
          ...(selected && activeDisabledStyle),
        };
      }
    }

    let {hover} = this.state;
    let hoverEvents = {};
    if (editable !== false && !disabled && Platform.OS === 'web') {
      hoverEvents.onMouseOver = this.onMouseOver;
      hoverEvents.onMouseOut = this.onMouseOut;
    }

    return (
      <TouchableOpacity
        {...hoverEvents}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          cursor: disabled ? 'not-allowed' : 'pointer',
          height: hoverSize,
          width: hoverSize,
          borderRadius: hoverSize / 2,
          borderWidth: (hoverSize - size) / 2,
          borderColor: hover
            ? (selected && activeHoverColor) || hoverColor
            : 'transparent',
        }}
        onPress={
          disabled || editable === false
            ? void 0
            : (e) => onChangeValue && onChangeValue(true, e)
        }>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: size,
            width: size,
            borderRadius: size / 2,
            borderWidth,
            borderColor: (selected && activeColor) || color,
            ...(disabled ? disabledStyle : void 0),
          }}>
          <View
            style={{
              width: selected ? innerSize : 0,
              height: selected ? innerSize : 0,
              borderRadius: innerSize / 2,
              backgroundColor: (disabled && activeDisabledColor) || activeColor,
              transition: '.2s',
            }}
          />
        </View>
      </TouchableOpacity>
    );
  }
}

RadioButton.defaultProps = {
  disabledStyle: {
    opacity: 0.3,
  },
  color: '#acabb1',
  activeColor: '#1ed69e',
  hoverColor: '#f0f0f0',
  activeHoverColor: 'rgba(1, 135, 134, 0.04)',
};
