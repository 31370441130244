import React from 'react';
import {View, Text} from '../../react-core-components';
import { WithInputWrapper } from '../../react-input-wrapper';
import {Switch} from './Switch';

class ToggleSwitch extends React.Component {
  render() {
    let {
      value,
      style,
      onLabelStyle,
      offLabelStyle,
      selectedLabelStyle,
      onValue,
      offValue,
      leftValue,
      rightValue,
      onChangeValue,
      defaultValue,
      showOuterLabel,
      ...restProps
    } = this.props;
    if (value === undefined) {
      value = defaultValue;
    }
    let onLabel = 'On';
    let offLabel = 'Off';
    onValue = onValue || rightValue || true;
    offValue = offValue || leftValue || false;

    if (typeof onValue === 'string') {
      onLabel = onValue;
    } else if (typeof onValue === 'object') {
      onLabel = onValue.label;
      onValue = onValue.value || true;
    }
    if (typeof offValue === 'string') {
      offLabel = offValue;
    } else if (typeof offValue === 'object') {
      offLabel = offValue.label;
      offValue = offValue.value || false;
    }
    let isOn = value === onValue;
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          ...style,
        }}>
        {showOuterLabel && offLabel && (
          <Text
            style={{
              ...offLabelStyle,
              ...(!isOn && selectedLabelStyle),
            }}>
            {offLabel}
          </Text>
        )}
        <Switch
          {...restProps}
          value={isOn}
          onChangeValue={(_value, e) => {
            onChangeValue && onChangeValue(_value ? onValue : offValue, e);
          }}
        />
        {showOuterLabel && onLabel && (
          <Text
            style={{
              ...onLabelStyle,
              ...(isOn && selectedLabelStyle),
            }}>
            {onLabel}
          </Text>
        )}
      </View>
    );
  }
}
export default WithInputWrapper(ToggleSwitch);
