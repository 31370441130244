import React from 'react';
import StyleSheet from './StyleSheet';

const Image = ({source, resizeMode, style, ...rest}) => {
  if (!source) {
    return null;
  }
  if (Array.isArray(style)) {
    style = StyleSheet.flatten(style);
  }
  if (style) {
    if (style.resizeMode) {
      resizeMode = style.resizeMode;
      delete style.resizeMode;
    }
    if (!style.objectFit && resizeMode) {
      style = {
        ...style,
        objectFit: resizeMode,
      };
    }
  }
  if (typeof source === 'function') {
    source = source();
  }
  if (typeof source === 'object') {
    if (source.spriteProps) {
      style = {...style, ...source.spriteProps};
    }
    source = source.uri;
  }
  return <img src={source} alt="" draggable={false} style={style} {...rest} />;
};

export default Image;
