import React from 'react';
import {cancel} from '../../../../app-components/action/CancelAction';
import {save} from '../../../../app-components/action/SaveAction';
import FrontDeskForm from './FrontDeskForm';
import {EditClinicAdmin} from '../../../../Queries';
import CustomHeaderLabel from '../../../../app-components/CustomHeaderLabel';

const header = {
  hideBackIcon: true,
  title: ({navigation}) => {
    const {state: {params: {item} = {}} = {}} = navigation;
    // console.log('item', navigation);
    return (
      <CustomHeaderLabel
        preLabel={'Edit User Detail'}
        seperator={true}
        postLabel={item?.name}
      />
    );
  },
  actions: [cancel(), save()],
};
export default FrontDeskForm({header, uri: EditClinicAdmin});
