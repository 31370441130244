import React from 'react';
import {Keyboard} from '../../react-core-components';
import {AppKeyboardListenerContext} from './AppKeyboardListenerContext';

const KEYBOARD_DID_SHOW = 'keyboardDidShow';
const KEYBOARD_DID_HIDE = 'keyboardDidHide';
class AppKeyboardListener extends React.Component {
  listeners = {};
  constructor(props) {
    super(props);
    this.state = {
      addKeyboardListener: this.addKeyboardListener,
      removeKeyboardListener: this.addKeyboardListener,
    };
  }

  addKeyboardListener = (event, callback) => {
    this.listeners[event] = this.listeners[event] || [];
    let indexOf = this.listeners[event].indexOf(callback);
    if (indexOf < 0) {
      this.listeners[event].push(callback);
      if (this.keyboardEvent) {
        callback(this.keyboardEvent);
      }
    }
  };

  removeKeyboardListener = (event, callback) => {
    this.listeners[event] = this.listeners[event] || [];
    let indexOf = this.listeners[event].indexOf(callback);
    if (indexOf >= 0) {
      this.listeners[event].splice(indexOf, 1);
    }
  };
  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this._keyboardDidShow,
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this._keyboardDidHide,
    );
  }
  componentWillUnmount() {
    this.keyboardDidShowListener && this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener && this.keyboardDidHideListener.remove();
  }

  fireKeyboardDidShowListener = () => {
    const didShowListeners = this.listeners[KEYBOARD_DID_SHOW];
    didShowListeners &&
      didShowListeners.forEach(listener => listener(this.keyboardEvent));
  };

  _keyboardDidShow = e => {
    this.keyboardEvent = e;
    this.fireKeyboardDidShowListener();
  };

  _keyboardDidHide = () => {
    this.keyboardEvent = void 0;
    const didHideListeners = this.listeners[KEYBOARD_DID_HIDE];
    didHideListeners &&
      didHideListeners.forEach(listener => listener(this.keyboardEvent));
  };
  render() {
    return (
      <AppKeyboardListenerContext.Provider value={this.state}>
        {this.props.children}
      </AppKeyboardListenerContext.Provider>
    );
  }
}

export default AppKeyboardListener;
