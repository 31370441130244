import React from 'react';
import {Image, View, Text} from '../../../app-components';
import {TableHoc as Table} from '../../../app-components/table/Table';
import {getUser} from '../../../AppServices';
import {careProgramAdmin, coppiedCareProgram} from '../../../images';
import {h10_SemiBold} from '../../../theme/fonts';
import {tableText} from '../rpmUtils';
const showName = (name) => {
  if (!name) {
    return;
  }
  if (typeof name === 'string') {
    name = name && name.split(' ');
    if (name.length === 2) {
      name =
        (name[0] && name[0].substring(0, 1).toUpperCase()) +
        (name[1] && name[1].substring(0, 1).toUpperCase());
    } else {
      name = name[0] && name[0].substring(0, 1).toUpperCase();
    }

    return name;
  }
  return '';
};
const uri = () => {
  const {organization, userType} = getUser() || {};
  return {
    query: {
      id: 'careProgramList',
      addOnFilter: {
        ...(userType === 'SuperAdmin'
          ? {isDefault: true}
          : {$or: [{isDefault: true}, {organization}]}),
      },
      sort: { _id: -1 },
    },
    model: 'CarePrograms',
  };
};
export default Table({
  reloadEvent: 'reloadProgramLibrary',
  uri,
  action: ({item}) => {
    return {
      type: 'link',
      link: {
        view: 'detailRpmProgramForm',
        params: {item},
      },
    };
  },
  columns: [
    {
      width: 30,
      render: ({item}) => {
        const {
          isDefault = true,
          organization: {organization_name = ''} = {},
        } = item;
        if (!isDefault) {
          return (
            <View
              style={{
                width: 20,
                height: 20,
                borderRadius: 25,
                backgroundColor: 'green',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              title={organization_name}>
              <Text style={{color: 'white', ...h10_SemiBold}}>
                {showName(organization_name)}
              </Text>
            </View>
          );
        }
        return (
          <Image
            source={isDefault ? careProgramAdmin : coppiedCareProgram}
            style={{height: 20, width: 20}}
            title="Super Admin"
          />
        );
      },
    },
    {
      type: 'text',
      field: 'name',
      header: 'Program Name',
      // filter: searchFilter({
      //   field: 'chronicDisease',
      //   model: 'Diagnosis',
      //   id: 'diagnoseLists',
      //   search: 'name',
      //   varient: 'curved',
      //   placeholder: 'Write here',
      //   sugestionField: 'name',
      //   valueField: 'name',
      //   searchField: 'name',
      //   searching: true,
      // }),
    },
    {
      type: 'text',
      field: 'description',
      header: 'Program Description',
    },
    {
      type: 'text',
      field: 'programAdmin.name',
      header: 'Program Admin',
    },
    {
      header: 'Medical Condition',
      render: ({item}) => {
        let {chronicDisease = []} = item;
        let nameList =
          Array.isArray(chronicDisease) &&
          chronicDisease.map((chronicItem, index) => {
            return '' + chronicItem?.name + '';
          });

        return tableText((nameList || []).join(', '));
      },
    },
    // {
    //   header: 'Device',
    //   render: ({item}) => {
    //     let {monitoringParameter = []} = item;
    //     let nameList =
    //       Array.isArray(monitoringParameter) &&
    //       monitoringParameter.map((item1, index) => {
    //         if (item1?.device) {
    //           let careProgramList = item1.device;
    //           if (!Array.isArray(careProgramList)) {
    //             careProgramList = [careProgramList];
    //           }
    //           if (careProgramList) {
    //             return careProgramList.map((item2) => {
    //               return ' ' + item2?.name + ' ';
    //             });
    //           }
    //         }
    //       });

    //     return tableText(nameList.join(' , '));
    //   },
    // },
  ],
  // hoverActions: [
  //   {
  //     value: 'Edit',
  //     textStyle: {
  //       color: '#9a9a9a',
  //       fontWeight: 500,
  //     },
  //     action: ({item}) => {
  //       return {
  //         type: 'link',
  //         link: {
  //           view: 'editRpmProgramForm',
  //           params: {item},
  //         },
  //       };
  //     },
  //   },
  // ],
});
