import React from 'react';
import moment from 'moment';

import './adviceAndPrescription.css';
import { ScrollView, Text, View } from '../../../app-components';
import { h12_SansSemiBold, h13_Regular, h15_Medium } from '../../../theme/fonts';

const AdviceAndPrescription = ({ prescriptionAndAdvice }) => {
  return (
    <ScrollView>
      {prescriptionAndAdvice?.map((items) => {
        return (items?.prescription || items?.advice) && (
          <View
            key={items?._id}
            style={{
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#eee',
              width: '100%',
              marginBottom: 2,
              backgroundColor: 'rgb(240, 240, 247)',
              padding: 10,
            }}>
            <View style={{ alignSelf: 'flex-end' }}>
              <Text style={{ ...h12_SansSemiBold }}>
                {moment(
                  items?.date || items?._createdOn,
                ).format('DD MMM YYYY | HH:mm:ss')}
              </Text>
            </View>
            {items?.prescription &&
              <>
                <View style={{ paddingTop: -200 }}>
                  <Text
                    style={{
                      ...h15_Medium,
                      color: 'black',
                    }}>
                    Prescription
                  </Text>
                </View>
                <Text
                  style={{
                    ...h13_Regular,
                  }}>{`- ${items?.prescription || ''}`}
                </Text>
              </>}
            {items?.advice &&
              <>
                <View>
                  <Text
                    style={{
                      ...h15_Medium,
                      color: 'black',
                    }}>
                    Advice {items?.labType && `(${items?.labType})`}
                  </Text>
                </View>
                <Text
                  style={{
                    ...h13_Regular,
                  }}>{`- ${items?.advice || ''}`}
                </Text>
              </>}
          </View>
        );
      })}
    </ScrollView>
  );
};

AdviceAndPrescription.defaultProps = {
  prescriptionAndAdvice: [],
};

export default AdviceAndPrescription;
