import React from 'react'
import FavouriteDoctor from './FavouriteDoctor';
import {View} from '../../app-components';

export default {
  'FavoriteDoctor': {
    renderHeader: () => {
      return (
        <View
          style={{
            height: 1,
            marginTop: 5,
            width: '100%',
            backgroundColor: 'rgb(151,151,151, 0.34)',
          }}
        />
      );
    },
    screen: FavouriteDoctor,
  },
};
