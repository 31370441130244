import {isEmpty} from 'lodash';
import {getUser} from '../AppServices';
import {getMobileAndEmail} from './patientUtility';

export const resolveDoctorName = (data) => {
  let {name = []} = data || {};
  let fullName = '';
  if (Array.isArray(name) && name.length) {
    const {given = [], family = ''} = name[0] || {};
    fullName = given.join(' ') + ' ' + family;
  }
  return typeof name === 'string' ? name : fullName;
};

export const resolveDoctorFirstName = (data) => {
  const {name} = data || {};
  return (name?.[0]?.given || []).join(' ') ?? '';
};
export const resolveDoctorLastName = (data) => {
  const {name} = data || {};
  return (name?.[0] || [])?.family || '';
};
export const resolveDoctorEmail = (data) => {
  let {telecom = []} = data || {};
  let email = '';
  if (Array.isArray(telecom) && telecom.length) {
    for (let value of telecom) {
      if (value?.system === 'email') {
        email = value?.value || '';
        break;
      }
    }
  }
  return email;
};
export const resolveDoctorMobile = (data) => {
  let {telecom = []} = data || {};
  let mobile = '';
  if (Array.isArray(telecom) && telecom.length) {
    for (let value of telecom) {
      if (value?.system == 'phone') {
        mobile = value?.value || '';
        break;
      }
    }
  }
  return mobile;
};
export const resolveDoctornpi = (data) => {
  let {identifier = []} = data || {};
  let value = '';
  if (Array.isArray(identifier) && identifier.length) {
    value = identifier[0]?.value || '';
  }
  return value;
};
export const resolveDoctorAddress = (data) => {
  let {address} = data;
  if (address) {
    return address.city + address.country;
  }
};

export const updateDoctorDataInForm = ({updates}) => {
  let address;
  const {address: updatedAddress = {}} = updates || '';
  address = {
    ...updatedAddress,
    postalCode: updatedAddress?.pin_code || '',
  };
  return {
    updates: {
      ...updates,
      telecom: [
        {
          system: 'phone',
          value: updates.mobile || '',
        },
        {system: 'email', value: updates.email || ''},
      ],
      name: [
        {
          family: updates?.Lname,
          given: [updates?.Fname],
        },
      ],
      address,
      identifier: [
        {
          system: 'http://hospital.smarthealthit.org',
          value: updates.npi || '',
        },
      ],
      userType: 'Doctor',
    },
  };
};
export const doctorDefaultValuesForDoctor = ({navigation}) => {
  const {state: {params: {item: item1 = {}} = {}} = {}} = navigation;
  let {_id} = item1 || {};
  if (!_id) {
    const {organization = {}} = getUser();
    _id = organization?._id;
  }
  let Fname,
    Lname,
    npi,
    {item} = navigation.state.params;
  const address = item.organization?.organization_address;
  const {telecom = [], name = [], identifier = []} = item || {};
  if (Array.isArray(name) && name.length) {
    const given = name?.[0]?.given;
    if (Array.isArray(given)) {
      Fname = given.join(' ');
    }
    Lname = name[0]?.family;
  }

  if (Array.isArray(identifier) && identifier.length) {
    npi = identifier[0].value;
  }

  return {
    ...item,
    Fname,
    Lname,
    npi,
    time_zone: item?.time_zone,
    userType: 'Doctor',
    address,
    ...getMobileAndEmail(telecom),
  };
};
export const doctorDefaultValues = ({navigation}) => {
  const {state: {params: {item1 = {}} = {}} = {}} = navigation;
  let {_id, organization_address = {}} = item1 || {};
  if (!_id) {
    const {organization = {}} = getUser();
    _id = organization?._id;
    organization_address = organization?.organization_address;
  }
  let Fname,
    Lname,
    npi,
    time_zone,
    {items} = navigation.state.params;
  const {telecom = [], name = [], identifier = []} = items || {};
  if (Array.isArray(name) && name.length) {
    Fname = (name[0]?.given || []).join(' ');
    Lname = name[0]?.family;
  }
  if (Array.isArray(identifier) && identifier.length) {
    npi = identifier[0]?.value;
  }
  time_zone = items?.time_zone;

  return {
    ...items,
    Fname,
    Lname,
    npi,
    time_zone,
    userType: 'Doctor',
    organization: {_id},
    address: organization_address,
    ...getMobileAndEmail(telecom),
  };
};
export const doctorDefaultValuesForAdd = ({navigation}) => {
  const {
    state: {
      params: {booking_duration = 0, booking_fee = 0, item = {}} = {},
    } = {},
  } = navigation;
  let {_id, organization_address = {}} = item || {};
  if (!_id) {
    const {organization = {}} = getUser();
    _id = organization?._id;
    organization_address = organization?.organization_address;
  }
  return {
    userType: 'Doctor',
    organization: {_id},
    consultation_duration: booking_duration,
    cost_of_booking: booking_fee,
    address: organization_address,
    isAdded: true,
  };
};
export const modifyDoctorNameForDropDown = ({data}) => {
  const newData = data.map((item) => ({
    ...item,
    fullname: resolveDoctorName(item),
  }));
  return {data: newData};
};
export const updateDoctorDataInFor = ({updates}) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  return {
    updates: {
      ...updates,
      effectiveDateTime: today,
      issued: today,
    },
  };
};

export const defaultValueWhileEdit = ({items}) => {
  if (isEmpty(items)) {
    return {};
  }
  const {telecom = []} = items || {};
  return {
    ...items,
    ...getMobileAndEmail(telecom),
  };
};
