import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  isMobile,
  Dimensions,
} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {h16_Regular} from '../../theme/fonts';
// import { setInitiallyLogged, setSelectedDoctor } from '../../AppServices';
const {height, width} = Dimensions.get('window');
const SucessFullCreatedModal = (props) => {
  const {navigation, eventDispatcher} = props;
  let {
    state: {
      params: {
        message,
        isNavigate = false,
        onClick = () => {
          navigation.reset({
            view: 'login',
            params: {
              type: 'Doctor',
            },
          });
          // navigation.reset({view: 'clinic-signup', params: {completed: true}});
        },
      } = {},
    } = {},
  } = navigation;
  // if (message && message.length > 150) {
  //   message = 'Enter your name';
  // }
  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: isMobile ? width * 0.9 : 500,
        padding: 20,
        alignItems: 'center',
      }}>
      <Text style={{marginBottom: 40, marginTop: 20, ...h16_Regular}}>
        {message || 'Payment Failed'}
      </Text>
      <TouchableOpacity
        style={{cursor: 'pointer', width: isMobile ? width * 0.75 : 250}}
        onPress={onClick}>
        <GradientButton label="OK" />
      </TouchableOpacity>
    </View>
  );
};

export default SucessFullCreatedModal;
