import React, {useState, useEffect} from 'react';
import {
  attachmentIcon,
  pdf,
  bookingConfirm,
  crossPdf,
  MayaAiGirl,
} from '../../images';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
} from '../../app-components';
import {
  h15_AvenirNext_500,
  h14_AvenirNext,
  h16_Regular,
  h20_AvenirNext_600,
  h18_AvenirNext_500,
} from '../../theme/fonts';
import moment from 'moment';
import {
  TextAreaInput,
  MultiTextInput,
  UploadImage,
} from '../../app-components/input-components/Editors';
import {post, getImageUrl, getUser} from '../../AppServices';
import {isMobile} from '../../app-components';
import {confirmBox} from '../../theme/shadows';
import {getFileExtension} from '../../app-components/UtilityFunctions';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {colors} from '../../theme/colors';
const {themeContainer} = colors;

const BookingConfirm = (props) => {
  const {navigation} = props;
  const {t} = props;
  const {state: {params} = {}} = navigation || {};
  const [symptom, setSymptom] = useState('');
  const [illnessDescription, setIllnessDescription] = useState('');
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const {} = getUser() || {};
  let medical_Report_Slice = file;
  let medical_Report_Slice_Second = [];
  const IconLength = 4;
  if (file.length > IconLength) {
    medical_Report_Slice = file.slice(0, IconLength);
    medical_Report_Slice_Second = file.slice(IconLength, file.length);
  }

  useEffect(() => {
    const {res = {}} = params || {};
    const {
      medical_report = [],
      patient_symptoms = [],
      illness_description = '',
    } = res || {};
    if (Array.isArray(medical_report) && medical_report.length) {
      setFile([...medical_report]);
    }
    if (Array.isArray(patient_symptoms) && patient_symptoms.length) {
      setSymptom([...patient_symptoms]);
    }
    if (illness_description) {
      setIllnessDescription(illness_description);
    }
  }, []);
  const setValue = (value) => {
    setFile(value);
  };
  const submitData = () => {
    setLoading(true);
    const {res} = params;
    post({
      data: {_id: res?._id},
      updates: {
        medical_report: file,
        patient_symptoms: symptom,
        illness_description: illnessDescription,
      },
      model: 'Appointments',
    })
      .then(() => {
        navigation.reset({view: 'patient-waiting-room'});
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const imageView = () => {
    navigation.push({
      view: 'pdfReader',
      params: {
        dataArr: file,
      },
      modal: true,

      modalProps: {
        autoHide: true,
        minHeight: 180,
        height: 600,
        width: isMobile ? 300 : 700,
        position: 'screenCenter',
        screenCenterStyle: {
          flex: 1,
          overflow: 'hidden',
          borderRadius: 8,
          maxHeight: '90%',
          maxWidth: '90%',
          zIndex: 12,
        },
      },
    });
  };

  const removeElement = (fileIndex) => {
    const fileCopy = [...file];
    const updatedFile = fileCopy.filter((item, index) => index !== fileIndex);
    console.log(updatedFile);
    setFile([...updatedFile]);
  };
  return (
    <ScrollView
      style={{
        flex: 1,
        alignItems: 'center',
        paddingTop: 55,
        // paddingBottom: 97,
        // backgroundColor:"#fff"
      }}>
      <View
        style={{
          borderRadius: 16,
          // borderColor: 'rgba(53, 53, 53, 0.14)',
          width: 800,
          // backgroundColor: '#fff',
          ...confirmBox,
        }}>
        <View
          style={{
            // backgroundImage: `url(${bookingConfirm})`,
            backgroundColor: themeContainer,
            borderRadius: '16px 16px 0 0',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            // paddingTop: 34,
            // paddingRight: 25,
            // paddingBottom: 34,
            // paddingLeft: 25,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <View
            style={{
              marginTop: 34,
              marginRight: 25,
              marginBottom: 34,
              marginLeft: 25,
            }}>
            <Text
              style={{
                ...h20_AvenirNext_600,
                color: '#fff',
              }}>
              {params?.titleText
                ? t(params?.titleText)
                : t('Confirm Your Booking' + '!')}
            </Text>
            <View style={{}}>
              <Text
                style={{
                  ...h15_AvenirNext_500,
                  color: 'rgba(255, 255, 255, 0.85)',
                  marginTop: 6,
                }}>
                {/* Your Video Consultation has been succesfully booked */}
                {t('videoConsultSuccess')}
              </Text>
              <Text
                style={{
                  ...h15_AvenirNext_500,
                  color: 'rgba(255, 255, 255, 0.85)',
                  marginTop: 6,
                }}>
                {`with ${
                  params?.item?.name[0].given[0] +
                  ' ' +
                  params?.item?.name[0].family
                } at ${moment(params?.selectedTimeSlot?.start_time).format(
                  'hh:mm A',
                )} | ${moment(params?.selectdDate).format('DD MMMM YYYY')}`}
              </Text>
            </View>
          </View>
          <Image
            source={MayaAiGirl}
            style={{height: 150, objectFit: 'contain'}}
          />
        </View>

        <View style={{backgroundColor: '#fff', borderRadius: '0 0 16px 16px'}}>
          <View
            style={{
              backgroundColor: '#f9ffff',
              flex: 1,
              padding: 15,
            }}>
            <Text
              style={{
                color: 'rgba(56, 56, 56, 0.7)',
                ...h18_AvenirNext_500,
                // marginTop: 24,
                marginLeft: 12,
              }}>
              {/* Help your doctor to understand your condition better */}
              {t('helpDoctorUnderstand')}
            </Text>
          </View>
          <View
            style={{
              paddingLeft: 35,
              paddingRight: 35,
              paddingTop: 15,
              backgroundColor: '#fff',
            }}>
            {/* <Text
              style={{
                ...h16_Regular,
                color: 'rgba(56, 56, 56, 0.37)',
              }}>
              Give your Symptoms
            </Text> */}
            {/* <View
              style={{
                borderRadius: 4,
                marginTop: 15,
              }}>
              <MultiTextInput
                value={symptom}
                variant={'filled'}
                onChangeValue={(value) => setSymptom(value)}
                style={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#5ccfe9',
                  borderRadius: 4,
                  backgroundColor: '#fff',
                  minHeight: 38,
                }}
                activeStyle={{}}
                placeholder="Add Symptoms here.."
                // {...theme}
              />
            </View> */}

            <Text
              style={{
                ...h16_Regular,
                marginTop: 15,
                color: 'rgba(56, 56, 56, 0.37)',
              }}>
              {/* Additional information for clinician */}
              {t('additionalInfo')}
            </Text>
            <View
              style={{
                borderWidth: 1,
                borderColor: '#5ccfe9',
                borderRadius: 4,
                marginTop: 15,
              }}>
              <TextAreaInput
                style={{
                  ...h14_AvenirNext,
                  color: 'rgba(56, 56, 56, 0.7)',
                  marginTop: 15,
                  marginBottom: 20,
                }}
                minHeight={50}
                maxHeight={50}
                variant={'filled'}
                placeholder={t('additionalInfo')} //"Additional information for clinician"
                value={illnessDescription}
                onChangeText={(value) => setIllnessDescription(value)}
              />
            </View>
            <View
              style={{
                marginTop: 15,
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}>
              <Text
                style={{
                  ...h14_AvenirNext,
                  color: '#51cdc0',
                }}>
                {t('Add Attachments')}
              </Text>
              <UploadImage
                imageOnly={false}
                imageWidth={25}
                imageHeight={25}
                minHeight={28}
                onChangeValue={setValue}
                value={file}
                defaultImage={attachmentIcon}
                uploadOptions={{s3: true}}
                multiple={true}
              />
            </View>
          </View>
          {/* Attachments ICONS */}
          <View
            style={{
              flexDirection: 'row',
              backgroundColor: '#fff',
            }}>
            <View>
              <View style={{flexDirection: 'row', marginLeft: 40}}>
                {Array.isArray(medical_Report_Slice)
                  ? medical_Report_Slice.map((item, index) => (
                      <TouchableOpacity
                        style={{
                          marginRight: 19,
                        }}
                        onPress={() => imageView()}>
                        {getFileExtension(item.name) === 'pdf' ? (
                          <Image source={pdf} />
                        ) : (
                          <Image
                            source={getImageUrl(item?.thumbnail)}
                            style={{
                              height: 49,
                              width: 50,
                            }}
                          />
                        )}
                        <TouchableOpacity
                          style={{
                            zIndex: 9,
                            top: -6,
                            right: -6,
                            position: 'absolute',
                          }}
                          onPress={(e) => {
                            e.stopPropagation();
                            removeElement(index);
                          }}>
                          <Image
                            source={crossPdf}
                            style={{
                              height: 16,
                              width: 16,
                            }}
                          />
                        </TouchableOpacity>
                      </TouchableOpacity>
                    ))
                  : null}
                {Array.isArray(medical_Report_Slice_Second) &&
                medical_Report_Slice_Second.length ? (
                  <TouchableOpacity
                    style={{
                      height: 49,
                      width: 50,
                      marginLeft: 5,
                      borderRadius: 4,
                      justifyContent: 'center',
                      backgroundColor: '#383838',
                      alignItems: 'center',
                    }}
                    onPress={() => imageView()}>
                    <Text style={{color: '#fff'}}>
                      {'+' + medical_Report_Slice_Second.length}
                    </Text>
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>
          </View>
          {/* SUBMIT OR Cancel BUTTONS */}
          <View style={style.buttonWrapper}>
            {params?.isCancel ? (
              <TouchableOpacity
                style={style.cancelButton}
                onPress={() => {
                  navigation.reset({view: 'patient-waiting-room'});
                }}>
                <Text
                  style={{
                    ...h14_AvenirNext,
                    color: '#383838',
                  }}>
                  {t('Cancel')}
                </Text>
              </TouchableOpacity>
            ) : (
              void 0
            )}
            <TouchableOpacity
              style={style.submitButton}
              onPress={() => {
                if (!loading) {
                  submitData();
                }
              }}>
              <GradientButton
                label={t('Submit')}
                loading={loading}
                indicatorColor={'#fff'}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const style = {
  buttonWrapper: {
    marginBottom: 20,
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
    paddingRight: 35,
  },
  cancelButton: {
    backgroundColor: 'rgb(240, 240, 247)',
    justifyContent: 'center',
    minHeight: 40,
    borderRadius: 8,
    minWidth: 125,
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 13.5,
    marginLeft: 13.5,
    paddingLeft: 13.5,
    paddingRight: 13.5,
  },
  submitButton: {
    cursor: 'pointer',
    marginLeft: 15,
    marginRight: 15,
    paddingLeft: 13.5,
    paddingRight: 13.5,
  },
};

export default BookingConfirm;
