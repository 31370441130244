import React, {useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  Dimensions,
  isMobile,
} from '../../app-components';
import {
  h12_Italic,
  h12_Regular,
  h14_Medium,
  h14_Regular,
  h16_Regular,
} from '../../theme/fonts';
import {collapseIcon, expandIcon, redCloseIcon} from '../../images';
import NestedList from '../../app-components/input-components/NestedList';
import {autoSuggestOptionsInput, searchInput} from '../../autoSuggestions';
import {moreActionsShadow} from '../../theme/shadows';
import {fetch, getUser, invoke, post} from '../../AppServices';
import {TextAreaInput} from '../../app-components/input-components/Editors';
import {ActivityIndicator} from '../../app-components';
import {colors, bgs} from '../../theme/colors';
import {WithModal} from '../../npms/react-with-modal';
import SendEPrescriptionModalView from './SendEPrescriptionModalView';
import MayaMdNote from './EvaluateViewComponents/MayaMdNote';
import MayaMdPastNotes from './EvaluateViewComponents/MayaMdPastNotes';
import Symptom from './EvaluateViewComponents/Symptom';
import Investigation from './EvaluateViewComponents/Investigation';
import Diagnosis from './EvaluateViewComponents/Diagnosis';
import {isEmpty} from 'lodash';
let {
  textColorGrey,
  tableHoverColor,
  textColor70,
  silverTree,
  themeColor,
} = colors;

const staticData = {
  mayamd_note: false,
  mayamd_past_notes: false,
  symptom: false,
  investigation: false,
  diagnosis: false,
  treatment: false,
};

const EvaluateView = ({item, ...props}) => {
  let {
    form_state: {data = {}} = {},
    form_context: {setValue} = {},
    eventDispatcher,
  } = props;

  const [textAreaVisible, setTextAreaVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [toast, setToast] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [clinic_note, setClinic_note] = useState([]);

  const [tabData, setTabData] = useState({...staticData, mayamd_note: true});

  let nestedlistprops = {
    field: 'treatment',
    visible: () => {
      let {userType} = getUser();
      return userType === 'Doctor' ? true : false;
    },
    addButtonStyle: {width: 200, height: 100},
    footer: {
      addLabel: 'Add Drug',
    },
    labelHeader: [
      {label: 'Drug', width: 140},
      {label: 'Dosage', width: 75},
      {label: 'Frequency', width: 100},
      {label: 'Timing', width: 120},
      {label: 'Days', width: 68},
    ],

    formGroups: [
      {
        columnsPerRow: 6,
        fieldVariant: 'filled',
        // fieldContainer: 'topLabel',

        groups: [
          {
            columns: [
              searchInput({
                field: 'drug',
                query: 'drugLists',
                model: 'Drugs',
                placeholder: 'Name Here',
                idField: '_id',
                searchField: 'name',
                width: 140,
                showSelected: false,
                suggestionField: 'name',
                valueField: 'name',
                renderSelector: false,
              }),
              {
                width: 75,
                type: 'text',
                field: 'dosage',
                placeholder: 'tablets/ ml',
              },
              autoSuggestOptionsInput({
                field: 'frequency',
                options: ['Once', 'Twice', 'Thrice'],
                placeholder: 'Select',
                showSelected: false,
                width: 100,
                dropdownStyle: {
                  height: 130,
                  borderRadius: 8,
                  backgroundColor: '#ffffff',
                  ...moreActionsShadow,
                },
              }),
              autoSuggestOptionsInput({
                field: 'timing',
                placeholder: 'Before meal',
                options: ['Before meal', 'After meal'],
                width: 120,
                showSelected: false,
                dropdownStyle: {
                  height: 80,
                  borderRadius: 8,
                  backgroundColor: '#ffffff',
                  ...moreActionsShadow,
                },
              }),

              {
                width: 68,
                type: 'number',
                field: 'duration',
                placeholder: 'Days',
              },
              {
                width: 20,
                render: (props) => {
                  let {item, index, removeRow} = props;
                  return (
                    <TouchableOpacity
                      onPress={() => removeRow({item, index})}
                      style={{
                        cursor: 'pointer',
                        height: 38,
                        justifyContent: 'center',
                      }}>
                      <Image
                        source={redCloseIcon}
                        style={{width: 20, height: 20}}
                      />
                    </TouchableOpacity>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
  let {investigate, diagnosis, treatment, investigated_symptoms} = data;

  const uploadDetails = async (props) => {
    let {hideModal, completionText, data, model, updates, ...restProps} = props;
    let {treatment = []} = updates;
    let checkedTeatment = [];
    if (Array.isArray(treatment) && treatment.length) {
      for (let i = 0; i < treatment.length; i += 1) {
        if (treatment[i]?.drug?._id) {
          checkedTeatment = [...checkedTeatment, treatment[i]];
        }
      }
    }
    // console.log('>>>>>Data,Upda', data, updates);
    setLoader(true);
    delete updates._id;
    await post({
      data,
      updates: {
        ...updates,
        treatment: checkedTeatment,
        session_end: new Date(),
      },
      model,
    }).then(async (res) => {
      setClinic_note([]);
      const result = await fetch({
        uri: {
          props: {
            query: {
              id: 'appointmentLists',
              addOnFilter: {
                _id: data?._id,
              },
            },
            model: 'Appointments',
          },
        },
      });
      const {data: fetchedData = []} = result;
      if (Array.isArray(fetchedData) && fetchedData.length) {
        let {clinic_note = {}} = fetchedData[0] || {};
        if (!isEmpty(clinic_note)) {
          setClinic_note([clinic_note]);
        }
      }
      setToast({completionText});
      setUploaded(true);
      eventDispatcher.notify('reloadAddApointment');
    });

    setLoader(false);
    hideModal && hideModal();
    setTimeout(() => {
      setToast(void 0);
    }, 2000);
  };

  const shareWithPatientButton = () => {
    return (
      <WithModal
        position={'screenCenter'}
        style={{flex: 1, flexDirection: 'row'}}
        dropdownStyle={{height: 230, width: 230}}
        renderModal={({frameStyle, hideModal}) => {
          return (
            <SendEPrescriptionModalView
              setToast={setToast}
              frameStyle={frameStyle}
              data={item}
              setValue={setValue}
              hideModal={hideModal}
            />
          );
        }}>
        <TouchableOpacity
          style={{
            backgroundColor: themeColor,
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            cursor: 'pointer',
            height: 60,
          }}
          {...(!uploaded
            ? {
                onPress: () => {
                  setToast({
                    completionText: 'Upload Prescription First',
                    backgroundColor: 'red',
                  });
                  setTimeout(() => {
                    setToast(void 0);
                  }, 2000);
                },
              }
            : void 0)}>
          <Text style={{...h14_Regular, color: 'white'}}>
            Share with Patient
          </Text>
        </TouchableOpacity>
      </WithModal>
    );
  };

  const getCollapsibleContent = (props) => {
    let {data, placeholder} = props;
    return (
      <View style={{flex: 1, flexDirection: 'row'}}>
        <Text numberOfLines={1} style={{...h12_Italic, lineHeight: 16}}>
          {data?.length > 0
            ? data.map((item, index) => {
                let separator = index + 1 < data.length ? ', ' : '';
                if (typeof item === 'object' && item.name) {
                  return item.name + separator;
                } else {
                  return item + separator;
                }
              })
            : placeholder}
        </Text>
      </View>
    );
  };
  const getNestedListCollpasibleComponent = (props) => {
    let {data, placeholder} = props;
    return (
      <View style={{flex: 1, flexDirection: 'row'}}>
        {data?.length > 0 ? (
          data.map(({drug}, index) => {
            return (
              <Text numberOfLines={1} style={{...h12_Italic, lineHeight: 16}}>
                {drug?.name ? drug.name : placeholder}
                {index + 1 < data.length ? ', ' : ''}
              </Text>
            );
          })
        ) : (
          <Text numberOfLines={1} style={{...h12_Italic, lineHeight: 16}}>
            {placeholder}
          </Text>
        )}
      </View>
    );
  };

  const collapse_handler = ({selected}) => {
    setTabData({...staticData, [selected]: !tabData[selected]});
  };
  // console.log(">Data,item",data,item);
  return (
    <ScrollView style={{flex: 1}}>
      <View
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 20,
          backgroundColor: '#fff',
          flexDirection: 'column',
        }}>
        {[
          {
            label: 'MayaMd Note',
            value: 'mayamd_note',
            visible: true,
            component: (
              <MayaMdNote
                item={item}
                publisherViewWidth={props.publisherViewWidth}
                {...props}
              />
            ),
          },
          {
            label: 'MayaMd Past Notes',
            value: 'mayamd_past_notes',
            visible: true,
            component: <MayaMdPastNotes item={item} {...props} />,
          },
          {
            label: 'Clinical note' || 'SYMPTOM',
            value: 'symptom',
            visible: true,
            component: (
              <Symptom
                item={item}
                {...props}
                data={data}
                setValue={setValue}
                collapse_handler={collapse_handler}
              />
            ),
          },
          {
            label: 'DIAGNOSIS',
            value: 'diagnosis',
            visible: true,
            component: (
              <Diagnosis
                item={item}
                {...props}
                data={data}
                setValue={setValue}
                collapse_handler={collapse_handler}
              />
            ),
          },
          {
            label: 'INVESTIGATION',
            value: 'investigation',
            visible: true,
            component: (
              <Investigation
                item={item}
                {...props}
                data={data}
                setValue={setValue}
                collapse_handler={collapse_handler}
              />
            ),
          },
          {
            // label: 'TREATMENT',
            label: 'Assessment and Plan',
            value: 'treatment',
            visible: true,
          },
        ].map((item, index) =>
          item?.visible ? (
            <>
              <TouchableOpacity
                onPress={() => {
                  collapse_handler({selected: item?.value});
                }}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                  flex: 1,
                  marginTop: index === 0 ? 0 : 13,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flex: 1,
                  }}>
                  <Text
                    style={{
                      ...h14_Medium,
                      lineHeight: 16,
                      color: tabData[item?.value] ? '#2e6ecd' : '#9a9a9a',
                      width: 150,
                    }}>
                    {item.label.toUpperCase()}
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flex: 1,
                    }}>
                    {item?.value === 'symptom' && !tabData[item?.value] ? (
                      getCollapsibleContent({
                        data: investigated_symptoms,
                        placeholder: 'Add Symptoms Here',
                      })
                    ) : (
                      <View />
                    )}
                    {item?.value === 'investigation' &&
                    !tabData[item?.value] ? (
                      getCollapsibleContent({
                        data: investigate,
                        placeholder: 'Recommend Sample Tests, MRI, X-ray, etc',
                      })
                    ) : (
                      <View />
                    )}
                    {item?.value === 'diagnosis' && !tabData[item?.value] ? (
                      getCollapsibleContent({
                        data: diagnosis,
                        placeholder: 'Write Illness/Disease Name',
                      })
                    ) : (
                      <View />
                    )}
                    {item?.value == 'treatment' && !tabData[item?.value] ? (
                      getNestedListCollpasibleComponent({
                        data: treatment,
                        placeholder:
                          'Write Treatment/ procedure and medication',
                      })
                    ) : (
                      <View />
                    )}
                    <TouchableOpacity>
                      {tabData[item?.value] ? (
                        <Image
                          source={collapseIcon}
                          style={{height: 20, width: 20}}
                        />
                      ) : (
                        <Image
                          source={expandIcon}
                          style={{height: 20, width: 20}}
                        />
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
              </TouchableOpacity>
              {item?.component && tabData[item?.value]
                ? item?.component
                : void 0}
            </>
          ) : (
            void 0
          ),
        )}
      </View>

      {tabData.treatment ? (
        <View style={{marginTop: 20, justifyContent: 'space-between'}}>
          <View style={{flex: 1, marginLeft: 20, marginRight: 20}}>
            <NestedList
              {...nestedlistprops}
              {...props}
              setValue={setValue}
              data={data}
              defaultAddRow={data.treatment ? false : true}
            />
            <TextAreaInput
              placeholder={'Enter treatment here'}
              variant={'filled'}
              value={data.treatment_other}
              minHeight={50}
              onChangeValue={(value) => {
                setValue({field: 'treatment_other', data, value});
              }}
            />
          </View>
          {textAreaVisible ? (
            <View
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 10,
                paddingTop: 10,
              }}>
              <Text style={{marginBottom: 10, ...h14_Regular}}>Extra note</Text>
              <TextAreaInput
                placeholder={'Enter Note'}
                variant={'filled'}
                minHeight={50}
                value={data.extra_note}
                onChangeValue={(value) => {
                  setValue({field: 'extra_note', data, value});
                }}
              />
            </View>
          ) : (
            void 0
          )}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 30,
              paddingRight: 30,
            }}>
            <TouchableOpacity
              onPress={() => {
                setTextAreaVisible(!textAreaVisible);
              }}>
              <Text style={{...h12_Regular, color: textColorGrey}}>
                {textAreaVisible
                  ? 'Close Note Panel'
                  : 'Add Extra Note (Optional)'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                cursor: 'pointer',
              }}
              onPress={() => {
                setValue({field: 'treatment', data, value: []});
              }}>
              <Text style={{...h12_Regular, color: textColorGrey}}>
                Clear All Drugs
              </Text>
            </TouchableOpacity>
            {clinic_note && clinic_note.length ? (
              <TouchableOpacity
                style={{
                  cursor: 'pointer',
                }}
                onPress={() => {
                  const {navigation = {}} = props || {};
                  navigation.push({
                    view: 'pdfReader',
                    params: {
                      dataArr: clinic_note,
                    },
                    modal: true,

                    modalProps: {
                      autoHide: true,
                      minHeight: 180,
                      position: 'screenRight',
                      style: {
                        height: '100%',
                        width: isMobile
                          ? '100%'
                          : Dimensions.get('window').width -
                            props.publisherViewWidth,
                        zIndex: 999,
                      },
                      backdropStyle: {},
                      // ...confirmModalProps,
                    },
                  });
                  // setValue({field: 'treatment', data, value: []});
                }}>
                <Text style={{...h12_Regular, color: textColorGrey}}>
                  View Note
                </Text>
              </TouchableOpacity>
            ) : (
              void 0
            )}
          </View>
          <View style={{flexDirection: 'row', flex: 1}}>
            <TouchableOpacity
              style={{
                backgroundColor: tableHoverColor,
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                height: 60,
                cursor: 'pointer',
              }}
              onPress={() => {
                uploadDetails({
                  data: item,
                  updates: data,
                  model: 'Appointments',
                  completionText: 'Uploaded Successfully',
                });
              }}>
              {loader ? (
                <ActivityIndicator size="small" color={'#0095C9'} />
              ) : (
                <Text style={{...h14_Regular, color: textColor70}}>
                  Upload Prescription
                </Text>
              )}
            </TouchableOpacity>
            {shareWithPatientButton()}
            {toast ? (
              <View
                style={{
                  position: 'absolute',
                  paddingTop: 8,
                  paddingBottom: 8,
                  backgroundColor: toast.backgroundColor || silverTree,
                  borderRadius: 20,
                  bottom: 40,
                  left: 150,
                  right: 150,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text
                  style={{...h16_Regular, color: '#fff', alignSelf: 'center'}}>
                  {toast.completionText}
                </Text>
              </View>
            ) : (
              void 0
            )}
          </View>
        </View>
      ) : (
        void 0
      )}
    </ScrollView>
  );
};

export default EvaluateView;
