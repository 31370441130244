import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {PLACEHOLDER} from '../../app-components/utils';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {autoSuggestOptionsInput} from '../../autoSuggestions';
import {moreActionsShadow} from '../../theme/shadows';
import countryDropdown from './StaticData/countryData.json';

export default ({uri, header}) => {
  const Specialityform = Form({
    type: 'standardShadow',
    uri,
    onSubmit: submit({
      model: 'OrganizationAdvertisement',
    }),
    reloadEvent: 'reloadAdvertisement',
    closeView: 1,
    mandatory: {country: 1, to_display: 1},
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 2,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'title',
                  label: 'Title',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'link',
                  label: 'Link',
                  mandatory: true,
                  placeholder: PLACEHOLDER,
                },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'Description',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                autoSuggestOptionsInput({
                  field: 'country',
                  options: countryDropdown,
                  placeholder: 'Select',
                  showSelected: false,
                  keyField: 'name',
                  suggestionField: 'name',
                  // width: 100,
                  label: 'Select Country',
                  dropdownStyle: {
                    height: 180,
                    borderRadius: 8,
                    backgroundColor: '#ffffff',
                    ...moreActionsShadow,
                  },
                }),
                {
                  field: 'logo',
                  placeholder: 'File',
                  label: 'Advertisement Icon',
                  uploadOptions: {s3: true},
                  type: 'file',
                },
                {
                  field: 'image',
                  placeholder: 'File',
                  label: 'Advertisement Image',
                  uploadOptions: {s3: true},
                  type: 'file',
                },
                {
                  type: 'chipsToggle',
                  field: 'to_display',
                  label: 'Where to Display',
                  mandatory: true,
                  options: [
                    {value: 'before_login', label: 'Before Login'},
                    {value: 'after_login', label: 'After Login'},
                    {value: 'both', label: 'Both'},
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'title',
                  label: 'Title',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'link',
                  label: 'Link',
                  mandatory: true,
                  placeholder: PLACEHOLDER,
                },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'Description',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                autoSuggestOptionsInput({
                  field: 'country',
                  options: countryDropdown,
                  placeholder: 'Select',
                  showSelected: false,
                  keyField: 'name',
                  suggestionField: 'name',
                  // width: 100,
                  label: 'Select Country',
                  dropdownStyle: {
                    height: 130,
                    borderRadius: 8,
                    backgroundColor: '#ffffff',
                    ...moreActionsShadow,
                  },
                }),
                {
                  field: 'logo',
                  placeholder: 'File',
                  label: 'Advertisement Icon',
                  uploadOptions: {s3: true},
                  type: 'file',
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return Specialityform;
};
