import React, {useEffect, useState, useRef, createRef} from 'react';
import {View, Image, TouchableOpacity} from '../../app-components';
import {fetch, getUser, getImageUrl, setChannelName} from '../../AppServices';
import {attachmentIcon, sendChatIcon, chatDownArrowIcon} from '../../images';
import PubNub from 'pubnub';
import {
  UploadImage,
  TextAreaInput,
} from '../../app-components/input-components/Editors';
import {getFileExtension} from '../../app-components/UtilityFunctions';
// import {Storage} from '../../app-components';

import {style} from '../../theme/ChatTheme';
import uuid from 'uuid/v4';
import InvertedList from './InvertedList';
import {RenderMessagefromOthers, RenderMyOwnMessage} from './ChatUtility';

import {get} from 'lodash';

import {ActivityIndicator} from '../../app-components';

const getNewId = () => {
  return uuid();
};
const getAvtar = (type) => {
  switch (type) {
    case 'Doctor':
      return getUser().doctor?.profile_picture;
    case 'MedicalAssistant':
      return getUser().medicalAssistant?.profile_picture;
    case 'Nurse':
      return getUser().nurse?.profile_picture;
    default:
      return false;
  }
};
const ChatView = (props) => {
  const [channel, setChannel] = useState(void 0);
  const [notifChannel, setNotificationChannel] = useState(void 0);
  const {
    renderTextInput = true,
    item,
    channelId = '',
    medicalAssistant = false,
  } = props;
  // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>: item', item);

  const user = {
    id: getUser()._id,
    _id: getUser()._id,
    name: getUser().name || 'N/A',
    avatar: getAvtar(getUser()?.userType)
      ? getAvtar(getUser()?.userType)
      : item?.patient_id?.profile_picture ?? getUser().patient?.profile_picture,
  };

  const getChannelDetail = async () => {
    const response = await fetch({
      uri: {
        props: {
          query: {
            id: 'patientStatLists',
            addOnFilter: {
              patient: {_id: item.patient_id._id},
              doctor: {_id: item.doctor_id._id},
            },
          },
          model: 'PatientStats',
        },
      },
    });
    let data = response?.data || [];
    let channel = data.length > 0 && data[0]?.channel;
    let notificationChannel = channel;
    channel = channel && `Appt-${channel}`;
    notificationChannel = notificationChannel && `Notif-${notificationChannel}`;
    setNotificationChannel(notificationChannel);
    setChannel(channel);
    setChannelName(channel);
    // console.log('notifChannel', notificationChannel);
  };

  const Pubnub = new PubNub({
    // publishKey: 'pub-c-0762a11a-399c-491a-80fd-b166d1c4fd18',
    // subscribeKey: 'sub-c-1fa049e0-39db-11eb-ab29-9acd6868450b',
    publishKey:
      'pub-c-4a569e11-7612-41b9-a06c-b50efbd1fe96' ||
      'pub-c-0762a11a-399c-491a-80fd-b166d1c4fd18',
    subscribeKey:
      'sub-c-faa9828c-dfca-11eb-b709-22f598fbfd18' ||
      'sub-c-1fa049e0-39db-11eb-ab29-9acd6868450b',
    uuid: getUser()?._id,
  });

  const [data, setData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [message, setMessage] = useState('');
  const [messageIndicator, setMessageIndicator] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [allDateFetched, setAllDateFetched] = useState(false);

  let listRef = null;

  const chatHistory_listener = () => {
    setLoadMore(true);

    // Pubnub.history({channel: notifChannel, count: 10}, (status, res) => {
    //   console.log(res);
    // });

    Pubnub.history({channel: channel, count: 10}, (status, res) => {
      const {messages = [], startTimeToken: latestTime} = res || {};
      let newMessage = [];
      messages.forEach((element, index) => {
        newMessage[index] = element.entry[0];
      });
      setData((previousState) => {
        return [...newMessage?.reverse()];
      });
      setStartTime(latestTime);
    });
    setLoadMore(false);
    addListenerAndSubscripition();
  };

  const addListenerAndSubscripition = () => {
    Pubnub.addListener({
      message: (messageEvent) => {
        setData((previousState) => {
          return [messageEvent.message[0], ...previousState];
        });
      },
    });
    Pubnub.subscribe({channels: [channel]});
  };

  const cleanUpSubscription = () => {
    Pubnub.unsubscribe({channels: [channel]});
  };
  const setChannelDetails = () => {
    if (channelId) {
      let channel = channelId;
      let notificationChannel = channel;
      channel = channel && `Appt-${channel}`;
      notificationChannel =
        notificationChannel && `Notif-${notificationChannel}`;
      setNotificationChannel(notificationChannel);
      setChannel(channel);
      setChannelName(channel);
    }
  };
  useEffect(() => {
    if (medicalAssistant || channelId) {
      setChannelDetails();
    } else {
      getChannelDetail();
    }
    return () => {
      cleanUpSubscription();
      setChannelName('');
    };
  }, []);
  useEffect(() => {
    chatHistory_listener();
  }, [channel]);
  const sendOnNotificationChannel = (message) => {
    const userData = getUser();
    let payload = {
      room: notifChannel,
      pn_apns: {
        aps: {
          alert: {
            title: `New Mesaage from ${message[0].user.name}`,
            body: message[0].text || message[0].imageName,
          },
          sound: 'default',
        },
        pn_push: [
          {
            targets: [
              {
                environment: 'development',
                topic: 'ai.mayamd.mayamd',
              },
            ],
            version: 'v2',
          },
        ],
        data: {
          uri: userData?.userType === 'Patient' ? 'ModalView' : 'chatHistory',
          appointment_id: item?.appointment_id?._id,
          patient_id: item?.patient_id?._id,
          doctor_id: item?.doctor_id?._id,
          channel_id: channel,
          medicalAssistant_id: item?.medicalAssistant_id?._id,
          role: userData?.userType || '',
          userId: userData?._id || '',
          notificationChannel: notifChannel,
          messageNotification: true,
          title: `New Message from ${message[0].user.name}`,
          message: message[0].text,
        },
      },
      pn_gcm: {
        notification: {
          title: `New Mesaage from ${message[0].user.name}`,
          body: message[0].text || message[0].imageName,
          sound: 'default',
          // click_action: window.location.href,
        },
        data: {
          uri: userData?.userType === 'Patient' ? 'ModalView' : 'chatHistory',
          appointment_id: item?.appointment_id?._id,
          patient_id: item?.patient_id?._id,
          doctor_id: item?.doctor_id?._id,
          medicalAssistant_id: item?.medicalAssistant_id?._id,
          channel_id: channel,
          role: userData?.userType || '',
          userId: userData?._id || '',
          notificationChannel: notifChannel,
          messageNotification: true,
          title: `New Message from ${message[0].user.name}`,
          message: message[0].text,
        },
      },
    };
    Pubnub.publish({
      message: payload,
      channel: [notifChannel],
    });
  };

  const onSend = (messages = []) => {
    messages.map((message) => {
      Pubnub.publish({
        channel: channel,
        message: [message],
      })
        .then((res) => {
          sendOnNotificationChannel([message]);
          setMessage('');
          // console.log('Response>>>>>>>> ', res);
        })
        .catch((error) => {
          console.log('Error>>>>>', error);
        });
    });
  };

  const loadEarlierMessages = () => {
    if (allDateFetched) {
      return;
    }
    setLoadMore(true);
    Pubnub.history(
      {channel: channel, count: 10, start: startTime},
      (status, res) => {
        const {messages = [], startTimeToken: latestTime} = res || {};
        let newMessage = [];
        messages.forEach((element, index) => {
          newMessage[index] = element.entry[0];
        });
        if (newMessage.length && latestTime && startTime != latestTime) {
          setData((data) => {
            return [...data, ...newMessage?.reverse()];
          });
          setStartTime(latestTime);
        } else {
          setAllDateFetched(true);
        }
        setLoadMore(false);
      },
    );
  };
  const getModifedFileData = (value) => {
    const {name, file, thumbnail} = value || {};
    let isPdf =
      getFileExtension && name && getFileExtension(name) == 'pdf'
        ? true
        : false;
    let isVideo = false;
    if (!isPdf) {
      const vidoFormat = ['mkv', 'mp4', '3gp'];
      isVideo = vidoFormat.includes(getFileExtension(name) || '');
    }
    return {
      id: getNewId(),
      _createdOn: Date.now(),
      user,
      messageType: isPdf ? 'pdf' : isVideo ? 'video' : 'image',
      fileName: name,
      thumbnail,
      fileUrl: file,
    };
  };

  const getFileValue = (value) => {
    let modified_file_data = [];
    if (value && Array.isArray(value)) {
      modified_file_data = value.map((item) => {
        return getModifedFileData(item);
      });
    } else {
      modified_file_data = [getModifedFileData(value)];
    }
    onSend(modified_file_data);
  };

  const renderListItem = (item, index) => {
    let messageFromOthers = isMessageFromOthers({item, index});
    let {navigation} = props;
    if (messageFromOthers) {
      return (
        <RenderMessagefromOthers
          navigation={navigation}
          item={item}
          style={style}
        />
      );
    } else {
      return (
        <RenderMyOwnMessage navigation={navigation} item={item} style={style} />
      );
    }
  };

  const renderlist = ({item, index}) => {
    return renderListItem(item, index);
  };

  const isMessageFromOthers = ({item, index}) => {
    let {_id} = getUser() || {};
    if (item && item.user._id == _id) {
      return false;
    } else {
      return true;
    }
  };

  const onScrollToTop = () => {
    if (startTime) {
      loadEarlierMessages();
    }
  };

  // const scrollToBottom = (e) => {
  //   console.log('listRef.current',e);

  //   if (e) {
  //   e.scrollToOffset({animated: true, offset: 0});
  //   }
  // };

  //   function handleScroll (e)  {
  //   let {scrollTop} = e;
  //   console.log('scrollTopscrollTop3', e);
  //   let ScrollTopThresold = 30;
  //   if (scrollTop == 0) {
  //     // loadEarlierMessages()
  //      scrollToBottom(e);
  //   }
  //   if (scrollTop > ScrollTopThresold) {
  //     messageIndicator == false && setMessageIndicator(true);
  //   } else {
  //     messageIndicator == true && setMessageIndicator(false);
  //   }
  // };
  const validate = (value) => {
    const checkSize = 15;
    let {size = 0, name = ''} = value;
    size = size / (1000 * 1000);
    if (size < checkSize && size !== 0) {
      return false;
    } else {
      return `${name} size is greater than ${checkSize} Mb `;
    }
  };
  const renderImageUpload = () => {
    return (
      <UploadImage
        imageOnly={false}
        imageWidth={28}
        imageHeight={28}
        minHeight={28}
        onChangeValue={getFileValue}
        validateValue={validate}
        defaultImage={attachmentIcon}
        uploadOptions={{s3: true}}
        multiple={true}
      />
    );
  };

  const sendMessage = () => {
    if (!message.trim().length) {
      return;
    }
    onSend([
      {
        text: message.trim(),
        user: user,
        _createdOn: Date.now(),
        _id: Date.now(),
      },
    ]);
  };

  const appendMessage = (newMessage) => {
    let {messages} = this.state;
    messages.unshift(newMessage);
    this.setState({messages: [...messages]});
  };

  const arroundMessage = (item, index = 0) => {
    let {messages} = this.state;
    let lenght = messages.lenght;
    let messageIndex = messages.indexOf(item);
    return messageIndex + index + 1 > lenght
      ? false
      : get(messages, `${[messageIndex + index]}.user._id`, false);
  };
  function getRef(e) {
    // console.log('currentcurrent', e);
    listRef = e;
    // console.log('currentcurrent', listRef.scrollToOffset);
  }

  //   <TouchableOpacity
  //   style={{...style.downArrowStyle.boxStyle}}
  //   onPress={()=>{scrollToBottom()}}>
  //     <ActivityIndicator/>
  //   {/* <Image source={chatDownArrowIcon} style={{height: 20, width: 20}} /> */}
  // </TouchableOpacity>
  return (
    <>
      {' '}
      {channel ? (
        <View style={{flex: 1, overflow: 'hidden'}}>
          {loadMore ? (
            <TouchableOpacity style={style.loadMoreContainer}>
              <ActivityIndicator size={'small'} />
            </TouchableOpacity>
          ) : null}
          <InvertedList
            getRef={getRef}
            onEndReached={onScrollToTop}
            style={style.listContainerStyle.listStyle}
            // onScroll={handleScroll}
            data={data}
            renderItem={renderlist}
            keyExtractor={(item) => item._id}
          />

          {renderTextInput ? (
            <View style={style.messageInputStyle.boxStyle}>
              {renderImageUpload()}
              <View style={style.messageInputStyle.seperator} />

              <View style={{marginRight: 5, marginBottom: 3, flex: 1}}>
                <TextAreaInput
                  value={message}
                  onChangeText={(text) => {
                    setMessage(text);
                  }}
                  onSubmitEditing={sendMessage}
                  placeholder={'Type here...'}
                />
              </View>
              <TouchableOpacity
                style={{
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={sendMessage}>
                <Image source={sendChatIcon} />
              </TouchableOpacity>
            </View>
          ) : (
            void 0
          )}
        </View>
      ) : (
        <ActivityIndicator size={'small'} />
      )}
    </>
  );
};

export default ChatView;
