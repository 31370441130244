import {isMobile} from './app-components';
import {
  autosuggestFetch,
  googlePlaceFetch,
  getGooglePlaceDetail,
} from './AppServices';
import {roundCross} from './images';

export const defaultProps = (props, fetchProps, extraProps) => {
  const {
    model,
    query,
    searchField,
    valueField,
    suggestionField,
    primaryField,
    secondaryField,
    avatarIcon,
    ...restProps
  } = props;
  const newProps = {
    valueField: valueField || suggestionField,
    sugestionField: suggestionField,
    renderSelector: true,
    renderRowProps: {
      primaryField: primaryField || suggestionField,
      secondaryField,
      avatarIcon,
    },
    modalPosition: isMobile ? 'screenBottom' : void 0,
    ...extraProps,
    ...restProps,
  };
  if (model && query && !newProps.fetch) {
    newProps.fetch = autosuggestFetch({
      model,
      id: query,
      search: searchField,
      ...fetchProps,
    });
  }
  return newProps;
};

export const autoSuggestInput = (props, fetchProps) => {
  return defaultProps(props, fetchProps, {
    type: 'autoSuggest',
    showArrow: true,
    floatLabelOnActive: false,
  });
};

export const multiAutoSuggestInput = (props, fetchProps) => {
  return defaultProps(props, fetchProps, {
    type: 'multiAutoSuggest',
    showArrow: true,
    floatLabelOnActive: false,
    removeChipIcon: roundCross,
    chipsVariant: 'rounded',
  });
};

export const autoSuggestAvatarInput = (props, fetchProps) => {
  return defaultProps(props, fetchProps, {
    type: 'autoSuggestAvatar',
    showArrow: true,
    floatLabelOnActive: false,
  });
};

export const multiAutoSuggestAvatarInput = (props, fetchProps) => {
  return defaultProps(props, fetchProps, {
    type: 'multiAutoSuggestAvatar',
    showArrow: true,
    floatLabelOnActive: false,
    removeChipIcon: roundCross,
    chipsVariant: 'rounded',
  });
};

export const autoSuggestOptionsInput = (props) =>
  defaultProps(props, void 0, {
    type: 'autoSuggest',
    keyField: 'value',
    showArrow: true,
    floatLabelOnActive: false,
  });

export const multiAutoSuggestOptionsInput = (props) => {
  return defaultProps(props, void 0, {
    type: 'multiAutoSuggest',
    keyField: 'value',
    showArrow: true,
    floatLabelOnActive: false,
    removeChipIcon: roundCross,
    chipsVariant: 'rounded',
  });
};

export const searchInput = (props, fetchProps) => {
  return defaultProps(props, fetchProps, {
    type: 'search',
    searching: true,
  });
};

export const searchAvatarInput = (props, fetchProps) => {
  return defaultProps(props, fetchProps, {
    type: 'searchAvatar',
    searching: true,
  });
};

export const multiSearchInput = (props, fetchProps) => {
  return defaultProps(props, fetchProps, {
    type: 'multiSearch',
    searching: true,
    removeChipIcon: roundCross,
    chipsVariant: 'rounded',
  });
};

export const multiSearchAvatarInput = (props, fetchProps) => {
  return defaultProps(props, fetchProps, {
    type: 'multiSearchAvatar',
    searching: true,
    removeChipIcon: roundCross,
    chipsVariant: 'rounded',
  });
};

export const googlePlaceInput = (props) => {
  const {
    valueField = 'description',
    suggestionField = 'description',
    ...restProps
  } = props || {};

  return defaultProps({...restProps, valueField, suggestionField}, void 0, {
    type: 'googlePlaceMap',
    fetch: googlePlaceFetch,
    modifySelectedData: getGooglePlaceDetail,
    searching: true,
  });
};
