import React, {Component} from 'react';
import {Image, TouchableOpacity, Text} from '../../index';
import {WithInputWrapper} from '../../../npms/react-input-wrapper';
import {h12_Regular} from '../../../theme/fonts';

class CheckBox extends Component {
  onPress = (e) => {
    let {value, onChangeValue, onChange, editable = true} = this.props;
    if (!editable) {
      return;
    }
    if (onChange) {
      onChange(e);
    }
    if (onChangeValue) {
      onChangeValue && onChangeValue(!value, e);
    }
  };
  render() {
    let {
      value,
      iconStyle,
      selectedIcon,
      unSelectedIcon,
      label,
      enableLabel = false,
      editable = true,
      showCheckBox = true,
    } = this.props;
    if (enableLabel) {
      return (
        <TouchableOpacity
          style={
            editable
              ? {cursor: 'pointer', flexDirection: 'row'}
              : {flexDirection: 'row'}
          }
          onPress={this.onPress}>
          {showCheckBox && (
            <Image
              source={value ? selectedIcon : unSelectedIcon}
              style={iconStyle}
            />
          )}
          <Text
            style={{
              color: 'rgb(142, 142, 142)',
              paddingLeft: 7,
              ...h12_Regular,
            }}>
            {label || ''}
          </Text>
        </TouchableOpacity>
      );
    }
    return (
      <TouchableOpacity
        style={editable ? {cursor: 'pointer'} : {}}
        onPress={this.onPress}>
        <Image
          source={value ? selectedIcon : unSelectedIcon}
          style={iconStyle}
        />
      </TouchableOpacity>
    );
  }
}

export default WithInputWrapper(CheckBox);
