import React from 'react';
import {
  Text,
  View,
  ProgressIndicator,
  Snackbar,
  isMobile,
  ToolBar,
} from '../index';
import {Action} from '../action/Action';
import {
  tableHeaderTheme,
  tableFooterTheme,
  tableMobileTheme,
  tableTheme,
} from '../../theme/tableTheme';
import {
  expandedListTheme,
  expandedListMobileTheme,
  filledExpandedListMobileTheme,
  filledExpandedListTheme,
} from '../../theme/expandedTableRowTheme';
import {getUser} from '../../AppServices';
import {DataFetch} from '../data-fetch/DataFetch';
import {getResolvedMQProps} from '../../BreakPoints';
import {Card} from '../../npms/react-card';
import {ReactTable, HeaderRow, TableRow} from '../../npms/react-table';
import {DataFetchConstants} from '../../npms/react-datafetch';
import {cardTheme, cardMobileTheme} from '../../theme/cardTheme';
import MoreActions from '../action/MoreActions';
import {CellRender, HeaderCellRender} from './cell/CellRenders';
import {
  clearSelectionState,
  getSelectionState,
  setAllSelectionState,
  setSelectionState,
} from '../render-components/selector/utility';
import {
  WithHeaderSelector,
  WithRowSelector,
} from '../render-components/selector/Selector';

const {fetchMoreEvent} = DataFetchConstants;

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

const StandardCard = (props) => {
  return <Card {...(isMobile ? cardMobileTheme : cardTheme)} {...props} />;
};

const cardTypes = {
  standard: StandardCard,
};
class CardRow extends React.Component {
  render() {
    let {type = 'standard', Component, ...restProps} = this.props;
    if (!Component) {
      Component = cardTypes[type];
    }
    if (!Component) {
      return null;
    }
    return <Component {...restProps} />;
  }
}
const typeWiseExpandableRow = {
  filledList: (props) => {
    return (
      <Table
        {...(isMobile
          ? filledExpandedListMobileTheme
          : filledExpandedListTheme)}
        {...props}
      />
    );
  },
  list: (props) => {
    return (
      <Table
        {...(isMobile ? expandedListMobileTheme : expandedListTheme)}
        {...props}
      />
    );
  },
};

class RenderRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    // Object.keys(this.props).forEach(key => {
    //   if (this.props[key] !== prevProps[key]) {
    //     console.log('@@@@@@@@key changes', key);
    //   }
    // });
    let {navigation} = this.props;
    if (navigation) {
      let newParms = navigation.state && navigation.state.params;
      if (this.oldParams && newParms && this.oldParams !== newParms) {
        this.setState(this.getInitialState());
      }
      this.oldParams = newParms;
    }
  }

  getInitialState = () => {
    let {expanded = false} = this.props;
    return {
      expanded,
      selected: false,
      swiped: false,
      isHover: false,
      modalVisible: false,
    };
  };

  removeMouseHover = () => {
    this.setState({isHover: false});
  };

  onMouseLeave = (e) => {
    let {onRowMouseEnter, onMouseLeave} = this.props;
    if (this.state.modalVisible) {
      this.isMouseleave = e;
      return;
    }
    onRowMouseEnter ? onRowMouseEnter() : this.removeMouseHover();
    onMouseLeave && onMouseLeave(e);
  };

  onMouseEnter = (e) => {
    let {onRowMouseEnter, onMouseEnter} = this.props;
    this.setState({isHover: true});
    onRowMouseEnter && onRowMouseEnter(this.removeMouseHover);
    onMouseEnter && onMouseEnter(e);
  };

  onModalShow = (modalVisible) => {
    this.setState({modalVisible});
    if (!modalVisible && this.isMouseleave) {
      this.onMouseLeave(this.isMouseleave);
      this.isMouseleave = void 0;
    }
  };

  removeActionSelection = () => {
    let {disabledToggle} = this.props;
    this.setState({
      expanded: !disabledToggle ? false : this.state.expanded,
      selected: false,
    });
    this.expanded = false;
  };

  handleExpanded = (value) => {
    let {disabledToggle, onRowSelect, onToggleExpanded} = this.props;
    this.expanded = this.state.expanded === value ? void 0 : value;
    this.setState({expanded: this.expanded});
    onToggleExpanded && onToggleExpanded({expanded: this.expanded});
    !disabledToggle && onRowSelect && onRowSelect(this.removeActionSelection);
  };

  toggleExpanded = () => {
    this.handleExpanded(true);
  };

  onExpandColumn = (column) => () => {
    this.handleExpanded(column);
  };

  onSelect = (props) => {
    let {onRowSelect, onSelect, onRowTouch, item} = this.props;
    if (this.expanded) {
      return;
    }
    if (this.state.swiped) {
      this.setState({swiped: false});
      onRowSelect && onRowSelect();
    } else {
      this.setState({selected: true});
      onRowSelect && onRowSelect(this.removeActionSelection);
      onRowTouch && onRowTouch({item});
    }
    onSelect && onSelect(props);
  };

  onSwipe = (callback) => {
    let {onRowSelect} = this.props;
    this.setState({swiped: true});
    onRowSelect && onRowSelect(callback);
  };

  getExpandedComponent = (rowProps = {}) => {
    let {
      renderExpandedRow,
      currentViewIndex,
      lastViewIndex,
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
    } = this.props;
    let {expanded} = this.state;
    if (!renderExpandedRow || !expanded) {
      return null;
    }
    let expandedComponent = getRenderComponent(renderExpandedRow, rowProps);
    if (expandedComponent && !React.isValidElement(expandedComponent)) {
      const {type, ...restRowProps} = expandedComponent || {};
      let params = {
        ...restRowProps,
        currentViewIndex,
        lastViewIndex,
        navigation,
        eventDispatcher,
        screenState,
        setScreenState,
      };
      expandedComponent =
        (typeWiseExpandableRow[type] && typeWiseExpandableRow[type](params)) ||
        typeWiseExpandableRow['list'](params);
    }
    return (
      <View key={`${expanded}_${rowProps.index}`}>{expandedComponent}</View>
    );
  };

  getSeparator = (separatorStyle) => {
    return separatorStyle ? <View style={separatorStyle}></View> : void 0;
  };

  render() {
    let {
      currentViewIndex,
      lastViewIndex,
      onSelect,
      expanded,
      renderRow,
      renderColumn,
      rowStyle,
      rowWidth,
      card,
      renderExpandedRow,
      toggleExpanded,
      expandedListType,
      hoverable,
      ...restProps
    } = this.props;
    let {selected, isHover} = this.state;
    let showSelected =
      currentViewIndex !== undefined &&
      lastViewIndex !== undefined &&
      currentViewIndex !== lastViewIndex &&
      selected;

    let rowProps = {
      Action: Action,
      MultiActions: MoreActions,
      isHover,
      selected: showSelected,
      onSelect: this.onSelect,
      onSwipe: this.onSwipe,
      onModalShow: this.onModalShow,
      toggleExpanded: this.toggleExpanded,
      onExpandColumn: this.onExpandColumn,
      expanded: this.state.expanded,
      ...restProps,
    };
    let Container = void 0;
    let containerProps = void 0;
    let renderRowComponent = null;
    if (renderRow) {
      renderRowComponent = getRenderComponent(renderRow, rowProps);
      if (!renderRowComponent) {
        return null;
      }
      if (!React.isValidElement(renderRowComponent)) {
        Container = renderRowComponent.Container;
        containerProps = renderRowComponent.containerProps;
        renderRowComponent = null;
      }
    }

    let {
      topSeparator,
      rowWrapperStyle,
      evenRowWrapperStyle,
      separatorStyle,
      cardSeparatorStyle,
      cardWrapperStyle,
      ...restRowStyle
    } = rowStyle;
    if (!renderRowComponent) {
      if (card) {
        card = getRenderComponent(card, rowProps);
        if (!card) {
          return null;
        }
        renderRowComponent = React.isValidElement(card) ? (
          card
        ) : (
          <CardRow {...rowProps} {...card} />
        );
        rowWrapperStyle = cardWrapperStyle;
        separatorStyle = cardSeparatorStyle;
      } else {
        renderRowComponent = (
          <TableRow
            {...rowProps}
            {...restRowStyle}
            renderColumn={renderColumn}
          />
        );
        if (restProps.index % 2 === 0 && evenRowWrapperStyle) {
          rowWrapperStyle = evenRowWrapperStyle;
        }
        rowWrapperStyle = {
          ...rowWrapperStyle,
        };
        if (rowWidth) {
          rowWrapperStyle.width = rowWidth;
        }
      }
    }
    if (hoverable !== false && !isMobile) {
      renderRowComponent = (
        <View onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          {renderRowComponent}
        </View>
      );
    }
    let Component = (
      <View style={rowWrapperStyle}>
        {topSeparator ? this.getSeparator(separatorStyle) : void 0}
        {renderRowComponent}
        {this.getExpandedComponent(rowProps)}
        {topSeparator !== false ? this.getSeparator(separatorStyle) : void 0}
      </View>
    );
    if (Container) {
      Component = <Container {...containerProps}>{Component}</Container>;
    }
    return Component;
  }
}
RenderRow = WithRowSelector(RenderRow);

class RenderHeaderRow extends React.PureComponent {
  render() {
    let {renderHeaderRow, ...restProps} = this.props;
    if (renderHeaderRow) {
      return getRenderComponent(renderHeaderRow, restProps);
    } else {
      return <HeaderRow {...restProps} />;
    }
  }
}

RenderHeaderRow = WithHeaderSelector(RenderHeaderRow);

class PureTable extends React.PureComponent {
  getSelectionProps = () => {
    return {
      setAllSelectionState: this.setAllSelectionState,
      setSelectionState: this.setSelectionState,
      getSelectionState: this.getSelectionState,
      clearSelectionState: this.clearSelectionState,
    };
  };

  onDataError = (error) => {
    error = error && error.message ? error.message : JSON.stringify(error);
    error &&
      Snackbar &&
      Snackbar.show({
        text: `${error}`,
        duration: Snackbar.LENGTH_LONG,
      });
  };
  onToggleExpanded = ({expanded}) => {
    const {navigation} = this.props;
    if (navigation) {
      let isModalView = navigation.isModalView && navigation.isModalView();
      if (!isModalView) {
        let routeCount = navigation.getRouteCount && navigation.getRouteCount();
        if (routeCount && navigation.getIndex) {
          let count = routeCount - 1 - (navigation.getIndex() || 0);
          if (count) {
            navigation.pop(count);
          }
        }
      }
    }
  };

  renderItem = (props) => {
    let {
      header,
      footer,
      renderHeader,
      renderFooter,
      typeWiseWidth,
      leftGap,
      RightGap,
      data,
      ...restProps
    } = this.props;
    let extraProps = {
      onToggleExpanded: this.onToggleExpanded,
      ...this.getSelectionProps(),
    };
    return (
      <RenderRow
        renderColumn={CellRender}
        {...restProps}
        {...props}
        {...extraProps}
      />
    );
  };

  renderHeaderRow = (props) => {
    let headerProps = {
      Action,
      ...this.props,
      ...props,
      renderColumn: HeaderCellRender,
      ...this.getSelectionProps(),
    };
    return <RenderHeaderRow {...headerProps} />;
  };

  renderHeader = (props) => {
    let {
      navigation,
      eventDispatcher,
      renderHeader,
      header,
      screenState,
      setScreenState,
    } = this.props;
    let headerProps = {
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      ...props,
    };
    if (renderHeader) {
      return getRenderComponent(renderHeader, headerProps);
    } else if (header) {
      return <ToolBar {...tableHeaderTheme} {...headerProps} {...header} />;
    } else {
      return null;
    }
  };

  renderFooter = (props) => {
    let {
      navigation,
      eventDispatcher,
      renderFooter,
      footer,
      screenState,
      setScreenState,
    } = this.props;
    let footerProps = {
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      ...props,
      ...this.getSelectionProps(),
    };
    if (renderFooter) {
      return getRenderComponent(renderFooter, footerProps);
    } else if (footer) {
      return <ToolBar {...tableFooterTheme} {...footerProps} {...footer} />;
    } else {
      return null;
    }
  };

  renderNoData = (props) => {
    let {navigation, eventDispatcher, renderNoData} = this.props;
    if (renderNoData) {
      return getRenderComponent(renderNoData, {
        navigation,
        eventDispatcher,
        ...props,
      });
    }
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}>
        <Text>No Data Found</Text>
      </View>
    );
  };

  resolveVisible = (visible = true) => {
    let {navigation} = this.props;
    if (typeof visible === 'function') {
      visible = visible({
        user: getUser && getUser(),
        navigation,
      });
    }
    return visible;
  };

  getSelectionState = () => {
    let {screenState} = this.props;
    return getSelectionState({screenState});
  };
  clearSelectionState = () => {
    let {screenState, setScreenState} = this.props;
    clearSelectionState({screenState, setScreenState});
  };
  setSelectionState = (props) => {
    const {screenState, setScreenState, singleSelection} = this.props;
    setSelectionState({...props, screenState, setScreenState, singleSelection});
  };
  setAllSelectionState = (props) => {
    const {screenState, setScreenState} = this.props;
    setAllSelectionState({...props, setScreenState, screenState});
  };

  render() {
    let {screenState, ...restProps} = this.props;
    if (restProps.columns && restProps.columns.length) {
      restProps.columns = restProps.columns.filter((column) => {
        if (column) {
          return this.resolveVisible(column.visible);
        }
      });
    }

    return (
      <ReactTable
        getUser={getUser}
        onDataError={this.onDataError}
        DataFetch={DataFetch}
        StatusBar={ProgressIndicator}
        fetchMoreEvent={fetchMoreEvent}
        {...restProps}
        renderNoData={this.renderNoData}
        renderHeader={this.renderHeader}
        renderFooter={this.renderFooter}
        renderHeaderRow={this.renderHeaderRow}
        renderItem={this.renderItem}
      />
    );
  }
}

export class Table extends React.Component {
  render() {
    let {navigation} = this.props;
    const activeMQ =
      navigation && navigation.getActiveMQ && navigation.getActiveMQ();
    let resolvedProps = getResolvedMQProps(this.props, {activeMQ});
    return (
      <PureTable
        {...(isMobile ? tableMobileTheme : tableTheme)}
        {...resolvedProps}
        activeMQ={activeMQ}
      />
    );
  }
}

export const TableHoc = (defs) => {
  class TableComponent extends React.Component {
    render() {
      let _defs = defs;
      const {appointmentStore} = this.props;
      let key = void 0;
      if (appointmentStore) {
        const {newData: {_id} = {}} = appointmentStore || {};
        key = _id;
      }
      if (typeof _defs === 'function') {
        _defs = _defs(this.props);
      }
      return <Table {..._defs} {...this.props} key={key} />;
    }
  }
  return TableComponent;
};

export default TableHoc;
