import React from 'react';
import {View, Text} from '../../app-components';
import {resolvePatientName} from '../../app-components/patientUtility';
import {resolveDoctorName} from '../../app-components/DoctorUtility';
import {Table} from '../../app-components/table/Table';
import {h12_Medium} from '../../theme/fonts';
import {patientWatingRoomCard} from '../../theme/shadows';
import { serialNumber } from '../RPM/rpmUtils';

const TransactionTable = (props) => {
  const tableRow = {
    columns: [
      {
        width: 50,
        render: serialNumber
      },
      {
        header: 'Date | time',
        type: 'date',
        field: 'date',

        width: 200,
        format: 'DD MMM, hh:mm A',
      },
      {
        header: 'Doctor Name',
        type: 'text',
        field: (data) => {
          return resolveDoctorName(data?.doctor);
        },
        width: 200,
      },
      {
        header: 'Payment',
        type: 'text',
        field: 'amount',
        width: 150,
      },
      {
        header: 'Payee Name',
        type: 'text',
        field: (data) => {
          return (
            resolvePatientName(data?.appointment?.patient_id) ||
            resolveDoctorName(data?.appointment?.patient_id)
          );
        },
        width: 180,
      },
      {
        header: 'Status',
        type: 'text',
        field: 'status',
        width: 150,
      },
      {
        header: 'Transaction ID',
        type: 'text',
        field: 'transaction_id',
      },
    ],
  };

  let uri = () => {
    const {filter} = props;
    return {
      query: {
        id: 'transactionLists',
        addOnFilter: filter,
      },
      model: 'Transactions',
    };
  };

  return (
    <View
      style={{
        minHeight: 75,
        // border: '1px solid rgba(56, 56, 56, 0.27)',
        ...patientWatingRoomCard,
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
      }}>
      <Table uri={uri} {...tableRow} {...props} />
    </View>
  );
};

export default TransactionTable;
