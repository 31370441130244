import React from 'react';
import ScrollView from './ScrollView';

class FlatList extends React.Component {
  render() {
    const {data = [], renderItem, ...restProps} = this.props;
    return (
      <ScrollView {...restProps}>
        {data.map((item, index) => {
          if (!renderItem) {
            return;
          }
          return renderItem({item, index});
        })}
      </ScrollView>
    );
  }
}

export default FlatList;
