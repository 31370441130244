import React from 'react';
import {
  View,
  // TouchableOpacity,
  // Text,
  ScrollView,
  isMobile,
} from '../app-components';
import {BackgroundImage} from '../images';
import SideBar from './SideBar';
import Header from './Header';
import BottomBar from './BottomBar';

const Layout = (props) => {
  const {children = {}, navigation} = props || {};
  const [selectedMenu, setSelectedMenu] = React.useState('dashboard');
  const routes = navigation.getActiveRoutes();
  let firstRoute = routes[0] || [];
  React.useEffect(() => {
    const routeName = navigation?.getRouteName();
    if (routeName === 'patient-waiting-room') {
      setSelectedMenu('appointments');
    }
    if (routeName === 'provider' || routeName === 'doctor-search') {
      setSelectedMenu('findAProvider');
    }
    if (routeName === 'health-assistant') {
      setSelectedMenu('healthAssistant');
    }
    if (routeName === 'graph-image') {
      setSelectedMenu('graphImage');
    }
  }, [navigation]);
  let videoCall = false;
  if (
    firstRoute !== 'videoSessionMobile' &&
    routes?.length > 1 &&
    routes?.indexOf('videoSessionMobile') !== -1
  ) {
    // return null;
    videoCall = true;
  }
  return (
    <View
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flex: 1,
        flexDirection: 'row',
        padding: isMobile ? 0 : 15,
        overflow: 'hidden',
      }}>
      {isMobile ? (
        void 0
      ) : (
        <SideBar
          {...props}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
        />
      )}
      <View
        style={{
          // backgroundColor: 'red',
          overflow: 'hidden',
          flex: 1,
          paddingLeft: isMobile ? 5 : 20,
          paddingRight: isMobile ? 5 : 20,
        }}>
        {selectedMenu !== 'healthAssistant' && !videoCall ? (
          <Header {...props} />
        ) : (
          ''
        )}

        <ScrollView style={{flex: 1, borderRadius: 10}}>{children}</ScrollView>
        {isMobile && !videoCall ? (
          <BottomBar
            {...props}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
          />
        ) : (
          void 0
        )}
      </View>
    </View>
  );
};

export default Layout;
