import React from 'react';
import {
  View,
  Text,
  Linking,
  TouchableOpacity,
  isMobile,
} from './app-components';
const Footer = (props) => {
  const {navigation = {}} = props || {};
  const routes = navigation.getActiveRoutes();
  let firstRoute = routes[0] || [];
  // if (isMobile) {
  //   return null;
  // }
  if (
    firstRoute !== 'videoSession' &&
    routes?.length > 1 &&
    routes?.indexOf('videoSession') !== -1
  ) {
    return null;
  }
  if (
    firstRoute !== 'videoSessionMobile' &&
    routes?.length > 1 &&
    routes?.indexOf('videoSessionMobile') !== -1
  ) {
    return null;
  }
  const getText = ({title, link, view = '', id = ''}) => {
    return (
      <TouchableOpacity
        id={id}
        style={{cursor: 'pointer'}}
        onPress={() => {
          if (view) {
            navigation.push({
              view,

              modal: true,
              modalProps: {
                autoHide: true,
                minHeight: 200,
                height: '100%', //600,
                width: isMobile ? 300 : '100%',
                position: 'screenCenter',
              },
            });
          } else {
            Linking.openURL(link, '_blank');
          }
        }}>
        <Text style={{color: '#fff'}}>{title}</Text>
      </TouchableOpacity>
    );
  };
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingTop: 4,
        paddingBottom: 4,
        backgroundImage: false
          ? ''
          : 'linear-gradient(to right,#0095C9 ,#112E45)',
      }}>
      {isMobile ? (
        void 0
      ) : (
        <View style={{flex: 1.5}}>
          {getText({
            title: 'Copyright © 2021 MayaMD. All rights reserved',
            link: 'https://www.mayamd.ai',
            id: 'copyright',
          })}
        </View>
      )}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          flex: 1,
        }}>
        {getText({
          title: 'Privacy Policy',
          link: 'https://www.mayamd.ai/privacy-policy',
          id: 'privacy_policy',
        })}
        {getText({
          title: 'Terms & Conditions',
          view: 'terms',
          id: 'terms_conditions',
        })}
        {getText({
          title: 'User Agreement',
          link: 'https://www.mayamd.ai/user-agreement',
          id: 'user_agreement',
        })}
        {getText({
          title: 'Contact us',
          link: 'https://www.mayamd.ai/contact',
          id: 'contact_us',
        })}
      </View>
    </View>
  );
};
export default Footer;
