import React from 'react';
import {
  ReactModalViewComponentContext,
  ReactModalViewListenerContext,
} from './ReactModalViewContext';
import {BackHandler} from '../../react-core-components';
class ReactModalViewContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.listeners = {
      pushModal: this.pushModal,
      popModal: this.popModal,
      setModalState: this.setModalState,
      clearAll: this.clearAll,
      updateState: this.updateState,
    };
  }
  componentDidMount() {
    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress,
    );
  }
  componentWillUnmount() {
    this.backHandler && this.backHandler.remove();
  }
  handleBackPress = () => {
    let {views} = this.state;
    if (views && views.length) {
      let lastView = views[views.length - 1];
      if (lastView && lastView.state && lastView.state.closeModal) {
        lastView.state.closeModal();
        return true;
      }
    }
    return false;
  };
  updateState = () => {
    let {views} = this.state;
    if (views && views.length) {
      this.setState({});
    }
  };
  clearAll = () => {
    this.setState({views: []});
  };
  pushModal = (_state) => {
    let {views} = this.state;
    views = views ? [...views] : [];
    views.push({state: _state, push: true});
    this.setState({views});
  };
  popModal = (_state) => {
    let {views} = this.state;
    if (views && views.length) {
      views = views.slice(0, views.length - 1);
      this.setState({views});
    }
  };
  setModalState = (_state) => {
    //for autosuggest input, we will push in last with replace:true
    // push modal will be called from stackmodal as a top level modal

    let {views} = this.state;
    views = views ? [...views] : [];

    if (!_state.showModal) {
      if (!views || !views.length) {
        return;
      }
      const replace = _state.replace;
      if (replace) {
        /**Navin Prakash && Vishal 10/10/2020
         * we splice last view if replce true exist in last view
         * change it because issue comes in autosuggest-input.
         * the textinput of autosuggest setmodal on blur and on select value. because of this another modal closed
         * and next modal view is not working
         */
        let lastView = views[views.length - 1];
        if (lastView && lastView.replace) {
          views = views.slice(0, views.length - 1);
        }
      } else {
        views = views.slice(0, views.length - 1);
      }
      this.setState({views});
      return;
    }
    if (!views.length) {
      views.push({state: _state, replace: true});
    } else {
      let lastView = views[views.length - 1];
      if (lastView.push) {
        views.push({state: _state, replace: true});
      } else {
        views[views.length - 1] = {state: _state, replace: true};
      }
    }

    this.setState({views});
  };
  render() {
    return (
      <ReactModalViewListenerContext.Provider value={this.listeners}>
        <ReactModalViewComponentContext.Provider value={this.state}>
          {this.props.children}
        </ReactModalViewComponentContext.Provider>
      </ReactModalViewListenerContext.Provider>
    );
  }
}

export default ReactModalViewContextProvider;
