import React from 'react';
import {
  Text,
  TouchableWithoutFeedback,
  View,
  Image,
  TouchableOpacity,
  isMobile,
} from '../../app-components';
import Table from '../../app-components/table/Table';
import {
  h11_Medium,
  h12_Regular,
  h13_Regular,
  h14_Regular,
  h20_Regular,
  h14_AvenirNext,
} from '../../theme/fonts';
import moment from 'moment';
import {femaleSign, maleSign, userIcon, doctorPatientChat} from '../../images';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {getUser, getImageUrl, invoke, post} from '../../AppServices';
import EmergencyContext from '../../EmergencyContext';
import {getAge} from '../../app-components/UtilityFunctions';
import { capitalize } from 'lodash';
let uri = () => {
  let {organization: {_id=""} = {}} = getUser() || {};
  return {
    query: {
      id: 'emergencyRequest',
      addOnFilter: {
        hospital_id: {_id},
        // status: 'scheduled',
      },
      sort: {_createdOn: -1},
      // paramValue: {
      //   period: {
      //     from: moment(new Date()).startOf('day').toDate(),
      //     // to: moment(new Date()).endOf('day').toDate(),
      //   },
      // },
    },
    model: 'Emergency_request',
  };
};

const appointmentTiming = ({item}) => {
// console.log(`>@>Puru ~ file: Emergency.js ~ line 47 ~ item`, item)
  return (
    <View>
      <View>
        <Text style={{...h20_Regular, lineHeight: 21, letterSpacing: 0.71}} />
      </View>
      <View>
        <Text style={{...h13_Regular, lineHeight: 21, letterSpacing: 0.46}}>
          {moment(item?._createdOn).format('MMM DD | hh:mm A')}
          {/* {item?.waiting || 'Offline'} */}
        </Text>
      </View>
    </View>
  );
};

const patientMobile = ({item}) => {
  // console.log(`>@>Puru ~ file: Emergency.js ~ line 47 ~ item`, item)
    return (
      <View>
        <View>
          <Text style={{...h20_Regular, lineHeight: 21, letterSpacing: 0.71}} />
        </View>
        <View>
          <Text style={{...h13_Regular, lineHeight: 21, letterSpacing: 0.46}}>
          {item?.patient_id?.mobile}
          </Text>
        </View>
      </View>
    );
  };

const appointmentType = ({item}) => {
  return (
    <TouchableWithoutFeedback>
      <View
        style={{
          backgroundColor:
            item?.appointment_type === 'follow_up'
              ? 'rgb(224, 227, 240)'
              : 'rgb(255, 240, 227)',
          width: 100,
          borderRadius: 15.5,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text
          style={{
            ...h12_Regular,
            padding: 5,
            color:
              item?.appointment_type === 'follow_up' ? '#275dad' : '#ff7b20',
          }}>
          {item?.appointment_type === 'follow_up'
            ? 'Follow Up'
            : item?.appointment_type === 'new'
            ? 'New'
            : null}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

const patientName = ({item}) => {
  let {checkedIn=false}=item||{}
  let imageUrl = item?.patient_id?.profile_picture;
  imageUrl = imageUrl && getImageUrl(imageUrl?.thumbnail);
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <View
        style={{
          alignItems: 'center',
          position: 'relative',
        }}>
        <Image
          source={imageUrl || userIcon}
          style={{
            height: 40,
            width: 40,
            borderRadius: 20,
            // objectFit:"contain"
          }}
        />
        {/* Availablity Button */}
        {checkedIn?<View
          style={{
            height: 12,
            width: 12,
            backgroundColor: 'green',
            position: 'absolute',
            bottom: -1.5,
            right: -1.5,
            border: '0.5px solid white',
            borderRadius: 10,
          }}
        />:void 0}
      </View>

      <View style={{padding: 10}}>
        <Text
          style={{
            ...h14_AvenirNext,
            lineHeight: 21,
            letterSpacing: 0.57,
            numberOfLines: 1,
          }}
          numberOfLines={1}>
          {item?.patient_id?.name}
        </Text>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {item?.patient_id?.gender === 'male' ? (
            <Image
              source={maleSign}
              style={{marginRight: 5}}
              height={15}
              width={15}
            />
          ) : item?.patient_id?.gender === 'female' ? (
            <Image
              source={femaleSign}
              style={{marginRight: 5}}
              height={15}
              width={15}
            />
          ) : null}

          <Text style={{...h13_Regular, lineHeight: 21, letterSpacing: 0.57}}>
            {item?.patient_id?.gender &&
              item?.patient_id?.gender[0].toUpperCase()}
            {item?.patient_id?.birthDate && ' | ' + getAge(item?.patient_id?.birthDate)}
          </Text>
        </View>
      </View>
    </View>
  );
};
const cancelButton = (props) => {
  return(
    <TouchableOpacity 
    style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:"red",
        cursor:"pointer",
        color:"white",
        padding:10,
        borderRadius:8,
        minHeight:40
      }}
      onPress =  {() => {
              const {eventDispatcher, item} = props;
              invoke({
                id: 'refundTransaction',
                paramValue: {
                  data: {
                    status: 'cancelled',
                    _id: item?._id,
                  },
                },
              })
                .then((res) => {
                  eventDispatcher.notify('reloadAddApointment');
                })
                .catch((err) => {
                  eventDispatcher.notify('reloadAddApointment');
                  console.log('err', err);
                });
            }}
   >
      <Text>Cancel</Text>

    </TouchableOpacity>
  )
}
const completeAppointmentButton = (props) => {
// console.log(`>@>Puru ~ file: Emergency.js ~ line 212 ~ props`, props)
  return (
  <TouchableOpacity style={    {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:"green",
      // cursor:"pointer",
      color:"white",
      padding:10,
      borderRadius:8,
      minHeight:40,
      // marginLeft:19,
      // marginRight:19
    }}
   
    // onPress ={async () => {
    //   const {eventDispatcher, item} = props;
    //   await post({
    //     data: {...item},
    //     updates: {status: 'completed'},
    //     model: 'Appointments',
    //   }).then(() => {
    //     eventDispatcher.notify('reloadAddApointment');
    //   });
    // }}
    >
   
    <Text>{capitalize(props?.status)}</Text>

  </TouchableOpacity>
  )
}
const patientQuery = ({item, navigation}) => {
  const {medical_report = [], mayamd_notes = []} = item;
  return (
    <View>
      <View>
        <Text
          numberOfLines={2}
          style={{...h14_Regular, lineHeight: 21, letterSpacing: 0.5}}>
          {item.illness_description}
        </Text>
      </View>
      <View style={{flexDirection: 'row'}}>
        {Array.isArray(mayamd_notes) && mayamd_notes.length ? (
          <TouchableOpacity
            style={{flexDirection: 'row', cursor: 'pointer'}}
            onPress={() => {
              navigation.push({
                view: 'pdfReader',
                params: {
                  dataArr: mayamd_notes,
                },
                modal: true,
                modalProps: {
                  autoHide: true,
                  minHeight: 200,
                  height: 600,
                  width: isMobile ? 300 : 650,
                  position: 'screenCenter',
                },
              });
            }}>
            <Text style={{...h11_Medium, color: '#275dad'}}>
              {'Mayamd note' + ' '}
            </Text>
          </TouchableOpacity>
        ) : null}
        {/* <TouchableOpacity
          style={{flexDirection: 'row', cursor: 'pointer'}}
          onPress={() =>
            navigation.push({
              view: 'view-detail',
              params: {item},
            })
          }>
          <Text style={{...h11_Medium, color: '#275dad'}}>
            {'View Details' + ' '}
          </Text>
        </TouchableOpacity> */}

        {Array.isArray(medical_report) && medical_report.length ? (
          <TouchableOpacity
            style={{flexDirection: 'row', cursor: 'pointer'}}
            onPress={() => {
              navigation.push({
                view: 'pdfReader',
                params: {
                  dataArr: medical_report,
                },
                modal: true,

                modalProps: {
                  autoHide: true,
                  minHeight: 200,
                  height: 600,
                  width: isMobile ? 300 : 650,
                  position: 'screenCenter',
                  // ...confirmModalProps,
                },
              });
            }}>
            <Text style={{...h11_Medium, color: '#275dad'}}>
              {Array.isArray(mayamd_notes)&& mayamd_notes.length?' | ' + 'Attachments':'Attachments'}
            </Text>
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};

const duration = ({item}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <View
        style={{
          backgroundColor: item?.isAvailable ? '#54dea0' : '#9a9a9a',
          height: 10,
          width: 10,
          borderRadius: 50,
          marginRight: 10,
        }}
      />
      <Text style={{...h14_Regular, lineHeight: 21, letterSpacing: 0.5}}>
        {item?.duration} mins
      </Text>
    </View>
  );
};

const startVideoSessionBtn = ({navigation, item}) => {
  const {_id} = item || {};
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <TouchableOpacity
        style={{marginRight: 20, cursor: 'pointer'}}
        onPress={() => {
          navigation.push({
            view: 'ModalView',
            modal: true,
            params: {
              value: {
                appointment: {_id},
                tabNumber: 2,
              },
            },
            modalProps: {
              position: 'screenLeft',
              style: {
                width: 500,
              },
            },
          });
        }}>
        <Image
          source={doctorPatientChat}
          style={{width: 40, height: 40}}></Image>
      </TouchableOpacity>
      <TouchableOpacity
        style={{cursor: 'pointer', flex: 1}}
        key={item._id}
        onPress={() => {
          navigation.push({
            view: 'videoSession',
            params: {
              item,
            },
          });
        }}>
        <GradientButton label="Start Video Session" buttonStyle={{
              flex: 1, 
              marginLeft:20,
              marginRight:20
        }} />
      </TouchableOpacity>
    </View>
  );
};

let tableItems = {
  // action: ({item}) => {
  //   return {
  //     type: 'link',
  //     link: {
  //       view: 'edit-appointment',
  //       params: {item},
  //     },
  //   };
  // },
  lg: {
    columns: [
      {
        width:50,
      },
      {
        header: 'Request time',
        width: 300,
        render: (item) => appointmentTiming(item),
      },

      // {
      //   header: 'Appt. type',
      //   field: 'appointment_type',
      //   width: 150,
      //   render: (item) => appointmentType(item),
      // },
      {
        header: 'Patient Name',
        // width: 300,
        render: (item) => patientName(item),
      },
      {
        header: 'Patient Mobile',
        render: (item) => patientMobile(item),
      },
      {
        header: 'Maya note',
        width: 300,
        render: (item) => patientQuery(item),
      },
      
      // {
      //   header: 'Duration',
      //   width: 100,
      //   render: (item) => duration(item),
      // },
      // {
      //   render: (item) => startVideoSessionBtn(item),
      // },
      {
        width:180,
        render:(props) => {
          const {item} = props
          // console.log("$$$$",item);
          return completeAppointmentButton(item)
        }
      },
      // {
      //   type: 'moreActions',
      //   actions: [
      //     {
      //       text: 'Complete Appointement',
      //       visible: ({item = {}}) => {
      //         const {session_start = null} = item || {};
      //         return session_start ? true : false;
      //       },
      //       onPress: async (props) => {
      //         const {eventDispatcher, item} = props;
      //         await post({
      //           data: {...item},
      //           updates: {status: 'completed'},
      //           model: 'Appointments',
      //         }).then(() => {
      //           eventDispatcher.notify('reloadAddApointment');
      //         });
      //       },
      //     },
      //     {
      //       text: 'Cancel Appointement',
      //       visible: ({item = {}}) => {
      //         const {session_start = null} = item || {};
      //         return session_start ? false : true;
      //       },
      //       onPress: (props) => {
      //         const {eventDispatcher, item} = props;
      //         invoke({
      //           id: 'refundTransaction',
      //           paramValue: {
      //             data: {
      //               status: 'cancelled',
      //               _id: item?._id,
      //             },
      //           },
      //         })
      //           .then((res) => {
      //             eventDispatcher.notify('reloadAddApointment');
      //           })
      //           .catch((err) => {
      //             eventDispatcher.notify('reloadAddApointment');
      //             console.log('err', err);
      //           });
      //       },
      //     },
      //   ],
      // },
    ],
  },
  // md: {
  //   columns: [
  //     {
  //       header: 'Patient Name',
  //       width: 200,
  //       render: (item) => patientName(item),
  //     },
  //     {
  //       header: 'Patient’s Query',
  //       render: (item) => patientQuery(item),
  //     },

  //     {
  //       render: (item) => startVideoSessionBtn(item),
  //     },
  //   ],
  // },
  // sm: {
  //   columns: [
  //     {
  //       header: 'Patient Name',
  //       width: 100,
  //       render: (item) => patientName(item),
  //     },
  //     {
  //       header: 'Patient’s Query',
  //       render: (item) => patientQuery(item),
  //       width: 140,
  //     },

  //     {
  //       render: (item) => startVideoSessionBtn(item),
  //       width: 100,
  //     },
  //   ],
  // },
};

let TablesData = Table({
  uri,
  ...tableItems,
  reloadEvent: 'reloadEmergency',
});
let WrapperEmergencyContext = EmergencyContext(TablesData);
export default WrapperEmergencyContext;

