import {isMobile} from '..';

export default (props = {}) => {
  let {
    navigation,
    action: {confirmModalProps, ...restAction},
    item,
  } = props;
  if (navigation) {
    setTimeout(
      () =>
        navigation.push({
          view: 'confirm',
          params: {
            confirmProps: {item, action: restAction},
          },
          modal: true,
          modalProps: {
            minHeight: 180,
            width: isMobile ? 300 : 400,
            position: 'screenCenter',
            ...confirmModalProps,
          },
        }),
      100,
    );
  }
};
