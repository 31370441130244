import Invites from './Invites';
import InviteLinks from './InviteLinks';
import InviteLinkForm from './InviteLinkForm';

export default {
  'manage-invite-links': {
    screen: Invites,
  },
  'invite-links': {
    screen: InviteLinks,
  },
  'create-link': {
    screen: InviteLinkForm,
    expanded: true
  }
};
