import React, {Component} from 'react';
import {View, ScrollView} from '../../react-core-components';

export default class TabBar extends Component {
  tabsSelectedKeys = {};

  getTab = ({tab, tabKey, index}) => {
    if (!tab) {
      return null;
    }
    let {
      renderItem,
      selectedTab,
      navigation,
      screenState,
      setScreenState,
      eventDispatcher,
      uid,
    } = this.props;
    if (!Array.isArray(tab) && tab.renderItem) {
      renderItem = tab.renderItem;
    }
    if (!renderItem) {
      return null;
    }
    let renderItemProps = {
      uid,
      navigation,
      screenState,
      setScreenState,
      eventDispatcher,
      key: tabKey,
      tab,
      tabKey,
      tabIndex: index,
      selectedTab,
    };

    if (typeof renderItem === 'function') {
      renderItem = renderItem(renderItemProps);
    } else if (React.isValidElement(renderItem)) {
      renderItem = React.cloneElement(renderItem, renderItemProps);
    }
    return renderItem;
  };

  render() {
    let {
      tabs,
      minTabScrollCount,
      containerStyle,
      minTabContainerStyle,
    } = this.props;
    if (!tabs) {
      return null;
    }

    let newTabs = {};
    for (let tabKey in tabs) {
      let tabInfo = tabs[tabKey];

      if (tabInfo) {
        let {groupName, ...restTabInfo} = tabInfo;

        if (groupName) {
          newTabs[groupName] = newTabs[groupName] || [];
          newTabs[groupName].push({tabKey, tab: restTabInfo});
        } else {
          newTabs[tabKey] = tabInfo;
        }
      }
    }
    let tabKeys = Object.keys(newTabs);
    if (!tabKeys.length) {
      return null;
    }
    let renderComponent = tabKeys.map((tabKey, index) => {
      const tab = newTabs[tabKey];
      return this.getTab({tab, tabKey, index});
    });

    if (minTabScrollCount && tabKeys.length < minTabScrollCount) {
      return (
        <View style={{flex: 1, flexDirection: 'row', ...minTabContainerStyle}}>
          {renderComponent}
        </View>
      );
    } else {
      return (
        <ScrollView
          style={{flex: 1, ...containerStyle}}
          horizontal={true}
          showsHorizontalScrollIndicator={false}>
          {renderComponent}
        </ScrollView>
      );
    }
  }
}
