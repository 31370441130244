import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  ProgressIndicator,
  Snackbar,
  Dimensions,
  Platform,
} from '../../app-components';
import {
  timeFormat,
  showError,
  sectionShows,
} from '../../app-components/UtilityFunctions';
import {getTeacherScheduleQuery, listUri} from '../../Queries';
import {DataFetch} from '../../app-components/data-fetch/DataFetch';
// import {
// // getTeacherSchedule,
// // getDownloadUrl,
// // scheduleInactive,
// } from '../../YellowLiveAppService';
import {nonEditScheduleStyle} from '../../theme/addScheduleTheme';
// import Data from './data.json';
import {
  h14_Medium,
  h18_Medium,
  h14_SansSemiBold,
  h24_Medium,
  h16_Medium,
  h15_Regular,
  h15_Medium,
  h14_Regular,
} from '../../theme/fonts';
import {colors, bgs} from '../../theme/colors';
// import {
// backIcon20_16,
// inActivatingSchedule} from '../../images';
import {withoutProfile} from '../../images';
import uuid from 'uuid/v4';
import moment from 'moment';
import {getImageUrl, getUser, gpsStore} from '../../AppServices';
import {shadow} from '../../theme/shadows';
import { resolvePatientName } from '../../app-components/patientUtility';
// import {getData} from '../../AppServices';
let {
  themeColor,
  radioButtonColor,
  textWhite,
  darkTextColor,
  textColor54,
  textColor87,
  textColor70,
  lightgreenTextColor,
} = colors;
let {lightGrayColor} = bgs;
let default_widthHeader = 155;

let windowWidth = Dimensions.get('window').width;
const isInCurrentTime = ({utc_start_time, utc_end_time}) => {
  let currentTime = new Date().getHours() * 60 + new Date().getMinutes();
  let currentUtcTime = currentTime + new Date().getTimezoneOffset();
  if (!utc_start_time || !utc_end_time) {
    return false;
  }
  if (currentUtcTime >= utc_start_time && currentUtcTime <= utc_end_time) {
    return true;
  }
  return false;
};

const monthMap = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'July',
  7: 'Aug',
  8: 'Sept',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

class NonEditScheduleWrapper extends React.Component {
  render() {
    const {doctor} = getUser();
    const {screenState: {dataParams} = {}, navigation} = this.props;
    if (!dataParams) {
      return null;
    }
    return (
      <DataFetch
        navigation={navigation}
        dataParams={dataParams}
        uri={listUri({
          model: 'DoctorSlots',
          id: 'doctorSlotsLists',
          filter: {
            doctor: {_id: doctor?._id},
          },
          limit: 520,
        })}
        reloadEvent="reloadSchedule"
        eventDispatcher={this.props.eventDispatcher}>
        {({data, loading}) => {
          return (
            <NonEditSchedule
              fetchLoading={loading}
              data={data}
              // data={Data?.response?.result}
              {...this.props}
            />
          );
        }}
      </DataFetch>
    );
  }
}

export default NonEditScheduleWrapper;

/** */
const getCurrentWeek = (time) => {
  var currentDate = moment(time);
  var weekStart = currentDate.clone().startOf('week');
  var days = [];
  let i;
  for (i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').toDate());
  }
  return days;
};

const checkToday = (time) => {
  const today = new Date();
  if (
    time.getDay() === today.getDay() &&
    time.getDate() === today.getDate() &&
    time.getMonth() === today.getMonth() &&
    today.getFullYear() === time.getFullYear()
  ) {
    return true;
  }
  return false;
};

const SesssionHeaderComponent = (props) => {
  let {centerHeaderStyle, centerHeaderTextStyle} = nonEditScheduleStyle;
  let {header = [], widthHeader, screenState} = props;
  const {dataParams: {filters: {period: {value} = {}} = {}} = {}} = screenState;
  const decideIsToday = ({text, index}) => {
    // let newIndex = index;
    // if (index === header.length - 1) {
    // newIndex = 0;
    // }
    const datearr = getCurrentWeek(value?.from);
    if (
      // newIndex < 7 &&
      // newIndex === new Date().getDay() &&
      checkToday(datearr[index])
    ) {
      return {text: `TODAY | ${text}`, today: true, date: datearr[index]};
    }
    return {text, today: false, date: datearr[index]};
  };

  let commonStyle = {
    paddingBottom: 15,
    paddingTop: 15,
  };
  return (
    <View
      style={{
        flexDirection: 'row',
        borderBottomColor: lightGrayColor,
        borderBottomWidth: 1,
        backgroundColor: '#fafbfc',
        borderTopWidth: 1,
        borderTopColor: 'rgba(246, 248, 249, 0.7)',
      }}>
      <View
        style={{
          ...centerHeaderTextStyle,
          paddingLeft: 35,
          ...commonStyle,
          paddingRight: 6,
          width: widthHeader,
        }}>
        {/* <Text style={{...h15_Medium}}>Date</Text>
 <Text style={{...h15_Medium}}>Time</Text> */}
      </View>
      {header.map((text, index) => {
        let {text: shownValue, today, date} = decideIsToday({
          text,
          index,
        });
        return (
          <View
            style={{
              ...centerHeaderTextStyle,
              justifyContent: 'center',
              backgroundColor: today ? 'white' : '#fafbfc',
              alignItems: 'center',
              ...commonStyle,
              color: today
                ? themeColor
                : index == 0
                ? 'rgba(229, 82, 82, 0.8)'
                : textColor54,
              borderRightWidth: index == 6 ? 0 : 1,
              borderLeftWidth: index == 0 ? 1 : 0,
              marginRight: index == 6 ? 6 : 0,
              borderRightColor: 'rgba(217, 217, 224, 0.37)',
              borderLeftColor: 'rgba(217, 217, 224, 0.37)',
              width: widthHeader,
            }}>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
              {`${date.getDate()} ${monthMap[date.getMonth()]}`}
              <br />
              {shownValue}
            </View>
          </View>
        );
      })}
    </View>
  );
};
/* session header closed here*/

const actionOnCard = async ({data, eventDispatcher}) => {
  try {
    if (!data || !data._id) {
      throw new Error('id not found');
    }
    let idValue = data._id.split(':')[1];
    if (!idValue) {
      throw new Error('id not found');
    }
    let paramValue = {_id: idValue, model: 'Lecture'};
    Snackbar.show({
      text: 'Inactivating schedule...',
      duration: Snackbar.LENGTH_SHORT,
    });
    // await scheduleInactive({paramValue});
    Snackbar.show({
      text: 'Schedule inactivated successfully',
      duration: Snackbar.LENGTH_SHORT,
    });
    eventDispatcher && eventDispatcher.notify('reloadlecture');
  } catch (err) {
    showError(err);
  }
};

class ScheduleShowComponent extends React.Component {
  constructor(props) {
    super(props);
    this._button = null;
    this._buttonFrame = null;
    this.state = {selected: false};
  }

  getBackgroundColor = ({status, appointment_type,session_start}) => {
    if (status === 'completed'|| session_start) {
      return '#73c5a7';
    }
    if (status === 'scheduled' && appointment_type === 'new') {
      return ' #ff7b20';
    }
    if (status === 'scheduled' && appointment_type === 'follow_up') {
      return '#2e6ecd';
    }
    if (status === 'cancelled') {
      return '#f14f4f';
    }
    return '#2e6ecd';
  };

  onClickSchedule = ({item}) => {
    let {navigation, eventDispatcher} = this.props;

    navigation &&
      navigation.push({
        view: 'inactive-schedule-confirm',
        params: {
          confirm: {
            descrition: 'Are you sure you want to delete this session?',
            actions: [
              {
                onPress: () => actionOnCard({data: item, eventDispatcher}),
                text: 'Yes',
              },
              {
                onPress: () => {
                  return null;
                },
                text: 'No',
                textColor: textWhite,
                bg: lightgreenTextColor,
              },
            ],
          },
        },
        modal: true,
      });
  };

  _updatePosition = (callback) => {
    if (this._button && this._button.measure) {
      this._button.measure((fx, fy, width, height, px, py) => {
        this._buttonFrame = {x: px, y: py, w: width, h: height};
        callback && callback();
      });
    }
  };
  openModal = (value) => {
    let {navigation} = this.props;
    let {x: clickPosition, w: componentWidth} = this._buttonFrame || {};
    let modalWidth = 500;

    let position = 'screenRight';
    if (clickPosition) {
      this.setSelected(true);
      position =
        windowWidth - (clickPosition + componentWidth) < modalWidth
          ? 'screenLeft'
          : 'screenRight';
    }
    navigation.push({
      view: 'ModalView',
      modal: true,
      params: {value, setSelected: this.setSelected},
      modalProps: {
        position,
        style: {
          zIndex: 2,
          width: modalWidth,
        },
      },
    });
  };
  setSelected = (value) => {
    this.setState({selected: value});
  };
  render() {
    let {
      day,
      scheduleData,
      index,
      groupBySchedules,
      dayByKeys,
      refreshData,
      widthHeader,
      // hideInactive,
      navigation,
    } = this.props;
    let {key} = day;
    let {value} = scheduleData[key] || {};
    let {doctor, start_time, appointment} = value || {};
    const {patient_id: patientData, appointment_type, status,session_start=null} =
    appointment || {};
    const {mouseEnter, selected} = this.state;
    return (
      <TouchableOpacity
        ref={(button) => (this._button = button)}
        onPress={() => {
          this._updatePosition();
          doctor && this.openModal(value);
        }}
        onMouseEnter={() => {
          this.setState({mouseEnter: true});
        }}
        style={[
          {
            cursor: 'pointer',
            justifyContent: 'space-between',
            borderRightWidth: index === dayByKeys.length - 1 ? 0 : 1,
            borderRightColor: 'rgba(217, 217, 224, 0.37)',
            flexDirection: 'row',
            paddingTop: 8,
            paddingBottom: 8,
            display: 'flex',
            paddingLeft: 15,
            // paddingRight: index == dayByKeys.length - 1 ? 40 : 35,
            backgroundColor: doctor
              ? this.getBackgroundColor({
                  status: status,
                  appointment_type: appointment_type,
                  session_start
                })
              : '#fff',
            width: widthHeader,
          },
          (mouseEnter && doctor) || selected
            ? {
                ...shadow,
                transform:
                  Platform.OS === 'web' ? 'scale(1.1)' : [{scale: 1.1}],
                borderRadius: 5,
                zIndex: 1,
              }
            : null,
        ]}
        onMouseLeave={() => {
          this.setState({mouseEnter: false});
        }}>
        {doctor ? (
          <View
            style={{
              cursor: 'pointer',
            }}>
            {withoutProfile ? (
              <Image
                source={
                  patientData?.profile_picture
                    ? getImageUrl(patientData?.profile_picture?.thumbnail)
                    : withoutProfile
                }
                style={{width: 26, height: 26, borderRadius: 30}}
              />
            ) : (
              <View
                style={{
                  width: 26,
                  height: 26,
                  borderRadius: 25,
                  backgroundColor: radioButtonColor,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={{...h18_Medium, color: 'white'}}>
                  {/* {showName(name) || ''} */}
                </Text>
              </View>
            )}
          </View>
        ) : (
          <View
            style={{
              width: 26,
              height: 26,
              borderRadius: 25,
              // backgroundColor: radioButtonColor,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        )}

        <View
          style={{
            paddingLeft: 10,
            justifyContent: 'center',
            // backgroundColor:"yellow",
            flex: 1,
            overflow: 'hidden',
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...h14_Medium,
              color: '#fff',
            }}>
            {resolvePatientName(patientData) || ""}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

class ScheduleDayRow extends React.Component {
  oldValue = {};

  render() {
    let {
      dayByKeys = [],
      scheduleData,
      groupBySchedules,
      navigation,
      eventDispatcher,
      refressData,
      widthHeader,
      hideInactive,
    } = this.props || {};
    return (
      <View style={{flexDirection: 'row', flex: 1}}>
        {dayByKeys.map((day, index) => {
          return (
            <ScheduleShowComponent
              hideInactive={hideInactive}
              day={day}
              widthHeader={widthHeader}
              refressData={refressData}
              scheduleData={scheduleData}
              index={index}
              groupBySchedules={groupBySchedules}
              dayByKeys={dayByKeys}
              navigation={navigation}
              eventDispatcher={eventDispatcher}
            />
          );
        })}
      </View>
    );
  }
}

class ScheduleRow extends React.Component {
  render() {
    let {scheduleData} = this.props || {};
    let {startStyle} = nonEditScheduleStyle;
    let startValue = (scheduleData && scheduleData.utc_start_time) || void 0;
    const value = moment(startValue).format('h:mm a');
    // startValue = startValue ? timeFormat(startValue) : 'N/A';
    // let endValue = (scheduleData && scheduleData.utc_end_time) || void 0;
    // endValue = endValue ? timeFormat(endValue) : 'N/A';
    return (
      <View>
        <Text style={{...startStyle}}>{value}</Text>
      </View>
    );
  }
}

class NonEditSchedule extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {schedules: []};
    this.header = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
    ];
    this.dayByKeys = [
      {key: 'Sunday', value: 'SUN'},
      {key: 'Monday', value: 'MON'},
      {key: 'Tuesday', value: 'TUE'},
      {key: 'Wednesday', value: 'WED'},
      {key: 'Thursday', value: 'THU'},
      {key: 'Friday', value: 'FRI'},
      {key: 'Saturday', value: 'SAT'},
    ];
  }
  callback = async (value) => {
    let {eventDispatcher} = this.props;
    eventDispatcher.notify('reloadSchedule');
  };
  subscribeMeetingCallback = (data) => {
    console.log('subscribeMeetingCallback');
  };

  socketSubscription = () => {
    let {doctor: {_id: doctorId} = {}, userType} = getUser();
    this.socketUid = uuid();
    gpsStore.subscribeEvent({
      event: 'newAppointmentCreated',
      callback: this.callback,
    });
    gpsStore.emitDataOnConnect({
      uid: this.socketUid,
      event: 'subscribeGroup',
      data: {
        groupId: `${doctorId}__new`,
        sessionId: this.socketUid,
        source: 'web',
      },
      callback: this.subscribeMeetingCallback,
    });
  };

  unsubscribSocket = () => {
    if (gpsStore && gpsStore.removeEmitDataOnConnect) {
      gpsStore.removeEmitDataOnConnect({uid: this.socketUid});
      gpsStore.unsubscribeEvent({
        event: 'newAppointmentCreated',
        callback: this.callback,
      });
    }
  };
  componentWillUnmount() {
    this.unsubscribSocket();
  }
  checkDay = ({result}) => {
    let dayBySchedule = {};
    let timeBySchedule = [];
    result.forEach((value, index) => {
      let dayByKey = void 0;
      let timeByKey = void 0;
      let {
        start_time: startTime,
        end_time: endTime,
        day,
        status,
        _id,
        appointment = {},
      } = value || {};

      const {status: appointmentStatus = ''} = appointment || {};

      const utc_start_time = startTime;
      const utc_end_time = endTime;
      const start_time = moment(startTime).format('hh:mm');
      const end_time = moment(endTime).format('hh:mm');
      timeByKey = `${start_time}_${end_time}`;
      if (!timeBySchedule[timeByKey]) {
        timeBySchedule[timeByKey] = {
          start_time,
          end_time,
          utc_start_time,
          utc_end_time,
          schedule_id: _id,
        };
      }

      if (
        day === 'Sunday' &&
        status === 'Booked' &&
        appointmentStatus !== 'pending'
      ) {
        //throw error if multiple value
        dayByKey = `${start_time}_${end_time}_Sunday`;
        dayBySchedule[dayByKey] = value;
      } else if (
        day === 'Monday' &&
        status === 'Booked' &&
        appointmentStatus !== 'pending'
      ) {
        dayByKey = `${start_time}_${end_time}_Monday`;
        dayBySchedule[dayByKey] = value;
      } else if (
        day === 'Tuesday' &&
        status === 'Booked' &&
        appointmentStatus !== 'pending'
      ) {
        dayByKey = `${start_time}_${end_time}_Tuesday`;
        dayBySchedule[dayByKey] = value;
      } else if (
        day === 'Wednesday' &&
        status === 'Booked' &&
        appointmentStatus !== 'pending'
      ) {
        dayByKey = `${start_time}_${end_time}_Wednesday`;
        dayBySchedule[dayByKey] = value;
      } else if (
        day === 'Thursday' &&
        status === 'Booked' &&
        appointmentStatus !== 'pending'
      ) {
        dayByKey = `${start_time}_${end_time}_Thursday`;
        dayBySchedule[dayByKey] = value;
      } else if (
        day === 'Friday' &&
        status === 'Booked' &&
        appointmentStatus !== 'pending'
      ) {
        dayByKey = `${start_time}_${end_time}_Friday`;
        dayBySchedule[dayByKey] = value;
      } else if (
        day === 'Saturday' &&
        status === 'Booked' &&
        appointmentStatus !== 'pending'
      ) {
        dayByKey = `${start_time}_${end_time}_Saturday`;
        dayBySchedule[dayByKey] = value;
      }
    });

    let groupBySchedules = [];
    let scheduleTimes = Object.keys(timeBySchedule);
    scheduleTimes.forEach((scheduleKey) => {
      let {
        start_time,
        end_time,
        utc_start_time,
        utc_end_time,
        schedule_id,
      } = timeBySchedule[scheduleKey];
      let mainId = `old_${uuid()}`;
      let groupByScheduleRow = {
        _id: mainId,
        start_time,
        end_time,
        utc_start_time,
        utc_end_time,
        schedule_id,
      };
      this.dayByKeys.forEach(({key}) => {
        let dayByScheduleKey = start_time + '_' + end_time + '_' + key;
        let dayValue = dayBySchedule[dayByScheduleKey];

        if (dayValue) {
          let oldDayValueId = dayValue._id;
          dayValue = {
            ...dayValue,
            schedule_id,
            _id: mainId + ':' + oldDayValueId,
          };
        } else {
          dayValue = {_id: mainId + ':' + 'new_' + uuid()};
        }

        groupByScheduleRow[key] = {
          value: dayValue,
        };
      });
      groupBySchedules.push(groupByScheduleRow);
    });
    this.setState({groupBySchedules});
  };

  refreshData = async () => {
    this.setState({dataLoading: true});
    let {data: result} = this.props;
    this.setState({dataLoading: false});
    if (result) {
      this.checkDay({result});
    }
    // if (result && result.length) {
    // this.checkDay({result});
    // }
  };
  componentDidMount = async () => {
    this.refreshData();
    this.socketSubscription();
  };

  componentDidUpdate(prevProps) {
    let {data: _prevData = []} = prevProps;
    let {data = []} = this.props;
    if (data !== _prevData) {
      this.refreshData();
    }
  }
  showMessage = (message) => {
    Snackbar.show({
      text: message,
      duration: Snackbar.LENGTH_LONG,
    });
  };
  cancel = () => {
    let {navigation} = this.props || {};
    navigation.pop(1);
  };
  // timeConvert = (time) => {
  // let hours = time / 60;
  // let rhours = Math.floor(hours);
  // let minutes = (hours - rhours) * 60;
  // minutes = Math.floor(minutes);
  // let text = '';
  // if (rhours && minutes) {
  // text = `${rhours} Hr ${minutes} Min `;
  // } else if (minutes) {
  // text = `${minutes} Min`;
  // } else if (rhours) {
  // text = `${rhours} Hr`;
  // }
  // return text;
  // };

  timeMessage = () => {
    let {groupBySchedules = []} = this.state || {};
    let time = 0;
    groupBySchedules.map((value) => {
      let {start_time, end_time} = value || {};
      let aggregateStartTime = void 0;
      let aggregateEndTime = void 0;
      if (start_time && end_time) {
        let _start_time = start_time.split(':');
        let _end_time = end_time.split(':');
        let start_time_h = parseInt(_start_time[0]);
        let start_time_m = parseInt(_start_time[1]);
        aggregateStartTime = start_time_h * 60 + start_time_m;
        let end_time_h = parseInt(_end_time[0]);
        let end_time_m = parseInt(_end_time[1]);
        aggregateEndTime = end_time_h * 60 + end_time_m;
        aggregateEndTime = aggregateEndTime - aggregateStartTime;
      }
      if (start_time && end_time) {
        this.dayByKeys.map(({key}) => {
          let {value: newValue} = value[key] || {};
          let {class_id, section, subject} = newValue || {};
          if (class_id && section && subject) {
            time = time + aggregateEndTime;
          }
        });
      }
    });
    return time;
  };

  edit = () => {
    let {navigation} = this.props;
    let {item} = this.props.navigation.state.params || {};
    navigation && navigation.push('addScheduleCustom', {item});
  };
  showName = (name) => {
    if (!name) {
      return;
    }
    name = name && name.split(' ');
    if (name.length === 2) {
      name =
        (name[0] && name[0].substring(0, 1).toUpperCase()) +
        (name[1] && name[1].substring(0, 1).toUpperCase());
    } else {
      name = name[0] && name[0].substring(0, 1).toUpperCase();
    }

    return name;
  };

  getRow = ({scheduleData, groupBySchedules}) => {
    let startValue = (scheduleData && scheduleData.utc_start_time) || void 0;
    let endValue = (scheduleData && scheduleData.utc_end_time) || void 0;

    let isActive = isInCurrentTime({
      utc_end_time: endValue,
      utc_start_time: startValue,
    });
    return (
      <View style={{flexDirection: 'row'}}>
        <View
          style={{
            flexDirection: 'row',
            borderRightWidth: 1,
            borderRightColor: 'rgba(217, 217, 224, 0.37)',
            borderBottomColor: 'rgba(217, 217, 224, 0.37)',
            borderBottomWidth: 1,
            // paddingLeft: 35,
            alignItems: 'center',
            backgroundColor: 'white',
            justifyContent: 'center',
            width: this.widthHeader,
          }}>
          <ScheduleRow scheduleData={scheduleData} />
        </View>
        <View
          style={{
            borderBottomColor: lightGrayColor,
            borderBottomWidth: 1,
            flex: 1,
          }}>
          <ScheduleDayRow
            refressData={this.refreshData}
            dayByKeys={this.dayByKeys}
            scheduleData={scheduleData}
            groupBySchedules={groupBySchedules}
            widthHeader={this.widthHeader}
            {...this.props}
          />
        </View>
      </View>
    );
  };

  render() {
    let {containerStyle} = nonEditScheduleStyle;
    let {fetchLoading, hideHeader} = this.props;
    let {groupBySchedules = [], dataLoading} = this.state || {};
    return (
      <View
        onLayout={({
          nativeEvent: {
            layout: {width},
          },
        }) => {
          if (width) {
            //50 is TotalMargin 8 is no.Of grid
            this.widthHeader = (width - 50) / 8;
          } else {
            this.widthHeader = default_widthHeader;
          }
        }}
        style={{
          ...containerStyle,
          userSelect: 'none',
          overflow: 'hidden',
          backgroundColor: 'rgba(246, 248, 249, 0.7)',
        }}>
        {/* {!hideHeader && (
 <Header
 loading={this.state.loading}
 cancel={this.cancel}
 edit={this.edit}
 />
 )} */}
        {/* <TopHeader
 {...this.props}
 timeMessage={this.timeMessage}
 timeConvert={this.timeConvert}
 showName={this.showName}
 loading={this.state.loading}
 /> */}
        {/* <View
 style={{
 // flex: 1,
 // overflow: 'auto',
 maxWidth: 'max-content',
 marginLeft: 24,
 marginRight: 24,
 borderRadius: 4,
 // boxShadow: '0 0 10px 0 rgba(53, 53, 53, 0.08)',
 }}>
 <SesssionHeaderComponent
 header={this.header}
 widthHeader={this.widthHeader}
 {...this.props}
 />
 </View> */}
        <View
          style={{
            flex: 1,
            overflow: 'auto',
            maxWidth: 'max-content',
            marginLeft: 24,
            marginRight: 24,
            borderRadius: 4,
            boxShadow: '0 0 10px 0 rgba(53, 53, 53, 0.08)',
          }}>
          <SesssionHeaderComponent
            header={this.header}
            widthHeader={this.widthHeader}
            {...this.props}
          />
          <View
            style={{
              flex: 1,
              // overflow: 'auto',
              backgroundColor: 'rgba(246, 248, 249, 0.7)',
              borderWidth: 1,
              borderColor: 'rgba(246, 248, 249, 0.7)',
              borderBottomRadius: 4,
              marginBottom: 5,
            }}
            getRef={(_) => (this._containerRef = _)}>
            {fetchLoading && <ProgressIndicator />}
            {groupBySchedules.length ? (
              groupBySchedules.map((scheduleData, index) => {
                let {status} = scheduleData || {};
                return this.getRow({scheduleData, groupBySchedules});
              })
            ) : dataLoading || fetchLoading ? null : (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 20,
                }}>
                <Text>No Schedule</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}
