import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from '../../app-components';
import {ActivityIndicator} from '../../app-components';
import {TextInput} from '../../npms/react-text-input';
import {
  h12_Regular,
  h14_AvenirNext,
  h15_Regular,
} from '../../theme/fonts';
import {mayamd_authenticate} from '../../AppServices';
import { GradientButton } from '../../app-components/buttons/Buttons';

const OTP = (props) => {
  let {navigation} = props;
  let {params} = navigation?.state || {};
  let {mobile} = params || {};
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState('');
  const [otpError, setOtpError] = useState('');
  const setValue = (text) => {
    setOTP(text);
  };

  const verifyOTP = async () => {
    if (!otp.length) {
      setOtpError('OTP is required');
    } else {
      setLoading(true);
      await mayamd_authenticate({
        mobile,
        password: otp,
      })
        .then((res) => {
          if (res?.user) {
            navigation.replace({view: 'patient-details', params: {...params}});
            // changeRightSideScreen('patientDetails');
          } else {
            setOtpError('Wrong OTP');
          }
        })
        .catch((err) => {
          setLoading(false);
        });
      setLoading(false);
    }
  };
  return (
    <View style={{margin: 25}}>
      <Text
        style={{
          ...h15_Regular,
          color: '#2e6ecd',
          fontWeight: 600,
          marginBottom: 10,
        }}>
        Enter OTP
      </Text>
      <TextInput
        variant={'filled'}
        style={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'rgb(56,56,56, 0.2)',
          backgroundColor: 'white',
          minHeight: 40,
          borderRadius: 4,
          paddingTop: 9,
        }}
        madatory={true}
        activeStyle={{}}
        inputStyle={{
          outline: 'none',
          border: 0,
          backgroundColor: 'transparent',
          paddingLeft: 12,
          paddingRight: 12,
          ...h14_AvenirNext,
          color: 'rgb(56,56,56, 0.5)',
          paddingBottom: 0,
        }}
        value={otp}
        placeholder="6 digit OTP"
        inputProps={{
          type: 'number',
        }}
        onChangeText={setValue}
        onSubmitEditing={() => {
          verifyOTP();
        }}
      />
      {otpError && (
        <Text style={{...h12_Regular, color: 'red'}}>{otpError}</Text>
      )}
      <View
        style={{
          flexDirection: 'row',
          marginBottom: 10,
          alignItems: 'center',
          marginTop: 10,
        }}>
        <Text
          style={{
            ...h14_AvenirNext,
            color: 'rgba(56, 56, 56, 0.37)',
            paddingRight: 5,
          }}>
          We have sent OTP on {mobile}{' '}
        </Text>
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() =>
            navigation.replace({
              view: 'patient-login',
              params: {
                ...params,
                mobile,
              },
            })
          }>
          <Text style={{...h12_Regular, color: '#2e6ecd'}}>Edit</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        style={{
          padding: 10,
          marginTop: 47,
          cursor: 'pointer',
        }}
        onPress={() => {
          verifyOTP();
        }}>
          <GradientButton
          label={'Enter Details'}
          loading={loading}
          buttonStyle={{flex: 1}}
          indicatorColor={"#fff"}
        />
      </TouchableOpacity>
    </View>
  );
};

export default OTP;
