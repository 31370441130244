import React from 'react';
import Form from '../../app-components/form/Form';
import {save} from '../../app-components/action/SaveAction';
import {searchInput} from '../../autoSuggestions';
import {cancelButton} from '../../app-components/action/CloseAction';
import {submit} from '../../AppServices';

const AssignModal = (props) => {
  const ModifiedForm = Form({
    ...props,
    reloadEvent: 'reloadPatient',
    reloadOnChangeEvent: 'reloadPatient',
    closeView: 1,
    fieldVariant: 'filled',
    validateOnChange: true,
    footer: {
      containerStyle: {
        margin: 0,
        borderTopWidth: 0,
      },
      actions: [cancelButton(), save({label: 'Submit'})],
    },
    onSubmit: submit({
      model: 'Patients',
      recordActivity: true,
      op: 'update',
    }),
    beforeSubmit: ({updates}) => {
      delete updates._id;
      return {
        updates: {
          ...updates, 
        },
      };
    },
    defaultValues: ({navigation}) => {
      const {
        state: {
          params: {assignedANM = '', _id},
        },
      } = navigation;

      return {
        assignedANM,
        _id,
      };
    },
    mandatory: {
      assignedANM: 'This field cannot be empty.',
    },
    header: {
      title: 'Assign ANM',
      titleTextStyle: {
        color: 'rgba(56, 56, 56, 0.87)',
        fontSize: 20,
        marginLeft: 10,
        fontFamily: 'AvenirNext-Regular',
      },
    },
    formGroups: [
      {
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              searchInput(
                {
                  label: 'Assign ANM',
                  placeholder: 'Select Assign ANM',
                  field: 'assignedANM',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: 'User',
                  query: 'userLists',
                  keyField: '_id',
                  mandatory: true,
                  searchField: 'name',
                },
                {
                  addOnFilter: () => {
                    return {
                      userType: 'ANM',
                    };
                  },
                },
              ),
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default AssignModal;
