export const publicRoutes = [
  '/login',
  '/otp',
  '/passcode',
  // '/patient-signIn',
  // '/patient-signUp',
  // '/front-page',
  '/choose-user',
  '/patient-otp',
  '/patient-signin',
  '/clinic-signup',
  // '/doctor-search',
  '/superAdmin',
  '/language'
  // '/maya-auth',
];
export const superAdminRoutes = [
  '/superAdminAnalytics',
  '/clinics',
  '/patient',
  '/master-data',
  '/contact-support',
  '/importList',
  '/superAdminRPM'
];
export const clinicAdminRoutes = [
  '/analytics',
  '/manage-invite-links',
  '/patient',
  '/PaymentAnalytics',
  '/contact_support',
  '/clinic-detail',
  '/thankYouStripe',
  '/reauth',
  '/patient-clinic-signup',
  '/RPM'
  // '/emergency-request',
];
export const doctorRoutes = [
  '/patient-requests',
  '/doctor-patient-queue',
  '/PaymentAnalytics',
  '/analytics',
  '/my-patient',
  '/schedule',
  '/instant-patient-queue',
  '/RPM',
  '/RPM:assignProgram',
  '/patientReading'
];
export const patientRoutes = [
  '/patients',
  '/patient-waiting-room',
  '/doctor-search',
  '/follow-up',
  '/Dashboard',
  '/health-assistant',
  '/provider',
  '/graph-image',
];
export const frontDeskRoutes = ['/manage-invite-links'];
export const medicalAssistantRoutes =['/RPM',]
export const dialysisAdminRoutes =['/RPM']
export const nurseRoutes =['/RPM']