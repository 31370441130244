import React from 'react';
import Table from '../../../../app-components/table/Table';
import {showDOB} from '../../../../app-components/UtilityFunctions';
import {h14_AvenirNext} from '../../../../theme/fonts';
import {getUser} from '../../../../AppServices';
import {Text} from '../../../../app-components';

let uri = ({navigation}) => {
  let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
  let {organization: {_id: organizationId} = {}} = getUser();
  if (!_id) {
    _id = organizationId;
  }
  return {
    query: {
      id: 'userLists',
      addOnFilter: {
        organization: {_id},
        userType: 'ClinicAdmin',
      },
    },
    model: 'User',
  };
};

let tableItems = {
  lg: {
    action: ({item}) => {
      return {
        type: 'link',
        link: {
          view: 'edit-ClinicAdmin',
          params: {item},
        },
      };
    },
    columns: [
      {
        render: (props) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {props.index + 1}
            </Text>
          );
        },
        header: 'S.No.',
        width: 50,
      },
      {
        header: 'Name',
        type: 'text',
        width: 300,
        field: 'name',
        // textStyle: {...h16_Regular},
      },
      {
        header: 'Email',
        type: 'text',
        width: 300,
        field: 'email',
      },
      // {
      //   header: 'Organisation Name',
      //   type: 'text',
      //   width: 300,
      //   field: 'name',
      // },
      {
        header: 'Mobile Number',
        type: 'text',
        field: 'mobile',
      },
      // {
      //   header: 'User Type',
      //   type: 'text',
      //   width: 100,
      //   field: 'userType',
      // },
    ],
  },
  sm:{
    action: ({item}) => {
      return {
        type: 'link',
        link: {
          view: 'edit-ClinicAdmin',
          params: {item},
        },
      };
    },
    columns: [
      {
        render: (props) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {props.index + 1}
            </Text>
          );
        },
        header: 'S.No.',
        width: 50,
      },
      {
        header: 'Name',
        type: 'text',
        width: 100,
        field: 'name',
        // textStyle: {...h16_Regular},
      },
      {
        header: 'Email',
        type: 'text',
        width: 120,
        field: 'email',
      },
      // {
      //   header: 'Organisation Name',
      //   type: 'text',
      //   width: 300,
      //   field: 'name',
      // },
      {
        header: 'Mobile Number',
        type: 'text',
        field: 'mobile',
        width: 100,
      },
      // {
      //   header: 'User Type',
      //   type: 'text',
      //   width: 100,
      //   field: 'userType',
      // },
    ],
  }
};
export default Table({
  uri,
  ...tableItems,
  reloadEvent: 'reloadClinicAdmin',
});
