import React, {Component} from 'react';
import {
  View,
  Image,
  Text,
  // TouchableWithoutFeedback,
  TouchableOpacity,
  ScrollView,
  // TextInput,
  ActivityIndicator,
} from '../../app-components';
import moment from 'moment';
import {
  withoutProfile,
  maleSign,
  femaleSign,
  modalCross,
  medicine,
  arrowDownIcon,
  collapseIcon,
} from '../../images';
import {
  getUser,
  getData,
  invoke,
  getImageUrl,
  getCurrency,
  calculateTime,
  showMessage,
} from '../../AppServices';
import {
  h13_Regular,
  h12_Regular,
  h20_Regular,
  h14_Medium,
  h14_Regular,
  h11_Regular,
  h14_AvenirNext,
} from '../../theme/fonts';
import ChatView from '../PatientQueue/ChatView';
import {cancelModalButton} from '../../theme/shadows';
import {colors} from '../../theme/colors';
import NestedList from '../../app-components/input-components/NestedList';
import {getAge} from '../../app-components/UtilityFunctions';
import {isEmpty} from 'lodash';
import PatientDetails from '../PatientQueue/PatientProfile';
import {resolvePatientName} from '../../app-components/patientUtility';
import {isTimerVisible} from '../RPM/rpmUtils';
import Timer from '../GenericComponent/Timer';
const {themeColor} = colors;
let nestedlistprops = {
  field: 'treatment',

  visible: () => {
    let {userType} = getUser();
    return userType === 'Doctor' ? true : false;
  },
  addButtonStyle: {backgroundColor: 'red', width: 200, height: 100},
  // footer: {
  //   // label: 'Treatment',
  //   addLabel: 'Add Drug',
  // },
  labelHeader: [
    {label: 'Drug', width: 120},
    {label: 'Dosage', width: 60},
    {label: 'Frequency', width: 80},
    {label: 'Timing', width: 120},
    {label: 'Days', width: 60},
  ],

  formGroups: [
    {
      columnsPerRow: 6,
      fieldVariant: 'filled',
      fieldContainer: 'topLabel',

      groups: [
        {
          columns: [
            {
              disabled: true,
              type: 'text',
              field: 'drug.name',
              width: 120,
            },
            {
              disabled: true,
              width: 60,
              type: 'text',
              field: 'dosage',
              placeholder: 'tablets/ ml',
            },
            {disabled: true, type: 'text', field: 'frequency', width: 80},
            {disabled: true, type: 'text', field: 'timing', width: 120},

            {
              disabled: true,
              width: 60,
              type: 'number',
              field: 'duration',
              placeholder: 'Days',
            },
          ],
        },
      ],
    },
  ],
};

class PastAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {toggle: true};
  }
  toggle = () => {
    const toggleState = this.state.toggle;
    this.setState({toggle: !toggleState});
  };
  render() {
    const {item = {}, index = 0, size = 0, setSelected} = this.props;
    const {date, investigated_symptoms = []} = item;
    const {toggle} = this.state;
    return (
      <View style={{marginTop: 10, marginBottom: index + 1 === size ? 20 : 0}}>
        <View style={{alignItems: 'flex-end'}}>
          <Text style={{...h11_Regular, color: 'rgba(56, 56, 56, 0.54)'}}>
            {/* {12 Dec, 20} */}
            {moment(date).format('MMM DD, YYYY')}
          </Text>
        </View>
        <TouchableOpacity
          onPress={() => {
            if (toggle) {
              setSelected(index);
              this.toggle();
            } else {
              this.toggle();
              setSelected(null);
            }
          }}
          style={{cursor: 'pointer'}}>
          <View
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 15,
              paddingBottom: 15,
              backgroundColor: 'rgba(216, 216, 216, 0.34)',
              borderRadius: 8,
              marginTop: 4,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Image source={medicine} style={{height: 28, width: 28}} />
            <View style={{flex: 1, marginLeft: 10}}>
              <Text style={{...h14_Regular, color: 'rgba(56, 56, 56, 0.9)'}}>
                Investigation
              </Text>
              <Text style={{...h14_Regular, color: 'rgba(56, 56, 56, 0.7)'}}>
                {/* {investigated_symptoms && investigated_symptoms[0]} */}
                {investigated_symptoms?.length > 0 &&
                  investigated_symptoms.map((item, index) => {
                    let separator =
                      (index + 1 < investigated_symptoms.length && ', ') || ' ';
                    return item + separator;
                  })}
              </Text>
            </View>

            <Image
              source={toggle ? arrowDownIcon : collapseIcon}
              style={toggle ? {height: 7, width: 11} : {height: 17, width: 14}}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

class AppointmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {patientHistory: void 0, selectedIndex: null};
  }
  setSelected = (index) => {
    this.setState({selectedIndex: index});
  };
  getPatientHistory = async () => {
    const {patient_id = {}} = this.props || {};
    const {doctor = {}} = getUser();
    if (!doctor?._id || !patient_id?._id) {
      return void 0;
    }
    const apiData = await getData({
      model: 'Appointments',
      id: 'appointmentLists',
      filter: {
        doctor_id: {_id: doctor?._id},
        patient_id: {_id: patient_id?._id},
      },
    });
    const {data} = apiData;
    this.setState({patientHistory: data});
  };

  componentDidMount() {
    this.getPatientHistory(this.props);
  }

  render() {
    const {booking_fee, duration, illness_description, time_slot} =
      this.props || {};
    let {start_time} = time_slot || {};
    const {patientHistory} = this.state;
    return (
      <View
        style={{
          flex: 1,
          // backgroundColor: 'red',
          paddingLeft: 30,
          paddingRight: 30,
        }}>
        <Text
          style={{
            marginTop: 14,
            marginBottom: 14,
            ...h14_Regular,
            color: 'rgba(154, 154, 154, 0.87)',
          }}>
          Upcoming Appointment
        </Text>
        <View
          style={{border: '1px solid #9a9a9a', borderRadius: 8, padding: 20}}>
          <View style={{height: 200}}>
            <View style={{flexDirection: 'row'}}>
              <Text
                style={{
                  width: 120,
                  ...h14_Regular,
                  color: 'rgba(154, 154, 154, 0.87)',
                }}>
                Timings
              </Text>
              <Text style={{...h14_Medium}}>
                {`${moment(start_time).format(
                  'DD MMM | hh:mm a',
                )} | ${duration} mins`}{' '}
              </Text>
            </View>
            <View style={{flexDirection: 'row', marginTop: 20}}>
              <Text
                style={{
                  width: 120,
                  ...h14_Regular,
                  color: 'rgba(154, 154, 154, 0.87)',
                }}>
                Amount Paid
              </Text>
              <Text style={{...h14_Medium}}>
                {getCurrency()} {booking_fee}
              </Text>
            </View>
            <View style={{marginTop: 16}}>
              <Text style={{...h14_Regular, fontStyle: 'italic'}}>
                {illness_description}
              </Text>
            </View>
          </View>
        </View>

        <Text
          style={{
            marginTop: 14,
            // marginBottom: 14,
            ...h14_Regular,
            color: 'rgba(154, 154, 154, 0.87)',
          }}>
          Past Appointments
        </Text>
        {/* Past Appointments */}
        {Array.isArray(patientHistory) && patientHistory.length ? (
          patientHistory.map((item, index) =>
            this.props.currentAppointmentId === item?._id ? (
              void 0
            ) : (
              <>
                <PastAppointment
                  item={item}
                  size={patientHistory.length}
                  index={index}
                  setSelected={this.setSelected}
                />
                {index === this.state.selectedIndex && (
                  <NestedList
                    {...nestedlistprops}
                    // {...props}
                    // setValue={setValue}
                    data={item}
                    defaultAddRow={true}
                  />
                )}
              </>
            ),
          )
        ) : (
          <Text style={{...h11_Regular}}>No History Found</Text>
        )}
        {/* <PastAppointment /> */}
      </View>
    );
  }
}
class PatientInfo extends Component {
  render() {
    const {patient_id: patientData} = this.props || {};
    const data = {
      'Patient Name': patientData?.name,
      DOB: patientData?.birthDate
        ? moment(patientData?.birthDate).format('MM-DD-YYYY')
        : '',
      Gender: patientData?.gender,
      // 'Blood Group': patientData?.blood_group,
      Mobile: patientData?.mobile,
      Email: patientData?.email,
      Address: patientData?.address || true,
    };
    return (
      <View style={{paddingLeft: 10, paddingTop: 50}}>
        {Object.entries(data).map(([key, value]) => {
          return (
            <View
              style={{
                marginTop: 15,
                flexDirection: 'row',
                // flex: 1,
                // backgroundColor: 'yellow',
                alignItems: 'center',
              }}>
              <View style={{width: 200}}>
                <Text style={{...h14_Medium, color: '#9a9a9a'}}>{key}</Text>
              </View>
              <View style={{paddingRight: 10}}>
                {value ? (
                  <Text style={{...h14_Medium, color: 'rgba(0,0,0,0.7)'}}>
                    {value}
                  </Text>
                ) : null}
              </View>
            </View>
          );
        })}
      </View>
    );
  }
}
export default class ScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {activatedState: 4, loading: false, appointmentData: {}};
  }
  componentDidMount = async () => {
    const {navigation = {}} = this.props || {};
    const {state: {params: {value = {}} = {}} = {}} = navigation;
    let {appointment = {}, tabNumber = 0} = value || {};
    let {_id = null} = appointment || {};
    if (!_id) {
      return void 0;
    }
    this.setState({loading: true});
    const result = await getData({
      id: 'appointmentLists',
      filter: {
        _id,
        // patient_id: {_id: patient?._id},
        // status: 'scheduled',
      },
      model: 'Appointments',
    });
    const {data = []} = result || {};
    if (Array.isArray(data) && data.length > 0) {
      this.setState({
        appointmentData: data[0],
        loading: false,
        activatedState: tabNumber,
      });
    } else {
      this.setState({loading: false});
    }
  };
  getBackgroundColor = ({status, appointment_type, session_start}) => {
    if (status === 'completed' || session_start) {
      return '#73c5a7';
    }
    if (status === 'scheduled' && appointment_type === 'new') {
      return ' #ff7b20';
    }
    if (status === 'scheduled' && appointment_type === 'follow_up') {
      return '#2e6ecd';
    }
    if (status === 'cancelled') {
      return '#f14f4f';
    }
    return '#2e6ecd';
  };

  render() {
    const {navigation = {}, eventDispatcher} = this.props;
    const {
      state: {params: {setSelected = () => {}, value} = {}} = {},
    } = navigation;
    let {appointment: {_id: currentAppointmentId} = {}} = value || {};
    const {appointmentData} = this.state;
    let {
      patient_id: patientData = {},
      appointment_type,
      status,
      doctor_id: doctor = {},
      _id,
      session_start = null,
      time_slot = {},
      care_program,
    } = appointmentData || {};

    const modalColor = this.getBackgroundColor({
      status,
      appointment_type,
      session_start,
    });
    const {activatedState, loading} = this.state;
    if (isEmpty(appointmentData)) {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: '#fff',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: 30,
            position: 'relative',
          }}>
          <TouchableOpacity
            onPress={() => {
              setSelected(false);
              navigation.pop();
            }}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              zIndex: 5,
              top: 10,
              right: 10,
            }}>
            <Image source={modalCross} style={{width: 20, height: 20}} />
          </TouchableOpacity>
          <View>
            <ActivityIndicator size="small" />
          </View>
        </View>
      );
    }
    return (
      <View style={{flex: 1, overflow: 'hidden'}}>
        {/* Header */}
        <View
          style={{
            height: 106,
            backgroundColor: modalColor,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: 30,
            position: 'relative',
          }}>
          <TouchableOpacity
            onPress={() => {
              setSelected(false);
              navigation.pop();
            }}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              zIndex: 5,
              top: 10,
              flexDirection: 'row',
              right: 10,
            }}>
            {isTimerVisible() && care_program ? (
              <View style={{marginRight: 5}}>
                <Timer type="chat" patientId={patientData?._id} />
              </View>
            ) : (
              void 0
            )}
            <Image source={modalCross} style={{width: 20, height: 20}} />
          </TouchableOpacity>
          <View
            style={{
              // backgroundColor: 'yellow',
              height: 60,
              flex: 1,
              flexDirection: 'row',
            }}>
            <Image
              source={
                patientData?.profile_picture
                  ? getImageUrl(patientData?.profile_picture?.thumbnail)
                  : withoutProfile
              }
              style={{height: 60, width: 60, borderRadius: 60}}
            />
            <View>
              <View style={{padding: 10}}>
                <Text
                  style={{
                    ...h20_Regular,
                    lineHeight: 21,
                    letterSpacing: 0.57,
                    color: '#fff',
                  }}>
                  {resolvePatientName(patientData)}
                </Text>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <View
                    style={{
                      backgroundColor: 'rgb(255, 240, 227)',
                      padding: 2,
                      paddingLeft: 15,
                      paddingRight: 15,
                      borderRadius: 15,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    {patientData?.gender === 'male' ? (
                      <Image
                        source={maleSign}
                        style={{marginRight: 5}}
                        height={15}
                        width={15}
                      />
                    ) : patientData?.gender === 'female' ? (
                      <Image
                        source={femaleSign}
                        style={{marginRight: 5}}
                        height={15}
                        width={15}
                      />
                    ) : null}

                    <Text
                      style={{
                        ...h13_Regular,
                        lineHeight: 21,
                        letterSpacing: 0.57,
                      }}>
                      {patientData?.gender &&
                        patientData?.gender[0].toUpperCase() + ' | '}
                      {patientData?.birthDate && getAge(patientData?.birthDate)}
                    </Text>
                  </View>
                  {/* <TouchableWithoutFeedback> */}
                  <View
                    style={{
                      marginLeft: 30,
                      backgroundColor:
                        appointment_type === 'follow_up'
                          ? 'rgb(224, 227, 240)'
                          : 'rgb(255, 240, 227)',
                      width: 100,
                      borderRadius: 15.5,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text
                      style={{
                        ...h12_Regular,
                        padding: 5,
                        color:
                          appointment_type === 'follow_up'
                            ? '#275dad'
                            : '#ff7b20',
                      }}>
                      {appointment_type === 'follow_up' ? 'Follow Up' : 'New'}
                    </Text>
                  </View>
                  {/* </TouchableWithoutFeedback> */}
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Navigation Bar */}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            height: 45,
          }}>
          {[
            {label: 'Appt. Details', visible: true},
            {label: 'Profile Info', visible: true},
            {label: 'Chat', visible: status === 'canceled' ? false : true},
          ].map((item, index) => (
            <>
              {item?.visible ? (
                <TouchableOpacity
                  onPress={() => {
                    this.setState({activatedState: index});
                  }}
                  style={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Text
                    style={{
                      ...h14_Medium,
                      color: activatedState === index ? themeColor : '#383838',
                    }}>
                    {item?.label}
                  </Text>
                  <View
                    style={{
                      height: 2,
                      marginTop: 8,
                      width: 30,
                      backgroundColor:
                        activatedState === index ? themeColor : 'transparent',
                    }}
                  />
                </TouchableOpacity>
              ) : (
                void 0
              )}
            </>
          ))}
        </View>
        {/* components to be renderd according to activatedState */}
        {activatedState === 0 ? (
          <ScrollView style={{flex: 1}}>
            <AppointmentDetail
              {...appointmentData}
              currentAppointmentId={currentAppointmentId}
            />
          </ScrollView>
        ) : null}
        {activatedState === 1 ? (
          <PatientDetails item={{...appointmentData}} />
        ) : null}
        {/* {activatedState === 1 ? <PatientInfo {...appointmentData} /> : null} */}
        {activatedState === 2 && patientData && doctor && _id ? (
          <ChatView
            item={{
              patient_id: {_id: patientData?._id},
              doctor_id: {_id: doctor?._id},
              appointment_id: {_id},
            }}
            {...this.props}
            // renderTextInput={false}
          />
        ) : (
          <View />
        )}
        {/* {activatedState === 2 ? <PdfReader /> : null} */}

        {/**
         * @description bottom Tab
         */}
        {activatedState === 0 && !session_start && status !== 'cancelled' ? (
          <TouchableOpacity
            style={{cursor: 'pointer'}}
            onPress={() => {
              if (calculateTime(time_slot?.start_time, true) < 6 * 60 * 60) {
                showMessage({
                  message: 'Cannot be cancelled as less than 6 hours remain.',
                });
              } else {
                this.setState({loading: true});
                invoke({
                  id: 'refundTransaction',
                  paramValue: {
                    data: {
                      status: 'cancelled',
                      _id: appointmentData?._id,
                    },
                  },
                })
                  .then((res) => {
                    navigation.pop(1);
                    setSelected(false);
                    eventDispatcher.notify('reloadSchedule');
                    this.setState({loading: false});
                  })
                  .catch((err) => {
                    navigation.pop(1);
                    setSelected(false);
                    eventDispatcher.notify('reloadSchedule');
                    this.setState({loading: false});
                    const message = JSON.parse(err?.message)?.error?.message;
                    showMessage({message});
                    console.log('err', err);
                  });
              }
            }}>
            <View
              style={{
                ...cancelModalButton,
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {loading ? (
                <ActivityIndicator size={'small'} />
              ) : (
                <Text
                  style={{color: 'rgba(241, 79, 79, 0.87)', ...h14_AvenirNext}}>
                  Cancel Appointment
                </Text>
              )}
            </View>
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }
}
