import React, {useState, useEffect} from 'react';
import {fetch, getDependentPatient, getUser} from '../../AppServices';
import moment from 'moment';
import {ActivityIndicator, ScrollView, View, Text} from '../../app-components';
import PatientCard from './PatientCard';
import {h15_Medium} from '../../theme/fonts';
const PastAppointment = (props) => {
  const [waitingRoomData, setWaitingRoom] = useState([]);
  const [loading, setLoading] = useState(false);

  const appointmentDatas = async () => {
    const {patient} = getUser();
    setLoading(true);
    try {
      const result = await fetch({
        uri: {
          props: {
            query: {
              id: 'appointmentLists',
              addOnFilter: {
                status: 'completed',
                patient_id: {
                  _id: {
                    $in: [
                      ...(getDependentPatient() ? getDependentPatient() : []),
                      patient?._id,
                    ],
                  },
                },
              },
              // sort: {time_slot: {start_time: -1}},
              // paramValue: {
              //   period: {
              //     // from: moment(new Date()).startOf('day').toDate(),
              //     // to: moment(new Date()).endOf('day').toDate(),
              //     to: moment(new Date()).startOf('day').toDate(),s
              //   },
              // },
            },
            model: 'Appointments',
          },
        },
      });
      const {data = []} = result || {};
      if (Array.isArray(data)) {
        setWaitingRoom(data.reverse());
      }
    } catch (err) {
      console.log(`>@>Puru ~ file: PastAppointment.js ~ line 48 ~ err`, err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log('>>.UseEffect ');
    appointmentDatas();
  }, []);
  const {selectedTab = ''} = props;
  return (
    <ScrollView style={{flex: 1}}>
      {loading ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ActivityIndicator size={'small'} color={'#fff'} />
        </View>
      ) : Array.isArray(waitingRoomData) && waitingRoomData.length ? (
        waitingRoomData.map((item, index) => (
          <View style={{flexBasis: 'auto'}} className="appointment-card">
            <PatientCard
              item={item}
              size={waitingRoomData.length}
              index={index}
              selectedTab={selectedTab}
              {...props}
              key={index.toString()}
              socketData={{}}
              appointmentDatas={appointmentDatas}
            />
          </View>
        ))
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text style={{...h15_Medium, color: '#fff'}}>
            You have no past appointments
          </Text>
        </View>
      )}
    </ScrollView>
  );
};
export default PastAppointment;
