import {colors, bgs} from './colors';

import {h12_Regular, h10_Regular} from './fonts';
import {uploadIcon, crossIcon, documentIcon} from '../images';
import {Headline9} from './Headlines';
import {manazeV6Colors} from './manazeV6Colors';

const {borderDashedFile, whiteBg} = bgs;
const {textColor60, neroLight} = colors;
const {neutral_4} = manazeV6Colors;
const boxStyle = {
  alignItems: 'center',
  flexDirection: 'row',
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 6,
  paddingRight: 10,
  backgroundColor: whiteBg,
  borderWidth: 1,
  borderRadius: 4,
  borderStyle: 'dashed',
  borderColor: borderDashedFile,
};
export const downloadFileBoxTheme = {
  fileInputStyle: {
    boxStyle: {...boxStyle, cursor: 'pointer'},
    uploadImageStyle: {
      image: uploadIcon,
      viewStyle: {
        justifyContent: 'flex-end',
        paddingRight: 6,
      },
      imageProps: {
        resizeMode: 'contain',
      },
      imageStyle: {
        width: 16,
        height: 13,
      },
    },
    uploadTextStyle: {
      viewStyle: {
        flex: 1,
        marginLeft: 6,
        marginRight: 6,
      },

      textStyle: {
        ...h12_Regular,
        color: neroLight,
      },
    },
  },
  fileRenderBoxTheme: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 4,
      marginBottom: 4,
      ...boxStyle,
    },

    documentImageStyle: {
      image: documentIcon,
      viewStyle: {
        padding: 6,
      },
      imageStyle: {
        width: 16,
        height: 16,
      },
    },
    downloadLinkContainerStyle: {
      containerStyle: {
        width: 150,
        flex: 1,
        padding: 6,
      },
      textStyle: {
        ...h12_Regular,
        color: textColor60,
        numberOfLines: 1,
      },
    },
    crossStyle: {
      viewStyle: {
        flex: 1,
        alignItems: 'flex-end',
        paddingLeft: 8,
        paddingRight: 8,
        cursor: 'pointer',
      },
      // textStyle: {
      //   // ...h2,
      //   color: 'red',
      // },
      // text: 'X',
      image: crossIcon,
      viewStyle: {
        padding: 6,
        cursor: 'pointer',
      },
      imageStyle: {
        width: 15,
        height: 15,
      },
    },
  },
};
export const fileInputBoxTheme = {
  // selectFileIcon,>>> to do icons
  containerViewStyle: {flex: 1, minHeight: 25},
  fileInputStyle: {
    boxStyle: {...boxStyle, cursor: 'pointer'},
    uploadImageStyle: {
      image: uploadIcon,
      viewStyle: {
        justifyContent: 'flex-end',
        paddingRight: 6,
      },
      imageProps: {
        resizeMode: 'contain',
      },
      imageStyle: {
        marginRight: 6,
        width: 15,
        height: 15,
      },
    },
    uploadTextStyle: {
      viewStyle: {
        flex: 1,
      },

      textStyle: {
        ...Headline9,
        color: neutral_4,
      },
    },
  },
  fileRenderBoxTheme: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 4,
      marginBottom: 4,
    },

    documentImageStyle: {
      image: documentIcon,
      viewStyle: {
        padding: 6,
      },
      imageStyle: {
        width: 16,
        height: 16,
      },
    },
    downloadLinkContainerStyle: {
      containerStyle: {
        width: 150,
        flex: 1,
        padding: 6,
      },
      textStyle: {
        ...h10_Regular,
        color: textColor60,
        numberOfLines: 1,
      },
    },
    crossStyle: {
      // textStyle: {
      //   // ...h2,
      //   color: 'red',
      // },
      // text: 'X',
      image: crossIcon,
      viewStyle: {
        padding: 6,
        cursor: 'pointer',
      },
      imageStyle: {
        width: 15,
        height: 15,
      },
    },
  },
};

export const UploadImageTheme = {
  // selectFileIcon,>>> to do icons
  // containerViewStyle: {},
  fileInputStyle: {
    boxStyle: {...boxStyle, cursor: 'pointer'},
    uploadImageStyle: {
      image: uploadIcon,
      viewStyle: {
        justifyContent: 'flex-end',
        paddingRight: 6,
      },
      imageProps: {
        resizeMode: 'contain',
      },
      imageStyle: {
        marginRight: 6,
        width: 15,
        height: 15,
      },
    },
    uploadTextStyle: {
      viewStyle: {
        flex: 1,
      },

      textStyle: {
        ...Headline9,
        color: neutral_4,
      },
    },
  },
  fileRenderBoxTheme: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 4,
      marginBottom: 4,
    },

    documentImageStyle: {
      image: documentIcon,
      viewStyle: {
        padding: 6,
      },
      imageStyle: {
        width: 16,
        height: 16,
      },
    },
    downloadLinkContainerStyle: {
      containerStyle: {
        width: 150,
        flex: 1,
        padding: 6,
      },
      textStyle: {
        ...h10_Regular,
        color: textColor60,
        numberOfLines: 1,
      },
    },
    crossStyle: {
      // textStyle: {
      //   // ...h2,
      //   color: 'red',
      // },
      // text: 'X',
      image: crossIcon,
      viewStyle: {
        padding: 6,
        cursor: 'pointer',
      },
      imageStyle: {
        width: 15,
        height: 15,
      },
    },
  },
};