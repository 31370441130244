import {Headline11,Headline10} from './Headlines';
import {manazeV6Colors} from './manazeV6Colors';

const {neutral_4, neutral_3} = manazeV6Colors;

export const leftIconStyle = {};

export const leftLabelStyle = {
  labelStyle: {
    ...Headline10,
    color: neutral_3,
    numberOfLines: 1,
  },
};

export const topLabelStyle = {
  labelContainerStyle: {
    height: 24,
  },
  labelStyle: {
    ...Headline10,
    color: neutral_3,
    numberOfLines: 1,
  },
};
