import React, {useState} from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  ActivityIndicator,
  isMobile,
} from '../../app-components';
import {BackgroundImage, MayaAiGirl} from '../../images';
import {TextInput} from '../../app-components/input-components/Editors';

import {mayamd_authenticate, mayaAISession, invoke} from '../../AppServices';
import {h14_Regular, h16_Regular} from '../../theme/fonts';
import {BRAND_NAME} from '../../Config';
import {brandImg, brandStyle} from '../../IconStyleBrand';
import {PasswordInput} from '../../npms/react-text-input';
// import axios from 'axios';

const OTP = (props) => {
  const {navigation} = props;
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState('');
  const mobile = navigation.getParam('mobile');
  const from = navigation.getParam('from');
  const type = navigation.getParam('type');

  const verifyOTP = async () => {
    if (!otp.length) {
      setOtpError('OTP is required');
    } else {
      setLoading(true);
      if (from === 'login') {
        mayamd_authenticate({
          mobile,
          password: otp,
          type,
        })
          .then((res) => {
            if (res?.user) {
              navigation.navigate('authenticator');
            } else {
              setOtpError('Wrong OTP');
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        await invoke({
          id: 'verifyOtp',
          paramValue: {
            mobile: mobile,
            password: otp,
            userType: type,
          },
        })
          .then((res) => {
            const {result = {}} = res || {};
            // console.log(`>@>Puru ~ file: OTP.js ~ line 59 ~ res`, result)
            navigation.replace({
              view: 'create-password',
              params: {
                otp,
                mobile,
                user: result,
                type,
                from,
              },
            });
          })
          .catch((err) => {
            setLoading(false)
            console.log(err);
          });
      }
    }
  };
  return (
    <View
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flex: 1,
        flexDirection: 'row',
      }}>
      {/* Login signup */}
      <View
        style={{
          // backgroundColor: 'red',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          className={'pulse'}
          style={{
            backgroundColor: '#fff',
            boxShadow: 'rgba(0,0,0,0.4) 5px 5px 10px 0px',
            marginBottom: 20,
            borderRadius: 20,
            padding: 8,
          }}>
          <Image
            // source={StewardLogo}
            source={brandImg[BRAND_NAME][0]}
            style={brandStyle[BRAND_NAME]['patient-login']}
          />
        </View>
        <View
          style={{
            backgroundColor: 'rgba(1, 59, 86, 0.35)',
            maxWidth: isMobile ? 320 : 400,
            minWidth: isMobile ? 320 : 400,
            minHeight: 150,
            borderRadius: 50,
            padding: 20,
          }}>
          <View
            style={{
              marginTop: 20,
              flex: 1,
            }}>
            <Text style={{marginBottom: 10, color: '#fff', ...h16_Regular}}>
              Enter Passcode
            </Text>
            <PasswordInput
              value={otp}
              onSubmitEditing={(event) => {
                if (event.key === 'Enter') {
                  !loading && verifyOTP && verifyOTP();
                }
              }}
              onChangeText={(value) => {
                setOtp(value);
              }}
              variant="filled"
              placeholder="Enter Passcode"
            />
            {/* <TextInput
              moreInputStyle={{width: 335}}
              value={otp}
              onChangeText={(value) => {
                setOtp(value);
              }}
              variant="filled"
              inputProps={{
                type: 'password',
              }}
              onSubmitEditing={(event) => {
                if (event.key === 'Enter') {
                  !loading && verifyOTP && verifyOTP();
                }
              }}
              placeholder="Enter Passcode"
            /> */}
          </View>
          {/* <View>
            <Text
              style={{
                ...h14_Regular,
                letterHeight: 21,
                letterSpacing: 0.5,
                color: '#fff',
                marginTop: 5,
                // opacity: 0.54,
              }}>
              We have sent Passcode on {mobile}
            </Text>
          </View> */}
          <TouchableOpacity
            onPress={() => {
              // if (from === 'signup') {
              //   navigation.replace('create-password');
              // } else {
              // }
              verifyOTP();
            }}
            style={{
              backgroundColor: '#00A7DA',
              flex: 1,
              minHeight: 45,
              maxHeight: 45,
              marginTop: 20,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              cursor: 'pointer',
            }}>
            {loading ? (
              <ActivityIndicator size={'small'} color={'white'} />
            ) : (
              <Text style={{color: '#fff'}}>Verify</Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
      {isMobile ? (
        void 0
      ) : (
        <View style={{flex: 1, justifyContent: 'flex-end'}}>
          <Image
            source={MayaAiGirl}
            style={{width: '100%', objectFit: 'contain'}}
          />
        </View>
      )}
    </View>
  );
};

export default OTP;
