import Table from '../../app-components/table/Table';
import {listUri} from '../../Queries';
import {getUser} from '../../AppServices';
import {resolvePatientName} from '../../app-components/patientUtility';
import {startCase} from 'lodash';
import {serialNumber} from '../RPM/rpmUtils';
let uri = ({
  navigation: {
    state: {params: {item: {_id} = {}} = {}},
  },
}) => {
  if (!_id) {
    const {organization = {}} = getUser();
    _id = organization?._id;
  }
  return listUri({
    model: 'Patients',
    id: 'patientLists',
    filter: {
      organization: {_id},
    },
  });
};
const tableData = {
  lg: {
    // action: ({item}) => {
    //   return {
    //     type: 'link',
    //     link: {
    //       view: 'edit-patient-form',
    //       params: {item},
    //     },
    //   };
    // },
    columns: [
      {
        render: (props) => serialNumber(props),
        header: 'S.No.',
        width: 50,
      },
      {
        type: 'text',
        field: (data) => resolvePatientName(data),
        header: 'Name',
        width: 200,
      },
      {
        type: 'text',
        // field: 'gender',
        header: 'Gender',
        field: (data) => startCase(data?.gender || ''),
        width: 200,
      },
      {
        type: 'text',
        field: 'mobile',
        header: 'Mobile number',
        width: 200,
      },
      {
        type: 'text',
        field: 'uniqueId',
        header: 'Unique ID',
        width: 300,
      },
      {
        type: 'text',
        field: 'email',
        header: 'Email',
        // width: 200,
      },
    ],
  },
  sm: {
    columns: [
      {
        render: (props) => serialNumber(props),
        header: 'S.No.',
        width: 50,
      },
      {
        type: 'text',
        field: (data) => resolvePatientName(data),
        header: 'Name',
        width: 100,
      },
      {
        type: 'number',
        field: 'mobile',
        header: 'Mobile number',
        width: 150,
      },
    ],
  },
};

export default Table({
  reloadEvent: 'reloadPatientData',
  uri,
  ...tableData,
});
