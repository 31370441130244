import React from 'react';
import {View, Text} from '../../app-components';
import TabNavigator from '../../app-components/tab/TabNavigator';
import CardView from './CardView';
import TableView from './TableView';
import {close} from '../../app-components/action/CloseAction';
import ExpandAction from '../../app-components/action/ExpandAction';
import FormWorking from './FormWorking';

export default TabNavigator({
  actions: [ExpandAction, close],
  tabs: {
    tab1: {
      label: 'Card',
      screen: CardView,
    },
    tab2: {
      label: 'Table',
      screen: TableView,
    },
    tab3: {
      label: 'Form',
      screen: FormWorking,
    },
  },
});
