import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  BackHandler,
  Dimensions,
} from '../../react-core-components';
import {WithReactEventDispatcher} from '../../react-event-dispatcher';
import StackItem from './AnimatedStackItem';
import StackModal from './StackModal';

class Stack extends React.Component {
  constructor(props) {
    super(props);
    const {routes} = props;
    let views = [
      this.getRoute(Object.keys(routes)[0], void 0, void 0, {index: 0}),
    ];
    views = this.getViewStyle(views);
    this.state = {views};
  }

  componentDidMount() {
    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress,
    );
  }

  componentWillUnmount() {
    this.backHandler && this.backHandler.remove();
  }

  handleBackPress = () => {
    let {backPressTime = 3000, showBackPressMessage} = this.props;
    if (this.pop()) {
      return true;
    }
    if (
      this.lastBackPressTime &&
      new Date() - this.lastBackPressTime < backPressTime
    ) {
      return false;
    }
    this.lastBackPressTime = new Date();
    showBackPressMessage && showBackPressMessage();
    return true;
  };

  getRouteInfo = view => {
    let route = void 0;
    if (typeof view === 'string') {
      route = {
        view,
      };
    } else {
      route = {...view};
    }
    let registeredRoute = this.props.routes[route.view];
    if (registeredRoute) {
      if (registeredRoute.modal !== undefined && route.modal === undefined) {
        route.modal = registeredRoute.modal;
      }
      if (
        registeredRoute.modalProps !== undefined &&
        route.modalProps === undefined
      ) {
        route.modalProps = registeredRoute.modalProps;
      }
    }
    return route;
  };

  getRoute = (view, params, action, {index}) => {
    let {itemStyle} = this.props;
    let route = this.getRouteInfo(view);
    const routeName = route.view;
    let registeredRoute = this.props.routes[routeName] || {
      screen: this.getErrorView({view: routeName}),
    };
    let {
      params: routeParams,
      action: routeAction,
      modal,
      modalProps,
      autoHide,
      routeOptions,
    } = route;
    params = params || routeParams || {};
    action = action || routeAction;
    let {expanded, width, defaultScreenState} = registeredRoute || {};
    if (routeOptions) {
      if (routeOptions.expanded !== undefined) {
        expanded = routeOptions.expanded;
      }
      if (routeOptions.width !== undefined) {
        width = routeOptions.width;
      }
      if (routeOptions.defaultScreenState !== undefined) {
        defaultScreenState = routeOptions.defaultScreenState;
      }
    }
    let navigation = this.getNavigation({
      params,
      routeName,
      action,
      modal,
      index,
    });
    if (route.navigation) {
      navigation._parent = route.navigation;
    }
    if (defaultScreenState && typeof defaultScreenState === 'function') {
      defaultScreenState = defaultScreenState({navigation});
    }
    return {
      view: registeredRoute,
      routeName,
      navigation,
      modal,
      modalProps,
      autoHide,
      width,
      screenState: {expanded, style: itemStyle, ...defaultScreenState},
      setScreenState: this.getScreenStateSetter({index, modal}),
    };
  };

  getScreenStateSetter = ({index, modal}) => {
    return state => {
      let {views, modalViews} = this.state;
      if (modal) {
        if (modalViews && modalViews[index]) {
          let {screenState} = modalViews[index];
          let newState = {...screenState, ...state};
          modalViews[index].screenState = newState;
          this.setState({modalViews});
        }
      } else if (views && views[index]) {
        let {screenState} = views[index];
        let newState = {...screenState, ...state};
        views[index].screenState = newState;
        if (state.expanded !== undefined) {
          views = this.getViewStyle(views);
        }
        this.setState({views});
      }
    };
  };

  getNavigation = ({params = {}, routeName, action, modal, index}) => {
    const {navigationOptions} = this.props;
    let navigation = {state: {params, routeName, index}, action};

    const getScreenState = () => {
      let {views, modalViews} = this.state;
      let {screenState} =
        (modal ? modalViews && modalViews[index] : views && views[index]) || {};
      return screenState || {};
    };

    const setScreenState = state => {
      this.getScreenStateSetter({index, modal})(state);
    };

    navigation.setScreenState = setScreenState;
    navigation.getScreenState = getScreenState;

    navigation.setExpanded = expanded => {
      setScreenState({expanded: expanded});
    };
    navigation.getExpanded = () => {
      let screenState = getScreenState();
      return screenState.expanded;
    };
    navigation.getVisibleViews = () => {
      return this.props.visibleViews || 1;
    };
    navigation.getActiveMQ = () => {
      let screenState = getScreenState();
      return (
        screenState.activeMQ ||
        (this.props.getActiveMQ && this.props.getActiveMQ())
      );
    };
    navigation.getParam = key => {
      let params = navigation.state.params || {};
      return params[key];
    };
    navigation.getParams = key => {
      let params = navigation.state.params || {};
      return key ? params[key] : params;
    };
    navigation.getRouteName = () => {
      return routeName;
    };
    navigation.getRoutePath = () => {
      return routeName;
    };
    navigation.getScreenName = () => {
      return routeName;
    };
    navigation.getIndex = () => {
      return index;
    };
    navigation.isModalView = () => {
      return modal ? true : false;
    };
    navigation.replace = (view, params, action) => {
      view = this.getRouteInfo(view);
      if (!modal && view.modal) {
        view.index = 0;
      } else if (view.index === void 0) {
        view.index = index;
      }
      return this.replace(view, params, action);
    };

    navigation.push = (view, params, action) => {
      view = this.getRouteInfo(view);
      if (!modal && view.modal) {
        view.index = 0;
      } else {
        view.index = index + 1;
      }
      this.replace(view, params, action);
    };

    navigation.navigate = navigation.push;

    navigation.reset = this.reset;
    navigation.pop = this.pop;
    navigation.getRouteCount = this.getRouteCount;
    navigation.goBack = this.goBack;
    navigation.openDrawer = this.openDrawer;
    navigation.closeDrawer = this.closeDrawer;
    navigation.getActiveRoutes = this.getActiveRoutes;
    navigation.navigationKey = this.props.navigationKey || 'stack';

    for (let key in navigationOptions) {
      navigation[key] = navigationOptions[key];
    }

    return navigation;
  };

  getErrorView = ({view}) => {
    return () => {
      return (
        <TouchableOpacity
          onPress={() => {
            this.goBack();
          }}>
          <Text>View not found [{view}]. [Close] </Text>
        </TouchableOpacity>
      );
    };
  };
  openDrawer = () => {
    this._drawer && this._drawer.openDrawer();
  };
  closeDrawer = () => {
    this._drawer && this._drawer.closeDrawer();
  };

  goBack = () => {
    return this.pop();
  };
  getRouteCount = () => {
    const {views = []} = this.state;
    return views.length;
  };
  getActiveRoutes = () => {
    const {views = []} = this.state;
    return views.map(view => view.routeName);
  };

  pop = count => {
    if (count === undefined || typeof count !== 'number') {
      count = 1;
    }
    let {views, modalViews = []} = this.state;
    let viewRemoved = false;
    if (modalViews.length && count > 0) {
      let newModalViews = [];
      for (let index = 0; index < modalViews.length - count; index++) {
        newModalViews.push(modalViews[index]);
      }
      count = count - (modalViews.length - newModalViews.length);
      modalViews = newModalViews;
      viewRemoved = true;
    }
    if (views.length > 1 && count > 0) {
      let newViews = [];
      for (let index = 0; index < views.length - count; index++) {
        newViews.push(views[index]);
      }
      views = newViews;
      if (views.length > 1) {
        let lastView = views[views.length - 1];
        lastView.screenState = {
          ...lastView.screenState,
          expanded: lastView.screenState.prevExpanded,
          prevExpanded: false,
        };
      }
      viewRemoved = true;
    }
    this.updateState({views, modalViews});
    return viewRemoved;
  };

  updateState = state => {
    let {views} = state;
    if (views) {
      views = this.getViewStyle(views);
      state.views = views;
      this.props.onRouteChange && this.props.onRouteChange({views});
    }
    this.setState(state);
  };

  getViewStyle = views => {
    let windowWidth = Dimensions.get('window').width;
    let expandedIndex = -1;
    let newViews = views.map((view, index) => {
      let {expanded, activeMQ, style} = this.getWidthStyle({
        index,
        views,
        windowWidth,
      });
      if (expanded) {
        expandedIndex = index;
      }
      view.screenState = {...view.screenState, activeMQ, style};
      return view;
    });
    if (expandedIndex !== -1) {
      newViews = newViews.map((view, index) => {
        if (index !== expandedIndex) {
          let viewScreenState = view.screenState;
          if (viewScreenState) {
            viewScreenState.style = {
              ...viewScreenState.style,
              viewStyle: {
                ...viewScreenState.style.viewStyle,
                flex: 0,
                display: 'none',
              },
            };
          }
        }
        return view;
      });
    }
    return newViews;
  };

  addRoute = ({views, route, index}) => {
    if (index >= views.length) {
      views.push(route);
    } else if (index !== undefined) {
      //if required index is less than current length, we may have to remove some views
      views = views.filter((view, viewIndex) => {
        return viewIndex <= index;
      });
      views[index] = route;
    }
    return views;
  };

  replace = (screen, params, action) => {
    let {views, modalViews} = this.state;
    let {modal, view} = screen;
    let length = 0;
    if (modal) {
      modalViews = modalViews ? [...modalViews] : [];
      length = modalViews.length;
    } else {
      modalViews = [];
      views = views ? [...views] : [];
      length = views.length;
      let prevViewIndex = ((views && views.length) || 0) - 1;
      if (views[prevViewIndex].routeName === view) {
        prevViewIndex = prevViewIndex - 1;
      }
      if (prevViewIndex > -1) {
        const {screenState: {expanded} = {}, setScreenState} =
          views[prevViewIndex] || {};
        expanded && setScreenState({expanded: false, prevExpanded: true});
      }
    }
    let requiredIndex;
    if (typeof screen === 'object' && screen.index !== void 0) {
      //to replace at a particular index
      requiredIndex = screen.index;
      if (requiredIndex > length) {
        requiredIndex = length;
      }
    }
    if (requiredIndex === undefined) {
      requiredIndex = length > 0 ? length - 1 : 0;
    }
    let route = this.getRoute(screen, params, action, {
      index: requiredIndex,
    });
    if (modal) {
      modalViews = this.addRoute({
        views: modalViews,
        route,
        index: requiredIndex,
      });
    } else {
      views = this.addRoute({views, route, index: requiredIndex});
    }
    this.updateState({views, modalViews});
  };
  reset = (screen, params, navigationOptions) => {
    let views = [this.getRoute(screen, params, navigationOptions, {index: 0})];
    this.updateState({views, modalViews: []});
  };
  drawerRef = ref => {
    this._drawer = ref;
  };

  getWidthStyle = ({index, views, windowWidth}) => {
    let {visibleViews = 1, itemStyle, getActiveMQ} = this.props;
    let {width, screenState: {expanded} = {}} = views[index] || {};
    let lengthValue = views.length;
    if (visibleViews === 1) {
      expanded = index === lengthValue - 1;
    }
    let activeMQ = getActiveMQ && getActiveMQ({width: windowWidth});
    if (expanded) {
      itemStyle = {
        ...itemStyle,
        viewStyle: {
          ...itemStyle.viewStyle,
          width: '100%',
          flex: void 0,
        },
      };
    } else if (visibleViews > 1) {
      if (
        views.length > 1 &&
        (index === lengthValue - 1 || index === lengthValue - 2)
      ) {
        if (index === lengthValue - 1) {
          let maxWidth = void 0;
          let mqWidth = void 0;
          if (width) {
            if (width <= 1) {
              maxWidth = `${width * 100}%`;
              mqWidth = windowWidth * width;
            } else {
              maxWidth = width;
              mqWidth = width;
            }
          } else {
            mqWidth = windowWidth / 2;
          }
          activeMQ = getActiveMQ && getActiveMQ({width: mqWidth});
          itemStyle = {
            ...itemStyle,
            viewStyle: {
              ...itemStyle.viewStyle,
              flex: maxWidth === undefined ? 1 : void 0,
              width: maxWidth,
            },
          };
        } else {
          const {width} = views[views.length - 1] || {};
          let mqWidth = void 0;
          if (width) {
            if (width <= 1) {
              mqWidth = windowWidth - windowWidth * width;
            } else {
              mqWidth = windowWidth - width;
            }
          } else {
            mqWidth = windowWidth / 2;
          }
          activeMQ = getActiveMQ && getActiveMQ({width: mqWidth});
          itemStyle = {
            ...itemStyle,
            viewStyle: {
              ...itemStyle.viewStyle,
              flex: 1,
            },
          };
        }
      } else if (views.length > 2) {
        itemStyle = {
          ...itemStyle,
          viewStyle: {
            ...itemStyle.viewStyle,
            flex: 0,
            display: 'none',
          },
        };
      }
    }
    return {expanded, activeMQ, style: itemStyle};
  };

  render() {
    let {
      containerStyle,
      renderHeader,
      renderFooter,
      Wrapper,
      Container,
      DrawerNavigator,
      modalStyle,
      ScreenComponent,
      getActiveMQ,
      getResolvedMQProps,
      eventDispatcher,
    } = this.props;
    let {views, modalViews} = this.state;
    let {
      navigation: firstChildNavigation,
      screenState: firstScreenState,
      setScreenState: firstSetScreenState,
    } = views[0] || {};
    let children = (
      <View style={{flex: 1, flexDirection: 'row', overflow: 'hidden'}}>
        {views && views.length
          ? views.map(
              (
                {view, navigation, routeName, screenState, setScreenState},
                index,
              ) => {
                return (
                  <StackItem
                    key={`${routeName}_${index}`}
                    theme={screenState.style}
                    index={index}
                    lastViewIndex={views.length - 1}
                    navigation={navigation}
                    eventDispatcher={eventDispatcher}
                    view={view}
                    screenState={screenState}
                    setScreenState={setScreenState}
                    ScreenComponent={ScreenComponent}
                    renderHeader={renderHeader}
                    renderFooter={renderFooter}
                  />
                );
              },
            )
          : null}
      </View>
    );
    let modalChildren =
      modalViews && modalViews.length
        ? modalViews.map(
            (
              {
                view,
                navigation,
                screenState,
                setScreenState,
                autoHide,
                modalProps,
              },
              index,
            ) => {
              const modalparams = {
                index,
                autoHide,
                view,
                modalProps,
                modalViews,
                navigation,
                eventDispatcher,
                screenState,
                setScreenState,
                ScreenComponent,
                renderHeader,
                renderFooter,
                modalStyle,
                getActiveMQ,
                getResolvedMQProps,
                closeModal: this.pop,
              };
              return <StackModal {...modalparams} />;
            },
          )
        : void 0;
    if (Container) {
      children = (
        <Container
          state={this.state}
          screenState={firstScreenState}
          setScreenState={firstSetScreenState}
          navigation={firstChildNavigation}
          eventDispatcher={eventDispatcher}>
          {children}
        </Container>
      );
    }
    if (DrawerNavigator) {
      children = (
        <DrawerNavigator
          getRef={this.drawerRef}
          navigation={firstChildNavigation}>
          {children}
        </DrawerNavigator>
      );
    }

    children = (
      <>
        {modalChildren}
        {children}
      </>
    );

    if (Wrapper) {
      children = (
        <Wrapper
          navigation={firstChildNavigation}
          eventDispatcher={eventDispatcher}>
          {children}
        </Wrapper>
      );
    }

    return (
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          ...containerStyle,
        }}>
        {children}
      </View>
    );
  }
}
Stack = WithReactEventDispatcher(Stack);
export default Stack;
