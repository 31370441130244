import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en/en.json';
import ar from './ar/ar.json';
import es from './es/es.json';
import moment from 'moment';
import dateFormat from './DateFormat/dateFormat.json';

// import 'moment/locale/;
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      ...en,
    },
  },
  es: {
    translation: {
      ...es,
    },
  },
  ar: {
    translation: {
      ...ar,
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: 'en', // Comment this line for using LanguageDetector functionality

    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: 'en',
    interpolation: {
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (value instanceof Date)
          return moment(value).format(dateFormat[lng] || format);
        return value;
      },
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
