import React from 'react';
import {clinicFilter} from '../../app-components/filter/MayaMDFilters';
import PaymentAnalytics from './PaymentAnalytics';
import {View, Text, isMobile} from '../../app-components';
import {h24_AvenirNext_500} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import CustomHeaderDate from '../../app-components/CustomHeaderDates/CustomHeaderDate';
import {getUser} from '../../AppServices';
let {textColor87} = colors;
let {DateFilter} = CustomHeaderDate;
const styles = {
  headerContainer: {
    flexDirection: 'row',
    paddingTop: isMobile ? 10 : 15,
    paddingBottom: isMobile ? 10 : 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
  },
  headerText: {
    paddingLeft: isMobile ? 17 : 27,
    ...h24_AvenirNext_500,
    color: textColor87,
  },
};
export default {
  PaymentAnalytics: {
    screen: PaymentAnalytics,
    renderHeader: (props) => {
      let {navigation, screenState} = props;
      let {setScreenState} = navigation;
      let {userType} = getUser();
      return (
        <>
          <View
            style={{
              height: 1,
              marginTop: 5,
              width: '100%',
              backgroundColor: 'rgb(151,151,151, 0.34)',
            }}
          />
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Payments</Text>
            <View style={{flexDirection: 'row'}}>
              {/* {userType === 'SuperAdmin'
              ? clinicFilter({setScreenState, screenState})
              : void 0} */}
              <DateFilter
                screenState={screenState}
                setScreenState={setScreenState}
                defaultFilter={'Week'}
                showDateToggle={!isMobile}
              />
            </View>
          </View>
        </>
      );
    },
  },
};
