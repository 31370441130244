import React from 'react';
import {StyleSheet} from '../../react-core-components';

class NumberInput extends React.Component {
  onChange = e => {
    let {onChangeText, onChangeValue, onChange, typecast} = this.props;
    if (onChange) {
      onChange(e);
    }
    if (onChangeText || onChangeValue) {
      let text = e && e.target && e.target.value;
      if (typeof text === 'string') {
        text = text.trim();
        if (text.length) {
          text = Number(text);
        } else {
          text = void 0;
        }
      }
      if (typecast) {
        text = typecast(text);
      }
      onChangeText && onChangeText(text);
      onChangeValue && onChangeValue(text, e);
    }
  };

  onKeyUp = e => {
    let {onKeyUp, onSubmitEditing} = this.props;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      onSubmitEditing && onSubmitEditing(e);
    }
    onKeyUp && onKeyUp(e);
  };

  render() {
    let {
      editable,
      onSubmitEditing,
      getRef,
      style,
      onChange,
      onChangeText,
      onChangeValue,
      typecast,
      ...restProps
    } = this.props;
    if (style && Array.isArray(style)) {
      style = StyleSheet.flatten(style);
    }
    let extraProps = {};
    if (editable === false) {
      extraProps['disabled'] = true;
    }
    if (getRef) {
      extraProps.ref = getRef;
    }
    if (onSubmitEditing) {
      extraProps['onKeyUp'] = this.onKeyUp;
    }
    return (
      <input
        type="number"
        keyboardType={'numeric'}
        style={style}
        onChange={this.onChange}
        {...extraProps}
        {...restProps}
      />
    );
  }
}
export default NumberInput;
