import {colors, bgs} from './colors';
import {h14_Regular, h12_Regular, h14_Medium} from './fonts';

import {
  expandIcon,
  selected_checkBoxIcon,
  mesageSendTickIcon,
  checkSelectedIcon,
  unCheckboxIcon,
  backCrossIcon,
  newSearchIcon,
} from '../images';
import {dropDownShadow, shadow} from './shadows';
import {manazeV6Colors} from './manazeV6Colors';
import {Headline11, Headline14, Headline8, Headline9} from './Headlines';
import {isMobile} from '../app-components';

import {Headline7} from './Headlines';
const {
  neutral_1,
  primary_5,
  primary_1,
  neutral_3,
  surface_white,
  primary_2,
  neutral_10,
  neutral_4,
  neutral_7,
  neutral_2,
  primary_6,
} = manazeV6Colors;

const {
  whiteBg,
  paleBlue,
  paleBlueLight,
  roundedChipsbg,
  onHoverBackgroundColor,
} = bgs;
const {textColor87, nero, neroLight, themeColor} = colors;

export const textFieldTheme = {
  viewStyle: {
    borderWidth: 1,
    borderStyle: 'solid',
    textOverflow: 'ellipsis',
    outline: 'none',
    color: nero,
    borderColor: paleBlue,
    backgroundColor: paleBlueLight,
    padding: 6,
    ...h12_Regular,
    borderRadius: 4,
  },
  textStyle: {
    ...h12_Regular,
    color: nero,
  },
};

export const checkBoxTheme = {
  iconStyle: {width: 20, height: 20},
  selectedIcon: checkSelectedIcon,
  unSelectedIcon: unCheckboxIcon,
};

export const dropdownInputTheme = {
  loadingStyle: {
    viewStyle: {position: 'absolute', right: 10, top: 0, bottom: 0},
    activityIndicatorProps: {
      width: 1.5,
      size: 15,
    },
  },
  dropdownStyle: {
    height: 200,
    backgroundColor: surface_white,
    border: 'none',
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 8,
    ...dropDownShadow,
  },
  dropdownTextStyle: {
    ...h12_Regular,
    color: nero,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: surface_white,
  },
  dropdownTextHighlightStyle: {
    ...h12_Regular,
    color: neroLight,
    backgroundColor: paleBlueLight,
  },
  arrowDownIcon: expandIcon,
  arrowIconStyle: {height: 18, width: 18},
};

export const autoSuggestMobileTheme = {
  crossIcon: backCrossIcon,
  searchIcon: newSearchIcon,
  dropdownStyle: {
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    height: 350,
    backgroundColor: 'white',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    paddingTop: 20,
  },
  headerContainer: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerTextAndIconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerStyle: {
    color: neutral_3,
    ...Headline9,
  },
  crossIconStyle: {
    height: 16,
    width: 16,
  },
  searchContainer: {
    backgroundColor: neutral_10,
    borderRadius: 4,
    marginTop: 12,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    height: 52,
    paddingRight: 17,
  },
  inputStyle: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    flex: 1,
    paddingLeft: 17,
    paddingRight: 17,
    ...Headline9,
    color: neutral_4,
    outline: 'none',
  },
  searchIconStyle: {
    height: 16,
    width: 16,
  },
  flatlistStyle: {paddingLeft: 16, paddingRight: 16},
  bottomButtonContainer: {height: 50, flexDirection: 'row'},
  resetButtonStyle: {
    margin: 8,
    flex: 1,
    borderWidth: 1,
    borderColor: neutral_7,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
  },
  resetTextStyle: {
    ...Headline7,
    color: primary_1,
  },
  submitButtonStyle: {
    margin: 8,
    flex: 1,
    backgroundColor: primary_1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
  },
  submitTextStyle: {
    ...Headline7,
    color: surface_white,
  },
};

export const autoSuggestTheme = {
  crossIcon: backCrossIcon,
  searchIcon: newSearchIcon,
  dropdownStyle: {
    backgroundColor: whiteBg,
    borderRadius: 4,
    margin: 8,
    height: 250,
    width: 312,
    ...dropDownShadow,
  },
  headerTextAndIconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: neutral_7,
  },
  headerStyle: {
    color: neutral_3,
    ...Headline9,
  },
  crossIconStyle: {
    height: 16,
    width: 16,
  },
  searchContainer: {
    // marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    height: 44,
    paddingRight: 17,
    borderBottomWidth: 1,
    borderColor: neutral_7,
  },
  inputStyle: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    flex: 1,
    paddingLeft: 17,
    paddingRight: 17,
    ...Headline9,
    color: neutral_4,
    outline: 'none',
  },
  searchIconStyle: {
    height: 16,
    width: 16,
  },
  bottomButtonContainer: {
    height: 50,
    flexDirection: 'row',
  },
  resetButtonStyle: {
    margin: 8,
    flex: 1,
    borderWidth: 1,
    borderColor: neutral_7,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
  },
  resetTextStyle: {
    ...Headline7,
    color: primary_1,
  },
  submitButtonStyle: {
    margin: 8,
    flex: 1,
    backgroundColor: primary_1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
  },
  submitTextStyle: {
    ...Headline7,
    color: surface_white,
  },
};

export const autoSuggestInputTheme = {
  autoSuggestModalTheme: isMobile ? autoSuggestMobileTheme : autoSuggestTheme,
  crossIcon: backCrossIcon,
  searchIcon: newSearchIcon,
  loadingStyle: {
    viewStyle: {position: 'absolute', right: 16, top: 0, bottom: 0},
    activityIndicatorProps: {
      width: 1.5,
      size: 15,
    },
  },
  dropdownStyle: {
    height: 200,
    backgroundColor: surface_white,
    border: 'none',
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 8,
    paddingTop: 8,
    paddingBottom: 8,
    ...shadow,
  },
  dropdownTextStyle: {
    ...h12_Regular,
    color: nero,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: surface_white,
  },
  dropdownTextHighlightStyle: {
    ...h12_Regular,
    color: neroLight,
    backgroundColor: paleBlueLight,
  },
  arrowDownIcon: expandIcon,
  arrowIconStyle: {height: 18, width: 18},
};

export const multiAutosuggestInputTheme = {
  ...dropdownInputTheme,
  chipsStyle: {
    containerStyle: {
      paddingTop: 4,
      paddingBottom: 4,
      maxHeight: 100,
    },
    itemContainerStyle: {
      paddingRight: 6,
      paddingLeft: 6,
      paddingTop: 4,
      paddingBottom: 4,
    },
    itemTextStyle: {
      paddingleft: 6,
      paddingRight: 6,
      ...Headline9,
      color: neutral_1,
    },
    crossContainerStyle: {
      paddingleft: 6,
      paddingRight: 6,
    },
    crossIconStyle: {
      height: 16,
      width: 16,
    },
    crossTextStyle: {
      ...Headline9,
      color: primary_2,
    },
  },

  roundedChipsStyle: {
    containerStyle: {
      marginTop: 4,
      marginBottom: 4,
      maxHeight: 100,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    itemContainerStyle: {
      padding: 10,
      alignItems: 'center',
      borderWidth: 0,
      width: 192,
      borderRadius: 19,
      backgroundColor: roundedChipsbg,
      margin: 4,
    },
    itemTextStyle: {
      maxWidth: 140,
      numberOfLines: 1,
      ...h14_Medium,
      color: themeColor,
    },
    crossContainerStyle: {
      width: 20,
      height: 20,
      cursor: 'pointer',
      marginLeft: 5,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-end',
    },
    crossIconStyle: {
      height: 20,
      width: 20,
    },
    crossTextStyle: {
      ...Headline9,
      color: primary_2,
    },
  },
  roundedChipsWithAvatarStyle: {
    containerStyle: {
      marginTop: 4,
      marginBottom: 4,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    itemContainerStyle: {
      backgroundColor: primary_5,
      borderRadius: 24,
      margin: 4,
      alignItems: 'center',
    },
    itemTextStyle: {
      ...Headline9,
      color: primary_1,
      numberOfLines: 1,
    },
    avatrStyle: {
      borderWidth: 1,
      borderColor: '#E1E1E1',
      backgroundColor: surface_white,
    },
    crossContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
    },
    crossIconStyle: {
      height: 16,
      width: 16,
    },
    crossTextStyle: {
      ...Headline9,
      color: neutral_3,
    },
  },
};

export const multiAutosuggestMobileInputTheme = {
  ...autoSuggestInputTheme,
  chipsStyle: {
    containerStyle: {
      marginTop: 4,
      marginBottom: 4,
      maxHeight: 100,
    },
    itemContainerStyle: {
      paddingRight: 6,
      paddingLeft: 6,
      paddingTop: 4,
      paddingBottom: 4,
    },
    itemTextStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      ...Headline9,
      color: neutral_1,
    },
    crossContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
    },
    crossIconStyle: {
      height: 16,
      width: 16,
    },
    crossTextStyle: {
      ...Headline9,
      color: primary_2,
    },
  },
  roundedChipsStyle: {
    containerStyle: {
      marginTop: 4,
      marginBottom: 4,
      maxHeight: 100,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    itemContainerStyle: {
      paddingRight: 6,
      paddingLeft: 6,
      paddingTop: 4,
      paddingBottom: 4,
      borderWidth: 1,
      borderColor: primary_2,
      borderRadius: 24,
      margin: 4,
    },
    itemTextStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      ...Headline9,
      color: neutral_2,
    },
    crossContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
    },
    crossIconStyle: {
      height: 16,
      width: 16,
    },
    crossTextStyle: {
      ...Headline9,
      color: primary_2,
    },
  },
  roundedChipsWithAvatarStyle: {
    containerStyle: {
      marginTop: 4,
      marginBottom: 4,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    itemContainerStyle: {
      backgroundColor: surface_white,
      borderRadius: 24,
      margin: 4,
      alignItems: 'center',
      borderWidth: 1,
      borderColor: neutral_7,
    },
    itemTextStyle: {
      ...Headline9,
      color: neutral_1,
      numberOfLines: 1,
    },
    avatrStyle: {
      borderWidth: 1,
      borderColor: neutral_7,
      backgroundColor: primary_6,
    },
    crossContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
    },
    crossIconStyle: {
      height: 16,
      width: 16,
    },
    crossTextStyle: {
      ...Headline9,
      color: neutral_3,
    },
  },
};

export const renderRowStyle = {
  containerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 2,
    paddingTop: 8,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8,
  },
  selectedIconContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 12,
    width: 12,
    marginLeft: 6,
  },
  hoverDescriptionStyle: {},
  hoverContainerStyle: {
    backgroundColor: onHoverBackgroundColor,
  },
  iconContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  hoverTitleStyle: {
    color: neutral_1,
  },
  avatarStyle: {
    marginRight: 12,
    backgroundColor: '#e8ecfc',
  },
  hoverAvatarStyle: {
    backgroundColor: surface_white,
  },
  avatarTextStyle: {...Headline11, color: primary_2},
  titleStyle: {...h14_Regular, color: neutral_2, numberOfLines: 1},
  selectedTitleStyle: {...h14_Medium, color: neutral_1},
  descriptionStyle: {
    marginTop: 3,
    ...Headline14,
    color: neutral_3,
    numberOfLines: 1,
  },
  selectedDescriptionStyle: {color: primary_2},
  selectedIcon: mesageSendTickIcon,
};


export const multiTextInputTheme = {
  chipsStyle: {
    containerStyle: {
      paddingTop: 4,
      paddingBottom: 4,
      maxHeight: 100,
    },
    itemContainerStyle: {
      paddingRight: 6,
      paddingLeft: 6,
      paddingTop: 4,
      paddingBottom: 4,
    },
    itemTextStyle: {
      paddingleft: 6,
      paddingRight: 6,
      ...Headline9,
      color: neutral_1,
    },
    crossContainerStyle: {
      paddingleft: 6,
      paddingRight: 6,
    },
    crossIconStyle: {
      height: 16,
      width: 16,
    },
    crossTextStyle: {
      ...Headline9,
      color: primary_2,
    },
  },

  roundedChipsStyle: {
    containerStyle: {
      marginTop: 4,
      marginBottom: 4,
      maxHeight: 100,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    itemContainerStyle: {
      padding: 10,
      alignItems: 'center',
      borderWidth: 0,
      // width: 192,
      borderRadius: 19,
      backgroundColor: roundedChipsbg,
      margin: 4,
    },
    itemTextStyle: {
      maxWidth: 140,
      numberOfLines: 1,
      ...h14_Medium,
      color: themeColor,
    },
    crossContainerStyle: {
      width: 20,
      height: 20,
      cursor: 'pointer',
      marginLeft: 5,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-end',
    },
    crossIconStyle: {
      height: 20,
      width: 20,
    },
    crossTextStyle: {
      ...Headline9,
      color: primary_2,
    },
  },
};
