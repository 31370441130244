import React from 'react';
import Form from '../../../app-components/form/Form';
import {save} from '../../../app-components/action/SaveAction';
import {cancelButton} from '../../../app-components/action/CloseAction';
import {submit} from '../../../AppServices';
import {autoSuggestInput} from '../../../autoSuggestions';

const EditMasterTestResult = (props) => {
  const ModifiedForm = Form({
    ...props,
    reloadEvent: 'reloadTestResultMapping',
    reloadOnChangeEvent: 'reloadTestResultMapping',
    closeView: 1,
    fieldVariant: 'filled',
    footer: {
      containerStyle: {
        margin: 0,
        borderTopWidth: 0,
      },
      actions: [cancelButton(), save({label: 'Submit'})],
    },
    onSubmit: submit({
      model: 'MayaTestResult',
      _id: 1,
    }),
    defaultValues: ({navigation}) => {
      const {
        state: {
          params: {item = {}},
        },
      } = navigation;

      return {
        krsnaaTestName: item?.krsnaaTestName,
        mayaTestName: item?.mayaTestName,
        mayaLabId: item?.mayaLabId,
        currentUnit: item?.currentUnit,
        mayaUnitId: item?.mayaUnitId,
        ref_from: item?.ref_from,
        ref_to: item?.ref_to,
        _id: item?._id,
        labCategory: item?.labCategory,
        labSubCategory:item?. labSubCategory,
        testSortOrder: item?.testSortOrder,
        alert_ref_from: item?.alert_ref_from,
        alert_ref_to: item?.alert_ref_to,
      };
    },
    header: {
      title: 'Edit Test Result Mapping',
      titleTextStyle: {
        color: 'rgba(56, 56, 56, 0.87)',
        fontSize: 20,
        marginLeft: 10,
        fontFamily: 'AvenirNext-Regular',
      },
    },
    formGroups: [
      {
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'text',
                label: 'Lab Test Name',
                field: 'krsnaaTestName',
              },
              {
                type: 'text',
                label: 'Maya Test Name',
                field: 'mayaTestName',
              },
              {
                type: 'text',
                label: 'Maya Lab Id',
                field: 'mayaLabId',
              },
              {
                type: 'text',
                label: 'Current Unit',
                field: 'currentUnit',
              },
              {
                type: 'number',
                label: 'Maya Unit Id',
                field: 'mayaUnitId',
              },
              {
                type: 'text',
                label: 'Reference From',
                field: 'ref_from',
              },
              {
                type: 'text',
                label: 'Reference To',
                field: 'ref_to',
              },
              autoSuggestInput({
                label: 'Lab Categories',
                placeholder: 'Select Lab Categories',
                field: 'labCategory',
                valueField: 'labCategoryName',
                suggestionField: 'labCategoryName',
                model: 'MayaLabCategory',
                query: 'mayaLabCategoryList',
                keyField: '_id',
              }),
              autoSuggestInput({
                label: 'Lab Sub Categories',
                placeholder: 'Select Lab Sub Categories',
                field: 'labSubCategory',
                valueField: 'labCategoryName',
                suggestionField: 'labCategoryName',
                model: 'MayaLabCategory',
                query: 'mayaLabCategoryList',
                keyField: '_id',
              }),
              {
                type: 'text',
                label: 'Sort Order',
                field: 'testSortOrder',
              },
              {
                type: 'text',
                label: 'Alert Reference From',
                field: 'alert_ref_from',
              },
              {
                type: 'text',
                label: 'Alert Reference To',
                field: 'alert_ref_to',
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default EditMasterTestResult;
