import React from 'react';
import {View, BasicNumberInput} from '../../react-core-components';
import {
  WithLabel,
  WithInputWrapper,
  WithVariant,
  InputError,
  getInputProps,
} from '../../react-input-wrapper';
import NumberSelector from './NumberSelector';

class NumberInput extends React.Component {
  onChangeValue = (value, e) => {
    let {onChangeText, onChangeValue} = this.props;
    onChangeText && onChangeText(value);
    onChangeValue && onChangeValue(value, e);
  };

  incValue = (e) => {
    let {value} = this.props;
    value = (value || 0) + 1;
    this.onChangeValue(value, e);
  };

  decValue = (e) => {
    let {value} = this.props;
    value = (value || 0) - 1;
    this.onChangeValue(value, e);
  };

  render() {
    let inputProps = getInputProps(this.props);
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, flexDirection: 'row', overflow: 'hidden'}}>
          <View style={{flex: 1, overflow: 'hidden'}}>
            <BasicNumberInput {...inputProps} />
          </View>
          <InputError {...this.props} />
        </View>
        <NumberSelector
          {...this.props}
          onInc={this.incValue}
          onDec={this.decValue}
        />
      </View>
    );
  }
}

NumberInput = WithLabel(WithInputWrapper(WithVariant(NumberInput)));

export default NumberInput;
