import React from 'react';
import Form from '../../../app-components/form/Form';
import {save} from '../../../app-components/action/SaveAction';
import {cancelButton} from '../../../app-components/action/CloseAction';
import {submit} from '../../../AppServices';
import isEmpty from 'lodash/isEmpty';

const EditLabCategory = (props) => {
  const ModifiedForm = Form({
    ...props,
    closeView: 1,
    reloadEvent: 'reloadLabCategories',
    reloadOnChangeEvent: 'reloadLabCategories',
    fieldVariant: 'filled',
    footer: {
      containerStyle: {
        margin: 0,
        borderTopWidth: 0,
      },
      actions: [cancelButton(), save({label: 'Save'})],
    },
    onSubmit: submit({
      model: 'MayaLabCategory',
    }),
    mandatory: {
      labCategoryName: 'Lab category name is mandatory.',
    },
    validations: {
      sortOrder: ({data}) => {
        const {sortOrder = ''} = data || {};
        if (!isEmpty(sortOrder)) {
          const value = !/^[0-9]+$/.test(sortOrder);
          if (value) {
            return 'Please enter number only.';
          }
        }
      },
    },
    defaultValues: ({navigation}) => {
      const {
        state: {
          params: {item = {}},
        },
      } = navigation;

      return {
        labCategoryName: item?.labCategoryName,
        sortOrder: item?.sortOrder,
        _id: item?._id,
      };
    },
    header: {
      title: 'Edit Lab Category',
      titleTextStyle: {
        color: 'rgba(56, 56, 56, 0.87)',
        fontSize: 20,
        marginLeft: 10,
        fontFamily: 'AvenirNext-Regular',
      },
    },
    formGroups: [
      {
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columns: [
              {
                type: 'text',
                label: 'LAB Category Name',
                field: 'labCategoryName',
              },
              {
                type: 'text',
                label: 'Sort Order',
                field: 'sortOrder',
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default EditLabCategory;
