import React from 'react';
import PatientDetailCard from '../GenericComponent/PatientDetailsCard';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
} from '../../app-components';
import {getParamsFromNav} from '../../app-components/utils';
import Table from '../../app-components/table/Table';
import {h16_Medium} from '../../theme/fonts';
import {getUser} from '../../AppServices';
import {closeIcon} from '../../images';
let uri = (props) => {
  const {item} = getParamsFromNav(props);
  const {patient: {_id: patientId} = {}} = item || {};
  let {doctor: {_id} = {}} = getUser();
  return {
    query: {
      id: 'appointmentLists',
      addOnFilter: {
        doctor_id: {_id},
        patient_id: {_id: patientId},
      },
      sort: {date: -1},
    },
    model: 'Appointments',
  };
};

let tableItems = {
  renderRow: (props) => <PatientDetailCard {...props} />,
};
const Cardview = Table({uri, ...tableItems});

const PatientDetailComponent = ({navigation}) => {
  return (
    <>
      <View
        style={{
          height: 1,
          marginTop: 5,
          width: '100%',
          backgroundColor: 'rgb(151,151,151, 0.34)',
        }}/>
      <View style={{backgroundColor: '#f8fafb', flex: 1, overflow: 'hidden'}}>
        <View
          style={{
            backgroundColor: '#f5f7f9',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 16,
            justifyContent: 'space-between',
          }}>
          <Text style={{...h16_Medium}}>Patient Details</Text>
          <View
            style={{flexDirection: 'row', alignItems: 'center', minHeight: 42}}>
            <TouchableOpacity
              onPress={() => navigation.pop()}
              style={{paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}}>
              <Image source={closeIcon} style={{height: 20, width: 20}} />
            </TouchableOpacity>
          </View>
        </View>
        <ScrollView style={{flex: 1}}>
          <Cardview navigation={navigation} />
        </ScrollView>
      </View>
    </>
  );
};
export default PatientDetailComponent;
