import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import AddPatientForm from './AddPatient';

let header = {
  title: 'Patient Detail',
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },

  actions: [cancelButton({label: '< Back '}), save()],
};

let editable = true;
export default AddPatientForm({
  header,
  editable,
  closeView: 2,
  formType: 'edit',
});
