import {Image, TouchableOpacity} from '../index';
import React from 'react';
import {
  collapseIcon,
  expandIcon,
  collapseNewIcon,
  expandNewIcon,
} from '../../images';
import {
  compactFormGroupStyle,
  expandIconStyle,
  expandStyle,
  formGroupHeaderStyle,
  formGroupMobileStyle,
  formGroupStyle,
  nestedFormGroupStyle,
  nonEditableformGroupHeaderStyle,
  nonEditableFormGroupStyle,
  sectionFormGroupStyle,
} from '../../theme/formTheme';

import {
  FormGroupHeader as ReactFormGroupHeader,
  FormGroup as ReactFormGroup,
} from '../../npms/react-form-groups-v1';
import {FormField} from './field';
import {CustomFormGroup as CustomFormGroupHoc} from '../../npms/react-form-groups-v1';
import {getRenderComponent, isMobile} from '../UtilityFunctions';

const CustomFormGroup = CustomFormGroupHoc();

const renderFormGroupHeader = (props) => {
  let {type, formType, editable, ...restProps} = props;
  if (type === undefined && editable === false) {
    type = 'nonEditableFormGroup';
  }
  type = type || formType;
  let formGroupHeaderTheme = formGroupHeaderStyle;
  if (type === 'nonEditableFormGroup') {
    formGroupHeaderTheme = nonEditableformGroupHeaderStyle;
  }
  return <ReactFormGroupHeader {...formGroupHeaderTheme} {...restProps} />;
};

export const renderFormGroup = (props) => {
  let {type, formType, ...restProps} = props;
  if (type === undefined && restProps.editable === false) {
    type = 'nonEditableFormGroup';
  }
  type = type || formType;
  if (type === 'custom') {
    return <CustomFormGroup {...restProps} />;
  } else {
    let formGroupTheme = isMobile ? formGroupMobileStyle : formGroupStyle;
    if (type === 'nonEditableFormGroup') {
      formGroupTheme = nonEditableFormGroupStyle;
    } else if (type === 'section') {
      formGroupTheme = sectionFormGroupStyle;
    } else if (type === 'nested') {
      formGroupTheme = nestedFormGroupStyle;
    } else if (type === 'compact') {
      formGroupTheme = compactFormGroupStyle;
    }
    return <FormGroup {...formGroupTheme} {...restProps} />;
  }
};

export default class FormGroup extends React.Component {
  isVisible = (visible = true) => {
    let {resolveVisible, ...restProps} = this.props;
    if (typeof visible === 'function') {
      if (resolveVisible) {
        return resolveVisible({
          ...restProps,
          visible,
        });
      } else {
        visible = visible(restProps);
      }
    }
    return visible;
  };

  renderColumn = (column) => {
    let {
      navigation,
      eventDispatcher,
      fieldVariant,
      fieldContainer,
      editable: fieldEditable = true,
      removeRow,
      index,
      error,
    } = this.props;
    let style = {flex: 1, overflow: 'hidden'};
    let component = void 0;
    if (React.isValidElement(column)) {
      component = column;
    } else {
      let {
        field,
        render,
        width,
        maxWidth,
        minWidth,
        visible,
        style: columnStyle,
        container = fieldContainer,
        editable = fieldEditable,
        variant = fieldVariant,
        ...rest
      } = column;
      if (!this.isVisible(visible)) {
        return;
      }
      if (columnStyle) {
        style = columnStyle;
      }
      if (width) {
        style = {width, ...columnStyle};
      } else {
        if (maxWidth) {
          style.maxWidth = maxWidth;
        }
        if (minWidth) {
          style.minWidth = minWidth;
        }
      }
      const activeMQ =
        navigation && navigation.getActiveMQ && navigation.getActiveMQ();
      if (error && typeof error === 'object') {
        error = error[field];
      }
      let formFieldProps = {
        activeMQ,
        navigation,
        eventDispatcher,
        field,
        render,
        editable,
        variant,
        container,
        removeRow,
        index,
        error,
        ...rest,
      };
      component = <FormField {...formFieldProps} />;
    }
    return {
      style,
      component,
    };
  };

  renderHeader = (headerProps) => {
    let {type, formType, editable, renderHeader, ...restProps} = this.props;
    if (renderHeader) {
      return getRenderComponent(renderHeader, {...restProps, ...headerProps});
    }

    return renderFormGroupHeader({type, formType, editable, ...headerProps});
  };

  renderExpandComponent = (props) => {
    let {expanded, onClick} = props;
    return (
      <TouchableOpacity onPress={onClick} {...expandStyle}>
        <Image
          source={
            expanded
              ? collapseNewIcon || collapseIcon
              : expandNewIcon || expandIcon
          }
          {...expandIconStyle}
        />
      </TouchableOpacity>
    );
  };

  render() {
    let {visible, label, ...restProps} = this.props;
    if (!this.isVisible(visible)) {
      return null;
    }
    if (label && typeof label === 'function') {
      label = label(restProps);
    }
    return (
      <ReactFormGroup
        {...restProps}
        label={label}
        renderHeader={this.renderHeader}
        expandComponent={this.renderExpandComponent}
        renderColumn={this.renderColumn}
      />
    );
  }
}
