import {colors, bgs} from './colors';
import {
  h12_Regular,
  h12_SemiBold,
  h18_Regular,
  h18_AvenirNext,
  h14_Medium,
  h14_AvenirNext,
  h18_SemiBold,
  h18_Medium,
} from './fonts';
import {shadow} from './shadows';
let {
  darkTextColor,
  textColor70,
  textColor20,
  errorColor,
  blueButton1,
  textWhite,
  textColor54,
  purpleColor,
  grey,
  themeColor
} = colors;
let {whiteBg} = bgs;
const loginTheme = {
  scrollViewStyle: {
    flex: 1,
    backgroundColor: whiteBg,
  },
  containerStyle: {
    backgroundSize: 'contain',
    flex: 1,
    backgroundColor: whiteBg,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    justifyContent: 'center',
  },

  loginBoxStyle: {
    backgroundColor: textWhite,
    borderRadius: 15,
    ...shadow,
    borderColor: '#E7EEF5',
    borderWidth: 1,
    padding: 30,
    paddingBottom: 20,
    margin: 20,
  },
  loginLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 42,
  },
  loginLogoStyle: {height: 60, width: 250,objectFit:"contain"},

  loginText: {
    marginBottom: 25,
    ...h18_SemiBold,
    color: darkTextColor,
  },
  mobileLabel: {
    ...h14_AvenirNext,
    marginBottom: 15,
    color: textColor70,
  },
  loginTextInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: textColor20,
    borderRadius: 4,
    ...h14_AvenirNext,
    outline: 'none',
    color: darkTextColor,
    marginBottom: 20,
  },
  loginButtonText: {
    ...h14_AvenirNext,
    lineHeight: 0.88,
    color: textWhite,
  },
  loginButton: {
    cursor: 'pointer',
    height: 46,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    backgroundColor: themeColor,
  },
  buttonContainer: {
    alignItems: 'center',
  },
  helpContainer: {
    flexDirection: 'row',
  },
  helpText: {paddingLeft: 4, color: purpleColor, ...h12_SemiBold},
  errorStyle: {...h12_Regular, color: errorColor},
  contactSupportViewStyle: {
    borderBottomWidth: 1,
    alignItem: 'center',
    margin: 20,
    borderBottomColor: textColor54,
  },
  contactSupportText: {
    ...h18_Regular,
    color: textColor54,
  },
};

const otpTheme = {
  scrollViewStyle: {
    flex: 1,
    backgroundColor: whiteBg,
  },
  containerStyle: {
    flex: 1,
    backgroundSize: 'contain',
    backgroundColor: whiteBg,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    justifyContent: 'center',
  },

  loginBoxStyle: {
    // width: 494,
    // height: 480,
    backgroundColor: textWhite,
    borderRadius: 15,
    ...shadow,
    borderColor: '#E7EEF5',
    borderWidth: 1,
    padding: 30,
    paddingBottom: 20,
    margin: 20,
  },
  loginLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 22,
  },
  loginLogoStyle: {height: 60,objectFit:"contain", width: 250},
  backLogo: {width: 24, height: 24, marginLeft: -5},

  labelStyle: {...h18_Medium, color: darkTextColor, marginBottom: 12},
  otpText: {
    ...h14_Medium,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  editButton: {
    color: themeColor,
    ...h14_Medium,
    marginLeft: 5,
    cursor: 'pointer',
  },

  buttonContainer: {
    alignItems: 'center',
  },
  otpInfoText: {
    ...h14_Medium,
    marginBottom: 20,
    color: grey,
  },
  loginTextInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: textColor20,
    borderRadius: 4,
    ...h14_AvenirNext,
    outline: 'none',
    color: darkTextColor,
  },
  loginButtonText: {
    ...h14_AvenirNext,
    // lineHeight: 0.88,
    color: textWhite,
  },
  loginButton: {
    height: 46,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginTop: 20,
    backgroundColor: themeColor,
  },
  resendPinContainer: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  resendText: {...h14_AvenirNext, color: textColor70, paddingRight: 5},
  resendButton: {color: themeColor, ...h12_SemiBold, cursor: 'pointer'},
  helpContainer: {flexDirection: 'row'},
  helpText: {paddingLeft: 4, color: purpleColor, ...h12_SemiBold},
  errorStyle: {...h12_Regular, height: 16, color: errorColor},
  contactSupportViewStyle: {
    borderBottomWidth: 1,
    alignItem: 'center',
    margin: 20,
    borderBottomColor: textColor54,
  },
  contactSupportText: {
    ...h18_Regular,
    color: textColor54,
  },
};

const theme = {loginTheme, otpTheme};
export default theme;
