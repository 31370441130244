import React from 'react';
import {cancel} from '../../app-components/action/CancelAction';
import {save} from '../../app-components/action/SaveAction';
import SignupFormHoc from './SignupFormHoc';
import CustomHeaderLabel from '../../app-components/CustomHeaderLabel';
import {isMobile} from '../../app-components';
const uri = ({navigation}) => {
  return {
    query: {
      id: 'patientLists',
      relationValue: {
        model: 'Patients',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Patients',
  };
};
const header = {
  hideBackIcon: true,
  title: ({navigation}) => {
    const {state: {params: {item} = {}} = {}} = navigation;
    return (
      <CustomHeaderLabel
        seperator={true}
        preLabel={'Edit Details'}
        postLabel={item?.name}
      />
    );
  },
  actions: isMobile ? [] : [cancel(), save()],
};

export default SignupFormHoc({header, uri});
