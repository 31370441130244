import React from 'react';
import {Platform} from '../index';
import {shadow} from '../../theme/shadows';
import {
  moreActionsTheme,
  moreActionsMobileTheme,
} from '../../theme/moreActionsTheme';
import {DropDownAction} from '../../npms/react-drop-down-v1';
import {moreOptionsIcon} from '../../images';
import {Action} from '../action/Action';

const detectMob = () => {
  if (
    Platform.OS !== 'web' ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export class MoreActions extends React.Component {
  render() {
    let {actions, ...restProps} = this.props;
    return <DropDownAction options={actions} Action={Action} {...restProps} />;
  }
}

MoreActions.defaultProps = {
  icon: moreOptionsIcon,
  dropdownStyle: {
    width: 200,
    height: 'auto',
    maxHeight: 300,
    backgroundColor: '#fff',
    borderRadius: 4,
    ...shadow,
  },
  ...(detectMob() ? moreActionsMobileTheme : moreActionsTheme),
};

export default MoreActions;
