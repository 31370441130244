import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import SurgicalHistoryForm from './SurgicalHistoryForm';

let header = {
  title: 'Add Surgical History',
  actions: [cancelButton(), save()],
};

export default SurgicalHistoryForm({header});
