import React, {memo} from 'react';

import Table from '../../app-components/table/Table';
import {
  weighingMachine,
  glucose,
  Bp,
  redWeighingMachine,
  redGlucose,
  redBp,
} from '../../images';
import PatientDetails from './PatientDetails';
import {getAgeFromDob} from '../../app-components/UtilityFunctions';
const imgMap = {
  'Tenovi Scale': {
    active: weighingMachine,
    err: redWeighingMachine,
    name: 'Weighing Scale',
  },
  'Tenovi Glucometer': {active: glucose, err: redGlucose, name: 'Glucometer'},
  'Tenovi BPM': {active: Bp, err: redBp, name: 'Blood Pressure Monitor'},
};

const checkAlgoType = (noteGenerated) => {
  let result = false;
  noteGenerated.filter((noteData) => {
    if (!noteData.algo_type) {
      result = true;
    } else if (noteData.algo_type === 'Symptom Checker') {
      result = true;
    }
  });
  return result;
};

const uri = (props) => {
  const {navigation} = props;
  const {state: {params: {item = {}} = {}} = {}} = navigation;

  let addOnFilter = {};
  if (item) {
    addOnFilter = {
      familyId: item.familyId,
      _id: {$nin: [item?._id]},
    };
  }
  return {
    query: {
      id: 'patientLists',
      addOnFilter: addOnFilter,
      sort: {_id: -1},
      skipAggregates: false,
    },
    model: 'Patients',
  };
};

const PatientList = Table({
  uri,
  reloadEvent: 'reloadPatient',
  reloadOnChangeEvent: 'reloadPatient',
  action: ({item}) => {
    return {
      type: 'link',
      link: {
        view: 'detailPatientForm',
        params: {item},
      },
    };
  },
  columns: [
    {
      type: 'selection',
      field: '_id',
      visible: (data) => !data?.navigation?.state?.params?.item,
    },
    {
      render: ({item}) => {
        return <PatientDetails item={item} showTitle />;
      },
      header: 'Member Details',
      width: 220,
    },
    {
      header: 'Age',
      type: 'text',
      field: (data) => {
        let {birthDate} = data || {};
        return birthDate ? getAgeFromDob(birthDate) : '';
      },
      width: 100,
      sortable: 'birthDate',
      reverseSort: true,
    },
    {
      header: 'Family Id',
      type: 'text',
      field: 'familyId',
      sortable: 'familyId',
      reverseSort: true,
    },
    {
      header: 'Member Id',
      type: 'text',
      field: 'patientId',
      sortable: 'patientId',
      reverseSort: true,
    },
    {
      header: 'Care Program',
      type: 'text',
      field: (data) => {
        let {careProgram = []} = data || {};
        let rpmProgramListName = [];
        (careProgram || []).map((item) => {
          if (
            typeof item?.careProgram?.name === 'string' &&
            item?.status === 'Active'
          ) {
            rpmProgramListName.push(item?.careProgram?.name.trim());
            return item?.careProgram?.name.trim();
          }
          return '';
        });
        return rpmProgramListName.join(', ');
      },
      // filter: {
      //   type: 'autoSuggest',
      //   field: 'careProgram.careProgram',
      //   fetch: autosuggestFetch({
      //     id: 'careProgramList',
      //     sort: { isDefault: -1 },
      //     model: 'CarePrograms',
      //     search: 'name',
      //     addOnFilter: ({ user }) => {
      //       const { organization } = user;
      //       return {
      //         $or: [{ isDefault: true }, { organization }],
      //       };
      //     },
      //   }),
      //   placeholder: 'Search Care Program',
      //   sugestionField: 'name',
      //   valueField: 'name',
      //   searchField: 'name',
      //   searching: true,
      // },
    },
    {
      header: 'Registered On',
      type: 'date',
      field: '_createdOn',
    },
    {
      type: 'moreActions',
      actions: [
        {
          text: 'View Notes',
          visible: ({item: {noteGenerated = []}}) =>
            !!noteGenerated?.length && checkAlgoType(noteGenerated),
          onPress: async (props) => {
            const {item, navigation} = props;
            navigation.push({
              view: 'viewNoteSelectLanguageModal',
              params: {patientId: item?._id},
              modal: true,
              modalProps: {
                autoHide: true,
                screenCenterStyle: {
                  borderRadius: 10,
                },
                style: {},
              },
            });
          },
        },
        {
          text: 'Edit Details',
          onPress: async (props) => {
            const {item, navigation} = props;
            navigation.push({
              view: 'editMemberDetail',
              params: {
                patientId: item?._id,
              },
            });
          },
        },
        // {
        //   text: 'Assign care programme',
        //   onPress: async (props) => {
        //     const {item, navigation} = props;
        //     navigation.push({
        //       view: 'assignCareProgramme',
        //       params: {
        //         item: item
        //       },
        //     });
        //   },
        // },
        {
          text: 'Assign ANM',
          onPress: async (props) => {
            const {item, navigation} = props;
            navigation.push({
              view: 'assignModal',
              params: {
                _id: item?._id,
                assignedANM: item?.assignedANM,
              },
              modal: true,
              modalProps: {
                autoHide: true,
                screenCenterStyle: {
                  borderRadius: 10,
                  width: '40%',
                  height: '40%',
                },
                style: {},
              },
            });
          },
        },
      ],
    },
  ],
});

export default memo(PatientList);
