import React from 'react';
import { circlesImage, } from '../../../../images';
import './MemberGraphTile.css';

const MemberGraphTile = (props) => {
  const { title, count, background ,icon } = props;

  return (
    <div className="member-graph-tile" style={{ background: background }}>
      <img src={circlesImage} class="card-img-absolute" alt="circle"></img>
      <div className="tile-title">{title}</div>
      <div className="tile-count">{count}</div>
      <div className='tile-icon'><img src={icon} style={{ height: "100%", width: "100%", objectFit: "contain", filter: "invert(100%)" }} alt="circle"></img></div>
    </div>
  );
};

MemberGraphTile.defaultProps = {
  backgroundColor: 'rgb(12, 175, 235)',
};

export default MemberGraphTile;
