import React from 'react';
import CenterTabNavigator from '../../../app-components/tab/CenterTabNavigator';
import ClinicPerformance from './ClinicPerformance';
import ClinicSummary from './ClinicSummary';
import ClinicTeam from './team';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import {getUser} from '../../../AppServices';
import OrganisationSetting from '../../masterData/OrganisationSetting';
import ClinicLocation from './clinicLoaction';
import Fts from '../../../app-components/filter/Fts';
import Patients from '../../RPM/Patients';
const headerTextContainerStyle = {
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    padding: 18,
    fontFamily: 'AvenirNext-Regular',
  },
  containerStyle: {backgroundColor: 'rgb(231, 238, 245)'},
};

export default CenterTabNavigator({
  tabs: {
    summary: {
      label: 'Summary',
      screen: ClinicSummary,
    },
    team: {
      label: 'Team',
      screen: ClinicTeam,
    },
    Patients: {
      label: 'Patients',
      screen: Patients,
      visible: () => {
        const {userType = ''} = getUser() || {};
        return userType === 'SuperAdmin';
      },
      fontFamily: 'AvenirNext-Regular',
      screenProps: {
        header: {
          title: 'Patients',
          ...headerTextContainerStyle,
          actions: [
            <Fts
              // {...props}
              // asParams
              // field={'searchText'}
              placeholder={'Search Patient '}
            />,
          ],
        },
      },
    },
    location: {
      label: 'Clinic Locations',
      screen: ClinicLocation,
      actions: [
        {
          visible: () => {
            const {userType} = getUser();
            return userType !== 'SuperAdmin';
          },
          render: () => {
            return <GradientButton label={'Add Clinic Location'} />;
          },
          type: 'link',
          link: {
            view: 'add-location',
          },
        },
      ],
    },
    // settingTab: {
    //   actions: [
    //     {
    //       render: () => {
    //         return <GradientButton label={'Add Setting'} />;
    //       },
    //       type: 'link',
    //       link: {
    //         view: 'add-organisationSetting',
    //       },
    //       visible: ({screenState: {data} = {}}) => {
    //         if (data === true) {
    //           return false;
    //         } else {
    //           return true;
    //         }
    //       },
    //     },
    //   ],
    //   visible: () => {
    //     let {userType} = getUser();
    //     return userType === 'ClinicAdmin' ? true : false;
    //   },
    //   label: 'Settings',
    //   reloadEvent: 'reloadOrganisationSetting',
    //   screen: OrganisationSetting,
    //   // countUri: countUri({
    //   //   model: 'OrganizationSetting',
    //   //   id: 'organizationSettingList',
    //   // }),
    // },
  },
});
