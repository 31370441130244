import LoginHoc from './LoginForm';
import AuthenticatorHoc from './Authenticator';
import OTPHoc from './OTPForm';
const LoginTemplate = ({login, otp,authenticator}) => {
  const Login = LoginHoc(login);
  const SuperAdminLogin = LoginHoc(login);
  const Authenticator = AuthenticatorHoc(authenticator);
  const OTP = OTPHoc(otp);
  return {
    Login,
    OTP,
    Authenticator,
    SuperAdminLogin
  };
};

export {LoginTemplate};
