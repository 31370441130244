import React from 'react';

import {View, Text, TouchableOpacity, isMobile} from '../../app-components';
import Table from '../../app-components/table/Table';
import {post} from '../../AppServices';
import {h11_Medium, h16_Regular} from '../../theme/fonts';

const moveStatus = ({
  item,
  title,
  value,
  model = 'Organizations',
  eventDispatcher,
}) => {
  return (
    <TouchableOpacity
      style={{
        backgroundColor: title == 'Approve' ? '#57ccbf' : '#f14f4f',
        borderRadius: 50,
        cursor: 'pointer',
      }}
      onPress={() =>
        post({
          data: item,
          updates: {
            status: value || title,
          },
          model,
        }).then(({}) => {
          eventDispatcher.notify('reloadClinic');
        })
      }>
      <View
        style={{
          alignItems: 'center',
          padding: 5,
        }}>
        <Text
          style={{
            ...h16_Regular,
            color: 'white',
          }}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};




let uri = () => {
  return {
    query: {
      id: 'organizationLists',
      addOnFilter: {
        status: 'pending',
      },
    },
    model: 'Organizations',
  };
};

let tableItems = {
  lg: {
    action: ({item}) => {
      return {
        type: 'link',
        link: {
          view: 'clinic-detail',
          params: {item},
        },
      };
    },
    columns: [
      {type: 'selection', width: 50},
      {
        type: 'text',
        field: 'email',
        header: 'Email',
        width: 200,
      },
      {
        type: 'text',
        field: 'organization_name',
        header: 'Organization Name',
        width: 200,
      },
      {
        type: 'text',
        field: 'organization_address.description',
        header: 'Organization Address',
        // width: 200,
      },
      {
        // header: 'Aprrove',
        header: {
          containerStyle: {justifyContent: 'center'},
          label: 'Aprrove',
          // textStyle:{},
        },
        width: 100,
        render: ({item, eventDispatcher}) =>
          moveStatus({
            item,
            title: 'Approve',
            value: 'approved',
            eventDispatcher,
          }),
      },
      {
        type: 'text',
        width: 100,
      },
      {
        header: {containerStyle: {justifyContent: 'center'}, label: 'Reject'},
        width: 100,
        render: ({item, eventDispatcher}) =>
          moveStatus({
            item,
            title: 'Reject',
            value: 'rejected',
            eventDispatcher,
          }),
      },
    ],
  },
  sm: {
    columns: [
      {
        type: 'text',
        field: 'email',
        header: 'Email',
        width: 100,
      },
      {
        type: 'text',
        field: 'organization_name',
        header: 'Organization Name',
        width: 100,
      },
      {
        header: {
          containerStyle: {justifyContent: 'center'},
          label: 'Aprrove',
        },
        width: 90,
        render: ({item, eventDispatcher}) =>
          moveStatus({
            item,
            title: 'Approve',
            value: 'approved',
            eventDispatcher,
          }),
      },
      {
        header: {containerStyle: {justifyContent: 'center'}, label: 'Reject'},
        width: 85,
        render: ({item, eventDispatcher}) =>
          moveStatus({
            item,
            title: 'Reject',
            value: 'rejected',
            eventDispatcher,
          }),
      },
    ],
  },
};

export default Table({
  uri,
  ...tableItems,
  rowHoverContainerStyle: {cursor: 'default'},
  reloadEvent: 'reloadClinic',
});
