import React from 'react';
import {View, Text, isMobile} from '../../app-components';
import {organisationNameFilter} from '../../app-components/filter/MayaMDFilters';
import Patients from './patient';
import PatientDetail from './patientDetails';
import SucessModal from './SucessModal';
import {h16_Medium} from '../../theme/fonts';

const styles = {
  headerContainer: {
    paddingTop: isMobile ? 15 : 25,
    paddingBottom: isMobile ? 15 : 25,
    justifyContent: 'space-between',
  },
  paddingleft: {
    paddingLeft: isMobile ? 10 : 27,
    ...h16_Medium,
  },
  paddingright: {
    paddingRight: 27,
  },
};

export default {
  'my-patient': {
    screen: Patients,
    renderHeader: (
      <>
        {isMobile ? (
          void 0
        ) : (
          <View
            style={{
              height: 1,
              marginTop: 5,
              width: '100%',
              backgroundColor: 'rgb(151,151,151, 0.34)',
            }}
          />
        )}
        <View style={styles.headerContainer}>
          <Text style={styles.paddingleft}>My Patients</Text>
          <View style={{flexDirection: 'row'}} />
        </View>
      </>
    ),
  },
  'patient-detail': {
    screen: PatientDetail,
    width: 0.48,
  },
  sucessModal: {
    screen: SucessModal,
  },
};
