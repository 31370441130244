/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  Image,
  ScrollView,
  TouchableOpacity,
  isMobile,
  Dimensions,
} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {
  getImageUrl,
  getUser,
  getPatientProfile,
  getDoctorProfile,
} from '../../AppServices';
import {default_logo, closeIcon, backIcon, userProfile} from '../../images';
import {h16_Regular, h14_Medium} from '../../theme/fonts';
import {getAge} from '../../app-components/UtilityFunctions';
import moment from 'moment';
import {resolvePatientName} from '../../app-components/patientUtility';
const {height, width} = Dimensions.get('window');
const Profile = (props) => {
  const {navigation, eventDispatcher} = props;
  const [profileInfo, setProfileInfo] = useState({});
  const [profilePhoto, setProfilePhoto] = useState();
  const profileData = getUser() || {};
  const {userType} = profileData;
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const OpenEditProfile = () => {
    if (userType === 'Patient') {
      navigation.push({
        view: 'edit-patient-profile',
        params: {
          items: profileData,
        },
        modal: true,
        modalProps: {
          position: 'screenRight',
          style: {width: isMobile ? width : 500},
        },
      });
    } else if (userType === 'Doctor') {
      navigation.push({
        view: 'edit-doctor-profile',
        params: {
          items: profileInfo,
        },
        modal: true,
        modalProps: {
          position: 'screenRight',
          style: {width: isMobile ? width : 500},
        },
      });
    } else {
      navigation.push({
        view: 'edit-user-profile',
        params: {
          items: profileData,
        },
        modal: true,
        modalProps: {
          position: 'screenRight',
          style: {width: isMobile ? width : 500},
        },
      });
    }
  };
  const reloadData = () => {
    if (userType === 'Patient') {
      const value = async () => {
        let resultPatient = await getPatientProfile({
          _id: profileData?.patient?._id,
        });
        setProfileInfo(resultPatient?.data?.[0]);
        setProfilePhoto(resultPatient?.data[0]?.profile_picture);
      };
      value();
    } else if (userType === 'Doctor') {
      const value1 = async () => {
        let resultDoctor = await getDoctorProfile({
          _id: profileData._id,
        });

        setProfileInfo(resultDoctor?.data[0]);
        setProfilePhoto(resultDoctor?.data[0]?.profile_picture);
      };
      value1();
    } else {
      setProfileInfo(profileData);
      setProfilePhoto(profileData?.photo);
    }
  };
  useEffect(() => {
    reloadData();
    eventDispatcher && eventDispatcher.listen('reloadProfile', reloadData);
    return () => {
      eventDispatcher && eventDispatcher.unlisten('reloadProfile', reloadData);
    };
  }, []);

  return (
    <>
      <ScrollView
        style={{
          flex: 1,
          margin: 15,
          padding: 10,
          // boxShadow: '0 2px 9px 0 rgba(0, 0, 0, 0.14)',
          // backgroundColor: 'rgb(248, 248, 248)',
        }}>
        <View
          style={{
            marginRight: 14,
            marginTop: 14,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <TouchableOpacity
            onPress={() => {
              navigation.pop();
            }}
            style={{cursor: 'pointer'}}>
            <Image source={backIcon} />
          </TouchableOpacity>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <TouchableOpacity
              style={{cursor: 'pointer', marginLeft: 10, marginRight: 10}}
              onPress={() => {
                // OpenEditProfile();
                navigation.push({
                  view: 'change-password',
                  params: {
                    // items: profileData,
                  },
                  modal: true,
                  modalProps: {
                    position: 'screenRight',
                    style: {width: isMobile ? width : 500},
                  },
                });
              }}>
              <GradientButton label={'Change Password'} />
            </TouchableOpacity>
            <TouchableOpacity
              style={{cursor: 'pointer'}}
              onPress={() => {
                OpenEditProfile();
              }}>
              <GradientButton label={'Edit Profile'} />
            </TouchableOpacity>
            {/* <TouchableOpacity
            onPress={() => {
              navigation.pop();
            }}
            style={{paddingLeft: 10, cursor: 'pointer'}}>
            <Image source={closeIcon} />
          </TouchableOpacity> */}
          </View>
        </View>
        <View
          style={{
            // flex: 1,
            // margin:30,
            paddingTop: 30,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {profilePhoto ? (
            <Image
              source={getImageUrl(profilePhoto.file)}
              // height={150}
              // width={150}
              style={{
                borderRadius: 5,
                paddingBottom: 20,
                // height: 150,
                width: 150,
              }}
            />
          ) : (
            <Image
              source={userProfile}
              style={{
                height: 150,
                width: 150,
                borderRadius: 5,
                paddingBottom: 20,
              }}
            />
          )}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'block',
              // marginLeft: 45,
            }}>
            <View
              style={{
                marginLeft: 45,
                // alignItems: 'center',
                // justifyContent: 'center',
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 20,
                }}>
                <View style={{width: 150}}>
                  <Text
                    style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                    Name
                  </Text>
                </View>
                <View>
                  <Text
                    style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                    {userType === 'Patient'
                      ? resolvePatientName(profileData?.patient)
                      : Array.isArray(profileData.name)
                      ? profileData?.patient?.name[0].given[0] +
                        ' ' +
                        profileData?.patient?.name[0].family
                      : profileData?.name}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 20,
                  flex: 1,
                }}>
                <View style={{width: 150}}>
                  <Text
                    style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                    E-mail
                  </Text>
                </View>
                <View>
                  <Text
                    style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                    {profileData?.email || 'N/A'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 20,
                }}>
                <View style={{width: 150}}>
                  <Text
                    style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                    Mobile
                  </Text>
                </View>
                <View>
                  <Text
                    style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                    {profileData?.mobile || 'N/A'}
                  </Text>
                </View>
              </View>

              {profileData?.about_me_description && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      About me
                    </Text>
                  </View>
                  <View style={{width: 200}}>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {profileData?.about_me_description || 'N/A'}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.address && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      Physical Address
                    </Text>
                  </View>
                  <View style={{width: isMobile ? width * 0.35 : 200}}>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {(profileData?.address?.line || ' ') +
                        ' ' +
                        (profileData?.address?.city || ' ') +
                        ' ' +
                        (profileData?.address?.state || ' ') +
                        ' ' +
                        (profileData?.address?.postalCode || ' ') +
                        ' ' +
                        (profileData?.address?.county || ' ') || 'N/A'}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.birthDate && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      DOB
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {moment(profileInfo.birthDate).format('MM/DD/YYYY')}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.blood_group && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      Blood Group
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {profileData.blood_group || 'N/A'}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.gender && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      Gender
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {capitalizeFirstLetter(profileInfo.gender || 'N/A') ||
                        'N/A'}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.surgical_history && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      Surgical History
                    </Text>
                  </View>
                  <View style={{flex: 1, maxWidth: 'fit-content'}}>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {profileData?.surgical_history
                        ? profileData?.surgical_history?.map(
                            (item, index, array) => {
                              const {name} = item;
                              if (array?.length === index + 1) {
                                return name + ' ';
                              }
                              return name + ', ';
                            },
                          )
                        : 'N/A '}
                      {profileData?.surgical_history_other &&
                        profileData?.surgical_history_other?.map(
                          (item, index, array) => {
                            if (array?.length === index + 1) {
                              return item;
                            }
                            return item + ', ';
                          },
                        )}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.allergies && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      Allergies
                    </Text>
                  </View>
                  <View style={{flex: 1, maxWidth: 'fit-content'}}>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {profileData?.allergies
                        ? profileData?.allergies?.map((item, index, array) => {
                            const {name} = item;
                            if (array?.length === index + 1) {
                              return name + ' ';
                            }
                            return name + ', ';
                          })
                        : 'N/A '}
                      {profileData?.allergies_other &&
                        profileData?.allergies_other?.map(
                          (item, index, array) => {
                            if (array?.length === index + 1) {
                              return item;
                            }
                            return item + ', ';
                          },
                        )}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.medications && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      Medications
                    </Text>
                  </View>
                  <View style={{flex: 1, maxWidth: 'fit-content'}}>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {profileData?.medications
                        ? profileData?.medications?.map(
                            (item, index, array) => {
                              const {name} = item;
                              if (array?.length === index + 1) {
                                return name;
                              }
                              return name + ', ';
                            },
                          )
                        : 'N/A '}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.medical_history && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      Medical History
                    </Text>
                  </View>
                  <View style={{flex: 1, maxWidth: 'fit-content'}}>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {profileData?.medical_history
                        ? profileData?.medical_history?.map(
                            (item, index, array) => {
                              const {name_en} = item;
                              if (array?.length === index + 1)
                                return name_en + ' ';
                              return name_en + ', ';
                            },
                          )
                        : 'N/A '}
                      {profileData?.medical_history_other &&
                        profileData?.medical_history_other?.map(
                          (item, index, array) => {
                            if (array?.length === index + 1) {
                              return item;
                            }
                            return item + ', ';
                          },
                        )}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.social_history && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      Social History
                    </Text>
                  </View>
                  <View style={{flex: 1, maxWidth: 'fit-content'}}>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {profileData?.social_history
                        ? profileData?.social_history?.map(
                            (item, index, array) => {
                              const {name} = item;
                              if (array?.length === index + 1) {
                                return name + ' ';
                              }
                              return name + ', ';
                            },
                          )
                        : 'N/A '}
                      {profileData?.social_history_other &&
                        profileData?.social_history_other?.map(
                          (item, index, array) => {
                            if (array?.length === index + 1) {
                              return item;
                            }
                            return item + ', ';
                          },
                        )}
                    </Text>
                  </View>
                </View>
              )}

              {profileData?.family_history && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}>
                  <View style={{width: 150}}>
                    <Text
                      style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                      Family History
                    </Text>
                  </View>
                  <View style={{flex: 1, maxWidth: 'fit-content'}}>
                    <Text
                      style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                      {profileData?.family_history
                        ? profileData?.family_history?.map(
                            (item, index, array) => {
                              const {name} = item;
                              if (array?.length === index + 1) {
                                return name + ' ';
                              }
                              return name + ', ';
                            },
                          )
                        : 'N/A '}

                      {profileData?.family_history_other &&
                        profileData?.family_history_other?.map(
                          (item, index, array) => {
                            if (array?.length === index + 1) {
                              return item;
                            }
                            return item + ', ';
                          },
                        )}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </>
  );
};
export default Profile;
