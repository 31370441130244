import React from 'react';
import AutosuggestInputWithoutModal from './AutosuggestInputWithoutModal';
import {Text, Image, View, WithModalButton} from '../index';

class AutosuggestInputFilterField extends React.Component {
  state = {
    isFilterSelected: false,
  };

  keyExtractor = (item, index) => {
    return String(index);
  };
  onSelect = ({item, index}) => {
    let {data, field, setValue, valueField} = this.props;

    if (item && item._id === '_all_') {
      item = void 0;
    }
    setValue && setValue({data, field, value: item});
    if (item) this.setState({isFilterSelected: true});
    else this.setState({isFilterSelected: false});
    const value = item && valueField ? item[valueField] : item;
    return value;
  };

  isHighlighted = ({item, index}) => {
    const {data, field, valueField, ...rest} = this.props;
    let value = data && field && data[field];
    if (value && value._id && item && item._id && value._id === item._id) {
      return true;
    } else {
      return false;
    }
  };
  onRenderNoDataCallback = ({result} = {}) => {
    const {field, setValue} = this.props;
    if (result) {
      setValue({field, value: result});
    }
  };

  renderNoData = (searchProps) => {
    const {renderNoData} = this.props;
    if (!renderNoData) {
      return;
    }
    return renderNoData({
      ...searchProps,
      onSubmitCallback: this.onRenderNoDataCallback,
    });
  };

  fetch = (searchProps) => {
    const {fetch, data, field, valueField, text} = this.props;

    return fetch({...searchProps, data}).then((result) => {
      let value = data && field && data[field];
      value = (value && value.value) || value;
      value = value && valueField ? value[valueField] : value;

      let options = result.data;
      // if (value && text) {
      //   //show all text
      //   options = [{_id: '_all_', [valueField]: text}, ...options];
      // }

      return options;
    });
  };

  reset = () => {
    this.onSelect({item: {_id: '_all_'}});
  };

  renderModal = ({frameStyle, hideModal}) => {
    let {
      data,
      field,
      valueField,
      setValue,
      fetch,
      renderNoData,
      theme,
      ...rest
    } = this.props;
    let value = data && field && data[field];

    value = value && valueField ? value[valueField] : value;

    const {top, left, right, width, bottom} = frameStyle;
    let {autosuggestInputStyle} = theme;
    rest = {
      theme,
      ...rest,
    };
    return (
      <AutosuggestInputWithoutModal
        style={{top, left, right, width, bottom, ...autosuggestInputStyle}}
        value={value}
        keyExtractor={this.keyExtractor}
        onItemSelect={(props) => {
          this.onSelect(props);
          hideModal();
        }}
        isHighlighted={this.isHighlighted}
        fetch={this.fetch}
        renderNoData={this.renderNoData}
        fetchOnMount={true}
        {...rest}
      />
    );
  };

  render() {
    const {
      data,
      field,
      valueField,
      text,
      placeholder,
      theme,
      selfIcon,
    } = this.props;
    let {isFilterSelected} = this.state;
    let value = data && field && data[field];
    value = (value && value.value) || value;
    value = value && valueField ? value[valueField] : value;
    let {
      withModalContainerStyle,
      imageStyle,
      icon,
      crossIcon,
      valueTextStyle,
      imageTextStyle,
      crossImageStyle,
      dropdownStyle,
    } = theme;
    if (selfIcon) icon = selfIcon;
    return (
      <WithModalButton
        renderModal={this.renderModal}
        autoHide={true}
        style={withModalContainerStyle}
        dropdownStyle={dropdownStyle}>
        <View style={{...imageTextStyle, cursor: 'pointer'}}>
          <Text style={valueTextStyle}>{value || text || placeholder}</Text>
          {value || isFilterSelected
            ? crossIcon && (
                <View onPress={this.reset}>
                  <Image source={crossIcon} style={crossImageStyle} />
                </View>
              )
            : icon && <Image source={icon} style={imageStyle} />}
        </View>
      </WithModalButton>
    );
  }
}

export default AutosuggestInputFilterField;
