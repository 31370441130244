import React from 'react';
import {FlatList, Platform} from '../../react-core-components';
import StandardDropDownItem from './StandardDropDownItem';

const StandardDropDown = (props) => {
  const {
    options = [],
    dropDownContainerStyle,
    dropDownItemContainerStyle,
    dropDownItemHoverContainerStyle,
    dropDownItemActiveContainerStyle,
    dropDownItemIconContainerStyle,
    dropDownItemIconStyle,
    dropDownItemTextStyle,
    dropDownItemHoverTextStyle,
    dropDownItemActiveTextStyle,
    dropDownItemSelectedIconContainerStyle,
    dropDownItemSelectedIconStyle,
    ...restProps
  } = props;
  let flexStyle = {};
  if (Platform.OS === 'web') {
    flexStyle.flex = 1;
  }

  const dropDownItemStyle = {
    containerStyle: dropDownItemContainerStyle,
    hoverContainerStyle: dropDownItemHoverContainerStyle,
    activeContainerStyle: dropDownItemActiveContainerStyle,
    iconContainerStyle: dropDownItemIconContainerStyle,
    iconStyle: dropDownItemIconStyle,
    textStyle: dropDownItemTextStyle,
    hoverTextStyle: dropDownItemHoverTextStyle,
    activeTextStyle: dropDownItemActiveTextStyle,
    selectedIconContainerStyle: dropDownItemSelectedIconContainerStyle,
    selectedIconStyle: dropDownItemSelectedIconStyle,
  };
  return (
    <FlatList
      data={options}
      style={{...dropDownContainerStyle, ...flexStyle}}
      renderItem={({item, index}) => {
        return (
          <StandardDropDownItem
            {...restProps}
            {...dropDownItemStyle}
            index={index}
            action={item}
            {...item}
          />
        );
      }}
    />
  );
};

StandardDropDown.defaultProps = {
  dropDownContainerStyle: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  dropDownItemContainerStyle: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 6,
    paddingBottom: 6,
    marginTop: 4,
    marginBottom: 4,
  },
  dropDownItemIconContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  dropDownItemTextStyle: {
    numberOfLines: 1,
    color: '#6d91f0',
    fontSize: 14,
  },
  dropDownItemHoverContainerStyle: {backgroundColor: '#e8ecfc'},
  dropDownItemActiveTextStyle: {fontSize: 14, fontWeight: 'bold'},
  dropDownItemSelectedIconContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 12,
  },
};

export default StandardDropDown;
