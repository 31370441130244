import React from 'react';
import TabNavigator from '../../app-components/tab/TabNavigator';
import {countUri} from '../../Queries';
import {GradientButton} from '../../app-components/buttons/Buttons';
import InviteLinks from './InviteLinks';
import {getUser} from '../../AppServices';

export default TabNavigator({
  tabs: {
    tab1: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Create Invite Links'} />;
          },
          type: 'link',
          link: {
            view: 'create-link',
            // expanded: true,
            // height: 300,
          },
        },
      ],
      label: 'Invite Links',
      reloadEvent: 'reloadInviteLinks',
      screen: InviteLinks,
      countUri: () => {
        const {organization} = getUser();
        return countUri({
          filter: {
            organization,
          },
          model: 'Invites',
          id: 'inviteLists',
        });
      },
    },
  },
});
