import React from 'react';
import {Text, TouchableOpacity, View} from '../../app-components';
import {moreIcon, userIcon} from '../../images';
import Table from '../../app-components/table/Table';
let uri = () => {
  return {
    query: {
      id: 'demoDetails',
    },
    model: 'Demo',
  };
};

const cardDemo = {
  card: {
    action: () => {
      alert('Welcome');
    },
    title: {primaryField: 'name', secondaryField: 'email'},
    left: [
      {
        size: 'lg',
        avatar: true,
        iconField: 'image',
        defaultIcon: userIcon,
        variant: 'square',
        value: ({item}) => item && item.name,
      },
    ],

    right: [
      {
        width: 300,
        items: [
          {
            value: 'CLASSES',
          },
          {
            value: ({item}) => {
              console.log('@@@@@@@@==>item', item);
              return (
                <View>
                  {item.class_id &&
                    item.class_id.length &&
                    item.class_id.map((item) => {
                      return <Text style={{fontSize: 10}}>{item.name}</Text>;
                    })}
                </View>
              );
            },
          },
        ],
      },
      {
        width: 150,
        items: [
          {
            value: 'UNIT',
          },
          {
            field: 'unit.name',
          },
        ],
      },
      {
        width: 150,
        items: [
          {
            field: 'subject',
          },
          {
            field: 'dob',
          },
        ],
      },
      {
        width: 200,
        items: [
          {
            prefix: '$',
            field: 'fee',
            textStyle: {fontSize: 18, fontWeight: 'bold'},
          },
          {
            prefix: 'for ',
            field: 'time',
            suffix: 'Hrs',
          },
          {
            value: () => (
              <TouchableOpacity
                style={{
                  backgroundColor: 'aqua',
                  height: 30,
                  width: 200,
                  borderRadius: 5,
                  marginTop: 8,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text>Book Now</Text>
              </TouchableOpacity>
            ),
          },
        ],
      },
    ],
    hoverActions: [
      {
        icon: moreIcon,
        iconStyle: {height: 18, width: 6},
        action: () => {
          alert('hello');
        },
      },
    ],
  },
};
export default Table({uri, ...cardDemo});
