import React from 'react';
import {Text, View, Image} from '../../app-components';
import {greenTick, tickIcon,blueTick, greyTick} from '../../images';
import {h15_Regular} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import {
  getSelectedDoctor,
  getUser,
  getinitiallyLogged,
} from '../../AppServices';

const {themeColor}= colors
const TabsFlow = ({navigation}) => {
  const routes = navigation.getActiveRoutes();
  const user = getUser();
  const styles = {
    container: {
      flexDirection: 'row',
      paddingTop: 29,
      paddingBottom: 19,
      alignItems: 'center',
      justifyContent: 'sapce-between',
      backgroundColor: 'rgb(216,216,216, 0.2)',
      flex: 1,
    },
    reachedText: {
      ...h15_Regular,
      fontWeight: 500,
      // color: '#73c5a7'
      color: "#00AB25"
    },
    currentText: {
      ...h15_Regular,
      fontWeight: 500,
      color: themeColor,
    },
    restText: {
      ...h15_Regular,
      fontWeight: 500,
      color: '#383838',
      opacity: 0.34,
    },
  };

  const ImageType = ({status}) => {
    let image;
    if (status === 'reached') {
      image = greenTick;
    } else if (status === 'current') {
      image = tickIcon;
    } else {
      image = greyTick;
    }
    return <Image source={image} style={{paddingRight: 7,height:25,width:25,objectFit:"contain" }} />;
  };

  const TextType = (status) => {
    let text;
    if (status === 'reached') {
      text = styles.reachedText;
    } else if (status === 'current') {
      text = styles.currentText;
    } else {
      text = styles.restText;
    }
    return text;
  };

  const tab = (props) => {
    const {name, status} = props || {};
    return (
      <View
        style={{
          flexDirection: 'row',
          paddingTop: 19,
          paddingBottom: 12,
          paddingLeft: 30,
          paddingRight: 30,
          // flex: SymptomsCurrentCondition ? 1 : '',
          alignItems: 'center',
          backgroundColor: status === 'current' ? 'rgb(92,207,233, 0.1)' : '',
        }}>
        <ImageType status={status} />
        <Text style={TextType(status)}>{name}</Text>
      </View>
    );
  };

  const statusCheck = (reached, current) => {
    if (reached) {
      return 'reached';
    } else if (current) {
      return 'current';
    }
    return 'rest';
  };

  const DateTimeReachedCondition =
    routes?.indexOf('after-booking-slot') !== -1 ||
    routes?.indexOf('booking-confirm') !== -1;

  const DateTimeCurrentCondition = routes?.indexOf('after-booking-slot') === -1;

  const loginSignUpReachedCondition =
    routes?.indexOf('patient-details') !== -1 ||
    routes?.indexOf('booking-confirm') !== -1 ||
    routes?.indexOf('patient-payment') !== -1;

  const loginSignUpCurrentCondition =
    routes?.indexOf('patient-login') !== -1 || routes?.indexOf('OTP') !== -1;

  const PatientNameReachedCondition =
    routes?.indexOf('booking-confirm') !== -1 ||
    routes?.indexOf('patient-payment') !== -1;
  const PatientNameCurrentCondition = routes?.indexOf('patient-details') !== -1;

  const PaymentReachedCondition = routes?.indexOf('booking-confirm') !== -1;
  const PaymentCurrentCondition =
    routes?.indexOf('patient-payment') !== -1 &&
    routes?.indexOf('booking-confirm') === -1;

  const SymptomsCurrentCondition = routes?.indexOf('booking-confirm') !== -1;

  const getBorderWidth = () => {
    if (DateTimeCurrentCondition) return '28%';
    else if (loginSignUpCurrentCondition && !user) return '40%';
    else if (loginSignUpCurrentCondition) return '34%';
    else if (PatientNameCurrentCondition) return '58%';
    else if (PaymentCurrentCondition) return '78%';
    else return '100%';
  };
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-around',
          backgroundColor: 'rgb(216,216,216, 0.2)',
          paddingLeft: 15,
          // paddingRight: 15,
        }}>
        {tab({
          name: 'Dr. ' + getSelectedDoctor().split(' ')[0],
          status: 'reached',
        })}
        {tab({
          name: 'Select Date & Time',
          status: statusCheck(
            DateTimeReachedCondition,
            DateTimeCurrentCondition,
          ),
        })}
        {!getinitiallyLogged()
          ? tab({
              name: 'Login/ Sign up',
              status: statusCheck(
                loginSignUpReachedCondition,
                loginSignUpCurrentCondition,
              ),
            })
          : null}
        {tab({
          name: 'Patient Name',
          status: statusCheck(
            PatientNameReachedCondition,
            PatientNameCurrentCondition,
          ),
        })}
        {tab({
          name: 'Payment',
          status: statusCheck(PaymentReachedCondition, PaymentCurrentCondition),
        })}
        {tab({
          name: 'Add Symptoms/Query',
          status: statusCheck(false, SymptomsCurrentCondition),
        })}
      </View>
      <View
        style={{
      // color: '#73c5a7',
          backgroundColor:"#00AB25",
          // backgroundImage: 'linear-gradient(95deg, #55cfea -6%, #5c98f0 111%)',
          height: 3,
          width: getBorderWidth(),
        }}
      />
    </>
  );
};

export default TabsFlow;
