import React from 'react';
import {Text} from '../../react-core-components';
import {resolveValue} from './CardUtility';
import {
  titleColor,
  primaryTitleColor,
  secondaryTitleColor,
} from './theme/Colors';

const CardTitle = props => {
  let {field, value, variant, item} = props;
  if (typeof value === 'function') {
    value = value(props);
  } else if (value === undefined && field) {
    value = resolveValue(item, field);
  }
  if (value === undefined) {
    return null;
  }
  let titleStyle = props[variant ? `${variant}TitleStyle` : titleStyle];
  return (
    <Text style={titleStyle} numberOfLines={1}>
      {value}
    </Text>
  );
};

CardTitle.defaultProps = {
  primaryTitleStyle: {
    fontSize: 19,
    color: primaryTitleColor,
    numberOfLines: 1,
  },
  secondaryTitleStyle: {
    fontSize: 14,
    color: secondaryTitleColor,
    numberOfLines: 1,
  },
  titleStyle: {
    fontSize: 14,
    color: titleColor,
    numberOfLines: 1,
  },
};

export default CardTitle;
