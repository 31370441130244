import React from 'react';
import RoundedTabNavigator from '../../../../app-components/tab/RoundedTabNavigator';
import {GradientButton} from '../../../../app-components/buttons/Buttons';
import ClinicAdmin from './ClinicAdmin';
import {countUri} from '../../../../Queries';
import Doctors from './Doctors';
import FrontDesk from './FrontDesk';
import Button from './AddDoctorDefault';
import LabList from './LabList';
import {getUser} from '../../../../AppServices';
import MedicalAssistantList from './MedicalAssistantList';
import DialysisAdminList from './DialysisAdminList';
import NurseList from './NurseList';
// doctorLists

export default RoundedTabNavigator({
  tabs: {
    doctors: {
      label: 'Doctors',
      reloadEvent: 'reloadDoctor',
      screen: Doctors,
      countUri: ({navigation}) => {
        let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
        let {organization: {_id: organizationId} = {}} = getUser();
        if (!_id) {
          _id = organizationId;
        }
        return countUri({
          model: 'Doctor',
          id: 'doctorLists',
          filter: {organization: {_id}},
        });
      },
      actions: [
        {
          render: ({navigation}) => {
            return <Button navigation={navigation} />;
          },
        },
      ],
    },
    'front-desk': {
      label: 'Front Desk',
      reloadEvent: 'reloadFrontDesk',
      screen: FrontDesk,
      countUri: ({navigation}) => {
        let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
        let {organization: {_id: organizationId} = {}} = getUser();
        if (!_id) {
          _id = organizationId;
        }
        return countUri({
          model: 'User',
          id: 'userLists',
          filter: {organization: {_id}, userType: 'FrontDesk'},
        });
      },
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Front Desk'} />;
          },
          type: 'link',
          link: ({
            navigation: {
              state: {params = {}},
            },
          }) => {
            return {
              view: 'add-front-desk',
              params,
            };
          },
        },
      ],
    },
    'clinic-admin': {
      label: 'Clinic Admin',
      reloadEvent: 'reloadClinicAdmin',
      screen: ClinicAdmin,
      countUri: ({navigation}) => {
        let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
        let {organization: {_id: organizationId} = {}} = getUser();
        if (!_id) {
          _id = organizationId;
        }
        return countUri({
          model: 'User',
          id: 'userLists',
          filter: {organization: {_id}, userType: 'ClinicAdmin'},
        });
      },
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Clinic Admin'} />;
          },
          type: 'link',
          link: ({
            navigation: {
              state: {params = {}},
            },
          }) => {
            return {
              view: 'add-clinic-admin',
              params,
            };
          },
        },
      ],
    },
    'lab-member': {
      label: 'Lab Admin',
      reloadEvent: 'reloadLab',
      screen: LabList,
      countUri: ({navigation}) => {
        let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
        let {organization: {_id: organizationId} = {}} = getUser();
        if (!_id) {
          _id = organizationId;
        }
        return countUri({
          model: 'User',
          id: 'userLists',
          filter: {organization: {_id}, userType: 'LabAdmin'},
        });
      },
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Lab Admin'} />;
          },
          type: 'link',
          link: ({
            navigation: {
              state: {params = {}},
            },
          }) => {
            return {
              view: 'add-lab-member',
              params,
            };
          },
        },
      ],
    },
    'medical-assistant': {
      label: 'Medical Assistant',
      reloadEvent: 'reloadMedicalAssistant',
      screen: MedicalAssistantList,
      countUri: ({navigation}) => {
        let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
        let {organization: {_id: organizationId} = {}} = getUser();
        if (!_id) {
          _id = organizationId;
        }
        return countUri({
          model: 'User',
          id: 'userLists',
          filter: {organization: {_id}, userType: 'MedicalAssistant'},
        });
      },
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Medical Assistant'} />;
          },
          type: 'link',
          link: ({
            navigation: {
              state: {params = {}},
            },
          }) => {
            return {
              view: 'add-medical-assistant',
              params,
            };
          },
        },
      ],
    },
    'dialysis-admin': {
      visible: ({navigation}) => {
        let {state: {params: {item:{is_dialysis=false}={}} = {}} = {}} = navigation;
        let {organization:{is_dialysis: organizationDialysis=false} = {}} = getUser();
        if (!is_dialysis) {
          is_dialysis   = organizationDialysis;
        }
        return is_dialysis;
      },
      label: 'Dialysis Admin',
      reloadEvent: 'reloadDialysisAdmin',
      screen: DialysisAdminList,
      countUri: ({navigation}) => {
        let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
        let {organization: {_id: organizationId} = {}} = getUser();
        if (!_id) {
          _id = organizationId;
        }
        return countUri({
          model: 'User',
          id: 'userLists',
          filter: {organization: {_id}, userType: 'DialysisAdmin'},
        });
      },
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Dialysis Admin'} />;
          },
          type: 'link',
          link: ({
            navigation: {
              state: {params = {}},
            },
          }) => {
            return {
              view: 'add-dialysis-admin',
              params,
            };
          },
        },
      ],
    },
    'nurse': {
      label: 'Nurse',
      reloadEvent: 'reloadNurse',
      screen: NurseList,
      countUri: ({navigation}) => {
        let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
        let {organization: {_id: organizationId} = {}} = getUser();
        if (!_id) {
          _id = organizationId;
        }
        return countUri({
          model: 'User',
          id: 'userLists',
          filter: {organization: {_id}, userType: 'Nurse'},
        });
      },
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Nurse'} />;
          },
          type: 'link',
          link: ({
            navigation: {
              state: {params = {}},
            },
          }) => {
            return {
              view: 'add-nurse',
              params,
            };
          },
        },
      ],
    },
  },
});
