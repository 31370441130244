import React, {useState} from 'react';
import {View, Text, TouchableOpacity, Image} from '../../../app-components';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import {h12_Medium, h14_AvenirNext, h16_Regular} from '../../../theme/fonts';
import {invoke} from '../../../AppServices';
import {TextAreaInput} from '../../../app-components/input-components/Editors';
import {redCloseIcon} from '../../../images';
function AddComment(props) {
  const {navigation = {}, eventDispatcher} = props;
  const {
    state: {
      params: {item, isAttended = false, setAttended, attended} = {},
    } = {},
  } = navigation;
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleChange = (value) => {
    if (error) {
      setError('');
    }
    setComment(value);
  };
  const handleOnSubmit = () => {
    if (comment.trim()) {
      setLoading(true);
      invoke({
        id: 'markAttended',
        paramValue: {
          patientId: item?._id,
          markAttend: isAttended,
          comment: comment.trim(),
        },
      })
        .then((res) => {
          if (isAttended) {
            setAttended(true);
            eventDispatcher.notify('deviceReload');
          }
          navigation.pop();
          eventDispatcher.notify('reloadReview');
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(`>@>Puru ~ file: AddComment.js ~ line 40 ~ err`, err);
        });
    } else {
      setError('Please enter comment');
    }
  };
  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: 500,
        padding: 20,
        zIndex: 3,
      }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Text style={{marginBottom: 10, marginTop: 10, ...h16_Regular}}>
          Enter Your Comment
        </Text>
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            navigation.pop();
          }}>
          <Image source={redCloseIcon} style={{height: 20, width: 20}} />
        </TouchableOpacity>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderColor: '#0095C9',
          borderRadius: 4,
          marginTop: 15,
        }}>
        <TextAreaInput
          style={{
            ...h14_AvenirNext,
            color: 'rgba(56, 56, 56, 0.7)',
            // marginTop: 15,
            // marginBottom: 20,
          }}
          minHeight={80}
          maxHeight={80}
          variant={'filled'}
          placeholder={'Your Comment'} //"Additional information for clinician"
          value={comment}
          onChangeText={(value) => handleChange(value)}
        />
      </View>
      <Text style={{...h12_Medium, color: 'red', marginTop: 10}}>{error}</Text>

      <TouchableOpacity
        style={{
          cursor: 'pointer',
          marginTop: 30,
          width: 300,
          alignSelf: 'center',
        }}
        onPress={() => {
          if (!loading) {
            handleOnSubmit();
          }
        }}>
        <GradientButton
          label="Submit"
          loading={loading}
          indicatorColor="#fff"
        />
      </TouchableOpacity>
    </View>
  );
}

export default AddComment;
