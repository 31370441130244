import ContactSupportForm from './ContactSupportForm';
import ContactTab from './ContactTab'
export default {
  'contact-support': {
    screen: ContactTab,
  },
  "contact-support-form": {
    screen:ContactSupportForm ,
    expanded:true
  }
};
