import React from 'react';
import {View, Text} from '../../../app-components';
import {resolvePatientName} from '../../../app-components/patientUtility';
import {resolveDoctorName} from '../../../app-components/DoctorUtility';
import {Table} from '../../../app-components/table/Table';
import {h12_Medium, h14_Medium} from '../../../theme/fonts';
import {patientWatingRoomCard} from '../../../theme/shadows';
import {serialNumber} from '../rpmUtils';
import {getUser} from '../../../AppServices';

const ActivityLog = (props) => {
  const tableRow = {
    columns: [
      {
        width: 50,
        render: serialNumber,
      },
      {
        header: 'Date | time',
        type: 'date',
        field: '_createdOn',

        width: 200,
        format: 'DD MMM, hh:mm A',
      },
      {
        header: 'Reviewed By',
        type: 'text',
        field: '_createdBy.name',
        width: 200,
      },
      {
        header: 'Comment',
        type: 'text',
        field: 'comment',
        // width: 150,
      },
      // {
      //   header: 'Payee Name',
      //   type: 'text',
      //   field: (data) => {
      //     console.log(`>@>Puru ~ file: ActivityLog.js ~ line 43 ~ data`, data);
      //     return (
      //       resolvePatientName(data?.appointment?.patient_id) ||
      //       resolveDoctorName(data?.appointment?.patient_id)
      //     );
      //   },
      //   width: 180,
      // },
      // {
      //   header: 'Status',
      //   type: 'text',
      //   field: 'status',
      //   width: 150,
      // },
      // {
      //   header: 'Transaction ID',
      //   type: 'text',
      //   field: 'transaction_id',
      // },
    ],
  };

  let uri = (props) => {
    const {navigation} = props;
    const {state: {params: {item: {_id} = {}} = {}} = {}} = navigation || {};
    const {organization} = getUser() || {};
    return {
      query: {
        id: 'patientAttendingHistoryList',
        addOnFilter: {
          organization,
          patient: {_id},
        },
        sort: {_id: -1},
      },
      model: 'PatientAttendingHistory',
    };
  };

  return (
    <View
      style={{
        minHeight: 75,
        ...patientWatingRoomCard,
        marginTop: 10,
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
        // maxHeight:200,
      }}>
      <Text
        style={{
          ...h14_Medium,
          lineHeight: 21,
          letterSpacing: 0.57,
          numberOfLines: 1,
          margin: 8,
        }}>
        Review History
      </Text>
      <Table uri={uri} {...tableRow} {...props} reloadEvent={'reloadReview'} />
    </View>
  );
};

export default ActivityLog;
