import SurgicalHistoryForm from './SurgicalHistoryForm';
import {EditSurgicalHistory} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Surgical History',
  actions: [cancelButton(), save()],
};

export default SurgicalHistoryForm({uri: EditSurgicalHistory, header});
