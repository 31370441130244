const addLink = ({link, actionFrame, navigation, onModalShow}) => {
  let extraModalProps = {
    position: void 0,
    parentDisplay: true,
    noBackdrop: true,
    onModalShow,
  };
  if (actionFrame) {
    extraModalProps.parentFrame = actionFrame;
  }
  link = {
    ...link,
    modal: true,
    modalProps: {
      ...extraModalProps,
      ...link.modalProps,
    },
  };
  navigation.push(link);
};

export default (props = {}) => {
  let {action: {link} = {}, actionRef, ...restProps} = props;
  if (typeof link === 'function') {
    link = link(props);
  }
  if (!link) {
    return;
  }
  if (actionRef && actionRef.measure) {
    actionRef.measure((fx, fy, width, height, px, py) => {
      let actionFrame = {x: px, y: py, w: width, h: height};
      addLink({...restProps, link, actionFrame});
    });
  } else {
    addLink({...restProps, link});
  }
};
