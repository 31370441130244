import {manazeV5Colors} from './manazeV5Colors';
import {Headline8} from './Headlines';

const {
  neutral_500_medium_surface,
  neutral_300_low_surface,
  secondary_300_main,
  neutral_200,
  secondary_100,
  secondary_050,
  primary_2
} = manazeV5Colors;

export const radioGroupTheme = {
  optionStyle: {paddingRight: 8},
  labelStyle: {
    paddingLeft: 8,
    ...Headline8,
    color: neutral_500_medium_surface,
  },
  disabledColor: neutral_300_low_surface,
  activeDisabledColor: secondary_100,
  color: neutral_500_medium_surface,
  activeColor: primary_2,
  hoverColor: neutral_200,
  activeHoverColor: secondary_050,
};
export const radioTheme = {
  optionStyle: {paddingRight: 8},
  labelStyle: {paddingLeft: 8, ...Headline8, color: neutral_500_medium_surface},
  disabledColor: neutral_300_low_surface,
  activeDisabledColor: secondary_100,
  color: neutral_500_medium_surface,
  activeColor: secondary_300_main,
  hoverColor: neutral_200,
  activeHoverColor: secondary_050,
};
