import Clinics from './Clinics';
import AddClinic from './AddClinic';
import clinicDetails from './clinicDetails';

export default {
  clinics: {
    screen: Clinics,
  },
  'add-clinic': {
    screen: AddClinic,
    expanded: true,
  },
  ...clinicDetails,
};
