import {colors, bgs} from './colors';
import {h14_SemiBold, h14_Medium} from './fonts';

const {textWhite, textColor70, themeColor} = colors;
const {actionBgColor, actionBg2} = bgs;

const saveButtonTheme = {
  containerStyle: {
    minWidth: 100,
    minHeight: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: themeColor,
  },
  labelStyle: {
    ...h14_SemiBold,
    color: textWhite,
  },
};

const cancelButtonTheme = {
  containerStyle: {
    minWidth: 100,
    minHeight: 38,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: actionBg2,
  },
  labelStyle: {
    ...h14_Medium,
    color: textColor70,
  },
};

export {saveButtonTheme, cancelButtonTheme};
