import {colors, bgs} from './colors';
import {
  h20_Regular,
  h22_SemiBold,
  h10_Regular,
  h11_Regular,
  h16_Medium,
  h12_SemiBold,
  h30_Regular,
  h14_Medium,
  h12_Medium,
  h18_Medium,
  h14_Regular,
} from './fonts';
let {
  backTransparent,
  greenColor,
  lightGrayColor,
  darkBlackColor,
  darkGray,
  dashboardLiveCardBg7,
  paleBlueLight,
  darkGreen,
  blueColor,
} = bgs;
let {
  redTextColor,
  textColor87,
  textColor70,
  textColor54,
  lightgreenTextColor,
  darkTextColor,
  themeColor,
} = colors;

const addScheduleStyle = {
  timeSelectorStyle: {
    backgroundColor: '#f7f8fa',
    marginTop: 4,
    marginBottom: 4,
  },
  inputStyle: {
    textAlign: 'center',
    outline: 'none',
    width: 90,
    height: 32,
    border: 0,
    paddingLeft: 5,
    color: textColor87,
    backgroundColor: '#f7f8fa',
    borderRadius: 4,
    ...h14_Medium,
  },
  addSlotTextStyle: {paddingRight: 5, color: lightgreenTextColor},
  getRowContainerStyle: {
    borderBottomColor: lightGrayColor,
    borderBottomWidth: 1,
  },
  removeTextStyle: {...h30_Regular, color: redTextColor},
  addTextStyle: {...h30_Regular, color: darkGreen},

  removeRowStyle: {
    alignItems: 'center',
    width: 28,
    height: 28,
    // borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: 10,
  },
  addRowStyle: {
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: paleBlueLight,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  containerStyle: {
    flex: 1,
  },
  scrollViewStyle: {flex: 1},
  renderContainerTop: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 10,
  },
  addScheduleTextStyle: {
    ...h22_SemiBold,
    paddingBottom: 5,
    color: darkBlackColor,
  },
  nameTextStyle: {...h20_Regular, width: 150, numberOfLines: 1},
  teacherViewStyle: {flexDirection: 'row', alignItems: 'center'},
  buttonSheduleStyle: {
    ...h14_Medium,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 12,
    paddingTop: 12,
    backgroundColor: themeColor,
    borderRadius: 4,
    cursor: 'pointer',
    color: textColor70,
  },
  buttonCancelStyle: {
    ...h14_Medium,
    paddingLeft: 37,
    paddingRight: 37,
    paddingBottom: 11,
    paddingTop: 12,
    backgroundColor: '#f0f0f7',
    borderRadius: 4,
    cursor: 'pointer',
    color: textColor70,
  },
  buttonSaveStyle: {
    ...h12_SemiBold,
    paddingLeft: 44,
    paddingRight: 43,
    paddingBottom: 11,
    paddingTop: 12,
    minWidth:117,
    backgroundColor:themeColor,
    // background: 'linear-gradient(0.25turn, #4de49f, #73c5a7)',
    marginLeft: 24,
    borderRadius: 4,
    cursor: 'pointer',
  },
  centerHeaderStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingTop: 15,
    // paddingBottom: 10,
    borderBottomColor: lightGrayColor,
    borderBottomWidth: 1,
    paddingRight: 12,
  },
  centerHeaderTextStyle: {...h12_Medium, color: textColor54},
  weekStyle: {...h18_Medium, color: darkTextColor, paddingBottom: 5},
  weekTextStyle: {...h20_Regular, paddingBottom: 5, color: darkGray},
  planedTextStyle: {...h14_Medium, color: textColor54},
  teacherTextStyle: {...h11_Regular},
};

const nonEditScheduleStyle = {
  startStyle: {
    color: textColor87,

    ...h14_Regular,
  },
  addSlotTextStyle: {paddingRight: 5, color: lightgreenTextColor},
  getRowContainerStyle: {
    // flexDirection: 'row',
    flex: 1,
    borderBottomColor: lightGrayColor,
    borderBottomWidth: 1,
    // paddingBottom: 10,
    // paddingRight: 20,
  },
  removeTextStyle: {...h30_Regular, color: redTextColor},
  addTextStyle: {...h30_Regular, color: darkGreen},

  removeRowStyle: {
    alignItems: 'center',
    width: 28,
    // height: 28,
    // borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: 10,
  },
  addRowStyle: {
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: paleBlueLight,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  containerStyle: {
    flex: 1,
  },
  scrollViewStyle: {flex: 1},
  renderContainerTop: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 10,
  },
  addScheduleTextStyle: {
    ...h22_SemiBold,
    paddingBottom: 5,
    color: darkBlackColor,
  },
  nameTextStyle: {...h20_Regular, width: 150, numberOfLines: 1},
  teacherViewStyle: {flexDirection: 'row', alignItems: 'center'},
  buttonSheduleStyle: {
    ...h14_Medium,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 12,
    paddingTop: 12,
    backgroundColor: blueColor,
    borderRadius: 4,
    cursor: 'pointer',
    color: textColor70,
  },
  buttonCancelStyle: {
    ...h14_Medium,
    paddingLeft: 37,
    paddingRight: 37,
    paddingBottom: 11,
    paddingTop: 12,
    backgroundColor: '#f0f0f7',
    borderRadius: 4,
    cursor: 'pointer',
    color: textColor70,
  },
  buttonSaveStyle: {
    ...h12_SemiBold,
    paddingLeft: 44,
    paddingRight: 43,
    paddingBottom: 11,
    paddingTop: 12,
    background: 'linear-gradient(0.25turn, #4de49f, #73c5a7)',
    marginLeft: 24,
    borderRadius: 4,
    cursor: 'pointer',
  },
  centerHeaderStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingTop: 15,
    // paddingBottom: 10,
    borderBottomColor: lightGrayColor,
    borderBottomWidth: 1,
    paddingRight: 12,
  },
  centerHeaderTextStyle: {...h12_Medium, color: textColor54},
  weekStyle: {...h18_Medium, color: darkTextColor, paddingBottom: 5},
  weekTextStyle: {...h20_Regular, paddingBottom: 5, color: darkGray},
  planedTextStyle: {...h14_Medium, color: textColor54},
  teacherTextStyle: {...h11_Regular},
};

export {addScheduleStyle, nonEditScheduleStyle};
