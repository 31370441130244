import React from 'react';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import {
  FilterByVillage,
  FilterVillageByBlock,
  FilterVillageByDistrict,
} from '../../../app-components/filter/MayaMDFilters';
import TabNavigator from '../../../app-components/tab/TabNavigator';
import villageList from './villageList';
import VillageSubCenter from './villageSubCenterList';

export default TabNavigator({
  tabs: {
    villages: {
      label: 'Village List',
      reloadEvent: 'reloadSpeciality',
      screen: villageList,
      actions: [FilterVillageByDistrict, FilterVillageByBlock],
    },
    subCenters: {
      label: 'Village Sub-Center',
      reloadEvent: 'reloadSpeciality',
      screen: VillageSubCenter,
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Village Center'} />;
          },
          type: 'link',
          link: {
            view: 'addVillageSubCenterModal',
            modal: true,
            modalProps: {
              autoHide: true,
              screenCenterStyle: {
                borderRadius: 10,
                width: '30%',
              },
              style: {},
            },
          },
        },
      ],
    },
  },
});
