import React, { useCallback, useState } from 'react';

import {cancelButton} from '../../app-components/action/CloseAction';
import {getAgeFromDob} from '../../app-components/UtilityFunctions';
import {autoSuggestInput, searchInput} from '../../autoSuggestions';
import Form from '../../app-components/form/Form';
import {invoke, submit} from '../../AppServices';
import PatientList from './Patients';
import {isArray, omit} from 'lodash';
import { Snackbar, Text, TouchableOpacity, View } from '../../app-components';
import { GradientButton } from '../../app-components/buttons/Buttons';
import { h13_SemiBold, h14_Regular } from '../../theme/fonts';

// const joinInput = ({input, field}) => {
//   let modifiyInput =
//     Array.isArray(input) &&
//     input.map((input_item) => {
//       return input_item[field] || '';
//     });
//   return (Array.isArray(modifiyInput) && modifiyInput.join(', ')) || '';
// };

const MedicalConditions = ({ careProgram }) => {
  return (
    <View>
      <Text style={{ ...h13_SemiBold, color: '#7c7c7c' }}>Medical Conditions</Text><br />
      <View style={{
        flexDirection: 'row',
        margin: 4,
      }}>
        {
          careProgram?.map((item) => {
            return (
              <Text style={{
                backgroundColor: '#f2f2f2',
                border: '1px solid #efefef',
                borderRadius: 8,
                padding: '6px 8px 5px',
                marginRight: 4,
                ...h14_Regular,
              }}>{item?.chronicDisease[0]?.name || ""}</Text>
            )
          })
        }
      </View>
    </View>
  )
}

const CareProgramName = ({ careProgram }) => {
  return (
    <View>
      <Text style={{ ...h13_SemiBold, color: '#7c7c7c' }}>Care Program Name</Text><br />
      <View style={{
        flexDirection: 'row',
        margin: 4,
      }}>
        {
          careProgram.map((item) => {
            let result;
            if (
              typeof item?.careProgram?.name === 'string' &&
              item?.status === 'Active'
            ) {
              result = item?.careProgram?.name.trim();
            }
            return (
              <Text style={{
                backgroundColor: '#f2f2f2',
                border: '1px solid #efefef',
                borderRadius: 8,
                padding: '6px 8px 5px',
                marginRight: 4,
                ...h14_Regular,
              }}>{result || ""}</Text>
            )
          })
        }
      </View>
    </View>
  )
}

const DetailPatient = (props) => {
  const {navigation} = props;
  const {state: {params: {item: {isNoteGenerated}}}} = navigation;
  const [isLoading, setIsLoading] = useState(false);

  let header = {
    title: 'Member Detail',
    titleTextStyle: {
      color: 'rgba(56, 56, 56, 0.87)',
      fontSize: 20,
      marginLeft: 10,
      fontFamily: 'AvenirNext-Regular',
    },
    
    actions: [
      isNoteGenerated && {
        render: () => {
          return (
            <TouchableOpacity
              onPress={() => handleRegenrateNotes(props)}
              style={{cursor: 'pointer', height: 38}}>
              <GradientButton
                color="#0095c9"
                label={'Regenrate Notes'}
                loading={isLoading}
              />
            </TouchableOpacity>
          );
        },
      },
      cancelButton({label: ' Back '})],
  };

  const handleRegenrateNotes = useCallback((props) => {
    setIsLoading(true)
      const {navigation} = props;
      const {state: {params: {item: {_id}}}} = navigation;
      invoke({
        id: 'regenerateNote',
        paramValue: {
          _id,
        },
      })
        .then((res) => {
          Snackbar.show({
            text: 'Note Generated Successfully',
            duration: Snackbar.LENGTH_LONG,
          });
          setIsLoading(false)
          navigation.goBack();
        })
        .catch((error) => {
          Snackbar.show({
            text: error,
            duration: Snackbar.LENGTH_LONG,
          });
          setIsLoading(false)
        });
    }, []);

  const editable = false;

  const ModifiedForm = Form({
    ...props,
    reloadEvent: 'reloadPatient',
    reloadOnChangeEvent: 'reloadPatient',
    type: 'standardShadow',
    closeView: 1,
    fieldVariant: 'filled',
    validateOnChange: true,
    onSubmit: submit({
      model: 'Patients',
    }),

    header: header,
    defaultValues: ({navigation}) => {
      const {state: {params: {item = {}} = {}} = {}} = navigation || {};
      return {
        userType: 'Patient',
        ...item,
      };
    },
    formGroups: [
      {
        editable: editable,
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'text',
                label: 'First Name',
                field: 'first_name',
              },
              {
                type: 'text',
                label: 'Last Name',
                field: 'last_name',
              },
              {
                type: 'date',
                label: 'Date Of Birth',
                field: 'birthDate',
              },
              {
                type: 'text',
                label: 'Gender',
                field: (data) => {
                  let {gender} = data || {};
                  if (gender) {
                    return gender[0].toUpperCase() + gender.slice(1);
                  }
                },
              },
              {
                type: 'text',
                label: 'Age',
                field: (data) => {
                  let {birthDate} = data || {};
                  return birthDate ? getAgeFromDob(birthDate) : '';
                },
              },
              {
                type: 'text',
                label: 'Phone Number',
                field: 'mobile',
              },
              {
                type: 'text',
                label: 'Family Id',
                field: 'familyId',
              },
              {
                type: 'text',
                label: 'Member Id',
                field: 'patientId',
              },
              {
                type: 'text',
                label: 'Aadhar number',
                field: 'aadharNumber',
              },
              {
                type: 'text',
                label: 'MHIS Number',
                field: 'mhis',
                moreInputStyle: { textTransform: 'uppercase' },
              },
              {
                type: 'text',
                label: 'Assigned ANM',
                field: 'assignedANM.name',
              },
              autoSuggestInput({
                label: 'District',
                placeholder: 'Select District',
                field: 'district',
                valueField: 'name',
                suggestionField: 'name',
                model: 'Districts',
                query: 'districtList',
                keyField: '_id',
                mandatory: true,
              }),
              autoSuggestInput(
                {
                  label: 'Block',
                  placeholder: 'Select Block',
                  field: 'block',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: 'Blocks',
                  query: 'blockList',
                  keyField: '_id',
                  mandatory: true,
                },
                {
                  addOnFilter: ({data}) => ({
                    district: {_id: data?.district?._id},
                  }),
                },
              ),
              searchInput(
                {
                  label: 'Village',
                  placeholder: 'Select Village',
                  field: 'village',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: 'Villages',
                  query: 'villageList',
                  keyField: '_id',
                  mandatory: true,
                  searchField: 'name',
                },
                {
                  addOnFilter: ({data}) => ({block: {_id: data?.block?._id}}),
                },
              ),
              autoSuggestInput({
                label: 'How did you know about PPHM?',
                valueField: 'label',
                suggestionField: 'label',
                field: (data) => {
                  if (
                    data?.socialMediaPlatform ===
                    'Others'
                  ) {
                    return data?.otherSocialMediaPlatform;
                  }
                  return data?.socialMediaPlatform;
                },
              }),
            ],
          },
        ],
      },
      // isArray(props?.navigation?.state?.params?.item?.careProgram) && {
      //   label: "Care Program",
      //   expandable: true,
      //   color: 'red',
      //   groups: [
      //     {
      //       columns: [
      //         {
      //           type: 'custom',
      //           render: ({ data }) => {
      //             let { careProgram = [] } = data || {};
      //             return (
      //               <CareProgramName careProgram={careProgram} />
      //             )
      //           }
      //         },
      //         {
      //           type: 'custom',
      //           render: ({ data }) => {
      //             let { careProgram = [] } = data || {};
      //             return (
      //               <MedicalConditions careProgram={careProgram} />
      //             )
      //           }
      //         },
      //       ],
      //     }
      //   ],
      // },
      {
        label: 'Family Members',
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                type: 'custom',
                render: (props) => {
                  const clonedProps = omit(props, 'data');
                  return (
                    <PatientList
                      {...clonedProps}
                      addOnFilter={{familyId: props?.data?.familyId}}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default DetailPatient;
