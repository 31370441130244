import React from 'react';
import {View} from '../../../app-components';
import Form from '../../../app-components/form/Form';
import {
  autoSuggestOptionsInput,
  multiSearchInput,
  searchInput,
} from '../../../autoSuggestions';
import {submit} from '../../../AppServices';
import {closeButton, getNewId} from '../rpmUtils';

export default ({uri, header, editable = true, iconEditable}) =>
  Form({
    closeView: 1,
    type: 'standardShadow',
    onSubmit: submit({
      model: 'Devices',
    }),
    uri,
    fieldVariant: 'filled',
    fieldContainer: 'topLabel',
    mandatory: {
      name: 1,
      device_id: 1,
      // chronicDisease: 1,
      monitoringParameter: {
        parameter: 1,
        unitMeasurement: 1,
      },
    },
    validateOnChange: true,
    computations: {
      children: {
        monitoringParameter: {
          self: {
            empty_text_field: {
              compute: (data) => {
                return {
                  set: {
                    unitMeasurement: {},
                  },
                };
              },
              onChange: ['parameter'],
            },
          },
        },
      },
    },
    header: header,
    defaultValues: (props) => {
      return {monitoringParameter: [{_id: getNewId()}]};
    },
    reloadEvent: 'deviceReload',
    reloadOnChangeEvent: 'deviceReload',
    formGroups: [
      {
        direction: 'row',
        groups: [
          {
            columns: [
              {
                minHeight: 150,
                type: 'uploadImage',
                label: 'Device Image',
                field: 'image',
                editable: editable,
              },
            ],
          },
          {
            columnsPerRow: 1,

            columns: [
              autoSuggestOptionsInput({
                label: 'Vendor Name',
                field: 'vendor',
                suggestionField: 'label',
                valueField: 'value',
                options: [{label: 'Tenovi', value: 'Tenovi'}],
                editable: editable,
                width: '93.5%',
              }),
              {
                type: 'text',
                label: 'Device Name',
                field: 'name',
                width: '93.5%',
                editable: editable,
              },
              {
                type: 'text',
                label: 'Device Id',
                field: 'device_id',
                editable: editable,
                width: '93.5%',
              },
              multiSearchInput({
                label: 'Chronic Disease',
                field: 'chronicDisease',
                placeholder: 'Select Disease',
                query: 'diagnoseLists',
                model: 'Diagnosis',
                valueField: 'name',
                suggestionField: 'name',
                searchField: 'name',
                idField: '_id',
                width: '93.5%',
                visible: false,
                editable: editable,
              }),
            ],
          },
        ],
      },

      {
        columns: [
          {
            type: 'nestedList',
            field: 'monitoringParameter',
            addInBottom: true,
            formGroupHeaderStyle: {marginLeft: 10},
            label: 'Monitoring Parameter',
            footer: {
              addLabel: iconEditable ? 'ADD' : void 0,
            },
            formGroups: [
              {
                columnsPerRow: 3,
                fieldVariant: 'filled',
                groups: [
                  {
                    columns: [
                      searchInput({
                        label: 'Parameter',
                        field: 'parameter',
                        placeholder: 'Select Parameter',
                        query: 'parameterList',
                        model: 'MonitoringParameter',
                        valueField: 'name',
                        suggestionField: 'name',
                        searchField: 'name',
                        editable: editable,
                      }),
                      searchInput(
                        {
                          label: 'Unit Measurement',
                          field: 'unitMeasurement',
                          placeholder: 'Select Unit',
                          query: 'unitMeasurementList',
                          model: 'UnitMeasurements',
                          valueField: 'name',
                          suggestionField: 'name',
                          searchField: 'name',
                          editable: editable,
                        },
                        {
                          addOnFilter: ({data}) => {
                            let {
                              parameter: {
                                unitMeasurement: {_id},
                              },
                            } = data;
                            return {
                              _id,
                            };
                          },
                        },
                      ),

                      {
                        width: 30,
                        render: (props) => {
                          const {index} = props;
                          if (index === 0 || !iconEditable) {
                            return <View />;
                          }
                          return closeButton(props);
                        },
                      },
                      {
                        type: 'custom',
                        width: '100%',
                        render: () => (
                          <View
                            style={{
                              height: 20,
                              borderBottomWidth: 1.5,
                              borderBottomColor: '#f2eeed',
                            }}
                          />
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });
