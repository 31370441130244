import todoForm from './todoForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit To-Do Task',
  actions: [cancelButton(), save()],
};

let uri = ({navigation}) => {
  return {
    query: {
      id: 'todoList',
      relationValue: {
        model: 'Todo',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Todo',
  };
};
export default todoForm({uri, editable: false, header});
