import React, {memo} from 'react';

import Table from '../../../app-components/table/Table';
import PatientDetails from '../PatientDetails';
import {getAgeFromDob} from '../../../app-components/UtilityFunctions';
import {getUser} from '../../../AppServices';
import {roles} from '../../../Lib/constants';

const checkAlgoType = (noteGenerated) => {
  let result = false;
  noteGenerated.filter((noteData) => {
    if (!noteData.algo_type) {
      result = true;
    } else if (noteData.algo_type === 'Symptom Checker') {
      result = true;
    }
  });
  return result;
};

const uri = (props) => {
  const {navigation} = props;
  const {state: {params: {item = {}} = {}} = {}} = navigation;
  const {userType = '', _id: userId} = getUser() || {};
  let addOnFilter = {};
  if (item) {
    addOnFilter = {
      familyId: item.familyId,
      _id: {$nin: [item?._id]},
    };
  }
  if (userType === roles.ANM) {
    addOnFilter = {...addOnFilter, assignedANM: {_id: userId}};
  } else if (userType === roles.DOCTOR) {
    addOnFilter = {...addOnFilter, my_doctors: {_id: {$in: [userId]}}};
  }
  return {
    query: {
      id: 'patientLists',
      addOnFilter: addOnFilter,
      sort: {_id: -1},
      skipAggregates: false,
    },
    model: 'Patients',
  };
};

const AssignedMemberList = Table({
  uri,
  reloadEvent: 'reloadPatient',
  reloadOnChangeEvent: 'reloadPatient',
  action: ({item}) => {
    return {
      type: 'link',
      link: {
        view: 'detailPatientForm',
        params: {item},
      },
    };
  },
  columns: [
    {
      type: 'selection',
      field: '_id',
      visible: (data) => !data?.navigation?.state?.params?.item,
    },
    {
      render: ({item}) => {
        return <PatientDetails item={item} showTitle />;
      },
      header: 'Member Details',
      width: 220,
    },
    {
      header: 'Age',
      type: 'text',
      field: (data) => {
        let {birthDate} = data || {};
        return birthDate ? getAgeFromDob(birthDate) : '';
      },
      width: 100,
      sortable: 'birthDate',
      reverseSort: true,
    },
    {
      header: 'Family Id',
      type: 'text',
      field: 'familyId',
      sortable: 'familyId',
      reverseSort: true,
    },
    {
      header: 'Member Id',
      type: 'text',
      field: 'patientId',
      sortable: 'patientId',
      reverseSort: true,
    },
    {
      header: 'Aadhar',
      type: 'text',
      field: 'aadharNumber',
    },
    {
      header: 'Care Program',
      type: 'text',
      field: (data) => {
        let {careProgram = []} = data || {};
        let rpmProgramListName = [];
        (careProgram || []).map((item) => {
          if (
            typeof item?.careProgram?.name === 'string' &&
            item?.status === 'Active'
          ) {
            rpmProgramListName.push(item?.careProgram?.name.trim());
            return item?.careProgram?.name.trim();
          }
          return '';
        });
        return rpmProgramListName.join(', ');
      },
      // filter: {
      //   type: 'autoSuggest',
      //   field: 'careProgram.careProgram',
      //   fetch: autosuggestFetch({
      //     id: 'careProgramList',
      //     sort: { isDefault: -1 },
      //     model: 'CarePrograms',
      //     search: 'name',
      //     addOnFilter: ({ user }) => {
      //       const { organization } = user;
      //       return {
      //         $or: [{ isDefault: true }, { organization }],
      //       };
      //     },
      //   }),
      //   placeholder: 'Search Care Program',
      //   sugestionField: 'name',
      //   valueField: 'name',
      //   searchField: 'name',
      //   searching: true,
      // },
    },
    {
      header: 'Registered On',
      type: 'date',
      field: '_createdOn',
    },
    {
      type: 'moreActions',
      actions: [
        {
          text: 'View Notes',
          visible: ({item: {noteGenerated = []}}) =>
            !!noteGenerated?.length && checkAlgoType(noteGenerated),
          onPress: async (props) => {
            const {item, navigation} = props;
            navigation.push({
              view: 'viewNoteSelectLanguageModal',
              params: {patientId: item?._id},
              modal: true,
              modalProps: {
                autoHide: true,
                screenCenterStyle: {
                  borderRadius: 10,
                },
                style: {},
              },
            });
          },
        },
      ],
    },
  ],
});

export default memo(AssignedMemberList);
