import {getUser} from '../../AppServices';
import {
  clinicAdminRoutes,
  doctorRoutes,
  frontDeskRoutes,
  patientRoutes,
  publicRoutes,
  superAdminRoutes,
  medicalAssistantRoutes,
  dialysisAdminRoutes,
  nurseRoutes,
} from './Routes';

const decodeAction = ({path, action}) => {
  if (!action) {
    return path;
  }
  if (typeof action === 'string') {
    path += ':' + action;
    return path;
  } else if (action.view) {
    path += ':' + action.view;
    return decodeAction({path, action: action.action});
  }
};
export const getPathName = () => {
  let {pathname, hash, search} =
    typeof window !== undefined ? window.location : {pathname: '/'};
  return {pathName: pathname};
};
export const setPathName = ({pathName, replace, remove}) => {
  let {pathname: currentPathName, hash, search} =
    typeof window !== undefined ? window.location : {pathname: '/'};
  let history = window.history;
  if (history && currentPathName !== pathName) {
    if (remove) {
      let removeCount = 1;
      if (typeof remove === 'number') {
        removeCount = 1;
      }
      history.go(removeCount);
    } else if (replace) {
      history.replaceState({}, '', pathName);
    } else {
      history.pushState({}, '', pathName);
    }
  }
};
export const setLocationView = ({view, action}) => {
  let path = '/';
  if (view && typeof view === 'string' && view !== 'choose-user') {
    path += view;
  }
  // if (view && typeof view === 'string' && view !== 'clinic-signup') {
  //     path += view;
  //   }
  path = decodeAction({path, action});
  let {pathname: currentPathname, search: currentSearch} = window.location;
  let history = window.history;
  if (history && currentPathname !== path) {
    history.pushState({}, '', path);
  }
};
export const getLocationView = ({pathName}) => {
  let slashIndex = pathName.indexOf('/');
  if (slashIndex >= 0) {
    pathName = pathName.substring(slashIndex + 1);
  }
  if (!pathName) {
    return void 0;
  }
  let actions = pathName.split(':');
  if (actions && actions.length) {
    let view = {
      view: actions[0],
    };
    if (actions.length > 1) {
      let lastViewAction = view;
      for (let index = 1; index < actions.length; index++) {
        lastViewAction.action = {
          view: actions[index],
        };
        lastViewAction = lastViewAction.action;
      }
    }
    return view;
  }
};
export const getUserWiseRoute = (pathname) => {
  let {userType} = getUser() || {};
  if (publicRoutes.includes(pathname)) {
    return true;
  } else if (userType === 'Doctor' && doctorRoutes.includes(pathname)) {
    return false;
  } else if (userType === 'SuperAdmin' && superAdminRoutes.includes(pathname)) {
    return false;
  } else if (
    userType === 'ClinicAdmin' &&
    clinicAdminRoutes.includes(pathname)
  ) {
    return false;
  } else if (userType === 'Patient' && patientRoutes.includes(pathname)) {
    return false;
  } else if (userType === 'FrontDesk' && frontDeskRoutes.includes(pathname)) {
    return false;
  } else if (userType === 'MedicalAssistant' && medicalAssistantRoutes.includes(pathname)) {
    return false;
  }else if (userType === 'DialysisAdmin' && dialysisAdminRoutes.includes(pathname)) {
    return false;
  }else if (userType === 'Nurse' && nurseRoutes.includes(pathname)) {
    return false;
  }

  return true;
};

export const getDefaultLocationView = (view) => {
  let {pathname, hash, search} =
    typeof window !== undefined ? window.location : {pathname: '/'};
  if (getUserWiseRoute(pathname)) {
    return view;
  }
  if (
    pathname === '/login' ||
    pathname === '/otp' ||
    pathname === '/passcode' ||
    pathname === '/authenticator'
  ) {
    pathname = '/';
  }

  let locationView = getLocationView({pathName: pathname}) || view;
  return locationView;
};
