import {colors, bgs} from './colors';
import {dropDownShadow} from './shadows';
import {
  h10_Regular,
  h12_Regular,
  h12_SemiBold,
  h10_SemiBold,
  h8_Regular,
} from './fonts';

const {yellowColor, whiteBg, transparent, lightGray, chatBg, GrayColor} = bgs;

const {
  textColor87,
  textColor37,
  errorColor,
  textColor70,
  secondaryColor,
  activeColor,
  textWhite,
  dodgerBlue,
} = colors;

const notificationStyle = {
  imageStyle: {
    width: 20,
    height: 20,
    marginRight: 20,
  },
  notificationViewStyle: {
    ...dropDownShadow,
    position: 'absolute',
    top: -10,
    right: -10,
    height: 15,
    minWidth: 15,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    backgroundColor: whiteBg,
  },
  notificationTextStyle: {...h10_Regular, numberOfLines: 1, color: activeColor},
};

const notificationHeaderStyle = {
  container: {
    borderwidth: 1,
    paddingTop: 0,
    marginRight: 30,
  },
  imageStyle: {
    height: 20,
    width: 20,
    objectFit: 'contain',
  },
  footerViewStyle: {
    backgroundColor: whiteBg,
    alignItems: 'center',
    paddingTop: 10,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    paddingBottom: 10,
  },
  footerTextStyle: {
    color: dodgerBlue,
    ...h10_Regular,
  },
  headerContainerStyle: {
    flexDirection: 'row',
    backgroundColor: GrayColor,
    alignItems: 'center',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  headerViewStyle: {
    paddingLeft: 5,
    paddingBottom: 10,
    paddingTop: 10,
    flex: 1,
  },
  headerTextStyle: {
    color: textWhite,
    ...h10_Regular,
  },
  headerCrossStyle: {
    paddingLeft: 5,
    paddingBottom: 12,
    paddingTop: 12,
    paddingRight: 5,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    backgroundColor: whiteBg,
    alignItems: 'flex-end',
  },
  modalConatinerTip: {
    width: 0,
    height: 0,
    borderTopWidth: 8,
    borderBottomWidth: 8,
    borderRightWidth: 8,
    borderLeftWidth: 8,
    borderRightColor: whiteBg,
    borderTopColor: whiteBg,
    borderBottomColor: GrayColor,
    borderLeftColor: whiteBg,
    marginRight: 15,
    alignSelf: 'flex-end',
  },
  modalContainer: {
    borderRadius: 5,
    ...dropDownShadow,
    backgroundColor: whiteBg,
  },
};

export {notificationStyle, notificationHeaderStyle};
