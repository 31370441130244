import {EditSearchDevice} from '../../../Queries';
import {save} from '../../../app-components/action/SaveAction';
import {cancelButton} from '../../../app-components/action/CloseAction';
import SearchDeviceForm from './SearchDeviceForm';
import {getUser} from '../../../AppServices';

let header = {
  title: 'Edit Device',
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },
  actions: [
    cancelButton({label: '< Back '}),
    save({
      visible: (props) => {
        let {userType} = getUser();
        return userType === 'SuperAdmin' ? true : false;
      },
    }),
  ],
};

// const editable = false;
const editable = (props) => {
  let {userType} = getUser();
  if (userType === 'SuperAdmin') {
    return true;
  }
  return false;
};
let iconEditable = true;

export default SearchDeviceForm({
  uri: EditSearchDevice,
  header,
  editable: true,
  iconEditable,
});
