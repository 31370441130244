import React from 'react';
import Form from '../../app-components/form/Form';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {submitByQuery} from '../../AppServices';
import {googlePlaceInput} from '../../autoSuggestions';
import {PLACEHOLDER} from '../../app-components/utils';
import {View} from '../login/components';
import {hospitalUpload} from '../../images';

const header = {
  title: 'Add Clinic',
  actions: [cancelButton(), save({label: 'Save'})],
};

export default Form({
  type: 'standardShadow',
  closeView: 1,
  reloadEvent: 'reloadClinic',
  onSubmit: submitByQuery({
    query_id: 'organizationWithFirstAdmin',
    lowerCaseFields: ['email'],
  }),

  // uri,
  mandatory: {organization_mobile: 1, mobile: 1},
  defaultValues: () => {
    return {
      status: 'pending',
    };
  },
  lg: {
    header,
    formGroups: [
      {
        // label: 'Organizations Details',
        fieldVariant: 'filled',
        direction: 'row',
        fieldContainer: 'topLabel',

        groups: [
          {
            width: 300,
            columns: [
              {
                type: 'uploadImage',
                field: 'organization_logo',
                label: 'Organization Logo',
                defaultImage: hospitalUpload,
                description: '*File should be image (Jpeg or png).',
              },
            ],
          },

          {
            columnsPerRow: 1,
            columns: [
              {
                type: 'text',
                field: 'email',
                label: 'Email',
                mandatory: true,
                placeholder: PLACEHOLDER,
              },
              {
                type: 'text',
                field: 'organization_name',
                label: 'Organization Name',
                mandatory: true,
                placeholder: PLACEHOLDER,
              },
              {
                type: 'mobile',
                field: 'organization_mobile',
                label: 'Organization Mobile',
                mandatory: true,
                placeholder: PLACEHOLDER,
              },
              {
                type: 'text',
                field: 'name',
                label: 'Admin Name',
                mandatory: true,
                placeholder: PLACEHOLDER,
              },
              {
                type: 'mobile',
                field: 'mobile',
                label: 'Admin Mobile',
                mandatory: true,
                placeholder: PLACEHOLDER,
              },
              {
                type: 'checkBox',
                field: 'is_emergency',
                label: 'Emergency Hospital',
                // mandatory: true,
                // placeholder: PLACEHOLDER,
              }
              // googlePlaceInput({
              //   field: 'organization_address',
              //   placeholder: 'Select Place',
              //   mapHeight: 300,
              // }),
            ],
          },
          {
            columns: [
              googlePlaceInput({
                field: 'organization_address',
                label: 'Organization Address',
                mapHeight: 300,
                placeholder: PLACEHOLDER,
                mandatory: true,
              }),
            ],
          },
        ],
      },
    ],
  },
  sm: {
    header: {
      title: 'Add Clinic',
    },
    footer: {actions: [cancelButton(), save({label: 'Save'})]},
    formGroups: [
      {
        // label: 'Organizations Details',
        fieldVariant: 'filled',
        fieldContainer: 'topLabel',
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                type: 'uploadImage',
                field: 'organization_logo',
                label: 'Organization Logo',
                defaultImage: hospitalUpload,
                description: '*File should be image (Jpeg or png).',
              },
              {
                type: 'text',
                field: 'email',
                label: 'Email',
                mandatory: true,
                placeholder: PLACEHOLDER,
              },
              {
                type: 'text',
                field: 'organization_name',
                label: 'Organization Name',
                mandatory: true,
                placeholder: PLACEHOLDER,
              },
              {
                type: 'text',
                field: 'organization_mobile',
                label: 'Organization Mobile',
                mandatory: true,
                placeholder: PLACEHOLDER,
                inputProps: {type: 'number'},
              },
              {
                type: 'text',
                field: 'name',
                label: 'Admin Name',
                mandatory: true,
                placeholder: PLACEHOLDER,
              },
              {
                type: 'text',
                field: 'mobile',
                label: 'Admin Mobile',
                mandatory: true,
                placeholder: PLACEHOLDER,
                inputProps: {type: 'number'},
              },
            ],
          },
          {
            columns: [
              googlePlaceInput({
                field: 'organization_address',
                label: 'Organization Address',
                mapHeight: 300,
                placeholder: PLACEHOLDER,
                mandatory: true,
              }),
            ],
          },
        ],
      },
    ],
  },
});
