import React from 'react';
import ImportAppBoard from './AddImport';
import AddImport from './AddImport';
import ImportDetail from './ImportDetail';
import ImportList from './ImportList';
import {GradientButton} from '../../app-components/buttons/Buttons';

export default {
  import: {
    screen: ImportAppBoard,
    reloadEvent: 'realodimport',
    expanded: true,
  },
  addImport: {
    screen: AddImport,
    expanded: true,
  },
  importDetail: {screen: ImportDetail, expanded: true},
  importList: {
    screen: ImportList,
    header: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Import'} />;
          },
          type: 'link',
          link: {
            view: 'addImport',
          },
        },
      ],
    },
  },
};
