import React, {useState, useEffect} from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  ActivityIndicator,
  isMobile,
} from '../../app-components';
import {
  BackgroundImage,
  MayaAiGirl,
  MotivHealthLogo,
  // StewardLogo,
} from '../../images';
// import {TextInput} from '../../app-components/input-components/Editors';
import {TextInput} from '../../npms/react-text-input';
import {mayamd_authenticate, mayaAISession, invoke} from '../../AppServices';
import {h14_Regular, h16_Regular} from '../../theme/fonts';
import {BRAND_NAME} from '../../Config';
import {brandImg, brandStyle} from '../../IconStyleBrand';
// import axios from 'axios';

const CreatePassword = (props) => {
  const {navigation} = props;
  const [data, setData] = useState({
    newPassword: '',
    confirmPassword: '',
    oldPassword: '',
    user: {},
  });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const otp = navigation.getParam('otp');
  const user = navigation.getParam('user');
  const type = navigation.getParam('type');
  const from = navigation.getParam('from');
  const mobile = navigation.getParam('mobile');

  useEffect(() => {
    setData({...data, oldPassword: otp || '', userDetail: user});
  }, []);
  const changePassword = async () => {
    setLoading(true);
    if (!data.oldPassword.length) {
      setError({type: 'old', value: 'Please enter value'});
    } else if (!data?.newPassword.length) {
      setError({type: 'new', value: 'Please enter value'});
    } else if (!data?.confirmPassword.length) {
      setError({type: 'confirm', value: 'Please enter value'});
    } else if (data?.confirmPassword !== data?.newPassword) {
      setError({
        type: 'confirm',
        value: 'Please make sure your passwords match',
      });
    } else {
      try {
        const result = await invoke({
          id: 'resetPassword',
          paramValue: {
            ...data,
          },
        });
        mayamd_authenticate({
          mobile,
          password: data?.newPassword,
          type,
        })
          .then((res) => {
            if (res?.user) {
              navigation.navigate('authenticator');
            } else {
              // setOtpError('Wrong OTP');
            }
            // setLoading(false);
          })
          .catch((err) => {
            console.log(
              `>@>Puru ~ file: CreatePassword.js ~ line 72 ~ err`,
              err,
            );
            // setLoading(false);
          });
        // navigation.pop();
        navigation.navigate('authenticator');
        setLoading(false);
      } catch (err) {
        setLoading(false);
        let message = JSON.parse(err?.message)?.error?.message;
        if (message === 'Old Password is not matched') {
          setError({
            type: 'old',
            value: message || '',
          });
        } else {
          setError({
            type: 'confirm',
            value: message || '',
          });
        }
        console.log(JSON.parse(err?.message)?.error?.message);
      }
    }
  };
  return (
    <View
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flex: 1,
        flexDirection: 'row',
      }}>
      {/* Login signup */}
      <View
        style={{
          // backgroundColor: 'red',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          className={'pulse'}
          style={{
            backgroundColor: '#fff',
            boxShadow: 'rgba(0,0,0,0.4) 5px 5px 10px 0px',
            marginBottom: 20,
            borderRadius: 20,
          }}>
          <Image
            // source={StewardLogo}
            source={brandImg[BRAND_NAME][0]}
            style={brandStyle[BRAND_NAME]['patient-login']}
          />
        </View>
        <View
          style={{
            backgroundColor: 'rgba(1, 59, 86, 0.35)',
            maxWidth: isMobile ? 320 : 400,
            minWidth: isMobile ? 320 : 400,
            minHeight: 150,
            borderRadius: 50,
            padding: 20,
          }}>
          <View
            style={{
              marginTop: 20,
              flex: 1,
            }}>
            <Text style={{marginBottom: 10, color: '#fff', ...h16_Regular}}>
              Enter New Password
            </Text>
            <TextInput
              moreInputStyle={{width: 335}}
              value={data['newPassword']}
              onChangeText={(value) => {
                // setOtp(value);
                setData({...data, newPassword: value});
              }}
              renderInputError={
                error?.type === 'new'
                  ? () => {
                      return <View></View>;
                    }
                  : false
              }
              error={error?.type === 'new' ? error?.value : ''}
              inputProps={{
                type: 'password',
              }}
              variant="filled"
              inputProps={{
                type: 'password',
              }}
              onSubmitEditing={(event) => {
                if (event.key === 'Enter') {
                  // !loading && verifyOTP && verifyOTP();
                }
              }}
              placeholder="Enter Passcode"
            />
          </View>
          <View
            style={{
              marginTop: 20,
              flex: 1,
            }}>
            <Text style={{marginBottom: 10, color: '#fff', ...h16_Regular}}>
              Confirm Password
            </Text>
            <TextInput
              moreInputStyle={{width: 335}}
              value={data['confirmPassword']}
              onChangeText={(value) => {
                // setOtp(value);
                setData({...data, confirmPassword: value});
              }}
              renderInputError={
                error?.type === 'confirm'
                  ? () => {
                      return <View></View>;
                    }
                  : false
              }
              error={error?.type === 'confirm' ? error?.value : ''}
              variant="filled"
              inputProps={{
                type: 'password',
              }}
              onSubmitEditing={(event) => {
                if (event.key === 'Enter') {
                  // !loading && verifyOTP && verifyOTP();
                }
              }}
              placeholder="Enter Passcode"
            />
          </View>
          {/* <View>
            <Text
              style={{
                ...h14_Regular,
                letterHeight: 21,
                letterSpacing: 0.5,
                color: '#fff',
                marginTop: 5,
                // opacity: 0.54,
              }}>
              We have sent Passcode on {mobile}
            </Text>
          </View> */}
          <TouchableOpacity
            onPress={() => {
              changePassword();
            }}
            style={{
              backgroundColor: '#00A7DA',
              flex: 1,
              minHeight: 45,
              maxHeight: 45,
              marginTop: 20,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              cursor: 'pointer',
            }}>
            {loading ? (
              <ActivityIndicator size={'small'} color={'white'} />
            ) : (
              <Text style={{color: '#fff'}}>Verify</Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
      {isMobile ? (
        void 0
      ) : (
        <View style={{flex: 1, justifyContent: 'flex-end'}}>
          <Image
            source={MayaAiGirl}
            style={{width: '100%', objectFit: 'contain'}}
          />
        </View>
      )}
    </View>
  );
};

export default CreatePassword;
