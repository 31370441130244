import React from 'react';
import {cancelButton} from '../../../app-components/action/CloseAction';
import Form from '../../../app-components/form/Form';
import {save} from '../../../app-components/action/SaveAction';
import {View} from '../../login/components';
import {closeButton} from '../rpmUtils';
import {invoke} from '../../../AppServices';
import {Snackbar} from '../../../app-components';
import {searchInput} from '../../../autoSuggestions';

let defaultHeader = {
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },
  title: 'Village Name:',
  actions: [save(), cancelButton({label: ' Back '})],
};

const AddVillageInformation = (props) => {
  const {navigation = {}} = props;
  const saveVillageAdditionalInformation = (villageDetails) => {
    const {
      navigation: {
        state: {
          params: {
            item: {_id, doctor: beforeUpdateDoctor = ''},
          },
        },
      },
    } = villageDetails;
    const {data = {}} = villageDetails;
    const {
         contactInformation = [],
         anm,
         doctor,
         subCenter = {},
         villageFamily = 0,
         villageOther = 0,
         villageFemale = 0,
         villageMale = 0,
         villageIndividual=0
       } = data;
    invoke({
      id: 'updateVillageInformation',
      paramValue: {
        _id,
        contactInformation,
        anm,
        doctor,
        beforeUpdateDoctor,
        subCenter,
        villageFamily,
        villageOther,
        villageFemale,
        villageMale,
        villageIndividual,
      },
    })
      .then((response) => {
        Snackbar.show({
          text: 'Village details update successfully.',
          duration: Snackbar.LENGTH_LONG,
        });
      })
      .catch((error) => {
        Snackbar.show({
          text: 'Unable to update village details.',
          duration: Snackbar.LENGTH_LONG,
        });
      });
  };
  const ModifiedForm = Form({
    ...props,
    type: 'standardShadow',
    closeView: 1,
    fieldVariant: 'filled',
    reloadEvent: 'reloadVillage',
    reloadOnChangeEvent: 'reloadVillage',
    validateOnChange: true,
    onSubmit: (data) => {
      saveVillageAdditionalInformation(data);
    },
    computations:{
      self: {
        "set villageIndividual": {
          compute: async data => {
            if(data){
            const villageIndividual=(data.villageMale ||0)+(data.villageFemale ||0)+(data.villageOther ||0);
                return { set: { villageIndividual } };
            }
            },
            onChange: ["villageMale","villageFemale","villageOther"]
          },
        },
      },
    defaultValues: (villageData) => {
      const {
        navigation: {
          state: {
            params: {item},
          },
        },
      } = villageData;
      return {
        contactInformation: item?.contactInformation || [],
        anm: item?.anm || '',
        doctor: item?.doctor || '',
        subCenter: item?.subCenter,
        villageFamily: item?.villageFamily ||'',
        villageOther: item?.villageOther ||'',
        villageFemale: item?.villageFemale ||'',
        villageMale: item?.villageMale ||'',
      };
    },
    mandatory: {
      anm: 1,
      subCenter: 1,
    },
    header: navigation?.state?.params?.header || defaultHeader,
    formGroups: [
      {
        label: 'Assign ANM',
        direction: 'row',
        fieldVariant: 'filled',

        groups: [
          {
            width: '80%',
            columnsPerRow: 3,
            columns: [
              searchInput(
                {
                  label: 'Assign ANM',
                  placeholder: 'Assign ANM',
                  field: 'anm',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: 'User',
                  query: 'userLists',
                  keyField: '_id',
                  mandatory: true,
                  searchField: 'name',
                },
                {
                  addOnFilter: () => {
                    return {
                      userType: 'ANM',
                    };
                  },
                },
              ),
              searchInput(
                {
                  label: 'Assign doctor',
                  placeholder: 'Select doctor',
                  field: 'doctor',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: 'User',
                  query: 'userLists',
                  keyField: '_id',
                  searchField: 'name',
                },
                {
                  addOnFilter: () => {
                    return {
                      userType: 'Doctor',
                    };
                  },
                },
              ),
              searchInput({
                label: 'Sub-center',
                placeholder: 'select village sub-center',
                field: 'subCenter',
                valueField: 'centerName',
                suggestionField: 'centerName',
                model: 'villageSubCenter',
                query: 'villageSubCenterList',
                keyField: '_id',
                mandatory: true,
                searchField: 'centerName',
              }),
            ],
          },
        ],
      },
      {
        label: 'Add More Details',
        direction: 'row',
        fieldVariant: 'filled',
        editable: true,
        groups: [
          {
            width: '80%',
            columnsPerRow: 3,
            columns: [
              {
                type: 'number',
                label: 'Number of families',
                field: 'villageFamily',
              },
              {
                type: 'number',
                label: 'Number of Males',
                field: 'villageMale',
              },
              {
                type: 'number',
                label: 'Number of Females',
                field: 'villageFemale',
              },
              {
                type: 'number',
                label: 'Number of Others',
                field: 'villageOther',
              },
              {
                type: 'number',
                label: 'Number of Individual',
                field: 'villageIndividual',
                editable:false,   
              },
            ],
          },
        ],
      },
      {
        label: 'Add Contact Detail',
        direction: 'row',
        fieldVariant: 'filled',
        editable: true,
        groups: [
          {
            columns: [
              {
                columnsPerRow: 2,
                type: 'nestedList',
                field: 'contactInformation',
                addInBottom: true,
                formGroupHeaderStyle: {marginLeft: 10},
                footer: {
                  addLabel: true ? 'ADD' : void 0,
                },
                formGroups: [
                  {
                    columnsPerRow: 3,
                    fieldVariant: 'filled',
                    groups: [
                      {
                        columns: [
                          {
                            type: 'text',
                            label: 'Name',
                            field: 'name',
                          },
                          {
                            type: 'text',
                            label: 'Value',
                            field: 'value',
                          },
                          {
                            width: 30,
                            render: (props) => {
                              const {index} = props;
                              if (index === 0 || !true) {
                                return <View />;
                              }
                              return closeButton(props);
                            },
                          },
                          {
                            type: 'custom',
                            width: '100%',
                            render: () => (
                              <View
                                style={{
                                  height: 20,
                                  borderBottomWidth: 1.5,
                                  borderBottomColor: '#f2eeed',
                                }}
                              />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default AddVillageInformation;
