import React, {useState} from 'react';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  Image,
} from '../../app-components';
import {colors} from '../../theme/colors';
import {h14_AvenirNext} from '../../theme/fonts';
import PatientDetails from './PatientDetails';
import {getUser, setDependentPatient} from '../../AppServices';
import AddMember from './AddMember';
const {themeContainer, themeTextColor} = colors;
const RadioButton = (props) => {
  const {selected = false} = props;
  return (
    <View
      style={{
        borderColor: themeTextColor,
        borderWidth: 2,
        // backgroundColor: 'red',
        minWidth: 10,
        minHeight: 10,
        borderRadius: 10,
        // padding: 10,
        margin: 5,
      }}>
      <View
        style={{
          backgroundColor: selected ? themeTextColor : 'transparent',
          minWidth: 7,
          minHeight: 7,
          borderRadius: 7,
          // padding: 10,
          margin: 3,
        }}></View>
    </View>
  );
};
const Details = (props) => {
  const {navigation = {}, t = () => {}} = props;
  const {state: {params: {item = {}} = {}} = {}} = navigation;
  const {dependent = null} = item;
  React.useEffect(() => {
    if (dependent) {
      setMeOrSomeOne('someone');
      setDependentPatient({id: dependent?._id});
    }
  }, [dependent]);
  // console.log(`>@>Puru ~ file: Details.js ~ line 44 ~ params`, item, dependent);
  const [meOrSomeOne, setMeOrSomeOne] = useState('me');
  return (
    <ScrollView
      style={{
        padding: 10,
        flex: 1,
        // paddingBottom: 50,
        backgroundColor: themeContainer,
        marginTop: 10,
        // paddingBottom: 20,
        marginLeft: 12,
        marginRight: 12,
        borderRadius: 8,
        paddingBottom: 0,
      }}>
      <TouchableOpacity
        onPress={() => navigation.pop(2)}
        style={{
          cursor: 'pointer',
          alignItems: 'flex-start',
          marginTop: 5,
          // marginBottom: 20,
        }}>
        <Text
          style={{
            ...h14_AvenirNext,
            lineHeight: 21,
            letterSpacing: 0.5,
            // color: '#383838',
            color: themeTextColor,
            // opacity: 0.54,
          }}>
          {'< '} {t('Back')}
        </Text>
      </TouchableOpacity>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 10,
          marginBottom: 10,
        }}>
        <View
          style={{flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
          <Text style={{color: themeTextColor, ...h14_AvenirNext}}>Me</Text>
          {/* <RadioInput /> */}
          <TouchableOpacity
            style={{
              cursor: 'pointer',
            }}
            onPress={() => {
              setMeOrSomeOne('me');
            }}>
            <RadioButton selected={meOrSomeOne === 'me' ? true : false} />
          </TouchableOpacity>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={{color: themeTextColor, ...h14_AvenirNext}}>
            Someone else
          </Text>
          <TouchableOpacity
            onPress={() => {
              setMeOrSomeOne('someone');
            }}
            style={{
              cursor: 'pointer',
            }}>
            <RadioButton selected={meOrSomeOne === 'someone' ? true : false} />
          </TouchableOpacity>
        </View>
      </View>

      {meOrSomeOne === 'me' ? (
        <PatientDetails
          {...props}
          userId={getUser()?.patient?._id}
          meOrSomeOne={'me'}
        />
      ) : (
        void 0
      )}
      <AddMember {...props} meOrSomeOne={meOrSomeOne} dependent={dependent} />
    </ScrollView>
  );
};

export default Details;
