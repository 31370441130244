import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  Dimensions,
  Image,
  TouchableOpacity,
  isMobile,
} from '../../app-components';
import {modalCross} from '../../images';
import {h20_AvenirNext_500, h13_Regular} from '../../theme/fonts';
import {getDownloadUrl, getUser} from '../../AppServices';
import ChatView from './ChatView';
import {bgs} from '../../theme/colors';

let {borderColor} = bgs;
const SidePanel = (props) => {
  const isUserDoctor = getUser().userType === 'Doctor' ? true : false;
  const [chat, setChat] = useState(false);

  useEffect(() => {
    if (!isUserDoctor) {
      setChat(true);
    }
  }, []);

  const {item, toggleChatBox} = props;
  let imageUrl = item?.patient_id?.profile_picture;
  imageUrl = imageUrl && getDownloadUrl(imageUrl);
  return (
    <View
      style={{
        display: props.sideViewVisible,
        position: 'absolute',
        right: 0,
        zIndex: 5,
        // width: Dimensions.get('window').width - props.publisherViewWidth,
        height: '100%',
        // borderLeftWidth: 1,
        // borderColor: borderColor,
        borderRadius: 8,
        ...(isMobile ? {width: '100%'} : {}),
      }}>
      <View
        style={{
          position: 'relative',
          backgroundColor: '#2e6ecd',
        }}>
        <Text
          style={{
            paddingTop: 17,
            paddingLeft: 26,
            ...h20_AvenirNext_500,
            color: '#fff',
          }}>
          Chat with your doctor
        </Text>
        <Text
          style={{
            paddingTop: 6,
            paddingBottom: 26,
            paddingLeft: 26,
            color: '#fff',
            ...h13_Regular,
          }}>
          Share text,images & pdf
        </Text>
        <TouchableOpacity
          style={{
            height: 22,
            width: 22,
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer',
          }}
          onPress={() => {
            toggleChatBox();
          }}>
          <Image source={modalCross} />
        </TouchableOpacity>
      </View>
      {/* <View style={{backgroundColor:"#fff",}}> */}
      {chat ? (
        <ChatView
          item={{...item, appointment_id: {_id: item?._id}}}
          navigation={props?.navigation}
        />
      ) : (
        void 0
      )}
      {/* </View> */}
    </View>
  );
};
export default SidePanel;
