let extensions = [
  'jpg',
  'png',
  'jpeg',
  'tif',
  'gif',
  'dng',
  'raw',
  'bmp',
  'psd',
];

const iterator = (array, task) => {
  return new Promise((resolve, reject) => {
    let length = array ? array.length : 0;
    if (length === 0) {
      resolve();
      return;
    }
    let index = 0;
    let loop = index => {
      try {
        let onResolve = function() {
          index = index + 1;
          if (index === array.length) {
            resolve();
          } else {
            loop(index);
          }
        };
        try {
          let p = task(index, array[index]);
          if (!p) {
            onResolve();
            return;
          }
          p.then(onResolve).catch(function(err) {
            reject(err);
          });
        } catch (e) {
          reject(e);
        }
      } catch (e) {
        reject(e);
      }
    };
    loop(index);
  });
};

const isImage = ({name, _extensionProps}) => {
  let ext = name && name.split('.').pop();
  if (ext) {
    ext = ext.toLowerCase();
  }

  if (_extensionProps) {
    return _extensionProps.indexOf(ext) >= 0;
  }
  return extensions.indexOf(ext) >= 0;
};

const canOpenInNewTab = ({name, extensions: _extensionProps}) => {
  if (!isImage || isImage({name, _extensionProps})) {
    return true;
  } else {
    let extension = name && name.split('.').pop();
    if (extension) {
      extension = extension.toLowerCase();
    }
    if (extension === 'pdf') {
      return true;
    }
  }
};
   const resolveExp = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    var result = [];
    for (var i = 0; i < values.length; i++) {
      var row = values[i];
      var resolvedValue = resolveExp(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (var j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17*/
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else {
          if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
            result.push(resolvedValue);
          }
        }
      }
    }
    return result;
  } else {
    var value = values[key];
    if (value !== undefined) {
      return value;
    }
    var index = key.indexOf('.');
    if (index === -1) {
      return;
    }
    var firstPart = key.substring(0, index);
    var nextPart = key.substring(index + 1);
    return resolveExp(values[firstPart], nextPart);
  }
};
export {iterator, isImage, canOpenInNewTab,resolveExp};
