import React, {useEffect, useState} from 'react';
import {View, Text} from '../../../app-components';
import {h18_AvenirNext_500} from '../../../theme/fonts';
import AnalyticsCard from './AnalyticsCard';

const ConversionCard = ({getData,timeFilter}) => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData({id: 'getAppointmentAnalysis', setResponse, setLoading});
  }, [timeFilter]);

  const data = [
    {
      header: 'Completed',
      title:
        response?.completed || response?.completed === 0
          ? response?.completed
          : 'N/A',
    },
    {
      header: 'Cancelled',
      title:
        response?.cancelled != undefined && response?.cancelled != null
          ? response?.cancelled
          : 'N/A',
      titleColor: 'red',
    },
    {
      header: 'Total',
      title:
        response?.total_transactions || response?.total_transactions === 0
          ? response?.total_transactions
          : 'N/A',
    },
  ];

  const data2 = [
    {
      header: 'Direct Booking',
      title: '1000',
    },
    {
      header: 'Booking via Invite link',
      title: '800',
    },
  ];

  return (
    <View style={{flex: 1}}>
      <Text style={{...h18_AvenirNext_500}}>Conversions</Text>
      <AnalyticsCard cardContent={data} loading={loading} />
      {/* <AnalyticsCard cardContent={data2} loading={loading} /> */}
    </View>
  );
};

export default ConversionCard;
