import React from 'react';
import {View, Text, TouchableOpacity} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {h16_Regular} from '../../theme/fonts';
// import { setInitiallyLogged, setSelectedDoctor } from '../../AppServices';

const PaymentFailedModal = (props) => {
  const {navigation, eventDispatcher} = props;
  let {
    state: {params: {message, isNavigate = false, setVisible} = {}} = {},
  } = navigation;
  if (message && message.length > 150) {
    message = 'Enter your name';
  }
  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: 500,
        padding: 20,
        alignItems: 'center',
      }}>
      <Text style={{marginBottom: 40, marginTop: 20, ...h16_Regular}}>
        {message || 'Payment Failed'}
      </Text>
      <TouchableOpacity
        style={{cursor: 'pointer', width: 250}}
        onPress={() => {
          if (isNavigate) {
            eventDispatcher.notify('ScreenPop');
            navigation.pop(3);
          } else {
            navigation.pop();
            setVisible && setVisible(true);
          }
        }}>
        <GradientButton label="OK" />
      </TouchableOpacity>
    </View>
  );
};

export default PaymentFailedModal;
