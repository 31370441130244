import {
  myDoctor,
  myDoctorActive,
  upCommingAppointment,
  upCommingAppointmentActive,
  doctorSearch,
  doctorSearchActive,
  patientQueue,
  patientQueueActive,
  support,
  supportActive,
  analyticsLogo,
  manageClinicsLogo,
  managePatientsLogo,
  manageMasterDataLogo,
  analyticsLogoActive,
  manageClinicsLogoActive,
  managePatientsLogoActive,
  manageMasterDataLogoActive,
  inviteLinksActive,
  inviteLinksInactive,
  mySchedule,
  myScheduleActive,
  payment,
  paymentActive,
  imports,
  importsActive,
  followUpActive,
  followUp,
  patientRequestsActive,
  patientRequests,
} from './images';

const navigationMenus = [
  {
    title: 'Care Program',
    activeIcon: patientRequestsActive,
    icon: patientRequests,
    navigate: {
      view: 'RPM',
    },
  },
  // {
  //   title: 'Member Management',
  //   activeIcon: patientRequestsActive,
  //   icon: patientRequests,
  //   navigate: {
  //     view: 'superAdminRPM',
  //   },
  // },
  {
    title: 'Analytics',
    activeIcon: analyticsLogoActive,
    icon: analyticsLogo,
    navigate: {
      view: 'analytics',
    },
  },
  {
    title: 'Payment',
    activeIcon: paymentActive,
    icon: payment,
    navigate: {
      view: 'PaymentAnalytics',
    },
  },
  {
    title: 'Analytics',
    activeIcon: analyticsLogoActive,
    icon: analyticsLogo,
    navigate: {
      view: 'superAdminAnalytics',
    },
  },
  {
    title: 'Doctor Search',
    activeIcon: doctorSearchActive,
    icon: doctorSearch,
    navigate: {
      view: 'doctor-search',
    },
  },
  {
    title: 'My Doctors',
    activeIcon: myDoctorActive,
    icon: myDoctor,
    navigate: {
      view: 'FavoriteDoctor',
    },
  },
  {
    title: 'My Schedule',
    activeIcon: myScheduleActive,
    icon: mySchedule,
    navigate: {
      view: 'schedule',
    },
  },
  {
    icon: manageClinicsLogo,
    activeIcon: manageClinicsLogoActive,
    title: 'Manage Clinics',
    navigate: {
      view: 'clinics',
    },
  },
  {
    activeIcon: patientQueueActive,
    icon: patientQueue,
    title: 'Patient Queue',
    navigate: {
      view: 'doctor-patient-queue',
    },
  },
  {
    activeIcon: patientQueueActive,
    icon: patientQueue,
    title: 'Instant Patient Queue',
    navigate: {
      view: 'instant-patient-queue',
    },
  },
  {
    activeIcon: upCommingAppointmentActive,
    icon: upCommingAppointment,
    title: 'Upcoming appointments',
    navigate: {
      view: 'patient-waiting-room',
    },
  },
  {
    activeIcon: managePatientsLogoActive,
    icon: managePatientsLogo,
    title: 'Appointment',
    navigate: {
      view: 'patients',
    },
  },
  {
    activeIcon: followUpActive,
    icon: followUp,
    title: 'Follow-Up',
    navigate: {
      view: 'follow-up',
    },
  },
  // {
  //   activeIcon: manageMasterDataLogoActive,
  //   icon: manageMasterDataLogo,
  //   title: 'System Settings',
  //   navigate: {
  //     view: 'master-data',
  //   },
  // },
  {
    activeIcon: manageMasterDataLogoActive,
    icon: manageMasterDataLogo,
    title: 'Settings',
    navigate: {
      view: 'clinic-detail',
    },
  },
  {
    activeIcon: inviteLinksActive,
    icon: inviteLinksInactive,
    title: 'Emergency Patients',
    navigate: {
      view: 'emergency-request',
    },
  },
  {
    activeIcon: inviteLinksActive,
    icon: inviteLinksInactive,
    title: 'Patient Invite Links',
    navigate: {
      view: 'manage-invite-links',
    },
  },
  {
    activeIcon: inviteLinksActive,
    icon: inviteLinksInactive,
    title: 'Add Organization Patient',
    navigate: {
      view: 'patient-clinic-signup',
    },
  },
  {
    activeIcon: managePatientsLogoActive,
    icon: managePatientsLogo,
    title: 'My Patient',
    navigate: {
      view: 'my-patient',
    },
  },
  {
    title: 'Import',
    activeIcon: importsActive,
    icon: imports,
    navigate: {
      view: 'importList',
    },
  },
  {
    title: 'Patient Requests',
    activeIcon: patientRequestsActive,
    icon: patientRequests,
    navigate: {
      view: 'patient-requests',
    },
  },
  {
    title: 'Lab Requests',
    activeIcon: managePatientsLogoActive,
    icon: managePatientsLogo,
    navigate: {
      view: 'labAppointment',
    },
  },
  {
    activeIcon: supportActive,
    icon: support,
    title: 'Contact Support',
    navigate: {
      view: 'contact-support',
    },
  },
];

export default navigationMenus;
