import React from 'react';
import {cancel} from '../../../../app-components/action/CancelAction';
import {save} from '../../../../app-components/action/SaveAction';
import CustomHeaderLabel from '../../../../app-components/CustomHeaderLabel';
import {getUser} from '../../../../AppServices';
import DialysisAdminForm from './DialysisAdminForm';

const header = {
  hideBackIcon: true,
  title: ({navigation}) => {
    let {state: {params: {item} = {}} = {}} = navigation;
    const {organization} = getUser();
    if (!item) {
      item = organization;
    }
    return (
      <CustomHeaderLabel
        seperator={true}
        preLabel={'Add New User'}
        postLabel={item?.organization_name || 'N/A'}
      />
    );
  },
  actions: [cancel(), save()],
};

export default DialysisAdminForm({header});
