import React, {Component} from 'react';
import {
  Image,
  Text,
  TouchableOpacity,
  View,
} from '../index';
import {Action} from './Action';
import {shadow} from '../../theme/shadows';
import {manazeV5Colors} from '../../theme/manazeV5Colors';
import {Headline8} from '../../theme/Headlines';
const {primary_900, neutral_50, Surface} = manazeV5Colors;
const floatingActionsStyle = {
  expandedStyle: {
    containerStyle: {
      right: 20,
      bottom: 110,
      height: 'auto',
      position: 'absolute',
    },
    actionContainerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 5,
      marginBottom: 5,
    },
    textViewStyle: {
      padding: 6,
      borderRadius: 4,
      backgroundColor: neutral_50,
      ...shadow,
    },
    textStyle: {...Headline8, color: primary_900},
    iconViewStyle: {
      marginLeft: 8,
      backgroundColor: neutral_50,
      ...shadow,
      borderRadius: 25 / 2,
      height: 25,
      width: 25,
      padding: 6,
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconStyle: {objectFit: 'contain', height: 20, width: 20},
  },
  collpsedStyle: {
    containerStyle: {
      right: 0,
      bottom: 0,
      position: 'absolute',
    },
    actionContainerStyle: {
      flexDirection: 'row',
      height: 40,
      width: 40,
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Surface,
      ...shadow,
    },
    textViewStyle: {},
    textStyle: {fontSize: 28, color: primary_900},
    iconViewStyle: {},
    iconStyle: {},
  },
};

export class FloatingActions extends Component {
  state = {expanded: false};
  close = () => {
    if (this.state.expanded !== false) {
      this.setState({expanded: false});
    }
  };
  rendeCollapsed = ({
    uid,
    state,
    setState,
    actions,
    navigation,
    eventDispatcher,
    ...rest
  }) => {
    return this.getActions({
      uid,
      state,
      setState,
      actions,
      navigation,
      eventDispatcher,
      direction: 'row',
      onPress: index => {
        const {expanded} = this.state;
        this.setState({expanded: expanded === false ? index : false});
      },

      ...rest,
    });
  };
  renderExpanded = ({
    uid,
    state,
    setState,
    actions,
    navigation,
    eventDispatcher,
    ...rest
  }) => {
    const {expanded} = this.state;
    if (expanded === false) {
      return null;
    }
    let {actions: expadedActions} = actions[expanded] || {};
    if (!expadedActions || !expadedActions.length) {
      return null;
    }
    return this.getActions({
      uid,
      state,
      setState,
      actions: expadedActions,
      navigation,
      eventDispatcher,
      direction: 'column',

      ...rest,
    });
  };
  getActions = ({
    uid,
    state,
    setState,
    actions,
    navigation,
    eventDispatcher,
    direction,
    onPress,
    ...rest
  }) => {
    const {
      containerStyle,
      actionContainerStyle,
      textViewStyle,
      textStyle,
      iconViewStyle,
      iconStyle,
    } = rest || {};
    if (actions && typeof actions === 'function') {
      actions = actions({navigation, eventDispatcher});
    }
    return (
      <View
        style={{
          ...containerStyle,
          flexDirection: direction,
        }}>
        {actions.map((action, index) => {
          let {
            icon,
            text,
            style: _actionStyle,
            color,
            type,
            visible = true,
          } = action;
          if (typeof visible === 'function') {
            visible = visible && visible({navigation});
          }
          let component = null;
          if (visible) {
            if (action.actions) {
              component = (
                <Action
                  uid={uid}
                  style={_actionStyle || actionContainerStyle}
                  navigation={navigation}
                  eventDispatcher={eventDispatcher}
                  //   action={action}
                  state={state}
                  setState={setState}
                  onSelect={onPress ? () => onPress(index) : void 0}>
                  {text && (
                    <View style={textViewStyle}>
                      <Text style={textStyle}>{text}</Text>
                    </View>
                  )}
                  {icon && (
                    <View style={iconViewStyle}>
                      <Image style={iconStyle} source={icon} />
                    </View>
                  )}
                </Action>
              );
              //To Do for neasted actions
            } else if (action.render) {
              if (typeof action.render === 'function') {
                component = action.render({
                  uid,
                  navigation,
                  eventDispatcher,
                  action,
                  state,
                  setState,
                });
              } else {
                component = React.cloneElement(action.render, {
                  uid,
                  navigation,
                  eventDispatcher,
                  action,
                  state,
                  setState,
                });
              }
              component = (
                <Action
                  uid={uid}
                  style={_actionStyle || actionContainerStyle}
                  navigation={navigation}
                  eventDispatcher={eventDispatcher}
                  action={action}
                  state={state}
                  setState={setState}
                  closeModal={this.close}>
                  {component}
                </Action>
              );
            } else {
              component = (
                <Action
                  uid={uid}
                  style={_actionStyle || actionContainerStyle}
                  navigation={navigation}
                  eventDispatcher={eventDispatcher}
                  action={action}
                  state={state}
                  setState={setState}
                  closeModal={this.close}>
                  {text && (
                    <View style={textViewStyle}>
                      <Text style={textStyle}>{text}</Text>
                    </View>
                  )}
                  {icon && (
                    <View style={iconViewStyle}>
                      <Image style={iconStyle} source={icon} />
                    </View>
                  )}
                </Action>
              );
            }
          }
          return component;
        })}
      </View>
    );
  };
  render() {
    const {
      uid,
      state,
      setState,
      floatingActions,
      navigation,
      eventDispatcher,
    } = this.props;
    if (!floatingActions) {
      return null;
    }
    const {expanded} = this.state;
    let style = {bottom: 8, right: 16, height: 100, width: 50};
    let containerStyle = floatingActionsStyle.collpsedStyle.containerStyle;
    if (expanded || expanded === 0) {
      style = {
        backgroundColor: 'rgba(56, 56, 56, 0.1)',
        height: '100%',
        width: '100%',
        bottom: 0,
        right: 0,
      };
      containerStyle = {
        ...containerStyle,
        right: 16,
        bottom: 8,
        position: 'absolute',
      };
    }

    return (
      <TouchableOpacity
        onPress={this.close}
        activeOpacity={1}
        style={{
          position: 'absolute',
          ...style,
        }}>
        {this.renderExpanded({
          uid,
          state,
          setState,
          actions: floatingActions,
          navigation,
          eventDispatcher,
          ...floatingActionsStyle.expandedStyle,
        })}
        {this.rendeCollapsed({
          uid,
          state,
          setState,
          actions: floatingActions,
          navigation,
          eventDispatcher,
          ...floatingActionsStyle.collpsedStyle,
          containerStyle,
        })}
      </TouchableOpacity>
    );
  }
}

export default FloatingActions;
