import React from 'react';
import {isMobile, Text, View} from '../../app-components';
import Doctor from './DoctorSearchList';
import DoctorProfile from './DoctorProfile';
import Booking from './Booking';
import AfterBookingSlotLogin from './AfterBookingSlot';
import BookingConfirm from './BookingConfirmed';
import PatientSignUP from './PatientSignUP';
import {h18_Medium} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import Fts from '../../app-components/filter/Fts';
import PatientDoctorSearchLogin from './PatientDoctorSearchLogin';
import {
  specilityNameFilter,
  clinicFilter,
  locationFilter,
} from '../../app-components/filter/MayaMDFilters';
import PatientSignIn from './PatientSignIn';
import PatientOTP from './PatientOTP';
import OTP from './OTP';
import PatientLogin from './PatientLogin';
import PatientDetails from './Details';
// import Payment from './Payment';
// import PaymentFailedModal from './PaymentFailedModal';
import MapModal from './MapModal';
import PatientAppointmentDetails from './PatientAppointmentDetails';
import BookingConfirmedMessage from './BookingConfirmedMessage';
let {textColor87, themeColor, themeContainer} = colors;

const styles = {
  headerContainer: {
    // backgroundColor:themeContainer,
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 8,
    paddingLeft: 40,
    paddingRight: 30,
    // borderBottomWidth: 0.5,
    // borderColor: 'rgb(151,151,151, 0.34)',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    paddingLeft: 27,
    ...h18_Medium,
    color: textColor87,
  },
};

export default {
  'front-page-changed': {
    screen: PatientDoctorSearchLogin,
    public: true,
  },
  'doctor-search': {
    renderHeader: (props) => {
      let {navigation, screenState, t, i18n} = props;
      let {setScreenState} = navigation;
      const routes = navigation.getActiveRoutes();
      let firstRoute = routes[0] || [];
      if (
        (firstRoute !== 'booking' && routes?.length > 1) ||
        routes?.indexOf('booking') !== -1
      ) {
        return null;
      }
      return (
        <View style={styles.headerContainer}>
          {isMobile?void 0:<View style={{width: 500, borderRadius: 20}}>
            <Fts
              placeholder={props.t('Search By Doctor')}
              screenState={screenState}
              setScreenState={setScreenState}
            />
          </View>}
          <View style={{flexDirection: 'row'}}>
            {specilityNameFilter({setScreenState, screenState, t})}
            {clinicFilter({setScreenState, screenState, t})}
            {locationFilter({screenState, setScreenState, t, i18n})}
          </View>
        </View>
      );
    },
    screen: Doctor,
    public: true,
    patient: true,
  },
  'map-modal': {
    screen: MapModal,
  },
  'patient-appointment-details': {
    screen: PatientAppointmentDetails,
  },
  // 'booking-confirm-message':{
  //   screen:BookingConfirmedMessage,
  //   expanded: true,
  // },
  'doctor-profile': {
    screen: DoctorProfile,
    public: true,
  },
  booking: {
    screen: Booking,
    public: true,
  },
  'after-booking-slot': {
    screen: AfterBookingSlotLogin,
    public: true,
    expanded: true,
  },
  'booking-confirm': {
    screen: BookingConfirmedMessage,
    public: true,
    expanded: true,
  },
  'edit-booking': {
    screen: BookingConfirm,
    public: true,
    expanded: true,
  },
  'patient-signUp': {
    screen: PatientSignUP,
    expanded: true,
    public: true,
  },
  'patient-signIn': {
    screen: PatientSignIn,
    expanded: true,
    public: true,
  },
  'patient-otp': {
    screen: PatientOTP,
    expanded: true,
    public: true,
  },
  'patient-login': {
    screen: PatientLogin,
    public: true,
  },
  OTP: {
    screen: OTP,
    public: true,
  },
  'patient-details': {
    screen: PatientDetails,
    public: true,
    patient: true,
  },
  // 'patient-payment': {
  //   screen: Payment,
  //   public: true,
  //   expanded: true,
  // },
  // payment_failed: {
  //   screen: PaymentFailedModal,
  //   public: true,
  // },
};
