import {autoSuggestInput, searchInput} from '../../autoSuggestions';
import Form from '../../app-components/form/Form';
import {submit, checkDob, getUser} from '../../AppServices';
import {closeButton, getNewId} from './rpmUtils';
import axios from 'axios';
import {BASE_URL} from '../../Config';
import {isObject} from 'lodash';
import districts from './MeghalyaDistrictsAndBlocks';
import {knowAboutPPHMData} from './KnowAboutPPHMData';
const libPhoneNumber = require('google-libphonenumber');
const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

const space = {
  width: 35,
  type: 'custom',
  render: () => {
    return [];
  },
};
export default ({header, editable, closeView}) => {
  return Form({
    reloadEvent: 'reloadPatient',
    reloadOnChangeEvent: 'reloadPatient',
    expandSingleFormgroup: true,
    type: 'standardShadow',
    closeView: closeView,
    fieldVariant: 'filled',
    // mandatory: {
    //   first_name: 1,
    //   birthDate: 1,
    //   mobile: 1,
    // },
    validateOnChange: true,
    onSubmit: submit({
      model: 'Patients',
      recordActivity: true,
      op: 'insert',
    }),
    beforeSubmit: ({updates}) => {
      const {address} = updates;
      if (Array.isArray(address) && address.length) {
        const addressModify = address.map((address) => {
          if (isObject(address.state)) {
            address.stateCode = address?.state?.isoCode;
            address.state = address?.state?.name;
          }
          if (isObject(address.city)) {
            address.city = address?.city?.name;
          }
          return address;
        });
        return {
          updates: {
            ...updates,
            address: addressModify,
            isNoteGenerated: false,
            op: 'insert',
            user: getUser(),
            mhis: updates?.mhis?.toUpperCase(),
            familyId: updates?.familyId?.toUpperCase(),
          },
        };
      }
    },
    mandatory: {
      district: 'District is mandatory.',
      block: 'Block is mandatory.',
      village: 'Village is mandatory.',
      gender: 'Gender is mandatory',
      first_name: 1,
      birthDate: 1,
      socialMediaPlatform: 1
      // mobile: 1,
      // aadharNumber: 1,
    },
    validations: {
      otherSocialMediaPlatform: ({data}) => {
        const {otherSocialMediaPlatform = '', socialMediaPlatform = ''} = data;
        if (
          socialMediaPlatform ===
            'Others' &&
          !otherSocialMediaPlatform
        ) {
          return 'This field cannot be empty.';
        }
      },
      email: ({data}) => {
        let {email} = data;
        if (!email) {
          return;
        }
        const value = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/i.test(email);
        if (!value) {
          return 'Invalid Email';
        }
      },
      birthDate: ({data}) => {
        const val = checkDob(data.birthDate);
        if (!val) {
          return 'Invalid Date of birth';
        }
      },
      first_name: ({data}) => {
        const {first_name = ''} = data || {};
        const value = /^[a-zA-Z\s]*$/.test(first_name);
        if (!value) {
          return 'Invalid Name';
        }
      },
      last_name: ({data}) => {
        const {last_name = ''} = data || {};
        const value = /^[a-zA-Z\s]*$/.test(last_name);
        if (!value) {
          return 'Invalid Name';
        }

      },
      familyId: ({ data }) => {
        const { familyId = '' } = data;
        if (familyId?.length) {
          const firstVal = familyId.slice(0, 3).toLowerCase();
          let value = /^svm/.test(firstVal);
          const newVal = familyId?.slice(3, 16);
          let val = /^([0-9]*)$/.test(newVal);
          if (familyId && familyId?.length !== 16) {
            return 'FamilyId must be 16 digits long.';
          }
          else if (!value) {
            return 'FamilyID should start with SVM';
          }
          else if (!val) {
            return 'Invalid FamilyId';
          }
        }
      },
      mobile: ({data}) => {
        const {mobile = ''} = data || {};
        if (!mobile) {
          return;
        }
        try {
          let result = phoneUtil.isValidNumber(phoneUtil.parse(mobile));
          if (!result) {
            return 'The mobile number you entered appears to be invalid';
          }
        } catch (e) {
          return 'Please enter mobile number';
        }
      },
      weight: ({data}) => {
        if(data?.weight <= 0){
          return 'Weight cannot be zero or negative';
        }
        else if(data?.weight > 200){
          return 'Weight cannot be more than 200 kgs';
        }
      },
      height: ({data}) => {
        if(data?.height <= 0){
          return 'Height cannot be zero or negative';
        }
        else if(data?.height > 85){
          return 'Height cannot be more than 85 inches';
        }
      },
      mhis: ({ data }) => {
        const value = /^[a-zA-Z0-9]*$/.test(data?.mhis);
        if (!value) {
          return 'MHIS Number should be alphabet and number only.';
        }
      },
    },
    header: header,
    defaultValues: ({navigation}) => {
      const {organization: userOrganization, userType} = getUser() || {};
      const {state: {params: {item = {}} = {}} = {}} = navigation || {};
      let {organization = [], address = []} = item || {};
      if (
        userType !== 'SuperAdmin' &&
        Array.isArray(organization) &&
        organization.findIndex(
          (organizationItem) => organizationItem?._id === userOrganization?._id,
        ) === -1
      ) {
        organization.push(userOrganization);
      }
      let addressModify = [];
      if (Array.isArray(address) && address.length) {
        addressModify = address.map((address) => {
          if (typeof address.state === 'string') {
            address.state = {name: address.state};
          }
          if (typeof address.city === 'string') {
            address.city = {name: address.city};
          }
          return address;
        });
      }
      return {
        organization,
        userType: 'Patient',

        ...item,
        ...(Array.isArray(address) && address.length
          ? {address: addressModify}
          : {address: [{_id: getNewId()}]}),
      };
    },
    formGroups: [
      {
        editable: editable,
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'text',
                label: 'First Name',
                field: 'first_name',
                mandatory: true,
              },
              {
                type: 'text',
                label: 'Last Name',
                field: 'last_name',
              },
              {
                type: 'mobile',
                label: 'Phone Number',
                field: 'mobile',
                disabled: !editable,
              },
              {
                type: 'text',
                label: 'Email',
                field: 'email',
              },
              {
                type: 'chipsToggle',
                field: 'gender',
                label: 'Gender',
                editable: editable,
                mandatory: true,
                options: [
                  {value: 'male', label: 'Male'},
                  {value: 'female', label: 'Female'},
                  {value: 'other', label: 'Other'},
                ],
              },
              {
                type: 'date',
                label: 'Date Of Birth',
                field: 'birthDate',
                mandatory: true,
              },
              {
                type: 'text',
                label: 'Family Id',
                field: 'familyId',
              },
              {
                type: 'text',
                label: 'Aadhar Number',
                field: 'aadharNumber',
              },
              {
                type: 'text',
                label: 'MHIS Number',
                field: 'mhis',
                moreInputStyle: { textTransform: 'uppercase' },
              },
              autoSuggestInput({
                label: 'District',
                placeholder: 'Select District',
                field: 'district',
                valueField: 'name',
                suggestionField: 'name',
                model: 'Districts',
                query: 'districtList',
                keyField: '_id',
                mandatory: true,
              }),
              autoSuggestInput(
                {
                  label: 'Block',
                  placeholder: 'Select Block',
                  field: 'block',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: 'Blocks',
                  query: 'blockList',
                  keyField: '_id',
                  mandatory: true,
                },
                {
                  addOnFilter: ({data}) => ({
                    district: {_id: data?.district?._id},
                  }),
                },
              ),
              searchInput(
                {
                  label: 'Village',
                  placeholder: 'Select Village',
                  field: 'village',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: 'Villages',
                  query: 'villageList',
                  keyField: '_id',
                  mandatory: true,
                  searchField: 'name',
                },
                {
                  addOnFilter: ({data}) => ({block: {_id: data?.block?._id}}),
                },
              ),
              autoSuggestInput({
                label: 'How did you know about PPHM?',
                options: knowAboutPPHMData,
                valueField: 'label',
                suggestionField: 'label',
                field: 'socialMediaPlatform',
                keyField: 'label',
                mandatory: true,
              }),
              {
                type: 'text',
                label: 'If others, please specify below:',
                valueField: 'label',
                suggestionField: 'label',
                field: 'otherSocialMediaPlatform',
                visible: ({data}) => {
                  if (
                    data.socialMediaPlatform ===
                    'Others'
                  ) {
                    return true;
                  }
                  return false;
                },
                mandatory: true,
              },
              {
                type: 'number',
                label: 'Weight (kg)',
                field: 'weight',
              },
              {
                type: 'number',
                label: 'Height (inches)',
                field: 'height',
              },
            ],
          },
        ],
      },
      // {
      //   label: 'Medical Information',
      //   expandable: true,
      //   groups: [
      //     {
      //       columnsPerRow: 2,
      //       columns: [
      //         // multiSearchInput({
      //         //   label: 'Past medical condition',
      //         //   placeholder: 'Add past medical history here..',
      //         //   field: 'medical_history',
      //         //   query: 'medicalHistoryLists',
      //         //   model: 'MedicalHistories',
      //         //   valueField: 'name_en',
      //         //   suggestionField: 'name_en',
      //         //   searchField: 'name_en',
      //         //   editable: editable,
      //         // }),

      //         // multiSearchInput({
      //         //   label: 'Past surgical history',
      //         //   placeholder: 'Add Past surgical history here..',
      //         //   field: 'surgical_history',
      //         //   query: 'surgicalHistoryLists',
      //         //   model: 'SurgicalHistories',
      //         //   valueField: 'name',
      //         //   suggestionField: 'name',
      //         //   searchField: 'name',
      //         //   editable: editable,
      //         // }),\
      //         {
      //           type: 'otherAutoSuggest',
      //           label: 'Past medical condition',
      //           placeholder: 'Add past medical history here..',
      //           field: 'medical_history',
      //           query: 'medicalHistoryLists',
      //           model: 'MedicalHistories',
      //           valueField: 'name_en',
      //           suggestionField: 'name_en',
      //           searchField: 'name_en',
      //           editable: editable,
      //           otherField: 'medical_history_other',
      //         },
      //         {
      //           type: 'otherAutoSuggest',
      //           label: 'Past surgical history',
      //           placeholder: 'Add Past surgical history here..',
      //           field: 'surgical_history',
      //           query: 'surgicalHistoryLists',
      //           model: 'SurgicalHistories',
      //           valueField: 'name',
      //           suggestionField: 'name',
      //           searchField: 'name',
      //           editable: editable,
      //           otherField: 'surgical_history_other',
      //         },

      //         {
      //           type: 'otherAutoSuggest',
      //           label: 'Family history',
      //           placeholder: 'Add Family history here..',
      //           field: 'family_history',
      //           query: 'medicalHistoryLists',
      //           model: 'MedicalHistories',
      //           valueField: 'name_en',
      //           suggestionField: 'name_en',
      //           searchField: 'name_en',
      //           editable: editable,
      //           otherField: 'family_history_other',
      //         },
      //         {
      //           type: 'otherAutoSuggest',
      //           label: 'Social History',
      //           placeholder: 'Add Social history here..',
      //           field: 'social_history',
      //           query: 'socialHistoryLists',
      //           model: 'SocialHistories',
      //           valueField: 'name',
      //           suggestionField: 'name',
      //           searchField: 'name',
      //           editable: editable,
      //           otherField: 'social_history_other',
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   label: 'Allergies',
      //   expandable: true,
      //   groups: [
      //     {
      //       columnsPerRow: 2,
      //       columns: [
      //         {
      //           label: 'Allergies',
      //           placeholder: 'Add allergies here..',
      //           field: 'allergies',
      //           query: 'allergyLists',
      //           model: 'Allergies',
      //           valueField: 'name',
      //           suggestionField: 'name',
      //           searchField: 'name',
      //           type: 'otherAutoSuggest',
      //           otherField: 'allergies_other',
      //           editable: editable,
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   label: 'Medications',
      //   expandable: true,
      //   groups: [
      //     {
      //       columnsPerRow: 2,
      //       columns: [
      //         {
      //           type: 'otherAutoSuggest',
      //           placeholder: 'Add medications here' + '...',
      //           field: 'medications',
      //           query: 'drugLists',
      //           model: 'Drugs',
      //           valueField: 'name',
      //           otherField: 'medications_other',
      //           suggestionField: 'name',
      //           editable: editable,
      //         },
      //         // multiSearchInput({
      //         //   label: 'Medications',
      //         //   placeholder: 'Add medications here..',
      //         //   field: 'medications',
      //         //   query: 'drugLists',
      //         //   model: 'Drugs',
      //         //   valueField: 'name',
      //         //   suggestionField: 'name',
      //         //   searchField: 'name',
      //         //   editable: editable,
      //         // }),
      //       ],
      //     },
      //   ],
      // },
      // {
      //   label: 'Add Address',
      //   expandable: true,
      //   // expanded: true,
      //   columns: [
      //     {
      //       type: 'nestedList',
      //       field: 'address',
      //       addInBottom: true,
      //       formGroups: [
      //         {
      //           fieldVariant: 'filled',
      //           groups: [
      //             {
      //               columnsPerRow: 3,
      //               columns: [
      //                 autoSuggestInput({
      //                   label: 'District',
      //                   placeholder: 'Select District',
      //                   field: 'district',
      //                   valueField: 'value',
      //                   options: districts.map((item) => ({ label: item.name, value: item })),
      //                   suggestionField: 'label',
      //                 }),
      //                 space,
      //                 autoSuggestInput({
      //                   label: 'Block',
      //                   placeholder: 'Select Block',
      //                   field: 'block',
      //                   valueField: 'value',
      //                   options: ({ data }) => data?.district?.blocks?.map((item) => ({ label: item.name, value: item })),
      //                   suggestionField: 'label',
      //                   value: 'test'
      //                 }),
      //                 {
      //                   type: 'text',
      //                   label: 'Village',
      //                   field: 'village',
      //                 },
      //               ],
      //             },
      //             {
      //               width: 30,
      //               visible: () => {
      //                 return editable;
      //               },
      //               columns: [
      //                 {
      //                   type: 'custom',
      //                   render: (props) =>
      //                     closeButton(
      //                       props,
      //                       {
      //                         height: '100%',
      //                         cursor: 'pointer',
      //                       },
      //                       true,
      //                     ),
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  });
};
