import React from 'react';
import {View, Text} from '../../index';
import {FormField as ReactFormField} from '../../../npms/react-form';
import {inputTypes, renderTypes} from './types';
import fieldContainers from './FieldContainers';
import { getRenderComponent } from '../../UtilityFunctions';

const WithContainer = Component => {
  class WithContainerWrapper extends React.Component {
    render() {
      let {containers = fieldContainers, container, ...restProps} = this.props;

      let renderComponent = <Component {...restProps} />;
      if (container) {
        let ContainerComponent = container;
        if (typeof container === 'string') {
          ContainerComponent = containers && containers[container];
        }
        if (ContainerComponent) {
          renderComponent = getRenderComponent(ContainerComponent, {
            ...restProps,
            children: renderComponent,
          });
        }
      }
      return renderComponent;
    }
  }
  return WithContainerWrapper;
};

class FormFieldComponent extends React.Component {
  render() {
    let {
      types = inputTypes,
      renders = renderTypes,
      type,
      render,
      editable = true,
      children,
      Component,
      componentProps,
      ...restProps
    } = this.props;
    if (typeof editable === 'function') {
      editable = editable(restProps);
    }
    let renderComponentProps = {
      ...restProps,
      type,
      editable,
    };

    let formFieldComponent = null;
    if (type && typeof type === 'string') {
      let TypeComponent = editable
        ? types && types[type]
        : renders && renders[type];
      if (TypeComponent) {
        formFieldComponent = getRenderComponent(
          TypeComponent,
          renderComponentProps,
        );
      }
    }
    if (!formFieldComponent) {
      if (Component) {
        formFieldComponent = getRenderComponent(Component, {
          ...renderComponentProps,
          ...componentProps,
        });
      } else {
        formFieldComponent = getRenderComponent(
          render || children,
          renderComponentProps,
        );
      }
    }
    if (!formFieldComponent) {
      formFieldComponent = (
        <View>
          <Text>No Editor found</Text>
        </View>
      );
    }
    return formFieldComponent;
  }
}

FormFieldComponent = WithContainer(FormFieldComponent);

export class FormField extends React.PureComponent {
  render() {
    return (
      <ReactFormField {...this.props}>
        {(formFieldProps = {}) => {
          let {field} = formFieldProps;
          return <FormFieldComponent key={field} {...formFieldProps} />;
        }}
      </ReactFormField>
    );
  }
}

export default ({types, renders, containers} = {}) => {
  types = {
    ...inputTypes,
    ...types,
  };
  renders = {
    ...renderTypes,
    ...renders,
  };
  containers = {
    ...fieldContainers,
    ...containers,
  };
  class JSONFormField extends React.Component {
    render() {
      return (
        <FormField
          types={types}
          renders={renders}
          containers={containers}
          {...this.props}
        />
      );
    }
  }
  return JSONFormField;
};
