import React from 'react';
import HoverActions from './HoverActions';
import {detectMob} from './CardUtility';

export default Component => {
  class WithMobileHoverActions extends React.Component {
    render() {
      return <Component {...this.props} />;
    }
  }

  class WithWebHoverActions extends React.Component {
    render() {
      let {hoverActions, swipeActions, ...restProps} = this.props;
      let renderComponent = <Component {...restProps} />;
      if (!hoverActions && !swipeActions) {
        return renderComponent;
      }
      return (
        <>
          <Component {...restProps} />
          <HoverActions
            hoverActions={hoverActions}
            swipeActions={swipeActions}
            opacity={restProps.isHover ? 1 : 0}
            {...restProps}
          />
        </>
      );
    }
  }
  return detectMob() ? WithMobileHoverActions : WithWebHoverActions;
};
