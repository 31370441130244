import React from 'react';
import AppAutosuggestFilter from './AppAutosuggestFilter';
import {autosuggestFetch, getUser, getUserLocation} from '../../AppServices';
import {View} from '../../app-components';
import {TouchableOpacity, Image} from '../../screens/login/components';
import {locationActive, location, loctionPermission} from '../../images';
// import  MonthSelectFilter from "./MonthSelectFilter"
export const organisationNameFilter = ({screenState, setScreenState}) => (
  <AppAutosuggestFilter
    field="_id"
    fetch={autosuggestFetch({
      model: 'Organizations',
      id: 'organizationLists',
      search: 'organization_name',
      addOnFilter: {status: 'approved'},
    })}
    text="All Clinics"
    placeholder={'Write here'}
    screenState={screenState}
    setScreenState={setScreenState}
    sugestionField="organization_name"
    valueField="organization_name"
    searching={true}
  />
);

export const memberFilter = ({
  screenState,
  setScreenState,
  t = (text) => text,
}) => {
  return (
    <AppAutosuggestFilter
      field="isNoteGenerated"
      screenState={screenState}
      setScreenState={setScreenState}
      options={[
        {label: 'Note Generated', value: true},
        {label: 'Without Note Generated', value: false},
      ]}
      onFilterChange={(props) => {
        const modifiedFilter = {
          filters: {
            isNoteGenerated: props?.filters?.isNoteGenerated?.value?.value,
          },
        };
        return modifiedFilter;
      }}
      // asParams
      text="Filter By Screening"
      keyField="value"
      sugestionField="label"
      valueField="value"
    />
  );
};

export const TestResultOrderFilter = ({
  screenState,
  setScreenState,
  t = (text) => text,
}) => {
  return (
    <AppAutosuggestFilter
      field="testResultArriveDate"
      screenState={screenState}
      setScreenState={setScreenState}
      options={[
        {label: 'Result arrived', value: {$exists: true}},
        {label: 'Result not arrived', value: {$exists: false}},
      ]}
      asParams
      text="Result Status"
      keyField="label"
      sugestionField="label"
      valueField="label"
    />
  );
};
export const MemberGraphYearFilter = ({
  screenState,
  setScreenState,
  t = (text) => text,
}) => {
  return (
    <AppAutosuggestFilter
      field="MemberGraphYear"
      screenState={screenState}
      setScreenState={setScreenState}
      options={[
        {label: '2022', value: {$exists: true}},
        {label: '2021', value: {$exists: false}},
      ]}
      asParams
      text="Change Year"
      keyField="label"
      sugestionField="label"
      valueField="label"
    />
  );
};

export const FilterVillageByBlock = ({screenState, setScreenState}) => {
  return (
    <AppAutosuggestFilter
      field="block"
      fetch={autosuggestFetch({
        model: 'Blocks',
        id: 'blockList',
        search: 'name',
      })}
      text="Filter by block"
      placeholder={'Write here'}
      screenState={screenState}
      setScreenState={setScreenState}
      sugestionField="name"
      valueField="name"
      searchField="name"
      searching={true}
    />
  );
};

export const FilterVillageByDistrict = ({screenState, setScreenState}) => {
  return (
    <AppAutosuggestFilter
      field="block.district"
      fetch={autosuggestFetch({
        model: 'Districts',
        id: 'districtList',
        search: 'name',
      })}
      text="Filter by district"
      placeholder={'Write here'}
      screenState={screenState}
      setScreenState={setScreenState}
      sugestionField="name"
      valueField="name"
      searchField="name"
      searching={true}
    />
  );
};

export const FilterByVillage = ({ screenState, setScreenState }) => {
  return (
    <AppAutosuggestFilter
      field="_id"
      fetch={autosuggestFetch({
        model: 'Villages',
        id: 'villageList',
        search: 'name',
      })}
      text="Filter by village"
      placeholder={'Write here'}
      screenState={screenState}
      setScreenState={setScreenState}
      sugestionField="name"
      valueField="name"
      searchField="name"
      searching={true}
    />
  );
};

export const VillageFilterOnCitizenList = ({ screenState, setScreenState, fieldName }) => {
  return (
    <AppAutosuggestFilter
      field={fieldName}
      fetch={autosuggestFetch({
        model: 'Villages',
        id: 'villageList',
        search: 'name',
      })}
      text="Village"
      placeholder={'Write here'}
      screenState={screenState}
      setScreenState={setScreenState}
      sugestionField="name"
      valueField="name"
      searchField="name"
      searching={true}
      filterField="_id"
    />
  );
};

export const specilityNameFilter = ({
  screenState,
  setScreenState,
  t = (text) => text,
}) => {
  // console.log('@ search for doctors ', t);
  return (
    <AppAutosuggestFilter
      field={'speciality'}
      screenState={screenState}
      setScreenState={setScreenState}
      fetch={autosuggestFetch({
        id: 'getSpecialityList',
        search: 'name',
      })}
      text={t('All Specialities')}
      placeholder={t('Write here')}
      sugestionField="name"
      valueField="name"
      searching={true}
    />
  );
};

export const clinicFilter = ({
  screenState,
  setScreenState,
  t = (text) => text,
}) => {
  return (
    <AppAutosuggestFilter
      field={'organization'}
      screenState={screenState}
      setScreenState={setScreenState}
      fetch={autosuggestFetch({
        id: 'getClinicList',
        search: 'organization_name',
      })}
      text={t('All Clinics')}
      placeholder={t('Write here')}
      sugestionField="organization_name"
      valueField="organization_name"
      searching={true}
    />
  );
};

export const patientNameFilter = ({
  screenState,
  setScreenState,
  field = 'patient',
  t = (text) => text,
}) => {
  return (
    <AppAutosuggestFilter
      field={field}
      screenState={screenState}
      setScreenState={setScreenState}
      fetch={autosuggestFetch({
        model: 'Patients',
        id: 'patientLists',
        search: 'name',
      })}
      text="All Patients"
      placeholder={'Search Patient name'}
      sugestionField="name"
      valueField="name"
      searching={true}
    />
  );
};

export const patientNumberFilter = ({
  screenState,
  setScreenState,
  field = 'patient',
}) => {
  return (
    <AppAutosuggestFilter
      field={field}
      screenState={screenState}
      setScreenState={setScreenState}
      fetch={autosuggestFetch({
        model: 'Patients',
        id: 'patientLists',
        search: 'mobile',
      })}
      text="All Patients Mobile"
      placeholder={'Search Patient mobile'}
      sugestionField="mobile"
      valueField="mobile"
      searching={true}
    />
  );
};
export const patientAdharFilter = ({
  screenState,
  setScreenState,
  field = 'patient',
}) => {
  return (
    <AppAutosuggestFilter
      field={field}
      screenState={screenState}
      setScreenState={setScreenState}
      fetch={autosuggestFetch({
        model: 'Patients',
        id: 'patientLists',
        search: 'uniqueId',
      })}
      text="All Patients unique"
      placeholder={'Search Patient uniqueId'}
      sugestionField="uniqueId"
      valueField="uniqueId"
      searching={true}
    />
  );
};

export const statusFilter = ({screenState, setScreenState}) => {
  return (
    <AppAutosuggestFilter
      field={'activity_status'}
      screenState={screenState}
      setScreenState={setScreenState}
      options={[
        {label: 'Active', value: 'active'},
        {label: 'Inactive', value: 'inactive'},
      ]}
      text="All Patients"
      keyField="value"
      placeholder={'Search Patient name'}
      sugestionField="label"
      valueField="value"
      searching={true}
    />
  );
};

// export const monthFilter=(
//   <MonthSelectFilter/>
// )

export const locationFilter = (props) => {
  let {screenState: {dataParams} = {}, setScreenState, asParams} = props;
  let {filters} = dataParams || {};
  let {i18n} = props || {};
  // const {location: {filter: {value = {}} = {}} = {}} = filters || {};
  const {nearby: {filter: {value = false} = {}} = {}} = filters || {};
  const {data: {coords = {}} = {}, Allowed = false} = getUserLocation() || {};
  let lon = {};
  if (!value?.latitude) {
    lon = {
      latitude: coords.latitude,
      longitude: coords.longitude,
    };
  }
  let arabicStyle = {};
  if (i18n.language === 'ar') {
    arabicStyle.marginRight = 10;
  }
  const applyFilter = () => {
    // filters = {
    //   ...filters,
    //   ['location']: {
    //     // value:"hello",
    //     filter: {
    //       asParams,
    //       // filterType: 'autosuggest',
    //       value: {...lon},
    //     },
    //   },
    // };
    filters = {
      ...filters,
      ['nearby']: {
        // value:"hello",
        filter: {
          asParams,
          // filterType: 'autosuggest',
          value: !value,
        },
      },
    };
    setScreenState({
      dataParams: {
        ...dataParams,
        filters,
      },
    });
  };
  return (
    <View
      style={{
        backgroundColor: '#fff',
        padding: 4,
        borderRadius: 8,
        ...arabicStyle,
      }}>
      {Allowed ? (
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            applyFilter();
          }}>
          <Image source={value ? locationActive : location} />
        </TouchableOpacity>
      ) : (
        <TouchableOpacity style={{cursor: 'not-allowed'}}>
          <Image source={loctionPermission} />
        </TouchableOpacity>
      )}
    </View>
  );
};

export const DeviceFilter = ({screenState, setScreenState}) => {
  return (
    <AppAutosuggestFilter
      field={'addMonitoringParameter'}
      screenState={screenState}
      setScreenState={setScreenState}
      fetch={autosuggestFetch({
        model: 'Devices',
        id: 'devicesList',
        search: 'deviceName',
      })}
      text="All Patients"
      placeholder={'Search Patient name'}
      sugestionField="deviceName"
      valueField="deviceName"
      filterType="deviceFilter"
      searching={true}
    />
  );
};

export const DiseaseFilter = ({screenState, setScreenState}) => (
  <AppAutosuggestFilter
    field="chronicDisease"
    fetch={autosuggestFetch({
      model: 'Diagnosis',
      id: 'diagnoseLists',
      search: 'name',
    })}
    varient="curved"
    text="All Diseases"
    placeholder={'Write here'}
    screenState={screenState}
    setScreenState={setScreenState}
    sugestionField="name"
    valueField="name"
    searchField="name"
    searching={true}
  />
);
export const MrnFilter = ({screenState, setScreenState}) => (
  <AppAutosuggestFilter
    field="mrn"
    fetch={autosuggestFetch({
      model: 'Patients',
      id: 'patientLists',
      search: 'mrn',
    })}
    text="All MRN No."
    placeholder={'Write here'}
    screenState={screenState}
    setScreenState={setScreenState}
    sugestionField="mrn"
    valueField="mrn"
    searchField="mrn"
    searching={true}
  />
);
export const PatientNameFilter = ({screenState, setScreenState}) => (
  <AppAutosuggestFilter
    field="name"
    fetch={autosuggestFetch({
      model: 'Patients',
      id: 'patientLists',
      search: 'name',
    })}
    text="All Patients"
    placeholder={'Write here'}
    screenState={screenState}
    setScreenState={setScreenState}
    sugestionField="name"
    valueField="name"
    searchField="name"
    // keyField="name"
    searching={true}
  />
);
export const doctorFilter = ({screenState, setScreenState}) => (
  <AppAutosuggestFilter
    field="primaryDoctor"
    fetch={autosuggestFetch({
      model: 'Doctor',
      id: 'doctorLists',
      search: 'name',
    })}
    text="All Doctor"
    placeholder={'Write here'}
    screenState={screenState}
    setScreenState={setScreenState}
    sugestionField="name"
    valueField="name"
    searchField="name"
    searching={true}
  />
);

export const ParameterFilter = ({screenState, setScreenState}) => {
  return (
    <AppAutosuggestFilter
      field={'monitoringParameter.parameter'}
      screenState={screenState}
      setScreenState={setScreenState}
      fetch={autosuggestFetch({
        model: 'MonitoringParameter',
        id: 'parameterList',
        search: 'name',
      })}
      varient="curved"
      text="All Parameter"
      placeholder={'Search Parameter here...'}
      sugestionField="name"
      valueField="name"
      searching={true}
    />
  );
};
export const genderFilter = ({screenState, setScreenState}) => {
  return (
    <AppAutosuggestFilter
      field="gender"
      screenState={screenState}
      setScreenState={setScreenState}
      options={[
        {label: 'Male', value: 'Male'},
        {label: 'Female', value: 'Female'},
        {label: 'Other', value: 'Other'},
      ]}
      asParams
      text="All Genders"
      keyField="label"
      sugestionField="label"
      valueField="label"
    />
  );
};

export const flagFilter = ({screenState, setScreenState, fieldName}) => {
  return (
    <AppAutosuggestFilter
      field={fieldName}
      varient="curved"
      screenState={screenState}
      setScreenState={setScreenState}
      options={[
        {label: 'Urgent', value: 'Red'},
        {label: 'Risk', value: 'Orange'},
        {label: 'Normal', value: 'Green'},
      ]}
      filterField="value"
      // asParams
      text="Flag"
      keyField="label"
      sugestionField="label"
      valueField="label"
    />
  );
};

export const branchNameFilter = ({screenState, setScreenState}) => {
  return (
    <AppAutosuggestFilter
      field="clinicLocation"
      fetch={autosuggestFetch({
        model: 'OrganizationLocations',
        id: 'organtzationLocationsLists',
        search: 'branchName',

        addOnFilter: () => {
          const {organization} = getUser() || {};
          return {
            organization,
          };
        },
      })}
      text="All Branches"
      placeholder={'Write here'}
      screenState={screenState}
      setScreenState={setScreenState}
      sugestionField="branchName"
      valueField="branchName"
      searchField="branchName"
      // keyField="name"
      varient="curved"
      searching={true}
    />
  );
};

export const educationContentFilter = ({screenState, setScreenState}) => {
  return (
    <AppAutosuggestFilter
      field="educationCategory"
      fetch={autosuggestFetch({
        model: 'EducationCategory',
        id: 'EducationCategoryList',
        search: 'name',
      })}
      text="Education Category"
      placeholder={'Write here'}
      screenState={screenState}
      setScreenState={setScreenState}
      sugestionField="name"
      valueField="name"
      searchField="name"
      // keyField="name"
      varient="curved"
      searching={true}
    />
  );
};
