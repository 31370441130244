import {Text, View} from '../index';
import React, {useState, useEffect} from 'react';
import {h12_Regular} from '../../theme/fonts';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const MobileInput = (props) => {
  const {
    label = 'Mobile',
    labelStyle,
    labelProps,
    value="+91",
    setValue = () => {},
    field = 'mobile',
    data,
    inputStyle,
    mobileInputStyle,
    inputProps,
    error,
    errorStyle,
    errorProps,
    disabled,
    ...restProps
  } = props || {};

  useEffect(() => {
    if (value && typeof value === 'string') {
      if (!value.startsWith('+91')) {
        setSelectedMask('IN');
      }
    }
  }, [value]);
  const [isMobileOnFocus, setIsMobileOnFocus] = useState(false);
  const [selectedMask, setSelectedMask] = useState('IN');
  const onChange = (value) => {
    setValue({field: field, value, data});
  };

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <ReactPhoneInput
          value={value || ''}
          containerStyle={{}}
          onChange={(value, country, e, formattedValue) => {
            onChange(`+${value}`);
            // onKeyPress(e);
          }}
          disabled={disabled}
          searchClass="search-class"
          searchStyle={{margin: '0', width: '97%', height: '30px'}}
          enableSearch
          disableSearchIcon
          inputStyle={{width: '100%'}}
          dropdownStyle={{height: 170,position:"fixed"}}
          country="us"
          masks={{in: '..... .....'}}
          preferredCountries={['us', 'in']}
          onFocus={() => {
            setIsMobileOnFocus(true);
          }}
          onBlur={() => setIsMobileOnFocus(false)}
        />
      </View>
      {error && (
        <Text
          style={{...h12_Regular, color: 'rgb(255, 129, 129)', ...errorStyle}}
          {...errorProps}>
          {error}
        </Text>
      )}
    </>
  );
};

export default MobileInput;
