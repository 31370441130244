import React from 'react';
import moment from 'moment';

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Snackbar,
} from '../../app-components';
import {getImageUrl} from '../../AppServices';
import {h14_Regular, h14_Medium} from '../../theme/fonts';
import {downloadChatIcon, pdfChatIcon, VideoActive} from '../../images';
import {bgs, colors} from '../../theme/colors';
import Avatar from './Avatar';

const {ownMessageBg} = bgs;
const {darkTextColor, textColor87} = colors;

const downloadRender = ({
  pdfName = 'pdf',
  pdfUrl,
  messageType,
  timeBoxStyle,
  date,
}) => {
  if (!pdfUrl) {
    return null;
  }
  return (
    <TouchableOpacity
      style={{
        borderRadius: 8,
        paddingTop: 10,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#e7eef5',
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Image
          source={messageType === 'video' ? VideoActive : pdfChatIcon}
          style={{objectFit: 'contain',...(messageType=="video"?{height:30,width:30,marginRight:5}:{})}}
        />
        <Text
          style={{
            color: textColor87,
            ...h14_Medium,
            width: 200,
            numberOfLines: 1,
            paddingTop: 5,
          }}>
          {pdfName}
        </Text>
        <a
          download={pdfName}
          target={'_blank'}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 5,
            cursor: 'pointer',
          }}
          href={pdfUrl}
          onClick={(e) => e && e.stopPropagation && e.stopPropagation()}>
          <Image title="Download" source={downloadChatIcon} />
        </a>
      </View>
      <Text
        style={{
          ...timeBoxStyle,
          paddingTop: 5,
        }}>
        {getChatTimeString(date)}
      </Text>
    </TouchableOpacity>
  );
};

export const RenderMessagefromOthers = ({
  item,
  style = {},
  invertedCardStyle,
  navigation,
}) => {
  if (!item) {
    return null;
  }
  let {
    text: message,
    _createdOn: date,
    user,
    messageType,
    fileName,
    fileUrl,
    thumbnail,
  } = item || {};
  let avatar_image = void 0;
  let avatar_name = '';
  if (user) {
    avatar_image = user?.avatar;
    avatar_name = user?.name || '';
  }
  const imageObj =
    messageType == 'image' && fileUrl && thumbnail
      ? {
          fileName,
          fileUrl,
          thumbnail,
        }
      : {};
  let {othersMessageContainerStyle, avatarStyle} = style;
  let {
    boxStyle,
    messageBubbleStyle,
    messageContainerStyle,
    timeBoxStyle,
  } = othersMessageContainerStyle;

  if (invertedCardStyle) {
    boxStyle = boxStyle || {};
    boxStyle = {...boxStyle, ...invertedCardStyle};
  }
  avatar_image = avatar_image && getImageUrl(avatar_image?.thumbnail);
  const imageUrl = (messageType == 'image' && getImageUrl(fileUrl)) || void 0;
  const isPdf = (messageType == 'pdf' || messageType === 'video')  && fileUrl && fileName ? true : false;
  let bubbleColor = messageType != 'undefined' ? '#e7eef5' : 'white';
  return (
    <View style={{flexDirection: 'row', ...boxStyle}}>
      <Avatar icon={avatar_image} text={avatar_name} theme={avatarStyle} />
      <View
        style={{
          ...messageBubbleStyle,
        }}
      />
      <View style={{...messageContainerStyle.containerStyle}}>
        {imageUrl ? (
          <TouchableOpacity
            style={{
              cursor: 'pointer',
              borderWidth: 1,
              borderColor: '#e7eef5',
              'border-top-right-radius': 5,
              'border-bottom-right-radius': 5,
              'border-bottom-left-radius': 5,
              backgroundColor: 'rgba(233, 233, 233, 1)',
            }}
            onPress={() =>
              navigation &&
              navigation.push({
                view: 'show-file',
                params: imageObj,
                modal: true,
                modalProps: {
                  autoHide: true,
                  position: 'fullScreen',
                  fullScreenStyle: {flex: 1, zIndex: 9},
                },
              })
            }>
            <Image
              source={getImageUrl(thumbnail)}
              style={{objectFit: 'contain', width: 100, height: 100}}
            />
            <Text
              style={{
                ...timeBoxStyle.timeTextStyle,
                paddingTop: 4,
                alignSelf: 'flex-start',
              }}>
              {getChatTimeString(date)}
            </Text>
          </TouchableOpacity>
        ) : isPdf ? (
          downloadRender({
            pdfName: fileName,
            pdfUrl: getImageUrl(fileUrl),
            date,
            messageType,
            timeBoxStyle: {
              ...timeBoxStyle.timeTextStyle,
              paddingTop: 4,
              alignSelf: 'flex-start',
            },
          })
        ) : (
          <View
            style={{
              ...messageContainerStyle.boxStyle,
              paddingBottom: 4,
            }}>
            <Text
              style={{
                ...messageContainerStyle.msgTextStyle,
                ...h14_Regular,
                color: darkTextColor,
                width: '100%',
                'word-break': 'break-all',
              }}>
              {message}
            </Text>
            <Text
              style={{
                ...timeBoxStyle.timeTextStyle,
                paddingTop: 4,
                alignSelf: 'flex-start',
              }}>
              {getChatTimeString(date)}
            </Text>
          </View>
        )}
        {/* <View style={{...timeBoxStyle.boxStyle}}>
          <Text style={{...timeBoxStyle.timeTextStyle}}>
            {getChatTimeString(date)}
          </Text>
        </View> */}
      </View>
    </View>
  );
};

export const RenderMyOwnMessage = ({
  navigation,
  item,
  style = {},
  invertedCardStyle,
}) => {
  if (!item) {
    return null;
  }
  let {
    text: message,
    _createdOn: date,
    user,
    messageType,
    fileName,
    thumbnail,
    fileUrl,
  } = item || {};
  let avatar_image = void 0;
  let avatar_name = '';
  if (user) {
    avatar_image = user?.avatar;
    avatar_name = user?.name || '';
  }

  avatar_image = avatar_image && getImageUrl(avatar_image?.thumbnail);

  let {avatarStyle, ownMessageContainerStyle} = style;
  let {
    boxStyle,
    messageContainerStyle,
    messageBubbleStyle,
    timeBoxStyle,
  } = ownMessageContainerStyle;
  if (invertedCardStyle) {
    boxStyle = boxStyle || {};
    boxStyle = {...boxStyle, ...invertedCardStyle};
  }
  const imageObj =
    messageType == 'image' && fileUrl && thumbnail
      ? {
          fileName,
          fileUrl,
          thumbnail,
        }
      : {};
  const isPdf =
    (messageType == 'pdf' || messageType === 'video') && fileUrl && fileName
      ? true
      : false;
  let bubbleColor = messageType == 'pdf' ? 'white' : ownMessageBg;
  return (
    <View style={{flexDirection: 'row', ...boxStyle}}>
      <View style={{...messageContainerStyle.containerStyle}}>
        {imageObj?.thumbnail ? (
          <TouchableOpacity
            style={{
              cursor: 'pointer',
              borderWidth: 1,
              borderColor: '#e7eef5',
              'border-top-left-radius': 5,
              'border-bottom-right-radius': 5,
              'border-bottom-left-radius': 5,
              backgroundColor: 'rgba(233, 233, 233, 1)',
            }}
            onPress={() =>
              navigation &&
              navigation.push({
                view: 'show-file',
                params: imageObj,
                modal: true,
                modalProps: {
                  autoHide: true,
                  position: 'fullScreen',
                  fullScreenStyle: {flex: 1, zIndex: 9},
                },
              })
            }>
            <Image
              source={getImageUrl(fileUrl)}
              style={{objectFit: 'contain', width: 100, height: 100}}
            />
            <Text
              style={{
                ...timeBoxStyle.timeTextStyle,
                paddingTop: 3,
                alignSelf: 'flex-end',
              }}>
              {getChatTimeString(date)}
            </Text>
          </TouchableOpacity>
        ) : isPdf ? (
          downloadRender({
            pdfName: fileName,
            pdfUrl: getImageUrl(fileUrl),
            messageType,
            date,
            timeBoxStyle: {
              ...timeBoxStyle.timeTextStyle,
              paddingTop: 3,
              alignSelf: 'flex-end',
            },
          })
        ) : (
          <View
            style={{
              ...messageContainerStyle.boxStyle,
              paddingBottom: 4,

              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}>
            <Text
              style={{
                ...messageContainerStyle.msgTextStyle,
                ...h14_Regular,
                color: darkTextColor,
                width: '100%',
                'word-break': 'break-all',
              }}>
              {message}
            </Text>
            <Text
              style={{
                ...timeBoxStyle.timeTextStyle,
                paddingTop: 3,
                alignSelf: 'flex-end',
              }}>
              {getChatTimeString(date)}
            </Text>
          </View>
        )}
        {/* <View style={{...timeBoxStyle.boxStyle}}>
          <Text style={{...timeBoxStyle.timeTextStyle}}>
            {getChatTimeString(date)}
          </Text>
        </View> */}
      </View>
      <View
        style={{
          ...messageBubbleStyle,
          borderTopColor: bubbleColor,
          borderLeftColor: bubbleColor,
        }}
      />
      <Avatar icon={avatar_image} theme={avatarStyle} text={avatar_name} />
    </View>
  );
};

const getChatTimeString = (date) => {
  let currentDate = new Date();
  let chatDate = new Date(date);
  if (chatDate.setHours(0, 0, 0, 0) == currentDate.setHours(0, 0, 0, 0)) {
    return moment(date).fromNow();
  } else return moment(date).format('lll');
};

export const showError = ({err}) => {
  let message = (err && err.message) || '';
  Snackbar.show({
    text: `${message}`,
    duration: Snackbar.LENGTH_SHORT,
  });
};
