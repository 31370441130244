import educationContentForm from './educationContentForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Education Content',
  actions: [cancelButton(), save()],
};

let uri = ({navigation}) => {
  return {
    query: {
      id: 'EducationContentList',
      relationValue: {
        model: 'EducationContents',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'EducationContents',
  };
};
export default educationContentForm({uri, editable: true, header});
