import React from 'react';
import PaymentRazorpay from './PaymentRazorpay';
import PaymentStripe from './PaymentStripe';
import PaymentWithZero from './PaymentWithZero';

const PaymentType = (props) => {
  const {navigation = {}} = props || {};
  const {
    state: {
      params: {item: {organization = {}, cost_of_booking = 0} = {}} = {},
    } = {},
  } = navigation || {};
  const {payment_gateway: {name = ''} = {}} = organization || {};
  if (cost_of_booking == 0) {
    return <PaymentWithZero {...props} />;
  } else if (name === 'RazorPay') {
    return <PaymentRazorpay {...props} />;
  }
  return <PaymentStripe {...props} />;
};
export default PaymentType;
