import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {PLACEHOLDER} from '../../app-components/utils';

export default ({uri, header}) => {
  const SymptomForm = Form({
    type: 'standardShadow',
    header,
    uri,
    closeView: 1,
    reloadEvent: 'reloadSymptom',
    onSubmit: submit({
      model: 'Symptoms',
    }),
    mandatory: {name: 1},
    formGroups: [
      {
        columnsPerRow: 1,
        fieldVariant: 'filled',
        fieldContainer: 'topLabel',
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'name',
                label: 'Symptom Name',
                placeholder: PLACEHOLDER,
                mandatory: true,
                width:'50%'
              },
            ],
          },
        ],
      },
    ],
  });
  return SymptomForm;
};
