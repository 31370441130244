import React from 'react';
import {View, Text, Image} from '../../react-core-components';
import WithHover from './WithHover';

let StandardDropDownItem = (props) => {
  let {
    icon,
    hoverIcon,
    activeIcon,
    text,
    visible = true,
    action,
    item = {},
    index,
    closeModal,
    user,
    navigation,
    eventDispatcher,
    Action,
    highlighted,
    showSelected,
    showIcon,
    selectedIcon,
    isHover,
    onSelect,
    containerStyle,
    hoverContainerStyle,
    activeContainerStyle,
    iconContainerStyle,
    iconStyle,
    textStyle,
    hoverTextStyle,
    activeTextStyle,
    selectedIconContainerStyle,
    selectedIconStyle,
  } = props;
  if (visible && typeof visible === 'function') {
    visible = visible({item, navigation, eventDispatcher, user});
  }
  if (!visible) {
    return null;
  }
  if (highlighted && typeof highlighted === 'function') {
    highlighted = highlighted({item});
  }
  if (isHover) {
    icon = hoverIcon || icon;
    containerStyle = {
      ...containerStyle,
      ...hoverContainerStyle,
    };
    textStyle = {
      ...textStyle,
      ...hoverTextStyle,
    };
  } else if (highlighted) {
    containerStyle = {
      ...containerStyle,
      ...activeContainerStyle,
    };
  }
  if (highlighted) {
    icon = activeIcon || icon;
    textStyle = {
      ...textStyle,
      ...activeTextStyle,
    };
  }
  return (
    <Action
      key={index}
      style={{
        ...containerStyle,
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
      }}
      navigation={navigation}
      eventDispatcher={eventDispatcher}
      onSelect={
        onSelect
          ? (rowProps) => {
              onSelect({...props});
            }
          : void 0
      }
      action={action}
      closeModal={closeModal}
      item={item}>
      {showIcon ? (
        <View style={iconContainerStyle}>
          {icon ? (
            <Image style={iconStyle} source={icon} resizeMode="contain" />
          ) : (
            void 0
          )}
        </View>
      ) : (
        void 0
      )}
      <View style={{flex: 1, overflow: 'hidden'}}>
        <Text style={textStyle}>{text || ''}</Text>
      </View>
      {showSelected && selectedIcon ? (
        <View style={selectedIconContainerStyle}>
          {highlighted ? (
            <Image
              style={selectedIconStyle}
              source={selectedIcon}
              resizeMode="contain"
            />
          ) : (
            void 0
          )}
        </View>
      ) : (
        void 0
      )}
    </Action>
  );
};

StandardDropDownItem = WithHover(StandardDropDownItem);

export default StandardDropDownItem;
