import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import ReactApexChart from 'react-apexcharts';
import './VillageGraph.css';
import get from 'lodash/get';
import {fetch} from '../../../../AppServices';
import {ActivityIndicator} from '../../../../app-components';
import {monthLabels} from '../../../../Lib/constants';
import {
  yearlyFlaggingLabels,
  dataForBarGraphFilter,
  dataForFlaggingFilter,
  yearData,
} from './constants';
import {
  currentMonth,
  currentYear,
  getLastTwelveMonthsLabels,
} from '../../../../Utility/getLastYears/getLastYearsAndMonth';
import CustomDropDown from './CustomDropDown';
import {ToggleSwitch} from '../../../../npms/react-toggle';
import {loaderTheme, barChartOptions, getDataUri, radialBarOptions, familyBarPercentage } from './chartTools';
import {getAllDataTillDate, getBarData, getFamilyPercentageSeries} from './utils';
import { exportPdfIcon } from '../../../../images';

let barChartDataLabels = {
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val > 0 ? Number(val).toLocaleString() : '';
    },
    offsetX: 0,
    offsetY: 0,
    horizontal: false,
    style: {
      fontFamily: 'system-ui',
      fontWeight: 400,
      fontSize: '10px',
      colors: ['black'],
    },
  },
};

const VillageGraph = (props) => {
  let treeMapSeriesData = useRef([]);
  const [treeMapData, setTreeMapData] = useState([]);
  const [selectedBarGraphData, setSelectedBarGraphData] = useState([]);
  const [totalPopulationData, setTotalPopulationData] = useState({})
  const [loaderForBarGraph, setLoaderForBarGraph] = useState(false);
  const [loaderForTreeMap, setLoaderForTreeMap] = useState(false);
  const [currentMapFor, setCurrentMapFor] = useState('district');
  const [barChartGlobalInfo, setBarChartGlobalInfo] = useState({
    isBlockSelected: false,
    isVillageSelected: false,
    treeClickVal: -1,
    selectedYear: currentYear,
  });

  const [barGraphFilter, setBarGraphFilter] = useState('Registration');
  const [flaggingFilter, setFlaggingFilter] = useState('Screening');
  const [flaggingYearlyFilter, setFlaggingYearlyFilter] = useState(false);

  const getTreeMapData = useCallback(async () => {
    try {
      setLoaderForTreeMap(true);
      let filterInfo = {};
      let queryId = 'globalCountDistrictAggregateLists';
      if (currentMapFor === 'block') {
        filterInfo = {
          ...filterInfo,
          district: {
            _id: treeMapData[barChartGlobalInfo.treeClickVal]?.district?._id,
          },
        };
        queryId = 'globalCountBlockAggregateLists';
      } else if (currentMapFor === 'village') {
        filterInfo = {
          ...filterInfo,
          block: {
            _id: treeMapData[barChartGlobalInfo.treeClickVal]?.block?._id,
          },
        };
        queryId = 'globalVillageMemberCountList';
      }

      const uri = {
        token: (await get('token')) || null,
        props: {
          query: {
            id: queryId,
            addOnFilter: filterInfo,
          },
          model: 'GlobalVillageMemberCount',
        },
      };
      const data = await fetch({uri});
      if (data?.data) {
        setTreeMapData(data?.data.sort(function(b,a) {return a.totalMember - b.totalMember}));
        setLoaderForTreeMap(false);
      }
    } catch (error) {
      console.log('@eror', error);
      setLoaderForTreeMap(false);
    }
  }, [currentMapFor]);

  useEffect(() => {
    getTreeMapData();
  }, [currentMapFor]);

  useEffect(() => {
    (async () => {
      try {
        setLoaderForBarGraph(true);

        barChartOptions.xaxis.categories =
          barChartGlobalInfo.selectedYear == 'Last 12 months'
            ? getLastTwelveMonthsLabels()
            : monthLabels;

        let filterInfo = {};
        if (
          barChartGlobalInfo.selectedYear &&
          barChartGlobalInfo.selectedYear != 'All' &&
          barChartGlobalInfo.selectedYear != 'Last 12 months'
        ) {
          filterInfo = {year: barChartGlobalInfo.selectedYear};
        } else if (barChartGlobalInfo.selectedYear == 'Last 12 months') {
          filterInfo = {
            $or: [
              {
                year: currentYear,
                month: {
                  $in: [...monthLabels].slice(0, currentMonth + 1),
                },
              },
              {
                year: currentYear - 1,
                month: {
                  $in: [...monthLabels].slice(currentMonth + 1),
                },
              },
            ],
          };
        }
        if (currentMapFor === 'block') {
          filterInfo = {
            ...filterInfo,
            district: {
              _id:
                treeMapData[barChartGlobalInfo.treeClickVal]?.block?.district
                  ?._id ||
                treeMapData[barChartGlobalInfo.treeClickVal]?.district?._id,
            },
          };
        } else if (
          currentMapFor === 'village' &&
          !barChartGlobalInfo.isVillageSelected
        ) {
          filterInfo = {
            ...filterInfo,
            block: {
              _id: treeMapData[barChartGlobalInfo.treeClickVal]?.block?._id,
            },
          };
        } else if (
          currentMapFor === 'village' &&
          barChartGlobalInfo.isVillageSelected
        ) {
          filterInfo = {
            ...filterInfo,
            village: {
              _id: treeMapData[barChartGlobalInfo.treeClickVal]?.village?._id,
            },
          };
        }
        const uri = {
          token: (await get('token')) || null,
          props: {
            query: {
              id: 'villageMemberCountList',
              addOnFilter: filterInfo,
            },
            model: 'VillageMemberCount',
          },
        };
        const data = await fetch({uri});

        setSelectedBarGraphData(data?.data);
        setLoaderForBarGraph(false);
      } catch (error) {
        console.log('@eror', error);
        setLoaderForBarGraph(false);
      }
    })();
  }, [barChartGlobalInfo]);

  useEffect(() => {
    (async () => {
      try {
        barChartOptions.xaxis.categories =
          barChartGlobalInfo.selectedYear == 'Last 12 months'
            ? getLastTwelveMonthsLabels()
            : monthLabels;

        let filterInfo = {};

        if (currentMapFor === 'block') {
          filterInfo = {
            block: {
              district: {
                _id:
                  treeMapData[barChartGlobalInfo.treeClickVal]?.block?.district?._id ||
                  treeMapData[barChartGlobalInfo.treeClickVal]?.district?._id,
              },
            }
          };
        } else if (currentMapFor === 'village' && !barChartGlobalInfo.isVillageSelected) {
          filterInfo = {
            block: { _id: treeMapData[barChartGlobalInfo.treeClickVal]?.block?._id, },
          };
        } else if (currentMapFor === 'village' && barChartGlobalInfo.isVillageSelected) {
          filterInfo = {
            _id: treeMapData[barChartGlobalInfo.treeClickVal]?.village?._id,
          };
        }

        const uri = {
          token: (await get('token')) || null,
          props: {
            query: {
              id: 'populationAggregateVillageLists',
              addOnFilter: {
                ...filterInfo
              },
            },
            model: 'Villages',
          },
        };
        const data = await fetch({ uri });
        setTotalPopulationData(data.data[0]);
      } catch (error) {
        console.log('@eror', error);
      }
    })();
  }, [barChartGlobalInfo]);

  let getChartTitle = (chartName) => {
    return ` ${chartName == "mainBarChart" ? barGraphFilter : chartName} data of ${barChartGlobalInfo.isVillageSelected ? treeMapData[barChartGlobalInfo.treeClickVal]?.village?.name + ' Village ': barChartGlobalInfo.isBlockSelected ? selectedBarGraphData[0]?.block?.name + ' block ' : selectedBarGraphData[0]?.district?.name + ' District '} ${barChartGlobalInfo.selectedYear && barChartGlobalInfo.selectedYear != 'All ' ? 'in ' : ''} ${barChartGlobalInfo.selectedYear &&barChartGlobalInfo.selectedYear != 'All'? barChartGlobalInfo.selectedYear: ''}                       `
  }

  const temp = useMemo(() => {
    treeMapSeriesData.current = [];
    if (treeMapData?.length) {
      treeMapData.forEach((ele) => {
        const x = ele[currentMapFor]?.name;
        if (x) {
          treeMapSeriesData.current.push({
            x: ele[currentMapFor]?.name,
            y: ele?.totalMember,
            maleCount: ele?.totalMale || "",
            femaleCount: ele?.totalFemale || "",
          });
        }
      });
    }
  }, [treeMapData, currentMapFor,barChartGlobalInfo]);

  let treeMapSeries = [
    {
      data: treeMapSeriesData.current,
    },
  ];

  let barData = getBarData(selectedBarGraphData, barChartGlobalInfo);

  let barChartMemberSeries = [
    {
      name: 'Individuals',
      data:
        barGraphFilter == 'Registration'
          ? barData.totalData
          : barGraphFilter == 'Screening'
          ? barData.totalMemberScreeningData
          : barGraphFilter == 'Lab Result'
          ? barData.totalMemberLabResult
          : null,
    },
    {
      name: 'Male',
      data:
        barGraphFilter == 'Registration'
          ? barData.maleData
          : barGraphFilter == 'Screening'
          ? barData.totalMaleScreeningData
          : barGraphFilter == 'Lab Result'
          ? barData.totalMaleMemberLabResult
          : null,
    },
    {
      name: 'Female',
      data:
        barGraphFilter == 'Registration'
          ? barData.femaleData
          : barGraphFilter == 'Screening'
          ? barData.totalFemaleScreeningData
          : barGraphFilter == 'Lab Result'
          ? barData.totalFemaleMemberLabResult
          : null,
    },
  ];

  let allDataTillDate = getAllDataTillDate(barData,totalPopulationData);
  let radialBarSeries = allDataTillDate.percentageDataTillDate[
    barGraphFilter == 'Registration'? "registration":
    barGraphFilter == 'Screening'? "screening":
    barGraphFilter == 'Lab Result'? "labResult":
    null];

  let barChartFlaggingSeries = [
    {
      name: 'Urgent',
      data:
        flaggingFilter == 'Screening'
          ? barData.urgentMemberScreeningData
          : flaggingFilter == 'Lab Result'
          ? barData.urgentMemberLabData
          : barData.urgentMemberData,
    },
    {
      name: 'Risk',
      data:
        flaggingFilter == 'Screening'
          ? barData.riskMemberScreeningData
          : flaggingFilter == 'Lab Result'
          ? barData.riskMemberLabData
          : barData.riskMemberData,
    },
    {
      name: 'Normal',
      data:
        flaggingFilter == 'Screening'
          ? barData.normalMemberScreeningData
          : flaggingFilter == 'Lab Result'
          ? barData.normalMemberLabData
          : barData.normalMemberData,
    },
  ];
  if(flaggingFilter!='Screening'){(barChartFlaggingSeries.push(
    {
      name: 'Pending',
      data: barData.pendingMemberLabData,
    }
    ))}

  let barChartYearlyFlaggingSeries = [
    {
      name: 'Urgent',
      data: barData.yearlyUrgentMemberData,
    },
    {
      name: 'Risk',
      data: barData.yearlyRiskMemberData,
    },
    {
      name: 'Normal',
      data: barData.yearlyNormalMemberData,
    },
    {
      name: 'Pending',
      data: barData.yearlyPendingMemberData,
    }
  ];

  let barChartFamilySeries = [
    {
      name: 'No.of Family',
      data: barData.familyData,
    },
  ];

  const handleBreadCrumbClick = useCallback(
    (val) => {
      if (val == 'district' || val == 'block') {
        setBarChartGlobalInfo({
          ...barChartGlobalInfo,
          isBlockSelected: false,
          isVillageSelected: false,
          treeClickVal: 0,
        });
        setCurrentMapFor(val);
      } else {
        setBarChartGlobalInfo({
          ...barChartGlobalInfo,
          isVillageSelected: false,
          treeClickVal: 0,
        });
      }
    },
    [setBarChartGlobalInfo, setCurrentMapFor],
  );

  const treeMapOptions = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return '<div style="width:fit-content;height:fit-content;">' +
          '<div style="padding:5px 10px ;font-weight: 400;color:#4d4d4d;box-shadow: 0px 0px 5px 0px #cccccc;background-color:#e7eef5 ;font-size:15px">'
          + data.x +
          '</div >' +
          '<div style="display :flex ;flex-direction:row;margin-top:10px;font-size:13px">' +
          '<div style="margin:0px 0px 10px 10px;"><div style="padding:3px">Individuals: </div><div style="padding:3px">Males: </div><div style="padding:3px">Females: </div></div>' +
          '<div style="margin:0px 10px 10px 5px;font-weight:600"><div style="padding:3px">'
          + data.y
          + '</div><div style="padding:3px">'
          + data.maleCount
          + '</div><div style="padding:3px">'
          + data.femaleCount + '</div></div></div></div>';
      }
    },
    chart: {
      height: 350,
      type: 'treemap',
      toolbar: {
        show: false,
      },
      events: {
        click: function (event, chartContext, config) {
          if (config.dataPointIndex > -1) {
            if (currentMapFor == 'district') {
              setBarChartGlobalInfo({
                ...barChartGlobalInfo,
                treeClickVal: config.dataPointIndex,
              });
              setCurrentMapFor('block');
            } else if (currentMapFor == 'block') {
              setBarChartGlobalInfo({
                ...barChartGlobalInfo,
                isBlockSelected: true,
                treeClickVal: config.dataPointIndex,
              });
              setCurrentMapFor('village');
            } else {
              setBarChartGlobalInfo({
                ...barChartGlobalInfo,
                isVillageSelected: true,
                treeClickVal: config.dataPointIndex,
              });
            }
          }
        },
      },
    },
  };

  return (
    <div className="graph-parent-container">
      <div className="graph-parent">
        <div className="tree-map ">
        <div className='tree-map-header-parent'>
          <div className="chart-header">
            <div
              className="tree-map-header-breadcrumb"
              onClick={(event) => {
                event.preventDefault();
                return handleBreadCrumbClick('district');
              }}>
              {treeMapData[0]?.district?.name ||
                treeMapData[0]?.block.district?.name}
            </div>

            {barChartGlobalInfo.isBlockSelected && (
              <div
                className="tree-map-header-breadcrumb"
                onClick={(event) => {
                  event.preventDefault();
                  return handleBreadCrumbClick('block');
                }}>
                {' > '}
                {treeMapData[0]?.block?.name}
              </div>
            )}
            {barChartGlobalInfo.isVillageSelected && (
              <div
                className="tree-map-header-breadcrumb"
                onClick={(event) => {
                  event.preventDefault();
                  return handleBreadCrumbClick('Village');
                }}>
                {' > '}
                {treeMapData[barChartGlobalInfo.treeClickVal]?.village?.name}
              </div>
            )}
          </div>
          <div> <button className='downloadPdfButton'><img src={exportPdfIcon} width="25px" height="25px" onClick={() => getDataUri("mainTreeMap")} /></button></div>

          </div>
          {loaderForTreeMap ? (
            <ActivityIndicator
              theme={loaderTheme}
              size="small"
              color={'#0095C9'}
            />
          ) : (
            <div className="tree-map-body">
              <ReactApexChart
                key={currentMapFor}
                options={{...treeMapOptions,
                  dataLabels: {
                  enabled: true,
                    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                      return [value, `(${w.config.series[seriesIndex].data[dataPointIndex].y})`]
                    },
                  },
                  chart: {
                    ...treeMapOptions.chart,
                    id: "mainTreeMap",
                  },
                }}
                series={treeMapSeries}
                type="treemap"
                height={350}
              />
            </div>
          )}
        </div>
      </div>

      <div className="graph-parent">
        <div className="tree-map ">
          {loaderForBarGraph ? (
            <ActivityIndicator
              theme={loaderTheme}
              size="small"
              color={'#0095C9'}
            />
          ) : (
            <>
              <div className="chart-header bar-graph-header">
                {selectedBarGraphData[0] ? (
                  <div className="bar-graph-sub-header">
                    <p className="bar-graph-title">
                      {getChartTitle("mainBarChart")}
                    </p>
                  </div>
                ) : (
                  <div />
                )}
                <div className="filter-drop-down">
                  <CustomDropDown
                    selectedValue={barChartGlobalInfo.selectedYear}
                    data={yearData}
                    onChangeValue={(value) => {
                      setBarChartGlobalInfo({
                        ...barChartGlobalInfo,
                        selectedYear: value,
                      });
                    }}
                    crossIcon
                    width={158}
                  />
                  <CustomDropDown
                    selectedValue={barGraphFilter}
                    data={dataForBarGraphFilter}
                    onChangeValue={(value) => {
                      value != null && setBarGraphFilter(value);
                    }}
                    width={110}
                  />
                </div>
                <button className='downloadPdfButton'><img src={exportPdfIcon} 
                  width="25px" height="25px" 
                  onClick={() => getDataUri("mainBarChart",getChartTitle("mainBarChart"))} /></button>
              </div>
              <div className='flagging-family-Chart-parent'>
                <div className='mainBarChart' 
                // style={{  width: radialBarSeries?.every((currentValue) => currentValue <= 100) ? "80%":"100%" }}
                >
                  <ReactApexChart
                    options={{
                      ...barChartOptions,
                      ...barChartDataLabels,
                      chart: {
                        ...barChartOptions.chart,
                        id: "mainBarChart",
                      },
                      grid: { show: false },
                      colors: ["#00bcd4", "#03a9f4", "#b39ddb"],
                    }}
                    series={selectedBarGraphData[0] ? barChartMemberSeries : []}
                    type="bar"
                    height={350}
                  />
                </div>
                <div className='circularPercentageChart' style={{ display: radialBarSeries.every((currentValue) => currentValue <= 100) ? "" :"none" }}>
                  <ReactApexChart
                    options={{...radialBarOptions,
                      population:totalPopulationData,
                      newLabels:['totalFemale','totalMale','totalIndividual'],
                      legend: {
                        show: true,
                        position: "bottom",
                        offsetY: 7.5,
                        formatter: function (val, opts) {
                          return  opts.w.globals.series[opts.seriesIndex] + "% of "+opts.w.config.population[opts.w.config.newLabels[opts.seriesIndex]]+" "+opts.w.config.labels[opts.seriesIndex]+"s" ;
                        }
                      },}
                      }
                    series={radialBarSeries}
                    height="300"
                    // width="240"
                    type="radialBar" />

                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flagging-family-Chart-parent">
        <div className="graph-parent flagging-bar-chart">
          <div className="tree-map flagging-family-box ">
            {loaderForBarGraph ? (
              <ActivityIndicator
                theme={loaderTheme}
                size="small"
                color={'#0095C9'}
              />
            ) : (
              <>
                <div className="chart-header bar-graph-header">
                  {selectedBarGraphData[0] ? (
                    <div className="bar-graph-sub-header flagging-header">
                      <p className="bar-graph-title">
                        {getChartTitle("Flagging")}
                      </p>
                    </div>
                  ) : (
                    <div />
                  )}
                  <div className="filter-drop-down">
                    {flaggingYearlyFilter ? (
                      <CustomDropDown
                        selectedValue={flaggingFilter}
                        data={dataForFlaggingFilter}
                        onChangeValue={(value) => {
                          value != null && setFlaggingFilter(value);
                        }}
                        width={110}
                      />
                    ) : null}
                    <ToggleSwitch
                      style={{
                        fontSize: '12px',
                        marginLeft: '10px',
                        justifyContent: 'space-between',
                        width: '108px',
                      }}
                      width={40}
                      tintColor={'#50b2fc'}
                      value={flaggingYearlyFilter}
                      onValue={{value: true, label: 'Month'}}
                      offValue={{value: false, label: 'Year'}}
                      showOuterLabel
                      onChangeValue={(value) => {
                        setFlaggingYearlyFilter(value);
                      }}
                    />
                     <button className='downloadPdfButton'><img src={exportPdfIcon}
                      width="25px" height="25px"
                      onClick={() => getDataUri(flaggingYearlyFilter ? "flaggingChart" : "flaggingYearlyChart",getChartTitle("Flagging"))} />
                    </button>
                  </div>
                </div>
                <div>
                  <div style={{ display: flaggingYearlyFilter ? "" : "none" }}>
                    <ReactApexChart
                      options={{
                        ...barChartOptions,
                        ...barChartDataLabels,
                        chart: {
                          ...barChartOptions.chart,
                          id: "flaggingChart",

                        },
                        colors: ['#FF0000', '#FF8C00', '#76ff03', '#A9A9A9'],
                        plotOptions: {
                          bar: {
                            ...barChartOptions.plotOptions.bar,
                            horizontal: false,
                            columnWidth: '95%',
                            endingShape: 'rounded',
                          },
                        },
                      }}
                      series={
                        selectedBarGraphData[0] ? barChartFlaggingSeries : []
                      }
                      type="bar"
                      height={350}
                    />
                  </div>
                  <div style={{ display: flaggingYearlyFilter ? "none" : "" }}>
                    <ReactApexChart
                      options={{
                        ...barChartOptions,
                        ...barChartDataLabels,
                        chart: {
                          ...barChartOptions.chart,
                          id: "flaggingYearlyChart",

                        },
                        xaxis: {
                          categories: yearlyFlaggingLabels,
                        },
                        colors: ['#FF0000', '#FF8C00', '#76ff03', '#A9A9A9'],
                        plotOptions: {
                          bar: {
                            ...barChartOptions.plotOptions.bar,
                            horizontal: false,
                            columnWidth: '35%',
                            endingShape: 'rounded',
                          },
                        },
                      }}
                      series={
                        selectedBarGraphData[0]
                          ? barChartYearlyFlaggingSeries
                          : []
                      }
                      type="bar"
                      height={350}
                    /></div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="graph-parent family-bar-chart">
          <div className="tree-map flagging-family-box">
            {loaderForBarGraph ? (
              <ActivityIndicator
                theme={loaderTheme}
                size="small"
                color={'#0095C9'}
              />
            ) : (
              <>
                <div className="chart-header bar-graph-header">
                  {selectedBarGraphData[0] ? (
                    <div className="bar-graph-sub-header">
                      <p className="bar-graph-title">
                        {getChartTitle("Family")}
                      </p>                   
                    </div>
                  ) : (
                    <div />
                  )}
                  <button className='downloadPdfButton'><img src={exportPdfIcon}
                        width="25px" height="25px"
                        onClick={() => getDataUri("familyBarChart", getChartTitle("Family"))} />
                      </button>
                </div>

                <ReactApexChart
                  options={{
                    ...barChartOptions,
                    ...barChartDataLabels,
                    chart: {
                      ...barChartOptions.chart,
                      id: "familyBarChart",

                    },
                    colors: ['#4caf50'],
                    plotOptions: {
                      bar: {
                        ...barChartOptions.plotOptions.bar,
                        horizontal: false,
                        columnWidth: '85%',
                        endingShape: 'rounded',
                      },
                    },
                  }}
                  series={selectedBarGraphData[0] ? barChartFamilySeries : []}
                  type="bar"
                  height={300}
                />
                <div className='familyPercentageChart'>
                  <ReactApexChart
                    options={{ ...familyBarPercentage ,  colors: ['#4caf50','#cdcdcb'], }}
                    series={getFamilyPercentageSeries(allDataTillDate, totalPopulationData)}
                    type="bar"
                    height={110}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(VillageGraph);
