import React from 'react';

import Table from '../../app-components/table/Table';
import {View, Text} from '../../app-components';
import {h11_Regular, h13_Medium} from '../../theme/fonts';
import moment from 'moment';
import {Headline11} from '../../theme/Headlines';
import {manazeV6Colors} from '../../theme/manazeV6Colors';
import {getUser} from '../../AppServices';
import { BASE_URL } from '../../Config';
import { resolvePatientName } from '../../app-components/patientUtility';
let {neutral_3} = manazeV6Colors;
let uri = () => {
  const {organization = {}} = getUser() || {};
  let orgFilter = {};
  if (organization && organization._id) {
    orgFilter = {
      organization: {
        _id: organization?._id,
      },
    };
  }
  return {
    query: {
      // id: 'organizationPatient',
      // addOnFilter: orgFilter,
    },
    url: (uri) => {
      let {paramValue} = uri?.props?.query || {};
      let {name} = paramValue || {};
      if (name) {
        return `${BASE_URL}/Appointment?name=${name}`;
      }
      return `${BASE_URL}/Appointment`;
    },
    method: 'get',
  };
};

let tableItems = {
  lg: {
    columns: [
      // {type: 'text', field: 'name', header: 'Name ⥮', width: 200},
      {
        render: ({item}) => {
          return item._count > 1 ? (
            <View
              style={{
                height: 16,
                width: 36,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(115, 197, 167, 0.2)',
              }}>
              <Text style={{color: '#73c5a7', ...h11_Regular}}>Rec</Text>
            </View>
          ) : (
            <View
              style={{
                height: 16,
                width: 36,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 123, 32, 0.1)',
              }}>
              <Text style={{color: '#ff7b20', ...h11_Regular}}>New</Text>
            </View>
          );
        },
        header: '',
        width: 50,
      },
      {
        type: 'text',
        field: (data) => {
          console.log(data);
          let {patient = {}} = data || {};

        
          return resolvePatientName(patient);
        },
        header: 'Name',
        width: 250,
      },

      {
        type: 'text',
        field: 'patient.mobile',
        header: 'Mobile no.',
        width: 200,
      },
      {
        type: 'text',
        field: 'total_num_of_appointments',
        header: 'No. of appts',
        width: 150,
      },
      {
        type: 'date',
        format: 'DD-MMM-YYYY',
        field: 'last_appointment_date',
        header: 'Last appt. date',
        width: 200,
      },
      {
        type: 'text',
        field: 'organization.organization_name',
        header: 'Clinic',
      },
      {
        type: 'text',
        field: 'total_booking_fee',
        header: 'Fee Recieved',
        width: 150,
      },
      // {
      //   type: 'text',
      //   field: 'Status',
      //   header: 'Status',
      //   width: 150,
      // },
    ],
  },
  sm: {
    card: {
      left: [
        {
          value: ({item}) => {
            return item._count > 1 ? (
              <View
                style={{
                  height: 16,
                  width: 36,
                  borderRadius: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(115, 197, 167, 0.2)',
                }}>
                <Text style={{color: '#73C5A7', ...h11_Regular}}>Rec</Text>
              </View>
            ) : (
              <View
                style={{
                  height: 16,
                  width: 36,
                  borderRadius: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 123, 32, 0.1)',
                }}>
                <Text style={{color: '#FF7B20', ...h11_Regular}}>New</Text>
              </View>
            );
          },
        },
      ],
      title: {
          primaryField: (data) => {
            console.log(data);
            let {patient = {}} = data || {};
            return resolvePatientName(patient);
          },
      },
      contentItems: [
        [
          {
            field: 'patient.mobile',
          },
        ],
      ],
      right: {
        align: 'right',
        direction: 'column',
        position: 'center',
        items: [
          {
            field: 'total_num_of_appointments',
            suffix: ' Appts',
          },
          {
            align: 'right',
            field: 'organization.organization_name',
            suffix: ' Clinic',
          },
          {
            value: ({item}) => {
              const date = item?.last_appointment_date;
              return (
                <Text style={{...Headline11, color: neutral_3}}>
                  {'Appt on: '}
                  {moment(date).format('MMM-DD-YYYY')}
                </Text>
              );
            },
          },
        ],
      },
    },
  },
};
export default Table({
  uri,
  ...tableItems,
});
