import React from 'react';
import {settingData} from '../../AppServices';
import {ActivityIndicator} from '../../app-components';

export default class SeetingButton extends React.Component {
  state = {data: [], loader: false};
  componentDidMount = async () => {
    this.setState({loader: true});
    // console.log(this.props.id, this.props.model);
    let {data = []} = await settingData({
      id: this?.props?.id,
      model: this?.props?.model,
    });
    this.setState({data: data, loader: false});
  };
  render() {
    let {data = [], loader} = this.state;
    let {custom} = this.props;
    return <>{loader || data.length ? null : custom}</>;
  }
}
