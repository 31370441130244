import React from 'react';
import {TouchableOpacity} from '../index';
import {GradientButton} from '../buttons/Buttons';

const LoadingInput = (props) => {
  const {
    label = 'Fetch',
    labelStyle,
    labelProps,
    value,
    setValue = () => {},
    field = 'loading',
    data,
    inputStyle,
    mobileInputStyle,
    inputProps,
    error,
    errorStyle,
    errorProps,
    disabled,
    ...restProps
  } = props || {};

  const onChange = (value) => {
    setValue({field: field, value, data});
  };

  return (
    <TouchableOpacity
      style={{width: 200, cursor: 'pointer', maxHeight: 33}}
      onPress={() => {
        onChange(!value);
      }}>
      <GradientButton label={label} />
    </TouchableOpacity>
  );
};

export default LoadingInput;
