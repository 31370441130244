// import React from 'react'
import PaymentType from './PaymentType';
import PaymentFailedModal from './PaymentFailedModal'

export default {
  'patient-payment': {
    screen: PaymentType,
    public: true,
    expanded: true,
  },
  payment_failed: {
    screen: PaymentFailedModal,
    public: true,
  },
};
