import React from 'react';
import StackNavigator from './app-components/stack/StackNavigator';
import AppContainer from './AppContainer';
import {registerAction} from './app-components/action/Action';
import {invokeAction} from './app-components/action/InvokeAction';
import LinkAction from './app-components/action/LinkAction';
import ModalLinkAction from './app-components/action/ModalLinkAction';
import {closeAction} from './app-components/action/CloseAction';
import {cancelAction} from './app-components/action/CancelAction';
import ConfirmAction from './app-components/action/ConfirmAction';
import {saveAction} from './app-components/action/SaveAction';
import {ftsAction} from './app-components/action/FtsAction';
import {formUpdateInvokeAction} from './app-components/action/FormUpdateInvokeAction';
import {downloadAction} from './app-components/action/DownloadAction';
import {GroupFilterAction} from './app-components/action/FilterAction';
import {
  Authenticator,
  Login,
  OTP,
  SuperAdminLogin,
} from '../src/screens/login/LoginTemplate';
import ScreenComponent from './app-components/screen/ScreenComponent';
import MayamdScreens from './screens/index';
// import PatientForm from './screens/patients/PatientForm';
import Term from './Term';
import DemoView from './screens/Demo/DemoView';
import ImportExcelData from './screens/ImportExcelData';
import ConfirmView from './app-components/screen/ConfirmView';
import DoctorSearch from './screens/DoctorSearch';
import BeginerPage from './screens/BeginnerPage';
registerAction('invoke', invokeAction);
registerAction('link', LinkAction);
registerAction('modalLink', ModalLinkAction);
registerAction('close', closeAction);
registerAction('cancel', cancelAction);
registerAction('save', saveAction);
registerAction('fts', ftsAction);
registerAction('confirm', ConfirmAction);
registerAction('download', downloadAction);
registerAction('formUpdateInvoke', formUpdateInvokeAction);
registerAction('groupFilter', GroupFilterAction);

const AppNavigator = StackNavigator(
  {
    // demo: {screen: DemoView},
    authenticator: {screen: Authenticator},
    login: {
      public: true,
      screen: ({navigation}) => {
        return <Login navigation={navigation} />;
      },
    },
    superAdmin: {
      screen: ({navigation}) => {
        return <SuperAdminLogin navigation={navigation} />;
      },
    },
    otp: {
      screen: ({navigation}) => {
        return (
          <OTP
            navigation={navigation}
            onLogin={() => {
              navigation.navigate('authenticator');
            }}
          />
        );
      },
    },
    passcode: {
      screen: ({navigation}) => {
        return (
          <OTP
            navigation={navigation}
            onLogin={() => {
              navigation.navigate('authenticator');
            }}
          />
        );
      },
    },
    'choose-user': {screen: BeginerPage, public: true},

    ...DoctorSearch,

    ...MayamdScreens,
    ...ImportExcelData,
    terms: {screen: Term, public: true},
    // addPatient: {
    //   screen: PatientForm,
    //   expanded: true,
    // },
    confirm: {screen: ConfirmView},
  },
  {
    Container: AppContainer,
    ScreenComponent,
  },
);

export default AppNavigator;
