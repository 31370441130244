import {manazeV6Colors} from './manazeV6Colors';

import {Headline11, Headline9, Headline15, Headline10} from './Headlines';

const {
  neutral_1,
  neutral_2,
  neutral_4,
  neutral_7,
  neutral_9,
  neutral_10,
  primary_2,
  negative_communication_3,
  surface_white,
} = manazeV6Colors;

const defaultInputStyle = {
  outline: 'none',
  border: 0,
  padding: 0,
  backgroundColor: 'transparent',
};

const errorMessageStyle = {
  errorMessageContainerStyle: {paddingTop: 3},
  errorMessageIconStyle: {
    paddingRight: 3,
  },
  errorMessageStyle: {
    ...Headline11,
    color: negative_communication_3,
  },
};

const helperTextStyle = {
  helperTextContainerStyle: {paddingTop: 3},
  helperTextIconStyle: {
    paddingRight: 3,
  },
  helperTextStyle: {
    ...Headline11,
    color: neutral_4,
  },
};

const labelStyle = {
  labelStyle: {
    ...Headline9,
    color: neutral_4,
  },
  floatingLabelstyle: {
    ...Headline15,
    color: neutral_2,
  },
  hoverLabelStyle: {
    // color: neutral_2,
  },
  activeLabelStyle: {
    color: primary_2,
  },
};

const compactLabelStyle = {
  labelStyle: {
    ...Headline11,
    color: neutral_4,
  },
  floatingLabelstyle: {
    ...Headline15,
    color: neutral_2,
  },
  hoverLabelStyle: {
    // color: neutral_2,
  },
  activeLabelStyle: {
    color: primary_2,
  },
};

export const filledMobileInputTheme = {
  style: {
    // borderWidth: 1,
    // borderStyle: 'solid',
    // // borderColor: neutral_7,
    // borderRadius: 4,
    // backgroundColor: surface_white,
    // minHeight: 44,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_7,
    borderRadius: 4,
    backgroundColor: surface_white,
    minHeight: 38
  },
  hoverStyle: {
    // backgroundColor: neutral_100_background,
  },
  activeStyle: {
    borderColor: primary_2,
  },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingTop: 14,
    paddingBottom: 10,
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline9,
    color: neutral_1,
  },
  floatingLabelInputStyle: {
    ...defaultInputStyle,
    paddingTop: 19,
    paddingBottom: 3,
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline9,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 13,
    left: 13,
  },
  floatingLabelContainerStyle: {
    top: 5,
  },
  ...labelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    alignItems: 'center',
  },
  inputSelectorContainerStyle: {
    paddingLeft: 8,
    paddingRight: 8,
    justifyContent: 'center',
  },
};

export const standardMobileInputTheme = {
  style: {
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_7,
  },
  hoverStyle: {
    // color: neutral_1,
  },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  activeStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderBottomWidth: 2,
    borderColor: primary_2,
    borderStyle: 'solid',
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingTop: 16,
    paddingBottom: 7,
    ...Headline10,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 17,
    left: 0,
  },
  floatingLabelContainerStyle: {
    top: 5,
  },
  ...labelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    alignItems: 'flex-end',
  },
  inputSelectorContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    justifyContent: 'flex-end',
  },
};

export const filledCompactMobileInputTheme = {
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_7,
    borderRadius: 4,
    backgroundColor: surface_white,
    minHeight: 32,
  },
  hoverStyle: {
    // backgroundColor: neutral_100_background,
  },
  activeStyle: {
    borderColor: primary_2,
  },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 8,
    ...Headline11,
    color: neutral_1,
  },
  floatingLabelInputStyle: {
    ...defaultInputStyle,
    paddingTop: 15,
    paddingBottom: 3,
    paddingLeft: 5,
    paddingRight: 5,
    ...Headline11,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 11,
    left: 6,
  },
  floatingLabelContainerStyle: {
    top: 3,
  },
  ...compactLabelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    alignItems: 'center',
  },
  inputSelectorContainerStyle: {
    paddingLeft: 8,
    paddingRight: 8,
    justifyContent: 'center',
  },
};

export const standardCompactMobileInputTheme = {
  style: {
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_7,
  },
  hoverStyle: {
    // color: neutral_1,
  },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  activeStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderBottomWidth: 2,
    borderColor: primary_2,
    borderStyle: 'solid',
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingTop: 11,
    paddingBottom: 8,
    ...Headline11,
    color: neutral_1,
  },
  floatingLabelInputStyle: {
    ...defaultInputStyle,
    paddingTop: 16,
    paddingBottom: 3,
    ...Headline11,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 11,
    left: 0,
  },
  floatingLabelContainerStyle: {
    top: 3,
  },
  ...compactLabelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    alignItems: 'flex-end',
  },
  inputSelectorContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    justifyContent: 'flex-end',
  },
};

export const filledCompactUnborderedMobileInputTheme = {
  ...filledCompactMobileInputTheme,
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: 4,
    backgroundColor: neutral_10,
    minHeight: 32,
  },
};

export const standardCompactUnborderedMobileInputTheme = {
  ...standardCompactMobileInputTheme,
  style: {
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    minHeight: 32,
  },
};

export const filledCompactBorderedMobileInputTheme = {
  ...filledCompactMobileInputTheme,
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_9,
    borderRadius: 4,
    backgroundColor: neutral_10,
    minHeight: 32,
  },
};

export const standardCompactBorderedMobileInputTheme = {
  ...standardCompactMobileInputTheme,
  style: {
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_9,
    minHeight: 32,
  },
};
