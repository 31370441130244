
import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {PLACEHOLDER} from '../../app-components/utils';

export default ({uri, header}) => {
  const Settingform = Form({
    type: 'standardShadow',
    header,
    uri,
    onSubmit: submit({
      model: 'Settings',
    }),
    closeView: 1,
    reloadEvent: 'reloadSetting', 
    mandatory: {joining_time_limit: 1, chat_time_limit: 1},
    formGroups: [
      {
        columnsPerRow: 1,
        fieldVariant: 'filled',
        fieldContainer: 'topLabel',
        groups: [
          {
            columns: [
              {
                type: 'number',
                field: 'joining_time_limit',
                label: 'Joining Time Limit (in minutes)',
                placeholder: PLACEHOLDER,
                width: '50%',
                mandatory:true
              },
              {
                type: 'number',
                field: 'chat_time_limit',
                label: 'Chat Time Limit (in days)',
                placeholder: PLACEHOLDER,
                width: '50%',
                mandatory:true
              },
            ],
          },
        ],
      },
    ],
  });
  return Settingform;
};
