import React from 'react';
import Dashboard from './Dashboard';
import Provider from './Provider';
import Assistant from './Assistant';
import Graph from './Graph';

import Covid from './Covid';
import EmergencyRoom from './EmergencyRoom';
import {View} from '../../app-components';
import {
  specilityNameFilter,
} from '../../app-components/filter/MayaMDFilters';
import InstantConsultation from './InstantConsultation';

export default {
  Dashboard: {
    screen: Dashboard,
  },
  provider: {
    screen: Provider,
  },
  'health-assistant': {
    screen: Assistant,
  },
  'graph-image': {
    screen: Graph,
    expanded: true,

  },
  covidvaccine: {
    screen: Covid,
    expanded: true,
  },
  WaitingRoom: {
    screen: InstantConsultation,
    renderHeader: (props) => {
      let {navigation, screenState, t, i18n} = props;
      let {setScreenState} = navigation;
      return (
        <View style={{padding: 10, alignItems: 'flex-end'}}>
          {specilityNameFilter({setScreenState, screenState, t})}
        </View>
      );
    },
    expanded: true,
  },
  EmergencyRoom: {
    screen: EmergencyRoom,
    expanded: true,
  },
};
