import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from '../../app-components';
import {ActivityIndicator} from '../../app-components';
import {TextInput} from '../../app-components/input-components/Editors';
import {
  h12_Regular,
  h14_AvenirNext,
  h20_AvenirNext_500,
  h22_Medium,
  h22_SemiBold,
  h14_Regular,
  h15_SemiBold,
  h18_AvenirNext,
  h15_Medium,
  h15_Regular,
} from '../../theme/fonts';
import {invoke} from '../../AppServices';
import MobileInput from '../../app-components/input-components/MobileInput';
import { GradientButton } from '../../app-components/buttons/Buttons';

const PatientLogin = (props) => {
  let {navigation} = props;
  let {params} = navigation?.state || {};
  let {mobile: propMobile} = params || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [mobile, setMobile] = useState(propMobile || '');
  const setValue = (text) => {
    setMobile(text);
  };
  const sendOTP = async () => {
    if (!mobile.length) {
      setError('Mobile number is required');
    } else {
      setLoading(true);
      await invoke({
        id: 'createPatient',
        paramValue: {data: {mobile, userType: 'Patient'}},
      })
        .then(() => {
          navigation.replace({
            view: 'OTP',
            params: {
              ...params,
              mobile,
            },
          });
          // changeRightSideScreen('otp');
        })
        .catch((err) => {
          if (err && err.message) {
            const parseError = JSON.parse(err?.message);
            setError(parseError?.error?.message);
          } else {
            setError('Error');
          }
        });
      setLoading(false);
    }
  };
  return (
    <View style={{margin: 25}}>
      <TouchableOpacity
        onPress={() => navigation.pop(2)}
        style={{
          cursor: 'pointer',
          alignItems: 'flex-start',
          marginTop: 5,
          marginBottom: 20,
        }}>
        <Text
          style={{
            ...h14_AvenirNext,
            lineHeight: 21,
            letterSpacing: 0.5,
            color: '#383838',
            opacity: 0.54,
          }}>
          {'< '} Back
        </Text>
      </TouchableOpacity>
      <Text
        style={{
          ...h15_Regular,
          color: '#2e6ecd',
          fontWeight: 600,
          marginBottom: 10,
        }}>
        Enter Mobile Number
      </Text>
      <MobileInput
        value={mobile}
        onChangeText={setValue}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            sendOTP();
          }
        }}
      />

      {error && <Text style={{...h12_Regular, color: 'red'}}>{error}</Text>}
      <View style={{marginTop: 35, marginBottom: 23}}>
        <Text
          style={{
            ...h14_Regular,
            letterHeight: 1.5,
            letterSpacing: 0.5,
            color: 'rgb(56,56,56, 0.54)',
            opacity: 0.54,
            paddingBottom: 7,
          }}>
          You will recieve OTP on this number
        </Text>
        <Text
          style={{
            ...h14_Regular,
            letterHeight: 1.5,
            letterSpacing: 0.5,
            color: 'rgb(56,56,56, 0.54)',
            opacity: 0.54,
          }}>
          We will send appointment relation communication on this number
        </Text>
      </View>
      <TouchableOpacity
        style={{
          padding: 10,
          cursor: 'pointer',
        }}
        onPress={() => {
          sendOTP();
        }}>
          <GradientButton
          label={'Send OTP'}
          loading={loading}
          buttonStyle={{flex: 1}}
          indicatorColor={"#fff"}
        />
      </TouchableOpacity>
    </View>
  );
};

export default PatientLogin;
