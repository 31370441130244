import React, {useState} from 'react';
// import PatientDetailCard from '../GenericComponent/PatientDetailsCard';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
  isMobile,
} from '../../app-components';
import {getParamsFromNav} from '../../app-components/utils';
import Table from '../../app-components/table/Table';
import NestedList from '../../app-components/input-components/NestedList';
import {
  h12_Regular,
  h13_Regular,
  h14_Medium,
  h11_Regular,
  h16_Medium,
} from '../../theme/fonts';
import moment from 'moment';
import {getUser, getImageUrl} from '../../AppServices';
import {closeIcon, pdf} from '../../images';
import {getFileExtension} from '../../app-components/UtilityFunctions';
import {cancelModalButton} from '../../theme/shadows';
import {isEmpty} from 'lodash';
// let uri = (props) => {
//   const {item} = getParamsFromNav(props);
//   const {patient: {_id: patientId} = {}} = item || {};
//   let {doctor: {_id} = {}} = getUser();
//   return {
//     query: {
//       id: 'appointmentLists',
//       addOnFilter: {
//         doctor_id: {_id},
//         patient_id: {_id: patientId},
//       },
//     },
//     model: 'Appointments',
//   };
// };

let nestedlistprops = {
  field: 'treatment',

  visible: () => {
    let {userType} = getUser();
    return userType === 'Doctor' ? true : false;
  },
  addButtonStyle: {backgroundColor: 'red', width: 200, height: 100},
  // footer: {
  //   // label: 'Treatment',
  //   addLabel: 'Add Drug',
  // },
  labelHeader: [
    {label: 'Drug', width: 160},
    {label: 'Dosage', width: 70},
    {label: 'Frequency', width: 120},
    {label: 'Timing', width: 140},
    {label: 'Days', width: 70},
  ],

  formGroups: [
    {
      columnsPerRow: 6,
      fieldVariant: 'filled',
      fieldContainer: 'topLabel',

      groups: [
        {
          columns: [
            {
              disabled: true,
              type: 'text',
              field: 'drug.name',
              width: 160,
            },
            {
              disabled: true,
              width: 70,
              type: 'text',
              field: 'dosage',
              placeholder: 'tablets/ ml',
            },
            {disabled: true, type: 'text', field: 'frequency', width: 120},
            {disabled: true, type: 'text', field: 'timing', width: 140},

            {
              disabled: true,
              width: 70,
              type: 'number',
              field: 'duration',
              placeholder: 'Days',
            },
          ],
        },
      ],
    },
  ],
};

const PatientDetails = (props) => {
  const [tab, setTab] = useState('symptoms');
  const {item = {}} = props || {};
  const {
    patient_symptoms = [],
    investigated_symptoms = [],
    illness_description = '',
    investigate = [],
    diagnosis = [],
    time_slot,
    medical_report = [],
    e_prescription_report = {},
  } = item;

  let medical_Report_Slice = medical_report;
  let medical_Report_Slice_Second = [];
  const IconLength = 4;
  if (medical_report.length > IconLength) {
    medical_Report_Slice = medical_report.slice(0, IconLength);
    medical_Report_Slice_Second = medical_report.slice(
      IconLength,
      medical_report.length,
    );
  }
  return (
    <>
      <View
        style={{
          margin: 10,
          marginLeft: isMobile ? 5 : 16,
          marginRight: isMobile ? 5 : 16,
          height: 300,
          maxHeight: 290,
          overflow: 'hidden',
          backgroundColor: '#fff',
          borderRadius: 5,
          padding: 20,
          ...cancelModalButton,
        }}>
        <View style={{paddingLeft: 10}}>
          <Text style={{...h13_Regular, lineHeight: 21, letterSpacing: 0.46}}>
            {moment(time_slot?.start_time).format('MMM DD | hh:mm A')}
          </Text>
        </View>
        {/* navigation bar */}
        <ScrollView>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 10,
            }}>
            {[
              {label: 'SYMPTOMS', value: 'symptoms'},
              {label: 'E-PRESCRIPTION', value: 'e_prescription_report'},
              {label: 'INVESTIGATION', value: 'investigate'},
              {label: 'DIAGNOSIS', value: 'diagonosis'},
              {label: 'TREATMENT', value: 'treatment'},
            ].map((item) => (
              <TouchableOpacity
                onPress={() => setTab(item?.value)}
                style={{
                  cursor: 'pointer',
                  borderRadius: 4,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 10,
                  paddingBottom: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: tab === item?.value ? '#e6faf9' : '',
                }}>
                <View
                  style={{
                    alignItems: 'center',
                  }}>
                  <Text
                    style={{
                      ...h14_Medium,
                      color: tab === item?.value ? '#5dcfc6' : '',
                      fontWeight: 500,
                      opacity: 0.54,
                    }}>
                    {item?.label}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </ScrollView>
        {tab === 'symptoms' ? (
          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                // backgroundColor: 'red',
                alignItems: 'center',
              }}>
              <View style={{flexDirection: 'row'}}>
                {patient_symptoms.length
                  ? patient_symptoms.map((item) => {
                      return (
                        <View
                          style={{
                            borderRadius: 15.5,
                            minWidth: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#FEE9E9',
                            padding: 5,
                            margin: 8,
                          }}>
                          <Text
                            style={{
                              ...h12_Regular,
                              color: '#f14f4f',
                            }}>
                            {item}
                          </Text>
                        </View>
                      );
                    })
                  : null}
              </View>
              {patient_symptoms.length ? (
                <View style={{alignItems: 'center', margin: 8}}>
                  <Text
                    style={{...h11_Regular, color: 'rgba(56, 56, 56, 0.35)'}}>
                    Reported by Patient
                  </Text>
                </View>
              ) : null}
            </View>
            <View style={{margin: 8}}>
              <Text> </Text>
              <Text style={{...h13_Regular}}>{illness_description}</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <View style={{flexDirection: 'row'}}>
                {investigated_symptoms.length
                  ? investigated_symptoms.map((item) => {
                      return (
                        <View
                          style={{
                            borderRadius: 15.5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#FEE9E9',
                            padding: 5,
                            minWidth: 50,
                            margin: 8,
                          }}>
                          <Text
                            style={{
                              ...h12_Regular,
                              color: '#f14f4f',
                            }}>
                            {item}
                          </Text>
                        </View>
                      );
                    })
                  : null}
              </View>
              {investigated_symptoms.length ? (
                <View style={{alignItems: 'center', margin: 8}}>
                  <Text
                    style={{...h11_Regular, color: 'rgba(56, 56, 56, 0.35)'}}>
                    Identified by Doctor
                  </Text>
                </View>
              ) : null}
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                marginLeft: 8,
                marginTop: 10,
                marginBottom: 10,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <View style={{flexDirection: 'row', width: 300}}>
                {Array.isArray(medical_Report_Slice)
                  ? medical_Report_Slice.map((item) => (
                      <TouchableOpacity
                        style={{
                          height: 49,
                          width: 50,
                          marginLeft: 5,
                          borderRadius: 4,
                          cursor: 'pointer',
                        }}
                        onPress={() => {
                          const {navigation} = props;
                          navigation.push({
                            view: 'pdfReader',
                            params: {
                              dataArr: medical_report,
                            },
                            modal: true,

                            modalProps: {
                              autoHide: true,
                              minHeight: 180,
                              height: 600,
                              width: isMobile ? 300 : 700,
                              position: 'screenCenter',
                              // ...confirmModalProps,
                            },
                          });
                        }}>
                        {getFileExtension(item.name) === 'pdf' ? (
                          <Image source={pdf} style={{height: 49, width: 50}} />
                        ) : (
                          <Image
                            source={getImageUrl(item?.thumbnail)}
                            style={{height: 49, width: 50}}
                          />
                        )}
                      </TouchableOpacity>
                    ))
                  : null}
                {Array.isArray(medical_Report_Slice_Second) &&
                medical_Report_Slice_Second.length ? (
                  <TouchableOpacity
                    style={{
                      height: 49,
                      width: 50,
                      marginLeft: 5,
                      borderRadius: 4,
                      justifyContent: 'center',
                      backgroundColor: '#383838',
                      alignItems: 'center',
                    }}
                    onPress={() => {
                      const {navigation} = props;
                      navigation.push({
                        view: 'pdfReader',
                        params: {
                          dataArr: medical_report,
                        },
                        modal: true,

                        modalProps: {
                          autoHide: true,
                          minHeight: 180,
                          height: 600,
                          width: isMobile ? 300 : 700,
                          position: 'screenCenter',
                          // ...confirmModalProps,
                        },
                      });
                    }}>
                    <Text style={{color: '#fff'}}>
                      {'+' + medical_Report_Slice_Second.length}
                    </Text>
                  </TouchableOpacity>
                ) : null}
              </View>
              {medical_report.length ? (
                <View style={{alignItems: 'center', margin: 8}}>
                  <Text
                    style={{...h11_Regular, color: 'rgba(56, 56, 56, 0.35)'}}>
                    Medical Report
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
        ) : null}

        {tab === 'investigate' ? (
          <View>
            {investigate.length
              ? investigate.map((item, index) => {
                  return (
                    <View style={{margin: 8}}>
                      <Text
                        style={{
                          ...h12_Regular,
                        }}>
                        {index + 1}. {item}
                      </Text>
                    </View>
                  );
                })
              : null}
          </View>
        ) : null}

        {tab === 'e_prescription_report' ? (
          <View style={{padding: 10}}>
            {!isEmpty(e_prescription_report) ? (
              <TouchableOpacity
                onPress={() => {
                  const {navigation} = props;
                  navigation.push({
                    view: 'pdfReader',
                    params: {
                      dataArr: [e_prescription_report],
                    },
                    modal: true,

                    modalProps: {
                      autoHide: true,
                      minHeight: 180,
                      height: isMobile ? '80%' : 600,
                      width: isMobile ? '100%' : 700,
                      position: 'screenCenter',
                      // ...confirmModalProps,
                    },
                  });
                }}>
                <Image source={pdf} style={{height: 49, width: 50}} />
              </TouchableOpacity>
            ) : (
              void 0
            )}
          </View>
        ) : null}

        {tab === 'diagonosis' ? (
          <View>
            {diagnosis.length
              ? diagnosis.map((item, index) => {
                  return (
                    <View style={{margin: 8}}>
                      <Text
                        style={{
                          ...h12_Regular,
                        }}>
                        {index + 1}. {item?.name ?? item}
                      </Text>
                    </View>
                  );
                })
              : null}
          </View>
        ) : null}
        {tab === 'treatment' ? (
          <ScrollView style={{flex: 1}}>
            <NestedList
              {...nestedlistprops}
              // {...props}
              // setValue={setValue}
              data={item}
              defaultAddRow={true}
            />
          </ScrollView>
        ) : null}
      </View>
    </>
  );
};

export default PatientDetails;
