import Form from '../../../../app-components/form/Form';
import {getUser, submit} from '../../../../AppServices';
import {cancelButton} from '../../../../app-components/action/CloseAction';
import {save} from '../../../../app-components/action/SaveAction';
import {googlePlaceInput} from '../../../../autoSuggestions';
const PLACEHOLDER = 'Enter Clinic Name';
export default ({uri, header}) => {
  const Specialityform = Form({
    type: 'standardShadow',
    uri,
    onSubmit: submit({
      model: 'OrganizationLocations',
    }),
    reloadEvent: 'reloadOrgantizationLocations',
    closeView: 1,
    mandatory: {
      branchName: 'Please enter Clinic Name',
      branchLocation: 'Please enter Clinic Location',
    },
    defaultValues: ({navigation}) => {
      const {state: {params: {item = {}} = {}} = {}} = navigation || {};
      const {organization} = getUser() || {};
      return {
        organization,
        ...item,
      };
    },
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 2,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'branchName',
                  label: 'Clinic Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  width: '50%',
                },
                googlePlaceInput({
                  field: 'branchLocation',
                  placeholder: 'Search Clinic Address',
                  label: 'Search Clinic Address',
                  isMapVisible: false,
                }),
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'branchName',
                  label: 'Clinic Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                // multiSearchInput({
                //   field: 'procedures',
                //   query: 'procedureLists',
                //   model: 'Procedures',
                //   placeholder: 'Search Procedures',
                //   idField: '_id',
                //   searchField: 'name',
                //   suggestionField: 'name',
                //   valueField: 'name',
                //   // mandatory: true,
                //   label: 'Procedure',
                // }),
                googlePlaceInput({
                  field: 'branchLocation',
                  placeholder: 'Select Address',
                  label: 'Select Address',
                  isMapVisible: false,
                }),
              ],
            },
          ],
        },
      ],
    },
  });
  return Specialityform;
};
