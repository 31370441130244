import {save} from '../../app-components/action/SaveAction';
import Form from '../../app-components/form/Form';
// import {modifyPatientNameForDropDown} from '../../app-components/patientUtility';
import {invoke, submit} from '../../AppServices';
import {searchInput} from '../../autoSuggestions';

export default Form({
  // closeView: 1,
  // type: 'standardShadow',
  onSubmit: submit({model: 'PatientReadings'}),
  // onSubmit: ({data}) => {
  //   console.log('@@@@@@@@@@@@@@@data is', data);
  //   invoke({
  //     id: 'checkValue',
  //     paramValue: {data},
  //   });
  // },

  fieldVariant: 'filled',
  fieldContainer: 'topLabel',
  header: {title: 'Patient Reading', actions: [save({label: 'Send'})]},
  computations: {
    self: {
      'empty text fields': {
        compute: (data) => {
          let parameterList = [];
          if (data.name) {
            let {name: {careProgram = []} = {}} = data || {};
            const len = careProgram.length - 1;
            let rpm = careProgram[len];
            console.log('..........', rpm, len);
            careProgram &&
              rpm.monitoringParameter &&
              rpm.monitoringParameter.map((item1) => {
                parameterList.push({
                  ...item1,
                });
              });

            return {set: {parameters: [...parameterList], rpmName: rpm.name}};
          }
        },
        onChange: ['name'],
      },
    },
  },
  formGroups: [
    {
      groups: [
        {
          columnsPerRow: 2,

          columns: [
            searchInput(
              {
                label: 'Select Patient',
                field: 'name',
                placeholder: 'Select Patient',
                query: 'patientLists',
                model: 'Patients',
                valueField: 'fullName',
                suggestionField: 'fullName',
                searchField: 'fullName',
              },
              {
                // modifyResult: modifyPatientNameForDropDown,
              },
            ),
            ,
            {type: 'text', field: 'rpmName', editable: false},
            ,
          ],
        },
      ],
    },
    {
      columns: [
        {
          type: 'nestedList',
          field: 'parameters',
          addInBottom: true,
          formGroupHeaderStyle: {marginLeft: 10},
          label: 'Parameter',

          formGroups: [
            {
              columnsPerRow: 4,
              fieldVariant: 'filled',
              groups: [
                {
                  columns: [
                    searchInput({
                      label: 'Parameter',
                      field: 'parameter',
                      placeholder: 'Select Parameter',
                      query: 'parameterList',
                      model: 'MonitoringParameter',
                      valueField: 'name',
                      suggestionField: 'name',
                      searchField: 'name',
                      editable: false,
                    }),

                    {
                      type: 'number',
                      label: 'Lower Threshold',
                      field: 'PatientlowerThresholds',
                      visible: ({data}) => {
                        if (data?.parameter?.type === 'range') {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                    {
                      type: 'number',
                      label: 'Higher Threshold',
                      field: 'PatienthigherThresholds',
                      visible: ({data}) => {
                        if (data?.parameter?.type === 'range') {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                    {
                      type: 'number',
                      label: 'Rate Of Change',
                      field: 'PatientrateOfChanges',
                      visible: ({data}) => {
                        if (data?.parameter?.type === 'rate of change') {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                    searchInput({
                      label: 'Unit Measurement',
                      field: 'unitMeasurement',
                      placeholder: 'Select Unit',
                      query: 'unitMeasurementList',
                      model: 'UnitMeasurements',
                      valueField: 'name',
                      suggestionField: 'name',
                      searchField: 'name',
                      editable: false,
                    }),
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});
