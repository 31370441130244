import React from 'react';
import {passwordHide, passwordShow} from '../../../images';
import {
  View,
  BasicTextInput,
  TouchableOpacity,
  Image,
} from '../../react-core-components';
import {
  WithLabel,
  WithInputWrapper,
  WithVariant,
  InputError,
  getInputProps,
} from '../../react-input-wrapper';

class PasswordInputEditor extends React.Component {
  render() {
    let {type, ...restProps} = this.props;
    let inputProps = getInputProps(restProps);
    return (
      <BasicTextInput
        {...inputProps}
        type={type}
        secureTextEntry={type === 'text' ? false : true}
      />
    );
  }
}

// PasswordInputEditor = WithLabel(WithInputWrapper(WithVariant(PasswordInputEditor)));

class PasswordInput extends React.Component {
  state = {type: 'password'};
  onChangeValue = (value, e) => {
    let {onChangeText, onChangeValue} = this.props;
    onChangeText && onChangeText(value);
    onChangeValue && onChangeValue(value, e);
  };

  togglePassword = () => {
    let {type} = this.state;
    if (type === 'password') {
      this.setState({type: 'text'});
    } else {
      this.setState({type: 'password'});
    }
  };
  render() {
    let {showIcon, hideIcon} = this.props;
    let {type} = this.state;
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <PasswordInputEditor {...this.props} type={type} />
        </View>
        <TouchableOpacity
          onPress={this.togglePassword}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginRight: 10,
          }}>
          <Image
            source={type === 'text' ? passwordShow : passwordHide}
            style={{height: 25, width: 25}}
          />
        </TouchableOpacity>
      </View>
    );
  }
}

PasswordInput = WithLabel(WithInputWrapper(WithVariant(PasswordInput)));

export default PasswordInput;
