import React from 'react';
import {View, ScrollView, isMobile} from '../../app-components';
import {invoke} from '../../AppServices';
import BottomCard from './CardComponent/BottomCard';
import RevenueCard from './CardComponent/RevenueCard';
import EfficiencyCard from './CardComponent/EfficiencyCard';
import ConversionCard from './CardComponent/ConversionCard';

const Analytics = (props) => {
  let {
    screenState: {
      dataParams: {filters: {period: {value: timeFilter} = {}} = {}} = {},
    } = {},
  } = props;
  const {navigation} = props;
  const {state: {params} = {}} = navigation;

  let organizationFilter = '';

  const getData = async ({id, setResponse, setLoading}) => {
    if (params && params.item && params.item._id) {
      organizationFilter = params?.item?._id;
    }
    let paramValue = {
      query: {
        organization: organizationFilter,
      },
      period: {
        ...timeFilter,
      },
    };
    // console.log('prarmvalue', paramValue);
    try {
      setLoading(true);
      let res = void 0;
      if (timeFilter) {
        res = await invoke({
          id: id,
          paramValue,
        });
        setResponse(res);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: '#F1F3F9',
        padding: isMobile ? 8 : 10,
        paddingTop: 0,
      }}>
      {/* Header */}
      {/* <View
        style={{
          alignItems: 'flex-start',
          backgroundColor: '#fff',
          // marginTop: 10,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          padding: 15,
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
        <View style={{backgroundColor: '#E3FCF2', padding: 10}}>
          <Text style={{...h16_Regular, color: '#4AD7AD'}}>
            {`${timeFilter?.filterOf}`} Overview
          </Text>
        </View>
        <View style={{border: '1px solid #4AD7AD', padding: 10}}>
          <Text style={{...h16_Regular, color: '#4AD7AD'}}>
            Download Report
          </Text>
        </View>
      </View> */}

      <View
        style={{
          backgroundColor: '#fff',
          marginTop: 2,
          padding: 20,
          borderRadius: 10,
          paddingBottom: 0,
        }}>
        <View
          style={{
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}>
          <RevenueCard getData={getData} timeFilter={timeFilter} />
          <EfficiencyCard getData={getData} timeFilter={timeFilter} />
          <ConversionCard getData={getData} timeFilter={timeFilter} />
        </View>
        <BottomCard getData={getData} timeFilter={timeFilter} />
      </View>
    </ScrollView>
  );
};

export default Analytics;
