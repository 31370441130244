import {MODE} from './Config';
import {roles} from './Lib/constants';

export const DASHBOARD_ANALYTICS = 'analytics';
export const PAYMANT_ANALYTICS = 'PaymentAnalytics';
export const CLINICS_BOARD = 'clinics';
export const PATIENT_BOARD = 'patients';
export const MASTER_DATA = 'master-data';
export const CONTACT_SUPPORT = 'contact-support';
export const MANAGE_INVITE_LINKS = 'manage-invite-links';
export const SETTING = 'clinic-detail';
export const USER_MANAGEMENT = 'user-list';
export const MY_PATIENT = 'my-patient';
export const PATIENT_REQUESTS = 'patient-requests';
export const IMPORT = 'importList';
export const DOCTOR_PATIENT_QUEUE = 'doctor-patient-queue';
export const INSTANT_PATIENT_QUEUE = 'instant-patient-queue';
export const SCHEDULE = 'schedule';
export const PATIENT_WAITING_ROOM = 'patient-waiting-room';
export const DOCTOR_SEARCH = 'doctor-search';
export const FOLLOW_UP = 'follow-up';
export const SUPER_ADMIN_ANALYTICS = 'superAdminAnalytics';
export const FAVOURITE_DOCTOR = 'FavoriteDoctor';
export const DASHBOARD = 'Dashboard';
export const RPM = 'RPM';
export const SUPER_ADMIN_RPM = 'superAdminRPM';
export const PATIENT_READING = 'patientReading';
export const EMERGENCY_REQUEST = 'emergency-request';
export const PATIENT_CLINIC_SIGNUP = 'patient-clinic-signup';
export const LABAPPOINTMENT = 'labAppointment';

export const USER_TYPES = {
  PATIENT: 'Patient',
  DOCTOR: 'Doctor',
  LIVE_SUPER_ADMIN: 'SuperAdmin',
  CLINICADMIN: 'ClinicAdmin',
  LABADMIN: 'LabAdmin',
  MEDICALASSISTANT: 'MedicalAssistant',
  DIALYSISADMIN: 'DialysisAdmin',
  NURSE: 'Nurse',
  ADMIN: 'Admin',
};

export const getUserViewsMap = ({user}) => {
  let {resource} = user || {};
  if (user && user.userType === roles.SUPERADMIN) {
    resource = {
      // superAdminAnalytics: true,
      // clinics: true,
      // patient: true,
      master_data: true,
      // contact_support: true,
      // import: true,
      superAdminRPM: true,
      // paymentAnalytics: true,

      // 'doctor-patient-queue': true,
    };
  }

  if (user && user.userType === roles.ADMIN) {
    resource = {
      // superAdminAnalytics: true,
      // clinics: true,
      // patient: true,
      master_data: true,
      // contact_support: true,
      // import: true,
      superAdminRPM: true,
      // paymentAnalytics: true,

      // 'doctor-patient-queue': true,
    };
  }

  if (user && user.userType === roles.ANM) {
    resource = {
      // superAdminAnalytics: true,
      // clinics: true,
      // patient: true,
      master_data: false,
      // contact_support: true,
      // import: true,
      superAdminRPM: false,
      // paymentAnalytics: true,

      // 'doctor-patient-queue': true,
    };
  }
  if (user && user.userType === roles.MO) {
    resource = {
      // superAdminAnalytics: true,
      // clinics: true,
      // patient: true,
      master_data: true,
      // contact_support: true,
      // import: true,
      superAdminRPM: true,
      // paymentAnalytics: true,

      // 'doctor-patient-queue': true,
    };
  }
  if (user && user.userType === roles.LT) {
    resource = {
      // superAdminAnalytics: true,
      // clinics: true,
      // patient: true,
      master_data: true,
      // contact_support: true,
      // import: true,
      superAdminRPM: true,
      // paymentAnalytics: true,

      // 'doctor-patient-queue': true,
    };
  }
  // if (user && user.userType === 'ClinicAdmin') {
  //   resource = {
  //     analytics: true,
  //     manage_invite_links: true,
  //     patient: true,
  //     paymentAnalytics: true,
  //     contact_support: true,
  //     // manage_invite_links: true,
  //     'clinic-detail': true,
  //     RPM: true,
  //     'emergency-request': true,
  //     // patient_clinic_signup: true,
  //   };
  // }
  if (user && user.userType === 'Doctor') {
    resource = {
      // contact_support: true,
      // 'doctor-patient-queue': true,
      // patient: true,
      // paymentAnalytics: true,
      // analytics: true,
      // my_patient: true,
      // 'instant-patient-queue': true,
      // 'patient-requests': true,
      // schedule: true,
      // RPM: true,
      // patientReading: true,
      master_data: true,
      // contact_support: true,
      // import: true,
      superAdminRPM: true,
    };
  }
  if (user && user.userType === 'Patient') {
    resource = {
      patient: true,
      patient_waiting_room: true,
      'doctor-search': true,
      FavoriteDoctor: true,
      follow_up: true,
      Dashboard: true,
    };
  }
  if (user && user.userType === 'FrontDesk') {
    resource = {
      manage_invite_links: true,
    };
  }
  if (user && user.userType === 'LabAdmin') {
    resource = {
      lab_appointment: true,
    };
  }
  if (user && user.userType === 'MedicalAssistant') {
    resource = {
      RPM: true,
    };
  }
  if (user && user.userType === 'DialysisAdmin') {
    resource = {
      RPM: true,
    };
  }
  if (user && user.userType === 'Nurse') {
    resource = {
      RPM: true,
    };
  }
  return {
    // [DASHBOARD_ANALYTICS]: resource.analytics,
    [CLINICS_BOARD]: resource.clinics,
    [PATIENT_BOARD]: resource.patient,
    [MASTER_DATA]: resource.master_data,
    [CONTACT_SUPPORT]: resource.contact_support,
    [MANAGE_INVITE_LINKS]: resource.manage_invite_links,
    [SETTING]: resource['clinic-detail'],
    [USER_MANAGEMENT]: resource['user-list'],
    [MY_PATIENT]: resource.my_patient,
    [PATIENT_REQUESTS]: resource['patient-requests'],
    [RPM]: resource['RPM'],
    [DOCTOR_PATIENT_QUEUE]: resource['doctor-patient-queue'],
    [DOCTOR_PATIENT_QUEUE]: resource['doctor-patient-queue'],
    [SCHEDULE]: resource.schedule,
    [IMPORT]: resource.import,
    [PATIENT_WAITING_ROOM]: resource.patient_waiting_room,
    [DOCTOR_SEARCH]: resource['doctor-search'],
    [PAYMANT_ANALYTICS]: resource['paymentAnalytics'],
    [FOLLOW_UP]: resource['follow_up'],
    [SUPER_ADMIN_ANALYTICS]: resource['superAdminAnalytics'],
    [FAVOURITE_DOCTOR]: resource['FavoriteDoctor'],
    [DASHBOARD]: resource['Dashboard'],
    [SUPER_ADMIN_RPM]: resource['superAdminRPM'],
    [PATIENT_READING]: resource['patientReading'],
    [EMERGENCY_REQUEST]: resource['emergency-request'],
    [INSTANT_PATIENT_QUEUE]: resource['instant-patient-queue'],
    [PATIENT_CLINIC_SIGNUP]: resource.patient_clinic_signup,
    [LABAPPOINTMENT]: resource.lab_appointment,
  };
};

export const tabAccess = ({tab}) => ({user}) => {
  let {userType} = user || {};
  if (
    tab === 'classRoomLiveIncharge' ||
    tab === 'add-class-room-live-incharge'
  ) {
    return (
      userType === USER_TYPES.LIVE_SUPER_ADMIN ||
      userType === USER_TYPES.RESOURCE
    );
  } else {
    return userType === USER_TYPES.SUPER_ADMIN;
  }
};

export const getUserWiseDefaultView = ({user = {}}) => {
  let {userType, resource} = user;
  let type = (user && user.userType) || '';

  const userWiseViews = {
    SuperAdmin: 'superAdminRPM' || 'clinics',
    ANM: 'superAdminRPM',
    MO: 'superAdminRPM',
    ClinicAdmin: 'RPM' || 'analytics',
    Doctor: 'RPM' || 'doctor-patient-queue',
    FrontDesk: 'manage-invite-links',
    Patient: 'Dashboard',
    LabAdmin: 'labAppointment',
    DialysisAdmin: 'RPM',
    Nurse: 'RPM',
    MedicalAssistant: 'RPM',
    Admin: 'superAdminRPM',
  };

  return userWiseViews[type];
};
