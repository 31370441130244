import React from 'react';
import {
  View,
  ActivityIndicator,
  Text,
  isMobile,
  Dimensions,
} from '../../../app-components';
import {patientWatingRoomCard} from '../../../theme/shadows';
import {
  h24_AvenirNext_500,
  h16_Regular,
  h14_AvenirNext,
  h14_Regular,
} from '../../../theme/fonts';
const {height, width} = Dimensions.get('window');
const AnalyticsCard = (props) => {
  let {
    cardContent,
    loading = false,
    cardHeaderText,
    cardHeaderStyle = {},
    cardStyle = {},
  } = props;
  let justifyContentStyle =
    cardContent?.length > 1 ? 'space-between' : 'center';
  const getColumn = (content) => {
    let {
      header,
      title,
      subTitle,
      titleColor = 'green',
      headerColor,
      subTitleColor,
    } = content;
    return (
      <View style={{paddingLeft: 10, paddingRight: 10}}>
        <Text
          style={{
            ...h16_Regular,
            color: headerColor,
          }}>
          {header}
        </Text>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: 20,
          }}>
          <Text style={{...h24_AvenirNext_500, color: titleColor}}>
            {title != undefined && title != null ? title : 'N/A'}
          </Text>
          {subTitle != undefined && subTitle != null ? (
            <Text
              style={{...h14_AvenirNext, marginLeft: 10, color: subTitleColor}}>
              {subTitle}
            </Text>
          ) : (
            void 0
          )}
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        minWidth: isMobile ? width * 0.75 : 400,
        // maxWidth: isMobile ? width * 0.75 : 1000,
        ...patientWatingRoomCard,
        backgroundColor: '#fff',
        minHeight: 150,
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
        borderRadius: 10,
        margin: 10,
        ...cardStyle,
        flex: 1,
      }}>
      {cardHeaderText ? (
        <Text style={{...h14_Regular, ...cardHeaderStyle,marginBottom:isMobile?20:undefined}}>
          {cardHeaderText}
        </Text>
      ) : (
        void 0
      )}

      {!loading ? (
        <View
          style={{
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            flex: 1,
            justifyContent: justifyContentStyle,
          }}>
          {cardContent?.map((content, index) => {
            return getColumn(content);
          })}
        </View>
      ) : (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator size={'small'} />
        </View>
      )}
    </View>
  );
};

export default AnalyticsCard;
