import React from 'react';

import {
  View,
  Text,
  Image,
  Linking,
  TouchableOpacity,
} from '../../react-core-components';
import {canOpenInNewTab} from './fileUtility';

class FileDownloadLink extends React.Component {
  render() {
    let {
      children,
      value,
      openInNewTab,
      getDownloadUrl,
      linkTextStyle,
      linkViewStyle,
      extensions,
    } = this.props;
    let {key, name} = value || {};
    let downloadUrl =
      getDownloadUrl && getDownloadUrl({key, name, openInNewTab});
    let linkProps = {};

    if (openInNewTab && canOpenInNewTab({name: name || key, extensions})) {
      linkProps.target = '_blank';
    }
    let downloadLink = (
      <TouchableOpacity
        style={{cursor: 'pointer'}}
        onPress={() => Linking.openURL(downloadUrl, linkProps.target)}
        onClick={(e) => e && e.stopPropagation && e.stopPropagation()}>
        {children || <Text style={linkTextStyle}>{name || key || ''}</Text>}
      </TouchableOpacity>
    );
    return <View style={linkViewStyle}>{downloadLink}</View>;
  }
}

export default class FileRenderBox extends React.Component {
  render() {
    let {
      value,
      onRemove,
      editable,
      theme: {fileRenderBoxTheme},
    } = this.props;

    const {
      containerStyle,
      documentImageStyle: {
        viewStyle: _documentViewStyle,
        imageStyle: _documentImageStyle,
        image: _documentImage,
      },
      downloadLinkContainerStyle: {
        containerStyle: _downloadcontainerStyle,
        textStyle: _downloadTextStyle,
        linkViewStyle,
      },
      crossStyle: {
        viewStyle: _crossViewStyle,
        image: _crossImage,
        imageStyle: _crossImageStyle,
        textStyle: _crossTextStyle,
        text,
      },
    } = fileRenderBoxTheme || {};

    let RenderCompoment = ({value}) => {
      return (
        <View style={{...containerStyle}}>
          <View
            style={{
              ..._documentViewStyle,
            }}>
            <Image style={_documentImageStyle} source={_documentImage || ''} />
          </View>
          <View
            style={{
              ..._downloadcontainerStyle,
            }}>
            <FileDownloadLink
              {...this.props}
              linkTextStyle={_downloadTextStyle}
              linkViewStyle={linkViewStyle}
              value={value}
            />
          </View>
          {editable !== false && (
            <View
              style={{
                ..._crossViewStyle,
              }}
              onClick={() => {
                onRemove({item: value});
              }}>
              {_crossImage && _crossImageStyle ? (
                <Image
                  style={{..._crossImageStyle}}
                  source={_crossImage || ''}
                />
              ) : _crossTextStyle ? (
                <Text style={_crossTextStyle}>{text || 'X'}</Text>
              ) : null}
            </View>
          )}
        </View>
      );
    };

    if (value && Array.isArray(value)) {
      return value.map((val) => <RenderCompoment value={val} />);
    }

    return (
      <View style={{...containerStyle}}>
        <View
          style={{
            ..._documentViewStyle,
          }}>
          <Image style={_documentImageStyle} source={_documentImage || ''} />
        </View>
        <View
          style={{
            ..._downloadcontainerStyle,
          }}>
          <FileDownloadLink
            {...this.props}
            linkTextStyle={_downloadTextStyle}
            linkViewStyle={linkViewStyle}
            value={value}
          />
        </View>
        {editable !== false && (
          <View
            style={{
              ..._crossViewStyle,
            }}
            onClick={onRemove}>
            {_crossImage && _crossImageStyle ? (
              <Image style={{..._crossImageStyle}} source={_crossImage || ''} />
            ) : _crossTextStyle ? (
              <Text style={_crossTextStyle}>{text || 'X'}</Text>
            ) : null}
          </View>
        )}
      </View>
    );
  }
}
