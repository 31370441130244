import React from 'react';
import {View, Text, Image} from '../../react-core-components';
import {DropDownAction} from '../../react-drop-down-v1';

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

class ToolBar extends React.Component {
  getAction = ({action, index}) => {
    if (!action) {
      return null;
    }
    let {
      navigation,
      eventDispatcher,
      state,
      setState,
      Action,
      uid,
      onSelect,
      screenState,
      setScreenState,
      actionStyle,
      moreActionStyle,
      actionProps: extraActionProps,
    } = this.props;
    if (extraActionProps && typeof extraActionProps === 'function') {
      extraActionProps = extraActionProps(this.props);
    }
    let actionProps = {
      uid,
      navigation,
      eventDispatcher,
      index,
      state,
      setState,
      screenState,
      setScreenState,
      onSelect,
      ...extraActionProps,
    };
    let {
      containerStyle,
      activeContainerStyle,
      iconStyle: actionIconStyle,
      gap: actionGap = 8,
      textStyle: actionTextStyle,
    } = actionStyle || {};
    action = getRenderComponent(action, actionProps);
    if (React.isValidElement(action)) {
      return <View style={containerStyle}>{action}</View>;
    }
    if (Array.isArray(action)) {
      return (
        <DropDownAction
          options={action}
          Action={Action}
          navigation={navigation}
          eventDispatcher={eventDispatcher}
          {...moreActionStyle}
        />
      );
    }
    let {
      key,
      icon,
      activeIcon,
      text,
      isActive,
      style = containerStyle,
      activeStyle = activeContainerStyle,
      iconStyle = actionIconStyle,
      gap = actionGap,
      textStyle = actionTextStyle,
      visible = true,
      disabled,
    } = action;
    if (typeof visible === 'function') {
      visible = visible(actionProps);
    }
    if (!visible) {
      return null;
    }
    if (action.actions) {
      const {actions, ...rest} = action;
      return (
        <DropDownAction
          options={actions}
          Action={Action}
          navigation={navigation}
          eventDispatcher={eventDispatcher}
          {...rest}
          {...moreActionStyle}
        />
      );
    }
    if (typeof disabled === 'function') {
      disabled = disabled(actionProps);
    }
    actionProps = {
      ...actionProps,
      action,
      disabled,
    };
    let active = isActive && isActive(actionProps);
    if (active) {
      icon = activeIcon || icon;
      style = {
        ...style,
        ...activeStyle,
      };
    }
    let component = null;
    if (action.render) {
      component = getRenderComponent(action.render, actionProps);
    } else if (icon || text) {
      let {resizeMode = 'contain', ...restIconStyle} = iconStyle || {};
      component = (
        <>
          {icon && (
            <Image
              style={{objectFit: 'contain', ...restIconStyle}}
              source={icon}
              resizeMode={resizeMode}
            />
          )}
          {icon && text && gap ? <View style={{width: gap}} /> : void 0}
          {text && <Text style={textStyle}>{text}</Text>}
        </>
      );
    }
    if (!component) {
      return null;
    }
    return (
      <Action key={key} style={style} {...actionProps}>
        {component}
      </Action>
    );
  };

  getActions = ({actions}) => {
    let {actionContainerStyle} = this.props;
    if (actions && typeof actions === 'function') {
      actions = actions(this.props);
    }
    if (!actions) {
      return null;
    }
    if (Array.isArray(actions)) {
      if (!actions.length) {
        return null;
      }
      return (
        <View style={actionContainerStyle}>
          {actions.map((action, index) => {
            return this.getAction({action, index});
          })}
        </View>
      );
    } else {
      return this.getAction({action: actions});
    }
  };

  getTitle = ({title, titleTextStyle, selectionCount}) => {
    let {navigation, state} = this.props;
    if (typeof title === 'function') {
      title = title({navigation, state, selectionCount});
    }
    if (!title) {
      return null;
    }
    if (!React.isValidElement(title)) {
      if (typeof title !== 'string') {
        title = JSON.stringify(title);
      }
      title = (
        <Text
          style={{
            numberOfLines: 1,
            ...titleTextStyle,
          }}>
          {title}
        </Text>
      );
    }
    return title;
  };

  getTitleComponent = ({selectionCount}) => {
    let {
      title,
      subTitle,
      titleFlex,
      titleContainerStyle = {},
      titleTextStyle,
      subTitleTextStyle,
    } = this.props;
    if (selectionCount) {
      if (title && typeof title !== 'function') {
        title = `${selectionCount} Selected`;
      }
      subTitle = void 0;
    }
    let titleComponent = this.getTitle({title, titleTextStyle, selectionCount});
    let subTitleComponent = this.getTitle({
      title: subTitle,
      titleTextStyle: subTitleTextStyle,
      selectionCount,
    });
    if (!titleComponent && !subTitleComponent) {
      return;
    }
    let flexStyle = {};
    if (titleFlex !== false) {
      flexStyle.flex = titleFlex || 1;
      flexStyle.overflow = 'hidden';
    }
    return (
      <View style={{...flexStyle, ...titleContainerStyle}}>
        {titleComponent}
        {subTitleComponent}
      </View>
    );
  };

  render() {
    let {
      topSeparator,
      bottomSeparator,
      leftAction,
      leftActions,
      actions,
      containerStyle,
      itemsStyle,
      separatorStyle,
      selectionCount,
    } = this.props;

    let titleComponent = this.getTitleComponent({selectionCount});
    if (!leftAction && !titleComponent && !leftActions && !actions) {
      return null;
    }
    let leftActionsComponent = leftActions
      ? this.getActions({actions: leftActions})
      : void 0;
    return (
      <>
        {topSeparator && separatorStyle && <View style={separatorStyle} />}
        <View style={containerStyle}>
          <View style={itemsStyle}>
            {leftAction && this.getActions({actions: leftAction})}
            <View
              style={{
                flex: 1,
                overflow: 'hidden',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              {titleComponent}
              {leftActionsComponent ? (
                <View style={{flex: 1, overflow: 'hidden'}}>
                  {leftActionsComponent}
                </View>
              ) : null}
            </View>
            {this.getActions({actions})}
          </View>
        </View>
        {bottomSeparator && separatorStyle && <View style={separatorStyle} />}
      </>
    );
  }
}

export default ToolBar;
