import React, {useState, useEffect} from 'react';
import {
  attachmentIcon,
  pdf,
  bookingConfirm,
  crossPdf,
  MayaAiGirl,
} from '../../images';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
} from '../../app-components';
import {
  h15_AvenirNext_500,
  h14_AvenirNext,
  h16_Regular,
  h20_AvenirNext_600,
  h18_AvenirNext_500,
} from '../../theme/fonts';
import moment from 'moment';
import {
  TextAreaInput,
  MultiTextInput,
  UploadImage,
} from '../../app-components/input-components/Editors';
import {post, getImageUrl, getUser} from '../../AppServices';
import {isMobile} from '../../app-components';
import {confirmBox} from '../../theme/shadows';
import {getFileExtension} from '../../app-components/UtilityFunctions';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {colors} from '../../theme/colors';
import { resolveDoctorName } from '../../app-components/DoctorUtility';
const {themeContainer} = colors;

const BookingConfirm = (props) => {
  const {navigation} = props;
  const {t} = props;
  const {state: {params} = {}} = navigation || {};
  const [loading, setLoading] = useState(false);
  const {} = getUser() || {};

  const submitData = () => {
    setLoading(true);
    // const {res} = params;
    navigation.reset({view: 'patient-waiting-room'});
    setLoading(false);
    // post({
    //   data: {_id: res?._id},
    //   updates: {
    //     medical_report: file,
    //     patient_symptoms: symptom,
    //     illness_description: illnessDescription,
    //   },
    //   model: 'Appointments',
    // })
    //   .then(() => {
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });
  };
  return (
    <ScrollView
      style={{
        flex: 1,
        alignItems: 'center',
        paddingTop: 55,
        justifyContent: 'center',
        // paddingBottom: 97,
        // backgroundColor:"#fff"
      }}>
      <View
        style={{
          borderRadius: 16,
          // borderColor: 'rgba(53, 53, 53, 0.14)',
          width: 550,
          // backgroundColor: '#fff',
          ...confirmBox,
        }}>
        <View
          style={{
            // backgroundImage: `url(${bookingConfirm})`,
            backgroundColor: themeContainer,
            borderRadius: '16px 16px 0 0',
            // backgroundSize: 'contain',
            // backgroundRepeat: 'no-repeat',
            // paddingTop: 34,
            // paddingRight: 25,
            // paddingBottom: 34,
            // paddingLeft: 25,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <View
            style={{
              // marginTop: 34,
              // marginBottom: 34,
              // marginLeft: 25,
              margin: 20,
              marginRight: 0,
            }}>
            <Text
              style={{
                ...h20_AvenirNext_600,
                color: '#fff',
              }}>
              {/* {params?.titleText
                ? t(params?.titleText)
                : t('Confirm Your Booking' + '!')} */}
              Appointment Booked
            </Text>
            <View style={{}}>
              <Text
                style={{
                  ...h15_AvenirNext_500,
                  color: 'rgba(255, 255, 255, 0.85)',
                  marginTop: 6,
                }}>
                {/* Your Video Consultation has been succesfully booked */}
                {t('videoConsultSuccess')}
              </Text>
              <Text
                style={{
                  ...h15_AvenirNext_500,
                  color: 'rgba(255, 255, 255, 0.85)',
                  marginTop: 6,
                }}>
                {`with ${resolveDoctorName(params?.item)} at ${moment(params?.selectedTimeSlot?.start_time).format(
                  'hh:mm A',
                )} | ${moment(params?.selectdDate).format('DD MMMM YYYY')}`}
              </Text>
            </View>
          </View>
          <Image
            source={MayaAiGirl}
            style={{height: 150, objectFit: 'contain'}}
          />
        </View>

        <View style={{backgroundColor: '#fff', borderRadius: '0 0 16px 16px'}}>
          {/* SUBMIT OR Cancel BUTTONS */}
          <View style={style.buttonWrapper}>
            <TouchableOpacity
              style={style.submitButton}
              onPress={() => {
                if (!loading) {
                  submitData();
                }
              }}>
              <GradientButton
                label={t('OK')}
                loading={loading}
                indicatorColor={'#fff'}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const style = {
  buttonWrapper: {
    marginBottom: 25,
    marginTop: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#fff',
    // paddingRight: 35,
  },
  submitButton: {
    cursor: 'pointer',
    marginLeft: 15,
    marginRight: 15,
    paddingLeft: 13.5,
    paddingRight: 13.5,
  },
};

export default BookingConfirm;
