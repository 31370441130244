import React from 'react';
import {getResolvedStyle} from './Utility';
import AnimatedLabel from './AnimatedLabel';
class InputLabel extends React.Component {
  render() {
    let {
      label,
      labelPosition,
      error,
      hover,
      isActive,
      labelClassName,
      labelContainerStyle,
      floatingLabelContainerStyle,
      labelStyle,
      floatingLabelstyle,
      hoverLabelStyle,
      topHoverLabelStyle,
      activeLabelStyle,
      errorLabelStyle,
      variant = 'standard',
      getInputRef,
    } = this.props;
    if (variant !== undefined) {
      if (typeof variant === 'string') {
        labelContainerStyle =
          this.props[`${variant}LabelContainerStyle`] || labelContainerStyle;
        floatingLabelContainerStyle =
          this.props[`${variant}FloatingLabelContainerStyle`] ||
          floatingLabelContainerStyle;
      }
    }
    if (labelPosition === 'floating') {
      labelStyle = {
        ...labelStyle,
        ...floatingLabelstyle,
      };
      hoverLabelStyle = {
        ...hoverLabelStyle,
        ...topHoverLabelStyle,
      };
    }
    labelStyle = getResolvedStyle({
      hover,
      error,
      isActive,
      style: labelStyle,
      activeStyle: activeLabelStyle,
      hoverStyle: hoverLabelStyle,
      errorStyle: errorLabelStyle,
    });
    return (
      <AnimatedLabel
        className={labelClassName}
        style={labelContainerStyle}
        floatingStyle={floatingLabelContainerStyle}
        labelStyle={labelStyle}
        labelPosition={labelPosition}
        label={label}
        getInputRef={getInputRef}
      />
    );
  }
}

InputLabel.defaultProps = {
  labelContainerStyle: {
    top: 13,
    left: 12,
  },
  floatingLabelContainerStyle: {
    left: 12,
    top: 5,
  },
  outlinedFloatingLabelContainerStyle: {
    top: -5,
    backgroundColor: '#ffffff',
    paddingLeft: 6,
    paddingRight: 6,
  },
  labelStyle: {
    fontSize: 14,
    color: '#8E8E8E',
  },
  floatingLabelstyle: {
    fontSize: 10,
    color: '#4B4B4B',
  },
  hoverLabelStyle: {
    color: '#a2a2a2',
  },
  activeLabelStyle: {
    color: '#4276EC',
  },
};

export default InputLabel;
