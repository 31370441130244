import {colors} from './colors';
import {shadow} from './shadows';
import {back, blueArrowDown} from '../images';
import {Platform} from '../app-components';

import {h14_Medium, h14_Regular, h16_Regular} from './fonts';
import {manazeV6Colors} from './manazeV6Colors';

import {Headline8, Headline9, Headline13} from './Headlines';
import {standardDropDownTheme} from './dropDownTheme';

const {
  textColor87,
  themeColor,
  textColor70,
  defaultThemeColor,
  tabBackgroundColor,
} = colors;

const {
  neutral_1,
  neutral_8,
  primary_2,
  primary_5,
  neutral_10,
  neutral_4,
  neutral_9,
  surface_white,
} = manazeV6Colors;

export const moreActionStyle = {
  style: {justifyContent: 'center', padding: 6},
  textStyle: {
    marginLeft: 12,
  },
  dropdownStyle: {
    width: 200,
    height: 'auto',
    maxHeight: 300,
    backgroundColor: surface_white,
    borderRadius: 4,
    ...shadow,
  },
  ...standardDropDownTheme,
};

const tabHeaderToolBarTheme = {
  containerStyle: {
    height: 82,
    paddingLeft: 24,
    paddingRight: 24,
    justifyContent: 'center',
    backgroundColor: surface_white,
    borderBottomWidth: 2,
    borderBottomColor: neutral_8,
  },
  itemsStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleTextStyle: {
    paddingLeft: 13,
    color: neutral_1,
    ...Headline9,
  },
  actionContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionStyle: {
    containerStyle: {
      padding: 8,
      marginLeft: 4,
      alignItems: 'center',
      flexDirection: 'row',
    },
    iconStyle: {
      height: 20,
      width: 20,
      objectFit: 'contain',
      resizeMode: 'contain',
    },
    textStyle: {...h16_Regular, color: textColor87},
  },
  moreActionStyle: {
    ...moreActionStyle,
  },
};

export const tabActionStyle = {
  containerStyle: {
    justifyContent: 'center',
  },
  itemsStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionStyle: {
    containerStyle: {
      marginLeft: 15,
      marginRight: 15,
      alignItems: 'center',
      flexDirection: 'row',
    },
    activeContainerStyle: {
      borderRadius: 4,
      backgroundColor: primary_5,
      marginLeft: 4,
    },
    iconStyle: {
      height: 20,
      width: 20,
      objectFit: 'contain',
      resizeMode: 'contain',
    },
    textStyle: {...h16_Regular, color: textColor87, paddingLeft: 16},
  },
  moreActionStyle: {
    ...moreActionStyle,
  },
};

export const tabGroupItemsStyle = {
  autoHide: true,
  dropdownStyle: {
    backgroundColor: '#fff',
    zIndex: 1,
    ...shadow,
    width: 200,
    height: 'auto',
    maxHeight: 300,
    borderRadius: 4,
    ...shadow,
  },
  ...standardDropDownTheme,
};

export const tabTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 70,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: '#e7eef5',
    },
    tabRowStyle: {
      flex: 1,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      marginLeft: 15,
      marginRight: 15,
      marginTop: 14,
      marginBottom: 14,
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeBorderStyle: {
      height: 3,
      borderRadius: 2,
      width: 42,
      backgroundColor: themeColor,
      position: 'absolute',
      bottom: 0,
    },
    itemContainerStyle: {flexDirection: 'row', alignItems: 'center'},
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...h16_Regular,
      color: textColor70,
    },
    activeTextStyle: {
      ...h16_Regular,
      color: themeColor,
    },
    countStyle: {
      marginLeft: 3,
      ...h16_Regular,
      color: textColor70,
    },
    activeCountStyle: {
      color: themeColor,
    },
  },
  tabActionStyle: {
    ...tabActionStyle,
  },
  tabGroupItemsStyle: {
    ...tabGroupItemsStyle,
  },
};

export const tabMobileTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 70,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: '#e7eef5',
    },
    tabRowStyle: {
      flex: 1,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      marginLeft: 15,
      marginRight: 15,
      marginTop: 14,
      marginBottom: 14,
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeBorderStyle: {
      height: 3,
      borderRadius: 2,
      width: 42,
      backgroundColor: themeColor,
      position: 'absolute',
      bottom: 0,
    },
    itemContainerStyle: {flexDirection: 'row', alignItems: 'center'},
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...h16_Regular,
      color: textColor70,
    },
    activeTextStyle: {
      ...h16_Regular,
      color: themeColor,
    },
    countStyle: {
      marginLeft: 3,
      ...h16_Regular,
      color: textColor70,
    },
    activeCountStyle: {
      color: themeColor,
    },
  },
  tabActionStyle: {
    ...tabActionStyle,
  },
  tabGroupItemsStyle: {
    ...tabGroupItemsStyle,
  },
};

export const centerTabTheme = {
  ...tabTheme,
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 70,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: tabBackgroundColor,
      alignItems: 'center',
    },
    tabRowStyle: {
      flex: 1,
    },
  },
};

export const centerTabMobileTheme = {
  ...tabMobileTheme,
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 50,
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: tabBackgroundColor,
      alignItems: 'center',
    },
    tabRowStyle: {
      flex: 1,
    },
  },
};

export const roundedTabTheme = {
  ...tabTheme,
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 70,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: '#fff',
      borderBottomWidth: 1,
      borderBottomColor: 'rgba(151, 151, 151, 0.37)',
    },
    tabRowStyle: {
      flex: 1,
    },
  },
  tabBarStyle: {
    containerStyle: {
      alignItems: 'center',
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: 15,
      marginRight: 15,
      borderRadius: 15,
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeContainerStyle: {
      backgroundColor: '#eefafc',
    },
    itemContainerStyle: {flexDirection: 'row', alignItems: 'center'},
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...h14_Regular,
      color: textColor70,
    },
    activeTextStyle: {
      ...h14_Medium,
      color: themeColor,
      fontWeight: 500,
    },
    countStyle: {
      marginLeft: 3,
      ...h16_Regular,
      color: textColor70,
    },
    activeCountStyle: {
      color: themeColor,
      fontWeight: 500,
    },
  },
};

export const roundedTabMobileTheme = {
  ...tabMobileTheme,
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 50,
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#fff',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: 'rgba(151, 151, 151, 0.37)',
    },
    tabRowStyle: {
      flex: 1,
    },
  },
  tabBarStyle: {
    containerStyle: {
      alignItems: 'center',
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: 15,
      marginRight: 15,
      borderRadius: 15,
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeContainerStyle: {
      backgroundColor: '#eefafc',
    },
    itemContainerStyle: {flexDirection: 'row', alignItems: 'center'},
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...h16_Regular,
      color: textColor70,
    },
    activeTextStyle: {
      ...h16_Regular,
      color: themeColor,
      fontWeight: 500,
    },
    countStyle: {
      marginLeft: 3,
      ...h16_Regular,
      color: textColor70,
    },
    activeCountStyle: {
      color: themeColor,
      fontWeight: 500,
    },
  },
};

export const formTabTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 50,
      borderBottomWidth: 2,
      borderBottomColor: neutral_8,
    },
    tabRowStyle: {
      flex: 1,
      paddingLeft: 4,
      paddingRight: 4,
      backgroundColor: surface_white,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      marginLeft: 16,
      marginRight: 16,
      justifyContent: 'center',
    },
    activeBorderStyle: {
      height: 2,
      backgroundColor: primary_2,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
    itemContainerStyle: {flexDirection: 'row', alignItems: 'center'},
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...Headline9,
      color: neutral_1,
    },
    activeTextStyle: {
      ...Headline8,
      color: primary_2,
    },
  },
  tabActionStyle: {
    ...tabActionStyle,
  },
  tabGroupItemsStyle: {
    ...tabGroupItemsStyle,
  },
};

export const formTabMobileTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 50,
      borderBottomWidth: 2,
      borderBottomColor: neutral_8,
    },
    tabRowStyle: {
      flex: 1,
      paddingLeft: 4,
      paddingRight: 4,
      backgroundColor: surface_white,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      marginLeft: 16,
      marginRight: 16,
      justifyContent: 'center',
    },
    activeBorderStyle: {
      height: 2,
      backgroundColor: primary_2,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
    itemContainerStyle: {alignItems: 'center'},
    iconStyle: {
      height: 20,
      width: 20,
    },
    textStyle: {
      ...Headline9,
      color: neutral_1,
    },
    activeTextStyle: {
      ...Headline8,
      color: primary_2,
    },
  },
  tabActionStyle: {
    ...tabActionStyle,
  },
  tabGroupItemsStyle: {
    ...tabGroupItemsStyle,
  },
  tabHeaderStyle: {...tabHeaderToolBarTheme},
};

export const bottomTabTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 44,
      backgroundColor: neutral_9,
    },
    tabRowStyle: {
      flex: 1,
      paddingLeft: 4,
      paddingRight: 4,
      backgroundColor: surface_white,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      paddingLeft: 16,
      paddingRight: 16,
      justifyContent: 'center',
    },
    activeContainerStyle: {
      backgroundColor: neutral_10,
      paddingTop: 4,
      marginBottom: 4,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    itemContainerStyle: {flexDirection: 'row', alignItems: 'center'},
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...Headline9,
      color: neutral_1,
    },
    activeTextStyle: {
      ...Headline8,
      color: primary_2,
    },
    groupIconContainerStyle: {
      paddingLeft: 12,
    },
    groupIcon: blueArrowDown,
    selectedGroupIcon: blueArrowDown,
  },
  tabActionStyle: {
    ...tabActionStyle,
  },
  tabGroupItemsStyle: {
    ...tabGroupItemsStyle,
  },
  tabHeaderStyle: {...tabHeaderToolBarTheme},
};

export const bottomTabMobileTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 44,
      backgroundColor: neutral_9,
    },
    tabRowStyle: {
      flex: 1,
      paddingLeft: 4,
      paddingRight: 4,
      backgroundColor: surface_white,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      paddingLeft: 16,
      paddingRight: 16,
      justifyContent: 'center',
    },
    activeContainerStyle: {
      backgroundColor: neutral_10,
      paddingTop: 4,
      marginBottom: 4,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    itemContainerStyle: {alignItems: 'center'},
    iconStyle: {
      height: 20,
      width: 20,
    },
    textStyle: {
      ...Headline9,
      color: neutral_1,
    },
    activeTextStyle: {
      ...Headline8,
      color: primary_2,
    },
    groupIconContainerStyle: {
      paddingLeft: 12,
      paddingTop: Platform.OS === 'web' ? 0 : 4,
    },
    groupIcon: blueArrowDown,
    activeGroupIcon: blueArrowDown,
  },
  tabActionStyle: {
    ...tabActionStyle,
  },
  tabGroupItemsStyle: {
    ...tabGroupItemsStyle,
  },
  tabHeaderStyle: {...tabHeaderToolBarTheme},
};
