export const clearSelectionState = ({screenState, setScreenState} = {}) => {
  if (!setScreenState) {
    return;
  }
  let {selectedIds, allPageSelected, allSelected} = screenState || {};
  if ((selectedIds && selectedIds.length) || allSelected || allPageSelected) {
    setScreenState({
      selectedIds: void 0,
      allPageSelected: void 0,
      allSelected: void 0,
    });
  }
};

export const getSelectionState = ({screenState = {}} = {}) => {
  return screenState;
};

export const setSelectionState = ({
  item,
  screenState,
  setScreenState,
  singleSelection,
} = {}) => {
  if (!item) {
    return;
  }
  const field = '_id';
  let {selectedIds = [], allSelected, allPageSelected} = screenState || {};
  let itemValue = item[field] || item;
  if (itemValue) {
    let idIndex = selectedIds.findIndex(key => key === itemValue);
    if (idIndex > -1) {
      allSelected = false;
      allPageSelected = false;
      selectedIds = [...selectedIds];
      selectedIds.splice(idIndex, 1);
    } else {
      if (singleSelection) {
        selectedIds = [itemValue];
      } else {
        selectedIds = [...selectedIds, itemValue];
      }
    }
  }

  setScreenState && setScreenState({selectedIds, allSelected, allPageSelected});
};

export const setAllSelectionState = ({
  setScreenState,
  data,
  field = '_id',
}) => {
  if (!data || !data.length) {
    return;
  }
  let selectedIds = data.map(item => item[field] || item);
  setScreenState && setScreenState({selectedIds, allSelected: true});
};


export const setAllPageSelectionState = ({
  setScreenState,
}) => {
  setScreenState && setScreenState({allPageSelected: true});
};
