import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import algorithmForm from './algorithmForm';

let header = {
  title: 'Add Algorithm',
  actions: [cancelButton(), save()],
};

export default algorithmForm({header});
