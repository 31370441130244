import {colors, bgs} from './colors';
import {h13_Regular} from './fonts';
let {textWhite, lightGrey, textColor20} = colors;
let {lightGrayColor} = bgs;
let placeholderFont = void 0;
if (window.innerWidth < 800) {
  if (window.innerWidth < 480) {
    placeholderFont = h13_Regular;
  }
}
const ftsThemeMD = {
  searchContainerStyle: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    paddingRight: 15,
    paddingLeft: 15,
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: textColor20,
    marginRight: 5,
  },
  inputStyle: {
    height: 30,
    flex: 1,
    outline: 'none',
    borderWidth: 0,
    backgroundColor: '#fff',
    paddingLeft: 14,
    paddingRight: 14,
  },
  searchImageStyle: {
    opacity: 0.5,
    width: 17,
    height: 17,
  },
  crossImageStyle: {
    width: 20,
    height: 20,
  },
};

const ftsThemeSM = {
  searchContainerStyle: {
    flexDirection: 'row',
    paddingRight: 10,
    paddingLeft: 5,
    alignItems: 'center',
    backgroundColor: lightGrayColor,
    marginRight: 5,
    paddingTop: 3,
    paddingBottom: 3,
    marginTop: 14,
  },
  inputStyle: {
    ...placeholderFont,
    height: 30,
    paddingLeft: 10,
    outline: 'none',
    border: `0px solid ${textWhite}`,
    backgroundColor: lightGrayColor,
  },
  searchImageStyle: {
    opacity: 0.5,
    width: 17,
    height: 17,
  },
};

const ftsThemeV2MD = {
  searchContainerStyle: {
    height: 30,
    flexDirection: 'row',
    paddingRight: 10,
    paddingLeft: 5,
    alignItems: 'center',
    marginRight: 5,
    paddingTop: 3,
    paddingBottom: 3,
  },
  inputStyle: {
    height: 30,
    paddingLeft: 10,
    outline: 'none',
    border: `0px solid ${textWhite}`,
    backgroundColor: lightGrayColor,
  },
  searchImageStyle: {
    opacity: 0.5,
    width: 17,
    height: 17,
  },
  crossImageStyle: {
    opacity: 0.5,
    width: 12,
    height: 12,
  },
};

const ftsThemeV2SM = {
  searchContainerStyle: {
    height: 30,
    flexDirection: 'row',
    paddingRight: 10,
    paddingLeft: 5,
    alignItems: 'center',
    marginRight: 5,
    paddingTop: 3,
    paddingBottom: 3,
  },
  inputStyle: {
    ...placeholderFont,
    height: 30,
    paddingLeft: 10,
    outline: 'none',
    border: `0px solid ${textWhite}`,
    backgroundColor: lightGrayColor,
  },
  searchImageStyle: {
    opacity: 0.5,
    width: 17,
    height: 17,
  },
  crossImageStyle: {
    opacity: 0.5,
    width: 12,
    height: 12,
  },
};

export {ftsThemeSM, ftsThemeMD, ftsThemeV2MD, ftsThemeV2SM};
