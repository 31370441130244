import React from 'react';

import {Image, TouchableOpacity} from '../../react-core-components';

import SelectDate from './SelectDate';
import {WithModal} from '../../react-with-modal';

export default class DateCalendar extends React.Component {
  onChange = (value) => {
    let {onDateChange} = this.props;
    onDateChange && onDateChange(value);
  };

  render() {
    let {
      mode,
      backdropStyle,
      pushModal = true,
      iconContainerStyle,
      modalStyle,
      calendarIconStyle,
      timeCalendarIconStyle,
      calendarStyle = {},
    } = this.props || {};
    let ValueSelector = mode === 'time' ? null : SelectDate;
    const renderModal = ({frameStyle, hideModal}) => {
      let valueSelectorComponent = ValueSelector && (
        <ValueSelector
          {...this.props}
          theme={calendarStyle}
          mode={mode}
          hideModal={hideModal}
          onChange={this.onChange}
        />
      );
      return (
        <TouchableOpacity
          style={{...frameStyle, ...modalStyle}}
          onPress={(e) => {
            e.stopPropagation();
          }}>
          {valueSelectorComponent}
        </TouchableOpacity>
      );
    };

    let container = mode == 'time' ? timeCalendarIconStyle : calendarIconStyle;

    return (
      <WithModal
        style={iconContainerStyle || container.viewStyle}
        backdropStyle={backdropStyle}
        renderModal={renderModal}
        autoHide={true}
        dropdownStyle={{height: 230, width: 230}}
        pushModal={pushModal}>
        <Image style={container.imageStyle} source={container.image}></Image>
      </WithModal>
    );
  }
}
