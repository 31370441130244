import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {PLACEHOLDER} from '../../app-components/utils';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {mayaEngine} from '../../Config';
import {autoSuggestInput, searchInput} from '../../autoSuggestions';
import axios from 'axios';
const splitResult = (item) => {
  const {title = ''} = item || {};
  const splitedData = title.split(':');
  let name = '';
  if (Array.isArray(splitedData) && splitedData.length) {
    if (splitedData.length > 1) {
      name = splitedData[1].trim();
    } else {
      name = splitedData[0].trim();
    }
  }
  return name;
};
const modifyAlgorithmCategory = ({data}) => {
  const newData = data.map((item) => {
    return {
      ...item,
      name: splitResult(item),
    };
  });
  return {data: newData};
};
const visibleVideoField = ({contentType = ''}) => contentType === 'video';
const visibleArticleField = ({contentType = ''}) => contentType === 'article';
const visibleAlgorithmField = ({contentType = ''}) =>
  contentType === 'algorithm';
const visiblePdfField = ({contentType = ''}) => contentType === 'pdf';
// const visibleHTMLField = ({contentType = ''}) => contentType === 'html';
export default ({uri, editable = true, header}) => {
  return Form({
    type: 'standardShadow',
    defaultValues: ({navigation}) => {
      const {state: {params: {item = {}} = {}} = {}} = navigation || {};
      let {algorithmCategory} = item || {};
      if (visibleAlgorithmField(item)) {
        algorithmCategory = {
          ...(algorithmCategory || {}),
          name: splitResult(algorithmCategory),
        };
      }
      return {
        ...item,
        algorithmCategory,
      };
    },
    onSubmit: submit({
      model: 'EducationContents',
    }),
    reloadEvent: 'reloadEducationContent',
    closeView: 1,
    mandatory: {title: 1, contentType: 1, educationCategory: 1, tag: 1},
    lg: {
      header,

      formGroups: [
        {
          direction: 'row',
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              // width: 220,
              columns: [
                searchInput({
                  label: 'Education Category',
                  field: 'educationCategory',
                  placeholder: 'Select Category',
                  query: 'EducationCategoryList',
                  model: 'EducationCategory',
                  valueField: 'name',
                  suggestionField: 'name',
                }),
                {
                  type: 'text',
                  field: 'title',
                  label: 'Title',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  editable: editable,
                },
                {
                  type: 'text',
                  field: 'tag',
                  label: 'Tag',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'chipsToggle',
                  field: 'contentType',
                  label: 'Content Type',
                  mandatory: true,
                  options: [
                    {value: 'video', label: 'Video'},
                    {value: 'article', label: 'Article'},
                    {value: 'algorithm', label: 'Algorithm'},
                    {value: 'pdf', label: 'PDF'},
                    // {value: 'html', label: 'HTML'},
                  ],
                },
                {
                  type: 'checkBox',
                  field: 'isPublished',
                  label: 'Publish',
                },
              ],
            },
            {
              columnsPerRow: 1,
              columns: [
                {
                  type: 'textArea',
                  field: 'shortDescription',
                  label: 'Short Description',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  editable: editable,
                  visible: ({data}) => visibleArticleField(data),
                },
                // {
                //   type: 'html',
                //   field: 'sk',
                //   visible: ({data}) => visibleHTMLField(data),
                // },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'Description',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  editable: editable,
                  visible: ({data}) => visibleArticleField(data),
                },
                {
                  field: 'imageFile',
                  placeholder: 'File',
                  label: 'Image',
                  uploadOptions: {s3: true},
                  type: 'file',
                  visible: ({data}) => visibleArticleField(data),
                },
                {
                  field: 'pdfFile',
                  placeholder: 'File',
                  label: 'Pdf',
                  uploadOptions: {s3: true},
                  type: 'file',
                  visible: ({data}) => visiblePdfField(data),
                },
                {
                  field: 'iconFile',
                  placeholder: 'File',
                  label: 'Icon Image',
                  uploadOptions: {s3: true},
                  type: 'file',
                  visible: ({data}) => visibleArticleField(data),
                },
                {
                  field: 'videoFile',
                  placeholder: 'File',
                  label: 'Video',
                  uploadOptions: {s3: true},
                  type: 'file',
                  visible: ({data}) => visibleVideoField(data),
                },
                {
                  type: 'text',
                  field: 'videoLink',
                  label: 'Video Link',
                  placeholder: PLACEHOLDER,
                  editable: editable,
                  visible: ({data}) => visibleVideoField(data),
                },
                searchInput(
                  {
                    label: 'Algorithm Categories',
                    placeholder: 'Add Algorithm Categories here..',
                    field: 'algorithmCategory',
                    query: 'AlgorithmCategoryList',
                    idField: '_id',
                    model: 'AlgorithmCategories',
                    valueField: 'name',
                    suggestionField: 'name',
                    searchField: 'name',
                    visible: ({data}) => visibleAlgorithmField(data),
                  },
                  {
                    modifyResult: modifyAlgorithmCategory,
                  },
                ),
                autoSuggestInput({
                  label: 'Algorithm Name',
                  placeholder: 'Add Algorithm Name',
                  field: 'algorithmId',
                  idField: 'id',
                  valueField: 'name',
                  suggestionField: 'name',
                  searchField: 'name',
                  keyField: 'id',
                  visible: ({data}) => visibleAlgorithmField(data),
                  fetch: ({data}) => {
                    const {algorithmCategory: {title = ''} = {}} = data || {};
                    if (title) {
                      let category = [title];
                      return axios
                        .post(mayaEngine.newApiUrl, {
                          apiKey: mayaEngine.apiKey,
                          apiSecret: mayaEngine.apiSecret,
                          requestType: 'getAlgorithmicSymptom',
                          category,
                        })
                        .then((response) => {
                          let {
                            data: {result},
                          } = response;
                          return {data: result};
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    } else {
                      return {data: []};
                    }
                  },
                }),
                {
                  type: 'custom',
                  render: () => [],
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                searchInput({
                  label: 'Education Category',
                  field: 'educationCategory',
                  placeholder: 'Select Category',
                  query: 'EducationCategoryList',
                  model: 'EducationCategory',
                  valueField: 'name',
                  suggestionField: 'name',
                }),
                {
                  type: 'text',
                  field: 'title',
                  label: 'Title',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  editable: editable,
                },
                {
                  type: 'chipsToggle',
                  field: 'contentType',
                  label: 'Content Type',
                  mandatory: true,
                  options: [
                    {value: 'video', label: 'Video'},
                    {value: 'article', label: 'Article'},
                    {value: 'algorithm', label: 'Algorithm'},
                    {value: 'pdf', label: 'PDF'},
                  ],
                },
                {
                  type: 'checkBox',
                  field: 'isPublished',
                  label: 'Publish',
                },

                {
                  type: 'textArea',
                  field: 'shortDescription',
                  label: 'Short Description',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  editable: editable,
                  visible: ({data}) => visibleArticleField(data),
                },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'Description',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  editable: editable,
                  visible: ({data}) => visibleArticleField(data),
                },
                {
                  field: 'imageFile',
                  placeholder: 'File',
                  label: 'Image',
                  uploadOptions: {s3: true},
                  type: 'file',
                  visible: ({data}) => visibleArticleField(data),
                },
                {
                  field: 'pdfFile',
                  placeholder: 'File',
                  label: 'Pdf',
                  uploadOptions: {s3: true},
                  type: 'file',
                  visible: ({data}) => visiblePdfField(data),
                },
                {
                  field: 'iconFile',
                  placeholder: 'File',
                  label: 'Icon Image',
                  uploadOptions: {s3: true},
                  type: 'file',
                  visible: ({data}) => visibleArticleField(data),
                },
                {
                  field: 'videoFile',
                  placeholder: 'File',
                  label: 'Video',
                  uploadOptions: {s3: true},
                  type: 'file',
                  visible: ({data}) => visibleVideoField(data),
                },
                {
                  type: 'text',
                  field: 'videoLink',
                  label: 'Video Link',
                  placeholder: PLACEHOLDER,
                  editable: editable,
                  visible: ({data}) => visibleVideoField(data),
                },
                searchInput({
                  label: 'Algorithm Categories',
                  placeholder: 'Add Algorithm Categories here..',
                  field: 'algorithmCategory',
                  query: 'AlgorithmCategoryList',
                  idField: '_id',
                  model: 'AlgorithmCategories',
                  valueField: 'title',
                  suggestionField: 'title',
                  searchField: 'title',
                  visible: ({data}) => visibleAlgorithmField(data),
                }),
                autoSuggestInput({
                  label: 'Algorithm Name',
                  placeholder: 'Add Algorithm Name',
                  field: 'algorithmId',
                  idField: 'id',
                  valueField: 'name',
                  suggestionField: 'name',
                  searchField: 'name',
                  keyField: 'id',
                  visible: ({data}) => visibleAlgorithmField(data),
                  fetch: ({data}) => {
                    const {algorithmCategory: {title = ''} = {}} = data || {};
                    if (title) {
                      let category = [title];
                      return axios
                        .post(mayaEngine.newApiUrl, {
                          apiKey: mayaEngine.apiKey,
                          apiSecret: mayaEngine.apiSecret,
                          requestType: 'getAlgorithmicSymptom',
                          category,
                        })
                        .then((response) => {
                          let {
                            data: {result},
                          } = response;
                          return {data: result};
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    } else {
                      return {data: []};
                    }
                  },
                }),
              ],
            },
          ],
        },
      ],
    },
  });
};
