import React from 'react';
import PropTypes from 'prop-types';
import {WithReactForm} from './WithReactFormContext';
import {resolveExp} from '../../react-utility-functions';

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

class FormField extends React.Component {
  getErrorMessage = () => {
    let {
      field,
      form_state: {validationErrors, mandatoryErrors} = {},
    } = this.props;
    if (!field) {
      return;
    }
    if (mandatoryErrors && mandatoryErrors[field]) {
      return mandatoryErrors[field];
    } else if (validationErrors && validationErrors[field]) {
      return validationErrors[field];
    }
  };

  setFocus = (focus, e) => {
    let {field, form_context: {setFocus} = {}} = this.props;
    field && setFocus && setFocus({field, focus, event: e});
  };

  setValue = props => {
    let {field, form_context: {setValue} = {}} = this.props;
    field && setValue && setValue({field, ...props});
  };

  getMeta = () => {
    let {
      field,
      form_state: {focusField, focussedFields} = {},
      form_context: {setFocus} = {},
    } = this.props;
    if (!field) {
      return;
    }
    let isActive = setFocus && focusField ? focusField === field : void 0;
    let isTouched =
      setFocus && focussedFields && focussedFields[field] ? true : void 0;
    let meta = {isActive, isTouched};
    let error = this.getErrorMessage();
    if (error) {
      meta.error = error;
    }
    return meta;
  };

  render() {
    let {
      field,
      form_state: {data = {}} = {},
      form_context: {navigation, eventDispatcher} = {},
      children,
      ...restProps
    } = this.props;
    if (!children) {
      return;
    }
    let value = data && field ? resolveExp(data, field) : void 0;
    let childrenProps = {
      ...restProps,
      ...this.getMeta(),
      data,
      field,
      value,
      setValue: this.setValue,
      setFocus: this.setFocus,
      navigation,
      eventDispatcher,
    };
    return getRenderComponent(children, childrenProps);
  }
}

FormField.propTypes = {
  field: PropTypes.string,
};

FormField = WithReactForm(FormField, {
  key: 'form',
});

export default FormField;
