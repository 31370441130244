import React, {useCallback, useState} from 'react';
import {View, Text, TouchableOpacity} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {invoke} from '../../AppServices';
import {h22_SemiBold} from '../../theme/fonts';

function ViewNoteLanguageSelectModal(props) {
  const {navigation = {}} = props;
  const {state: {params = {}} = {}} = navigation;
  const {patientId} = params;
  const [forceUpdate, setForceUpdate] = useState(new Date());
  const [isLoading, setLoading] = useState({
    en: false,
    kha: false,
  });

  const handleViewNote = useCallback(
    async (patientId, language) => {
      // language === 'en'
      //   ? setLoading({
      //       en: true,
      //       kha: false,
      //     })
      //   : setLoading({
      //       en: false,
      //       kha: true,
      //     });
      navigation.push({
        view: 'notes',
        params: {
          // dataArr: [result],
          // prevNote: true,
          // clickedIndex: 0,
          // care_program: true,
          // forceUpdate: setForceUpdate,
          careProgram: true,
          patientItem: {patient_id: {_id: params?.patientId}},
          language,
          modalWidth: 580,
        },
        modal: true,

        modalProps: {
          autoHide: true,
          minHeight: 180,
          position: 'screenLeft',
          style: {
            height: '100%',
            width: 580,
            zIndex: 999,
          },
        },
      });
      // await invoke({
      //   id: 'viewNoteForMultipleLanguages',
      //   paramValue: {
      //     patientId,
      //     language,
      //   },
      // })
      //   .then((res) => {
      //     setLoading({
      //       en: false,
      //       kha: false,
      //     });
      //     const result = {mayamd_note: res};
      //     navigation.push({
      //       view: 'notes',
      //       params: {
      //         // dataArr: [result],
      //         // prevNote: true,
      //         // clickedIndex: 0,
      //         // care_program: true,
      //         // forceUpdate: setForceUpdate,
      //         careProgram: true,
      //         patientItem: {patient_id: {_id: params?.patientId}},
      //         modalWidth: 580,
      //       },
      //       modal: true,

      //       modalProps: {
      //         autoHide: true,
      //         minHeight: 180,
      //         position: 'screenLeft',
      //         style: {
      //           height: '100%',
      //           width: 580,
      //           zIndex: 999,
      //         },
      //       },
      //     });
      //   })
      //   .catch((err) => {
      //     setLoading({
      //       en: false,
      //       kha: false,
      //     });
      //     console.log('error', err);
      //   });
    },
    [navigation, params.patientId],
  );

  const Click = useCallback(
    (language) => {
      handleViewNote(patientId, language);
    },
    [handleViewNote, patientId],
  );

  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: 500,
        padding: 20,
      }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginRight: '5%',
        }}>
        <Text
          style={{
            marginBottom: 10,
            marginTop: 10,
            fontSize: '45px',
            color: '#275dad',
            ...h22_SemiBold,
          }}>
          Select Note Language
        </Text>
        <TouchableOpacity
          onPress={() => {
            navigation.pop();
          }}>
          <img
            alt="svgImg"
            style={{
              cursor: 'pointer',
              // marginBottom: 10,
              // marginTop: 10,
              height: '25px',
              width: '25px',
            }}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBkPSJNIDQuNzA3MDMxMiAzLjI5Mjk2ODggTCAzLjI5Mjk2ODggNC43MDcwMzEyIEwgMTAuNTg1OTM4IDEyIEwgMy4yOTI5Njg4IDE5LjI5Mjk2OSBMIDQuNzA3MDMxMiAyMC43MDcwMzEgTCAxMiAxMy40MTQwNjIgTCAxOS4yOTI5NjkgMjAuNzA3MDMxIEwgMjAuNzA3MDMxIDE5LjI5Mjk2OSBMIDEzLjQxNDA2MiAxMiBMIDIwLjcwNzAzMSA0LjcwNzAzMTIgTCAxOS4yOTI5NjkgMy4yOTI5Njg4IEwgMTIgMTAuNTg1OTM4IEwgNC43MDcwMzEyIDMuMjkyOTY4OCB6Ij48L3BhdGg+PC9zdmc+"
          />
        </TouchableOpacity>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: '28.3%',
            marginRight: '5%',
          }}
          onPress={() => Click('en')}>
          <GradientButton label="English" loading={isLoading.en} />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: '28.3%',
            marginRight: '5%',
          }}
          onPress={() => Click('kha')}>
          <GradientButton label="Khasi" loading={isLoading.kha} />
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default ViewNoteLanguageSelectModal;
