import SocialHistoryForm from './SocialHistoryForm';
import {EditSocialHistory} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Social History',
  actions: [cancelButton(), save()],
};

export default SocialHistoryForm({uri: EditSocialHistory, header});
