import React, { useCallback, useState } from 'react'
import { get } from 'lodash';
import { Snackbar, Text, TouchableOpacity, View } from '../../../app-components';
import { h14_Regular, h14_SemiBold, h16_Medium } from '../../../theme/fonts';
import { TextAreaInput } from '../../../app-components/input-components/Editors';
import { invoke } from '../../../AppServices';

const PrescriptionAndAdviceForm = (props) => {
    const {
        navigation = {},
        prescriptionAndAdvice = [],
        setPrescriptionAndAdvice,
        clinicalHistoryId,
    } = props;

    const { _id: patientId } = get(navigation, 'state.params.item.patient', {});
    const { advice = '', prescription = '' } = prescriptionAndAdvice[0] || [];

    const [
        prescriptionAndAdviceDefaultValue,
        setPrescriptionAndAdviceDefaultValue,
    ] = useState({
        prescription,
        advice,
    });

    const handleOnSubmit = useCallback(async () => {
        const id = 'updatePrescriptionAndAdvice';
        const paramValue = {
            patientId,
            prescriptionAndAdviceValue: prescriptionAndAdviceDefaultValue,
            clinicalHistoryId,
        };
        await invoke({ id, paramValue })
            .then((res) => {
                Snackbar.show({
                    text: 'Prescription and Advice save successfully',
                    duration: Snackbar.LENGTH_LONG,
                });
                setPrescriptionAndAdvice((prev) => {
                    const modifiedPrev = [...prev];
                    const result = {
                        ...prescriptionAndAdviceDefaultValue,
                        date: new Date(),
                    };
                    return [result, ...modifiedPrev];
                });
            })
            .catch((error) => {
                console.log('error: ', error);
                Snackbar.show({
                    text: 'Prescription and Advice do not save successfully',
                    duration: Snackbar.LENGTH_LONG,
                });
            });
    }, [patientId, prescriptionAndAdviceDefaultValue, setPrescriptionAndAdvice]);

    return (
        <>
            <View
                style={{
                    flexDirection: 'row',
                }}>
                <View style={{ width: '49%', marginRight: 10 }}>
                    <Text
                        style={{
                            ...h16_Medium,
                            color: 'black',
                            lineHeight: 19,
                            paddingBottom: 5,
                        }}>
                        Add Prescription
                    </Text>
                    <TextAreaInput
                        onChangeText={(text) =>
                            setPrescriptionAndAdviceDefaultValue((prev) => {
                                return {
                                    ...prev,
                                    prescription: text,
                                };
                            })
                        }
                        value={prescriptionAndAdviceDefaultValue?.prescription}
                        style={{
                            borderRadius: 5,
                            borderColor: 'rgba(0,0,0,0.4)',
                            ...h14_Regular,
                            borderWidth: 1,
                            paddingLeft: 5,
                            color: '#000',
                        }}
                    />
                </View>
                <View style={{ width: '50%' }}>
                    <Text
                        style={{
                            ...h16_Medium,
                            color: 'black',
                            lineHeight: 19,
                            paddingBottom: 5,
                        }}>
                        Add Advice
                    </Text>
                    <TextAreaInput
                        onChangeText={(text) =>
                            setPrescriptionAndAdviceDefaultValue((prev) => {
                                return {
                                    ...prev,
                                    advice: text,
                                };
                            })
                        }
                        value={prescriptionAndAdviceDefaultValue?.advice}
                        style={{
                            borderRadius: 5,
                            borderColor: 'rgba(0,0,0,0.4)',
                            ...h14_Regular,
                            borderWidth: 1,
                            paddingLeft: 5,
                            color: '#000',
                        }}
                    />
                </View>
            </View>
            <View
                style={{
                    alignSelf: 'flex-end',
                    marginTop: 10,
                }}>
                <TouchableOpacity
                    style={{
                        backgroundColor: '#0095C9',
                        borderRadius: 5,
                        alignItems: 'center',
                        height: 32,
                        justifyContent: 'center',
                        padding: 15,
                        cursor: 'pointer',
                    }}
                    onPress={handleOnSubmit}>
                    <Text
                        style={{
                            ...h14_SemiBold,
                            color: 'white',
                        }}>
                        Approve
                    </Text>
                </TouchableOpacity>
            </View>
        </>
    );
};

export default PrescriptionAndAdviceForm