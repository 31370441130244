import React from 'react';
import {View} from '../../npms/react-core-components';
import {
  ProgressIndicator as ProgressIndicatorHoc,
  ActivityIndicator as ActivityIndicatorHoc,
} from '../../npms/react-activity-indicator';
import {
  progressIndicatorStyle,
  activityIndicatorStyle,
} from '../../theme/progressIndicatorTheme';

export const ProgressIndicator = ProgressIndicatorHoc({
  View,
  theme: progressIndicatorStyle,
});
export const ActivityIndicator = props => {
  return <ActivityIndicatorHoc {...activityIndicatorStyle} {...props} />;
};
