import DrugForm from './DrugForm';
import {EditDrug} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
let header = {
  title: 'Edit Drug',
  actions: [cancelButton(), save()],
};

export default DrugForm({uri: EditDrug, header});
