import {Image, View, Text} from '../../react-core-components';
import React from 'react';
import PropsType from 'prop-types';

const resolveAvatarValue = value => {
  if (!value || typeof value !== 'string') {
    return '';
  }
  value = value.split(' ');
  let avatarValue = '';
  if (value.length) {
    avatarValue = value[0].substring(0, 1);
    if (value.length > 1) {
      avatarValue = avatarValue + value[1].substring(0, 1);
    }
  }
  return avatarValue.toUpperCase();
};

const Avatar = props => {
  let {
    icon,
    resolveIcon,
    value,
    defaultIcon,
    defaultText,
    size,
    variant = 'circular',
    iconStyle,
    textStyle,
    children,
    isActive,
    indicatorStyle,
    backgroundColor,
    borderColor,
    borderWidth,
    resizeMode = 'cover',
  } = props;
  if (icon) {
    if (typeof icon === 'function') {
      icon = icon(props);
    } else if (resolveIcon) {
      icon = resolveIcon(icon);
    }
  }
  if (typeof value === 'function') {
    value = value(props);
  }
  if (icon === undefined && value === undefined) {
    icon = defaultIcon;
    value = defaultText;
  }
  if (icon === undefined && value === undefined) {
    return null;
  }
  let style =
    (size && typeof size === 'string' && props[`${size}Style`]) ||
    props['style'];
  style = {
    ...style,
    alignItems: 'center',
    justifyContent: 'center',
  };
  if (size && typeof size === 'number') {
    style.width = size;
    style.height = size;
    style.borderRadius = size / 2;
  }
  if (variant === 'square') {
    delete style.borderRadius;
  }
  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }
  if (borderColor) {
    style.borderColor = borderColor;
  }
  if (borderWidth) {
    style.borderWidth = borderWidth;
  }
  let avatarComponent = (
    <View style={style}>
      {children ||
        (icon ? (
          <Image
            style={{
              width: style.width,
              height: style.height,
              borderRadius: style.borderRadius,
              objectFit: resizeMode,
              ...iconStyle,
            }}
            source={typeof icon === 'string' ? {uri: icon} : icon}
            resizeMode={resizeMode}
            onError={event => {
              if (event && event.target && event.target.style) {
                event.target.style.display = 'none';
              }
            }}
          />
        ) : (
          <Text style={textStyle}>{resolveAvatarValue(value)}</Text>
        ))}
      {isActive && indicatorStyle && <View style={{...props.indicatorStyle}} />}
    </View>
  );
  return avatarComponent;
};

export const AvatarGroup = props => {
  const childrenLength = props.children.length;
  console.log(props.style.width);
  return (
    <View style={{display: 'flex', flexDirection: 'row'}}>
      {props.children.map((ele, index) => {
        return (
          <View
            style={{
              position: 'relative',
              zIndex: childrenLength - index,
              right: (index * parseInt(props.style.width)) / 5,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'black',
              ...props.style,
            }}>
            <Avatar
              source={ele.props.source}
              style={{...props.style}}
              online={true}
              indicatorStyle={{...props.indicatorStyle}}>
              {ele.props.children}
            </Avatar>
          </View>
        );
      })}
    </View>
  );
};

AvatarGroup.prototype = {
  style: PropsType.object.isRequired, // style of avatar
  indicatorStyle: PropsType.object, //  indicator style
  children: PropsType.node, //  children of Avatar
};

Avatar.propsType = {
  style: PropsType.object.isRequired, // style of avatar
  indicatorStyle: PropsType.object, //  indicator style
  children: PropsType.node, //  children of Avatar
  source: PropsType.string, //  source of image of avatar
};

Avatar.defaultProps = {
  variant: 'circular',
  resizeMode: 'cover',
  backgroundColor: '#e8eeff',
  style: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  smStyle: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  mdStyle: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  lgStyle: {
    width: 70,
    height: 70,
    borderRadius: 35,
  },
};

export default Avatar;
