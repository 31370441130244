import React, {useReducer, createContext, useState} from 'react';
import {
  notificationCount,
  notificationData,
  getUser,
  markRead,
  post,
} from './AppServices';
import withGpsContext from './withGpsContext';
import {Snackbar} from './app-components';

const intialState = {};
const ClassRoomNotificationContext = createContext(intialState);
class NotificationContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationCount: 0,
      notificationData: [],
      markRead: this.markRead,
    };
  }

  callback = (data) => {
    let {notification, userId} = data;
    let {notificationData, notificationCount} = this.state;
    let newNotificationData = [notification, ...notificationData];
    let newNotificationcount = notificationCount + 1;
    this.setState({
      notificationData: newNotificationData,
      notificationCount: newNotificationcount,
    });
  };

  notificationSubscription = () => {
    let {_id, userType} = getUser() || {};
    this.userId = _id;
    let {gpsStore} = this.props;
    let {subscribeNotification} = gpsStore;
    if (subscribeNotification) {
      subscribeNotification({userId: _id, callback: this.callback});
    }
  };

  notificationUnSubscription = () => {
    let {gpsStore} = this.props;
    if (gpsStore && gpsStore.unsubscribeNotification && this.userId) {
      gpsStore.unsubscribeNotification({userId: this.userId});
    }
  };

  componentDidMount() {
    this.notificationSubscription();
    this.getDataCount();
    this.getData();
  }

  componentWillUnmount() {
    this.notificationUnSubscription();
  }
  markReadWithReadId = (readId) => {
    post({
      data: {_id: readId},
      updates: {isRead: true},
      model: 'App_Notification',
    })
      .then((res) => {
        if (this.state.notificationCount) {
          const {notificationCount: stateCount} = this.state;
          this.setState({notificationCount: stateCount - 1}, () => {
            this.getData();
          });
        }
      })
      .catch((err) => {
        // let message = (err && err.message) || '';
      });
  };
  markRead = (readId) => {
    if (getUser()) {
      try {
        if (readId) {
          this.markReadWithReadId(readId);
        } else {
          markRead()
            .then((res) => {
              if (this.state.notificationCount) {
              this.setState({notificationCount:0})
              }
            })
            .catch((err) => {
              let message = (err && err.message) || '';
              // throw new Error('Error in find Notification Data', message);
            });
        }
      } catch (err) {
        let message = (err && err.message) || '';
        Snackbar.show({
          text: `${message}`,
          duration: Snackbar.LENGTH_SHORT,
        });
      }
    }
  };

  getDataCount = () => {
    if (getUser()) {
      try {
        notificationCount()
          .then((res) => {
            let {aggregates: {_count} = {}} = res;
            if (_count) {
              this.setState({
                notificationCount: _count,
              });
            }
          })
          .catch((err) => {
            let message = (err && err.message) || '';
            // throw new Error('Error in find Notification Data count', message);
          });
      } catch (err) {
        let message = (err && err.message) || '';
        Snackbar.show({
          text: `${message}`,
          duration: Snackbar.LENGTH_SHORT,
        });
      }
    }
  };

  getData = () => {
    if (getUser()) {
      try {
        notificationData()
          .then((res) => {
            // console.log('####res', res);
            let {data = []} = res;
            if (data.length != 0) {
              this.setState({
                notificationData: data,
              });
            }
          })

          .catch((err) => {
            let message = (err && err.message) || '';
            // throw new Error('Error in find Notification Data', message);
          });
      } catch (err) {
        let message = (err && err.message) || '';
        Snackbar.show({
          text: `${message}`,
          duration: Snackbar.LENGTH_SHORT,
        });
      }
    }
  };

  render() {
    let {children} = this.props;
    return (
      <ClassRoomNotificationContext.Provider value={{...this.state}}>
        {children}
      </ClassRoomNotificationContext.Provider>
    );
  }
}

const NotificationContextProviderHoc = withGpsContext(
  NotificationContextProvider,
);
export {NotificationContextProviderHoc};

export default (Component) => (props) => (
  <ClassRoomNotificationContext.Consumer>
    {(value) => <Component classroomStore={value} {...props} />}
  </ClassRoomNotificationContext.Consumer>
);
