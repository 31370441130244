import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from '../../app-components';
import {h16_Medium} from '../../theme/fonts';
import {closeIcon} from '../../images';
import ChatView from '../PatientQueue/ChatView';
import {fetch, getData, getImageUrl, getUser} from '../../AppServices';
import {isEmpty} from 'lodash';
import {resolveDoctorName} from '../../app-components/DoctorUtility';
import {resolvePatientName} from '../../app-components/patientUtility';
import Timer from '../GenericComponent/Timer';
import {isTimerVisible} from '../RPM/rpmUtils';
const ChatHistory = (props) => {
  const [appointmentData, setAppointmentData] = useState({});
  const [channelId, setChannelId] = useState('');
  const {navigation} = props;
  const {state: {params: {item = {}}} = {}} = navigation || {};
  const {
    _id = null,
    isList = false,
    medicalAssistant = false,
    notificationClick = false,
  } = item;
  const getAppointmentData = async () => {
    if (!_id) {
      return void 0;
    }
    const result = await getData({
      id: 'appointmentLists',
      filter: {
        _id,
      },
      model: 'Appointments',
    });
    const {data = []} = result || {};
    if (Array.isArray(data) && data.length > 0) {
      setAppointmentData(data[0]);
    } else {
      console.log('error in getting data');
    }
  };

  const {medicalAssistant: userMedicalAssistant} = getUser();
  // const {doctor:doctorMe={}} =getUser();
  const getChannelId = async () => {
    try {
      let result = await fetch({
        uri: {
          props: {
            query: {
              id: 'careTeamLists',
              addOnFilter: {
                patients: {_id: item?._id || ''},
                medicalAssistant: {
                  member: {_id: {$in: [userMedicalAssistant?._id]}},
                },
                // physician:{
                //   member: {_id: {$in: [doctorMe?._id]}},
                // }
              },
            },
            model: 'CareTeam',
          },
        },
      });
      const {data = []} = result || {};
      if (Array.isArray(data) && data.length > 0) {
        const {channel = ''} = data[0];
        setChannelId(channel);
      } else {
        console.log('error in getting data,helloo', result);
      }
    } catch (err) {
      console.log(`>@>Puru ~ file: ChatHistory.js ~ line 69 ~ err`, err);
    }
  };

  const getPatientData = async () => {
    try {
      if (notificationClick) {
        let result = await fetch({
          uri: {
            props: {
              query: {
                id: 'patientLists',
                addOnFilter: {
                  _id: item?._id,
                },
              },
              model: 'Patients',
            },
          },
        });
        const {data = []} = result || {};
        if (Array.isArray(data) && data.length > 0) {
          setAppointmentData(data[0]);
        } else {
          console.log('error in getting data  patient list,', result);
        }
      }
    } catch (err) {
      console.log(`>@>Puru ~ file: ChatHistory.js ~ line 69 ~ err`, err);
    }
  };
  useEffect(() => {
    if (isList) {
      if (medicalAssistant) {
        getPatientData();
        getChannelId();
      }
      if (!notificationClick) {
        setAppointmentData(item);
      }
    } else {
      getAppointmentData();
    }
  }, [item]);

  const {
    doctor_id: doctor = {},
    patient_id: patientData = {},
  } = appointmentData;

  let imageUrl = medicalAssistant
    ? getImageUrl(appointmentData?.profile_picture?.file)
    : getImageUrl(doctor?.profile_picture?.file);

  return (
    <View style={{backgroundColor: '#f8fafb', flex: 1, overflow: 'hidden'}}>
      <View
        style={{
          backgroundColor: '#f5f7f9',
          flexDirection: 'row',
          alignItems: 'center',
          padding: 14,
          justifyContent: 'space-between',
        }}>
        <View
          style={{
            // backgroundColor: '#000000',
            flexDirection: 'row',
            alignItems: 'center',

            // padding: 14,
            // justifyContent: 'space-between',
          }}>
          <View>
            <Image
              source={imageUrl}
              style={{width: 43, height: 43, borderRadius: 30, marginRight: 10}}
            />
          </View>
          <Text style={{...h16_Medium}}>{`Chat with ${
            medicalAssistant
              ? resolvePatientName(appointmentData)
              : resolveDoctorName(doctor)
          }`}</Text>
        </View>
        <View
          style={{flexDirection: 'row', alignItems: 'center', minHeight: 42}}>
          {isTimerVisible() ? (
            <Timer patientId={item?._id} type="chat" />
          ) : (
            void 0
          )}
          <TouchableOpacity
            onPress={() => navigation.pop()}
            style={{paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}}>
            <Image source={closeIcon} style={{height: 20, width: 20}} />
          </TouchableOpacity>
        </View>
      </View>
      <ScrollView style={{flex: 1}}>
        {!medicalAssistant && (isEmpty(patientData) || isEmpty(doctor)) ? (
          <View style={{flex: 1}}>
            <ActivityIndicator size="small" />
          </View>
        ) : (
          <ChatView
            key={channelId}
            item={{
              patient_id: {_id: item?._id},
              doctor_id: {_id: doctor?._id},
              appointment_id: {_id: medicalAssistant ? undefined : _id},
              medicalAssistant_id: {_id: userMedicalAssistant?._id},
            }}
            channelId={channelId}
            medicalAssistant={medicalAssistant}
            {...props}
            // renderTextInput={false}
          />
        )}
      </ScrollView>
    </View>
  );
};

export default ChatHistory;
