import {EditRpmProgram} from '../../../Queries';
import {save} from '../../../app-components/action/SaveAction';
import {cancelButton} from '../../../app-components/action/CloseAction';
import RpmLibraryForm from './RpmLibraryForm';
import {getUser} from '../../../AppServices';

let header = {
  title: 'Edit Program',
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },

  actions: [
    cancelButton({label: ' Back '}),
    save({
      // visible: (props) => {
      //   let {userType} = getUser();
      //   return userType === 'SuperAdmin' ? true : false;
      // },
    }),
  ],
};

const superAdminEditable = (props) => {
  let {userType} = getUser();
  if (userType === 'SuperAdmin') {
    return true;
  }
  return false;
};
let columnsPerRow = 2;
let editable = false;
export default RpmLibraryForm({
  uri: EditRpmProgram,
  header,
  editable,
  superAdminEditable,
  columnsPerRow,
  closeView:2,
  openProgramDetails:true
});
