import { get } from 'lodash';
import React, { useCallback, useState } from 'react'
import { Image, ScrollView, Snackbar, Text, TouchableOpacity, View } from '../../../app-components';
import { TextAreaInput } from '../../../app-components/input-components/Editors';
import { invoke } from '../../../AppServices';
import { editIconSecond } from '../../../images';
import { h14_Medium, h14_Regular, h14_SemiBold, h16_Medium } from '../../../theme/fonts';

const ProvisionalDiagnosisAndClinicalNote = (props) => {
    const { navigation = {}, clinicalHistoryInfo = {}, setClinicalHistoryInfo = () => { } } = props;

    const { provisionalDiagnosis = '', clinicalNotes = '', clinicalHistoryId = '', labClinicalNotes = '' } = clinicalHistoryInfo
    const { _id: patientId } = get(navigation, 'state.params.item.patient', {});

    const [provisionalDiagnosisEdit, setProvisionalDiagnosisEdit] = useState(provisionalDiagnosis + '\n' || '');
    const [iseEditButtonEnable, setEditButtonEnable] = useState(false);

    const handleOnSaveProvisionalDiagnosis = useCallback(async () => {
        if (provisionalDiagnosisEdit) {
            const id = 'updateProvisionalDiagnosis';
            const paramValue = {
                patientId,
                clinicalHistoryId,
                provisionalDiagnosis: provisionalDiagnosisEdit,
            };
            await invoke({ id, paramValue })
                .then((res) => {
                    setEditButtonEnable(false);
                    Snackbar.show({
                        text: 'Provisional Diagnosis save successfully',
                        duration: Snackbar.LENGTH_LONG,
                    });
                    setClinicalHistoryInfo({
                        ...clinicalHistoryInfo,
                        provisionalDiagnosis: provisionalDiagnosisEdit
                    })
                })
                .catch((error) => {
                    Snackbar.show({
                        text: 'Provisional Diagnosis do not save successfully',
                        duration: Snackbar.LENGTH_LONG,
                    });
                    console.log('error: ', error);
                });
        }
    }, [patientId, provisionalDiagnosisEdit]);

    return (
        <>
            <View>
                <View
                    style={{
                        paddingBottom: 5,
                        flexDirection: 'row',
                    }}>
                    <View>
                        <Text
                            style={{
                                ...h16_Medium,
                                color: 'black',
                                lineHeight: 19,
                            }}>
                            Provisional Diagnosis
                        </Text>
                    </View>
                    {!iseEditButtonEnable ? (
                        <TouchableOpacity
                            style={{
                                marginLeft: 30,
                                paddingHorizontal: 5,
                                cursor: 'pointer',
                            }}
                            onPress={() => setEditButtonEnable(true)}>
                            <Image
                                source={editIconSecond}
                                style={{
                                    height: 20,
                                    objectFit: 'contain',
                                }}
                            />
                        </TouchableOpacity>
                    ) : null}
                </View>
                {iseEditButtonEnable ? (
                    <View style={{ width: '50%' }}>
                        <TextAreaInput
                            onChangeText={(text) => setProvisionalDiagnosisEdit(text)}
                            value={provisionalDiagnosisEdit}
                            style={{
                                borderRadius: 5,
                                borderColor: 'rgba(0,0,0,0.4)',
                                ...h14_Regular,
                                borderWidth: 1,
                                minHeight: 40,
                                color: '#000',
                            }}
                        />
                        <View
                            style={{
                                flexDirection: 'row',
                                alignSelf: 'flex-end',
                                marginTop: 10,
                            }}>
                            <TouchableOpacity
                                style={{
                                    backgroundColor: 'rgb(240, 240, 247)',
                                    borderRadius: 5,
                                    alignItems: 'center',
                                    height: 32,
                                    justifyContent: 'center',
                                    padding: 15,
                                    marginRight: 5,
                                    cursor: 'pointer',
                                }}
                                onPress={() => {
                                    setEditButtonEnable(false);
                                    setProvisionalDiagnosisEdit(provisionalDiagnosis + '\n');
                                }}>
                                <Text
                                    style={{
                                        ...h14_Medium,
                                        color: 'rgba(56, 56, 56, 0.7)',
                                    }}>
                                    Cancel
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    backgroundColor: '#0095C9',
                                    borderRadius: 5,
                                    alignItems: 'center',
                                    height: 32,
                                    justifyContent: 'center',
                                    padding: 15,
                                    cursor: 'pointer',
                                }}
                                onPress={handleOnSaveProvisionalDiagnosis}>
                                <Text
                                    style={{
                                        ...h14_SemiBold,
                                        color: 'white',
                                    }}>
                                    Save
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                ) : provisionalDiagnosisEdit ? (
                    provisionalDiagnosisEdit.split('\n').map((val, index) => (
                        val ? <View key={index} style={{ paddingLeft: 10, flexDirection: 'row' }}>
                            <Text style={{ color: 'black' }}>-</Text>
                            <Text
                                style={{
                                    ...h14_Regular,
                                    color: 'black',
                                    lineHeight: 16,
                                    whiteSpace: 'pre-line',
                                    paddingLeft: 5,
                                }}>
                                {val}
                            </Text>
                        </View> : null))
                ) : null}
            </View>
            <View
                style={{
                    paddingTop: 20,
                    flexDirection: 'row',
                }}>
                <View style={{ width: '60%' }}>
                    <Text
                        style={{
                            ...h16_Medium,
                            color: 'black',
                            lineHeight: 19,
                            paddingBottom: 5,
                        }}>
                        Clinical Notes
                    </Text>
                    {clinicalNotes ? clinicalNotes.split('\n').map((val, index) => {
                        return val ? (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    paddingLeft: 10,
                                    paddingBottom: 2,
                                }}
                                key={index}>
                                <Text style={{ color: 'black' }}>-</Text>
                                <Text
                                    style={{
                                        ...h14_Regular,
                                        color: 'black',
                                        paddingLeft: 5,
                                        lineHeight: 16,
                                    }}>
                                    {val}
                                </Text>
                            </View>
                        ) : null;
                    }) : null}
                </View>
                <View>
                    {labClinicalNotes ? (
                        <View>
                            <Text
                                style={{
                                    ...h16_Medium,
                                    color: 'black',
                                    lineHeight: 19,
                                    paddingBottom: 5,
                                }}>
                                Lab based Notes
                            </Text>
                            <ScrollView nestedScrollEnabled={true}>
                                {labClinicalNotes?.split('\n')?.map((val, index) => (
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            paddingLeft: 10,
                                            paddingBottom: 2,
                                        }}
                                        key={index}>
                                        <Text style={{ color: 'black' }}>-</Text>
                                        <Text
                                            style={{
                                                ...h14_Regular,
                                                color: 'black',
                                                paddingLeft: 5,
                                                lineHeight: 16,
                                            }}>
                                            {val}
                                        </Text>
                                    </View>
                                ))}
                            </ScrollView>
                        </View>
                    ) : null}
                </View>
            </View>
        </>
    );
};

export default ProvisionalDiagnosisAndClinicalNote