import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import countrywiseSpecialityFeeForm from './countrywiseSpecialityFeeForm';

let header = {
  title: 'Add Speciality',
  actions: [cancelButton(), save()],
};

export default countrywiseSpecialityFeeForm({header});
