import Toast from '../../npms/react-toast';
import React from 'react';
import {StandardButton, ButtonLoader} from '../buttons/Buttons';

export const formUpdateInvoke = ({
  id,
  label,
  showLoader,
  invoke,
  ...restProps
} = {}) => {
  label = label || 'Done';
  if (!id && typeof label === 'string') {
    id = label;
  }
  return {
    id: label,
    label,
    type: 'formUpdateInvoke',
    invoke,
    render: ({state} = {}) => {
      let {submitting} = state || {};
      if (typeof label === 'function') {
        label = label({data: state.data});
      }
      let loaderProps = showLoader
        ? {
            loaderComponent: <ButtonLoader />,
            loading: submitting,
          }
        : void 0;
      return <StandardButton label={label} {...loaderProps} {...restProps} />;
    },
  };
};

export const formUpdateInvokeAction = async (props = {}) => {
  let {
    action: {
      formUpdateEvent = 'formUpdates',
      invoke,
      preMessage,
      postMessage,
    } = {},
  } = props;
  if (!invoke) {
    return;
  }
  if (preMessage) {
    Toast.show({
      id: 12,
      title: preMessage,
      duration: 2000,
      type: 'success',
    });
  }
  try {
    let {eventDispatcher} = props;
    if (!eventDispatcher) {
      throw new Error(
        'EventDispatcher is mandatory while pressing multuUpdateForm action',
      );
    }
    let formUpdates = eventDispatcher.notify(formUpdateEvent);
    let result = await invoke({...props, formUpdates});
    if (postMessage) {
      Toast.show({
        id: 12,
        title: postMessage,
        duration: 2000,
        type: 'success',
      });
    }
    return result;
  } catch (err) {
    Toast.show({
      id: 12,
      title: err.message || 'Error in invoke',
      duration: 2000,
      type: 'error',
    });
    throw err;
  }
};
