import React from 'react';
import View from './View';
import StyleSheet from './StyleSheet';
import {isMobile} from '../../../app-components';

export default class ScrollView extends React.Component {
  constructor(props){
    super(props);
    if (!isMobile) {
      require('./scrollView.css');
    }
  }
  componentDidMount() {
    let {ref} = this.props;
    ref && ref(this);
  }

  scrollToEnd = () => {
    if (this.ref) {
      let {scrollWidth, clientWidth} = this.ref;
      let scrollTo = scrollWidth - clientWidth;
      this.ref.scrollLeft = scrollTo;
    }
  };

  onScroll = (e) => {
    let eventValues = e.target;
    let {onScroll} = this.props;
    onScroll && onScroll(eventValues);
  };

  render() {
    let {
      style,
      contentContainerStyle,
      keyboardShouldPersistTaps,
      horizontal,
      getRef,
      ...restProps
    } = this.props;
    if (Array.isArray(style)) {
      style = StyleSheet.flatten(style);
    }
    if (contentContainerStyle && Array.isArray(contentContainerStyle)) {
      contentContainerStyle = StyleSheet.flatten(contentContainerStyle);
    }
    style = {...style, ...contentContainerStyle, overflow: 'auto'};
    if (horizontal) {
      style['flexDirection'] = 'row';
    }
    return (
      <View
        getRef={(e) => {
          this.ref = e;
          getRef && getRef(e);
        }}
        {...restProps}
        style={style}
        onScroll={this.onScroll}
      />
    );
  }
}
