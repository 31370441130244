import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  Legend,
  CartesianAxis,
  Tooltip,
  ResponsiveContainer,
} from '../../app-components/recharts/src';
import {View, Text, isMobile, Dimensions} from '../../app-components';
import {h16_Medium} from '../../theme/fonts';
import { getCurrency } from '../../AppServices';
const {height, width} = Dimensions.get('window');
const LineSimple = (props) => {
  const {data = [],currencyType="USD"} = props;

  if (Array.isArray(data) && !data.length) {
    return (
      <View
        style={{
          width: '100%',
          minWidth: isMobile ? width * 0.75 : 500,
          height: 450,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text style={{...h16_Medium}}>NO DATA</Text>
      </View>
    );
  }

  const customYaxis = (tick) => {
    if (tick === 0) {
      return tick;
    }
    return tick + `${getCurrency(currencyType)}`;
  };

  return (
    <div
      style={{
        width: '100%',
        minWidth: isMobile ? width * 0.75 : 50,
        height: 450,
        position: 'relative',
      }}>
      <ResponsiveContainer>
        <LineChart
          width={isMobile ? width * 0.75 : 800}
          height={450}
          data={data}
          margin={{
            top: 5,
            right: 30,
            bottom: 5,
          }}>
          <XAxis dataKey="date" tickLine={false} />
          <YAxis
            width={isMobile ? 50 : 80}
            tickFormatter={customYaxis}
            // axisLine={false}
            tickSize={5}
            tickLine={false}
            tick={{stroke: 'none'}}
          />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          {/* <CartesianAxis vertical={false} /> */}
          <Tooltip
            cursor={false}
            wrapperStyle={{
              pointerEvents: 'auto',
              zIndex: 1,
            }}
            // contentStyle={{ }}
            // content={customToolTip}
          />
          {/* <Legend /> */}

          <Line
            type="monotone"
            dataKey={'cancelled'}
            strokeWidth={2}
            stroke="#ff0000"
            // stroke={lineColors[index]}
            activeDot={{r: 5}}
          />
          <Line
            type="monotone"
            dataKey={'balance'}
            strokeWidth={2}
            stroke="#82ca9d"
            activeDot={{r: 5}}
          />
          <Line
            type="monotone"
            dataKey={'total'}
            strokeWidth={2}
            stroke="#8884d8"
            activeDot={{r: 5}}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineSimple;
