import React from 'react';

import {save} from '../../../app-components/action/SaveAction';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import Form from '../../../app-components/form/Form';
import {submit} from '../../../AppServices';
import {TouchableOpacity} from '../../login/components';

const uri = () => {
  return {
    query: {
      id: 'remoteConfigurationDetails',
      sort: {_id: -1},
    },
    model: 'RemoteConfig',
  };
};

const RemoteConfiguration = (props) => {
  let header = {
    title: 'Remote Configuration',
    titleTextStyle: {
      color: 'rgba(56, 56, 56, 0.87)',
      fontSize: 20,
      marginLeft: 10,
      fontFamily: 'AvenirNext-Regular',
    },
    actions: [
      save(),
      {
        render: () => {
          return (
            <TouchableOpacity
              onPress={() =>
                props?.navigation?.push({
                  view: 'RPM',
                })
              }
              style={{cursor: 'pointer', height: 38}}>
              <GradientButton color="#0095c9" label={'Back'} />
            </TouchableOpacity>
          );
        },
      },
    ],
  };

  const ModifiedForm = Form({
    ...props,
    uri,
    reloadEvent: 'reloadPatient',
    reloadOnChangeEvent: 'reloadPatient',
    type: 'standardShadow',
    closeView: 1,
    fieldVariant: 'filled',
    validateOnChange: true,
    onSubmit: submit({
      model: 'RemoteConfig',
      type: 'moSignature',
      _id: 1,
    }),
    beforeSubmit: ({updates}) => {
      return {
        updates: {
          ...updates,
          type: 'moSignature',
        },
      };
    },
    submitMessage: 'Signature added successfully.',
    header: header,
    defaultValues: ({navigation}) => {
      const {
        state: {params: {item: {Signature, signatureName = ''}} = {}} = {},
      } = navigation || {};

      return {Signature, signatureName};
    },
    mandatory: {
      signatureName: 'Signature name is mandatory.',
      signature: 'Please upload your signature.',
    },
    formGroups: [
      {
        direction: 'row',
        fieldVariant: 'filled',
        editable: true,
        label: 'Add Signature',
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                type: 'file',
                editable: true,
                label: 'Upload Signature',
                field: 'signature',
                imageOnly: true,
              },
              {
                type: 'textArea',
                numberOfLines: 3,
                label: 'Signature',
                field: 'signatureName',
                editable: true,
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default RemoteConfiguration;
