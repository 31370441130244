import React from 'react';
import {isMobile} from '../index';
import AutosuggestInputFilterField from './AutosuggestInputFilterField';
import {
  AutosuggestFilterThemeSM,
  AutosuggestFilterThemeMD,
  AutosuggestFilterCurvedThemeMD,
} from '../../theme/autosuggestFilterTheme';

class AppAutosuggestFilter extends React.Component {
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onWidowResize);
  };
  onWidowResize = () => {
    this.setState({});
  };
  componentDidMount = () => {
    window.addEventListener('resize', this.onWidowResize);
  };
  setValue = ({field, value}) => {
    let {screenState: {dataParams} = {}, setScreenState, asParams, filterField, fieldType } = this.props;
    let {filters} = dataParams || {};
    filters = {
      ...filters,
      [field]: {
        value,
        filter: {
          asParams,
          filterType: 'autosuggest',
          value,
          filterField, fieldType
        },
      },
    };
    const {onFilterChange} = this.props;
    if (onFilterChange) {
      filters = onFilterChange({filters});
    }
    setScreenState({
      dataParams: {
        ...dataParams,
        filters,
      },
    });
  };
  render() {
    const {
      screenState: {dataParams: {filters} = {}} = {},
      icon = '',
      setValue,
      userTheme,
      varient = '',
      ...restProps
    } = this.props;
    let AutosuggestFilterTheme = isMobile
      ? AutosuggestFilterThemeSM
      : varient === 'curved'
      ? AutosuggestFilterCurvedThemeMD
      : AutosuggestFilterThemeMD;

    let filterTheme = userTheme || AutosuggestFilterTheme;
    let setFilterValue = setValue || this.setValue;
    return (
      <AutosuggestInputFilterField
        data={filters}
        setValue={setFilterValue}
        theme={filterTheme}
        {...restProps}
        selfIcon={icon}
      />
    );
  }
}

export default AppAutosuggestFilter;
