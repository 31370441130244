import Profile from './Profile';
import EditUserProfile from './EditUserProfile';
import EditDoctorProfile from './EditDoctorProfile';
import EditPatientProfile from './EditPatientProfile';
import ChangePassword from './ChangePassword';

export default {
  profile: {
    screen: Profile,
    // expanded:true
  },
  'edit-user-profile': {
    screen: EditUserProfile,
  },
  'edit-patient-profile': {
    screen: EditPatientProfile,
  },
  'edit-doctor-profile': {
    screen: EditDoctorProfile,
  },
  'change-password': {
    screen: ChangePassword,
  },
};
