import React from 'react';
import {TextInput, View, TouchableOpacity, Image} from '../../app-components';
import {debounce, isArray, set} from 'lodash';
import detectMob from '../../Lib/detectMobile';
import {searchIcon, backCrossIcon} from '../../images';
import {ftsThemeMD, ftsThemeSM} from '../../theme/ftsTheme';

const isMobile = detectMob();

const ftsTheme = isMobile ? ftsThemeSM : ftsThemeMD;
const {
  searchContainerStyle,
  inputStyle,
  searchImageStyle,
  crossImageStyle,
} = ftsTheme;

class Fts extends React.Component {
  constructor(props) {
    super(props);
    const {screenState: {dataParams = {}} = {}} = props;
    const {value = ''} = dataParams?.ftsFilter || {};

    this.state = {
      search: !(value && value.length),
      text: value || null,
    };

    this.debouncedFilter = debounce(this.applyFilter, 1500);
  }

  applyFilter = ({value}) => {
    let {
      action,
      screenState: {dataParams = {}} = {},
      setScreenState,
    } = this.props;
    let ftsFilter = null;
    if (value) {
      ftsFilter = {
        value,
      };
      if (action && action.exp) {
        if (isArray(action.exp)) {
          ftsFilter.regExpFilter = {$or: []};
          action.exp.map((field) => {
            const filter = set({}, field, {
              $regex: value.trim(),
              $options: 'i',
            });
            ftsFilter.regExpFilter.$or.push(filter);
          });
        } else {
          const filter = set({}, action.exp, {
            $regex: value.trim(),
            $options: 'i',
          });
          ftsFilter.regExpFilter = filter;
        }
      } else {
        ftsFilter.filter = {$text: {$search: `"${value}"`}};
      }
    }

    const {onFilterChange} = this.props;
    if (onFilterChange) {
      ftsFilter = onFilterChange({ftsFilter});
    }
    dataParams = {...dataParams, ftsFilter};
    setScreenState && setScreenState({dataParams});
    this.setState({search: !(value && value.length), text: value});
  };

  onChange = (e) => {
    const text = e.target.value;
    this.setState({text});
    this.debouncedFilter({value: text});
  };

  onSubmitEditing = () => {
    const {text} = this.state;
    this.debouncedFilter({value: text});
  };

  reset = () => {
    this.applyFilter({value: null});
  };

  componentDidUpdate(prevProps) {
    const {screenState: {dataParams = {}} = {}} = this.props;
    const {value} = (dataParams && dataParams.ftsFilter) || {};
    if (prevProps?.screenState?.dataParams?.ftsFilter?.value !== value) {
      this.setState({
        search: !(value && value.length),
        text: value || null,
      });

      this.debouncedFilter = debounce(this.applyFilter, 700);
    }
  }

  render() {
    const {text, search} = this.state;
    let {placeholder = 'Write here to search ', action} = this.props;
    if (action && action.placeholder) {
      placeholder = action.placeholder;
    }
    return (
      <View style={searchContainerStyle}>
        <TextInput
          style={inputStyle}
          value={text}
          placeholder={placeholder}
          autoCapitalize="none"
          onChange={this.onChange}
          onSubmitEditing={this.onSubmitEditing}
          className="placeholder-green"
        />
        {!text && <Image source={searchIcon} style={searchImageStyle} />}
        <TouchableOpacity style={{cursor: 'pointer'}} onPress={this.reset}>
          <Image source={!text ? '' : backCrossIcon} style={crossImageStyle} />
        </TouchableOpacity>
      </View>
    );
  }
}

export default Fts;
