import React from 'react';

import Table from '../../app-components/table/Table';

const uri = () => {
  return {
    query: {
      id: 'inferenceHistoryList',
      sort: { _id: -1 },
    },
    model: 'InferenceHistory',
  }
}
export default Table({
  uri,
  reloadEvent: 'reloadLabInference',
  reloadOnChangeEvent: 'reloadLabInference',
  action: ({ item }) => {
    return {
      type: 'link',
      link: {
        view: 'editInferences',
        params: {item},
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
            width: '35%',
          },
          style: {},
        },
      },
    };
  },
columns: [
    {
      header: 'Inference ID',
      type: 'text',
      field: 'inferenceId',
    },
    {
      header: 'Inference Name',
      type: 'text',
      field: 'inferenceName',
    },
  ],
})
