import {getRenderComponent} from './Utility';

const InputError = props => {
  let {error, renderInputError} = props;
  if (!error || !renderInputError) {
    return null;
  }
  return getRenderComponent(renderInputError, props);
};

export default InputError;
