import React from 'react';
import TabNavigator from '../../app-components/tab/TabNavigator';
import Speciality from './Speciality';
import Drugs from './Drugs';
import Setting from './settings';
import Allergies from './Allergies';
import SocialHistory from './SocialHistory';
import {countUri} from '../../Queries';
import {GradientButton} from '../../app-components/buttons/Buttons';
import SettingButon from './SettingButon';
import Diagnosis from './Diagnosis';
import MedicalHistory from './MedicalHistory';
import SurgicalHistory from './SurgicalHistory';
import CountrywiseSpecialityFee from './CountrywiseSpecialityFee';
import Advertisement from './Advertisement';
import Algorithm from './Algorithm';
import CareProgram from './CareProgram';
import Todo from './Todo';
import EducationContent from './EducationContent';
import EducationCategory from './EducationCategory';
import CCMList from './CCMList';
import TodoCategory from './TodoCategory';
import { educationContentFilter } from '../../app-components/filter/MayaMDFilters';

export default TabNavigator({
  tabs: {
    tab1: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Speciality'} />;
          },
          type: 'link',
          link: {
            view: 'add-speciality',
            expanded: true,
            height: 300,
          },
        },
      ],
      label: 'Speciality',
      reloadEvent: 'reloadSpeciality',
      screen: Speciality,
      countUri: countUri({
        model: 'Specialities',
        id: 'specialityLists',
      }),
    },
    tab15: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add To-Do Task'} />;
          },
          type: 'link',
          link: {
            view: 'add-todo',
            expanded: true,
            height: 300,
          },
        },
      ],
      label: 'To-Do Task',
      reloadEvent: 'reloadTodo',
      screen: Todo,
      countUri: countUri({
        model: 'Todo',
        id: 'todoList',
      }),
    },
    tab19: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add To-Do Task Category'} />;
          },
          type: 'link',
          link: {
            view: 'add-todo-category',
            expanded: true,
            height: 300,
          },
        },
      ],
      label: 'To-Do Task Category',
      reloadEvent: 'reloadTodoCategory',
      screen: TodoCategory,
      countUri: countUri({
        model: 'TodoCategory',
        id: 'todoCategoryList',
      }),
    },
    tab18: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add CCM Checklist'} />;
          },
          type: 'link',
          link: {
            view: 'add-ccm',
            expanded: true,
            height: 300,
          },
        },
      ],
      label: 'CCM Checklist',
      reloadEvent: 'reloadCCM',
      screen: CCMList,
      countUri: countUri({
        model: 'CcmCheckList',
        id: 'ccmCheckLists',
      }),
    },
    // tab16: {
    //   actions: [
    //     {
    //       render: () => {
    //         return <GradientButton label={'Add Education Content'} />;
    //       },
    //       type: 'link',
    //       link: {
    //         view: 'add-education-content',
    //         expanded: true,
    //         height: 300,
    //       },
    //     },
    //     educationContentFilter
    //   ],
    //   label: 'Education Content',
    //   reloadEvent: 'reloadEducationContent',
    //   screen: EducationContent,
    //   countUri: countUri({
    //     model: 'EducationContents',
    //     id: 'EducationContentList',
    //   }),
    // },
    // tab17: {
    //   actions: [
    //     {
    //       render: () => {
    //         return <GradientButton label={'Add Education Category'} />;
    //       },
    //       type: 'link',
    //       link: {
    //         view: 'add-education-category',
    //       },
    //     },
    //   ],
    //   label: 'Education Category',
    //   reloadEvent: 'reloadEducationCategory',
    //   screen: EducationCategory,
    //   countUri: countUri({
    //     model: 'EducationCategory',
    //     id: 'EducationCategoryList',
    //   }),
    // },
    // tab12: {
    //   actions: [
    //     {
    //       render: () => {
    //         return <GradientButton label={'Add Advertisement'} />;
    //       },
    //       type: 'link',
    //       link: {
    //         view: 'add-advertisement',
    //         expanded: true,
    //         height: 300,
    //       },
    //     },
    //   ],
    //   label: 'Advertisement',
    //   reloadEvent: 'reloadAdvertisement',
    //   screen: Advertisement,
    //   countUri: countUri({
    //     model: 'OrganizationAdvertisement',
    //     id: 'organizationAdList',
    //   }),
    // },
    tab13: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Algorithm'} />;
          },
          type: 'link',
          link: {
            view: 'add-algorithm',
            expanded: true,
            height: 300,
          },
        },
      ],
      label: 'Algorithms',
      reloadEvent: 'reloadAlgorithms',
      screen: Algorithm,
      countUri: countUri({
        model: 'AlgorithmCategories',
        id: 'AlgorithmCategoryList',
      }),
    },
    tab14: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Care Program Speciality'} />;
          },
          type: 'link',
          link: {
            view: 'add-care-program',
            expanded: true,
            height: 300,
          },
        },
      ],
      label: 'Care Program Speciality',
      reloadEvent: 'reloadCareProgram',
      screen: CareProgram,
      countUri: countUri({
        model: 'CareProgramSpeciality',
        id: 'careProgramSpecialityList',
      }),
    },
    // tab11: {
    //   actions: [
    //     {
    //       render: () => {
    //         return <GradientButton label={'Add Speciality Fee Countrywise'} />;
    //       },
    //       type: 'link',
    //       link: {
    //         view: 'add-countrywise-speciality-fee',
    //         expanded: true,
    //         height: 300,
    //       },
    //     },
    //   ],
    //   label: 'Countrywise Speciality Fee',
    //   reloadEvent: 'reloadSpecialityFee',
    //   screen: CountrywiseSpecialityFee,
    //   countUri: countUri({
    //     model: 'specialityFee',
    //     id: 'feeQuery',
    //   }),
    // },
    // tab2: {
    //   actions: [
    //     {
    //       render: () => {
    //         return <GradientButton label={'Add Procedure'} />;
    //       },
    //       type: 'link',
    //       link: {
    //         view: 'add-procedure',
    //       },
    //     },
    //   ],
    //   reloadEvent: 'reloadProcedure',
    //   countUri: countUri({
    //     model: 'Procedures',
    //     id: 'procedureLists',
    //   }),
    //   label: 'Procedure',
    //   screen: Procedure,
    // },

    tab3: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Medical History'} />;
          },
          type: 'link',
          link: {
            view: 'add-medical-history',
          },
        },
      ],
      reloadEvent: 'reloadMedicalHistory',
      countUri: countUri({
        model: 'MedicalHistories',
        id: 'medicalHistoryLists',
      }),
      label: 'Medical History',
      screen: MedicalHistory,
    },

    tab5: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Surgical History'} />;
          },
          type: 'link',
          link: {
            view: 'add-surgical-history',
          },
        },
      ],
      reloadEvent: 'reloadSurgicalHistory',
      countUri: countUri({
        model: 'SurgicalHistories',
        id: 'surgicalHistoryLists',
      }),
      label: 'Surgical History',
      screen: SurgicalHistory,
    },

    tab4: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Drug'} />;
          },

          type: 'link',
          link: {
            view: 'add-drug',
          },
        },
      ],
      label: 'Drugs',
      reloadEvent: 'reloadDrug',
      screen: Drugs,
      countUri: countUri({
        model: 'Drugs',
        id: 'drugLists',
      }),
    },
    tab7: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Allergies'} />;
          },
          type: 'link',
          link: {
            view: 'add-allergy',
          },
        },
      ],
      label: 'Allergies',
      reloadEvent: 'reloadAllergies',
      screen: Allergies,
      countUri: countUri({
        model: 'Allergies',
        id: 'allergyLists',
      }),
    },
    tab10: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Social History'} />;
          },
          type: 'link',
          link: {
            view: 'add-social-history',
          },
        },
      ],
      label: 'Social History',
      reloadEvent: 'reloadSocial',
      screen: SocialHistory,
      countUri: countUri({
        model: 'SocialHistories',
        id: 'socialHistoryLists',
      }),
    },
    // tab7: {
    //   actions: [
    //     {
    //       render: () => {
    //         return <GradientButton label={'Add Dosage'} />;
    //       },
    //       type: 'link',
    //       link: {
    //         view: 'add-dosage',
    //       },
    //     },
    //   ],
    //   label: 'Dosage',
    //   reloadEvent: 'reloadDosage',
    //   screen: Dosage,
    //   countUri: countUri({
    //     model: 'Dosages',
    //     id: 'dosageLists',
    //   }),
    // },

    tab8: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Add Diagnosis'} />;
          },
          type: 'link',
          link: {
            view: 'add-diagnosis',
          },
        },
      ],
      label: 'Diagnosis',
      reloadEvent: 'reloadDiagnosis',
      screen: Diagnosis,
      countUri: countUri({
        model: 'Diagnosis',
        id: 'diagnoseLists',
      }),
    },

    // tab6: {
    //   actions: [
    //     {
    //       render: (props) => {
    //         return (
    //           <SettingButon
    //             {...props}
    //             model="Settings"
    //             id="settingLists"
    //             custom={<GradientButton label={'Add Setting'} />}
    //           />
    //         );
    //       },
    //       type: 'link',
    //       link: {
    //         view: 'add-setting',
    //       },
    //     },
    //   ],
    //   label: 'Setting',
    //   reloadEvent: 'reloadSetting',
    //   screen: Setting,
    //   countUri: countUri({
    //     model: 'Settings',
    //     id: 'settingLists',
    //   }),
    // },
  },
});
