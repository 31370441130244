import React from 'react';
import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
} from './app-components';
import {redCloseIcon} from './images';
import {getSelectedLoginType, getUser} from './AppServices';
import {h15_Regular} from './theme/fonts';
const TnC = () => {
  let TNCArray = [
    `I give my consent for remote consultation, i.e. telephonic
  oronline consultation through WhatsApp, SMS, e-Mail or othermeans,
  video consultation to consultants of MayaMD`,
    `I state, that I am not talking to the doctor from outside
  thecountry and the details given by in the form above are accurate`,
    `I understand that there may be technological
  problem/challenges during consultation like poor quality of voice,
  deviation in colour of images or lack of understanding on both sides
  because bothdoctor and patient are not face to face.`,
    `I understand that there is limitation of online-consultation and
  itcannot act as a substitute for physical and clinical examination
  ofpatient by the doctor. Hence, wherever so will be required or
  advised, I hereby undertake that I will get myself
  clinically examined with concern licensed medical practitioner
  beforeplacing the complete reliance on this online consultation and
  willmake required follow-up visits to the clinic/centre.`,
    `I understand that the remote consultation will be
  recorded concurrently on case notes and I am liable to make payment
  forthe same.`,
    ` I permit consultants of MayaMD to record the conversation aswell
  as images during the consultation process and I understand that
  recording may be used as part of medical record.`,
    `I understand that the telephonic consultation will be based onthe
  information furnished by me as a patient/ attendant/ relative.I also
  understand that if any history is not revealed at that timeof
  consultation, diagnosis made and treatment given by thedoctor may
  vary accordingly. I undertake to provide such missing/ forgotten
  information to the doctor as early as possible so that necessary
  corrections in the treatment can be made`,
    `I undertake to get investigations as advised by the doctor
  during the consultation process and bear the responsibility to
  correctly and fully inform the doctor as early as possible.`,
    ` I understand that the record will be kept confidential
  by consultants of MayaMD.`,
    `I am aware that consultant of MayaMD has offered me hisservices
  from remote location at my request only.`,
    `I will not hold doctor negligent, if there is any error
  injudgment on the part of the doctor due to remote consultation`,
    `I am giving above consent without any pressure or force,and I am
not under influence of any drugs or alcohol while I am agreeing to
this consent`,
    `I hereby also confirm to the privacy policy of MayaMD.`,
    `Patient will make the payment via Debit/Credit cards or byany
other real-time online payment mode as stated in
the website of MayaMD.I appreciate the risk that consultant of MayaMD
is taking to helpme to reduce my suffering.`,
  ];

  const TNCDATA = [
    `Please read these terms carefully before using this Site.
    These terms may be changed from time to time and without further
notice.Your continued use of the Site after any such changes
constitutes youracceptance of the new terms. If you do not agree to
abide by these or anyfuture terms, please do not use the Site or
download content from it.`,
    `All services and products stated on MayaMD are subject to
availability.`,
    `MayaMD may terminate, change, suspend or discontinue any aspect of
theSite, including the availability of any consultation, at any time.
MayaMD mayremove, modify or otherwise change any content, including
that of thirdparties, on or from this Site. MayaMD may impose limits
on certain featuresand services or restrict your access to parts or
the entire Site without notice or liability. MayaMD may terminate your
use of the Site at any time in its sole discretion.`,
    `These terms apply exclusively to your access to and use of the Site
and donot alter the terms or conditions of any other agreement you may
have with MayaMD or its parents, subsidiaries or affiliates.`,
  ];
  return (
    <View
      style={{
        padding: '2em',
        paddingTop: 0,
        fontFamily: 'AvenirNext-DemiBold',
      }}>
      <Text style={{textAlign: 'center', fontSize: '3em', marginBottom: 25}}>
        Terms and Conditions
      </Text>
      <View>
        {TNCDATA.map((item) => {
          return (
            <>
              <Text
                style={{
                  fontFamily: 'AvenirNext-Regular',
                  letterSpacing: '0.05rem',
                  wordWrap: 'break-word',
                  fontSize: '1.2em',
                }}>
                {item}
              </Text>
              <View style={{height: 40}} />
            </>
          );
        })}
      </View>
      <Text
        style={{
          textAlign: 'center',
          fontSize: '2.5em',
          fontFamily: 'AvenirNext-DemiBold',
          marginBottom: 25,
        }}>
        Refund Policy
      </Text>
      <Text
        style={{
          fontFamily: 'AvenirNext-Regular',
          letterSpacing: '0.05rem',
          wordWrap: 'break-word',
          fontSize: '1.2em',
        }}>
        We will initiate the refund as soon the appointment is cancelled by you.
        If you are not available for consultation at the time of appointment or
        not intimating the clinic 6 hours prior to the appointment, you are not
        eligible for refund. Refund processing will take 3-7 working days and
        you can expect the refund in your account within this time interval.
        <View style={{height: 30}} />
        To cancel/reschedule the appointment, call +918921110739.
        <View style={{height: 30}} />
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: '3em',
          fontFamily: 'AvenirNext-DemiBold',
          paddingBottom: 10,
        }}>
        Consent
      </Text>
      <View style={{paddingLeft: 10}}>
        {TNCArray.map((item, index) => (
          <>
            <Text style={{...h15_Regular}}>
              {' '}
              {index + 1}. {item}
            </Text>{' '}
            <View style={{height: 30}} />
          </>
        ))}
      </View>
    </View>
  );
};

const DoctorTnC = () => {
  return (
    <>
      <View
        style={{
          padding: '2em',
          fontFamily: 'AvenirNext-DemiBold',
          //   overflow: 'scroll',
        }}>
        {/* <p style={{textAlign: 'center', fontSize: '3em'}}>
          Terms and Conditions
        </p>{' '} */}
        {/* <p
          style={{
            fontFamily: 'AvenirNext-Regular',
            letterSpacing: '0.05rem',
            wordWrap: 'break-word',
            fontSize: '1em',
          }}> */}
        <p style={{textAlign: 'center', fontSize: '3em'}}>
          {' '}
          TERMS AND CONDITIONS
        </p>
        <p
          style={{
            fontFamily: 'AvenirNext-Regular',
            letterSpacing: '0.05rem',
            wordWrap: 'break-word',
            fontSize: '1.2em',
          }}>
          {' '}
          <br />
          <br />
          1. The Agreement is being entered into on a Principal to Principal
          basis. The Doctor is an independent doctor and not an agent and/or
          employee of the Aggregator. The Doctor agrees that it will not
          represent that he is an agent of the Aggregator nor hold
          himself/herself out as such. The Doctor shall not enter into any
          agreement or arrangement which will bind Aggregator legally or
          otherwise.
          <br />
          <br />
          2. The Doctor hereby grants the Aggregator the right to list Doctor on
          the MayaMD platform.&#160;
          <br />
          <br /> 3. The Doctor shall use the MayaMD platform in accordance with
          the Aggregator&rsquo;s terms of use and shall ensure that it always
          uses an updated version of the MayaMD platform. The Doctor shall use
          an Internet connection based on a bandwidth of minimum&#160;4
          mbps&#160;as agreed with the Aggregator.&#160;
          <br />
          <br />
          4. The Doctor hereby agrees and covenants that it shall use the MayaMD
          Platform, at all times during the term of this Agreement, for
          providing the healthcare services to the Patients, whether it is to
          provide necessary responses to the Patients, render advice or conduct
          any diagnosis, on the MayaMD platform, or store the records of the
          Patient on the MayaMD platform.&#160;
          <br />
          <br /> 5. The Aggregator shall intimate the Doctor of an appointment
          booking by an agreed mode and the Doctor shall have the option of
          rescheduling or cancelling the appointment at that time.
          <br />
          <br /> 6. The Doctor shall provide the Patients with appropriate
          treatment as specifically required for the care of patients for their
          medical ailments. The Doctor shall ensure that they conduct their own
          independent history and assessment of the Patients and provide such
          treatment to the Patient, as it deems fit, in its sole discretion. The
          Doctor shall treat the Patient in the same manner as they treat other
          patients who directly visit the Doctor. The Doctor shall at its sole
          responsibility ensure that consultation provided is of the highest
          standard and care.&#160;
          <br />
          <br />
          7. Not withstanding anything elsewhere; the Doctor understands that it
          alone stands responsible for its infrastructure and facilities and
          that Aggregator shall not bear or have any responsibility or liability
          with respect to the same.
          <br />
          <br />
          8. Doctor hereby agrees that the Aggregator does not have any control
          over the Patient and is not responsible for the order placed by the
          Patient on the MayaMD platform. Doctor also acknowledges and agrees
          that the Aggregator is not responsible for verifying the authenticity
          of the prescription.
          <br />
          <br />
          9. Doctor shall at its sole cost and expense, obtain and maintain all
          permits and licenses necessary in connection with its performance of
          its obligations under this Agreement and shall comply with all
          relevant governmental rules and regulations.
          <br />
          <br /> 10. Doctor hereby agrees to comply at all times with the
          Aggregator&rsquo;s terms of use, privacy policy, applicable rules and
          regulations regarding safety, security, use, and conduct, of the
          MayaMD Platform.
          <br />
          <br /> 11. Doctor hereby agrees and undertakes to ensure that s/he
          shall be available to receive training from the Aggregator on the
          MayaMD Platform and shall duly act and perform his/her job to his best
          efforts and in such a manner as agreed with the Aggregator and as set
          out in this Agreement and specific data, plans, memoranda,
          instructional manuals and/or guidelines framed by the Aggregator and
          shared with the Doctor.&#160; <br />
          <br />
          12. Doctor agrees and acknowledges that by acting in pursuance to this
          Agreement, the characteristics of a doctor-patient relationship
          between it and the Patients does not get impacted in any manner. As
          such, the terms of the Indian Medical Council&rsquo;s Professional
          Conduct, Etiquette and Ethics Regulations, 2002
          (&ldquo;MCI&#160;Regulations&rdquo;) or (any substitute thereof or
          similar relevant regulations) will apply to the Doctor in their
          interactions with the Patients, that are undertaken pursuant to this
          Agreement. MCI Regulations, if and as applicable shall also apply to
          the staff of the Aggregator involved in the MayaMD platform for the
          purposes of this agreement.&#160; <br />
          <br />
          13. Doctor hereby further agrees that in no event and under no
          circumstances shall the Aggregator be held responsible and liable,
          whether together with the Doctor or independently, with respect to any
          services rendered by Doctor to the Patients. It is hereby clarified
          that the Aggregator shall not be liable for any claims or damages in
          respect of the medical services, treatment and care rendered by the
          Doctor to the Patients under this Agreement, except if same is due to
          the negligence of MayaMD staff pursuant to this agreement or due to
          any bug, technical error, malfunction or failures in the MayaMD
          Platform.&#160;
          <br />
          <br />
          14. The Doctor agrees to provide all the required details to be
          registered as a doctor on MayaMD Platform. These details shall
          include:&#160; <br />
          <br />{' '}
          <p style={{fontSize: '1em', padding: '8px'}}>
            {' '}
            1. Unique Indian Medical Association number / Registration number
            <br />
            <br /> 2. Degrees and certificates&#160;
            <br />
            <br /> 3. Current affiliations with clinics or hospitals
            <br />
            <br />
            4. Contact and correspondence information including contact number
            and email address&#160;
            <br />
            <br /> 5. Copy of digital signature
            <br />
            <br /> 6. High resolution photograph
            <br />
            <br />
            7. Time slot for each day of the week for Patient Consultation via
            video call on MayaMD Platform
          </p>
          <br />
          <br />
          15. The Doctor agrees not to divulge your MayaMD Platform credentials
          to anyone, and that no one but&#160;yourself will be operating this
          account.&#160;
          <br />
          <br /> 16. You understand that you will be provided patients&rsquo;
          sensitive information for consultation purposes. You agree not utilize
          this sensitive patient information for any other purpose except
          consultation.
          <br />
          <br /> 17. You agree to not send spam or promotional messages to any
          Patients for your services via messaging on the MayaMD Platform or
          over the emails. In the event any Patient flags you for sending spam
          or promotional messages, your account will be suspended, and your case
          will be subjected to internal investigation.
          <br />
          <br /> 18. All information shared by the Doctor for your profile, or
          information you share with the Patient on the MayaMD Platform will be
          governed by Aggregators Terms of Use and Privacy Policy.
          <br />
          <br />
          19. The Parties further agree that if any responsibility not
          specifically described in this Agreement is found to be an inherent,
          necessary or customary part of the Services and/or required for proper
          performance or provision of the Services; Services to be delivered by
          the Doctor.
        </p>
      </View>
    </>
  );
};
const Terms = ({navigation}) => {
  const {userType = ''} = getUser() || {};
  const getView = () => {
    if (userType) {
      if (userType === 'Patient') {
        return true;
      } else {
       return false;
      }
    }
    else{
      if(getSelectedLoginType() === 'Patient' ||
      !getSelectedLoginType()){
        return true;
      }
      else{
        return false;
      }
    }
  };
  return (
    <>
      <View
        style={{
          alignItems: 'flex-end',
          padding: 13,
        }}>
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => navigation.pop()}>
          <Image source={redCloseIcon} style={{height: 25, width: 25}} />
        </TouchableOpacity>
      </View>
      <ScrollView style={{flex: 1}}>
        {getView()? (
          <TnC />
        ) : (
          <DoctorTnC />
        )}
      </ScrollView>
    </>
  );
};

export default Terms;
