import React from 'react';
import {ScrollView, Text, View} from '../index';
import {NestedField} from '../../npms/react-form';
import {NestedForm} from '../form/Form';
import {NestedFieldHeaderDefault} from './NestedListHeader';
import uuid from 'uuid/v4';
import {h12_Regular} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import {NestedFieldFooterDefault} from './NestedListFooter';
let {labelHeaderTextColor} = colors;

const getNewId = () => {
  return `new_${uuid()}`;
};
let count = 0;

class CustomNestedFieldTable extends React.Component {
  renderHeader = (props) => {
    const {header, defaultAddRow} = this.props;
    if (!header) {
      return null;
    }

    return <NestedFieldHeaderDefault {...props} {...header} />;
  };
  renderFooter = (props) => {
    const {footer} = this.props;
    if (!footer) {
      return null;
    }
    return <NestedFieldFooterDefault {...props} {...footer} />;
  };
  resolveError = ({item, error}) => {
    return error && error[item._id];
  };
  renderItem = (params, props) => {
    if (this.props.renderItem) {
      this.props.renderItem(params, props);
      return;
    }
    let {item, index} = params;
    let {navigation, eventDispatcher, setValue, _getState, removeRow} = props;
    let {formGroups, error} = this.props;
    let resolvedError = this.resolveError({error, item});
    return (
      <NestedForm
        error={resolvedError}
        removeRow={(params) => {
          removeRow({item, index});
        }}
        index={index}
        formGroups={formGroups}
        state={_getState({item, index})}
        setValue={setValue}
        navigation={navigation}
        eventDispatcher={eventDispatcher}
      />
    );
  };
  renderLabelHeader = () => {
    const {labelHeader} = this.props;

    let labelHeaderComponent = void 0;
    if (labelHeader) {
      labelHeaderComponent = labelHeader.map((item) => {
        let labelHeaderStyle = {};
        if (item.width) {
          labelHeaderStyle = {...labelHeaderStyle, width: item.width};
        } else {
          labelHeaderStyle = {...labelHeaderStyle, flex: 1};
        }
        return (
          <View style={labelHeaderStyle}>
            <Text style={{...h12_Regular, color: labelHeaderTextColor}}>
              {item.label}
            </Text>
          </View>
        );
      });
    }
    return labelHeaderComponent;
  };

  renderBody = (params, props) => {
    const {renderBody, defaultAddRow} = this.props;
    let {addRow} = props;
    if (renderBody) {
      renderBody(params, props);
      return;
    }
    if (defaultAddRow && count < 1) {
      count += 1;
      addRow({_id: getNewId()});
    }

    let {value} = params;
    return (
      <>
        {value && value.length ? (
          <ScrollView
            style={{
              paddingTop: 6,
              paddingBottom: 6,
              // maxHeight: 400,
            }}>
            <View style={{flexDirection: 'row', paddingLeft: 6}}>
              {this.renderLabelHeader()}
            </View>

            {value.map((item, index) => {
              return (
                <View key={item._id}>
                  {this.renderItem({item, index}, props)}
                </View>
              );
            })}
          </ScrollView>
        ) : null}
        {this.renderFooter(props)}
      </>
    );
  };

  render() {
    return (
      <NestedField
        {...this.props}
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default CustomNestedFieldTable;
