import {cancelButton} from '../../../../app-components/action/CloseAction';
import {save} from '../../../../app-components/action/SaveAction';
import { defaultValueWhileEdit } from '../../../../app-components/DoctorUtility';
import Form from '../../../../app-components/form/Form';
import {submit, getUser} from '../../../../AppServices';

const updateNurseForm = ({updates}) => {
  const {mobile = '', email = '', first_name = '', last_name = ''} = updates;
  let telecom = [];
  if (mobile) {
    telecom.push({
      system: 'phone',
      value: mobile,
    });
  }
  if (email) {
    telecom.push({
      system: 'email',
      value: email,
    });
  }
  return {
    updates: {
      ...updates,
      telecom,
      name: [{family: last_name, given: [first_name]}],
    },
  };
};

export default ({header}) => {
  return Form({
    type: 'standardShadow',
    beforeSubmit: updateNurseForm,
    fieldContainer: 'topLabel',
    closeView: 1,
    onSubmit: submit({
      model: 'Nurse',
      lowerCaseFields: ['email'],
    }),
    reloadEvent: 'reloadNurse',
    mandatory: {first_name: 1, email: 1, mobile: 1},
    defaultValues: (props) => {
      let {
        navigation: {
          state: {params: {item: {_id} = {},items={}} = {}},
        },
      }=props
      if (!_id) {
        const {organization = {}} = getUser();
        _id = organization?._id;
      }
      const data=defaultValueWhileEdit({items})
      return {
        userType: 'Nurse',
        organization: {_id},
        isActive: true,
        ...data
      };
    },
    lg: {
      header,
      formGroups: [
        {
          fieldVariant: 'filled',
          direction: 'row',
          groups: [
            {
              columnsPerRow: 2,
              columns: [
                {
                  type: 'text',
                  field: 'first_name',
                  label: 'First Name',
                  placeholder: 'First name',
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'last_name',
                  label: 'Last name',
                  placeholder: 'Last name',
                  // mandatory: true,
                },
                {
                  type: 'text',
                  field: 'email',
                  placeholder: 'Email',
                  label: 'Email',
                  mandatory: true,
                },
                {
                  label: 'Mobile',
                  type: 'mobile',
                  field: 'mobile',
                  placeholder: 'Mobile',
                  mandatory: true,
                },
                {
                  type: 'chipsToggle',
                  field: 'gender',
                  label: 'Gender',
                  options: [
                    {value: 'male', label: 'Male'},
                    {value: 'female', label: 'Female'},
                    {value: 'other', label: 'Other'},
                  ],
                },
                {
                  type: 'checkBox',
                  field: 'isActive',
                  // placeholder: PLACEHOLDER,
                  label: 'Active',
                  // mandatory: true,
                },
              ],
            },
            {
              width: 250,
              columns: [
                {
                  type: 'uploadImage',
                  field: 'profile_picture',
                  description:
                    '*File should be image (Jpeg or png). Profile image should be square in shape.',
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          fieldVariant: 'filled',
          direction: 'row',

          columnsPerRow: 1,
          columns: [
            {
              type: 'uploadImage',
              field: 'profile_picture',
              description:
                '*File should be image (Jpeg or png). Profile image should be square in shape.',
            },
            {
              type: 'text',
              field: 'first_name',
              label: 'First Name',
              placeholder: 'First name',
              mandatory: true,
            },
            {
              type: 'text',
              field: 'last_name',
              label: 'Last name',
              placeholder: 'Last name',
              // mandatory: true,
            },
            {
              type: 'text',
              field: 'email',
              placeholder: 'Email',
              label: 'Email',
              mandatory: true,
            },
            {
              label: 'Mobile',
              type: 'mobile',
              field: 'mobile',
              placeholder: 'Mobile',
              mandatory: true,
            },
            {
              type: 'chipsToggle',
              field: 'gender',
              label: 'Gender',
              options: [
                {value: 'male', label: 'Male'},
                {value: 'female', label: 'Female'},
                {value: 'other', label: 'Other'},
              ],
            },
          ],
        },
      ],
    },
  });
};
