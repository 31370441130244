import React from 'react';
import Form from '../../app-components/form/Form';

import {cancelButton} from '../../app-components/action/CloseAction';
import {submit} from '../../AppServices';
import {save} from '../../app-components/action/SaveAction';

const AddLabCategory = (props) => {
  const ModifiedForm = Form({
    ...props,
    closeView: 1,
    reloadEvent: 'reloadLabCategories',
    reloadOnChangeEvent: 'reloadLabCategories',
    footer: {
      containerStyle: {
        margin: 0,
        borderTopWidth: 0,
      },
      actions: [cancelButton(), save({label: 'Save'})],
    },
    onSubmit: submit({
      model: 'MayaLabCategory',
      _id: 1,
    }),
    mandatory: {
        labCategoryName: 'Lab Category Name is mandatory.',
    },
    header: {
      title: 'Add Lab Category',
      titleTextStyle: {
        color: 'rgba(56, 56, 56, 0.87)',
        fontSize: 20,
        marginLeft: 10,
        fontFamily: 'AvenirNext-Regular',
      },
    },
    formGroups: [
      {
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 1,
            
            columns: [
              {
                type: 'text',
                label: 'LAB Category Name',
                field: 'labCategoryName',
                mandatory: true,
              },
              {
                type: 'number',
                label: 'Sort Order',
                field: 'sortOrder',
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default AddLabCategory;
