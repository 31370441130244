import React, {useEffect} from 'react';
import {View, TouchableOpacity} from '../../../app-components';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import {addIcon, roundCross} from '../../../images';
import {MultiTextInput} from '../../../app-components/input-components/Editors';

const Investigation = (props) => {
  const {data = {}, setValue = () => {}, collapse_handler = () => {}} = props;
  const {investigate} = data;
  return (
    <>
      <View style={{marginTop: 10, marginBottom: 10}}>
        <MultiTextInput
          value={investigate}
          renderSelector={addIcon}
          variant={'filled'}
          removeChipIcon={roundCross}
          onChangeValue={(value) => {
            setValue({data, field: 'investigate', value});
          }}
          placeholder="Recommend Sample Tests, MRI, X-ray, etc"
        />
      </View>
      <View style={{flexDirection: 'row-reverse'}}>
        <TouchableOpacity
          style={{width: 90, marginRight: 30, cursor: 'pointer'}}
          onPress={() => {
            collapse_handler({selected: 'treatment'});
          }}>
          <GradientButton label="Next" />
        </TouchableOpacity>
      </View>
    </>
  );
};

export default Investigation;
