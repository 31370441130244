import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity} from '../../../app-components';
import {
  h32_SansSemiBold,
  h20_AvenirNext_500,
} from '../../../theme/fonts';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import {invoke} from '../../../AppServices';
const ThankYou = (props) => {
  const [result, setResult] = useState(false);
  useEffect(() => {
    (async () => {
      let result = await invoke({id: 'connectedAccountConfirmation'});
      setResult(result?.success);
    })();
  }, []);

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Text style={{...h32_SansSemiBold}}>Thank you!</Text>
      <Text style={{...h20_AvenirNext_500, marginTop: 5}}>
        for connecting stripe
      </Text>
      <TouchableOpacity
        style={{cursor: 'pointer', marginTop: 20}}
        onPress={() => {
          const {navigation} = props;
          navigation.replace({view: 'clinic-detail', params: {account_status:result}});
        }}>
        <GradientButton label={'OK'} />
      </TouchableOpacity>
    </View>
  );
};

export default ThankYou;
