import Patients from './Patients';
import AppointmentDetail from './AppointmentDetail'
import ChatHistory from './ChatHistory';

export default {
  patients: {
    screen: Patients,
  },
  'chatHistory':{
    screen:ChatHistory,
    width:0.4
  },
  'appointmentDetails': {
    screen: AppointmentDetail,
  }
};
