import DiagnosisForm from './DiagnosisForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

const header = {
  title: 'Add Diagnosis',
  actions: [cancelButton(), save()],
};

export default DiagnosisForm({header});
