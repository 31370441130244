import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity} from '../../app-components';
import {Multiselect} from 'multiselect-react-dropdown';
import {h14_AvenirNext} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import PatientDetails from './PatientDetails';
import {fetch, getUser} from '../../AppServices';
import { resolvePatientName } from '../../app-components/patientUtility';

const {themeColor} = colors;
let themeTextColor = '#fff';
let key = 1;
const AddMember = (props) => {
  const {meOrSomeOne = 'me', dependent = null} = props;
  const [addMember, setAddmember] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const getMemberData = async () => {
    const result = await fetch({
      uri: {
        props: {
          query: {
            id: 'dependentList',
            addOnFilter: {
              main_patient: {_id: getUser()?.patient?._id},
            },
          },
          model: 'Dependents',
        },
      },
    });
    const {data = []} = result || {};
    if (Array.isArray(data) && data.length) {
      let modifiedData = data.map((item) => {
        const {dependent_patient={}}=item||{}
        return {
          name: resolvePatientName(dependent_patient),
          _id: dependent_patient?._id,
          relation: item?.relation,
        };
      });
      setMemberData(modifiedData);
    }
  };
  useEffect(() => {
    getMemberData();
  }, []);
  useEffect(() => {
    if (dependent) {
      const {_id = null, name = '', relation = ''} = dependent;
      setAddmember(false);
      setSelectedMember({
        _id,
        name,
        relation,
      });
    }
  }, [dependent]);
  return (
    <View style={{display: meOrSomeOne !== 'me' ? 'flex' : 'none'}}>
      {Array.isArray(memberData) && memberData.length ? (
        <Multiselect
          key={key}
          options={memberData}
          selectedValues={
            dependent
              ? [
                  {
                    _id: dependent?._id,
                    name: dependent?.name,
                    relation: '',
                  },
                ]
              : []
          }
          style={{
            searchBox: {
              backgroundColor: '#fff',
            },
            option: {},
          }}
          avoidHighlightFirstOption={true}
          onSelect={(value, selected) => {
            setAddmember(false);
            setSelectedMember(selected);
          }} // Function will trigger on select event
          onRemove={(value) => {}} // Function will trigger on remove event
          displayValue="name"
          singleSelect
        />
      ) : (
        void 0
      )}
      {/* <Multiselect
        key={key}
      options={memberData}
      selectedValues={[]}
      style=
      {{
        searchBox: {
          backgroundColor: '#fff',
        },
        option: {},
      }}
      avoidHighlightFirstOption={true}
      onSelect=
      {(value, selected) => {
        setAddmember(false);
        setSelectedMember(selected);
      }}{' '}
      // Function will trigger on select event onRemove={(value) => {}} //
      Function will trigger on remove event displayValue="name" singleSelect />
       */}
      <View
        style={{
          width: '100%',
          alignItems: 'flex-end',
          marginTop: Array.isArray(memberData) && memberData.length ? 10 : 0,
        }}>
        <TouchableOpacity
          onPress={() => {
            setAddmember(true);
            setSelectedMember({});
            key = key + 1;
          }}
          style={style.addMemberButton}>
          <Text style={{color: themeColor, ...h14_AvenirNext, fontWeight: 500}}>
            Add Member
          </Text>
        </TouchableOpacity>
      </View>
      {addMember ? (
        <View style={{marginBottom: 18}}>
          <PatientDetails
            userId={''}
            {...props}
            patientType={'Member'}
            meOrSomeOne={'someone'}
          />
        </View>
      ) : selectedMember && selectedMember?._id ? (
        <PatientDetails
          userId={
            selectedMember && selectedMember?._id ? selectedMember?._id : ''
          }
          {...props}
          patientType={'Member'}
          meOrSomeOne={'someone'}
          key={selectedMember?._id}
        />
      ) : (
        void 0
      )}
    </View>
  );
};

const style = {
  addMemberButton: {
    backgroundColor: themeTextColor,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 10,
    width: 'fit-content',
    marginBottom: 10,
    cursor: 'pointer',
    alignItems: 'flex-end',
  },
};
export default AddMember;
