import React from 'react';
import Signup from './Signup';
import ClinicDetails from './ClinicDetails';
import ClinicAdminDetails from './ClinicAdminDetails';
import SucessFullCreatedModal from './SucessFullCreatedModal';
import SignupOtp from './SignupOtp';
import SinglePageSignup from './SinglePageSignup';
import CreatePassword from './CreatePassword';

export default {
  'clinic-signup': {
    screen: SinglePageSignup,
    expanded: true,
  },
  'clinic-signup-second': {
    screen: Signup,
    expanded: true,
  },
  sucess: {
    screen: SucessFullCreatedModal,
  },
  'clinc-details': {
    screen: ClinicDetails,
    expanded: true,
  },
  'clinic-admin-detail': {
    screen: ClinicAdminDetails,
    expanded: true,
  },
  'signup-otp': {
    screen: SignupOtp,
    expanded: true,
  },
  'create-doctor-password': {
    screen:CreatePassword,
    expanded: true,
  }
};
