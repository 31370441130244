import React from 'react';
import {View, TouchableOpacity, Image} from '../../app-components';
import { getImageUrl } from '../../AppServices';
import {whiteCrossIcon} from '../../images';

export default class ImageViewer extends React.Component {
  state = {newData: {}};
  cancel = () => {
    let {navigation} = this.props;
    navigation.pop(1);
  };

  render() {
    let {
      navigation: {
        state: {params: {fileName, fileUrl, thumbnail} = {}} = {},
      } = {},
    } = this.props;
    let ImageUrl = fileUrl ? getImageUrl(fileUrl) : void 0;
    return (
      <TouchableOpacity
        style={{
          backgroundColor: 'rgba(0,0,0,0.7)',
          height: window.innerHeight,
          width: window.innerWidth,
        }}
        onPress={event => event.stopPropagation()}>
        <TouchableOpacity
          onPress={this.cancel}
          style={{
            position: 'absolute',
            alignItems: 'flex-end',
            paddingTop: 20,
            right: 0,
            zIndex: 2,
            paddingRight: 30,
            cursor: 'pointer',
            height: 30,
            width: 30,
            alignItems: 'center',
          }}>
          <Image source={whiteCrossIcon}  style={{ height: 25,
            width: 25}}/>
        </TouchableOpacity>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={ImageUrl}
            style={{
              objectFit: 'contain',
              maxHeight: window.innerHeight - 5,
              maxWidth: window.innerWidth - 5,
            }}
          />
        </View>
      </TouchableOpacity>
    );
  }
}
