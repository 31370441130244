import {LoadingInput, MobileInputComponent,HTMLInputField} from '../../input-components/Editors';
import {
  TextInput,
  TextAreaInput,
  NumberInput,
  DateInput,
  TimeInput,
  ProgressSliderInput,
  DownloadFile,
  FileInput,
  CheckBox,
  AutoSuggestInput,
  AutoSuggestAvatarInput,
  MultiAutoSuggestInput,
  MultiAutoSuggestAvatarInput,
  SearchInput,
  SearchAvatarInput,
  MultiSearchInput,
  MultiSearchAvatarInput,
  GooglePlaceInput,
  ToggleInput,
  ToggleSwitchInput,
  RadioGroupInput,
  RadioInput,
  MultiTextInput,
  RichTextInput,
  ChipsToggleInput,
  GooglePlaceMapInput,
  UploadImage,
  PasswordInput,
  OtherAutoSuggest
} from '../../input-components/Editors';
import NestedList from '../../input-components/NestedList';
import {
  TextRender as FormTextRender,
  TextAreaRender as FormTextAreaRender,
  FilledAutoSuggestAvatarRender,
  AutoSuggestAvatarRender,
  MultiAutoSuggestRender,
  MultiAutoSuggestAvatarRender,
  RadioRender,
  UploadImageRender,
} from '../../render-components/FormRenders';

const inputTypes = {
  text: TextInput,
  multiText: MultiTextInput,
  textArea: TextAreaInput,
  number: NumberInput,
  date: DateInput,
  time: TimeInput,
  progressSlider: ProgressSliderInput,
  downloadFile: DownloadFile,
  file: FileInput,
  checkBox: CheckBox,
  autoSuggest: AutoSuggestInput,
  autoSuggestAvatar: AutoSuggestAvatarInput,
  search: SearchInput,
  searchAvatar: SearchAvatarInput,
  multiSearch: MultiSearchInput,
  multiSearchAvatar: MultiSearchAvatarInput,
  multiAutoSuggest: MultiAutoSuggestInput,
  multiAutoSuggestAvatar: MultiAutoSuggestAvatarInput,
  googlePlace: GooglePlaceInput,
  toggle: ToggleInput,
  toggleSwitch: ToggleSwitchInput,
  radioGroup: RadioGroupInput,
  radio: RadioInput,
  richText: RichTextInput,
  chipsToggle: ChipsToggleInput,
  googlePlaceMap: GooglePlaceMapInput,
  uploadImage: UploadImage,
  nestedList: NestedList,
  mobile: MobileInputComponent,
  password:PasswordInput,
  otherAutoSuggest: OtherAutoSuggest,
  loading: LoadingInput,
  html:HTMLInputField,
};

const renderTypes = {
  text: FormTextRender,
  multiText: FormTextRender,
  textArea: FormTextAreaRender,
  number: FormTextRender,
  date: FormTextRender,
  time: FormTextRender,
  file: FormTextRender,
  html: FormTextAreaRender,
  richText: FormTextAreaRender,
  switch: FormTextRender,
  toggle: FormTextRender,
  toggleSwitch: FormTextRender,
  autoSuggest: FormTextRender,
  autoSuggestAvatar: AutoSuggestAvatarRender,
  search: FormTextRender,
  searchAvatar: AutoSuggestAvatarRender,
  multiSearch: MultiAutoSuggestRender,
  multiSearchAvatar: MultiAutoSuggestAvatarRender,
  multiAutoSuggest: MultiAutoSuggestRender,
  multiAutoSuggestAvatar: MultiAutoSuggestAvatarRender,
  googlePlace: FormTextRender,
  radio: RadioRender,
  progressSlider: FormTextRender,
  filledAutoSuggestAvatar: FilledAutoSuggestAvatarRender,
  uploadImage: UploadImageRender,
  chipsToggle: FormTextRender,
  mobile: MobileInputComponent,
  checkBox: CheckBox,
};

export {inputTypes, renderTypes};
