import React from 'react';
import {Text, TouchableOpacity, View, Image} from '../../app-components';
import {lifeLine, mapMarker, profile, tickIcon} from '../../images';
import Table from '../../app-components/table/Table';
import {h14_AvenirNext, h20_AvenirNext_500} from '../../theme/fonts';
import {
  setInitiallyLogged,
  setSelectedDoctor,
  getProfileUrl,
  getImageStyle,
} from '../../AppServices';
import {colors} from '../../theme/colors';
import {
  patientCardMDTheme,
  patientCardTheme,
  mayaCardTheme,
} from '../../theme/cardTheme';

let {themeColor, stackThemeColor} = colors;
const textColor = 'rgba(37, 29, 41, 0.54)';
// let uri = () => {
//   return listUri({model: 'Doctor', id: 'doctorLists'});
// };
let uri = (props) => {
  return {
    id: 'getDoctorList',
    query: {
      addOnFilter: {waiting_room_available: true},
    },
  };
};

const cardDemo = {
  lg: {
    card: {
      // ...patientCardTheme,
      ...mayaCardTheme,
      // title: {
      //   primaryField: 'name',
      //   // secondaryField: 'organization.organization_name',
      //   secondaryField: 'qualification_description',
      // },
      left: [
        {
          items: [
            {
              value: ({navigation, item}) => {
                let {setScreenState} = navigation;
                setScreenState({doctorCount: item.length});
                let {profile_picture} = item;
                let imageUrl = getProfileUrl(profile_picture?.file);
                return (
                  <View
                    style={{
                      position: 'relative',
                      width: '90px',
                      height: '90px',
                      overflow: 'hidden',
                      borderRadius: '50%',
                    }}>
                    <Image
                      source={imageUrl || profile}
                      style={getImageStyle({
                        imageType: profile_picture?.imageType ?? '',
                      })}
                    />
                  </View>
                );
              },
            },
          ],
        },
      ],
      contentItems: [
        [
          {
            value: ({item, t, navigation}) => {
              const {
                name,
                specialities_procedures,
                qualification_description,
                experience_in_years,
                organization,
              } = item;
              let qualification = qualification_description
                ? ' | ' + qualification_description
                : '';
              return (
                <View style={{}}>
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        ...h20_AvenirNext_500,
                        marginTop: 10,
                        marginLeft: 22,
                        color: '#fff',
                      }}>
                      {name}
                    </Text>
                    <Image
                      style={{
                        marginTop: 11,
                        marginLeft: 11,
                        height: 20,
                        width: 20,
                      }}
                      source={tickIcon}
                    />
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      marginLeft: 22,
                      marginTop: 6,
                    }}>
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        // color: textColor87,
                        color: '#fff',
                      }}>
                      {/* {item?.speciality?.name} */}
                      {specialities_procedures?.length > 0 &&
                        specialities_procedures.map((item, index) => {
                          let separator =
                            (index + 1 < specialities_procedures.length &&
                              ', ') ||
                            ' ';

                          return (
                            item.speciality?.name &&
                            item.speciality?.name + separator
                          );
                        })}
                    </Text>

                    <Text
                      style={{
                        color: '#fff',
                        // color: 'rgba(37, 29, 41, 0.54)',
                        ...h14_AvenirNext,
                        whiteSpace: 'normal',
                      }}>
                      {qualification}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 12,
                      // marginBottom: 27,
                    }}>
                    {/* <View
                      style={{
                        // marginTop: 15,
                        marginLeft: 19,
                        color: '#57ccbf',
                        borderRadius: 15,
                        backgroundColor: 'rgba(115, 197, 167, 0.1)',
                        // marginTop: 15,
                      }}>
                      <Text
                        style={{
                          paddingTop: 7,
                          paddingLeft: 13,
                          paddingBottom: 7,
                          paddingRight: 13,
                          ...h13_Regular,
                        }}>
                        Available Today, 11:00 AM
                      </Text>
                    </View> */}
                    {experience_in_years ? (
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          borderRadius: 8,
                          backgroundColor: '#f9f9f9',
                          marginLeft: 16,
                          paddingTop: 7,
                          paddingLeft: 13,
                          paddingBottom: 7,
                          paddingRight: 13,
                          // marginTop: 8,
                        }}>
                        <Image
                          style={{
                            width: 19,
                            height: 16,
                          }}
                          source={lifeLine}
                        />
                        <View style={{marginLeft: 10, paddingRight: 6}}>
                          <Text
                            style={{
                              ...h14_AvenirNext,
                              color: '#383838',
                            }}>
                            {experience_in_years} {t('yrs exp')}
                          </Text>
                        </View>
                      </View>
                    ) : (
                      void 0
                    )}
                    {organization?.organization_name ? (
                      <View
                        style={{
                          borderRadius: 8,
                          backgroundColor: '#f9f9f9',
                          marginLeft: 22,
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: 8,
                          paddingBottom: 8,
                          paddingLeft: 11,
                          paddingRight: 10,
                          // marginTop: 8,
                        }}>
                        <Text
                          style={{
                            ...h14_AvenirNext,
                            color: '#383838',
                          }}>
                          {organization?.organization_name}
                        </Text>
                      </View>
                    ) : (
                      void 0
                    )}
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: 8,
                        backgroundColor: '#f9f9f9',
                        marginLeft: 16,
                        padding: 7,
                        // cursor: 'pointer',
                      }}>
                      <TouchableOpacity
                        onPress={() => {
                          navigation.push({
                            view: 'map-modal',
                            params: {item, t},
                            modal: true,
                            height: 700,
                            modalProps: {
                              autoHide: true,
                              position: 'screenCenter',
                            },
                          });
                        }}
                        style={{cursor: 'pointer'}}>
                        <Image
                          source={mapMarker}
                          style={{height: 22, width: 22}}></Image>
                      </TouchableOpacity>
                    </View>
                    {/* <View
                      style={{
                        borderRadius: 8,
                        backgroundColor: '#f9f9f9',
                        flexDirection: 'row',
                        marginLeft: 11,
                        justifyContent: 'center',
                        alignItems: 'center',
                        // marginTop: 8,
                      }}>
                      <Image
                        style={{
                          height: 14,
                          width: 14,
                          marginLeft: 13,
                          // marginTop: 3,
                          marginRight: 8,
                        }}
                        source={starIconRating}
                      />
                      <View style={{paddingRight: 9}}>
                        <Text style={{color: '#383838', ...h14_AvenirNext}}>
                          4.5
                        </Text>
                      </View>
                    </View> */}
                  </View>
                </View>
              );
            },
          },
        ],
      ],
      right: [
        {
          align: 'right',
          items: [
            // {align: 'right'},
            {paddingTop: 16, paddingBottom: 16},
            {
              prefix: '$',
              field: 'cost_of_booking',
              value: 50,
              textStyle: {
                color: '#fff',
                // color: 'rgba(56, 56, 56, 0.87)',
                ...h20_AvenirNext_500,
                // ...h18_AvenirNext,
                marginTop: 10,
                marginRight: 32,
              },
            },
            {
              prefix: '', //'for ',
              field: 'consultation_duration',
              suffix: '', //'min',
              value: ({t}) => `${t('for')} 15 ${t('min')}`,
              // value:(props)=>{
              // const {t=()=>{},item={}}=props||{}
              // console.log("🚀 ~ file: DoctorSearchList.js ~ line 282 ~ props", props)

              //   return <View>GGGGG</View>
              // },
              textStyle: {
                ...h14_AvenirNext,
                color: '#fff',
                marginTop: 6,
                marginRight: 32,
              },
            },

            [
              {
                action: ({item, index}) => {
                  return {
                    type: 'link',
                    link: {
                      view: 'doctor-profile',
                      params: {item, index},
                    },
                  };
                },
                value: (props) => {
                  const {t} = props || {};

                  return (
                    <TouchableOpacity
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: themeColor,
                        paddingTop: 7,
                        paddingLeft: 16,
                        paddingBottom: 7,
                        paddingRight: 16,
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 29,
                        marginTop: 12,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: themeColor,
                        }}>
                        {/* View Profile */}
                        {t('View Profile')}
                      </Text>
                    </TouchableOpacity>
                  );
                },
              },
              {
                action: ({item, index, navigation}) => {
                  const {organization: {account_status} = {}} = item;
                  if (account_status !== 'linked') {
                    return null;
                  }
                  const {
                    state: {params: {mayaAISessionId = null} = {}} = {},
                  } = navigation;
                  return {
                    type: 'link',
                    link: () => {
                      setSelectedDoctor(item.name);
                      setInitiallyLogged();
                      navigation.push({
                        view: 'doctor-profile',
                        params: {
                          item: {
                            ...item,
                            appointment_type: 'new',
                            mayaAISessionId: mayaAISessionId,
                          },
                          index,
                          openBooking: true,
                        },
                      });
                    },
                  };
                },
                value: ({item, navigation, t}) => {
                  const {organization: {account_status} = {}} = item;
                  let visible = true;
                  if (account_status !== 'linked') {
                    visible = false;
                  }
                  return (
                    <TouchableOpacity
                      style={{
                        backgroundColor: visible ? themeColor : '#cccccc',
                        borderRadius: 8,
                        paddingTop: 7,
                        paddingLeft: 30,
                        paddingBottom: 7,
                        paddingRight: 30,
                        cursor: visible ? 'pointer' : 'not-allowed',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 29,
                        marginTop: 12,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#fff',
                        }}>
                        {visible ? t('Book') : t('Book')}
                      </Text>
                    </TouchableOpacity>
                  );
                },
              },
            ],
            {
              value: () => {
                return <View style={{}} />;
              },
            },
          ],
        },
      ],
    },
  },
  md: {
    card: {
      ...mayaCardTheme,
      left: [
        {
          items: [
            {
              value: ({navigation, item}) => {
                let {setScreenState} = navigation;
                setScreenState({doctorCount: item.length});

                let {profile_picture} = item;
                let imageUrl = getProfileUrl(profile_picture?.file);

                return (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      // paddingTop: 5,
                      // paddingLeft: 5,
                      // marginTop: 6,
                    }}>
                    <Image
                      source={imageUrl || profile}
                      style={{width: 90, height: 90, borderRadius: 90}}
                    />
                  </View>
                );
              },
            },
          ],
        },
      ],
      contentItems: [
        {
          align: 'left',
          position: 'top',
          value: ({item, t}) => {
            const {
              name,
              specialities_procedures,
              qualification_description,
              experience_in_years,
              organization,
            } = item;
            let qualification = qualification_description
              ? ' | ' + qualification_description
              : '';
            return (
              // <View style={{flex: 1}}>
              <View>
                <Text
                  style={{
                    ...h20_AvenirNext_500,
                    color: '#fff',
                  }}>
                  {name}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    // marginLeft: 22,
                    marginTop: 6,
                  }}>
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      // color: textColor87,
                      color: '#fff',
                    }}>
                    {/* {item?.speciality?.name} */}
                    {specialities_procedures?.length > 0 &&
                      specialities_procedures.map((item, index) => {
                        let separator =
                          (index + 1 < specialities_procedures.length &&
                            ', ') ||
                          ' ';
                        return (
                          item.speciality?.name &&
                          item.speciality?.name + separator
                        );
                      })}
                  </Text>

                  <Text
                    style={{
                      color: '#fff',
                      ...h14_AvenirNext,
                      whiteSpace: 'normal',
                    }}>
                    {qualification}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'flex-start',
                    borderRadius: 8,
                    backgroundColor: '#f9f9f9',
                    // marginLeft: 16,
                    paddingTop: 7,
                    paddingLeft: 9,
                    paddingBottom: 7,
                    paddingRight: 9,
                    marginTop: 8,
                    width: 'fit-content',
                  }}>
                  <Image
                    style={{
                      width: 19,
                      height: 16,
                      // paddingLeft: 13,
                      // paddingRight: 1,
                      // marginTop: 2,
                    }}
                    source={lifeLine}
                  />
                  <View style={{marginLeft: 5}}>
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        color: '#383838',
                      }}>
                      {experience_in_years} {t('yrs exp')}
                    </Text>
                  </View>
                </View>
              </View>
            );
          },
        },
      ],
      right: [
        {
          align: 'right',
          items: [
            // {align: 'right'},
            {
              prefix: '$',
              field: 'cost_of_booking',
              textStyle: {
                color: '#fff',
                // ...h18_AvenirNext,
                ...h20_AvenirNext_500,
                // marginTop: 10,
                // marginRight: 32,
              },
            },
            {
              prefix: 'for ',
              field: 'consultation_duration',
              suffix: 'min',
              textStyle: {
                ...h14_AvenirNext,
                color: '#fff',
                marginTop: 6,
                // marginTop: 4,
                // marginRight: 32,
              },
            },

            {
              action: ({item, index}) => {
                return {
                  type: 'link',
                  link: {
                    view: 'doctor-profile',
                    params: {item, index},
                  },
                };
              },
              value: ({t}) => {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                      // marginTop: 12,
                      // marginBottom: 20,
                    }}>
                    <TouchableOpacity
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: themeColor,
                        paddingTop: 7,
                        paddingLeft: 30,
                        paddingBottom: 7,
                        paddingRight: 16,
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // marginRight: 29,
                        marginTop: 8,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: themeColor,
                        }}>
                        {/* View Profile */}
                        {t('View Profile')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              },
            },

            {
              value: () => {
                return <View style={{}} />;
              },
            },
          ],
        },
      ],
    },
  },
};

export default Table({
  uri,
  cardScrollViewStyle: {
    // backgroundColor: stackThemeColor,
    flex: 1,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },
  reloadEvent: 'reloadSearchList',
  ...cardDemo,
});
