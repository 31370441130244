import {startCase} from 'lodash';
import React, {useMemo} from 'react';
import {
  View,
  Text,
  Image,
  isMobile,
  TouchableOpacity,
} from '../../app-components';
import {
  resolvePatientMrn,
  resolvePatientName,
} from '../../app-components/patientUtility';
import {femaleSign, maleSign, userIcon} from '../../images';
import {h13_Regular, h14_Medium} from '../../theme/fonts';
import moment from 'moment';
import {getImageUrl} from '../../AppServices';

const getSeparator = (val, val2) => {
  if (val && val2) {
    return `${val} | ${val2}`;
  }
  return val || val2;
};
const PatientDetails = ({
  item = {},
  contactInformation = false,
  chronicDisease = false,
  showTitle = false,
}) => {
  const {profile_picture, gender, email = '', mobile = ''} = item;
  const profilePhoto = getImageUrl(profile_picture?.file || '');
  let chronicDiseaseName = '';
  const {careProgram = [], device_gateway_id = ''} = item || {};
  const ActiveCareProgram = Array.isArray(careProgram)
    ? careProgram.filter((careItem) => careItem?.status === 'Active')
    : [];
  if (chronicDisease) {
    let chronicDisease = [];
    for (let val of careProgram) {
      chronicDisease = [...chronicDisease, ...(val?.chronicDisease || [])];
    }
    const name = chronicDisease.map((item) => item?.name || '');
    chronicDiseaseName = name.join(', ');
  }
  const titleMessage = useMemo(() => {
    let message = [];
    if (showTitle) {
      if (device_gateway_id) {
        // message.push('Device: Assigned');
      } else {
        message.push('Device: Not Assigned');
      }
      if (Array.isArray(ActiveCareProgram) && ActiveCareProgram.length) {
        // message.push('Care Program: Assigned');
        for (let {
          careTeam,
          careProgram: {name = ''} = {},
        } of ActiveCareProgram) {
          if (careTeam?._id) {
            // message.push(`${name}: Care Team Assigned`);
          } else {
            message.push(`Care Team for program ${name}: Not Assigned`);
          }
        }
      } else {
        message.push('Care Program: Not Assigned');
      }
      return message.join('\n');
    }
    return '';
  }, [item]);
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
      title={showTitle ? titleMessage : ''}>
      <View
        style={{
          alignItems: 'center',
        }}>
        <Image
          source={profilePhoto || userIcon}
          style={{
            height: isMobile ? 25 : 40,
            width: isMobile ? 25 : 40,
            borderRadius: 20,
          }}
        />
      </View>

      <View style={{marginLeft: 10, height: '100%', width: '80%'}}>
        <Text
          style={{
            ...h14_Medium,
            lineHeight: 21,
            letterSpacing: 0.57,
            numberOfLines: 1,
          }}
          numberOfLines={1}>
          {resolvePatientName(item)}
        </Text>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {gender === 'male' ? (
            <Image
              source={maleSign}
              style={{marginRight: 5}}
              height={15}
              width={15}
            />
          ) : gender === 'female' ? (
            <Image
              source={femaleSign}
              style={{marginRight: 5}}
              height={15}
              width={15}
            />
          ) : null}

          <Text style={{...h13_Regular, lineHeight: 21, letterSpacing: 0.57}}>
            {getSeparator(
              startCase(gender),
              item?.birthDate
                ? moment(item?.birthDate).format('Do MMM YYYY')
                : '',
            )}
          </Text>
        </View>
        {resolvePatientMrn(item) ? (
          <Text style={{...h13_Regular, lineHeight: 21, letterSpacing: 0.57}}>
            {`MRN No. - ${resolvePatientMrn(item)}`}
          </Text>
        ) : (
          void 0
        )}
      </View>
      {contactInformation ? (
        <View style={{marginLeft: 20, height: '100%'}}>
          <View>
            <Text
              style={{
                ...h14_Medium,
              }}>
              Contact Information
            </Text>
            <Text
              style={{
                ...h13_Regular,
                lineHeight: 21,
                letterSpacing: 0.57,
              }}>
              {email}
            </Text>
            <Text
              style={{
                ...h13_Regular,
                lineHeight: 21,
                letterSpacing: 0.57,
              }}>
              {mobile}
            </Text>
          </View>
        </View>
      ) : (
        void 0
      )}
      {chronicDisease ? (
        <View style={{marginLeft: 20, height: '100%'}}>
          <View>
            <Text
              style={{
                ...h14_Medium,
              }}>
              Chronic Disease
            </Text>
            <Text
              style={{
                ...h13_Regular,
                lineHeight: 21,
                letterSpacing: 0.57,
              }}>
              {chronicDiseaseName}
            </Text>
            {/* <Text
              style={{
                ...h13_Regular,
                lineHeight: 21,
                letterSpacing: 0.57,
              }}>
              {mobile}
            </Text> */}
          </View>
        </View>
      ) : (
        void 0
      )}
    </View>
  );
};

export default PatientDetails;
