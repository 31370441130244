import SymptomForm from './SymptomForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Add Symptom',
  actions: [cancelButton(), save()],
};

export default SymptomForm({header});
