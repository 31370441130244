import React from 'react';
import {
  Text,
  View,
  WithModalButton,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
} from './app-components';
import {ToggleSwitchInput} from './app-components/input-components/Editors';
import {getUser, waitingRoom} from './AppServices';
import {mayaMdLogo, menuBar} from './images';
import navigationMenus from './NavigationMenu';
import {colors} from './theme/colors';
import {h12_SemiBold, h15_AvenirNext_500} from './theme/fonts';

const {height, width} = Dimensions.get('window');
const {themeColor, defaultThemeColor} = colors;

const SideBar = (props) => {
  const {navigation, visibleTabs} = props;
  const routes = navigation.getActiveRoutes();
  let firstRoute = routes[0] || [];
  let activeHeaderTextStyle = {
    ...h15_AvenirNext_500,
    fontWeight: 700,
    color: themeColor,
  };
  let headerTextStyle = {
    ...h15_AvenirNext_500,
    color: defaultThemeColor,
  };
  const renderModal = ({frameStyle, hideModal}) => {
    let {left, width} = frameStyle;
    const {userType = '', organization = {}, doctor = {}} = getUser();
    return (
      <TouchableOpacity
        style={{
          ...frameStyle,
          left: 0,
          right: 0,
          top: 0,
          zIndex: 5,
          height: '100%',
          overflow: 'hidden',
          backgroundColor: '#fff',
        }}>
        <TouchableOpacity
          onPress={() => {
            // navigation && navigation.reset && navigation.reset('home');
          }}
          style={{alignItems: 'center'}}>
          <Image
            source={mayaMdLogo}
            style={{
              width: width * 0.75,
              height: height * 0.1,
              objectFit: 'contain',
            }}
          />
        </TouchableOpacity>
        <ScrollView
          // horizontal={true}
          style={{flex: 1}}>
          {navigationMenus.map((menu, index) => {
            let {title: text, icon, activeIcon, navigate: link} = menu;
            let active;
            let textStyle;
            let tempActive;
            if (userType === 'ClinicAdmin') {
              const {is_emergency = false} = organization;
              if (!is_emergency) {
                if (link?.view === 'emergency-request') {
                  return null;
                }
              }
            }
            if (userType === 'SuperAdmin') {
              if (text === 'Appointment') {
                text = 'Patients';
              }
            }
            if (userType === 'Patient') {
              if (text === 'Appointment') {
                text = 'Past appointments';
              }
              if (text === 'Follow-Up') {
                text = 'Folloup reqeusts';
              }
            }
            if (userType === 'Doctor') {
              const {waiting_room_available = false} = doctor;
              if (!waiting_room_available) {
                if (link?.view === 'instant-patient-queue') {
                  return null;
                }
              }
            }
            if (firstRoute) {
              active = link.view === firstRoute;
              tempActive = firstRoute;
            } else {
              active = link.view === tempActive;
            }
            if (active) {
              icon = activeIcon || icon;
              textStyle = activeHeaderTextStyle;
            } else {
              textStyle = headerTextStyle;
            }
            return (
              <>
                {visibleTabs({
                  view: link.view,
                  params: link.params,
                }) && (
                  <View
                    style={{
                      minWidth: '8%',
                      paddingTop: 10,
                      cursor: 'pointer',
                    }}>
                    <TouchableOpacity
                      style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        padding: 10,
                      }}
                      onPress={() => {
                        navigation.replace(link);
                        hideModal();
                      }}>
                      {icon && (
                        <Image source={icon} style={{width: 20, height: 20}} />
                      )}
                      {text && (
                        <Text
                          style={{
                            ...textStyle,
                            marginLeft: 10,
                          }}>
                          {text}
                        </Text>
                      )}

                      {/* {active && (
                      <View
                        style={{
                          ...bottomBorderStyle,
                          backgroundColor: active ? themeColor : 'white',
                        }}
                      />
                    )} */}
                    </TouchableOpacity>
                  </View>
                )}
              </>
            );
          })}
        </ScrollView>
        {userType === 'Doctor' ? (
          <View
            style={{
              paddingRight: 10,
              marginBottom: 20,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <ToggleSwitchInput
              value={doctor.waiting_room_available}
              showOuterLabel
              onChangeValue={(value) => {
                waitingRoom(value)
                  .then(() => {
                    if (
                      !value &&
                      routes &&
                      routes[0] &&
                      routes[0] === 'instant-patient-queue'
                    ) {
                      navigation.reset('analytics');
                    }
                    hideModal();
                  })
                  .catch((err) => {
                    console.log(`>@>Puru-NavigationBar.js~line-255`, err);
                  });
              }}
            />
            <Text style={{...h12_SemiBold, color: 'red', marginTop: 10}}>
              Instant consultation
            </Text>
          </View>
        ) : null}
      </TouchableOpacity>
    );
  };
  return (
    <WithModalButton
      renderModal={renderModal}
      autoHide={true}
      backdropStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        overflow: 'hidden',
        top: 0,
        left: 0,
      }}
      dropdownStyle={{
        width: width * 0.6,
        height: '100%',
        overflow: 'hidden',
      }}>
      <View
        onClick={() => {}}
        style={{
          height: 70,
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Image source={menuBar} style={{width: 40, marginRight: 10}} />
      </View>
    </WithModalButton>
  );
};

export default SideBar;
