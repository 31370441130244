import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, Image} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {
  h12_SemiBold,
  h15_Medium,
  h16_SemiBold,
} from '../../theme/fonts';
import {TextInput} from '../../npms/react-text-input';
import {invoke} from '../../AppServices';
import {CheckBox} from '../../app-components/input-components/Editors';
import {redCloseIcon} from '../../images';
let initial_device_names = [];
function AddPatientDevice(props) {
  const {navigation = {}, eventDispatcher} = props;
  const {
    state: {
      params: {
        _id = '',
        device_gateway_id = '',
        device: selectedDevice = [],
      } = {},
      params,
    } = {},
  } = navigation;
  const [gateway, setGateway] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState({
    'Tenovi BPM': {
      check: false,
      code: 1,
      label: 'Blood Pressure Monitor',
      name: 'Tenovi BPM',
    },
    'Tenovi Scale': {
      check: false,
      code: 2,
      label: 'Weighing Scale',
      name: 'Tenovi Scale',
    },
    'Tenovi Glucometer': {
      check: false,
      code: 3,
      label: 'Glucometer',
      name: 'Tenovi Glucometer',
    },
  });
  useEffect(() => {
    handleChange(
      typeof device_gateway_id === 'string'
        ? device_gateway_id.replaceAll('-', '')
        : '',
    );
    for (let val of selectedDevice) {
      if (
        val?.status == 'Device Activated!' ||
        val?.status === 'Device Already Active!'
      ) {
        handleDevice({
          field: val?.device?.name,
          value: true,
          name: val?.device?.name || '',
        });
      }
    }
  }, [device_gateway_id]);
  const handleChange = (value) => {
    let result = [];
    if (error) {
      setError('');
    }
    for (let i = 0; i < value.length; i += 4) {
      result.push(value.substr(i, 4));
    }
    setGateway(result.join('-'));
  };
  const handleDevice = ({value, field, name = ''}) => {
    if (error) {
      setError('');
    }
    if (name) {
      initial_device_names.push(name);
    }
    setDevice((prev) => ({
      ...prev,
      [field]: {...prev?.[field], check: value},
    }));
  };
  const onSubmit = () => {
    if (!gateway.trim()) {
      setError('Gateway is required');
      return;
    }
    if (gateway.length < 14) {
      setError('Invalid Gateway');
      return;
    }
    let device_names = [];
    let unselected_device_names = [];
    let isDeviceSelected = false;
    for (let value of Object.values(device)) {
      if (value.check) {
        isDeviceSelected = true;
        device_names.push(value.name);
      } else if (initial_device_names.includes(value.name)) {
        unselected_device_names.push(value.name);
      }
    }
    if (!isDeviceSelected) {
      setError('Please select at least one device.');
      return;
    }
    setLoading(true);
    invoke({
      id: 'customDeviceActivation',
      paramValue: {
        patient: _id,
        gateway: gateway.replaceAll('-', '')?.toLowerCase(),
        device_names,
        unselected_device_names,
        // data: {device_names},
      },
    })
      .then((res) => {
        setLoading(false);
        eventDispatcher.notify('reloadPatient');
        navigation.pop();
      })
      .catch((err) => {
        setLoading(false);
        if (
          typeof err.message === 'string' &&
          err.message.includes('duplicate key error collection')
        ) {
          setError('Gateway is assigned to another patient');
        } else {
          navigation.pop();
        }
      });
  };
  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: 500,
        padding: 20,
        paddingTop: 10,
      }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10,
          marginTop: 10,
        }}>
        <Text style={{...h16_SemiBold}}>Enter Gateway Id</Text>
        <TouchableOpacity
          style={{
            height: 20,
            width: 20,
            cursor: 'pointer',
          }}
          onPress={() => {
            navigation.pop();
          }}>
          <Image source={redCloseIcon} style={{width: 20, height: 20}} />
        </TouchableOpacity>
      </View>
      <TextInput
        value={gateway}
        onChangeText={(value) => {
          if (value.length <= 14) {
            handleChange(
              typeof value === 'string' ? value.replaceAll('-', '') : value,
            );
          }
        }}
        variant="filled"
        placeholder={'Enter Gateway Id'}
      />
      <View
        style={{
          // backgroundColor: '#f8fafb',
          flex: 1,
          justifyContent: 'space-between',
          flexDirection: 'row',
          marginTop: 10,
        }}>
        <View
          style={{
            flexDirection: 'row',
          }}>
          <CheckBox
            value={device['Tenovi BPM']?.check}
            onChangeValue={(value) => {
              handleDevice({field: 'Tenovi BPM', value});
            }}
          />
          <Text style={{marginLeft: 10, ...h15_Medium}}>
            {device['Tenovi BPM'].label}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
          }}>
          <CheckBox
            value={device['Tenovi Glucometer'].check}
            onChangeValue={(value) => {
              handleDevice({field: 'Tenovi Glucometer', value});
            }}
          />
          <Text style={{marginLeft: 10, ...h15_Medium}}>
            {device['Tenovi Glucometer'].label}
          </Text>
        </View>
      </View>

      <View
        style={{
          // backgroundColor: '#f8fafb',
          flex: 1,
          justifyContent: 'space-between',
          flexDirection: 'row',
          marginTop: 10,
        }}>
        <View
          style={{
            flexDirection: 'row',
          }}>
          <CheckBox
            value={device['Tenovi Scale']?.check}
            onChangeValue={(value) => {
              handleDevice({field: 'Tenovi Scale', value});
            }}
          />
          <Text style={{marginLeft: 10, ...h15_Medium}}>
            {device['Tenovi Scale'].label}
            {/* (वेगहिंग स्केल) */}
          </Text>
        </View>
      </View>

      <TouchableOpacity
        style={{
          cursor: 'pointer',
          marginTop: 20,
          width: 300,
          alignSelf: 'center',
          // marginBottom: 10,
        }}
        onPress={() => {
          // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>', device);
          onSubmit();
        }}>
        {error ? (
          <Text
            style={{
              ...h12_SemiBold,
              color: 'red',
              textAlign: 'center',
              marginBottom: 8,
            }}>
            {error}
          </Text>
        ) : null}
        <GradientButton
          indicatorColor="#fff"
          loading={loading}
          label="Submit"
        />
      </TouchableOpacity>
    </View>
  );
}

export default AddPatientDevice;
