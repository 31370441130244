import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  isMobile,
} from '../../app-components';
import {
  TextInput,
  CheckBox,
} from '../../app-components/input-components/Editors';
import {greenTick} from '../../images';
import moment from 'moment';
import {
  CardNumberElement,
  CardExpiryElement,
  useStripe,
  useElements,
  CardCvcElement,
  Elements,
  IbanElement,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
  h14_AvenirNext,
  h10_Regular,
  h14_AvenirNext_Bold,
  h15_Regular,
  h14_Medium,
  h13_Regular,
} from '../../theme/fonts';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {filledInputTheme} from '../../theme/inputTheme';
import {colors} from '../../theme/colors';
import {invoke, post, getUser} from '../../AppServices';
import SavedCards from './PaymentComponent/SavedCards';
import {STRIPE_KEY} from '../../Config';
const IBAN = 'DE89370400440532013000';
const CARD = '4000 0025 0000 3155';
const {themeColor} = colors;
const stripePromise = loadStripe(STRIPE_KEY);
const optionsTheme = {
  style: {
    base: {
      lineHeight: '40px',
      padding: 10,
      fontSize: '16px',
      backgroundColor: '#fff',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: 'red',
    },
  },
};

const CardPayment = (
  {
    name,
    setName,
    setIsCardError,
    setIsCvcError,
    setIsDateError,
    err,
    setError,
    checkBox,
    setCheckBox,
    t,
  },
  props,
) => {
  const updateState = ({elementType = '', complete, value}) => {
    if (elementType === 'cardNumber' && complete) {
      setIsCardError(value);
    }
    if (elementType === 'cardCvc' && complete) {
      setIsCvcError(value);
    }
    if (elementType === 'cardExpiry' && complete) {
      setIsDateError(value);
    }
  };
  useEffect(() => {
    return () => {
      setName('');
      setIsCardError(true);
      setIsCvcError(true);
      setIsDateError(true);
    };
  }, []);
  const handleChange = (stripe_props) => {
    const {error, complete, elementType, empty} = stripe_props;

    if (empty) {
      updateState({elementType, complete: !complete, value: true});
    }
    if (error) {
      setError(error.message);
      updateState({elementType, complete: !complete, value: true});
    } else {
      setError('');
      updateState({elementType, complete: complete, value: false});
    }
  };

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
      }}>
      <Text style={{...h14_Medium, marginBottom: 10, color: '#fff'}}>
        {/* NAME ON CARD */}
        {t('NAME ON CARD')}
      </Text>
      <TextInput
        value={name}
        onChangeText={(value) => {
          setName(value);
        }}
        variant="filled"
        placeholder={t('Enter Your Name')}
      />
      <View style={{width: '100%', marginTop: 10, marginBottom: 10}}>
        <Text style={{...h14_Medium, marginBottom: 10, color: '#fff'}}>
          {t('CARD NUMBER')}
        </Text>
        <View style={{...filledInputTheme?.style, padding: 3}}>
          <CardNumberElement
            options={{...optionsTheme}}
            onChange={handleChange}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginTop: 10,
          }}>
          <View>
            <Text style={{...h14_Medium, marginBottom: 10, color: '#fff'}}>
              {t('Card Expiry').toUpperCase()}
            </Text>
            <View
              style={{...filledInputTheme?.style, padding: 3, paddingLeft: 10}}>
              <CardExpiryElement
                options={{...optionsTheme}}
                onChange={handleChange}
              />
            </View>
          </View>
          <View style={{marginLeft: 20, marginRight: 20}}>
            <Text style={{...h14_Medium, marginBottom: 10, color: '#fff'}}>
              {t('CARD CVV')}
            </Text>
            <View style={{...filledInputTheme?.style, padding: 3}}>
              <CardCvcElement
                options={{...optionsTheme}}
                onChange={handleChange}
              />
            </View>
          </View>
        </View>
      </View>
      <View style={{flexDirection: 'row'}}>
        <View style={{width: 15, height: 15}}>
          <CheckBox
            value={checkBox}
            onChangeValue={(value) => {
              setCheckBox(value);
            }}
          />
        </View>
        <Text
          style={{
            ...h13_Regular,
            color: '#fff',
            marginLeft: 10,
            marginRight: 10,
          }}>
          {/* Save this card for future appointments */}
          {t('saveCardForFuture')}
        </Text>
      </View>
      <Text style={{...h14_Medium, marginTop: 5, color: 'red'}}>{err}</Text>
    </form>
  );
};
// const BankPayment = ({name, setName, email, setEmail}) => {
//   // const [t] = useTranslation();
//   return (
//     <form
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         marginTop: 10,
//       }}>
//       <Text style={{...h14_Medium, marginBottom: 10}}>{t('NAME ON CARD')}</Text>
//       <TextInput
//         value={name}
//         onChangeText={(value) => {
//           setName(value);
//         }}
//         variant="filled"
//         placeholder={t('Please enter') + props.t('First Name')}
//       />
//       <Text style={{...h14_Medium, marginBottom: 10, marginTop: 10}}>
//         {props.t('Email Address')}
//       </Text>
//       <TextInput
//         value={email}
//         onChangeText={(value) => {
//           setEmail(value);
//         }}
//         variant="filled"
//         placeholder={props.t('Enter email address')}
//       />
//       <View style={{width: '100%', marginTop: 10, marginBottom: 10}}>
//         <Text style={{...h14_Medium, marginBottom: 10}}>BANK ACCOUNT</Text>
//         <View style={{...filledInputTheme?.style, padding: 3}}>
//           <IbanElement
//             options={{supportedCountries: ['SEPA'], ...optionsTheme}}
//           />
//         </View>
//       </View>
//     </form>
//   );
// };
const CheckoutForm = (props) => {
  const {navigation = {}, t} = props;
  const {state: {params = {}} = {}} = navigation;
  const {
    item = {},
    selectdDate,
    selectedTimeSlot,
    appointmentData,
    userId,
  } = params;
  console.log(
    '>>ayment.js ~ line 233 ~ CheckoutForm ~ appointmentData',
    userId,
  );
  // console.log("~ line 233 ~ CheckoutForm ~ appointmentData", appointmentData)
  const [loading, setLoading] = useState(false);
  // const {patient: {_id: patientId} = {}} = getUser() || {};
  const patientId = userId;
  const [selectedPaymentOption, setSelectdPaymentOption] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const [isCardError, setIsCardError] = useState(true);
  const [isCvcError, setIsCvcError] = useState(true);
  const [isDateError, setIsDateError] = useState(true);
  const [err, setError] = useState('');

  const [selectedSavedCard, setSelectedSavedCard] = useState(null);
  const [checkBox, setCheckBox] = useState(false);

  const createPaymentIntent = async ({payment_method_id = null}) => {
    try {
      const res = await invoke({
        id: 'createPaymentIntent',
        paramValue: {
          data: {
            doctorId: item?._id ?? '',
            payment_method_id,
          },
        },
      });
      const {client_secret} = res;
      return client_secret;
    } catch (err) {
      let message = err.message;
      return {error: JSON.parse(message)?.error?.message};
    }
  };

  const SucessHandler = ({createAppointmentResponse, paymentIntent}) => {
    return invoke({
      id: 'successHandler',
      paramValue: {
        paymentIntent: {id: paymentIntent},
        appointment_id: createAppointmentResponse?._id,
        // payment_gateway: 'Stripe',
        appointment_offset: new Date().getTimezoneOffset(),
      },
    });
    // // const {client_secret} = res||{};
    // return res;
  };
  const paymentSucess = ({createAppointmentResponse}) => {
    post({
      data: {_id: createAppointmentResponse?._id},
      updates: {
        status: 'scheduled',
        appointment_timezone_offset: new Date().getTimezoneOffset(),
      },
      model: 'Appointments',
    })
      .then((res) => {
        const {result = []} = res || {};
        if (result.length) {
          const {payment_intent = {}} = result[0].result || {};
          SucessHandler({
            createAppointmentResponse,
            paymentIntent: payment_intent?.id,
          })
            .then((resp) => {
              setLoading(false);
              navigation.push({
                view: 'booking-confirm',
                params: {
                  ...params,
                  res: result[0]?.result,
                },
              });
            })
            .catch((err) => {
              console.log('err', err);
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createAppointment = async ({clientSecret}) => {
    const {item, selectdDate, selectedTimeSlot, data} = params;
    const {
      cost_of_booking,
      _id,
      organization,
      appointment_type,
      mayaAISessionId = null,
    } = item || {};
    try {
      const res = await post({
        data: {},
        updates: {
          doctor_id: {_id},
          organization_id: {_id: organization?._id},
          booking_fee: cost_of_booking,
          date: selectdDate,
          time_slot: selectedTimeSlot,
          time: selectedTimeSlot?.start_time,
          appointment_type,
          sid: mayaAISessionId,
          patient_id: {_id: patientId},
          payment_gateway: 'Stripe',
          clientSecret,
          ...appointmentData,
        },
        model: 'Appointments',
      });

      const {result = []} = res || {};
      if (Array.isArray(result) && result.length) {
        return result[0]?.result;
      }
    } catch (err) {
      console.log('error in create apppintement', err);
    }
  };

  const onGettingError = async ({createAppointmentResponse}) => {
    try {
      const res = await invoke({
        id: 'failureHandler',
        paramValue: {
          appointmentId: createAppointmentResponse?._id,
        },
      });
      return res;
    } catch (err) {
      console.log('Errr in seding', err);
    }
  };

  // const handleSavedCard = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>: handleSavedCard -> selectedSavedCard", selectedSavedCard)
  //   const clientSecret = await createPaymentIntent({
  //     // payment_method_id:selectedSavedCard?.id,
  //     // selectedSavedCard && selectedSavedCard.id ? selectedSavedCard.id : null,
  //   });

  //   // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>: handleSavedCard -> clientSecret", clientSecret)
  //   const createAppointmentResponse = await createAppointment({
  //     clientSecret,
  //     status: '',
  //   });
  //   let result;
  //   // if (selectedPaymentOption === 0) {

  //   // }
  //   if (!createAppointmentResponse) {
  //     navigation.push({
  //       view: 'payment_failed',
  //       params: {
  //         message:
  //           'Selected Slot Booked by someone else, please try for another slot',
  //         isNavigate: true,
  //       },
  //       modal: true,
  //       modalProps: {
  //         autoHide: true,
  //         screenCenterStyle: {
  //           borderRadius: 10,
  //         },
  //         style: {},
  //       },
  //     });
  //     setLoading(false);
  //     return null;
  //   }

  //   result = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: selectedSavedCard.id,
  //   });

  //   if (result && result.error && result.error.message) {
  //     const {error: {payment_intent} = {}} = result;
  //     if (createAppointmentResponse) {
  //       const error = await onGettingError({createAppointmentResponse});
  //     }
  //     setLoading(false);
  //     navigation.push({
  //       view: 'payment_failed',
  //       params: {message: result?.error?.message},
  //       modal: true,
  //       modalProps: {
  //         autoHide: true,
  //         screenCenterStyle: {
  //           borderRadius: 10,
  //         },
  //         style: {},
  //       },
  //     });
  //   } else {
  //     paymentSucess({
  //       createAppointmentResponse: createAppointmentResponse,
  //     });
  //   }
  // };

  const handleCardSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    const clientSecret = await createPaymentIntent({
      payment_method_id: null,
    });
    if (clientSecret && clientSecret?.error) {
      navigation.push({
        view: 'payment_failed',
        params: {message: clientSecret?.error},
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
        },
      });
      setLoading(false);
      // Stripe.js has not yet loa\ded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if (!stripe || !elements || !clientSecret) {
      navigation.push({
        view: 'payment_failed',
        params: {message: 'Error in connection'},
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
        },
      });
      setLoading(false);
      // Stripe.js has not yet loa\ded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const createAppointmentResponse = await createAppointment({clientSecret});
    if (!createAppointmentResponse) {
      navigation.push({
        view: 'payment_failed',
        params: {
          message:
            'Selected Slot Booked by someone else, please try for another slot',
          isNavigate: true,
        },
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
        },
      });
      setLoading(false);
      return null;
    }
    let result = {};
    if (selectedPaymentOption === 1) {
      result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement('cardNumber'),
          billing_details: {
            name: name,
          },
        },
        setup_future_usage: checkBox ? 'off_session' : null,
      });
    }
    if (selectedPaymentOption === 0) {
      result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: selectedSavedCard.id,
      });
    }
    if (result.error) {
      const {error: {payment_intent} = {}} = result;
      if (createAppointmentResponse) {
        const error = await onGettingError({createAppointmentResponse});
      }
      setLoading(false);
      navigation.push({
        view: 'payment_failed',
        params: {message: result?.error?.message},
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
        },
      });
      // console.log('error12345', result.error.message);
    } else {
      // The payment has been processed!
      if (selectedPaymentOption === 1) {
        if (
          result &&
          result.paymentIntent &&
          result.paymentIntent.status &&
          result.paymentIntent.status === 'succeeded'
        ) {
          // console.log("Booking confirmed")
          paymentSucess({
            createAppointmentResponse: createAppointmentResponse,
          });
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        } else {
          setLoading(false);
        }
      } else {
        paymentSucess({
          createAppointmentResponse: createAppointmentResponse,
        });
      }
    }
  };

  const handleBankSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const clientSecret = null;
    const result = await stripe.confirmSepaDebitPayment(clientSecret, {
      payment_method: {
        sepa_debit: elements.getElement(IbanElement),
        billing_details: {
          name: name,
          email: email,
        },
      },
    });

    if (result.error) {
      setLoading(false);
      console.log(result.error.message);
    } else {
      setLoading(false);
      paymentSucess({payment_intent: {id: result?.paymentIntent?.id}});
    }
  };
  const getErrorMessage = () => {
    if (!name) {
      return 'Enter Your Name';
    }
    if (isCardError) {
      return 'Please enter your card details';
    }
    if (isDateError) {
      return 'Please enter your card expiry date';
    }
    if (isCvcError) {
      return 'Please enter cvc';
    }
    return ' ';
  };

  return (
    <ScrollView style={{flex: 1}}>
      <View
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          paddingTop: 45,
          paddingBottom: 30,
          // flex:1
          // backgroundColor: 'rgba(255,255,255,0.1)'
        }}>
        <View
          style={{
            width: isMobile ? '98%' : '50%',
            // border: '1px solid rgba(56, 56, 56, 0.37)',
            padding: 20,
            borderRadius: 8,
            paddingBottom: 10,
            marginBottom: 10,
            // backgroundColor: 'rgba(255,255,255,0.5)'
            backgroundImage:
              'linear-gradient(180deg, rgba(0,0,0,0.2), transparent)',
          }}>
          {/* {isMobile ? <Text>{props.t('Consultation Fee')} : {`$${item?.cost_of_booking}`}</Text> : void 0} */}
          <View>
            <Text style={{...h14_Medium, color: '#fff'}}>
              {props.t('Select Payment method').toUpperCase()}
            </Text>
            <View style={{flexDirection: 'row', marginTop: 10}}>
              {[
                {label: props.t('Saved Cards'), visible: true},
                {label: props.t('Credit Card'), visible: true},
                {label: 'Bank Transfer', visible: false},
              ].map((item, index) =>
                item?.visible ? (
                  <TouchableOpacity
                    onPress={() => {
                      setSelectdPaymentOption(index);
                    }}
                    style={{
                      width: isMobile ? 80 : 160,
                      height: isMobile ? 35 : 50,
                      borderRadius: 8,
                      border:
                        selectedPaymentOption === index
                          ? `1px solid ${themeColor}`
                          : '1px solid rgba(56, 56, 56, 0.37)',
                      backgroundColor:
                        selectedPaymentOption === index ? themeColor : '#fff',
                      margin: 8,
                      marginLeft: index === 0 ? 0 : null,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text
                      style={{
                        ...(isMobile ? h15_Regular : h13_Regular),
                        color:
                          selectedPaymentOption === index
                            ? '#fff'
                            : 'rgba(56, 56, 56, 0.47)',
                      }}>
                      {item?.label}
                    </Text>
                  </TouchableOpacity>
                ) : (
                  void 0
                ),
              )}
            </View>
            {selectedPaymentOption === 0 ? (
              <SavedCards
                key={selectedPaymentOption}
                t={t}
                userId={userId}
                setSelectedSavedCard={setSelectedSavedCard}
              />
            ) : null}
            {selectedPaymentOption === 1 ? (
              <CardPayment
                setError={setError}
                err={err}
                name={name}
                setName={setName}
                setIsCardError={setIsCardError}
                setIsDateError={setIsDateError}
                setIsCvcError={setIsCvcError}
                key={selectedPaymentOption}
                checkBox={checkBox}
                setCheckBox={setCheckBox}
                t={t}
              />
            ) : null}
            {/* {selectedPaymentOption === 2 ? (
              <BankPayment
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                key={selectedPaymentOption}
              />
            ) : null} */}
          </View>
        </View>
        {/* SidePannel */}
        <View style={{padding: 20, paddingTop: 0}}>
          <View
            style={{
              // border: '1px solid rgba(56, 56, 56, 0.37)',
              backgroundImage:
                'linear-gradient(180deg, rgba(0,0,0,0.2), transparent)',
              borderRadius: 8,
              minWidth: 190,
            }}>
            <View style={{padding: 10}}>
              <Text style={{...h14_AvenirNext_Bold, color: '#fff'}}>
                {/* Consultation Fee */}
                {props.t('Consultation Fee')}
              </Text>

              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 22,
                }}>
                <Text style={{...h10_Regular, color: '#fff'}}>
                  {props.t('Video Consultation')}
                </Text>
                <Text
                  style={{
                    ...h14_AvenirNext,
                    // color: themeColor,
                    color: '#fff',
                  }}>{`$${item?.cost_of_booking}`}</Text>
              </View>
              <View style={{marginTop: 15}}></View>
            </View>
            <View
              style={{
                width: '100%',
                height: 50,
                padding: 10,
                backgroundColor: '#f1f5f8',
                justifyContent: 'center',
                alignItems: 'center',
                borderTopWidth: 1,
                borderTopColor: 'rgba(56, 56, 56, 0.37)',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}>
              <Text style={{...h14_AvenirNext}}>
                {props.t('Appointment on')}{' '}
                {moment(selectdDate).format('MMM DD YYYY')}
                <Text>
                  {props.t('at')}{' '}
                  {moment(selectedTimeSlot?.start_time).format('hh:mm A')}{' '}
                </Text>
              </Text>
            </View>
          </View>
          {stripe &&
          !isCardError &&
          !isCvcError &&
          !isDateError &&
          name &&
          selectedPaymentOption === 1 ? (
            <TouchableOpacity
              style={{cursor: 'pointer', marginTop: 30}}
              onPress={(e) => {
                if (
                  stripe &&
                  !loading &&
                  !isCardError &&
                  !isCvcError &&
                  !isDateError &&
                  name
                ) {
                  selectedPaymentOption === 1
                    ? handleCardSubmit(e)
                    : handleBankSubmit(e);
                } else {
                  setError(getErrorMessage());
                }
              }}>
              <GradientButton
                label={props.t('Pay Now')}
                loading={loading}
                indicatorColor={'#fff'}
                // color={'#cccccc'}
              />
            </TouchableOpacity>
          ) : selectedPaymentOption === 1 ? (
            <TouchableOpacity style={{cursor: 'not-allowed', marginTop: 30}}>
              <GradientButton
                label={props.t('Pay Now')}
                loading={loading}
                color={'#cccccc'}
                indicatorColor={'#fff'}
              />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={{
                cursor:
                  selectedSavedCard && selectedSavedCard.id
                    ? 'pointer'
                    : 'not-allowed',
                marginTop: 30,
              }}
              onPress={(e) => {
                if (selectedSavedCard && selectedSavedCard.id) {
                  handleCardSubmit(e);
                }
              }}>
              <GradientButton
                label={props.t('Pay Now')}
                loading={loading}
                color={
                  selectedSavedCard && selectedSavedCard.id
                    ? undefined
                    : '#cccccc'
                }
                indicatorColor={'#fff'}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

export default (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);
