import React from 'react';
import TabNavigator from '../../app-components/tab/TabNavigator';
import OrganizationLists from './OrganizationLists';
import {getUser} from '../../AppServices';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {organisationNameFilter} from '../../app-components/filter/MayaMDFilters';

export default TabNavigator({
  tabs: {
    tab1: {
      actions: [
        {
          visible: () => {
            let {userType} = getUser();
            console.log('((((((((((((((((((((((((((((', userType);
            return userType === 'SuperAdmin' ? true : false;
          },
          render: organisationNameFilter,
        },
      ],
      label: 'Organizations',
      // reloadEvent: 'reloadSpeciality',
      screen: OrganizationLists,
    },
  },
});
