
import {monthLabels} from '../../../../Lib/constants';
import ApexCharts from "apexcharts";
import jsPDF from 'jspdf';
import { chartPDFFont } from './fontAvenirNextDemiBold';

export const loaderTheme = {
  containerStyle: {
    overflow: void 0,
    position: 'relative',
    left: 0,
    top: '50%',
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 11,
  },
  animationStyle: {
    width: 40,
    height: 40,
    borderWidth: 4,
    borderColor: 'green',
    borderRightColor: 'transparent',
    borderRadius: '50%',
    animationName: 'spinnerRotate',
    animationDuration: '0.75s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
};
export const noData = {
  text: 'No Data Found!',
  align: 'center',
  verticalAlign: 'middle',
  offsetX: 0,
  offsetY: 0,
  style: {
    color: undefined,
    fontSize: '14px',
    fontFamily: undefined,
  },
};

export let barChartOptions = {
  chart: {
    type: 'bar',
    height: 350,
    toolbar: {
      show: false,
    },
  },
  noData: noData,
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '84%',
      endingShape: 'rounded',
      dataLabels: {
        orientation: 'vertical',
        position: 'center', // bottom/center/top
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    categories: monthLabels,
  },
  yaxis: {
    // title: {
    //   text: ' (Members)',
    // },
  },
  fill: {
    opacity: 1,
  },

  tooltip: {
    y: {
      // formatter: function (val) {
      //   return +val + ' Members';
      // },
    },
  },
};
export let barChartDataLabels = {
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val > 0 ? Number(val).toLocaleString() : '';
    },
    offsetX: 0,
    offsetY: 0,
    horizontal: false,
    style: {
      fontFamily: 'system-ui',
      fontWeight: 400,
      fontSize: '10px',
      colors: ['black'],
    },
  },
};

export let radialBarOptions = {
  chart: {
    type: 'radialBar',
    offsetY: 60,
    toolbar: {
      show: false,
    },
  },
  colors: ["#b39ddb", "#03a9f4", "#00bcd4"],
  plotOptions: {
    radialBar: {
      size: undefined,
      inverseOrder: true,
      hollow: {
        margin: 5,
        size: '48%',
      },
      track: {
        background: '#e6e6e6',
        show: true,
        margin: 1 // margin is in pixels
      }
    }
  },
  labels: ['Female','Male','Individual'],
};
export let familyBarPercentage = {
  chart: {
    type: "bar",
    height: '0px',
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false,
    }
  },
  plotOptions: {
    bar: {
      horizontal: true
    }
  },
  xaxis: {
    categories: [""]
  },
  legend: {
    show: true,
    position: "bottom",
      formatter: function (val, opts) {
      return val + " - " + opts.w.globals.series[opts.seriesIndex];
    }
  },
};


export const getImageDimension = (imageURI) => new Promise((resolve, reject) => {
  let i = new Image();
  let val = {}
  i.onload = function () {
    val = { width: i.width * 0.75, height: i.height * 0.75 };
    resolve(val)
  };
  i.src = imageURI;
})

export const  getDataUri = async (chartId,chartTitle="") => {
  try {
    const result = await ApexCharts.exec(chartId, "dataURI")
    const dimension = await getImageDimension(result.imgURI)
    let pdf={};
    if(dimension.width >840){
      dimension.height= dimension.height/dimension.width*840;
      dimension.width=840;
   
    }
    pdf = new jsPDF({ orientation: 'l',unit: 'pt',
     format: [dimension.width + 10, dimension.height + 40]
    });
    pdf.addImage(result.imgURI, 'PNG', 0, 30,dimension.width,dimension.height);
    pdf.addFileToVFS("AvenirNext-DemiBold.ttf", chartPDFFont);
    pdf.addFont("AvenirNext-DemiBold.ttf", "AvenirNext-DemiBold", "normal");
    pdf.setFont("AvenirNext-DemiBold");
    pdf.setFontSize(12);
    pdf.text(String(chartTitle), (dimension.width/2)+30, 20,{ align: 'center' })
    pdf.save("download.pdf");
  }
  catch (error) {
    console.log(error)
  }
}
