import advertisementForm from './advertisementForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Advertisement',
  actions: [cancelButton(), save()],
};

let uri = ({navigation}) => {
  return {
    query: {
      id: 'organizationAdList',
      relationValue: {
        model: 'OrganizationAdvertisement',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'OrganizationAdvertisement',
  };
};
export default advertisementForm({uri, header});
