import React from 'react';

export const getInputProps = props => {
  let {
    value,
    placeholder,
    getRef,
    inputStyle,
    disabled,
    editable,
    onFocus,
    onBlur,
    onChange,
    onChangeValue,
    onChangeText,
    onSubmitEditing,
    inputProps: propInputProps,
    maxRows,
    renderSelector,
  } = props;
  let inputProps = {
    style: inputStyle,
    value,
    placeholder,
    getRef,
    onFocus,
    onBlur,
    onChange,
    onChangeValue,
    onChangeText,
    onSubmitEditing,
    renderSelector,
    ...propInputProps,
  };
  if (disabled !== undefined) {
    inputProps.disabled = disabled;
  }
  if (editable !== undefined) {
    inputProps.editable = editable;
  }
  if (maxRows !== undefined) {
    inputProps.maxRows = maxRows;
  }
  return inputProps;
};

export const getResolvedStyle = props => {
  let {
    isActive,
    hover,
    error,
    disabled,
    style,
    disabledStyle,
    activeStyle,
    hoverStyle,
    errorStyle,
  } = props;
  let resolvedStyle = void 0;
  if (disabled) {
    resolvedStyle = disabledStyle;
  } else if (error && errorStyle) {
    resolvedStyle = errorStyle;
  } else if (isActive && activeStyle) {
    resolvedStyle = activeStyle;
  } else if (hover && hoverStyle) {
    resolvedStyle = hoverStyle;
  }
  if (Array.isArray(style)) {
    style = [...style, resolvedStyle];
  } else {
    style = {...style, ...resolvedStyle};
  }
  return style;
};

export const getRenderComponent = (renderComponent, props) => {
  if (!renderComponent) {
    return;
  }
  if (React.isValidElement(renderComponent)) {
    return React.cloneElement(renderComponent, {...props});
  } else if (typeof renderComponent === 'function') {
    let RenderComponent = renderComponent;
    if (
      RenderComponent.prototype &&
      RenderComponent.prototype.isReactComponent
    ) {
      return <RenderComponent {...props} />;
    } else {
      return RenderComponent({...props});
    }
  }
};
