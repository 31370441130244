import React from 'react';
import {cancel} from '../../app-components/action/CancelAction';
import {save} from '../../app-components/action/SaveAction';
import SignupFormHoc from './SignupFormHoc';
import CustomHeaderLabel from '../../app-components/CustomHeaderLabel';
import {getUser} from '../../AppServices';
import {isMobile} from '../../app-components';
const header = {
  hideBackIcon: true,
  title: ({navigation}) => {
    let {state: {params: {item} = {}} = {}} = navigation;
    const {organization} = getUser();
    if (!item) {
      item = organization;
    }
    return (
      <CustomHeaderLabel
        seperator={true}
        preLabel={'Add Patient'}
        postLabel={item?.organization_name || 'N/A'}
      />
    );
  },
  actions: isMobile ? [] : [cancel(), save()],
};

export default SignupFormHoc({header});
