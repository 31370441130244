import React from 'react';
import {View, Text, Image, isMobile} from '../app-components';
import {getUser, getProfileUrl, getImageStyle} from '../AppServices';
import {HeaderNotification, searchFilter, userIcon, translate} from '../images';
import {h16_Regular, h30_Regular} from '../theme/fonts';
import {colors} from '../theme/colors';
import {WithModal} from '../npms/react-with-modal';
import {Avatar} from '../npms/react-avatar';
import LanguageChange from './LanguageChange';
import ProfileDropdown from './ProfileDropdown';
import {useTranslation} from 'react-i18next';
import {resolvePatientName} from '../app-components/patientUtility';
const {themeColor} = colors;

const Header = (props) => {
  const {t, i18n} = useTranslation();
  const userData = getUser() || {};
  const {patient = {}, photo} = userData || {};
  let firstName = resolvePatientName(patient);
  document.body.dir = i18n.dir();
  return (
    <View
      style={{
        flex: 1,
        maxHeight: isMobile ? 40 : 50,
        minHeight: isMobile ? 40 : 50,
        flexDirection: 'row',
        justifyContent: isMobile ? 'flex-end' : 'space-between',
        alignItems: 'center',
      }}>
      {isMobile ? (
        void 0
      ) : (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={{...h30_Regular, color: '#fff', opacity: 0.75}}>
            {t('Welcome')},&nbsp;
          </Text>
          <Text
            style={{
              ...h30_Regular,
              color: '#fff',
              fontWeight: 800,
              maxWidth: 400,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {firstName}
          </Text>
        </View>
      )}

      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <WithModal
          autoHide={true}
          dropdownStyle={{
            width: 135,
            height: 120,
          }}
          renderModal={({frameStyle, hideModal}) => {
            return (
              <LanguageChange
                frameStyle={frameStyle}
                hideModal={hideModal}
                {...props}
              />
            );
          }}>
          <View
            style={{
              flexDirection: 'row',
              position: 'relative',
              overflow: 'hidden',
              // alignItems: 'center/',
              cursor: 'pointer',
              height: 35,
              width: isMobile ? 40 : 50,
              alignItems: 'center',
              // borderRadius: 25,
            }}>
            <Image
              source={translate}
              style={{
                width: isMobile ? 25 : 30,
                height: isMobile ? 25 : 30,
                objectFit: 'contain',
              }}
            />
          </View>
        </WithModal>

        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Image
            source={searchFilter}
            style={{marginRight: 10, marginLeft: 10, height: 15, width: 15}}
          />
          {/* <ClassRoomNotification {...props} /> */}
          <Image
            source={HeaderNotification}
            style={{marginRight: 10, marginLeft: 10}}
          />
        </View>

        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text
            style={{
              ...h16_Regular,
              fontWeight: 700,
              color: '#fff',
              marginLeft: 15,
              marginRight: 15,
              maxWidth: 160,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {firstName}
          </Text>

          <WithModal
            autoHide={true}
            dropdownStyle={{
              width: 100,
              height: 145,
            }}
            renderModal={({frameStyle, hideModal}) => {
              return (
                <ProfileDropdown
                  frameStyle={frameStyle}
                  hideModal={hideModal}
                  {...props}
                />
              );
            }}>
            <View
              style={{
                flexDirection: 'row',
                position: 'relative',
                overflow: 'hidden',
                // alignItems: 'center/',
                cursor: 'pointer',
                height: isMobile ? 35 : 50,
                width: isMobile ? 35 : 50,
                borderRadius: 25,
              }}>
              {photo ? (
                <Image
                  source={getProfileUrl(photo.thumbnail)}
                  // height={150}
                  // width={150}
                  style={getImageStyle({imageType: photo.imageType})}
                />
              ) : (
                <Avatar icon={userIcon} />
              )}
              {/* <View style={{paddingLeft: 5, overflow: 'hidden', maxWidth: 70}}>
                <Text numberOfLines={1} style={{...headerTextStyle}}>
                  {user?.name || 'NA'}
                </Text>
              </View> */}
            </View>
          </WithModal>
        </View>
      </View>
    </View>
  );
};

export default Header;
