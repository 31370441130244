import React, {Component} from 'react';
import {AutoSuggestInput} from './input-components/Editors';
import moment from 'moment'
class TimeSelector extends Component {
  render() {
    let {field, setValue, data, filterField, ...restProps} = this.props;
    let hours = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ];
    let minutes = ['00', '15', '30', '45'];
    let time = [];
    hours.map((item) => {
      minutes.map((totalTime) => {
        time.push({
          label: moment(item + ':' + totalTime, ['HH:mm']).format('h:mm A'),
          [field]: item + ':' + totalTime,
        });
      });
    });
    let filteredArray;
    if (filterField) {
      filteredArray = time.filter((item) => {
        if (item[field] >= data[filterField]) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (!filteredArray) {
      filteredArray = time;
    }
    return (
      <AutoSuggestInput
        // value={data[field]}
        value={
          data[field]
            ? moment(data[field], ['HH:mm']).format('h:mm A')
            : data[field]
        }
        data={data}   
        options={filteredArray}
        keyField={field}
        valueField={field}
        sugestionField={field}
        showArrow={true}
        renderSelector={true}
        variant={'filled'}
        renderRowProps={{
          primaryField: 'label',
        }}
        onItemSelect={({item}) => {
          setValue({
            scheduleData: data,
            field,
            value: item,
          });
        }}
        {...restProps}
      />
    );
  }
}

export default TimeSelector;
