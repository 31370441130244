import { autoSuggestInput } from '../../autoSuggestions';
import Form from '../../app-components/form/Form';
import { submit } from '../../AppServices';
const libPhoneNumber = require('google-libphonenumber');
const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

export default ({ header, editable, closeView }) => {
  return Form({
    reloadEvent: 'reloadPatient',
    reloadOnChangeEvent: 'reloadPatient',
    expandSingleFormgroup: true,
    type: 'standardShadow',
    closeView: closeView,
    fieldVariant: 'filled',
    validateOnChange: true,
    onSubmit: submit({
      model: 'User',
      recordActivity: true,
    }),
    beforeSubmit:({updates}) => {
        return {
                 updates:{
                    ...updates,
                    email: updates?.email?.toLowerCase()
                 }
        }
    },
    mandatory: {
      mobile: 'Phone No. is mandatory.',
      password:'Password is mandatory.',
      userType:'Role is mandatory.'
    },
    defaultValues:() => {
  return {
      isActive:true,
      mobileVerified:true
  }
    },
    validations: {
      email: ({ data }) => {
        let { email } = data;
        if (!email) {
          return;
        }
        const value = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/i.test(email);
        if (!value) {
          return 'Invalid Email';
        }
      },
      name: ({ data }) => {
        const { name = '' } = data || {};
        const value = /^[a-zA-Z\s]*$/.test(name);
        if (!value) {
          return 'Invalid Name';
        }
      },
      mobile: ({ data }) => {
        const { mobile = '' } = data || {};
        if (!mobile) {
          return;
        }
        try {
          let result = phoneUtil.isValidNumber(phoneUtil.parse(mobile));
          if (!result) {
            return 'The mobile number you entered appears to be invalid';
          }
        } catch (e) {
          return 'Please enter mobile number';
        }
      },
      password:({data}) => {
          const {password} = data;
          if(password?.trim()?.length < 6){
          return 'Password must have 6 characters.'
          }
      }
    },
    header: header,
    formGroups: [
      {
        editable: editable,
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'text',
                label: 'Name',
                field: 'name',
              },
              {
                type: 'mobile',
                label: 'Phone Number',
                field: 'mobile',
                mandatory:true
              },
              {
                type: 'text',
                label: 'Email',
                field: 'email',
              },
              {
                type: 'text',
                label: 'Password',
                field: 'password',
                mandatory:true
            },
              autoSuggestInput({
                label: 'Role',
                placeholder: 'Select Role',
                mandatory:true,
                options:[
                    {name:'ANM',value:'ANM'},
                    {name:'MO',value:'MO'},
                    {name:'LT',value:'LT'},
                    {name:'Doctor',value:'Doctor'},
                    {name: 'Admin', value:'Admin'}
                ],
                valueField: 'name',
                suggestionField: 'name',
                field:'userType'
              }),
            ],
          },
        ],
      },
    ],
  });
};
