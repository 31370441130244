import React from 'react';
import {View} from '../../react-core-components';
import uuid from 'uuid/v4';

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

const getSelectedTabFromProps = (props) => {
  let {selectedTab, tabs, navigation} = props;
  let selectedTabParams, selectedTabAction;
  if (navigation && navigation.action) {
    let action = navigation.action;
    if (typeof action === 'string') {
      action = {view: action};
    } else {
      action = {...action};
    }
    selectedTab = action.view;
    selectedTabAction = action.action;
    selectedTabParams = action.params;
  }
  if (!selectedTab) {
    selectedTab = Object.keys(tabs)[0];
  }
  return {
    selectedTab,
    selectedTabAction,
    selectedTabParams,
  };
};
class TabPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    let {tabs, tabChangeFromProps} = props;
    let {
      selectedTab,
      selectedTabAction,
      selectedTabParams,
    } = getSelectedTabFromProps(props);
    let tabState = {};
    let tabNavigations = {};
    let tabStateSetters = {};
    for (let key in tabs) {
      let navigationProps = {tabKey: key};
      if (key === selectedTab) {
        navigationProps.action = selectedTabAction;
        navigationProps.params = selectedTabParams;
      }
      tabState[key] = {uid: `tab_${key}_${uuid()}`};
      tabNavigations[key] = this.getNavigation(navigationProps);
      tabStateSetters[key] = this.setTabState(key);
    }
    this.state = {tabState};
    if (!tabChangeFromProps) {
      this.state.selectedTab = selectedTab;
    }
    this.tabNavigations = tabNavigations;
    this.tabStateSetters = tabStateSetters;
  }
  _getState = () => {
    return this.state;
  };
  _setState = (state) => {
    return this.setState(state);
  };
  getNavigation = ({params, action, tabKey} = {}) => {
    const parentNavigation = this.props.navigation;
    let tabNavigationParams = {...params};
    if (
      parentNavigation &&
      parentNavigation.state &&
      parentNavigation.state.params
    ) {
      tabNavigationParams = {
        ...parentNavigation.state.params,
        ...tabNavigationParams,
      };
    }
    let navigation = {state: {params: tabNavigationParams}, action};

    navigation.getSelectedTab = () => {
      return tabKey;
    };
    navigation.getRoutePath = () => {
      let path = ':' + tabKey;

      if (parentNavigation && parentNavigation.getRoutePath) {
        let parentPath = parentNavigation.getRoutePath();
        if (parentPath) {
          path = parentPath + path;
        }
      }
      return path;
    };
    navigation.getParam = (key) => {
      return navigation.state.params[key];
    };
    navigation.getScreenName = () => {
      let screenName = '';
      if (this.props.navigation) {
        screenName = this.props.navigation.getScreenName() + '_';
      }
      screenName += tabKey;
      return screenName;
    };

    navigation.getTabScreenState = () => {
      return this._getState();
    };

    navigation.setTabScreenState = (_state) => {
      return this._setState(_state);
    };

    navigation.getScreenState = () => {
      let {tabState = {}} = this.state;
      let tabScreenState = tabState[tabKey] || {};
      return tabScreenState;
    };

    navigation.setScreenState = (state) => {
      let setState = this.tabStateSetters[tabKey];
      setState(state);
    };

    navigation.navigate = this.navigate;
    if (parentNavigation) {
      //parent navigation
      navigation._parent = this._parentNavigation;
      const {
        state,
        _parent,
        navigate,
        getParam,
        getScreenName,
        action,
        getRoutePath,
        getTabScreenState,
        setTabScreenState,
        getScreenState,
        setScreenState,
        ...restNavigation
      } = parentNavigation;
      for (let key in restNavigation) {
        navigation[key] = restNavigation[key];
      }
    }
    return navigation;
  };

  tabStateSetters = {};

  getSelectedTab = () => {
    let props = this.props;
    let {tabChangeFromProps} = props;
    if (tabChangeFromProps) {
      let {selectedTab} = getSelectedTabFromProps(props);
      return selectedTab;
    } else {
      return this.state.selectedTab;
    }
  };
  setTabState = (tabKey) => {
    return (_state) => {
      this.setState((state) => {
        let tabState = state.tabState;
        const tabStateForGivenTab = tabState[tabKey];
        if (typeof _state === 'function') {
          _state = _state(tabStateForGivenTab);
        }
        tabState = {
          ...tabState,
          [tabKey]: {...tabStateForGivenTab, ..._state},
        };
        return {
          tabState,
        };
      });
    };
  };
  selectTab = ({selectedTab, params, action}) => {
    let {tabChangeFromProps} = this.props;
    let currentSelectedTab = this.getSelectedTab();
    this.tabNavigations[selectedTab] = this.getNavigation({
      params,
      action,
      tabKey: selectedTab,
    });
    let proceed =
      this.props.onTabChange &&
      this.props.onTabChange({
        selectedTab,
        currentSelectedTab: currentSelectedTab,
        params,
        action,
        navigation: this.tabNavigations[selectedTab],
      });
    if (proceed === false) {
      return;
    }
    if (!tabChangeFromProps) {
      this.setState({selectedTab});
    }
  };
  navigate = (view, params, action) => {
    if (typeof view === 'string') {
      view = {view: view};
    }
    if (params) {
      view = {...view, params};
    }
    if (action) {
      view = {...view, action};
    }
    let selectedTab = view.view;
    const {tabs, navigation} = this.props;
    if (tabs && tabs[selectedTab]) {
      params = view.params;
      action = view.action;

      this.selectTab({selectedTab, params, action});
    } else if (navigation) {
      navigation.navigate(view);
    }
  };

  _parentNavigation = () => {
    return this.props.navigation;
  };

  getTabBar = ({tabs, selectedTab}) => {
    let {renderItem, renderTabBar, eventDispatcher} = this.props;
    let selectedTabNavigation = this.tabNavigations
      ? this.tabNavigations[selectedTab]
      : void 0;
    let selectedTabState = selectedTabNavigation.getScreenState();
    let {uid} = selectedTabState || {};
    if (renderTabBar) {
      let tabBarProps = {
        navigation: selectedTabNavigation,
        eventDispatcher,
        uid,
        tabs,
        selectedTab,
        state: selectedTabState,
        setState: this.tabStateSetters
          ? this.tabStateSetters[selectedTab]
          : void 0,
        screenState: selectedTabState,
        setScreenState: selectedTabNavigation.setScreenState,
      };
      if (typeof renderTabBar === 'function') {
        renderTabBar = renderTabBar(tabBarProps);
      } else if (React.isValidElement(renderTabBar)) {
        renderTabBar = React.cloneElement(renderTabBar, tabBarProps);
      }
      return renderTabBar;
    } else {
      let tabComponents = Object.keys(tabs).map((tab) => {
        let {navigationOptions, label} = tabs[tab];
        let render = renderItem;
        if (navigationOptions) {
          if (typeof navigationOptions === 'function') {
            navigationOptions = navigationOptions({
              navigation: selectedTabNavigation,
              selectedTab,
            });
          }
          if (navigationOptions && navigationOptions.renderTab) {
            render = navigationOptions.renderTab;
          }
        }
        if (!render) {
          return null;
        }
        let renderProps = {
          label,
          tab,
          selected: tab === selectedTab,
          selectedTab,
          selectTab: this.selectTab,
        };
        if (typeof render === 'function') {
          render = render(renderProps);
        } else if (React.isValidElement(render)) {
          render = React.cloneElement(render, renderProps);
        }
        return render;
      });
      return (
        <View style={{flexDirection: 'row', overflow: 'hidden'}}>
          {tabComponents}
        </View>
      );
    }
  };

  componentDidUpdate(oldProps) {
    const {navigation} = this.props;
    if (
      oldProps &&
      oldProps.navigation &&
      navigation &&
      oldProps.navigation !== navigation
    ) {
      let {tabs} = this.props;
      let selectedTab = this.getSelectedTab();
      let selectedTabParams, selectedTabAction;
      if (navigation && navigation.action) {
        let action = navigation.action;
        if (typeof action === 'string') {
          action = {view: action};
        } else {
          action = {...action};
        }

        selectedTabAction = action.action;
        selectedTabParams = action.params;
      }
      let tabNavigations = {};
      for (let key in tabs) {
        let navigationProps = {tabKey: key};
        if (key === selectedTab) {
          navigationProps.action = selectedTabAction;
          navigationProps.params = selectedTabParams;
        }
        tabNavigations[key] = this.getNavigation(navigationProps);
      }

      this.tabNavigations = tabNavigations;
      this.setState({toggled: !this.state.toggled});
    }
  }

  renderTabRow = ({tabs, selectedTab}) => {
    let {
      eventDispatcher,
      tabRowContainerStyle,
      tabRowStyle,
      tabRowBorderStyle,
      renderLeftAction,
      renderActions,
      leftActions,
      actions,
    } = this.props;
    let tabBarComponent = this.getTabBar({tabs, selectedTab});
    let tabRowComponent = tabBarComponent;
    if (renderLeftAction || renderActions) {
      let selectedTabInfo = tabs[selectedTab];
      let {
        actions: selectedTabActions,
        leftActions: selectedTabLeftActions,
      } = selectedTabInfo;
      if (selectedTabActions) {
        actions = actions
          ? [...selectedTabActions, ...actions]
          : selectedTabActions;
      }
      if (selectedTabLeftActions) {
        leftActions = leftActions
          ? [...selectedTabLeftActions, ...leftActions]
          : selectedTabLeftActions;
      }
      let selectedTabNavigation = this.tabNavigations[selectedTab];
      let selectedTabState = selectedTabNavigation.getScreenState();
      let {uid} = selectedTabState;
      let actionProps = {
        uid,
        navigation: selectedTabNavigation,
        selectedTab,
        eventDispatcher,
        screenState: selectedTabState,
        setScreenState: selectedTabNavigation.setScreenState,
      };
      if ((leftActions && leftActions.length) || (actions && actions.length)) {
        tabRowComponent = (
          <View style={{flex: 1, flexDirection: 'row'}}>
            {leftActions && leftActions.length
              ? getRenderComponent(renderLeftAction, {
                  ...actionProps,
                  actions: leftActions,
                })
              : void 0}
            <View style={{flex: 1, overflow: 'hidden'}}>{tabRowComponent}</View>
            {actions && actions.length
              ? getRenderComponent(renderActions, {
                  ...actionProps,
                  actions,
                })
              : void 0}
          </View>
        );
      }
    }
    return (
      <View style={tabRowContainerStyle}>
        <View style={tabRowStyle}>{tabRowComponent}</View>
        {tabRowBorderStyle && <View style={tabRowBorderStyle} />}
      </View>
    );
  };

  render() {
    let {
      containerStyle,
      tabs,
      screenProps,
      renderHeader,
      direction,
      eventDispatcher,
      currentViewIndex,
      lastViewIndex,
      animation,
      ScreenComponent,
    } = this.props;

    let selectedTab = this.getSelectedTab();

    const {tabState} = this.state;
    let selectedTabInfo = tabs[selectedTab];
    let {
      screen: Component,
      header,
      renderHeader: tabRenderHeader,
      screenProps: tabScreenProps,
    } = selectedTabInfo;
    if (tabRenderHeader) {
      renderHeader = tabRenderHeader;
    }
    let selectedTabNavigation = this.tabNavigations[selectedTab];
    let selectedTabState = selectedTabNavigation.getScreenState();
    let {uid} = selectedTabState;

    if (Component) {
      let componentProps = {
        key: uid,
        uid,
        navigation: selectedTabNavigation,
        eventDispatcher,
        screenState: selectedTabState,
        setScreenState: selectedTabNavigation.setScreenState,
        state: selectedTabState,
        setState: selectedTabNavigation.setScreenState,
        currentViewIndex,
        lastViewIndex,
        ...screenProps,
        ...tabScreenProps,
      };
      if (animation !== void 0) {
        componentProps.animation = animation;
      }
      if (ScreenComponent) {
        Component = <ScreenComponent {...componentProps} screen={Component} />;
      } else {
        Component = <Component {...componentProps} />;
      }
    }

    let headerToolBarComponent = getRenderComponent(renderHeader, {
      header,
      navigation: selectedTabNavigation,
      eventDispatcher,
      uid,
      screenState: selectedTabState,
      setScreenState: selectedTabNavigation.setScreenState,
      state: tabState[selectedTab],
      setState: this.tabStateSetters[selectedTab],
    });
    let tabRowComponent = this.renderTabRow({tabs, selectedTab});
    containerStyle = {
      flex: 1,
      overflow: 'hidden',
      ...containerStyle,
    };
    let tabPanelComponent = null;
    if (direction === 'bottom') {
      tabPanelComponent = (
        <View style={containerStyle}>
          {headerToolBarComponent}
          {Component}
          {tabRowComponent}
          {this.props.renderAbsolute &&
            this.props.renderAbsolute({
              navigation: selectedTabNavigation,
              eventDispatcher,
            })}
        </View>
      );
    } else {
      tabPanelComponent = (
        <View style={containerStyle}>
          {headerToolBarComponent}
          {tabRowComponent}
          {Component}
        </View>
      );
    }
    return tabPanelComponent;
  }
}
export default TabPanel;
