import React from 'react';

export default Component => {
  class WithAction extends React.Component {
    render() {
      let {action, actions, disabled, ...restProps} = this.props;
      let renderComponent = <Component {...restProps} />;
      let {style, Action, MultiActions, ...restActionProps} = restProps;

      if (action && Array.isArray(action)) {
        actions = action;
        action = void 0;
      }
      if (action && Action) {
        if (typeof action === 'function') {
          action = action(restProps);
        }
        if (action) {
          renderComponent = (
            <Action action={action} {...restActionProps} disabled={disabled}>
              {renderComponent}
            </Action>
          );
        }
      } else if (MultiActions && actions) {
        if (typeof actions === 'function') {
          actions = actions(restActionProps);
        }
        if (actions && actions.length) {
          renderComponent = (
            <MultiActions
              actions={actions}
              Action={Action}
              {...restActionProps}>
              {renderComponent}
            </MultiActions>
          );
        }
      }
      return renderComponent;
    }
  }
  return WithAction;
};
