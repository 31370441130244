import React, {useEffect, useState, useMemo} from 'react';
import {
  View,
  ScrollView,
  TouchableOpacity,
  Text,
} from '../../../app-components';
import LineChart from '../charts/LineChart';
import {invoke, post} from '../../../AppServices';
import {
  resolvePatientMrn,
  resolvePatientName,
  resolvePatientEmail,
  resolvePatientMobile,
} from '../../../app-components/patientUtility';
import Form from '../../../app-components/form/Form';
import TabNavigator from '../../../app-components/tab/TabNavigator';
import {isEmpty, startCase, isNumber} from 'lodash';
import moment from 'moment';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import Timer from '../../GenericComponent/Timer';
import {isTimerVisible} from '../rpmUtils';
import PatientDetails from '../PatientDetails';
import ActivityLog from './ActivityLog';
import { h13_Regular } from '../../../theme/fonts';
const tenoviDevicesNameMap = {
  'Blood Pressure': 'blood_pressure',
  Pulse: 'pulse',
  'Blood glucose level': 'glucose',
  Weight: 'weight',
  'Oxygen Saturation': 'spO2',
};

const monthMap = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'July',
  7: 'Aug',
  8: 'Sept',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};
const weekMap = {
  1: 'Week 1',
  2: 'Week 2',
  3: 'Week 3',
  4: 'Week 4',
  5: 'Week 5',
};
const dayMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const PatientData = Form({
  closeView: 1,
  fieldVariant: 'filled',
  fieldContainer: 'topLabel',
  defaultValues: ({navigation}) => {
    const {state: {params: {item = {}} = {}} = {}} = navigation;
    return {
      ...item,
    };
  },
  formGroups: [
    {
      label: ({data}) => {
        console.log(`>@>Puru ~ file: DataAnalysis.js ~ line 70 ~ data`, data);
        return 'Patient Details';
      },
      expandable: true,
      expanded: false,
      groups: [
        {
          columnsPerRow: 3,
          columns: [
            {
              type: 'text',
              label: 'Name',
              editable: false,
              field: (data) => resolvePatientName(data),
            },
            {
              type: 'text',
              label: 'Mobile',
              editable: false,
              field: (data) => resolvePatientMobile(data),
            },
            {
              type: 'text',
              label: 'MRN',
              editable: false,
              field: (data) => resolvePatientMrn(data),
            },
            {
              type: 'text',
              label: 'Email',
              editable: false,
              field: (data) => resolvePatientEmail(data),
            },
            {
              type: 'text',
              label: 'Gender',
              editable: false,
              field: (data) => startCase(data?.gender || ''),
            },
            {
              type: 'text',
              label: 'Date of Birth',
              editable: false,
              field: (data) => moment(data.birthDate).format('DD MMM YYYY'),
            },
            {
              type: 'text',
              label: 'Monitoring parameters',
              editable: false,
              field: (data) => {
                const {careProgram = []} = data || {};
                let monitoringParameter = [];
                for (let val of careProgram) {
                  monitoringParameter = [
                    ...monitoringParameter,
                    ...(val?.monitoringParameter || []),
                  ];
                }
                const name = monitoringParameter.map(
                  (item) => item?.parameter?.name || '',
                );
                return name.join(', ');
              },
            },
            {
              type: 'text',
              label: 'Chronic diseases',
              editable: false,
              field: (data) => {
                const {careProgram = []} = data || {};
                let chronicDisease = [];
                for (let val of careProgram) {
                  chronicDisease = [
                    ...chronicDisease,
                    ...(val?.chronicDisease || []),
                  ];
                }
                const name = chronicDisease.map((item) => item?.name || '');
                return name.join(', ');
              },
            },
            {
              type: 'text',
              label: 'Care Program',
              editable: false,
              field: (data) => {
                const {careProgram = []} = data || {};
                const name = careProgram.map(
                  (item) => item?.careProgram?.name || '',
                );
                return name.join(', ');
              },
            },
          ],
        },
      ],
    },
  ],
});

const resolveMonitoringParameter = (item) => {
  const {careProgram = []} = item || {};
  let monitoringParameter = [];
  for (let val of careProgram) {
    monitoringParameter = [
      ...monitoringParameter,
      ...(val?.monitoringParameter || []),
    ];
  }

  return monitoringParameter.map((monitoringParameterItem) => {
    const {
      parameter: {name = ''} = {},
      higherThreshold = 0,
      lowerThreshold = 0,
      rateOfChange = 0,
      // diastolicHigherThreshold = 0,
      diastolicLowerThreshold = 0,
      systolicHigherThreshold = 0,
      // systolicLowerThreshold = 0,
      flag = 'green',
      _id = 'n/a',
      device = [],
    } = monitoringParameterItem || {};
    return {
      name,
      higherThreshold,
      lowerThreshold,
      rateOfChange,
      bloodPressureUpperThreshold: systolicHigherThreshold,
      bloodPressureLowerThreshold: diastolicLowerThreshold,
      flag,
      _id,
      deviceId: Array.isArray(device) && device.length ? device?.[0]?._id : '',
    };
  });
};

const Analysis = (screenProps) => {
  const [data, setGraphData] = useState([]);
  const {
    higherThreshold = 0,
    lowerThreshold = 0,
    rateOfChange = 0,
    timeFilter,
    bloodPressureUpperThreshold = 0,
    bloodPressureLowerThreshold = 0,
    item,
    tabName,
    deviceId = '',
  } = screenProps || {};
  const {filterOf, from, to} = timeFilter || {};
  const getGraphData = async () => {
    let paramValue = {
      data: {
        patientId: item?._id,
        deviceId,
        filterOf,
        metric: tenoviDevicesNameMap[tabName] || '',
      },
      period: {
        from,
        to,
      },
    };

    try {
      const responseGraph = await invoke({id: 'getDeviceData', paramValue});
      const filterData = responseGraph[filterOf.toLowerCase()];
      const modifyGraph = Array.isArray(filterData)
        ? filterData.map((filterDataItem) =>
            modifyGraphDataItem(filterDataItem, filterOf),
          )
        : [];
      setGraphData(() => {
        return modifyGraph.sort((a, b) => a.index - b.index);
      });
    } catch (err) {
      console.log(`>@>~ file: DataAnalysis.js ~ line 175 ~ err`, err);
    }
  };
  useEffect(() => {
    if (to && from && deviceId) {
      getGraphData();
    }
  }, [timeFilter, deviceId]);
  return (
    <View style={{minHeight: 50}}>
      <LineChart
        bgColor="#Fffafa"
        dataKey="value1"
        dataKey2={
          data.findIndex((dataItem) => dataItem?.value2) === -1
            ? null
            : 'value2'
        }
        xAxisDataKey={`${filterOf}_new`}
        data={data}
        tabName={tabName}
        lowerThreshold={lowerThreshold}
        rateOfChange={rateOfChange}
        higherThreshold={higherThreshold}
        bloodPressureLowerThreshold={bloodPressureLowerThreshold}
        bloodPressureUpperThreshold={bloodPressureUpperThreshold}
      />
    </View>
  );
};

const modifyGraphDataItem = (filterDataItem, filterOf) => {
  const {
    _id: {month = 0, week = 0, day = 0},
    averageValue1,
    averageValue2,
  } = filterDataItem || {};
  let value = '',
    index;
  if (filterOf === 'Year') {
    value = monthMap[month];
    index = month;
  }
  if (filterOf === 'Month') {
    value = weekMap[week];
    index = week;
  }
  if (filterOf == 'Week') {
    value = dayMap[day];
    index = day;
  }
  return {
    ...(isNumber(averageValue1)
      ? {value1: Number(averageValue1.toFixed(2))}
      : {}),
    ...(isNumber(averageValue2) && averageValue2
      ? {value2: Number(averageValue2.toFixed(2))}
      : {}),
    // value1: isNumber(averageValue1) ? averageValue1.toFixed(2) : 0,
    // value2: isNumber(averageValue2) ? averageValue2.toFixed(2) : 0,
    // ...filterDataItem,
    [`${filterOf}_new`]: value,
    index,
  };
};

const makeTabs = ({
  nameMonitoringParameter,
  timeFilter,
  data,
  item,
  attendedParams,
  eventDispatcher,
  navigation,
}) => {
  let tempTabsData = {};
  for (let tabs of nameMonitoringParameter) {
    tempTabsData = {
      ...tempTabsData,
      [tabs?.name || 'N/A']: {
        label: tabs?.name || '',
        screen: Analysis,
        screenProps: {
          ...tabs,
          data,
          timeFilter,
          item,
          tabName: tabs?.name || '',
        },
        actions: [
          {
            render: (props) => {
              const {attended, setAttended} = attendedParams || {};
              if (attended) {
                return [];
              }
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.push({
                      view: 'addComment',
                      params: {
                        item,
                        setAttended,
                        attended,
                        isAttended: true,
                        eventDispatcher,
                      },
                      modal: true,
                      modalProps: {
                        autoHide: true,
                        screenCenterStyle: {
                          borderRadius: 10,
                        },
                        style: {},
                      },
                    });
                  }}>
                  <GradientButton minWidth={200} label={'Mark Attended'} />
                </TouchableOpacity>
              );
            },
          },
          {
            render: (props) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.push({
                      view: 'addComment',
                      params: {item},
                      modal: true,
                      modalProps: {
                        autoHide: true,
                        screenCenterStyle: {
                          borderRadius: 10,
                        },
                        style: {},
                      },
                    });
                  }}>
                  <GradientButton minWidth={200} label={'Add Comment'} />
                </TouchableOpacity>
              );
            },
          },
        ],
      },
    };
  }
  return tempTabsData;
};

const DataAnalysis = (props) => {
  const {
    screenState: {
      dataParams: {filters: {period: {value: timeFilter} = {}} = {}} = {},
    } = {},
    eventDispatcher = {},
    navigation,
  } = props;
  const {
    navigation: {state: {params: {item = {}, getArrayFromKey} = {}} = {}} = {},
  } = props || {};
  const nameMonitoringParameter = useMemo(() => {
    return resolveMonitoringParameter(item);
  }, [item]);
  const flagStatus = useMemo(() => {
    const {careProgram = []} = item;
    if (!(Array.isArray(careProgram) && careProgram.length)) {
      return null;
    }
    const flag = getArrayFromKey({arr: careProgram, field: 'flag'});
    const missedFlag = getArrayFromKey({arr: careProgram, field: 'missedFlag'});
    const notesFlag = getArrayFromKey({arr: careProgram, field: 'notesFlag'});
    const flagsColorArray = [...flag, ...notesFlag, ...missedFlag];
    return flagsColorArray.includes('red')
      ? false
      : flagsColorArray.includes('grey')
      ? true
      : true;
  }, [item?.careProgram]);
  const [attended, setAttended] = useState(flagStatus);
  const tabsData = useMemo(() => {
    return makeTabs({
      nameMonitoringParameter,
      timeFilter,
      item,
      attendedParams: {attended, setAttended},
      eventDispatcher,
      navigation,
    });
  }, [nameMonitoringParameter, timeFilter, attended]);
  const Tabs = useMemo(() => {
    return TabNavigator({
      tabs: {
        ...tabsData,
      },
    });
  }, [tabsData]);
  return (
    <>
      <View
        style={{
          width: '100%',
          minHeight: 6,
          backgroundColor: attended ? 'green' : 'red',
        }}
      />
      <View style={{padding: 8, flexDirection: 'row'}}>
        <PatientDetails item={item} contactInformation chronicDisease />
        <View style={{alignItems: 'flex-end', flex: 1}}>
          {isTimerVisible() ? (
            <Timer type="analysis" patientId={item?._id} />
          ) : (
            void 0
          )}
        </View>
      </View>
      <ScrollView style={{flex: 1}}>
        {/* <PatientData {...props} /> */}
        <View>
          {isEmpty(tabsData) ? (
            <View
              style={{
                minHeight: 50,
                maxHeight:80,
                justifyContent: 'center',
                alignItems: 'center',
                ...h13_Regular,
                lineHeight: 21,
                letterSpacing: 0.57,
              }}>
              <Text>Monitoring Parameter is not available</Text>
            </View>
          ) : (
            <Tabs {...props} />
          )}
        </View>
        <ActivityLog {...props} />
      </ScrollView>
    </>
  );
};
export default DataAnalysis;
