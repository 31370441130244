import {EditUserProfile} from '../../Queries';
import Form from '../../app-components/form/Form';
import {PLACEHOLDER} from '../../app-components/utils';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import {submit} from '../../AppServices';

let header = {
  title: 'Edit Profile',
  actions: [cancelButton(), save()],
};

export default Form({
  type: 'standardShadow',
  header,
  closeView: 1,
  reloadEvent: 'reloadProfile',
  onSubmit: submit({
    model: 'User',
  }),
  uri: EditUserProfile,
  formGroups: [
    {
      columnsPerRow: 1,
      fieldVariant: 'filled',
      fieldContainer: 'topLabel',
      groups: [
        {
          columns: [
            {
              type: 'uploadImage',
              field: 'photo',
              label: 'Profile Picture',
            },
            {
              type: 'text',
              field: 'name',
              label: 'User Name',
              placeholder: PLACEHOLDER,
            },
            {
              type: 'text',
              field: 'email',
              label: 'Email Address',
              placeholder: PLACEHOLDER,
            },
          ],
        },
      ],
    },
  ],
});
