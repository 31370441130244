import React from 'react';
import {Text} from '../../index';
import {FormField} from '../../form/field';
import {
  AvatarRender,
  AvatarTextRender,
  CardRender,
  checkBoxRender,
  TextRender,
  TextAreaRender,
  NumberRender,
  OpenActionsRender,
  MoreActionsRender,
  CustomRender,
  SelectionRender,
  HeaderSelectionRender,
  HeaderTextRender,
  HeaderNumberRender,
  HeaderCustomRender,
} from '../../render-components/ListRenders';
import WithSort from '../sort/WithSort';
import WithAggregate from '../aggregate/WithAggregate';

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

const renderTypes = {
  default: TextRender,
  custom: CustomRender,
  selection: SelectionRender,
  avatar: AvatarRender,
  avatarText: AvatarTextRender,
  card: CardRender,
  moreActions: MoreActionsRender,
  openActions: OpenActionsRender,
  text: TextRender,
  multiText: TextRender,
  textArea: TextAreaRender,
  number: NumberRender,
  date: TextRender,
  time: TextRender,
  file: TextRender,
  checkBox: checkBoxRender,
  autoSuggest: TextRender,
  autoSuggestAvatar: TextRender, // todo
  search: TextRender,
  searchAvatar: TextRender, //todo
  multiAutoSuggest: TextRender,
  multiAutoSuggestAvatar: TextRender, // todo
  multiSearch: TextRender,
  multiSearchAvatar: TextRender, // todo
  googlePlace: TextRender,
  switch: TextRender,
  toggle: TextRender,
  chipsToggle: TextRender,

  toggleSwitch: TextRender,
  radioGroup: TextRender,
  radio: TextRender,
  richText: TextAreaRender,
  progressSlider: TextRender,
  downloadFile: TextRender, // todo
  action: TextRender,
};

const headerTypes = {
  selection: HeaderSelectionRender,
  default: HeaderTextRender,
  number: HeaderNumberRender,
  text: HeaderTextRender,
  custom: HeaderCustomRender,
};

let HeaderCellRender = (props) => {
  let {column: {type, headerType} = {}} = props;
  if (headerType) {
    type = headerType;
  }
  if (type && headerTypes[type]) {
    return headerTypes[type](props);
  } else {
    return headerTypes.default(props);
  }
};

HeaderCellRender = WithSort(WithAggregate(HeaderCellRender));

export const CellRender = (props) => {
  let {column, ...restProps} = props;
  let {type, render, Component, componentProps, ...restColumn} = column;
  if (type && renderTypes[type]) {
    return renderTypes[type](props);
  }
  if (Component) {
    return getRenderComponent(Component, {
      ...restProps,
      ...restColumn,
      data: restProps.item,
      ...componentProps,
    });
  } else if (render) {
    return render(props);
  } else {
    return type ? (
      <Text>No type registered : {type}</Text>
    ) : (
      renderTypes.default(props)
    );
  }
};

export const EditableCellRender = (props) => {
  let {column, navigation, eventDispatcher} = props;
  let {header, editable, ...restColumn} = column;
  if (typeof editable === 'function') {
    editable = editable(props);
  }
  if (editable === false || restColumn.type === 'action') {
    return CellRender(props);
  } else {
    return (
      <FormField
        variant="standard"
        variantTheme="standard-compact-unbordered"
        navigation={navigation}
        eventDispatcher={eventDispatcher}
        {...restColumn}
      />
    );
  }
};

export {HeaderCellRender};
