import EducationCategoryForm from './EducationCategoryForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
let header = {
  title: 'Edit Education Category',
  actions: [cancelButton(), save()],
};
const uri=({navigation})=>{
  return {
    query: {
      id: 'EducationCategoryList',
      relationValue: {
        model: 'EducationCategory',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'EducationCategory',
  }
}

export default EducationCategoryForm({uri, header});
