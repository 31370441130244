import React from 'react';
import {getUser} from './AppServices';
import withGpsContext from './withGpsContext';
import uuid from 'uuid/v4';

export const AppointmentContext = React.createContext({});

class AppointmentContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unReadCount: void 0,
      getAllChatThreads: {},
      callBackReceived: false,
    };
  }

  callback = (data) => {
    this.setState({newData: {...data, _id: data?._id + '_Modified_' + uuid()}});
  };
  subscribeMeetingCallback = (data) => {};

  socketSubscription = () => {
    let {gpsStore} = this.props;
    let {doctor: {_id: doctorId} = {}, userType} = getUser();
    this.socketUid = uuid();
    gpsStore.subscribeEvent({
      event: 'newAppointmentCreated',
      callback: this.callback,
    });
    gpsStore.subscribeEvent({
      event: 'newCheckIn',
      callback: this.callback,
    });
    gpsStore.subscribeEvent({
      event: 'AppointmentCancelled',
      callback: this.callback,
    });
    gpsStore.emitDataOnConnect({
      uid: this.socketUid,
      event: 'subscribeGroup',
      data: {
        groupId: `${doctorId}__new`,
        sessionId: this.socketUid,
        source: 'web',
      },
      callback: this.subscribeMeetingCallback,
    });
  };

  unsubscribSocket = () => {
    let {gpsStore} = this.props;
    if (gpsStore && gpsStore.removeEmitDataOnConnect) {
      gpsStore.removeEmitDataOnConnect({uid: this.socketUid});
      gpsStore.unsubscribeEvent({
        event: 'newAppointmentCreated',
        callback: this.callback,
      });
      gpsStore.unsubscribeEvent({
        event: 'newCheckIn',
        callback: this.callback,
      });
      gpsStore.unsubscribeEvent({
        event: 'AppointmentCancelled',
        callback: this.callback,
      });
    }
  };

  componentDidMount() {
    this.socketSubscription();
  }

  componentWillUnmount() {
    this.unsubscribSocket();
  }

  render() {
    return (
      <AppointmentContext.Provider value={{...this.state}}>
        {this.props.children}
      </AppointmentContext.Provider>
    );
  }
}

const AppointmentContextProviderHoc = withGpsContext(
  AppointmentContextProvider,
);

export {AppointmentContextProviderHoc};

export default (Component) => (props) => (
  <AppointmentContext.Consumer>
    {(value) => {
      return <Component appointmentStore={value} {...props} />;
    }}
  </AppointmentContext.Consumer>
);
