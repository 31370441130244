export const findById = (data, field, valueToFind) => {
  if (!data || !data.length) {
    return void 0;
  }
  return data.find((doc) => {
    let docValue = doc[field];
    if (docValue === valueToFind) {
      return true;
    }
  });
};

export const getSelectedData = ({data, selectedIds} = {}) => {
  if (!selectedIds || !selectedIds.length) {
    return selectedIds;
  }
  if (!data) {
    return;
  }
  return selectedIds.map((selectedId) => {
    return findById(data, '_id', selectedId);
  });
};

export const getSelectionInfo = ({screenState, data, item, multiple} = {}) => {
  let {selectedIds, allPageSelected, selectedData} = screenState || {};
  if (multiple && selectedIds && selectedIds.length) {
    if (!selectedData && data) {
      selectedData = getSelectedData({data, selectedIds});
    }
  } else if (item && item._id) {
    selectedIds = [item._id];
    selectedData = [item];
  }
  return {
    selectedIds,
    selectedData,
    allPageSelected,
  };
};

export const removeSelection = ({screenState, setScreenState} = {}) => {
  let {selectedIds} = screenState || {};
  if (selectedIds && selectedIds.length && setScreenState) {
    setScreenState({
      selectedIds: void 0,
      allPageSelected: void 0,
    });
  }
};

export const isActionVisible = ({navigation} = {}) => {
  if (navigation) {
    const routeCount = navigation.getRouteCount && navigation.getRouteCount();
    const routeIndex = navigation.getIndex && navigation.getIndex();
    if (routeCount && routeIndex !== undefined && routeIndex < routeCount - 1) {
      return false;
    }
  }
  return true;
};
