import DosageForm from './DosageForm';
import {EditDosage} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
let header = {
  title: 'Edit Dosage',
  actions: [cancelButton(), save()],
};

export default DosageForm({uri: EditDosage, header});
