import MasterDataTab from './MasterDataTab';
import AddProcedure from './AddProcedure';
import AddSpeciality from './AddSpeciality';
import AddDrug from './AddDrug';
import AddSymptom from './AddSymptoms';
import editSpeciality from './editSpeciality';
import AddSetting from './AddSetting';
import AddAllergy from './AddAllergy';
import EditSetting from './EditSetting';
import EditSymptom from './EditSymptom';
import EditDrug from './EditDrug';
import DeleteAnyMasterData from './DeleteAnyMasterData';
import EditProcedure from './EditProcedure';
import EditAllergy from './EditAllergy';
import AddSocialHistory from './AddSocialHistory';
import EditSocialHistoryForm from './EditSocialHistoryForm';
import AddOrganisationSetting from './AddOrganisationSetting';
import EditOrganisationsetting from './EditOrganisationSetting';
import AddDosage from './AddDosage';
import EditDosage from './EditDosage';
import AddDiagnosis from './AddDiagnosis';
import EditDiagnosis from './EditDiagnosis';
import AddMedicalHistory from './AddMedicalHistory';
import EditMedicalHistory from './EditMedicalHistory';
import AddSurgicalHistory from './AddSurgicalHistory';
import EditSurgicalHistory from './EditSurgicalHistory';
import AddCountrywiseSpecialityFee from './AddCountrywiseSpecialityFee';
import EditCountrywiseSpecialityFee from './EditCountrywiseSpecialityFee';
import AddAdvertisement from './AddAdvertisement';
import EditAdvertisement from './EditAdvertisement';
import EditAlgorithm from './EditAlgorithm';
import AddAlgorithm from './AddAlgorithm';
import AddCareProgram from './AddCareProgram';
import EditCareProgram from './EditCareProgram';
import AddTodo from './AddTodo';
import EditTodo from './EditTodo';
import AddEducationContent from './AddEducationContent';
import EditEducationContent from './EditEducationContent';
import AddEducationCategory from './AddEducationCategory';
import EditEducationCategory from './EditEducationCategory';
import EditCCM from './EditCCM';
import AddCCM from './AddCCM';
import AddTodoCategory from './AddTodoCategory';
import EditTodoCategory from './EditTodoCategory';

export default {
  'master-data': {
    screen: MasterDataTab,
  },
  'add-speciality': {
    screen: AddSpeciality,
    expanded: true,
  },
  'edit-speciality': {
    screen: editSpeciality,
    expanded: true,
  },
  'add-countrywise-speciality-fee': {
    screen: AddCountrywiseSpecialityFee,
    expanded: true,
  },
  'edit-countrywise-speciality-fee': {
    screen: EditCountrywiseSpecialityFee,
    expanded: true,
  },
  'add-procedure': {
    screen: AddProcedure,
    expanded: true,
  },
  'edit-procedure': {
    screen: EditProcedure,
    expanded: true,
  },
  'add-medical-history': {
    screen: AddMedicalHistory,
    expanded: true,
  },
  'edit-medical-history': {
    screen: EditMedicalHistory,
    expanded: true,
  },
  'add-surgical-history': {
    screen: AddSurgicalHistory,
    expanded: true,
  },
  'edit-surgical-history': {
    screen: EditSurgicalHistory,
    expanded: true,
  },
  'add-drug': {
    screen: AddDrug,
    expanded: true,
  },
  'edit-drug': {
    screen: EditDrug,
    expanded: true,
  },
  'add-symptom': {
    screen: AddSymptom,
    expanded: true,
  },
  'edit-symptom': {
    screen: EditSymptom,
    expanded: true,
  },
  'add-setting': {
    screen: AddSetting,
    expanded: true,
  },
  'edit-setting': {
    screen: EditSetting,
    expanded: true,
  },
  'add-allergy': {
    screen: AddAllergy,
    expanded: true,
  },
  'edit-allergy': {
    screen: EditAllergy,
    expanded: true,
  },
  'add-social-history': {
    screen: AddSocialHistory,
    expanded: true,
  },
  'edit-social-history': {
    screen: EditSocialHistoryForm,
    expanded: true,
  },
  'delete-masterData': {
    screen: DeleteAnyMasterData,
  },
  'add-organisationSetting': {
    screen: AddOrganisationSetting,
    expanded: true,
  },
  'edit-organisationSetting': {
    screen: EditOrganisationsetting,
    expanded: true,
  },
  'add-dosage': {
    screen: AddDosage,
    expanded: true,
  },
  'edit-dosage': {
    screen: EditDosage,
    expanded: true,
  },
  'add-diagnosis': {
    screen: AddDiagnosis,
    expanded: true,
  },
  'edit-diagnosis': {
    screen: EditDiagnosis,
    expanded: true,
  },
  'add-advertisement': {
    screen: AddAdvertisement,
    expanded: true,
  },
  'edit-advertisement': {
    screen: EditAdvertisement,
    expanded: true,
  },
  'edit-algorithm': {
    screen: EditAlgorithm,
    expanded: true,
  },
  'add-algorithm': {
    screen: AddAlgorithm,
    expanded: true,
  },
  'add-care-program': {
    screen: AddCareProgram,
    expanded: true,
  },
  'edit-care-program': {
    screen: EditCareProgram,
    expanded: true,
  },
  'add-todo': {
    screen: AddTodo,
    expanded: true,
  },
  'edit-todo': {
    screen: EditTodo,
    expanded: true,
  },
  'add-education-content': {
    screen: AddEducationContent,
    expanded: true,
  },
  'edit-education-content': {
    screen: EditEducationContent,
    expanded: true,
  },
  'add-education-category': {
    screen: AddEducationCategory,
    expanded: true,
  },
  'edit-education-category': {
    screen: EditEducationCategory,
    expanded: true,
  },
  'add-ccm': {
    screen: AddCCM,
    expanded: true,
  },
  'edit-ccm': {
    screen: EditCCM,
    expanded: true,
  },
  'add-todo-category': {
    screen: AddTodoCategory,
    expanded: true,
  },
  'edit-todo-category': {
    screen: EditTodoCategory,
    expanded: true,
  },
};
