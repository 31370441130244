import React, { useState } from 'react';
import {
  View,
  TouchableOpacity,
  isMobile,
  Dimensions,
  Text,
} from '../app-components';
import { logout } from '../AppServices';
import { colors } from '../theme/colors';
import { h14_Medium } from '../theme/fonts';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { roles } from '../Lib/constants';

const { themeColor } = colors;
const { width, height } = Dimensions.get('window');
const ProfileDropdown = (props) => {
  const {
    frameStyle,
    navigation = {},
    hideModal = () => { },
    userData = {},
    revertColor = false,
    user: { userType = '' },
  } = props || {};
  let invertFrameStyle = cloneDeep(frameStyle);
  if (revertColor) {
    invertFrameStyle.top = frameStyle.top + 10;
    // delete invertFrameStyle.left;
    // invertFra/meStyle.right = 50;
  }
  const [hover, setHover] = useState('');
  const { t } = useTranslation();
  let menuDropDown = [
    { label: 'Profile', value: 'profile' },
    { label: 'Logout', value: 'logout' },
  ];
  let isDoctorAndANM = false;
  if (userType === roles?.DOCTOR || userType === roles?.ANM) {
    isDoctorAndANM = true;
  }
  if (!isDoctorAndANM) {
    menuDropDown.push({
      label: 'Remote Configuration',
      value: 'remoteConfiguration',
    });
  }
  return (
    <View
      style={{
        ...(revertColor ? invertFrameStyle : frameStyle),
        // ...invertFrameStyle,
        padding: 5,
        zIndex: 100,
        ...(isDoctorAndANM && { height: 100 }),
        borderRadius: 8,
        backgroundColor: '#fff',
        border: revertColor ? `1px solid ${themeColor}` : undefined,
      }}>
      {menuDropDown.map((item) => (
        <TouchableOpacity
          onMouseEnter={() => {
            setHover(item.value);
          }}
          onMouseLeave={() => {
            setHover('');
          }}
          onPress={() => {
            if (item.value === 'profile') {
              navigation.push({
                view: 'profile',
                params: { items: navigation },
                modal: true,
                modalProps: {
                  position: 'screenRight',
                  style: { width: isMobile ? width * 1 : 500, zIndex: 2 },
                },
              });
            } else if (item.value === 'remoteConfiguration') {
              navigation.push({
                view: 'remoteConfig',
                params: { items: userData },
              });
            } else {
              logout().then(() => {
                window.location.reload();
                // navigation.reset('authenticator');
                hideModal();
              });
            }
            hideModal();
          }}
          style={{
            marginBottom: 5,
            backgroundColor: item.value === hover ? themeColor : '#fff',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: 'white',
            cursor: 'pointer',
            minHeight: 30,
            borderRadius: 15,
          }}>
          <Text
            style={{
              color: item.value === hover ? '#fff' : themeColor,
              ...h14_Medium,
              alignSelf: 'center',
              textAlign: 'center',
            }}>
            {t(item.label)}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default ProfileDropdown;
