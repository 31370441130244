import Table from '../../app-components/table/Table';
import {h16_Regular} from '../../theme/fonts';

let uri = () => {
  return {
    // id: 'organizationLists',
    query: {
      id:"organizationLists",
      addOnFilter: {
        status: 'approved',
      },
    },
    model: 'Organizations',
  };
};

let tableItems = {
  lg: {
    action: ({item}) => {
      return {
        type: 'link',
        link: {
          view: 'clinic-detail',
          params: {item},
        },
      };
    },
    columns: [
      {width: 20},
      {
        header: 'Email',
        type: 'text',
        width: 200,
        field: 'email',
      },
      {
        header: 'Organization Name',
        type: 'text',
        width: 200,
        field: 'organization_name',
      },
      {
        header: 'Organization Address',
        type: 'text',
        // width: 300,
        field: 'organization_address.description',
      },
      {
        header: 'No. of Doctors',
        type: 'text',
        width: 100,
        field: 'num_doctors',
      },
      {
        header: 'No. of Clinic Admins',
        type: 'text',
        width: 100,
        field: 'num_clinic_admins',
      },
      {
        header: 'No. of Front desk users',
        type: 'text',
        width: 100,
        field: 'num_frontdesk_user',
      },
      {
        header: 'Revenue',
        type: 'text',
        width: 100,
        field: 'total_revenue_amount',
      },
    ],
  },
  sm: {
    action: ({item}) => {
      return {
        type: 'link',
        link: {
          view: 'clinic-detail',
          params: {item},
        },
      };
    },
    card: {
      title: {
        primaryField: 'organization_name',
      },
      contentItems: [
        {
          field: 'email',
        },
        {
          field: 'organization_address.description',
        },
      ],
      right: {
        align: 'right',
        direction: 'column',
        position: 'center',
        items: [
          {
            prefix: 'No. of Clinic Admins: ',
            field: 'num_clinic_admins',
          },
          {
            prefix: 'No. of Doctors: ',
            field: 'num_doctors',
          },
          {
            prefix: 'No. of Front desk: ',
            field: 'num_frontdesk_user',
          },
          {
            prefix: 'Revenue',
            field: 'total_revenue_amount',
          },
        ],
      },
    },
  },
};
export default Table({
  uri,
  reloadEvent: 'reloadClinic',
  ...tableItems,
});
