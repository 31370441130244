import AllergyForm from './AllergyForm';
import {EditAllergy} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
let header = {
  title: 'Edit Allergy',
  actions: [cancelButton(), save()],
};

export default AllergyForm({uri: EditAllergy, header});
