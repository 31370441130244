import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './date';
import App from './App';
import './i18n/i18n';
import reportWebVitals from './reportWebVitals';
class MainApp extends Component {
  componentDidMount() {
    const loadingdiv = document.getElementById('hospital-loading');
    loadingdiv &&
      loadingdiv.parentNode &&
      loadingdiv.parentNode.removeChild(loadingdiv);
    const __PROD__ = document.domain !== 'localhost';
    if (__PROD__) {
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
    }
  }

  render() {
    return <App />;
  }
}

ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
