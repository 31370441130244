import React, {useState} from 'react';
import {
  View,
  Text,
  ScrollView,
  Linking,
  Image,
  TouchableOpacity,
  isMobile,
} from '../../../app-components';
import GoogleMap from '../../../app-components/GoogleMap';
import {getUser, invoke, getImageUrl} from '../../../AppServices';
import {default_logo} from '../../../images/index';
import {h16_Regular, h14_Medium} from '../../../theme/fonts';
import {GradientButton} from '../../../app-components/buttons/Buttons';

const Summary = (props) => {
  const {navigation} = props;
  const {state: {params} = {}} = navigation;
  let {item} = navigation.getParams();
  let {organization = {}} = getUser();
  let userType = getUser()?.userType;
  const [loading, setLoading] = useState(false);
  if (!item) {
    item = organization;
  }

  let {account_status = false} = params;
  if (item?.account_status === 'linked') {
    account_status = true;
  }

  const connectToStripe = async () => {
    setLoading(true);
    const result = await invoke({
      id: 'createConnectedAccount',
    });
    const {url = ''} = result || {};
    Linking.openURL(url);
    setLoading(false);
  };
  const {payment_gateway: {name = ''} = {}} = item || {};
  return (
    <>
      <View style={{flexDirection: 'row-reverse'}}>
        <View
          style={{
            marginRight: isMobile ? 15 : 44,
            marginTop: isMobile ? 15 : 24,
            flexDirection: 'row',
          }}>
          {userType === 'ClinicAdmin' &&
          !account_status &&
          name === 'Stripe' ? (
            <TouchableOpacity
              style={{cursor: 'pointer', marginRight: 10}}
              onPress={() => {
                if (!loading) {
                  connectToStripe();
                }
              }}>
              <GradientButton
                label="Connect to Stripe"
                loading={loading}
                indicatorColor={'#fff'}
              />
            </TouchableOpacity>
          ) : (
            void 0
          )}
          {userType === 'SuperAdmin' &&
          !account_status &&
          name === 'RazorPay' ? (
            <TouchableOpacity
              style={{cursor: 'pointer', marginRight: 10}}
              onPress={() => {
                navigation.push({
                  view: 'accountId',
                  params: {...item},
                  modal: true,
                  modalProps: {
                    autoHide: true,
                    screenCenterStyle: {
                      borderRadius: 10,
                    },
                    style: {},
                  },
                });
              }}>
              <GradientButton
                label="Connect to Razorpay"
                loading={loading}
                indicatorColor={'#fff'}
              />
            </TouchableOpacity>
          ) : (
            void 0
          )}
          <TouchableOpacity
            style={{cursor: 'pointer'}}
            onPress={() => {
              navigation.push({
                view: 'clinic-summary-form',
                params: {
                  item,
                },
              });
            }}>
            <GradientButton label="Edit" />
          </TouchableOpacity>
        </View>
      </View>
      <ScrollView style={{flex: 1}}>
        <View
          style={{
            marginTop: 34,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <View
            style={{
              ...(isMobile
                ? {
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                : {flexDirection: 'row'}),
            }}>
            {item?.organization_logo ? (
              <Image
                source={getImageUrl(item?.organization_logo.file)}
                style={{
                  objectFit: 'contain',
                  height: 200,
                  width: 200,
                  borderRadius: 5,
                }}
              />
            ) : (
              <Image
                source={default_logo}
                style={{height: 150, width: 150, borderRadius: 5}}
              />
            )}

            <View style={{...(isMobile?{marginLeft:60}:{marginLeft: 45})}}>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 20,
                }}>
                <View style={{width: 200}}>
                  <Text
                    style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                    Organisation name
                  </Text>
                </View>
                <View style={{width: 300}}>
                  <Text
                    style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                    {item?.organization_name}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 20,
                }}>
                <View style={{width: 200}}>
                  <Text
                    style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                    Organisation address
                  </Text>
                </View>
                <View style={{width: 300}}>
                  <Text
                    style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                    {item && item?.organization_address?.description}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 20,
                }}>
                <View style={{width: 200}}>
                  <Text
                    style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                    E-mail
                  </Text>
                </View>
                <View style={{width: 300}}>
                  <Text
                    style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                    {item?.email}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 20,
                }}>
                <View style={{width: 200}}>
                  <Text
                    style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                    Mobile no.
                  </Text>
                </View>
                <View style={{width: 300}}>
                  <Text
                    style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                    {item?.organization_mobile}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 20,
                }}>
                <View style={{width: 200}}>
                  <Text
                    style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                    Clinic admin
                  </Text>
                </View>
                <View style={{width: 300}}>
                  <Text
                    style={{...h14_Medium, color: '#383838', opacity: 0.76}}>
                    {item?.first_admin?.name}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 20,
                }}>
                <View style={{width: 200}}>
                  <Text
                    style={{...h16_Regular, color: '#3c3c3c', opacity: 0.7}}>
                    Status
                  </Text>
                </View>
                <View style={{width: 300}}>
                  {item?.activity_status === 'active' ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <Text
                        style={{
                          ...h14_Medium,
                          color: '#00D4C4',
                          opacity: 0.76,
                        }}>
                        Active
                      </Text>
                      <View
                        style={{
                          backgroundColor: '#00D4C4',
                          borderRadius: 50,
                          height: 5,
                          width: 5,
                          marginLeft: 5,
                        }}
                      />
                    </View>
                  ) : (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <Text
                        style={{
                          ...h14_Medium,
                          color: '#FF1E30',
                          opacity: 0.76,
                        }}>
                        Inactive
                      </Text>
                      <View
                        style={{
                          backgroundColor: '#FF1E30',
                          borderRadius: 50,
                          height: 5,
                          width: 5,
                          marginLeft: 5,
                        }}
                      />
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            marginBottom: 50,
          }}>
          <View
            style={{
              width: isMobile ? '100%' : 700,
            }}>
            <GoogleMap
              latitude={item?.organization_address?.latitude}
              longitude={item?.organization_address?.longitude}
              mapHeight={200}
            />
          </View>
        </View>
      </ScrollView>
    </>
  );
};
export default Summary;
