import React, {useEffect} from 'react';
import {
  View,
  Text,
  ScrollView,
  isMobile,
  Image,
  TouchableOpacity,
  Linking,
} from './app-components';
import {getImageUrl, getUserLocation, invoke} from './AppServices';
import {Checkmark} from './images';
import {h13_Medium, h16_SansSemiBold} from './theme/fonts';
import {colors} from './theme/colors';
import {gradiantButtonShadow, patientWatingRoomCard} from './theme/shadows';
const {themeColor} = colors;
const Advertisement = (props) => {
  const {navigation = {}} = props || {};
  const routes = navigation.getActiveRoutes();
  const firstRoute = routes[0] || [];
  const [data, setData] = React.useState([]);
  const getDatas = async () => {
    try {
      const {Allowed = false, data:locationData = {}} = getUserLocation() || {};
      const res = await invoke({
        id: 'getAdvertisement',
        paramValue: {
          data: {
            location: {
              latitude: Allowed ? locationData.coords.latitude : null,
              longitude: Allowed ? locationData?.coords?.longitude : null,
            },
            toDisplay: 'after_login',
          },
        },
      });
      const {result = []} = res || {};
      if (Array.isArray(result) && result.length) {
        setData(result);
      }
    } catch (err) {
      console.log(`>@>Puru ~ file: Advertisement.js ~ line 29 ~ err`, err);
    }
  };
  useEffect(() => {
    getDatas();
  }, []);

  if (isMobile) {
    return null;
  }
  if (
    firstRoute !== 'videoSession' &&
    routes?.length > 1 &&
    routes?.indexOf('videoSession') !== -1
  ) {
    return null;
  }
  if (
    firstRoute !== 'videoSessionMobile' &&
    routes?.length > 1 &&
    routes?.indexOf('videoSessionMobile') !== -1
  ) {
    return null;
  }
  if (firstRoute == 'doctor-patient-queue' || firstRoute == 'schedule') {
    return null;
  }
  if (!Array.isArray(data)) {
    return null;
  }
  if (!data.length) {
    return null;
  }

  return (
    <ScrollView style={{flex: 0.4, padding: 5, ...patientWatingRoomCard}}>
      {data.map((item) => {
        const {logo, link = '', title = '', description = ''} = item;
        const image = logo && logo.file ? getImageUrl(logo.file) : Checkmark;
        return (
          <View
            style={{
              flexDirection: 'row',
              padding: 5,
              marginBottom: 5,
              paddingTop: 20,
              paddingBottom: 20,
              overflow: 'hidden',
              ...gradiantButtonShadow,
            }}>
            <Image style={{width: 80, objectFit: 'contain'}} source={image} />
            <View style={{marginLeft: 20, width: '80%'}}>
              <TouchableOpacity
                onPress={() => {
                  if (link) {
                    Linking.openURL(link, '_blank');
                  }
                }}>
                <Text
                  style={{
                    ...h16_SansSemiBold,
                    fontWeight: 'bold',
                    numberOfLines: 1,
                    color: themeColor,
                    ...(link ? {cursor: 'pointer'} : {}),
                  }}
                  title={title}
                  numberOfLines={1}>
                  {title}
                </Text>
              </TouchableOpacity>
              <Text
                style={{...h13_Medium, wordWrap: 'break-word', marginTop: 10}}>
                {description}
              </Text>
            </View>
          </View>
        );
      })}
    </ScrollView>
  );
};

export default Advertisement;
