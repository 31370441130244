import React from 'react';
import Form from '../../app-components/form/Form';
import {rescheduleIcon} from '../../images';
import {close} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {getUser, submit} from '../../AppServices';
import {
  autoSuggestOptionsInput,
  googlePlaceInput,
  multiSearchInput,
  searchInput,
} from '../../autoSuggestions';
const header = {
  title: 'Live Demo',
  subTitle: 'Form For Demo',
  actions: [close],
};

const footer = {
  actions: [save({label: 'Done'})],
};
const FormDemo = Form({
  closeView: 1,
  onSubmit: submit({
    model: 'Demo',
    lowerCaseFields: ['email'],
  }),

  reloadEvent: 'reloadProgress',
  // uri,
  header,
  footer,
  defaultValues: () => {
    let {org_id, organization} = getUser() || {};
    return {
      org_id: org_id && org_id.length && org_id[0],
      school: organization && organization.length && organization[0],
    };
  },

  formGroups: [
    {
      columnsPerRow: 2,
      columns: [
        {
          field: 'name',
          placeholder: 'Enter Name',
          label: 'Name',
          type: 'text',
          variant: 'filled',
        },
        {
          field: 'email',
          placeholder: 'Enter Email',
          label: 'Email',
          type: 'textArea',
        },
        {
          field: 'fee',
          placeholder: 'Enter fee',
          label: 'Fee',
          type: 'number',
        },
        {
          field: 'time',
          hrPlaceholder: 'Hours',
          minPlaceholder: 'Minutes',
          type: 'time',
          variant: 'filled',
        },
        {
          field: 'dob',
          placeholder: 'Enter DOB',
          label: 'DOB',
          type: 'date',
          variant: 'filled',
        },
        {
          field: 'subject',
          placeholder: 'Enter Subject',
          label: 'Subject',
          type: 'text',
        },
      ],
    },

    {
      columnsPerRow: 1,
      columns: [
        multiSearchInput({
          icon: rescheduleIcon,
          field: 'class_id',
          query: 'classLists',
          model: 'Classes',
          placeholder: 'Search Input',
          idField: '_id',
          searchField: 'name',
          suggestionField: 'name',
          valueField: 'name',
          container: 'leftIcon',
          chipsVariant: 'rounded',
          chipsPosition: 'top',
          mandatory: true,
        }),
        searchInput({
          icon: rescheduleIcon,
          field: 'unit',
          query: 'units',
          model: 'MasterUnit',
          placeholder: 'Search Input',
          idField: '_id',
          searchField: 'name',
          suggestionField: 'name',
          valueField: 'name',
          container: 'leftIcon',
          mandatory: true,
        }),
        {
          icon: rescheduleIcon,
          type: 'file',
          field: 'image',
        },
        googlePlaceInput({
          field: 'address',
          placeholder: 'Select Place',
        }),
        autoSuggestOptionsInput({
          icon: rescheduleIcon,
          field: 'gender',
          placeholder: 'Select Gender',
          suggestionField: 'label',
          container: 'leftIcon',
          options: [
            {value: 'm', label: 'Male'},
            {value: 'f', label: 'Female'},
          ],
        }),
        autoSuggestOptionsInput({
          icon: rescheduleIcon,
          field: 'status',
          placeholder: 'Select Status',
          container: 'leftIcon',
          options: ['Active', 'Inactive'],
        }),
      ],
    },
  ],
});

const FormWorking = (props) => <FormDemo {...props} />;
export default FormWorking;
