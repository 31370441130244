import React from 'react';
import {downloadData} from '../../AppServices';
import Toast from '../../npms/react-toast';
import {buttonMappings, StandardButton} from '../buttons/Buttons';
import {getResolvedParamValue} from '../data-fetch/DataFetchFunctions';
import {getSelectionInfo, removeSelection} from './Utility';
import {PermissionsAndroid, Platform} from '../../npms/react-core-components';

export const download = ({
  id,
  label,
  disabled,
  preMessage,
  postMessage,
  reloadEvent,
  closeView,
  buttonType,
  outline,
  service,
  type = 'download',
  ...restProps
} = {}) => {
  label = label || 'Download';
  if (!id && typeof label === 'string') {
    id = label;
  }
  if (!buttonType) {
    buttonType = outline ? 'outline' : 'standard';
  }
  return {
    id: label,
    type,
    service,
    preMessage,
    postMessage,
    reloadEvent,
    closeView,
    disabled,
    render: ({state = {}, disabled} = {}) => {
      let buttonLabel = label;
      if (typeof buttonLabel === 'function') {
        buttonLabel = buttonLabel({data: state.data});
      }
      let ButtonComponent = buttonMappings[buttonType] || StandardButton;
      return (
        <ButtonComponent
          {...restProps}
          label={buttonLabel}
          disabled={disabled}
        />
      );
    },
    ...restProps,
  };
};

const onError = (error) => {
  let errorMessage = typeof error === 'string' ? error : error.message;
  if (!errorMessage) {
    return false;
  }
};

const isPermissionGranted = async () => {
  if (Platform.OS === 'android') {
    try {
      const grants = await PermissionsAndroid.requestMultiple([
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
      ]);
      const writeStorageGrant =
        grants[PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE];

      if (writeStorageGrant !== PermissionsAndroid.RESULTS.GRANTED) {
        if (writeStorageGrant === PermissionsAndroid.RESULTS.NEVER_ASK_AGAIN) {
          onError('PERMMISSION_NOT_GRANTED');
        }
        return false;
      }
    } catch (err) {
      return false;
    }
  }
  return true;
};

const downloadAction = async (props) => {
  let {
    action: {preMessage, postMessage, closeView, ...restAction},
    screenState,
    setScreenState,
    global_params,
    getSelectionState,
    clearSelectionState,
    item,
    eventDispatcher,
    fetchUriEvent,
    navigation,
    state,
    data,
    uri,
    multiple,
  } = props;
  if (typeof getSelectionState === 'function') {
    screenState = getSelectionState();
  }
  try {
    preMessage && Toast.show(preMessage);
    if (fetchUriEvent && eventDispatcher) {
      // required when action is outside list/form state
      let uriEventInfo = eventDispatcher.notify(fetchUriEvent) || {};
      data = data || uriEventInfo.state?.data;
      uri = uri || uriEventInfo.uri;
    }
    let {selectedIds, allPageSelected, selectedData} = getSelectionInfo({
      screenState,
      data,
      item,
      multiple,
    });
    let globalParamValue = getResolvedParamValue(
      global_params?.dataParams,
      true,
    );
    let permissionGranted = await isPermissionGranted();
    if (permissionGranted) {
      let result = await downloadData({
        data: item,
        selectedData,
        uri,
        globalParamValue,
        selectedIds,
        allPageSelected,
        state,
        ...restAction,
      });
      if (typeof clearSelectionState === 'function') {
        clearSelectionState();
      } else {
        removeSelection({screenState, setScreenState});
      }
      postMessage && Toast.show(postMessage);
      closeView && navigation.pop(closeView);
    }
  } catch (err) {
    Toast.error(err.message || err || 'Error in download');
  }
};

export {downloadAction};
