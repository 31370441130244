import React, {Component} from 'react';

import {
  View,
  TouchableOpacity,
  BasicTextInput,
  Image,
  Keyboard,
  Platform,
  StyleSheet,
} from '../../react-core-components';
import PropTypes from 'prop-types';

import {withAppKeyboardListenerContext} from '../../react-app-keyboard-listener';
import {withModalContext} from '../../react-modal-view';
import AutoSuggestWithSearchInput from './AutoSuggestWithSearchInput';

class AutosuggestInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    valueField: PropTypes.string,
    sugestionField: PropTypes.string,
    options: PropTypes.array,
    minChar: PropTypes.number,

    accessible: PropTypes.bool,
    searching: PropTypes.bool,
    animated: PropTypes.bool,
    showsVerticalScrollIndicator: PropTypes.bool,
    keyboardShouldPersistTaps: PropTypes.string,

    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    dropdownStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    dropdownTextStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    dropdownTextHighlightStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),

    adjustFrame: PropTypes.func,
    renderRow: PropTypes.func,
    renderSeparator: PropTypes.func,
    renderButtonText: PropTypes.func,
    renderNoData: PropTypes.func,
    navigation: PropTypes.object,

    onDropdownWillShow: PropTypes.func,
    onDropdownWillHide: PropTypes.func,
    onItemSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.typing = '';
    this._button = null;
    this._buttonFrame = null;
    this.lastFetchSequence = 0;
    this.state = {};
  }

  updateSuggestions = data => {
    this.setState({data}, () => {
      this.show();
    });
  };

  show = () => {
    this.props.setModal &&
      this.props.setModal.setModalState({
        renderModal: this._renderModal,
        showModal: true,
        closeModal: this.hide,
      });
    this.setState({modalOpen: true});
  };

  hide = () => {
    this.props.setModal &&
      this.props.setModal.setModalState({
        renderModal: null,
        showModal: false,
        replace: true,
      });
    this.setState({modalOpen: false});
  };

  setButtonText = value => {
    this.buttonText = value;
  };

  onFocus = e => {
    const {onFocus} = this.props;
    onFocus && onFocus(e);
    this._renderData();
  };

  onBlur = e => {
    const {onBlur} = this.props;
    onBlur && onBlur(e);
    this.setButtonText(void 0);
    this.typing = '';
    this.lastFetchSequence = 0;
    if (this.fetching) {
      this.fetching = false;
      this.setState({toggled: !this.state.toggled});
    }
  };

  _onButtonPress = event => {
    event.preventDefault();
    Keyboard && Keyboard.dismiss();
    this._inputRef && this._inputRef.focus();
  };

  _renderData = () => {
    const {onDropdownWillShow} = this.props;
    onDropdownWillShow && onDropdownWillShow();
    this.show();
  };

  _renderModal = () => {
    const {accessible} = this.props;
    return (
      <TouchableOpacity
        accessible={accessible}
        activeOpacity={1}
        onPress={this._onModalPress}
        style={{
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          position: 'absolute',
          backgroundColor: 'rgba(52, 52, 52, 0.1)',
        }}>
        <AutoSuggestWithSearchInput
          {...this.props}
          hideModal={this.hide}
          buttonInputRef={this._inputRef}
        />
      </TouchableOpacity>
    );
  };

  _onModalPress = () => {
    const {onDropdownWillHide, autoHide} = this.props;
    if ((!onDropdownWillHide || onDropdownWillHide() !== false) && autoHide) {
      this.hide();
    }
  };

  renderSelector = () => {
    let {arrowIconStyle, showArrow, arrowDownIcon, clearValue} = this.props;
    if (!showArrow) {
      return null;
    }
    let touchProps = {};
    if (Platform.OS === 'web') {
      touchProps.onMouseDown = e => {
        e && e.stopPropagation && e.stopPropagation();
        clearValue && clearValue(e);
      };
    } else {
      touchProps.onPress = clearValue;
    }
    return (
      <TouchableOpacity {...touchProps}>
        <Image source={arrowDownIcon} style={arrowIconStyle} />
      </TouchableOpacity>
    );
  };
  getInputRef = ref => {
    const {getRef} = this.props;
    this._inputRef = ref;
    getRef && getRef(ref);
  };
  clearText = e => {
    this.setButtonText('');
  };
  _renderButton() {
    let {
      disabled,
      accessible,
      children,
      buttonContainerStyle,
      inputStyle,
      value,
      placeholder,
      searching,
      renderButtonText,
      inputProps,
      renderSelector,
      imageComponent,
      clearValue,
    } = this.props;
    let buttonText = void 0;
    if (value !== this.oldValue) {
      buttonText = value;
      this.buttonText = void 0;
    } else if (this.buttonText || this.buttonText === '') {
      buttonText = this.buttonText;
    } else {
      buttonText = value;
    }

    this.oldValue = value;
    let buttonComponent = children;
    if (!buttonComponent) {
      let textComponent = null;
      if (renderButtonText) {
        textComponent = renderButtonText({
          text: buttonText,
          placeholder,
        });
      } else {
        textComponent = (
          <BasicTextInput
            style={inputStyle}
            value={buttonText || ''}
            placeholder={placeholder}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            getRef={this.getInputRef}
            caretHidden={true}
            showSoftInputOnFocus={false}
            editable={true}
            {...inputProps}
          />
        );
      }
      renderSelector = renderSelector || this.renderSelector;
      buttonComponent = (
        <View style={{flexDirection: 'row', ...buttonContainerStyle}}>
          {imageComponent ? imageComponent({text: buttonText}) : void 0}
          <View style={{flex: 1, overflow: 'hidden'}}>{textComponent}</View>
          {renderSelector({
            value,
            searching,
            modalOpen: this.state.modalOpen,
            searchText: this.buttonText,
            clearValue,
            clearText: this.clearText,
          })}
        </View>
      );
    }
    let touchProps = {};
    if (Platform.OS === 'web') {
      touchProps.onMouseDown = this._onButtonPress;
    } else {
      touchProps.onPress = this._onButtonPress;
    }
    return (
      <TouchableOpacity
        ref={button => (this._button = button)}
        disabled={disabled}
        accessible={accessible}
        {...touchProps}>
        {buttonComponent}
      </TouchableOpacity>
    );
  }

  render() {
    return this._renderButton();
  }
}

AutosuggestInput.defaultProps = {
  disabled: false,
  placeholder: 'Please select...',
  animated: true,
  showsVerticalScrollIndicator: true,
  keyboardShouldPersistTaps: 'always',
  searching: false,
  minChar: 0,
  topContainerStyle: {flex: 1},
  buttonContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownStyle: {
    position: 'absolute',
    height: (33 + StyleSheet.hairlineWidth) * 5,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: 'lightgray',
    borderRadius: 2,
    backgroundColor: 'white',
  },
  dropdownTextStyle: {
    paddingHorizontal: 6,
    paddingVertical: 10,
    fontSize: 11,
    color: 'gray',
    backgroundColor: 'white',
    textAlignVertical: 'center',
  },
  dropdownTextHighlightStyle: {
    color: 'black',
  },
  separatorStyle: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: 'lightgray',
  },
  arrowIconStyle: {
    height: 10,
    width: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default withAppKeyboardListenerContext(
  withModalContext(AutosuggestInput),
);
