import Table from '../../../app-components/table/Table';

const uri = () => {
  return {
    query: {
      id: 'mayaLabCategoryList',
    },
    model: 'MayaLabCategory',
  };
};

export default Table({
  uri,
  reloadEvent: 'reloadLabCategories',
  reloadOnChangeEvent: 'reloadLabCategories',

  action: ({item}) => {
    return {
      type: 'link',
      link: {
        view: 'EditLabCategory',
        params: {item},
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
            width: '35%',
          },
          style: {},
        },
      },
    };
  },
  columns: [
    {
      header: 'Category Name',
      type: 'text',
      field: 'labCategoryName',
      sortable: 'labCategoryName',
      reverseSort: true,
    },
    {
      header: 'Sort Order',
      type: 'text',
      field: 'sortOrder',
      sortable: 'sortOrder',
      reverseSort: true,
    },
    {
      header: 'Created On',
      type: 'date',
      field: 'date',
      sortable: 'date',
      reverseSort: true,
    },
  ],
});
