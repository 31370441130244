import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {
  autoSuggestOptionsInput,
  multiSearchInput,
  searchInput,
} from '../../autoSuggestions';
import {PLACEHOLDER} from '../../app-components/utils';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {moreActionsShadow} from '../../theme/shadows';
import countryDropdown from './StaticData/countryData.json';

export default ({uri, header}) => {
  const Specialityform = Form({
    type: 'standardShadow',
    uri,
    onSubmit: submit({
      model: 'specialityFee',
    }),
    reloadEvent: 'reloadSpecialityFee',
    closeView: 1,
    mandatory: {speciality: 1, fee: 1,country:1},
    computations: {
      self: {
        name: {
          compute: (data = {}) => {
            let name = data?.speciality?.name;
            return {
              set: {
                name,
              },
            };
          },
          onChange: ['speciality', 'speciality._id'],
        },
        country_code: {
          compute: (data = {}) => {
            let country_code = '';
            countryDropdown.map((item) => {
              if (item?.name == data?.country) {
                country_code = item.code;
              }
            });
            return {
              set: {
                country_code,
              },
            };
          },
          onChange: ['country'],
        },
      },
    },
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 2,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                searchInput({
                  field: 'speciality',
                  query: 'specialityLists',
                  model: 'Specialities',
                  placeholder: 'Select Speciality',
                  idField: '_id',
                  searchField: 'name',
                  suggestionField: 'name',
                  valueField: 'name',
                  label: 'Speciality',
                }),
                autoSuggestOptionsInput({
                  field: 'country',
                  options: countryDropdown,
                  placeholder: 'Select',
                  showSelected: false,
                  keyField: 'name',
                  suggestionField: 'name',
                  // width: 100,
                  label: 'Select Country',
                  dropdownStyle: {
                    height: 180,
                    borderRadius: 8,
                    backgroundColor: '#ffffff',
                    ...moreActionsShadow,
                  },
                }),
                {
                  type: 'number',
                  field: 'fee',
                  label: 'Fee',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'number',
                  field: 'deduction_margin',
                  label: 'Commission in %',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                searchInput({
                  field: 'speciality',
                  query: 'specialityLists',
                  model: 'Specialities',
                  placeholder: 'Select Speciality',
                  idField: '_id',
                  searchField: 'name',
                  suggestionField: 'name',
                  valueField: 'name',
                  label: 'Speciality',
                }),
                autoSuggestOptionsInput({
                  field: 'country',
                  options: countryDropdown,
                  placeholder: 'Select',
                  showSelected: false,
                  keyField: 'name',
                  // width: 100,
                  label: 'Select Country',
                  dropdownStyle: {
                    height: 130,
                    borderRadius: 8,
                    backgroundColor: '#ffffff',
                    ...moreActionsShadow,
                  },
                }),
                {
                  type: 'number',
                  field: 'fee',
                  label: 'Fee',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'number',
                  field: 'deduction_margin',
                  label: 'Commission in %',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return Specialityform;
};
