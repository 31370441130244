import {colors, bgs} from './colors';

import {
  h12_Regular,
  h12_SemiBold,
  h18_Regular,
  h18_AvenirNext,
  h14_Medium,
  h28_AvenirNext,
  h14_AvenirNext,
  h24_AvenirNext_600,
  h24_SemiBold,
  h24_Medium,
  h24_Regular,
  h13_Regular,
} from './fonts';
import {shadow} from './shadows';
import {flagGreyIcon} from '../images';
let {
  darkTextColor,
  textColor70,
  textColor20,
  errorColor,
  blueButton1,
  textWhite,
  textColor54,
  loginBorderColor,
  purpleColor,
  themeColor,
  grey,
} = colors;
let {whiteBg} = bgs;
const loginTheme = {
  scrollViewStyle: {
    flex: 1,
    backgroundColor: whiteBg,
  },
  containerStyle: {
    flex: 1,
    backgroundColor: whiteBg,
    // backgroundPosition: 'bottom',
    // backgroundSize: '100% 212px',
    // backgroundRepeat: 'no-repeat',
    // alignItems: 'flex-end',
    backgroundSize: 'contain',
    // justifyContent: '',
    // height:"100%",
  },

  loginBoxStyle: {
    width: 500,
    height: '100%',
    backgroundColor: textWhite,
    // borderRadius: 15,
    // ...shadow,
    // borderColor: '#E7EEF5',
    paddingTop: 28,
    // borderWidth: 1,
    paddingLeft: 50,
    paddingRight: 50,
    // paddingBottom: 36,
  },
  // LoginLogo_style: { width: 188, height: 18 },
  loginLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  loginLogoStyle: {height: 45, width: 200, objectFit: 'contain'},

  loginText: {
    // marginBottom: 25,
    ...h24_Regular,
    color: 'black' || darkTextColor,
  },
  mobileLabel: {
    ...h13_Regular,
    marginBottom: 10,
    color: textColor70,
  },
  loginTextInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: textColor20,
    borderRadius: 4,
    ...h14_AvenirNext,
    outline: 'none',
    color: darkTextColor,
  },
  loginButtonText: {
    ...h14_AvenirNext,
    lineHeight: 0.88,
    color: textWhite,
  },
  loginButton: {
    cursor: 'pointer',
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    backgroundColor: themeColor,
  },
  buttonContainer: {
    alignItems: 'center',
  },
  helpContainer: {
    flexDirection: 'row',
    paddingTop: 16,
    paddingbottom: 16,
  },
  helpText: {paddingLeft: 4, color: purpleColor, ...h12_SemiBold},
  errorStyle: {...h12_Regular, color: errorColor},
  contactSupportViewStyle: {
    borderBottomWidth: 1,
    alignItem: 'center',
    margin: 20,
    borderBottomColor: textColor54,
  },
  contactSupportText: {
    ...h18_Regular,
    color: textColor54,
  },
};

const otpTheme = {
  scrollViewStyle: {
    flex: 1,
    backgroundColor: whiteBg,
  },
  containerStyle: {
    flex: 1,
    backgroundColor: whiteBg,
    // backgroundPosition: 'bottom',
    // backgroundSize: '100% 212px',
    // backgroundRepeat: 'no-repeat',
    // alignItems: 'flex-end',
    backgroundSize: 'contain',
    flexDirection: 'row',
  },
  buttonContainer: {
    alignItems: 'center',
  },
  loginBoxStyle: {
    width: 500,
    height: '100%',
    backgroundColor: textWhite,
    // borderRadius: 15,
    // ...shadow,
    // borderColor: '#E7EEF5',
    paddingTop: 28,
    // borderWidth: 1,
    paddingLeft: 50,
    paddingRight: 50,
  },
  loginLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  loginLogoStyle: {height: 45, width: 200, objectFit: 'contain'},

  backLogo: {width: 30, height: 30, marginLeft: -9},

  labelStyle: {...h24_AvenirNext_600, color: darkTextColor, marginBottom: 12},
  otpText: {
    // ...h24_AvenirNext_600,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 35,
    justifyContent: 'flex-start',
    // marginLeft: 6,
    // marginBottom: 16,
  },
  editButton: {
    color: themeColor,
    ...h14_Medium,
    marginLeft: 5,
    cursor: 'pointer',
  },

  buttonContainer: {
    alignItems: 'center',
  },
  otpInfoText: {
    ...h18_AvenirNext,
    marginBottom: 64,
    color: grey,
  },
  loginTextInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: textColor20,
    borderRadius: 4,
    ...h14_AvenirNext,
    outline: 'none',
    color: darkTextColor,
    borderRadius: 8,
  },
  loginButtonText: {
    ...h14_AvenirNext,
    lineHeight: 0.88,
    color: textWhite,
  },
  loginButton: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 81,
    backgroundColor: themeColor,
    cursor: 'pointer',
    height: 36,
    borderRadius: 12,
  },
  resendPinContainer: {
    marginTop: 18,
    flexDirection: 'row',
    alignItems: 'center',
  },
  resendText: {...h14_AvenirNext, color: textColor70, paddingRight: 5},
  resendButton: {color: themeColor, ...h12_SemiBold, cursor: 'pointer'},
  helpContainer: {flexDirection: 'row', paddingTop: 16, paddingBottom: 16},
  helpText: {paddingLeft: 4, color: purpleColor, ...h12_SemiBold},
  errorStyle: {...h12_Regular, height: 16, color: errorColor},
  contactSupportViewStyle: {
    borderBottomWidth: 1,
    alignItem: 'center',
    margin: 20,
    borderBottomColor: textColor54,
  },
  contactSupportText: {
    ...h18_Regular,
    color: textColor54,
  },
};

const theme = {loginTheme, otpTheme};
export default theme;
