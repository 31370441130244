import React from 'react';
import TabNavigator from '../../app-components/tab/TabNavigator';
import {countUri} from '../../Queries';
import {GradientButton} from '../../app-components/buttons/Buttons';
import InviteLinks from './OrganizationPatients';
import {getUser} from '../../AppServices';

export default TabNavigator({
  tabs: {
    tab1: {
      actions: [
        {
          render: () => {
            return <GradientButton label={'Create Patient'} />;
          },
          type: 'link',
          link: {
            view: 'patient-signup-form',
            // expanded: true,
            // height: 300,
          },
        },
      ],
      label: 'Organization Patients',
      reloadEvent: 'reloadPatientData',
      screen: InviteLinks,
      countUri: () => {
        const {organization} = getUser();
        return countUri({
          filter: {
            organization,
          },
          model: 'Patients',
          id: 'patientLists',
        });
      },
    },
  },
});
