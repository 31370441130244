import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
} from '../index';

import {manazeV6Colors} from '../../theme/manazeV6Colors';
import {Headline7, Headline9} from '../../theme/Headlines';
import {Action} from '../action/Action';
import {crossIcon} from '../../images';
const {
  neutral_1,
  neutral_6,
  surface_white,
  neutral_2,
  neutral_9,
  neutral_4,
  primary_1,
} = manazeV6Colors;
const confirmModalStyle = {
  containerStyle: {
    flex: 1,
    backgroundColor: surface_white,
  },
  headerContainerStyle: {
    height: 44,
    paddingLeft: 12,
    paddingRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: neutral_6,
  },
  titleTextStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    ...Headline7,
    color: neutral_2,
    numberOfLines: 2,
  },
  crossIconContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    cursor: 'pointer',
  },
  crossIconStyle: {
    // height: 16,
    // width: 16,
  },
  descriptionContainerStyle: {
    paddingTop: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingBottom: 12,
  },
  descriptionStyle: {...Headline9, color: neutral_1},
  actionRowStyle: {
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 12,
    paddingBottom: 12,
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  buttonContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    minWidth: 60,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 4,
    backgroundColor: neutral_9,
    marginLeft: 18,
  },
  buttonTextStyle: {...Headline9, color: neutral_4},
  activeButtonContainerStyle: {
    backgroundColor: primary_1,
  },
  activeButtonTextStyle: {
    color: surface_white,
  },
};
const getValueComponent = ({value, valueTextStyle, ...props}) => {
  if (typeof value === 'function') {
    value = value({...props});
  }
  if (!value) {
    return null;
  }
  if (React.isValidElement(value)) {
    return value;
  } else {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    return <Text style={valueTextStyle}>{value}</Text>;
  }
};

export const StandardConfirmView = props => {
  let {
    title = 'title',
    message = 'message',
    hideCancel,
    hideCross,
    hideConfirm,
    cancelText = 'Cancel',
    onCancel,
    confirmText = 'Ok',
    closeModal,
    onConfirm,
    Action,
    action,
    actions,
    containerStyle,
    headerContainerStyle,
    titleTextStyle,
    crossIconContainerStyle,
    crossIconStyle,
    descriptionContainerStyle,
    descriptionStyle,
    actionRowStyle,
    buttonContainerStyle,
    activeButtonContainerStyle,
    buttonTextStyle,
    activeButtonTextStyle,
    ...restProps
  } = props;
  if (!title && !message) {
    return null;
  }

  if (!actions) {
    let cancelButton = hideCancel
      ? void 0
      : {text: cancelText, onPress: onCancel};
    let confirmButton = hideConfirm
      ? void 0
      : {text: confirmText, active: true, ...action, onPress: onConfirm};
    actions = [cancelButton, confirmButton];
  }

  let onClose = e => {
    setTimeout(_ => {
      closeModal && closeModal(e);
    }, 0);
  };

  return (
    <View style={containerStyle}>
      <View style={headerContainerStyle}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          {getValueComponent({
            value: title,
            valueTextStyle: titleTextStyle,
            ...restProps,
          })}
        </View>
        {hideCross ? void 0 : <TouchableOpacity style={crossIconContainerStyle} onPress={onClose}>
          <Image style={crossIconStyle} source={crossIcon} />
        </TouchableOpacity>}
      </View>
      <View style={descriptionContainerStyle}>
        {getValueComponent({
          value: message,
          valueTextStyle: descriptionStyle,
          ...restProps,
        })}
      </View>
      {actions.length && (
        <View style={actionRowStyle}>
          {actions.map(action => {
            if (!action) {
              return null;
            }
            const {text, icon, active, ...restAction} = action;
            return (
              <Action
                {...restProps}
                style={{
                  ...buttonContainerStyle,
                  ...(active && activeButtonContainerStyle),
                }}
                action={restAction}
                closeModal={onClose}>
                {icon ? <Image source={icon} /> : void 0}
                {text ? (
                  <Text
                    style={{
                      ...buttonTextStyle,
                      ...(active && activeButtonTextStyle),
                    }}>
                    {text}
                  </Text>
                ) : (
                  void 0
                )}
              </Action>
            );
          })}
        </View>
      )}
    </View>
  );
};

const typeWiseConfirmModal = {
  standard: props =>
    StandardConfirmView({...confirmModalStyle, ...props, Action: Action}),
};
export default props => {
  const {navigation} = props;
  let {action: {confirm, ...restAction} = {}, ...restConfirmProps} =
    navigation && navigation.getParams && navigation.getParams('confirmProps');
  if (!confirm) {
    return null;
  }
  if (confirm === true) {
    confirm = {};
  }
  const {type, ...restConfirm} = confirm;
  let confirmModalComponent =
    (type && typeWiseConfirmModal[type]) || typeWiseConfirmModal['standard'];

  return confirmModalComponent({
    ...restConfirmProps,
    ...props,
    ...restConfirm,
    action: restAction,
  });
};
