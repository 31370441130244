import React from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
} from '../../react-core-components';

export const HeaderCell = props => {
  let {column} = props;
  let columnTextStyle = props['textStyle'] || props['headerRowColumnTextStyle'];
  let columnIconStyle = props['iconStyle'] || props['headerRowColumnIconStyle'];
  let columnIconGap = props['iconGap'] || props['headerRowIconGap'] || 6;
  let columnIconPosition =
    props['iconPosition'] || props['headerRowIconPosition'] || 'left';

  let {header, align: columnAlign} = column;
  if (!header) {
    return null;
  }
  if (typeof header === 'function') {
    header = header(props);
  }
  if (typeof header === 'string') {
    header = {label: header};
  }
  let {
    label,
    icon,
    render,
    align = columnAlign,
    containerStyle,
    iconStyle = columnIconStyle,
    textStyle = columnTextStyle,
    iconGap = columnIconGap,
    iconPosition = columnIconPosition,
    onPress,
    resizeMode = 'contain',
  } = header;
  let headerComponent = void 0;
  if (render) {
    headerComponent = render(props);
  } else {
    containerStyle = {
      flexDirection: 'row',
      ...containerStyle,
    };
    if (align === 'right') {
      containerStyle.justifyContent = 'flex-end';
    } else if (align === 'center') {
      containerStyle.justifyContent = 'center';
    }

    let iconComponent = icon ? (
      <Image source={icon} style={iconStyle} resizeMode={resizeMode} />
    ) : (
      void 0
    );
    let valueComponent =
      label !== undefined ? <Text style={textStyle}>{label}</Text> : void 0;
    let gapComponent =
      iconComponent && valueComponent ? (
        <View style={{width: iconGap}} />
      ) : (
        void 0
      );
    headerComponent = (
      <View style={containerStyle} title={label}>
        {iconPosition === 'left' && iconComponent ? (
          <>
            {iconComponent}
            {gapComponent}
          </>
        ) : (
          void 0
        )}
        {valueComponent}
        {iconPosition === 'right' && iconComponent ? (
          <>
            {gapComponent}
            {iconComponent}
          </>
        ) : (
          void 0
        )}
      </View>
    );
  }
  if (!headerComponent) {
    return null;
  }
  if (onPress) {
    headerComponent = (
      <TouchableOpacity style={{cursor: 'pointer'}} onPress={onPress}>
        {headerComponent}
      </TouchableOpacity>
    );
  }
  return headerComponent;
};

HeaderCell.defaultProps = {
  textStyle: {
    font: 14,
    fontWeight: 'bold',
    color: 'rgba(56, 56, 56, 0.37)',
    numberOfLines: 1,
  },
};

export default HeaderCell;
