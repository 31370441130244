import React from 'react';
import {View, Text} from '../../app-components';
import moment from 'moment';
import {NotificationListCard} from './NotificationListCard';
import Table from '../../app-components/table/Table';
import {colors, bgs} from '../../theme/colors';
import {h14_Regular} from '../../theme/fonts';

let {textColor87} = colors;
let NotificationList = Table({...NotificationListCard});
class ListComponent extends React.Component {
  groupByData = (data) => {
    let newData = {};
    let newDataValues = [];
    data.map((value) => {
      let {date} = value || {};
      let key = moment(date).format('DD:MM');
      newData[key] = newData[key] || {};
      newData[key].data = newData[key].data || [];
      newData[key].data.push(value);
      newData[key].date = date;
    });
    for (let key in newData) {
      let {date} = newData[key];
      let arrayData = {
        title: moment(date).format('dddd,DD MMM'),
        data: newData[key].data,
      };
      newDataValues.push(arrayData);
    }
    return newDataValues;
  };
  render() {
    let {
      classroomStore: {notificationData = []},
      classroomStore,
      navigation,
      eventDispatcher,
      hideModal,
      data,
    } = this.props;
    console.log(
      `>@>Puru ~ file: NotificationList.js ~ line 41 ~ this.props`,
      this.props,
    );
    data = this.groupByData(data);
    // // notificationData=[{_id:"1",name:"hhh"},{_id:"1",name:"hhh"},{_id:"1",name:"hhh"},{_id:"1",name:"hhh"}]
    // notificationData = [
    //   {
    //     _id: '123',
    //     imgId: 'study_material',
    //     message:
    //       'Introduction to Algebra    Maths has been shared with you',
    //     createdOn: '1 days ago',
    //     hideModal
    //   },
    //   {
    //     _id: '123',
    //     imgId: 'study_material',
    //     message:
    //       'Introduction to Algebra    Maths has been shared with you',
    //     createdOn: '1 days ago',
    //     hideModal

    //   },

    // ];
    // notificationData = [
    //   ...notificationData,
    //   ...notificationData,
    //   ...notificationData,
    //   ...notificationData,
    //   ...notificationData,
    // ];
    return (
      <View>
        {data.map((value, key) => {
          let {data: ListData, title} = value || {};
          return (
            <View>
              <Text
                style={{
                  paddingLeft: 25,
                  paddingTop: 10,
                  color: textColor87,
                  ...h14_Regular,
                }}>
                {title}
              </Text>
              <NotificationList
                hideModal={hideModal}
                classroomStore={classroomStore}
                hideColumnHeader={true}
                data={ListData}
                navigation={navigation}
                eventDispatcher={eventDispatcher}
              />
            </View>
          );
        })}
      </View>
    );
  }
}

// const WrappedComponent = withNotificationContext(ListComponent);
export default ListComponent;
