import React from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  Text,
  isMobile,
  Dimensions,
} from '../../app-components';
import {login_background_web, backIcon, mayaMdLogo} from '../../images';
import {h22_Medium, h18_Medium} from '../../theme/fonts';
import {shadow} from '../../theme/shadows';
import AdvertisementBeforeLogin from './AdvertisementBeforeLogin';
const {height, width} = Dimensions.get('window');
const ClinicSignupFormat = ({
  boxVisible,
  navigation = {},
  backIconVisible,
  mayaMdLogoVisible,
  title,
  ...restProps
}) => {
  return (
    <View style={{flexDirection: 'row', height: '100%'}}>
      {isMobile ? (
        void 0
      ) : (
        <View style={{flex: 2}}>
          <AdvertisementBeforeLogin {...restProps} />
        </View>
      )}
      <ScrollView
        style={{
          flex: 1,
          ...(isMobile ? {} : {paddingLeft: 80, paddingRight: 80}),
        }}>
        <View style={style.containerStyle}>
          {boxVisible ? (
            <View style={{...style.shadowBox}}>
              {mayaMdLogoVisible && (
                <Image
                  id="brand_image"
                  source={mayaMdLogo}
                  style={{
                    width: isMobile ? width * 0.55 : 200,
                    // height:
                    objectFit: 'contain',
                    alignSelf: 'center',
                  }}
                />
              )}
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 35,
                  alignItems: 'center',
                }}>
                {backIconVisible ? (
                  <TouchableOpacity
                    style={{cursor: 'pointer'}}
                    onPress={() => {
                      if (navigation) {
                        navigation.pop();
                      }
                    }}>
                    <Image
                      source={backIcon}
                      style={{height: 30, width: 35}}></Image>{' '}
                  </TouchableOpacity>
                ) : (
                  void 0
                )}
                {title ? (
                  <Text style={{...(isMobile ? h18_Medium : h22_Medium)}}>
                    {title}
                  </Text>
                ) : (
                  void 0
                )}
              </View>
              {restProps?.children}
            </View>
          ) : (
            restProps?.children
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default ClinicSignupFormat;

ClinicSignupFormat.defaultProps = {
  boxVisible: true,
  backIconVisible: true,
  mayaMdLogoVisible: true,
  title: '',
};
const style = {
  containerStyle: {
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    ...(isMobile
      ? {
          backgroundImage: `url(${login_background_web})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundSize: 'contain',
        }
      : {}),
    //`,
  },
  shadowBox: {
    ...(isMobile
      ? {
          borderRadius: 15,
          ...shadow,
          borderColor: '#E7EEF5',
          borderWidth: 1,
          padding: 15,
        }
      : {}),
    margin: isMobile ? 20 : undefined,
    backgroundColor: '#fff',
  },
};
