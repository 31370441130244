import React from 'react';
import {WithReactEventDispatcher} from '../../npms/react-event-dispatcher';
import {ActivityIndicator} from './Loader';

class AppLoader extends React.Component {
  state = {};
  setLoading = status => {
    this.setState({showLoader: status});
  };
  componentDidMount() {
    const {eventDispatcher} = this.props;
    eventDispatcher && eventDispatcher.listen('appLoader', this.setLoading);
  }
  componentWillUnmount() {
    const {eventDispatcher} = this.props;
    eventDispatcher && eventDispatcher.unlisten('appLoader', this.setLoading);
  }
  render() {
    return this.state.showLoader ? <ActivityIndicator /> : null;
  }
}

export default WithReactEventDispatcher(AppLoader);
