import React, {useState, useEffect} from 'react';
import {View, Text,isMobile} from '../../app-components';
import AnalyticsCard from '../analytics/CardComponent/AnalyticsCard';
import {h30_Regular} from '../../theme/fonts';
import {getCurrency, invoke} from '../../AppServices';

const HeaderCard = (props) => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  let {timeFilter, filter, currency = 'USD'} = props;
  let paramValue = {
    period: {
      ...timeFilter,
    },
  };

  const getData = async () => {
    try {
      setLoading(true);
      if (timeFilter) {
        const res = await invoke({
          id: 'getTransactions',
          paramValue,
        });
        setResponse(res);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [timeFilter]);
  const balanceData = [
    {
      header: `Balance(${getCurrency(currency)})`,
      title: `${response?.balance ?? 'N/A'}`,
    },
  ];
  const totalData = [
    {
      header: `Total Payment(${getCurrency(currency)})`,
      title: `${response?.total_payments ?? 'N/A'}`,
      titleColor: 'blue',
    },
  ];
  const cancelData = [
    {
      header: `Cancellation(${getCurrency(currency)})`,
      title: `${response?.cancellations ?? 'N/A'}`,
      titleColor: 'red',
    },
  ];
  const cardStyle = {
    border: '1px solid rgba(56, 56, 56, 0.37)',
    minHeight: 110,
    minWidth: 200,
  };

  return (
    <View
      style={{
        flexDirection: isMobile ? 'column' : 'row',
        marginBottom: 20,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <AnalyticsCard
        cardContent={balanceData}
        cardStyle={cardStyle}
        loading={loading}
      />
      <Text style={{...h30_Regular}}>=</Text>
      <AnalyticsCard
        loading={loading}
        cardContent={totalData}
        cardStyle={cardStyle}
      />

      <Text style={{...h30_Regular}}>-</Text>
      <AnalyticsCard
        cardContent={cancelData}
        cardStyle={cardStyle}
        loading={loading}
      />
    </View>
  );
};

export default HeaderCard;
