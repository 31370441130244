import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {cancelButton} from '../../app-components/action/CloseAction';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {getAgeFromDob} from '../../app-components/UtilityFunctions';
import {
  autoSuggestInput,
  multiSearchInput,
  searchInput,
} from '../../autoSuggestions';
import {Snackbar, Text, TouchableOpacity, View} from '../../app-components';
import {submit, invoke, getUser, fetch} from '../../AppServices';
import Form from '../../app-components/form/Form';
import PatientList from './Patients';
import {omit} from 'lodash';
import {h12_Regular} from '../../theme/fonts';
import {roles} from '../../Lib/constants';

const editable = false;

const DetailPatient = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [regenerateNoteLoading, setRegenerateLoading] = useState(false);
  const [testSuggestion, setTestSuggestion] = useState([]);
  const testSuggestionValues = {};
  const {
    navigation: {
      state: {
        params: {
          item: {_id: patientId, orderGenerationResponse},
        },
      },
    },
  } = props;
  const {_id = '', userType = ''} = getUser() || {};

  useEffect(() => {
    fetch({
      uri: {
        props: {
          query: {
            id: 'recommendedTest',
            addOnFilter: {
              patient: {_id: patientId},
            },
          },
          model: 'PatientNotes',
        },
      },
    })
      .then(async (res) => {
        const algoRawSuggestion = await JSON.parse(
          res?.data[0]?.algoRawSuggestion,
        );
        setTestSuggestion(algoRawSuggestion);
      })
      .catch((error) => {
        console.log('@error', error);
      });
  }, [patientId]);

  const NoteCheckBoxes = useMemo(() => {
    return testSuggestion?.map((item) => ({
      type: 'checkBox',
      editable: false,
      label: item?.name,
      field: item?.name.toString(),
      enableLabel: true,
      showCheckBox: false,
    }));
  }, [testSuggestion]);

  const handleOnCreateOrder = useCallback(
    (patientData) => {
      const {
        state: {data = {}},
      } = patientData;
      setIsLoading(true);

      const {eventDispatcher, navigation} = patientData;
      const testRecommended = [];

      Object.keys(testSuggestionValues).map((item) => {
        if (data[item]) {
          testRecommended.push({name: item});
        }
        return true;
      });

      if (data?.testsPrescribed?.length) {
        data.testsPrescribed.forEach((test) => delete test._id);
      }

      invoke({
        id: 'registerPatientOnKrsnaaAPI',
        paramValue: {
          patient: {
            _id: data?._id,
          },
          isOrderGenerated: true,
          suggestions: testSuggestion,
          // testsPrescribed: data?.testsPrescribed || [],
          // testRecommended,
          // ...(data.prescription ? {prescription: data.prescription} : {}),
          // ...(data.uploadPrescription
          // ? {uploadPrescription: data.uploadPrescription}
          // : {}),
          user: {
            _id,
          },
          role: 'MO',
        },
      })
        .then((res) => {
          setIsLoading(false);
          Snackbar.show({
            text: res.result,
            duration: Snackbar.LENGTH_LONG,
          });
          eventDispatcher.notify('reloadPatient');
          navigation.goBack();
        })
        .catch((error) => {
          Snackbar.show({
            text: error,
            duration: Snackbar.LENGTH_LONG,
          });
          setIsLoading(false);
        });
    },
    [_id, testSuggestion, testSuggestionValues],
  );

  const handleCreateOrder = useCallback(
    (patientDetail) => {
      const {
        state: {data = {}},
      } = patientDetail;

      const {navigation} = patientDetail;

      // if (!data?.testsPrescribed?.length) {
      //   Snackbar.show({
      //     text: 'Please select test.',
      //     duration: Snackbar.LENGTH_LONG,
      //   });
      //   setIsLoading(false);
      //   return;
      // } else {
      navigation.push({
        view: 'createOrderModal',
        params: {handleOnCreateOrder, patientDetail, setIsLoading},
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
        },
      });
      // }
    },
    [handleOnCreateOrder],
  );

  const handleRegenerateNotes = useCallback(
    (noteDetails) => {
      setRegenerateLoading(true);
      const {navigation} = noteDetails;
      invoke({
        id: 'regenerateNote',
        paramValue: {
          _id: patientId,
        },
      })
        .then((response) => {
          setRegenerateLoading(false);
          Snackbar.show({
            text: 'Note generate successfully.',
            duration: Snackbar.LENGTH_LONG,
          });
          navigation.goBack();
        })
        .catch((err) => {
          setRegenerateLoading(false);
          Snackbar.show({
            text: err,
            duration: Snackbar.LENGTH_LONG,
          });
          console.log('err', err);
        });
    },
    [patientId],
  );

  let header = {
    title: 'Member Detail',
    titleTextStyle: {
      color: 'rgba(56, 56, 56, 0.87)',
      fontSize: 20,
      marginLeft: 10,
      fontFamily: 'AvenirNext-Regular',
    },
    actions: [
      {
        render: (patientData) => {
          const {
            isNoteGenerated,
            isOrderGeneratedForThisPatient,
          } = patientData?.state?.data;
          if (isNoteGenerated && !isOrderGeneratedForThisPatient) {
            return (
              <TouchableOpacity
                onPress={() => handleCreateOrder(patientData)}
                style={{cursor: 'pointer', height: 38}}>
                <GradientButton
                  color="#0095c9"
                  label={'Create Order'}
                  loading={isLoading}
                />
              </TouchableOpacity>
            );
          }
        },
      },
      userType === roles.SUPERADMIN && {
        render: (patientData) => {
          return (
            <TouchableOpacity
              onPress={() => handleRegenerateNotes(patientData)}
              style={{cursor: 'pointer', height: 38}}>
              <GradientButton
                color="#0095c9"
                label={'Regenerate Notes'}
                loading={regenerateNoteLoading}
              />
            </TouchableOpacity>
          );
        },
      },
      cancelButton({label: ' Back '}),
    ],
  };

  const ModifiedForm = Form({
    ...props,
    reloadEvent: 'reloadPatient',
    reloadOnChangeEvent: 'reloadPatient',
    type: 'standardShadow',
    closeView: 1,
    fieldVariant: 'filled',
    validateOnChange: true,
    mandatory: {
      testsPrescribed: 'This field is mandatory.',
    },
    onSubmit: submit({
      model: 'Patients',
    }),
    header: header,
    defaultValues: ({navigation}) => {
      const {state: {params: {item = {}} = {}} = {}} = navigation || {};
      Array.isArray(testSuggestion) &&
        testSuggestion.map((test) => {
          testSuggestionValues[test?.name] = true;
        });
      return {
        userType: 'Patient',
        ...item,
        ...testSuggestionValues,
      };
    },
    formGroups: [
      orderGenerationResponse &&
        !orderGenerationResponse?.status && {
          editable: editable,
          direction: 'row',
          fieldVariant: 'filled',
          groups: [
            {
              columnsPerRow: 1,
              columns: [
                {
                  type: 'custom',
                  render: (props) => {
                    const {
                      navigation: {
                        state: {
                          params: {
                            item: {orderGenerationResponse},
                          },
                        },
                      },
                    } = props;
                    return (
                      <Text
                        style={{
                          backgroundColor: '#F8D7DA',
                          padding: 20,
                          borderRadius: 5,
                          color: '#852B4B',
                          fontSize: 14,
                          fontWeight: 'bold',
                        }}>
                        Error: {orderGenerationResponse?.message}
                      </Text>
                    );
                  },
                },
              ],
            },
          ],
        },
      {
        editable: editable,
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'text',
                label: 'First Name',
                field: 'first_name',
              },
              {
                type: 'text',
                label: 'Last Name',
                field: 'last_name',
              },
              {
                type: 'date',
                label: 'Date Of Birth',
                field: 'birthDate',
              },
              {
                type: 'text',
                label: 'Gender',
                field: (data) => {
                  let {gender} = data || {};
                  if (gender) {
                    return gender[0].toUpperCase() + gender.slice(1);
                  }
                },
              },
              {
                type: 'text',
                label: 'Age',
                field: (data) => {
                  let {birthDate} = data || {};
                  return birthDate ? getAgeFromDob(birthDate) : '';
                },
              },
              {
                type: 'text',
                label: 'Phone Number',
                field: 'mobile',
              },
              {
                type: 'text',
                label: 'Family Id',
                field: 'familyId',
              },
              {
                type: 'text',
                label: 'Aadhar number',
                field: 'aadharNumber',
              },
              {
                type: 'text',
                label: 'Member Id',
                field: 'patientId',
                editable: false,
              },
              autoSuggestInput({
                label: 'District',
                placeholder: 'Select District',
                field: 'district',
                valueField: 'name',
                suggestionField: 'name',
                model: 'Districts',
                query: 'districtList',
                keyField: '_id',
                mandatory: true,
              }),
              autoSuggestInput(
                {
                  label: 'Block',
                  placeholder: 'Select Block',
                  field: 'block',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: 'Blocks',
                  query: 'blockList',
                  keyField: '_id',
                  mandatory: true,
                },
                {
                  addOnFilter: ({data}) => ({
                    district: {_id: data?.district?._id},
                  }),
                },
              ),
              searchInput(
                {
                  label: 'Village',
                  placeholder: 'Select Village',
                  field: 'village',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: 'Villages',
                  query: 'villageList',
                  keyField: '_id',
                  mandatory: true,
                  searchField: 'name',
                },
                {
                  addOnFilter: ({data}) => ({block: {_id: data?.block?._id}}),
                },
              ),
            ],
          },
        ],
      },
      {
        label: 'Recommended Tests',
        direction: 'row',
        fieldVariant: 'filled',
        editable: true,
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              ...(NoteCheckBoxes || []),
              // multiSearchInput(
              //   {
              //     label: 'Select Test',
              //     getChipValue: (item) =>
              //       `${item?.test_name} (${item?.test_id})`,
              //     renderRow: (data) => (
              //       <View
              //         style={{
              //           height: 40,
              //           justifyContent: 'center',
              //           cursor: 'pointer',
              //         }}>
              //         <Text
              //           style={{
              //             ...h12_Regular,
              //             paddingLeft: 12,
              //           }}>
              //           {data?.item?.test_name +
              //             ' ' +
              //             '-' +
              //             ' ' +
              //             data?.item?.test_id}
              //         </Text>
              //       </View>
              //     ),
              //     field: 'testsPrescribed',
              //     valueField: 'test_name',
              //     suggestionField: 'test_name',
              //     model: 'Test',
              //     query: 'testMaster',
              //     keyField: '_id',
              //     searchField: 'test_name',
              //     mandatory: true,
              //   },
              //   {
              //     addOnFilter: ({data: {testsPrescribed = []}}) => {
              //       const testId = testsPrescribed.map((item) => item._id);
              //       if (!testsPrescribed?.length) {
              //         return {};
              //       } else {
              //         return {_id: {$nin: testId}};
              //       }
              //     },
              //   },
              // ),
              // {
              //   type: 'text',
              //   label: 'Prescription',
              //   field: 'prescription',
              // },
              // {
              //   type: 'file',
              //   label: 'Upload Prescription',
              //   field: 'uploadPrescription',
              // },
            ],
          },
        ],
      },
      {
        label: 'Family Members',
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                type: 'custom',
                render: (props) => {
                  const clonedProps = omit(props, 'data');
                  return (
                    <PatientList
                      {...clonedProps}
                      addOnFilter={{familyId: props?.data?.familyId}}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default DetailPatient;
