import {colors, bgs} from '../theme/colors';
import {
  h8_SansSemiBold,
  h14_Regular,
  h12_Regular,
  h12_Regular_500,
  h12_SemiBold,
  // h14_SansRegular,
} from './fonts';
let {themeColor, defaultThemeColor} = colors;
let {lightGrayColor} = bgs;

const navigationBarStyle = {
  headerTextStyle: {
    ...h12_Regular,
    color: defaultThemeColor,
    paddingTop: 12,
    paddingBottom: 6,
  },
  activeHeaderTextStyle: {
    ...h12_SemiBold,
    color: themeColor,
    paddingTop: 12,
    paddingBottom: 6,
  },
  containerChildrenStyle: {
    flexDirection: 'row',
    height: 72,
    paddingRight: 20,
    paddingLeft: 20,
    borderBottomWidth: 2,
    borderBottomColor: lightGrayColor,
  },
  containerStyle: {
    boxShadow: '0 2px 6px 0 rgba(154, 154, 154, 0.14)',
    backgroundColor: '#ffffff',
    // zIndex:1,
    overflow: 'hidden',
    paddingLeft: 30,
    paddingRight: 30,
    height: 79,
  },
  tochableContainerTabStyle: {
    // flexDirection: 'row',
    alignItems: 'center',
  },
  imageIconStyle: {
    width: 24,
    height: 24,
  },
  notificationStyle: {
    width: 25,
    height: 22,
    paddingRight: 5,
    marginRight: 24,
  },
  bottomChildrenStyle: {flex: 1, flexDirection: 'row', overflow: 'hidden'},
  flagImageStyle: {
    // paddingTop: 15,
    // paddingBottom: 15,
    // paddingRight: 15,
    height: 40,
    width: 140,
    objectFit:"contain"
  },
  rightHeaderStyle: {
    alignItems: 'center',
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  containerTabStyle: {
    paddingRight: 10,
    paddingLeft: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '8%',
    paddingTop: 20,
    cursor: 'pointer',
  },
  bottomBorderStyle: {width: 46, height: 3},
  modalTextStyle: {
    ...h12_Regular,
    color: 'white',
    paddingTop: 12,
    // paddingBottom: 6,
  },
};
// const appHeaderThemeSM = {
//   headerTextStyle: {...h8_SansSemiBold, color: textColor70},
//   containerChildrenStyle: {
//     flexDirection: 'row',
//     justifyContent: 'center',
//     height: 54,
//     paddingRight: 10,
//     paddingLeft: 10,
//     borderBottomWidth: 2,
//     borderBottomColor: lightGrayColor,
//   },
//   containerStyle: {flex: 1, overflow: 'hidden'},
//   tochableContainerTabStyle: {
//     // flexDirection: 'row',
//     alignItems: 'center',
//   },
//   imageIconStyle: {
//     width: 15,
//     height: 15,
//     objectFit: 'contain',
//     paddingRight: 2,
//   },
//   bottomChildrenStyle: {flex: 1, flexDirection: 'row', overflow: 'hidden'},
//   flagImageStyle: {
//     marginTop: 15,
//     height: 25,
//     width: 25,
//     objectFit: 'contain',
//     marginBottom: 10,
//     marginRight: 5,
//   },
//   rightHeaderStyle: {
//     alignItems: 'center',
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'flex-end',
//   },
//   containerTabStyle: {
//     paddingRight: 5,
//     paddingLeft: 5,
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     minWidth: '7%',
//     paddingTop: 10,
//     cursor: 'pointer',
//   },
//   bottomBorderStyle: {width: 25, height: 3},
// };
export {navigationBarStyle};
