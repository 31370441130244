import React from 'react';
import {
  TouchableOpacity,
  Image,
  View,
  Text,
  isMobile,
  isLocalHost,
} from '../../app-components';
import {redCloseIcon} from '../../images';
import uuid from 'uuid/v4';
import {h14_AvenirNext, h14_Regular} from '../../theme/fonts';
import {getImageUrl, getUser} from '../../AppServices';
import {get} from 'lodash';

export const closeButton = (props, style, indexCondition = false) => {
  let {item, index, removeRow} = props;
  if (indexCondition && index === 0) {
    return <View />;
  }
  return (
    <TouchableOpacity
      onPress={() => removeRow({item, index})}
      style={
        style
          ? style
          : {
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }
      }>
      <Image
        source={redCloseIcon}
        style={{
          width: 20,
          height: 20,
        }}
      />
    </TouchableOpacity>
  );
};

export const getNewId = () => `new_${uuid()}`;

export const tableText = (data, isHover = false) => {
  let label = '';
  let hover = false;
  if (typeof data === 'string') label = data;
  if (isHover) hover = isHover;
  const {text = '', isHover: dataHover, style = {}, ...restData} = data || {};
  if (text) label = text;
  if (dataHover) hover = dataHover;
  return (
    <Text
      style={{
        ...h14_Regular,
        color: hover ? '#3896c9' : 'rgba(56, 56, 56, 0.87)',
        ...style,
      }}
      {...restData}>
      {label}
    </Text>
  );
};

export const imageView = ({imgArray, navigation}) => {
  return (
    <View style={{flexDirection: 'row', marginTop: 5}}>
      {Array.isArray(imgArray) && imgArray.length
        ? imgArray.map((imgItem, index) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.push({
                    view: 'pdfReader',
                    params: {
                      dataArr: imgArray,
                      clickedIndex: index,
                    },
                    modal: true,
                    modalProps: {
                      autoHide: true,
                      minHeight: 200,
                      height: 600,
                      width: isMobile ? 300 : 650,
                      position: 'screenCenter',
                    },
                  });
                }}
                style={{marginLeft: 5, cursor: 'pointer'}}>
                <Image
                  source={getImageUrl(imgItem?.file)}
                  style={{height: 60, width: 60}}
                />
              </TouchableOpacity>
            );
          })
        : void 0}
    </View>
  );
};

export const serialNumber = ({index = 0}) => {
  return (
    <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
      {index + 1}
    </Text>
  );
};

export const resolveName = (data) => {
  let {name = []} = data || {};
  let fullName = '';
  if (Array.isArray(name) && name.length) {
    const {given = [], family = ''} = name[0] || {};
    fullName = given.join(' ') + ' ' + family;
  }
  return typeof name === 'string' ? name : fullName;
};

export const resolveMobileAndEmail = (data) => {
  const {telecom = []} = data || {};
  let email = '',
    mobile = '';
  if (Array.isArray(telecom) && telecom.length) {
    for (let value of telecom) {
      const {system = '', value: telecomValue = ''} = value;
      if (system === 'email') {
        email = telecomValue || '';
      } else if (system == 'phone') {
        mobile = telecomValue || '';
      }
    }
  }
  return {email, mobile};
};

export const isTimerVisible = () => {
  const {organization: {enable_timer = false} = {}, userType} = getUser() || {};
  if (isLocalHost) {
    return false;
  }
  if (
    userType === 'Doctor' ||
    userType === 'CareCordinator' ||
    userType === 'Nurse'
  ) {
    return enable_timer;
  }
  return false;
};

export const arrayToString = ({arr = [], field = ''}) => {
  const result = [];
  Array.isArray(arr) &&
    arr.length &&
    arr.forEach((val, index) => {
      let str = '';
      if (typeof val === 'string') {
        str = val;
      } else {
        str = get(val, field, '');
      }
      if (str.length > 0) {
        result.push(str);
      }
    });
  return result.join(', ');
};
