import React, {useState, useEffect} from 'react';
import Format from '../GenericComponent/ClinicSignupFormat';
import {
  View,
  TouchableOpacity,
  Text,
  isMobile,
  Dimensions,
} from '../../app-components';
import {GooglePlaceMapInput} from '../../app-components/input-components/Editors';
import {TextInput} from '../../app-components/input-components/Editors';
import {
  h11_Medium,
  h13_Regular,
  h14_AvenirNext,
  h15_AvenirNext_500,
  h16_Medium,
  h22_Medium,
} from '../../theme/fonts';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {
  getGooglePlaceDetail,
  googlePlaceFetch,
  invoke,
} from '../../AppServices';
import {defaultProps} from '../../autoSuggestions';
import {isEmpty} from 'lodash';
const {height, width} = Dimensions.get('window');
const AlreadyRegister = (props) => {
  const {
    organizationName = '',
    setAlreadyExist = () => {},
    alreadyExists = {},
  } = props;
  const {name = '', mobile = '', email = ''} = alreadyExists || {};
  return (
    <View
      style={{
        minWidth: isMobile ? width * 0.75 : 550,
        maxWidth: isMobile ? width * 0.75 : 550,
        height: isMobile ? height * 0.65 : 400,
        justifyContent: 'space-between',
      }}>
      <View>
        <Text style={{...h22_Medium, marginTop: 20}}>
          Your Organization already register
        </Text>
        <Text style={{...h14_AvenirNext, marginTop: 20, marginBottom: 10}}>
          Organizations full name
        </Text>
        <View style={{paddingRight: isMobile ? 0 : 150}}>
          <TextInput
            value={organizationName}
            editable={false}
            variant="filled"
            placeholder={'Organization Name'}
          />
        </View>
        <View
          style={{
            backgroundColor: '#FFEDED',
            padding: 8,
            marginTop: 10,
            borderRadius: 5,
          }}>
          <Text style={{...h16_Medium}}>
            Your organization is already registered with us. To register as a
            new admin, please contact to the existing admin as it's invite only
            registration.
          </Text>
        </View>
        <View style={{marginTop: 10, flexDirection: 'row'}}>
          <View
            style={{
              height: 54,
              width: 54,
              borderRadius: 5,
              backgroundColor: '#F8F8F8',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text style={{...h22_Medium, fontWeight: 800, color: '#d6d6d6'}}>
              {name && name.length ? name[0].toUpperCase() : ''}
            </Text>
          </View>
          <View style={{marginLeft: 10, marginRight: 10}}>
            <Text style={{...h15_AvenirNext_500, fontWeight: 800}}>
              {name || ''}
            </Text>
            <Text style={{...h13_Regular, color: 'gray'}}>{email || ''}</Text>
            <Text style={{...h13_Regular, color: 'gray'}}>{mobile || ''}</Text>
          </View>
        </View>
      </View>

      <View
        style={{alignItems: isMobile ? 'center' : 'flex-end', marginTop: 10}}>
        <TouchableOpacity
          onPress={() => {
            setAlreadyExist({});
          }}
          style={{width: 120, cursor: 'pointer'}}>
          <GradientButton label="Back" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const Signup = (props) => {
  const {navigation = {}} = props || {};
  const {state: {params: {type, completed = false} = {}} = {}} =
    navigation || {};
  const [organizationName, setOrganizationName] = useState('');
  const [organizationAddress, setOrganizationAddress] = useState({});
  const [error, setError] = useState({});
  const [alreadyExists, setAlreadyExist] = useState({});
  useEffect(() => {
    if (completed) {
      setOrganizationName('');
      setOrganizationAddress({});
    }
  }, [completed]);
  if (!isEmpty(alreadyExists)) {
    return (
      <AlreadyRegister
        {...props}
        alreadyExists={alreadyExists}
        organizationName={organizationName}
        setAlreadyExist={setAlreadyExist}
      />
    );
  }
  return (
    <View
      style={{
        minWidth: isMobile ? width * 0.75 : 550,
        maxWidth: isMobile ? width * 0.75 : 550,
        height: isMobile ? height * 0.65 : 400,
        justifyContent: 'space-between',
      }}>
      <View style={{paddingRight: isMobile ? 0 : 150}}>
        <Text style={{...h14_AvenirNext, marginTop: 10, marginBottom: 10}}>
          Write your Clinic full name
        </Text>
        <TextInput
          value={organizationName}
          onChangeText={(value) => {
            setOrganizationName(value);
          }}
          variant="filled"
          placeholder={'Clinic Name'}
        />
        {error && error?.type === 'name' ? (
          <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
        ) : (
          void 0
        )}
        <Text style={{...h14_AvenirNext, marginTop: 10, marginBottom: 10}}>
          Address
        </Text>
        <GooglePlaceMapInput
          isSpace={true}
          onChangeValue={(value) => {
            setOrganizationAddress(value);
          }}
          mapHeight={height * 0.3}
          value={organizationAddress}
          {...defaultProps(
            {valueField: 'description', suggestionField: 'description'},
            void 0,
            {
              type: 'googlePlaceMap',
              fetch: googlePlaceFetch,
              modifySelectedData: getGooglePlaceDetail,
              searching: true,
            },
          )}
          variant="filled"
        />
        {error && error?.type === 'address' ? (
          <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
        ) : (
          void 0
        )}
      </View>
      <View
        style={{
          alignItems: isMobile ? 'center' : 'flex-end',
          marginTop: 10,
        }}>
        <TouchableOpacity
          onPress={async () => {
            // setAlreadyExist(true)
            // navigation.push({
            //   view: 'clinc-details',
            //   params: {data: {organizationAddress, organizationName}},
            //   expanded: true,
            // });
            if (!organizationName.length) {
              setError({type: 'name', value: 'Please enter'});
            } else if (isEmpty(organizationAddress)) {
              setError({type: 'address', value: 'Please enter'});
            } else {
              await invoke({
                id: 'checkRegisteredAddress',
                paramValue: {
                  data: {address: organizationAddress},
                },
              }).then((res) => {
                const {data = {}} = res || {};
                // result: "success"
                if (data?.result === 'success') {
                  navigation.push({
                    view: 'clinc-details',
                    params: {
                      data: {organizationAddress, organizationName},
                      type,
                    },
                    expanded: true,
                  });
                } else {
                  setAlreadyExist(data);
                }
              });
            }
          }}
          style={{width: 120, cursor: 'pointer'}}>
          <GradientButton label="Next" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (props) => (
  <Format {...props} backIconVisible={false} title="Register Your Clinic">
    <Signup {...props} />
  </Format>
);
