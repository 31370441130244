import React, {Component} from 'react';
import {View, ToolBar} from '../index';
import uuid from 'uuid/v4';
import FloatingActions from '../action/FloatingActions';
import {
  screenHeaderToolBarTheme,
  screenFooterToolBarTheme,
} from '../../theme/toolBarTheme';

export class ScreenComponent extends Component {
  state = {};
  constructor(props) {
    super(props);
    let {uid, fetchUriEvent} = props;
    this.fetchUriEvent = fetchUriEvent || `${uid || uuid()}_fetchUri`;
  }

  setScreenData = ({state} = {}) => {
    this.setState({
      data: state?.data,
      fetchMoreProps: state?.fetchMoreProps,
      aggregates: state?.aggregates,
    });
  };

  componentDidMount() {
    const {eventDispatcher} = this.props;
    eventDispatcher?.listen &&
      eventDispatcher.listen(
        `${this.fetchUriEvent}_loadData`,
        this.setScreenData,
      );
  }
  componentWillUnmount() {
    const {eventDispatcher} = this.props;
    eventDispatcher?.unlisten &&
      eventDispatcher.unlisten(
        `${this.fetchUriEvent}_loadData`,
        this.setScreenData,
      );
  }
  render() {
    let {
      screen: Component,
      floatingActions,
      header,
      footer,
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      ...rest
    } = this.props;

    // let navigationScreenState =
    //   (navigation &&
    //     navigation.getScreenState &&
    //     navigation.getScreenState()) ||
    //   {};
    // if (!setScreenState && navigation && navigation.setScreenState) {
    //   screenState = navigationScreenState;
    //   setScreenState = navigation.setScreenState;
    // }
    let componentProps = {
      fetchUriEvent: this.fetchUriEvent,
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
    };

    return (
      <View style={{flex: 1, overflow: 'hidden'}}>
        {header && (
          <ToolBar
            {...screenHeaderToolBarTheme}
            {...componentProps}
            {...header}
          />
        )}
        <Component
          {...componentProps}
          {...rest}
          dataParams={screenState && screenState.dataParams}
        />
        {footer && (
          <ToolBar
            {...screenFooterToolBarTheme}
            {...componentProps}
            {...footer}
          />
        )}
        <FloatingActions
          floatingActions={floatingActions}
          {...componentProps}
          {...rest}
        />
      </View>
    );
  }
}

export default ScreenComponent;
