import { cancelButton } from '../../app-components/action/CloseAction';
import { autoSuggestInput } from '../../autoSuggestions';
import Form from '../../app-components/form/Form';
import { submit } from '../../AppServices';
let header = {
    title: 'User Detail',
    titleTextStyle: {
        color: 'rgba(56, 56, 56, 0.87)',
        fontSize: 20,
        marginLeft: 10,
        fontFamily: 'AvenirNext-Regular',
    },

    actions: [
        cancelButton({ label: ' Back ' }),
    ],
};

const editable = false;
export default Form({
    reloadEvent: 'reloadPatient',
    reloadOnChangeEvent: 'reloadPatient',

    type: 'standardShadow',
    closeView: 1,
    fieldVariant: 'filled',
    validateOnChange: true,

    onSubmit: submit({
        model: 'User',
    }),

    header: header,
      defaultValues: ({ navigation }) => {
        const { state: { params: { item = {} } = {} } = {} } = navigation || {};
        return {
          ...item,
        };
      },
    formGroups: [
        {
            editable: editable,
            direction: 'row',
            fieldVariant: 'filled',
            groups: [
                {
                    columnsPerRow: 2,
                    columns: [
                        {
                            type: 'text',
                            label: 'Name',
                            field: 'name',
                        },
                        {
                            type: 'text',
                            label: 'Phone Number',
                            field: 'mobile',
                        },
                        {
                            type: 'text',
                            label: 'Email',
                            field: 'email',
                        },
                        autoSuggestInput({
                            label: 'Role',
                            placeholder: 'Select Role',
                            options: [
                                { name: 'ANM', value: 'ANM' },
                                { name: 'MO', value: 'MO' }
                            ],
                            valueField: 'name',
                            suggestionField: 'name',
                            field: 'userType'
                        }),
                    ],
                }]
        }]
})

