import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {searchInput} from '../../autoSuggestions';
export default ({uri, header}) => {
 return Form({
    type: 'standardShadow',
    uri,
    onSubmit: submit({
      model: 'CareProgramSpeciality',
    }),
    reloadEvent: 'reloadCareProgram',
    closeView: 1,
    mandatory: {speciality: 1,tileLabel: 1,tileIcon: 1},
    // computations: {
    //   self: {
    //     title: {
    //       compute: (data = {}) => {
    //         let title = data?.speciality?.name;
    //         return {
    //           set: {
    //             title,
    //           },
    //         };
    //       },
    //       onChange: ['speciality', 'speciality._id'],
    //     },
    //   },
    // },
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 3,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                searchInput({
                  field: 'speciality',
                  query: 'specialityLists',
                  model: 'Specialities',
                  placeholder: 'Select Speciality',
                  idField: '_id',
                  searchField: 'name',
                  suggestionField: 'name',
                  valueField: 'name',
                  label: 'Speciality',
                  mandatory: true
                }),
                {
                  type: 'text',
                  field: 'tileLabel',
                  label: 'Tile label',
                  placeholder: "Tile label",
                  mandatory: true,
                },
                {
                  field: 'tileIcon',
                  placeholder: 'File',
                  label: 'Tile Icon',
                  uploadOptions: {s3: true},
                  type: 'file',
                },
              ],
            },
            {
              columnsPerRow:3,
              columns: [                
                {
                  type: 'checkBox',
                  field: 'CKD',
                  label: 'Enable CKD',
                },
                {
                  type: 'checkBox',
                  field: 'GFR',
                  label: 'Enable GFR',
                },
                {
                  type: 'checkBox',
                  field: 'EF',
                  label: 'Enable EF',
                },
                {
                  type: 'checkBox',
                  field: 'CHF',
                  label: 'Enable CHF',
                },
                {
                  type: 'checkBox',
                  field: 'Creatinine',
                  label: 'Enable Creatinine',
                },
                {
                  type: 'checkBox',
                  field: 'Etiology',
                  label: 'Enable Etiology',
                },
                {
                  type: 'checkBox',
                  field: 'Albumin',
                  label: 'Enable Albumin',
                },

                // {
                //   type: 'checkBox',
                //   field: 'DietPlan',
                //   label: 'Enable Diet Plan',
                // },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                searchInput({
                  field: 'speciality',
                  query: 'specialityLists',
                  model: 'Specialities',
                  placeholder: 'Select Speciality',
                  idField: '_id',
                  searchField: 'name',
                  suggestionField: 'name',
                  valueField: 'name',
                  label: 'Speciality',
                }),
                {
                  type: 'text',
                  field: 'tileLabel',
                  label: 'Tile label',
                  placeholder: "Tile label",
                  mandatory: true,
                },
                {
                  field: 'tileIcon',
                  placeholder: 'File',
                  label: 'Tile Icon',
                  uploadOptions: {s3: true},
                  type: 'file',
                },
              ],
            },
            {
              columnsPerRow:3,
              columns: [                
                {
                  type: 'checkBox',
                  field: 'CKD',
                  label: 'Enable CKD',
                },
                {
                  type: 'checkBox',
                  field: 'GFR',
                  label: 'Enable GFR',
                },
                {
                  type: 'checkBox',
                  field: 'EF',
                  label: 'Enable EF',
                },
                {
                  type: 'checkBox',
                  field: 'CHF',
                  label: 'Enable CHF',
                },
                {
                  type: 'checkBox',
                  field: 'Creatinine',
                  label: 'Enable Creatinine',
                },
                {
                  type: 'checkBox',
                  field: 'Etiology',
                  label: 'Enable Etiology',
                },
                {
                  type: 'checkBox',
                  field: 'Albumin',
                  label: 'Enable Albumin',
                },

                // {
                //   type: 'checkBox',
                //   field: 'DietPlan',
                //   label: 'Enable Diet Plan',
                // },
              ],
            },
          ],
        },
      ],
    },
  });
};
