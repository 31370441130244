import React from 'react';
import {Text, TouchableOpacity} from '../../app-components';
import {moreIcon, userIcon} from '../../images';
import Table from '../../app-components/table/Table';
const data = [
  {
    name: 'Dr. Ajay Trihan',
    position: 'Director Gastroenterology, Hepatology & Therapeutic Endoscopy',
    firstQualification: 'Fellowship on Sleep Medicine',
    secondQualification: 'Fellowship on Stroke Neurology',
    firstSpecialization: 'Sleep Disorders',
    secondSpecialization: 'Stroke',
    thirdSpecialization: 'Restless leg Syndrome',
    fee: 100,
    time: 15,
    experience: 10,
  },
  {
    name: 'Dr. Ajay Trihan',
    position: 'Director Gastroenterology, Hepatology & Therapeutic Endoscopy',
    firstQualification: 'Fellowship on Sleep Medicine',
    secondQualification: 'Fellowship on Stroke Neurology',
    firstSpecialization: 'Sleep Disorders',
    secondSpecialization: 'Stroke',
    thirdSpecialization: 'Restless leg Syndrome',
    fee: 100,
    time: 15,
    experience: 10,
  },
  {
    name: 'Dr. Ajay Trihan',
    position: 'Director Gastroenterology, Hepatology & Therapeutic Endoscopy',
    firstQualification: 'Fellowship on Sleep Medicine',
    secondQualification: 'Fellowship on Stroke Neurology',
    firstSpecialization: 'Sleep Disorders',
    secondSpecialization: 'Stroke',
    thirdSpecialization: 'Restless leg Syndrome',
    fee: 100,
    time: 15,
    experience: 10,
  },
  {
    name: 'Dr. Ajay Trihan',
    position: 'Director Gastroenterology, Hepatology & Therapeutic Endoscopy',
    firstQualification: 'Fellowship on Sleep Medicine',
    secondQualification: 'Fellowship on Stroke Neurology',
    firstSpecialization: 'Sleep Disorders',
    secondSpecialization: 'Stroke',
    thirdSpecialization: 'Restless leg Syndrome',
    fee: 100,
    time: 15,
    experience: 10,
  },
];

const cardDemo = {
  lg: {
    card: {
      title: {primaryField: 'name', secondaryField: 'position'},
      left: [
        {
          iconStyle: {Width: 80, height: 80},
          icon: userIcon,
        },
      ],

      contentItems: [
        [
          {
            field: 'experience',
            suffix: ' yrs',
            prefix: 'Exp of ',
          },

          {
            position: 'center',
            textStyle: {
              color: '#7A5AF1',
              fontSize: 8,
              paddingLeft: 8,
            },
            value: 'show Profile',
          },
        ],
      ],
      right: [
        {
          width: 300,
          items: [
            {
              value: 'QUALIFICATION',
            },
            {
              field: 'firstQualification',
            },
            {
              field: 'secondQualification',
            },
          ],
        },
        {
          width: 300,
          items: [
            {
              value: 'SPECIALIZATIONS',
            },
            {
              field: 'firstSpecialization',
            },
            {
              field: 'secondSpecialization',
            },
            {
              field: 'thirdSpecialization',
            },
          ],
        },
        {
          width: 300,
          items: [
            {
              prefix: '$',
              field: 'fee',
              textStyle: {fontSize: 18, fontWeight: 'bold'},
            },
            {
              prefix: 'for ',
              field: 'time',
              suffix: 'min',
            },
            {
              value: () => (
                <TouchableOpacity
                  style={{
                    backgroundColor: 'aqua',
                    height: 30,
                    width: 200,
                    borderRadius: 5,
                    marginTop: 8,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Text>Book Now</Text>
                </TouchableOpacity>
              ),
            },
          ],
        },
      ],
      hoverActions: [
        {
          icon: moreIcon,
          iconStyle: {height: 18, width: 6},
        },
      ],
    },
  },
  md: {
    card: {
      title: {primaryField: 'name', secondaryField: 'position'},

      left: [
        {
          iconStyle: {Width: 50, height: 50},
          icon: userIcon,
        },
      ],

      contentItems: [
        [
          {
            field: 'experience',
            suffix: ' yrs',
            prefix: 'Exp of ',
          },

          {
            position: 'center',
            textStyle: {
              color: '#7A5AF1',
              fontSize: 8,
              paddingLeft: 8,
            },
            value: 'show Profile',
          },
        ],
      ],
      right: [
        {
          width: 150,
          items: [
            {
              prefix: '$',
              field: 'fee',
              textStyle: {fontSize: 18, fontWeight: 'bold'},
            },
            {
              prefix: 'for ',
              field: 'time',
              suffix: 'min',
            },
            {
              value: () => (
                <TouchableOpacity
                  style={{
                    backgroundColor: 'aqua',
                    height: 30,
                    width: 150,
                    borderRadius: 5,
                    marginTop: 8,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Text>Book Now</Text>
                </TouchableOpacity>
              ),
            },
          ],
        },
      ],
      hoverActions: [
        {
          icon: moreIcon,
          iconStyle: {height: 18, width: 6},
        },
      ],
    },
  },
  sm: {
    card: {
      title: {primaryField: 'name', secondaryField: 'position'},
      left: [
        {
          iconStyle: {Width: 30, height: 30},
          icon: userIcon,
        },
      ],

      contentItems: [
        [
          {
            field: 'experience',
            suffix: ' yrs',
            prefix: 'Exp of ',
          },

          {
            position: 'center',
            textStyle: {
              color: '#7A5AF1',
              fontSize: 8,
              paddingLeft: 8,
            },
            value: 'show Profile',
          },
        ],
      ],

      hoverActions: [
        {
          icon: moreIcon,
          iconStyle: {height: 18, width: 6},
        },
      ],
    },
  },
};
export default Table({data, ...cardDemo});
