import React from 'react';
import {cancel} from '../../../../app-components/action/CancelAction';
import {save} from '../../../../app-components/action/SaveAction';
import ClinicAdminFrom from './ClinicAdminFrom';
import {EditClinicAdmin} from '../../../../Queries';
import CustomHeaderLabel from '../../../../app-components/CustomHeaderLabel';

// const header = {
//   title: 'Edit Clinic Admin',
//   actions: [cancel(), save({label: 'Save'})],
// };
const header = {
  hideBackIcon: true,
  title: ({navigation}) => {
    const {state: {params: {item} = {}} = {}} = navigation;
    return (
      <CustomHeaderLabel
        seperator={true}
        preLabel={'Edit User Detail'}
        postLabel={item?.name}
      />
    );
  },
  actions: [cancel(), save()],
};

export default ClinicAdminFrom({header, uri: EditClinicAdmin});
