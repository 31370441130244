import React from 'react';
import {
  TouchableOpacity,
  Animated,
  Platform,
} from '../../react-core-components';
import {WithInputWrapper} from '../../react-input-wrapper';

class Switch extends React.Component {
  state = {animation: new Animated.Value(0)};
  moveThumb = (value) => {
    Animated.timing(this.state.animation, {
      toValue: value ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };
  thumbPosition = (customValue) => {
    let {width = 100, height = 18, thumbSize, value} = this.props;
    if (customValue === false || customValue === true) {
      value = customValue;
    }
    if (!thumbSize) {
      thumbSize = height - 4;
    }
    let thumbPosition = 0;
    if (thumbSize <= height) {
      if (value) {
        thumbPosition = width - thumbSize - (height - thumbSize) / 2;
      } else {
        thumbPosition = (height - thumbSize) / 2;
      }
    } else {
      if (value) {
        thumbPosition = width - thumbSize;
      } else {
        thumbPosition = 0;
      }
    }
    return thumbPosition;
  };
  componentDidUpdate(prevProps) {
    if (Platform.OS !== 'web') {
      if (prevProps.value !== this.props.value) {
        this.moveThumb(this.props.value);
      }
    }
  }
  componentDidMount() {
    Platform.OS !== 'web' && this.moveThumb(this.props.value);
  }
  render() {
    let {
      value,
      height = 18,
      width = 100,
      borderRadius = 10,
      onChangeValue,
      tintColor = '#1ed69e',
      activeTintColor,
      thumbColor = '#ffffff',
      disabled,
      thumbSize,
    } = this.props;
    if (!activeTintColor) {
      activeTintColor = tintColor;
    }
    let thumbBorderColor = 'transparent';
    if (!thumbSize) {
      thumbSize = height - 4;
    }
    let thumbPosition = 0;
    if (Platform.OS === 'web') {
      thumbPosition = this.thumbPosition();
    } else {
      thumbPosition = this.state.animation.interpolate({
        inputRange: [0, 1],
        outputRange: [this.thumbPosition(false), this.thumbPosition(true)],
      });
    }
    if (Platform.OS === 'web') {
      if (value) {
        tintColor = activeTintColor;
      }
    } else {
      tintColor = this.state.animation.interpolate({
        inputRange: [0, 1],
        outputRange: [tintColor, activeTintColor],
      });
    }
    if (height <= thumbSize) {
      thumbBorderColor = tintColor;
    }
    return (
      <Animated.View
        style={{
          height,
          width,
          cursor: disabled ? 'not-allowed' : 'pointer',
          webkitTapHighlightColor: 'transparent',
          borderRadius,
          backgroundColor: tintColor,
          opacity: disabled ? 0.3 : 1,
          transition: '.5s',
        }}>
        <TouchableOpacity
          activeOpacity={1}
          style={{
            flex: 1,
            backgroundColor: 'transparent',
          }}
          onPress={
            disabled
              ? void 0
              : (e) => {
                  onChangeValue && onChangeValue(!value, e);
                }
          }>
          <Animated.View
            style={{
              position: 'absolute',
              top: (height - thumbSize) / 2,
              left: thumbPosition,
              height: thumbSize,
              width: thumbSize,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: thumbBorderColor,
              borderRadius: thumbSize / 2,
              transition: '.3s',
              backgroundColor: thumbColor,
            }}></Animated.View>
        </TouchableOpacity>
      </Animated.View>
    );
  }
}

export {Switch};

export default WithInputWrapper(Switch);
