import React from 'react';
import Form from '../../../app-components/form/Form';
import {
  autoSuggestOptionsInput,
  googlePlaceInput,
  searchInput,
} from '../../../autoSuggestions';
// import {backIcon} from '../../../images';
import {getUser, submit} from '../../../AppServices';
import {cancelButton, back} from '../../../app-components/action/CloseAction';
import {save} from '../../../app-components/action/SaveAction';
import {EditOrganizationQuerry} from '../../../Queries';
import CustomHeaderLabel from '../../../app-components/CustomHeaderLabel';
import {moreActionsShadow} from '../../../theme/shadows';

export default Form({
  closeView: 1,
  uri: EditOrganizationQuerry,
  onSubmit: submit({
    model: 'Organizations',
    lowerCaseFields: ['email'],
  }),
  mandatory: {},
  fieldVariant: 'filled',
  fieldContainer: 'topLabel',
  lg: {
    header: {
      leftAction: {...back},
      title: ({navigation}) => {
        const {state: {params: {item} = {}} = {}} = navigation;
        return (
          <CustomHeaderLabel
            preLabel={'Edit Clinic Profile'}
            postLabel={item?.organization_name}
          />
        );
      },
      // title: 'Edit Clinic Summary',
      actions: [cancelButton(), save()],
    },
    formGroups: [
      {
        // label: 'General Information',
        direction: 'row',
        groups: [
          {
            width: 300,
            columns: [
              {
                type: 'uploadImage',
                uploadOptions: {s3: true},
                field: 'organization_logo',
                label: 'Organization Logo',
              },
            ],
          },
          {
            columnsPerRow: 2,
            columns: [
              autoSuggestOptionsInput({
                label: 'Status',
                field: 'activity_status',
                suggestionField: 'label',
                options: [
                  {label: 'Active', value: 'active'},
                  {label: 'Inactive', value: 'inactive'},
                ],
                dropdownStyle: {
                  height: 100,
                  borderRadius: 8,
                  backgroundColor: '#ffffff',
                  ...moreActionsShadow,
                },
                // width: 200,
              }),
              {
                type: 'text',
                field: 'organization_name',
                label: 'Organisation Name',
                placeholder: 'Organisation Name',
              },
              {
                type: 'text',
                field: 'email',
                label: 'E-mail',
                placeholder: 'E-mail',
              },
              {
                type: 'mobile',
                field: 'organization_mobile',
                label: 'Mobile no.',
                placeholder: 'Mobile no.',
                disabled: true,
              },
              {
                type: 'number',
                field: 'deduction_margin',
                label: 'Commission in %',
                placeholder: 'Enter Commission',
                // inputProps: {maxLength: 10,type:'number'},
                visible: () => {
                  let {userType} = getUser();
                  return userType === 'SuperAdmin' ? true : false;
                },
              },
              searchInput({
                label: 'Payment gateway',
                field: 'payment_gateway',
                query: 'gatewayList',
                model: 'PaymentGateways',
                placeholder: 'Select gateway',
                idField: '_id',
                searchField: 'name',
                // width: 140,
                visible: () => {
                  let {userType} = getUser();
                  return userType === 'SuperAdmin' ? true : false;
                },
                showSelected: false,
                suggestionField: 'name',
                valueField: 'name',
                renderSelector: false,
              }),
              {
                type: 'checkBox',
                field: 'is_emergency',
                label: 'Emergency Hospital',
                visible: () => {
                  let {userType} = getUser();
                  return userType === 'SuperAdmin' ? true : true;
                },
              },
              {
                type: 'checkBox',
                field: 'is_dialysis',
                label: 'Enable Dialysis',
                visible: () => {
                  let {userType} = getUser();
                  return userType === 'SuperAdmin' ? true : true;
                },
              },
              {
                type: 'checkBox',
                field: 'enable_timer',
                label: 'Enable Timer',
                visible: () => {
                  let {userType} = getUser();
                  return userType === 'SuperAdmin';
                },
              },

            ],
          },
          {
            width: 350,
            columns: [
              googlePlaceInput({
                field: 'organization_address',
                placeholder: 'Select Address',
                label: 'Select Address',
                mapHeight: 300,
              }),
            ],
          },
          {width: 87},
        ],
      },
    ],
  },
  sm: {
    header: {
      leftAction: {...back},
      title: ({navigation}) => {
        const {state: {params: {item} = {}} = {}} = navigation;
        return (
          <CustomHeaderLabel
            preLabel={'Edit Clinic Profile'}
            postLabel={item?.organization_name}
          />
        );
      },
      // title: 'Edit Clinic Summary',
    },
    footer: {
      actions: [cancelButton(), save()],
    },
    formGroups: [
      {
        columnsPerRow: 1,
        columns: [
          {
            type: 'uploadImage',
            uploadOptions: {s3: true},
            field: 'organization_logo',
            label: 'Organization Logo',
          },
          autoSuggestOptionsInput({
            label: 'Status',
            field: 'activity_status',
            suggestionField: 'label',
            options: [
              {label: 'Active', value: 'active'},
              {label: 'Inactive', value: 'inactive'},
            ],
            dropdownStyle: {
              height: 100,
              borderRadius: 8,
              backgroundColor: '#ffffff',
              ...moreActionsShadow,
            },
            width: 200,
          }),
          {
            type: 'text',
            field: 'organization_name',
            label: 'Organisation Name',
            placeholder: 'Organisation Name',
          },
          {
            type: 'text',
            field: 'email',
            label: 'E-mail',
            placeholder: 'E-mail',
          },
          {
            type: 'mobile',
            field: 'organization_mobile',
            label: 'Mobile no.',
            placeholder: 'Mobile no.',
            disabled: true,
          },
        ],
      },
      {
        width: 350,
        columns: [
          googlePlaceInput({
            field: 'organization_address',
            placeholder: 'Select Address',
            label: 'Select Address',
            mapHeight: 300,
          }),
        ],
      },
    ],
  },
});
