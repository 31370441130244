import React from 'react';
import {
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  View,
  WithModalButton,
} from '../../app-components';

import {WithScreenContext} from '../../app-components/screen/ScreenContext';
import {arrowDownIcon} from '../../images';

import {h14_Regular} from '../../theme/fonts';
import {bgs, colors} from '../../theme/colors';
import {dropDownShadow} from '../../theme/shadows';
let {cardHighlight} = bgs;
let {textColor87} = colors;

class Sort extends React.Component {
  onPress = ({field, asc = true, shownValue}) => {
    let {
      action,
      screenContext: {setScreenContextValue},
    } = this.props;

    let sortOrder = asc ? 1 : -1;
    setScreenContextValue({sort: {[field]: sortOrder}});
  };

  render() {
    let {screenContext: {sort = {}} = {}} = this.props;
    let shownSortValue = 'Sort By';
    let sortConatiner = [
      {field: 'created_on', asc: false, shownValue: 'Created On'},
      {field: 'due_date', asc: false, shownValue: 'Due On'},
    ];
    sortConatiner.forEach(elem => {
      let {field, shownValue} = elem;
      if (sort && sort.hasOwnProperty(field)) {
        shownSortValue = shownValue;
      }
    });

    const renderModal = ({frameStyle, hideModal}) => {
      let modalStyle = {
        ...dropDownShadow,
        height: 200,
        width: 180,
        backgroundColor: cardHighlight,
      };

      return (
        <TouchableOpacity style={{...frameStyle, ...modalStyle}}>
          <ScrollView style={{flex: 1, marginLeft: 5, marginRight: 1}}>
            {sortConatiner.map((value, index) => {
              return (
                <View
                  style={{
                    paddingTop: 5,
                    paddingLeft: 9,
                    paddingBottom: 5,
                    cursor: 'pointer',
                  }}
                  onClick={() => this.onPress(value)}>
                  <Text style={{...h14_Regular, color: textColor87}}>
                    {value.shownValue || ''}
                  </Text>
                </View>
              );
            })}
          </ScrollView>
        </TouchableOpacity>
      );
    };

    return (
      <WithModalButton renderModal={renderModal} autoHide={true}>
        <View
          style={{
            paddingTop: 11,
            paddingBottom: 11,
            paddingLeft: 8,
            paddingRight: 8,
            marginTop: 14,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: 'rgba(96, 212, 161,0.2)',
          }}>
          <Text
            style={{
              ...h14_Regular,
              minWidth: 125,
              paddingRight: 20,
              color: textColor87,
            }}>
            {shownSortValue}
          </Text>
          <Image
            source={arrowDownIcon}
            style={{flex: 1, resizeMode: 'contain', height: 7}}
          />
        </View>
      </WithModalButton>
    );
  }
}

Sort = WithScreenContext(Sort, {
  key: 'screenContext',
});

export default Sort;
