import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  // CartesianGrid,
  // Tooltip,
  Legend,
  // CartesianAxis,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
} from '../../../app-components/recharts/src';
import {View, Text} from '../../../app-components';
import {h16_Medium} from '../../../theme/fonts';

const getName = (tabName, value) => {
  if (tabName == 'Blood Pressure') {
    if (value == 'value2') {
      return 'Diastolic';
    }
    return 'Systolic';
  }
  if (tabName == 'Pulse') {
    return 'Pulse';
  }
  return value;
};

const LineSimple = (props) => {
  const {
    data = [],
    bgColor = '#FF1493',
    dataKey = 'averageValue1',
    dataKey2 = '',
    xAxisDataKey = 'key',
    lowerThreshold = 0,
    rateOfChange = 0,
    higherThreshold = 0,
    bloodPressureLowerThreshold = 0,
    bloodPressureUpperThreshold = 0,
    tabName = '',
  } = props;

  if (Array.isArray(data) && !data.length) {
    return (
      <div>
        <div
          style={{
            width: '100%',
            minWidth: 340,
            height: 330,
            position: 'relative',
            padding: 10,
            backgroundColor: bgColor,
            // marginLeft: '10%',
          }}>
          <ResponsiveContainer>
            <LineChart
              width={800}
              height={450}
              data={[
                {
                  name: 'Sunday',
                  uv: 400,
                },
                {
                  name: 'Monday',
                  uv: 300,
                },
                {
                  name: 'Tuesday',
                  uv: 200,
                },
                {
                  name: 'Wednesday',
                  uv: 270,
                },
                {
                  name: 'Thursday',
                  uv: 180,
                },
                {
                  name: 'Friday',
                  uv: 230,
                },
                {
                  name: 'Saturday',
                  uv: 340,
                },
              ]}
              margin={{
                top: 5,
                right: 30,
                bottom: 5,
                left: -25,
              }}>
              <XAxis dataKey={'name'} tickLine={false} />
              <YAxis
                width={80}
                tickFormatter={''}
                // axisLine={false}
                tickSize={5}
                tickLine={false}
                tick={{stroke: 'none'}}
              />
              <Line
                type="monotone"
                dataKey={'uv'}
                strokeWidth={2}
                stroke="transparent"
                // name={getName(tabName, dataKey)}
                // stroke={lineColors[index]}
                connectNulls
                activeDot={{r: 5}}
              />
              {/* <CartesianGrid vertical={false} strokeDasharray="3 3" /> */}
              {/* <CartesianAxis vertical={false} /> */}
            </LineChart>
          </ResponsiveContainer>
          <View
            style={{
              width: '100%',
              // minWidth: 500,
              // backgroundColor:'red',
              height: 330,
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              zIndex: 2,
            }}>
            <Text style={{...h16_Medium}}>NO DATA</Text>
          </View>
        </div>
      </div>
    );
  }

  const customYaxis = (tick) => {
    if (tick === 0) {
      return tick;
    }
    return tick + ' ';
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          minWidth: 340,
          height:330,
          position: 'relative',
          padding: 10,
          backgroundColor: bgColor,
          // marginLeft: '10%',
        }}>
        <ResponsiveContainer>
          <LineChart
            width={800}
            height={450}
            data={data}
            margin={{
              top: 5,
              right: 30,
              bottom: 5,
              left: -25,
            }}>
            <XAxis dataKey={xAxisDataKey} tickLine={false} />
            <YAxis
              width={80}
              tickFormatter={customYaxis}
              // axisLine={false}
              tickSize={5}
              tickLine={false}
              tick={{stroke: 'none'}}
            />
            {/* <CartesianGrid vertical={false} strokeDasharray="3 3" /> */}
            {/* <CartesianAxis vertical={false} /> */}
            <Tooltip
              cursor={false}
              wrapperStyle={{
                pointerEvents: 'auto',
                zIndex: 1,
              }}
              // contentStyle={{ }}
              // content={customToolTip}
            />
            <Legend />
            {dataKey ? (
              <Line
                type="monotone"
                dataKey={dataKey}
                strokeWidth={2}
                stroke="#bc5090"
                name={getName(tabName, dataKey)}
                // stroke={lineColors[index]}
                connectNulls
                activeDot={{r: 5}}
              />
            ) : null}
            {lowerThreshold || bloodPressureLowerThreshold ? (
              <ReferenceLine
                alwaysShow
                y={lowerThreshold || bloodPressureLowerThreshold}
                stroke="green"
                strokeDasharray="3"
              />
            ) : null}
            {higherThreshold || bloodPressureUpperThreshold ? (
              <ReferenceLine
                alwaysShow
                y={higherThreshold || bloodPressureUpperThreshold}
                stroke="red"
                strokeDasharray="3"
              />
            ) : null}
            {rateOfChange ? (
              <ReferenceLine
                alwaysShow
                y={rateOfChange}
                stroke="red"
                strokeDasharray="3"
              />
            ) : null}
            {dataKey2 ? (
              <Line
                type="monotone"
                dataKey={dataKey2}
                strokeWidth={2}
                stroke="#0095C9"
                name={getName(tabName, dataKey2)}
                connectNulls
                activeDot={{r: 5}}
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default LineSimple;
