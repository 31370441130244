import React from "react";
import ReactFormContext from "./ReactFormContext";
import ReactFormStateContext from "./ReactFormStateContext";

export const WithReactForm = (Component, { key = "form" }) => {
  class WithReactFormContext extends React.Component {
    render() {
      return (
        <ReactFormContext.Consumer>
          {formListener => {
            return (
              <ReactFormStateContext.Consumer>
                {formValue => {
                  let extraProps = {
                    [`${key}_context`]: formListener,
                    [`${key}_state`]: formValue
                  };
                  return <Component {...this.props} {...extraProps} />;
                }}
              </ReactFormStateContext.Consumer>
            );
          }}
        </ReactFormContext.Consumer>
      );
    }
  }
  return WithReactFormContext;
};

export const WithReactFormListener = (Component, { key = "form_context" }) => {
  class WithReactFormContext extends React.Component {
    render() {
      return (
        <ReactFormContext.Consumer>
          {formListener => {
            let extraProps = {
              [key]: formListener
            };
            return <Component {...this.props} {...extraProps} />;
          }}
        </ReactFormContext.Consumer>
      );
    }
  }
  return WithReactFormContext;
};

export const WithReactFormstate = (Component, { key = "form_state" }) => {
  class WithReactFormContext extends React.Component {
    render() {
      return (
        <ReactFormStateContext.Consumer>
          {formValue => {
            let extraProps = {
              [key]: formValue
            };
            return <Component {...this.props} {...extraProps} />;
          }}
        </ReactFormStateContext.Consumer>
      );
    }
  }
  return WithReactFormContext;
};
