import React from 'react';
import {cancel} from '../../../../app-components/action/CancelAction';
import {save} from '../../../../app-components/action/SaveAction';
import NurseForm from './NurseForm';
import CustomHeaderLabel from '../../../../app-components/CustomHeaderLabel';

const uri=({navigation})=>{
  return {
    query: {
      id: 'userLists',
      relationValue: {
        model: 'User',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'User',
  }
}
const header = {
  hideBackIcon: true,
  title: ({navigation}) => {
    const {state: {params: {item} = {}} = {}} = navigation;
    return (
      <CustomHeaderLabel
        seperator={true}
        preLabel={'Edit User Detail'}
        postLabel={item?.name}
      />
    );
  },
  actions: [cancel(), save()],
};

export default NurseForm({header, uri});
