import React from 'react';
import {
  MultiTextInputField,
  TextInput as ReactTextInput,
  PasswordInput as ReactPasswordInput,
} from '../../npms/react-text-input';
import {TextAreaInput as ReactTextAreaInput} from '../../npms/react-text-area-input';
import {NumberInput as ReactNumberInput} from '../../npms/react-number-input';
import {DatePicker} from '../../npms/react-date-input';
import {Slider} from '../../npms/react-slider';
import {TimeInput as ReactTimeInput} from '../../npms/react-time-input';
import {RadioGroup, Radio} from '../../npms/react-radio-button-v1';
import {RichTextInput as ReactRichTextInput} from '../../npms/react-rich-text-input';
import {FileInput as ReactFileInput} from '../../npms/react-file-input-v1';
import {isMobile} from '../index';
import {dateInputStyle} from '../../theme/DateBoxtheme';
import {
  checkBoxTheme,
  autoSuggestInputTheme,
  multiAutosuggestInputTheme,
  multiAutosuggestMobileInputTheme,
  multiTextInputTheme,
} from '../../theme/editorTheme';
import {
  fileInputBoxTheme,
  downloadFileBoxTheme,
  UploadImageTheme,
} from '../../theme/fileBoxTheme';
import {
  inputToggleTheme,
  toggleSwitchTheme,
  toggleLabelTheme,
  inputChipsToggleTheme,
} from '../../theme/toggleTheme';
import {
  upload,
  getDownloadUrl,
  getImageUrl,
  autosuggestFetch,
} from '../../AppServices';
import {
  backCrossIcon,
  inputErrorIcon,
  profile_preview,
  roundCross,
} from '../../images';
import {date} from '../UtilityFunctions';
import {
  renderAvatarChips,
  getAutoSuggestProps,
  OtherRenderSelector,
} from './autoSuggest/renderRowCard';
import {progressSliderTheme} from '../../theme/sliderTheme';
import {ToggleButton, ToggleSwitch} from '../../npms/react-toggle';
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  Image,
} from '../../npms/react-core-components';
import {radioGroupTheme, radioTheme} from '../../theme/radioTheme';
import {
  AutosuggestInputField,
  MultiAutosuggestInputField,
  GooglePlaceInputField,
} from '../../npms/react-autosuggest';
import FileInputRender from './file/FileInputRender';
import {getInputTheme} from './themeUtility';
import ReactCheckBox from './checkBox/CheckBox';
import GoogleMap from '../GoogleMap';
import {h13_Medium} from '../../theme/fonts';
import MobileInputForm from './MobileInputForm';
import LoadingInputButton from './LoadingInput';
import HTMLInput from './HTMLInput';
import {colors} from '../../theme/colors';
const {themeColor} = colors;
const formatters = {date};

const renderInputError = ({inputErrorStyle}) => {
  return null;
  // return <Label icon={inputErrorIcon} style={inputErrorStyle} />;
};

export const TextInput = (props) => {
  let theme = getInputTheme(props);
  return (
    <ReactTextInput {...theme} renderInputError={renderInputError} {...props} />
  );
};

export const PasswordInput = (props) => {
  let theme = getInputTheme(props);
  return (
    <ReactPasswordInput
      {...theme}
      renderInputError={renderInputError}
      {...props}
    />
  );
};

export const TextAreaInput = (props) => {
  let theme = getInputTheme(props);
  return (
    <ReactTextAreaInput
      {...theme}
      renderInputError={renderInputError}
      {...props}
    />
  );
};

export const NumberInput = (props) => {
  let theme = getInputTheme(props);
  return (
    <ReactNumberInput
      {...theme}
      renderInputError={renderInputError}
      {...props}
    />
  );
};

export const DateInput = (props) => {
  let theme = getInputTheme(props);
  return (
    <DatePicker
      format={'DD/MM/YYYY'}
      zeroTimeDate={true}
      {...theme}
      calendarStyle={dateInputStyle.dateCalendarStyle}
      renderInputError={renderInputError}
      floatLabelOnActive={Platform.OS === 'web'}
      {...props}
    />
  );
};

export const TimeInput = (props) => {
  let theme = getInputTheme(props);
  return (
    <ReactTimeInput
      {...theme}
      renderSeparator={
        <View style={{width: 10, backgroundColor: 'transparent'}} />
      }
      renderInputError={renderInputError}
      {...props}
    />
  );
};

export const ProgressSliderInput = (props) => {
  let title = ({value} = {}) => {
    let progressTitle = '';
    if (!value || value < 50) {
      progressTitle = 'Progress (just started)';
    } else if (value < 80) {
      progressTitle = 'Progress (Halfway there)';
    } else {
      progressTitle = 'Progress (Almost there)';
    }
    return progressTitle;
  };
  return <Slider {...progressSliderTheme} title={title} {...props} />;
};

export const FileInput = (props) => {
  let componentProps = {upload, getDownloadUrl, openInNewTab: true, ...props};
  return (
    <ReactFileInput
      theme={fileInputBoxTheme}
      renderInputComponent={<FileInputRender {...props} />}
      {...componentProps}
    />
  );
};

export const DownloadFile = (props) => {
  let componentProps = {
    upload,
    getDownloadUrl,
    editable: false,
    openInNewTab: true,
    ...props,
  };
  return <ReactFileInput theme={downloadFileBoxTheme} {...componentProps} />;
};

export const CheckBox = (props) => {
  return <ReactCheckBox {...checkBoxTheme} {...props} />;
};

export const RadioGroupInput = (props) => {
  return <RadioGroup {...radioGroupTheme} {...props} />;
};

export const RadioInput = (props) => {
  return <Radio {...radioTheme} {...props} />;
};

const ToggleLabel = (props) => {
  let {label, children} = props;
  let {style, labelStyle, toggleContainerStyle} = toggleLabelTheme;
  return (
    <View style={{flexDirection: 'row', alignItems: 'center', ...style}}>
      {<Text style={labelStyle}>{label}</Text>}
      <View style={{flex: 1, ...toggleContainerStyle}}>{children}</View>
    </View>
  );
};

export const ToggleInput = (props) => {
  let {label, ...restProps} = props;
  let toggleComponent = <ToggleButton {...inputToggleTheme} {...restProps} />;
  if (label) {
    toggleComponent = (
      <ToggleLabel label={label}>{toggleComponent}</ToggleLabel>
    );
  }
  return toggleComponent;
};

export const ToggleSwitchInput = (props) => {
  let {label, ...restProps} = props;
  let toggleComponent = <ToggleSwitch {...toggleSwitchTheme} {...restProps} />;
  if (label) {
    toggleComponent = (
      <ToggleLabel label={label}>{toggleComponent}</ToggleLabel>
    );
  }
  return toggleComponent;
};

export const AutoSuggestInput = (props) => {
  let {renderRowProps, showAvatar, ...restProps} = props;
  let extraProps = getAutoSuggestProps(props);
  let theme = getInputTheme(props);
  return (
    <AutosuggestInputField
      {...theme}
      {...autoSuggestInputTheme}
      renderInputError={renderInputError}
      {...restProps}
      {...extraProps}
    />
  );
};

export const AutoSuggestAvatarInput = (props) => {
  return <AutoSuggestInput showAvatar={true} {...props} />;
};

export const SearchInput = (props) => {
  return <AutoSuggestInput searching={true} {...props} />;
};

export const SearchAvatarInput = (props) => {
  return <AutoSuggestAvatarInput searching={true} {...props} />;
};

export const MultiAutoSuggestInput = (props) => {
  let {renderRowProps, showAvatar, ...restProps} = props;
  let extraProps = getAutoSuggestProps(props);
  let theme = getInputTheme(props);
  let multiAutosuggestTheme = isMobile
    ? multiAutosuggestMobileInputTheme
    : multiAutosuggestInputTheme;
  return (
    <MultiAutosuggestInputField
      {...theme}
      {...multiAutosuggestTheme}
      chipsVariant={'rounded'}
      renderInputError={renderInputError}
      {...restProps}
      {...extraProps}
    />
  );
};

export const MultiAutoSuggestAvatarInput = (props) => {
  let {renderRowProps} = props;
  let multiAutosuggestTheme = isMobile
    ? multiAutosuggestMobileInputTheme
    : multiAutosuggestInputTheme;
  return (
    <MultiAutoSuggestInput
      showAvatar={true}
      renderChips={(props) =>
        renderAvatarChips({
          ...props,
          ...multiAutosuggestTheme,
          ...renderRowProps,
        })
      }
      {...props}
    />
  );
};

export const MultiSearchInput = (props) => {
  return <MultiAutoSuggestInput searching={true} {...props} />;
};

export const MultiSearchAvatarInput = (props) => {
  return <MultiAutoSuggestAvatarInput searching={true} {...props} />;
};

export const GooglePlaceInput = (props) => {
  let {renderRowProps, minChar = 3, ...restProps} = props;
  let extraProps = getAutoSuggestProps(props);
  let theme = getInputTheme(props);
  return (
    <GooglePlaceInputField
      {...theme}
      {...autoSuggestInputTheme}
      minChar={minChar}
      searching={true}
      renderInputError={renderInputError}
      {...restProps}
      {...extraProps}
    />
  );
};
export const MultiTextInput = (props) => {
  let theme = getInputTheme(props);
  let chipCrossIcon = roundCross;
  let {removeChipIcon, chipsVariant = 'rounded'} = props;
  if (removeChipIcon) {
    chipCrossIcon = removeChipIcon;
  }

  return (
    <MultiTextInputField
      removeChipIcon={chipCrossIcon}
      chipsVariant={chipsVariant}
      {...theme}
      {...multiTextInputTheme}
      {...props}
    />
  );
};

export const RichTextInput = (props) => {
  let theme = getInputTheme(props);
  return (
    <ReactRichTextInput
      {...theme}
      renderInputError={renderInputError}
      {...props}
    />
  );
};

export const ChipsToggleInput = (props) => {
  let {label, ...restProps} = props;
  let toggleComponent = (
    <ToggleButton {...inputChipsToggleTheme} {...restProps} />
  );
  if (label) {
    toggleComponent = (
      <ToggleLabel label={label}>{toggleComponent}</ToggleLabel>
    );
  }
  return toggleComponent;
};

export const GooglePlaceMapInput = (props) => {
  const {value, mapHeight, isSpace = false, isMapVisible = true} = props;
  const {latitude, longitude} = value || {};

  return (
    <>
      <GooglePlaceInput {...props} />
      {isSpace ? <br /> : void 0}
      {isMapVisible ? (
        <GoogleMap
          latitude={latitude}
          longitude={longitude}
          mapHeight={mapHeight}
        />
      ) : (
        void 0
      )}
    </>
  );
};

export const UploadImage = (props) => {
  let componentProps = {upload, getDownloadUrl, openInNewTab: true, ...props};
  let {
    data,
    setValue,
    field,
    defaultImage = profile_preview,
    description,
    imageWidth = 200,
    imageHeight = 200,
    imageOnly = true,
    public_upload = false,
  } = props;
  let image = (data && field && data[field]) || {};
  let ImageUrl = image && getImageUrl(image.file);
  return (
    <View style={{justifyContent: 'center', alignItems: 'center'}}>
      {ImageUrl ? (
        <View>
          <Image
            source={ImageUrl}
            style={{
              width: imageWidth,
              height: imageHeight,
              objectFit: 'contain',
              borderRadius: 5,
            }}
          />
          <View
            style={{
              flexDirection: 'row',
              padding: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <ReactFileInput
              hideLink={true}
              data={data}
              field={field}
              imageOnly={imageOnly}
              setValue={setValue}
              theme={UploadImageTheme}
              public_upload={public_upload}
              renderInputComponent={
                <TouchableOpacity style={{cursor: 'pointer'}}>
                  <Text style={{color: themeColor, ...h13_Medium}}>
                    Replace
                  </Text>
                </TouchableOpacity>
              }
              {...componentProps}
            />
            <View
              style={{
                width: 2,
                height: 10,
                backgroundColor: 'rgba(154, 154, 154, 0.34)',
                marginLeft: 10,
                marginRight: 10,
              }}
            />
            <TouchableOpacity
              style={{cursor: 'pointer'}}
              onPress={() => {
                setValue({value: null, field});
              }}>
              <Text style={{color: 'rgba(241, 79, 79, 0.7)', ...h13_Medium}}>
                Remove
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <ReactFileInput
          data={data}
          imageOnly={true}
          field={field}
          setValue={setValue}
          hideLink={true}
          theme={fileInputBoxTheme}
          public_upload={public_upload}
          renderInputComponent={
            <TouchableOpacity style={{cursor: 'pointer'}}>
              <Image
                source={defaultImage}
                style={{width: imageWidth, height: imageHeight}}
              />
            </TouchableOpacity>
          }
          {...componentProps}
        />
      )}
      {description ? (
        <Text
          style={{
            color: 'rgba(56, 56, 56, 0.4)',
            ...h13_Medium,
            marginTop: 12,
          }}>
          {description}
        </Text>
      ) : (
        void 0
      )}
    </View>
  );
};

export const MobileInputComponent = (props) => {
  return <MobileInputForm {...props} />;
};

export const LoadingInput = (props) => {
  return <LoadingInputButton {...props} />;
};
export const HTMLInputField = (props) => {
  return <HTMLInput {...props} />;
};

export const OtherAutoSuggest = (props) => {
  let {
    placeholder = 'Enter your info',
    field = '',
    otherField = '',
    model,
    id,
    valueField,
    searchField,
    data,
    setValue,
    other = {},
    query = '',
    suggestionField = '',
  } = props;
  console.log('>@>Puru ~ file: AddPatient.js ~ line 45 ~ props', props);
  searchField = searchField || valueField;
  id = query || id;
  return (
    <>
      <MultiAutoSuggestInput
        data={data}
        onChangeValue={(value) => {
          setValue({
            field,
            value,
            data,
          });
        }}
        showArrow={true}
        removeChipIcon={roundCross}
        renderSelector={(props) =>
          OtherRenderSelector({
            otherValue: data[otherField] || other[field],
            onChangeOtherValue: (value, clearText) => {
              setValue({
                field: otherField,
                value: [...(data[otherField] ? data[otherField] : []), value],
                data,
              });
              clearText && clearText();
            },
            ...props,
          })
        }
        fetch={autosuggestFetch({
          model,
          id,
          search: searchField,
          showOther: true,
        })}
        variant="filled"
        value={data[field]}
        valueField={valueField}
        idField={'_id'}
        field={valueField}
        sugestionField={valueField}
        searching={true}
        textColor="#0095c9"
        placeholder={placeholder}
        renderRowProps={{
          primaryField: suggestionField,
          secondaryField: otherField,
          // avatarIcon,
        }}
      />
      {data[otherField] || other[field] ? (
        <MultiTextInput
          data={data}
          value={data[otherField]}
          field={otherField}
          disableTextInput
          onChangeValue={(value) => {
            setValue({field: otherField, value, data});
          }}
          variant="filled"
          textColor="#0095c9"
          placeholder={placeholder}
        />
      ) : (
        void 0
      )}
    </>
  );
};
