import React, {useEffect, useState} from 'react';
import {View, Text} from '../../../app-components';
import {h18_AvenirNext_500} from '../../../theme/fonts';
import AnalyticsCard from './AnalyticsCard';

const EfficiencyCard = ({getData, timeFilter}) => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData({id: 'getDurationAnalysis', setResponse, setLoading});
  }, [timeFilter]);

  const Avg_Session_Delay = [
    {
      header: 'Avg. Delay in Session',
      title:
        response?.avg_delay || response?.avg_delay === 0
          ? `${response?.avg_delay} min`
          : 'N/A',
      titleColor: 'red',
    },
  ];
  const Avg_Session_Duration = [
    {
      header: 'Avg. Session Duration',
      title: response?.avg_duration ? `${response?.avg_duration} min` : '0 min',
    },
    {
      header: 'Total Scheduled Duration',
      title: response?.total_duration
        ? `${parseInt(response?.total_duration / 60) ?? ''}:${parseInt(
            response?.total_duration % 60,
          )} hrs`
        : '0 min',
    },
  ];
  return (
    <View style={{flex: 1}}>
      <Text style={{...h18_AvenirNext_500}}>Efficiency</Text>
      <AnalyticsCard cardContent={Avg_Session_Delay} loading={loading} />
      <AnalyticsCard cardContent={Avg_Session_Duration} loading={loading} />
    </View>
  );
};

export default EfficiencyCard;
