import {EditDoctorProfile} from '../../Queries';
import Form from '../../app-components/form/Form';
import {PLACEHOLDER} from '../../app-components/utils';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import {submit} from '../../AppServices';
import {
  updateDoctorDataInForm,
  doctorDefaultValues,
} from '../../app-components/DoctorUtility';
let header = {
  title: 'Edit Profile',
  actions: [cancelButton(), save()],
};

export default Form({
  type: 'standardShadow',
  header,
  closeView: 1,
  reloadEvent: 'reloadProfile',
  beforeSubmit: updateDoctorDataInForm,
  onSubmit: submit({
    model: 'Doctor',
  }),

  defaultValues: doctorDefaultValues,
  formGroups: [
    {
      columnsPerRow: 1,
      fieldVariant: 'filled',
      fieldContainer: 'topLabel',
      groups: [
        {
          columns: [
            {
              type: 'uploadImage',
              field: 'profile_picture',
              label: 'Profile Picture',
            },
            {
              type: 'text',
              field: 'Fname',
              label: 'User Name',
              placeholder: PLACEHOLDER,
            },
            {
              type: 'text',
              field: 'email',
              label: 'Email Address',
              placeholder: PLACEHOLDER,
            },
            {
              type: 'file',
              field: 'signature',
              placeholder: PLACEHOLDER,
              label: 'Signature',
              uploadOptions: {s3: true},
              // mandatory: true,
            },
            {
              type: 'textArea',
              field: 'about_me_description',
              label: 'About me',
              placeholder: PLACEHOLDER,
              maxHeight: 100,
              minHeight: 100,
            },
            {
              label: 'Instant Appointment',
              type:'checkBox',
              field: 'waiting_room_available'
            },
          ],
        },
      ],
    },
  ],
});

// import {EditDoctorProfile} from '../../Queries';
// import Form from '../../app-components/form/Form';
// import {PLACEHOLDER} from '../../app-components/utils';
// import {save} from '../../app-components/action/SaveAction';
// import {cancelButton} from '../../app-components/action/CloseAction';
// import {submit} from '../../AppServices';
// import {
//   updateDoctorDataInFor,
//   doctorDefaultValues,
// } from '../../app-components/DoctorUtility';
// import {modifyPatientNameForDropDown} from '../../app-components/patientUtility';
// import {searchInput} from '../../autoSuggestions';
// let header = {
//   title: 'Edit Profile',
//   actions: [cancelButton(), save()],
// };

// export default Form({
//   type: 'standardShadow',
//   header,
//   closeView: 1,
//   reloadEvent: 'reloadProfile',
//   // beforeSubmit: updateDoctorDataInFor,
//   onSubmit: submit({
//     model: 'ResultProfile',
//   }),
//   // onSubmit: (data) => {
//   //   console.log('zzzzzzzzzzzzzzzzzzzzz', data);
//   // },
//   // uri: EditDoctorProfile,
//   // defaultValues: ({navigation}) => {
//   //   console.log('kkkkkkkk', navigation);
//   //   let item = navigation.state.params.items;
//   //   let name = item?.name[0]?.given[0] + ' ' + item?.name[0]?.family;
//   //   let email = item.email;
//   //   let about_me_description = item?.about_me_description;
//   //   let mobile = item.mobile;
//   //   return {
//   //     name,
//   //     email,
//   //     about_me_description,
//   //     mobile,
//   //   };
//   // },
//   // defaultValues: doctorDefaultValues,
//   formGroups: [
//     {
//       columnsPerRow: 1,
//       fieldVariant: 'filled',
//       fieldContainer: 'topLabel',
//       groups: [
//         {
//           columns: [
//             // searchInput(
//             //   {
//             //     label: 'Select Patient',
//             //     field: 'actor',
//             //     placeholder: 'Select Patient',
//             //     query: 'doctorLists',
//             //     model: 'Doctor',
//             //     valueField: 'fullName',
//             //     suggestionField: 'fullName',
//             //     searchField: 'fullName',
//             //     // editable: false,
//             //   },
//             //   {
//             //     modifyResult: modifyPatientNameForDropDown,
//             //   },
//             // ),
//             searchInput(
//               {
//                 label: 'Select Patient',
//                 field: 'patients',
//                 placeholder: 'Select Patient',
//                 query: 'patientLists',
//                 model: 'Patients',
//                 valueField: 'fullName',
//                 suggestionField: 'fullName',
//                 searchField: 'fullName',
//                 // editable: false,
//               },
//               {
//                 modifyResult: modifyPatientNameForDropDown,
//               },
//             ),
//             searchInput({
//               label: 'Select category',
//               field: 'code',
//               placeholder: 'Select code',
//               query: 'ResultProfileCodeList',
//               model: 'ResultProfileCode',
//               valueField: 'display',
//               suggestionField: 'display',
//               searchField: 'display',
//               // editable: false,
//             }),
//             searchInput({
//               label: 'Select absent Reason',
//               field: 'DataAbsentReason',
//               placeholder: 'Select reason',
//               query: 'AbsentReasonList',
//               model: 'AbsentReason',
//               valueField: 'display',
//               suggestionField: 'display',
//               searchField: 'display',
//               // editable: false,
//             }),
//             // searchInput({
//             //   label: 'Select code',
//             //   field: 'code',
//             //   placeholder: 'Select test',
//             //   query: 'CodeList',
//             //   model: 'Format',
//             //   valueField: 'value',
//             //   suggestionField: 'value',
//             //   searchField: 'value',
//             //   // editable: false,
//             // }),
//             {
//               type: 'text',
//               field: 'category',
//               label: 'category',
//               placeholder: PLACEHOLDER,
//             },
//             {
//               type: 'text',
//               field: 'status',
//               label: 'status',
//               placeholder: PLACEHOLDER,
//             },
//             // {
//             //   type: 'text',
//             //   field: 'type',
//             //   label: 'type',
//             //   placeholder: PLACEHOLDER,
//             // },
//             // {
//             //   type: 'text',
//             //   field: 'format',
//             //   label: 'Format',
//             //   placeholder: PLACEHOLDER,
//             // },
//             // {
//             //   type: 'text',
//             //   field: 'identifier',
//             //   label: 'identifier',
//             //   placeholder: PLACEHOLDER,
//             // },
//           ],
//         },
//       ],
//     },
//   ],
// });
