import get from 'lodash/get';
import React, {useCallback, useEffect, useState} from 'react';
import Form from '../../../app-components/form/Form';
import {fetch, getUser} from '../../../AppServices';
import {roles} from '../../../Lib/constants';
import { labResultIcon, registrationIcon, screeningIcon } from '../../../images';
import MemberGraphScreenHeader from './MemberGraphScreenHeader/MemberGraphScreenHeader';
import MemberGraphTile from './MemberGraphTile/MemberGraphTile';
import VillageGraph from './VillageGraph/VillageGraph';

const MemberGraphScreen = (props) => {
  const [globalData, setGlobalData] = useState({});
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const getGlobalData = useCallback(async () => {
    try {
      const uri = {
        token: (await get('token')) || null,
        props: {
          query: {
            id: 'globalMemberCountList',
          },
          model: 'GlobalMemberCount',
        },
      };
      const data = await fetch({uri});
      setGlobalData(data?.data[0]);
    } catch (error) {
      console.log('@eror', error);
    }
  }, []);

  useEffect(() => {
    getGlobalData();
  }, []);

  const ModifiedForm = Form({
    ...props,
    closeView: 1,
    fieldVariant: 'filled',
    containerStyle: {padding: 0},
    formGroups: [
      {
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: (props) => {
                  return <MemberGraphScreenHeader />;
                },
              },
              {
                type: 'custom',
                render: (props) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                      }}>
                      {globalData?.totalMember ? (
                        <MemberGraphTile
                          title="Registration"
                          count={globalData?.totalMember}
                          background= "linear-gradient(90deg,#90caf9,#047edf)"
                          icon= {registrationIcon}
                        />
                      ) : null}
                      {globalData?.totalScreening ? (
                        <MemberGraphTile
                          title="Members Screened"
                          count={globalData?.totalScreening}
                          background="linear-gradient(90deg,#ee9617,#fE5858)"
                          icon= {screeningIcon}
                        />
                      ) : null}
                      {globalData?.totalLabResult ? (
                        <MemberGraphTile
                          title="Members Lab Result"
                          count={globalData?.totalLabResult}
                          background="linear-gradient(90deg,#da8cff,#9a55ff)"
                          icon ={labResultIcon}
                        />
                      ) : null}
                    </div>
                  );
                },
              },
              {
                type: 'custom',
                render: () => {
                  return <VillageGraph />;
                },
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default MemberGraphScreen;
