import React from 'react';
import {View} from '../../react-core-components';
const HeaderRow = (props) => {
  let {
    columns,
    headerRowColumnContainerStyle,
    renderColumn: HeaderRowColumn,
    ...restProps
  } = props;
  return columns
    ? columns.map(({column, widthStyle}) => {
        return (
          <View
            style={{
              ...headerRowColumnContainerStyle,
              ...widthStyle,
            }}>
            <HeaderRowColumn column={column} {...restProps} />
          </View>
        );
      })
    : null;
};

HeaderRow.defaultProps = {
  headerRowColumnContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    justifyContent: 'center',
  },
};

export default HeaderRow;
