import moment from 'moment';
import {resolveExp} from '../UtilityFunctions';

const getOptionValue = ({
  value,
  valueField = 'value',
  suggestionField = 'label',
  options,
}) => {
  for (let option of options) {
    if (typeof option === 'object') {
      if (option[valueField] === value) {
        return option[suggestionField] || option[valueField];
      }
    }
  }
  return value;
};

const resolveValue = ({
  value,
  valueField,
  suggestionField,
  options,
  valueSeparator = '; ',
}) => {
  if (!value) {
    return value;
  }
  if (options) {
    value = resolveOptionsValue({value, options, valueField, suggestionField});
  } else if (typeof value === 'object' && valueField) {
    value = resolveExp(value, valueField);
  }
  if (Array.isArray(value)) {
    value = value.join(valueSeparator);
  }
  return value;
};
const resolveOptionsValue = ({value, valueField, suggestionField, options}) => {
  if (Array.isArray(value)) {
    return value.map((_value) =>
      getOptionValue({value: _value, valueField, suggestionField, options}),
    );
  } else {
    return getOptionValue({value, valueField, suggestionField, options});
  }
};
const resolveToggleValue = (props) => {
  let {value, options = []} = props;
  if (!value) {
    return value;
  }
  options.forEach((option) => {
    if (typeof option === 'object') {
      if (option.value === value) {
        value = option.label || option.value;
      }
    }
  });
  return value || '';
};

export default {
  number: (props) => {
    let {value, toFixed} = props;
    if (!value) {
      return value;
    }
    value = Number(value);
    if (!isNaN(value)) {
      if (toFixed) {
        value = value.toFixed(toFixed);
      }
    }
    return value;
  },
  date: (props) => {
    let {value, format = 'DD MMM YYYY'} = props;
    if (!value || !format) {
      return value;
    }
    return moment(value).format(format);
  },
  time: (props) => {
    let {value, toFixed = 2} = props;
    if (!value) {
      return value;
    }
    if (typeof value === 'number') {
      value = value.toFixed(toFixed);
    }
    return value;
  },
  multiText: resolveValue,
  autoSuggest: resolveValue,
  autoSuggestAvatar: resolveValue,
  search: resolveValue,
  searchAvatar: resolveValue,
  multiAutoSuggest: resolveValue,
  multiAutoSuggestAvatar: resolveValue,
  multiSearch: resolveValue,
  multiSearchAvatar: resolveValue,
  googlePlace: resolveValue,
  toggle: resolveToggleValue,
  toggleSwitch: resolveToggleValue,
  radio: resolveToggleValue,
  radioGroup: resolveToggleValue,
  switch: resolveToggleValue,
  file: (props) => {
    return resolveValue({...props, valueField: 'name'});
  },
};
