import {cancelButton} from '../../app-components/action/CloseAction';
import {PLACEHOLDER} from '../../app-components/utils';
import {save} from '../../app-components/action/SaveAction';
import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';

let header = {
  title: 'Add Allergy',
  actions: [cancelButton(), save()],
};

export default ({uri, header}) => {
  const AllergyForm = Form({
    type: 'standardShadow',
    header,
    onSubmit: submit({
      model: 'Allergies',
    }),
    uri,
    reloadEvent: 'reloadAllergy',
    closeView: 1,
    mandatory: {name: 1},
    lg: {
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'Alergy',
                  mandatory: true,
                  placeholder: PLACEHOLDER,
                  width: '50%',
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'Alergy',
                  mandatory: true,
                  placeholder: PLACEHOLDER,
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return AllergyForm;
};
