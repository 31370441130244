import React from 'react';
import {cancel} from '../../../../app-components/action/CancelAction';
import DoctorFormHoc from './DoctorForm';
import {save} from '../../../../app-components/action/SaveAction';
import CustomHeaderLabel from '../../../../app-components/CustomHeaderLabel';
import {getUser} from '../../../../AppServices';
import {doctorDefaultValuesForAdd} from '../../../../app-components/DoctorUtility';

let organizationData = '';
const header = {
  hideBackIcon: true,
  title: ({navigation}) => {
    var {state: {params: {item} = {}} = {}} = navigation;
    const {organization = {}} = getUser();
    organizationData = item;
    if (!item) {
      item = organization;
    }
    return (
      <CustomHeaderLabel
        seperator={true}
        preLabel={'Add New User'}
        postLabel={item?.organization_name}
      />
    );
  },
  actions: [cancel(), save()],
};

export default DoctorFormHoc({
  header,
  defaultValues: doctorDefaultValuesForAdd,
});
