import {BASE_URL} from './Config';

export const EditSettingDetail = ({user, navigation}) => {
  return {
    query: {
      id: 'settingLists',
      relationValue: {
        model: 'Settings',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Settings',
  };
};

export const countUri = ({id, model, filter}) => {
  return {
    query: {
      id,
      addOnFilter: filter,
    },
    fetchCount: true,
    model,
  };
};
export const listUri = ({id, model, filter, limit}) => {
  return {
    query: {
      id,
      addOnFilter: filter,
      limit,
    },
    model,
  };
};
export const aPPP = () => {
  return {
    query: {
      id: 'patientAppointments',
      addOnFilter: {},
    },
    model: 'Appointments',
  };
};

export const EditSymptom = ({navigation}) => {
  let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
  return {
    query: {
      id: 'symptomLists',
      relationValue: {
        model: 'Symptoms',
        _id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Symptoms',
  };
};

export const EditDrug = ({navigation}) => {
  return {
    query: {
      id: 'drugLists',
      relationValue: {
        model: 'Drugs',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Drugs',
  };
};
export const EditDoctor = ({navigation}) => {
  return {
    query: {
      id: 'doctorLists',
      relationValue: {
        model: 'Doctor',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Doctor',
  };
};

export const specialityDetail = ({navigation}) => {
  return {
    query: {
      id: 'specialityLists',
      relationValue: {
        model: 'Specialities',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Specialities',
  };
};
export const countryWiseFee = ({navigation}) => {
  return {
    query: {
      id: 'feeQuery',
      relationValue: {
        model: 'specialityFee',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'specialityFee',
  };
};

export const EditProcedure = ({navigation}) => {
  return {
    query: {
      id: 'procedureLists',
      relationValue: {
        model: 'Procedures',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Procedures',
  };
};

export const EditSocialHistory = ({navigation}) => {
  return {
    query: {
      id: 'socialHistoryLists',
      relationValue: {
        model: 'SocialHistories',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'SocialHistories',
  };
};

export const EditMedicalHistory = ({navigation}) => {
  return {
    query: {
      id: 'medicalHistoryLists',
      relationValue: {
        model: 'MedicalHistories',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'MedicalHistories',
  };
};

export const EditSurgicalHistory = ({navigation}) => {
  return {
    query: {
      id: 'surgicalHistoryLists',
      relationValue: {
        model: 'SurgicalHistories',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'SurgicalHistories',
  };
};

export const EditAllergy = ({navigation}) => {
  return {
    query: {
      id: 'allergyLists',
      relationValue: {
        model: 'Allergies',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Allergies',
  };
};

export const EditOrganisationSetting = ({navigation}) => {
  return {
    query: {
      id: 'organizationSettingList',
      relationValue: {
        model: 'OrganizationSetting',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'OrganizationSetting',
  };
};

export const EditOrganizationQuerry = ({navigation}) => {
  return {
    query: {
      id: 'organizationLists',
      relationValue: {
        model: 'Organizations',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Organizations',
  };
};

export const EditClinicAdmin = ({navigation}) => {
  return {
    query: {
      id: 'userLists',
      relationValue: {
        model: 'User',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'User',
  };
};

export const EditAppointmentAdmin = ({navigation}) => {
  return {
    query: {
      id: 'appointmentLists',
      relationValue: {
        model: 'Appointments',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Appointments',
  };
};

export const importData = ({}) => {
  return {
    query: {
      id: 'importHistoryLogList',
      // addOnFilter: filter,
    },
    model: 'ImportHistoryLogs',
  };
};

export const importDetail = ({user, navigation}) => {
  return {
    query: {
      id: 'importHistoryLogDetails',
      addOnFilter: {_id: navigation.state.params.import._id},
    },
    model: 'ImportHistoryLogs',
  };
};

export const EditDosage = ({navigation}) => {
  return {
    query: {
      id: 'dosageLists',
      relationValue: {
        model: 'Dosages',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Dosages',
  };
};

export const EditDiagnosis = ({navigation}) => {
  return {
    query: {
      id: 'diagnoseLists',
      relationValue: {
        model: 'Diagnosis',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Diagnosis',
  };
};

export const EditPatientProfile = ({navigation}) => {
  console.log('====>>>>', navigation);
  return {
    query: {
      id: 'patientLists',
      relationValue: {
        model: 'Patients',
        _id: navigation.state.params.items.patient._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Patients',
  };
};

export const EditDoctorProfile = ({navigation}) => {
  return {
    query: {
      id: 'doctorLists',
      relationValue: {
        model: 'Doctor',
        _id: navigation.state.params.items.doctor._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Doctor',
  };
};

export const EditUserProfile = ({navigation}) => {
  return {
    query: {
      id: 'userLists',
      relationValue: {
        model: 'User',
        _id: navigation.state.params.items._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'User',
  };
};

export const EditRpmProgram = ({navigation}) => {
  return {
    query: {
      id: 'careProgramList',
      relationValue: {
        model: 'CarePrograms',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'CarePrograms',
  };
};
export const PatientDetail = ({navigation}) => {
  return {
    query: {},
    url: `${BASE_URL}/patients/${navigation.state.params.item.mrn}`,
    method: 'get',
  };
};
export const PatientDetail1 = ({navigation}) => {
  return {
    query: {
      id: 'patientLists',
      relationValue: {
        model: 'Patients',
        _id: navigation.state.params._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Patients',
  };
};
export const EditSearchDevice = ({navigation}) => {
  return {
    query: {
      id: 'devicesList',
      relationValue: {
        model: 'Devices',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Devices',
  };
};
