import React, {useState, useEffect} from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  Linking,
  isMobile,
  Dimensions,
} from '../../app-components';
import {
  BackgroundImage,
  MayaAiGirl,
  mayaMdLogo,
  mayaMdLogoWhite,
} from '../../images';
import {Multiselect} from 'multiselect-react-dropdown';
import {h14_AvenirNext_Bold} from '../../theme/fonts';
const {height, width} = Dimensions.get('window');
const key = 1;
const dropdownData = [
  {name: 'Hindi', url: 'https://hindi.corona.mayamd.ai/'},
  {name: 'English', url: 'https://english.corona.mayamd.ai/'},
  {name: 'Tamil', url: 'https://tamil.corona.mayamd.ai/'},
  {name: 'Telugu', url: 'https://telugu.corona.mayamd.ai/'},
  {name: 'Malayalam', url: 'https://malayalam.corona.mayamd.ai/'},
  {name: 'Bengali', url: 'https://bengali.corona.mayamd.ai/'},
  {name: 'Kannada', url: 'https://kannada.corona.mayamd.ai/'},
];
const Home = (props) => {
  const [selectedTab, setSelectedTab] = useState('choose');
  return (
    <View
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flex: 1,
        flexDirection: 'row',
      }}>
      <View
        style={{
          flex: 1,
        }}>
        <View
          style={{
            // backgroundColor: '#000',
            // boxShadow: 'rgba(0,0,0,0.4) 5px 5px 10px 0px',
            marginTop: 30,
            // justifyContent: 'center',
            alignItems: isMobile ? 'center' : undefined,
            padding: 8,
            borderRadius: 20,
          }}>
          <Image
            // source={StewardLogo}
            source={mayaMdLogoWhite}
            style={{width: isMobile ? width * 0.98 : 389, objectFit: 'contain'}}
          />
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}>
          <View
            style={{
              backgroundColor: 'rgba(1, 59, 86, 0.35)',
              maxWidth: isMobile ? width * 0.85 : 400,
              minWidth: isMobile ? width * 0.85 : 400,
              minHeight: 150,
              borderRadius: 50,
              padding: 20,
            }}>
            <View
              style={{
                flex: 1,
                // backgroundColor: '#fff',
                maxHeight: 45,
                minHeight: 45,
                marginBottom: 20,
                flexDirection: 'row',
                justifyContent: 'center',
              }}>
              {[
                // {label: 'Sign Up', value: 'signup', visible: !isForgot},
                {label: 'Choose Language', value: 'choose', visible: true},
              ].map((item, index) => {
                return item?.visible ? (
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedTab(item.value);
                    }}
                    style={{
                      backgroundColor:
                        selectedTab === item.value
                          ? 'rgba(255, 255, 255, 0.06)'
                          : '',
                      width: 160,
                      marginRight: index === 0 ? 10 : 0,
                      borderRadius: 10,
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <Text
                      style={{
                        color:
                          selectedTab === item.value
                            ? '#fff'
                            : 'rgba(255, 255, 255, 0.6)',
                        ...h14_AvenirNext_Bold,
                      }}>
                      {item?.label}
                    </Text>
                  </TouchableOpacity>
                ) : (
                  void 0
                );
              })}
            </View>

            <Multiselect
              key={key}
              options={dropdownData}
              selectedValues={[]}
              style={{
                searchBox: {
                  backgroundColor: '#fff',
                },
                option: {},
                optionContainer: {height: 180},
              }}
              placeholder="Select Language"
              avoidHighlightFirstOption={true}
              onSelect={(value, selected) => {
                const {url = 'https://mayamd-web.cloudzmall.com/'} =
                  selected || {};
                Linking.openURL(url, '_blank');
              }} // Function will trigger on select event
              onRemove={(value) => {}} // Function will trigger on remove event
              displayValue="name"
              singleSelect
            />
          </View>
        </View>
      </View>
      {isMobile ? (
        void 0
      ) : (
        <View style={{flex: 1, justifyContent: 'flex-end'}}>
          <Image
            source={MayaAiGirl}
            style={{width: '100%', objectFit: 'contain'}}
          />
        </View>
      )}
    </View>
  );
};

export default Home;
