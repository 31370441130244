import React from 'react';
import Toast from '../../npms/react-toast';
import {View, Text, Image} from '../index';
import {shadow} from '../../theme/shadows';
import {Headline11, Headline5, Headline9} from '../../theme/Headlines';
import {manazeV6Colors} from '../../theme/manazeV6Colors';
import {Action} from '../action/Action';
let {
  neutral_4,
  neutral_1,
  positive_communication_2,
  surface_white,
} = manazeV6Colors;

const standardTheme = {
  containerStyle: {
    ...shadow,
    flexDirection: 'row',
    borderRadius: 10,
    padding: 8,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  leftIconStyle: {
    width: 20,
    height: 20,
    marginLeft: 2,
    marginRight: 14,
  },
  messageStyle: {
    ...Headline5,
    color: neutral_1,
  },
  descriptionStyle: {
    paddingTop: 2,
    ...Headline11,
    color: neutral_4,
  },
  actionIconStyle: {
    marginLeft: 5,
    marginRight: 5,
    width: 12,
    height: 12,
  },
  actionTextStyle: {
    marginLeft: 5,
    marginRight: 5,
    ...Headline5,
    color: neutral_1,
  },
};

const successTheme = {
  ...standardTheme,
  containerStyle: {
    ...shadow,
    flexDirection: 'row',
    borderRadius: 10,
    padding: 12,
    alignItems: 'center',
    backgroundColor: positive_communication_2,
  },
  messageStyle: {
    ...Headline5,
    color: surface_white,
  },
  descriptionStyle: {
    paddingTop: 2,
    ...Headline11,
    color: surface_white,
  },

  actionTextStyle: {
    marginLeft: 12,
    marginRight: 5,
    ...Headline9,
    color: surface_white,
  },
};
const errorTheme = {
  ...standardTheme,
  containerStyle: {
    ...shadow,
    flexDirection: 'row',
    borderRadius: 10,
    padding: 8,
    alignItems: 'center',
    backgroundColor: neutral_1,
  },
  messageStyle: {
    ...Headline5,
    color: surface_white,
  },
  descriptionStyle: {
    paddingTop: 4,
    ...Headline11,
    color: surface_white,
  },
  actionTextStyle: {
    marginLeft: 5,
    marginRight: 5,
    ...Headline5,
    color: surface_white,
  },
};

const ToastComponent = theme => props => {
  let {
    containerStyle,
    leftIconStyle,
    messageStyle,
    descriptionStyle,
    actionTextStyle,
    actionIconStyle,
  } = theme || {};
  let {leftIcon, message, description, action, hideToast} = props;
  let {text, icon, textStyle, iconStyle, onPress, ...restAction} = action || {};
  return (
    <View style={containerStyle}>
      {leftIcon && <Image source={leftIcon} style={leftIconStyle} />}
      <View style={{flex: 1}}>
        {message && <Text style={messageStyle}>{message}</Text>}
        {description && <Text style={descriptionStyle}>{description}</Text>}
      </View>
      {action && (
        <Action
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          action={{
            ...restAction,
            onPress: () => {
              hideToast && hideToast();
              onPress && onPress();
            },
          }}>
          <>
            {text && <Text style={textStyle || actionTextStyle}>{text}</Text>}
            {icon && (
              <Image source={icon} style={iconStyle || actionIconStyle} />
            )}
          </>
        </Action>
      )}
    </View>
  );
};

const toastConfig = {
  standard: ToastComponent(standardTheme),
  success: ToastComponent(successTheme),
  error: ToastComponent(errorTheme),
};
export default props => (
  <Toast
    config={toastConfig}
    ref={ref => {
      Toast.setRef(ref);
    }}
    {...props}
  />
);
