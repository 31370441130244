import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {PLACEHOLDER} from '../../app-components/utils';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';


export default ({uri, header}) => {
  const DosageForm = Form({
    type: 'standardShadow',
    uri,
    closeView: 1,
    reloadEvent: 'reloadDosage',
    onSubmit: submit({
      model: 'Dosages',
    }),
    mandatory: {dosage: 1},
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'dosage',
                  label: 'Dosage Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  width: '50%',
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'dosage',
                  label: 'Dosage Name',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return DosageForm;
};
