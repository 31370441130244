import React from 'react';
import {View} from '../../react-core-components';
import BasicTextAreaInput from './BasicTextAreaInput';
import {
  WithLabel,
  WithInputWrapper,
  WithVariant,
  InputError,
  getInputProps,
} from '../../react-input-wrapper';

class TextAreaInput extends React.Component {
  render() {
    let inputProps = getInputProps(this.props);
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <BasicTextAreaInput {...inputProps} />
        </View>
        <InputError {...this.props} />
      </View>
    );
  }
}

TextAreaInput = WithLabel(WithInputWrapper(WithVariant(TextAreaInput)));

export default TextAreaInput;
