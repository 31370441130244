import React from 'react';
import {
  View,
  Image,
  Text,
  Snackbar,
  TouchableOpacity,
} from '../../app-components';
import moment from 'moment';

import {favorite, crossIcon, Checkmark} from '../../images';

import {colors, bgs} from '../../theme/colors';
import {h14_Medium} from '../../theme/fonts';
import {getImageUrl, getUser} from '../../AppServices';
import {resolvePatientName} from '../../app-components/patientUtility';
import {resolveDoctorName} from '../../app-components/DoctorUtility';

let {textColor54, textColor89, deActiveDateColor} = colors;

export const dateFormatter = ({value, format = 'DD MMM,YY', isZeroTime}) => {
  if (value === undefined || value === null || value === '') {
    return '';
  }
  let formattedValue = moment(value);
  if (isZeroTime) {
    formattedValue = formattedValue.utc();
  }
  if (format === 'fromNow') {
    /*return how much time is elapsed like == 1 min ago or 24 days ago etc -- ashish*/
    return formattedValue.fromNow();
  }
  return formattedValue.format(format);
};

let notificationCardTheme = {
  containerStyle: {
    flexDirection: 'row',
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    cursor: 'pointer',
  },
  imgcontainerStyle: {
    alignItems: 'center',
    width: 48,
    height: 48,
    marginRight: 18,
    justifyContent: 'center',
  },
  imgViewStyle: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    // backgroundColor: grey,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: {objectFit: 'contain', borderRadius: 50, height: 55, width: 55},

  infocontainerStyle: {
    flex: 1,
    overflow: 'hidden',
  },
  headerViewStyle: {
    paddingBottom: 2,
  },
  headerTextStyle: {
    ...h14_Medium,
    maxWidth: '80%',
    color: textColor54,
    numberOfLines: 1,
  },
  messageViewStyle: {
    paddingBottom: 2,
  },
  messageTextStyle: {
    ...h14_Medium,
    maxWidth: '100%',
    color: textColor89,
    numberOfLines: 2,
  },
  dateAndActioncontainerStyle: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },

  actionViewStyle: {
    flex: 1,
  },
  actionTextStyle: {
    ...h14_Medium,
    numberOfLines: 1,
    flex: 1,
    color: '#73c5a7',
  },
  dateViewStyle: {alignItems: 'flex-end', flex: 1},
  dateTextStyle: {
    ...h14_Medium,
    flex: 1,
    color: textColor54,
  },
};

export const getImageIcon = ({item = {}, style, title, userData}) => {
  let extImage = Checkmark;
  const {userType} = userData;
  const {data = {}} = item || {};
  if (userType === 'Patient') {
    let {doctor_id: {profile_picture = null} = {}} = data || {};
    if (profile_picture) {
      extImage = getImageUrl(profile_picture.thumbnail);
    }
  }
  if (userType === 'Doctor') {
    let {patient_id: {profile_picture = null} = {}} = data || {};
    if (profile_picture) {
      extImage = getImageUrl(profile_picture.thumbnail);
    }
  }

  // if (item) {
  //   extImage = imageMappings[title] || submissionAlert;
  // }
  return <Image source={extImage} style={{...style}} />;
};
const checkToday = (time) => {
  const today = new Date();
  if (
    time.getDay() === today.getDay() &&
    time.getDate() === today.getDate() &&
    time.getMonth() === today.getMonth() &&
    today.getFullYear() === time.getFullYear()
  ) {
    return true;
  }
  return false;
};

const clickAction = ({
  navigation,
  eventDispatcher,
  hideModal,
  title,
  item = {},
  userData = {},
}) => {
  const {userType} = userData;
  const {data: {time_slot: {start_time} = {}} = {}} = item;
  if (userType === 'Patient') {
    if (checkToday(new Date(start_time))) {
      navigation.reset('patient-waiting-room');
    } else {
      navigation.reset('patients');
    }
    hideModal && hideModal();
  }
  if (userType === 'Doctor') {
    if (checkToday(new Date(start_time))) {
      navigation.reset('doctor-patient-queue');
    } else {
      navigation.reset('schedule');
    }
    hideModal && hideModal();
  }
};
const clickReminderAction = ({
  navigation,
  eventDispatcher,
  hideModal,
  title,
  item = {},
  userData = {},
}) => {
  const {userType} = userData;
  if (userType === 'Patient') {
    navigation.reset('patient-waiting-room');
  }
  if (userType === 'Doctor') {
    if (title === 'New Instant Appointment') {
      navigation.reset('instant-patient-queue');
    } else {
      navigation.reset('doctor-patient-queue');
    }
  }
  if (title == 'Abnormal readings monitored') {
    navigation.reset({
      view: 'RPM',
      action: {
        view: 'PatientsAnalysis',
      },
    });
  }
  hideModal && hideModal();
};

const notificationViewCard = ({
  eventDispatcher,
  hideModal,
  navigation,
  item = {},
  classroomStore:{markRead},
  ...restProps
}) => {
  let {date = new Date(), message = '', title = 'N/A',_id} = item;
  // console.log(`>@>Puru ~ file: NotificationCard.js ~ line 205 ~ restProps`, item)
  let {
    containerStyle,
    imgcontainerStyle,
    imgViewStyle,
    iconStyle,
    infocontainerStyle,
    headerViewStyle,
    headerTextStyle,
    messageViewStyle,
    messageTextStyle,
    dateAndActioncontainerStyle,
    dateViewStyle,
    dateTextStyle,
  } = notificationCardTheme;
  const userData = getUser();
  if (userData?.userType === 'Patient' && title === 'Appointment') {
    const {data: {doctor_id = {}} = {}} = item;
    let name = resolveDoctorName(doctor_id);
    if (name) {
      message = message + ' with ' + name;
    }
  }
  if (userData?.userType === 'Doctor' && title === 'Appointment') {
    const {data: {patient_id = {}} = {}} = item;
    let name = resolvePatientName(patient_id);
    if (name) {
      message = message + ' with ' + name;
    }
  }
  return (
    <TouchableOpacity
      style={{...containerStyle}}
      onPress={() => {
        markRead&& markRead(_id)
        if (title === 'Appointment') {
          clickAction({
            navigation,
            title,
            item,
            eventDispatcher,
            hideModal,
            userData,
          });
        } else {
          clickReminderAction({
            navigation,
            title,
            item,
            eventDispatcher,
            hideModal,
            userData,
          });
        }
      }}>
      <View
        style={{
          ...imgcontainerStyle,
        }}>
        <View style={{...imgViewStyle}}>
          {getImageIcon({item, style: iconStyle, title, userData})}
        </View>
      </View>
      <View style={{...infocontainerStyle}}>
        <View style={{...headerViewStyle}}>
          <Text title={message} style={{...headerTextStyle}}>
            {title}
          </Text>
        </View>
        <View style={{...messageViewStyle}}>
          <Text title={message} style={{...messageTextStyle}}>
            {message}
          </Text>
        </View>
        <View style={{...dateAndActioncontainerStyle}}>
          {/* {actionButton({navigation, eventDispatcher, hideModal, title, item})} */}
          <View style={{...dateViewStyle}}>
            <Text style={{...dateTextStyle}}>
              {dateFormatter({value: date, format: 'fromNow'})}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const notificationView = ({
  eventDispatcher,
  navigation,
  hideModal,
  item,
  classroomStore,
}) => {
  return notificationViewCard({
    item,
    hideModal,
    navigation,
    eventDispatcher,
    classroomStore
  });
};
