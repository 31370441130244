import React from 'react';

import {withGoogleMap, GoogleMap, Marker} from 'react-google-maps';
import {MarkerClusterer} from 'react-google-maps/lib/components/addons/MarkerClusterer';

const MapWithAMarker = withGoogleMap(({lat, lng, locations, field}) => {
  let defaultCenter = {lat, lng};
  let markers = [];
  // let avgLat = 0,
  //   avgLng = 0;
  if (Array.isArray(locations) && locations.length) {
    markers = locations.map((location) => {
      defaultCenter = {
        lat: location?.[field]?.latitude,
        lng: location?.[field]?.longitude,
      };
      // avgLat += defaultCenter?.lat;
      // avgLng += defaultCenter?.lng;
      return (
        <Marker
          position={{
            lat: location?.[field]?.latitude,
            lng: location?.[field]?.longitude,
          }}
        />
      );
    });
    // avgLat = avgLat / locations.length;
    // avgLng = avgLng / locations.length;
    // defaultCenter = {
    //   lat: avgLat,
    //   lng: avgLng,
    // };
  }

  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{...defaultCenter}}
      key={defaultCenter.lat + defaultCenter.lng}>
      <MarkerClusterer averageCenter gridSize={40}>
        {markers.length ? markers : <Marker position={{lat, lng}} />}
      </MarkerClusterer>
    </GoogleMap>
  );
});

export default MapWithAMarker;
