import React from 'react';
import {View, Image, Text} from '../../react-core-components';
import {getRenderComponent} from './Utility';

const ErrorMessage = (props) => {
  let {
    errorMessageClassName,
    hideErrorMessage,
    error,
    errorIcon,
    renderErrorMessage,
    errorMessageContainerStyle,
    errorMessageIconStyle,
    errorMessageStyle,
  } = props;
  if (!error || hideErrorMessage) {
    return null;
  }
  if (renderErrorMessage) {
    return getRenderComponent(renderErrorMessage, props);
  }
  let errorText = error;
  if (typeof errorText === 'object') {
    errorText = error.shortMessage || error.message || JSON.stringify(error);
  }
  return (
    <View
      className={errorMessageClassName}
      style={{
        flexDirection: 'row',
        alignItems: 'baseline',
        ...errorMessageContainerStyle,
      }}>
      {errorIcon && <Image style={errorMessageIconStyle} source={errorIcon} />}
      <Text style={errorMessageStyle}>{errorText}</Text>
    </View>
  );
};

ErrorMessage.defaultProps = {
  errorMessageContainerStyle: {
    // marginTop: 3,
  },
  errorMessageIconStyle: {
    paddingRight: 3,
  },
  errorMessageStyle: {
    fontSize: 12,
    color: '#FF8181',
  },
};

export default ErrorMessage;
