import React from 'react';
import {EditRpmProgram} from '../../../Queries';
import {cancelButton} from '../../../app-components/action/CloseAction';
import RpmLibraryForm from './RpmLibraryForm';
import {getUser} from '../../../AppServices';
import {GradientButton} from '../../../app-components/buttons/Buttons';

let header = {
  title: 'Program Detail',
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },

  actions: [
    cancelButton({label: ' Back '}),
    {
      visible: ({navigation}) => {
        const {state: {params: {item = {}} = {}} = {}} = navigation;
        const {isDefault = true} = item;
        let {userType} = getUser();
        return userType === 'SuperAdmin' || !isDefault ? true : false;
      },
      render: (props) => {
        return (
          <GradientButton
            buttonStyle={{minHeight: 38, minWidth: 100, borderRadius: 3}}
            color="#0095c9"
            label={'Edit'}
          />
        );
      },
      type: 'link',
      link: ({navigation}) => {
        let {
          state: {
            params: {item},
          },
        } = navigation;

        return {
          view: 'editRpmProgramForm',
          params: {item},
        };
      },
    },

    {
      visible: ({navigation}) => {
        const {state: {params: {item = {}} = {}} = {}} = navigation;
        const {isDefault = true} = item;
        let {userType} = getUser();
        return userType !== 'SuperAdmin';
      },
      render: (props) => {
        return (
          <GradientButton
            buttonStyle={{minHeight: 38, minWidth: 100, borderRadius: 3}}
            color="#0095c9"
            label={'Copy'}
          />
        );
      },
      type: 'link',
      link: ({navigation}) => {
        let {
          state: {
            params: {item},
          },
        } = navigation;
        const data = {...item};
        delete data._id;
        delete data.name;
        delete data.isDefault;
        delete data.organization
        return {
          view: 'copyRpmProgramForm',
          params: {item: {...data, name: item?.name + ' - Copy'}},
        };
      },
    },
  ],
};

let columnsPerRow = 2;
let superAdminEditable = false;
let editable = false;
export default RpmLibraryForm({
  uri: EditRpmProgram,
  header,
  editable,
  superAdminEditable,
  columnsPerRow,
  openProgramDetails:true,
});
