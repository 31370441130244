import React, {useState, useEffect} from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  isMobile,
  Dimensions,
} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import Format from '../GenericComponent/ClinicSignupFormat';
import {TextInput} from '../../app-components/input-components/Editors';
import {h11_Medium, h16_Regular} from '../../theme/fonts';
import {invoke, mayamd_authenticate} from '../../AppServices';
const {height, width} = Dimensions.get('window');

const VerifyOtp = (props) => {
  const {navigation = {}} = props;
  const {
    state: {
      params: {
        mobile,
        otp,
        user,
        isForgot = false,
        userType = 'Doctor',
        mobileVerified,
      } = {},
    } = {},
  } = navigation || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState({
    newPassword: '',
    confirmPassword: '',
    oldPassword: '',
    user: {},
  });
  useEffect(() => {
    setData({...data, oldPassword: otp || '', userDetail: user});
  }, []);
  const changePassword = async () => {
    if (!data.oldPassword.length) {
      setError({type: 'old', value: 'Please enter value'});
    } else if (!data?.newPassword.length) {
      setError({type: 'new', value: 'Please enter value'});
    } else if (data.newPassword.length < 8) {
      setError({type: 'new', value: 'Password must be of min 8 characters'});
    } else if (!data?.confirmPassword.length) {
      setError({type: 'confirm', value: 'Please enter value'});
    } else if (data.confirmPassword.length < 8) {
      setError({
        type: 'confirm',
        value: 'Password must be of min 8 characters',
      });
    } else if (data?.confirmPassword !== data?.newPassword) {
      setError({
        type: 'confirm',
        value: 'Please make sure your passwords match',
      });
    } else {
      try {
        setLoading(true);
        const result = await invoke({
          id: 'resetPassword',
          paramValue: {
            ...data,
          },
        });
        setLoading(false);
        if (isForgot || !mobileVerified) {
          mayamd_authenticate({
            mobile,
            password: data?.newPassword,
            type: userType,
          })
            .then((res) => {
              if (res?.user) {
                navigation.navigate('authenticator');
              } else {
                // setOtpError('Wrong OTP');
              }
              // setLoading(false);
            })
            .catch((err) => {
              console.log(
                `>@>Puru ~ file: CreatePassword.js ~ line 72 ~ err`,
                err,
              );
              // setLoading(false);
            });
        } else {
          navigation.push({
            view: 'sucess',
            params: {
              message:
                'Thanks for registering with us! MayaMD team will verify your details soon. You will be notified by e-mail and text message. ',
              // isNavigate: true,
            },
            modal: true,
            modalProps: {
              autoHide: true,
              screenCenterStyle: {
                borderRadius: 10,
              },
              style: {},
            },
          });
        }
      } catch (err) {
        setLoading(false);
        let message = JSON.parse(err?.message)?.error?.message;
        if (message === 'Old Password is not matched') {
          setError({
            type: 'new',
            value: message || '',
          });
        } else {
          setError({
            type: 'confirm',
            value: message || '',
          });
        }
        console.log(JSON.parse(err?.message)?.error?.message);
      }
    }
  };
  return (
    <View
      id="create_password_container"
      style={{
        // minWidth: 450,
        marginTop: 20,
        // minHeight: 300,
        minWidth: isMobile ? width * 0.75 : 450,
        maxWidth: isMobile ? width * 0.75 : 450,
        minHeight: isMobile ? height * 0.65 : 300,
        justifyContent: 'space-between',
      }}>
      <View>
        <View style={{marginRight: 50, marginTop: 20}}>
          <Text style={{marginBottom: 10, ...h16_Regular}}>
            Enter New Password
          </Text>
          <TextInput
            value={data['newPassword']}
            onChangeText={(value) => {
              setData({...data, newPassword: value});
              setError({});
            }}
            inputProps={{
              type: 'password',
              id: 'new_password',
            }}
            variant="filled"
            placeholder={`Enter your New Password`}
          />
          {error && error?.type === 'new' ? (
            <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
          ) : (
            void 0
          )}
          <Text style={{marginBottom: 10, ...h16_Regular, marginTop: 10}}>
            Confirm Password
          </Text>
          <TextInput
            value={data['confirmPassword']}
            onChangeText={(value) => {
              setData({...data, confirmPassword: value});
              setError({});
            }}
            inputProps={{
              type: 'password',
              id: 'confirm_password',
            }}
            variant="filled"
            placeholder={`Enter Confirm Password`}
          />
          {error && error?.type === 'confirm' ? (
            <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
          ) : (
            void 0
          )}
        </View>
      </View>

      <View
        style={{alignItems: isMobile ? 'center' : 'flex-end', marginTop: 10}}>
        <TouchableOpacity
          onPress={() => {
            if (!loading) {
              changePassword();
            }
          }}
          style={{width: 150, cursor: 'pointer'}}>
          <GradientButton
            label="Create"
            loading={loading}
            indicatorColor={'#fff'}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default (props) => (
  <Format {...props} backIconVisible={false} title="Create Password">
    <VerifyOtp {...props} />
  </Format>
);
