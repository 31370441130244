import React from 'react';
import {
  ToggleButton,
  ToggleSwitch as ReactToggleSwitch,
} from '../npms/react-toggle';
import {toggleSwitchTheme, dateFilterToogleTheme} from '../theme/toggleTheme';

export const DateToggle = props => {
  return <ToggleButton {...dateFilterToogleTheme} {...props} />;
};

export const ToggleSwitch = props => {
  return <ReactToggleSwitch {...toggleSwitchTheme} {...props} />;
};
