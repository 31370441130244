import React from 'react';
import uuid from 'uuid/v4';

export const getUid = () => uuid();

export const getNewId = () => {
  return `new_${uuid()}`;
};

export const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

const resolveDottedValue = (data, expression, confirm, confirmType) => {
  if (!data) {
    return;
  }
  while (expression !== undefined) {
    var fieldIndex = expression.indexOf('.');
    var exp = expression;
    if (fieldIndex >= 0) {
      exp = expression.substring(0, fieldIndex);
      expression = expression.substring(fieldIndex + 1);
    } else {
      expression = undefined;
    }

    if ((data[exp] === undefined || data[exp] === null) && !confirm) {
      return;
    }
    if (data[exp] !== undefined && data[exp] !== null) {
      data = data[exp];
    } else {
      if (expression) {
        data[exp] = {};
      } else {
        if (confirmType === 'array') {
          data[exp] = [];
        } else {
          data[exp] = {};
        }
      }
      data = data[exp];
    }
  }
  return data;
};

export const putDottedValue = (data, key, value) => {
  if (!data) {
    throw new Error('data does not exists for putting dotted value');
  }
  var lastDottedIndex = key.lastIndexOf('.');
  if (lastDottedIndex >= 0) {
    var firstExpression = key.substring(0, lastDottedIndex);
    key = key.substring(lastDottedIndex + 1);
    data = resolveDottedValue(data, firstExpression, true);
  }
  data[key] = value;
};

export const resolveExp = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    var result = [];
    for (var i = 0; i < values.length; i++) {
      var row = values[i];
      var resolvedValue = resolveExp(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (var j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17*/
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else {
          if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
            result.push(resolvedValue);
          }
        }
      }
    }
    return result;
  } else {
    var value = values[key];
    if (value !== undefined) {
      return value;
    }
    var index = key.indexOf('.');
    if (index === -1) {
      return;
    }
    var firstPart = key.substring(0, index);
    var nextPart = key.substring(index + 1);
    return resolveExp(values[firstPart], nextPart);
  }
};

export const findById = (data, field, value) => {
  if (!data || !data.length) {
    return void 0;
  }
  let valueToFind = value[field];
  return data.find(doc => {
    let docValue = doc[field];
    if (docValue === valueToFind) {
      return true;
    }
  });
};

export const findIndex = (data, field, value) => {
  if (!data || !data.length) {
    return void 0;
  }
  let valueToFind = value[field];
  return data.findIndex(doc => {
    let docValue = doc[field];
    if (docValue === valueToFind) {
      return true;
    }
  });
};

export const getFieldError = ({field, form_state} = {}) => {
  let {validationErrors, mandatoryErrors} = form_state || {};
  if (!field) {
    return;
  }
  if (mandatoryErrors && mandatoryErrors[field]) {
    return mandatoryErrors[field];
  } else if (validationErrors && validationErrors[field]) {
    return validationErrors[field];
  }
};
