import React, {useState} from 'react';
import {backgroundImage1} from '../../images';
import {View, Text, TouchableOpacity, Image} from '../../app-components';
import {navigationBarStyle} from '../../theme/navigationBarTheme';
import {
  h14_AvenirNext_Bold,
  h33_AvenirNext,
  h15_Regular,
  h15_Medium,
} from '../../theme/fonts';
import {HospitalLOGO, dropdown, searchFilter} from '../../images';
import {
  SearchInput,
  TextInput,
} from '../../app-components/input-components/Editors';
import {bgs, colors} from '../../theme/colors';
import {WithModal} from '../../npms/react-with-modal';
import {autosuggestFetch} from '../../AppServices';
let {borderColor} = bgs;
let {textColor34,themeColor} = colors;
const PatientDoctorSearchLogin = (props) => {
  const [hoverSignIn, setHoverSignIn] = useState(false);
  const [hoverSignUp, setHoverSignUp] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('doctor');
  const [filterValue, setFilterValue] = useState('');
  const {navigation} = props;
  let {rightHeaderStyle, flagImageStyle, containerStyle} = navigationBarStyle;
  const textTheme = {
    style: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: borderColor,
      backgroundColor: 'white',
      minHeight: 38,
      height: 58,
      width: 500,
      justifyContent: 'center',
    },
    activeStyle: {},
    inputStyle: {
      outline: 'none',
      border: 0,
      padding: 0,
      backgroundColor: 'transparent',
      paddingLeft: 12,
      paddingRight: 12,
      ...h15_Medium,
      color: textColor34,
    },
    dropdownStyle: {
      height: 250,
      backgroundColor: 'red',
      border: 'none',
      borderTopRightRadius: 16,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      backgroundColor: '#fff',
      marginTop: 30,
      marginRight: -15,
    },
  };
  const defaultScreenState = ({navigation}) => {
    let {state: {params: {searchData: {type, value}} = {}} = {}} = navigation;
    let dataParams = {};
    if (type === 'doctor' && value) {
      dataParams = {
        ftsFilter: {value, filter: {$text: {$search: `"${value}"`}}},
      };
    } else if ((type === 'speciality', value)) {
      dataParams.filters = {
        [type]: {
          value,
          filter: {
            filterType: 'autosuggest',
            value,
          },
        },
      };
    }
    return {dataParams};
  };
  const navigateToDoctor = () => {
    navigation.reset({
      view: 'doctor-search',
      params: {
        searchData: {type: selectedFilter, value: filterValue},
      },
      routeOptions: {
        expanded: true,
        defaultScreenState,
      },
    });
  };
  return (
    <View
      style={{
        backgroundImage: `url(${backgroundImage1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flex: 1,
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 30,
          paddingRight: 30,
          height: 79,
        }}>
        <Image source={HospitalLOGO}  style={{height:70,width:200,objectFit:"contain"}} />
        <View style={{flexDirection: 'row', ...rightHeaderStyle}}>
          <TouchableOpacity
            style={{
              paddingRight: 50,
              cursor: 'pointer',
              backgroundColor: !hoverSignIn ? '#fff' : themeColor,
              borderRadius: 16,
              paddingLeft: 16,
              paddingTop: 6,
              paddingRight: 15,
              paddingBottom: 7,
              marginRight: 16,
            }}
            onPress={() => {
              navigation.reset('patient-signIn');
            }}
            onMouseEnter={() => setHoverSignIn(true)}
            onMouseLeave={() => setHoverSignIn(false)}>
            <Text
              style={{
                ...h14_AvenirNext_Bold,
                color: hoverSignIn ? '#fff' : 'rgba(56, 56, 56, 0.7)',
                // opacity: 0.7,
              }}>
              Sign In
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              paddingRight: 50,
              cursor: 'pointer',
              backgroundColor: !hoverSignUp ? '#fff' : themeColor,
              borderRadius: 16,
              paddingLeft: 16,
              paddingTop: 6,
              paddingRight: 15,
              paddingBottom: 7,
            }}
            onPress={() => {
              navigation.reset('patient-signUp');
            }}
            onMouseEnter={() => setHoverSignUp(true)}
            onMouseLeave={() => setHoverSignUp(false)}>
            <Text
              style={{
                ...h14_AvenirNext_Bold,
                color: hoverSignUp ? '#fff' : 'rgba(56, 56, 56, 0.7)',
                // opacity: 0.7,
              }}>
              Sign Up
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{
          paddingTop: 127,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={{flexDirection: 'row', paddingBottom: 20}}>
          <Text style={{...h33_AvenirNext, color: '#fff'}}>
            Get the best care from the
          </Text>
          <Text
            style={{
              ...h33_AvenirNext,
              color: '#fff',
              fontWeight: 600,
              paddingLeft: 7,
            }}>
            {'best doctors of the world'}
          </Text>
        </View>
        <View style={{flexDirection: 'row'}}>
          <WithModal
            autoHide={true}
            dropdownStyle={{
              width: 170,
              height: 100,
            }}
            renderModal={({frameStyle, hideModal}) => {
              return (
                <View
                  style={{
                    ...frameStyle,
                    borderTopLeftRadius: 16,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                    backgroundColor: '#fff',
                    marginTop: 10,
                    paddingLeft: 25,
                    justifyContent: 'center',
                    marginLeft: -15,
                  }}>
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingBottom: 16,
                      cursor: 'pointer',
                    }}
                    onPress={() => {
                      setSelectedFilter('doctor');
                      hideModal();
                    }}>
                    <View
                      style={{
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        borderWidth: 1,
                        borderColor:
                          selectedFilter === 'doctor' ? '#5ccfe9' : '#cdcdce',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 16,
                      }}>
                      <View
                        style={{
                          width: 10,
                          height: 10,
                          borderRadius: '50%',
                          backgroundColor:
                            selectedFilter === 'doctor' ? '#5ccfe9' : '',
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        ...h15_Regular,
                        fontWeight: 500,
                        color: '#383838',
                        opacity: 0.54,
                      }}>
                      Doctor
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onPress={() => {
                      setSelectedFilter('speciality');
                      hideModal();
                    }}>
                    <View
                      style={{
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        borderWidth: 1,
                        borderColor:
                          selectedFilter === 'speciality'
                            ? '#5ccfe9'
                            : '#cdcdce',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 16,
                      }}>
                      <View
                        style={{
                          width: 10,
                          height: 10,
                          borderRadius: '50%',
                          backgroundColor:
                            selectedFilter === 'speciality' ? '#5ccfe9' : '',
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        ...h15_Regular,
                        fontWeight: 500,
                        color: '#383838',
                        opacity: 0.54,
                      }}>
                      Speciality
                    </Text>
                  </TouchableOpacity>
                </View>
              );
            }}>
            <TouchableOpacity
              style={{
                width: 155,
                height: 58,
                backgroundColor: '#fff',
                borderTopLeftRadius: 16,
                borderBottomLeftRadius: 16,
                borderRightWidth: 1,
                borderColor: 'rgb(151,151,151, 0.34)',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              <Text
                style={{
                  ...h15_Regular,
                  fontWeight: 500,
                  color: '#383838',
                  opacity: 0.54,
                  paddingRight: 12,
                }}>
                {selectedFilter === 'doctor' ? 'Doctor' : 'Speciality'}
              </Text>
              <Image source={dropdown} />
            </TouchableOpacity>
          </WithModal>

          {selectedFilter === 'speciality' ? (
            <SearchInput
              field={'speciality'}
              fetch={autosuggestFetch({
                id: 'getSpecialityList',
                search: 'name',
              })}
              onItemSelect={({item}) => {
                setFilterValue(item);
              }}
              placeholder={'Search here...'}
              sugestionField="name"
              valueField="name"
              searching={true}
              variant={'filled'}
              value={filterValue}
              renderRowProps={{
                primaryField: 'name',
              }}
              type={'specialitySearchRenderRow'}
              floatLabelOnActive={false}
              {...textTheme}
            />
          ) : (
            <TextInput
              variant={'filled'}
              {...textTheme}
              value={filterValue}
              onChangeValue={(value) => setFilterValue(value)}
              onSubmitEditing={() => navigateToDoctor()}
            />
          )}
          <TouchableOpacity
            style={{
              width: 93,
              height: 58,
              backgroundColor: themeColor,
              borderTopRightRadius: 16,
              borderBottomRightRadius: 16,
              borderLeftWidth: 1,
              borderColor: 'rgb(151,151,151, 0.34)',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onPress={() => {
              navigateToDoctor();
            }}>
            <Image source={searchFilter} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default PatientDoctorSearchLogin;
