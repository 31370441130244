import React from 'react';
import MapWithAMarker from './MapWithAMarker';

const GoogleMap = ({
  latitude = -34.397,
  longitude = 150.644,
  mapHeight = 200,
  locations = [],
  field = '',
}) => {
  return (
    <MapWithAMarker
      lat={latitude}
      lng={longitude}
      locations={locations}
      loadingElement={<div style={{height: mapHeight}} />}
      containerElement={<div style={{height: mapHeight}} />}
      mapElement={<div style={{height: mapHeight}} />}
      field={field}
    />
  );
};

export default GoogleMap;
