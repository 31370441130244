import React from 'react';
import Table from '../../app-components/table/Table';
import {removeData} from '../../AppServices';
import {listUri} from '../../Queries';
import {colors} from '../../theme/colors';
import {Text} from '../../app-components'
import { h12_Regular, h14_Medium} from '../../theme/fonts';
const {backdropColor70} = colors;
let uri = () => {
  return listUri({
    model: 'CareProgramSpeciality',
    id: 'careProgramSpecialityList',
  });
};

const tableData = {
  lg: {
    columns: [
      {
        render: (props) => {
          return <Text style={{...h12_Regular}}>{props.index + 1}</Text>;
        },
        header: 'S.No.',
        width: 150,
      },
      // {
      //   type: 'text',
      //   field: 'title',
      //   header: 'Title',
      //   width: 250,
      // },
      {
        type: 'text',
        field: 'speciality.name',
        header: 'Speciality',
      },
    ],
    hoverActions: [
      {
        value: 'Edit',
        align: 'right',
        textStyle: {
          color: '#9a9a9a',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'edit-care-program',
              params: {item},
            },
          };
        },
      },
      {
        value: 'Delete',
        position: 'center',
        align: 'right',
        textStyle: {
          color: 'rgb(241,79,79, 0.87)',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: {
          type: 'link',
          link: (props) => {
            let {item} = props;
            return {
              view: 'delete-masterData',
              modal: true,
              params: {
                item,
                label: 'Care Program',
                deleteAction: () =>
                  removeData({item, model: 'CareProgramSpeciality'}),
                reloadEvent: 'reloadCareProgram',
              },
              modalProps: {
                autoHide: true,
                height: 250,
                backdropColor: backdropColor70,
              },
            };
          },
        },
      },
    ],
  },
  sm: {
    columns: [
      {
        width: 50,
      },
      {
        render: (props) => {
          return <Text style={{...h12_Regular}}>{props.index + 1}</Text>;
        },
        header: 'S.No.',
        width: 150,
      },
      // {
      //   type: 'text',
      //   field: 'title',
      //   header: 'Title',
      //   width: 300,
      // },
      {
        type: 'text',
        field: 'speciality.name',
        header: 'Speciality',
      },
    ],
  },
};

export default Table({
  uri,
  ...tableData,
  reloadEvent: 'reloadCareProgram',
});
