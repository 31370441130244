import React from 'react';
import {TabPanel} from '../index';
import {getResolvedMQProps} from '../../BreakPoints';
import {TabBar, TabItem} from '../../npms/react-tab-bar-v1';

export default class FormTabNavigator extends React.Component {
  state = {};
  onTabChange = ({navigation}) => {
    if (navigation) {
      let isModalView = navigation.isModalView && navigation.isModalView();
      if (!isModalView) {
        let routeCount = navigation.getRouteCount && navigation.getRouteCount();
        if (routeCount && navigation.getIndex) {
          let count = routeCount - 1 - (navigation.getIndex() || 0);
          if (count) {
            navigation.pop(count);
          }
        }
      }
    }
  };
  render() {
    let {tabPanelStyle, tabBarStyle, tabItemStyle, ...restProps} = this.props;
    let {navigation, tabs, screenProps} = restProps;
    const activeMQ =
      navigation && navigation.getActiveMQ && navigation.getActiveMQ();
    if (tabs) {
      Object.keys(tabs).map((tab) => {
        let tabDetail = tabs[tab];
        tabs[tab] = getResolvedMQProps(tabDetail, {activeMQ});
      });
      restProps.tabs = tabs;
    }
    if (screenProps) {
      restProps.screenProps = getResolvedMQProps(screenProps, {activeMQ});
    }
    return (
      <TabPanel
        onTabChange={this.onTabChange}
        {...tabPanelStyle}
        renderTabBar={
          <TabBar {...tabBarStyle} renderItem={<TabItem {...tabItemStyle} />} />
        }
        {...restProps}
      />
    );
  }
}
