import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  isMobile,
} from '../../app-components';
import {
  h28_Regular,
  h28_SemiBold,
  h14_Regular,
  h12_Regular,
  h10_Medium,
  h18_Medium,
} from '../../theme/fonts';
import {preConsultMaya, selectSearchMaya} from '../../images';

const multiplyingFactor = isMobile ? 0.8 : 1;
function Provider(props) {
  const {t} = props; //useTranslation();
  const {navigation} = props;
  let fontSize = isMobile ? h18_Medium : h28_SemiBold;
  return (
    <ScrollView
      style={{
        // backgroundColor: 'red',
        flex: 1,
      }}>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: isMobile ? 5 : 35,
        }}>
        <Text style={{color: '#fff', ...h28_Regular}}>
          {/* Select an Option */}
          {t('Select an Option')}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}>
          <TouchableOpacity
            onPress={() => navigation.reset('health-assistant')}
            style={{
              ...style.container,
            }}>
            <View
              style={{
                backgroundColor: '#E7FFF6',
                ...style.imgContainer,
              }}>
              <Image
                style={{width: isMobile ? 20 : 35, objectFit: 'contain'}}
                source={preConsultMaya}></Image>
            </View>
            <Text style={{...fontSize, color: '#fff'}}>
              {/* Pre-Consultation with Maya */}
              {t('mayaPreConsultation')}
            </Text>
            <Text
              style={{
                ...h10_Medium,
                color: '#fff',
                marginTop: 10 * multiplyingFactor,
              }}>
              ({t('Recommended')})
            </Text>
            <Text
              style={{
                ...style.text,
                marginTop: 20 * multiplyingFactor,
              }}>
              {t('mayaPreConsultationText')}
            </Text>
          </TouchableOpacity>

          {/* DIVIDE */}
          <TouchableOpacity
            onPress={() => {
              props.navigation.replace('doctor-search');
            }}
            style={{
              ...style.container,
            }}>
            <View
              style={{
                backgroundColor: '#FEFFED',
                ...style.imgContainer,
              }}>
              <Image
                style={{width: isMobile ? 20 : 35, objectFit: 'contain'}}
                source={selectSearchMaya}></Image>
            </View>
            <Text style={{...fontSize, color: '#fff'}}>
              {/* Search for a Provider */}
              {t('searchProvider')}
            </Text>
            <Text
              style={{
                ...h10_Medium,
                color: '#fff',
                marginTop: 10 * multiplyingFactor,
              }}></Text>
            <Text
              style={{
                ...style.text,
              }}>
              {t('searchProviderText')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
}

const style = {
  container: {
    backgroundColor: 'rgba(1, 59, 86, 0.35)',
    minWidth: 400 * multiplyingFactor,
    cursor: 'pointer',
    // height: 200,
    marginTop: isMobile ? 15 : 60,
    marginRight: isMobile ? 10 : 25,
    marginLeft: isMobile ? 10 : 25,
    paddingTop: 20 * multiplyingFactor,
    paddingBottom: 20 * multiplyingFactor,
    // marginRight: 50,
    borderRadius: 35 * multiplyingFactor,
    alignItems: 'center',
  },
  text: {
    ...h12_Regular,
    color: '#fff',
    width: 300 * multiplyingFactor,
    marginTop: 28 * multiplyingFactor*0.5,
  },
  imgContainer: {
    height: isMobile ? 50 : 75,
    width: isMobile ? 50 : 75,
    borderRadius: 18,
    marginTop: isMobile ? 10 : 20,
    marginBottom: 30,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  },
};
export default Provider;
