import React from 'react';
import PatientDetailCard from '../GenericComponent/PatientDetailsCard';
import {View, ScrollView} from '../../app-components';
import Table from '../../app-components/table/Table';
import {getUser} from '../../AppServices';
let uri = (props) => {
  const {navigation: {state: {params: {item = {}} = {}} = {}} = {}} = props;
  const {patient_id: {_id: patientId} = {}} = item || {};
  let {doctor: {_id} = {}} = getUser();
  return {
    query: {
      id: 'appointmentLists',
      addOnFilter: {
        doctor_id: {_id},
        patient_id: {_id: patientId},
      },
      sort: {time_slot: {start_time: -1}},
    },
    model: 'Appointments',
  };
};

let tableItems = {
  renderRow: (props) => {
    const {item} = props || {};
    const {
      navigation: {state: {params: {item: currentItem = {}} = {}} = {}} = {},
    } = props || {};
    if (currentItem?._id === item?._id) {
      return null;
    }
    return <PatientDetailCard {...props} />;
  },
};
const Cardview = Table({uri, ...tableItems});

const MedicalHistory = (props) => {
  const {navigation, selectedTab} = props;
  return (
    <View
      style={{
        backgroundColor: '#f8fafb',
        flex: 1,
        overflow: 'hidden',
        display: selectedTab === 'medical_history' ? 'flex' : 'none',
      }}>
      <ScrollView style={{flex: 1}}>
        <Cardview navigation={navigation} {...props} />
      </ScrollView>
    </View>
  );
};
export default MedicalHistory;
