import React, {useState, useEffect} from 'react';
import {View, Text} from '../../app-components';
import {h13_Medium, h15_Medium, h14_Medium} from '../../theme/fonts';
import {getData} from '../../AppServices';
import {colors} from '../../theme/colors';
import {isEmpty} from 'lodash';
const {themeColor} = colors;

const MayaAiSymptomNonEditable = (props) => {
  const [mayaAIData, setMayaAIData] = useState({});
  const {mayaAISessionId = null} = props;
  const mayaData = async () => {
    if (mayaAISessionId) {
      const apiData = await getData({
        model: 'PatientNotes',
        id: 'patientNoteLists',
        filter: {
          sid: mayaAISessionId,
        },
      });
      const {data = []} = apiData;
      if (Array.isArray(data) && data.length) {
        console.log('>>AAAAA', data[0]);
        setMayaAIData(data[0]);
      }
    }
  };
  useEffect(() => {
    mayaData();
  }, []);

  if (isEmpty(mayaAIData)) {
    return null;
  }
  const {contexts = [], symptoms = []} = mayaAIData || {};
  return (
    <View style={{flex: 1, marginTop: 10}}>
      <Text
        style={{
          ...h15_Medium,
          marginBottom: 10,
          color: '#fff',
        }}>
        Context
      </Text>
      <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
        {contexts &&
          contexts.map((item) => (
            <View
              style={{
                ...style.chipStyle,
              }}>
              <Text
                numberOfLines={1}
                style={{...style.textStyle}}
                title={item?.alias_en || item?.name_en}>
                {item?.alias_en || item?.name_en}
              </Text>
            </View>
          ))}
      </View>
      <Text
        style={{
          ...h15_Medium,
          marginBottom: 10,
          color: '#fff',
        }}>
        Chief Complaints
      </Text>
      {symptoms &&
        symptoms.map((item) => (
          <View style={{flexDirection: 'row'}}>
            <View style={{padding: 3}}>
              <Text style={{...h13_Medium, color: '#fff', textAlign: 'center'}}>
                Disease
              </Text>
              <View
                style={{
                  ...style.chipStyle,
                }}>
                <Text
                  numberOfLines={1}
                  style={{...style.textStyle}}
                  title={item?.id?.alias_en || item?.id?.name_en}>
                  {item?.id?.alias_en || item?.id?.name_en}
                </Text>
              </View>
            </View>
            <View style={{padding: 3}}>
              <Text style={{...h13_Medium, color: '#fff', textAlign: 'center'}}>
                Duration
              </Text>
              <View
                style={{
                  ...style.chipStyle,
                }}>
                <Text
                  numberOfLines={1}
                  style={{...style.textStyle}}
                  title={item?.duration?.alias_en || item?.duration?.name_en}>
                  {item?.duration?.alias_en || item?.duration?.name_en}
                </Text>
              </View>
            </View>
            {item &&
            item?.qualifiers &&
            Array.isArray(item?.qualifiers) &&
            item?.qualifiers.length ? (
              <View style={{padding: 3}}>
                <Text
                  style={{...h13_Medium, color: '#fff', textAlign: 'center'}}>
                  Qualifiers
                </Text>
                {item && item?.qualifiers
                  ? item?.qualifiers.map((qul) => (
                      <View
                        style={{
                          ...style.chipStyle,
                        }}>
                        <Text
                          numberOfLines={1}
                          style={{
                            ...style.textStyle,
                          }}
                          title={qul?.name_en || qul?.alias_en}>
                          {qul?.alias_en || qul?.name_en || ''}
                        </Text>
                      </View>
                    ))
                  : void 0}
              </View>
            ) : (
              void 0
            )}
          </View>
        ))}
    </View>
  );
};

const style = {
  textStyle: {
    ...h14_Medium,
    color: themeColor,
    maxWidth: 180,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  chipStyle: {
    backgroundColor: '#fff',
    padding: 8,
    margin: 3,
    borderRadius: 16,
    maxWidth: 200,
    minWidth: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
};
export default MayaAiSymptomNonEditable;
