import React from 'react';
import {View, Text, Image} from '../../react-core-components';
import {resolveExp} from './Utility';

const DataCell = props => {
  let {column, item, typeCast, html} = props;
  let columnTextStyle = props['textStyle'] || props['rowColumnTextStyle'];
  let columnIconStyle = props['iconStyle'] || props['rowColumnIconStyle'];
  let columnIconGap = props['iconGap'] || props['rowColumnIconGap'] || 6;
  let columnIconPosition =
    props['iconPosition'] || props['rowColumnIconPosition'] || 'left';

  let {
    text,
    icon,
    field,
    value,
    render,
    title,
    containerStyle,
    iconStyle = columnIconStyle,
    textStyle = columnTextStyle,
    iconGap = columnIconGap,
    iconPosition = columnIconPosition,
    defaultText,
    type,
    modifyValue,
    align,
    prefix,
    suffix,
    resizeMode = 'contain',
    ...rest
  } = column;
  let rowComponent = void 0;
  if (render) {
    rowComponent = render(props);
  } else {
    if (!value && text) {
      value = text;
    }
    if (typeof value === 'function') {
      value = value(props);
    } else if (field) {
      value = resolveExp(item, field);
    }
    if (modifyValue) {
      value = modifyValue({value});
    }
    if (type && typeCast && typeCast[type]) {
      value = typeCast[type]({value, ...rest});
    }
    if (value === null || value === void 0) {
      value = defaultText;
    } else {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      } else {
        if (prefix) {
          value = prefix + value;
        }
        if (suffix) {
          value = value + suffix;
        }
      }
    }
    containerStyle = {
      flexDirection: 'row',
      alignItems: 'center',
      ...containerStyle,
    };
    if (align === 'right') {
      containerStyle.justifyContent = 'flex-end';
    } else if (align === 'center') {
      containerStyle.justifyContent = 'center';
    }
    let iconComponent = icon ? (
      <Image source={icon} style={iconStyle} resizeMode={resizeMode} />
    ) : (
      void 0
    );
    let valueComponent = void 0;
    if (value !== undefined) {
      if (html) {
        valueComponent = (
          <Text
            style={textStyle}
            dangerouslySetInnerHTML={{__html: value}}></Text>
        );
      } else {
        valueComponent = <Text style={textStyle}>{value}</Text>;
      }
    }
    let gapComponent =
      iconComponent && valueComponent ? (
        <View style={{width: iconGap}} />
      ) : (
        void 0
      );
    rowComponent = (
      <View style={containerStyle} title={title || value}>
        {iconPosition === 'left' && iconComponent ? (
          <>
            {iconComponent}
            {gapComponent}
          </>
        ) : (
          void 0
        )}
        {valueComponent}
        {iconPosition === 'right' && iconComponent ? (
          <>
            {gapComponent}
            {iconComponent}
          </>
        ) : (
          void 0
        )}
      </View>
    );
  }
  return rowComponent;
};

DataCell.defaultProps = {
  textStyle: {
    font: 14,
    color: '#383838',
    numberOfLines: 1,
  },
};
export default DataCell;
