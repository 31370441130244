const context = process.env;
// let baseUrl = context.REACT_APP_BASE_URL || 'https://mayamd-api.cloudzmall.com';
let baseUrl = 'https://svmapi.mayamd.ai';
// let baseUrl = 'http://localhost:7000';
let gpsSocketUrl =
  context.REACT_APP_SOCKET_URL || 'https://socket-mayamd.cloudzmall.com';
// baseUrl ='https://telemedicine-dev-api.mayamd.ai'
// gpsSocketUrl='https://telemedicine-dev-socket.mayamd.ai'
// baseUrl ='https://api.mayamdai.com'
// gpsSocketUrl ='https://socket.mayamdai.com'
export const BASE_URL = baseUrl;
export const SOCKET_BASE_URL = baseUrl;
export const GPS_SOCKET_URL = gpsSocketUrl;
export const SESSION_CHECK = process.env.REACT_APP_SESSION_CHECK;
export const VERSION = 'v-2.3.4 ';
export const SERVER_VERSION = 10;
export const MODE = process.env.REACT_APP_MODE;
export const LOG_ENABLED = process.env.REACT_APP_LOG_ENABLED;
export const ENCRYPTION = false;
//  context.REACT_APP_ENCRYPTION || true;
export const FIRE_BASE_CONFIGURATION = {
  apiKey: 'AIzaSyDOAtuzghVHjp04ZEC01zqPELlwhjC3nhQ',
  authDomain: 'mayamd-project.firebaseapp.com',
  projectId: 'mayamd-project',
  storageBucket: 'mayamd-project.appspot.com',
  messagingSenderId: '743495932437',
  appId: '1:743495932437:web:e169025d2d196124184c1c',
  measurementId: 'G-WGPSDBJY00',
};
const STRIPE_LIVE_KEY =
  'pk_live_51HwJgxDSkL3ExR1ZsOtRZvK7xY0sdJdZZC90VQDL13v9aMseYXoqlkXX3XkcsBtwBc6c0JB5IL4rEKkl1Fh1T5yk006LK7qps9';
const STRIPE_TEST_KEY =
  'pk_test_51HwJgxDSkL3ExR1ZNlbUYFX79wffsdKU9q2RTwrwW2X9efpAHFI2YJyc29TdFGKjbpTJ7ZZCOWijOiXQ7FeWzT7U00eqsnRv7m';
export const STRIPE_KEY = STRIPE_TEST_KEY;
export const RAZORPAY_DEV_KEY =
  'rzp_test_A2OCAp2ArVuJkM' || 'rzp_live_B3SAgQCYVjb5y4';
export const RAZORPAY_PROD_KEY =
  'rzp_test_A2OCAp2ArVuJkM' || 'rzp_live_B3SAgQCYVjb5y4';
export const BRAND_NAME = 'MayaMD';
export const mayaEngine = {
  apiKey: '6061a8d76d3ab00010b35430',
  apiSecret: '5wQZGx%KxL/ZbJH^|i',
  newApiUrl: 'https://mayaengine.mayamd.ai/',
};
