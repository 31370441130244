/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { ScrollView, View, TouchableOpacity, Text } from '../../app-components';

import { GradientButton } from '../../app-components/buttons/Buttons';
import { downloadExcelData, fetch } from '../../AppServices';
import { roles } from '../../Lib/constants';
import { h22_SemiBold, h18_SemiBold } from '../../theme/fonts';
import ReactCheckBox from '../../app-components/input-components/checkBox/CheckBox.js';
import { checkBoxTheme } from '../../theme/editorTheme';
import { isEmpty } from 'lodash';

const ExportSelectedModal = (props) => {
  const { navigation } = props;
  const {
    state: {
      params: {
        id = '',
        model = '',
        queryId = '',
        dataParams = [],
      },
    },
  } = navigation;
  const limit = 50000;

  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setLoading] = useState(true);
  let [selectedCategories, setSelectedCategories] = useState({
    isSelectedScreeningQue: true,
    isSelectedScreeningInference: true,
    isSelectedTestResult: true,
    isSelectedVitals: true,
    isSelectedLabInference: true,
  });

  let filters = {};
  if (!isEmpty(dataParams)) {
    filters = {
      _id: { $in: dataParams }
    }
  }

  useEffect(() => {
    fetch({
      uri: {
        props: {
          query: {
            id: queryId,
            fieldAggregates: { _count: 1 },
            addOnFilter: filters,
          },
          model,
        },
      },
    })
      .then((res) => {
        setTotalCount(res?.aggregates?._count);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [
    filters,
    model,
    queryId,
  ]);

  const handleOnPressExport = useCallback(
    async (skip, limit) => {
      downloadExcelData({
        uriProps: {
          id,
          paramValue: {
            model,
            file: 'memberListSheet',
            _skip: skip,
            _limit: limit,
            _query: {
              id: queryId,
              addOnFilter: filters,
            },
            selectedCategories
          },
        },
      });
    }, [selectedCategories, filters]);

  const CategoryCheckBoxes = () => {
    return (
      <View style={{ marginTop: 10, marginBottom: 10 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View>
            <View style={{ flexDirection: 'row', padding: 2, marginBottom: 10 }}>
              <ReactCheckBox
                {...checkBoxTheme}
                {...props}
                value={selectedCategories?.isSelectedVitals}
                onChangeValue={(val) => setSelectedCategories({ ...selectedCategories, isSelectedVitals: val })}
              />
              <Text style={{ fontSize: 15, marginLeft: 4 }}>Vitals</Text>
            </View>
            <View style={{ flexDirection: 'row', padding: 2 , marginBottom: 10}}>
              <ReactCheckBox
                {...checkBoxTheme}
                {...props}
                value={selectedCategories?.isSelectedTestResult}
                onChangeValue={(val) => setSelectedCategories({ ...selectedCategories, isSelectedTestResult: val })}
              />
              <Text style={{ fontSize: 15, marginLeft: 4 }}>Lab Results</Text>
            </View>
            <View style={{ flexDirection: 'row', padding: 2 }}>
              <ReactCheckBox
                {...checkBoxTheme}
                {...props}
                value={selectedCategories?.isSelectedLabInference}
                onChangeValue={(val) => setSelectedCategories({ ...selectedCategories, isSelectedLabInference: val })}
              />
              <Text style={{ fontSize: 15, marginLeft: 4 }}>Lab Inferences</Text>
            </View>
          </View>
          <View>
            <View style={{ flexDirection: 'row', padding: 2, marginBottom: 10 }}>
              <ReactCheckBox
                {...checkBoxTheme}
                {...props}
                value={selectedCategories?.isSelectedScreeningQue}
                onChangeValue={(val) => setSelectedCategories({ ...selectedCategories, isSelectedScreeningQue: val })}
              />
              <Text style={{ fontSize: 15, marginLeft: 4 }}>Screening Questions</Text>
            </View>
            <View style={{ flexDirection: 'row', padding: 2 }}>
              <ReactCheckBox
                {...checkBoxTheme}
                {...props}
                value={selectedCategories?.isSelectedScreeningInference}
                onChangeValue={(val) => setSelectedCategories({ ...selectedCategories, isSelectedScreeningInference: val })}
              />
              <Text style={{ fontSize: 15, marginLeft: 4 }}>Screening Inferences</Text>
            </View>
          </View>
        </View>
      </View>
    )
  };

  const Loader = () => {
    return (
      <View
        style={{
          fontSize: '15px',
          textAlign: 'center',
          ...h18_SemiBold,
        }}>
        <Text>Loading...</Text>
      </View>
    );
  };

  return (
    <View
      style={{
        backgroundColor: '#f9f9f9',
        padding: 25,
        borderRadius: 8,
      }}>
      <View
        style={{
          fontSize: '40px',
        }}>
        <Text style={{ fontSize: '45px', color: '#275dad', ...h22_SemiBold }}>
          Export Selected Members
        </Text>
      </View>
      <ScrollView style={{ maxHeight: 400 }}>
        {!isLoading ? (
          <>
            <CategoryCheckBoxes />
            <TouchableOpacity
              onPress={() => handleOnPressExport(0, totalCount)}
              style={{
                paddingBottom: 10,
                paddingTop: 10,
                paddingRight: 10,
                cursor: 'pointer',
              }}>
              <GradientButton
                label={`Click here to export 1-${totalCount} records.`}
              />
            </TouchableOpacity>
          </>
        ) : (
          <Loader />
        )}
      </ScrollView>
      <TouchableOpacity
        onPress={() => navigation.pop()}
        style={{
          paddingBottom: 10,
          paddingTop: 10,
          paddingRight: 10,
          cursor: 'pointer',
        }}>
        <GradientButton label={'Cancel'} />
      </TouchableOpacity>
    </View>
  );
};

export default ExportSelectedModal;
