import React from 'react';
import {View, Text, Image} from '../../app-components';
import { resolvePatientName } from '../../app-components/patientUtility';

import {resolveValue} from '../../app-components/UtilityFunctions';
const getAvatarDisplay = (input) => {
  let value = resolvePatientName({name: input})
  if (value && typeof value === 'string') {
    value = value.trim();
  }
  let _avatarDisplay = '';
  if (value) {
    var splitValues = value.split(' ');
    let _firstName = splitValues[0];
    let _lastName = splitValues[1];
    _avatarDisplay = _firstName[0] + (_lastName ? _lastName[0] : '');
  }
  return _avatarDisplay.toUpperCase();
};

export default (props) => {
  let {
    data,
    value,
    text,
    circular,
    icon,
    theme = {},
    bg,
    title,
    displayValue,
  } = props;

  let containerStyle = {...theme.container};
  let textStyle = theme.text;
  let imageStyle = theme.image;

  if (circular) {
    containerStyle.borderRadius = containerStyle.height / 2;
  }
  if (bg) {
    containerStyle['backgroundColor'] = bg;
  }

  var valueToDisplay = displayValue || '';
  if (text) {
    valueToDisplay = getAvatarDisplay(text);
  } else if (data && value) {
    let valueToShow =
      typeof value === 'function' ? value(data) : resolveValue(data, value);
    valueToDisplay = getAvatarDisplay(valueToShow);
  }
  return (
    <View style={{...containerStyle}}>
      {icon ? (
        <Image source={icon} style={{...imageStyle}} />
      ) : (
        <Text style={{...textStyle}} title={title || text}>
          {valueToDisplay}
        </Text>
      )}
    </View>
  );
};
