import {isMobile} from '../index';
import {centerTabTheme, centerTabMobileTheme} from '../../theme/tabBarTheme';
import TabNavigator from './TabNavigator';

export default defs => {
  return TabNavigator({
    key: 'centerTab',
    theme: isMobile ? centerTabMobileTheme : centerTabTheme,
    ...defs,
  });
};
