import React from 'react';
import {OTSession, OTPublisher, OTStreams, OTSubscriber} from 'opentok-react';
import OT from '@opentok/client';
import PatientChatBox from './PatientChatBox';
import {
  Dimensions,
  Image,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from '../../app-components';
import {h16_Regular, h18_AvenirNext} from '../../theme/fonts';
import {
  audioIcon,
  muteIcon,
  endCall,
  snipToolIcon,
  videoIcon,
  videoOffIcon,
  patientDoctorChat,
  // hospitalLiveLogo,
} from '../../images';
import SidePanel from './SidePanel';
import {fetch, getUser, invoke} from '../../AppServices';
import {bgs} from '../../theme/colors';
let {borderColor} = bgs;

export default class VideoSession extends React.Component {
  constructor(props) {
    super(props);
    this.item = this.props?.navigation?.state?.params?.item;

    this.state = {
      error: null,
      connection: 'Connecting',
      visibleSmallView: false,
      publishVideo: true,
      publishAudio: true,
      sideViewVisible: 'none',
      publisherViewWidth: Dimensions.get('window').width,
      buttonTop: Dimensions.get('window').height / 2.5,
      audio: true,
      video: true,
      chatData: [],
      openChatBox: false,
      toggleChat: true,
    };
  }
  toggleChatBox = () => {
    const {toggleChat} = this.state;

    this.setState({toggleChat: !toggleChat});
    const {publisherViewWidth, sideViewVisible} = this.state;

    if (sideViewVisible === 'none') {
      this.setState({
        sideViewVisible: 'flex',
        publisherViewWidth: publisherViewWidth / 1.39,
      });
    } else {
      this.setState({
        sideViewVisible: 'none',
        publisherViewWidth: publisherViewWidth * 1.39,
      });
    }
  };
  WebCall = async () => {
    let {_id} = this.item;

    let data = {
      appointment: _id,
    };
    let result = await invoke({
      paramValue: {data},
      id: 'createOpenTokRoom',
    });
    let {apiKey, token, sessionId} = result || {};
    this.initSession({apiKey, sessionId});
    const {eventDispatcher = {}} = this.props || {};
    eventDispatcher.notify('reloadAddApointment');
    eventDispatcher.notify('reloadInstantApointment');
    this.setState({
      apiKey: apiKey,
      token: token,
      sessionId: sessionId,
    });
  };

  popScreens = ({navigation, session}) => {
    // let openedViewCount = navigation.getRouteCount();
    session.disconnect();
    navigation.pop();
  };

  initSession = ({apiKey, sessionId}) => {
    if (apiKey && sessionId) {
      this.session = OT.initSession(apiKey, sessionId);
    }
    let e = this;
    this.session &&
      this.session.on('streamDestroyed', function (event) {
        // if (event.reason === 'clientDisconnected') {
        //   let {navigation} = e.props;
        //   e.session.disconnect();
        //   let session = e.session;
        //   e.popScreens({navigation, session});
        // }
      });
  };

  openChat = () => {
    const {publisherViewWidth, sideViewVisible} = this.state;
    if (sideViewVisible === 'none') {
      this.setState({
        sideViewVisible: 'flex',
        // publisherViewWidth: publisherViewWidth / 1.8,
      });
    } else {
      this.setState({
        sideViewVisible: 'none',
        // publisherViewWidth: publisherViewWidth * 1.8,
      });
    }
  };
  componentDidMount() {
    this.WebCall();
    const userTypeData = getUser()?.userType;
    // if (userTypeData !== 'Patient') {
    //   this.openChat();
    // }
  }
  renderSubscriber = () => {
    let {publisherViewWidth, buttonTop} = this.state;
    const userTypeData = getUser()?.userType;
    return (
      <View
        style={{
          position: 'relative',
          zIndex: 1,
          width: publisherViewWidth,
        }}>
        <OTStreams>
          <OTSubscriber
            properties={{
              width: getUser()?.userType === 'Patient' ? '100%' : '100%',
              height: Dimensions.get('window').height,
            }}
          />
        </OTStreams>
        {userTypeData === 'Patient' ? null : (
          <TouchableOpacity
            onPress={() => this.openChat()}
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              zIndex: 1,
              width: 20,
              height: 80,
              right: 0,
              top: buttonTop,
              justifyContent: 'space-evenly',
              alignItems: 'center',
              borderTopLeftRadius: 50,
              borderBottomLeftRadius: 50,
              borderTopWidth: 1,
              borderBottomWidth: 1,
              borderLeftWidth: 1,
              borderColor: borderColor,
              cursor: 'pointer',
            }}>
            <View
              style={{
                height: 25,
                width: 1,
                backgroundColor: 'black',
                borderStyle: 'solid',
                borderWidth: 1.5,
                borderColor: 'black',
                borderRadius: 12,
                opacity: 0.34,
              }}
            />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  publisher = () => {
    const {publishAudio, publishVideo} = this.state;
    return (
      <View
        style={{
          position: 'absolute',
          zIndex: 1,
          top: 10,
          // left: 30,
          right: 20,
          borderRadius: 20,
          overflow: 'hidden',
          boxShadow: 'rgba(0,0,0,0.4) 5px 5px 10px 0px'
        }}>
        <OTPublisher
          properties={{
            publishVideo,
            publishAudio,
            width: 95,
            height: 120,
            borderRadius: 10,
          }}
        />
        <View
          style={{
            position: 'absolute',
            margin: 10,
            zIndex: 2,
            justifyContent: 'center',
          }}>
          <Text style={{...h16_Regular, color: 'white'}}>You</Text>
        </View>
      </View>
    );
  };

  muteUnMute = () => {
    this.setState((state) => ({
      audio: !state.audio,
      publishAudio: !state.publishAudio,
    }));
  };

  endCall = () => {
    let {navigation} = this.props;
    this.session.disconnect();
    let session = this.session;
    this.popScreens({navigation, session});
  };

  turnOnOffVideo = () => {
    this.setState((state) => ({
      video: !state.video,
      publishVideo: !state.publishVideo,
    }));
  };

  renderPubliserControl = () => {
    const {audio, video} = this.state;
    const userTypeData = getUser()?.userType;
    let controls = [
      {icon: audio ? audioIcon : muteIcon, onPress: this.muteUnMute},
      {icon: endCall, onPress: this.endCall},
      {
        icon: video ? videoIcon : videoOffIcon,
        onPress: this.turnOnOffVideo,
      },
      (userTypeData === 'Patient'?{icon: patientDoctorChat, onPress: this.toggleChatBox}:{}),
    ];
    return (
      <View
        style={{
          flexDirection: 'row',
        }}>
        {controls.map((item) => {
          let {icon, onPress} = item;
          return (
            <TouchableOpacity
              style={{margin: 5, cursor: 'pointer'}}
              onPress={() => onPress && onPress()}>
              <Image source={icon} style={{height: 50, width: 50}} />
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };
  renderPublisher = () => {
    const {publisherViewWidth} = this.state;
    return (
      <>
        {this.publisher()}
        <View
          style={{
            position: 'absolute',
            zIndex: 1,
            alignItems: 'center',
            marginLeft: 10,
            top: Dimensions.get('window').height - 100,
            width: publisherViewWidth,
          }}>
          {this.renderPubliserControl()}
        </View>
      </>
    );
  };

  renderBody = () => {
    const {
      publisherViewWidth,
      visibleSmallView,
      sideViewVisible,
      chatData,
      toggleChat,
    } = this.state;
    // const userTypeData = getUser()?.userType;

    return (
      <View
        style={{
          position: 'relative',
          display:sideViewVisible === 'none'? 'flex': 'none',
        }}>
        {this.renderSubscriber()}

        {visibleSmallView && this.renderPublisher()}

        {/* {userTypeData === 'Patient' && toggleChat && (
          <TouchableOpacity
            onPress={() => {
              this.toggleChatBox();
            }}
            style={{
              position: 'absolute',
              right: 50,
              zIndex: 1,
              top: Dimensions.get('window').height - 200,
              height: 46,
              borderRadius: 30,
              backgroundColor: '#2e6ecd',
              padding: 20,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Text style={{...h18_AvenirNext, color: '#fff'}}>Chat</Text>
          </TouchableOpacity>
        )} */}
      </View>
    );
  };

  render() {
    const userTypeData = getUser()?.userType;
    const {
      apiKey,
      token,
      sessionId,
      publisherViewWidth,
      sideViewVisible,
      chatData,
      toggleChat,
      visibleSmallView,
    } = this.state;
    if (apiKey && token && sessionId) {
      return (
        <>
          <OTSession
            apiKey={apiKey}
            sessionId={sessionId}
            token={token}
            onConnect={() => {
              this.setState({visibleSmallView: true});
            }}>
            {visibleSmallView ? (
              this.renderBody()
            ) : userTypeData !== 'Patient' ? (
              <View
                style={{
                  width: publisherViewWidth,
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: Dimensions.get('window').height,
                  flex: 1,
                }}>
                <ActivityIndicator size={'large'} />
              </View>
            ) : (
              <ActivityIndicator size={'large'} />
            )}
          </OTSession>
          {userTypeData !== 'Patient' && (
            <SidePanel
              sideViewVisible={sideViewVisible}
              publisherViewWidth={publisherViewWidth}
              {...this.props}
              item={this.item}
              chatData={chatData}
              openChat={this.openChat}
            />
          )}
          {!toggleChat && (
            <PatientChatBox
              sideViewVisible={sideViewVisible}
              publisherViewWidth={publisherViewWidth}
              {...this.props}
              item={this.item}
              toggleChatBox={this.toggleChatBox}
              chatData={chatData}
            />
          )}
        </>
      );
    } else {
      return <ActivityIndicator size={'large'} />;
    }
  }
}
