import {
  filledInputTheme,
  filledCompactInputTheme,
  filledCompactUnborderedInputTheme,
  standardCompactInputTheme,
  standardInputTheme,
  standardCompactUnborderedInputTheme,
  filledCompactBorderedInputTheme,
  standardCompactBorderedInputTheme,
} from '../../theme/inputTheme';
import {
  filledCompactBorderedMobileInputTheme,
  filledCompactMobileInputTheme,
  filledCompactUnborderedMobileInputTheme,
  filledMobileInputTheme,
  standardCompactBorderedMobileInputTheme,
  standardCompactMobileInputTheme,
  standardCompactUnborderedMobileInputTheme,
  standardMobileInputTheme,
} from '../../theme/inputMobileTheme';
import { isMobile } from '../../Lib/detectMobile';

const variantWiseTheme = isMobile
  ? {
      standard: standardMobileInputTheme,
      filled: filledMobileInputTheme,
      'standard-compact': standardCompactMobileInputTheme,
      'filled-compact': filledCompactMobileInputTheme,
      'standard-compact-unbordered': standardCompactUnborderedMobileInputTheme,
      'filled-compact-unbordered': filledCompactUnborderedMobileInputTheme,
      'standard-compact-bordered': standardCompactBorderedMobileInputTheme,
      'filled-compact-bordered': filledCompactBorderedMobileInputTheme,
    }
  : {
      standard: standardInputTheme,
      filled: filledInputTheme,
      'standard-compact': standardCompactInputTheme,
      'filled-compact': filledCompactInputTheme,
      'standard-compact-unbordered': standardCompactUnborderedInputTheme,
      'filled-compact-unbordered': filledCompactUnborderedInputTheme,
      'standard-compact-bordered': standardCompactBorderedInputTheme,
      'filled-compact-bordered': filledCompactBorderedInputTheme,
    };

export const getInputTheme = props => {
  let {
    variant = 'standard',
    variantTheme,
    height,
    minHeight,
    maxHeight,
    moreInputStyle,
  } = props || {};
  let inputTheme =
    (variantTheme && variantWiseTheme[variantTheme]) ||
    variantWiseTheme[variant];
  inputTheme = {
    ...inputTheme,
  };
  let extraProps = {...moreInputStyle};
  if (height) {
    extraProps.height = height;
  }
  if (minHeight) {
    extraProps.minHeight = minHeight;
  }
  if (maxHeight) {
    extraProps.maxHeight = maxHeight;
  }
  if (extraProps) {
    inputTheme.inputStyle = {
      ...inputTheme.inputStyle,
      ...extraProps,
    };
    if (inputTheme.floatingLabelInputStyle) {
      inputTheme.floatingLabelInputStyle = {
        ...inputTheme.floatingLabelInputStyle,
        ...extraProps,
      };
    }
  }
  return inputTheme;
};
