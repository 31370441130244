import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {PLACEHOLDER} from '../../app-components/utils';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {closeButton, getNewId} from '../RPM/rpmUtils';
const space = {
  width: 35,
  type: 'custom',
  render: () => {
    return [];
  },
};
export default ({uri, editable = true, header}) => {
  return Form({
    type: 'standardShadow',
    uri,
    onSubmit: submit({
      model: 'TodoCategory',
    }),
    reloadEvent: 'reloadTodoCategory',
    closeView: 1,
    mandatory: {name: 1},
    // defaultValues: () => {
    //   return editable ? {tasks:[{_id: getNewId()}]} : {};
    // },
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 2,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'To-Do Task Category',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  // editable: editable,
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'To-Do Task Category',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  editable: editable,
                },
              ],
            },
          ],
        },
      ],
    },
  });
};
