import React from 'react';
import StyleSheet from './StyleSheet';
import _ from 'lodash';
class TextInput extends React.Component {
  onChange = (e) => {
    let {
      setValue,
      field,
      data,
      dataUpdate,
      onChangeText,
      onChange,
    } = this.props;
    let text = e.target.value;
    if (dataUpdate) {
      setValue = dataUpdate.setValue;
      data = dataUpdate.data;
    }
    if (onChange) {
      onChange(e);
    }
    if (onChangeText) {
      onChangeText(text);
    }
    if (setValue) {
      if (field && data) {
        setValue({field, value: text, event: e});
      } else {
        setValue({value: text, event: e});
      }
    }
  };

  onFocus = (e) => {
    let {setFocus, dataUpdate, field, onFocus} = this.props;

    if (dataUpdate) {
      setFocus = dataUpdate.setFocus;
    }

    if (setFocus && field) {
      setFocus({field, focus: true});
    }
    onFocus && onFocus(e);
  };
  onBlur = (e) => {
    let {setFocus, dataUpdate, field, onBlur} = this.props;
    if (setFocus && field) {
      setFocus({field, focus: false});
    } else if (dataUpdate) {
      dataUpdate.setFocus({field, focus: false});
    }
    onBlur && onBlur(e);
  };
  onKeyUp = (e) => {
    let {onKeyUp, onSubmitEditing} = this.props;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      onSubmitEditing && onSubmitEditing(e);
    }
    onKeyUp && onKeyUp(e);
  };

  render() {
    let {
      value,
      dataUpdate,
      data,
      field,
      onChangeValue,
      editable,
      onSubmitEditing,
      getRef,
      style,
      theme,
      ...restProps
    } = this.props;

    if (Array.isArray(style)) {
      style = StyleSheet.flatten(style);
    }

    style = theme || style;

    if (style && style.lineHeight && typeof style.lineHeight === 'number') {
      style = {...style, lineHeight: style.lineHeight + 'px'};
    }

    if (dataUpdate) {
      data = dataUpdate.data;
    }

    if (data && field) {
      value = _.get(data, field);
    }
    if (value === undefined || value === null) {
      value = '';
    }

    let extraProps = {};
    if (editable === false) {
      extraProps['disabled'] = true;
    }
    if (getRef) {
      extraProps.ref = getRef;
    }
    if (onSubmitEditing) {
      extraProps['onKeyUp'] = this.onKeyUp;
    }
    return (
      <input
        {...restProps}
        {...extraProps}
        style={style}
        value={value}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      />
    );
  }
}
export default TextInput;
