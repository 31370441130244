import SignupTab from './SignupTab';
import SignupForm from './SignupForm';
import EditPatient from './EditPatient';

export default {
  'patient-clinic-signup': {
    screen: SignupTab,
  },
  'patient-signup-form': {
    screen: SignupForm,
    expanded: true
  },
  'edit-patient-form':{
    screen:EditPatient,
    expanded: true
  }
};
