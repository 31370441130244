import React, {useEffect, useState} from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  Image,
  isMobile,
} from '../../../app-components';
import {getFileExtension} from '../../../app-components/UtilityFunctions';
import {getImageUrl, invoke} from '../../../AppServices';
import {pdf} from '../../../images';
import moment from 'moment';

const MayaMdPastNotes = (props) => {
  const {item = {}} = props;
  const {mayamd_notes = []} = item;
  const [prevMayaNoteArrayMain, setPrevMayaNoteArrayMain] = useState([]);
  const [prevMayaNoteArray, setPrevMayaNoteArray] = useState([]);
  const [prevMayaNoteArraySlice, setPrevMayaNoteArraySlice] = useState([]);
  const [
    prevMayaNoteArraySliceSecond,
    setPrevMayaNoteArraySliceSecond,
  ] = useState([]);

  useEffect(() => {
    try {
      (async () => {
        await invoke({
          id: '_find',
          paramValue: {
            query: {
              id: 'patientNoteLists',
              addOnFilter: {patient: {_id: item?.patient_id?._id}},
            },
            model: 'PatientNotes',
          },
        })
          .then((res) => {
            const {result = []} = res || {};
            setPrevMayaNoteArrayMain(result);
          })
          .catch((err) => console.log('<<< err >>>', err));
      })();
    } catch (err) {
      console.log('<<< err >>>', err);
    }
  }, []);
  const IconLength = 4;
  useEffect(() => {
    setPrevMayaNoteArraySlice(prevMayaNoteArray?.slice(0, IconLength));
    setPrevMayaNoteArraySliceSecond(
      prevMayaNoteArray?.slice(IconLength, prevMayaNoteArray?.length),
    );
  }, [prevMayaNoteArray]);

  useEffect(() => {
    let filterArray = prevMayaNoteArrayMain?.filter((item) => {
      if (
        Array.isArray(mayamd_notes) &&
        mayamd_notes[0]?.file != item?.mayamd_note?.file
      )
        return item;
    });
    setPrevMayaNoteArray(filterArray);
  }, [prevMayaNoteArrayMain]);
  return (
    <View
      style={{
        backgroundColor: '#f9f9f9',
        marginTop: 10,
        padding: 10,
        borderRadius: 8,
      }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          // marginTop: 15,
        }}>
        <View>
          <View style={{flexDirection: 'row'}}>
            {Array.isArray(prevMayaNoteArraySlice)
              ? prevMayaNoteArraySlice.map((item) => {
                  return (
                    <TouchableOpacity
                      style={{
                        height: 49,
                        width: 50,
                        marginLeft: 5,
                        borderRadius: 4,
                        cursor: 'pointer',
                      }}
                      title={moment(item?.date).format('MM/DD/YY')}
                      onPress={() => {
                        const {navigation} = props;

                        navigation.push({
                          view: 'pdfReader',
                          params: {
                            dataArr: prevMayaNoteArray,
                            prevNote: true,
                          },
                          modal: true,

                          modalProps: {
                            autoHide: true,
                            minHeight: 180,
                            position: 'screenRight',
                            style: {
                              height: '100%',
                              width: isMobile
                                ? '100%'
                                : Dimensions.get('window').width -
                                  props.publisherViewWidth,
                              zIndex: 999,
                            },
                            backdropStyle: {},
                            // ...confirmModalProps,
                          },
                        });
                      }}>
                      {getFileExtension(item?.mayamd_note?.name) === 'pdf' ? (
                        <Image source={pdf} style={{height: 49, width: 50}} />
                      ) : (
                        <Image
                          source={getImageUrl(item?.thumbnail)}
                          style={{height: 49, width: 50}}
                        />
                      )}
                    </TouchableOpacity>
                  );
                })
              : null}
            {Array.isArray(prevMayaNoteArraySliceSecond) &&
            prevMayaNoteArraySliceSecond.length ? (
              <TouchableOpacity
                style={{
                  height: 49,
                  width: 50,
                  marginLeft: 5,
                  borderRadius: 4,
                  justifyContent: 'center',
                  backgroundColor: '#383838',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onPress={() => {
                  const {navigation} = props;

                  navigation.push({
                    view: 'past-notes',
                    params: {
                      dataArr: prevMayaNoteArraySliceSecond,
                      prevNote: true,
                      publisherViewWidth: props.publisherViewWidth,
                    },
                    modal: true,
                    modalProps: {
                      autoHide: true,
                      minHeight: 180,
                      position: 'screenRight',
                      style: {
                        height: '100%',
                        width: isMobile
                          ? '100%'
                          : Dimensions.get('window').width -
                            props.publisherViewWidth,
                        zIndex: 999,
                      },
                      backdropStyle: {},
                      // ...confirmModalProps,
                    },
                    // modalProps: {
                    //   autoHide: true,
                    //   minHeight: 180,
                    //   height: 600,
                    //   width: isMobile ? 300 : 700,
                    //   position: 'screenCenter',
                    //   // ...confirmModalProps,
                    // },
                  });
                }}>
                <Text style={{color: '#fff'}}>
                  {'+' + prevMayaNoteArraySliceSecond.length}
                </Text>
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};

export default MayaMdPastNotes;
