import React from 'react';
import {ModalView} from '../../react-with-modal';
import {getRenderComponent} from './Utility';
export class StackModal extends React.Component {
  render() {
    let {
      index,
      view,
      modalViews,
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      autoHide,
      modalProps,
      getResolvedMQProps,
      modalStyle,
      renderHeader,
      renderFooter,
      ScreenComponent,
      closeModal,
    } = this.props;
    const {
      screen: Component,
      screenProps,
      header,
      footer,
      renderHeader: viewRenderHeader,
      renderFooter: viewRenderFooter,
      ...restProps
    } = view;
    if (viewRenderHeader) {
      renderHeader = viewRenderHeader;
    }
    if (viewRenderFooter) {
      renderFooter = viewRenderFooter;
    }
    if (autoHide !== undefined) {
      modalProps = {...modalProps, autoHide};
    }
    if (modalProps && getResolvedMQProps) {
      modalProps = getResolvedMQProps(modalProps);
    }
    let display = void 0;
    if (index < modalViews.length - 1) {
      let nextModalProps = modalViews[index + 1].modalProps;
      if (!nextModalProps || !nextModalProps.parentDisplay) {
        display = 'none';
      }
    }
    let screenComponentProps = {
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      autoHide,
      ...screenProps,
      ...restProps,
    };
    return (
      <ModalView
        key={`stackModal_${index}`}
        index={index}
        display={display}
        closeModal={closeModal}
        {...modalStyle}
        {...modalProps}>
        {getRenderComponent(renderHeader, {
          navigation,
          eventDispatcher,
          modal: true,
          header,
        })}
        {ScreenComponent ? (
          <ScreenComponent {...screenComponentProps} screen={Component} />
        ) : (
          <Component {...screenComponentProps} />
        )}
        {getRenderComponent(renderFooter, {
          modal: true,
          navigation,
          eventDispatcher,
          footer,
        })}
      </ModalView>
    );
  }
}

export default StackModal;
