import Form from '../../app-components/form/Form';
import {submit} from '../../AppServices';
import {PLACEHOLDER} from '../../app-components/utils';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {searchInput} from '../../autoSuggestions';
const space = {
  width: 35,
  type: 'custom',
  render: () => {
    return [];
  },
};
export default ({uri, editable = true, header}) => {
  return Form({
    type: 'standardShadow',
    uri,
    onSubmit: submit({
      model: 'Todo',
    }),
    reloadEvent: 'reloadTodo',
    closeView: 1,
    mandatory: {category: 1, title: 1},
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 2,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                searchInput({
                  label: 'To-Do task Category',
                  field: 'category',
                  placeholder: 'Select Category',
                  query: 'todoCategoryList',
                  model: 'TodoCategory',
                  valueField: 'name',
                  suggestionField: 'name',
                }),
                {
                  field: 'image',
                  placeholder: 'File',
                  label: 'Image',
                  uploadOptions: {s3: true},
                  type: 'file',
                },
                {
                  type: 'text',
                  field: 'title',
                  label: 'Title',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'checkBox',
                  field: 'enable_algorithm',
                  label: 'Enable algorithm',
                  enableLabel: true,
                },
              ],
            },
          ],
        },
      ],
    },

    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'category',
                  label: 'To-Do Task Category',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  // editable: editable,
                },
              ],
            },
            {
              columns: [
                {
                  field: 'image',
                  placeholder: 'File',
                  label: 'Image',
                  uploadOptions: {s3: true},
                  type: 'file',
                },
                {
                  type: 'text',
                  field: 'title',
                  label: 'Title',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'checkBox',
                  field: 'enable_algorithm',
                  label: 'Enable algorithm',
                  enableLabel: true,
                },
              ],
            },
          ],
        },
      ],
    },
  });
};
