import Form from '../../app-components/form/Form';
import {submit, getUser} from '../../AppServices';
import {PLACEHOLDER} from '../../app-components/utils';
import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
// import {updatePatientDataInForm} from '../../app-components/patientUtility';

export default ({header, uri}) => {
  return Form({
    type: 'standardShadow',
    onSubmit: submit({
      model: 'Patients',
      lowerCaseFields: ['email'],
    }),
    uri,
    closeView: 1,
    reloadEvent: 'reloadPatientData',
    mandatory: {first_name: 1},
    // beforeSubmit: updatePatientDataInForm,
    defaultValues: ({
      navigation: {
        state: {params: {item: {_id} = {}} = {}},
      },
    }) => {
      if (!_id) {
        const {organization = {}} = getUser();
        _id = organization?._id;
      }
      return {
        userType: 'Patient',
        organization: {_id},
      };
    },
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columnsPerRow: 2,
              columns: [
                {
                  type: 'text',
                  field: 'first_name',
                  label: 'First Name',
                  placeholder: PLACEHOLDER,
                  // width: '50%',
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'last_name',
                  label: 'Last Name',
                  placeholder: PLACEHOLDER,
                  // width: '50%',
                },
                {
                  type: 'chipsToggle',
                  field: 'gender',
                  label: 'Gender',
                  options: [
                    {value: 'male', label: 'Male'},
                    {value: 'female', label: 'Female'},
                    {value: 'other', label: 'Other'},
                  ],
                },
                {
                  type: 'mobile',
                  field: 'mobile',
                  label: 'Mobile number',
                  placeholder: PLACEHOLDER,
                },
                {
                  type: 'date',
                  field: 'birthDate',
                  label: 'Date of Birth',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'email',
                  label: 'Email',
                  placeholder: PLACEHOLDER,
                  // width: '50%',
                  // mandatory: true,
                },
                {
                  type: 'text',
                  field: 'uniqueId',
                  label: 'Unique Identification No.',
                  placeholder: PLACEHOLDER,
                  // width: '50%',
                  // mandatory: true,
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header,
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'first_name',
                  label: 'First Name',
                  placeholder: PLACEHOLDER,
                  // width: '50%',
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'last_name',
                  label: 'Last Name',
                  placeholder: PLACEHOLDER,
                  // width: '50%',
                  // mandatory: true,
                },
                {
                  type: 'chipsToggle',
                  field: 'gender',
                  label: 'Gender',
                  options: [
                    {value: 'Male', label: 'Male'},
                    {value: 'Female', label: 'Female'},
                    {value: 'Other', label: 'Other'},
                  ],
                },
                {
                  type: 'text',
                  field: 'email',
                  label: 'Email',
                  placeholder: PLACEHOLDER,
                  // width: '50%',
                  // mandatory: true,
                },
                {
                  type: 'text',
                  field: 'uniqueId',
                  label: 'Unique Identification No.',
                  placeholder: PLACEHOLDER,
                  // width: '50%',
                  // mandatory: true,
                },
                {
                  type: 'mobile',
                  field: 'mobile',
                  label: 'Mobile number',
                  placeholder: PLACEHOLDER,
                  // width: '100%',
                  // mandatory: true,
                },
              ],
            },
          ],
        },
      ],
    },
  });
};
