import DosageForm from './DosageForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

const header = {
  title: 'Add Dosage',
  actions: [cancelButton(), save()],
};

export default DosageForm({header});
