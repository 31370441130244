import React from 'react';
import {TouchableOpacity} from '../../../react-core-components';
import './Toast.css';

let height = 60;
const ToastComponent = props => {
  let {
    position = 'bottom',
    onToastPress,
    index,
    toastContainerStyle = {},
    Component,
  } = props;

  if (position && position === 'bottom') {
    toastContainerStyle = {
      ...toastContainerStyle,
      bottom: (index + 1) * height,
    };
  }
  if (position === 'top') {
    toastContainerStyle = {
      ...toastContainerStyle,
      top: (index + 1) * height,
    };
  }
  return (
    <TouchableOpacity
      style={{zIndex: 100, position: 'absolute', ...toastContainerStyle}}
      onLayout={({nativeEvent: {layout} = {}} = {}) => {
        height = (layout.height || 60) + 10;
      }}
      onPress={e => {
        onToastPress && onToastPress(e);
      }}
      className={`${position}-left`}>
      {Component}
    </TouchableOpacity>
  );
};

ToastComponent.defaultProps = {
  toastContainerStyle: {
    marginLeft: 16,
    marginRight: 16,
    width: 'auto',
    marginTop: 5,
    marginBottom: 5,
  },
};
export default ToastComponent;
