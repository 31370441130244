import React from 'react';
import {View, Text} from './index';
import {h18_Medium} from '../theme/fonts';
import {colors} from '../theme/colors';
const {textColor87} = colors;

const textStyle = {...h18_Medium, color: textColor87};

export default class CustomHeaderLabel extends React.Component {
  render() {
    const {preLabel, postLabel, seperator = true} = this.props;
    return (
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          paddingLeft: 16,
        }}>
        <Text style={textStyle}>{preLabel}</Text>
        {seperator ? (
          <View
            style={{
              width: 4,
              height: 4,
              borderRadius: 4,
              backgroundColor: textColor87,
              marginLeft: 8,
              marginRight: 8,
            }}
          />
        ) : (
          void 0
        )}
        <Text style={textStyle}>{postLabel}</Text>
      </View>
    );
  }
}
