import React, { useCallback, useState } from 'react';
import { ActivityIndicator } from '../../../../app-components';
import { BASE_URL } from '../../../../Config';
import { loaderTheme } from '../VillageGraph/chartTools';

const ShowIframe = () => {
  const [loaderForIframe, setLoaderForIframe] = useState(true)
  const setLoader = useCallback(() => {
    setLoaderForIframe(false);
  }, [])
  const isStaging= (BASE_URL !== 'https://svmapi.mayamd.ai' ? "&stagging=true" : "");
  return (
    <div style={{ height: '100%', marginBottom: 10 }}>
      {loaderForIframe ? (
        <ActivityIndicator
          theme={loaderTheme}
          size="medium"
          color={'#0095C9'}
        />
      ) : null}
      <iframe
        src={'https://pphm-analysis.mayamd.ai/mayamd/MayaMDAnalytics/?token=' + localStorage.getItem("token") + isStaging}
        onLoad={setLoader}
        width={'100%'}
        height={'100%'}
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default ShowIframe;
