import React from 'react';
import moment from 'moment';
import {Platform, Snackbar} from './index';

const ObjConstructor = {}.constructor;

export const endTime = () => {
  let date = new Date();
  return moment(date).endOf('day').toDate();
};
export const timeFormator = ({item}) => {
  let {_createdOn} = item || {};
  return moment(_createdOn).format('hh:mm A');
};
export const showDate = date => {
  date = new Date(date);
  return moment(date).format('DD MMM | hh:mm A');
};

export const getDateRange = (rangeStr = '', rangeOptions = {}) => {
  var {date = new Date()} = rangeOptions;
  var from, to;
  switch (rangeStr) {
    case 'Today':
      from = moment(date).startOf('day').toDate();
      to = moment(date).endOf('day').toDate();
      break;

    case 'Yesterday':
      from = moment(date).subtract(1, 'day').startOf('day').toDate();
      to = moment(date).subtract(1, 'day').endOf('day').toDate();
      break;
    case 'This week':
      from = moment(date).startOf('week').toDate();
      to = moment(date).endOf('week').toDate();
      break;

    case 'Last week':
      from = moment(date).subtract(1, 'weeks').startOf('Week').toDate();
      to = moment(date).subtract(1, 'weeks').endOf('Week').toDate();
      break;
  }
  return {from, to};
};
export const setUtcTime = time => {
  time = time.split(':');
  let utc = Number(time[0]) * 60 + Number(time[1]);
  return utc;
};
export const resolveExpFormatter = ({item, column}) => {
  let {field} = column;
  if (item && item[field]) {
    return item[field];
  } else {
    return 'N/A';
  }
};

export const dateFormatter = ({item, format = 'DD MMM YYYY'}) => {
  let {date} = item;
  date = moment(date).format(format);
  return date;
};
export const lastSignalFormatter = ({item}) => {
  let lastSignalTime = item && item.lastSignalTime;
  let date = '-';
  if (lastSignalTime) {
    let text = moment(lastSignalTime).fromNow();
    text = text.split(' ');
    if (text.length === 4) {
      let time = text[0];
      date = `${time}${text[2].substring(0, 1)} ago`;
    } else {
      if (text[1] === 'hours' || text[1] === 'minutes') {
        date = `${text[0]}${text[1].substring(0, 1)} ago`;
      } else {
        date = `${text[0]} ${text[1]} ago`;
      }
    }
  }
  return date;
};

export const format = ({dateValue}) => {
  const {data, field, valueField} = dateValue;
  let value = data && field && data[field];
  let date = value && valueField ? value[valueField] : value;
  let todayDate = new Date().getDate();
  let selectedDate = new Date(date).getDate();
  if (todayDate === selectedDate) {
    date = moment(date).format('h:mm A');
    date = `${date} (current time)`;
  } else {
    date = moment(date).format('YYYY-MM-DD h:mm:ss A');
  }
  return date;
};
export const resolveStatus = ({item}) => {
  let text = 'N/A';
  let {status, start_time, plant_in, plant_out, reached_status} = item;
  if (status === 'New') {
    text = '';
  } else if (status === 'Active' && !plant_out) {
    text = 'In Plant';
  } else {
    text =
      reached_status === 'Delay'
        ? `${reached_status} | ${resolveValue(
            item,
            'delayed_minutes',
            formatHours,
          )}`
        : 'On-Time';
  }

  return text;
};
export const date = date => {
  if (!date) {
    return;
  }
  let value = `${(date && moment(date).format('HH:mm A')) || '-'} | ${
    (date && moment(date).format('DD, MMM')) || '-'
  }`;
  return value;
};
export const formatAddress = fieldValue => {
  let value = void 0;

  if (fieldValue && (fieldValue.address_levels || fieldValue.description)) {
    let {address_levels, description} = fieldValue;
    if (address_levels && address_levels.locality) {
      value = address_levels.locality;
    } else {
      value = description;
    }
  }

  return value;
};

export const formatHours = fieldValue => {
  let day = 0,
    hour = 0;
  if (fieldValue) {
    day = Math.floor(fieldValue / (60 * 24));
    hour = Math.floor((fieldValue / (60 * 24) - day) * 24);
  }

  let value = '';

  if (day) {
    value = `${day}d`;
  }
  if (hour) {
    value = `${value} ${hour}h`;
  }
  return value;
};

export const resolveExp = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    var result = [];
    for (var i = 0; i < values.length; i++) {
      var row = values[i];
      var resolvedValue = resolveExp(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (var j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17*/
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else {
          if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
            result.push(resolvedValue);
          }
        }
      }
    }
    return result;
  } else {
    var value = values[key];
    if (value !== undefined) {
      return value;
    }
    var index = key.indexOf('.');
    if (index === -1) {
      return;
    }
    var firstPart = key.substring(0, index);
    var nextPart = key.substring(index + 1);
    return resolveExp(values[firstPart], nextPart);
  }
};
export const resolveValue = (data, field, display, defaultValue = 'N/A') => {
  let value = data[field];
  if (display && value) {
    if (typeof display === 'function') {
      value = display(value);
    } else {
      value = value[display];
    }
  }
  if (!value) {
    value = defaultValue;
  }
  return value;
};
const getIndianNumberFormat = (value, format, skipRoundOff = true) => {
  let zeroCount = 0;
  var space = format.indexOf(' i') !== -1 ? ' ' : ' ';
  let fragments = format.split('.');

  if (fragments.length === 2) {
    zeroCount = (fragments[1].match(/0/g) || []).length;
  } else if (fragments.length > 2) {
    return 'Invalid Format';
  }

  const crore = 10000000;
  const lakh = 100000;
  const thousand = 1000;

  let abbr;
  let abs = Math.abs(value);

  if (abs >= crore) {
    value = value / crore;
    abbr = 'CR';
  } else if (abs < crore && abs >= lakh) {
    value = value / lakh;
    abbr = 'L';
  } else if (abs < lakh && abs >= thousand) {
    value = value / thousand;
    abbr = 'K';
  } else if (abs < thousand) {
    abbr = '';
  }
  value = value.toFixed(zeroCount);
  if (value % 1 === 0 && skipRoundOff) {
    value = parseInt(value);
  }
  return value + space + abbr;
};

export const numberFormatter = (value, format = '0,0.00', skipRoundOff) => {
  if (value === undefined || value === null || value === '' || !format) {
    return value;
  }
  return getIndianNumberFormat(value, format, skipRoundOff);
};

export const showError = (error, duration, theme) => {
  let message = '';

  try {
    if (error && error.message) {
      let parsed = JSON.parse(error.message);
      if (parsed && parsed.error && parsed.error.message) {
        message = parsed.error.message;
      } else {
        message = (error && error.message) || error || '';
      }
    } else {
      message = (error && error.message) || error || '';
    }
  } catch (e) {
    message = (error && error.message) || error || '';
  }

  Snackbar.show({
    text: `${message}`,
    duration: duration || Snackbar.LENGTH_SHORT,
    theme: theme,
  });
};

export const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

export const detectMob = () => {
  if (
    Platform.OS !== 'web' ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
export const isMobile = detectMob();

export const isJSONObject = (obj) => {
  if (
    obj === undefined ||
    obj === null ||
    obj === true ||
    obj === false ||
    typeof obj !== 'object' ||
    Array.isArray(obj)
  ) {
    return false;
  } else if (
    obj.constructor === ObjConstructor ||
    obj.constructor === undefined
  ) {
    return true;
  } else {
    return false;
  }
};

export const getDateFilter = (date) => {
  if (!date) {
    return null;
  }
  let backDate = new Date(date);
  backDate.setUTCHours(0);
  backDate.setUTCMinutes(0);
  backDate.setUTCSeconds(0);
  backDate.setUTCMilliseconds(0);
  backDate.setUTCDate(backDate.getUTCDate());

  let nxtDate = new Date(date);
  nxtDate.setUTCHours(0);
  nxtDate.setUTCMinutes(0);
  nxtDate.setUTCSeconds(0);
  nxtDate.setUTCMilliseconds(0);

  nxtDate.setUTCDate(nxtDate.getUTCDate() + 1);
  let a = {$gte: backDate, $lt: nxtDate};
  return a;
};

export const sectionShows = ({lecture_students, manual, section}) => {
  let sections = [];
  let sectionText = '';
  if (manual) {
    lecture_students &&
      lecture_students.map((valueItem, index) => {
        let {student: {section: {name: sectionName} = {}} = {}} =
          valueItem || {};
        if (sections.indexOf(sectionName) === -1 && sectionName) {
          sections.push(sectionName);
        }
      });
  } else {
    sectionText = section && section.name;
  }
  if (sections.length) {
    for (let i = 0; i < sections.length; i++) {
      let text1 = sections[i];
      if (sections.length - 1 === i) {
        sectionText = sectionText + text1;
      } else {
        sectionText = sectionText + text1 + ',';
      }
    }
  }
  return sectionText;
};

export const getFileExtension = (filename) => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
};

export const getAge = (dob) => {
  var today = new Date();
  var birthDate = new Date(dob);
  var ageNow = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  var d = today.getDate() - birthDate.getDate();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    ageNow--;
  } else if (ageNow === 0 && m > 0) {
    ageNow = m;
    return ageNow + ' months';
  } else if (m === 0 && ageNow === 0) {
    ageNow = d;
    return ageNow + ' days';
  }
  return ageNow + ' years';
};
export const getRemainingTime = (dt2, dt1) => {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.floor(diff));
};
export const calculateInFormat = (dt2, dt1) => {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff = Math.abs(diff);
  let seconds = diff % 60;
  diff /= 60;

  seconds = Math.abs(Math.floor(seconds));
  seconds = seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  let minutes;
  let hours = diff / 60;
  hours = Math.floor(hours);
  if (hours > 23) {
    return `${hours / 24} Day Left`;
  }
  minutes = diff % 60;
  minutes = Math.floor(minutes);
  minutes = minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  hours = hours.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  if (diff > 60) {
    return hours + ':' + minutes + ':' + seconds + ' Hrs';
  } else {
    return minutes + ':' + seconds + ' Mins';
  }
};

export const getAgeFromDob = (dob) => moment().diff(dob, 'years');