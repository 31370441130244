import React from 'react';
import {
  WithLabel,
  WithInputWrapper,
  WithVariant,
} from '../../react-input-wrapper';
import AutosuggestInput from './AutosuggestInput';
import AutoSuggestBottomInput from './AutoSuggestBottomInput';

class AutosuggestInputField extends React.Component {
  getOptionValue = (item) => {
    if (item === undefined || item === null) {
      return item;
    }
    let {keyField = 'value'} = this.props;
    return (typeof item === 'object' && item[keyField]) || item;
  };

  getOptionDisplay = (item) => {
    if (item === undefined || item === null) {
      return item;
    }
    let {valueField} = this.props;
    return (
      (typeof item === 'object' && valueField && item[valueField]) ||
      this.getOptionValue(item)
    );
  };

  getDisplayValue = (value) => {
    let {valueField, options} = this.props;
    if (typeof options === 'function') {
      options = options(this.props);
    }
    if (value && valueField) {
      if (options && options.length > 0) {
        for (let option of options) {
          if (this.getOptionValue(option) === value) {
            return this.getOptionDisplay(option);
          }
        }
      } else {
        value = value[valueField];
      }
    }
    return value;
  };

  onChangeValue = (value, e) => {
    const {onChangeValue, onChangeText} = this.props;
    onChangeValue && onChangeValue(value, e);
    onChangeText && onChangeText(value);
  };

  onSelect = ({item, e}) => {
    const {onItemSelect, options} = this.props;
    if (options) {
      item = this.getOptionValue(item);
    }
    this.onChangeValue(item, e);
    onItemSelect && onItemSelect({item, props: this.props});
    return this.getDisplayValue(item);
  };
  clearValue = (e) => {
    this.onChangeValue(null, e);
  };
  isHighlighted = ({item}) => {
    const {value, options} = this.props;
    if (value && item) {
      if (options) {
        return this.getOptionValue(item) === value;
      } else {
        return value._id === item._id;
      }
    }
  };

  onRenderNoDataCallback = ({result} = {}) => {
    if (result) {
      this.onChangeValue(result);
    }
  };

  renderNoData = (searchProps) => {
    const {renderNoData} = this.props;
    if (!renderNoData) {
      return;
    }
    return renderNoData({
      ...searchProps,
      onSubmitCallback: this.onRenderNoDataCallback,
    });
  };

  fetch = async (searchProps) => {
    try {
      const {fetch, data} = this.props;
      let result = fetch ? await fetch({...searchProps, data}) : void 0;
      return (result && result.data) || [];
    } catch (err) {
      return [];
    }
  };

  render() {
    const {
      value,
      valueField,
      fetch,
      renderNoData,
      onItemSelect,
      renderButtonText,
      imageComponent,
      modalPosition,
      ...rest
    } = this.props;
    const AutoSuggestComponent =
      modalPosition === 'screenBottom'
        ? AutoSuggestBottomInput
        : AutosuggestInput;
    return (
      <AutoSuggestComponent
        value={this.getDisplayValue(value)}
        keyExtractor={this.keyExtractor}
        onItemSelect={this.onSelect}
        clearValue={this.clearValue}
        isHighlighted={this.isHighlighted}
        fetch={this.fetch}
        renderNoData={this.renderNoData}
        renderButtonText={
          renderButtonText
            ? (props) => renderButtonText({...props, value})
            : void 0
        }
        imageComponent={
          imageComponent ? (props) => imageComponent({...props, value}) : void 0
        }
        {...rest}
      />
    );
  }
}

AutosuggestInputField = WithLabel(
  WithInputWrapper(WithVariant(AutosuggestInputField)),
);

export default AutosuggestInputField;
