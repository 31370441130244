import React from 'react';
import {View,Text,ScrollView,TouchableOpacity,Image} from '../../app-components'
import { h16_Medium } from '../../theme/fonts';
import { closeIcon } from '../../images';
import PatientDetailsCard from '../GenericComponent/PatientDetailsCard'
const AppointmentDetail = (props) => {
  const {navigation}=props
  const {state:{params:{item={}}}={}}=navigation||{}
  return (
    <View style={{backgroundColor: '#f8fafb', flex: 1, overflow: 'hidden'}}>
      <View
        style={{
          backgroundColor: '#f5f7f9',
          flexDirection: 'row',
          alignItems: 'center',
          padding: 14,
          justifyContent: 'space-between',
        }}>
        <Text style={{...h16_Medium}}>Appintment Details</Text>
        <View
          style={{flexDirection: 'row', alignItems: 'center', minHeight: 42}}>
          <TouchableOpacity
            onPress={() => navigation.pop()}
            style={{paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}}>
            <Image source={closeIcon} style={{height: 20, width: 20}} />
          </TouchableOpacity>
        </View>
      </View>
      <ScrollView style={{flex: 1}}>
        <PatientDetailsCard item={item} {...props} />
      </ScrollView>
    </View>
  );
};

export default AppointmentDetail;