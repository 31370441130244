import {save} from '../../../app-components/action/SaveAction';
import {cancelButton} from '../../../app-components/action/CloseAction';
import SearchDeviceForm from './SearchDeviceForm';
let header = {
  title: 'Add Device',
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },
  actions: [cancelButton(), save()],
};
let editable = true;
let iconEditable = true;
export default SearchDeviceForm({
  header,
  editable,
  iconEditable,
});
