import Table from '../../app-components/table/Table';
import React from 'react';
import {Text} from '../../app-components';
import {listUri} from '../../Queries';
import {h14_AvenirNext} from '../../theme/fonts';
import { getUser } from '../../AppServices';

let uri = () => {
  const {organization}=getUser();
  return listUri({model: 'Invites', id: 'inviteLists',filter:{
    organization
  } });
};
const tableData = {
  lg: {
    columns: [
      {
        render: (props) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {props.index + 1}
            </Text>
          );
        },
        header: 'S.No.',
        width: 50,
      },
      {
        type: 'text',
        field: 'name',
        header: 'Name',
        width: 200,
      },
      {
        type: 'text',
        field: 'mobile',
        header: 'Mobile number',
        width: 200,
      },
    ],
  },
  sm: {
    columns: [
      {
        render: (props) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {props.index + 1}
            </Text>
          );
        },
        header: 'S.No.',
        width: 50,
      },
      {
        type: 'text',
        field: 'name',
        header: 'Name',
        // width: 50,
        width: 100,
      },
      {
        type: 'number',
        field: 'mobile',
        header: 'Mobile number',
        width: 150,
      },
      // {
      //   type: 'text',
      //   field: 'link',
      //   header: 'Link Generated',
      //   width: 100,
      // },
    ],
  },
};

export default Table({
  reloadEvent: 'reloadInviteLinks',
  uri,
  ...tableData,
});
