import React from 'react';
import {View} from '../../react-core-components';
import {getResolvedStyle} from './Utility';

export default class Standard extends React.Component {
  render() {
    let {
      className,
      isActive,
      hover,
      disabled,
      error,
      style,
      hoverStyle,
      activeStyle,
      errorStyle,
      disabledStyle,
      labelPosition,
      inputStyle,
      floatingLabelInputStyle,
      Component,
    } = this.props;
    style = getResolvedStyle({
      isActive,
      hover,
      disabled,
      error,
      style,
      hoverStyle,
      errorStyle,
      disabledStyle,
    });
    if (labelPosition === 'floating') {
      inputStyle = floatingLabelInputStyle || inputStyle;
    }
    return (
      <View>
        <View className={className} style={style}>
          <Component {...this.props} inputStyle={inputStyle} />
        </View>
        <View
          style={{
            ...activeStyle,
            transform: isActive && !error ? 'scaleX(1)' : 'scaleX(0)',
            transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          }}
        />
      </View>
    );
  }
}

Standard.defaultProps = {
  style: {
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E1E1E1',
    justifyContent: 'flex-end',
  },
  activeStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderBottomWidth: 2,
    borderColor: '#4276EC',
    borderStyle: 'solid',
  },
  errorStyle: {
    // borderBottomWidth: 2,
    borderColor: '#FF8181',
  },
  inputStyle: {
    outline: 'none',
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    paddingTop: 17,
    paddingBottom: 7,
    fontSize: 14,
    color: '#2C2C2C',
  },
  inputErrorStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    alignItems: 'flex-end',
  },
  inputSelectorContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    justifyContent: 'flex-end',
  },
};
