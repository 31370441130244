import {putDottedValue} from '../../npms/react-utility-functions';
import {resolveExp} from '../UtilityFunctions';

const initialPageLimit = 40;
const fetchMoreLimit = 20;
export const beforeFetch = ({state, uri, fetchProps, props}) => {
  let {query, viewFilters: applicableViewFilters} = uri;
  const {dataParams} = props;
  let viewFilters = void 0;
  let viewParams = void 0;

  let sortParams = void 0;
  let ftsFilter = void 0;
  let queryParams = void 0;
  if (dataParams && dataParams.filters) {
    let dataParamsFilters = dataParams.filters;

    for (let field in dataParamsFilters) {
      let fieldValue = dataParamsFilters[field];

      if (fieldValue && fieldValue.filter) {
        let filter = dataParamsFilters[field]['filter'];
        let { asParams, value, filterType, filterField, fieldType } = filter;

        if (applicableViewFilters && applicableViewFilters.indexOf(field) < 0) {
          continue;
        }
        if (value) {
          if (filterField) {
            value = resolveExp(value, filterField);
            filterType = fieldType;
          }
          if (asParams) {
            viewParams = viewParams || {};
            viewParams[field] = value;
          } else {
            viewFilters = viewFilters || {};
            if (filterType === 'autosuggest' && field.indexOf('.') !== -1) {
              putDottedValue(viewFilters, field, value);
            } else if (filterType === 'autosuggest' && field === '_id') {
              viewFilters[field] = { $in: [value._id] };
            } else if (value && filterType === 'nameAutoSuggest') {
              viewFilters[field] = { name: value.name || value };
            } else {
              putDottedValue(viewFilters, field, value);
            }
          }
        }
      }
    }
  }
  if (dataParams && dataParams.sort) {
    sortParams = {sort: {...dataParams.sort}};
  }

  if (dataParams && dataParams.ftsFilter) {
    let {field, paramValue, regExpFilter, filter} = dataParams.ftsFilter;
    if (filter) {
      ftsFilter = filter;
    } else if (regExpFilter) {
      if (!viewFilters) {
        viewFilters = regExpFilter;
      } else {
        viewFilters = {...viewFilters, ...regExpFilter};
      }
    } else if (paramValue) {
      viewParams = viewParams || {};
      viewParams[field] = paramValue;
    }
  }

  if (dataParams && dataParams.dataFilter) {
    if (dataParams.dataFilter.filter) {
      queryParams = dataParams.dataFilter.filter;
    } else if (dataParams.dataFilter.regExpFilter) {
      if (!viewFilters) {
        viewFilters = dataParams.dataFilter.regExpFilter;
      } else {
        viewFilters = {...viewFilters, ...dataParams.dataFilter.regExpFilter};
      }
    }
  }

  let {limit, skip, noLimit, addOnFilter, paramValue} = query;
  let {fetchMoreProps: fetchMoreState = {}, loading} = state;

  let newState = {};

  if (fetchProps && fetchProps.fetchMore) {
    let {skip: fetchMoreSkip, limit: fetchMoreLimit} = fetchMoreState;
    limit = fetchMoreLimit || fetchMoreLimit;
    skip = fetchMoreSkip;
    newState.fetchingMore = true;
  } else {
    if (limit === void 0) {
      if (!noLimit) {
        limit = initialPageLimit;
      }
    }
  }
  skip = skip || 0;
  limit = limit || initialPageLimit;
  if (viewFilters) {
    addOnFilter = {...addOnFilter, ...viewFilters};
  }
  if (viewParams) {
    paramValue = {...paramValue, ...viewParams};
  }

  query = {...query, addOnFilter, paramValue, limit, skip};

  if (sortParams) {
    query = {...query, ...sortParams};
  }
  if (ftsFilter) {
    query = {...query, ftsFilter};
  }
  if (queryParams) {
    query = {...query, paramValue: queryParams};
  }
  uri = {...uri, query};
  newState.loading = true;
  return {uri, state: newState};
};

const getResolvedFilterValue = (filter) => {
  let {value, filterType, filterField, fieldType, asParam} = filter;
  if (
    value === undefined ||
    value === null ||
    (Array.isArray(value) && !value.length)
  ) {
    return;
  }
  if (filterField) {
    value = resolveExp(value, filterField);
    filterType = fieldType;
  }
  let resolvedFilterValue = void 0;
  if (filterType === 'autoSuggest') {
    if (Array.isArray(value)) {
      resolvedFilterValue = {_id: {$in: value.map((row) => row._id)}};
    } else if (value._id) {
      resolvedFilterValue = {_id: value._id};
    }
  } else if (filterType === 'googlePlace') {
    let address_levels = value && value.address_levels;
    if (address_levels) {
      resolvedFilterValue = {address_levels};
    }
  } else if (filterType === 'boolean') {
    const getBooleanValue = (_value) => {
      return typeof _value === 'string' ? JSON.parse(_value) : _value;
    };
    if (Array.isArray(value)) {
      resolvedFilterValue = {
        $in: value.map((_value) => getBooleanValue(_value)),
      };
    } else {
      resolvedFilterValue = getBooleanValue(value);
    }
  } else if ((filterType === 'date' || filterType === 'number') && !asParam) {
    if (value.from || value.to) {
      resolvedFilterValue = {};
      if (value.from) {
        resolvedFilterValue.$gte = value.from;
      }
      if (value.to) {
        resolvedFilterValue.$lte = value.to;
      }
    }
  } else {
    if (Array.isArray(value)) {
      resolvedFilterValue = {$in: value};
    } else {
      resolvedFilterValue = value;
    }
  }
  return resolvedFilterValue;
};

export const getResolvedFilters = (appliedFilters, isGlobal) => {
  let resolvedAppliedFilters = {};
  let paramValue = {};
  let globalParamValue = {};
  for (var field in appliedFilters) {
    let fieldValue = appliedFilters[field];
    if (!fieldValue || !fieldValue.filter) {
      continue;
    }
    let filterInfo = fieldValue.filter;
    let {asParam, asGlobalParam = isGlobal, ...restFilterInfo} = filterInfo;
    let resolvedFilterValue = getResolvedFilterValue({
      ...restFilterInfo,
      asParam: asGlobalParam || asParam,
    });
    if (resolvedFilterValue !== undefined) {
      if (asParam || asGlobalParam) {
        if (asParam) {
          paramValue[field] = resolvedFilterValue;
        }
        if (asGlobalParam) {
          globalParamValue[field] = resolvedFilterValue;
        }
      } else {
        putDottedValue(resolvedAppliedFilters, field, resolvedFilterValue);
      }
    }
  }
  return {
    filters: resolvedAppliedFilters,
    paramValue,
    globalParamValue,
  };
};

export const getResolvedParamValue = (dataParams, isGlobal) => {
  if (!dataParams || !dataParams.filters) {
    return;
  }
  let {paramValue, globalParamValue} = getResolvedFilters(
    dataParams.filters,
    isGlobal,
  );
  return isGlobal ? globalParamValue : paramValue;
};

export const afterFetch = ({state, uri, fetchProps, result}) => {
  let {query} = uri;
  let {fetchMoreProps: fetchMoreState = {}} = state;

  let {
    data: newData,
    aggregates: newAggregates,
    _count,
    subscribeInfo,
  } = result;
  let {data = []} = state;
  let newState = {};
  let {limit, skipFetchMore, noLimit} = query;
  const newSize = newData.length;

  let hasNext = true;
  if (noLimit || skipFetchMore) {
    hasNext = false;
  } else if (newSize < limit) {
    hasNext = false;
  }

  fetchMoreState = {...fetchMoreState};
  fetchMoreState.hasNext = hasNext;

  if (!skipFetchMore) {
    if (fetchProps && fetchProps.fetchMore) {
      fetchMoreState.skip = (fetchMoreState.skip || 0) + newSize;
    } else {
      fetchMoreState.skip = newSize;
    }
    fetchMoreState.limit = fetchMoreLimit;
  }

  if (fetchProps && fetchProps.fetchMore) {
    data = [...data, ...newData];
    //if loading more then we do not need to override aggregates but if coming in result then should override
    if (newAggregates) {
      //aggregates should be override in case of result reload
      newState.aggregates = newAggregates;
      newState._count = _count;
    }
  } else {
    data = newData;
    newState.aggregates = newAggregates;
    newState._count = _count;
  }
  newState.data = data;
  newState.fetchMoreProps = fetchMoreState;
  newState.loading = false;
  newState.fetchingMore = false;
  newState.loaded = true;
  if (subscribeInfo) {
    newState.subscribeInfo = subscribeInfo;
  }

  return {state: newState};
};

export const beforeFetchForm = ({state, uri}) => {
  let {query} = uri;
  query = {...query, limit: query && query.limit ? query.limit : 1, skip: 0};

  uri = {...uri, query};
  return {uri, state: {loading: true}};
};

export const afterFetchForm = ({state, uri, result}) => {
  let {data: newData} = result;
  let newState = {};
  if (Array.isArray(newData) && newData.length > 0) {
    if (newData.length > 0) {
      newData = newData[0];
    } else {
      newData = {};
    }
  } else if (!newData) {
    newData = {};
  }
  newState.data = newData;
  newState.loading = false;
  newState.loaded = true;

  return {state: newState};
};
