import React, {useState} from 'react';
import {backgroundImage1} from '../../images';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
} from '../../app-components';
import {TextInput} from '../../app-components/input-components/Editors';
import {navigationBarStyle} from '../../theme/navigationBarTheme';
import {
  h14_AvenirNext,
  h14_Regular,
  h14_AvenirNext_Bold,
  h20_AvenirNext_500,
  h12_Regular,
} from '../../theme/fonts';
import {HospitalLOGO} from '../../images';
import {dropDownShadow} from '../../theme/shadows';
import {checkUserExist, invoke} from '../../AppServices';
import {ActivityIndicator} from '../../app-components';
import InputMask from 'react-input-mask';
import {filledInputTheme} from '../../theme/inputTheme';
import MobileInput from '../../app-components/input-components/MobileInput';
import {colors} from '../../theme/colors';
const {themeColor}=colors
const PatientSignIn = (props) => {
  const {navigation} = props;
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [hoverSignIn, setHoverSignIn] = useState(true);
  const [hoverSignUp, setHoverSignUp] = useState(false);
  const [error, setError] = useState('');

  const sendOTP = async () => {
    const isMobileLengthError = mobileNumber.length ? true : false;
    if (!isMobileLengthError) {
      setError('Mobile number is required');
    } else {
      setLoading(true);
      checkUserExist(mobileNumber,"Patient")
        .then((res) => {
          navigation.replace('patient-otp', {mobile: mobileNumber});
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setError('Mobile not registered');
        });
    }
  };

  return (
    <ScrollView
      style={{
        backgroundImage: `url(${backgroundImage1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flex: 1,
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 30,
          paddingRight: 30,
          height: 79,
        }}>
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            navigation.reset('front-page');
          }}>
          <Image source={HospitalLOGO} style={{height:70,width:200,objectFit:"contain"}} />
        </TouchableOpacity>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            // flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <TouchableOpacity
            style={{
              paddingRight: 50,
              cursor: 'pointer',
              backgroundColor: !hoverSignIn ? '#fff' : themeColor,
              borderRadius: 16,
              paddingLeft: 16,
              paddingTop: 6,
              paddingRight: 15,
              paddingBottom: 7,
              marginRight: 16,
            }}
            onPress={() => {
              // navigation.reset('patient-signIn');
            }}
            onMouseEnter={() => setHoverSignIn(true)}
            onMouseLeave={() => setHoverSignIn(true)}>
            <Text
              style={{
                ...h14_AvenirNext_Bold,
                color: hoverSignIn ? '#fff' : 'rgba(56, 56, 56, 0.7)',
                // opacity: 0.7,
              }}>
              Sign In
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              paddingRight: 50,
              cursor: 'pointer',
              backgroundColor: !hoverSignUp ? '#fff' : themeColor,
              borderRadius: 16,
              paddingLeft: 16,
              paddingTop: 6,
              paddingRight: 15,
              paddingBottom: 7,
            }}
            onPress={() => {
              navigation.replace('patient-signUp');
            }}
            onMouseEnter={() => setHoverSignUp(true)}
            onMouseLeave={() => setHoverSignUp(false)}>
            <Text
              style={{
                ...h14_AvenirNext_Bold,
                color: hoverSignUp ? '#fff' : 'rgba(56, 56, 56, 0.7)',
                // opacity: 0.7,
              }}>
              Sign Up
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <View
          style={{
            ...dropDownShadow,
            backgroundColor: 'white',
            padding: 40,
            borderRadius: 15,
            width: '40%',
          }}>
          <View>
            <Text
              style={{
                ...h20_AvenirNext_500,
                color: '#2e6ecd',
                fontWeight: '700',
              }}>
              Enter Mobile Number
            </Text>
          </View>
          <View style={{marginTop: 10, marginBottom: 16}}>
            <MobileInput
              value={mobileNumber}
              fieldName="name"
              error={error}
              onChangeText={setMobileNumber}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  !loading && sendOTP && sendOTP();
                }
              }}
            />

          </View>

          <View>
            <Text
              style={{
                ...h14_Regular,
                letterHeight: 21,
                letterSpacing: 0.5,
                color: '#000',
                opacity: 0.54,
              }}>
              You will receive OTP on this number
            </Text>
            <Text
              style={{
                ...h14_Regular,
                letterHeight: 21,
                letterSpacing: 0.5,
                color: '#000',
                opacity: 0.54,
              }}>
              We will send appointment related communications on this number
            </Text>
          </View>

          <View style={{paddingRight: 50, paddingLeft: 50, paddingTop: 65}}>
            <TouchableOpacity
              style={{
                backgroundColor: themeColor,
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: 10,
                height: 40,
                borderRadius: 8,
                cursor: 'pointer',
              }}
              onPress={() => {
                !loading && sendOTP();
              }}>
              {loading ? (
                <ActivityIndicator size="small" color={"#fff"} />
              ) : (
                <Text style={{...h14_AvenirNext, color: '#fff'}}>Send OTP</Text>
              )}
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default PatientSignIn;
