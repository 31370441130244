import React from 'react';
import { addIcon } from '../../../images';
import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  BasicTextInput,
  Platform,
} from '../../react-core-components';
import {
  WithLabel,
  WithInputWrapper,
  WithVariant,
  getInputProps,
} from '../../react-input-wrapper';

class MultiTextInputField extends React.Component {
  state = {};
  onSelect = (e) => {
    let item = Platform.OS === 'web' ? e.target.value : e.nativeEvent.text;
    if (!item) {
      item = this.state.text;
    }
    let {value, onSubmitEditing, onChangeValue} = this.props;
    let newValue = value ? [...value] : [];
    newValue.push(item);
    onChangeValue && onChangeValue(newValue, e);
    onSubmitEditing && onSubmitEditing(e);
    this.setState({text: void 0});
  };
  onChangeText = (text) => {
    this.setState({text});
  };
  render() {
    let {
      onChange,
      onChangeValue,
      onChangeText,
      value,
      renderSelector,
      ...restInputProps
    } = getInputProps(this.props);
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <BasicTextInput
            {...restInputProps}
            value={this.state.text || ''}
            onChangeText={this.onChangeText}
            onSubmitEditing={this.onSelect}
          />
        </View>
        {renderSelector && this.state.text ? (
          <TouchableOpacity
            onPress={this.onSelect}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}>
            <Image
              source={renderSelector||addIcon}
              style={{height: 20, width: 20, marginRight: 8}}
            />
          </TouchableOpacity>
        ) : (
          void 0
        )}
      </View>
    );
  }
}

MultiTextInputField = WithLabel(
  WithInputWrapper(WithVariant(MultiTextInputField)),
);

class MultiTextInput extends React.Component {
  removeItem = ({item, index, e}) => {
    let {value, onChangeValue} = this.props;
    if (!item || !value || !value.length) {
      return;
    }
    let newValue = value.filter((doc, _index) => {
      return index !== _index;
    });
    onChangeValue && onChangeValue(newValue, e);
  };

  renderChips = ({chipsStyle, roundedChipsStyle, chipsVariant}) => {
    let {value, removeChipIcon, maxHeight, textColor} = this.props;
    if (!value || !value.length) {
      return;
    }
    if (chipsVariant === 'rounded') {
      chipsStyle = roundedChipsStyle;
    }

    let {
      containerStyle,
      itemContainerStyle,
      itemTextStyle,
      crossContainerStyle,
      crossIconStyle,
      crossTextStyle,
    } = chipsStyle;
    containerStyle = {...containerStyle};

    if (textColor) {
      itemTextStyle = {
        ...itemTextStyle,
        color: textColor,
      };
    }
    if (maxHeight) {
      containerStyle.maxHeight = maxHeight;
    }
    return (
      <ScrollView style={containerStyle}>
        {Array.isArray(value) && value.map((item, index) => {
          if (!item) {
            return null;
          }
          return (
            <View
              key={index}
              style={{flexDirection: 'row', ...itemContainerStyle}}>
              <View style={{flex: 1}}>
                <Text style={itemTextStyle}>{item}</Text>
              </View>
              <TouchableOpacity
                style={{cursor: 'pointer', ...crossContainerStyle}}
                onPress={(e) => this.removeItem({item, index, e})}>
                {removeChipIcon ? (
                  <Image style={crossIconStyle} source={removeChipIcon} />
                ) : (
                  <Text style={crossTextStyle}>X</Text>
                )}
              </TouchableOpacity>
            </View>
          );
        })}
      </ScrollView>
    );
  };
  render() {
    let {
      chipsVariant,
      chipsPosition = 'bottom',
      chipsStyle,
      renderChips,
      roundedChipsStyle,
      disableTextInput,
      ...restProps
    } = this.props;
    let {value} = this.props;
    if (!renderChips) {
      renderChips = this.renderChips;
    }
    let chipsComponent = this.renderChips({
      value,
      chipsStyle,
      onRemove: this.removeItem,
      roundedChipsStyle,
      chipsVariant,
    });
    return (
      <>
        {chipsPosition === 'top' ? chipsComponent : void 0}
        {disableTextInput ? void 0 : <MultiTextInputField {...restProps} />}
        {chipsPosition === 'bottom' ? chipsComponent : void 0}
      </>
    );
  }
}

export default MultiTextInput;
