import React from 'react';
import PropTypes from 'prop-types';
import FormGroupHeader from './FormGroupHeader';
import {View} from '../../react-core-components';

class FormGroup extends React.Component {
  constructor(props) {
    super(props);
    let {expanded = false} = props;
    this.state = {expanded};
  }
  populateRows = ({rows, columns = [], columnsPerRow = 1}) => {
    let {
      rowContainerStyle,
      columnContainerStyle,
      renderColumn,
      columnGap,
      rowGap,
    } = this.props;

    let maxIterationCount = Math.ceil(columns.length / columnsPerRow);
    let lastColumnIndex = -1;
    for (let i = 0; i < maxIterationCount; i++) {
      let row = [];
      let componentCount = 0;
      for (let j = lastColumnIndex + 1; j < columns.length; j++) {
        lastColumnIndex = j;
        let column = columns[j];
        if (!column) {
          continue;
        }
        let {expand, ...restColumn} = column;
        if (expand) {
          rowContainerStyle = {
            ...rowContainerStyle,
            flex: 1,
          };
          columnContainerStyle = {
            ...columnContainerStyle,
            flex: 1,
          };
        }
        column = restColumn;
        let columnComponent = void 0;
        let columnStyle = void 0;
        if (!renderColumn) {
          if (React.isValidElement(column)) {
            columnComponent = column;
            columnStyle = {flex: 1, overflow: 'hidden'};
          } else {
            continue;
          }
        } else {
          let renderColumnInfo = renderColumn(column) || {};
          if (renderColumnInfo) {
            columnStyle = renderColumnInfo.style;
            columnComponent = renderColumnInfo.component;
          }
        }
        if (columnComponent) {
          if (!columnStyle || columnStyle.width) {
            columnComponent = (
              <View
                style={{
                  ...columnContainerStyle,
                  ...columnStyle,
                }}>
                {columnComponent}
              </View>
            );
          } else {
            columnComponent = (
              <View style={columnStyle}>
                <View style={columnContainerStyle}>{columnComponent}</View>
              </View>
            );
          }

          if (row.length && columnGap) {
            row.push(<View style={{width: columnGap}} />);
          }
          row.push(columnComponent);
          componentCount += 1;
          if (componentCount === columnsPerRow) {
            break;
          }
        }
      }
      if (componentCount) {
        if (componentCount < columnsPerRow) {
          for (let i = componentCount; i < columnsPerRow; i++) {
            //so that last column do not spread all over row
            if (columnGap) {
              row.push(<View style={{width: columnGap}} />);
            }
            row.push(
              <View
                style={{
                  flex: 1,
                }}
              />,
            );
          }
        }

        if (rows.length && rowGap) {
          rows.push(<View style={{height: rowGap}} />);
        }
        let Row = (
          <View
            style={{
              flexDirection: 'row',
              ...rowContainerStyle,
            }}>
            {row}
          </View>
        );
        rows.push(Row);
      }
    }
  };

  getFormGroup = (props) => {
    const {
      columns = [],
      direction,
      groups,
      columnsPerRow = 1,
      children,
    } = props;
    let rows = [];
    if (children) {
      React.Children.forEach(children, (child) => {
        columns.push(child);
      });
    }
    if (groups && groups.length) {
      let rowGroupRows = [];
      for (let index = 0; index < groups.length; index++) {
        let group = groups[index];
        let groupRows = this.getFormGroup({
          ...group,
          columnsPerRow: group.columnsPerRow || columnsPerRow,
        });
        if (direction === 'row') {
          let {width, maxWidth, style} = group;
          if (groupRows.length) {
            let groupStyle = style || {flex: 1, overflow: 'hidden'};
            if (width) {
              groupStyle = {width, ...style};
            } else if (maxWidth) {
              groupStyle.maxWidth = maxWidth;
            }
            rowGroupRows.push(<View style={groupStyle}>{groupRows}</View>);
          }
        } else {
          rows.push.apply(rows, groupRows);
        }
      }
      if (rowGroupRows.length) {
        rows.push(<View style={{flexDirection: 'row'}}>{rowGroupRows}</View>);
      }
    } else if (columns && columns.length) {
      this.populateRows({
        columns,
        rows,
        columnsPerRow,
      });
    }
    return rows;
  };

  closeExpanded = () => {
    this.setState({expanded: false});
  };

  onExpandClick = () => {
    let {onFormGroupExpand} = this.props;
    this.setState({expanded: !this.state.expanded});
    if (!this.state.expanded) {
      onFormGroupExpand && onFormGroupExpand(this.closeExpanded);
    }
  };

  expandComponent = () => {
    let {expandable, expandComponent} = this.props || {};
    if (expandable && expandComponent) {
      let expandComponentProps = {
        onClick: this.onExpandClick,
        expanded: this.state.expanded,
      };
      if (React.isValidElement(expandComponent)) {
        return React.cloneElement(expandComponent, expandComponentProps);
      } else if (typeof expandComponent === 'function') {
        let ExpandComponent = expandComponent;
        if (
          ExpandComponent.prototype &&
          ExpandComponent.prototype.isReactComponent
        ) {
          return <ExpandComponent {...expandComponentProps} />;
        } else {
          return ExpandComponent(expandComponentProps);
        }
      }
    }
  };

  renderHeader = () => {
    let {label, headerType, icon, actions, renderHeader} = this.props;
    let expandComponent = this.expandComponent();
    if (!icon && !label && !actions && !expandComponent) {
      return;
    }
    let headerProps = {
      type: headerType,
      icon,
      label,
      actions,
      expandComponent,
    };
    renderHeader = renderHeader || FormGroupHeader;

    if (React.isValidElement(renderHeader)) {
      return React.cloneElement(renderHeader, headerProps);
    } else if (typeof renderHeader === 'function') {
      let HeaderComponent = renderHeader;
      if (
        HeaderComponent.prototype &&
        HeaderComponent.prototype.isReactComponent
      ) {
        return <HeaderComponent {...headerProps} />;
      } else {
        return renderHeader(headerProps);
      }
    }
  };

  render() {
    let {
      expandable,
      separator,
      style,
      groupsContainerStyle,
      separatorStyle,
    } = this.props;
    let groupRows = this.getFormGroup(this.props);
    if (!groupRows || !groupRows.length) {
      return null;
    }
    return (
      <>
        <View style={style}>
          {this.renderHeader()}
          {!expandable || this.state.expanded ? (
            <View style={groupsContainerStyle}>{groupRows}</View>
          ) : (
            void 0
          )}
        </View>
        {separator ? <View style={separatorStyle} /> : null}
      </>
    );
  }
}

FormGroup.defaultProps = {
  style: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  separatorStyle: {
    marginLeft: 24,
    marginRight: 24,
    borderBottomWidth: 0.3,
    borderColor: 'grey',
    opacity: 0.2,
  },
  columnContainerStyle: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
    paddingBottom: 12,
  },
};

FormGroup.propTypes = {
  label: PropTypes.string, // used for header label
  icon: PropTypes.string, // used for header icon
  actions: PropTypes.array, // used for header actions
  renderHeader: PropTypes.func, // used to show header
  separator: PropTypes.bool,
  columnsPerRow: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  direction: PropTypes.string,
  columnGap: PropTypes.number,
  rowGap: PropTypes.number,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool, // default false, work only when expandable true
  expandComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]), // will be shown only when expandable true
  renderColumn: PropTypes.func,
  style: PropTypes.object,
  groupsContainerStyle: PropTypes.object,
  separatorStyle: PropTypes.object,
  rowContainerStyle: PropTypes.object,
  columnContainerStyle: PropTypes.object,
  children: PropTypes.element,
};

export default FormGroup;
