import React from 'react';
import {View} from '../../react-core-components';
import {WithHoverActions, WithAction} from '../../react-card';

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

class ColumnWithAction extends React.Component {
  render() {
    let {renderColumn, ...restProps} = this.props;
    return getRenderComponent(renderColumn, restProps);
  }
}

ColumnWithAction = WithAction(ColumnWithAction);

class TableRow extends React.PureComponent {
  render() {
    let {
      columns,
      rowContainerStyle,
      rowSelectedContainerStyle,
      rowHoverContainerStyle,
      rowColumnContainerStyle,
      ...restProps
    } = this.props;
    if (!columns || !columns.length) {
      return null;
    }
    let {isHover, selected} = restProps;
    let rowContainerExtraStyle = selected
      ? rowSelectedContainerStyle
      : isHover
      ? rowHoverContainerStyle
      : void 0;

    let component = (
      <View
        style={{
          flexDirection: 'row',
          overflow: 'hidden',
          ...rowContainerStyle,
          ...rowContainerExtraStyle,
        }}>
        {columns.map(({column, widthStyle}) => {
          if (!column) {
            return null;
          }
          let {action, actions, ...restColumn} = column;
          return (
            <View
              style={{
                ...rowColumnContainerStyle,
                ...widthStyle,
              }}>
              <ColumnWithAction
                {...restProps}
                action={action}
                actions={actions}
                column={restColumn}
              />
            </View>
          );
        })}
      </View>
    );
    return component;
  }
}

export default WithHoverActions(WithAction(TableRow));
