import moment from 'moment';
import {currentMonth} from '../../../../Utility/getLastYears/getLastYearsAndMonth';

export const getAllDataTillDate = (barsData, totalPopulationData) => {
  let countDataTillDate = {};
  if (barsData != undefined) {
    for (const [key, value] of Object.entries(barsData)) {
      countDataTillDate[key] = (barsData[key].reduce((a, b) => a + b, 0))
    }
  }
  let percentageDataTillDate = {
    registration: [((countDataTillDate?.femaleData / totalPopulationData?.totalFemale) * 100).toFixed(2),
    ((countDataTillDate?.maleData / totalPopulationData?.totalMale) * 100).toFixed(2),
    ((countDataTillDate?.totalData / totalPopulationData?.totalIndividual) * 100).toFixed(2)],
    screening: [((countDataTillDate?.totalFemaleScreeningData / totalPopulationData?.totalFemale) * 100).toFixed(2),
    ((countDataTillDate?.totalMaleScreeningData / totalPopulationData?.totalMale) * 100).toFixed(2),
    ((countDataTillDate?.totalMemberScreeningData / totalPopulationData?.totalIndividual) * 100).toFixed(2)],
    labResult: [((countDataTillDate?.totalFemaleMemberLabResult / totalPopulationData?.totalFemale) * 100).toFixed(2),
    ((countDataTillDate?.totalMaleMemberLabResult / totalPopulationData?.totalMale) * 100).toFixed(2),
    ((countDataTillDate?.totalMemberLabResult / totalPopulationData?.totalIndividual) * 100).toFixed(2)]
  }
  return { countDataTillDate, percentageDataTillDate };
};

export const getBarData = (selectedBarGraphData, barChartGlobalInfo) => {
  let barsData = {
    familyData: new Array(12).fill(0),
    totalData: new Array(12).fill(0),
    maleData: new Array(12).fill(0),
    femaleData: new Array(12).fill(0),

    totalMemberScreeningData: new Array(12).fill(0),
    totalMaleScreeningData: new Array(12).fill(0),
    totalFemaleScreeningData: new Array(12).fill(0),

    totalMemberLabResult: new Array(12).fill(0),
    totalFemaleMemberLabResult: new Array(12).fill(0),
    totalMaleMemberLabResult: new Array(12).fill(0),

    urgentMemberData: new Array(12).fill(0),
    riskMemberData: new Array(12).fill(0),
    normalMemberData: new Array(12).fill(0),

    urgentMemberScreeningData: new Array(12).fill(0),
    riskMemberScreeningData: new Array(12).fill(0),
    normalMemberScreeningData: new Array(12).fill(0),

    urgentMemberLabData: new Array(12).fill(0),
    riskMemberLabData: new Array(12).fill(0),
    normalMemberLabData: new Array(12).fill(0),
    pendingMemberLabData: new Array(12).fill(0),

    yearlyUrgentMemberData: new Array(3).fill(0),
    yearlyRiskMemberData: new Array(3).fill(0),
    yearlyNormalMemberData: new Array(3).fill(0),
    yearlyPendingMemberData: new Array(3).fill(0),
  };

  selectedBarGraphData.forEach((month) => {
    let monthNum =
      barChartGlobalInfo.selectedYear == 'Last 12 months'
        ? (moment().month(month?.month).format('M') - 1 + (11 - currentMonth)) %
          12
        : moment().month(month?.month).format('M') - 1;

    barsData.totalData[monthNum] =
      barsData.totalData[monthNum] + month?.totalMember ||
      barsData.totalData[monthNum];
    barsData.maleData[monthNum] =
      barsData.maleData[monthNum] + month?.totalMale ||
      barsData.maleData[monthNum];
    barsData.femaleData[monthNum] =
      barsData.femaleData[monthNum] + month?.totalFemale ||
      barsData.femaleData[monthNum];

    barsData.totalMemberScreeningData[monthNum] =
      barsData.totalMemberScreeningData[monthNum] + month?.totalScreening ||
      barsData.totalMemberScreeningData[monthNum];
    barsData.totalMaleScreeningData[monthNum] =
      barsData.totalMaleScreeningData[monthNum] + month?.totalScreeningMale ||
      barsData.totalMaleScreeningData[monthNum];
    barsData.totalFemaleScreeningData[monthNum] =
      barsData.totalFemaleScreeningData[monthNum] +
        month?.totalScreeningFemale ||
      barsData.totalFemaleScreeningData[monthNum];

    barsData.urgentMemberData[monthNum] =
      barsData.urgentMemberData[monthNum] + month?.totalUrgent ||
      barsData.urgentMemberData[monthNum];
    barsData.riskMemberData[monthNum] =
      barsData.riskMemberData[monthNum] + month?.totalRisk ||
      barsData.riskMemberData[monthNum];
    barsData.normalMemberData[monthNum] =
      barsData.normalMemberData[monthNum] + month?.totalNormal ||
      barsData.normalMemberData[monthNum];

    barsData.urgentMemberScreeningData[monthNum] =
      barsData.urgentMemberScreeningData[monthNum] +
        month?.totalScreeningUrgent ||
      barsData.urgentMemberScreeningData[monthNum];

    barsData.riskMemberScreeningData[monthNum] =
      barsData.riskMemberScreeningData[monthNum] + month?.totalScreeningRisk ||
      barsData.riskMemberScreeningData[monthNum];

    barsData.normalMemberScreeningData[monthNum] =
      barsData.normalMemberScreeningData[monthNum] +
        month?.totalScreeningNormal ||
      barsData.normalMemberScreeningData[monthNum];

    barsData.urgentMemberLabData[monthNum] =
      barsData.urgentMemberLabData[monthNum] + month?.totalLabUrgent ||
      barsData.urgentMemberLabData[monthNum];

    barsData.riskMemberLabData[monthNum] =
      barsData.riskMemberLabData[monthNum] + month?.totalLabRisk ||
      barsData.riskMemberLabData[monthNum];

    barsData.normalMemberLabData[monthNum] =
      barsData.normalMemberLabData[monthNum] + month?.totalLabNormal ||
      barsData.normalMemberLabData[monthNum];

    barsData.familyData[monthNum] =
      barsData.familyData[monthNum] + month?.totalFamily ||
      barsData.familyData[monthNum];

    barsData.totalMemberLabResult[monthNum] =
      barsData.totalMemberLabResult[monthNum] + month?.totalLabResult ||
      barsData.totalMemberLabResult[monthNum];

    barsData.totalMaleMemberLabResult[monthNum] =
      barsData.totalMaleMemberLabResult[monthNum] +
        month?.totalMaleReceivedLabResult ||
      barsData.totalMaleMemberLabResult[monthNum];

    barsData.totalFemaleMemberLabResult[monthNum] =
      barsData.totalFemaleMemberLabResult[monthNum] +
        month?.totalFemaleReceivedLabResult ||
      barsData.totalFemaleMemberLabResult[monthNum];

    barsData.pendingMemberLabData[monthNum] = 
    (barsData.urgentMemberScreeningData[monthNum]
      +barsData.riskMemberScreeningData[monthNum]
      +barsData.normalMemberScreeningData[monthNum])
    -(barsData.urgentMemberData[monthNum]
      + barsData.riskMemberData[monthNum]
      +barsData.normalMemberData[monthNum])
     

  });

for (let i = 0; i < 12; i++) {
  barsData.yearlyUrgentMemberData[0] += barsData.urgentMemberData[i];
  barsData.yearlyRiskMemberData[0] += barsData.riskMemberData[i];
  barsData.yearlyNormalMemberData[0] += barsData.normalMemberData[i];
  barsData.yearlyPendingMemberData[0] += barsData.pendingMemberLabData[i];

  barsData.yearlyUrgentMemberData[1] += barsData.urgentMemberScreeningData[i];
  barsData.yearlyRiskMemberData[1] += barsData.riskMemberScreeningData[i];
  barsData.yearlyNormalMemberData[1] += barsData.normalMemberScreeningData[i];

  barsData.yearlyUrgentMemberData[2] += barsData.urgentMemberLabData[i];
  barsData.yearlyRiskMemberData[2] += barsData.riskMemberLabData[i];
  barsData.yearlyNormalMemberData[2] += barsData.normalMemberLabData[i];
  barsData.yearlyPendingMemberData[2] += barsData.pendingMemberLabData[i];
}
return barsData;
};

export const getFamilyPercentageSeries = (allDataTillDate, totalPopulationData) => {
  let unregisteredFamilies = (totalPopulationData?.totalFamily && totalPopulationData?.totalFamily > 0)
    ? totalPopulationData?.totalFamily - allDataTillDate.countDataTillDate.familyData : 0
  const data = [
    {
      name: "Registered",
      data: [allDataTillDate.countDataTillDate.familyData]
    },
    {
      name: "Unregistered",
      data: [unregisteredFamilies]
    }]
  return data
}
