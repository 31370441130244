import React, { useState } from 'react';
import { Image, Text, TouchableOpacity, View, ActivityIndicator } from '../../../app-components';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Image as ShowImage } from '../../../npms/react-core-components';
import { getImageUrl } from '../../../AppServices';
import { h12_Regular, h13_SemiBold } from '../../../theme/fonts';
import { collapseNewIcon, expandNewIcon, pdf } from '../../../images';
import Form from '../../../app-components/form/Form';
import MemberSymptomCheckerNote from './Components/MemberSymptomsCheckerNote';
import SymptomCheckerHistory from './Components/SymptomCheckerHistory';
import { getFileExtension } from '../../../app-components/UtilityFunctions';
import { colors } from '../../../theme/colors';

const MedicalHistory = (props) => {
  const { clinicalHistory: { chatHistory = [] } = {}, navigation = {} } = props;
  return (
    <View style={{ flex: 1, flexDirection: 'row',flexWrap: 'wrap' }}>
      {!isEmpty(chatHistory)
        ? chatHistory?.map((item) => {
          return (
            <View
              style={{
                marginLeft: 10,
                cursor: 'pointer',
                alignItems: 'center',
              }}>
              {getFileExtension(item?.assetFile?.key) === 'pdf' ? (
                <TouchableOpacity
                  onPress={() => {
                    let result = { mayamd_note: item?.assetFile };
                    navigation.push({
                      view: 'pdfReader',
                      params: {
                        dataArr: [result],
                        prevNote: true,
                        clickedIndex: 0,
                      },
                      modal: true,
                      modalProps: {
                        autoHide: true,
                        minHeight: 180,
                        position: 'screenLeft',
                        style: {
                          height: '100%',
                          width: 580,
                          // zIndex: 999,
                        },
                      },
                    });
                  }}>
                  <Image source={pdf} style={{ width: 70, height: 70 }} />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    navigation.push({
                      view: 'showPopupImage',
                      params: {
                        dataArr: item?.assetFile,
                      },
                      modal: true,
                      modalProps: {
                        autoHide: true,
                        minHeight: 180,
                        position: 'screenLeft',
                        style: {
                          height: '100%',
                          width: 580,
                          // zIndex: 999,
                        },
                      },
                    });
                  }}>
                  <Image
                    source={{ uri: getImageUrl(item?.assetFile?.key) }}
                    style={{ width: 70, height: 70, objectFit: 'contain',}}
                  />
                </TouchableOpacity>
              )}
              <View style={{ paddingTop: 4, marginBottom: 15 }}>
                <Text
                  style={{
                    ...h12_Regular,
                    color: colors.textColor,
                  }}>
                  {item?.date ? moment(item?.date).format('DD MMM YYYY') : '--'}
                </Text>
              </View>
            </View>
          );
        })
        : null}
    </View>
  );
};

export const SymptomChecker = (props) => {
  const { response, fullName } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <View>
      <TouchableOpacity
        style={{
          marginBottom: 5,
          borderRadius: 4,
          cursor: 'pointer',
          padding: 15,
          position: 'relative',
          backgroundColor: 'rgb(240, 240, 247)',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onPress={() => {
          setExpanded(!expanded);
        }}>
        <Text
          style={{
            ...h13_SemiBold,
            color: 'black',
          }}>
          {moment(response.date).format('DD MMM YYYY | hh:mm:ss')}
        </Text>
        {!expanded ? (
          <Image
            source={expandNewIcon}
            style={{
              height: 20,
              objectFit: 'contain',
            }}
          />
        ) : (
          <Image
            source={collapseNewIcon}
            style={{
              height: 20,
              objectFit: 'contain',
            }}
          />
        )}
      </TouchableOpacity>
      {expanded ? (
        response?.type === 'maya-pphm' ? (
          <MemberSymptomCheckerNote response={response} fullName={fullName} />
        ) : (
          <SymptomCheckerHistory
            symptomChecker={response?.mayaProSymptomChecker}
          />
        )
      ) : null}
    </View>
  );
};

export default MedicalHistory;
