import React, {useState, useEffect} from 'react';

import NestedList from '../../app-components/input-components/NestedList';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  ScrollView,
  // ActivityIndicator,
} from '../../app-components';
import {TextInput} from '../../app-components/input-components/Editors';
import {
  h14_AvenirNext,
  h16_Regular,
  h12_Regular,
  h14_Medium,
  h15_Regular,
} from '../../theme/fonts';
import {
  DateInput,
  ChipsToggleInput,
  MultiAutoSuggestInput,
  MultiTextInput,
  CheckBox,
} from '../../app-components/input-components/Editors';
import {get} from 'lodash';
import {
  getUser,
  post,
  setUser,
  checkDob,
  autosuggestFetch,
  setDependentPatient,
  getCurrency,
} from '../../AppServices';
import {Form} from '../../app-components/form/Form';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {colors} from '../../theme/colors';
import {redCloseIcon, roundCross} from '../../images';
import {
  autoSuggestInput,
  autoSuggestOptionsInput,
  searchInput,
} from '../../autoSuggestions';
import {
  OtherRenderSelector,
  RenderNoData,
} from '../../app-components/input-components/autoSuggest/renderRowCard';

const {themeContainer, themeTextColor, themeColor} = colors;
const PatientDetailsForm = (props) => {
  let {
    navigation,
    t = (text) => {
      return text;
    },
    userId,
    patientType,
    meOrSomeOne = 'me',
  } = props;
  const {state: {params = {}} = {}} = navigation;
  const [loading, setLoading] = useState(false);
  let {form_state: {data = {}} = {}, form_context: {setValue} = {}} = props;
  const {item = {}} = params;
  const {organization: {currency = 'USD'} = {}} = item || {};
  // const {patient: {_id: patientId} = {}} = getUser() || {};
  const [error, setError] = useState({});
  const [other, setOther] = useState({
    medical_history: false,
    surgical_history: false,
    allergies: false,
    medications: false,
    social_history: false,
    family_history: false,
  });
  const formField = ({
    title = '',
    placeholder = 'Enter your info',
    field = '',
    isChip = false,
    otherField = '',
    model,
    id,
    valueField,
    searchField,
  }) => {
    searchField = searchField || valueField;

    return (
      <View style={{marginTop: 15}}>
        <View style={{flexDirection: 'row'}}>
          <Text
            style={{
              ...h14_Medium,
              color: themeTextColor,
              // color: 'rgba(56, 56, 56)',
              marginBottom: 8,
            }}>
            {title}
          </Text>
        </View>
        {isChip ? (
          <>
            <MultiAutoSuggestInput
              data={data}
              onChangeValue={(value) => {
                setValue({
                  field,
                  value,
                  data,
                });
              }}
              showArrow={true}
              removeChipIcon={roundCross}
              renderSelector={(props) =>
                OtherRenderSelector({
                  otherValue: data[otherField] || other[field],
                  onChangeOtherValue: (value, clearText) => {
                    setValue({
                      field: otherField,
                      value: [
                        ...(data[otherField] ? data[otherField] : []),
                        value,
                      ],
                      data,
                    });
                    clearText && clearText();
                  },
                  ...props,
                })
              }
              fetch={autosuggestFetch({
                model,
                id,
                search: searchField,
                showOther: true,
              })}
              variant="filled"
              value={data[field]}
              valueField={valueField}
              idField={'_id'}
              field={valueField}
              sugestionField={valueField}
              searching={true}
              textColor={themeTextColor}
              placeholder={placeholder}
            />
          </>
        ) : (
          <TextInput
            data={data}
            value={data[field]}
            field={field}
            onChangeText={(value) => setValue({field, value, data})}
            variant="filled"
            placeholder={placeholder}
          />
        )}
        {/* <br></br> */}
        {data[otherField] || other[field] ? (
          <MultiTextInput
            data={data}
            value={data[otherField]}
            field={otherField}
            disableTextInput
            onChangeValue={(value) => {
              setValue({field: otherField, value, data});
            }}
            variant="filled"
            textColor={themeTextColor}
            placeholder={placeholder}
          />
        ) : (
          void 0
        )}
      </View>
    );
  };
  const UpdatePatient = () => {
    const {first_name = '', name = '', email = '', birthDate = ''} = data;
    if (!first_name.length) {
      setError({type: 'first_name', value: t('Please enter value')});
    } else if (!birthDate) {
      setError({type: 'dob', value: t('Please enter value')});
    } else if (!email.length) {
      setError({type: 'email', value: t('Please enter value')});
    } else {
      setLoading(true);
      // let family = data?.last_name;
      // let given = [data?.first_name||""];
      // let _id = (data && data.name && data?.name[0]?._id) || '';
      // let newNameArray = [{given, family, ...(_id ? {_id} : {})}];
      // let mobile = '';
      // const {telecom: dataTelecom = []} = data || {};
      // let email = {}
      // for ( let value of dataTelecom) {
      //   if (value?.system === 'email') {
      //     email = value;
      //   } else if (value?.system == 'phone') {
      //     mobile = value;
      //   }
      // }
      // email = {
      //   ...email,
      //   system: 'email',
      //   value: data.email || '',
      // };
      // let telecom = [];
      // if (mobile) {
      //   telecom.push(mobile);
      // }
      // if (email) {
      //   telecom.push(email);
      // }
      data = {
        ...data,
        // name: newNameArray,
        // telecom: telecom,
      };
      delete data._id;
      post({
        data: userId ? {_id: userId} : {},
        updates: {
          ...data,
          isDependent: meOrSomeOne === 'me' ? false : true,
          relation: patientType || '',
        },
        model: 'Patients',
      })
        .then((res) => {
          let response = res.result[0].result || {};
          if (meOrSomeOne === 'me') {
            let user = getUser();
            user['patient']['name'] = response?.name || user.name;
            setUser(user);
          } else {
            if (!userId) {
              userId = response?._id;
              // console.log(">>>>>>>>",response);
              setDependentPatient({id: response?._id});
            }
          }
          navigation.replace({
            // view: 'patient-payment',
            view: 'patient-appointment-details',
            params: {
              ...params,
              userId,
              data,
            },
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  let nestedlistprops = {
    field: 'Allergies',
    addButtonStyle: {width: 200, height: 100},
    footer: {
      addLabel: 'Add Allergy',
    },
    addInBottom: true,

    formGroups: [
      {
        columnsPerRow: 2,
        fieldVariant: 'filled',
        // fieldContainer: 'topLabel',

        groups: [
          {
            columns: [
              autoSuggestInput({
                label: 'Allergies',
                placeholder: 'Add Allergies here..',
                field: 'allergy',
                query: 'allergyLists',
                model: 'Allergies',
                valueField: 'name',
                suggestionField: 'name',
                searchField: 'name',
              }),
              autoSuggestOptionsInput({
                label: 'ClinicalStatus',
                field: 'clinicalStatus',
                suggestionField: 'label',
                valueField: 'value',
                options: [
                  {label: 'Active', value: 'active'},
                  {label: 'Inactive', value: 'inactive'},
                  {label: 'Resolved', value: 'resolved'},
                ],
                valueField: void 0,
                keyField: 'label',
              }),
              autoSuggestOptionsInput({
                label: 'VerificationStatus',
                field: 'verificationStatus',
                suggestionField: 'label',
                valueField: 'value',
                options: [
                  {label: 'Unconfirmed', value: 'unconfirmed'},
                  {label: 'Confirmed', value: 'confirmed'},
                  {
                    label: 'Unable To Assess',
                    value: 'unable-to-assess',
                  },
                ],
              }),
              {
                type: 'date',
                label: 'Asserted Date',
                field: 'assertedDate',
              },
              autoSuggestOptionsInput({
                label: 'Criticality',
                field: 'criticality',
                suggestionField: 'label',
                valueField: 'value',
                options: [
                  {label: 'Low', value: 'low'},
                  {label: 'High', value: 'high'},
                  {label: 'Refuted', value: 'refuted'},
                ],
              }),
              autoSuggestInput({
                label: 'Reaction',
                placeholder: 'Add Allergies here..',
                field: 'reaction',
                query: 'reactionList',
                model: 'Reactions',
                valueField: 'name',
                suggestionField: 'name',
                searchField: 'name',
              }),
              {
                width: 20,
                render: (props) => {
                  let {item, index, removeRow} = props;
                  return (
                    <TouchableOpacity
                      onPress={() => removeRow({item, index})}
                      style={{
                        cursor: 'pointer',
                        height: 38,
                        justifyContent: 'center',
                      }}>
                      <Image
                        source={redCloseIcon}
                        style={{width: 20, height: 20}}
                      />
                    </TouchableOpacity>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };

  let nestedMedicationlistprops = {
    field: 'Medications',
    addButtonStyle: {width: 200, height: 100},
    footer: {
      addLabel: 'Add Medications',
    },
    addInBottom: true,

    formGroups: [
      {
        columnsPerRow: 2,
        fieldVariant: 'filled',
        // fieldContainer: 'topLabel',

        groups: [
          {
            columns: [
              searchInput({
                label: 'Medications',
                placeholder: 'Add medications here..',
                field: 'medication',
                query: 'drugLists',
                model: 'Drugs',
                valueField: 'name',
                suggestionField: 'name',
                searchField: 'name',
                // editable: editable,
              }),
              autoSuggestOptionsInput({
                label: 'Status',
                field: 'clinicalStatus',
                suggestionField: 'label',
                valueField: 'value',
                options: [
                  {label: 'Active', value: 'active'},
                  {label: 'On Hold', value: 'on-hold'},
                  {label: 'Cancelled', value: 'cancelled'},
                  {label: 'Completed', value: 'completed'},
                  {label: 'Stopped', value: 'stopped'},
                  {label: '	Draft', value: 'draft'},
                ],
                valueField: void 0,
                keyField: 'label',
                // editable: editable,
              }),

              {
                width: 20,
                render: (props) => {
                  let {item, index, removeRow} = props;
                  return (
                    <TouchableOpacity
                      onPress={() => removeRow({item, index})}
                      style={{
                        cursor: 'pointer',
                        height: 38,
                        justifyContent: 'center',
                      }}>
                      <Image
                        source={redCloseIcon}
                        style={{width: 20, height: 20}}
                      />
                    </TouchableOpacity>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
  // console.log(">>>>>>>>>data",data)
  // const [t] = useTranslation();
  return (
    <>
      <ScrollView
        style={{
          // padding: 10,
          flex: 1,
          // paddingBottom: 50,
          // backgroundColor: themeContainer,
          // borderRadius: 8,
        }}>
        {/* <TouchableOpacity
          onPress={() => navigation.pop(2)}
          style={{
            cursor: 'pointer',
            alignItems: 'flex-start',
            marginTop: 5,
            marginBottom: 20,
          }}>
          <Text
            style={{
              ...h14_AvenirNext,
              lineHeight: 21,
              letterSpacing: 0.5,
              // color: '#383838',
              color: themeTextColor,
              // opacity: 0.54,
            }}>
            {'< '} {t('Back')}
          </Text>
        </TouchableOpacity>
         */}
        <Text
          style={{
            ...h16_Regular,
            // color: '#2e6ecd',
            color: themeTextColor,
            fontWeight: 600,
            marginBottom: 10,
          }}>
          {/* Patient Details */}
          {patientType ? patientType + ' Details' : t('Your Details')}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          {/* Name */}
          <View style={{flex: 2.5, marginRight: 15}}>
            <View style={{flexDirection: 'row'}}>
              <Text
                style={{
                  ...h14_Medium,
                  color: themeTextColor,
                  // color: 'rgba(56, 56, 56)',
                  marginBottom: 8,
                }}>
                {t('First Name')}
              </Text>
              <Text style={{...h14_Medium, color: '#f14f4f', paddingLeft: 3}}>
                *
              </Text>
            </View>
            <TextInput
              data={data}
              field={'first_name'}
              value={data.first_name}
              onChangeText={(value) =>
                setValue({field: 'first_name', value, data})
              }
              variant="filled"
              placeholder={`${t('Enter your')} ${t('First Name')}`} //"Enter Your First Name"
            />
            {error.type === 'first_name' && (
              <Text style={{...h12_Regular, color: 'red'}}>{error.value}</Text>
            )}
          </View>

          <View style={{flex: 2.5, marginRight: 15}}>
            <View style={{flexDirection: 'row'}}>
              <Text
                style={{
                  ...h14_Medium,
                  color: themeTextColor,
                  // color: 'rgba(56, 56, 56)',
                  marginBottom: 8,
                }}>
                {t('Last Name')}
              </Text>
            </View>
            <TextInput
              data={data}
              field={'last_name'}
              value={data.last_name}
              onChangeText={(value) =>
                setValue({field: 'last_name', value, data})
              }
              variant="filled"
              placeholder={t('Enter Your Last Name')}
            />
          </View>
        </View>

        {/* date and gender */}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 10,
            marginBottom: 10,
          }}>
          {/* Date */}
          <View style={{flex: 1, marginRight: 15}}>
            <View style={{flexDirection: 'row'}}>
              <Text
                style={{
                  ...h14_Medium,
                  color: themeTextColor,
                  // color: 'rgba(56, 56, 56)',
                  marginBottom: 8,
                }}>
                {t('DOB')}
              </Text>
              <Text style={{...h14_Medium, color: '#f14f4f', paddingLeft: 3}}>
                *
              </Text>
            </View>
            <DateInput
              format={'MM/DD/YYYY'}
              data={data}
              field={'birthDate'}
              // value={t('dob', {dob: new Date(data.dob)})}
              value={data.birthDate}
              onChangeValue={(value) => {
                if (checkDob(value)) {
                  setValue({field: 'birthDate', value, data});
                  setError({});
                  // } else {
                  //   setError({type: 'dob', value: t('invalidDOB')});
                }
              }}
              variant="filled"
              placeholder={t('Enter Your DOB')}
            />
            {error.type === 'dob' && (
              <Text style={{...h12_Regular, color: 'red'}}>{error.value}</Text>
            )}
          </View>
          {/* Gender*/}
          <View style={{flex: 1}}>
            <Text
              style={{
                ...h14_Medium,
                color: themeTextColor,
                // color: 'rgba(56, 56, 56)',
                marginBottom: 8,
              }}>
              {t('Gender')}
            </Text>
            <ChipsToggleInput
              onlyFirstLetter={true}
              data={data}
              field={'gender'}
              value={data.gender}
              onChangeValue={(value) =>
                setValue({field: 'gender', value, data})
              }
              selectedItemStyle={{
                borderColor: themeColor,
                backgroundColor: themeColor,
              }}
              optionStyle={{
                ...h14_Medium,
                color: '#383838',
              }}
              selectedOptionStyle={{
                color: themeTextColor,
                ...h14_Medium,
              }}
              itemStyle={{
                paddingTop: 9,
                paddingBottom: 8,
                paddingLeft: 18,
                paddingRight: 18,
                borderWidth: 1,
                borderRadius: 8,
                marginRight: 8,
                borderColor: themeTextColor,
                backgroundColor: themeTextColor,
                cursor: 'pointer',
              }}
              variant="filled"
              options={[
                {value: 'male', label: 'Male'},
                {value: 'female', label: 'Female'},
                {value: 'other', label: t('Other')},
              ]}
            />
          </View>
        </View>
        <View>
          <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                ...h14_Medium,
                color: themeTextColor,
                // color: 'rgba(56, 56, 56)',
                marginBottom: 8,
              }}>
              {t('Email Address')}
            </Text>
            <Text style={{...h14_Medium, color: '#f14f4f', paddingLeft: 3}}>
              *
            </Text>
          </View>
          <TextInput
            data={data}
            value={data.email}
            field={'email'}
            onChangeText={(value) => setValue({field: 'email', value, data})}
            variant="filled"
            placeholder={t('Enter email address')}
          />
          {error.type === 'email' && (
            <Text style={{...h12_Regular, color: 'red'}}>{error.value}</Text>
          )}
        </View>
        {/* Past medical History */}
        {formField({
          title: t('Past medical history'),
          placeholder: t('Add medical history here') + '...',
          field: 'medical_history',
          isChip: true,
          id: 'medicalHistoryLists',
          model: 'MedicalHistories',
          valueField: 'name_en',
          otherField: 'medical_history_other',
        })}
        {/* past surgical history */}
        {formField({
          title: t('Past surgical history'),
          placeholder: t('Add surgical history here') + '...',
          field: 'surgical_history',
          isChip: true,
          id: 'surgicalHistoryLists',
          model: 'SurgicalHistories',
          valueField: 'name',
          otherField: 'surgical_history_other',
        })}
        {/* medications */}
        <NestedList
          {...nestedMedicationlistprops}
          {...props}
          setValue={setValue}
          data={data}
          defaultAddRow={data.treatment ? false : true}
        />

        {/* family  history  */}
        {formField({
          title: t('Family history'),
          placeholder: `${t('Enter your')} ${t('Family history')}`, //t('Enter your family history'),
          field: 'family_history',
          id: 'medicalHistoryLists',
          model: 'MedicalHistories',
          valueField: 'name_en',
          otherField: 'family_history_other',
          isChip: true,
        })}
        <NestedList
          {...nestedlistprops}
          {...props}
          setValue={setValue}
          data={data}
          defaultAddRow={data.treatment ? false : true}
        />

        {/* social history  */}
        {formField({
          title: t('Social history'),
          placeholder: t('Enter your social history'),
          field: 'social_history',
          isChip: true,
          id: 'socialHistoryLists',
          model: 'SocialHistories',
          valueField: 'name',
          otherField: 'social_history_other',
        })}
        {/* <View style={{marginTop: 10}}>
          <Text style={{...h12_Regular, color: '#fff', opacity: 0.6}}>
            {
              ' This email id will be used to send E-priscriptions & other important updates'
            }
          </Text>
        </View> */}

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 10,
            marginBottom: 10,
            marginTop: 20,
            color: themeTextColor,
          }}>
          <Text style={{...h14_AvenirNext, opacity: 0.54}}>
            {t('Consultation Fee')}
          </Text>
          <Text style={{...h14_AvenirNext, fontWeight: 600}}>
            {`${getCurrency(currency)} ${item?.cost_of_booking}`}
          </Text>
        </View>
      </ScrollView>

      <TouchableOpacity
        style={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 10,
          paddingTop: 10,
          cursor: 'pointer',
        }}
        onPress={() => {
          if (!loading) {
            UpdatePatient();
          }
        }}>
        <GradientButton
          label={t('Next')}
          loading={loading}
          buttonStyle={{flex: 1}}
          indicatorColor={'#fff'}
        />
      </TouchableOpacity>
    </>
  );
};

const PatientDetails = (props) => {
  let {navigation, userId = '', patientType = '', meOrSomeOne = 'me'} =
    props || {};
  let {t} = props;
  const uri = {
    query: {
      id: 'patientLists',
      relationValue: {
        model: 'Patients',
        // _id: patient?._id,
        _id: userId,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Patients',
  };
  return (
    <Form
      uri={uri}
      mandatory={{name: 1}}
      containerStyle={
        {
          // paddingTop: 10,
          // // paddingBottom: 20,
          // paddingLeft: 12,
          // paddingRight: 12,
          // backgroundColor: surface_white,
        }
      }>
      {({form_context, form_state}) => {
        return (
          <PatientDetailsForm
            t={t}
            navigation={navigation}
            form_context={form_context}
            form_state={form_state}
            userId={userId}
            patientType={patientType}
            meOrSomeOne={meOrSomeOne}
          />
        );
      }}
    </Form>
  );
};

export default PatientDetails;
