import algorithmForm from './algorithmForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Algorithm',
  actions: [cancelButton(), save()],
};

let uri = ({navigation}) => {
  return {
    query: {
      id: 'AlgorithmCategoryList',
      relationValue: {
        model: 'AlgorithmCategories',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'AlgorithmCategories',
  };
};
export default algorithmForm({uri, header});
