import React, {useCallback} from 'react';
import {View, Text, TouchableOpacity} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {h16_Regular} from '../../theme/fonts';

function CreateOrderModal(props) {
  const {navigation = {}} = props;
  console.log(props);
  const {state: {params = {}} = {}} = navigation;
  const {
    handleOnCreateOrder = () => {},
    patientDetail = {},
    setIsLoading,
  } = params;
  const handleYesClick = useCallback(() => {
    handleOnCreateOrder(patientDetail);
    navigation.goBack();
  }, []);

  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: 500,
        padding: 20,
      }}>
      <Text
        style={{
          marginBottom: 10,
          marginTop: 10,
          ...h16_Regular,
        }}>
        Are you sure, you want to submit data to KRSNAA lab for this member?
      </Text>
      <View
        style={{
          flexDirection: 'row',
        }}>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: '45%',
            marginRight: '10%',
          }}
          onPress={handleYesClick}>
          <GradientButton label="OK" />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: '45%',
          }}
          onPress={() => {
            setIsLoading(false);
            navigation.goBack();
          }}>
          <GradientButton label="Cancel" />
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default CreateOrderModal;
