import React from 'react';

import TabNavigator from '../../app-components/tab/TabNavigator';
import PatientData from './PatientData';
import MyAppointmentData from './MyAppointment';
import {getUser} from '../../AppServices';
import {
  clinicFilter,
  patientNameFilter,
} from '../../app-components/filter/MayaMDFilters';
import Fts from '../../app-components/filter/Fts';

export default TabNavigator({
  tabs: {
    tab1: {
      visible: () => {
        let {userType} = getUser();
        return userType === 'SuperAdmin' ||
          userType === 'Doctor' ||
          userType === 'ClinicAdmin'
          ? true
          : false;
      },

      actions: [
        // visible: () => {
        //   let {userType} = getUser();
        //   return userType === 'SuperAdmin' || userType === 'Doctor'
        //     ? // userType === 'ClinicAdmin'
        //       falsCone
        //     : true;
        // },
        (props) => (
          <Fts
            {...props}
            asParams
            field={'name'}
            placeholder={'Search Patient Name '}
          />
        ),

        {
          visible: () => {
            let {userType} = getUser();
            return userType === 'SuperAdmin' ? true : false;
          },
          render: clinicFilter,
        },
      ],
      label: 'Patients',
      reloadEvent: 'reloadSpeciality',
      screen: PatientData,
    },
    tab3: {
      visible: () => {
        let {userType} = getUser();
        return userType === 'SuperAdmin' ||
          userType === 'Doctor' ||
          userType === 'ClinicAdmin'
          ? false
          : true;
      },
      reloadEvent: 'reloadProcedure',
      label: 'My Appointment',
      reloadEvent: 'reloadAddApointment',
      screen: MyAppointmentData,
    },
  },
});
