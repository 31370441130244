import Table from '../../../app-components/table/Table';

const uri = () => {
  return {
    query: {
      id: 'mayaTestResultList',
    },
    model: 'MayaTestResult',
  };
};

export default Table({
  uri,
  reloadEvent: 'reloadTestResultMapping',
  reloadOnChangeEvent: 'reloadTestResultMapping',
  action: ({ item }) => {
    return {
      type: 'link',
      link: {
        view: 'editMasterTestResult',
        params: { item },
        modal: true,
        modalProps: {
          // autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
            width: '50%',
          },
          style: {},
        },
      },
    };
  },
  columns: [
    {
      header: 'Lab Test Name',
      type: 'text',
      field: 'krsnaaTestName',

    },
    {
      header: 'Maya Test Name',
      type: 'text',
      field: 'mayaTestName',

    },
    {
      header: 'Maya Lab Id',
      type: 'text',
      field: 'mayaLabId',
      width: 110,
    },
    {
      header: 'Current Unit',
      type: 'text',
      field: 'currentUnit',
      width: 110,
    },
    {
      header: 'Maya Unit Id',
      type: 'text',
      field: 'mayaUnitId',
      width: 110,
    },
    {
      header: 'Ref. From',
      type: 'text',
      field: 'ref_from',
      width: 100,
    },
    {
      header: 'Ref. To',
      type: 'text',
      field: 'ref_to',
      width: 100,
    },
    {
      header: 'Lab Category',
      type: 'text',
      field: 'labCategory.labCategoryName',
      width: 120,
    },
    {
      header: 'Lab Sub Category',
      type: 'text',
      field: 'labSubCategory.labCategoryName',
      width: 130,
    },
    {
      header: 'Sort Order',
      type: 'text',
      field: 'testSortOrder',
      width:85,
    },
    {
      header: 'Alert Ref. From',
      type: 'text',
      field: 'alert_ref_from',
      width: 85,
    },
    {
      header: 'Alert Ref. To',
      type: 'text',
      field: 'alert_ref_to',
      width: 85,
    },
  ],
});
