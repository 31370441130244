import React from 'react';
import {TabPanel, ToolBar, isMobile} from '../index';
import {DataFetch} from '../data-fetch/DataFetch';
import {Action} from '../action/Action';
import {getResolvedMQProps} from '../../BreakPoints';
import {TabBar, TabItem} from '../../npms/react-tab-bar-v1';
import {DropDownAction} from '../../npms/react-drop-down-v1';
import ScreenComponent from '../screen/ScreenComponent';
import {tabTheme, tabMobileTheme} from '../../theme/tabBarTheme';
import {getUser} from '../../AppServices';

class GroupTabItem extends React.Component {
  render() {
    let {items, theme, ...restProps} = this.props;
    let options = items
      ? items.map((item) => {
          let {tab: {label, icon, activeIcon} = {}} = item;
          return {
            ...item,
            text: label,
            icon,
            activeIcon,
          };
        })
      : [];
    return (
      <DropDownAction
        options={options}
        Action={Action}
        {...theme}
        {...restProps}
      />
    );
  }
}

export default (defs) => {
  let {theme, ...restDef} = defs;
  if (!theme) {
    theme = isMobile ? tabMobileTheme : tabTheme;
  }
  theme = theme || {};
  class TabNavigator extends React.Component {
    state = {};

    componentDidUpdate(prevProps) {
      if (
        (this.props.reloadTabOnNavigationChange || restDef.reloadTabOnNavigationChange) &&
        this.props.navigation !== prevProps.navigation
      ) {
        this.setState({uuid: `tabpanel_${new Date().getTime()}`});
      }
    }

    onTabChange = ({navigation}) => {
      if (navigation) {
        let isModalView = navigation.isModalView && navigation.isModalView();
        if (!isModalView) {
          let routeCount =
            navigation.getRouteCount && navigation.getRouteCount();
          if (routeCount && navigation.getIndex) {
            let count = routeCount - 1 - (navigation.getIndex() || 0);
            if (count) {
              navigation.pop(count);
            }
          }
        }
      }
    };

    renderHeader = ({header, ...restHeaderProps}) => {
      if (typeof header === 'function') {
        header = header(...restHeaderProps);
      }
      if (header) {
        return (
          <ToolBar {...theme.tabHeaderStyle} {...restHeaderProps} {...header} />
        );
      } else {
        return null;
      }
    };

    resolveCount = (props) => {
      const {loaded, aggregates} = props || {};
      if (loaded) {
        return (aggregates && aggregates._count) || 0;
      }
      return null;
    };

    render() {
      const {navigation} = this.props;
      const activeMQ =
        navigation && navigation.getActiveMQ && navigation.getActiveMQ();

      let {tabs, resolveVisible, screenProps} = restDef;
      if (tabs) {
        let newTabs = {};
        Object.keys(tabs).forEach((tab) => {
          let tabDetail = tabs[tab];
          tabDetail = getResolvedMQProps(tabDetail, {activeMQ});
          let {visible = true} = tabDetail;
          if (visible && typeof visible === 'function') {
            if (resolveVisible) {
              visible = resolveVisible({
                user: getUser && getUser(),
                navigation,
              });
            } else {
              visible = visible({
                user: getUser && getUser(),
                navigation,
              });
            }
          }
          if (visible === true) {
            newTabs[tab] = tabDetail;
          }
        });

        restDef.tabs = newTabs;
      }
      if (screenProps) {
        restDef.screenProps = getResolvedMQProps(screenProps, {activeMQ});
      }

      let {
        tabPanelStyle,
        tabBarStyle,
        tabItemStyle,
        tabActionStyle,
        tabGroupItemsStyle,
      } = theme;
      let tabKeyProps = this.state.uuid ? {key: this.state.uuid} : {};
      return (
        <TabPanel
          onTabChange={this.onTabChange}
          {...tabPanelStyle}
          renderHeader={this.renderHeader}
          renderTabBar={
            <TabBar
              {...tabBarStyle}
              renderItem={
                <TabItem
                  {...tabItemStyle}
                  DataFetch={DataFetch}
                  resolveCount={this.resolveCount}
                  renderGroupItems={<GroupTabItem theme={tabGroupItemsStyle} />}
                />
              }
            />
          }
          renderLeftAction={<ToolBar {...tabActionStyle} />}
          renderActions={<ToolBar {...tabActionStyle} />}
          ScreenComponent={ScreenComponent}
          {...restDef}
          {...this.props}
          {...tabKeyProps}
        />
      );
    }
  }

  return TabNavigator;
};
