import React, {useEffect, useState} from 'react';
import {View} from '../../app-components';
import {getUser} from '../../AppServices';
import DoctorProfile from './DoctorProfile';

const AfterBookingSlotLogin = ({navigation, t}) => {
  const {params} = navigation?.state;

  const {patient} = getUser() || {};
  useEffect(() => {
    if (patient) {
      navigation.push({view: 'patient-details', params: {...params}});
    } else {
      navigation.push({view: 'patient-login', params: {...params}});
    }
  }, []);
  return (
    <View>
      <View style={{flexDirection: 'row'}}>
        <View
          style={{
            flex: 1,
            borderRadius: 10,
          }}>
          <DoctorProfile navigation={navigation} t={t} />
        </View>
      </View>
    </View>
  );
};

export default AfterBookingSlotLogin;
