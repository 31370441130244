import React from 'react';
import {Image, Text, View} from '../../index';
import {fileInputBoxTheme} from '../../../theme/fileBoxTheme';

const FileInputRender = (props) => {
  const {label = ''} = props;
  let {
    fileInputStyle: {
      boxStyle,
      uploadImageStyle: {imageStyle, imageProps, image} = {},
      uploadTextStyle: {
        viewStyle,
        textStyle,
        text = 'Drag & Drop your files here',
      } = {},
    } = {},
  } = fileInputBoxTheme;
  return (
    <View
      style={{
        ...boxStyle,
        // paddingTop: 12,
        // paddingBottom: 12,
        flex: 1,
      }}>
      <View
        style={{
          ...viewStyle,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}>
        <Image source={image} style={{...imageStyle}} {...imageProps} />
        <Text style={{...textStyle}}>{label}</Text>
      </View>
    </View>
  );
};
export default FileInputRender;
