import React from 'react';
import {View, TouchableOpacity, Image} from '../../app-components';
import {closeIcon} from '../../images';
import GoogleMap from '../../app-components/GoogleMap';

const MapModal = (props) => {
  const {navigation = {}} = props || {};
  const {state: {params: {item}} = {}} = navigation || {};
  const {address = {}} = item || {};
  return (
    <View
      style={{
        width: 700,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}>
      <TouchableOpacity
        style={{
          position: 'absolute',
          zIndex: 99,
          marginLeft: 10,
          cursor: 'pointer',
          top: 8,
          right: 8,
        }}
        onPress={(e) => {
          e.stopPropagation();
          e.preventDefault();
          navigation.pop();
        }}>
        <Image source={closeIcon} style={{height: 20, width: 20}} />
      </TouchableOpacity>
      <View
        style={{
          width: 700,
          marginTop: 50,
          marginBottom: 50,
        }}>
        <GoogleMap
          latitude={address?.latitude}
          longitude={address?.longitude}
          mapHeight={500}
        />
      </View>
    </View>
  );
};

export default MapModal;
