import React, {useCallback, useState} from 'react';
import {isMobile} from '../../app-components';
import MobileInput from '../../app-components/input-components/MobileInput';
import {h12_SemiBold, h16_Regular} from '../../theme/fonts';
import AdvertisementBeforeLogin from '../GenericComponent/AdvertisementBeforeLogin';
import {
  ReactForm,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Image,
  ActivityIndicator,
} from './components';
import {validateEmail} from '../../AppServices';
const libPhoneNumber = require('google-libphonenumber');
const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

export default ({checkUserExist, theme: loginStyle, images}) => {
  const {SVMLogo} = images;
  const errorMessage = ({field, mandatoryErrors, errorStyle}) => {
    let {code} = (field && mandatoryErrors && mandatoryErrors[field]) || {};
    return <Text style={errorStyle}>{code}</Text>;
  };
  return (props) => {
    const {navigation} = props;
    const getUserType = () => {
      let type = navigation?.state?.params?.type;
      if (type) return type;
      const routes = navigation.getActiveRoutes();
      if (
        Array.isArray(routes) &&
        routes.length &&
        routes[0] === 'superAdmin'
      ) {
        return 'SuperAdmin';
      }
    };
    const [type, setType] = useState(getUserType());
    const Next = async (data, setValue) => {
      let {email, mobile, isForgot = false, mobileError, emailError} = data;
      const isMobileLengthError = data?.mobile?.length
        ? true
        : data?.email?.length
        ? true
        : false;
      if (mobile) {
        try {
          let result = phoneUtil.isValidNumber(phoneUtil.parse(mobile));
          if (!result) {
            setValue({
              field: 'mobileError',
              value: 'The mobile number you entered appears to be invalid',
            });
            return;
          }
        } catch (e) {
          setValue({field: 'mobileError', value: 'Please enter mobile number'});
          return;
        }
      }
      if (email) {
        if (!validateEmail(email)) {
          setValue({
            field: 'emailError',
            value: 'The email address you entered appears to be invalid',
          });
          return;
        }
      }
      if (mobileError || emailError) {
        return;
      }
      if (!isMobileLengthError) {
        setValue({field: 'mobileError', value: 'Please enter mobile/email'});
        return;
      }
      setValue && setValue({field: 'loading', value: true});
      if (email || mobile) {
        checkUserExist(email?.length ? email : mobile, type, false, isForgot)
          .then((res) => {
            const {mobileVerified = false} = res || {};
            setValue && setValue({field: 'loading', value: false});
            // if (isForgot || !mobileVerified) {
            //   navigation.replace('signup-otp', {
            //     mobile: email?.length ? email : mobile,
            //     userType: type,
            //     isForgot,
            //     mobileVerified,
            //   });
            // } else {
            navigation.replace('passcode', {
              mobile: email?.length ? email : mobile,
              type,
              isForgot,
              mobileVerified,
            });
            // }
          })
          .catch((e) => {
            console.log('>@>Puru ~ file: LoginForm.js ~ line 104 ~ e', e);
            const {data: {response: {error: {message = ''} = {}} = {}} = {}} =
              e?.response || {};
            setValue && setValue({field: 'loading', value: false});
            setValue({
              field: `${
                typeof email === 'string'
                  ? email?.length
                    ? 'emailError'
                    : 'mobileError'
                  : 'mobileError'
              }`,
              value: message
                ? message
                : `${
                    typeof email === 'string'
                      ? email?.length
                        ? 'Email'
                        : 'Mobile'
                      : 'Mobile'
                  } not registered`,
            });
          });
      }
    };

    let {
      scrollViewStyle,
      containerStyle,
      loginBoxStyle,
      loginText,
      mobileLabel,
      loginButton,
      loginButtonText,
      errorStyle,
      loginLogoStyle,
      loginLogoContainer,
      loginTextInput,
    } = loginStyle;

    const getLoginForm = useCallback(({form_context, form_state}) => {
      let {data} = form_state || {};
      let {setValue, mandatoryErrors} = form_context;
      const {isForgot = false} = data || {};
      return (
        <View
          style={{
            ...containerStyle,
            flexDirection: 'row',
            ...(isMobile
              ? {
                  maxHeight: 600,
                  marginTop: 30,
                }
              : {}),
          }}>
          {isMobile ? (
            void 0
          ) : (
            <View id="advertisement" style={{flex: 2}}>
              <AdvertisementBeforeLogin {...props} />
            </View>
          )}
          <View style={{...loginBoxStyle, height: '100%', flex: 1}}>
            <View
              style={{
                backgroundColor: isForgot
                  ? 'forgot_container'
                  : 'login_container',
              }}>
              <View style={loginLogoContainer} id="brand_logo">
                <Image
                  source={SVMLogo}
                  // source={hospitalLiveLogo}
                  style={{...loginLogoStyle}}
                />
              </View>
              <View id={'brand_title'} style={{alignItems: 'center'}}>
                <Text
                  style={{
                    ...loginText,
                  }}>
                  Get started with MayaMD
                </Text>
              </View>
              <View style={{flex: 1, marginTop: 35}}>
                {/* <Text
                  style={{
                    ...mobileLabel,
                    marginBottom: 20,
                  }}>
                  {isForgot
                    ? 'Enter the email or mobile number associated with mayaMD'
                    : ''}
                </Text> */}
                <View
                  style={{flex: 1}}
                  id={
                    isForgot ? 'forgot_form_container' : 'login_form_container'
                  }>
                  <Text
                    style={mobileLabel}
                    id={isForgot ? 'forgot_email_label' : 'email_label'}>{`${
                    isForgot
                      ? 'Enter the email address associated with MayaMD'
                      : 'Email Address'
                  }`}</Text>
                  <TextInput
                    style={{
                      ...loginTextInput,
                      borderRadius: 8,
                    }}
                    data={data}
                    autoFocus={true}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        Next && Next(data, setValue);
                      }
                    }}
                    placeholder="Enter Email Address"
                    setValue={setValue}
                    field="email"
                    // autoFocus={true}
                    id={'email'}
                    // onFocus={() => setValue({field: 'mobile', value: ''})}
                    onChangeText={() => {
                      if (data.mobile) {
                        setTimeout(() => {
                          setValue({field: 'mobile', value: ''});
                        }, 100);
                      }
                      if (data.mobileError || data.emailError) {
                        setTimeout(() => {
                          setValue({field: 'mobileError', value: ''});
                          setValue({field: 'emailError', value: ''});
                        }, 100);
                      }
                    }}
                  />
                  {data.emailError ? (
                    <Text style={errorStyle}>{data.emailError}</Text>
                  ) : (
                    <Text style={{...errorStyle, display: 'none'}} />
                  )}
                  {errorMessage({field: 'email', mandatoryErrors, errorStyle})}
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 40,
                    }}>
                    <Text id="or" style={{color: '#383838', ...h16_Regular}}>
                      Or
                    </Text>
                  </View>
                  <Text
                    style={mobileLabel}
                    id={isForgot ? 'forgot_mobile_label' : 'mobile_label'}>{`${
                    isForgot
                      ? 'Enter the mobile number associated with MayaMD'
                      : 'Mobile number'
                  }`}</Text>
                  <MobileInput
                    data={data}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        Next && Next(data, setValue);
                      }
                    }}
                    mobileId={'mobile'}
                    buttonStyle={{borderRadius: 8, width: 50}}
                    inputStyle={{borderRadius: 8}}
                    value={data?.mobile || ''}
                    setValue={setValue}
                    fieldName="mobile"
                    onChangeText={() => {
                      if (data.email) {
                        setValue({field: 'email', value: ''});
                      }
                      if (data.mobileError || data.emailError) {
                        setTimeout(() => {
                          setValue({field: 'mobileError', value: ''});
                          setValue({field: 'emailError', value: ''});
                        }, 100);
                      }
                    }}
                  />

                  {data.mobileError ? (
                    <Text style={errorStyle}>{data.mobileError}</Text>
                  ) : (
                    <Text style={{...errorStyle, display: 'none'}} />
                  )}
                  {errorMessage({field: 'email', mandatoryErrors, errorStyle})}
                </View>
              </View>
              <View
                style={{
                  alignItems: 'flex-end',
                  height: 40,
                  justifyContent: 'center',
                }}>
                <TouchableOpacity
                  style={{padding: 2, cursor: 'pointer'}}
                  onPress={() => {
                    setValue({field: 'isForgot', value: !isForgot});
                  }}>
                  <Text
                    id={isForgot ? 'back_to_login_link' : 'forgot_link'}
                    style={{color: '#0095C9', ...h12_SemiBold}}>
                    {isForgot ? 'Back to login' : 'Forgot Password'}
                  </Text>
                </TouchableOpacity>
              </View>
              <View>
                <TouchableOpacity
                  style={{...loginButton, marginTop: 10}}
                  onPress={() => {
                    Next(data, setValue);
                  }}
                  id={'confirm'}>
                  {data?.loading ? (
                    <ActivityIndicator size={'small'} color={'white'} />
                  ) : (
                    <Text
                      style={{
                        ...loginButtonText,
                      }}>
                      {isForgot ? 'Confirm' : 'Login'}
                    </Text>
                  )}
                </TouchableOpacity>
                {type === 'Doctor' ? (
                  <TouchableOpacity
                    style={{marginTop: 20, alignItems: 'center'}}
                    onPress={() => {
                      navigation.push({
                        view: 'clinic-signup',
                        params: {
                          type,
                        },
                      });
                    }}>
                    <Text
                      id="register_your_clinic"
                      style={{
                        ...h12_SemiBold,
                        color: '#0095C9',
                      }}>
                      Register Your Clinic
                    </Text>
                  </TouchableOpacity>
                ) : (
                  void 0
                )}
              </View>
            </View>
          </View>
          {/* <TouchableOpacity
            style={contactSupportViewStyle}
            onPress={() =>
              navigation.push({view: 'contact-support-form', modal: true})
            }>
            <Text style={contactSupportText}>Contact support</Text>
          </TouchableOpacity> */}
        </View>
      );
    });

    return (
      <ScrollView style={scrollViewStyle}>
        <ReactForm
          scrollable={false}
          mandatory={{
            mobile: 'Mobile is mandatory',
          }}>
          {getLoginForm}
        </ReactForm>
      </ScrollView>
    );
  };
};
