import React from 'react';
import {colors} from '../../theme/colors';
import moment from 'moment';
import {
  Text,
  View,
  TouchableWithoutFeedback,
  isMobile,
} from '../../app-components';
import CustomDateFiter from '../../app-components/DateFilter/DateFilter';
import MySchedule from './MySchedule';
import MyScheduleMobile from './MyScheduleMobile';
import {
  h12_Regular,
  h14_AvenirNext,
  h24_Regular,
  h24_AvenirNext_500,
} from '../../theme/fonts';
import {getUser, getData, getCurrency} from '../../AppServices';

const {themeColor} = colors;
const {DateFilter} = CustomDateFiter;
const styles = {
  headerContainer: {
    backgroundColor: '#e7eef5',
    flexDirection: isMobile ? 'column' : 'row',
    paddingTop: isMobile ? 15 : 32,
    paddingBottom: isMobile ? 15 : 32,
    justifyContent: 'space-evenly',
    ...(isMobile
      ? {
          alignItems: 'center',
        }
      : {}),
  },
  countViewContainer: {
    width: 90,
    borderRadius: 15.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerColumnContainer: {
    backgroundColor: '#ffff',
    padding: 10,
    borderRadius: 8,
  },
};
const countView = (bgColor, textColor, value) => {
  return (
    <TouchableWithoutFeedback>
      <View style={{...styles.countViewContainer, backgroundColor: bgColor}}>
        <Text
          style={{
            ...h12_Regular,
            color: textColor,
          }}>
          {value}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};
const headerColumn = ({text1, text2, countViewValues, color = '#73c5a7'}) => {
  return (
    <View
      style={{
        ...styles.headerColumnContainer,
        width: countViewValues ? 350 : 200,
        margin: isMobile ? 5 : 0,
      }}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <Text
          style={{
            ...h14_AvenirNext,
            lineHeight: 31,
            letterSpacing: 0.5,
            color: 'rgba(56, 56, 56, 0.77)',
          }}>
          {text1}
        </Text>
        {countViewValues
          ? countView(
              countViewValues?.bgColor,
              countViewValues?.textColor,
              countViewValues?.value,
            )
          : null}
      </View>
      <View>
        <Text
          style={{...h24_Regular, lineHeight: 31, letterSpacing: 0.86, color}}>
          {text2}
        </Text>
      </View>
    </View>
  );
};

class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {cardData: void 0};
  }
  componentDidMount = async () => {
    console.log('asdfg', this.props);

    // this.setState({cardData: data[0]});
  };
  refreshData = async ({screenState}) => {
    const {
      dataParams: {filters: {period: {value} = {}} = {}} = {},
    } = screenState;
    const {doctor = {}, organization: {currency = 'USD'} = {}} = getUser();
    const apiData = await getData({
      model: 'Appointments',
      id: 'doctorAppointments',
      filter: {doctor_id: {_id: doctor?._id}},
      paramValue: {
        period: {
          from: value?.from,
          to: value?.to,
        },
      },
    });
    const {data} = apiData;
    this.setState({cardData: data[0]});
  };
  componentDidUpdate = (prev, current) => {
    if (prev !== this.props) {
      this.refreshData(this.props);
    }
  };
  render() {
    const {cardData} = this.state;
    const {organization: {currency = 'USD'} = {}} = getUser();
    return (
      <>
        {headerColumn({
          text1: 'Total Appointments ',
          text2: cardData?._count ? cardData?._count : 0,
          color: '#3c3c3c',
        })}
        {headerColumn({
          text1: 'Scheduled Hours',
          text2: `${
            cardData?.total_duration
              ? (cardData?.total_duration / 60).toFixed(2)
              : 0
          } hrs`,
          color: '#3c3c3c',
        })}
        {headerColumn({
          text1: 'Total Revenue',
          text2: `${getCurrency(currency)} ${
            cardData?.total_booking_fee ? cardData?.total_booking_fee : 0
          }`,
          color: '#3c3c3c',
        })}
      </>
    );
  }
}

class ScheduleHeader extends React.Component {
  checkBetween = (date1, date2, date3) => {
    const date11 = moment(date1).format('MM/DD/YYYY');
    const date22 = moment(date2).format('MM/DD/YYYY');
    const date33 = moment(date3).format('MM/DD/YYYY');
    return moment(date33).isBetween(date11, date22);
  };
  render() {
    let {screenState} = this.props;
    const {
      dataParams: {filters: {period: {value} = {}} = {}} = {},
    } = screenState;
    return (
      <View style={styles.headerContainer}>
        <View
          style={{justifyContent: 'center', marginBottom: isMobile ? 10 : 0}}>
          <Text style={{...h24_AvenirNext_500, color: themeColor}}>
            {this.checkBetween(value?.from, value?.to, new Date())
              ? 'This Week’s'
              : `${moment(value?.from).format('DD')}-${moment(value?.to).format(
                  'MMM DD ',
                )}'s`}
          </Text>
          <Text style={{...h14_AvenirNext, color: '#383838'}}>
            Appointment Summary
          </Text>
        </View>
        {isMobile ? (
          void 0
        ) : (
          <>
            <Cards {...this.props} />
          </>
        )}
        {
          <DateFilter
            parent={'upcoming'}
            defaultFilter={'Week'}
            {...this.props}
            defaultStyle={{backgroundColor: '#fff', color: themeColor}}
          />
        }
      </View>
    );
  }
}
export default class DoctorSchedule extends React.Component {
  render() {
    return (
      <View style={{flex: 1, overflow: 'hidden'}}>
        <ScheduleHeader {...this.props} key={this.props.state.uid} />
        {isMobile ? (
          <MyScheduleMobile {...this.props} key={this.props.state.uid} />
        ) : (
          <MySchedule {...this.props} key={this.props.state.uid} />
        )}
      </View>
    );
  }
}
