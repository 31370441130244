import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import todoFormCategory from './todoFormCategory';

let header = {
  title: 'Add To-Do Task Category',
  actions: [cancelButton(), save()],
};

export default todoFormCategory({header});
