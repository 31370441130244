import React from 'react';
import axios from 'axios';
import compact from 'lodash/compact';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {View} from '../../../app-components';
import {closeButton, getNewId} from '../rpmUtils';
import Form from '../../../app-components/form/Form';
import {mayaEngine} from '../../../Config';
import {getData, getUser, submit} from '../../../AppServices';
import {
  autoSuggestInput,
  autoSuggestOptionsInput,
  multiAutoSuggestInput,
  multiSearchInput,
  searchInput,
} from '../../../autoSuggestions';
// import {
//   customRegexValidator,
//   multiValidator,
//   onlyAlphaWithDotValidator,
//   someAlphaValidator,
// } from '../../validations';

const parentWidth = '48.9%';
const checkIsNotSurgery = ({data}) => !data?.isSurgery;
const visibleBloodPressureField = ({data}) => {
  if (
    data?.parameter?.name === 'Blood Pressure' &&
    data?.parameter?.type === 'range'
  ) {
    return true;
  }
  return false;
};
const visibleRange = ({data}) => {
  if (data?.parameter?.name === 'Blood Pressure') {
    return false;
  }
  return data?.parameter?.type === 'range';
};
const horizontalLine = {
  type: 'custom',
  width: '100%',
  render: () => (
    <View
      style={{
        borderBottomWidth: 2,
        borderBottomColor: '#f2eeed',
        height: 20.1,
      }}
    />
  ),
};
const splitResult = (item) => {
  const {title = ''} = item || {};
  const splitedData = title.split(':');
  let name = '';
  if (Array.isArray(splitedData) && splitedData.length) {
    if (splitedData.length > 1) {
      name = splitedData[1].trim();
    } else {
      name = splitedData[0].trim();
    }
  }
  return name;
};
const modifyAlgorithmCategory = ({data}) => {
  const newData = data.map((item) => {
    return {
      ...item,
      name: splitResult(item),
    };
  });
  return {data: newData};
};
const updateForm = ({updates, data}) => {
  const {programTodos = [], monitoringParameter, isManualReading = true} =
    updates || {};
  const programTodosModify = [];
  const monitoringParameterModify = [];
  if (Array.isArray(monitoringParameter) && monitoringParameter.length) {
    for (let value of monitoringParameter) {
      const {
        parameter,
        unitMeasurement,
        checkInterval,
        device,
        diastolicHigherThreshold,
        diastolicLowerThreshold,
        systolicHigherThreshold,
        systolicLowerThreshold,
        higherThreshold,
        lowerThreshold,
      } = value;
      if (parameter?._id) {
        if (unitMeasurement?._id) {
          if (checkInterval?._id) {
            if (isManualReading) {
              monitoringParameterModify.push(value);
              // if (visibleBloodPressureField({data: value})) {

              // if (
              // diastolicHigherThreshold &&
              // diastolicLowerThreshold &&
              // systolicHigherThreshold &&
              // systolicLowerThreshold
              // ) {
              // monitoringParameterModify.push(value);
              // } else {
              // throw new Error(
              // `Systolic/Diastolic Threshold is required for ${parameter?.name}`,
              // );
              // }
              // } else {
              // if (lowerThreshold && higherThreshold) {
              // monitoringParameterModify.push(value);
              // } else {
              // throw new Error(
              // `Lower/Higher Threshold is required for ${parameter?.name}`,
              // );
              // }
              // }
            } else {
              throw new Error(`Device is required for ${parameter?.name}`);
            }
          } else {
            throw new Error(
              `Check Interval is required for ${parameter?.name}`,
            );
          }
        } else {
          throw new Error(
            `Unit Measurement is required for ${parameter?.name}`,
          );
        }
      }
    }
  }
  if (Array.isArray(programTodos) && programTodos.length) {
    for (let value of programTodos) {
      const {
        enableTodo = false,
        todoId,
        frequency = {},
        algorithmCategory = {},
        algorithmIds = [],
      } = value;
      if (enableTodo) {
        const {enable_algorithm = false} = todoId || {};
        if (frequency && frequency?._id) {
          if (enable_algorithm) {
            if (algorithmCategory && algorithmCategory?._id) {
              if (Array.isArray(algorithmIds) && algorithmIds.length) {
                programTodosModify.push(value);
              } else {
                throw new Error(
                  `Algorithm Name is required for To-Do ${todoId?.title}`,
                );
              }
            } else {
              throw new Error(
                `Algorithm Category is required for To-Do ${todoId?.title}`,
              );
            }
          } else {
            programTodosModify.push(value);
          }
        } else {
          throw new Error(`Frequency is required for To-Do ${todoId?.title}`);
        }
      }
    }
  }
  return {
    updates: {
      ...updates,
      programTodos: programTodosModify,
      monitoringParameter: monitoringParameterModify,
    },
  };
};
export default ({
  // uri,
  header,
  superAdminEditable,
  columnsPerRow,
  editable,
  openProgramDetails = false,
  closeView = 1,
  mandatory = true,
}) => {
  if (superAdminEditable) {
    editable = true;
  }
  const mayaAlog = multiAutoSuggestInput({
    label: 'Algorithm Name',
    placeholder: 'Add Algorithm Name',
    field: 'algorithmIds',
    idField: 'id',
    valueField: 'name',
    suggestionField: 'name',
    searchField: 'name',
    keyField: 'id',
    editable: editable,
    visible: ({data}) => !!data?.todoId?.enable_algorithm && !!data?.enableTodo,
    fetch: ({data}) => {
      const {algorithmCategory: {title = ''} = {}} = data || {};
      // console.log(`>@>Puru ~ file: RpmLibraryForm.js ~ line 93 ~ data`, data);
      let category = [title];
      return axios
        .post(mayaEngine.newApiUrl, {
          apiKey: mayaEngine.apiKey,
          apiSecret: mayaEngine.apiSecret,
          requestType: 'getAlgorithmicSymptom',
          category,
        })
        .then((response) => {
          let {
            data: {result},
          } = response;
          console.log(
            '>@>Puru ~ file: RpmLibraryForm.js ~ line 106 ~ result',
            result,
          );
          return {data: result};
        })
        .catch((err) => {
          console.log(err);
          return {data: []};
        });
    },
  });
  return Form({
    type: 'standardShadow',
    reloadEvent: 'reloadProgramLibrary',
    reloadOnChangeEvent: 'reloadProgramLibrary',
    closeView,
    onSubmit: submit({
      model: 'CarePrograms',
    }),
    // onSubmit: ({updates}) => {
    // console.log(
    // `>@>Puru ~ file: RpmLibraryForm.js ~ line 135 ~ props`,
    // updates,
    // );
    // },
    // uri,
    fieldVariant: 'filled',
    fieldContainer: 'topLabel',
    mandatory: {
      name: 'Please Enter Program Name',
      description: 1,
      programAdmin: 1,
      chronicDisease: 1,
    },
    validateOnChange: true,
    header: header,
    expandSingleFormgroup: true,
    validations: {
      // name: multiValidator(
      // {
      // field: 'name',
      // errMsg: 'Invalid Program Name',
      // regex: /^[a-zA-Z\s@1]*$/,
      // },
      // customRegexValidator,
      // someAlphaValidator,
      // ),
      // name: someAlphaValidator({
      //   field: 'name',
      //   errMsg: 'Invalid Program Name',
      //   // regex: /[A-Za-z]+/,
      // }),
      // programAdmin: onlyAlphaWithDotValidator({
      //   field: 'programAdmin',
      //   errMsg: 'Program Admin Name can not contain special characters',
      // }),
      // description: someAlphaValidator({ field: 'description' }),
    },
    beforeSubmit: updateForm,
    computations: {
      self: {
        get: {
          compute: async (data = {}) => {
            const {selectedTodo = [], programTodos = []} = data || {};
            let programTask = [];
            let newProgramTodos = [];
            if (Array.isArray(selectedTodo) && selectedTodo.length) {
              for (let i of selectedTodo) {
                const {_id} = i;
                let modifyTask = [];
                let isNewTask = true;
                programTodos.forEach((item) => {
                  if (item?.todoId?.category?._id === _id) {
                    newProgramTodos.push(item);
                    isNewTask = false;
                  }
                });
                if (isNewTask) {
                  let filter = {
                    category: {_id: {$in: [_id]}},
                  };
                  const {data: programTask = []} = await getData({
                    model: 'Todo',
                    id: 'todoList',
                    filter,
                  });
                  modifyTask = programTask.map((item) => {
                    return {
                      todoId: item,
                      _id: getNewId(),
                    };
                  });
                }
                programTask = [...newProgramTodos, ...modifyTask];
              }
            }
            return {
              set: {
                programTodos: programTask,
              },
            };
          },
          onChange: ['selectedTodo'],
        },
      },
      children: {
        monitoringParameter: {
          self: {
            addDataDynamic: {
              compute: async (data) => {
                const _id = get(data, 'parameter._id', '');
                // let {parameter: {_id = ''} = {}} = data || {};
                let device = [],
                  checkInterval = {},
                  unitMeasurement = {};
                const checkIntervalRes = await getData({
                  id: 'checkIntervalList',
                  model: 'CheckIntervals',
                });
                const {data: checkIntervalData} = checkIntervalRes || {};
                if (
                  Array.isArray(checkIntervalData) &&
                  checkIntervalData.length
                ) {
                  checkInterval = checkIntervalData[0];
                }

                if (_id) {
                  const deviceRes = await getData({
                    id: 'devicesList',
                    model: 'Devices',
                    filter: {
                      monitoringParameter: {
                        parameter: {_id} || '',
                      },
                    },
                  });
                  const {data: deviceData} = deviceRes || {};
                  if (Array.isArray(deviceData) && deviceData.length) {
                    device.push(deviceData[0]);
                  }
                  const unitMeasurementRes = await getData({
                    id: 'unitMeasurementList',
                    model: 'UnitMeasurements',
                    filter: {parameter: {_id}},
                  });
                  const {data: unitMeasurementData} = unitMeasurementRes || {};
                  if (
                    Array.isArray(unitMeasurementData) &&
                    unitMeasurementData.length
                  ) {
                    unitMeasurement = unitMeasurementData[0];
                  }
                }
                return {
                  set: {
                    device,
                    checkInterval,
                    unitMeasurement,
                  },
                };
              },
              onChange: ['parameter'],
            },
          },
        },
      },
    },
    defaultValues: ({navigation}) => {
      const {state: {params: {item = {}} = {}} = {}} = navigation || {};
      const {programTodos = []} = item || {};
      let programTodosModify = [];
      if (Array.isArray(programTodos) && programTodos.length) {
        for (let val of programTodos) {
          const {todoId: {enable_algorithm = false} = {}} = val || {};
          const {algorithmCategory = {}} = val || {};
          if (enable_algorithm) {
            programTodosModify.push({
              ...val,
              // algorithmIds:[],
              algorithmCategory: {
                ...algorithmCategory,
                name: splitResult(algorithmCategory),
              },
            });
          } else {
            programTodosModify.push(val);
          }
        }
      }

      const {userType = ''} = getUser() || {};
      let careProgramSpeciality = {};
      if (!isEmpty(item?.careProgramSpeciality)) {
        careProgramSpeciality = {
          ...item?.careProgramSpeciality,
          name: item?.careProgramSpeciality?.speciality?.name || '',
        };
      }
      return {
        monitoringParameter: [],
        algorithmTypes: [],
        status: 'Active',
        isManualReading: true,
        isSurgery: false,
        isCCM: true,
        ...(userType === 'SuperAdmin' ? {isDefault: true} : {isDefault: false}),
        ...item,
        ...(programTodosModify.length
          ? {
              programTodos: programTodosModify,
            }
          : {}),
        careProgramSpeciality,
      };
    },
    formGroups: [
      {
        label: 'Program Details',
        expandable: !openProgramDetails,
        // expanded: openProgramDetails,
        groups: [
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'text',
                label: 'Program Name',
                field: 'name',
                placeholder: 'Enter program name',
                inputProps: {
                  maxLength: 300,
                },
                editable: editable,
                width: parentWidth,
                mandatory,
              },
              searchInput(
                {
                  label: 'Choose Care Program Speciality',
                  placeholder: 'Choose Care Program Speciality',
                  field: 'careProgramSpeciality',
                  query: 'careProgramSpecialityList',
                  idField: '_id',
                  model: 'CareProgramSpeciality',
                  valueField: 'name',
                  suggestionField: 'name',
                  searchField: 'name',
                  editable: editable,
                  width: parentWidth,
                },
                {
                  modifyResult: ({data}) => {
                    const newData = data.map((item) => ({
                      ...item,
                      name: item?.speciality?.name,
                    }));
                    return {data: newData};
                  },
                },
              ),
              {
                type: 'textArea',
                label: 'Program Description',
                field: 'description',
                inputProps: {
                  maxLength: 500,
                },
                editable: editable,
                placeholder: 'Enter program description',
                width: parentWidth,
                mandatory,
              },
              {
                type: 'checkBox',
                field: 'isCCM',
                label: 'Enable CCM Check List',
                editable: editable,
              },
              // {
              //   type: 'checkBox',
              //   field: 'isSurgery',
              //   label: 'Enable Surgery',
              //   editable: editable,
              // },
            ],
          },
          {
            columnsPerRow: 2,
            columns: [
              // autoSuggestOptionsInput({
              // // width: '48.3%',
              // label: 'Status',
              // field: 'status',
              // suggestionField: 'label',
              // valueField: 'value',
              // options: [
              // {label: 'Active', value: 'active'},
              // {label: 'Pending', value: 'draft'},
              // {label: 'Suspended', value: 'suspended'},
              // {label: 'Cancelled', value: 'cancelled'},
              // {label: 'Completed', value: 'completed'},
              // {label: 'Entered In Error', value: 'entered-in-error'},
              // {label: ' Unknown', value: 'unknown'},
              // ],
              // valueField: void 0,
              // keyField: 'label',
              // editable: editable,
              // width: parentWidth,
              // }),
              // autoSuggestOptionsInput({
              // // width: '45.3%',
              // label: 'Intent',
              // field: 'intent',
              // suggestionField: 'label',
              // // valueField: 'value',
              // options: [
              // {label: 'Proposal', value: 'proposal'},
              // {label: 'Order', value: 'order'},
              // {label: 'Option', value: 'option'},
              // {label: 'Plan', value: 'plan'},
              // ],
              // valueField: void 0,
              // keyField: 'label',
              // editable: editable,
              // width: parentWidth,
              // }),

              // {
              //   // width: '48.3%',
              //   type: 'text',
              //   label: 'Program Admin',
              //   field: 'programAdmin',
              //   placeholder: 'Enter program admin name',
              //   editable: editable,
              //   width: parentWidth,
              //   mandatory,
              // },
              searchInput(
                {
                  label: 'Program Admin',
                  placeholder: 'Choose Program Admin',
                  field: 'programAdmin',
                  query: 'userLists',
                  idField: '_id',
                  model: 'User',
                  valueField: 'name',
                  suggestionField: 'name',
                  searchField: 'name',
                  editable: editable,
                  width: parentWidth,
                },
                {
                  addOnFilter: {userType: 'Doctor'},
                },
              ),
              multiSearchInput({
                // width: '45.3%',
                label: 'Medical Condition',
                placeholder: 'Add medical history here..',
                field: 'chronicDisease',
                query: 'diagnoseLists',
                idField: '_id',
                model: 'Diagnosis',
                valueField: 'name',
                suggestionField: 'name',
                searchField: 'name',
                editable: editable,
                width: parentWidth,
                mandatory,
              }),
            ],
          },
        ],
      },
      {
        label: 'Monitoring Parameter',
        expandable: true,
        visible: checkIsNotSurgery,
        groups: [
          {
            columns: [
              {
                type: 'checkBox',
                field: 'isManualReading',
                label: 'Enable Manual Reading',
                editable: false,
              },
              {
                type: 'nestedList',
                field: 'monitoringParameter',
                addInBottom: true,
                footer: {
                  addLabel: editable ? 'Add Monitoring Parameter' : void 0,
                },
                formGroupHeaderStyle: {marginLeft: 10, fontWeight: 'bold'},
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          autoSuggestInput(
                            {
                              label: 'Parameter',
                              field: 'parameter',
                              placeholder: 'Select Parameter...',
                              query: 'parameterList',
                              model: 'MonitoringParameter',
                              valueField: 'name',
                              suggestionField: 'name',
                              searchField: 'name',
                              idField: '_id',
                              editable: editable,
                            },
                            {
                              addOnFilter: ({data}) => {
                                const {
                                  _parent: {monitoringParameter = []} = {},
                                } = data || {};
                                let parameter = monitoringParameter.map(
                                  (parameterItem) => {
                                    const id = get(
                                      parameterItem,
                                      'parameter._id',
                                      '',
                                    );
                                    if (id) {
                                      return id;
                                    }
                                  },
                                );
                                const parameterIds = compact(parameter);
                                return {
                                  _id: {$nin: parameterIds},
                                };
                              },
                            },
                          ),
                          {
                            width: 35,
                            type: 'custom',
                            render: () => {
                              return [];
                            },
                          },
                          {
                            type: 'custom',
                            render: () => {
                              return [];
                            },
                            visible: ({data}) =>
                              !!data?._parent?.isManualReading,
                          },
                          multiAutoSuggestInput(
                            {
                              label: 'Add Device',
                              field: 'device',
                              placeholder: 'Select Device...',
                              query: 'devicesList',
                              model: 'Devices',
                              valueField: 'name',
                              suggestionField: 'name',
                              searchField: 'name',
                              avatarIcon: 'image.file',
                              showAvatar: true,
                              idField: '_id',
                              editable: editable,
                              visible: ({data}) =>
                                !data?._parent?.isManualReading,
                            },
                            {
                              addOnFilter: ({data}) => {
                                let {parameter: {_id = ''} = {}} = data || {};

                                return {
                                  monitoringParameter: {
                                    parameter: {_id} || '',
                                  },
                                };
                              },
                            },
                          ),

                          // {
                          //   type: 'number',
                          //   label: 'Lower Threshold',
                          //   field: 'lowerThreshold',
                          //   editable: editable,
                          //   visible: ({data}) => visibleRange({data}),
                          // },
                          // {
                          //   width: 35,
                          //   type: 'custom',
                          //   render: () => {
                          //     return [];
                          //   },
                          //   visible: ({data}) => visibleRange({data}),
                          // },
                          // {
                          //   type: 'number',
                          //   label: 'Higher Threshold',
                          //   field: 'higherThreshold',
                          //   visible: ({data}) => visibleRange({data}),

                          //   editable: editable,
                          // },

                          /////
                          // {
                          //   type: 'number',
                          //   label: 'Systolic Lower Threshold',
                          //   field: 'systolicLowerThreshold',
                          //   editable: editable,
                          //   visible: ({data}) =>
                          //     visibleBloodPressureField({data}),
                          // },
                          // {
                          //   width: 35,
                          //   type: 'custom',
                          //   render: () => {
                          //     return [];
                          //   },
                          //   visible: ({data}) =>
                          //     visibleBloodPressureField({data}),
                          // },
                          // {
                          //   type: 'number',
                          //   label: 'Systolic Higher Threshold',
                          //   field: 'systolicHigherThreshold',
                          //   visible: ({data}) =>
                          //     visibleBloodPressureField({data}),
                          //   editable: editable,
                          // },
                          // {
                          //   type: 'number',
                          //   label: 'Diastolic Lower Threshold',
                          //   field: 'diastolicLowerThreshold',
                          //   editable: editable,
                          //   visible: ({data}) =>
                          //     visibleBloodPressureField({data}),
                          // },
                          // {
                          //   width: 35,
                          //   type: 'custom',
                          //   render: () => {
                          //     return [];
                          //   },
                          //   visible: ({data}) =>
                          //     visibleBloodPressureField({data}),
                          // },
                          // {
                          //   type: 'number',
                          //   label: 'Diastolic Higher Threshold',
                          //   field: 'diastolicHigherThreshold',
                          //   visible: ({data}) =>
                          //     visibleBloodPressureField({data}),
                          //   editable: editable,
                          // },
                          // {
                          //   type: 'number',
                          //   label: 'Rate Of Change',
                          //   field: 'rateOfChange',
                          //   visible: ({data}) => {
                          //     return data?.parameter?.type === 'rate of change';
                          //   },
                          //   editable: editable,
                          // },
                          // {
                          //   width: 35,
                          //   type: 'custom',
                          //   render: () => {
                          //     return [];
                          //   },
                          //   visible: ({data}) => {
                          //     return data?.parameter?.type === 'rate of change';
                          //   },
                          // },

                          searchInput(
                            {
                              label: 'Unit Measurement',
                              field: 'unitMeasurement',
                              placeholder: 'Select Unit',
                              query: 'unitMeasurementList',
                              model: 'UnitMeasurements',
                              valueField: 'name',
                              suggestionField: 'name',
                              searchField: 'name',
                              editable: editable,
                            },
                            {
                              addOnFilter: ({data}) => {
                                let {
                                  parameter: {
                                    unitMeasurement: {_id},
                                  },
                                } = data;
                                return {
                                  _id,
                                };
                              },
                            },
                          ),
                          // {
                          // width: 35,
                          // type: 'custom',
                          // render: () => {
                          // return [];
                          // },
                          // visible: ({data}) => {
                          // return data?.parameter?.type !== 'rate of change';
                          // },
                          // },
                          searchInput({
                            label: 'Check Interval',
                            field: 'checkInterval',
                            placeholder: 'Select Unit',
                            query: 'checkIntervalList',
                            model: 'CheckIntervals',
                            valueField: 'name',
                            suggestionField: 'name',
                            searchField: 'name',
                            editable: editable,
                          }),
                          {
                            width: 35,
                            type: 'custom',
                            render: () => {
                              return [];
                            },
                            // visible: ({data}) => {
                            // return data?.parameter?.type === 'rate of change';
                            // },
                          },
                          {
                            // width: 35,
                            type: 'custom',
                            render: () => {
                              return [];
                            },
                            // visible: ({data}) => {
                            // return data?.parameter?.type === 'rate of change';
                            // },
                          },

                          // {
                          // type: 'custom',
                          // render: () => {
                          // return [];
                          // },
                          // visible: ({data}) => {
                          // return data?.parameter?.type === 'rate of change';
                          // },
                          // },
                          {
                            type: 'custom',
                            width: '100%',
                            render: () => (
                              <View
                                style={{
                                  borderBottomWidth: 2,
                                  borderBottomColor: '#f2eeed',
                                  height: 20,
                                }}
                              />
                            ),
                          },
                        ],
                      },
                      {
                        width: 20,
                        columns: [
                          {
                            type: 'custom',
                            render: (props) => {
                              const {data: {_parent = {}} = {}} = props;
                              const {
                                algorithmTypes = null,
                                monitoringParameter = null,
                              } = _parent;
                              let cross = true;
                              if (
                                !(
                                  Array.isArray(algorithmTypes) &&
                                  algorithmTypes.length
                                )
                              ) {
                                cross = false;
                              }
                              if (
                                Array.isArray(monitoringParameter) &&
                                monitoringParameter.length > 1
                              ) {
                                cross = true;
                              }
                              if (!cross || !editable) {
                                return <View />;
                              }
                              return closeButton(props);
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'Algorithm',
        expandable: true,
        visible: () => false,
        // expanded: true,
        groups: [
          {
            columns: [
              {
                type: 'nestedList',
                field: 'algorithmTypes',
                addInBottom: true,
                footer: {
                  addLabel: editable ? 'Add Algorithm' : void 0,
                },
                formGroupHeaderStyle: {marginLeft: 10, fontWeight: 'bold'},
                // label: 'Algorithm',

                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          autoSuggestOptionsInput({
                            label: 'Algorithm Type',
                            field: 'type',
                            suggestionField: 'label',
                            valueField: 'value',
                            options: [
                              {
                                label: 'Check in Symptoms',
                                value: 'CheckInSymptoms',
                              },
                              {label: 'Dialysis', value: 'Dialysis'},
                              // {label: 'Education', value: 'Education'},
                            ],
                            valueField: void 0,
                            keyField: 'label',
                            editable: editable,
                          }),
                          searchInput({
                            label: 'Algorithm Categories',
                            placeholder: 'Add Algorithm Categories here..',
                            field: 'algorithmCategory',
                            query: 'AlgorithmCategoryList',
                            idField: '_id',
                            model: 'AlgorithmCategories',
                            valueField: 'title',
                            suggestionField: 'title',
                            searchField: 'title',
                            editable: editable,
                          }),
                          searchInput({
                            label: 'Algorithm Name',
                            placeholder: 'Add Algorithm Name',
                            field: 'algorithmIds',
                            idField: 'id',
                            valueField: 'name',
                            suggestionField: 'name',
                            searchField: 'name',
                            keyField: 'id',
                            editable: editable,
                            fetch: ({data}) => {
                              const {algorithmCategory: {title = ''} = {}} =
                                data || {};
                              if (title) {
                                let category = [title];
                                return axios
                                  .post(mayaEngine.newApiUrl, {
                                    apiKey: mayaEngine.apiKey,
                                    apiSecret: mayaEngine.apiSecret,
                                    requestType: 'getAlgorithmicSymptom',
                                    category,
                                  })
                                  .then((response) => {
                                    let {
                                      data: {result},
                                    } = response;
                                    return {data: result};
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              } else {
                                return {data: []};
                              }
                            },
                          }),
                          horizontalLine,
                        ],
                      },
                      {
                        width: 20,
                        columns: [
                          {
                            type: 'custom',
                            render: (props) => {
                              const {data: {_parent = {}} = {}} = props;
                              const {
                                algorithmTypes = null,
                                monitoringParameter = null,
                              } = _parent;
                              let cross = true;
                              if (
                                !(
                                  Array.isArray(monitoringParameter) &&
                                  monitoringParameter.length
                                )
                              ) {
                                cross = false;
                              }
                              if (
                                Array.isArray(algorithmTypes) &&
                                algorithmTypes.length > 1
                              ) {
                                cross = true;
                              }
                              if (!cross || !editable) {
                                return <View />;
                              }
                              return closeButton(props);
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'To-Do Task',
        expandable: true,
        groups: [
          {
            columnsPerRow: 3,
            columns: [
              multiSearchInput({
                label: 'To-Do Task Category',
                field: 'selectedTodo',
                placeholder: 'Select To-Do Task Category',
                query: 'todoCategoryList',
                model: 'TodoCategory',
                idField: '_id',
                valueField: 'name',
                suggestionField: 'name',
                searchField: 'name',
                editable,
              }),
              {
                width: 35,
                type: 'custom',
                render: () => {
                  return [];
                },
              },
            ],
          },
          {
            columns: [
              {
                type: 'nestedList',
                field: 'programTodos',
                addInBottom: true,
                // footer: {
                // addLabel: editable ? 'Add Todo' : void 0,
                // },
                formGroupHeaderStyle: {marginLeft: 10, fontWeight: 'bold'},
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    // direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          {
                            type: 'checkBox',
                            field: 'enableTodo',
                            label: 'Enable',
                            enableLabel: true,
                            editable: editable,
                            width: '70px',
                          },
                          // {
                          // type: 'custom',
                          // render: ({data}) => {
                          // return (
                          // <TextInput
                          // variant="filled"
                          // value={data?.todoId?.title || ''}
                          // editable={false}
                          // />
                          // );
                          // },
                          // },
                          {
                            type: 'text',
                            editable: false,
                            field: 'todoId.title',
                            width: '200px',
                          },
                          searchInput({
                            label: 'Frequency',
                            field: 'frequency',
                            placeholder: 'Select Interval',
                            query: 'checkIntervalList',
                            model: 'CheckIntervals',
                            valueField: 'name',
                            suggestionField: 'name',
                            searchField: 'name',
                            editable: editable,
                            visible: ({data}) => {
                              return !!data?.enableTodo;
                            },
                          }),
                          searchInput(
                            {
                              label: 'Algorithm Categories',
                              placeholder: 'Add Algorithm Categories here..',
                              field: 'algorithmCategory',
                              query: 'AlgorithmCategoryList',
                              idField: '_id',
                              model: 'AlgorithmCategories',
                              valueField: 'name',
                              suggestionField: 'name',
                              searchField: 'name',
                              editable: editable,
                              visible: ({data}) =>
                                !!data?.todoId?.enable_algorithm &&
                                !!data?.enableTodo,
                            },
                            {
                              modifyResult: modifyAlgorithmCategory,
                            },
                          ),
                          // {
                          // // width: 35,
                          // type: 'custom',
                          // render: () => {
                          // return [];
                          // },
                          // visible: ({data}) => !!data?.enable_algorithm,
                          // },
                          mayaAlog,
                          // {
                          // type: 'checkBox',
                          // field: 'enableTodo',
                          // label: 'Enable Todo',
                          // enableLabel: true,
                          // },
                        ],
                      },
                      {
                        columns: [horizontalLine],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      // {
      //   label: 'Education',
      //   expandable: true,
      //   visible: checkIsNotSurgery,
      //   groups: [
      //     {
      //       columnsPerRow: 2,
      //       columns: [
      //         multiSearchInput({
      //           label: 'Education Category',
      //           field: 'educationCategory',
      //           placeholder: 'Select Education Category',
      //           query: 'EducationCategoryList',
      //           model: 'EducationCategory',
      //           idField: '_id',
      //           valueField: 'name',
      //           suggestionField: 'name',
      //           searchField: 'name',
      //           editable,
      //         }),
      //       ],
      //     },
      //   ],
      // },
      // {
      //   label: 'Education Surgery',
      //   expandable: true,
      //   visible: ({data}) => data?.isSurgery,
      //   groups: [
      //     {
      //       columnsPerRow: 2,
      //       columns: [
      //         multiSearchInput({
      //           label: 'Default Education',
      //           field: 'defaultEducation',
      //           placeholder: 'Select Education Category',
      //           query: 'EducationCategoryList',
      //           model: 'EducationCategory',
      //           idField: '_id',
      //           valueField: 'name',
      //           suggestionField: 'name',
      //           searchField: 'name',
      //           editable,
      //         }),
      //         multiSearchInput({
      //           label: 'End Program Education',
      //           field: 'endProgramEducation',
      //           placeholder: 'Select Education Category',
      //           query: 'EducationCategoryList',
      //           model: 'EducationCategory',
      //           idField: '_id',
      //           valueField: 'name',
      //           suggestionField: 'name',
      //           searchField: 'name',
      //           editable,
      //         }),
      //         multiSearchInput({
      //           label: 'Pre Operation Education',
      //           field: 'preOpEducation',
      //           placeholder: 'Select Education Category',
      //           query: 'EducationCategoryList',
      //           model: 'EducationCategory',
      //           idField: '_id',
      //           valueField: 'name',
      //           suggestionField: 'name',
      //           searchField: 'name',
      //           editable,
      //         }),
      //         multiSearchInput({
      //           label: 'Post Operation Education',
      //           field: 'postOpEducation',
      //           placeholder: 'Select Education Category',
      //           query: 'EducationCategoryList',
      //           model: 'EducationCategory',
      //           idField: '_id',
      //           valueField: 'name',
      //           suggestionField: 'name',
      //           searchField: 'name',
      //           editable,
      //         }),
      //       ],
      //     },
      //   ],
      // },
      {
        label: 'Configure Options',
        expandable: true,
        groups: [
          {
            columnsPerRow: 4,
            columns: [
              // {
              // type: 'checkBox',
              // field: 'enableCheckInSymptoms',
              // label: 'Check in Symptoms',
              // editable: editable,
              // },
              // {
              // type: 'text',
              // field: 'tileLabel',
              // label: 'CKD Stage Tile Label',
              // editable: editable,
              // },
              {
                type: 'checkBox',
                field: 'enableChat',
                label: 'Enable Chat',
                editable: editable,
              },
              // {
              //   type: 'checkBox',
              //   field: 'enableAppointment',
              //   editable: editable,
              //   label: 'Enable Telehealth Appointment',
              // },
              // {
              //   type: 'checkBox',
              //   field: 'enableDialysis',
              //   label: 'Enable Dialysis',
              //   editable: editable,
              // },
              // {
              // type: 'checkBox',
              // field: 'enableSymptomChecker',
              // label: 'Enable Symptom Checker',
              // editable: editable,
              // },
              // {
              //   type: 'checkBox',
              //   field: 'enableRpmInterface',
              //   label: 'Enable RPM',
              //   editable: editable,
              // },
              {
                type: 'checkBox',
                field: 'enableMedicines',
                label: 'Enable Medicines',
                editable: editable,
              },
              // {
              //   type: 'checkBox',
              //   field: 'enableEducation',
              //   label: 'Enable Education',
              //   editable: editable,
              // },
              // {
              //   type: 'checkBox',
              //   field: 'enableTransplant',
              //   label: 'Enable Transplant',
              //   editable: editable,
              // },
              // {
              // type: 'checkBox',
              // field: 'enableNutrition',
              // label: 'Enable Nutrition',
              // editable: editable,
              // },
            ],
          },
        ],
      },
    ],
  });
};
