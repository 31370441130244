import careProgramForm from './careProgramForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Care Program Speciality',
  actions: [cancelButton(), save()],
};

let uri = ({navigation}) => {
  return {
    query: {
      id: 'careProgramSpecialityList',
      relationValue: {
        model: 'CareProgramSpeciality',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'CareProgramSpeciality',
  };
};
export default careProgramForm({uri, header});
