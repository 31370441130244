import React from 'react';
import moment from 'moment';
import {
  TouchableOpacity,
  View,
  Text,
  Image,
  WithModalButton,
} from '../../app-components';
import {getUser, fetch} from '../../AppServices';
import Modal from './CustomHeaderPopup';
import {manazeV5Colors} from '../../theme/manazeV5Colors';
import {manazeV6Colors} from '../../theme/manazeV6Colors';
import {Headline7, Headline9, Headline11} from '../../theme/Headlines';

import {DateToggle} from '../../app-components/ToggleComponents';
import {LessThan, MoreThan} from '../../images';
const {
  snow2 = '#f9f9f9', // no replacement
  secondary_050,
  secondary_400,
  NegativeAction_050,
  neutral_400_low_background,
  NegativeAction_300,
} = manazeV5Colors;
const {primary_5, primary_2} = manazeV6Colors;
let positionFromTop = 0;
class DateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Range: '', // visible part of Day & Date filter at the browser
      Year: [], //previousFinancialYear, CurrentFinancialYear , ThisYear
      Month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      Week: [], // ... , LastWeek , ThisWeek , NextWeek , ...
      Day: '', // calculate the day like in range of previous , this Day and next Day
      filterOf: '', // which filter is applicable like Day Month Year Week,
      RangeWithYear: '',
    };
  }

  applyFilter = (data) => {
    let {value} = data || {};
    let from = '';
    let to = '';

    if (value == 'Day') {
      from = new Date(this.state.Day);
      to = new Date(this.state.Day);
      new Date(from.setHours(0, 0, 0, -1));
      new Date(to.setDate(to.getDate() + 1));
      new Date(to.setHours(0, 0, 0, -1));
      Date(from.setSeconds(from.getSeconds() + 1));
    } else if (value == 'Week') {
      from = new Date(this.state.Week[0]);
      to = new Date(this.state.Week[1]);
      new Date(from.setHours(0, 0, 0, -1));
      new Date(to.setDate(to.getDate() + 1));
      new Date(to.setHours(0, 0, 0, -1));
      Date(from.setSeconds(from.getSeconds() + 1));
    } else if (value == 'Month') {
      let MonthIndex = this.state.Month.indexOf(this.state.Range);
      from = new Date();
      to = new Date();
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      from = new Date(from.setMonth(MonthIndex));
      to = new Date(from.setMonth(MonthIndex));
      to = new Date(to.setMonth(MonthIndex + 1));
      new Date(to.setSeconds(to.getSeconds() - 1));
    } else if (value == 'Year') {
      let Range = this.state.Range || '';
      from = new Date();
      to = new Date();
      if (Range.toString().indexOf('-') >= 0) {
        from = new Date(`01 ${Range.slice(0, Range.indexOf('-')).trim()}`);
        to = new Date(`31 ${Range.slice(Range.indexOf('-') + 1).trim()}`);
        to = new Date(to.setDate(to.getDate() + 1));
        to = new Date(to.setHours(0, 0, 0, -1));
      } else {
        from = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);
        to = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
        new Date(to.setDate(to.getDate() + 1));
        new Date(to.setSeconds(to.getSeconds() - 1));
      }
    } else if (value == 'Custom') {
      let currentYear = new Date().getFullYear().toString();
      let range = this.state.RangeWithYear;
      let tempfrom = range.split('-')[0].trim();
      let tempto = range.split('-')[1].trim();
      from = new Date(tempfrom);
      // from = new Date(from.setFullYear(currentYear));
      to = new Date(tempto);
      // to = new Date(to.setFullYear(currentYear));
      to = new Date(to.setDate(to.getDate() + 1));
      to = new Date(to.setHours(0, 0, 0, -1));
    }

    let {
      screenState: {dataParams} = {},
      setScreenState,
      onFilterChange = null,
    } = this.props;
    let {filters} = dataParams || {};
    let filterValue = {from: from, to: to, filterOf: value};
    let period = {
      value: filterValue,
      filter: {
        asParams: true,
        value: filterValue,
      },
    };

    if (data && data.removeFilter) {
      period = void 0;
    }

    filters = {
      ...filters,
      period,
    };

    if (onFilterChange && typeof onFilterChange === 'function') {
      onFilterChange(period);
    }

    setScreenState &&
      setScreenState({
        dataParams: {
          ...dataParams,
          filters,
        },
      });
  };

  DateFilterFunction = (event) => {
    if (event == 'No Filter') {
      this.setState(
        {
          Range: 'No Filter',
          filterOf: 'No Filter',
        },
        () => {
          let data = {
            filterOf: this.state.filterOf,
            filterRange: this.state.Range,
            value: this.state.filterOf,
            removeFilter: true,
          };
          this.applyFilter(data);
        },
      );
    } else {
      let month = new Date().getMonth();
      let completeDate1 = new Date();
      let completeDate = completeDate1.toString();
      completeDate = completeDate.slice(0, 15);
      let dateFromCompleteDate = completeDate.slice(8, 10);
      let monthFromCompleteDate = completeDate.slice(4, 7);
      let yearFromCompleteDate = completeDate.slice(13, 15);
      let dayFromCompleteDate = completeDate.slice(0, 3);
      let temporaryDayOfToday = new Date().getDay();
      let startWeekFromDate = new Date(
        new Date().setDate(new Date().getDate() - temporaryDayOfToday),
      );
      let endWeekToDate = new Date(
        new Date().setDate(new Date().getDate() + (6 - temporaryDayOfToday)),
      );
      let tempStartWeek = startWeekFromDate.toString();
      tempStartWeek = tempStartWeek.slice(4, 15);
      tempStartWeek = `${tempStartWeek.slice(4, 6)} ${tempStartWeek.slice(
        0,
        3,
      )}`;
      let tempStartWeekWithYear = `${tempStartWeek.slice(
        4,
        6,
      )} ${tempStartWeek.slice(0, 3)},${tempStartWeek.slice(9)}`;
      let tempEndWeek = endWeekToDate.toString();
      tempEndWeek = tempEndWeek.slice(4, 15);
      tempEndWeek = `${tempEndWeek.slice(4, 6)} ${tempEndWeek.slice(0, 3)}`;
      let tempEndWeekWithYear = `${tempEndWeek.slice(4, 6)} ${tempEndWeek.slice(
        0,
        3,
      )},${tempEndWeek.slice(9)}`;
      let weeksFiltersIs = tempStartWeek + ' - ' + tempEndWeek;
      let weekfilterWithYear =
        tempStartWeekWithYear + ' - ' + tempEndWeekWithYear;
      let completeDayIs = `${dateFromCompleteDate} ${monthFromCompleteDate},${dayFromCompleteDate}`;
      let completeDayWithYear = `${dateFromCompleteDate} ${monthFromCompleteDate},${yearFromCompleteDate}`;
      let filterIs;
      let filterWithYear;
      this.setState({
        Week: [startWeekFromDate, endWeekToDate],
        Day: completeDate1,
      });
      if (event == 'Day') {
        filterIs = completeDayIs;
        filterWithYear = completeDayWithYear;
      }
      if (event == 'Week') {
        filterIs = weeksFiltersIs;
        filterWithYear = weekfilterWithYear;
      }
      if (event == 'Month') {
        filterIs = this.state.Month[month];
      }
      if (event == 'Year') {
        filterIs = this.state.Year[1];
      }
      if (event == 'Custom') {
        filterIs = this.state.Range;
        filterWithYear = this.state.RangeWithYear;
      }
      this.setState(
        {
          Range: filterIs,
          RangeWithYear: filterWithYear,
          filterOf: event,
        },
        () => {
          let data = {
            filterOf: this.state.filterOf,
            filterRange: this.state.RangeWithYear,
            value: this.state.filterOf,
          };
          this.applyFilter(data);
        },
      );
    }
  };
  PrevFunction = () => {
    if (this.state.filterOf == 'Day') {
      let completeDate1 = this.state.Day;
      completeDate1 = new Date(
        completeDate1.setDate(completeDate1.getDate() - 1),
      );
      let completeDate = completeDate1.toString();
      completeDate = completeDate.slice(0, 15);
      let dateFromCompleteDate = completeDate.slice(8, 10);
      let monthFromCompleteDate = completeDate.slice(4, 7);
      let yearFromCompleteDate = completeDate.slice(13, 15);
      let dayFromCompleteDate = completeDate.slice(0, 3);
      let completeDayIs = `${dateFromCompleteDate} ${monthFromCompleteDate},${dayFromCompleteDate}`;
      let completeDayWithYear = `${dateFromCompleteDate} ${monthFromCompleteDate},${yearFromCompleteDate}`;

      this.setState(
        {
          Day: completeDate1,
          Range: completeDayIs,
          RangeWithYear: completeDayWithYear,
        },
        () => {
          let data = {
            filterOf: this.state.filterOf,
            filterRange: this.state.RangeWithYear,
            value: this.state.filterOf,
          };
          this.applyFilter(data);
        },
      );
    }
    if (this.state.filterOf == 'Week') {
      let [startWeek, endWeek] = this.state.Week || [];
      let startWeekFromDate = new Date(
        startWeek.setDate(startWeek.getDate() - 7),
      );
      let endWeekToDate = new Date(endWeek.setDate(endWeek.getDate() - 7));
      let tempStartWeek = startWeekFromDate.toString();
      tempStartWeek = tempStartWeek.slice(4, 15);
      tempStartWeek = `${tempStartWeek.slice(4, 6)} ${tempStartWeek.slice(
        0,
        3,
      )}`;
      let tempStartWeekWithYear = `${tempStartWeek.slice(
        4,
        6,
      )} ${tempStartWeek.slice(0, 3)},${tempStartWeek.slice(9)}`;
      let tempEndWeek = endWeekToDate.toString();
      tempEndWeek = tempEndWeek.slice(4, 15);
      tempEndWeek = `${tempEndWeek.slice(4, 6)} ${tempEndWeek.slice(0, 3)}`;
      let tempEndWeekWithYear = `${tempEndWeek.slice(4, 6)} ${tempEndWeek.slice(
        0,
        3,
      )},${tempEndWeek.slice(9)}`;
      let weeksFiltersIs = tempStartWeek + ' - ' + tempEndWeek;
      let tempWeekWithYear =
        tempStartWeekWithYear + ' - ' + tempEndWeekWithYear;
      this.setState(
        {
          Range: weeksFiltersIs,
          RangeWithYear: tempWeekWithYear,
          Week: [startWeekFromDate, endWeekToDate],
        },
        () => {
          let data = {
            filterOf: this.state.filterOf,
            filterRange: this.state.RangeWithYear,
            value: this.state.filterOf,
          };
          this.applyFilter(data);
        },
      );
    }
    if (this.state.filterOf == 'Month') {
      let indexOfMonth = this.state.Month.indexOf(this.state.Range);
      if (indexOfMonth == 0) {
        indexOfMonth = 12;
      }
      this.setState(
        {
          Range: this.state.Month[indexOfMonth - 1],
        },
        () => {
          let data = {
            filterOf: this.state.filterOf,
            filterRange: this.state.Range,
            value: this.state.filterOf,
          };
          this.applyFilter(data);
        },
      );
    }
    if (this.state.filterOf == 'Year') {
      let indexOfYear = this.state.Year.indexOf(this.state.Range);
      if (indexOfYear != 0) {
        this.setState(
          {
            Range: this.state.Year[indexOfYear - 1],
          },
          () => {
            let data = {
              filterOf: this.state.filterOf,
              filterRange: this.state.Range,
              value: this.state.filterOf,
            };
            this.applyFilter(data);
          },
        );
      }
    }
  };
  onApply = (event) => {
    if (event && (event.from || event.to)) {
      let {from, to} = event || {};
      let fromWithoutYear = from.slice(0, from.length - 2).trim();
      let toWithoutYear = to.slice(0, to.length - 2).trim();
      this.setState(
        (prevState) => ({
          Range: `${fromWithoutYear} - ${toWithoutYear}`,
          RangeWithYear: `${from} - ${to}`,
          filterOf: 'Custom',
        }),
        () => {
          this.DateFilterFunction(this.state.filterOf);
        },
      );
    }
  };
  NextFunction = () => {
    if (this.state.filterOf == 'Day') {
      let completeDate1 = this.state.Day;
      completeDate1 = new Date(
        completeDate1.setDate(completeDate1.getDate() + 1),
      );
      let completeDate = completeDate1.toString();
      completeDate = completeDate.slice(0, 15);
      let dateFromCompleteDate = completeDate.slice(8, 10);
      let monthFromCompleteDate = completeDate.slice(4, 7);
      let yearFromCompleteDate = completeDate.slice(13, 15);
      let dayFromCompleteDate = completeDate.slice(0, 3);
      let completeDayIs = `${dateFromCompleteDate} ${monthFromCompleteDate},${dayFromCompleteDate}`;
      let completeDayWithYear = `${dateFromCompleteDate} ${monthFromCompleteDate},${yearFromCompleteDate}`;

      this.setState(
        {
          Day: completeDate1,
          Range: completeDayIs,
          RangeWithYear: completeDayWithYear,
        },
        () => {
          let data = {
            filterOf: this.state.filterOf,
            filterRange: this.state.RangeWithYear,
            value: this.state.filterOf,
          };
          this.applyFilter(data);
        },
      );
    }
    if (this.state.filterOf == 'Week') {
      let [startWeek, endWeek] = this.state.Week || [];
      let startWeekFromDate = new Date(
        startWeek.setDate(startWeek.getDate() + 7),
      );
      let endWeekToDate = new Date(endWeek.setDate(endWeek.getDate() + 7));
      let tempStartWeek = startWeekFromDate.toString();
      tempStartWeek = tempStartWeek.slice(4, 15);
      tempStartWeek = `${tempStartWeek.slice(4, 6)} ${tempStartWeek.slice(
        0,
        3,
      )}`;
      let tempStartWeekWithYear = `${tempStartWeek.slice(
        4,
        6,
      )} ${tempStartWeek.slice(0, 3)},${tempStartWeek.slice(9)}`;
      let tempEndWeek = endWeekToDate.toString();
      tempEndWeek = tempEndWeek.slice(4, 15);
      tempEndWeek = `${tempEndWeek.slice(4, 6)} ${tempEndWeek.slice(0, 3)}`;
      let tempEndWeekWithYear = `${tempEndWeek.slice(4, 6)} ${tempEndWeek.slice(
        0,
        3,
      )},${tempEndWeek.slice(9)}`;
      let weeksFiltersIs = tempStartWeek + ' - ' + tempEndWeek;
      let WeekFilterWithYear =
        tempStartWeekWithYear + ' - ' + tempEndWeekWithYear;
      this.setState(
        {
          Range: weeksFiltersIs,
          RangeWithYear: WeekFilterWithYear,
          Week: [startWeekFromDate, endWeekToDate],
        },
        () => {
          let data = {
            filterOf: this.state.filterOf,
            filterRange: this.state.RangeWithYear,
            value: this.state.filterOf,
          };
          this.applyFilter(data);
        },
      );
    }
    if (this.state.filterOf == 'Month') {
      let indexOfMonth = this.state.Month.indexOf(this.state.Range);
      if (indexOfMonth == 11) {
        indexOfMonth = -1;
      }
      this.setState(
        {
          Range: this.state.Month[indexOfMonth + 1],
        },
        () => {
          let data = {
            filterOf: this.state.filterOf,
            filterRange: this.state.Range,
            value: this.state.filterOf,
          };
          this.applyFilter(data);
        },
      );
    }
    if (this.state.filterOf == 'Year') {
      let indexOfYear = this.state.Year.indexOf(this.state.Range);
      if (indexOfYear != 2) {
        this.setState(
          {
            Range: this.state.Year[indexOfYear + 1],
          },
          () => {
            let data = {
              filterOf: this.state.filterOf,
              filterRange: this.state.Range,
              value: this.state.filterOf,
            };
            this.applyFilter(data);
          },
        );
      }
    }
  };
  PrevNextFunctionOfDate = (event) => {
    if (event == 'Prev') {
      this.PrevFunction();
    } else if (event == 'Next') {
      this.NextFunction();
    }
  };
  componentDidMount = () => {
    let {defaultFilter} = this.props || {};
    let completeDate1 = new Date();
    let completeDate = completeDate1.toString();
    completeDate = completeDate.slice(0, 15);
    let dateFromCompleteDate = completeDate.slice(8, 10);
    let monthFromCompleteDate = completeDate.slice(4, 7);
    let yearFromCompleteDate = completeDate.slice(13, 15);
    let dayFromCompleteDate = completeDate.slice(0, 3);
    let completeDayIs = `${dateFromCompleteDate} ${monthFromCompleteDate},${dayFromCompleteDate}`;
    let completeDayWithYear = `${dateFromCompleteDate} ${monthFromCompleteDate},${yearFromCompleteDate}`;

    let year = new Date().getFullYear();
    year = year.toString();
    year = Number(year.slice(2));
    let setYear = [];
    let month = new Date().getMonth();
    if (month > 2) {
      // month is greater than march
      setYear[0] = 'Apr,' + (year - 1) + ' - ' + 'Mar,' + year;
      setYear[1] = 'Apr,' + year + ' - ' + 'Mar,' + (year + 1);
      setYear[2] = new Date().getFullYear();
    } else if (month <= 2) {
      setYear[0] = 'Apr,' + (year - 2) + ' - ' + 'Mar,' + (year - 1);
      setYear[1] = 'Apr,' + (year - 1) + ' - ' + 'Mar,' + year;
      setYear[2] = new Date().getFullYear();
    }
    this.setState(
      {
        Year: setYear,
        Day: completeDate1,
        Range: completeDayIs,
        RangeWithYear: completeDayWithYear,
        filterOf: 'Day',
      },
      () => {
        this.DateFilterFunction(defaultFilter ? defaultFilter : 'No Filter');
        // let data = {
        //   filterOf: this.state.filterOf,
        //   filterRange: this.state.RangeWithYear,
        //   value: this.state.filterOf
        // };
        // this.applyFilter(data);
      },
    );
  };

  LeastDateTimeSetter = (toDate) => {
    toDate = new Date(toDate.setDate(toDate.getDate() + 1));
    toDate = new Date(toDate.setHours(0, 0, 0, -1));
    return toDate;
  };
  render() {
    let {filterOf, Range, RangeWithYear} = this.state;
    let {defaultStyle, graphFilter = false} = this.props || {};
    let currentYear = new Date().getFullYear().toString().slice(2);
    let fromDate = '',
      toDate = '',
      dateForDay = new Date(),
      dateSelectorData = this?.props?.dateSelectorData || [
        {label: 'All', value: 'No Filter'},
        // {label: 'Day', value: 'Day'},
        {label: 'Week', value: 'Week'},
        {label: 'Month', value: 'Month'},
        {label: 'Year', value: 'Year'},
      ];
    if (filterOf == 'Day') {
      fromDate = new Date(RangeWithYear);
      toDate = this.LeastDateTimeSetter(new Date(RangeWithYear));
    } else if (filterOf == 'Week') {
      if (Number(Range.slice(0, 2)) > 25 && Range.slice(3, 6) == 'Dec') {
        fromDate = new Date(
          `${Range.slice(0, Range.lastIndexOf('-')).trim()} ${currentYear}`,
        );
        toDate = this.LeastDateTimeSetter(
          new Date(
            `${Range.slice(Range.lastIndexOf('-')).trim()} ${
              Number(currentYear) + 1
            }`,
          ),
        );
      } else {
        fromDate = new Date(
          `${Range.slice(0, Range.lastIndexOf('-')).trim()} ${currentYear}`,
        );
        toDate = this.LeastDateTimeSetter(
          new Date(
            `${Range.slice(Range.lastIndexOf('-')).trim()} ${currentYear}`,
          ),
        );
      }
    } else if (filterOf == 'Month') {
      let tempFrom = new Date();
      tempFrom = moment(tempFrom).format('YY');
      fromDate = new Date(`01 ${Range},${tempFrom}`);
      toDate = new Date(`01 ${Range},${tempFrom}`);
      toDate = new Date(toDate.setMonth(toDate.getMonth() + 1));
      toDate = this.LeastDateTimeSetter(
        new Date(toDate.setDate(toDate.getDate() - 1)),
      );
    } else if (filterOf == 'Year') {
      if (Range.toString().indexOf('-') >= 0) {
        fromDate = new Date(
          `01 ${Range.slice(0, Range.lastIndexOf('-')).trim()}`,
        );
        toDate = this.LeastDateTimeSetter(
          new Date(`31 ${Range.slice(Range.lastIndexOf('-') + 1).trim()}`),
        );
      } else {
        fromDate = new Date(`01 January,${Range}`);
        toDate = this.LeastDateTimeSetter(new Date(`31 December,${Range}`));
      }
    } else if (filterOf == 'Custom') {
      fromDate = new Date(
        RangeWithYear.slice(0, RangeWithYear.lastIndexOf('-')).trim(),
      );
      toDate = this.LeastDateTimeSetter(
        new Date(
          RangeWithYear.slice(RangeWithYear.lastIndexOf('-') + 1).trim(),
        ),
      );
    }
    const {showDateToggle = true} = this.props;
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        {showDateToggle ? (
          <DateToggle
            value={this.state.filterOf}
            options={dateSelectorData}
            onChangeValue={(value) => {
              this.DateFilterFunction(value);
            }}
          />
        ) : (
          void 0
        )}

        <View
          style={{
            // backgroundColor:
            //   (defaultStyle && defaultStyle.backgroundColor) || primary_5,
            width: 160,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: 16,
            height:
              defaultStyle && defaultStyle.height ? defaultStyle.height : 38,
            // borderRadius: 20,
            // border: (defaultStyle && defaultStyle.border) || 'none',
          }}>
          {filterOf && filterOf != 'No Filter' && (
            <TouchableOpacity
              onPress={() => this.PrevNextFunctionOfDate('Prev')}
              style={{paddingRight: 6, paddingLeft: 6, cursor: 'pointer'}}
              title={
                filterOf == 'Year'
                  ? 'Previous financial year'
                  : `Previous ${this.state.filterOf}`
              }>
              <Image
                style={{
                  ...Headline7,
                  // color: (defaultStyle && defaultStyle.color) || primary_2,
                  width: 10,
                  height: 15,
                }}
                source={LessThan}
              />
            </TouchableOpacity>
          )}
          <WithModalButton
            autoHide={true}
            style={{flex: 1, cursor: 'pointer'}}
            dropdownStyle={{height: 200, width: 200}}
            renderModal={({frameStyle, hideModal}) => {
              if (graphFilter) {
                return null;
              }
              return (
                <Modal
                  frameStyle={frameStyle}
                  hideModal={hideModal}
                  onApply={this.onApply}
                  item={this.state}
                  fromDate={fromDate}
                  toDate={toDate}
                  {...this.props}
                />
              );
            }}>
            <View>
              {filterOf == 'Day' && (
                <Text
                  style={{
                    ...Headline11,
                    textAlign: 'center',
                    // color: (defaultStyle && defaultStyle.color) || primary_2,
                    paddingLeft: 4,
                    paddingRight: 4,
                  }}>
                  {filterOf === 'Day' &&
                  Range.slice(0, 2) == dateForDay.getDate()
                    ? 'Today'
                    : Range.slice(0, 2) == dateForDay.getDate() - 1
                    ? 'Yesterday'
                    : Range.slice(0, 2) == dateForDay.getDate() + 1
                    ? 'Tomorrow'
                    : ''}
                </Text>
              )}
              <Text
                style={{
                  ...Headline9,
                  textAlign: 'center',
                  // color: (defaultStyle && defaultStyle.color) || primary_2,
                  paddingLeft: 4,
                  paddingRight: 4,
                }}
                title={Range}>
                {Range}
              </Text>
            </View>
          </WithModalButton>
          {filterOf && filterOf != 'No Filter' && (
            <TouchableOpacity
              style={{
                cursor: 'pointer',
                paddingRight: 6,
                paddingLeft: 6,
              }}
              onPress={() => this.PrevNextFunctionOfDate('Next')}
              title={filterOf == 'Year' ? 'This Year' : `Next ${filterOf}`}>
              <Image
                style={{
                  ...Headline7,
                  width: 12,
                  height: 15,
                }}
                source={MoreThan}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }
}

class DaysHeader extends React.Component {
  state = {
    Days: [],
  };
  color = {
    green: {
      color: secondary_400,
      bgs: secondary_050,
    },
    red: {
      color: NegativeAction_300,
      bgs: NegativeAction_050,
    },
    today: {
      color: neutral_400_low_background,
      bgs: snow2,
      dotColor: secondary_400,
    },
  };
  componentDidMount = (event) => {
    let Days = {};
    let todayDate = new Date();
    let fromDate = new Date();
    let toDate = new Date();
    let user = getUser();

    if (!user || !user._id) return null;

    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);

    fromDate.setDate(fromDate.getDate() - 4);

    let offDays = 0;
    for (
      let date = new Date(fromDate);
      date <= toDate;
      date.setDate(date.getDate() + 1)
    ) {
      let day = date.getDay();
      if (day === 0 || day === 6) {
        offDays++;
      }
    }

    fromDate.setDate(fromDate.getDate() - offDays);

    if (fromDate.getDay() === 6) fromDate.setDate(fromDate.getDate() - 1);
    if (fromDate.getDay() === 0) fromDate.setDate(fromDate.getDate() - 2);

    let dayMapping = {
      0: 'Saturday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Sunday',
    };

    for (
      let date = new Date(fromDate);
      date <= toDate;
      date.setDate(date.getDate() + 1)
    ) {
      let dateNumber = date.getDate();
      let day = date.getDay();
      if (day !== 0 && day !== 6) {
        Days[dateNumber] = {date: new Date(date), title: dayMapping[day]};
      }
    }

    fetch({
      uri: {
        props: {
          query: {
            id: 'progressesForSubQueries',
            group: {
              _id: {date: 1},
              aggregates: {_count: 1, effort: 1},
              first: {date: 1},
              sort: {
                date: -1,
              },
              _children: true,
            },
            addOnFilter: {
              resource: {_id: user._id},
              date: {
                $gte: fromDate,
                $lt: toDate,
              },
            },
          },
          model: 'GoalProgress',
        },
      },
    }).then((response) => {
      let {data} = response;
      if (data && data.length) {
        for (let element of data) {
          if (element && element.date) {
            let day = new Date(element.date).getDate();
            if (Days[day]) Days[day].effort = element.effort || 0;
          }
        }
      }
      Days = Object.values(Days);
      Days.sort((elem_one, elem_second) => {
        return elem_one.date - elem_second.date;
      });
      this.setState({Days});
    });
  };
  render() {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        {this.state &&
          this.state.Days &&
          this.state.Days.map((element, index) => {
            let {color, bgs: backgroundColor} = this.color.red;
            let {title = ''} = element;
            let text = title[0];
            if (9 <= element.effort) {
              color = this.color.green.color;
              backgroundColor = this.color.green.bgs;
            }
            if (index === this.state.Days.length - 1) {
              let {color, bgs: backgroundColor, dotColor} = this.color.today;
              return (
                <View
                  title={`Today: ${title}`}
                  style={{
                    marginRight: 7,
                    width: 20,
                    position: 'relative',
                    color,
                    backgroundColor,
                    borderRadius: 5,
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingLeft: 4,
                    paddingRight: 5,
                    textAlign: 'center',
                  }}>
                  <Text>{text}</Text>
                  <View
                    style={{
                      position: 'absolute',
                      right: 1,
                      bottom: 2,
                      width: 1,
                      height: 1,
                      borderWidth: 2,
                      borderColor: dotColor,
                      backgroundColor: dotColor,
                      borderRadius: '100px',
                    }}
                  />
                </View>
              );
            }
            return (
              <View
                title={title}
                style={{
                  marginRight: 7,
                  width: 20,
                  position: 'relative',
                  color,
                  backgroundColor,
                  borderRadius: 5,
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 4,
                  paddingRight: 5,
                  textAlign: 'center',
                }}>
                <Text>{text}</Text>
              </View>
            );
          })}
      </View>
    );
  }
}

export default {DaysHeader, DateFilter};
