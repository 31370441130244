import React from 'react';
import {View, Image, TouchableOpacity} from '../../index';
class CheckBoxComponent extends React.Component {
  onPress = () => {
    const {item, onSelect, onRowTouch} = this.props;
    onSelect && onSelect({item});
    onRowTouch && onRowTouch({item});
  };
  render() {
    const {value, selectedIcon, unCheckboxIcon} = this.props;
    return (
      <TouchableOpacity onPress={this.onPress}>
        <View style={{paddingLeft: 6, paddingRight: 6}}>
          <Image
            source={value ? selectedIcon : unCheckboxIcon}
            style={{width: 20, height: 20}}
          />
        </View>
      </TouchableOpacity>
    );
  }
}
export class HeaderSelector extends React.Component {
  render() {
    let {isChecked, onChecked, singleSelection} = this.props;
    if (singleSelection) {
      return null;
    }
    return (
      <CheckBoxComponent
        {...this.props}
        value={isChecked}
        onSelect={onChecked}
      />
    );
  }
}
export class RowSelector extends React.Component {
  render() {
    let {isChecked, onChecked} = this.props;

    return (
      <CheckBoxComponent
        {...this.props}
        value={isChecked}
        onSelect={onChecked}
      />
    );
  }
}

export const WithHeaderSelector = (Component) => {
  class HeaderSelectorComponent extends React.Component {
    onChecked = ({}) => {
      const {data, clearSelectionState, setAllSelectionState} = this.props;
      if (this.isChecked()) {
        clearSelectionState && clearSelectionState();
      } else {
        setAllSelectionState &&
          setAllSelectionState({
            data,
          });
      }
    };
    isChecked = () => {
      const {data, getSelectionState} = this.props;
      if (!data || !data.length) {
        return;
      }
      let {selectedIds = [], allPageSelected} = getSelectionState
        ? getSelectionState()
        : {};
      return data.length === selectedIds.length || allPageSelected;
    };
    render() {
      return (
        <Component
          {...this.props}
          onChecked={this.onChecked}
          isChecked={this.isChecked()}
        />
      );
    }
  }
  return HeaderSelectorComponent;
};

export const WithRowSelector = (Component) => {
  class RowSelectorComponent extends React.Component {
    onChecked = ({item}) => {
      const {setSelectionState} = this.props;
      setSelectionState && setSelectionState({item});
    };
    isChecked = () => {
      const {item, getSelectionState} = this.props;
      const field = '_id';
      if (!item) {
        return;
      }
      let {selectedIds = [], allPageSelected} = getSelectionState
        ? getSelectionState()
        : {};
      if (allPageSelected) {
        return true;
      }
      let itemValue = item[field] || item;
      if (itemValue) {
        return selectedIds.findIndex((key) => key === itemValue) >= 0;
      }
    };
    render() {
      return (
        <Component
          {...this.props}
          onChecked={this.onChecked}
          isChecked={this.isChecked()}
        />
      );
    }
  }
  return RowSelectorComponent;
};
