import Table from '../../../../app-components/table/Table';
import {getUser} from '../../../../AppServices';
import {resolveMobileAndEmail, resolveName, serialNumber} from '../../../RPM/rpmUtils';

let uri = ({navigation}) => {
  let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
  let {organization: {_id: organizationId} = {}} = getUser();
  if (!_id) {
    _id = organizationId;
  }
  return {
    query: {
      id: 'nurseList',
      addOnFilter: {
        organization: {_id},
      },
    },
    model: 'Nurse',
  };
};
const action=({item}) => {
  return {
    type: 'link',
    link: {
      view: 'edit-nurse',
      params: {items:item},
    },
  };
}
let tableItems = {
  lg: {
    action,
    columns: [
      {
        render: (props) => serialNumber(props),
        header: 'S.No.',
        width: 50,
      },
      {
        header: 'Name',
        type: 'text',
        width: 300,
        field: (data) => resolveName(data),
      },
      {
        header: 'Email',
        type: 'text',
        width: 300,
        field: (data) => resolveMobileAndEmail(data)?.email,
      },
      {
        header: 'Mobile Number',
        type: 'text',
        field: (data) => resolveMobileAndEmail(data)?.mobile,
      },
    ],
  },
  sm: {
    action,
    columns: [
      {
        render: (props) => serialNumber(props),
        header: 'S.No.',
        width: 50,
      },
      {
        header: 'Name',
        type: 'text',
        width: 300,
        field: (data) => resolveName(data),
      },
      {
        header: 'Email',
        type: 'text',
        width: 300,
        field: (data) => resolveMobileAndEmail(data)?.email,
      },
      {
        header: 'Mobile Number',
        type: 'text',
        field: (data) => resolveMobileAndEmail(data)?.mobile,
      },
    ],
  }
};
export default Table({
  uri,
  ...tableItems,
  reloadEvent: 'reloadNurse',
});
