
import Table from '../../app-components/table/Table';
import {removeData} from '../../AppServices';
import {listUri} from '../../Queries';
import {colors} from '../../theme/colors';
import { h14_Medium} from '../../theme/fonts';
import { serialNumber } from '../RPM/rpmUtils';
const {backdropColor70} = colors;
let uri = () => {
  return listUri({
    model: 'Todo',
    id: 'todoList',
  });
};

const tableData = {
  lg: {
    columns: [
      {
        width: 50,
      },
      {
        render: serialNumber,
        header: 'S.No.',
        width: 150,
      },
      {
        type: 'text',
        field: 'category.name',
        header: 'To-Do Task Category',
        // width: 250,
      },
     
      {
        type: 'text',
        field: 'title',
        header: 'Titile',
      },
    ],
    hoverActions: [
      {
        value: 'Edit',
        align: 'right',
        textStyle: {
          color: '#9a9a9a',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'edit-todo',
              params: {item},
            },
          };
        },
      },
      {
        value: 'Delete',
        position: 'center',
        align: 'right',
        textStyle: {
          color: 'rgb(241,79,79, 0.87)',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: {
          type: 'link',
          link: (props) => {
            let {item} = props;
            return {
              view: 'delete-masterData',
              modal: true,
              params: {
                item,
                label: 'Todo',
                deleteAction: () =>
                  removeData({item, model: 'Todo'}),
                reloadEvent: 'reloadTodo',
              },
              modalProps: {
                autoHide: true,
                height: 250,
                backdropColor: backdropColor70,
              },
            };
          },
        },
      },
    ],
  },
  sm: {
    columns: [
      {
        width: 50,
      },
      {
        render: serialNumber,
        header: 'S.No.',
        width: 150,
      },
      {
        type: 'text',
        field: 'category.name',
        header: 'To-Do Task Category',
        // width: 250,
      },
     
      {
        type: 'text',
        field: 'title',
        header: 'Titile',
      },
    ],
  },
};

export default Table({
  uri,
  ...tableData,
  reloadEvent: 'reloadTodo',
});
