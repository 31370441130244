import React from 'react';

import Form from '../../app-components/form/Form';
import {PLACEHOLDER} from '../../app-components/utils';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import {submit} from '../../AppServices';
import {autoSuggestInput} from '../../autoSuggestions';
import {moreActionsShadow} from '../../theme/shadows';
import {
  patientDefaultValues,
  // updatePatientDataInForm,
} from '../../app-components/patientUtility';
import {backCrossIcon} from '../../images';
import {TouchableOpacity, Image, View} from '../../app-components';
import { EditPatientProfile } from '../../Queries';

let header = {
  title: 'Edit Profile',
  actions: [cancelButton(), save()],
};

export default Form({
  type: 'standardShadow',
  header,
  closeView: 1,
  reloadEvent: 'reloadProfile',
  onSubmit: submit({
    model: 'Patients',
    updateUser: true,
  }),
  // beforeSubmit: updatePatientDataInForm,
  // defaultValues: ({navigation})=>{
  //   const {state: {params={}}={}}=navigation||{}
  //   console.log(`>@>Puru ~ file: EditPatientProfile.js ~ line 34 ~ item`, params)
  //   return {

  //   }
  // },
  uri: EditPatientProfile,
  // defaultValues: () => {
  //   return {
  //     ...patientDefaultValues,
  //   };
  // },
  formGroups: [
    {
      fieldVariant: 'filled',
      fieldContainer: 'topLabel',
      groups: [
        {
          columnsPerRow: 1,
          columns: [
            {
              type: 'uploadImage',
              field: 'profile_picture',
              label: 'Profile Picture',
            },
            {
              type: 'text',
              field: 'first_name',
              label: 'First Name',
              placeholder: PLACEHOLDER,
            },
            {
              type: 'text',
              field: 'last_name',
              label: 'Last Name',
              placeholder: PLACEHOLDER,
            },
            {
              type: 'text',
              field: 'email',
              label: 'Email Address',
              placeholder: PLACEHOLDER,
            },
          ],
        },
        {
          columnsPerRow: 2,
          columns: [
            {
              type: 'date',
              field: 'birthDate',
              placeholder: PLACEHOLDER,
              label: 'Date of Birth',
              format: 'DD/MM/YYYY',
            },
            // autoSuggestOptionsInput({
            //   label: 'Blood Group',
            //   field: 'blood_group',
            //   suggestionField: 'label',
            //   options: [
            //     {label: 'A+', value: 'A+'},
            //     {label: 'A-', value: 'A-'},
            //     {label: 'B+', value: 'B+'},
            //     {label: 'B-', value: 'B-'},
            //     {label: 'O+', value: 'O+'},
            //     {label: 'O-', value: 'O-'},
            //     {label: 'AB+', value: 'AB+'},
            //     {label: 'AB-', value: 'AB-'},
            //   ],
            //   dropdownStyle: {
            //     height: 100,
            //     borderRadius: 8,
            //     backgroundColor: '#ffffff',
            //     ...moreActionsShadow,
            //   },
            // }),
          ],
        },
        {
          columns: [
            {
              type: 'chipsToggle',
              field: 'gender',
              label: 'Gender',
              options: [
                {value: 'male', label: 'Male'},
                {value: 'female', label: 'Female'},
                {value: 'other', label: 'Other'},
              ],
            },
          ],
        },
        {
          columns: [
            {
              type: 'nestedList',
              field: 'multipleAddress',
              addInBottom: true,

              footer: {
                addLabel: 'Add Addresses',
              },
              formGroups: [
                {
                  fieldVariant: 'filled',

                  label: 'Add Address',
                  direction: 'row',
                  groups: [
                    {
                      columnsPerRow: 2,
                      columns: [
                        {
                          type: 'text',
                          label: 'Address Type',
                          field: 'name',
                        },
                        {
                          type: 'text',
                          label: 'Street',
                          field: 'street',
                        },
                        {
                          type: 'text',
                          label: 'City',
                          field: 'city',
                        },
                        {
                          type: 'text',
                          label: 'State',
                          field: 'state',
                        },

                        // {
                        //   type: 'text',
                        //   label: 'Country',
                        //   field: 'country',
                        //   editable: editable,
                        // },
                        autoSuggestInput({
                          label: 'Country',
                          placeholder: 'Add country here..',
                          field: 'country',
                          query: 'countriesLists',
                          model: 'Countries',
                          valueField: 'name',
                          suggestionField: 'name',
                        }),
                        {
                          type: 'text',
                          label: 'Zip code',
                          field: 'zipcode',
                        },
                      ],
                    },
                    {
                      width: 70,

                      columns: [
                        {
                          type: 'custom',
                          render: (props) => {
                            let {item, index, removeRow} = props;
                            if (index === 0) {
                              return <View />;
                            }
                            return (
                              <TouchableOpacity
                                onPress={() => removeRow({item, index})}
                                style={{
                                  flex: 1,
                                  cursor: 'pointer',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  // height: '100%',
                                  marginTop: '100%',
                                }}>
                                <Image
                                  source={backCrossIcon}
                                  style={{width: 20, height: 20}}
                                />
                              </TouchableOpacity>
                            );
                          },
                        },
                      ],
                    },
                    ,
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});
