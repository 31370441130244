import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  isMobile,
} from '../../app-components';
import {getUser, mayaAISession, gpsStore} from '../../AppServices';
import uuid from 'uuid/v4';
import {colors} from '../../theme/colors';
import {h16_Regular} from '../../theme/fonts';
import {isString} from 'lodash';
const {themeColor} = colors;
let socketUid = uuid();

const Assistant = (props) => {
  const {navigation = {}} = props || {};
  const [socketData, setSocketData] = useState({});
  const [sessionId, setSessionId] = useState('');
  const [toggleIframe, setToggleIframe] = useState(true);
  const {mobile} = getUser() || {};
  const getSessionId = async () => {
    try {
      const sessionIdMaya = await mayaAISession({mobile});
      setSessionId(sessionIdMaya);
    } catch (e) {
      console.log(`>@>Puru ~ file: Assistant.js ~ line 29 ~ e`, e);
    }
  };
  useEffect(() => {
    toggleIframe && getSessionId();
  }, [toggleIframe]);

  useEffect(() => {
    socketSubscription();
    return () => {
      unsubscribSocket();
    };
  }, []);

  const callbackAppointmentUpdate = (data) => {
    setSocketData(data);
    setToggleIframe(false);
  };
  const socketSubscription = () => {
    let user_id = '';
    let {patient: {_id: patientId} = {}} = getUser();
    user_id = patientId;
    gpsStore.subscribeEvent({
      event: 'triageEvent',
      callback: callbackAppointmentUpdate,
    });
    gpsStore.emitDataOnConnect({
      uid: socketUid,
      event: 'subscribeGroup',
      data: {
        groupId: `${user_id}__new`,
        sessionId: socketUid,
        source: 'web',
      },
      callback: () => {},
    });
  };

  const unsubscribSocket = () => {
    if (gpsStore && gpsStore.removeEmitDataOnConnect) {
      gpsStore.removeEmitDataOnConnect({uid: socketUid});
      gpsStore.unsubscribeEvent({
        event: 'triageEvent',
        callback: callbackAppointmentUpdate,
      });
    }
  };

  const {searchTerm = '', mode = 'chat'} = socketData || {};
  const getButtonText = () => {
    if (isString(searchTerm) && searchTerm.toLowerCase() === 'urgent care') {
      return {label: 'Waiting Room', navigationTo: 'WaitingRoom'};
    } else if (
      isString(searchTerm) &&
      searchTerm.toLowerCase() === 'emergency room'
    ) {
      return {label: 'Emergency', navigationTo: 'EmergencyRoom'};
    } else {
      return {
        label: 'Schedule a Telehealth Visit',
        navigationTo: 'doctor-search',
      };
    }
  };

  const defaultScreenState = () => {
    let {state: {params: {searchData: {type, value}} = {}} = {}} = navigation;
    let dataParams = {};
    if (!value.name) {
      return void 0;
    }
    dataParams.filters = {
      [type]: {
        value,
        filter: {
          filterType: 'nameAutoSuggest',
          value,
        },
      },
    };
    return {dataParams};
  };
  const navigateToDoctor = () => {
    const {dependent = null} = socketData;
    navigation.reset({
      view: 'doctor-search',
      params: {
        mayaAISessionId: sessionId,
        dependent,
        searchData: {
          type: 'speciality',
          value: {name: searchTerm || '', _id: '12232'},
        },
      },
      routeOptions: {
        expanded: true,
        defaultScreenState,
      },
    });
  };

  return (
    <View
      style={{
        marginTop: isMobile ? 5 : undefined,
        width: '100%',
        height: isMobile ? '100%' : '96vh',
        justifyContent: 'center',
        // position: 'relative',
        flexDirection: 'row',
        // backgroundColor: 'red',
      }}>
      {sessionId ? (
        <iframe
          style={{
            height: isMobile ? '100%' : '96vh',
            border: 'none',
            borderRadius: 10,
            width: '100%',
          }}
          src={
            isMobile
              ? `https://telemedicine-daffodil.mayamd.ai?sid=${sessionId}`
              : `https://telemedicine-daffodil.mayamd.ai?embedded=true&sid=${sessionId}`
          }
          title="MAYA THE AI"></iframe>
      ) : (
        <View>
          <ActivityIndicator size={'small'} color={'#fff'} />
        </View>
      )}

      <View
        style={{
          width: 240,
          display: toggleIframe ? 'none' : 'flex',
          borderRadius: 8,
          padding: 10,
          position: 'absolute',
          bottom: 22,
          left: mode === 'chat' ? undefined : 80,
          marginRight: mode === 'chat' ? '20%' : undefined,
        }}>
        <View
          style={{
            alignItems: 'flex-end',
          }}>
          <TouchableOpacity
            onPress={() => {
              const {navigationTo} = getButtonText();
              navigationTo === 'doctor-search' && navigateToDoctor();
              navigationTo === 'WaitingRoom' && navigation.push('WaitingRoom');
              navigationTo === 'EmergencyRoom' &&
                navigation.push('EmergencyRoom');
            }}
            style={{
              minWidth: 100,
              // maxWidth: 190,
              paddingLeft: 10,
              paddingRight: 10,
              backgroundColor:
                'rgb(39, 93, 175)' || '#2C43B2' || '#275DAF' || themeColor,
              height: 45,
              display: searchTerm !== '' ? 'flex' : 'none',
              borderRadius: 8,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <Text style={{...h16_Regular, color: '#fff' || themeColor}}>
              {getButtonText().label}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default Assistant;
