import Form from '../../app-components/form/Form';
import {submit, getUser} from '../../AppServices';
import {searchInput} from '../../autoSuggestions';
import {PLACEHOLDER} from '../../app-components/utils';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

export default ({uri, header}) => {
  const OrganisationSettingForm = Form({
    type: 'standardShadow',
    uri,
    onSubmit: submit({
      model: 'OrganizationSetting',
    }),
    reloadEvent: 'reloadOrganisationSetting',
    closeView: 1,
    mandatory: {organization_id: 1, booking_fee: 1, booking_duration: 1},
    defaultValues: () => {
      const {organization = {}} = getUser();
      const id = organization?._id;
      // console.log(')_____))))', id);
      return {
        organization_id: {_id: id},
      };
    },
    lg: {
      header,
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                // searchInput({
                //   field: 'organization_id',
                //   query: 'organizationLists',
                //   model: 'Organizations',
                //   placeholder: 'Search organisation',
                //   idField: '_id',
                //   searchField: 'organization_name',
                //   suggestionField: 'organization_name',
                //   valueField: 'organization_name',
                //   mandatory: true,
                //   label: 'Organisation Name',
                //   width: '50%',
                // }),
                {
                  type: 'number',
                  field: 'booking_fee',
                  label: 'Booking Fee',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  width: '50%',
                },
                {
                  type: 'number',
                  field: 'booking_duration',
                  label: 'Booking Duration (in minutes)',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                  width: '50%',
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          columnsPerRow: 1,
          fieldVariant: 'filled',
          fieldContainer: 'topLabel',
          groups: [
            {
              columns: [
                // searchInput({
                //   field: 'organization_id',
                //   query: 'organizationLists',
                //   model: 'Organizations',
                //   placeholder: 'Search organisation',
                //   idField: '_id',
                //   searchField: 'organization_name',
                //   suggestionField: 'organization_name',
                //   valueField: 'organization_name',
                //   mandatory: true,
                //   label: 'Organisation Name',
                //   width: '50%',
                // }),
                {
                  type: 'number',
                  field: 'booking_fee',
                  label: 'Booking Fee (in USD)',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
                {
                  type: 'number',
                  field: 'booking_duration',
                  label: 'Booking Duration (in minutes)',
                  placeholder: PLACEHOLDER,
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return OrganisationSettingForm;
};
