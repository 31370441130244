import React, {Component} from 'react';

import {
  Dimensions,
  View,
  Text,
  TouchableWithoutFeedback,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableHighlight,
  BasicTextInput,
  FlatList,
  Image,
  Keyboard,
  StatusBarManager,
  Platform,
  StyleSheet,
  ScrollView,
} from '../../react-core-components';
import {ActivityIndicator} from '../../react-activity-indicator';
import PropTypes from 'prop-types';

import {withAppKeyboardListenerContext} from '../../react-app-keyboard-listener';
import {withModalContext} from '../../react-modal-view';

let STATUS_BAR_HEIGHT = 0;

StatusBarManager.getHeight((statusBarHeight) => {
  STATUS_BAR_HEIGHT = statusBarHeight && statusBarHeight.height;
});

const TOUCHABLE_ELEMENTS = [
  'TouchableHighlight',
  'TouchableOpacity',
  'TouchableWithoutFeedback',
  'TouchableNativeFeedback',
];

class AutosuggestInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    valueField: PropTypes.string,
    sugestionField: PropTypes.string,
    options: PropTypes.array,
    minChar: PropTypes.number,

    accessible: PropTypes.bool,
    searching: PropTypes.bool,
    animated: PropTypes.bool,
    showsVerticalScrollIndicator: PropTypes.bool,
    keyboardShouldPersistTaps: PropTypes.string,

    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    dropdownStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    dropdownTextStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    dropdownTextHighlightStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),

    adjustFrame: PropTypes.func,
    renderRow: PropTypes.func,
    renderSeparator: PropTypes.func,
    renderButtonText: PropTypes.func,
    renderNoData: PropTypes.func,
    navigation: PropTypes.object,

    onDropdownWillShow: PropTypes.func,
    onDropdownWillHide: PropTypes.func,
    onItemSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.typing = '';
    this._button = null;
    this._buttonFrame = null;
    this.lastFetchSequence = 0;
    this.state = {};
    this.addKeyboardListener();
  }

  addKeyboardListener = () => {
    let {addKeyboardListener} = this.props;
    if (addKeyboardListener) {
      addKeyboardListener('keyboardDidShow', this._keyboardDidShow);
      addKeyboardListener('keyboardDidHide', this._keyboardDidHide);
    }
  };

  componentWillUnmount() {
    let {removeKeyboardListener} = this.props;
    if (removeKeyboardListener) {
      removeKeyboardListener('keyboardDidShow', this._keyboardDidShow);
      removeKeyboardListener('keyboardDidHide', this._keyboardDidHide);
    }
  }

  _keyboardDidShow = (e) => {
    let keyboardHeight = e.endCoordinates.height;
    this.setState({keyboardShown: true, keyboardHeight}, () => {
      this._updatePosition();
    });
  };

  _keyboardDidHide = () => {
    this.setState({keyboardShown: void 0, keyboardHeight: 0});
  };

  updateSuggestions = (data) => {
    this.setState({data}, () => {
      this._updatePosition(() => {
        this.show();
      });
    });
  };

  fetchSuggestion = (searchValue) => {
    let {fetch, options} = this.props;
    this.lastFetchSequence++;
    let sequence = this.lastFetchSequence;
    this.setState({toggled: !this.state.toggled});
    let result;
    if (options && typeof options === 'function') {
      options = options({...this.props});
    }
    if (options && Array.isArray(options)) {
      result = options;
    } else if (fetch) {
      this.fetching = true;
      result = fetch({
        searchValue,
      });
    } else {
      console.warn(
        'Autosuggest input neither fetch provided nor options (in array )',
      );
    }

    if (result && result instanceof Promise) {
      result
        .then((data) => {
          if (this.lastFetchSequence === sequence) {
            this.fetching = false;
            this.updateSuggestions(data);
          }
        })
        .catch((e) => {
          this.fetching = false;
          // console.error('Error in fetching in autosuggest input', e);
        });
    } else if (result) {
      this.fetching = false;
      this.updateSuggestions(result);
    }
  };

  _updatePosition = (callback) => {
    if (this._button && this._button.measure) {
      this._button.measure((fx, fy, width, height, px, py) => {
        this._buttonFrame = {x: px, y: py, w: width, h: height};
        callback && callback();
      });
    }
  };

  show = () => {
    this.props.setModal &&
      this.props.setModal.setModalState({
        renderModal: this._renderModal,
        showModal: true,
        closeModal: this.hide,
      });
    this.setState({modalOpen: true});
  };

  hide = () => {
    this.props.setModal &&
      this.props.setModal.setModalState({
        renderModal: null,
        showModal: false,
        replace: true,
      });
    this.setState({modalOpen: false});
  };

  setButtonText = (value) => {
    this.buttonText = value;
  };

  onFocus = (e) => {
    const {onFocus, minChar} = this.props;
    onFocus && onFocus(e);
    !minChar && this._renderData();
  };

  onBlur = (e) => {
    const {onBlur} = this.props;
    this.hide();
    onBlur && onBlur(e);
    this.setButtonText(void 0);
    this.typing = '';
    this.lastFetchSequence = 0;
    if (this.fetching) {
      this.fetching = false;
      this.setState({toggled: !this.state.toggled});
    }
  };
  _onButtonPress = (event) => {
    event.preventDefault();
    Keyboard && Keyboard.dismiss();
    this._inputRef && this._inputRef.focus();
  };

  _renderData = () => {
    const {onDropdownWillShow, renderLoading} = this.props;
    onDropdownWillShow && onDropdownWillShow();
    if (renderLoading) {
      this._updatePosition(() => {
        this.show();
      });
    }
    this.fetchSuggestion();
  };

  _renderModal = () => {
    const {accessible} = this.props;
    if (this._buttonFrame) {
      return (
        <TouchableWithoutFeedback
          accessible={accessible}
          onPress={this._onRequestClose}>
          <View
            style={{
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
              position: 'absolute',
              backgroundColor: 'transparent',
            }}>
            {this._renderDropdown()}
          </View>
        </TouchableWithoutFeedback>
      );
    }
  };

  _calcPosition() {
    let {dropdownStyle, style, adjustFrame, position} = this.props;
    const {keyboardHeight} = this.state;
    const dimensions = Dimensions.get('window');
    const windowWidth = dimensions.width;
    let windowHeight = dimensions.height;
    if (keyboardHeight) {
      windowHeight -= keyboardHeight;
    }
    if (dropdownStyle) {
      dropdownStyle = StyleSheet.flatten(dropdownStyle);
    }
    if (style) {
      style = StyleSheet.flatten(style);
    }
    let marginBottom =
      (dropdownStyle && dropdownStyle.marginBottom) ||
      (style && style.marginBottom) ||
      0;
    if (!marginBottom) {
      marginBottom =
        (dropdownStyle && dropdownStyle.margin) || (style && style.margin) || 0;
    }
    let marginTop =
      (dropdownStyle && dropdownStyle.marginTop) ||
      (style && style.marginTop) ||
      0;
    if (!marginTop) {
      marginTop =
        (dropdownStyle && dropdownStyle.margin) || (style && style.margin) || 0;
    }
    let topBottomMargin = marginTop + marginBottom;

    const dropdownHeight = (dropdownStyle && dropdownStyle.height) || 0;
    //check whether modal should open in top or bottom
    let availableBottomSpace =
      windowHeight -
      this._buttonFrame.y -
      this._buttonFrame.h -
      STATUS_BAR_HEIGHT;
    let availabelTopSpace =
      this._buttonFrame.y - STATUS_BAR_HEIGHT - topBottomMargin;

    let showInBottom =
      dropdownHeight <= availableBottomSpace ||
      availableBottomSpace >= availabelTopSpace;
    if (
      showInBottom &&
      position === 'top' &&
      dropdownHeight &&
      dropdownHeight <= availabelTopSpace
    ) {
      showInBottom = false;
    }

    let modalHeight = 0;
    let modalTopPosition = 0;
    let modalBottomPosition = 0;
    //here we decide the modal height and modal top position
    if (showInBottom) {
      modalHeight =
        dropdownHeight <= availableBottomSpace
          ? dropdownHeight
          : availableBottomSpace;
      modalTopPosition = this._buttonFrame.y + this._buttonFrame.h;
    } else {
      //check if  space is sufficient for default given height or not
      modalHeight =
        dropdownHeight <= availabelTopSpace
          ? dropdownHeight
          : availabelTopSpace;
      modalBottomPosition =
        windowHeight - STATUS_BAR_HEIGHT - this._buttonFrame.y;
    }
    const dropdownWidth =
      (dropdownStyle && dropdownStyle.width) ||
      (style && style.width) ||
      this._buttonFrame.w;
    const positionStyle = {
      position: 'absolute',
    };

    positionStyle.width = dropdownWidth;
    if (modalHeight !== undefined) {
      positionStyle.height = modalHeight;
    }
    if (modalTopPosition) {
      positionStyle.top = modalTopPosition;
    }
    if (modalBottomPosition) {
      positionStyle.bottom = modalBottomPosition;
    }

    const rightSpace = windowWidth - this._buttonFrame.x;
    let showInRight = rightSpace >= dropdownWidth;
    if (
      showInRight &&
      position === 'left' &&
      dropdownWidth < this._buttonFrame.x
    ) {
      showInRight = false;
    }

    if (showInRight) {
      positionStyle.left = this._buttonFrame.x;
    } else {
      const dropdownWidth =
        (dropdownStyle && dropdownStyle.width) || (style && style.width) || -1;
      if (dropdownWidth !== -1) {
        positionStyle.width = dropdownWidth;
      }
      positionStyle.right = rightSpace - this._buttonFrame.w;
    }
    // console.warn('position style', positionStyle);
    return adjustFrame ? adjustFrame(positionStyle, this.state) : positionStyle;
  }

  _onRequestClose = () => {
    const {onDropdownWillHide} = this.props;
    if (!onDropdownWillHide || onDropdownWillHide() !== false) {
      this.hide();
    }
    Keyboard && Keyboard.dismiss();
    this._inputRef && this._inputRef.blur && this._inputRef.blur();
  };

  _renderLoading = () => {
    const {loadingStyle} = this.props;
    let {viewStyle, activityIndicatorProps} = loadingStyle || {};
    return (
      <>
        {viewStyle ? (
          <View style={viewStyle}>
            <ActivityIndicator {...activityIndicatorProps} />
          </View>
        ) : (
          <ActivityIndicator size="small" />
        )}
      </>
    );
  };

  _renderDropdown() {
    const {
      renderSeparator,
      showsVerticalScrollIndicator,
      keyboardShouldPersistTaps = 'always',
      dropdownStyle,
      renderNoData,
      navigation,
      keyExtractor,
      renderLoading,
    } = this.props;
    const frameStyle = this._calcPosition();
    let {data} = this.state;
    if (!data || !data.length) {
      if (this.fetching) {
        return renderLoading ? (
          <ScrollView style={[dropdownStyle, frameStyle]}>
            {renderLoading({
              loading: this.fetching,
              defaultLoadingComponent: this._renderLoading,
              closeModal: this._onRequestClose,
            })}
          </ScrollView>
        ) : null;
      }

      if (renderNoData && this.typing.length) {
        return (
          <View style={[frameStyle]}>
            {renderNoData({
              navigation,
              searchValue: this.typing,
              style: {
                ...dropdownStyle,
              },
              closeModal: this._onRequestClose,
            })}
          </View>
        );
      }
    } else {
      return (
        <FlatList
          ref={(index) => (this.FlatList = index)}
          style={[dropdownStyle, frameStyle]}
          data={data}
          renderItem={this._renderRow}
          ItemSeparatorComponent={renderSeparator || this._renderSeparator}
          keyExtractor={keyExtractor}
          showsHorizontalScrollIndicator={showsVerticalScrollIndicator}
          keyboardShouldPersistTaps={keyboardShouldPersistTaps}
        />
      );
    }
  }

  _renderRow = ({item, index}) => {
    const {
      renderRow,
      dropdownTextStyle,
      dropdownTextHighlightStyle,
      accessible,
      sugestionField,
      isHighlighted,
    } = this.props;
    let highlighted = isHighlighted && isHighlighted({item: item, index});

    const row = !renderRow ? (
      <Text
        style={[dropdownTextStyle, highlighted && dropdownTextHighlightStyle]}>
        {sugestionField ? item[sugestionField] : item}
      </Text>
    ) : (
      renderRow({item, index, highlighted, props: this.props})
    );

    let preservedProps;
    if (Platform.OS === 'web') {
      preservedProps = {
        accessible,
        onMouseDown: (e) => {
          e.preventDefault();
          this._onRowPress({item, index});
        },
      };
    } else {
      preservedProps = {
        accessible,
        onPress: (e) => {
          this._onRowPress({item, index});
        },
      };
    }
    if (TOUCHABLE_ELEMENTS.find((name) => name == row.type.displayName)) {
      const props = {...row.props};
      props.key = preservedProps.key;
      props.onPress = preservedProps.onPress;
      const {children} = row.props;
      switch (row.type.displayName) {
        case 'TouchableHighlight': {
          return <TouchableHighlight {...props}>{children}</TouchableHighlight>;
        }
        case 'TouchableOpacity': {
          return <TouchableOpacity {...props}>{children}</TouchableOpacity>;
        }
        case 'TouchableWithoutFeedback': {
          return (
            <TouchableWithoutFeedback {...props}>
              {children}
            </TouchableWithoutFeedback>
          );
        }
        case 'TouchableNativeFeedback': {
          return (
            <TouchableNativeFeedback {...props}>
              {children}
            </TouchableNativeFeedback>
          );
        }
        default:
          break;
      }
    }

    return <TouchableHighlight {...preservedProps}>{row}</TouchableHighlight>;
  };

  _dropdown_search_keypress = (e) => {
    switch (e.nativeEvent.key) {
      case 'Enter':
        this.props.onEnterPress &&
          this.props.onEnterPress({searchValue: this.typing});
        this.hide();
        break;
    }
  };

  _dropdown_search_textchange = (text) => {
    this.typing = text;
    let {minChar, onChangeText} = this.props;
    onChangeText && onChangeText(text);
    this.setButtonText(text);

    if (!minChar || (text && text.length >= minChar)) {
      this.fetchSuggestion(text);
    } else {
      this.setState({});
    }
  };

  _onRowPress({item, index}) {
    const {onItemSelect} = this.props;
    if (onItemSelect) {
      let value = onItemSelect({item, index, searchValue: this.typing});
      if (value !== undefined) {
        this.setButtonText(value);
        this.setState({});
      }
    }
    this._onRequestClose();
  }

  _renderSeparator = rowID => {
    let {separatorStyle} = this.props;
    return <View key={`spr_${rowID}`} style={separatorStyle} />;
  };

  renderSelector = () => {
    let {arrowIconStyle, showArrow, arrowDownIcon, clearValue} = this.props;
    if (!showArrow) {
      return null;
    }
    let touchProps = {};
    if (Platform.OS === 'web') {
      touchProps.onMouseDown = e => {
        e && e.stopPropagation && e.stopPropagation();
        clearValue && clearValue(e);
      };
    } else {
      touchProps.onPress = this.clearValue;
    }
    return (
      <TouchableOpacity {...touchProps}>
        <Image source={arrowDownIcon} style={arrowIconStyle} />
      </TouchableOpacity>
    );
  };
  getInputRef = (ref) => {
    const {getRef} = this.props;
    this._inputRef = ref;
    getRef && getRef(ref);
  };
  clearText = (e) => {
    this.setButtonText('');
  };
  _renderButton() {
    let {
      disabled,
      accessible,
      children,
      buttonContainerStyle,
      inputStyle,
      value,
      placeholder,
      searching,
      renderButtonText,
      inputProps,
      renderLoading,
      renderSelector,
      imageComponent,
      clearValue,
      hideLoader,
    } = this.props;
    let buttonText = void 0;
    if (value !== this.oldValue) {
      buttonText = value;
      this.buttonText = void 0;
    } else if (this.buttonText || this.buttonText === '') {
      buttonText = this.buttonText;
    } else {
      buttonText = value;
    }

    this.oldValue = value;
    let buttonComponent = children;
    if (!buttonComponent) {
      let textComponent = null;
      if (renderButtonText) {
        textComponent = renderButtonText({
          text: buttonText,
          placeholder,
        });
      } else {
        let extraInputProps = {};
        if (searching) {
          extraInputProps.onKeyPress = this._dropdown_search_keypress;
          extraInputProps.onChangeText = this._dropdown_search_textchange;
        } else {
          extraInputProps.caretHidden = true;
          extraInputProps.showSoftInputOnFocus = false;
          extraInputProps.editable = !disabled;
        }
        textComponent = (
          <BasicTextInput
            style={inputStyle}
            value={buttonText || ''}
            placeholder={placeholder}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            getRef={this.getInputRef}
            {...inputProps}
            {...extraInputProps}
          />
        );
      }
      renderSelector = renderSelector || this.renderSelector;
      buttonComponent = (
        <View style={{flexDirection: 'row', ...buttonContainerStyle}}>
          {imageComponent ? imageComponent({text: buttonText}) : void 0}
          <View style={{flex: 1, overflow: 'hidden'}}>
            {textComponent}
            {this.fetching && !renderLoading && !hideLoader
              ? this._renderLoading()
              : void 0}
          </View>
          {renderSelector({
            value,
            loading: this.fetching,
            searching,
            modalOpen: this.state.modalOpen,
            searchText: this.typing,
            clearValue,
            clearText: this.clearText,
          })}
        </View>
      );
    }

    let touchProps = {};
    if (Platform.OS === 'web') {
      touchProps.onMouseDown = this._onButtonPress;
    } else {
      touchProps.onPress = this._onButtonPress;
    }
    return (
      <TouchableOpacity
        ref={(button) => (this._button = button)}
        disabled={disabled}
        accessible={accessible}
        {...touchProps}>
        {buttonComponent}
      </TouchableOpacity>
    );
  }

  render() {
    return this._renderButton();
  }
}

AutosuggestInput.defaultProps = {
  disabled: false,
  placeholder: 'Please select...',
  animated: true,
  showsVerticalScrollIndicator: true,
  keyboardShouldPersistTaps: 'always',
  searching: false,
  minChar: 0,
  buttonContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownStyle: {
    position: 'absolute',
    height: (33 + StyleSheet.hairlineWidth) * 5,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: 'lightgray',
    borderRadius: 2,
    backgroundColor: 'white',
  },
  dropdownTextStyle: {
    paddingHorizontal: 6,
    paddingVertical: 10,
    fontSize: 11,
    color: 'gray',
    backgroundColor: 'white',
    textAlignVertical: 'center',
  },
  dropdownTextHighlightStyle: {
    color: 'black',
  },
  separatorStyle: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: 'lightgray',
  },
  arrowIconStyle: {
    height: 10,
    width: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default withAppKeyboardListenerContext(
  withModalContext(AutosuggestInput),
);
