import React from 'react';
import {
  Image,
  TouchableOpacity,
  View,
  Text,
  WithModalButton,
  isMobile,
  Dimensions,
} from '../../app-components';
import {getUser} from '../../AppServices';
import NotificationList from './NotificationList';

import {favorite, crossIcon} from '../../images';

import {
  notificationStyle as style,
  notificationHeaderStyle as theme,
} from '../../theme/notificationTheme';
import withNotificationContext from '../../AppNotification';
import {bgs, colors} from '../../theme/colors';
import {
  h10_Regular,
  h16_Medium,
  h14_Medium,
  h11_Medium,
  h12_SemiBold,
} from '../../theme/fonts';
import {dropDownShadow} from '../../theme/shadows';

const headerHeight = 72;

let {
  backLightColor,
  backTransparent,
  GrayColor,

  whiteBg,
} = bgs;
const {width: DimensionsWidth, height: DimensionsHeight} = Dimensions.get(
  'window',
);
let {textWhite, dodgerBlue, textColor87, textColor70} = colors;

class Notification extends React.Component {
  constructor(props) {
    super(props);
  }

  headerComponent = ({hideModal}) => {
    let {
      classroomStore: {notificationCount = 0, notificationData, markRead},
    } = this.props;
    return (
      <TouchableOpacity
        onPress={() => {
          hideModal();
        }}>
        <View
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 24,
            paddingBottom: 16,
            borderBottomColor: 'rgba(217, 217, 224, 0.3)',
            borderBottomWidth: 1,
          }}>
          <View style={{alignItems: 'center', flexDirection: 'row'}}>
            <Text style={{color: textColor87, ...h16_Medium, flex: 1}}>
              {'Your Notifications'}
            </Text>
            <View
              style={{
                alignItems: 'flex-end',
                height: 16,
                width: 16,
                cursor: 'pointer',
                paddingLeft: 10,
                paddingRight: 10,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Image source={crossIcon} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  listComponent = ({hideModal}) => {
    let {
      classroomStore: {notificationCount = 0, notificationData, markRead},
    } = this.props;
    if (!notificationData.length) {
      return (
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <Text style={{...h14_Medium, color: 'red'}}>
            No notification data available
          </Text>
        </View>
      );
    }
    return (
      <TouchableOpacity
        style={{flex: 1, overflow: 'auto'}}
        onPress={() => {
          // console.log("notification data",notificationData)
          // hideModal();
        }}>
        <NotificationList
          {...this.props}
          hideModal={hideModal}
          data={notificationData}
        />
      </TouchableOpacity>
    );
  };
  footerComponent = ({hideModal, view}) => {
    let {
      classroomStore: {notificationCount = 5, notificationData, markRead},
    } = this.props;

    let msg = 'See all Notifications';
    // if (!notificationData.length) {
    //   msg = 'No notification available';
    // }

    return (
      <TouchableOpacity
        onPress={() => {
          if (notificationData.length) {
            this.props.navigation.replace({view});
          }

          // hideModal();
        }}>
        <View
          style={{
            ...theme.footerViewStyle,
          }}>
          <Text style={{...theme.footerTextStyle}}>{msg}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  renderModal = ({frameStyle, hideModal}) => {
    let {left, width} = frameStyle;
    console.log('#####frameStyle', frameStyle);
    return (
      <TouchableOpacity
        style={{
          ...frameStyle,
          left: left,
          right: 0,
          // top: headerHeight,
          top: 0,
          zIndex: 5,
          height: '100%',
          overflow: 'hidden',
          //   backgroundColor: backLightColor,
        }}>
        <TouchableOpacity
          style={{
            backgroundColor: whiteBg,
            borderRadius: 4,
            flex: 1,
            overflow: 'hidden',
            boxShadow: '0 0 10px 0 rgba(53, 53, 53, 0.08)',
            border: 'solid 0.2px #c3c3c3',
          }}
          onPress={() => {
            // hideModal();
          }}>
          <View
            style={{
              borderRadius: 4,
              flex: 1,
              overflow: 'hidden',
              backgroundColor: whiteBg,
            }}>
            {this.headerComponent({hideModal})}
            {this.listComponent({hideModal})}
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    );
  };
  render() {
    let {
      classroomStore: {notificationCount = 0, notificationData, markRead},
      headerTextStyle,
    } = this.props;
    return (
      <WithModalButton
        renderModal={this.renderModal}
        autoHide={true}
        backdropStyle={{
          backgroundColor: 'rgba(0, 0, 0, .30)',
          overflow: 'hidden',
          top: 0,
        }}
        dropdownStyle={{
          width: isMobile ? DimensionsWidth * 0.75 : 413,
          height: '100%',
          overflow: 'hidden',
        }}>
        <View
          onClick={() => {
            // markRead && markRead();
          }}
          style={{
            ...theme.container,
            height: 70,
            paddingTop: 20,
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {notificationCount ? (
            <View
              style={{
                ...style.notificationViewStyle,
                backgroundColor: '#fb6b6b',
                top: void 0,
                right: void 0,
                bottom: 50,
                left: 32,
                zIndex: 1,
              }}>
              <Text
                style={{
                  ...style.notificationTextStyle,
                  color: 'white',
                  ...h11_Medium,
                }}>
                {notificationCount}
              </Text>
            </View>
          ) : null}
          {/* <View
            style={{
              height: 20,
              width: 22,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Image
              style={{width: 25, height: 22}}
              source={notificationCount ? favorite : favorite}
            />
          </View>
          <Text style={{...headerTextStyle}}>
            Notification
          </Text> */}
        </View>
      </WithModalButton>
    );
  }
}

let WrappedNotificationChat = withNotificationContext(Notification);
export default WrappedNotificationChat;
