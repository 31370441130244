import React from 'react';
import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
} from '../../react-core-components';
import {
  WithLabel,
  WithInputWrapper,
  WithVariant,
} from '../../react-input-wrapper';
import AutosuggestInput from './AutosuggestInput';
import AutoSuggestBottomInput from './AutoSuggestBottomInput';

const matchValue = (value1, value2, fieldToMatch) => {
  if (fieldToMatch && value1 && value2) {
    return value1[fieldToMatch] === value2[fieldToMatch];
  } else {
    return value1 === value2;
  }
};
/**
 *
 * need to provide idField so that we can search the selected value exists or not, other wise we will check === ops
 */
class MultiAutosuggestInput extends React.Component {
  keyExtractor = (item, index) => {
    return String(index);
  };
  onChangeValue = (value, e) => {
    const {onChangeValue, onChangeText} = this.props;
    onChangeValue && onChangeValue(value, e);
    onChangeText && onChangeText(value);
  };

  findValue = ({item}) => {
    let {value, idField} = this.props;
    if (!value) {
      return false;
    }
    if (!Array.isArray(value)) {
      value = [value];
    }
    for (let row of value) {
      let matched = matchValue(row, item, idField);
      if (matched) {
        return true;
      }
    }
    return false;
  };

  onSelect = ({item, e}) => {
    let {value, onItemSelect} = this.props;
    let isExist = this.findValue({item});
    if (value && !Array.isArray(value)) {
      value = [value];
    }
    if (!isExist) {
      let newValue = value ? [...value] : [];
      newValue.push(item);
      this.onChangeValue(newValue, e);
      onItemSelect && onItemSelect({item, props: this.props});
    }
    return '';
  };

  isHighlighted = ({item}) => {
    return this.findValue({item});
  };

  onRenderNoDataCallback = ({result} = {}) => {
    if (result) {
      this.onChangeValue(result);
    }
  };

  renderNoData = (searchProps) => {
    const {renderNoData} = this.props;
    if (!renderNoData) {
      return;
    }
    return renderNoData({
      ...searchProps,
      onSubmitCallback: this.onRenderNoDataCallback,
    });
  };

  fetch = (searchProps) => {
    const {fetch, data} = this.props;
    return fetch({...searchProps, data}).then((result) => {
      return result.data;
    });
  };
  render() {
    const {
      value,
      valueField,
      fetch,
      renderNoData,
      onItemSelect,
      renderButtonText,
      imageComponent,
      modalPosition,
      ...rest
    } = this.props;
    const AutoSuggestInputComponent =
      modalPosition === 'screenBottom'
        ? AutoSuggestBottomInput
        : AutosuggestInput;
    return (
      <AutoSuggestInputComponent
        keyExtractor={this.keyExtractor}
        onItemSelect={this.onSelect}
        isHighlighted={this.isHighlighted}
        fetch={this.fetch}
        renderNoData={this.renderNoData}
        renderButtonText={
          renderButtonText
            ? (props) => renderButtonText({...props, value})
            : void 0
        }
        imageComponent={
          imageComponent ? (props) => imageComponent({...props, value}) : void 0
        }
        {...rest}
      />
    );
  }
}

MultiAutosuggestInput = WithVariant(MultiAutosuggestInput);

class MultiAutosuggestInputField extends React.Component {
  onChangeValue = (value, e) => {
    const {onChangeValue, onChangeText} = this.props;
    onChangeValue && onChangeValue(value, e);
    onChangeText && onChangeText(value);
  };
  removeItem = ({item, e}) => {
    let {value, idField} = this.props;
    if (!item || !value || !value.length) {
      return;
    }
    let newValue = value.filter((doc) => {
      return !matchValue(doc, item, idField);
    });
    this.onChangeValue(newValue, e);
  };

  renderChips = ({
    renderChip,
    chipsStyle,
    roundedChipsStyle,
    chipsVariant,
    value,
    valueField,
    idField,
    removeChipIcon,
    maxHeight,
    textColor,
    secondaryValueField,
  }) => {
    if (value && !Array.isArray(value)) {
      value = [value];
    }
    if (!value || !value.length) {
      return;
    }
    if (chipsVariant === 'rounded') {
      chipsStyle = roundedChipsStyle;
    }
    let {
      containerStyle,
      itemContainerStyle,
      itemTextStyle,
      crossContainerStyle,
      crossIconStyle,
      crossTextStyle,
    } = chipsStyle;
    containerStyle = {...containerStyle};
    if (maxHeight) {
      containerStyle.maxHeight = maxHeight;
    }
    if (textColor) {
      itemTextStyle = {
        ...itemTextStyle,
        color: textColor,
      };
    }
    return (
      <ScrollView style={containerStyle}>
        {value.map((item, index) => {
          if (!item) {
            return null;
          }
          let key = idField ? item[idField] : index;
          let displayValue = '';
          if (this.props.getChipValue) {
            displayValue = this.props?.getChipValue(item);
          } else if (valueField && item[valueField]) {
            displayValue = item[valueField];
          } else if (secondaryValueField && item[secondaryValueField]) {
            displayValue = item[secondaryValueField];
          } else {
            displayValue = '';
          }
          if (renderChip) {
            return renderChip({displayValue, key, item, index});
          }
          return (
            <View
              key={key}
              style={{
                flexDirection: 'row',
                ...itemContainerStyle,
                width: 'auto',
              }}>
              <View style={{flex: 1}}>
                <Text
                  style={{...itemTextStyle, width: 'auto', maxWidth: '100%'}}
                  title={displayValue}>
                  {displayValue}
                </Text>
              </View>
              <TouchableOpacity
                style={{cursor: 'pointer', ...crossContainerStyle}}
                onPress={(e) => this.removeItem({item, e})}>
                {removeChipIcon ? (
                  <Image style={crossIconStyle} source={removeChipIcon} />
                ) : (
                  <Text style={crossTextStyle}>X</Text>
                )}
              </TouchableOpacity>
            </View>
          );
        })}
      </ScrollView>
    );
  };
  render() {
    let {
      chipsVariant,
      chipsPosition = 'bottom',
      chipsStyle,
      renderChips,
      roundedChipsStyle,
      renderChip,
      textColor,
      disableTextInput = false,
      visibleChip = true,
      secondaryValueField,
      ...restProps
    } = this.props;
    let {value, valueField, idField, removeChipIcon, maxHeight} = this.props;
    if (!renderChips) {
      renderChips = this.renderChips;
    }
    let chipsComponent = renderChips({
      value,
      valueField,
      idField,
      removeChipIcon,
      maxHeight,
      renderChip,
      chipsStyle,
      onRemove: this.removeItem,
      roundedChipsStyle,
      chipsVariant,
      textColor,
      secondaryValueField,
    });
    return (
      <>
        {visibleChip && chipsPosition === 'top' ? chipsComponent : void 0}
        {disableTextInput ? void 0 : <MultiAutosuggestInput {...restProps} />}
        {visibleChip && chipsPosition === 'bottom' ? chipsComponent : void 0}
      </>
    );
  }
}

export default WithLabel(WithInputWrapper(MultiAutosuggestInputField));
