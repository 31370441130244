import React from 'react';
import {View, Text, TouchableOpacity} from '../../app-components';
import {h20_AvenirNext_600} from '../../theme/fonts';

const EmergencyRoom = (props) => {
  const {navigation} = props;
  const {state: {params: {name = 'Hospital'} = {}} = {}} = navigation;
  const triageLinking = (name) => {
    return (
      'https://www.google.com/maps/search/embed/?api=1&query=' +
      encodeURIComponent(name)
    );
  };

  return (
    <View
      style={{
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
      }}>
      {/* <iframe
        style={{height: '100%'}}
        src={"https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d21056742.211671695!2d-97.15369!3d49.891235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s14735+32A+AVE+Surrey+V4P+1Z8+bc!5e0!3m2!1sen!2sca!4v1424618801986"}
        // src={`${triageLinking(name)}`}
        title="GOOGLE  I FRAME"></iframe> */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d13941.327506932577!2d75.74872363115307!3d29.125400081222335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1semergency!5e0!3m2!1sen!2sin!4v1614941013275!5m2!1sen!2sin"
        style={{height: '100%'}}
        title="Google maps"
        // loading="lazy"
      ></iframe>
    </View>
  );
};

export default EmergencyRoom;
