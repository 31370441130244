const manazeV6Colors = {
  neutral_1: "#2C2C2C", // black
  // neutral_2: "#4B4B4B",
  neutral_2: "#000",
  neutral_3: "#6E6E6E",
  // neutral_4: "#8E8E8E",
  neutral_4: "#000", // Chnage form value color to black
  neutral_5: "#B3B3B3",
  neutral_6: "#CACACA",
  neutral_7: "#E1E1E1",
  neutral_8: "#EAEAEA",
  neutral_9: "#F5F5F5",
  neutral_10: "#FAFAFA",
  surface_white: "#FFFFFF",

  primary_1: "#005DE2", // blue
  primary_2: "#4276EC",
  primary_3: "#6D91F0",
  primary_4: "#C4CFF8",
  primary_5: "#EEF2FF",

  warning_1: "#FE7F00", // yellow
  warning_2: "#FFAB00",
  warning_3: "#FFDC1C",
  warning_4: "#FDF16B",
  warning_5: "#FFFAC1",

  positive_communication_1: "#009E5B", //green
  positive_communication_2: "#01A864",
  positive_communication_3: "#00C175",
  positive_communication_4: "#00CC89",
  positive_communication_5: "#1ED69E",
  positive_communication_6: "#DFFBEF",

  negative_communication_1: "#F3423D", //red
  negative_communication_2: "#FF4C44",
  negative_communication_3: "#FF8181",
  negative_communication_4: "#FFA5A5",
  negative_communication_5: "#FFD4D9"
};

export { manazeV6Colors };
