import React, {useEffect, useState} from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Image,
  isMobile,
} from '../../app-components';
import {getFileExtension} from '../../app-components/UtilityFunctions';
import {getImageUrl, invoke} from '../../AppServices';
import {pdf, redCloseIcon} from '../../images';
import moment from 'moment';
import {h11_SemiBold, h13_Regular, h14_Medium} from '../../theme/fonts';
import {isEmpty, get} from 'lodash';
import {patientWatingRoomCard} from '../../theme/shadows';
import {isTimerVisible} from './rpmUtils';
import Timer from '../GenericComponent/Timer';

const Notes = (props) => {
  let {
    navigation: {
      state: {
        params: { patientItem: item = {}, modalWidth: calculateWidth, language = 'en' },
      } = {},
    } = {},
    navigation = {},
  } = props || {};

  const [prevMayaNoteArrayMain, setPrevMayaNoteArrayMain] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(new Date());
  const modifyResult = (result, key) => {
    let records = {MayaNote: [], 'Symptom Checker': []};
    Array.isArray(result) &&
      result.forEach((current) => {
        // if (!records[get(current, key)]) {
        //   records[get(current, key, 'MayaNote')] = [];
        // }
        // records[get(current, key, 'MayaNote')] = [
        //   ...records[get(current, key, 'MayaNote')],
        //   current,
        // ];
        if (get(current, 'algo_type', '') === 'Symptom Checker') {
          records['Symptom Checker'] = [...records['Symptom Checker'], current];
        } else {
          if (current && !isEmpty(current?.mayamdNotesCollection)) {
            current.mayamdNotesCollection.forEach(ele => {
              if (ele.language === language) {
                current.mayamd_note = ele.note;
              }
            })
          }
          records.MayaNote = [...records.MayaNote, current];
        }
      });
    if (!get(records, 'Symptom Checker', []).length) {
      delete records['Symptom Checker'];
    }
    if (!get(records, 'MayaNote', []).length) {
      delete records.MayaNote;
    }

    return records;
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        await invoke({
          id: '_find',
          paramValue: {
            query: {
              id: 'patientNoteLists',
              addOnFilter: {
                patient: {_id: item?.patient_id?._id},
              },
            },
            limit: 100,
            model: 'PatientNotes',
          },
        })
          .then((res) => {
            const {result = []} = res || {};
            const groupByCareProgram = modifyResult(
              result,
              'care_program.name',
            );
            const finalData = Object.entries(groupByCareProgram).map(
              ([key, value]) => {
                let temp = {};
                temp[key] = value;
                return {
                  name: [key],
                  [key]: temp,
                };
              },
            );
            setPrevMayaNoteArrayMain(finalData);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log('<<< err >>>', err);
            setIsLoading(false);
          });
      })();
    } catch (err) {
      console.log('<<< err >>>', err);
      setIsLoading(false);
    }
  }, [forceUpdate, item.patient_id._id]);
  return (
    <View
      style={{
        backgroundColor: '#f9f9f9',
        padding: 10,
        borderRadius: 8,
        flex: 1,
      }}>
      <View style={{justifyContent: 'flex-end', flexDirection: 'row'}}>
        {/* {!isEmpty(prevMayaNoteArrayMain)&& isTimerVisible() ? (
          <View
            style={{
              // alignItems: 'flex-end',
              // backgroundColor: 'green',
              paddingTop: 10,
              paddingBottom: 10,
              paddingRight: 10,
            }}>
            <Timer type="analysis" patientId={item?.patient_id?._id} />
          </View>
        ) : (
          <View />
        )} */}
        <TouchableOpacity
          onPress={() => {
            navigation.pop();
          }}
          style={{
            alignItems: 'flex-end',
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 10,
            cursor: 'pointer',
          }}>
          <Image source={redCloseIcon} style={{width: 20, height: 20}} />
        </TouchableOpacity>
      </View>
      <ScrollView style={{flex: 1, paddingLeft: 5}}>
        {isLoading ? (
          <View
            style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{...h14_Medium, color: '#275dad'}}>Loading...</Text>
          </View>
        ) : isEmpty(prevMayaNoteArrayMain) ? (
          <View
            style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{...h14_Medium, color: '#275dad'}}>No Data</Text>
          </View>
        ) : (
          !isEmpty(prevMayaNoteArrayMain) &&
          prevMayaNoteArrayMain.map((noteData, index) => {
            return (
              <View style={{marginBottom: 10}}>
                <Text
                  style={{
                    ...h14_Medium,
                    color: '#275dad',
                    paddingBottom: 10,
                  }}>
                  {noteData.name}
                </Text>
                <View style={{flexWrap: 'wrap'}}>
                  {Object.entries(noteData[noteData.name]).map(
                    ([key, value]) => {
                      return (
                        <View
                          style={{
                            paddingLeft: 10,
                            paddingTop: 5,
                            ...patientWatingRoomCard,
                            marginRight: 5,
                            marginTop: 5,
                            borderRadius: 8,
                            flex: 1,
                          }}>
                          <Text
                            style={{
                              ...h13_Regular,
                              color: '#275dad',
                              paddingBottom: 5,
                              display: 'none',
                              // ...(key === 'MayaNote' ? {display: 'none'} : {}),
                            }}>
                            {key === 'CKD' ? 'Checked in Symptoms' : key}
                          </Text>
                          <View
                            style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                            {!isEmpty(value)
                              ? value.map((noteValueItem, clickedIndex) => {
                                return (
                                  !!Object.keys(noteValueItem?.mayamd_note || {}).length ? <TouchableOpacity
                                    style={{
                                      margin: 5,
                                      borderRadius: 4,
                                      cursor: 'pointer',
                                      padding: 5,
                                      position: 'relative',
                                    }}
                                    title={moment(
                                      noteValueItem?.date ||
                                      noteValueItem?._createdOn,
                                    ).format('MM/DD/YY')}
                                    onPress={() => {
                                      navigation.push({
                                        view: 'pdfReader',
                                        params: {
                                          dataArr: value,
                                          prevNote: true,
                                          clickedIndex,
                                          care_program: true,
                                          forceUpdate: setForceUpdate,
                                        },
                                        modal: true,
                                        modalProps: {
                                          autoHide: true,
                                          minHeight: 180,
                                          position: 'screenLeft',
                                          style: {
                                            height: '100%',
                                            width: isMobile
                                              ? '100%'
                                              : calculateWidth,
                                            zIndex: 999,
                                          },
                                          // backdropStyle: {},
                                          // ...confirmModalProps,
                                        },
                                      });
                                    }}>
                                    {getFileExtension(
                                      noteValueItem?.mayamd_note?.key,
                                    ) === 'pdf' ? (
                                      <Image
                                        source={pdf}
                                        style={{ height: 40, width: 41 }}
                                      />
                                    ) : (
                                      <Image
                                        source={getImageUrl(
                                          noteValueItem?.thumbnail,
                                        )}
                                        style={{ height: 40, width: 41 }}
                                      />
                                    )}
                                    <View style={{ paddingTop: 5 }}>
                                      <Text style={{ ...h11_SemiBold }}>
                                        {moment(
                                          noteValueItem?.date ||
                                          noteValueItem?._createdOn,
                                        ).format('MM/DD/YY')}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        position: 'absolute',
                                        right: 5,
                                        top: -1,
                                        minHeight: 10,
                                        minWidth: 10,
                                        borderRadius: 5,
                                        backgroundColor: noteValueItem?.flag
                                          ? 'red'
                                          : 'green',
                                        display:
                                          noteValueItem?.seen_status === false
                                            ? 'flex'
                                            : 'none',
                                      }}
                                    />
                                  </TouchableOpacity>
                                  : null);
                                })
                              : null}
                          </View>
                        </View>
                      );
                    },
                  )}
                </View>
              </View>
            );
          })
        )}
      </ScrollView>
    </View>
  );
};

export default Notes;
