import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import MedicalHistoryForm from './MedicalHistoryForm';

let header = {
  title: 'Add Medical History',
  actions: [cancelButton(), save()],
};

export default MedicalHistoryForm({header});
