import React, {useMemo} from 'react';
import {TouchableOpacity, View} from '../index';
import {GradientButton} from '../buttons/Buttons';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Editor} from 'react-draft-wysiwyg';
import './htmlInput.css';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
const HTMLInput = (props) => {
  const {
    label = 'Fetch',
    labelStyle,
    labelProps,
    value,
    setValue = () => {},
    field = 'loading',
    data,
    inputStyle,
    mobileInputStyle,
    inputProps,
    error,
    errorStyle,
    errorProps,
    disabled,
    ...restProps
  } = props || {};
  // console.log(`>@>Puru ~ file: HTMLInput.js ~ line 27 ~ data`, data);

  const onChange = (value) => {
    setValue({field: field, value, data});
  };
  let e = useMemo(() => {
    const html = data[field] || '';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState || '';
    }
  }, []);
  return (
    <View>
      <Editor
        // editorState={e}
        onEditorStateChange={(editorState) => {
          let p = draftToHtml(convertToRaw(editorState.getCurrentContent()));
          onChange(p);
        }}
      />
    </View>
  );
  return (
    <TouchableOpacity
      style={{width: 200, cursor: 'pointer', maxHeight: 33}}
      onPress={() => {
        onChange(!value);
      }}>
      <GradientButton label={label} />
    </TouchableOpacity>
  );
};

export default HTMLInput;
