import React from 'react';
import ReactDOM from 'react-dom';

import {View} from '../../app-components';
const getModifiedData = data => {
  if (!data.length) {
    return data;
  }
  let field = '_createdOn';
  let get_PresentData = data.filter(value => value[field]) || [];
  let not_presentData = data.filter(value => !value[field]) || [];
  get_PresentData.sort((a, b) => {
    return new Date(b[field]).getTime() - new Date(a[field]).getTime();
  });
  data = [...get_PresentData, ...not_presentData];
  return data;
};

class InvertedList extends React.Component {
  state = {};

  componentWillMount() {
    let {getRef} = this.props;

    getRef && getRef(this);
  }

  componentDidMount() {
    this.invertScrolling(true);
  }

  componentWillUnmount() {
    this.invertScrolling(false);
  }

  onEndReached = () => {
    if (this.listRef) {
      let containerHeight = this.listRef.offsetHeight;
      let scrollHeight = this.listRef.scrollHeight;
      let scrollTop = this.listRef.scrollTop;
      if (scrollHeight - scrollTop == containerHeight) {
        this.props.onEndReached && this.props.onEndReached();
      }
    }
  };

  invertScrolling = invert => {
    if (this.listRef) {
      if (invert) {
        this.listRef.addEventListener('wheel', this.invertedWheelEvent);
      } else {
        this.listRef.removeEventListener('wheel', this.invertedWheelEvent);
      }
    }
  };

  invertedWheelEvent = e => {
    const styles = window.getComputedStyle(this.listRef);
    let lineHeight = styles.getPropertyValue('line-height') || 16 * 1.2;
    let deltaX = e.wheelDeltaX || -e.deltaX;
    let deltaY = e.wheelDeltaY || -e.deltaY;
    if (e.deltaMode === 1) {
      deltaX *= 2 * parseFloat(lineHeight);
      deltaY *= 2 * parseFloat(lineHeight);
    }
    if (this.props.horizontal) this.listRef.scrollLeft += deltaX;
    else this.listRef.scrollTop += deltaY;
    e.preventDefault();
  };

  scrollToOffset = ({offset}) => {
    if (this.listRef) {
      ReactDOM.findDOMNode(this.listRef).scrollTop = offset;
    }
  };

  onScroll = e => {
    let {onScroll} = this.props;
    onScroll && onScroll(e.target);
    this.onEndReached();
  };

  render() {
    let {data = [], renderItem, style, keyExtractor, ...rest} = this.props;

    data = getModifiedData(data);

    let containerStyle = {
      overflow: 'auto',
      flexDirection: 'column',
      ...style,
    };
    return (
      <View
        {...rest}
        style={{...containerStyle, transform: 'scale(1,-1)'}}
        onScroll={this.onScroll}
        getRef={e => {
          this.listRef = e;
        }}>
        {data.map((row, index) => {
          const key = keyExtractor
            ? keyExtractor(row, index)
            : typeof row === 'object' && row.key !== undefined
            ? row.key
            : index;
          const item = renderItem({item: row, index});
          return React.cloneElement(item, {
            key,
            invertedCardStyle: {transform: 'scale(1,-1)'},
          });
        })}
      </View>
    );
  }
}

export default InvertedList;
