import {colors} from './colors';
import {shadow} from './shadows';
import {h16_Regular} from './fonts';
import {manazeV6Colors} from './manazeV6Colors';
import {Headline9, Headline13, Headline3, Headline11} from './Headlines';
import {standardDropDownTheme} from './dropDownTheme';

const {textColor87} = colors;

const {
  neutral_1,
  neutral_3,
  neutral_8,
  neutral_4,
  surface_white,
} = manazeV6Colors;

export const moreActionStyle = {
  style: {justifyContent: 'center', padding: 6},
  textStyle: {
    marginLeft: 12,
  },
  dropdownStyle: {
    width: 200,
    height: 'auto',
    maxHeight: 300,
    backgroundColor: surface_white,
    borderRadius: 4,
    ...shadow,
  },
  ...standardDropDownTheme,
};

export const toolBarTheme = {
  containerStyle: {
    height: 82,
    paddingLeft: 24,
    paddingRight: 24,
    justifyContent: 'center',
    backgroundColor: surface_white,
    borderBottomWidth: 2,
    borderBottomColor: neutral_8,
    overflow: 'hidden',
  },
  itemsStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleTextStyle: {
    paddingLeft: 13,
    color: neutral_1,
    ...Headline9,
  },
  actionContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionStyle: {
    containerStyle: {
      padding: 8,
      marginLeft: 4,
      alignItems: 'center',
      flexDirection: 'row',
    },
    iconStyle: {
      height: 20,
      width: 20,
      objectFit: 'contain',
      resizeMode: 'contain',
    },
    textStyle: {...h16_Regular, color: textColor87},
  },
  moreActionStyle: {
    ...moreActionStyle,
  },
};

export const screenHeaderToolBarTheme = {
  containerStyle: {
    height: 60,
    justifyContent: 'center',
    backgroundColor: surface_white,
  },
  itemsStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleTextStyle: {
    paddingLeft: 13,
    color: neutral_4,
    ...Headline13,
  },
  actionContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionStyle: {
    containerStyle: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 4,
      paddingRight: 4,
      // marginLeft: 4,
      alignItems: 'center',
      flexDirection: 'row',
    },
    iconStyle: {
      height: 20,
      width: 20,
      objectFit: 'contain',
      resizeMode: 'contain',
    },
    textStyle: {...h16_Regular, color: textColor87, paddingLeft: 16},
  },
  moreActionStyle: {
    ...moreActionStyle,
  },
};

export const screenFooterToolBarTheme = {
  containerStyle: {
    height: 60,
    justifyContent: 'center',
    backgroundColor: surface_white,
  },
  itemsStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleTextStyle: {
    paddingLeft: 13,
    color: neutral_4,
    ...Headline13,
  },
  actionContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionStyle: {
    containerStyle: {
      padding: 8,
      marginLeft: 4,
      alignItems: 'center',
      flexDirection: 'row',
    },
    iconStyle: {
      height: 20,
      width: 20,
      objectFit: 'contain',
      resizeMode: 'contain',
    },
    textStyle: {...h16_Regular, color: textColor87},
  },
  moreActionStyle: {
    ...moreActionStyle,
  },
};

export const stackHeaderTheme = {
  ...toolBarTheme,
};

export const stackModalHeaderTheme = {
  ...toolBarTheme,
  containerStyle: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: surface_white,
  },
  titleTextStyle: {
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline3,
    color: neutral_1,
  },
  subTitleTextStyle: {
    paddingTop: 3,
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline11,
    color: neutral_3,
  },
  actionStyle: {
    containerStyle: {
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      flexDirection: 'row',
    },
    iconStyle: {height: 24, width: 24, objectFit: 'contain'},
  },
};

export const stackFooterTheme = {
  ...toolBarTheme,
};

export const stackModalFooterTheme = {
  ...toolBarTheme,
};
