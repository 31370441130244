import {colors, bgs} from './colors';
import {dropDownShadow} from './shadows';
import {
  h10_Regular,
  h12_Regular,
  h12_SemiBold,
  h10_SemiBold,
  h8_Regular,
  h10_Medium,
} from './fonts';

const {
  yellowColor,
  whiteBg,
  transparent,
  lightGray,
  chatBg,
  GrayColor,
  chatContainerBg,
  ownMessageBg,
} = bgs;

const {
  textColor87,
  textColor37,
  textColor34,
  errorColor,
  textColor70,
  secondaryColor,
  activeColor,
  textWhite,
  dodgerBlue,
} = colors;

const style = {
  listContainerStyle: {
    boxStyle: {
      flex: 1,
      backgroundColor: chatContainerBg,
    },
    listStyle: {
      flex: 1,
      backgroundColor: chatContainerBg,
    },
  },
  messageInputStyle: {
    seperator:{
      width: 1,
      height: 29,
      marginRight: 12,
      marginLeft: 8,
      opacity: 0.34,
      backgroundColor: '#979797',
    },
    boxStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 15,
      paddingBottom: 14,
      paddingLeft: 10,
      paddingRight: 20,
      boxShadow: '0 2px 10px 4px rgba(0, 0, 0, 0.03)',
      backgroundColor: 'white',
    },

    textBoxProps: {
      backgroundColor: transparent,
      height: 40,
      borderRadius: 4,
      borderColor: textColor37,
      paddingLeft: 12,
      outline: 'none',
      justifyContent: 'center',
      borderWidth: 1,
      color: textColor70,
      ...h12_Regular,
    },
    sendIconStyle: {
      backgroundColor: yellowColor,
      height: 40,
      width: 48,
      borderRadius: 4,
      marginLeft: 8,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  ///>>>>>>>>>>>>>>>> other
  othersMessageContainerStyle: {
    boxStyle: {
      justifyContent: 'flex-start',
      marginTop: 16,
      marginBottom: 16,
      marginRight: 5,
      marginLeft: 5,
    },

    messageBubbleStyle: {
      width: 0,
      height: 0,
      borderTopWidth: 4,
      borderBottomWidth: 4,
      borderRightWidth: 4,
      borderLeftWidth: 4,
      borderRightColor: whiteBg,
      borderTopColor: whiteBg,
      borderBottomColor: transparent,
      borderLeftColor: transparent,

      marginLeft: 8,
    },
    messageContainerStyle: {
      containerStyle: {
        maxWidth: '80%',
      },
      boxStyle: {
        paddingTop: 12,
        paddingBottom: 12,
        paddingRight: 12,
        paddingLeft: 12,
        //  boxShadow:"0 1px 2px 0 rgba(195, 195, 195, 0.24)",
        backgroundColor: whiteBg,
      },
      nameTextStyle: {
        ...h12_Regular,
        color: secondaryColor,
        marginBottom: 4,
      },
      msgTextStyle: {
        ...h12_Regular,
        color: textColor87,
      },
    },
    timeBoxStyle: {
      boxStyle: {
        FlexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: 4,
      },
      timeTextStyle: {
        ...h10_Medium,
        color: textColor34,
      },
    },
  },
  /// own message style
  ownMessageContainerStyle: {
    boxStyle: {
      justifyContent: 'flex-end',
      marginRight: 5,
      marginLeft: 5,
      marginTop: 16,
      marginBottom: 16,
    },
    messageContainerStyle: {
      containerStyle: {
        maxWidth: '80%',
      },
      boxStyle: {
        paddingTop: 12,
        paddingBottom: 12,
        paddingRight: 12,
        paddingLeft: 12,
        backgroundColor: ownMessageBg,
      },
      nameTextStyle: {
        ...h10_SemiBold,
        marginBottom: 4,
        color: secondaryColor,
      },
      msgTextStyle: {
        ...h12_Regular,
        color: textColor87,
      },
    },
    messageBubbleStyle: {
      width: 0,
      height: 0,
      borderTopWidth: 4,
      borderBottomWidth: 4,
      borderLeftWidth: 4,
      borderRightWidth: 4,
      borderRightColor: transparent,
      borderTopColor: ownMessageBg,
      borderBottomColor: transparent,
      borderLeftColor: ownMessageBg,
      marginRight: 8,
    },
    timeBoxStyle: {
      boxStyle: {
        FlexDirection: 'row',
        alignItems: 'flex-end',
        marginTop: 4,
      },
      timeTextStyle: {
        ...h10_Medium,
        color: textColor34,
      },
    },
  },

  avatarStyle: {
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      height: 36,
      width: 36,
      borderRadius: 18,
      backgroundColor: "rgb(249, 249, 249)",
    },
    text: {
      ...h12_SemiBold,
      color: textColor87,
    },
    image: {
      height: 36,
      width: 36,
      borderRadius: 18,
    },
  },
  downArrowStyle: {
    boxStyle: {
      position: 'absolute',
      zIndex: 1000,
      right: 30,
      bottom: 100,
      backgroundColor: yellowColor,
      height: 30,
      width: 30,
      borderRadius: 15,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  unreadCountStyle: {
    viewStyle: {
      height: 15,
      width: 15,
      backgroundColor: errorColor,
      borderRadius: 7.5,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      zIndex: 2000,
      right: 40,
      bottom: 125,
    },
    textStyle: {
      ...h10_Regular,
      color: whiteBg,
    },
  },

  loadMoreContainer: {
    position: 'absolute',
    zIndex: 1000,
    borderRadius: 4,
    boxShadow: '0 1px 2px 0 rgba(195, 195, 195, 0.24)',
    backgroundColor: 'white',
    left: '50%',
    top: 10,
    padding: 5,

    height: 40,
    width: 40,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const notificationChatStyle = {
  imageStyle: {
    height: 20,
    paddingRight: 30,
  },
  notificationViewStyle: {
    ...dropDownShadow,
    // padding: 5,
    // paddingLeft: 20,
    position: 'absolute',
    top: -10,
    right: 20,
    height: 15,
    width: 15,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    backgroundColor: whiteBg,
  },
  notificationTextStyle: {...h10_Regular, color: activeColor},
};

const notificationStyle = {
  imageStyle: {
    width: 20,
    height: 20,
    paddingTop: 10,
    paddingRight: 20,
  },
  notificationViewStyle: {
    ...dropDownShadow,
    position: 'absolute',
    top: -10,
    right: 5,
    height: 15,
    width: 15,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    backgroundColor: whiteBg,
  },
  notificationTextStyle: {...h10_Regular, color: activeColor},
};

const notificationHeaderStyle = {
  container: {
    borderwidth: 1,
    paddingTop: 0,
    paddingRight: 30,
  },
  imageStyle: {
    height: 20,
    width: 20,
    objectFit: 'contain',
  },
  footerViewStyle: {
    backgroundColor: whiteBg,
    alignItems: 'center',
    paddingTop: 10,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    paddingBottom: 10,
  },
  footerTextStyle: {
    color: dodgerBlue,
    ...h10_Regular,
  },
  headerContainerStyle: {
    flexDirection: 'row',
    backgroundColor: GrayColor,
    alignItems: 'center',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  headerViewStyle: {
    paddingLeft: 5,
    paddingBottom: 10,
    paddingTop: 10,
    flex: 1,
  },
  headerTextStyle: {
    color: textWhite,
    ...h10_Regular,
  },
  headerCrossStyle: {
    paddingLeft: 5,
    paddingBottom: 12,
    paddingTop: 12,
    paddingRight: 5,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    backgroundColor: whiteBg,
    alignItems: 'flex-end',
  },
  modalConatinerTip: {
    width: 0,
    height: 0,
    borderTopWidth: 8,
    borderBottomWidth: 8,
    borderRightWidth: 8,
    borderLeftWidth: 8,
    borderRightColor: whiteBg,
    borderTopColor: whiteBg,
    borderBottomColor: GrayColor,
    borderLeftColor: whiteBg,
    marginRight: 15,
    alignSelf: 'flex-end',
  },
  modalContainer: {
    borderRadius: 5,
    ...dropDownShadow,
    backgroundColor: whiteBg,
  },
};

export {
  style,
  notificationStyle,
  notificationHeaderStyle,
  notificationChatStyle,
};
