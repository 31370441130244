import React from 'react';
import {Text, View} from '../../react-core-components';

const CardFooter = props => {
  let {footerStyle} = props
  return<View style={footerStyle}><Text>Footer</Text></View>;
};

CardFooter.defaultProps = {
};

export default CardFooter;
