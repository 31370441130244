import Toast from '../../npms/react-toast';
import React from 'react';
import {StandardButton, ButtonLoader} from '../buttons/Buttons';

export const invoke = ({id, label, showLoader, invoke, ...restProps} = {}) => {
  label = label || 'Done';
  if (!id && typeof label === 'string') {
    id = label;
  }
  return {
    id: label,
    label,
    type: 'invoke',
    invoke,
    render: ({state} = {}) => {
      let {submitting} = state || {};
      if (typeof label === 'function') {
        label = label({data: state.data});
      }
      let loaderProps = showLoader
        ? {
            loaderComponent: <ButtonLoader />,
            loading: submitting,
          }
        : void 0;
      return <StandardButton label={label} {...loaderProps} {...restProps} />;
    },
  };
};

export const invokeAction = async (props = {}) => {
  let {
    action: {
      invoke,
      preMessage,
      postMessage,
      showLoader,
      reloadEvent,
      closeView,
    } = {},
    eventDispatcher,
    navigation,
  } = props;
  if (!invoke) {
    return;
  }
  if (showLoader && showLoader === true) {
    showLoader = 'appLoader';
  }
  preMessage && Toast.show(preMessage);
  try {
    showLoader && eventDispatcher && eventDispatcher.notify(showLoader, true);
    let result = await invoke(props);
    postMessage && Toast.show(postMessage);
    if (reloadEvent && eventDispatcher) {
      if (Array.isArray(reloadEvent)) {
        reloadEvent.forEach(_event => eventDispatcher.notify(_event));
      } else {
        eventDispatcher.notify(reloadEvent);
      }
    }
    closeView && navigation.pop(closeView);
    return result;
  } catch (err) {
    Toast.error(err.message || err || 'Error in invoke');
  } finally {
    showLoader && eventDispatcher && eventDispatcher.notify(showLoader, false);
  }
};
