import {colors, bgs} from './colors';
import {h12_SansSemiBold, h14_Regular, h14_SemiBold} from './fonts';
import {
  descendingSortActiveIcon,
  descendingSortUnActiveIcon,
  ascendingSortUnActiveIcon,
  ascendingSortActiveIcon,
  downArrowActiveNew,
  downArrowNew,
  upArrowNew,
  upArrowActiveNew,
} from '../images';
import {manazeV6Colors} from './manazeV6Colors';
import {toolBarTheme} from './toolBarTheme';
import {Headline14, Headline9} from './Headlines';

const {surface_white, neutral_1, neutral_4} = manazeV6Colors;

const {
  textColor37,
  textColor40,
  textColor87,
  tabBackgroundColor,
  tableHoverColor,
} = colors;
const {borderColor20} = bgs;

export const sortableHeaderTheme = {
  /* display none is added to remove sortIcon visibility*/
  containerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  viewStyle: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStyle: {...h12_SansSemiBold, color: textColor37},
  imageTheme: {
    iconStyle: {height: 17, width: 9, objectFit: 'cover'},
    imageContainerStyle: {
      marginLeft: 10,
      marginRight: 5,
      flexDirection: 'row',
      alignItems: 'center',
    },
    imageViewStyle: {
      padding: 1,
    },
    descendingSortActiveIcon:downArrowActiveNew,
    descendingSortUnActiveIcon:downArrowNew,
    ascendingSortUnActiveIcon:upArrowNew,
    ascendingSortActiveIcon:upArrowActiveNew,
  },
};

export const customHeaderCellTheme = {
  containerStyle: {},
  viewStyle: {
    //alignItems: "center",
    justifyContent: 'center',
    paddingLeft: 10,
  },
  textStyle: {...h12_SansSemiBold, color: textColor37},
};
export const numberCellTheme = {
  textStyle: {
    ...h14_Regular,
    color: textColor87,
    numberOfLines: 1,
  },
};

export const textCellTheme = {
  textStyle: {
    ...h14_Regular,
    color: textColor87,
    numberOfLines: 1,
  },
};

export const numberHeaderCellTheme = {
  textStyle: {
    ...h14_SemiBold,
    color: textColor40,
    numberOfLines: 2,
  },
};
export const textHeaderCellTheme = {
  textStyle: {
    ...h14_SemiBold,
    color: textColor40,
    numberOfLines: 2,
  },
};

export const selectionCellTheme = {};
export const selectionHeaderCellTheme = {
  viewStyle: {
    paddingRight: 5,
  },
};

export const tableTheme = {
  leftGap: 6,
  rightGap: 6,
  cardScrollViewStyle: {
    flex: 1,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },
  headerRowContainerStyle: {
    height: 56,
    paddingLeft: 6,
    paddingRight: 6,
    backgroundColor: surface_white,
    borderBottomWidth: 2,
    borderBottomColor: tabBackgroundColor,
    borderTopWidth: 2,
    borderTopColor: tabBackgroundColor,
  },
  headerRowColumnContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
  },
  headerRowColumnIconStyle: {
    width: 16,
    height: 16,
    marginRight: 12,
  },
  headerRowColumnTextStyle: {
    ...h14_Regular,
    color: textColor40,
    numberOfLines: 2,
  },
  rowStyle: {
    rowWrapperStyle: {
      borderBottomColor: borderColor20,
      borderBottomWidth: 1,
    },
    cardWrapperStyle: {
      marginTop: 2,
      marginBottom: 2,
    },
    rowContainerStyle: {
      minHeight: 48,
      paddingLeft: 6,
      paddingRight: 6,
    },
    rowSelectedContainerStyle: {
      backgroundColor: tableHoverColor,
    },
    rowHoverContainerStyle: {
      backgroundColor: tableHoverColor,
    },
    rowColumnContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      paddingTop: 8,
      paddingBottom: 8,
      justifyContent: 'center',
    },
    rowColumnTextStyle: {
      ...h14_Regular,
      color: textColor87,
      numberOfLines: 1,
    },
    hoverActionsWrapperStyle: {
      top: 1,
      bottom: 1,
      right: 1,
      backgroundColor: tableHoverColor,
    },
    selectedHoverActionsWrapperStyle: {
      backgroundColor: tableHoverColor,
    },
    hoverActionsContainerStyle: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: 8,
    },
  },
  typeWiseWidth: {
    number: {
      width: 100,
    },
    selection: {
      width: 50,
    },
    custom: {
      width: 100,
    },
    moreActions: {
      width: 30,
    },
  },
};

export const tableMobileTheme = {
  leftGap: 6,
  rightGap: 6,
  cardScrollViewStyle: {
    flex: 1,
    backgroundColor: surface_white,
  },
  headerRowContainerStyle: {
    height: 34,
    paddingLeft: 6,
    paddingRight: 6,
    backgroundColor: '#FAFAFA',
  },
  headerRowColumnContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
  },
  headerRowColumnIconStyle: {
    width: 16,
    height: 16,
    marginRight: 12,
  },
  headerRowColumnTextStyle: {
    ...h14_Regular,
    color: textColor40,
    numberOfLines: 1,
  },
  rowStyle: {
    rowContainerStyle: {
      minHeight: 48,
      paddingLeft: 6,
      paddingRight: 6,
      backgroundColor: '#ffffff',
    },
    rowColumnContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      paddingTop: 8,
      paddingBottom: 8,
      justifyContent: 'center',
    },
    rowColumnTextStyle: {
      ...h14_Regular,
      color: textColor87,
      numberOfLines: 1,
    },
  },
  typeWiseWidth: {
    number: {
      width: 100,
    },
    selection: {
      width: 50,
    },
    custom: {
      width: 100,
    },
    moreActions: {
      width: 30,
    },
  },
};

export const tableFooterTheme = {
  ...toolBarTheme,
};
export const tableHeaderTheme = {
  ...toolBarTheme,
};

export const cardCellTheme = {
  style: {},
  primaryTitleStyle: {
    ...Headline9,
    color: neutral_1,
    numberOfLines: 1,
    fontWeight: 400,
  },
  contentContainerStyle: {},
  contentItemTextStyle: {
    ...Headline14,
    color: neutral_4,
  },
};

export const fileCellTheme = {
  linkTextStyle: {
    textDecoration: 'underline',
    color: 'gray',
  },
};
export const customTableTheme = {
  ...tableTheme,
  dataRowStyle: {
    containerStyle: {
      ...tableTheme.containerStyle,
      height: 73,
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
};
