import React from 'react';
import {getUser} from './AppServices';
import withGpsContext from './withGpsContext';
import uuid from 'uuid/v4';

export const EmergencyContext = React.createContext({});

class EmergencyContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unReadCount: void 0,
      getAllChatThreads: {},
      callBackReceived: false,
    };
  }

  callback = (data) => {
    this.setState({newData: data});
  };
  subscribeMeetingCallback = (data) => {};

  socketSubscription = () => {
    let {gpsStore} = this.props;
    let {organization: {_id: organizationId} = {}, userType} = getUser();
    this.socketUid = uuid();
    gpsStore.subscribeEvent({
      event: 'newEmergencyRequest',
      callback: this.callback,
    });
    // gpsStore.subscribeEvent({
    //   event: 'newCheckIn',
    //   callback: this.callback,
    // });
    gpsStore.emitDataOnConnect({
      uid: this.socketUid,
      event: 'subscribeGroup',
      data: {
        groupId: `${organizationId}__new`,
        sessionId: this.socketUid,
        source: 'web',
      },
      callback: this.subscribeMeetingCallback,
    });
  };
  unsubscribSocket = () => {
    let {gpsStore} = this.props;
    if (gpsStore && gpsStore.removeEmitDataOnConnect) {
      gpsStore.removeEmitDataOnConnect({uid: this.socketUid});
      gpsStore.unsubscribeEvent({
        event: 'newEmergencyRequest',
        callback: this.callback,
      });
      // gpsStore.unsubscribeEvent({
      //   event: 'newCheckIn',
      //   callback: this.callback,
      // });
    }
  };
  
  componentDidMount() {
    this.socketSubscription();
  }

  componentWillUnmount() {
    this.unsubscribSocket();
  }

  render() {
    return (
      <EmergencyContext.Provider value={{...this.state}}>
        {this.props.children}
      </EmergencyContext.Provider>
    );
  }
}

const EmergencyContextProviderHoc = withGpsContext(
  EmergencyContextProvider,
);

export {EmergencyContextProviderHoc};

export default (Component) => (props) => (
  <EmergencyContext.Consumer>
    {(value) => {
      return <Component appointmentStore={value} {...props} />;
    }}
  </EmergencyContext.Consumer>
);
