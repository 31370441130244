import Form from '../../app-components/form/Form';
import {submit, importFile} from '../../AppServices';
export default ({submitMessage, uri, header}) => {
  const ImportForm = Form({
    type: 'standardShadow',
    onSubmit: importFile({model: 'ImportHistoryLogs'}),
    uri,
    closeView: 1,
    reloadEvent: 'reloadimport',
    submitMessage,
    header,
    computations: {
      defaultSchoolBranch: {
        compute: async ({data}) => {
          console.log('lllllllllllllllll', data);
          let {model} = data;
          return {
            source: model,
          };
        },
        onChange: ['model'],
      },
    },
    formGroups: [
      {
        columnsPerRow: 2,
        labelDirection: 'vertical',
        columns: [
          {
            field: 'model',
            placeholder: 'Select',
            label: 'Type',
            mandatory: true,
            type: 'search',
            options: [
              'Drugs',
              'Specialities',
              'Procedures',
              'Diagnosis',
              // 'Dosages',
              'Allergies',
              'MedicalHistories',
              'SurgicalHistories',
              'SocialHistories',
              'Category',
              'Code',
              'Status',
              'TypeCode',
              'Format',
              'DeviceType',
              'Conditions',
              'ResultProfileCode',
              'AbsentReason',
            ],
            searching: true,
            showArrow: true,
          },

          {
            field: 'file',
            placeholder: 'File',
            label: 'File',
            uploadOptions: {s3: false},
            type: 'file',
          },
        ],
      },
    ],
  });

  return ImportForm;
};
