import isEmpty from 'lodash/isEmpty';
import React from 'react';

import Form from '../../../app-components/form/Form';
import MedicalHistory, {
  SymptomChecker,
} from './symptomCheckerAndMedicalHistory';

const ClinicalHistory = (props) => {
  const {
    clinicalHistory: {memberNoteHistory = [], chatHistory = []} = {},
    fullName = '',
    navigation = {},
  } = props;
  const ModifiedForm = Form({
    ...props,
    closeView: 1,
    fieldVariant: 'filled',
    validateOnChange: true,
    formGroups: [
      !isEmpty(memberNoteHistory) && {
        label: 'Symptom checker history',
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: () => {
                  return memberNoteHistory.map((item) => {
                    return (
                      <SymptomChecker response={item} fullName={fullName} />
                    );
                  });
                },
              },
            ],
          },
        ],
      },
      !isEmpty(chatHistory) && {
        label: 'Medical records',
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: () => {
                  return <MedicalHistory {...props} />;
                },
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default ClinicalHistory;
