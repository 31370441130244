import React, {Component} from 'react';
import StandardDropDown from './StandardDropDown';
import {View, Text, Image} from '../../react-core-components';
import {WithModal} from '../../react-with-modal';

export class DropDownAction extends Component {
  renderModal = props => modalProps => {
    const {renderModal, dropdownStyle, renderDropDown, ...restProps} = props;
    if (renderModal) {
      return renderModal(modalProps);
    } else {
      let {frameStyle, hideModal} = modalProps;
      let dropdownComponent = void 0;
      if (renderDropDown) {
        let Component = renderDropDown;
        if (typeof Component === 'function') {
          if (Component.prototype && Component.prototype.isReactComponent) {
            dropdownComponent = (
              <Component {...restProps} closeModal={hideModal} />
            );
          } else {
            dropdownComponent = Component({
              ...restProps,
              closeModal: hideModal,
            });
          }
        }
      }
      if (!dropdownComponent) {
        dropdownComponent = (
          <StandardDropDown {...restProps} closeModal={hideModal} />
        );
      }
      return (
        <View
          style={{
            overflow: 'hidden',
            backgroundColor: '#ffffff',
            minHeight: 50,
            minWidth: 50,
            zIndex: 10000,
            ...frameStyle,
            ...dropdownStyle,
          }}>
          {dropdownComponent}
        </View>
      );
    }
  };
  render() {
    let {
      autoHide = true,
      position,
      style,
      backdropStyle,
      dropdownStyle,
      iconStyle,
      textStyle,
      activeTextStyle,
      icon,
      activeIcon,
      text,
      children,
      active,
      onModalShow,
      ...restProps
    } = this.props;
    if (position && this.props[`${position}DropdownStyle`]) {
      dropdownStyle = this.props[`${position}DropdownStyle`];
    }
    if (active) {
      icon = activeIcon || icon;
    }
    return (
      <WithModal
        renderModal={this.renderModal({...restProps, dropdownStyle})}
        autoHide={autoHide}
        style={{...style, cursor: 'pointer'}}
        dropdownStyle={dropdownStyle}
        backdropStyle={backdropStyle}
        position={position}
        onModalShow={onModalShow}>
        {children || (
          <View style={{flexDirection: 'row'}}>
            {icon ? <Image style={iconStyle} source={icon} /> : void 0}
            {text ? (
              <Text
                style={{
                  ...textStyle,
                  ...((active && activeTextStyle) || {}),
                }}>
                {text}
              </Text>
            ) : (
              void 0
            )}
          </View>
        )}
      </WithModal>
    );
  }
}

export default DropDownAction;
