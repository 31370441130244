import WaitingRoom from './WaitingRoom';
import CancelModal from "./CancelModal";
export default {
  'patient-waiting-room': {
    screen: WaitingRoom,
    expanded: true,
  },

  'cancel-modal': {
    screen: CancelModal,
    expanded: true,
  },

};
