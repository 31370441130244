import React from 'react';
import {Text, TouchableOpacity, View, Image} from '../../app-components';
import Table from '../../app-components/table/Table';
import {h14_AvenirNext, h20_AvenirNext_500} from '../../theme/fonts';
import {getUser, getImageUrl, post} from '../../AppServices';
import {colors} from '../../theme/colors';
import {patientCardMDTheme, patientCardTheme} from '../../theme/cardTheme';
import moment from 'moment';
const {themeColor} = colors;

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

let uri = () => {
  const {doctor = {}} = getUser() || {};
  return {
    query: {
      id: 'requestLists',
      addOnFilter: {
        doctor: {_id: doctor?._id},
        status: 'pending',
      },
      paramValue: {
        period: {
          from: moment(new Date()).startOf('day').toDate(),
          to: moment(new Date()).endOf('day').toDate(),
        },
      },
    },
    model: 'Appointmentrequests',
  };
};

const cardDemo = {
  lg: {
    card: {
      ...patientCardTheme,
      left: [
        {
          items: [
            {
              value: ({navigation, item}) => {
                const {patient} = item;
                let {profile_picture} = patient;
                let imageUrl = getImageUrl(profile_picture?.file);
                return (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: 10,
                      paddingLeft: 10,
                    }}>
                    <Image
                      source={imageUrl}
                      style={{width: 90, height: 90, borderRadius: 90}}
                    />
                  </View>
                );
              },
            },
          ],
        },
      ],

      contentItems: [
        [
          {
            value: ({item}) => {
              const {patient = {}, request_type, appointment} = item || {};
              const {time, description} = appointment;
              const {name} = patient;

              return (
                <View style={{}}>
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        ...h20_AvenirNext_500,
                        marginTop: 10,
                        marginLeft: 22,
                      }}>
                      {name}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      marginLeft: 22,
                      marginTop: 6,
                    }}>
                    <Text
                      style={{
                        color: 'rgba(37, 29, 41, 0.54)',
                        ...h14_AvenirNext,
                        whiteSpace: 'normal',
                      }}>
                      {description}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 12,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: 8,
                        backgroundColor: '#f9f9f9',
                        marginLeft: 16,
                        paddingTop: 7,
                        paddingLeft: 13,
                        paddingBottom: 7,
                        paddingRight: 13,
                      }}>
                      <View style={{marginLeft: 10, paddingRight: 6}}>
                        <Text
                          style={{
                            ...h14_AvenirNext,
                            color: '#383838',
                          }}>
                          {capitalizeFirstLetter(request_type)}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        borderRadius: 8,
                        backgroundColor: '#f9f9f9',
                        marginLeft: 22,
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 11,
                        paddingRight: 10,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#383838',
                        }}>
                        {moment(time).format('MMM-DD-YYYY | hh:mm A')}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            },
          },
        ],
      ],

      right: [
        {
          align: 'right',
          items: [
            [
              {
                action: ({item, index, navigation, eventDispatcher}) => {
                  const {doctor = {}, organization = {}} = item;
                  return {
                    type: 'invoke',
                    invoke: async () => {
                      await post({
                        data: {...item},
                        updates: {status: 'approved'},
                        model: 'Appointmentrequests',
                      }).then((res) => {
                        eventDispatcher.notify('reloadRequests');
                      });
                    },
                  };
                },
                value: () => {
                  return (
                    <TouchableOpacity
                      style={{
                        backgroundColor: themeColor,
                        borderRadius: 8,
                        paddingTop: 7,
                        paddingLeft: 30,
                        paddingBottom: 7,
                        paddingRight: 30,
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 29,
                        marginTop: 12,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#fff',
                        }}>
                        Approve
                      </Text>
                    </TouchableOpacity>
                  );
                },
              },

              {
                action: ({item, index, navigation, eventDispatcher}) => {
                  const {doctor = {}, organization = {}} = item;
                  return {
                    type: 'invoke',
                    invoke: async () => {
                      await post({
                        data: {...item},
                        updates: {status: 'rejected'},
                        model: 'Appointmentrequests',
                      }).then((res) => {
                        eventDispatcher.notify('reloadRequests');
                      });
                    },
                  };
                },
                value: () => {
                  return (
                    <TouchableOpacity
                      style={{
                        backgroundColor: themeColor,
                        borderRadius: 8,
                        paddingTop: 7,
                        paddingLeft: 30,
                        paddingBottom: 7,
                        paddingRight: 30,
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 29,
                        marginTop: 12,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#fff',
                        }}>
                        Reject
                      </Text>
                    </TouchableOpacity>
                  );
                },
              },
            ],
            {
              value: () => {
                return <View style={{}} />;
              },
            },
          ],
        },
      ],
    },
  },

  md: {
    card: {
      ...patientCardMDTheme,

      left: [
        {
          items: [
            {
              value: ({navigation, item}) => {
                let {setScreenState} = navigation;
                const {patient} = item;
                let {profile_picture} = patient;
                let imageUrl = getImageUrl(profile_picture?.file);
                return (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Image
                      source={imageUrl}
                      style={{width: 90, height: 90, borderRadius: 90}}
                    />
                  </View>
                );
              },
            },
          ],
        },
      ],
      contentItems: [
        {
          align: 'left',
          position: 'top',
          value: ({item}) => {
            const {patient = {}, request_type, appointment} = item || {};
            const {time, description} = appointment;
            const {name} = patient;
            return (
              <View>
                <Text
                  style={{
                    ...h20_AvenirNext_500,
                  }}>
                  {name}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 6,
                  }}>
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      color: 'rgba(37, 29, 41, 0.54)',
                    }}>
                    {description}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    minWidth: 250,
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 8,
                      backgroundColor: '#f9f9f9',
                      paddingTop: 7,
                      paddingLeft: 9,
                      paddingBottom: 7,
                      paddingRight: 9,
                      marginTop: 8,
                    }}>
                    <View style={{alignItems: 'flex-start'}}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#383838',
                        }}>
                        {request_type}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 8,
                      backgroundColor: '#f9f9f9',
                      paddingTop: 7,
                      paddingLeft: 9,
                      paddingBottom: 7,
                      paddingRight: 9,
                      marginTop: 8,
                    }}>
                    <View style={{alignItems: 'flex-start'}}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#383838',
                        }}>
                        {moment(time).format('MMM-DD-YYYY | hh:mm A')}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            );
          },
        },
      ],
      right: [
        {
          align: 'right',
          items: [
            {
              action: ({item, index, eventDispatcher}) => {
                return {
                  type: 'invoke',
                  invoke: async () => {
                    await post({
                      data: {...item},
                      updates: {status: 'approved'},
                      model: 'Appointmentrequests',
                    }).then((res) => {
                      eventDispatcher.notify('reloadRequests');
                    });
                  },
                };
              },
              value: () => {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                    }}>
                    <TouchableOpacity
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: themeColor,
                        paddingTop: 7,
                        paddingLeft: 16,
                        paddingBottom: 7,
                        paddingRight: 16,
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 8,
                        minWidth: 100,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: themeColor,
                        }}>
                        Approve
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              },
            },
            {
              action: ({item, index, eventDispatcher}) => {
                return {
                  type: 'invoke',
                  invoke: async () => {
                    await post({
                      data: {...item},
                      updates: {status: 'rejected'},
                      model: 'Appointmentrequests',
                    }).then((res) => {
                      eventDispatcher.notify('reloadRequests');
                    });
                  },
                };
              },
              value: () => {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                    }}>
                    <TouchableOpacity
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: themeColor,
                        paddingTop: 7,
                        paddingLeft: 16,
                        paddingBottom: 7,
                        paddingRight: 16,
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 8,
                        minWidth: 100,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: themeColor,
                        }}>
                        Reject
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              },
            },

            {
              value: () => {
                return <View style={{}} />;
              },
            },
          ],
        },
      ],
    },
  },
};
export default Table({uri, reloadEvent: 'reloadRequests', ...cardDemo});
