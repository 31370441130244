import {cancelButton} from '../../../../app-components/action/CloseAction';
import {save} from '../../../../app-components/action/SaveAction';
import locationform from './locationform';

let header = {
  title: 'Add Clinic Location',
  actions: [cancelButton(), save()],
};

export default locationform({header});
