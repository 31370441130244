import {getLastYears} from '../../../../Utility/getLastYears/getLastYearsAndMonth';

export const yearlyFlaggingLabels = ['Total', 'Screening', 'Lab'];

export const dataForBarGraphFilter = [
  {label: 'Registration', value: 'Registration'},
  {label: 'Screening', value: 'Screening'},
  {label: 'Lab Result', value: 'Lab Result'},
];

export const dataForFlaggingFilter = [
  {label: 'All', value: 'All'},
  {label: 'Screening', value: 'Screening'},
  {label: 'Lab Result', value: 'Lab Result'},
];

export const yearData = [
  {label: 'Last 12 months', value: 'Last 12 months'},
  {label: 'All', value: 'All'},
  ...getLastYears(),
];
