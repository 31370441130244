import React, {useEffect, useState} from 'react';
import {View} from '../../../../app-components';
import {getData, getUser} from '../../../../AppServices';
import {listUri} from '../../../../Queries';
import Table from '../../../../app-components/table/Table';
import GoogleMap from '../../../../app-components/GoogleMap';
import {colors} from '../../../../theme/colors';
import {h14_AvenirNext, h14_Medium} from '../../../../theme/fonts';
import {serialNumber} from '../../../RPM/rpmUtils';
const {backdropColor70} = colors;

let uri = ({navigation}) => {
  const {state: {params: {item = ''} = {}} = {}} = navigation;
  let {organization} = getUser();
  if (!organization) {
    organization = item;
  }
  return listUri({
    model: 'OrganizationLocations',
    id: 'organtzationLocationsLists',
    filter: {
      organization,
    },
  });
};
const tableData = {
  lg: {
    columns: [
      {
        render: serialNumber,
        header: 'S.No.',
        width: 80,
      },
      {
        type: 'text',
        field: 'branchName',
        header: 'Branch Name',
        width: 300,
      },
      {
        type: 'text',
        field: 'branchLocation.description',
        header: 'Branch Location',
      },
    ],
    hoverActions: [
      {
        value: 'Edit',
        align: 'right',
        textStyle: {
          color: '#9a9a9a',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'edit-location',
              params: {item},
            },
          };
        },
      },
      // {
      //   value: 'Delete',
      //   position: 'center',
      //   align: 'right',
      //   textStyle: {
      //     color: 'rgb(241,79,79, 0.87)',
      //     fontWeight: 500,
      //     ...h14_Medium,
      //   },
      //   action: {
      //     type: 'link',
      //     link: (props) => {
      //       let {item} = props;
      //       return {
      //         view: 'delete-masterData',
      //         modal: true,
      //         params: {
      //           item,
      //           label: 'Speciality',
      //           deleteAction: () => removeData({item, model: 'Specialities'}),
      //           reloadEvent: 'reloadSpeciality',
      //         },
      //         modalProps: {
      //           autoHide: true,
      //           height: 250,
      //           backdropColor: backdropColor70,
      //         },
      //       };
      //     },
      //   },
      // },
    ],
  },
  sm: {
    columns: [
      {
        type: 'text',
        field: 'branchName',
        header: 'Branch Name',
        width: 100,
      },
      {
        type: 'moreActions',
        width: 50,
        actions: [
          {
            text: 'Edit',
            textStyle: {
              color: '#9a9a9a',
              fontWeight: 500,
              ...h14_AvenirNext,
            },
            action: ({item}) => {
              return {
                type: 'link',
                link: {
                  view: 'edit-speciality',
                  params: {item},
                },
              };
            },
          },
          // {
          //   text: 'Delete',
          //   textStyle: {
          //     color: 'rgb(241,79,79, 0.87)',
          //     fontWeight: 500,
          //     ...h14_AvenirNext,
          //   },
          //   action: {
          //     type: 'link',
          //     link: (props) => {
          //       let {item} = props;
          //       return {
          //         view: 'delete-masterData',
          //         modal: true,
          //         params: {
          //           item,
          //           label: 'Speciality',
          //           deleteAction: () =>
          //             removeData({item, model: 'Specialities'}),
          //           reloadEvent: 'reloadSpeciality',
          //         },
          //         modalProps: {
          //           autoHide: true,
          //           height: 250,
          //           backdropColor: backdropColor70,
          //         },
          //       };
          //     },
          //   },
          // },
        ],
      },
    ],
  },
};

let LocationsTable = Table({
  uri,
  ...tableData,
  reloadEvent: 'reloadOrgantizationLocations',
});

const ClinicLocation = (props) => {
  const {navigation, eventDispatcher} = props;
  const [locations, setLocations] = useState([]);
  const {state: {params: {item = ''} = {}} = {}} = navigation;
  let {organization} = getUser();
  if (!organization) {
    organization = item;
  }
  const getLocationsData = () => {
    getData({
      model: 'OrganizationLocations',
      id: 'organtzationLocationsLists',
      filter: {
        organization,
      },
    })
      .then((res) => {
        const {data} = res;
        if (Array.isArray(data)) {
          setLocations(data);
        }
        console.log(`>@>Puru ~ file: index.js ~ line 174 ~ data`, data);
      })
      .catch((error) => {
        console.log(`>@>Puru ~ file: index.js ~ line 177 ~ error`, error);
      });
  };

  useEffect(() => {
    eventDispatcher.listen('reloadOrgantizationLocations', () => {
      getLocationsData();
    });
    return () => {
      eventDispatcher.unlisten('reloadOrgantizationLocations');
    };
  }, []);
  useEffect(() => {
    getLocationsData();
  }, []);
  return (
    <View style={{flex: 1, flexDirection: 'row', overflow: 'hidden'}}>
      <View style={{flex: 1.5, overflow: 'hidden'}}>
        <LocationsTable {...props} />
      </View>
      <View style={{flex: 1}}>
        {locations.length ? (
          <GoogleMap
            latitude={32}
            longitude={33}
            mapHeight={'100%'}
            locations={locations}
            field={'branchLocation'}
          />
        ) : (
          <View style={{flex: 1, height: '100%'}} />
        )}
      </View>
    </View>
  );
};

export default ClinicLocation;
