import React from 'react';
import Table from '../../app-components/table/Table';
import {listUri} from '../../Queries';
import {removeData} from '../../AppServices';
import {colors} from '../../theme/colors';
import {h14_AvenirNext, h14_Medium} from '../../theme/fonts';
import {serialNumber} from '../RPM/rpmUtils';

const {backdropColor70} = colors;

let uri = () => {
  return listUri({model: 'CcmCheckList', id: 'ccmCheckLists'});
};
const tableData = {
  lg: {
    columns: [
      {
        width: 50,
      },
      {
        render: serialNumber,
        header: 'S.No.',
        width: 150,
      },
      {
        type: 'text',
        field: 'question',
        header: 'Question',
        key: 'index',
      },
    ],
    hoverActions: [
      {
        value: 'Edit',
        align: 'right',
        textStyle: {
          color: '#9a9a9a',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'edit-ccm',
              params: {item},
            },
          };
        },
      },
      {
        value: 'Delete',
        position: 'center',
        align: 'right',
        textStyle: {
          color: 'rgb(241,79,79, 0.87)',
          fontWeight: 500,
          ...h14_Medium,
        },
        action: {
          type: 'link',
          link: (props) => {
            let {item} = props;
            return {
              view: 'delete-masterData',
              modal: true,
              params: {
                item,

                label: 'CCM Question',
                deleteAction: () =>
                  removeData({item, model: 'CcmCheckList'}),
                reloadEvent: 'reloadCCM',
              },
              modalProps: {
                autoHide: true,
                height: 250,
                backdropColor: backdropColor70,
              },
            };
          },
        },
      },
    ],
  },
  sm: {
    columns: [
      {
        render: serialNumber,
        header: 'S.No.',
        width: 50,
      },
      {
        type: 'text',
        field: 'question',
        header: 'Question',
        key: 'index',
      },
      {
        type: 'moreActions',
        width: 50,
        actions: [
          {
            text: 'Edit',
            align: 'right',
            textStyle: {
              color: '#9a9a9a',
              fontWeight: 500,
              ...h14_AvenirNext,
            },
            action: ({item}) => {
              return {
                type: 'link',
                link: {
                  view: 'edit-ccm',
                  params: {item},
                },
              };
            },
          },
          {
            text: 'Delete',
            position: 'center',
            align: 'right',
            textStyle: {
              color: 'rgb(241,79,79, 0.87)',
              fontWeight: 500,
              ...h14_AvenirNext,
            },
            action: {
              type: 'link',
              link: (props) => {
                let {item} = props;
                return {
                  view: 'delete-masterData',
                  modal: true,
                  params: {
                    item,
                    label: 'CCM Question',
                    deleteAction: () =>
                      removeData({item, model: 'CcmCheckList'}),
                    reloadEvent: 'reloadCCM',
                  },

                  modalProps: {
                    autoHide: true,
                    height: 250,
                    backdropColor: backdropColor70,
                  },
                };
              },
            },
          },
        ],
      },
    ],
  },
};
export default Table({
  uri,
  ...tableData,
  reloadEvent: 'reloadCCM',
});
