import React from 'react';
import {Image, Text, View} from '../../react-core-components';
import {resolveValue, resolveVisibleExp} from './CardUtility';
import {Avatar as CardAvatar} from '../../react-avatar';
import WithAction from './WithAction';

let CardItemComponent = props => {
  let {source = 'content', itemInfo} = props;
  if (!itemInfo) {
    return null;
  }

  let {
    icon,
    value,
    color,
    flex,
    numberOfLines,
    style,
    textStyle,
    iconContainerStyle,
    iconStyle,
    prefix,
    suffix,
    prefixStyle,
    suffixStyle,
    isDefaultText,
    resizeMode = 'contain',
  } = itemInfo;

  style = style || props[`${source}ItemStyle`];
  textStyle = textStyle || props[`${source}ItemTextStyle`];
  iconContainerStyle =
    iconContainerStyle || props[`${source}ItemIconContainerStyle`];
  iconStyle = iconStyle || props[`${source}ItemIconStyle`];

  let valueComponent = void 0;
  if (value !== undefined) {
    if (React.isValidElement(value)) {
      valueComponent = value;
    } else {
      textStyle = {
        ...textStyle,
      };
      if (color) {
        if (typeof color === 'function') {
          color = color({value});
        }
        if (color !== undefined) {
          textStyle.color = color;
        }
      }
      if (numberOfLines) {
        textStyle.numberOfLines = numberOfLines;
      }

      if (Array.isArray(value)) {
        value = value.join(';');
      } else if (value && typeof value === 'object') {
        value = JSON.stringify(value);
      } else if (typeof value !== 'string') {
        value = value.toString ? value.toString() : JSON.stringify(value);
      }
      let prefixComponent = void 0;
      let suffixComponent = void 0;
      if (!isDefaultText) {
        if (prefix) {
          if (prefixStyle) {
            prefixComponent = <Text style={prefixStyle}>{prefix}</Text>;
          } else {
            value = prefix + value;
          }
        }
        if (suffix) {
          if (suffixStyle) {
            suffixComponent = <Text style={suffixStyle}>{suffix}</Text>;
          } else {
            value = value + suffix;
          }
        }
      }
      valueComponent = <Text style={textStyle}>{value}</Text>;
      if (prefixComponent || suffixComponent) {
        valueComponent = (
          <View style={{flexDirection: 'row', flex: 1, overflow: 'hidden'}}>
            {prefixComponent}
            {valueComponent}
            {suffixComponent}
          </View>
        );
      }
    }
  }
  if (icon) {
    valueComponent = (
      <View style={{flexDirection: 'row'}}>
        {icon ? (
          <View style={iconContainerStyle}>
            <Image source={icon} style={iconStyle} resizeMode={resizeMode} />
          </View>
        ) : (
          void 0
        )}
        {valueComponent}
      </View>
    );
  }
  style = {
    ...style,
  };
  if (flex !== undefined) {
    style.flex = flex;
    style.overflow = 'hidden';
  }
  return <View style={style}>{valueComponent}</View>;
};

CardItemComponent.defaultProps = {
  contentItemIconContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
  },
};

CardItemComponent = WithAction(CardItemComponent);

const CardItem = props => {
  let {
    selected,
    itemInfo,
    defaultItemText,
    avatarStyle,
    selectedAvatarStyle,
    Avatar = CardAvatar,
    ...restProps
  } = props;
  if (!itemInfo) {
    return;
  }
  let {item} = restProps;
  let {
    field,
    value,
    text,
    render,
    icon,
    iconField,
    defaultText = defaultItemText,
    skipDefaultText,
    visible,
    action,
    actions,
    avatar,
    ...restItemInfo
  } = itemInfo;
  if (!resolveVisibleExp(visible, props)) {
    return null;
  }
  if (value === undefined) {
    value = render || text;
  }

  if (typeof icon === 'function') {
    icon = icon(props);
  } else if (!icon && iconField) {
    icon = resolveValue(item, iconField);
  }
  if (typeof value === 'function') {
    value = value(props);
  } else if (value === undefined && field) {
    value = resolveValue(item, field);
  }
  if (avatar) {
    avatarStyle = {
      ...avatarStyle,
      ...((selected && selectedAvatarStyle) || {}),
    };
    return (
      <Avatar
        {...avatarStyle}
        {...restItemInfo}
        icon={icon}
        value={value || ''}
      />
    );
  }
  let isDefaultText = false;
  if (
    (itemInfo.field !== undefined || itemInfo.value !== undefined) &&
    (value === undefined || value === null)
  ) {
    if (!skipDefaultText) {
      value = defaultText;
      isDefaultText = true;
    }
    icon = void 0;
  }
  if (icon === undefined && value === undefined) {
    return null;
  }
  return (
    <CardItemComponent
      {...restProps}
      itemInfo={{...restItemInfo, icon, value, isDefaultText}}
      action={action}
      actions={actions}
    />
  );
};

export default CardItem;
