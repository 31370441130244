import React from 'react';
import Table from '../../app-components/table/Table';
import {Text} from '../../app-components';
import {getUser} from '../../AppServices';
import {listUri} from '../../Queries';
import {h14_AvenirNext} from '../../theme/fonts';

let uri = () => {
  const {organization = {}} = getUser();
  return listUri({
    model: 'OrganizationSetting',
    id: 'organizationSettingList',
    filter: {organization_id: {_id: organization?._id}},
  });
};

const tableData = {
  lg: {
    columns: [
      {
        render: (props) => {
          let {item, setScreenState, screenState} = props;
          if (item && !screenState.data) {
            setScreenState({data: true});
          }
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {props.index + 1}
            </Text>
          );
        },
        header: 'S.No.',
        width: 50,
      },
      {
        type: 'text',
        field: 'organization_id.organization_name',
        header: 'Organisation',
        width: 200,
      },
      {
        header: 'Booking Fee',
        width: 300,
        render: ({item}) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {item?.organization_id?.currency + ' ' + item.booking_fee}
            </Text>
          );
        },
      },
      {
        header: 'Booking Duration',
        render: ({item}) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {item.booking_duration + ' mins'}
            </Text>
          );
        },
      },
    ],
    hoverActions: [
      {
        value: 'Edit',
        align: 'right',
        textStyle: {
          color: '#9a9a9a',
          fontWeight: 500,
          ...h14_AvenirNext,
          // paddingTop: 15,
        },
        action: ({item}) => {
          return {
            type: 'link',
            link: {
              view: 'edit-organisationSetting',
              params: {item},
            },
          };
        },
      },
    ],
  },
  sm: {
    action: ({item}) => {
      return {
        type: 'link',
        link: {
          view: 'edit-organisationSetting',
          params: {item},
        },
      };
    },
    columns: [
      {
        render: (props) => {
          let {item, setScreenState, screenState} = props;
          if (item && !screenState.data) {
            setScreenState({data: true});
          }
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {props.index + 1}
            </Text>
          );
        },
        header: 'S.No.',
        width: 50,
      },
      {
        type: 'text',
        field: 'organization_id.organization_name',
        header: 'Organisation',
        width: 120,
      },
      {
        header: 'Booking Fee',
        width: 100,
        render: ({item}) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {item?.organization_id?.currency + ' ' + item.booking_fee}
            </Text>
          );
        },
      },
      {
        header: 'Booking Duration',
        width: 100,
        render: ({item}) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {item.booking_duration + ' mins'}
            </Text>
          );
        },
      },
    ],
  },
};

export default Table({
  uri,
  ...tableData,
  reloadEvent: 'reloadOrganisationSetting',
});
