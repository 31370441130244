import TabNavigator from '../../app-components/tab/TabNavigator';
import {
  patientNameFilter,
  patientNumberFilter,
  patientAdharFilter,
} from '../../app-components/filter/MayaMDFilters';
import {getUser} from '../../AppServices';
import labAppointmentHoc from './labAppointmentListHoc';

let PreviosulyUploadedURI = () => {
  let {
    organization: {_id: organizationId},
  } = getUser() || {};
  return {
    query: {
      id: 'appointmentLists',
      organization_id: {_id: organizationId},
      addOnFilter: {
        investigate: {$exists: true, $not: {$size: 0}},
        lab_result: {$exists: true, $not: {$size: 0}},
      },
      sort: {_id: -1},
    },
    model: 'Appointments',
  };
};
let PendingUploadURI = () => {
  let {
    organization: {_id: organizationId},
  } = getUser() || {};
  return {
    query: {
      id: 'appointmentLists',
      organization_id: {_id: organizationId},
      addOnFilter: {
        investigate: {$exists: true, $not: {$size: 0}},
        $or: [
          {lab_result: {$exists: false}},
          {lab_result: {$exists: true, $size: 0}},
        ],
      },
      sort: {_id: -1},
    },
    model: 'Appointments',
  };
};
export default TabNavigator({
  tabs: {
    tab1: {
      actions: [
        // {
        //   render: (props) => {
        //     return patientNameFilter({field: 'patient_id', ...props});
        //   },
        // },
        // {
        //   render: (props) => {
        //     return patientNumberFilter({field: 'patient_id', ...props});
        //   },
        // },
        // {
        //   render: (props) => {
        //     return patientAdharFilter({field: 'patient_id', ...props});
        //   },
        // },
      ],
      label: 'Pending Requests',
      reloadEvent: 'reloadLabAppointment',
      screen: labAppointmentHoc({uri: PendingUploadURI}),
    },
    tab2: {
      actions: [
        // {
        //   render: (props) => {
        //     return patientNameFilter({field: 'patient_id', ...props});
        //   },
        // },
        // {
        //   render: (props) => {
        //     return patientNumberFilter({field: 'patient_id', ...props});
        //   },
        // },
        // {
        //   render: (props) => {
        //     return patientAdharFilter({field: 'patient_id', ...props});
        //   },
        // },
      ],
      label: 'Past Requests',
      reloadEvent: 'reloadLabAppointment',
      screen: labAppointmentHoc({uri: PreviosulyUploadedURI}),
    },
  },
});
