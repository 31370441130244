import React, {useCallback, useEffect, useState} from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {useMemo} from 'react';
import {fetch, getUser} from '../../../AppServices';

import './testResult.css';
import groupBy from 'lodash/groupBy';
import  sortBy from 'lodash/sortBy';

const TestResult = (props) => {
  const memberDetails = get(props, 'navigation.state.params.item.patient', {});
  const {columns = [], data = [],masterData=[]} = props;
  const columnsWidth = 100 / columns?.length;


  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, true);
  //   return () => window.removeEventListener('scroll', handleScroll, true);
  // }, []);

  let modifiedMasterData = {};

  if (!isEmpty(masterData)) {
    masterData.forEach((current) => {
      modifiedMasterData[current?.krsnaaTestName] = current;
    });
  }

  const getLowerAndUpperBoundForMaleANdFemale = useCallback(
    (ref_from, ref_to) => {
      let lowerRefranceRangeForMaleAndFemale = {};
      let upperRefranceRangeForMaleAndFemale = {};
      ref_from.split(',').map((item) => {
        if (item.indexOf('Male') !== -1) {
          lowerRefranceRangeForMaleAndFemale = {
            maleRefRange: parseFloat(item.match(/[+-]?\d+(\.\d+)?/g)[0]),
          };
        }
        if (item.indexOf('Female') !== -1) {
          lowerRefranceRangeForMaleAndFemale.femaleRefRange = parseFloat(
            item.match(/[+-]?\d+(\.\d+)?/g)[0],
          );
        }
      });
      ref_to.split(',').map((item) => {
        if (item.indexOf('Male') !== -1) {
          upperRefranceRangeForMaleAndFemale = {
            maleRefRange: parseFloat(item.match(/[+-]?\d+(\.\d+)?/g)[0]),
          };
        } else if (item.indexOf('Female') !== -1) {
          upperRefranceRangeForMaleAndFemale.femaleRefRange = parseFloat(
            item.match(/[+-]?\d+(\.\d+)?/g)[0],
          );
        }
      });
      return {
        lowerRefranceRangeForMaleAndFemale,
        upperRefranceRangeForMaleAndFemale,
      };
    },
    [],
  );

  const getAbnormalFlag = useCallback(
    (testValue, lowerBound = '', upperBound = '') => {
      if (lowerBound && parseFloat(testValue) < parseFloat(lowerBound)) {
        return 'L';
      } else if (upperBound && parseFloat(testValue) > parseFloat(upperBound)) {
        return 'H';
      }
      return '';
    },
    [],
  );

  let modifiedData = useMemo(() => {
    if (!isEmpty(data) && Object.keys(modifiedMasterData).length !== 0) {
      data.forEach((ele) => {
       ele.test_name = (ele?.test_name).trim();
       ele.testSortOrder= parseInt(modifiedMasterData[ele?.test_name]?.testSortOrder);
        const {
          ref_from = '',
          ref_to = '',
          krsnaaTestName = '',
          labCategory = '',
          labSubCategory = '',
        } = modifiedMasterData[ele?.test_name] || {};
        if (
          krsnaaTestName &&
          (ref_from?.indexOf(',') !== -1 || ref_to?.indexOf(',') !== -1)
        ) {
          delete ele?.ref_range;
          ele.ref_range =
            ref_from && ref_to
              ? `${ref_from} - ${ref_to}`
              : ref_from
              ? `>= ${ref_from}`
              : ref_to
                  ? `<= ${ref_to}`
                  : '';
          const result = getLowerAndUpperBoundForMaleANdFemale(
            ref_from,
            ref_to,
          );
          if (!isEmpty(result)) {
            if (memberDetails?.gender === 'male') {
              ele.abnormal_flag = getAbnormalFlag(
                ele?.test_val,
                result?.lowerRefranceRangeForMaleAndFemale?.maleRefRange,
                result?.upperRefranceRangeForMaleAndFemale?.maleRefRange,
              );
            } else if (memberDetails?.gender === 'female') {
              ele.abnormal_flag = getAbnormalFlag(
                ele?.test_val,
                result?.lowerRefranceRangeForMaleAndFemale?.femaleRefRange,
                result?.upperRefranceRangeForMaleAndFemale?.femaleRefRange,
              );
            }
          }
        } else if (ele?.test_name === krsnaaTestName) {
          delete ele?.ref_range;
          ele.ref_range =
            ref_from && ref_to
              ? `${ref_from} - ${ref_to}`
              : ref_from
              ? `>= ${ref_from}`
              : ref_to
                ? `<= ${ref_to}`
              : '';
          ele.abnormal_flag = getAbnormalFlag(ele?.test_val, ref_from, ref_to);
        }
        ele.labCategory = labCategory?.labCategoryName;
        ele.labCategorySortOrder = parseInt( labCategory?.sortOrder);

        ele.labSubCategory = labSubCategory?.labCategoryName;
        ele.labSubCategorySortOrder = parseInt(labSubCategory?.sortOrder);
        
      });
      return data;
    }
  }, [
    data,
    getAbnormalFlag,
    getLowerAndUpperBoundForMaleANdFemale,
    memberDetails,
    modifiedMasterData,
  ]);

  let myNewData = {};
  Object.entries(
    groupBy(sortBy(data,'labCategorySortOrder'), 'labCategory'),
  ).map(([labCategory, val], index) => {
    myNewData[labCategory] = groupBy(sortBy(val,'labSubCategorySortOrder'), 'labSubCategory');
  });

  // const handleScroll = () => {
  //   var header = document.getElementById('myHeader');
  //   var scrollTop = document.getElementById('scrollTop');

  //   if (scrollTop?.getBoundingClientRect().y < 200) {
  //     header.classList.add('sticky');
  //   } else {
  //     header.classList.remove('sticky');
  //   }
  // };

  const getColumn = useCallback(() => {
    return columns.map((item) => (
      <p
        style={{
          width: `${columnsWidth}%`,
          fontSize: '14px',
          fontWeight: 'bold',
          color: 'black',
        }}
        key={item}>
        {item?.column}
      </p>
    ));
  }, [columns, columnsWidth]);

  const getRow = useCallback(
    (data) => {
      return (
        !isEmpty(data || modifiedData) &&
        (data || modifiedData).map((item, key) => {
          return (
            <tr
              key={key}
              style={{
                minHeight: '48px',
                overflow: 'hidden',
                borderWidth:
                  key + 1 === (data || modifiedData)?.length
                    ? null
                    : '0px 0px 1px',
                borderStyle:
                  key + 1 === (data || modifiedData)?.length ? null : 'solid',
                boxSizing: 'border-box',
                borderBottomColor:
                  key + 1 === (data || modifiedData)?.length
                    ? null
                    : 'rgba(151, 151, 151, 0.2)',
              }}>
              {columns.map(({field}) => (
                <td
                  style={{
                    width: `${columnsWidth}%`,
                    padding: '10px',
                    fontSize: '14px',
                    fontWeight: item?.abnormal_flag ? 'bold' : null,
                    color: item?.abnormal_flag
                      ? 'rgba(255, 0, 0, 0.9)'
                      : 'black',
                  }}>
                  {item[field]}
                </td>
              ))}
            </tr>
          );
        })
      );
    },
    [columns, columnsWidth, modifiedData],
  );

  return (
    <div id="scrollTop" style={{position: 'relative', overflow: 'auto'}}>
      {Object.entries(myNewData).map(([labCategory, val1], index) => {
        return (
          <div>
            <div className="lab-category">
              <div>{labCategory != 'undefined' ? labCategory : 'OTHER'}</div>
            </div>
            <div class="header" id="myHeader">
              {getColumn()}
            </div>
            {Object.entries(val1).map(([labSubCategory, val2], index) => (
              <div>
                {labSubCategory != 'undefined' ? (
                  <div className="lab-sub-category">{labSubCategory}</div>
                ) : null}

                <table class="content">
                  {getRow(sortBy(val2, 'testSortOrder'))}
                </table>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default TestResult;
