import React, {useEffect} from 'react';
import {Linking,View, Text} from '../../app-components'
import {
  h32_SansSemiBold,
} from '../../theme/fonts';
import {invoke} from '../../AppServices';
import { ActivityIndicator } from '../../app-components';
import {colors} from '../../theme/colors';
const {themeColor}=colors
const Refresh = (props) => {
  useEffect(() => {
    (async () => {
      const result = await invoke({
        id: 'createConnectedAccount',
      });
      const {url = ''} = result || {};
      if(!url){
        return null
      }
      Linking.openURL(url);
    })();
  }, []);

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Text style={{...h32_SansSemiBold,marginBottom:50,color:themeColor}}>Refreshing... Stripe</Text>
      <View style={{marginTop:10,backgroundColor:"red"}}>
        <ActivityIndicator size={"large"}  color={themeColor}/>
      </View>
    </View>
  );
};

export default Refresh;
