import Demo from './Demo';
import clinic from './clinic';
import patients from './Patients';
import masterData from './masterData';
import analytics from './analytics';
import contactSupport from './contact-support';
import doctorPatient from './PatientQueue';
import manageInviteLinks from './manageInviteLinks';
import myPatient from './myPatient';
import Schedule from './Schedule';
import PatientWaitingRoom from './PatientWaitingRoom';
import Profile from './Profile';
import GenricComponent from './GenericComponent';
import PaymentAnaltyics from './PaymentAnaltyics';
import FollowUp from './FollowUp';
import SuperAdminAnalytics from './SuperAdminAnalytics';
import StripeRefreshPage from './StripeRefreshPage';
import FavouriteDoctor from './FavouriteDoctor';
import Maya from './MayaMdAuth';
import MayaMDPatient from './MayaMDPatient';
import PatientRequests from './PatientRequests';
import RPM from './RPM';
import SuperAdminRPM from './SuperAdminRpm';
import PatientReading from './PatientReading';
import ClinicSignUp from './ClinicSignUp';
import EmergencyRequest from './EmergencyRequest';
import Payments from './Payments';
import ChooseLanguage from './ChooseLanguage';
import PatientSignupByClinicAdmin from './PatientSignupByClinicAdmin';
import LabAppointment from './LabAppointment';

export default {
  ...RPM,
  ...PatientRequests,
  ...PaymentAnaltyics,
  ...analytics,
  ...Demo,
  ...clinic,
  ...patients,
  ...masterData,
  ...contactSupport,
  ...manageInviteLinks,
  ...myPatient,
  ...doctorPatient,
  ...manageInviteLinks,
  ...Schedule,
  ...PatientWaitingRoom,
  ...Profile,
  ...GenricComponent,
  ...FollowUp,
  ...SuperAdminAnalytics,
  ...StripeRefreshPage,
  ...FavouriteDoctor,
  ...Maya,
  ...MayaMDPatient,
  ...SuperAdminRPM,
  ...PatientReading,
  ...ClinicSignUp,
  ...EmergencyRequest,
  ...Payments,
  ...ChooseLanguage,
  ...PatientSignupByClinicAdmin,
  ...LabAppointment,
};
