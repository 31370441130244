import {save} from '../../../app-components/action/SaveAction';
import {cancelButton} from '../../../app-components/action/CloseAction';
import villageSetting from './villageSetting';

let header = {
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },

  actions: [cancelButton(), save()],
};

let editable = true;
export default villageSetting({
  header,
  editable,
  closeView: 1,
  formType: 'edit',
});
