import React from 'react';
import {View} from '../../react-core-components';
import {getResolvedStyle} from './Utility';

export default class Filled extends React.Component {
  render() {
    let {
      className,
      style,
      hoverStyle,
      activeStyle,
      errorStyle,
      disabledStyle,
      labelPosition,
      floatingLabelInputStyle,
      inputStyle,
      ...restProps
    } = this.props;
    let {isActive, hover, disabled, error, Component} = restProps;
    style = getResolvedStyle({
      isActive,
      hover,
      disabled,
      error,
      style,
      hoverStyle,
      activeStyle,
      errorStyle,
      disabledStyle,
    });
    if (labelPosition === 'floating') {
      inputStyle = floatingLabelInputStyle || inputStyle;
    }
    return (
      <View className={className} style={style}>
        <Component {...restProps} inputStyle={inputStyle} />
      </View>
    );
  }
}

Filled.defaultProps = {
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E1E1E1',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    minHeight: 44,
  },
  hoverStyle: {
    backgroundColor: '#f6f6f6',
  },
  activeStyle: {
    borderColor: '#4276EC',
  },
  errorStyle: {
    borderColor: '#FF8181',
  },
  inputStyle: {
    outline: 'none',
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    paddingTop: 13,
    paddingBottom: 9,
    paddingLeft: 12,
    paddingRight: 12,
    // marginLeft:6 ,
    // marginRight: 6,
    color: '#2C2C2C',
  },
  floatingLabelInputStyle: {
    outline: 'none',
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    paddingTop: 21,
    paddingBottom: 2,
    paddingLeft: 12,
    paddingRight: 12,
    color: '#2C2C2C',
  },

  inputErrorStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    alignItems: 'center',
  },
  inputSelectorContainerStyle: {
    paddingLeft: 8,
    paddingRight: 8,
    justifyContent: 'center',
  },
};
