import React from 'react';
import {
  WithLabel,
  WithInputWrapper,
  WithVariant,
} from '../../react-input-wrapper';
import AutosuggestInput from './AutosuggestInput';

class GooglePlaceInputField extends React.Component {
  keyExtractor = (item, index) => {
    return String(index);
  };
  getDisplayValue = (value) => {
    let {valueField} = this.props;
    if (value && valueField) {
      value = value[valueField];
    }
    return value;
  };

  onChangeValue = (value, e) => {
    const {onChangeValue, onChangeText} = this.props;
    onChangeValue && onChangeValue(value, e);
    onChangeText && onChangeText(value);
  };

  modifyAndSetValue = async ({item, e}) => {
    const {modifySelectedData} = this.props;
    try {
      if (modifySelectedData) {
        item = await modifySelectedData(item);
      }
      this.onChangeValue(item, e);
    } catch (err) {
      console.error('Error in google place field on select', err);
    }
  };

  onSelect = ({item, e}) => {
    let {onItemSelect} = this.props;
    this.modifyAndSetValue({item, e});
    onItemSelect && onItemSelect({item, props: this.props});
    return this.getDisplayValue(item);
  };

  clearValue = (e) => {
    this.onChangeValue(null, e);
  };

  onRenderNoDataCallback = ({result} = {}) => {
    if (result) {
      this.onChangeValue(result);
    }
  };

  renderNoData = (searchProps) => {
    const {renderNoData} = this.props;
    if (!renderNoData) {
      return;
    }
    return renderNoData({
      ...searchProps,
      onSubmitCallback: this.onRenderNoDataCallback,
    });
  };

  fetch = (searchProps) => {
    const {fetch, data} = this.props;
    return fetch({...searchProps, data}).then((result) => {
      return result.data;
    });
  };

  render() {
    const {
      value,
      valueField,
      fetch,
      renderNoData,
      onItemSelect,
      renderButtonText,
      afterSearchfetch,
      ...rest
    } = this.props;
    let displayValue = this.getDisplayValue(value);
    return (
      <AutosuggestInput
        value={displayValue}
        keyExtractor={this.keyExtractor}
        onItemSelect={this.onSelect}
        clearValue={this.clearValue}
        fetch={this.fetch}
        renderNoData={this.renderNoData}
        renderButtonText={
          renderButtonText
            ? (props) => renderButtonText({...props, value})
            : void 0
        }
        {...rest}
      />
    );
  }
}
GooglePlaceInputField = WithLabel(
  WithInputWrapper(WithVariant(GooglePlaceInputField)),
);

export default GooglePlaceInputField;
