import React from 'react';
import {View, FlatList, Platform} from '../../react-core-components';
import WithDataLoader from './WithDataLoader';

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
};

const getWidthStyle = (column, typeWiseWidth, minColumnWidth = 100) => {
  let {width, flex, type, minWidth = minColumnWidth} = column;
  let widthStyle = {};
  if (width) {
    widthStyle['width'] = width;
  } else if (flex) {
    widthStyle['flex'] = flex;
  } else if (type && typeWiseWidth && typeWiseWidth[type]) {
    const {width: typeWidth, flex: typeFlex} = typeWiseWidth[type];
    if (typeWidth) {
      widthStyle['width'] = typeWidth;
    } else if (typeFlex) {
      widthStyle['flex'] = typeFlex;
    }
  }
  if (!widthStyle.width && !widthStyle.flex) {
    widthStyle['flex'] = 1;
  }
  if (widthStyle.flex) {
    widthStyle.overflow = 'hidden';
    widthStyle.minWidth = minWidth;
  }
  return widthStyle;
};

class List extends React.PureComponent {
  keyExtractor = (item, index) => {
    return (item && item._id) || `${index}`;
  };

  onRowMouseEnter = callback => {
    if (this.lastOnMouseOver && this.lastOnMouseOver !== callback) {
      this.lastOnMouseOver();
    }
    this.lastOnMouseOver = callback;
  };

  onRowSelect = callback => {
    if (this.lastOnRowSelect && this.lastOnRowSelect !== callback) {
      this.lastOnRowSelect();
    }
    this.lastOnRowSelect = callback;
  };

  onScroll = e => {
    let {onScroll} = this.props;
    let {scrollHeight, scrollTop: currentHeight} = e;

    if (scrollHeight && currentHeight && currentHeight > scrollHeight / 2) {
      //if y has passed the 50% mark then we can load more data
      //we can load more records here
      onScroll && onScroll();
    }
    if (this.headerRef) {
      this.headerRef.scrollLeft = e.scrollLeft;
    }
  };

  getHeaderRef = headerRef => {
    this.headerRef = headerRef;
  };

  getHeaderRow = props => {
    const {
      renderHeaderRow,
      hideColumnHeader,
      headerRowContainerStyle,
      renderHeaderScrollColumn,
    } = this.props;
    let {columns, width} = props;
    if (!renderHeaderRow || !columns || !columns.length || hideColumnHeader) {
      return;
    }
    let extraColumn = void 0;
    if (renderHeaderScrollColumn && width) {
      let headerScrollColumn = renderHeaderScrollColumn();
      if (headerScrollColumn) {
        extraColumn = headerScrollColumn.columnComponent;
        width += headerScrollColumn.width || 0;
      }
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          overflow: 'hidden',
        }}
        getRef={this.getHeaderRef}>
        <View
          style={{
            width,
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
            ...headerRowContainerStyle,
          }}>
          {renderHeaderRow(props)}
          {extraColumn}
        </View>
      </View>
    );
  };

  renderItem = ({item, index}) => {
    let {renderRow, renderItem, columns, width, card, data} = this.props;
    renderItem = renderItem || renderRow;
    if (!renderItem) {
      return null; // todo
    }
    return renderItem({
      item,
      index,
      isFirst: index === 0,
      isLast: index === data.length - 1,
      card,
      columns,
      rowWidth: width,
      onRowMouseEnter: this.onRowMouseEnter,
      onRowSelect: this.onRowSelect,
    });
  };

  render() {
    let {
      card,
      StatusBar,
      renderRow,
      renderItem,
      containerStyle,
      scrollViewStyle,
      cardContainerStyle,
      cardScrollViewStyle,
      renderHeader,
      renderFooter,
      getRef,
      onLayout,
      renderNoData,
      skipHeaderOnNoData,
      ...restProps
    } = this.props;
    if (card) {
      containerStyle = cardContainerStyle;
      scrollViewStyle = cardScrollViewStyle;
    }
    let {loading, data} = restProps;
    let showNoData =
      renderNoData && loading === false && (!data || !data.length);
    return (
      <View style={{flex: 1, overflow: 'hidden', ...containerStyle}}>
        {loading && StatusBar && <StatusBar key="loading_table data" />}
        {getRenderComponent(renderHeader, restProps)}
        {showNoData && skipHeaderOnNoData
          ? void 0
          : this.getHeaderRow(restProps)}
        {showNoData ? (
          getRenderComponent(renderNoData, {})
        ) : (
          <FlatList
            {...(Platform.OS === 'web'
              ? {onLayout, onScroll: this.onScroll}
              : {})}
            getRef={getRef}
            data={data}
            keyExtractor={this.keyExtractor}
            style={scrollViewStyle}
            renderItem={this.renderItem}
          />
        )}
        {getRenderComponent(renderFooter, restProps)}
      </View>
    );
  }
}

export class ReactTable extends React.PureComponent {
  state = {};

  renderHeaderScrollColumn = () => {
    let {verticalScrollWidth, verticalScrollRequired} = this.state;
    if (verticalScrollRequired) {
      return {
        columnComponent: <View style={{width: verticalScrollWidth}} />,
        width: verticalScrollWidth,
      };
    }
  };

  getWidthWiseResolvedColumns = ({columns, clientWidth}) => {
    let {typeWiseWidth, leftGap = 0, rightGap = 0} = this.props;
    let columnWidthConsumed = 0;
    let totalFlexColumns = 0;

    let resolvedColumns = columns.map(column => {
      if (!column) {
        return null;
      }
      const columnStyle = getWidthStyle(
        column,
        typeWiseWidth,
        this.props.minColumnWidth,
      );
      let {width, flex} = columnStyle;
      if (width) {
        columnWidthConsumed += width;
      } else {
        totalFlexColumns += flex;
      }
      return {
        column,
        widthStyle: columnStyle,
      };
    });

    let totalWidthConsumed = void 0;
    if (columnWidthConsumed > 0 && clientWidth) {
      totalWidthConsumed = leftGap + rightGap;
      let availableFlexWidth =
        clientWidth - columnWidthConsumed - totalWidthConsumed;
      resolvedColumns = resolvedColumns.map(({column, widthStyle}) => {
        let {width, flex, minWidth} = widthStyle;
        let newWidthStyle = {};
        if (!width && flex) {
          if (availableFlexWidth > 0) {
            width = (availableFlexWidth * flex) / totalFlexColumns;
            width = Math.floor(width);
          } else {
            width = minWidth;
          }
        }
        if (width < minWidth) {
          width = minWidth;
        }
        newWidthStyle = {
          width,
        };
        totalWidthConsumed += width;
        return {
          column,
          widthStyle: newWidthStyle,
        };
      });
      if (totalWidthConsumed < clientWidth) {
        totalWidthConsumed = clientWidth;
      }
    }
    return {columns: resolvedColumns, width: totalWidthConsumed};
  };
  onBodyLayout = () => {
    if (this.bodyRef) {
      const {
        offsetHeight,
        offsetWidth,
        scrollHeight,
        scrollWidth,
        clientHeight,
        clientWidth,
      } = this.bodyRef;

      const {
        offsetHeight: stateOffsetHeight,
        offsetWidth: stateOffsetWidth,
        scrollHeight: stateScrollHeight,
        scrollWidth: stateScrollWidth,
        clientWidth: stateClientWidth,
        clientHeight: stateClientHeight,
      } = this.state;

      if (
        offsetHeight !== stateOffsetHeight ||
        offsetWidth !== stateOffsetWidth ||
        scrollHeight !== stateScrollHeight ||
        scrollWidth !== stateScrollWidth ||
        clientWidth !== stateClientWidth ||
        clientHeight !== stateClientHeight
      ) {
        let verticalScrollRequired = offsetWidth - clientWidth > 0;
        let verticalScrollWidth = offsetWidth - clientWidth;
        let stateToSet = {
          offsetHeight,
          offsetWidth,
          scrollHeight,
          scrollWidth,
          clientWidth,
          clientHeight,
          verticalScrollRequired,
          verticalScrollWidth,
        };
        this.setState(stateToSet);
      }
    }
  };

  getBodyRef = ref => {
    if (ref) {
      this.bodyRef = ref;
    }
  };

  getResolvedColumnsAndWidth = () => {
    const {columns} = this.props;
    let width = void 0;
    let finalColumns = columns;
    if (columns && columns.length) {
      let resolvedColumnsInfo = this.getWidthWiseResolvedColumns({
        columns,
        clientWidth: this.state.clientWidth,
      });
      finalColumns = resolvedColumnsInfo.columns;
      width = resolvedColumnsInfo.width;
    }
    return {width, columns: finalColumns};
  };

  render() {
    const {width, columns} = this.getResolvedColumnsAndWidth();
    return (
      <List
        {...this.props}
        width={width}
        columns={columns}
        getRef={this.getBodyRef}
        onLayout={this.onBodyLayout}
        renderHeaderScrollColumn={this.renderHeaderScrollColumn}
      />
    );
  }
}

ReactTable.defaultProps = {
  scrollViewStyle: {
    flex: 1,
  },
  cardScrollViewStyle: {
    flex: 1,
  },
};

ReactTable = WithDataLoader(ReactTable);

export default ReactTable;
