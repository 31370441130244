import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {getCurrency, invoke, post} from '../../AppServices';
import {h15_Medium, h16_Medium} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import moment from 'moment';
const {themeTextColor} = colors;

const PaymentWithZero = (props) => {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const {navigation = {}} = props;
  const {state: {params = {}} = {}} = navigation || {};
  const {
    item = {},
    selectedTimeSlot = {},
    userId = '',
    appointmentData,
    selectdDate,
    data: PatientData,
  } = params;
  const patientId = userId;
  const SucessHandler = ({createAppointmentResponse}) => {
    return invoke({
      id: 'successHandler',
      paramValue: {
        appointment_id: createAppointmentResponse?._id || '',
        appointment_offset: new Date().getTimezoneOffset(),
      },
    });
  };
  const createAppointment = async ({clientSecret}) => {
    const {
      cost_of_booking,
      _id,
      organization,
      appointment_type,
      mayaAISessionId = null,
    } = item || {};
    try {
      const res = await post({
        data: {},
        updates: {
          doctor_id: {_id},
          organization_id: {_id: organization?._id},
          booking_fee: cost_of_booking,
          date: selectdDate,
          time_slot: selectedTimeSlot,
          time: selectedTimeSlot?.start_time,
          appointment_type,
          sid: mayaAISessionId,
          patient_id: {_id: patientId},
          clientSecret,
          payment_gateway: 'N/A',
          status: 'scheduled',
          appointment_timezone_offset: new Date().getTimezoneOffset(),
          ...appointmentData,
        },
        model: 'Appointments',
      });

      const {result = []} = res || {};
      if (Array.isArray(result) && result.length) {
        setLoading(false);
        return result[0]?.result;
      }
    } catch (err) {
      setLoading(false);
      console.log('error in create apppintement', err);
    }
  };
   const appointmentWithZero=async()=> {
    setLoading(true);
    setLoader(true);
    const createAppointmentResponse = await createAppointment({
      clientSecret: '',
    });
    if (!createAppointmentResponse) {
      navigation.push({
        view: 'payment_failed',
        params: {
          message:
            'Selected Slot Booked by someone else, please try for another slot',
          isNavigate: true,
        },
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
        },
      });
      setLoader(false);
      setLoading(false);
      return null;
    }
    SucessHandler({
      createAppointmentResponse: createAppointmentResponse,
    })
      .then((res) => {
        navigation.push({
          view: 'booking-confirm',
          params: {
            ...params,
            res: createAppointmentResponse,
          },
        });
      })
      .catch((err) => {
        setLoader(false);
        setLoading(false);
        console.log(`>@>Puru ~ file: PaymentWithZero.js ~ line 184 ~ err`, err);
      });
  }
  useEffect(() => {
    appointmentWithZero();
  }, []);

  if (loader) {
    return <ActivityIndicator size="large" color={'#fff'} />;
  }

  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
          <Text
            style={{
              ...h16_Medium,
              color: themeTextColor,
            }}>{`Appointment with ${item?.name} at ${moment(
            selectedTimeSlot?.start_time,
          ).format('hh:mm A')} | ${moment(selectedTimeSlot?.start_time).format(
            'DD MMMM YYYY',
          )}`}</Text>
          <Text
            style={{
              ...h15_Medium,
              color: themeTextColor,
              marginTop: 8,
            }}>{`${props.t('Consultation Fee')} : ${getCurrency(item)} ${
            item?.cost_of_booking
          }`}</Text>
        </View>
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            appointmentWithZero();
          }}>
          <GradientButton
            label="Pay"
            loading={loading}
            indicatorColor={'#fff'}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = {
  mainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
    flex: 1,
  },
  container: {
    minWidth: 300,
    minHeight: 150,
    backgroundImage: 'linear-gradient(180deg, rgba(0,0,0,0.2), transparent)',
    // backgroundColor: themeContainer,
    borderRadius: 8,
    // alignItems: 'space-between',
    justifyContent: 'space-between',
    padding: 10,
  },
};
export default PaymentWithZero;
