import React from 'react';
import Table from '../../../app-components/table/Table';
import {getImageUrl} from '../../../AppServices';
import {profile_preview} from '../../../images';
import {Image} from '../../../npms/react-core-components';
import { tableText } from '../rpmUtils';

const uri = () => {
  return {
    query: {
      id: 'devicesList',
    },
    model: 'Devices',
  };
};
export default Table({
  uri,

  reloadEvent: 'deviceReload',
  action: ({item}) => {
    return {
      type: 'link',
      link: {
        view: 'detailSearchForm',
        params: {item},
      },
    };
  },
  columns: [
    {
      width: 100,
      header: 'Logo',
      field: 'image',
      render: ({item}) => {
        const {image} = item || {};
        let ImageUrl = image && getImageUrl(image?.file);
        return (
          <Image
            source={ImageUrl ? ImageUrl : profile_preview}
            style={{width: 30, height: 30}}
          />
        );
      },
    },
    {
      header: 'Device Name',
      type: 'text',
      field: 'name',
    },
    // {
    //   header: 'Chronic Disease',
    //   width: 400,
    //   render: ({item}) => {
    //     let {chronicDisease} = item;
    //     let nameList = chronicDisease.map((chronicDiseaseItem) => {
    //       return '' + chronicDiseaseItem?.name + '';
    //     });
    //     return tableText(nameList.join(', '));
    //   },
    // },
    {
      header: 'Parameter',
      render: ({item}) => {
        let {monitoringParameter} = item;
        let nameList = monitoringParameter.map(
          (monitoringParameterItem) => {
            return monitoringParameterItem?.parameter?.name;
          },
        );
        return tableText(nameList.join(', '));
      },
    },
    {
      header: 'Unit Measurement',
      render: ({item}) => {
        let {monitoringParameter} = item;
        let nameList = monitoringParameter.map(
          (monitoringParameterItem) => {
            return monitoringParameterItem?.unitMeasurement?.name;
          },
        );
        return tableText(nameList.join(', '));
      },
    },
  ],
});
