import React from 'react';
import {
  Text,
  View,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
} from '../../app-components';
import PatientCard from './PatientCard';
import {h15_Medium, h16_Medium} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import {getUser, gpsStore, fetch, getDependentPatient} from '../../AppServices';
import moment from 'moment';
import uuid from 'uuid/v4';
import PastAppointment from './PastAppointment';
const {themeColor, themeContainer, themeTextColor} = colors;
class WaitingRoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingRoomData: [],
      loading: false,
      socketData: {},
      selectedTab: 'upcomming',
    };
  }
  subscribeAndPersistMeeting = ({
    uid,
    gpsStore,
    data,
    unsubscribeEvent,
    callback,
  }) => {
    gpsStore.emitDataOnConnect({
      uid,
      event: 'subscribeGroup',
      unsubscribeEvent,
      data,
      callback,
    });
  };

  appointmentDatas = async () => {
    const {patient} = getUser();
    this.setState({loading: true});
    try {
      const result = await fetch({
        uri: {
          props: {
            query: {
              id: 'appointmentLists',
              addOnFilter: {
                status: 'scheduled',
                patient_id: {
                  _id: {
                    $in: [
                      ...(getDependentPatient() ? getDependentPatient() : []),
                      patient?._id,
                    ],
                  },
                },
              },
              sort: {time_slot: {start_time: -1}},
              paramValue: {
                period: {
                  from: moment(new Date()).startOf('day').toDate(),
                  // to: moment(new Date()).endOf('day').toDate(),
                },
              },
            },
            model: 'Appointments',
          },
        },
      });
      const {data = []} = result || {};
      if (Array.isArray(data)) {
        this.setState({waitingRoomData: data.reverse(), loading: false});
      } else {
        this.setState({loading: false});
      }
    } catch (err) {
      this.setState({loading: false});
    }
  };

  componentDidMount = () => {
    this.socketUid = uuid();
    this.appointmentDatas();

    this.subscribeAndPersistMeeting({
      uid: this.socketUid,
      gpsStore,
      data: {
        sessionId: this.socketUid,
        source: 'web',
      },
      callback: this.subscribeMeetingCallback,
    });
    gpsStore.subscribeEvent({
      event: 'videoCall',
      uid: this.socketUid,
      callback: this.callBackListener,
    });
    this.socketSubscription();
  };
  subscribeMeetingCallback = (data) => {
    console.log('callbackAppointmentUpdate=====================');
  };
  callBackListener = (event) => {
    console.log('callBackListener');

    let {result = {}} = event.updateResult;
    this.setState({socketData: result});
  };

  componentWillUnmount = () => {
    gpsStore.unsubscribeEvent({
      event: 'videoCall',
      uid: this.socketUid,
      callback: this.callBackListener,
    });
    gpsStore.removeEmitDataOnConnect({uid: this.socketUid});
    this.unsubscribSocket();
  };

  callbackAppointmentUpdate = (data) => {
    const {waitingRoomData} = this.state;
    this.setState({waitingRoomData: [data, ...waitingRoomData]});
  };
  callBackChange = (data) => {
    this.appointmentDatas();
  };

  socketSubscription = () => {
    let user_id = '';
    let {patient: {_id: patientId} = {}} = getUser();
    user_id = patientId;
    this.socketUid = uuid();
    gpsStore.subscribeEvent({
      event: 'newAppointmentCreated',
      callback: this.callbackAppointmentUpdate,
    });
    gpsStore.emitDataOnConnect({
      uid: this.socketUid,
      event: 'subscribeGroup',
      data: {
        groupId: `${user_id}__new`,
        sessionId: this.socketUid,
        source: 'web',
      },
      callback: this.subscribeMeetingCallback,
    });
  };
  // componentDidUpdate(){

  // }
  unsubscribSocket = () => {
    if (gpsStore && gpsStore.removeEmitDataOnConnect) {
      gpsStore.removeEmitDataOnConnect({uid: this.socketUid});
      gpsStore.unsubscribeEvent({
        event: 'newAppointmentCreated',
        callback: this.callbackAppointmentUpdate,
      });
    }
  };
  render() {
    const {t} = this.props;
    const {waitingRoomData, loading, socketData, selectedTab} = this.state;
    return (
      <View style={{flex: 1, overflow: 'hidden', ...theme.container}}>
        {true || (Array.isArray(waitingRoomData) && waitingRoomData.length) ? (
          <View style={theme.header}>
            <TouchableOpacity
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                borderBottomWidth: 1.5,
                ...(selectedTab === 'upcomming'
                  ? {
                      borderBottomColor: '#fff',
                    }
                  : {
                      cursor: 'pointer',
                      borderBottomWidth: 0,
                    }),
              }}
              onPress={() => {
                selectedTab !== 'upcomming' &&
                  this.setState({selectedTab: 'upcomming'}, () => {
                    this.appointmentDatas();
                  });
              }}>
              <Text style={theme.appointmentText}>
                {t('Upcoming Appointments')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                borderBottomWidth: 1.5,
                ...(selectedTab === 'past'
                  ? {
                      borderBottomColor: '#fff',
                    }
                  : {
                      cursor: 'pointer',
                      borderBottomWidth: 0,
                    }),
              }}
              onPress={() => {
                this.setState({selectedTab: 'past'});
              }}>
              <Text style={theme.appointmentText}>
                {t('Past Appointments')}
              </Text>
            </TouchableOpacity>
          </View>
        ) : (
          void 0
        )}
        {selectedTab === 'upcomming' ? (
          <ScrollView style={{flex: 1}}>
            {loading ? (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <ActivityIndicator size={'small'} color={'#fff'} />
              </View>
            ) : Array.isArray(waitingRoomData) && waitingRoomData.length ? (
              waitingRoomData.map((item, index) => (
                <View style={theme.main} className="appointment-card">
                  <PatientCard
                    item={item}
                    size={waitingRoomData.length}
                    index={index}
                    selectedTab={selectedTab}
                    {...this.props}
                    key={index.toString()}
                    socketData={socketData}
                    appointmentDatas={this.appointmentDatas}
                  />
                </View>
              ))
            ) : (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={{...h15_Medium, color: '#fff'}}>
                  You have no upcoming appointments
                </Text>
              </View>
            )}
          </ScrollView>
        ) : (
          <PastAppointment {...this.props} selectedTab={selectedTab} />
        )}
      </View>
    );
  }
}
const theme = {
  main: {
    flexBasis: 'auto',
    // marginTop: ,
    // marginLeft: 78,
    // marginRight: 78,
  },
  container: {
    marginTop: 20,
    // backgroundColor:themeColor,
    flex: 1,
  },
  header: {
    borderRadius: 8,
    // backgroundColor: 'rgba(231, 238, 245, 0.34)',
    backgroundColor: themeContainer,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
    // height: 50,
    minHeight: 70,
    // padding:15,
  },
  appointmentText: {
    ...h16_Medium,
    marginLeft: 10,
    marginRight: 10,
    color: themeTextColor,
  },
};
export default WaitingRoom;
