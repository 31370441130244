import React from 'react';
import {View} from '../../react-core-components';
import CardTitle from './CardTitle';
import CardContentLayout from './CardContentLayout';

const CardTitleBar = props => {
  const {
    title,
    primaryTitleStyle,
    secondaryTitleStyle,
    titleStyle,
    ...restProps
  } = props;
  let {
    primary,
    primaryField,
    secondary,
    secondaryField,
    primaryStyle = primaryTitleStyle,
    secondaryStyle = secondaryTitleStyle,
    left,
    right,
  } = title;

  let {item} = restProps;
  return (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      {left ? (
        <CardContentLayout
          {...restProps}
          direction="row"
          content={left}
          source={'left'}
        />
      ) : (
        void 0
      )}
      <View style={{flex: 1, overflow: 'hidden'}}>
        {primary || primaryField ? (
          <CardTitle
            key="primaryTitle"
            field={primaryField}
            value={primary}
            item={item}
            variant={'primary'}
            primaryTitleStyle={primaryStyle}
            titleStyle={titleStyle}
          />
        ) : (
          void 0
        )}
        {secondary || secondaryField ? (
          <CardTitle
            key="secondaryTitle"
            field={secondaryField}
            value={secondary}
            item={item}
            variant={'secondary'}
            secondaryTitleStyle={secondaryStyle}
            titleStyle={titleStyle}
          />
        ) : (
          void 0
        )}
      </View>
      {right && (
        <CardContentLayout
          {...restProps}
          direction="row"
          content={right}
          source={'right'}
        />
      )}
    </View>
  );
};

export default CardTitleBar;
