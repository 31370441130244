import React from 'react';
import {
  View,
  TouchableOpacity,
  ScrollView,
  Image,
  Text,
} from '../../../app-components';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import {autosuggestFetch, post} from '../../../AppServices';
import {roundCross} from '../../../images';
import {MultiAutoSuggestInput} from '../../../app-components/input-components/Editors';
import {OtherRenderSelector} from '../../../app-components/input-components/autoSuggest/renderRowCard';
import {h14_Medium} from '../../../theme/fonts';
import {colors, bgs} from '../../../theme/colors';
import {Headline9} from '../../../theme/Headlines';
const {themeColor} = colors;
const {roundedChipsbg} = bgs;
const Diagnosis = (props) => {
  const {data = {}, setValue = () => {}, collapse_handler = () => {}} = props;
  const {diagnosis = [], Diagnosis_others = []} = data;
  const {
    containerStyle,
    itemContainerStyle,
    itemTextStyle,
    crossContainerStyle,
    crossIconStyle,
    crossTextStyle,
  } = roundedChipsStyle;

  const matchValue = (value1, value2, fieldToMatch) => {
    if (fieldToMatch && value1 && value2) {
      return value1[fieldToMatch] === value2[fieldToMatch];
    } else {
      return value1 === value2;
    }
  };
  const removeItem = ({item, e}) => {
    if (item?.type) {
      let value = [...Diagnosis_others], idField = '_id';
      if (!item || !value || !value.length) {
        return;
      }
      let newValue = value.filter((doc) => {
        return !matchValue(doc, item, idField);
      });
      setValue({
        data,
        field: 'Diagnosis_others',
        value: newValue,
      });
    } else {
      let value = [...diagnosis], idField = '_id';
      if (!item || !value || !value.length) {
        return;
      }
      let newValue = value.filter((doc) => {
        return !matchValue(doc, item, idField);
      });
      setValue({
        data,
        field: 'diagnosis',
        value: newValue,
      });
    }
  };
  return (
    <>
      <View style={{marginTop: 10, marginBottom: 10}}>
        <MultiAutoSuggestInput
          data={data}
          field={'diagnosis'}
          renderSelector={(props) =>
            OtherRenderSelector({
              // otherValue: data[otherField] || other[field],
              onChangeOtherValue: (value, clearText) => {
                post({
                  data: {},
                  updates: {name_en: value, type: 'diagnosis'},
                  model: 'Other_master_data',
                }).then((res) => {
                  const {result = []} = res || {};
                  if (Array.isArray(result) && result.length) {
                    let val = result[0]?.result || {};
                    // console.log('asdfghjk', val);
                    setValue({
                      field: 'Diagnosis_others',
                      data,
                      value: [
                        ...(data?.Diagnosis_others
                          ? data.Diagnosis_others
                          : []),
                        val,
                      ],
                    });
                  }
                });
                clearText && clearText();
              },
              ...props,
            })
          }
          fetch={autosuggestFetch({
            model: 'Diagnosis',
            id: 'diagnoseLists',
            search: 'name',
          })}
          // value={diagnosis}
          value={diagnosis}
          idField={'_id'}
          placeholder="Write Illness/Disease Name "
          sugestionField="name"
          valueField="name"
          variant={'filled'}
          // renderSelector={true}
          renderRowProps={{
            primaryField: 'name',
          }}
          visibleChip={false}
          removeChipIcon={roundCross}
          chipsVariant={'rounded'}
          onChangeText={(value) => {
            // console.log('>>>>>>>>>>>>>>>', value);
            setValue({
              data,
              field: 'diagnosis',
              value,
            });
          }}
          searching={true}
        />
        <ScrollView style={containerStyle}>
          {[...diagnosis, ...Diagnosis_others].map((item, index) => {
            if (!item) {
              return null;
            }
            // console.log(item);
            let key = index;
            let displayValue =
              item?.type === 'diagnosis' ? item?.name_en : item?.name;
            return (
              <View
                key={key}
                style={{flexDirection: 'row', ...itemContainerStyle}}>
                <View style={{flex: 1}}>
                  <Text style={itemTextStyle} title={displayValue}>
                    {displayValue}
                  </Text>
                </View>
                <TouchableOpacity
                  style={{cursor: 'pointer', ...crossContainerStyle}}
                  onPress={(e) => removeItem({item, e})}
                >
                  {true ? (
                    <Image style={crossIconStyle} source={roundCross} />
                  ) : (
                    <Text style={crossTextStyle}>X</Text>
                  )}
                </TouchableOpacity>
              </View>
            );
          })}
        </ScrollView>
      </View>
      <View style={{flexDirection: 'row-reverse'}}>
        <TouchableOpacity
          style={{width: 90, marginRight: 30, cursor: 'pointer'}}
          onPress={() => {
            collapse_handler({selected: 'investigation'});
          }}>
          <GradientButton label="Next" />
        </TouchableOpacity>
      </View>
    </>
  );
};
const roundedChipsStyle = {
  containerStyle: {
    marginTop: 4,
    marginBottom: 4,
    maxHeight: 100,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  itemContainerStyle: {
    padding: 10,
    alignItems: 'center',
    borderWidth: 0,
    width: 188,
    borderRadius: 19,
    backgroundColor: roundedChipsbg,
    margin: 4,
  },
  itemTextStyle: {
    maxWidth: 140,
    numberOfLines: 1,
    ...h14_Medium,
    color: themeColor,
  },
  crossContainerStyle: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    marginLeft: 5,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
  },
  crossIconStyle: {
    height: 20,
    width: 20,
  },
  crossTextStyle: {
    ...Headline9,
    // color: primary_2,
  },
};
export default Diagnosis;
