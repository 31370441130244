import {isMobile} from '../index';
import {bottomTabTheme, bottomTabMobileTheme} from '../../theme/tabBarTheme';
import TabNavigator from './TabNavigator';

export default defs => {
  return TabNavigator({
    key: 'bottomTab',
    direction: 'bottom',
    theme: isMobile ? bottomTabMobileTheme : bottomTabTheme,
    ...defs,
  });
};
