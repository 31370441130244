import React from 'react';
import {StandardButton, ActionButton, ButtonLoader} from '../buttons/Buttons';
import {saveButtonTheme} from '../../theme/actionTheme';

export const save = ({id, label, text, role, visible, ...restProps} = {}) => {
  label = label || text || 'Save';
  if (!id && typeof label === 'string') {
    id = label;
  }
  return {
    id: label,
    label,
    type: 'save',
    role: role || 'edit',
    visible,
    render: ({state} = {}) => {
      if (typeof label === 'function') {
        label = label({data: state.data});
      }
      return (
        <ActionButton
          submitting={state?.submitting ? true : false}
          label={label}
          theme={saveButtonTheme}
          {...restProps}
        />
      );
    },
  };
};

export const saveAction = (props = {}) => {
  let {uid, eventDispatcher, action} = props;
  if (!uid && !eventDispatcher) {
    throw new Error(
      'EventDispatcher and uid is mandatory while pressing save action',
    );
  }
  eventDispatcher.notify(`${uid}-submit`, {
    action,
  });
};
