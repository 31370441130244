import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  Image,
} from '../../app-components';
import {lifeLine, tickIcon} from '../../images';
import Table from '../../app-components/table/Table';
import {
  h14_AvenirNext,
  h20_AvenirNext_500,
} from '../../theme/fonts';
import {
  setInitiallyLogged,
  setSelectedDoctor,
  getUser,
  getImageUrl,
} from '../../AppServices';
import {colors} from '../../theme/colors';
import {patientCardMDTheme, patientCardTheme} from '../../theme/cardTheme';
import moment from 'moment';
const {themeColor}=colors
let uri = () => {
  const {patient={}}=getUser()||{}
  return {
    query: {
      id: 'followupLists',
      addOnFilter: {
        patient: {_id: patient?._id},
      },
      // paramValue: {
      //   period: {
      //     from: moment(new Date()).startOf('day').toDate(),
      //     to: moment(new Date()).endOf('day').toDate(),
      //   },
      // },
    },
    model: 'followups',
  };
};

const cardDemo = {
  lg: {
    card: {
      ...patientCardTheme,
      left: [
        {
          items: [
            {
              value: ({navigation, item}) => {
                let {setScreenState} = navigation;
                const {doctor} = item;
                setScreenState({doctorCount: item.length});
                let {profile_picture} = doctor;
                let imageUrl = getImageUrl(profile_picture?.file);
                return (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: 10,
                      paddingLeft: 10,
                    }}>
                    <Image
                      source={imageUrl}
                      style={{width: 90, height: 90, borderRadius: 90}}
                    />
                  </View>
                );
              },
            },
          ],
        },
      ],
      contentItems: [
        [
          {
            value: ({item}) => {
              const {doctor = {}, organization,date} = item || {};
              const {
                name,
                specialities_procedures,
                qualification_description,
                experience_in_years,
              } = doctor;
              let qualification = ' | ' + qualification_description;
              return (
                <View style={{}}>
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        ...h20_AvenirNext_500,
                        marginTop: 10,
                        marginLeft: 22,
                      }}>
                      {name}
                    </Text>
                    <Image
                      style={{
                        marginTop: 11,
                        marginLeft: 11,
                        height: 20,
                        width: 20,
                      }}
                      source={tickIcon}
                    />
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      marginLeft: 22,
                      marginTop: 6,
                    }}>
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        // color: textColor87,
                        color: 'rgba(37, 29, 41, 0.54)',
                      }}>
                      {/* {item?.speciality?.name} */}
                      {specialities_procedures?.length > 0 &&
                        specialities_procedures.map((item, index) => {
                          let separator =
                            (index + 1 < specialities_procedures.length &&
                              ', ') ||
                            ' ';
                          return (
                            item.speciality?.name &&
                            item.speciality?.name + separator
                          );
                        })}
                    </Text>

                    <Text
                      style={{
                        color: 'rgba(37, 29, 41, 0.54)',
                        ...h14_AvenirNext,
                        whiteSpace: 'normal',
                      }}>
                      {qualification}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 12,
                      // marginBottom: 27,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: 8,
                        backgroundColor: '#f9f9f9',
                        marginLeft: 16,
                        paddingTop: 7,
                        paddingLeft: 13,
                        paddingBottom: 7,
                        paddingRight: 13,
                        // marginTop: 8,
                      }}>
                      <Image
                        style={{
                          width: 19,
                          height: 16,
                        }}
                        source={lifeLine}
                      />
                      <View style={{marginLeft: 10, paddingRight: 6}}>
                        <Text
                          style={{
                            ...h14_AvenirNext,
                            color: '#383838',
                          }}>
                          {experience_in_years} yrs exp
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        borderRadius: 8,
                        backgroundColor: '#f9f9f9',
                        marginLeft: 22,
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 11,
                        paddingRight: 10,
                        // marginTop: 8,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#383838',
                        }}>
                        {organization?.organization_name}
                      </Text>
                    </View>
                    <View
                      style={{
                        borderRadius: 8,
                        backgroundColor: '#f9f9f9',
                        marginLeft: 22,
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 11,
                        paddingRight: 10,
                        // marginTop: 8,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#383838',
                        }}>
                        {moment(date).format("MMM-DD-YYYY | hh:mm A")}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            },
          },
        ],
      ],
      right: [
        {
          align: 'right',
          items: [
            // {align: 'right'},
            {paddingTop: 16, paddingBottom: 16},
            {
              prefix: '$',
              field: 'doctor.cost_of_booking',
              textStyle: {
                color: 'rgba(56, 56, 56, 0.87)',
                ...h20_AvenirNext_500,
                // ...h18_AvenirNext,
                marginTop: 10,
                marginRight: 32,
              },
            },
            {
              prefix: 'for ',
              field: 'doctor.consultation_duration',
              suffix: 'min',
              textStyle: {
                ...h14_AvenirNext,
                color: 'rgba(37, 29, 41, 0.54)',
                marginTop: 6,
                marginRight: 32,
              },
            },

            [
              {
                action: ({item, index, navigation}) => {
                  const {doctor = {},organization={}} = item;
                  return {
                    type: 'link',
                    link: () => {
                      setSelectedDoctor(doctor.name);
                      setInitiallyLogged();
                      navigation.push({
                        view: 'doctor-profile',
                        params: {
                          item: {...doctor, appointment_type: 'follow_up',organization},
                          index,
                          openBooking: true,
                        },
                      });
                    },
                  };
                },
                value: () => {
                  return (
                    <TouchableOpacity
                      style={{
                        backgroundColor: themeColor,
                        borderRadius: 8,
                        paddingTop: 7,
                        paddingLeft: 30,
                        paddingBottom: 7,
                        paddingRight: 30,
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 29,
                        marginTop: 12,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#fff',
                        }}>
                        Book
                      </Text>
                    </TouchableOpacity>
                  );
                },
              },
            ],
            {
              value: () => {
                return <View style={{}} />;
              },
            },
          ],
        },
      ],
    },
  },
  md: {
    card: {
      // action: ({item, index}) => {
      //   return {
      //     type: 'link',
      //     link: {
      //       view: 'booking',
      //       params: {item, index},
      //     },
      //   };
      // },
      ...patientCardMDTheme,
      // title: {
      //   primaryField: 'name',
      //   // secondaryField: 'organization.organization_name',
      //   secondaryField: 'qualification_description',
      // },
      left: [
        {
          items: [
            {
              value: ({navigation, item}) => {
                let {setScreenState} = navigation;
                setScreenState({doctorCount: item.length});
                let {profile_picture} = item;
                let imageUrl = getImageUrl(profile_picture?.file);
                return (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      // paddingTop: 5,
                      // paddingLeft: 5,
                      // marginTop: 6,
                    }}>
                    <Image
                      source={imageUrl}
                      style={{width: 90, height: 90, borderRadius: 90}}
                    />
                  </View>
                );
              },
            },
          ],
        },
      ],
      contentItems: [
        {
          align: 'left',
          position: 'top',
          value: ({item}) => {
            const {
              name,
              specialities_procedures,
              qualification_description,
              experience_in_years,
            } = item;
            let qualification = ' | ' + qualification_description;
            return (
              // <View style={{flex: 1}}>
              <View>
                <Text
                  style={{
                    ...h20_AvenirNext_500,
                  }}>
                  {name}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    // marginLeft: 22,
                    marginTop: 6,
                  }}>
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      // color: textColor87,
                      color: 'rgba(37, 29, 41, 0.54)',
                    }}>
                    {/* {item?.speciality?.name} */}
                    {specialities_procedures?.length > 0 &&
                      specialities_procedures.map((item, index) => {
                        let separator =
                          (index + 1 < specialities_procedures.length &&
                            ', ') ||
                          ' ';
                        return (
                          item.speciality?.name &&
                          item.speciality?.name + separator
                        );
                      })}
                  </Text>

                  <Text
                    style={{
                      color: 'rgba(37, 29, 41, 0.54)',
                      ...h14_AvenirNext,
                      whiteSpace: 'normal',
                    }}>
                    {qualification}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    borderRadius: 8,
                    backgroundColor: '#f9f9f9',
                    // marginLeft: 16,
                    paddingTop: 7,
                    paddingLeft: 9,
                    paddingBottom: 7,
                    paddingRight: 9,
                    marginTop: 8,
                    width: 130,
                  }}>
                  <Image
                    style={{
                      width: 19,
                      height: 16,
                    }}
                    source={lifeLine}
                  />
                  <View style={{marginLeft: 5, alignItems: 'flex-start'}}>
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        color: '#383838',
                      }}>
                      {experience_in_years} yrs exp
                    </Text>
                  </View>
                </View>
              </View>
            );
          },
        },
      ],
      right: [
        {
          align: 'right',
          items: [
            // {align: 'right'},
            {
              prefix: '$',
              field: 'cost_of_booking',
              textStyle: {
                color: 'rgba(56, 56, 56, 0.87)',
                // ...h18_AvenirNext,
                ...h20_AvenirNext_500,
                // marginTop: 10,
                // marginRight: 32,
              },
            },
            {
              prefix: 'for ',
              field: 'consultation_duration',
              suffix: 'min',
              textStyle: {
                ...h14_AvenirNext,
                color: 'rgba(37, 29, 41, 0.54)',
                marginTop: 6,
                // marginTop: 4,
                // marginRight: 32,
              },
            },

            {
              action: ({item, index}) => {
                return {
                  type: 'link',
                  link: {
                    view: 'doctor-profile',
                    params: {item, index},
                  },
                };
              },
              value: () => {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                      // marginTop: 12,
                      // marginBottom: 20,
                    }}>
                    <TouchableOpacity
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: themeColor,
                        paddingTop: 7,
                        paddingLeft: 30,
                        paddingBottom: 7,
                        paddingRight: 16,
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // marginRight: 29,
                        marginTop: 8,
                      }}>
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: themeColor,
                        }}>
                        View Profile
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              },
            },

            {
              value: () => {
                return <View style={{}} />;
              },
            },
          ],
        },
      ],
    },
  },
};
export default Table({uri, reloadEvent: 'reloadSearchList', ...cardDemo});
