import React from 'react';
import {View} from '../../react-core-components';
import StackItem from './StackItem';

class AnimatedStackItem extends React.Component {
  state = {value: 100};
  componentDidMount() {
    this.setState({value: 0});
  }
  render() {
    let {view, theme = {}, index, screenState} = this.props;
    let {viewStyle, animationType, singleViewStyle = {}, sideViewStyle = {}} =
      view.theme || theme;

    let {expanded, visibleViews} = screenState || {};
    let isSingleViewVisible =
      index == 0 || !visibleViews || visibleViews === 1 || expanded
        ? true
        : false;
    let animStyle = {};
    if (index > 1 && (!visibleViews || visibleViews.length === 1)) {
      if (animationType === 'left') {
        animStyle = {
          transform: `translateX(-${this.state.value}%)`,
          transition: 'all 0.5s',
        };
      } else if (animationType === 'right') {
        animStyle = {
          transform: `translateX(+${this.state.value}%)`,
          transition: 'all 0.5s',
        };
      } else if (animationType === 'bottom') {
        animStyle = {
          transform: `translateY(+${this.state.value}%)`,
          transition: 'all 0.5s',
        };
      } else if (animationType === 'top') {
        animStyle = {
          transform: `translateY(-${this.state.value}%)`,
          transition: 'all 0.5s',
        };
      }
    }

    return (
      <View
        style={{
          ...viewStyle,
          ...(isSingleViewVisible ? singleViewStyle : sideViewStyle),
          // ...animStyle,
        }}>
        <StackItem {...this.props} />
      </View>
    );
  }
}

export default AnimatedStackItem;
