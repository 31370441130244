import React from 'react';
import {View, Image, TouchableOpacity} from '../../index';
import {sortableHeaderTheme} from '../../../theme/tableTheme';

export default (Component) => {
  class SortComponent extends React.Component {
    sort = (order) => () => {
      let {
        dataParams,
        setScreenState,
        column: {reverseSort},
      } = this.props;
      let sortField = this.getSortField();
      if (!sortField) {
        return;
      }
      let clearSort = false;
      let sortDirection = dataParams?.sort?.[sortField];
      let ascendingValue = 1;
      let descendingValue = -1;
      if (reverseSort) {
        [ascendingValue, descendingValue] = [descendingValue, ascendingValue];
      }
      if (order === 'des') {
        if (sortDirection === descendingValue) {
          clearSort = true;
        } else {
          sortDirection = descendingValue;
        }
      } else if ((order = 'asc')) {
        if (sortDirection === ascendingValue) {
          clearSort = true;
        } else {
          sortDirection = ascendingValue;
        }
      }
      let sortValue;
      if (clearSort) {
        sortValue = {
          dataParams: {
            ...dataParams,
          },
        };
        delete sortValue.dataParams.sort;
      } else {
        sortValue = {
          dataParams: {
            ...dataParams,
            sort: {[sortField]: sortDirection},
          },
        };
      }
      setScreenState && setScreenState(sortValue);
    };

    getSortField = () => {
      let {column: {sortable, field} = {}} = this.props;
      return sortable && typeof sortable === 'boolean'
        ? field
        : typeof sortable === 'string'
        ? sortable
        : void 0;
    };

    render() {
      let {column: {sortable, field, reverseSort} = {}, dataParams} =
        this.props;
      let renderComponent = <Component {...this.props} />;
      if (!sortable) {
        return renderComponent;
      }
      let sortField = this.getSortField();
      let asc = void 0;
      if (dataParams) {
        let {sort} = dataParams;
        let keys = sort && Object.keys(sort);
        let key = keys && keys[0];
        if (keys && sortField && keys.indexOf(sortField) > -1) {
          //find out if current sortable Field exists
          key = keys[keys.indexOf(sortField)];
        }
        if (key !== undefined && (key === field || key === sortable)) {
          asc = sort[key] === 1 ? false : true;
          if (reverseSort) {
            asc = !asc;
          }
        }
      }
      let {
        containerStyle = {},
        imageTheme: {
          iconStyle,
          imageContainerStyle,
          imageViewStyle,
          descendingSortActiveIcon,
          descendingSortUnActiveIcon,
          ascendingSortUnActiveIcon,
          ascendingSortActiveIcon,
        } = {},
      } = sortableHeaderTheme;
      let ascendingSortIcon;
      let descendingSortIcon;
      if (asc === undefined) {
        ascendingSortIcon = ascendingSortUnActiveIcon;
        descendingSortIcon = descendingSortUnActiveIcon;
      } else if (asc) {
        ascendingSortIcon = ascendingSortUnActiveIcon;
        descendingSortIcon = descendingSortActiveIcon;
      } else if (asc === false) {
        ascendingSortIcon = ascendingSortActiveIcon;
        descendingSortIcon = descendingSortUnActiveIcon;
      }
      let ascending = 'asc';
      let descending = 'des';

      return (
        <View style={containerStyle}>
          <View style={{overflow: 'hidden'}}>{renderComponent}</View>
          <View style={imageContainerStyle}>
            <TouchableOpacity
              onPress={this.sort(ascending)}
              style={imageViewStyle}>
              <Image style={iconStyle} source={ascendingSortIcon} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this.sort(descending)}
              style={imageViewStyle}>
              <Image style={iconStyle} source={descendingSortIcon} />
            </TouchableOpacity>
          </View>
        </View>
      );
    }
  }
  return SortComponent;
};
