import React from 'react';
import {
  View,
  Text,
  Linking,
  TouchableOpacity,
  isMobile,
} from '../app-components';
import {h9_Bold} from '../theme/fonts';

const SideBarFooter = ({navigation}) => {
  const getText = ({title, link, view = ''}) => {
    return (
      <TouchableOpacity
        style={{cursor: 'pointer'}}
        onPress={() => {
          if (view) {
            navigation.push({
              view,
              modal: true,
              modalProps: {
                autoHide: true,
                minHeight: 200,
                height: '100%', //600,
                width: isMobile ? 300 : '100%',
                position: 'screenCenter',
              },
            });
          } else {
            Linking.openURL(link, '_blank');
          }
        }}>
        <Text style={{color: '#fff', ...h9_Bold}}>{title}</Text>
      </TouchableOpacity>
    );
  };
  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          flex: 1,
          alignItems: 'streach',
        }}>
        {getText({
          title: 'Privacy Policy',
          link: 'https://www.mayamd.ai/privacy-policy',
        })}
        {getText({
          title: '| Terms & Conditions |',
          view: 'terms',
        })}
        {getText({
          title: 'User Agreement',
          link: 'https://www.mayamd.ai/user-agreement',
        })}
      </View>
      <View style={{alignItems: 'center', marginTop: 3}}>
        {getText({title: 'Contact us', link: 'https://www.mayamd.ai/contact'})}
      </View>
    </View>
  );
};
export default SideBarFooter;
