import Table from '../../app-components/table/Table';
import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  isMobile,
} from '../../app-components';
import {h16_Regular, h14_Medium, h14_AvenirNext} from '../../theme/fonts';
import {post, getUser} from '../../AppServices';
let uri = () => {
  return {
    query: {
      id: 'contactLists',
    },
    model: 'Contacts',
  };
};
// template
const moveStatus = ({
  item,
  title,
  value,
  model = 'Contacts',
  eventDispatcher,
}) => {
  return (
    <TouchableOpacity
      style={{
        backgroundColor: item?.status == 'unread' ? '#f14f4f' : '#57ccbf',
        borderRadius: 50,
        cursor: 'pointer',
      }}
      onPress={() => {
        const {userType} = getUser();
        if (item?.status === 'unread' && userType === 'SuperAdmin') {
          post({
            data: item,
            updates: {
              status: value || title,
            },
            model,
          })
            .then(({}) => {
              eventDispatcher.notify('ContactListUpdate');
            })
            .catch((err) => {
              console.log('error in modifying unread to read', err);
            });
        }
      }}>
      <View
        style={{
          alignItems: 'center',
          padding: 5,
        }}>
        <Text
          style={{
            ...h14_AvenirNext,
            color: 'white',
          }}>
          {'Mark as Read'}
          {/* {item?.status.toUpperCase()} */}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
const ViewAttachment = (attachments = [], navigation = {}) => {
  return (
    <TouchableOpacity
      style={{cursor: 'pointer'}}
      onPress={() => {
        navigation.push({
          view: 'pdfReader',
          params: {
            dataArr: attachments,
          },
          modal: true,
          // style: {minWidth: 500},
          modalProps: {
            autoHide: true,
            minHeight: 200,
            height: 600,
            width: isMobile ? 300 : 650,
            position: 'screenCenter',
            // ...confirmModalProps,
          },
        });
      }}>
      <Text
        style={{
          ...h14_Medium,
          color: '#00D4C4',
          opacity: 0.76,
        }}>
        View Attachment
      </Text>
    </TouchableOpacity>
  );
};

let tableItems = {
  columns: [
    {
      width: 10,
    },
    {
      type: 'text',
      field: 'organization_name.organization_name',
      header: 'Organization Name',
      width: 250,
      visible: () => {
        const {userType} = getUser();
        return userType === 'ClinicAdmin' ? false : true;
      },
    },
    {
      type: 'text',
      field: 'subject',
      header: 'Subject',
      width: 200,
    },
    {
      type: 'text',
      field: 'message',
      header: 'Message',
      // width: 400,
      flex: 1,
    },
    {
      render: ({item, navigation}) => {
        const {attachments = []} = item;
        return Array.isArray(attachments) && attachments.length > 0
          ? ViewAttachment(attachments, navigation)
          : null;
      },
    },
    {
      type: 'text',
      header: 'Status',
      width: 150,
      render: ({item, eventDispatcher}) => {
        const {userType} = getUser();
        if (userType === 'SuperAdmin' && item?.status === 'unread') {
          return moveStatus({item, eventDispatcher, value: 'read'});
        } else {
          return (
            <View
              style={{
                backgroundColor:
                  item?.status == 'unread' ? '#f14f4f' : '#57ccbf',
                borderRadius: 50,
              }}>
              <View
                style={{
                  alignItems: 'center',
                  padding: 5,
                }}>
                <Text
                  style={{
                    ...h14_AvenirNext,
                    color: 'white',
                  }}>
                  {item?.status?.toUpperCase()}
                </Text>
              </View>
            </View>
          );
        }
      },
    },
  ],
};
export default Table({uri, ...tableItems, reloadEvent: 'ContactListUpdate'});
