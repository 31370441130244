/* eslint-disable react-hooks/exhaustive-deps */
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { ScrollView, View, TouchableOpacity, Text } from '../../../app-components';

import { GradientButton } from '../../../app-components/buttons/Buttons';
import { downloadExcelData, fetch } from '../../../AppServices';
import { roles } from '../../../Lib/constants';
import { h22_SemiBold, h18_SemiBold } from '../../../theme/fonts';

const ExportCitizenList = (props) => {
  const { navigation } = props;
  const {
    state: {
      params: {
        id = '',
        model = '',
        queryId = '',
        fromPendingOrders = false,
        fromAssignedMember = false,
        dataParams = {},
        userType = '',
      },
    },
  } = navigation;
  const limit = 50000;

  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setLoading] = useState(true);

  const filters = {
    ...dataParams?.ftsFilter?.regExpFilter,
  };
  let paramValues = {};

  if (fromPendingOrders) {
    filters.isNoteGenerated = true;
    filters.isOrderGeneratedForThisPatient = { $ne: true };
    filters.isBloodGivenForTest = { $ne: false };
  }

  if (fromAssignedMember) {
    if (userType === roles.ANM) {
      filters.assignedANM = { _id: dataParams?.userId };
    } else {
      filters.my_doctors = { _id: { $in: [dataParams?.userId] } };
    }
  }

  if (dataParams?.filters?.period) {
    paramValues = {
      period: { ...dataParams?.filters?.period?.filter?.value },
    };
  }

  if (dataParams?.filters?.testResultArriveDate) {
    filters.testResultArriveDate =
      dataParams?.filters?.testResultArriveDate?.value?.value;
  }

  const villageId = dataParams?.filters && dataParams?.filters["village._id"] || {}

  if (!isEmpty(villageId?.value)) {
    filters.village = {
      ...(villageId?.value !== undefined && { _id: villageId?.value?._id })
    }
  }

  useEffect(() => {
    fetch({
      uri: {
        props: {
          query: {
            id: queryId,
            fieldAggregates: { _count: 1 },
            addOnFilter: filters,
            paramValue: paramValues,
          },
          model,
        },
      },
    })
      .then((res) => {
        setTotalCount(res?.aggregates?._count);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  }, [
    dataParams?.filters?.period,
    dataParams?.ftsFilter?.regExpFilter,
    fromPendingOrders,
    model,
    queryId,
  ]);

  const handleOnPressExport = useCallback(
    async (skip, limit) => {
      downloadExcelData({
        uriProps: {
          id,
          paramValue: {
            model,
            file: 'memberListSheet',
            _skip: skip,
            _limit: limit,
            _query: {
              id: queryId,
              addOnFilter: filters,
              paramValue: paramValues,
            },
          },
        },
      });
    },
    [dataParams, id, model, queryId],
  );

  const Loader = () => {
    return (
      <View
        style={{
          fontSize: '15px',
          textAlign: 'center',
          ...h18_SemiBold,
        }}>
        <Text>Loading...</Text>
      </View>
    );
  };

  return (
    <View
      style={{
        backgroundColor: '#f9f9f9',
        padding: 25,
        borderRadius: 8,
        width: 350,
      }}>
      <View
        style={{
          fontSize: '40px',
        }}>
        <Text style={{ fontSize: '45px', color: '#275dad', ...h22_SemiBold }}>
          Export Members
        </Text>
      </View>
      {limit < totalCount && (
        <View>
          <Text
            style={{ fontSize: '15px', color: '#852B4B', textAlign: 'justify' }}>
            It seems records are greater than {limit}. All records can be
            downloaded in multiple sheets with limit of {limit} each.
          </Text>
        </View>
      )}
      <ScrollView style={{ maxHeight: 400 }}>
        {!isLoading ? (
          limit < totalCount ? (
            <View>
              {[...Array(parseInt(totalCount / limit)).keys()]?.map(
                (_, index) => {
                  return (
                    <TouchableOpacity
                      onPress={() => handleOnPressExport(index * limit, limit)}
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        paddingRight: 10,
                        cursor: 'pointer',
                      }}>
                      <GradientButton
                        label={`Click here to export ${index === 0 ? 1 : limit * index + 1
                          } - ${limit * (index + 1)} records.`}
                      />
                    </TouchableOpacity>
                  );
                },
              )}
              {totalCount % limit !== 0 && (
                <TouchableOpacity
                  onPress={() =>
                    handleOnPressExport(
                      parseInt(totalCount / limit) * limit,
                      totalCount % limit,
                    )
                  }
                  style={{
                    paddingBottom: 10,
                    paddingTop: 10,
                    paddingRight: 10,
                    cursor: 'pointer',
                  }}>
                  <GradientButton
                    label={`Click here to export ${parseInt(totalCount / limit) * limit + 1
                      } - ${parseInt(totalCount / limit) * limit +
                      (totalCount % limit)
                      } records.`}
                  />
                </TouchableOpacity>
              )}
            </View>
          ) : totalCount ? (
            <TouchableOpacity
              onPress={() => handleOnPressExport(0, totalCount)}
              style={{
                paddingBottom: 10,
                paddingTop: 10,
                paddingRight: 10,
                cursor: 'pointer',
              }}>
              <GradientButton
                label={`Click here to export 1-${totalCount} records.`}
              />
            </TouchableOpacity>
          ) : (
            <View>
              <Text
                style={{
                  fontSize: '15px',
                  color: '#852B4B',
                  textAlign: 'justify',
                }}>
                There is no data to export.
              </Text>
            </View>
          )
        ) : (
          <Loader />
        )}
      </ScrollView>
      <TouchableOpacity
        onPress={() => navigation.pop()}
        style={{
          paddingBottom: 10,
          paddingTop: 10,
          paddingRight: 10,
          cursor: 'pointer',
        }}>
        <GradientButton label={'Cancel'} />
      </TouchableOpacity>
    </View>
  );
};

export default ExportCitizenList;
