import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ProgressIndicator,
} from '../../app-components';
import {listUri} from '../../Queries';
import {DataFetch} from '../../app-components/data-fetch/DataFetch';
import {h14_AvenirNext, h14_Medium} from '../../theme/fonts';
import {bgs} from '../../theme/colors';
import {withoutProfile} from '../../images';
import uuid from 'uuid/v4';
import {getImageUrl, getUser, gpsStore} from '../../AppServices';
import _ from 'lodash';
import {resolvePatientName} from '../../app-components/patientUtility';
let {lightGrayColor} = bgs;

class NonEditScheduleWrapper extends React.Component {
  render() {
    const {doctor} = getUser();
    const {screenState: {dataParams} = {}, navigation} = this.props;
    if (!dataParams) {
      return null;
    }
    return (
      <DataFetch
        navigation={navigation}
        dataParams={dataParams}
        uri={listUri({
          model: 'DoctorSlots',
          id: 'doctorSlotsLists',
          filter: {
            doctor: {_id: doctor?._id},
            status: 'Booked',
          },
          limit: 520,
        })}
        reloadEvent="reloadSchedule"
        eventDispatcher={this.props.eventDispatcher}>
        {({data, loading}) => {
          return (
            <NonEditSchedule
              fetchLoading={loading}
              data={data}
              {...this.props}
            />
          );
        }}
      </DataFetch>
    );
  }
}

export default NonEditScheduleWrapper;

class ScheduleShowComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selected: false};
  }

  getBackgroundColor = ({status, appointment_type, session_start}) => {
    if (status === 'completed' || session_start) {
      return '#73c5a7';
    }
    if (status === 'scheduled' && appointment_type === 'new') {
      return ' #ff7b20';
    }
    if (status === 'scheduled' && appointment_type === 'follow_up') {
      return '#2e6ecd';
    }
    if (status === 'cancelled') {
      return '#f14f4f';
    }
    return '#2e6ecd';
  };

  openModal = (value) => {
    let {navigation} = this.props;
    let modalWidth = 500;
    navigation.push({
      view: 'ModalView',
      modal: true,
      params: {value},
      modalProps: {
        style: {
          zIndex: 2,
          width: modalWidth,
        },
      },
    });
  };

  render() {
    let {item} = this.props;
    let {doctor, start_time, appointment} = item || {};
    const {
      patient_id: patientData,
      appointment_type,
      status,
      session_start = null,
    } = appointment || {};
    return (
      <TouchableOpacity
        onPress={() => {
          doctor && this.openModal(item);
        }}
        style={{
          justifyContent: 'space-between',
          borderRightColor: 'rgba(217, 217, 224, 0.37)',
          flexDirection: 'row',
          paddingTop: 8,
          paddingBottom: 8,
          display: 'flex',
          paddingLeft: 15,
          backgroundColor: doctor
            ? this.getBackgroundColor({
                status: status,
                appointment_type: appointment_type,
                session_start,
              })
            : '#fff',
          borderRadius: 8,
        }}>
        {doctor ? (
          <View>
            <Image
              source={
                patientData?.profile_picture
                  ? getImageUrl(patientData?.profile_picture?.thumbnail)
                  : withoutProfile
              }
              style={{width: 26, height: 26, borderRadius: 30}}
            />
          </View>
        ) : (
          void 0
        )}

        <View
          style={{
            paddingLeft: 10,
            justifyContent: 'center',
            flex: 1,
            overflow: 'hidden',
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...h14_Medium,
              color: '#fff',
            }}>
            {resolvePatientName(patientData) || 'N/A'}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

class NonEditSchedule extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {schedules: []};
  }
  callback = async (value) => {
    let {eventDispatcher} = this.props;
    eventDispatcher.notify('reloadSchedule');
  };

  socketSubscription = () => {
    let {doctor: {_id: doctorId} = {}, userType} = getUser();
    this.socketUid = uuid();
    gpsStore.subscribeEvent({
      event: 'newAppointmentCreated',
      callback: this.callback,
    });
    gpsStore.emitDataOnConnect({
      uid: this.socketUid,
      event: 'subscribeGroup',
      data: {
        groupId: `${doctorId}__new`,
        sessionId: this.socketUid,
        source: 'web',
      },
      callback: () => {},
    });
  };

  unsubscribSocket = () => {
    if (gpsStore && gpsStore.removeEmitDataOnConnect) {
      gpsStore.removeEmitDataOnConnect({uid: this.socketUid});
      gpsStore.unsubscribeEvent({
        event: 'newAppointmentCreated',
        callback: this.callback,
      });
    }
  };
  componentWillUnmount() {
    this.unsubscribSocket();
  }
  checkDay = ({result: data}) => {
    const mapDay2Num = {
      monday: 0,
      tuesday: 1,
      wednesday: 2,
      thursday: 3,
      friday: 4,
      saturday: 5,
      sunday: 6,
    };
    let modifiedData = new Array(7).fill(0);
    for (let obj of data) {
      let {day = '', status = ''} = obj;
      if (status == 'Booked') {
        day = day.toLowerCase();
        let index = mapDay2Num[day];
        if (!modifiedData[index]) {
          modifiedData[index] = {day: day, value: [obj]};
        } else {
          modifiedData[index].value.push(obj);
        }
      }
    }
    const filterResult = modifiedData.filter((x) => x !== 0);
    // console.log('output is', JSON.stringify(modifiedData, null, 2));
    this.setState({groupBySchedules: [...filterResult]});
  };

  refreshData = async () => {
    this.setState({dataLoading: true});
    let {data: result} = this.props;
    this.setState({dataLoading: false});
    if (result) {
      this.checkDay({result});
    }
  };
  componentDidMount = async () => {
    this.refreshData();
    this.socketSubscription();
  };

  componentDidUpdate(prevProps) {
    let {data: _prevData = []} = prevProps;
    let {data = []} = this.props;
    if (data !== _prevData) {
      this.refreshData();
    }
  }

  getRow = ({scheduleData}) => {
    const {day = '', value} = scheduleData;
    return (
      <View
        style={{
          borderBottomColor: lightGrayColor,
          borderBottomWidth: 1,
          flex: 1,
          width: '100%',
        }}>
        <Text style={{...h14_AvenirNext, marginBottom: 5, marginTop: 5}}>
          {day && _.startCase(day)}
        </Text>
        {Array.isArray(value) &&
          value.map((item, index) => (
            <View style={{marginBottom: 5}}>
              <ScheduleShowComponent item={item} {...this.props} />
            </View>
          ))}
      </View>
    );
  };

  render() {
    let {fetchLoading} = this.props;
    let {groupBySchedules = [], dataLoading} = this.state || {};
    return (
      <View
        style={{
          flex: 1,
          overflow: 'auto',
          marginLeft: 14,
          marginRight: 14,
          marginTop: 5,
          borderRadius: 4,
          padding: 5,
          boxShadow: '0 0 10px 0 rgba(53, 53, 53, 0.08)',
        }}>
        {fetchLoading && <ProgressIndicator />}
        {groupBySchedules.length ? (
          groupBySchedules.map((scheduleData, index) => {
            return this.getRow({scheduleData, groupBySchedules});
          })
        ) : dataLoading || fetchLoading ? null : (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 20,
              width: '100%',
            }}>
            <Text>No Schedule</Text>
          </View>
        )}
      </View>
    );
  }
}
