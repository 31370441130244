import React from 'react';
import {View, Text} from '../../react-core-components';
class TopLabel extends React.Component {
  render() {
    let {
      label,
      labelContainerStyle,
      labelStyle,
      mandatory,
      mandatoryStyle,
      children,
    } = this.props;
    if (label === false) {
      return children;
    }
    return (
      <>
        <View style={{flexDirection: 'row', ...labelContainerStyle}}>
          {label ? <Text style={labelStyle}>{label}</Text> : void 0}
          {label && mandatory ? (
            <Text style={mandatoryStyle || labelStyle}>*</Text>
          ) : (
            void 0
          )}
        </View>
        {React.cloneElement(children, {
          label: void 0,
          skipLabel: true,
          mandatory: void 0,
          skipMandatory: true,
        })}
      </>
    );
  }
}

TopLabel.defaultProps = {
  labelContainerStyle: {
    height: 20,
  },
  labelStyle: {
    fontSize: 14,
    numberOfLines: 1,
  },
};

export default TopLabel;
