import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  isMobile,
} from '../../app-components';
import {
  mayaScheduleAppointment,
  preConsultMayaWhite,
  searchFilter,
  videoCall,
  VaccineIcon,
} from '../../images';
import uuid from 'uuid/v4';
import {
  h18_Medium,
  h13_Medium,
  h14_Regular,
  h21_Regular,
  h10_Medium,
} from '../../theme/fonts';
import {
  gpsStore,
  getUser,
  getImageUrl,
  fetch,
  getDependentPatient,
} from '../../AppServices';

import moment from 'moment';
import {isEmpty} from 'lodash';
import {StartVideoCallButton} from '../../app-components/buttons/Buttons';
// import {mayaVideo, patientWatingRoomCard} from '../../theme/shadows';
let socketUid = uuid();
const UppcommingAppointment = (props) => {
  const {navigation, appointmentData, setAppointmentData} = props || {};
  const {t} = props; //useTranslation();

  if (isEmpty(appointmentData)) {
    return null;
  }
  const {doctor_id, time_slot = {}, session_start = null, session_end = null} =
    appointmentData || {};
  let {name = []} = doctor_id || {};
  if (Array.isArray(name)) {
    name = name[0]?.given.join(' ') + ' ' + name[0]?.family;
  }

  const getVideoCallButton = () => {
    return (
      <TouchableOpacity
        style={{cursor: 'pointer', width: '100%'}}
        onPress={() => {
          // const {item = {}, navigation} = props || {};
          navigation.push({
            view: isMobile ? 'videoSessionMobile' : 'videoSession',
            params: {item: {...appointmentData}},
          });
        }}>
        <StartVideoCallButton
          label={t('Join Session')}
          image={videoCall}
          imageStyle={{marginLeft: 15, width: 28, height: 28}}
        />
      </TouchableOpacity>
    );
  };

  // t function

  return (
    <>
      <View
        style={{
          marginTop: 20,
          marginBottom: 20,
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Text style={{...h18_Medium, color: '#fff'}}>
          {/* Upcoming Appointments */}
          {t('Upcoming Appointments')}
        </Text>
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            navigation.replace('patient-waiting-room');
          }}>
          <Text style={{...h13_Medium, color: '#fff'}}>
            {/* View All */}
            {t('View All')}
          </Text>
        </TouchableOpacity>
      </View>
      <View
        style={{
          backgroundColor: 'rgba(1, 59, 86, 0.35)',
          borderRadius: 5,
          padding: 10,
          flexDirection: 'row',
        }}>
        {isMobile ? (
          void 0
        ) : (
          <View style={{flex: 0.7, height: 100}}>
            <Image
              source={getImageUrl(doctor_id?.profile_picture?.thumbnail)}
              style={{
                height: 100,
                objectFit: 'contain',
                borderRadius: 8,
                width: 100,
              }}></Image>
          </View>
        )}
        <View
          style={{
            flex: 2.8,
            padding: 10,
            justifyContent: 'space-between',
          }}>
          <View>
            <Text style={{...h18_Medium, color: '#fff'}}>{name}</Text>
          </View>
          <View>
            <Text style={{...h18_Medium, color: '#fff'}}>
              {t('date', {date: new Date(time_slot?.start_time)})}
              {/* {moment(time_slot?.start_time).format('dddd, MMM. DD')} */}
            </Text>
            <Text style={{...h14_Regular, color: 'rgba(255,255,255,0.5)'}}>
              {`${moment(time_slot?.start_time).format('hh:mm A')} - ${moment(
                time_slot?.end_time,
              ).format('hh:mm A')}`}
            </Text>
          </View>
        </View>
        <View style={{flex: 1.5}}>
          <TouchableOpacity
            onPress={() => {
              navigation.replace('patient-waiting-room');
            }}
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              height: 40,
              cursor: 'pointer',
              borderRadius: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text style={{color: '#fff', ...h14_Regular, textAlign: 'center'}}>
              {/* View Details */}
              {t('View Details')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              marginTop: 10,
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              height: 40,
              justifyContent: 'center',
              borderRadius: 10,
              alignItems: 'center',
            }}>
            {session_start && session_end === null ? (
              getVideoCallButton()
            ) : (
              <Text style={{color: '#fff', ...h10_Medium, textAlign: 'center'}}>
                {/* Join Video Call */}
                {/* {t('Join Video Call')} */}
                {session_end ? t('Meeting Ended') : t('waitSessionStart')}
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const Dashboard = (props) => {
  const {navigation} = props;
  const [appointmentData, setAppointmentData] = useState({});

  const getAppointmentData = async () => {
    const {patient = {}} = getUser() || {};
    try {
      const result = await fetch({
        uri: {
          props: {
            query: {
              id: 'appointmentLists',
              addOnFilter: {
                patient_id: {
                  _id: {
                    $in: [
                      ...(getDependentPatient() ? getDependentPatient() : []),
                      patient?._id,
                    ],
                  },
                },
                status: 'scheduled',
              },
              sort: {time_slot: {start_time: -1}},
              limit: 1,
              paramValue: {
                period: {
                  from: moment(new Date()).startOf('day').toDate(),
                  // to: moment(new Date()).endOf('day').toDate(),
                },
              },
            },
            model: 'Appointments',
          },
        },
      });
      const {data = []} = result || {};
      if (Array.isArray(data) && data.length) {
        setAppointmentData(data[0]);
      }
    } catch (err) {
      console.log('error in getting data');
    }

    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>: getData -> data', data);
  };
  const unsubscribSocket = () => {
    if (gpsStore && gpsStore.removeEmitDataOnConnect) {
      gpsStore.removeEmitDataOnConnect({uid: socketUid});
      gpsStore.unsubscribeEvent({
        event: 'videoCall',
        callback: () => {},
      });
    }
  };
  const callbackAppointmentUpdate = () => {
    getAppointmentData();
  };
  const subscribeMeetingCallback = () => {
    // console.log('subscribeMeetingCallbackasdfghj');
  };
  const socketSubscription = () => {
    let user_id = '';
    let {patient: {_id: patientId} = {}} = getUser();
    user_id = patientId;
    // let socketUid = uuid();
    gpsStore.subscribeEvent({
      event: 'videoCall',
      callback: callbackAppointmentUpdate,
    });
    gpsStore.emitDataOnConnect({
      uid: socketUid,
      event: 'subscribeGroup',
      data: {
        groupId: `${user_id}__new`,
        sessionId: socketUid,
        source: 'web',
      },
      callback: subscribeMeetingCallback,
    });
  };

  useEffect(() => {
    getAppointmentData();
    socketSubscription();
    return () => {
      unsubscribSocket();
    };
  }, []);
  const {t} = props; //useTranslation();
  return (
    <ScrollView style={{flex: 1}}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          marginTop: isMobile ? 0 : isEmpty(appointmentData) ? 50 : 25,
          flexWrap: 'wrap',
          marginBottom: isMobile ? 10 : undefined,
        }}>
        <View
          style={{
            flex: 4,
            marginLeft: 8,
            marginRight: 8,
            minWidth: 200,
            alignSelf: 'flex-start',
          }}>
          <TouchableOpacity
            className="MayaPulse"
            onPress={() => {
              navigation.reset('health-assistant');
            }}
            style={{
              borderWidth: 1.5,
              borderColor: 'rgba(230,245,251,0.5)',
              borderRadius: 30,
              boxShadow: 'rgba(0,0,0,0.4) 5px 5px 10px 0px',
              cursor: 'pointer',
              // ...mayaVideo,
            }}>
            <video
              style={{flex: 1, borderRadius: 30, width: '100%'}}
              autoPlay
              loop>
              <source src="./mayaMDVideo.mp4" type="video/mp4" />
            </video>
          </TouchableOpacity>
          <Text
            style={{
              ...h18_Medium,
              color: '#fff',
              textAlign: 'center',
              marginTop: 13,
              marginBottom: 13,
            }}>
            {/* Meet Maya, Your Digital Health Assistant */}
            {t('Meet Maya')}
          </Text>
        </View>

        <View style={{flex: 6, marginLeft: 8, marginRight: 8}}>
          <View
            style={{
              backgroundColor: 'rgba(1, 59, 86, 0.35)',
              width: '100%',
              borderRadius: 20,
              padding: 10,
            }}>
            <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
              {[
                {
                  label: 'Make an Appointment',
                  color: '#fff',
                  backgroundColor: '#00A0FB',
                  icon: mayaScheduleAppointment,
                  navigateTo: 'doctor-search'
                },
                {
                  label: 'Check My Symptoms',
                  color: '#fff',
                  backgroundColor: '#00C1CD',
                  navigateTo: 'health-assistant',
                  icon: preConsultMayaWhite,
                },
                {
                  label: 'Find a Provider',
                  color: '#fff',
                  backgroundColor: '#9442FF',
                  navigateTo: 'provider',
                  icon: searchFilter,
                },
                {
                  label: 'COVID Vaccine Info',
                  color: '#fff',
                  backgroundColor: '#DA4E91',
                  icon: VaccineIcon,
                  pushTo: 'covidvaccine',
                },
              ].map((item, index) => (
                <TouchableOpacity
                  onPress={() => {
                    if (item?.navigateTo) {
                      navigation.replace(item?.navigateTo);
                    }
                    if (item?.pushTo) {
                      navigation.push({view: item?.pushTo});
                    }
                  }}
                  style={{
                    backgroundColor: item.backgroundColor,
                    height: 128,
                    borderRadius: 10,
                    flex: 1,
                    // marginRight:20,
                    minWidth: 200,
                    // maxWidth:300,
                    margin: 10,
                    padding: 10,
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Image
                    style={{
                      width: 25,
                      height: 25,
                      objectFit: 'contain',
                      marginBottom: 10,
                    }}
                    source={item?.icon}></Image>
                  <Text
                    style={{
                      color: item.color,
                      ...h21_Regular,
                      fontWeight: 800,
                      textAlign: 'center',
                    }}>
                    {t(item.label)}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          </View>

          <UppcommingAppointment
            {...props}
            appointmentData={appointmentData}
            setAppointmentData={setAppointmentData}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default Dashboard;
