import React from 'react';
import {View, Text} from '../../index';

export default Component => {
  class AggregateComponent extends React.Component {
    render() {
      let columnTextStyle =
        this.props['textStyle'] || this.props['headerRowColumnTextStyle'];
      let {
        column: {
          aggregate,
          field,
          header: {
            textStyle = columnTextStyle,
            aggregatesPosition = 'bottom',
          } = {},
        } = {},
        aggregates,
      } = this.props;

      let renderComponent = <Component {...this.props} />;
      if (!aggregate || !aggregates) {
        return renderComponent;
      }
      let aggregateField =
        typeof aggregate === 'boolean'
          ? field
          : typeof aggregate === 'string'
          ? aggregate
          : void 0;

      let aggregatesValue = aggregates[aggregateField];
      let aggregatesComponent = void 0;
      if (aggregatesValue) {
        aggregatesComponent = (
          <Text style={textStyle}>{`(${aggregatesValue})`}</Text>
        );
      }
      let aggregateContainerStyle = {flex: 1, overflow: 'hidden'};
      if (aggregatesPosition === 'right') {
        aggregateContainerStyle = {
          ...aggregateContainerStyle,
          flexDirection: 'row',
        };
      }

      return (
        <View style={aggregateContainerStyle}>
          {aggregatesPosition === 'top' && aggregatesComponent}
          {renderComponent}
          {aggregatesPosition === 'bottom' && aggregatesComponent}
        </View>
      );
    }
  }
  return AggregateComponent;
};
