import React from 'react';
import {
  ScrollView,
  View,
  Image,
  Text,
  TouchableOpacity,
} from '../app-components';
import {
  HealthAssistant,
  doctorSearchMaya,
  appointments,
  StewardLogo,
  MotivHealthLogo,
  dashboardIcon,
  medicalInformation,
  mayaMdLogo,
  mayaMdDarkLogo
} from '../images';
import {h16_Regular} from '../theme/fonts';
import './style.css';
import {useTranslation} from 'react-i18next';
import {brandImg, brandStyle} from '../IconStyleBrand';
import {BRAND_NAME} from '../Config';
import SideBarFooter from './SideBarFooter'

const SidePannel = (props) => {
  const {selectedMenu, setSelectedMenu, navigation} = props;
  const {t, i18n} = useTranslation();
  return (
    <View
      style={{
        height: '100%',
        width: 240,
        backgroundColor: 'rgba(1, 59, 86, 0.35)',
        borderRadius: 20,
        padding: 5,
        alignItems: 'center',
      }}>
      <TouchableOpacity
        onPress={() => {
          setSelectedMenu('dashboard');
          navigation.reset('Dashboard');
        }}
        style={{
          cursor: 'pointer',
          borderRadius: 10,
          marginTop: 33,
        }}>
        <Image
          source={brandImg[BRAND_NAME][0]}
          style={brandStyle[BRAND_NAME]['patient-sidebar']}
          className="pulse"
        />
      </TouchableOpacity>
      <ScrollView style={{marginTop: 50, width: '100%', flexShrink: 'initial'}}>
        {[
          {
            label: 'Dashboard',
            value: 'dashboard',
            isNavigate: 'Dashboard',
            icon: dashboardIcon,
          },
          {
            label: 'Appointments',
            value: 'appointments',
            icon: appointments,
            isNavigate: 'patient-waiting-room',
          },
          {
            label: 'Find a Provider',
            value: 'findAProvider',
            isNavigate: 'provider',
            icon: doctorSearchMaya,
          },
          {
            label: 'Check My Symptoms',
            value: 'healthAssistant',
            isNavigate: 'health-assistant',
            icon: medicalInformation,
          },
        ].map((item) => {
          return (
            <TouchableOpacity
              onPress={() => {
                if (item?.isNavigate) {
                  navigation.reset(item?.isNavigate);
                }
                setSelectedMenu(item.value);
              }}
              style={{
                backgroundColor:
                  selectedMenu === item.value ? 'rgba(255, 255, 255, 0.2)' : '',
                flex: 1,
                borderRadius: 8,
                paddingLeft: 15,
                minHeight: 48,
                marginBottom: 10,
                cursor: 'Pointer',
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  // paddingLeft: 35,
                  alignItems: 'center',
                }}>
                <Image
                  style={{
                    width: 16.5,
                    height: 16.5,
                    objectFit: 'contain',
                    marginRight: 20,
                    marginLeft: 20,
                  }}
                  source={item.icon}></Image>
                <Text style={{...h16_Regular, color: '#fff'}}>
                  {t(item.label)}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
      <View
        style={{
          flex: 1,
          width: '98%',
          justifyContent: 'flex-end',
          marginBottom:5
        }}>
      <SideBarFooter {...props}/>
      </View>
    </View>
  );
};

export default SidePannel;
