import React from 'react';
import {View, TouchableOpacity, Image} from '../../app-components';
import {whiteCrossIcon} from '../../images';
const Covid = (props) => {
  const {navigation = {}} = props || {};
  return (
    <View
      style={{
        width: '100%',
        height: '87vh',
        justifyContent: 'center',
        marginTop: 5,
        position: 'relative',
      }}>
      <iframe
        style={{height: '87vh', border: 'none', borderRadius: 10}}
        src="https://covidvaccine.mayamd.ai?embedded=true"
        title="Covid Ai"></iframe>
      <TouchableOpacity
        onPress={() => {
          navigation.pop();
        }}
        style={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}}>
        <Image source={whiteCrossIcon} style={{height: 15, width: 15}}></Image>
      </TouchableOpacity>
    </View>
  );
};

export default Covid;
