import React from 'react';
import Fts from '../../app-components/filter/Fts';
import Sort from './Sort';

const FtsRender = ({action}) => {
  return <Fts action={action} />;
};
export const fts = {
  render: FtsRender,
  type: 'fts',
};

const SortRender = ({action}) => {
  return <Sort action={action} />;
};
export const sortAction = {
  render: SortRender,
  type: 'sort',
};

export const ftsAction = ({}) => {};
