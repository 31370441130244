import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import ccmForm from './ccmForm';
let header = {
  title: 'Edit CCM Checklist',
  actions: [cancelButton(), save()],
};
const uri=({navigation})=>{
  return {
    query: {
      id: 'ccmCheckLists',
      relationValue: {
        model: 'CcmCheckList',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'CcmCheckList',
  }
}

export default ccmForm({uri, header});
