import React from 'react';
import { cancelButton } from '../../../app-components/action/CloseAction';
import { save } from '../../../app-components/action/SaveAction';
import Form from '../../../app-components/form/Form';
import { submit } from '../../../AppServices';

const AddVillageSubCenter = (props) => {

    const ModifiedForm = Form({
        ...props,
        reloadEvent: 'reloadVillage',
        reloadOnChangeEvent: 'reloadVillage',
        closeView: 1,
        fieldVariant: 'filled',
        footer: {
            containerStyle: {
                margin: 0,
                borderTopWidth: 0,
            },
            actions: [cancelButton(), save({ label: 'Submit' })],
        },
        onSubmit: submit({
            model: 'villageSubCenter',
            op: 'insert',
        }),
        header: {
            title: 'Add Village Sub Center',
            titleTextStyle: {
                color: 'rgba(56, 56, 56, 0.87)',
                fontSize: 20,
                marginLeft: 10,
                fontFamily: 'AvenirNext-Regular',
            },
        },
        formGroups: [
            {
                direction: 'row',
                fieldVariant: 'filled',
                groups: [
                    {
                        columnsPerRow: 1,
                        columns: [
                            {
                                type: 'text',
                                label: 'Center Name',
                                field: 'centerName',
                            },
                        ],
                    },
                ],
            },
        ],
    });
    return <ModifiedForm props={props} />;
};

export default AddVillageSubCenter;
