import {Headline9} from './Headlines';
import {manazeV6Colors} from './manazeV6Colors';
import {tableMobileTheme, tableTheme} from './tableTheme';
import {toolBarTheme} from './toolBarTheme';
const {neutral_10, neutral_2, surface_white} = manazeV6Colors;
export const expandedListTheme = {
  ...tableTheme,
  containerStyle: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  headerRowContainerStyle: {
    minHeight: 44,
    paddingTop: 12,
    backgroundColor: surface_white,
  },
  rowStyle: {
    ...tableTheme.rowStyle,
    topSeparator: true,
    rowWrapperStyle: {backgroundColor: surface_white},
    rowHoverContainerStyle: {
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',
    },
  },
};

export const expandedListMobileTheme = {
  ...tableMobileTheme,
  containerStyle: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  headerRowContainerStyle: {
    minHeight: 44,
    paddingTop: 12,
    paddingLeft: 6,
    paddingRight: 6,
  },
  rowStyle: {
    ...tableMobileTheme.rowStyle,
    topSeparator: true,
    rowWrapperStyle: {
      //   paddingLeft: 6,
      //   paddingRight: 6,
    },
  },
};

export const filledExpandedListTheme = {
  ...tableTheme,
  containerStyle: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  cardContainerStyle: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  headerRowContainerStyle: {
    minHeight: 44,
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
  },
  rowStyle: {
    ...tableTheme.rowStyle,
    topSeparator: true,
    rowWrapperStyle: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    rowHoverContainerStyle: {
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',
    },
  },
};

export const filledExpandedListMobileTheme = {
  ...tableMobileTheme,
  containerStyle: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  cardContainerStyle: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  headerRowContainerStyle: {
    minHeight: 44,
    paddingTop: 12,
    paddingLeft: 6,
    paddingRight: 6,
  },
  rowStyle: {
    ...tableMobileTheme.rowStyle,
    topSeparator: true,
    rowWrapperStyle: {
      paddingLeft: 6,
      paddingRight: 6,
    },
  },
};

export const groupListTheme = {
  ...tableTheme,
  headerRowContainerStyle: {
    minHeight: 44,
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: neutral_10,
  },
  rowStyle: {
    ...tableTheme.rowStyle,
    rowWrapperStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      backgroundColor: neutral_10,
    },
  },
};
export const groupListMobileTheme = {
  ...tableMobileTheme,
  headerRowContainerStyle: {
    minHeight: 44,
    paddingTop: 12,
    paddingLeft: 6,
    paddingRight: 6,

    backgroundColor: neutral_10,
  },
  rowStyle: {
    ...tableMobileTheme.rowStyle,
    rowWrapperStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      backgroundColor: neutral_10,
    },
  },
};

export const groupListToolBarTheme = {
  ...toolBarTheme,
  containerStyle: {paddingLeft: 4, paddingRight: 4, justifyContent: 'center'},
  titleTextStyle: {
    ...Headline9,
    color: neutral_2,
    padding: 6,
  },
  actionStyle: {
    ...toolBarTheme.actionStyle,
    activeContainerStyle: {},
    iconStyle: {height: 16, width: 16},
  },
};
