import React from 'react';
import moment from 'moment';
import {TouchableOpacity, View, DatePicker, Text} from '../../app-components';
import {dateInputStyle} from '../../theme/DateBoxtheme';
import {colors, bgs, manazeColors} from '../../theme/colors';
import {h12_Regular, h18_SemiBold} from '../../theme/fonts';
import {shadow} from '../../theme/shadows';
import {DateInput} from '../../app-components/input-components/Editors';

const {textWhite, grey, spindle2 = '#bbc1cc', papayawhip = '#f2fffe'} = colors;
const {whiteBg, lightGrayColor, darkBlackColor} = bgs;
const {blue} = manazeColors;

class Modal extends React.Component {
  state = {
    extraStyle: {},
    prevPositionFromTop: 0,
    date: {},
  };

  onDone = (e) => {
    let {onApply, hideModal} = this.props;
    let fromDate = new Date(this.state.date && this.state.date.from);
    let toDate = new Date(this.state.date && this.state.date.to);
    fromDate = moment(fromDate).format('DD MMM YY');
    toDate = moment(toDate).format('DD MMM YY');
    onApply && onApply({from: fromDate, to: toDate});
    hideModal && hideModal();
  };
  handleDate = ({field, value}) => {
    let date = this.state.date;
    date[field] = value;
    this.setState({date: date});
  };
  componentDidMount = () => {
    let {fromDate = {}, toDate = {}} = this.props || {};
    let date = {};
    date['from'] = fromDate;
    date['to'] = toDate;
    this.setState({
      date: date,
    });
  };
  render() {
    let {
      date: {from, to = {}},
    } = this.state || {};
    if (
      from &&
      this.props.fromDate &&
      from.toString() == this.props.fromDate.toString()
    ) {
      // when we insert the data
      from = new Date(from.setHours(6));
      from = new Date(from.setMinutes(30));
    }
    let {frameStyle, hideModal} = this.props;
    dateInputStyle['dateCalendarStyle']['modalStyle'] = {
      ...dateInputStyle.dateCalendarStyle.modalStyle,
      backgroundColor: 'white',
      zIndex: 2,
    };
    return (
      <View
        style={{
          ...frameStyle,
          ...this.state.extraStyle,
          width: 250,
          height: 190,
          paddingTop: 10,
          paddingBottom: 10,
          marginTop: 20,
          borderRadius: 5,
          backgroundColor: textWhite,
          overflow: 'hidden',
          color: grey,
          ...shadow,
        }}>
        <View
          onPress={(e) => e.stopPropagation()}
          style={{
            height: '80%',
            borderBottomWidth: 2,
            borderBottomColor: lightGrayColor,
          }}>
          <View
            style={{
              height: '23%',
              ...h18_SemiBold,
              marginBottom: 10,
              paddingLeft: 8,
              color: darkBlackColor,
              borderBottomWidth: 2,
              borderBottomColor: lightGrayColor,
            }}
            title="Select Range">
            <Text>Select Range</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginLeft: 8,
              marginRight: 10,
              marginBottom: 10,
            }}>
            <View
              style={{color: spindle2, ...h12_Regular, marginRight: 8}}
              title="Start Date">
              <Text>Start Date</Text>
            </View>
            <DateInput
              onChangeValue={(value) => this.handleDate({field: 'from', value})}
              value={this.state.date.from}
              placeholder={'Start'}
              variant={'filled'}
              moreInputStyle={{width: 100}}
            />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginLeft: 8,
              marginRight: 10,
            }}>
            <View
              style={{color: spindle2, ...h12_Regular, marginRight: 10}}
              title="End Date">
              <Text>End Date</Text>
            </View>
            <DateInput
              value={this.state.date.to}
              onChangeValue={(value) => this.handleDate({field: 'to', value})}
              placeholder={'End'}
              variant={'filled'}
              moreInputStyle={{width: 100}}
            />
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: 10,
            marginRight: 10,
            flex: 1,
          }}>
          <TouchableOpacity
            onPress={hideModal}
            style={{
              paddingTop: 6,
              paddingBottom: 6,
              paddingLeft: 12,
              paddingRight: 12,
              color: whiteBg,
              backgroundColor: blue,
              marginRight: 10,
              cursor: 'pointer',
              borderRadius: 5,
            }}>
            <Text>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              paddingTop: 6,
              paddingBottom: 6,
              paddingLeft: 15,
              paddingRight: 15,
              color: whiteBg,
              backgroundColor: blue,
              cursor: 'pointer',
              borderRadius: 5,
            }}
            onPress={this.onDone}>
            <Text>Done</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default Modal;
