import CreatePassword from './CreatePassword';
import Home from './Home';
import OTP from './OTP'

export default {
  'patient-signin': {
    screen: Home,
    public: true,
  },
  "maya-auth":{
    screen:OTP,
    public:true
  },
  'create-password':{
    screen:CreatePassword
  }
}