import React, {useState, useEffect} from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  isMobile,
  Dimensions,
} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import Format from '../GenericComponent/ClinicSignupFormat';
import {TextInput} from '../../app-components/input-components/Editors';
import {
  h11_Medium,
  h12_SemiBold,
  h14_AvenirNext,
  h14_Medium,
  h22_Medium,
} from '../../theme/fonts';
import {checkUserExist, invoke} from '../../AppServices';
import {colors} from '../../theme/colors';
import moment from 'moment';
const {themeColor, textColor70, grey} = colors;
const TimerSeconds = 30;
const {height, width} = Dimensions.get('window');
class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date: void 0, timer: TimerSeconds};
  }
  componentDidMount = async () => {
    let date = moment();
    this.timerID = setInterval(() => this.tick(), 1000);
    this.setState({date});
  };
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  tick = () => {
    let {date, timer} = this.state;
    const {resendTextVisible, setResendTextVisible} = this.props || {};
    if (timer === 0) {
      setResendTextVisible(true);
      this.setState({timer: TimerSeconds});
    } else {
      var t = new Date(date);
      t.setSeconds(t.getSeconds() + 1);
      this.setState({date: t, timer: timer - 1});
    }
  };
  render() {
    return (
      <View style={{justifyContent: 'center'}}>
        <Text id="resend_otp" style={{...h12_SemiBold, color: themeColor}}>
          {`Resend Pin in ${this.state.timer} second`}
        </Text>
      </View>
    );
  }
}

const VerifyOtp = (props) => {
  const {navigation = {}} = props;
  const {
    state: {
      params: {mobile, userType = 'Doctor', isForgot = false} = {},
      params,
    } = {},
  } = navigation || {};
  const [otp, setOTP] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [resendTextVisible, setResendTextVisible] = useState(false);
  const resendOtp = async () => {
    setLoading(true);
    await checkUserExist(mobile, userType, true, isForgot)
      .then((res) => {
        setLoading(false);
        console.log('res', res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(`>@>Puru ~ file: SignupOtp.js ~ line 74 ~ err`, err);
      });
  };
  const verify = async () => {
    if (!otp) {
      setError({type: 'otp', value: 'Please enter OTP'});
    } else {
      setLoading(true);
      await invoke({
        id: 'verifyOtp',
        paramValue: {
          mobile: mobile,
          password: otp,
          userType,
          forget: isForgot || false,
        },
      })
        .then((res) => {
          const {result = {}} = res || {};
          setLoading(false);
          navigation.push({
            view: 'create-doctor-password',
            params: {otp, user: result, isForgot, userType, mobile, ...params},
          });
        })
        .catch((err) => {
          setLoading(false);
          setError({type: 'otp', value: 'Incorrect otp'});
          console.log(err);
        });
    }
  };
  return (
    <View
      style={{
        // minWidth: 450,
        marginTop: 20,
        // minHeight: 300,
        minWidth: isMobile ? width * 0.75 : 450,
        maxWidth: isMobile ? width * 0.75 : 450,
        minHeight: isMobile ? height * 0.65 : 300,
        justifyContent: 'space-between',
      }}
      id="otp_container">
      <View>
        <Text id="otp_label" style={{...h22_Medium}}>
          Enter Your OTP
        </Text>

        <View style={{marginRight: 50, marginTop: 20}}>
          <Text
            style={{
              ...h14_Medium,
              marginBottom: 20,
              color: grey,
            }}
            id="otp_info">
            {`We have sent you a 6-digit verification PIN at ${mobile}`}
          </Text>
          <TextInput
            value={otp}
            onChangeText={(value) => {
              setOTP(value);
              setError({});
            }}
            inputProps={{
              id: 'otp',
            }}
            variant="filled"
            placeholder={`Enter your otp`}
          />
          {error && error?.type === 'otp' ? (
            <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
          ) : (
            void 0
          )}
        </View>
        <View
          id="resend_otp_container"
          style={{marginTop: 10, flexDirection: 'row', alignItems: 'center'}}>
          <Text
            style={{...h14_AvenirNext, color: textColor70, paddingRight: 5}}>
            Didn’t receive the pin?
          </Text>
          {!resendTextVisible && (
            <Clock
              resendTextVisible={resendTextVisible}
              setResendTextVisible={setResendTextVisible}
            />
          )}
          {resendTextVisible && (
            <TouchableOpacity
              onPress={() => {
                if (!loading) {
                  resendOtp();
                  setResendTextVisible(false);
                }
              }}>
              <Text
                style={{color: themeColor, ...h12_SemiBold, cursor: 'pointer'}}
                id="resend_otp_link">
                Resend PIN
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>

      <View
        style={{alignItems: isMobile ? 'center' : 'flex-end', marginTop: 10}}>
        <TouchableOpacity
          onPress={() => {
            if (!loading) {
              verify();
            }
          }}
          id="verify"
          style={{width: 150, cursor: 'pointer'}}>
          <GradientButton
            label="Verify"
            loading={loading}
            indicatorColor={'#fff'}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default (props) => (
  <Format {...props} backIconVisible={false}>
    <VerifyOtp {...props} />
  </Format>
);
