export const resolvePatientName = (data) => {
  let {name = [], first_name = '', last_name = ''} = data || {};
  return first_name + ' ' + last_name;
  // let fullName = '';
  // if (Array.isArray(name) && name.length) {
  //   let {given = [], family = ''} = name[0] || {};
  //   fullName = given.join(' ') + ' ' + family;
  // }
  // return typeof name === 'string' ? name : fullName;
};
export const resolvePatientMrn = (data) => {
  const {identifier = [],mrn=""} = data || {};
  return mrn;
  // return Array.isArray(identifier) && identifier.length
  //   ? identifier[0]?.value || ''
  //   : '';
};
export const resolvePatientMobile = (data) => {
  const {telecom = [],mobile=""} = data || {};
  // let mobile = '';
  // if (Array.isArray(telecom) && telecom.length) {
  //   for (let value of telecom) {
  //     if (value?.system == 'phone') {
  //       mobile = value?.value || '';
  //       break;
  //     }
  //   }
  // }
  return mobile;
  // if (Array.isArray(telecom) && telecom.length) {
  //   for (let value of telecom) {
  //     if (value?.system == 'phone') {
  //       mobile = value?.value || '';
  //       break;
  //     }
  //   }
  // }
  // return mobile;
};
export const resolvePatientEmail = (data) => {
  const {telecom = [],email=""} = data || {};
  // let email = '';
  // if (Array.isArray(telecom) && telecom.length) {
  //   for (let value of telecom) {
  //     if (value?.system === 'email') {
  //       email = value?.value || '';
  //       break;
  //     }
  //   }
  // }
  return email;
};

// export const updatePatientDataInForm = ({updates}) => {
//   let localName = {
//     family: updates.last_name || '',
//     given: [updates.first_name] || [],
//   };
//   let name = [localName];

//   let identifier = [
//     {
//       use: 'usual',
//       type: {
//         coding: [
//           {
//             system: 'http://hl7.org/fhir/v2/0203',
//             code: 'MR',
//             display: 'Medical Record Number',
//           },
//         ],
//         text: 'Medical Record Number',
//       },
//       system: 'http://hospital.smarthealthit.org',
//       value: updates.mrn || '',
//     },
//   ];
//   let telecom = [
//     {
//       system: 'phone',
//       value: updates.mobile || '',
//       use: 'personal',
//     },
//     {system: 'email', value: updates.email || ''},
//   ];
//   let address = [];
//   if (
//     updates.multipleAddress &&
//     Array.isArray(updates.multipleAddress) &&
//     updates.multipleAddress.length >= 1
//   ) {
//     updates.multipleAddress.map((item) => {
//       let tempAddress = {
//         name: item?.name || '',
//         line: [item?.street] || [],
//         city: item?.city || '',
//         state: item?.state || '',
//         postalCode: item?.zipcode || '',
//         country: item?.country?.name || '',
//       };
//       address.push(tempAddress);
//     });
//   }
//   let language = updates?.patient_language || '';
//   let communication = {language};

//   return {
//     updates: {
//       ...updates,
//       name,
//       identifier,
//       telecom,
//       address,
//       communication,
//     },
//   };
// };

export const updatePhysicianAssistantDataInForm = ({updates}) => {
  let localName = {
    family: updates.Lname,
    given: [updates.Fname],
  };
  let name = [localName];

  let identifier = [
    {
      value: updates.npi || '',
    },
  ];
  let telecom = [
    {
      system: 'phone',
      value: updates.mobile || '',
      use: 'personal',
    },
    {system: 'email', value: updates.email || ''},
  ];
  delete updates.Fname;
  delete updates.Lname;
  delete updates.npi;
  return {
    updates: {
      ...updates,
      name,
      identifier,
      telecom,
    },
  };
};

export const getMobileAndEmail = (telecom = []) => {
  let email = '',
    mobile = '';
  if (Array.isArray(telecom) && telecom.length) {
    for (let value of telecom) {
      const {system = '', value: telecomValue = ''} = value;
      if (system === 'email') {
        email = telecomValue || '';
      } else if (system == 'phone') {
        mobile = telecomValue || '';
      }
    }
  }
  return {email, mobile};
};

// export const patientDefaultValues = ({navigation}) => {
//   let multipleAddress = [];
//   let first_name,
//     patient_language,
//     last_name,
//     mrn,
//     dob,
//     {item = {}} = navigation.state.params;

//   const {
//     telecom = [],
//     name: patientName = [],
//     identifier = [],
//     communication = {},
//     address = [],
//   } = item || {};
//   let {my_doctors = []} = item;
//   if (Array.isArray(patientName) && patientName.length) {
//     first_name = (patientName[0].given || []).join(' ');
//     last_name = patientName[0].family;
//   }

//   if (Array.isArray(identifier) && identifier.length) {
//     mrn = identifier[0]?.value;
//   }
//   let {address: {country = {}} = {}} = item || {};
//   if (Array.isArray(address)) {
//     address.map((addressItem) => {
//       const {name = '', city = '', postalCode = '', state = '', line = []} =
//         addressItem || {};
//       country = {...country, name: addressItem?.country};
//       multipleAddress.push({
//         name,
//         city,
//         zipcode: postalCode,
//         state,
//         country,
//         street: (line || []).join(' '),
//       });
//     });
//   }
//   patient_language = communication?.language || '';
//   if (Array.isArray(my_doctors?.name)) {
//     my_doctors = {
//       ...my_doctors,
//       fullname:
//         (my_doctors?.name?.[0]?.given || []).join(' ') +
//         ' ' +
//         my_doctors?.name?.[0]?.family,
//     };
//   }
//   return {
//     ...item,
//     multipleAddress,
//     first_name: first_name,
//     last_name,
//     mrn,
//     dob,
//     patient_language,
//     userType: 'Patient',
//     ...(my_doctors?.name === undefined ? {} : {my_doctors}),
//     ...getMobileAndEmail(telecom),
//   };
// };


// export const modifyPatientNameForDropDown = ({data}) => {
//   const newData = data.map((item) => ({
//     ...item,
//     fullName: resolvePatientName(item),
//   }));
//   return {data: newData};
// };
