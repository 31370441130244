import React from 'react';
import {View} from '../../react-core-components';
import CardActions from './CardActions';

export default class HoverActions extends React.Component {
  render() {
    let {
      hoverActions,
      swipeActions,
      selected,
      opacity = 1,
      hoverActionsWrapperStyle,
      topHoverActionsWrapperStyle,
      bottomHoverActionsWrapperStyle,
      selectedHoverActionsWrapperStyle,
      hoverActionsContainerStyle,
      ...restProps
    } = this.props;
    if (swipeActions) {
      hoverActions = swipeActions;
    }
    if (typeof hoverActions === 'function') {
      hoverActions = hoverActions(restProps);
    }
    if (!hoverActions) {
      return null;
    }
    if (!Array.isArray(hoverActions)) {
      let {width, position, actions, items, containerStyle} = hoverActions;
      if (position === 'top' && topHoverActionsWrapperStyle) {
        hoverActionsWrapperStyle = topHoverActionsWrapperStyle;
      } else if (position === 'bottom' && bottomHoverActionsWrapperStyle) {
        hoverActionsWrapperStyle = bottomHoverActionsWrapperStyle;
      }
      hoverActionsContainerStyle = {
        ...hoverActionsContainerStyle,
        ...containerStyle,
      };
      if (width) {
        hoverActionsContainerStyle.width = width;
      }
      hoverActions = actions || items;
    }
    if (!hoverActions || !hoverActions.length) {
      return null;
    }
    hoverActionsWrapperStyle = {
      position: 'absolute',
      opacity,
      ...hoverActionsWrapperStyle,
      ...(selected ? selectedHoverActionsWrapperStyle : void 0),
    };
    return (
      <View style={hoverActionsWrapperStyle}>
        <CardActions
          actions={hoverActions}
          actionSource={'hoverActions'}
          hoverActionsContainerStyle={hoverActionsContainerStyle}
          {...restProps}
        />
      </View>
    );
  }
}

HoverActions.defaultProps = {
  hoverActionsWrapperStyle: {
    top: 0,
    bottom: 0,
    right: 0,
  },
  hoverActionsContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 8,
  },
  hoverActionsItemStyle: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  hoverActionsItemIconStyle: {
    height: 20,
    width: 20,
  },
};
