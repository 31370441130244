import React from 'react';
import {View, ScrollView} from '../../react-core-components';
import uuid from 'uuid/v4';

const getNewId = () => {
  return `new_${uuid()}`;
};

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

class NestedField extends React.Component {
  state = {};

  keyExtractor = (item) => {
    return item._id;
  };

  _getState = ({item = {}, index} = {}) => {
    let {data} = this.props;
    return {
      ...this.state[item._id],
      data: {...item, _parent: {...data}},
      index,
    };
  };
  _setState = ({item = {}, index} = {}) => (state) => {
    let _state = state;
    if (typeof _state === 'function') {
      _state = _state(this.state[item._id] || {});
    }
    this.setState({[item._id]: {...this.state[item._id], ..._state}});
  };

  addRow = ({_id}) => {
    let {field, setValue: parentSetValue, addInBottom} = this.props;
    parentSetValue &&
      parentSetValue({
        path: [{_id: _id || getNewId(), field}],
        insert: true,
        bottom: addInBottom,
      });
  };

  removeRow = ({item, index}) => {
    if (!item || !item._id) {
      return;
    }
    let {data, field, setValue: parentSetValue} = this.props;
    let value = data && field && data[field];
    if (!value || !value.length) {
      return;
    }
    let row = value[index];
    if (!row || !row._id) {
      return;
    }
    let path = [{_id: item._id, field}];
    parentSetValue &&
      parentSetValue({
        path,
        remove: true,
      });
  };

  setValue = ({data, path, ...rest}) => {
    if (!data || !data._id) {
      return;
    }
    let {field, setValue: parentSetValue} = this.props;
    path = path ? [...path] : [];
    path.unshift({_id: data._id, field});
    parentSetValue &&
      parentSetValue({
        path,
        ...rest,
      });
  };

  getExtraProps = () => {
    const {data, field, navigation, eventDispatcher} = this.props;
    return {
      data,
      field,
      navigation,
      eventDispatcher,
      addRow: this.addRow,
      removeRow: this.removeRow,
      setValue: this.setValue,
      _getState: this._getState,
      _setState: this._setState,
    };
  };

  render() {
    const {
      data,
      field,
      renderComponent,
      renderHeader,
      renderBody,
      renderFooter,
      renderItem,
      theme,
    } = this.props;
    let value = (data && field && data[field]) || [];
    if (renderComponent) {
      return (
        getRenderComponent(renderComponent, {
          value,
          ...this.getExtraProps(),
        }) || null
      );
    }
    let {containerStyle, bodyContainerStyle} = theme || {};
    return (
      <View style={containerStyle}>
        {getRenderComponent(renderHeader, this.getExtraProps())}
        {renderBody ? (
          renderBody({value}, this.getExtraProps())
        ) : renderItem ? (
          <ScrollView
            getRef={(_) => (this._containerRef = _)}
            style={bodyContainerStyle}>
            {value.map((item, index) => {
              return (
                <View key={item._id}>
                  {renderItem({item, index}, this.getExtraProps())}
                </View>
              );
            })}
          </ScrollView>
        ) : (
          void 0
        )}
        {getRenderComponent(renderFooter, this.getExtraProps())}
      </View>
    );
  }
}

export default NestedField;
