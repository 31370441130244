import React, {useCallback, useEffect} from 'react';
import {
  ReactForm,
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  TextInput,
  ActivityIndicator,
} from './components';
import {post, initUser, setUser} from '../../AppServices';
import {isMobile} from '../../app-components';
import AdvertisementBeforeLogin from '../GenericComponent/AdvertisementBeforeLogin';
export default ({authenticate, theme: otpStyle, images}) => {
  const {SVMLogo} = images;
  const errorMessage = ({field, mandatoryErrors, errorStyle}) => {
    let {code} = (field && mandatoryErrors && mandatoryErrors[field]) || {};
    return <Text style={errorStyle}>{code}</Text>;
  };
  return (props) => {
    const {navigation, onLogin} = props;
    const mobile = navigation.getParam('mobile');
    const type = navigation.getParam('type');
    useEffect(() => {
      if (!mobile) {
        navigation.reset('login');
      }
    }, []);
    const isPatientLogin = navigation.getParam('isPatientLogin') ?? true;
    const patientData = navigation.getParam('patientData');
    let {
      scrollViewStyle,
      containerStyle,
      loginBoxStyle,
      loginLogoContainer,
      loginLogoStyle,
      labelStyle,
      loginButton,
      loginButtonText,
      errorStyle,
      loginTextInput,
      otpText,
    } = otpStyle;

    const getLoginForm = useCallback(({form_context, form_state}) => {
      let {data} = form_state || {};
      let {setValue, mandatoryErrors} = form_context;
      const {password, otpError} = data;
      const handleOnSubmit = () => {
        if (password && password.length) {
          setValue && setValue({field: 'loading', value: true});
          authenticate({mobile, password, type})
            .then((res) => {
              if (res?.user) {
                // const {patient = {}} = res?.user || {};
                navigation.navigate('superAdminRPM');
                // if (isPatientLogin) {
                //   setValue && setValue({field: 'loading', value: false});
                //   onLogin();
                // } else {
                //   post({
                //     data: {_id: patient?._id},
                //     updates: {...patientData},
                //     model: 'Patients',
                //   }).then((res) => {
                //     setValue && setValue({field: 'loading', value: false});
                //     if (
                //       res &&
                //       res.result &&
                //       Array.isArray(res.result) &&
                //       res.result[0] &&
                //       res.result[0].result
                //     ) {
                //       setUser(res.result[0].result);
                //     }
                //     onLogin();
                //   });
                // }
              } else {
                setValue && setValue({field: 'loading', value: false});
                setValue && setValue({field: 'otpError', value: 'Wrong OTP'});
              }
            })
            .catch((err) => {
              console.log(
                `>@>Puru ~ file: OTPForm.js ~ line 96 ~ err`,
                typeof err,
              );
              setValue && setValue({field: 'loading', value: false});
              if (typeof err === 'string') {
                setValue && setValue({field: 'otpError', value: err});
              } else {
                let message = JSON.parse(err);
                if (message && message['error'] && message['error']?.message) {
                  setValue &&
                    setValue({
                      field: 'otpError',
                      value: message['error']?.message,
                    });
                } else {
                  setValue && setValue({field: 'otpError', value: 'Wrong OTP'});
                }
              }
            });
        } else {
          const valueMesage =
            password && password.length
              ? 'OTP length must be 6'
              : 'Please enter Passcode';
          setValue && setValue({field: 'otpError', value: valueMesage});
        }
      };
      return (
        <View
          style={{
            ...containerStyle,
            ...(isMobile
              ? {
                  maxHeight: 400,
                  marginTop: 30,
                  alignItems: 'center',
                }
              : {}),
            // backgroundImage: `url(${bgImage})`,
          }}>
          {isMobile ? (
            void 0
          ) : (
            <View style={{flex: 2}}>
              <AdvertisementBeforeLogin {...props} />
            </View>
          )}
          <View
            style={{...loginBoxStyle, height: '100%', flex: 1}}
            id="passcode_container">
            <View
              style={
                {
                  // ...loginBoxStyle,
                }
              }>
              <View style={loginLogoContainer}>
                <Image
                  // source={StewardLogo}
                  id="brand_logo"
                  // source={hospitalLiveLogo}
                  source={SVMLogo}
                  style={{...loginLogoStyle}}
                />
              </View>
              <View style={otpText}>
                {/* {isPatientLogin ? (
                  <TouchableOpacity onPress={() => navigation.replace('login')}>
                    <Image source={back} style={backLogo} />
                  </TouchableOpacity>
                ) : null} */}

                <Text id="passcode_label" style={labelStyle}>
                  Enter Passcode
                </Text>
              </View>
              {/* <Text style={otpInfoText}>
                We have sent you a 6-digit verification PIN at {mobile}
                {isPatientLogin ? (
                  <TouchableOpacity
                    style={{display: 'inline-block'}}
                    onPress={() => navigation.replace('login')}>
                    <Text style={editButton}>Edit</Text>
                  </TouchableOpacity>
                ) : null}
              </Text> */}

              <TextInput
                setValue={setValue}
                data={data}
                field="password"
                autoFocus={true}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleOnSubmit && handleOnSubmit();
                  }
                }}
                theme={loginTextInput}
                placeholder="Enter passcode"
                // inputmode="numeric"
                id="passcode"
                type={'password'}
                onFocus={() =>
                  setValue && setValue({field: 'otpError', value: ''})
                }
                onChangeText={() => {
                  if (data?.password?.length === 1 && data.otpError) {
                    setTimeout(() => {
                      setValue && setValue({field: 'otpError', value: ''});
                    }, 100);
                  }
                }}
              />
              <Text
                style={{
                  ...errorStyle,
                  display: data?.otpError ? 'flex' : 'flex',
                }}>
                {data?.otpError}
              </Text>
              {errorMessage({field: 'password', mandatoryErrors, errorStyle})}
              {/* <View style={resendPinContainer}>
                <Text style={resendText}>Didn’t receive the pin?</Text>
                <TouchableOpacity>
                  <Text style={{...resendButton}}>Resend PIN</Text>
                </TouchableOpacity>
              </View> */}

              <TouchableOpacity
                style={loginButton}
                onPress={() => {
                  handleOnSubmit();
                }}
                id="confirm">
                {data?.loading ? (
                  <ActivityIndicator size={'small'} color={'white'} />
                ) : (
                  <Text style={loginButtonText}>Verify</Text>
                )}
              </TouchableOpacity>
            </View>
          </View>

          {/* <TouchableOpacity
            style={contactSupportViewStyle}
            onPress={() =>
              navigation.push({view: 'contact-support-form', modal: true})
            }>
            <Text style={contactSupportText}>Contact support</Text>
          </TouchableOpacity> */}
        </View>
      );
    });
    return (
      <ScrollView style={scrollViewStyle}>
        <ReactForm
          mandatory={{
            password: {code: 'OTP is mandatory'},
          }}
          defaultValues={() => {
            return {
              mobile: mobile,
            };
          }}>
          {getLoginForm}
        </ReactForm>
      </ScrollView>
    );
  };
};
