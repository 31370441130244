import SymptomForm from './SymptomForm';
import {EditSymptom} from '../../Queries';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Edit Symptom',
  actions: [cancelButton(), save()],
};

export default SymptomForm({uri: EditSymptom, header});
