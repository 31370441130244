import React from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  Image,
} from '../../../app-components';
import {GradientButton} from '../../../app-components/buttons/Buttons';
import {getFileExtension} from '../../../app-components/UtilityFunctions';
import {getImageUrl} from '../../../AppServices';
import {pdf, roundCross} from '../../../images';
import {
  h12_Italic,
  h12_Regular,
  h14_Medium,
  h15_Medium,
  h16_Medium,
} from '../../../theme/fonts';
import {MultiTextInput} from '../../../app-components/input-components/Editors';
import PatientAppointmentDetails from '../../DoctorSearch/PatientAppointmentDetails';
import PatientDetailsUpdate from '../PatientDetailsUpdate';
import MayamdData from '../MayaAiSymptom';

const Symptom = (props) => {
  const {
    item = {},
    data = {},
    setValue = () => {},
    collapse_handler = () => {},
  } = props;
  // console.log('setValue', data);
  const {medical_report = []} = item;
  let medical_Report_Slice = medical_report;
  let medical_Report_Slice_Second = [];

  const IconLength = 4;
  if (medical_report.length > IconLength) {
    medical_Report_Slice = medical_report.slice(0, IconLength);
    medical_Report_Slice_Second = medical_report.slice(
      IconLength,
      medical_report.length,
    );
  }
  let {investigated_symptoms} = data;
  const getIdentifiedSymptomData = (props) => {
    let {data} = props;
    return data ? (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 15,
        }}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <Text
            style={{
              ...h12_Regular,
              lineHeight: 16,
              color: '#383838',
              numberOfLines: 1,
            }}>
            {data?.length > 0 &&
              data.map((name, index) => {
                let separator = (index + 1 < data.length && ', ') || '';
                return name && name + separator;
              })}
          </Text>
        </View>
        <View>
          <Text
            style={{
              ...h12_Italic,
              lineHeight: 16,
              color: '#383838',
            }}>
            Identified by you
          </Text>
        </View>
      </View>
    ) : (
      void 0
    );
  };

  return (
    <>
      <View
        style={{
          backgroundColor: '#f9f9f9',
          marginTop: 10,
          padding: 10,
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 8,
        }}>
        {/* <View>
          <Text style={{...h12_Regular, lineHeight: 21, letterSpacing: 0.43}}>
            {item?.illness_description}
          </Text>
        </View> */}

        {/* <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 15,
            flex: 1,
          }}>
          <View>
            <View style={{flexDirection: 'row'}}>
              {Array.isArray(medical_Report_Slice)
                ? medical_Report_Slice.map((item) => (
                    <TouchableOpacity
                      style={{
                        height: 49,
                        width: 50,
                        marginLeft: 5,
                        borderRadius: 4,
                        cursor: 'pointer',
                      }}
                      onPress={() => {
                        const {navigation} = props;
                        navigation.push({
                          view: 'pdfReader',
                          params: {
                            dataArr: medical_report,
                          },
                          modal: true,
                          modalProps: {
                            autoHide: true,
                            minHeight: 180,
                            position: 'screenRight',
                            style: {
                              height: '100%',
                              width:
                                Dimensions.get('window').width -
                                props.publisherViewWidth,
                              zIndex: 999,
                            },
                            backdropStyle: {},
                            // ...confirmModalProps,
                          },
                          // modalProps: {
                          //   autoHide: true,
                          //   minHeight: 180,
                          //   height: 600,
                          //   width: isMobile ? 300 : 700,
                          //   position: 'screenCenter',
                          //   zIndex: 9999,

                          //   // ...confirmModalProps,
                          // },
                        });
                      }}>
                      {getFileExtension(item.name) === 'pdf' ? (
                        <Image source={pdf} style={{height: 49, width: 50}} />
                      ) : (
                        <Image
                          source={getImageUrl(item?.thumbnail)}
                          style={{height: 49, width: 50}}
                        />
                      )}
                    </TouchableOpacity>
                  ))
                : null}
              {Array.isArray(medical_Report_Slice_Second) &&
              medical_Report_Slice_Second.length ? (
                <TouchableOpacity
                  style={{
                    height: 49,
                    width: 50,
                    marginLeft: 5,
                    borderRadius: 4,
                    justifyContent: 'center',
                    backgroundColor: '#383838',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    const {navigation} = props;
                    navigation.push({
                      view: 'pdfReader',
                      params: {
                        dataArr: medical_report,
                      },
                      modal: true,

                      modalProps: {
                        autoHide: true,
                        minHeight: 180,
                        position: 'screenRight',
                        style: {
                          height: '100%',
                          width:
                            Dimensions.get('window').width -
                            props.publisherViewWidth,
                          zIndex: 999,
                        },
                        backdropStyle: {},
                        // ...confirmModalProps,
                      },
                    });
                  }}>
                  <Text style={{color: '#fff'}}>
                    {'+' + medical_Report_Slice_Second.length}
                  </Text>
                </TouchableOpacity>
              ) : null}
            </View>
            <Text
              style={{
                ...h12_Regular,
                lineHeight: 16,
                color: '#383838',
                marginTop: 10,
              }}>
              {item?.patient_symptoms?.toString()}
            </Text>
          </View>
          
          {item?.patient_symptoms ||
          (Array.isArray(medical_report) && medical_report.length) ? (
            <Text
              style={{
                ...h12_Italic,
                lineHeight: 16,
                color: '#383838',
                alignSelf: 'flex-end',
              }}>
              Shared by Patient
            </Text>
          ) : (
            void 0
          )}
        </View>
           */}
        <MayamdData {...props} />
        <PatientDetailsUpdate {...props} isClinicalView={true} />
        <Text
          style={{
            ...h15_Medium,
            // marginBottom: 10,
            marginTop: 10,
            paddingLeft: 10,
            color: '#383838',
          }}>
          Review of Systems
        </Text>
        <PatientAppointmentDetails {...props} isDoctorView={true} />
        {getIdentifiedSymptomData({data: investigated_symptoms})}
      </View>

      <>
        {/* <View style={{marginTop: 15}}>
          <Text style={{...h14_Medium, lineHeight: 16, color: '#383838'}}>
            Symptoms Identified by you
          </Text>
        </View> */}
        {/* <View style={{marginTop: 10, marginBottom: 10}}>
          <MultiTextInput
            value={investigated_symptoms}
            field="investigated_symptoms"
            data={data}
            variant={'filled'}
            removeChipIcon={roundCross}
            onChangeValue={(value) => {
              setValue({data, field: 'investigated_symptoms', value});
            }}
            placeholder="Add Symptoms here.."
          />
        </View>
         */}
        <View style={{flexDirection: 'row-reverse', marginTop: 5}}>
          <TouchableOpacity
            style={{width: 90, marginRight: 30, cursor: 'pointer'}}
            onPress={() => {
              collapse_handler({selected: 'diagnosis'});
            }}>
            <GradientButton label="Next" />
          </TouchableOpacity>
        </View>
      </>
    </>
  );
};

export default Symptom;
