import React from 'react';
import {
  autoSuggestInput,
  multiSearchInput,
  searchInput,
  autoSuggestOptionsInput,
  multiSearchAvatarInput,
} from '../../autoSuggestions';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';
import Form from '../../app-components/form/Form';
import {getData, getUser, submit, invoke} from '../../AppServices';
import {TextInput} from '../../app-components/input-components/Editors';
import {View, Text} from '../../app-components';
import {resolvePatientName} from '../../app-components/patientUtility';
import uuid from 'uuid/v4';
import {compact, get} from 'lodash';
import {h15_Medium} from '../../theme/fonts';
import {colors} from '../../theme/colors';
import moment from 'moment';
import {serialNumber} from './rpmUtils';

const visibleBloodPressureField = ({data}) => {
  if (
    data?.parameter?.name === 'Blood Pressure' &&
    data?.parameter?.type === 'range'
  ) {
    return true;
  }
  return false;
};
const visibleRange = ({data}) => {
  if (data?.parameter?.name === 'Blood Pressure') {
    return false;
  }
  return data?.parameter?.type === 'range';
};
const visibleDeviceField = ({data}) => {
  const {_parent: {careProgram = []} = {}} = data || {};
  if (Array.isArray(careProgram) && careProgram.length) {
    const {careProgram: CarePrograms = {}} = careProgram[0];
    return !CarePrograms?.isManualReading;
  }

  return true;
};
const updateForm = async ({updates, data}) => {
  let {
    monitoringParameter = [],
    careProgram: careProgramChange,
    // multipleAddress,
    chronicDisease,
    checkList,
  } = updates;
  const {_id} = data;
  // const {_id: multipleAddressId = ''} = multipleAddress || {};
  // const { organization: { _id: organizationId = '123' } = {} } = getUser();
  // console.log('organizationId: ', organizationId);
  let careProgram,
    isProgramAssigned = false;
  if (Array.isArray(careProgramChange) && careProgramChange.length) {
    careProgram = careProgramChange.filter((item) => {
      delete item.past;
      return Object.keys(item).length > 1;
    });
    if (careProgram?.[0] && careProgram[0]?.careProgram?._id) {
      careProgram[0] = {
        ...careProgram?.[0],
        patientName: {_id},
        chronicDisease,
        // programAdmin:data
      };
    }
    if (careProgram?.[0] && careProgram[0]?.careProgram?._id) {
      if (Array.isArray(checkList) && checkList.length) {
        careProgram[0] = {
          ...careProgram?.[0],
          checkList,
        };
      }
      if (Array.isArray(monitoringParameter) && monitoringParameter.length) {
        monitoringParameter = monitoringParameter.map((item) => {
          delete item.devices;
          return item;
        });
        careProgram[0] = {
          ...careProgram?.[0],
          // organization: {_id: organizationId},
          monitoringParameter,
          // patientName: {_id},
          // chronicDisease,
          // ...(multipleAddressId
          //   ? {multipleAddress: {...multipleAddress, _id: multipleAddressId}}
          //   : {}),
        };
      }
    } else {
      careProgram.shift();
    }
  }
  if (Array.isArray(careProgram) && careProgram.length) {
    isProgramAssigned = true;
  }
  // let modifiedMedicationCondition = medicalConditions;
  // if (Array.isArray(chronicDisease) && chronicDisease.length) {
  //   if (Array.isArray(medicalConditions) && medicalConditions.length) {
  //     for (let val of chronicDisease) {
  //       const {_id: chronicDiseaseId} = val;
  //       if (
  //         medicalConditions.findIndex((i) => i?._id == chronicDiseaseId) == -1
  //       ) {
  //         modifiedMedicationCondition.push(val);
  //       }
  //     }
  //   } else {
  //     modifiedMedicationCondition = chronicDisease;
  //   }
  // }
  let modifiedProgramAdmin = [];
  if (data?.village?.doctor) {
    modifiedProgramAdmin.push({_id: data?.village?.doctor});
  } else if (Array.isArray(careProgram) && careProgram.length) {
    careProgram.map((item) => {
      const {
        careProgram: {programAdmin},
      } = item;
      if (programAdmin) {
        modifiedProgramAdmin.push(programAdmin);
      }
      return true;
    });
  }
  invoke({
    id: 'createCareTeam',
    paramValue: {
      updates,
      data,
    },
  })
    .then((res) => console.log('@res', res))
    .catch((error) => {
      console.log('updateForm -> error', error);
    });
  return {
    updates: {
      careProgram,
      medicalConditions: chronicDisease,
      isProgramAssigned,
      my_doctors: modifiedProgramAdmin,
    },
  };
};
const getNewId = () => {
  return `new_${uuid()}`;
};
export default Form({
  type: 'standardShadow',
  reloadEvent: 'reloadPatient',
  reloadOnChangeEvent: 'reloadPatient',
  fieldVariant: 'filled',
  closeView: 1,
  beforeSubmit: updateForm,
  onSubmit: submit({
    model: 'Patients',
  }),
  defaultValues: ({navigation = {}}) => {
    let patientDetails = navigation.getParam('item');
    const {careProgram} = patientDetails || {};
    let careProgramModified = Array.isArray(careProgram)
      ? careProgram.map((item, index) => {
          return {...item, past: index + 1};
        })
      : [];
    return {
      patientName: patientDetails,
      chronicDisease: patientDetails?.medicalConditions || [],
      _id: patientDetails?._id,
      careProgram: [
        ...(Array.isArray(careProgramModified)
          ? [
              {
                _id: getNewId(),
                startDate: new Date(),
                endDate: moment().add(1, 'years'),
                status: 'Active',
              },
              ...careProgramModified,
            ]
          : [
              {
                _id: getNewId(),
                startDate: new Date(),
                endDate: moment().add(1, 'years'),
                status: 'Active',
              },
            ]),
      ],
    };
  },
  header: {
    title: 'Assign Care Programme',
    titleTextStyle: {
      color: 'rgba(56, 56, 56, 0.87)',
      fontSize: 20,
      marginLeft: 10,
      fontFamily: 'AvenirNext-Regular',
    },
    actions: [cancelButton(), save({label: 'Save'})],
  },
  computations: {
    self: {
      'show field default': {
        compute: (data) => {
          let {careProgram} = data;
          if (careProgram) {
            const monitoringParameter = get(
              data,
              'careProgram[0].careProgram.monitoringParameter',
              [],
            );
            return {
              set: {
                monitoringParameter: [
                  ...(monitoringParameter ? monitoringParameter : []),
                ],
              },
            };
          }
        },
        onChange: ['careProgram.careProgram'],
      },
      ccm: {
        compute: async (data) => {
          let {careProgram} = data;
          if (careProgram) {
            const isCCM = get(data, 'careProgram[0].careProgram.isCCM', false);
            if (isCCM) {
              const res = await getData({
                model: 'CcmCheckList',
                id: 'ccmCheckLists',
              });
              const {data = []} = res || {};
              if (Array.isArray(data) && data.length) {
                const checkList = data.map((item) => {
                  return {
                    _id: getNewId(),
                    question: item,
                  };
                });
                return {
                  set: {
                    checkList,
                    isCCM,
                  },
                };
              }
              return {
                set: {
                  isCCM: false,
                },
              };
            }

            return {
              set: {
                isCCM,
              },
            };
          }
        },
        onChange: ['careProgram.careProgram'],
      },
      // setDefaultCareProgram: {
      //   compute: async (data) => {
      //     let {careProgram} = data;
      //     const careProgramName = get(
      //       data,
      //       'careProgram[0].careProgram.name',
      //       '',
      //     );
      //     if (careProgramName) {
      //       console.log(
      //         `>@>Puru ~ file: AssignProgramForm.js ~ line 225 ~ careProgramName`,
      //         careProgramName,
      //       );
      //     } else {
      //       console.log(
      //         `>@>Puru ~ file: AssignProgramForm.js ~ line 225 ~ careProgramName`,
      //         careProgramName,
      //       );
      //     }
      //     // console.log(`>@>Puru ~ file: AssignProgramForm.js ~ line 220 ~ data`, data)

      //     return {
      //       set: {
      //         // isCCM,
      //       },
      //     };
      //   },
      //   onChange: ['chronicDisease'],
      // },
    },
    children: {
      careProgram: {
        self: {
          'test tets': {
            compute: (data) => {
              const {_parent: {patientName} = {}} = data || {};
              return {
                set: {patientName},
              };
            },
            onChange: ['_id'],
          },
        },
      },
    },
  },
  formGroups: [
    {
      label: 'Medical Condition',
      // expandable: true,
      // expanded: false,
      columnsPerRow: 2,

      columns: [
        multiSearchInput(
          {
            label: 'Medical Condition',
            placeholder: 'Add medical history here..',
            field: 'chronicDisease',
            query: 'diagnoseLists',
            model: 'Diagnosis',
            valueField: 'name',
            suggestionField: 'name',
            searchField: 'name',
            // visible: ({data}) => !!!data.past,
          },
          {
            addOnFilter: (props) => {
              let users = get(props, 'data.chronicDisease', []);
              users = users.map((userItem) => {
                let id = get(userItem, '_id', '');
                if (id) {
                  return id;
                }
              });
              let userIds = compact(users);
              return {
                _id: {$nin: userIds},
              };
            },
          },
        ),
      ],
    },
    {
      label: 'Care Program',
      // expandable: true,
      groups: [
        {
          columns: [
            {
              type: 'nestedList',
              field: 'careProgram',
              addInBottom: true,
              formGroupHeaderStyle: {marginLeft: 10, fontWeight: 'bold'},
              formGroups: [
                {
                  fieldVariant: 'filled',
                  direction: 'row',
                  label: ({data}) => {
                    return 'Previous Care Program' || data?.careProgram?.name;
                  },
                  renderHeader: ({data, ...restProps}) => {
                    if (data?.past == 1) {
                      return (
                        <Text
                          style={{
                            ...h15_Medium,
                            marginBottom: 2,
                            marginTop: 8,
                            marginLeft: 10,
                          }}>
                          {restProps?.label}
                        </Text>
                      );
                    }
                    return void 0;
                  },
                  groups: [
                    {
                      columnsPerRow: 4,
                      columns: [
                        {
                          type: 'text',
                          field: 'careProgram.name',
                          editable: false,
                          // render: ({data}) => {
                          //   return (
                          //     <TextInput
                          //       variant="filled"
                          //       value={data?.careProgram?.name || ''}
                          //       editable={false}
                          //     />
                          //   );
                          // },
                          visible: ({data}) => {
                            if (data.past) {
                              return true;
                            }
                            return false;
                          },
                        },
                        autoSuggestInput(
                          {
                            label: 'Care Program',
                            placeholder: 'Add care program here..',
                            field: 'careProgram',
                            query: 'careProgramList',
                            model: 'CarePrograms',
                            valueField: 'name',
                            suggestionField: 'name',
                            visible: ({data}) => {
                              if (!data.past) {
                                return true;
                              }
                              return false;
                            },
                          },
                          {
                            addOnFilter: (props) => {
                              const {
                                data: {_parent},
                              } = props;
                              let filter = {$or: []};
                              let {chronicDisease} = _parent;
                              chronicDisease.map((item) => {
                                filter.$or.push({
                                  chronicDisease: item,
                                });
                              });
                              const {organization} = getUser() || {};
                              return {
                                $and: [
                                  {...filter},
                                  {$or: [{isDefault: true}, {organization}]},
                                ],
                              };
                            },
                          },
                        ),
                        {
                          type: 'date',
                          field: 'startDate',
                          label: 'Start Date',
                          format: 'MM/DD/yyyy',
                          editable: ({data}) => {
                            return !data?.past;
                          },
                        },
                        {
                          field: 'endDate',
                          label: 'End Date',
                          type: 'date',
                          format: 'MM/DD/yyyy',
                          // editable: ({data}) => {
                          //   return !!!data?.past;
                          // },
                        },
                        {
                          width: 250,
                          type: 'toggleSwitch',
                          rightValue: {label: 'Active', value: 'Active'},
                          leftValue: {label: 'Inactive', value: 'Inactive'},
                          tintColor: 'red',
                          activeTintColor: colors.themeColor,
                          field: 'status',
                          header: 'Status',
                          // label: 'Non Auditable',
                          showOuterLabel: true,
                        },
                        {
                          type: 'custom',
                          width: '100%',
                          render: ({data}) => {
                            return (
                              <View
                                style={{
                                  borderBottomWidth: 2,
                                  borderBottomColor: '#f2eeed',
                                  height: 5,
                                  marginBottom: 5,
                                }}
                              />
                            );
                          },
                        },
                      ],
                    },
                    // {
                    //   width: 20,
                    //   columns: [
                    //     {
                    //       // width: 50,
                    //       render: (props) => closeButton(props),
                    //       visible: ({data}) => !!data?.past,
                    //     },
                    //     {
                    //       // width: 50,
                    //       type: 'text',
                    //       editable: false,
                    //       // render: (props) => closeButton(props),
                    //       visible: ({data}) => !data?.past,
                    //     },
                    //   ],
                    // },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'Monitoring Parameter',
      visible: ({data}) => {
        const {monitoringParameter = []} = data;
        return Array.isArray(monitoringParameter) && monitoringParameter.length;
      },
      // expandable: true,
      groups: [
        {
          columns: [
            {
              type: 'nestedList',
              field: 'monitoringParameter',
              addInBottom: true,
              formGroupHeaderStyle: {marginLeft: 10, fontWeight: 'bold'},
              formGroups: [
                {
                  columnsPerRow: 4,
                  fieldVariant: 'filled',
                  label: ({data}) => {
                    return data?.parameter?.name;
                  },
                  renderHeader: (props) => {
                    return (
                      <Text
                        style={{
                          ...h15_Medium,
                          marginBottom: 2,
                          marginTop: 8,
                          marginLeft: 10,
                        }}>
                        {props?.label}
                      </Text>
                    );
                  },
                  groups: [
                    {
                      columns: [
                        autoSuggestInput({
                          label: 'Monitoring Parameter',
                          field: 'parameter',
                          placeholder: 'Select Unit',
                          query: 'parameterList',
                          model: 'MonitoringParameter',
                          valueField: 'name',
                          suggestionField: 'name',
                          visible: false,
                        }),

                        // searchInput({
                        //   label: 'Check Interval',
                        //   field: 'checkInterval',
                        //   placeholder: 'Select Unit',
                        //   query: 'devicesList',
                        //   model: 'Devices',
                        //   valueField: 'name',
                        //   suggestionField: 'name',
                        // }),

                        // {
                        //   type: 'text',
                        //   label: 'Lower Threshold',
                        //   field: 'lowerThreshold',
                        //   visible: ({data}) => visibleRange({data}),
                        // },
                        // {
                        //   type: 'text',
                        //   label: 'Higher Threshold',
                        //   field: 'higherThreshold',
                        //   visible: ({data}) => visibleRange({data}),
                        // },
                        // {
                        //   type: 'number',
                        //   label: 'Systolic Lower',
                        //   field: 'systolicLowerThreshold',
                        //   // editable: superAdminEditable,
                        //   visible: ({data}) =>
                        //     visibleBloodPressureField({data}),
                        // },

                        // {
                        //   type: 'number',
                        //   label: 'Systolic Higher',
                        //   field: 'systolicHigherThreshold',
                        //   visible: ({data}) =>
                        //     visibleBloodPressureField({data}),
                        //   // editable: superAdminEditable,
                        // },

                        // {
                        //   type: 'number',
                        //   label: 'Diastolic Lower',
                        //   field: 'diastolicLowerThreshold',
                        //   // editable: superAdminEditable,
                        //   visible: ({data}) =>
                        //     visibleBloodPressureField({data}),
                        // },

                        // {
                        //   type: 'number',
                        //   label: 'Diastolic Higher',
                        //   field: 'diastolicHigherThreshold',
                        //   visible: ({data}) =>
                        //     visibleBloodPressureField({data}),
                        //   // editable: superAdminEditable,
                        // },
                        // {
                        //   type: 'text',
                        //   label: 'Rate of change ',
                        //   field: 'rateOfChange',
                        //   visible: ({data}) =>
                        //     data?.parameter?.type == 'rate of change',
                        // },
                        searchInput({
                          label: 'Check Interval',
                          field: 'checkInterval',
                          placeholder: 'Select Interval',
                          query: 'checkIntervalList',
                          model: 'CheckIntervals',
                          valueField: 'name',
                          suggestionField: 'name',
                          searchField: 'name',
                        }),
                        searchInput({
                          // width: '40%',
                          label: 'Unit Measurement',
                          field: 'unitMeasurement',
                          placeholder: 'Select Unit',
                          query: 'unitMeasurementList',
                          model: 'UnitMeasurements',
                          valueField: 'name',
                          suggestionField: 'name',
                          visible: false,
                        }),
                        {
                          type: 'custom',
                          render: () => [],
                          visible: ({data}) => {
                            return !visibleDeviceField({data});
                          },
                        },
                        // multiSearchAvatarInput({
                        //   field: 'device',
                        //   placeholder: 'Select Unit',
                        //   suggestionField: 'name',
                        //   valueField: 'name',
                        //   keyField: 'name',
                        //   visible: ({data}) => {
                        //     return visibleDeviceField({data});
                        //   },
                        //   avatarIcon: 'image.file',
                        //   showAvatar: true,
                        //   options: ({data}) => {
                        //     let abc = [];
                        //     if (data.device) {
                        //       let devices = data.device;
                        //       if (!Array.isArray(data.device)) {
                        //         devices = [data.device];
                        //       }
                        //       abc = devices.map((item) => {
                        //         return item;
                        //       });
                        //       return abc;
                        //     }
                        //   },

                        //   label: 'Add Device',
                        //   editable: true,
                        // }),
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'CCM Checklist',
      expandable: true,
      expanded: true,
      visible: ({data}) => {
        const {isCCM = false, checkList = []} = data;
        return isCCM && Array.isArray(checkList) && checkList.length;
      },
      groups: [
        {
          columns: [
            {
              type: 'nestedList',
              field: 'checkList',
              addInBottom: true,
              formGroupHeaderStyle: {marginLeft: 10, fontWeight: 'bold'},
              formGroups: [
                {
                  columnsPerRow: 3,
                  fieldVariant: 'filled',
                  groups: [
                    {
                      columns: [
                        {
                          type: 'custom',
                          render: (props) => {
                            return (
                              <View
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  flex: 1,
                                }}>
                                {serialNumber(props)}
                              </View>
                            );
                          },
                          width: 30,
                        },
                        {
                          type: 'custom',
                          render: ({data}) => {
                            return (
                              <TextInput
                                variant="filled"
                                value={data?.question?.question || ''}
                                editable={false}
                              />
                            );
                          },
                        },
                        {
                          width: 205,
                          type: 'chipsToggle',
                          field: 'answer',
                          // label: 'Gender',
                          // editable: editable,
                          mandatory: true,
                          options: [
                            {value: 'yes', label: 'Yes'},
                            {value: 'no', label: 'No'},
                            {value: 'n/a', label: 'N/A'},
                          ],
                        },
                        // {
                        //   width: 50,
                        //   type: 'checkBox',
                        //   field: 'enable',
                        //   label: 'Enable Dialysis',
                        // },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});
