import { cancelButton } from '../../../../app-components/action/CloseAction';
import { save } from '../../../../app-components/action/SaveAction';
import Form from '../../../../app-components/form/Form';
import {submit, getUser} from '../../../../AppServices';

export default ({header, uri}) => {
  const CilnicAdminFrom = Form({
    type: 'standardShadow',
    fieldContainer: 'topLabel',
    closeView: 1,
    onSubmit: submit({
      model: 'User',
      lowerCaseFields: ['email'],
    }),
    uri,
    reloadEvent: 'reloadClinicAdmin',
    mandatory: {name: 1, email: 1, mobile: 1},
    defaultValues: ({
      navigation: {
        state: {params: {item: {_id} = {}} = {}},
      },
    }) => {
      if (!_id) {
        const {organization = {}} = getUser();
        _id = organization?._id;
      }
      
      return {
        userType: 'ClinicAdmin',
        organization: {_id},
        isActive:true
        // organization:{_id:}
      };
    },
    lg: {
      header,
      formGroups: [
        {
          fieldVariant: 'filled',
          direction: 'row',
          groups: [
            {
              columnsPerRow: 2,
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'Name',
                  placeholder: 'Name',
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'email',
                  placeholder: 'Email',
                  label: 'Email',
                  mandatory: true,
                },
                {
                  label: 'Mobile',
                  type: 'mobile',
                  field: 'mobile',
                  placeholder: 'Mobile',
                  mandatory: true,
                },
                {
                  type: 'chipsToggle',
                  field: 'gender',
                  label: 'Gender',
                  options: [
                    {value: 'Male', label: 'Male'},
                    {value: 'Female', label: 'Female'},
                    {value: 'Other', label: 'Other'},
                  ],
                },
                {
                  type: 'checkBox',
                  field: 'isActive',
                  // placeholder: PLACEHOLDER,
                  label: 'Active',
                  // mandatory: true,
                },
              ],
            },
            {
              width: 250,
              columns: [
                {
                  type: 'uploadImage',
                  field: 'photo',
                  description:
                    '*File should be image (Jpeg or png). Profile image should be square in shape.',
                },
              ],
            },
          ],
        },
      ],
    },
    sm: {
      header: {
        title: header.title,
      },
      footer: {
        actions: [cancelButton(), save()],
      },
      formGroups: [
        {
          fieldVariant: 'filled',
          direction: 'row',

          columnsPerRow: 1,
          columns: [
            {
              type: 'uploadImage',
              field: 'photo',
              description:
                '*File should be image (Jpeg or png). Profile image should be square in shape.',
            },
            {
              type: 'text',
              field: 'name',
              label: 'Name',
              placeholder: 'Name',
              mandatory: true,
            },
            {
              type: 'text',
              field: 'email',
              placeholder: 'Email',
              label: 'Email',
              mandatory: true,
            },
            {
              label: 'Mobile',
              type: 'mobile',
              field: 'mobile',
              placeholder: 'Mobile',
              mandatory: true,
            },
            {
              type: 'chipsToggle',
              field: 'gender',
              label: 'Gender',
              options: [
                {value: 'Male', label: 'Male'},
                {value: 'Female', label: 'Female'},
                {value: 'Other', label: 'Other'},
              ],
            },
          ],
        },
      ],
    },
  });
  return CilnicAdminFrom;
};
