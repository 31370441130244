import React from 'react';
import Table from '../../../../app-components/table/Table';
import {getUser, post,invoke, showMessage} from '../../../../AppServices';
import {BASE_URL} from '../../../../Config';
import {resolveDoctorName} from '../../../../app-components/DoctorUtility';
import {h11_Medium, h14_AvenirNext, h16_Regular} from '../../../../theme/fonts';
import {
  Text,
  View,
  TouchableOpacity,
  isMobile,
  Image,
} from '../../../../app-components';
import {GradientButton} from '../../../../app-components/buttons/Buttons';
import {newDeleteIcon} from '../../../../images';
// import {CheckBox} from '../../../../app-components/input-components/Editors';
let uri = ({navigation}) => {
  let {state: {params: {item: {_id} = {}} = {}} = {}} = navigation;
  let {organization: {_id: organizationId} = {}} = getUser();
  if (!_id) {
    _id = organizationId;
  }
  return {
    query: {},
    url: `${BASE_URL}/Practitioner?organizationId=${_id}`,
    method: 'get',
  };
  // return {
  // //   query: {
  // //     id: 'doctorLists',
  // //     addOnFilter: {
  // //       organization: {_id},
  // //     },
  // //   },
  // //   model: 'Doctor',
  // // };
};
// const verifyDoc = ({item, title, value, model = 'Doctor', eventDispatcher}) => {
//   const {documents = [], document_verified = ''} = item;
//   console.log(`>@>Puru ~ file: Doctors.js ~ line 32 ~ item`, item);
//   if (!documents.length) {
//     return null;
//   }
//   return (
//     <TouchableOpacity
//       style={{
//         backgroundColor: '#57ccbf',
//         backgroundColor: title == 'Verify' ? '#57ccbf' : '#f14f4f',
//         borderRadius: 50,
//         cursor: 'pointer',
//       }}
//       onPress={() => {
//         if (document_verified === value) {
//           return null;
//         }
//         post({
//           data: item,
//           updates: {
//             document_verified: value || title,
//           },
//           model,
//         }).then(({}) => {
//           eventDispatcher.notify('reloadDoctor');
//         });
//       }}>
//       <View
//         style={{
//           alignItems: 'center',
//           padding: 5,
//         }}>
//         <Text
//           style={{
//             ...h16_Regular,
//             color: 'white',
//           }}>
//           {title === 'Verify'
//             ? document_verified === 'verified'
//               ? 'Verified'
//               : title
//             : document_verified === 'rejected'
//             ? 'Rejected'
//             : title}
//         </Text>
//       </View>
//     </TouchableOpacity>
//   );
// };
// const documents = ({item, navigation}) => {
//   const {documents = [], mayamd_notes = []} = item;
//   // console.log('{}ITEM!!!!!!!!!!!!!!!!!!!!!!!!!!', item);
//   return (
//     <View style={{flexDirection: 'row'}}>
//       {Array.isArray(documents) && documents.length ? (
//         <TouchableOpacity
//           style={{flexDirection: 'row', cursor: 'pointer'}}
//           onPress={() => {
//             navigation.push({
//               view: 'pdfReader',
//               params: {
//                 dataArr: documents,
//               },
//               modal: true,

//               modalProps: {
//                 autoHide: true,
//                 minHeight: 200,
//                 height: 600,
//                 width: isMobile ? 300 : 650,
//                 position: 'screenCenter',
//                 // ...confirmModalProps,
//               },
//             });
//           }}>
//           <Text style={{...h11_Medium, color: '#275dad'}}>
//             {/* {Array.isArray(mayamd_notes) && mayamd_notes.length
//               ? ' | ' + 'Attachments'
//               : 'Attachments'} */}
//             Attachments
//           </Text>
//         </TouchableOpacity>
//       ) : null}
//     </View>
//   );
// };
const verifyDoc = ({item, title, value, model = 'Doctor', eventDispatcher}) => {
  const {documents = [], document_verified = ''} = item;
  // console.log(`>@>Puru ~ file: Doctors.js ~ line 32 ~ item`, item);
  // if (!documents.length) {
  //   return null;
  // }
  return (
    <TouchableOpacity
      style={{
        backgroundColor: '#57ccbf',
        backgroundColor: title == 'Verify' ? '#57ccbf' : '#f14f4f',
        borderRadius: 50,
        cursor: 'pointer',
      }}
      onPress={() => {
        if (document_verified === value) {
          return null;
        }
        post({
          data: item,
          updates: {
            document_verified: value || title,
          },
          model,
        }).then(({}) => {
          eventDispatcher.notify('reloadDoctor');
        });
      }}>
      <View
        style={{
          alignItems: 'center',
          padding: 5,
        }}>
        <Text
          style={{
            ...h16_Regular,
            color: 'white',
          }}>
          {title === 'Verify'
            ? document_verified === 'verified'
              ? 'Verified'
              : title
            : document_verified === 'rejected'
            ? 'Rejected'
            : title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
const documents = ({item, navigation}) => {
  const {documents = [], mayamd_notes = []} = item;
  // console.log('{}ITEM!!!!!!!!!!!!!!!!!!!!!!!!!!', item);
  return (
    <View style={{flexDirection: 'row'}}>
      {Array.isArray(documents) && documents.length ? (
        <TouchableOpacity
          style={{flexDirection: 'row', cursor: 'pointer'}}
          onPress={() => {
            navigation.push({
              view: 'pdfReader',
              params: {
                dataArr: documents,
              },
              modal: true,

              modalProps: {
                autoHide: true,
                minHeight: 200,
                height: 600,
                width: isMobile ? 300 : 650,
                position: 'screenCenter',
                // ...confirmModalProps,
              },
            });
          }}>
          <Text style={{...h11_Medium, color: '#275dad'}}>
            {/* {Array.isArray(mayamd_notes) && mayamd_notes.length
              ? ' | ' + 'Attachments'
              : 'Attachments'} */}
            Attachments
          </Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

let tableItems = {
  lg: {
    action: ({item}) => {
      return {
        type: 'link',
        link: {
          view: 'edit-doctors',
          params: {item},
        },
      };
    },
    columns: [
      {
        render: (props) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {props.index + 1}
            </Text>
          );
        },
        header: 'S.No.',
        width: 50,
      },
      {
        header: 'Name',
        type: 'text',
        width: 150,
        field: resolveDoctorName,
      },
      {
        header: 'Email',
        type: 'text',
        // width: 150,
        field: 'email',
      },
      // {
      //   header: 'Organisation Name',
      //   type: 'text',
      //   width: 150,
      //   field: 'organization.organization_name',
      //   visible: ({user}) => {
      //     let {userType} = user;
      //     return userType === 'ClinicAdmin' ? false : true;
      //   },
      // },
      {
        header: 'Gender',
        type: 'text',
        width: 100,
        field: 'gender',
      },
      {
        header: 'Mobile Number',
        type: 'text',
        width: 150,
        field: 'mobile',
      },
      {
        header: 'Qualification Description',
        type: 'text',
        visible: ({user}) => {
          let {userType} = user;
          return userType === 'ClinicAdmin' ? true : false;
        },
        // width: 250,
        field: 'qualification_description',
      },
      {
        visible: ({user}) => {
          let {userType} = user;
          return userType === 'ClinicAdmin' ? false : true;
        },
        header: 'Attachments',
        render: (item) => documents(item),
      },
      {
        visible: ({user}) => {
          let {userType} = user;
          return userType === 'ClinicAdmin' ? false : true;
        },
        render: ({item, eventDispatcher}) => {
          // console.log(item);
          return verifyDoc({
            item: item,
            title: 'Verify',
            value: 'verified',
            eventDispatcher,
          });
        },
        // width:150,
      },
      {
        visible: ({user}) => {
          let {userType} = user;
          return userType === 'ClinicAdmin' ? false : true;
        },
        render: ({item, eventDispatcher}) =>
          verifyDoc({
            item: item,
            title: 'Reject',
            value: 'rejected',
            eventDispatcher,
          }),
        // width:150,
      },
      // {
      //   width: 120,
      //   header: 'Waiting room',
      //   render: ({item, eventDispatcher}) => {
      //     return (
      //       <View style={{width: 15, height: 15}}>
      //         <CheckBox
      //           value={item?.waiting_room_available}
      //           onChangeValue={(value) => {
      //             post({
      //               data: {_id: item._id},
      //               updates: {waiting_room_available: value},
      //               model: 'Doctor',
      //             }).then((res) => {
      //               eventDispatcher.notify('reloadDoctor');
      //               // console.log('asdfg', res);
      //             });
      //           }}
      //         />
      //       </View>
      //     );
      //   },
      // },
      {
        visible: ({user}) => {
          let {userType} = user;
          return userType === 'ClinicAdmin' ? true : false;
        },
        render: ({item, navigation}) => {
          return (
            <TouchableOpacity
              style={{
                flex: 1,
                cursor: 'pointer',
                justifyContent: 'center',
              }}
              onPress={() => {
                navigation.push({
                  view: 'add-doctor-schedule',
                  params: {item},
                  modal: true,
                  modalProps: {
                    position: 'fullScreen',
                  },
                });
              }}>
              <GradientButton label={'Add/Edit Schedule'} />
            </TouchableOpacity>
          );
        },
      },
      {
        width: 50,
        visible: ({user}) => {
          let {userType} = user;
          return userType === 'ClinicAdmin' ? true : false;
        },
        render: ({item, navigation,eventDispatcher}) => {
          return (
            <TouchableOpacity
              style={{alignItems: 'center', justifyContent: 'center'}}
              onPress={() => {
                invoke({
                  id: 'deleteDoctor',
                  paramValue: {
                    doctor_id:item?._id
                  },
                })
                  .then((res) => {
                    eventDispatcher.notify('reloadDoctor');
                    console.log(`>@>Puru ~ file: Doctors.js ~ line 296 ~ res`, res)
                  })
                  .catch((err) => {
                    console.log(`>@>Puru ~ file: Doctors.js ~ line 296 ~ err`, err)
                    let message = JSON.parse(err.message);                    
                    if (message && message['error'] && message['error']?.message){
                      showMessage({message:message['error']?.message})
                    }
                    else{
                      showMessage({message:"Error in deleting the Doctor"})
                    }
                  });
              }}>
              <Image source={newDeleteIcon} style={{width: 20, height: 20}} />
            </TouchableOpacity>
          );
        },
      },
    ],
  },
  sm: {
    action: ({item}) => {
      return {
        type: 'link',
        link: {
          view: 'edit-doctors',
          params: {item},
        },
      };
    },
    columns: [
      {
        render: (props) => {
          return (
            <Text style={{...h14_AvenirNext, color: 'rgba(56, 56, 56, 0.87)'}}>
              {props.index + 1}
            </Text>
          );
        },
        header: 'S.No.',
        width: 50,
      },
      {
        header: 'Name',
        type: 'text',
        width: 100,
        field: resolveDoctorName,
      },
      {
        header: 'Email',
        type: 'text',
        width: 100,
        field: 'email',
      },
      {
        header: 'Mobile Number',
        type: 'text',
        field: 'mobile',
        // flex: 1,
        width: 100,
      },
      {
        visible: ({user}) => {
          let {userType} = user;
          return userType === 'ClinicAdmin' ? true : false;
        },
        render: ({item, navigation}) => {
          return (
            <TouchableOpacity
              style={{
                flex: 1,
                // backgroundImage: 'linear-gradient(110deg, #4de49f, #73c5a7)',
                // paddingTop: 12,
                // paddingBottom: 12,
                // paddingLeft: 24,
                // paddingRight: 24,
                cursor: 'pointer',
                // borderRadius: 4,
                // marginRight: 24,
                justifyContent: 'center',
                // alignItems: 'center',
              }}
              onPress={() => {
                navigation.push({
                  view: 'add-doctor-schedule',
                  params: {item},
                  modal: true,
                  modalProps: {
                    position: 'fullScreen',
                  },
                });
              }}>
              <GradientButton label={'Add/Edit Schedule'} />
              {/* <Text style={{color: 'white', ...h14_SansSemiBold}}>
              Add/Edit Schedule
              </Text> */}
            </TouchableOpacity>
          );
        },
      },
    ],
  },
};
export default Table({
  uri,
  ...tableItems,
  rowHoverContainerStyle: {cursor: 'default'},
  reloadEvent: 'reloadDoctor',
});
