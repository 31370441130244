import React from 'react';
import {View} from '../../app-components';
export default ({theme, initUser, landingView}) => {
  class AuthLoadingScreen extends React.Component {
    componentDidMount() {
      this.checkIfUserExist();
    }
    redirect = (res) => {
      // if(!res?.user){
      //   this.props.navigation.reset("front-page");
      // }
      let _landingView = landingView;
      if (typeof _landingView === 'function') {
        _landingView = _landingView({user: res.user});
      }
      this.props.navigation.reset(_landingView);
    };
    checkIfUserExist = async () => {
      const res = initUser();
      if (res && res.then) {
        res
          .then((user) => {
            this.redirect(user);
          })
          .catch((e) => {
            console.error('Error in check if user exists', {error: e, res});
          });
      } else {
        this.redirect(res);
      }
    };
    render() {
      const {containerStyle} = theme;
      return <View style={containerStyle} />;
    }
  }

  return AuthLoadingScreen;
};
