import React, {useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  isMobile,
} from '../../app-components';
import {Form} from '../../app-components/form/Form';
import MobileInput from '../../app-components/input-components/MobileInput';
import {invoke} from '../../AppServices';
import {TextInput} from '../../app-components/input-components/Editors';

const SignUp = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  let {navigation, setSelectedTab} = props;
  let {form_state: {data = {}} = {}, form_context: {setValue} = {}} = props;

  const submitInfo = async () => {
    const isMobileLengthError =
      data && data.mobile && data.mobile.length ? true : false;
    if (!isMobileLengthError) {
      setError('Mobile number is required');
    } else {
      // let given = [data?.first_name || ''];
      // let family = data?.last_name || '';
      // let Name = [{given, family}];

      // let mobile = {
      //   system: 'phone',
      //   value: data?.mobile || '',
      //   use: 'Personal',
      // };
      // let email = {system: 'email', value: data?.email || ''};

      // let telecom = [mobile, email];

      setLoading(true);
      await invoke({
        id: 'patientSignup',
        paramValue: {
          data: {...data, userType: 'Patient', isDependent: false},
        },
      })
        .then(() => {
          // setSelectedTab('signin');
          navigation.replace('maya-auth', {
            mobile: data?.mobile?.length ? data.mobile : data?.email,
            from: 'signup',
            type: 'Patient',
            // email: data?.email,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError('Mobile is already registered!');
          console.log('err', err);
        });
      setLoading(false);
    }
  };
  return (
    <View style={{flex: 1, paddingLeft: 15, paddingRight: 15, marginTop: 20}}>
      {/* first name last name */}
      <View
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          // marginTop: 10,
          flex: 1,
        }}>
        {/* First name */}

        <View
          style={{
            marginRight: isMobile ? 0 : 10,
            marginBottom: isMobile ? 10 : 0,
          }}>
          <TextInput
            moreInputStyle={{width: 160}}
            data={data}
            value={data.first_name}
            field={'first_name'}
            onChangeText={(value) =>
              setValue({field: 'first_name', value, data})
            }
            variant="filled"
            placeholder="First Name"
          />
        </View>
        {/*Last name*/}
        <View>
          <TextInput
            moreInputStyle={{width: 160}}
            data={data}
            value={data.last_name}
            field={'last_name'}
            onChangeText={(value) =>
              setValue({field: 'last_name', value, data})
            }
            variant="filled"
            placeholder="Last Name"
          />
        </View>
      </View>
      <View style={{flex: 1, marginTop: 20}}>
        <MobileInput
          value={data.mobile}
          setValue={setValue}
          fieldName="mobile"
          data={data}
          error={error}
        />
      </View>
      <View
        style={{
          marginTop: 20,
          flex: 1,
        }}>
        {/* First name */}

        <TextInput
          moreInputStyle={{width: 335}}
          data={data}
          value={data.email}
          field={'email'}
          onChangeText={(value) => setValue({field: 'email', value, data})}
          variant="filled"
          placeholder="Email Address"
        />
      </View>
      <TouchableOpacity
        onPress={() => {
          submitInfo();
        }}
        style={{
          backgroundColor: '#00A7DA',
          flex: 1,
          minHeight: 45,
          marginTop: 20,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 10,
          cursor: 'pointer',
        }}>
        {loading ? (
          <ActivityIndicator size={'small'} color={'white'} />
        ) : (
          <Text style={{color: '#fff'}}>Create New Account</Text>
        )}
      </TouchableOpacity>
    </View>
  );
};

const PatientSignUP = (props) => {
  const {navigation} = props;
  return (
    <Form
      // uri={uri}
      containerStyle={{flex: 1}}>
      {({form_context, form_state}) => {
        return (
          <SignUp
            {...props}
            navigation={navigation}
            form_context={form_context}
            form_state={form_state}
          />
        );
      }}
    </Form>
  );
};

export default PatientSignUP;
