import {isEmpty} from 'lodash';
import React, {useCallback} from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from '../../../../../app-components';
import {colors} from '../../../../../theme/colors';
import {h15_SemiBold, h16_Medium} from '../../../../../theme/fonts';

const MemberSymptomCheckerNote = (props) => {
  const {response = {}, fullName = ''} = props;
  const {
    input,
    output,
    negativeAssociatedSymptoms,
    negativePastMedicalHistory,
  } = response;

  const RenderCard = useCallback((cardProps) => {
    const {title = '', data = []} = cardProps || {};
    if (isEmpty(data)) {
      return null;
    }
    return (
      <View style={styles.cardContainer}>
        <TouchableOpacity style={styles.cardLabelContainer}>
          <Text style={styles.cardTitle}>{title}</Text>
        </TouchableOpacity>
        <RenderItem data={data} />
      </View>
    );
  }, []);

  return (
    <View style={{flex: 1}}>
      <ScrollView style={{maxHeight: 300}}>
        <RenderCard
          title="Patient Profile"
          data={{
            name: fullName,
            gender: input?.sex === 'unspecified' ? 'Other' : input?.sex,
            age: input?.age,
          }}
        />
        <RenderCard title="Chief Complaints" data={input?.symptoms} />

        <RenderCard
          title="Past medical history"
          data={input?.pastMedicalHistory}
        />

        <RenderCard
          title="No Past Medical History of:"
          data={negativePastMedicalHistory}
        />
        <RenderCard
          title={
            input?.sex === 'unspecified'
              ? 'He or She denies following'
              : (input?.sex).toLowerCase() === 'male'
              ? 'He denies following:'
              : 'She denies following:'
          }
          data={input?.negativeContexts}
        />
        <RenderCard
          title={
            input?.sex === 'unspecified'
              ? 'He or She has following'
              : (input?.sex).toLowerCase() === 'male'
              ? 'He has following:'
              : 'She has following:'
          }
          data={input?.contexts}
        />
        <RenderCard
          title="Review of systems - negative for"
          data={negativeAssociatedSymptoms}
        />
        <RenderCard
          title="Physical Exam Recommendations"
          data={output?.recommendation?.physicalExamination}
        />
        {/* <RenderCard title={getLanguageText(language, 'causes')}>
          <Diagonsis isNote />
        </RenderCard> */}
        <RenderCard
          title="Lab and Radiology Recommendations"
          data={output?.recommendation?.lab}
        />
        <RenderCard title="Care Options!" data={output?.triages} />
      </ScrollView>
    </View>
  );
};

const RenderItem = ({data = []}) => {
  return (
    <View>
      {Array.isArray(data) && !!data.length
        ? data?.map((item, index) => {
            return (
              <View style={styles.sectionItemContainer} key={index}>
                <View
                  style={{
                    flexDirection: 'row',
                  }}>
                  <Text style={styles.text}>•</Text>
                  <Text
                    style={{
                      ...styles.text,
                      width: '100%',
                    }}>
                    {item?.name || ''}
                  </Text>
                </View>
                {!!item?.duration && (
                  <Text style={styles.subText}>
                    {`   ${item?.duration?.name}` || ''}
                  </Text>
                )}
                {!isEmpty(item?.qualifiers) &&
                  item?.qualifiers.map((qualifierItem, qualifierindex) => {
                    return (
                      <Text
                        key={qualifierindex.toString()}
                        style={styles.subText}>
                        {`   ${qualifierItem?.name}` || ''}
                      </Text>
                    );
                  })}
              </View>
            );
          })
        : null}
      {!isEmpty(data) &&
        !Array.isArray(data) &&
        Object.entries(data).map(([key, value], index) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: 10,
                paddingTop: 10,
              }}
              key={index.toString()}>
              <Text style={{...styles.text, flex: 1}}>{key}</Text>
              <Text style={{...styles.text, flex: 1}}>{value || ''}</Text>
            </View>
          );
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  sectionItemContainer: {padding: 8},
  text: {
    ...h16_Medium,
    marginLeft: 10,
    color: colors.blackColor,
    textTransform: 'capitalize',
    opacity: 0.8,
  },
  subText: {
    ...h16_Medium,
    color: colors.blackColor,
    textTransform: 'capitalize',
    opacity: 0.8,
  },
  cardContainer: {
    flexDirection: 'column',
    borderRadius: 10,
    margin: 15,
    backgroundColor: colors.sectionBodyBgColor,
    shadowColor: colors.grayColor,
  },
  cardLabelContainer: {
    flex: 1,
    backgroundColor: '#CFEBFC',
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  cardTitle: {
    padding: 14,
    ...h15_SemiBold,
    color: colors.sectionTitleTextColor,
    width: '90%',
  },
});

export default MemberSymptomCheckerNote;
