/**
 *
 * Save button in bottom
 *
 */
import React from 'react';
import ReactFormContext from './ReactFormContext';
import ReactFormStateContext from './ReactFormStateContext';
import {Keyboard} from '../../react-core-components';
import uuid from 'uuid/v4';
import {putDottedValue} from '../../react-utility-functions';
import {loadData} from '../../data-update-v1';
import {compute} from './Computations';
import {validate} from './Validations';

const getNewId = () => {
  return `new_${uuid()}`;
};

const findById = (data, field, value) => {
  if (!data || !data.length) {
    return void 0;
  }
  let valueToFind = value[field];
  return data.find((doc) => {
    let docValue = doc[field];
    if (docValue === valueToFind) {
      return true;
    }
  });
};

const populateNestedDataAndUpdates = ({data, updates = {}}, props) => {
  const {field, value, path, insert, remove, bottom, values} = props;
  let {_id: nestedId, field: nestedField} = path[0];

  let nestedData = data[nestedField] || [];
  let nestedUpdates = updates[nestedField] || {};

  if (path.length > 1) {
    nestedData = [...nestedData];
    let nestedDoc = findById(nestedData, '_id', {
      _id: nestedId,
    });
    if (nestedDoc) {
      let nestedDocUpdates = findById(nestedUpdates.insert, '_id', {
        _id: nestedId,
      });
      if (!nestedDocUpdates) {
        nestedDocUpdates = findById(nestedUpdates.update, '_id', {
          _id: nestedId,
        });
        if (nestedDocUpdates) {
          nestedDocUpdates.changes = nestedDocUpdates.changes || {};
          nestedDocUpdates = nestedDocUpdates.changes;
        } else {
          nestedDocUpdates = {};
          nestedUpdates['update'] = nestedUpdates['update'] || [];
          nestedUpdates['update'].push({
            _id: nestedId,
            changes: nestedDocUpdates,
          });
        }
      }
      let {
        nestedData: innerNestedData,
        nestedUpdates: innerNestedUpdates,
      } = populateNestedDataAndUpdates(
        {
          data: nestedDoc,
          updates: nestedDocUpdates,
        },
        {
          ...props,
          path: path.slice(1, path.length),
        },
      );
      Object.assign(nestedDoc, {...innerNestedData});
      Object.assign(nestedDocUpdates, {...innerNestedUpdates});
    }
  } else {
    let isOverride = Array.isArray(nestedUpdates);
    if (insert) {
      //handle insert case
      nestedId = nestedId || getNewId();
      let nestedDoc = {_id: nestedId, ...values};
      nestedData = bottom
        ? [...nestedData, nestedDoc]
        : [nestedDoc, ...nestedData];
      if (isOverride) {
        nestedUpdates = [...nestedData];
      } else {
        nestedUpdates['insert'] = nestedUpdates['insert'] || [];
        let nestedUpdatesDoc = findById(nestedUpdates['insert'], '_id', {
          _id: nestedId,
        });
        if (!nestedUpdatesDoc) {
          nestedUpdates['insert'].push({...nestedDoc});
        }
      }
    } else if (remove) {
      //handle remove case
      nestedData = nestedData.filter((doc) => doc._id !== nestedId);

      if (isOverride) {
        nestedUpdates = [...nestedData];
      } else {
        //check if exists in insert
        let nestedDoc = findById(nestedUpdates['insert'], '_id', {
          _id: nestedId,
        });
        if (nestedDoc) {
          //means it is just added and now removed, so remove insert op as well
          nestedUpdates['insert'] = nestedUpdates['insert'].filter(
            (nestedDoc) => nestedDoc._id !== nestedId,
          );
        } else {
          nestedUpdates['remove'] = nestedUpdates['remove'] || [];
          nestedDoc = findById(nestedUpdates['remove'], '_id', {
            _id: nestedId,
          });
          if (!nestedDoc) {
            nestedUpdates['remove'].push({_id: nestedId});
          }
        }
      }
    } else {
      let nestedFieldUpdates = {};
      if (field) {
        nestedFieldUpdates[field] = value;
      } else if (values) {
        nestedFieldUpdates = values;
      }

      nestedData = nestedData.map((doc) =>
        doc._id === nestedId ? {...doc, ...nestedFieldUpdates} : doc,
      );
      if (isOverride) {
        nestedUpdates = [...nestedData];
      } else {
        //check first in insert then in updates
        //check in insert
        let nestedUpdatesDoc = findById(nestedUpdates['insert'], '_id', {
          _id: nestedId,
        });

        if (nestedUpdatesDoc) {
          Object.assign(nestedUpdatesDoc, {...nestedFieldUpdates});
        } else {
          nestedUpdatesDoc = findById(nestedUpdates['update'], '_id', {
            _id: nestedId,
          });
          if (nestedUpdatesDoc) {
            //it is in updates
            Object.assign(nestedUpdatesDoc.changes, {...nestedFieldUpdates});
          } else {
            nestedUpdates['update'] = nestedUpdates['update'] || [];
            nestedUpdates['update'].push({
              _id: nestedId,
              changes: {...nestedFieldUpdates},
            });
          }
        }
      }
    }
  }
  return {
    nestedData: {
      [nestedField]: nestedData,
    },
    nestedUpdates: {
      [nestedField]: nestedUpdates,
    },
  };
};

const populateComputationUpdates = ({data}, props) => {
  let {field, value, values, path, insert, remove} = props;
  let updates = values
    ? {
        set: {
          ...values,
        },
      }
    : field
    ? {
        set: {
          [field]: value,
        },
      }
    : {};
  if (path && path.length) {
    let nestedUpdates = void 0;
    if (insert) {
      nestedUpdates = {insert, set: {_id: path[path.length - 1]._id}};
    } else if (remove) {
      nestedUpdates = {remove, set: {}};
    } else if (updates) {
      nestedUpdates = updates;
    }
    if (nestedUpdates) {
      return {
        _id: data._id,
        path,
        updates: nestedUpdates,
      };
    }
  } else if (updates) {
    return {
      _id: data._id,
      updates,
    };
  }
};

const computeUpdatesRecursively = async (
  {computations, computeProps, computationUpdates, data, updates},
  props,
) => {
  let computedUpdates = await compute({
    computations,
    computeProps,
    data,
    updates: computationUpdates,
  });
  if (computedUpdates && computedUpdates.length) {
    for (let computedUpdate of computedUpdates) {
      let {_id, path, updates: updatesToSet} = computedUpdate;
      let {set} = updatesToSet;
      if (data._id === _id) {
        if (set) {
          set = {...set};
          if (path && path.length) {
            let {nestedData, nestedUpdates} = populateNestedDataAndUpdates(
              {data, updates},
              {
                path,
                values: set,
              },
            );
            Object.assign(data, nestedData);
            Object.assign(updates, nestedUpdates);
          } else {
            Object.assign(data, set);
            Object.assign(updates, set);
          }
        }
      }
    }
    await computeUpdatesRecursively(
      {
        computations,
        computeProps,
        computationUpdates: computedUpdates,
        data,
        updates,
      },
      props,
    );
    return {data, updates};
  }
};

const resolveComputations = async (
  {computations, computeProps, data, updates},
  props = {},
) => {
  if (!computations || !data || !data._id) {
    return;
  }
  let computationUpdates = populateComputationUpdates({data}, props);
  if (!computationUpdates) {
    return;
  }
  data = {...data};
  updates = {...updates};

  let computedUpdates = await computeUpdatesRecursively(
    {
      computations,
      computeProps,
      computationUpdates,
      data,
      updates,
    },
    props,
  );
  return computedUpdates;
};

class ReactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    if (!props.useState || !props.state) {
      this.state = {uid: uuid(), data: {}};
    }
    let {user, getUser, navigation, eventDispatcher} = props;
    this.formContext = {
      user,
      getUser,
      navigation,
      eventDispatcher,
      setValue: this.setValue,
      setFocus: this.setFocus,
      setState: this._setState,
      handleSubmit: this.handleSubmit,
    };
  }

  getFormUpdates = () => {
    let state = this._getState() || {};
    return {
      uid: state.uid,
      data: state.data,
      updates: state.updates,
    };
  };

  addFormUpdateListener = () => {
    let {eventDispatcher, formUpdateEvent} = this.props;
    if (!formUpdateEvent || this.formUpdateEvent) {
      return;
    }
    if (formUpdateEvent === true) {
      formUpdateEvent = 'formUpdates';
    }
    eventDispatcher &&
      eventDispatcher.listen(formUpdateEvent, this.getFormUpdates);
    this.formUpdateEvent = formUpdateEvent;
  };

  componentDidMount() {
    this.loadData(this._getState());
    const uid = this.getUid();
    let {eventDispatcher, navigation, reloadOnChangeEvent} = this.props;
    reloadOnChangeEvent &&
      eventDispatcher &&
      eventDispatcher.listen(reloadOnChangeEvent, this.reloadData);
    uid &&
      eventDispatcher &&
      eventDispatcher.listen(`${uid}-submit`, this.handleSubmit);
    if (navigation && navigation.state) {
      this.oldParams = navigation.state.params;
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
    const uid = this.getUid();
    let {eventDispatcher, reloadOnChangeEvent} = this.props;
    reloadOnChangeEvent &&
      eventDispatcher &&
      eventDispatcher.unlisten(reloadOnChangeEvent, this.reloadData);
    uid &&
      eventDispatcher &&
      eventDispatcher.unlisten(`${uid}-submit`, this.handleSubmit);
    this.formUpdateEvent &&
      eventDispatcher &&
      eventDispatcher.unlisten(this.formUpdateEvent, this.getFormUpdates);
  }

  componentDidUpdate() {
    let {navigation, reloadOnNavigationChange} = this.props;
    if (navigation && navigation.state) {
      let {params} = navigation.state;
      if (
        reloadOnNavigationChange &&
        this.oldParams &&
        this.oldParams !== params
      ) {
        //load Data again
        let newState = {
          data: {},
          updates: void 0,
          mandatoryErrors: void 0,
          validationErrors: void 0,
          submitTried: false,
        };
        this.loadData(newState);
        this.oldParams = params;
      }
    }
  }

  getUid = () => {
    let state = this._getState();
    return state && state.uid;
  };

  _getState = () => {
    if (this.props.useState && this.props.state) {
      return this.props.state;
    } else {
      return this.state;
    }
  };

  _setState = (_state) => {
    if (this._unmounted) {
      return;
    }
    if (this.props.useState && this.props.setState) {
      this.props.setState(_state);
    } else {
      this.setState(_state);
    }
  };

  reloadData = () => {
    this.loadData();
  };

  loadData = async (state = {}) => {
    let {
      fetch,
      beforeFetch,
      afterFetch,
      getUser,
      computations,
      computeProps,
      validateOnMount,
      dataMode,
      uri,
      data,
    } = this.props;
    let afterState;
    if (dataMode !== 'insert' && uri) {
      if (typeof uri === 'function') {
        uri = uri({
          user: getUser && getUser(),
          navigation: this.props.navigation,
        });
      }
      let resp = loadData({
        uri,
        fetch,
        beforeFetch,
        afterFetch,
        state,
      });
      let {beforeState, afterState: _afterState} = resp;
      state = {...state, ...beforeState};
      afterState = _afterState;
    } else {
      state = {...state, data};
      let defaultState = this._defaultValues();
      if (defaultState) {
        state.data = {...state.data, ...defaultState.data};
        state.updates = defaultState.updates;
      }
      if (!state.data._id) {
        state.data._id = getNewId();
      }
      let computationResult = await resolveComputations(
        {
          computations,
          computeProps,
          data: state.data,
        },
        {
          values: state.data,
        },
      );
      if (computationResult) {
        let {data, updates} = computationResult;
        state.data = {...state.data, ...data};
        state.updates = {
          ...state.updates,
          ...updates,
        };
        if (validateOnMount) {
          state = this.validateState({state});
        }
      }
    }
    this._setState(state);
    if (afterState) {
      afterState().then((_afterState) => {
        this._setState(_afterState);
      });
    }
  };

  _defaultValues = () => {
    const {navigation, defaultValues, nestedFields} = this.props;
    let defaultValuesData = defaultValues;
    let data, updates;
    if (
      navigation &&
      navigation.state &&
      navigation.state.params &&
      navigation.state.params.nextData
    ) {
      data = navigation.state.params.nextData;
      updates = navigation.state.params.nextUpdates;
    }
    if (defaultValuesData) {
      if (typeof defaultValuesData === 'function') {
        defaultValuesData = defaultValuesData({navigation});
      }
      if (defaultValuesData) {
        data = {...data};
        updates = {...updates};

        for (let defaultField in defaultValuesData) {
          if (nestedFields && nestedFields[defaultField]) {
            let nestedFieldValue = defaultValuesData[defaultField];
            if (nestedFieldValue) {
              nestedFieldValue = nestedFieldValue.map((nestedValue) => {
                if (!nestedValue._id) {
                  nestedValue = {...nestedValue, _id: getNewId()};
                }
                return nestedValue;
              });

              data[defaultField] = nestedFieldValue;
              updates[defaultField] = {insert: nestedFieldValue};
            }
          } else {
            data[defaultField] = defaultValuesData[defaultField];
            updates[defaultField] = defaultValuesData[defaultField];
          }
        }
      }
    }

    let stateToReturn = {};
    if (data) {
      stateToReturn.data = data;
    }
    if (updates) {
      stateToReturn.updates = updates;
    }
    return stateToReturn;
  };

  handleSubmit = async (params) => {
    try {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      Keyboard.dismiss();
      let {
        mandatoryErrors,
        hasMandatoryError,
        validationErrors,
        hasValidationError,
      } = this.validateState({state: this._getState()});
      if (hasMandatoryError || hasValidationError) {
        this.submitting = false;
        this._setState({
          mandatoryErrors,
          validationErrors,
          submitTried: true,
        });
        return;
      }

      let {
        navigation,
        eventDispatcher,
        dataMode,
        onSubmit,
        passDataToNext,
        closeView,
        reloadEvent,
        submitNext,
        next,
        onSubmitSuccess,
        onSubmitError,
        beforeSubmit,
      } = this.props;
      let {data, updates} = this._getState();
      if (next) {
        let nextParams = void 0;
        if (passDataToNext) {
          if (typeof passDataToNext === 'function') {
            nextParams = passDataToNext(this._getState(), {navigation});
          } else {
            nextParams = {
              nextData: {...data},
              nextUpdates: {...updates},
            };
          }
        }
        this.submitting = false;
        this.props.navigation.navigate(next, nextParams);
      } else {
        if (beforeSubmit) {
          let modifiedValues = await beforeSubmit({
            ...params,
            data,
            updates,
          });
          if (modifiedValues) {
            if (modifiedValues.data) {
              data = modifiedValues.data;
            }
            if (modifiedValues.updates) {
              updates = modifiedValues.updates;
            }
          }
        }
        if (!updates || !Object.keys(updates).length) {
          this.submitting = false;
          let noChangesError = new Error('No Changes Found');
          noChangesError.code = 'no_changes_found';
          onSubmitError && onSubmitError(noChangesError);
          return;
        }
        try {
          this._setState({submitTried: true, submitting: true});
          let result = await onSubmit({
            navigation,
            eventDispatcher,
            dataMode,
            data,
            updates,
          });
          this.submitting = false;
          onSubmitSuccess && onSubmitSuccess();
          if (reloadEvent && eventDispatcher) {
            if (Array.isArray(reloadEvent)) {
              reloadEvent.forEach((_event) => eventDispatcher.notify(_event));
            } else {
              eventDispatcher.notify(reloadEvent);
            }
          }
          submitNext &&
            submitNext({
              navigation,
              eventDispatcher,
              submitResult: result,
              data,
            });
          if (typeof closeView === 'function') {
            closeView = closeView({navigation, eventDispatcher});
          }
          if (closeView) {
            navigation && navigation.pop(closeView);
            return;
          }
          this._setState({submitting: false, submitTried: false});
        } catch (err) {
          this.submitting = false;
          onSubmitError && onSubmitError(err);
          this._setState({submitting: false});
        }
      }
    } catch (err) {
      this.submitting = false;
      this.props.onSubmitError && this.props.onSubmitError(err);
    }
  };

  setFocus = ({field, focus}) => {
    this._setState((state) => {
      let {focusField, focussedFields} = state;
      let revisedState = {};
      if (focus) {
        revisedState.focusField = field;
      } else if (focusField === field) {
        revisedState.focusField = void 0;
      }
      if (!focus) {
        focussedFields = {...focussedFields, [field]: 1};
        revisedState.focussedFields = focussedFields;
      }
      if (!focus && this.props.validateOnBlur) {
        revisedState = this.validateState({
          state,
          newState: revisedState,
          field,
        });
      }
      return revisedState;
    });
  };

  handleAutoSave = async ({data, field, value}) => {
    let {onSubmit} = this.props;
    if (!onSubmit) {
      return;
    }
    try {
      let result = await onSubmit({
        data,
        updates: {[field]: value},
      });
      return result;
    } catch (err) {
      console.error('Error in DateUpdate autosave in data update', err);
      //ToDo handle error
    }
  };

  setValue = (setValueProps) => {
    let state = this._getState();
    let {data, updates = {}} = state;
    if (this.props.setValue) {
      //case of nested table field updates
      this.props.setValue({data, ...setValueProps});
      return;
    }
    let {
      computations,
      validateOnChange,
      saveOnChange,
      validateAllOnChange,
    } = this.props;

    const {field, value, path} = setValueProps;
    if (data && data._id && !path && saveOnChange) {
      this.handleAutoSave({data, field, value});
    }
    if (path && path.length) {
      let {nestedData, nestedUpdates} = populateNestedDataAndUpdates(
        {data, updates},
        setValueProps,
      );
      data = {...data, ...nestedData};
      updates = {...updates, ...nestedUpdates};
    } else {
      data = {...data};
      updates = {...updates};
      putDottedValue(data, field, value);
      putDottedValue(updates, field, value);
    }

    if (computations && (computations.self || computations.children)) {
      resolveComputations(
        {
          computations,
          computeProps: this.props.computeProps,
          data,
          updates,
        },
        setValueProps,
      ).then((computationResult) => {
        if (computationResult) {
          let {data, updates} = computationResult;
          let state = this._getState();
          let revisedState = {data, updates};
          if (validateAllOnChange) {
            revisedState = this.validateState({
              state,
              newState: revisedState,
            });
          }
          this._setState(revisedState);
        }
      });
    }
    let revisedState = {data, updates};
    if ((validateOnChange && field) || validateAllOnChange) {
      revisedState = this.validateState({
        state,
        newState: revisedState,
        field: validateAllOnChange ? void 0 : field,
        path: validateAllOnChange ? void 0 : path,
      });
    }
    this._setState(revisedState);
    this.addFormUpdateListener();
  };

  validateState = ({state, newState, field, path}) => {
    let {
      mandatory,
      validations,
      mandatoryMessage,
    } = this.props;

    return validate(
      {...state, ...newState},
      {
        field,
        path, 
        mandatory,
        validations,
        mandatoryMessage,
      },
    );
  };

  render() {
    let state = this._getState();
    let {children} = this.props;
    return (
      <ReactFormContext.Provider value={this.formContext}>
        <ReactFormStateContext.Provider value={state}>
          {typeof children === 'function'
            ? children({
                form_context: this.formContext,
                form_state: state,
              })
            : children}
        </ReactFormStateContext.Provider>
      </ReactFormContext.Provider>
    );
  }
}
export default ReactForm;
