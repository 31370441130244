import {colors, bgs} from './colors';
import {h14_Regular, h12_Regular, h14_Medium} from './fonts';
import {arrowDownIcon, backCrossIcon, crossIcon, downArrow} from '../images';
import {dropDownShadow} from './shadows';
import {Platform, StyleSheet} from '../app-components';

const {lightGrey, whiteBg, darkGrey20} = bgs;
const {textColor87, textColor20, textColor70, textColor34, textWhite} = colors;

const AutosuggestFilterThemeMD = {
  autosuggestInputStyle: {
    height: 226,
    width: 234,
    position: 'absolute',
  },
  dropdownStyle: {height: 226, width: 234},
  withModalContainerStyle: {
    borderColor: textColor20,
    // borderWidth: 1,
    borderRadius: 4,
    marginRight: 15,
    padding: 10,
    backgroundColor: textWhite,
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageTextStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
  },
  imageStyle: {width: 12, height: 6, paddingLeft: 2},
  crossImageStyle: {width: 14, height: 14, paddingLeft: 2},
  valueTextStyle: {
    ...h14_Regular,
    color: textColor87,
    letterSpacing: 0.5,
    paddingRight: 10,
    textOverflow: 'clip',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  icon: arrowDownIcon,
  crossIcon: backCrossIcon,
  container: {
    ...dropDownShadow,
    backgroundColor: textWhite,
    borderRadius: 8,
    height: 100,
    paddingTop: Platform.OS == 'ios' ? 10 : 0,
    paddingBottom: Platform.OS == 'ios' ? 10 : 0,
  },
  button: {flexDirection: 'row'},
  buttonContainer: {
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonTextView: {
    flex: 1,
    flexDirection: 'row',
    // paddingTop: Platform.OS == 'ios' ? 0 : 11,
    // paddingBottom: Platform.OS == 'ios' ? 0 : 11,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    borderTopWidth: 0,
    borderLeftWidth: 0.3,
    borderRightWidth: 0.5,
    borderBottomWidth: 1,
    borderColor: '#e7eef5',
    borderRadius: 8,
    paddingTop: 6,
    paddingBottom: 6,
  },
  buttonText: {
    flex: 1,
    ...h14_Regular,
    color: textColor87,
  },
  buttonTextInput: {
    flex: 1,
    ...h14_Medium,
    color: textColor34,
    borderWidth: 0,
    padding: 5,
    outline: 'none',
    width: 100,
  },

  iconViewStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 16,
    width: 16,
  },
  iconStyle: {
    flex: 1,
    resizeMode: 'contain',
  },
  loading: {
    alignSelf: 'center',
  },
  list: {
    backgroundColor: whiteBg,
    paddingLeft: 6,
    height: '75%',
  },
  rowText: {
    textAlignVertical: 'center',
    ...h14_Medium,
    padding: 7,
    marginBottom: 2,
    backgroundColor: whiteBg,
    flex: 1,
    color: textColor70,
  },
  highlightedRowText: {
    textAlignVertical: 'center',
    ...h14_Medium,
    padding: 7,
    marginBottom: 2,
    backgroundColor: whiteBg,
    flex: 1,
    color: textColor70,
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: 'lightgray',
  },

  noDataView: {},
  renderSeparator: () => null,
};
const AutosuggestFilterCurvedThemeMD = {
  autosuggestInputStyle: {
    height: 226,
    width: 234,
    position: 'absolute',
  },
  dropdownStyle: {height: 226, width: 234},
  withModalContainerStyle: {
    borderColor: textColor20,
    // borderWidth: 1,
    borderRadius: 20,
    marginRight: 15,
    padding: 7,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: textWhite,
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageTextStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
  },
  imageStyle: {width: 12, height: 6, paddingLeft: 2},
  crossImageStyle: {width: 14, height: 14, paddingLeft: 2},
  valueTextStyle: {
    ...h14_Regular,
    color: textColor87,
    letterSpacing: 0.5,
    paddingRight: 10,
    textOverflow: 'clip',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  icon: arrowDownIcon,
  crossIcon: backCrossIcon,
  container: {
    ...dropDownShadow,
    backgroundColor: textWhite,
    borderRadius: 8,
    height: 100,
    paddingTop: Platform.OS == 'ios' ? 10 : 0,
    paddingBottom: Platform.OS == 'ios' ? 10 : 0,
  },
  button: {flexDirection: 'row'},
  buttonContainer: {
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonTextView: {
    flex: 1,
    flexDirection: 'row',
    // paddingTop: Platform.OS == 'ios' ? 0 : 11,
    // paddingBottom: Platform.OS == 'ios' ? 0 : 11,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    borderTopWidth: 0,
    borderLeftWidth: 0.3,
    borderRightWidth: 0.5,
    borderBottomWidth: 1,
    borderColor: '#e7eef5',
    borderRadius: 8,
    paddingTop: 6,
    paddingBottom: 6,
  },
  buttonText: {
    flex: 1,
    ...h14_Regular,
    color: textColor87,
  },
  buttonTextInput: {
    flex: 1,
    ...h14_Medium,
    color: textColor34,
    borderWidth: 0,
    padding: 5,
    outline: 'none',
    width: 100,
  },

  iconViewStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 16,
    width: 16,
  },
  iconStyle: {
    flex: 1,
    resizeMode: 'contain',
  },
  loading: {
    alignSelf: 'center',
  },
  list: {
    backgroundColor: whiteBg,
    paddingLeft: 6,
    height: '75%',
  },
  rowText: {
    textAlignVertical: 'center',
    ...h14_Medium,
    padding: 7,
    marginBottom: 2,
    backgroundColor: whiteBg,
    flex: 1,
    color: textColor70,
  },
  highlightedRowText: {
    textAlignVertical: 'center',
    ...h14_Medium,
    padding: 7,
    marginBottom: 2,
    backgroundColor: whiteBg,
    flex: 1,
    color: textColor70,
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: 'lightgray',
  },

  noDataView: {},
  renderSeparator: () => null,
};
const commonStyleSM = {
  autosuggestInputStyle: {
    height: 260,
    width: 200,
    position: 'absolute',
  },
  dropdownStyle: {height: 260, width: 200},
  imageTextStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
  },
  imageStyle: {width: 12, height: 6, paddingLeft: 2},
  crossIcon: crossIcon,
  icon: arrowDownIcon,
  container: {
    ...dropDownShadow,
    backgroundColor: textWhite,
    paddingTop: Platform.OS == 'ios' ? 10 : 0,
    paddingBottom: Platform.OS == 'ios' ? 10 : 0,
  },
  button: {flexDirection: 'row'},
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonTextView: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: Platform.OS == 'ios' ? 0 : 11,
    paddingBottom: Platform.OS == 'ios' ? 0 : 11,
  },
  buttonText: {
    flex: 1,
    ...h14_Regular,
    color: textColor87,
  },
  buttonTextInput: {
    flex: 1,
    ...h14_Regular,
    color: textColor87,
    borderWidth: '0 0 1px', //todo
    borderColor: darkGrey20,
    marginLeft: 5,
    marginRight: 5,
    padding: 5,
    outline: 'none',
  },

  iconViewStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    width: 20,
  },
  iconStyle: {
    flex: 1,
    resizeMode: 'contain',
  },
  loading: {
    alignSelf: 'center',
  },
  list: {
    backgroundColor: whiteBg,
    height: 210,
    // overflow: 'auto',
  },
  rowText: {
    paddingHorizontal: 6,
    paddingVertical: 10,
    textAlignVertical: 'center',
    ...h12_Regular,
    padding: 5,
    marginBottom: 2,
    backgroundColor: whiteBg,
    color: textColor87,
  },
  highlightedRowText: {
    ...h12_Regular,
    color: textColor87,
    backgroundColor: lightGrey,
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: 'lightgray',
  },

  noDataView: {},
  renderSeparator: () => null,
};
const AutosuggestFilterThemeSM = {
  withModalContainerStyle: {
    borderColor: textColor20,
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 10,
    padding: 10,
    backgroundColor: textWhite,
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueTextStyle: {
    ...h12_Regular,
    textOverflow: 'clip',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  ...commonStyleSM,
};

const AutosuggestFilterThemeLandScape = {
  withModalContainerStyle: {
    borderColor: textColor20,
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 10,
    padding: 10,
    backgroundColor: textWhite,
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueTextStyle: {
    ...h12_Regular,
    width: 90,
    textOverflow: 'clip',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  ...commonStyleSM,
};
export {
  AutosuggestFilterThemeSM,
  AutosuggestFilterThemeLandScape,
  AutosuggestFilterThemeMD,
  AutosuggestFilterCurvedThemeMD,
};
