import React from 'react';
import {View, isMobile} from './app-components';
import NavigationBar from './NavigationBar';
import {ScreenContextProvider} from './app-components/screen/ScreenContextProvider';
import {getUser, getChannelName} from './AppServices';
import firebase, {messaging} from './FireBaseConfiguration';
import _ from 'lodash';
import {AppointmentContextProviderHoc} from './AppointmentContext';
import {EmergencyContextProviderHoc} from './EmergencyContext';
import {InstantAppointmentContextProviderHoc} from './InstantAppointmentContext';
import {NotificationContextProviderHoc} from './AppNotification';
import 'firebase/messaging';
import Layout from './Layout';
import Footer from './Footer';
import Advertisement from './Advertisement';
export const ContainerContext = React.createContext({});

export default class AppContainer extends React.Component {
  componentDidMount() {
    if (firebase.messaging.isSupported()) {
      Notification.requestPermission(() => {
        if (Notification.permission === 'granted') {
          messaging.usePublicVapidKey(
            'BF9p7mhy5v5N4jLvLSfDpDup2s9ESQL0zkGdHzDR7tolzofRqY9A1W7Tf6FUib6R6K4k0I3K4VRmbJyzoya52rM',
          );
          messaging.onMessage((payload) => {
            this.onMessage(payload);
            console.log('Message received. ', payload);
          });
        }
      });
    }
  }
  onMessage = (payload) => {
    const userData = getUser() || {};
    const appointmentType = _.get(payload, 'data.appointment_type', null);
    if (appointmentType) {
      this.showServerNotification(payload, userData);
    } else {
      const messageFrom = _.get(payload, 'data.userId', '');
      if (userData?._id !== messageFrom) {
        this.showNotification(payload);
      }
    }
  };

  showServerNotification = ({data, notification}, userData) => {
    const {navigation = {}} = this.props || {};
    const routes = navigation.getActiveRoutes() || [];

    let title = notification.title;
    this.notification = new Notification(title, {
      body: notification.body,
      icon: notification.icon,
    });
    if (!routes.includes('videoSession')) {
      this.notification.onclick = (event) => {
        event.preventDefault();
        this.notification.close();
        if (userData?.userType === 'Patient') {
          navigation.reset('patient-waiting-room');
        } else if (userData?.userType === 'Doctor') {
          navigation.reset('doctor-patient-queue');
        }
        this.notification.addEventListener('close', this.onNotificationClose);
      };
    } else {
      this.notification.close();
    }
  };

  showNotification = ({data, notification}) => {
    const {navigation = {}} = this.props || {};
    const routes = navigation.getActiveRoutes() || [];
    const {userType = null} = getUser() || {};
    let notificationToBeShow = true;
    if (getChannelName() === data?.channel_id) {
      notificationToBeShow = false;
    }

    if (!notificationToBeShow) {
      return;
    }
    if (this.notification) {
      this.notification.removeEventListener('close', this.onNotificationClose);
      this.notification.close();
    }
    let title = notification.title;
    this.notification = new Notification(title, {
      body: notification.body,
      icon: notification.icon,
    });
    if (!routes.includes('videoSession') && userType) {
      this.notification.onclick = (event) => {
        event.preventDefault();
        this.notification.close();
        if (data.uri === 'ModalView' && data?.role === 'Patient') {
          navigation.push({
            view: 'ModalView',
            modal: true,
            params: {
              value: {
                appointment: {_id: data?.appointment_id},
                tabNumber: 2,
              },
            },
            modalProps: {
              position: 'screenLeft',
              style: {
                width: 500,
              },
            },
          });
        } else if (data.uri === 'chatHistory' && data?.role === 'Doctor') {
          navigation.push({
            view: 'chatHistory',
            modal: true,
            params: {
              item: {
                _id: data?.appointment_id,
              },
            },
            modalProps: {
              position: 'screenLeft',
              style: {
                width: 500,
              },
            },
          });
        } else if (data.uri === 'chatHistory' && data?.role === 'Patient') {
          navigation.push({
            view: 'chatHistory',
            modal: true,
            params: {
              item: {
                _id: data?.patient_id,
                notificationClick: true,
                isList: true,
                medicalAssistant: true,
              },
            },
            modalProps: {
              position: 'screenLeft',
              style: {
                width: 500,
              },
            },
          });
        }
      };
    }

    this.notification.addEventListener('close', this.onNotificationClose);
  };

  render() {
    let {children, ...restProps} = this.props;
    let user = getUser && getUser();
    let {userType} = user || {};
    let wrappedComponent;
    if (userType === 'Patient') {
      wrappedComponent = <Layout {...this.props} />;
    } else {
      wrappedComponent = (
        <>
          <NavigationBar {...restProps} user={user} />
          <View
            style={{
              flex: 1,
              overflow: 'hidden',
              ...(isMobile ? {} : {flexDirection: 'row'}),
            }}>
            {children}
            {userType === 'Doctor' ? <Advertisement {...this.props} /> : null}
          </View>
          <Footer {...this.props} />
        </>
      );
    }

    wrappedComponent = (
      <ScreenContextProvider>{wrappedComponent}</ScreenContextProvider>
    );
    if (user && userType === 'Doctor') {
      wrappedComponent = (
        <InstantAppointmentContextProviderHoc>
          <AppointmentContextProviderHoc>
            {wrappedComponent}
          </AppointmentContextProviderHoc>
        </InstantAppointmentContextProviderHoc>
      );
    }
    if (user && userType === 'ClinicAdmin') {
      wrappedComponent = (
        <EmergencyContextProviderHoc>
          {wrappedComponent}
        </EmergencyContextProviderHoc>
      );
    }
    if (user) {
      wrappedComponent = (
        <NotificationContextProviderHoc
          navigation={restProps.navigation}
          eventDispatcher={restProps.eventDispatcher}>
          {wrappedComponent}
        </NotificationContextProviderHoc>
      );
    }
    return wrappedComponent;
  }
}
