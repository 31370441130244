import React, {useState} from 'react';
import {View, TouchableOpacity, Text, Image} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {invoke} from '../../AppServices';
import {backIcon} from '../../images';
import {TextInput} from '../../npms/react-text-input';
import {h14_Medium, h22_SemiBold} from '../../theme/fonts';
const ChangePassword = (props) => {
  const {navigation} = props;
  const [password, setPassword] = useState({
    new_password: '',
    old_password: '',
    confirm_password: '',
  });
  const [error, setError] = useState({});
  const changePassword = async () => {
    if (!password?.old_password.length) {
      setError({type: 'old', value: 'Please enter value'});
    } else if (!password?.new_password.length) {
      setError({type: 'new', value: 'Please enter value'});
    } else if (!password?.confirm_password.length) {
      setError({type: 'confirm', value: 'Please enter value'});
    } else if (password?.confirm_password !== password?.new_password) {
      setError({
        type: 'confirm',
        value: 'Please make sure your passwords match',
      });
    } else {
      try {
        const result = await invoke({
          id: 'changePassword',
          paramValue: {
            oldPassword: password.old_password,
            newPassword: password?.new_password,
            confirmPassword: password.confirm_password,
          },
        });
        navigation.pop();
        console.log('>>>>>>>>>>>>Change', result);
      } catch (err) {
        let message = JSON.parse(err?.message)?.error?.message;
        if (message === 'Old Password is not matched') {
          setError({
            type: 'old',
            value: message || '',
          });
        } else {
          setError({
            type: 'confirm',
            value: message || '',
          });
        }
        console.log(JSON.parse(err?.message)?.error?.message);
      }
    }
  };
  return (
    <View style={{flex: 1, padding: 20, flexDirection: 'row'}}>
      <TouchableOpacity
        onPress={() => {
          navigation.pop();
        }}
        style={{cursor: 'pointer'}}>
        <Image source={backIcon} />
      </TouchableOpacity>
      <View style={{padding: 20, flex: 1, marginTop: 10}}>
        <Text style={{...h22_SemiBold, marginBottom: 8}}>Change Password</Text>
        <View style={{flexDirection: 'row'}}>
          <Text style={style.textStyle}>{'Old password'}</Text>
        </View>
        <TextInput
          value={password['old_password']}
          renderInputError={
            error?.type === 'old'
              ? () => {
                  return <View></View>;
                }
              : false
          }
          error={error?.type === 'old' ? error?.value : ''}
          inputProps={{
            type: 'password',
          }}
          onChangeText={(value) => {
            setError({});
            setPassword({...password, old_password: value});
          }}
          variant="filled"
          placeholder={'Enter old password'}
        />
        <View style={{flexDirection: 'row'}}>
          <Text style={style.textStyle}>{'New password'}</Text>
        </View>
        <TextInput
          value={password['new_password']}
          renderInputError={
            error?.type === 'new'
              ? () => {
                  return <View></View>;
                }
              : false
          }
          error={error?.type === 'new' ? error?.value : ''}
          inputProps={{
            type: 'password',
          }}
          onChangeText={(value) => {
            setError({});
            setPassword({...password, new_password: value});
          }}
          variant="filled"
          placeholder={'Enter new password'}
        />
        <View style={{flexDirection: 'row'}}>
          <Text style={style.textStyle}>{'Confirm password'}</Text>
        </View>
        <TextInput
          inputProps={{
            type: 'password',
          }}
          value={password['confirm_password']}
          renderInputError={
            error?.type === 'confirm'
              ? () => {
                  return <View></View>;
                }
              : false
          }
          error={error?.type === 'confirm' ? error?.value : ''}
          inputProps={{
            type: 'password',
          }}
          onChangeText={(value) => {
            setError({});
            setPassword({...password, confirm_password: value});
          }}
          variant="filled"
          placeholder={'Enter confirm password'}
        />
        <TouchableOpacity
          style={{marginTop: 10, cursor: 'pointer'}}
          onPress={() => {
            changePassword();
          }}>
          <GradientButton label={'Change Password'} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const style = {
  textStyle: {
    ...h14_Medium,
    // color: '#383838',
    color: 'rgba(56, 56, 56)',
    marginBottom: 8,
    marginTop: 8,
  },
};
export default ChangePassword;
