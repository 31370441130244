import React from 'react';
import {View, Text, TouchableOpacity, Image} from '../../app-components';
import {h15_Medium} from '../../theme/fonts';
import {addIcon, redCloseIcon, roundCross} from '../../images';
import {searchInput, multiAutoSuggestInput} from '../../autoSuggestions';
import NestedList from '../../app-components/input-components/NestedList';
import {autosuggestFetch, getUser, post} from '../../AppServices';
import {
  MultiAutoSuggestInput,
  MultiTextInput,
} from '../../app-components/input-components/Editors';
import {TextAreaInput} from '../../app-components/input-components/Editors';
import {OtherRenderSelector} from '../../app-components/input-components/autoSuggest/renderRowCard';

const MayaAiSymptom = (props) => {
  let {setValue = () => {}, data} = props;

  let nestedlistprops = {
    field: 'symptoms',
    visible: () => {
      let {userType} = getUser();
      return userType === 'Doctor' ? true : false;
    },
    addButtonStyle: {width: 200, height: 100},
    footer: {
      addLabel: 'Add',
    },
    labelHeader: [
      {label: 'Disease', width: 135},
      {label: 'Duration', width: 135},
      {label: 'Qualifiers'},
    ],

    formGroups: [
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        groups: [
          {
            columns: [
              searchInput({
                field: 'id',
                query: 'symptomLists',
                model: 'Symptoms',
                placeholder: 'Enter disease',
                idField: 'id',
                searchField: 'name_en',
                width: 135,
                showSelected: false,
                suggestionField: 'name_en',
                valueField: 'name_en',
                renderSelector: false,
              }),
              searchInput({
                field: 'duration',
                query: 'durationList',
                model: 'Durations',
                placeholder: 'Enter disease',
                idField: 'id',
                searchField: 'name_en',
                width: 135,
                showSelected: false,
                suggestionField: 'name_en',
                valueField: 'name_en',
                renderSelector: false,
              }),

              multiAutoSuggestInput({
                width: 215,
                field: 'qualifiers',
                placeholder: 'Select qualifiers',
                idField: '_id',
                searchField: 'name_en',
                suggestionField: 'name_en',
                valueField: 'name_en',
                fetch: autosuggestFetch({
                  model: 'Symptom_Qualifiers',
                  id: 'symptomQualifierList',
                  search: 'name_en',
                  showOther: true,
                }),
              }),
              // autoSuggestOptionsInput({
              //   field: 'frequency',
              //   options: ['Once', 'Twice', 'Thrice'],
              //   placeholder: 'Select',
              //   showSelected: false,
              //   width: 100,
              //   dropdownStyle: {
              //     height: 130,
              //     borderRadius: 8,
              //     backgroundColor: '#ffffff',
              //     ...moreActionsShadow,
              //   },
              // }),
              // autoSuggestOptionsInput({
              //   field: 'timing',
              //   placeholder: 'Before meal',
              //   options: ['Before meal', 'After meal'],
              //   width: 120,
              //   showSelected: false,
              //   dropdownStyle: {
              //     height: 80,
              //     borderRadius: 8,
              //     backgroundColor: '#ffffff',
              //     ...moreActionsShadow,
              //   },
              // }),

              // {
              //   width: 68,
              //   type: 'number',
              //   field: 'duration',
              //   placeholder: 'Days',
              // },
              {
                width: 20,
                render: (props) => {
                  let {item, index, removeRow} = props;
                  return (
                    <TouchableOpacity
                      onPress={() => removeRow({item, index})}
                      style={{
                        cursor: 'pointer',
                        height: 38,
                        justifyContent: 'center',
                      }}>
                      <Image
                        source={redCloseIcon}
                        style={{width: 20, height: 20}}
                      />
                    </TouchableOpacity>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <View style={{flex: 1, marginTop: 10}}>
      <Text
        style={{
          ...h15_Medium,
          marginBottom: 10,
          marginTop: 10,
          color: '#383838',
        }}>
        Chief Complaints
      </Text>
      <NestedList
        {...nestedlistprops}
        {...props}
        setValue={setValue}
        data={data}
      />
      <MultiTextInput
        value={data.chief_complaints_others}
        renderSelector={addIcon}
        variant={'filled'}
        removeChipIcon={roundCross}
        onChangeValue={(value) => {
          setValue({data, field: 'chief_complaints_others', value});
        }}
        placeholder="Enter Chief Complaints"
      />
      <Text
        style={{
          ...h15_Medium,
          marginBottom: 10,
          marginTop: 10,
          color: '#383838',
        }}>
        {/* Context */}
        History of persent illness
      </Text>
      <MultiAutoSuggestInput
        data={data}
        onChangeValue={(value) => {
          setValue({
            field: 'contexts',
            value,
            data,
          });
        }}
        renderSelector={(props) =>
          OtherRenderSelector({
            onChangeOtherValue: (value, clearText) => {
              post({
                data: {},
                updates: {name_en: value, type: 'context'},
                model: 'Other_master_data',
              }).then((res) => {
                const {result = []} = res || {};
                if (Array.isArray(result) && result.length) {
                  let val = result[0]?.result || {};
                  setValue({
                    field: 'Context_others',
                    data,
                    value: [
                      ...(data?.Context_others ? data.Context_others : []),
                      val,
                    ],
                  });
                }
              });
              clearText && clearText();
            },
            addData:"Add context",
            ...props,
          })
        }
        showArrow={false}
        removeChipIcon={roundCross}
        fetch={autosuggestFetch({
          model: 'Contexts',
          id: 'contextList',
          search: 'alias_en',
          showOther: true,
        })}
        variant="filled"
        value={data['contexts']}
        valueField={'alias_en'}
        idField={'_id'}
        field={'alias_en'}
        secondaryValueField={'name_en'}
        sugestionField={'alias_en'}
        searching={true}
        // textColor="#383838"
        placeholder={'Contexts'}
        renderRowProps= {{
          primaryField: "alias_en",
          secondarySugestionField:"name_en",
          // avatarIcon,
        }}
        maxHeight={500}
      />
      <MultiAutoSuggestInput
        data={data}
        onChangeValue={(value) => {
          setValue({
            field: 'Context_others',
            value,
            data,
          });
        }}
        showArrow={false}
        removeChipIcon={roundCross}
        fetch={autosuggestFetch({
          model: 'Other_master_data',
          id: 'otherMasterList',
          search: 'name_en',
        })}
        variant="filled"
        value={data['Context_others']}
        valueField={'name_en'}
        idField={'_id'}
        field={'name_en'}
        sugestionField={'name_en'}
        searching={true}
        // textColor="#383838"
        placeholder={'Contexts'}
        maxHeight={500}
        disableTextInput
      />
    </View>
  );
};

export default MayaAiSymptom;
