import {Text, View} from '../index';
import React, {useState, useEffect} from 'react';
import InputMask from 'react-input-mask';
import {h12_Regular, h16_Medium, h30_Regular} from '../../theme/fonts';
import {AutoSuggestInput} from './Editors';
import {moreActionsShadow} from '../../theme/shadows';
import {Headline9} from '../../theme/Headlines';
import {manazeV6Colors} from '../../theme/manazeV6Colors';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const {neutral_1} = manazeV6Colors;
const IndiaMask = '+\\91 99999 99999';
const USMask = '+1 999 999 9999';

const MobileInput = (props) => {
  const [selectedMask, setSelectedMask] = useState('IN');
  const {
    label,
    labelStyle,
    labelProps,
    value,
    setValue = () => {},
    onChangeText = () => {},
    fieldName = 'mobile',
    data,
    inputStyle = {},
    onKeyPress = () => {},
    inputProps,
    error,
    errorStyle,
    isEmail = false,
    buttonStyle = {},
    errorProps,
    mobileId = '',
    checkValidity = () => {
      return true;
    },
    ...restProps
  } = props || {};

  const [isMobileOnFocus, setIsMobileOnFocus] = useState(false);

  // useEffect(() => {
  //   if (value && typeof value === 'string') {
  //     if (!value.startsWith('+1')) {
  //       setSelectedMask('IN');
  //     }
  //   }
  // }, [value]);

  const onChange = (value) => {
    setValue({field: fieldName, value, data});
    onChangeText(value);
  };

  return (
    <>
      {label && (
        <Text
          style={{
            ...h16_Medium,
            color: 'rgba(56, 56, 56, 0.37)',
            marginBottom: 10,
            ...labelStyle,
          }}
          {...labelProps}>
          {label}
        </Text>
      )}
      <View
        style={{
          flexDirection: 'row',
          // backgroundColor: 'red',
          alignItems: 'center',
        }}>
        <ReactPhoneInput
          onEnterKeyPress={(e) => {
            onKeyPress(e);
          }}
          value={value || '+91'}
          containerStyle={{}}
          onChange={(value, country, e, formattedValue) => {
            if (country?.dialCode != value) {
              onChange(`+${value}`);
            }
          }}
          inputProps={{
            id: mobileId,
          }}
          // buttonStyle={{...buttonStyle}}
          searchClass="search-class"
          searchStyle={{margin: '0', width: '97%', height: '30px'}}
          enableSearch
          disableSearchIcon
          inputStyle={{width: '100%', height: 40, ...inputStyle}}
          dropdownStyle={{height: 170}}
          country="us"
          masks={{in: '..... .....'}}
          preferredCountries={['us', 'in']}
          onFocus={() => {
            if (isEmail) {
              setValue({field: 'email', value: '', data});
            }
            setIsMobileOnFocus(true);
          }}
          isValid={checkValidity}
        />
        {/* <InputMask
          mask={selectedMask === 'US' ? USMask : IndiaMask}
          value={value}
          onChange={(event) => {
            const {target: {value} = {}} = event;
            onChange(value);
          }}
          key={selectedMask}
          style={{
            flex: 1,
            marginLeft: 10,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: isMobileOnFocus ? '#4276EC' : '#E1E1E1',
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
            minHeight: 38,
            outline: 'none',
            ...inputStyle,
          }}
          alwaysShowMask={true}
          maskChar={null}
          inputMode="tel"
          onFocus={() => {
            if(isEmail){
              setValue({field:"email",value:"",data})
            }
            setIsMobileOnFocus(true)}}
          onBlur={() => setIsMobileOnFocus(false)}
          onKeyPress={(event) => onKeyPress(event)}
          {...inputProps}
        /> */}
      </View>
      {error && (
        <Text
          style={{...h12_Regular, color: 'red', ...errorStyle}}
          {...errorProps}>
          {error}
        </Text>
      )}
    </>
  );
};

export default MobileInput;
