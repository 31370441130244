import React from 'react';
import {View, BasicTextInput} from '../../react-core-components';
import {dateFormatter, getZeroTimeDate, typecastDate} from './DateUtility';
import {
  WithLabel,
  WithInputWrapper,
  WithVariant,
  InputError,
  getInputProps,
} from '../../react-input-wrapper';
import DateSelector from './DateSelector';

// todo
export const getFormatValue = (value, props = {}) => {
  let {format = 'DD MMM,YY', zeroTimeDate, time} = props;
  if (zeroTimeDate === undefined && !time) {
    zeroTimeDate = true;
  }
  return dateFormatter(value, format, zeroTimeDate);
};

// todo
export const typecast = (value, props = {}) => {
  value = typecastDate(value);
  if (!value) {
    return value;
  }
  let {time, eod} = props;
  if (time) {
    if (props.value) {
      let prevValue = new Date(props.value);
      prevValue.setDate(value.getDate());
      prevValue.setMonth(value.getMonth());
      prevValue.setFullYear(value.getFullYear());
      value = prevValue;
    }
  } else {
    value = getZeroTimeDate(value, eod);
  }
  return value;
};

class DateInput extends React.Component {
  state = {};

  setStateValue = value => {
    this.setState({
      value,
    });
  };

  onChangeValue = (value, e) => {
    let {onChangeText, onChangeValue} = this.props;
    if (typeof value === 'string') {
      value = value.trim();
    }
    let typecastDate = this.props.typecast || typecast;
    if (typecastDate) {
      value = typecastDate(value, this.props);
    } else {
      value = value ? new Date(value) : null;
      if (value === 'Invalid Date') {
        value = null;
      }
    }
    if (onChangeText) {
      onChangeText(value);
    }
    onChangeValue && onChangeValue(value, e);
  };

  onDateChange = (value, e) => {
    let {onBlur} = this.props;
    this.onChangeValue(value, e);
    onBlur && onBlur(e);
  };

  onBlur = e => {
    let {onBlur} = this.props;
    let {value} = this.state;
    if (value !== undefined) {
      this.onChangeValue(value, e);
      this.setStateValue(void 0);
    }
    onBlur && onBlur(e);
  };

  render() {
    let {mode = 'date', CustomDateCalendar, ...rest} = this.props;
    let {value, ...restInputProps} = getInputProps(this.props);
    if (value === undefined || value === null) {
      value = '';
    }
    const {value: stateValue} = this.state;
    let formattedValue = getFormatValue(value, rest);
    if (CustomDateCalendar) {
      return (
        <CustomDateCalendar
          mode={mode}
          value={formattedValue}
          {...rest}
          onDateChange={this.onDateChange}
        />
      );
    }
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <BasicTextInput
            {...restInputProps}
            value={stateValue !== undefined ? stateValue : formattedValue}
            onBlur={this.onBlur}
            onChangeValue={this.setStateValue}
          />
        </View>
        <InputError {...this.props} />
        <DateSelector
          {...this.props}
          value={formattedValue}
          onDateChange={this.onDateChange}
          mode={mode}
        />
      </View>
    );
  }
}

DateInput = WithLabel(WithInputWrapper(WithVariant(DateInput)));

export default DateInput;
