import React from 'react';
import {ActionButton} from '../buttons/Buttons';
import {cancelButtonTheme} from '../../theme/actionTheme';

export const cancel = ({id, label, text, ...restProps} = {}) => {
  label = label || text || 'Cancel';
  if (!id && typeof label === 'string') {
    id = label;
  }
  return {
    id: label,
    label,
    type: 'cancel',
    render: () => {
      return (
        <ActionButton theme={cancelButtonTheme} label={label} {...restProps} />
      );
    },
  };
};

export const cancelAction = ({navigation}) => {
  navigation && navigation.goBack();
};
