import React from 'react';
import {AutoSuggestInput} from '../../../../app-components/input-components/Editors';
import {crossIcon, whiteCrossIcon} from '../../../../images';
import {
  h12_Regular,
  h14_AvenirNext,
  h14_AvenirNext_Bold,
  h14_Medium,
} from '../../../../theme/fonts';
import {moreActionsShadow} from '../../../../theme/shadows';
import {colors, bgs} from '../../../../../src/theme/colors';
import {getLastYears} from '../../../../Utility/getLastYears/getLastYearsAndMonth';

const {paleBlueLight} = bgs;
const {black, surface_white} = colors;
const CustomDropDown = (props) => {
  const {
    selectedValue,
    onChangeValue = () => {},
    data,
    crossIcon,
    width = 150,
  } = props;

  //   data = [{label: 'All', value: 'All'}, ...Data];
  return (
    <AutoSuggestInput
      data={data}
      onChangeValue={
        (value) => onChangeValue(value)
        //    {
        //     setSelectedValue(value);
        //     getMonthlydata(value);
        //   }
      }
      renderSelector={crossIcon}
      variant="filled"
      valueField="value"
      selectedField={'value'}
      value={selectedValue}
      keyField={'value'}
      field={'value'}
      options={data}
      sugestionField={'value'}
      style={{
        width: width,
        border: '1px solid rgb(195, 195, 195)',
        backgroundColor: 'white',
        alignSelf: 'flex-end',
        marginRight: '15px',
        borderRadius: '15px',
      }}
      moreInputStyle={{
        minHeight: 22,
      }}
      dropdownInputTheme={{
        ...h14_AvenirNext,
      }}
      dropdownTextHighlightStyle={{
        color: 'black',
        backgroundColor: paleBlueLight,
        ...h14_Medium,
      }}
      dropdownStyle={{
        ...moreActionsShadow,
        height: 150,
        borderRadius: 3,
        backgroundColor: '#fff',
        zIndex: 1,
      }}
      dropdownTextStyle={{
        color: 'rgba(56, 56, 56, 0.7)',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: surface_white,
        cursor: 'pointer',
        ...h14_Medium,
      }}
    />
  );
};

export default CustomDropDown;
