import React, {useState} from 'react';
import {
  Image,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from '../../../npms/react-core-components';
import {
  backCrossIcon,
  newSearchIcon,
  collapseIcon,
  expandIcon,
  addIcon,
} from '../../../images';
import {getImageUrl} from '../../../AppServices';
import {h10_Regular, h16_Regular} from '../../../theme/fonts';
import {ActivityIndicator} from '../../../npms/react-activity-indicator';
import {Avatar} from '../../../npms/react-avatar';
import {renderRowStyle} from '../../../theme/editorTheme';
import {bgs} from '../../../theme/colors';
import {resolveExp} from '../../../npms/react-utility-functions';
import {getFileExtension} from '../../UtilityFunctions';
let {onHoverBackgroundColor} = bgs;

export const RenderLoading = ({
  loading,
  defaultLoadingComponent,
  closeModal,
}) => {
  // return defaultLoadingComponent()
  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ActivityIndicator width={3.5} size={35} color={'#6d91f0'} />
    </View>
  );
};
const getAvatarDisplay = (value) => {
  if (value && typeof value === 'string') {
    value = value.trim();
  }
  let _avatarDisplay = '';
  if (value) {
    var splitValues = value.split(' ');
    let _firstName = splitValues[0];
    let _lastName = splitValues[1];
    _avatarDisplay = _firstName[0] + (_lastName ? _lastName[0] : '');
  }
  return _avatarDisplay.toUpperCase();
};

export const RenderRowComponent = (props) => {
  const [mouseHover, setMouseHover] = useState(false);
  let {
    item,
    primaryField,
    secondaryField,
    iconField,
    avatarIcon,
    highlighted,
    containerStyle,
    selectedIconContainerStyle,
    selectedIconStyle,
    avatarTextStyle,
    selectedContainerStyle,
    hoverContainerStyle,
    titleStyle,
    selectedTitleStyle,
    hoverTitleStyle,
    hoverDescriptionStyle,
    descriptionStyle,
    selectedDescriptionStyle,
    selectedIcon,
    disableOnHover,
    iconContainerStyle,
    iconStyle,
    avatarStyle,
    hoverAvatarStyle,
    selectedAvatarStyle,
    showAvatar,
    showSelected = true,
    resolveIcon = getImageUrl,
    secondarySugestionField,
  } = props;
  if (!item) {
    return null;
  }
  let icon = iconField ? item[iconField] : void 0; 
  let title =""
  if (primaryField && item[primaryField]) {
    title = item[primaryField];
  } else if (secondarySugestionField && item[secondarySugestionField]) {
    title = item[secondarySugestionField];
  }else{
    title=item
  }
  if (title && typeof title === 'object') {
    title = JSON.stringify(title);
  }
  let description = secondaryField ? item[secondaryField] : void 0;
  if (description && typeof description === 'object') {
    description = JSON.stringify(description);
  }
  let backgroundColor = '#e8ecfc';

  if (!disableOnHover && mouseHover) {
    containerStyle = {...containerStyle, ...hoverContainerStyle};
    titleStyle = {...titleStyle, ...hoverTitleStyle};
    descriptionStyle = {...descriptionStyle, ...hoverDescriptionStyle};
    avatarStyle = {...avatarStyle, ...hoverAvatarStyle};
    backgroundColor = '#fff';
  }
  if (highlighted) {
    containerStyle = {...containerStyle, ...selectedContainerStyle};
    titleStyle = {...titleStyle, ...selectedTitleStyle};
    descriptionStyle = {...descriptionStyle, ...selectedDescriptionStyle};
    avatarStyle = {...avatarStyle, ...selectedAvatarStyle};
  }
  const onMouseEnter = () => {
    setMouseHover(true);
  };
  const onMouseLeave = () => {
    setMouseHover(false);
  };
  let renderComponent = title ? <Text style={titleStyle}>{title}</Text> : null;
  if (description) {
    renderComponent = (
      <>
        {renderComponent}
        <Text style={descriptionStyle}>{description}</Text>
      </>
    );
  }
  renderComponent = (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
      }}>
      {renderComponent}
    </View>
  );
  let iconComponent = iconField ? (
    <View style={iconContainerStyle}>
      <Image style={iconStyle} source={icon} resizeMode="contain" />
    </View>
  ) : (
    void 0
  );
  let avatarComponent = showAvatar ? (
    <Avatar
      size={28}
      style={avatarStyle}
      icon={avatarIcon ? resolveExp(item, avatarIcon) : void 0}
      backgroundColor={backgroundColor}
      value={title}
      resolveIcon={resolveIcon}
      textStyle={avatarTextStyle}
    />
  ) : (
    void 0
  );
  return (
    <View
      style={{
        flexDirection: 'row',
        cursor: 'pointer',
        ...containerStyle,
      }}
      title={title}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}>
      {iconComponent}
      {avatarComponent}
      {renderComponent}
      {showSelected && selectedIcon && highlighted ? (
        <View style={selectedIconContainerStyle}>
          <Image
            style={selectedIconStyle}
            source={selectedIcon}
            resizeMode="contain"
          />
        </View>
      ) : (
        void 0
      )}
    </View>
  );
};

export const getAutoSuggestProps = ({
  renderRow,
  renderRowProps,
  renderSelector,
  renderLoading,
  showAvatar,
  type,
}) => {
  let renderRowComponent = void 0;
  if (renderRowProps || renderRow) {
    renderRowComponent = (params) => {
      return renderRow ? (
        renderRow({
          ...params,
          ...renderRowProps,
        })
      ) : (
        <RenderRowComponent
          showAvatar={showAvatar}
          {...params}
          {...renderRowStyle}
          {...renderRowProps}
        />
      );
    };
  }
  if (type === 'specialitySearchRenderRow') {
    renderRowComponent = (params) => {
      return (
        <SpecialitySearchRenderRow
          {...params}
          {...renderRowProps}
          {...renderRowStyle}
        />
      );
    };
  }
  if (
    renderSelector &&
    (typeof renderSelector === 'object' || typeof renderSelector === 'boolean')
  ) {
    renderSelector = (params) => (
      <RenderSelector {...params} renderSelector={renderSelector} />
    );
  }
  if (renderLoading && typeof renderLoading === 'boolean') {
    renderLoading = (props) => {
      return <RenderLoading {...props} />;
    };
  }
  return {
    renderRow: renderRowComponent,
    renderSelector,
    renderLoading,
  };
};

export const RenderSelector = ({
  value,
  searching,
  modalOpen,
  searchText,
  clearValue,
  clearText,
}) => {
  let onPress = void 0;
  let selectorIcon;
  let extraStyle = {};
  if (searching) {
    if (searchText) {
      selectorIcon = backCrossIcon;
      onPress = clearText;
    } else if (modalOpen) {
      selectorIcon = newSearchIcon;
    } else if (value) {
      selectorIcon = backCrossIcon;
      onPress = clearValue;
    } else {
      selectorIcon = newSearchIcon;
    }
  } else {
    if (modalOpen) {
      selectorIcon = collapseIcon;
      extraStyle.marginTop = 4;
    } else if (value) {
      selectorIcon = backCrossIcon;
      onPress = clearValue;
    } else {
      selectorIcon = expandIcon;
      extraStyle.marginTop = 4;
    }
  }
  let touchProps = {};
  let RenderComponent = View;
  if (onPress) {
    RenderComponent = TouchableOpacity;
    if (Platform.OS === 'web') {
      touchProps.onMouseDown = (e) => {
        e && e.stopPropagation && e.stopPropagation();
        onPress && onPress(e);
      };
    } else {
      touchProps.onPress = onPress;
    }
  }

  return (
    <RenderComponent {...touchProps}>
      <Image
        source={selectorIcon}
        style={{
          ...extraStyle,
          cursor: 'pointer',
          resizeMode: 'contain',
          height: 16,
          width: 16,
          marginLeft: 6,
          marginRight: 12,
        }}
      />
    </RenderComponent>
  );
};
export const renderAvatarChips = ({
  roundedChipsWithAvatarStyle,
  value,
  valueField,
  idField,
  avatarIcon,
  removeChipIcon,
  onRemove,
}) => {
  if (!value || !value.length) {
    return;
  }

  let {
    containerStyle,
    itemContainerStyle,
    itemTextStyle,
    crossContainerStyle,
    crossIconStyle,
    crossTextStyle,
    avatrStyle,
  } = roundedChipsWithAvatarStyle;
  containerStyle = {...containerStyle};
  return (
    <View style={containerStyle}>
      {value.map((item, index) => {
        if (!item) {
          return null;
        }
        let key = idField ? item[idField] : index;
        let displayValue = valueField ? item[valueField] : item;
        const image = avatarIcon ? resolveExp(item, avatarIcon) : void 0;

        return (
          <View key={key} style={{flexDirection: 'row', ...itemContainerStyle}}>
            <Avatar
              size={36}
              icon={image}
              value={displayValue}
              resolveIcon={getImageUrl}
              textStyle={{...itemTextStyle}}
              {...avatrStyle}
            />
            <View
              style={{
                flexDirection: 'row',
                paddingLeft: 6,
                paddingRight: 6,
              }}>
              <Text style={itemTextStyle}>{displayValue}</Text>
              <TouchableOpacity
                style={{cursor: 'pointer', ...crossContainerStyle}}
                onPress={(e) => onRemove && onRemove({item, e})}>
                {removeChipIcon ? (
                  <Image style={crossIconStyle} source={removeChipIcon} />
                ) : (
                  <Text style={crossTextStyle}>X</Text>
                )}
              </TouchableOpacity>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export const SpecialitySearchRenderRow = (props) => {
  const [mouseHover, setMouseHover] = useState(false);
  let {
    item,
    primaryField,
    highlighted,
    onRowTouch,
    containerStyle,
    checkBox,
    titleStyle,
    selectedIcon,
  } = props;
  if (!item) {
    return null;
  }
  let title = primaryField ? item[primaryField] : item;
  if (title && typeof title === 'object') {
    title = JSON.stringify(title);
  }
  titleStyle = {color: '#383838', ...h16_Regular};

  if (highlighted || mouseHover) {
    titleStyle = {...titleStyle};
  }

  const onMouseEnter = () => {
    setMouseHover(true);
  };
  const onMouseLeave = () => {
    setMouseHover(false);
  };

  return (
    <View
      style={{
        ...containerStyle,
        backgroundColor: mouseHover ? onHoverBackgroundColor : void 0,
        paddingTop: 10,
        paddingBottom: 10,
      }}
      // onClick={onRowTouch}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}>
      {highlighted ? (
        <Image source={selectedIcon} style={checkBox} />
      ) : (
        <View style={checkBox} />
      )}

      <Text style={titleStyle}>{title}</Text>
    </View>
  );
};

export const OtherRenderSelector = ({
  value,
  searching,
  modalOpen,
  searchText,
  clearValue,
  clearText,
  otherValue,
  onChangeOtherValue,
  addData = '',
}) => {
  let onPress = void 0;
  let selectorIcon;
  let extraStyle = {};
  let icon_name = addIcon;
  if (addData) {
    icon_name = addData;
  }
  if (searching) {
    if (searchText) {
      selectorIcon = icon_name;
      onPress = onChangeOtherValue;
      extraStyle = {zIndex: 200};
    } else if (modalOpen) {
      selectorIcon = newSearchIcon;
    } else if (value) {
      selectorIcon = icon_name;
      onPress = onChangeOtherValue;
      extraStyle = {zIndex: 200};
    } else {
      selectorIcon = newSearchIcon;
    }
  } else {
    if (modalOpen) {
      selectorIcon = collapseIcon;
      extraStyle.marginTop = 4;
    } else if (value) {
      selectorIcon = icon_name;
      onPress = onChangeOtherValue;
      extraStyle = {zIndex: 200};
    } else {
      selectorIcon = expandIcon;
      extraStyle.marginTop = 4;
    }
  }
  let RenderComponent = View;
  return (
    <RenderComponent onPress={() => onPress && onPress(searchText, clearText)}>
      {!getFileExtension(selectorIcon) ? (
        <Text
          style={{
            ...extraStyle,
            cursor: 'pointer',
            marginLeft: 6,
            marginRight: 12,
            color: '#2e6ecd',
          }}>
          {selectorIcon}
        </Text>
      ) : (
        <Image
          source={selectorIcon}
          style={{
            ...extraStyle,
            cursor: 'pointer',
            resizeMode: 'contain',
            height: 16,
            width: 16,
            marginLeft: 6,
            marginRight: 12,
          }}
        />
      )}
    </RenderComponent>
  );
};
