import SettingForm from './SettingForm';
import {save} from '../../app-components/action/SaveAction';
import {cancelButton} from '../../app-components/action/CloseAction';

let header = {
  title: 'Add Setting',
  actions: [cancelButton(), save()],
};

export default SettingForm({header});
