import React from 'react';
import {gettingData, getUser} from '../../../../AppServices';
import {View} from '../../../../app-components';
import {GradientButton} from '../../../../app-components/buttons/Buttons';

const Button = (props) => {
  const {navigation} = props;
  const {state: {params = {}} = {}} = navigation;
  return (
    <View
      onClick={() => {
        getData({params, navigation});
      }}>
      <GradientButton label={'Add Doctor'} />
    </View>
  );
};
const getData = async ({params, navigation}) => {
  let {item: {_id} = {}} = params || {};
  if (!_id) {
    const {organization = {}} = getUser();
    _id = organization?._id;
  }
  const {data = []} = await gettingData({
    model: 'OrganizationSetting',
    query: 'organizationSettingList',
    organization_id: {_id: _id},
  });
  // console.log('>>>>params', organization_id);
  const orgId = _id;
  const {booking_fee = 0, booking_duration = 0} = data[0] || {};
  navigation &&
    navigation.push({
      view: 'add-doctors',
      params: {...params, booking_duration, booking_fee, orgId},
    });
  return null;
};

export default Button;
