import React, {useState, useEffect} from 'react';
import Format from '../GenericComponent/ClinicSignupFormat';
import {
  View,
  TouchableOpacity,
  Text,
  isMobile,
  Dimensions,
  Image,
} from '../../app-components';
import {UploadImage} from '../../app-components/input-components/Editors';
import {TextInput} from '../../app-components/input-components/Editors';
import {h11_Medium, h12_Regular, h14_AvenirNext} from '../../theme/fonts';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {
  getImageUrl,
  invoke,
  validateEmail,
  validateMobile,
} from '../../AppServices';
import {attachmentIcon, crossPdf, pdf} from '../../images';
import {getFileExtension} from '../../app-components/UtilityFunctions';
import {GooglePlaceMapInput} from '../../app-components/input-components/Editors';
import {getGooglePlaceDetail, googlePlaceFetch} from '../../AppServices';
import {defaultProps} from '../../autoSuggestions';
import {isEmpty} from 'lodash';
import MobileInput from '../../app-components/input-components/MobileInput';

const {height, width} = Dimensions.get('window');
const SinglePageSignup = (props) => {
  const {navigation = {}} = props || {};
  const {state: {params: {completed = false, type = 'Doctor'} = {}} = {}} =
    navigation || {};

  const [adminName, setAdminName] = useState('');
  const [error, setError] = useState({});
  const [adminMobile, setAdminMobile] = useState('');
  const [file, setFile] = useState([]);
  const [doc, setDoc] = useState([]);
  const [organizationName, setOrganizationName] = useState('');
  const [organizationAddress, setOrganizationAddress] = useState({});
  const [organizationEmail, setOrganizationEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (completed) {
      setAdminName('');
      setError({});
      setAdminMobile('');
      setDoc([]);
      setOrganizationName('');
      setOrganizationAddress({});
      setOrganizationEmail('');
    }
  }, [completed]);

  const setValueMobile = ({value, ...props}) => {
    setError({});
    // console.log(">>>>>>>>>>>>>>>>>>",props,value)
    setAdminMobile(value);
  };
  let medical_Report_Slice = doc;
  let medical_Report_Slice_Second = [];
  const IconLength = isMobile ? 3 : 3;
  if (doc.length > IconLength) {
    medical_Report_Slice = doc.slice(0, IconLength);
    medical_Report_Slice_Second = doc.slice(IconLength, doc.length);
  }
  const imageView = () => {
    navigation.push({
      view: 'pdfReader',
      params: {
        dataArr: doc,
      },
      modal: true,

      modalProps: {
        autoHide: true,
        minHeight: 180,
        height: isMobile ? height * 0.75 : 600,
        width: isMobile ? 300 : 700,
        position: 'screenCenter',
        screenCenterStyle: {
          flex: 1,
          overflow: 'hidden',
          borderRadius: 8,
          maxHeight: '90%',
          maxWidth: '90%',
          zIndex: 12,
        },
      },
    });
  };
  const removeElement = (fileIndex) => {
    const fileCopy = [...doc];
    const updatedFile = fileCopy.filter((item, index) => index !== fileIndex);
    // console.log(updatedFile);
    // setFile([...updatedFile]);
    setDoc([...updatedFile]);
  };
  const createUser = async () => {
    if (!organizationName.length) {
      setError({type: 'orgName', value: 'Please enter'});
    } else if (isEmpty(organizationAddress)) {
      setError({type: 'address', value: 'Please enter'});
    } else if (!adminName.length) {
      setError({type: 'name', value: 'Please enter name'});
    } else if (!adminMobile.length) {
      setError({type: 'mobile', value: 'Please enter mobile'});
    } else if (!organizationEmail.length) {
      setError({type: 'email', value: 'Please enter email address'});
    } else if (!validateEmail(organizationEmail)) {
      setError({type: 'email', value: 'Please enter valid email'});
    } else {
      setLoading(true);
      const sendingData = {
        organization_type: 'clinic',
        organization_name: organizationName,
        organization_mobile: adminMobile,
        email: organizationEmail,
        // organization_logo: data?.organizationImage,
        name: adminName,
        mobile: adminMobile,
        organization_address: organizationAddress,
        documents: doc || [],
        // profile_picture: (file && file.length && file[0]?.file) || {},
      };
      await invoke({
        id: 'organizationWithFirstAdmin',
        paramValue: {
          data: {
            ...sendingData,
            individually_registered: true,
          },
          selfDoctor: type === 'Doctor' ? true : false,
          selfClinicAdmin: type !== 'Doctor' ? true : false,
        },
      })
        .then((res) => {
          setLoading(false);
          navigation.push({view: 'signup-otp', params: {...sendingData}});
        })
        .catch((err) => {
          setLoading(false);
          let message = JSON.parse(err?.message)?.error?.message;
          if (message && typeof message === 'string') {
            if (message.includes('not a valid phone number')) {
              setError({type: 'mobile', value: 'Please enter a valid mobile'});
            } else {
              setError({type: 'otherError', value: message});
            }
          }

          console.log('error in creating', message);
        });
    }
  };
  return (
    <View
      id="signup_form"
      style={{
        minWidth: isMobile ? width * 0.75 : 'max-content',
        // maxWidth: isMobilestyle={{marginRight: 25}} height * 0.65 : 400,
        justifyContent: 'space-between',
      }}>
      <View
        style={{
          marginTop: 10,
          // paddingRight: isMobile ? 5 : 10,
          paddingLeft: isMobile ? 5 : 10,
        }}>
        <View style={{flexDirection: isMobile ? 'column' : 'row'}}>
          <View style={{marginRight: 15}}>
            <Text style={{...h14_AvenirNext, marginBottom: 15}}>
              Write your Clinic full name
            </Text>
            <TextInput
              value={organizationName}
              onChangeText={(value) => {
                setOrganizationName(value);
              }}
              inputProps={{
                id: 'clinic_name',
              }}
              variant="filled"
              placeholder={'Clinic Name'}
            />
            {error && error?.type === 'orgName' ? (
              <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
            ) : (
              void 0
            )}
            <Text style={{...h14_AvenirNext, marginTop: 15, marginBottom: 15}}>
              Address
            </Text>
            <GooglePlaceMapInput
              isSpace={true}
              onChangeValue={(value) => {
                setOrganizationAddress(value);
              }}
              id="address_input"
              mapHeight={height * 0.3}
              value={organizationAddress}
              {...defaultProps(
                {valueField: 'description', suggestionField: 'description'},
                void 0,
                {
                  type: 'googlePlaceMap',
                  fetch: googlePlaceFetch,
                  modifySelectedData: getGooglePlaceDetail,
                  searching: true,
                },
              )}
              variant="filled"
              isMapVisible={false}
            />
            {error && error?.type === 'address' ? (
              <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
            ) : (
              void 0
            )}
            {type === 'Doctor' ? (
              <>
                <Text
                  style={{
                    ...h14_AvenirNext,
                    marginTop: 15,
                    marginBottom: 15,
                  }}>
                  {`Upload Documents`}
                  <span
                    style={{
                      color: 'gray',
                      ...h12_Regular,
                    }}>{` (optional)`}</span>
                </Text>

                <View
                  id="upload_doccument"
                  style={{alignItems: 'flex-start'}}>
                  <UploadImage
                    imageOnly={false}
                    imageWidth={25}
                    imageHeight={25}
                    minHeight={28}
                    // setValue={setValue}
                    onChangeValue={(value) => {
                      setDoc([...doc, ...value]);
                      // setFile([{file: value}]);
                      // console.log('>>>>>>>>>>>>>>>.Hwllo', value);
                    }}
                    // value={file}
                    data={doc}
                    // field="file"
                    defaultImage={attachmentIcon}
                    uploadOptions={{s3: true}}
                    multiple={true}
                    public_upload={true}
                  />
                </View>
                <View style={{flexDirection: 'row', marginLeft: 40}}>
                  {Array.isArray(medical_Report_Slice)
                    ? medical_Report_Slice.map((item, index) => (
                        <TouchableOpacity
                          style={{
                            marginRight: 15,
                          }}
                          onPress={() => imageView()}>
                          {getFileExtension(item.name) === 'pdf' ? (
                            <Image
                              source={pdf}
                              style={{
                                height: 35,
                                width: 35,
                              }}
                            />
                          ) : (
                            <Image
                              source={getImageUrl(item?.thumbnail)}
                              style={{
                                height: 35,
                                width: 35,
                              }}
                            />
                          )}
                          <TouchableOpacity
                            style={{
                              zIndex: 9,
                              top: -6,
                              right: -6,
                              position: 'absolute',
                            }}
                            onPress={(e) => {
                              e.stopPropagation();
                              removeElement(index);
                            }}>
                            <Image
                              source={crossPdf}
                              style={{
                                height: 16,
                                width: 16,
                              }}
                            />
                          </TouchableOpacity>
                        </TouchableOpacity>
                      ))
                    : null}
                  {Array.isArray(medical_Report_Slice_Second) &&
                  medical_Report_Slice_Second.length ? (
                    <TouchableOpacity
                      style={{
                        height: 35,
                        width: 35,
                        marginLeft: 5,
                        borderRadius: 4,
                        justifyContent: 'center',
                        backgroundColor: '#383838',
                        alignItems: 'center',
                      }}
                      onPress={() => imageView()}>
                      <Text style={{color: '#fff'}}>
                        {'+' + medical_Report_Slice_Second.length}
                      </Text>
                    </TouchableOpacity>
                  ) : null}
                </View>
              </>
            ) : (
              void 0
            )}
          </View>
          <View>
            <Text style={{...h14_AvenirNext, marginBottom: 15}}>Name</Text>
            <TextInput
              value={adminName}
              onChangeText={(value) => {
                setAdminName(value);
              }}
              inputProps={{
                id: 'name',
              }}
              variant="filled"
              placeholder={'Enter name'}
            />
            {error && error?.type === 'name' ? (
              <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
            ) : (
              void 0
            )}
            {/* <Text style={{...h14_AvenirNext, marginTop: 20, marginBottom: 15}}>
              Email
            </Text>
            <TextInput
              value={adminEmail}
              onChangeText={(value) => {
                setAdminEmail(value);
              }}
              variant="filled"
              placeholder={'Enter Organization Email'}
            /> */}

            <Text style={{...h14_AvenirNext, marginTop: 15, marginBottom: 15}}>
              Mobile
            </Text>
            <MobileInput
              value={adminMobile}
              setValue={setValueMobile}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  // sendOTP();
                }
              }}
              mobileId="mobile"
              mobileInputStyle={{flex: 1}}
            />
            {error && error?.type === 'mobile' ? (
              <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
            ) : (
              void 0
            )}
            <Text style={{...h14_AvenirNext, marginTop: 15, marginBottom: 15}}>
              Email
            </Text>
            <TextInput
              value={organizationEmail}
              onChangeText={(value) => {
                setOrganizationEmail(value);
              }}
              inputProps={{
                id: 'email',
              }}
              variant="filled"
              placeholder={'Enter Email'}
            />
            {error && error?.type === 'email' ? (
              <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
            ) : (
              void 0
            )}
          </View>
        </View>
      </View>
      <View style={{alignItems: isMobile ? 'center' : 'center', marginTop: 15}}>
        {error && error?.type === 'otherError' ? (
          <Text
            style={{
              ...h11_Medium,
              color: 'red',
              marginTop: 10,
              marginBottom: 10,
            }}>
            {error.value}
          </Text>
        ) : (
          void 0
        )}
        <TouchableOpacity
          onPress={() => {
            if (!loading) {
              createUser();
            }
          }}
          id="confirm"
          style={{width: 120, cursor: 'pointer'}}>
          <GradientButton
            label="Submit"
            loading={loading}
            indicatorColor={'#fff'}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (props) => (
  <Format {...props} backIconVisible={true} title="Register Your Clinic">
    <SinglePageSignup {...props} />
  </Format>
);
