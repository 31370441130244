import ScheduleTab from './ScheduleTab';
import ScheduleModal from './ScheduleModal';

export default {
  schedule: {
    screen: ScheduleTab,
  },
  ModalView: {
    screen: ScheduleModal,
  },
};
