import React, {useCallback} from 'react';
import {View, Text, TouchableOpacity} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {h22_SemiBold} from '../../theme/fonts';

function LanguageSelectModal(props) {
  const {navigation = {}} = props;
  const {state: {params = {}} = {}} = navigation;
  const {handlePphmReportDownload = () => {}, item = {}} = params;
  const {
    patient: {_id = ''},
  } = item;
  const Click = useCallback((language) => {
    handlePphmReportDownload(item.patient._id, language);
    navigation.goBack();
  }, []);

  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: 500,
        padding: 20,
      }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginRight: '5%',
        }}>
        <Text
          style={{
            marginBottom: 10,
            marginTop: 10,
            fontSize: '45px',
            color: '#275dad',
            ...h22_SemiBold,
          }}>
          Select report language
        </Text>
        <TouchableOpacity
          onPress={() => {
            navigation.pop();
          }}>
          <img
            alt="svgImg"
            style={{
              cursor: 'pointer',
              // marginBottom: 10,
              // marginTop: 10,
              height: '30px',
              width: '30px',
            }}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBkPSJNIDQuNzA3MDMxMiAzLjI5Mjk2ODggTCAzLjI5Mjk2ODggNC43MDcwMzEyIEwgMTAuNTg1OTM4IDEyIEwgMy4yOTI5Njg4IDE5LjI5Mjk2OSBMIDQuNzA3MDMxMiAyMC43MDcwMzEgTCAxMiAxMy40MTQwNjIgTCAxOS4yOTI5NjkgMjAuNzA3MDMxIEwgMjAuNzA3MDMxIDE5LjI5Mjk2OSBMIDEzLjQxNDA2MiAxMiBMIDIwLjcwNzAzMSA0LjcwNzAzMTIgTCAxOS4yOTI5NjkgMy4yOTI5Njg4IEwgMTIgMTAuNTg1OTM4IEwgNC43MDcwMzEyIDMuMjkyOTY4OCB6Ij48L3BhdGg+PC9zdmc+"
          />
        </TouchableOpacity>
      </View>
      <View
        style={{
          flexDirection: 'row',
        }}>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: '28.3%',
            marginRight: '5%',
          }}
          onPress={() => Click('en')}>
          <GradientButton label="English" />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: '28.3%',
            marginRight: '5%',
          }}
          onPress={() => Click('kha')}>
          <GradientButton label="Khasi" />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: '28.3%',
            marginRight: '5%',
          }}
          onPress={() => Click('both')}>
          <GradientButton label="Both" />
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default LanguageSelectModal;
