import React from 'react';
import {View} from '../../react-core-components';
import InputLabel from './InputLabel';
const WithLabel = (Component) => {
  class Label extends React.Component {
    state = {};

    onFocus = (e) => {
      let {onFocus} = this.props;
      onFocus && onFocus(e);
      this.setState({focus: true});
    };

    onBlur = (e) => {
      let {onBlur} = this.props;
      onBlur && onBlur(e);
      this.setState({focus: false});
    };

    onMouseEnter = (e) => {
      let {onMouseEnter} = this.props;
      this.setState({
        hover: true,
      });
      onMouseEnter && onMouseEnter(e);
    };

    onMouseLeave = (e) => {
      let {onMouseLeave} = this.props;
      this.setState({
        hover: false,
      });
      onMouseLeave && onMouseLeave(e);
    };

    isValueExist = (value) => {
      if (
        Array.isArray(value) ||
        value === undefined ||
        value === null ||
        (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    };

    getRef = (ref) => {
      let {getRef} = this.props;
      getRef && getRef(ref);
      this._inputRef = ref;
    };

    getInputRef = () => this._inputRef;

    render() {
      let {
        label,
        floatingLabel,
        placeholder,
        labelPosition,
        floatLabelOnActive,
        onFocus,
        onBlur,
        onMouseEnter,
        onMouseLeave,
        ...restProps
      } = this.props;
      let {mandatory, skipMandatory} = restProps;
      let {focus, hover} = this.state;
      let {isActive = focus, value} = restProps;
      if (label) {
        if (labelPosition === undefined) {
          if (
            (isActive && floatLabelOnActive !== false) ||
            this.isValueExist(value)
          ) {
            labelPosition = 'floating';
            if (floatingLabel) {
              label = floatingLabel;
            }
          } else {
            placeholder = '';
          }
        }
        if (!skipMandatory && mandatory) {
          label = `${label}*`;
          skipMandatory = true;
        }
      } else {
        labelPosition = void 0;
      }
      let renderComponent = (
        <Component
          {...restProps}
          mandatory={mandatory}
          skipMandatory={skipMandatory}
          labelPosition={labelPosition}
          placeholder={placeholder}
          getRef={this.getRef}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        />
      );
      if (!label) {
        return renderComponent;
      }
      return (
        <View>
          {renderComponent}
          <InputLabel
            {...restProps}
            label={label}
            isActive={isActive}
            hover={hover}
            labelPosition={labelPosition}
            getInputRef={this.getInputRef}
          />
        </View>
      );
    }
  }
  return Label;
};

export default WithLabel;
