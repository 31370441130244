import React from 'react';
import {View, TouchableOpacity, Image, Text} from '../index';
import uuid from 'uuid/v4';
import {h13_Medium} from '../../theme/fonts';

const getNewId = () => {
  return `new_${uuid()}`;
};

const nestedFieldHeaderDefaultTheme = {
  containerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: '#c1c1bb21',
    paddingBottom: 12,
    borderBottomWidth: 1,
  },
  labelStyle: {
    color: '#13083c',
    opacity: 0.5,
  },
  addLabelStyle: {color: '#2e6ecd', ...h13_Medium},
  addButtonStyle: {
    backgroundColor: 'rgb(209 223 244)',
    padding: 10,
    borderRadius: 3,
    cursor: 'pointer',
  },
};

class NestedFieldHeader extends React.Component {
  render() {
    let {icon, label, addIcon, addLabel, addRow, theme} = this.props;
    let {
      containerStyle,
      iconStyle,
      labelStyle,
      addIconStyle,
      addButtonStyle,
      addLabelStyle,
    } = theme || {};

    return (
      <View style={containerStyle}>
        <View>
          {icon && <Image source={icon} style={iconStyle} />}
          {label && <Text style={labelStyle}>{label}</Text>}
        </View>
        {addIcon || addLabel ? (
          <TouchableOpacity
            style={addButtonStyle}
            onPress={() => addRow({_id: getNewId()})}>
            {addIcon && <Image source={addIcon} style={addIconStyle} />}
            {addLabel && <Text style={addLabelStyle}>{addLabel}</Text>}
          </TouchableOpacity>
        ) : (
          void 0
        )}
      </View>
    );
  }
}

const NestedFieldHeaderDefault = (props) => (
  <NestedFieldHeader {...props} theme={nestedFieldHeaderDefaultTheme} />
);

export {NestedFieldHeader, NestedFieldHeaderDefault};
