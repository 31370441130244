import React from 'react';
import {View} from '../../react-core-components';
import {getResolvedStyle} from './Utility';

export default class Outlined extends React.Component {
  render() {
    let {
      className,
      isActive,
      hover,
      disabled,
      error,
      style,
      hoverStyle,
      activeStyle,
      errorStyle,
      disabledStyle,
      labelPosition,
      inputStyle,
      floatingLabelInputStyle,
      Component,
    } = this.props;
    style = getResolvedStyle({
      isActive,
      hover,
      disabled,
      error,
      style,
      hoverStyle,
      activeStyle,
      errorStyle,
      disabledStyle,
    });
    if (labelPosition === 'floating') {
      inputStyle = floatingLabelInputStyle || inputStyle;
    }
    return (
      <View className={className} style={style}>
        <Component {...this.props} inputStyle={inputStyle} />
      </View>
    );
  }
}

Outlined.defaultProps = {
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fbfbfb',
    borderRadius: 3,
  },
  activeStyle: {
    borderColor: '#6d91f0',
  },
  errorStyle: {
    borderColor: 'red',
  },
  inputStyle: {
    outline: 'none',
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    paddingTop: 12,
    paddingBottom: 12,
    marginLeft: 6,
    marginRight: 6,
  },
};
