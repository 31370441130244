import {get, isEmpty, isString} from 'lodash';
import React, {useCallback, useState} from 'react';
import {useEffect} from 'react';
import {
  Image,
  Snackbar,
  Text,
  TouchableOpacity,
  View,
} from '../../app-components';

import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import {GradientButton} from '../../app-components/buttons/Buttons';
import Form from '../../app-components/form/Form';
import {fetch, getUser, invoke} from '../../AppServices';
import {editIconSecond} from '../../images';
import {roles} from '../../Lib/constants';

import AdviceAndPrescription from './AdviceAndPrescription/adviceAndPrescription';
import ClinicalHistory from './ClinicalHistory/clinicalHistorys.js';
import PrescriptionAndAdviceForm from './OrderComponent/PrescriptionAndAdviceForm';
import ProvisionalDiagnosisAndClinicalNote from './OrderComponent/ProvisionalDiagnosisAndClinicalNote';
// import ResultList from './ResultList';
import TestResult from './TestResult/TestResult';

const OrderDetail = (props) => {
  const {
    navigation: {
      state: {
        params: {item = {}},
      },
    },
  } = props;
  const {clinicalHistory: clinicalHistoryData = [], _id} = get(
    item,
    'patient',
    {},
  );
  const immediateTakeaways = get(item, 'immediateTakeaways', '');
  const [isLoading, setIsLoading] = useState(false);
  const [isPPHMReportLoading, setisPPHMReportLoading] = useState(false);
  const {userType = ''} = getUser() || {};
  const [prescriptionAndAdvice, setPrescriptionAndAdvice] = useState([]);
  const [clinicalHistory, setClinicalHistory] = useState([]);
  const [testResult, setTestResult] = useState(get(item, 'testResult', []));
  const [clinicalHistoryInfo, setClinicalHistoryInfo] = useState({});
  const [masterData, setMasterData] = useState([]);

  let header = {
    title: 'Member Detail',
    titleTextStyle: {
      color: 'rgba(56, 56, 56, 0.87)',
      fontSize: 20,
      marginLeft: 10,
      fontFamily: 'AvenirNext-Regular',
    },
    actions: [
      item?.testResultArriveDate && {
        render: (patientData) => {
          return (
            <TouchableOpacity
              onPress={() => handlePatientReportDownload(patientData)}
              style={{cursor: 'pointer', height: 38}}>
              <GradientButton
                color="#0095c9"
                label={'Download Test Results'}
                loading={isLoading}
              />
            </TouchableOpacity>
          );
        },
      },
      {
        render: () => {
          return (
            <TouchableOpacity
              onPress={handleLanguageSelectModal}
              style={{cursor: 'pointer', height: 38}}>
              <GradientButton
                color="#0095c9"
                label={'Download PPHM Report'}
                loading={isPPHMReportLoading}
              />
            </TouchableOpacity>
          );
        },
      },
      isString(immediateTakeaways) && immediateTakeaways && {
        render: () => {
          return (
            <TouchableOpacity
              onPress={handleImmediateTakeawayModal}
              style={{ cursor: 'pointer', height: 38 }}>
              <GradientButton
                color="#0095c9"
                label={
                  !(isString(immediateTakeaways) && immediateTakeaways)
                    ? 'Immediate Takeaways'
                    : 'Re-Submit Takeaways'
                }
              />
            </TouchableOpacity>
          );
        },
      },
      // (userType === roles.MO || userType === roles.SUPERADMIN) &&
      // save({ label: 'Save' }),
      cancelButton({label: ' Back '}),
    ],
  };

  const handleImmediateTakeawayModal = () => {
    const { navigation } = props;

    navigation.push({
      view: 'immediateTakeaways',
      params: {
        immediateTakeawaysDefaultValue: immediateTakeaways,
        patientItem: item?._id,
      },
      modal: true,
      modalProps: {
        autoHide: true,
        screenCenterStyle: {
          borderRadius: 10,
        },
        style: {},
      },
    });
  };

  const getClinicalHistoryData = useCallback(
    async (historyId) => {
      if (_id && historyId) {
        const token = await get('token');
        const id = 'getClinicalHistory';
        const paramValue = {
          patientId: _id,
          clinicalHistoryId: historyId,
        };
        await invoke({id, paramValue, token})
          .then((res) => {
            if (Object.keys(res).length) {
              const {clinicalHistoryResult = {}, historyData = []} = res;
              const {
                clinicalNotes = '',
                labClinicalNotes = '',
                prescriptionAndAdvice = [],
                provisionalDiagnosis = '',
              } = clinicalHistoryResult;

              setPrescriptionAndAdvice(prescriptionAndAdvice || {});
              setClinicalHistoryInfo({
                ...clinicalHistoryInfo,
                clinicalHistoryId: historyId,
                clinicalNotes,
                provisionalDiagnosis,
                labClinicalNotes,
                historyData,
              });
            }
          })
          .catch((error) => {
            console.log('error: ', error);
          });
      }
    },
    [_id, clinicalHistoryInfo],
  );

  useEffect(() => {
    if (!isEmpty(clinicalHistoryData)) {
      getClinicalHistoryData(clinicalHistoryData[0]?._id);
    }
  }, [clinicalHistoryData]);

  useEffect(() => {
    (async () => {
      try {
        const id = 'getMedicalRecord';
        const token = await get('token');
        const paramValue = {
          memberId: item.patient._id,
        };
        const res = await invoke({id, paramValue, token});
        setClinicalHistory(res);
      } catch (error) {
        console.log('@eror', error);
      }
    })();
  }, [item.patient._id]);

  useEffect(() => {
      (async () => {
          try {
            const uri = {
              token: get('token') || null,
              props: {
                query: {
                  id: 'mayaTestResultList',
               },
                model: 'MayaTestResult',
              },
            };
            const testMasterData = await fetch({ uri });
            setMasterData(testMasterData?.data);
          } catch (error) {
            console.log('error: ', error);
          }
        })();
    }, []);

  const handleLanguageSelectModal = () => {
    const {navigation} = props;
    const {
      navigation: {
        state: {
          params: {item},
        },
      },
    } = props;
    navigation.push({
      view: 'LanguageSelectModal',
      params: {handlePphmReportDownload, item, setIsLoading},
      modal: true,
      modalProps: {
        autoHide: true,
        screenCenterStyle: {
          borderRadius: 10,
        },
        style: {},
      },
    });
  };

  const handlePphmReportDownload = useCallback(
    (patientId, language) => {
      const {
        patient: {_id = ''},
      } = item;
      setisPPHMReportLoading(true);

      invoke({
        id: 'viewPphmTestReport',
        paramValue: {
          patientId: _id,
          language,
        },
      })
        .then((res) => {
          setisPPHMReportLoading(false);
          const byteCharacters = atob(res.base);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: 'application/pdf',
          });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.setAttribute('href', url);
          a.setAttribute('target', '_blank');
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((error) => {
          setisPPHMReportLoading(false);
          Snackbar.show({
            text: error,
            duration: Snackbar.LENGTH_LONG,
          });
        });
    },
    [item],
  );

  const handleViewNoteLanguageSelectModal = () => {
    const {navigation} = props;
    const {
      navigation: {
        state: {
          params: {item},
        },
      },
    } = props;
    navigation.push({
      view: 'viewNoteSelectLanguageModal',
      params: {patientId: item?.patient?._id},
      modal: true,
      modalProps: {
        autoHide: true,
        screenCenterStyle: {
          borderRadius: 10,
        },
        style: {},
      },
    });
  };

  const handlePatientReportDownload = useCallback(
    (patientData) => {
      setIsLoading(true);
      const {data = {}} = patientData;
      invoke({
        id: 'fetchPatientReport',
        paramValue: {
          uploadPrescription: data?.patientId,
          _id: item?._id,
        },
      })
        .then((res) => {
          const {LISMessage, LISResult, ReportURL} = res;
          if (ReportURL) {
            const url = ReportURL;
            const a = document.createElement('a');
            a.setAttribute('href', url);
            a.setAttribute('target', '_blank');
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            alert('Lab report not found. Please try again after some time');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          Snackbar.show({
            text: error,
            duration: Snackbar.LENGTH_LONG,
          });
        });
    },
    [item._id],
  );

  const upsertUploadPrescribed = useCallback(
    (orderData) => {
      const {navigation, data = {}} = orderData;
      invoke({
        id: 'updateDataOnOrderCollection',
        paramValue: {
          uploadPrescription: data?.uploadPrescription,
          prescription: data?.prescription,
          _id: item?._id,
        },
      })
        .then((res) => {
          Snackbar.show({
            text: res?.result,
            duration: Snackbar.LENGTH_LONG,
          });
          navigation.goBack();
        })
        .catch((error) => {
          Snackbar.show({
            text: error,
            duration: Snackbar.LENGTH_LONG,
          });
        });
    },
    [item._id],
  );

  const editable = false;

  const ModifiedForm = Form({
    ...props,
    reloadEvent: 'reloadOrderDetails',
    reloadOnChangeEvent: 'reloadOrderDetails',
    type: 'standardShadow',
    closeView: 1,
    fieldVariant: 'filled',
    validateOnChange: true,
    onSubmit: (data) => {
      upsertUploadPrescribed(data);
    },
    header: header,
    defaultValues: ({navigation}) => {
      const {
        state: {
          params: {
            item: {
              patient = {},
              user = {},
              testsPrescribed = [],
              uploadPrescription,
              prescription = '',
            },
          } = {},
        } = {},
      } = navigation || {};
      const testsPrescribedValues = testsPrescribed
        .map((item) => item.test_name + ' - ' + item.test_id)
        .join(', ');
      return {
        testsPrescribedValues: testsPrescribedValues,
        ...patient,
        ...user,
        uploadPrescription,
        prescription,
      };
    },
    formGroups: [
      {
        editable: editable,
        direction: 'row',
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'text',
                label: 'First Name',
                field: 'first_name',
              },
              {
                type: 'text',
                label: 'Last Name',
                field: 'last_name',
              },
              {
                type: 'date',
                label: 'Date Of Birth',
                field: 'birthDate',
              },
              {
                type: 'text',
                label: 'Gender',
                field: (data) => {
                  let {gender} = data || {};
                  if (gender) {
                    return gender[0].toUpperCase() + gender.slice(1);
                  }
                },
              },
            ],
          },
        ],
      },
      Object.keys(clinicalHistoryInfo).length && {
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: (props) => {
                  return (
                    <ProvisionalDiagnosisAndClinicalNote
                      {...props}
                      clinicalHistoryInfo={clinicalHistoryInfo}
                      setClinicalHistoryInfo={setClinicalHistoryInfo}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
      {
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: (props) => {
                  return (
                    <PrescriptionAndAdviceForm
                      {...props}
                      prescriptionAndAdvice={prescriptionAndAdvice}
                      setPrescriptionAndAdvice={setPrescriptionAndAdvice}
                      clinicalHistoryId={clinicalHistoryInfo?.clinicalHistoryId}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
      !isEmpty(prescriptionAndAdvice) && {
        label: 'Prescription & Advice',
        expandable: true,
        color: 'red',
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: () => {
                  return (
                    <AdviceAndPrescription
                      prescriptionAndAdvice={prescriptionAndAdvice}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
      (!isEmpty(clinicalHistory?.chatHistory) ||
        !isEmpty(clinicalHistory?.memberNoteHistory)) && {
        label: 'Clinical History:',
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: (props) => {
                  const {
                    navigation: {
                      state: {
                        params: {
                          item: {
                            patient: {full_name},
                          },
                        } = {},
                      },
                    },
                  } = props;
                  return (
                    <ClinicalHistory
                      clinicalHistory={clinicalHistory}
                      fullName={full_name}
                      navigation={props?.navigation}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
      !isEmpty(testResult) && {
        label: (props) => {
          const {navigation} = props;
          const item = get(navigation, 'state.params.item', []);
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Text>Test Results</Text>
              {/* <TouchableOpacity
                onPress={() => {
                  navigation.push({
                    view: 'editTestResultForm',
                    params: {
                      item,
                      testResult,
                      setTestResult,
                      getClinicalHistoryData,
                    },
                  });
                }}
                style={{marginLeft: 30, cursor: 'pointer'}}>
                <Image
                  source={editIconSecond}
                  style={{
                    height: 20,
                    objectFit: 'contain',
                  }}
                />
              </TouchableOpacity> */}
            </View>
          );
        },
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                type: 'custom',
                render: (props) => {
                  return (
                    <TestResult
                      {...props}
                      data={testResult}
                      masterData={masterData}
                      columns={[
                        {column: 'Test Name', field: 'test_name'},
                        {column: 'Test Value', field: 'test_val'},
                        {column: 'Unit', field: 'unit'},
                        {column: 'Reference Range', field: 'ref_range'},
                        {
                          column: 'Abnormal Flag',
                          field: 'abnormal_flag',
                          checkFlag: true,
                        },
                        {column: 'Test Date and Time', field: 'test_datetime'},
                      ]}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

export default OrderDetail;
