import React from 'react';
import {View, Text, Image} from '../../react-core-components';
import {ActivityIndicator} from '../../react-activity-indicator';
import {Snackbar} from '../../react-snack-bar';
import {iterator} from './fileUtility';
import FileRenderBox from './FileRenderComponent';
import {WithInputWrapper} from '../../react-input-wrapper';

const matchValue = (value1, value2, fieldToMatch) => {
  if (fieldToMatch && value1 && value2) {
    return value1[fieldToMatch] === value2[fieldToMatch];
  } else {
    return value1 === value2;
  }
};
class FileInput extends React.Component {
  state = {};
  componentDidMount() {
    window.addEventListener('dragover', this._fileDrag, false);
    window.addEventListener('drop', this._fileDrag, false);
  }

  componentWillUnmount() {
    window.removeEventListener('dragover', this._fileDrag, false);
    window.removeEventListener('drop', this._fileDrag, false);
  }

  _fileDrag = (e) => {
    e.preventDefault();
  };

  onFocus = (e) => {
    let {onFocus} = this.props;
    onFocus && onFocus(e);
  };

  onBlur = (e) => {
    let {onBlur} = this.props;
    onBlur && onBlur(e);
  };

  setValue = ({value, e}) => {
    let {onChangeValue} = this.props;
    onChangeValue && onChangeValue(value, e);
  };

  onChangeValue = (e) => {
    e.preventDefault();
    let {
      multiple,
      onUploadFile,
      upload,
      uploadOptions={},
      validateValue,
      public_upload=false,
    } = this.props;
    let files = e.dataTransfer
      ? e.dataTransfer.files
      : e.target && e.target.files;
    let filesKeys = files ? Object.keys(files) : [];
    if (!filesKeys.length) {
      return;
    }
    if(public_upload) {
      uploadOptions.uri="/public_upload"
    }

    this.setState({uploading: true});
    if (!multiple) {
      let validateMessage = validateValue && validateValue(files);
      if (validateMessage) {
        Snackbar.show({
          text: validateMessage,
          duration: Snackbar.LENGTH_SHORT,
        });
        return;
      }
      if (onUploadFile) {
        onUploadFile(files[filesKeys[0]], this.props);
        this.setState({uploading: false});
      } else {
        upload(files[filesKeys[0]], uploadOptions).then((result) => {
          if (result) {
            this.setValue({value: result, e});
          }
          this.setState({uploading: false});
        });
      }
    } else {
      let multiResult = [];
      iterator(filesKeys, (index, fileKey) => {
        let validateMessage = validateValue && validateValue(files[fileKey]);
        if (validateMessage) {
          Snackbar.show({
            text: validateMessage,
            duration: Snackbar.LENGTH_SHORT,
          });
          return;
        }
        return upload(files[fileKey], uploadOptions).then((result) => {
          if (result) {
            multiResult.push(result);
          }
        });
      }).then((_) => {
        let {value = []} = this.props;
        this.setValue({value: [...value, ...multiResult], e});
        this.setState({uploading: false});
      });
    }
    this.onBlur(e);
  };

  onClick = (e) => {
    this.inputRef.click();
    this.onFocus(e);
  };

  removeItem = ({item}) => {
    let {idField = 'key', multiple, value} = this.props;
    if (!value) {
      return;
    }
    if (multiple) {
      if (item && value.length) {
        let newValue = value.filter((doc) => {
          return !matchValue(doc, item, idField);
        });
        this.setValue({value: newValue});
      }
    } else {
      this.setValue({value: null});
    }
  };

  getRef = (ref) => {
    this.inputRef = ref;
    this.props.getRef && this.props.getRef(ref);
  };

  render() {
    let {
      style,
      theme,
      placeholder,
      value,
      multiple,
      imageOnly,
      editable,
      hideLink,
      renderInputComponent,
      renderLink,
    } = this.props;
    let defaultProps = {type: 'file'};
    if (imageOnly) {
      defaultProps = {...defaultProps, accept: 'image/*'};
    }
    if (multiple) {
      defaultProps = {...defaultProps, multiple};
    }

    let {
      fileInputStyle: {
        boxStyle,
        uploadImageStyle: {
          viewStyle: _imageViewStyle,
          imageStyle,
          imageProps,
          image,
        } = {},
        uploadTextStyle: {viewStyle, textStyle, text} = {},
      } = {},
      containerViewStyle,
    } = theme || style || {};
    let showInput = true;
    if (editable === false) {
      showInput = false;
    }
    if (!renderInputComponent) {
      renderInputComponent = (
        <View style={{...boxStyle}}>
          <View style={viewStyle}>
            <Text style={textStyle}>{text || placeholder || ''}</Text>
          </View>
          <View style={_imageViewStyle}>
            <Image source={image} {...imageStyle} {...imageProps} />
          </View>
        </View>
      );
    }

    return (
      <>
        <View
          style={containerViewStyle}
          draggable={true}
          onDragOver={(e) => {
            this.onFocus(e);
            this.setState({dragOver: true});
          }}
          onDrop={(e) => {
            this.onChangeValue(e);
            this.setState({dragOver: false});
          }}
          onDragLeave={(e) => {
            this.onBlur(e);
            this.setState({dragOver: false});
          }}
          onClick={this.onClick}>
          {showInput && (
            <>
              {renderInputComponent}
              <input
                {...defaultProps}
                style={{display: 'none'}}
                ref={this.getRef}
                onChange={this.onChangeValue}
              />
              {this.state.uploading && ActivityIndicator && (
                <ActivityIndicator key="file_upload_status" size={'small'} />
              )}
            </>
          )}
        </View>
        {value ? (
          hideLink ? null : renderLink ? (
            renderLink
          ) : (
            <FileRenderBox
              {...this.props}
              value={value}
              onRemove={this.removeItem}
            />
          )
        ) : null}
      </>
    );
  }
}

FileInput = WithInputWrapper(FileInput);

export default FileInput;
