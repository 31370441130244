/* eslint-disable no-sparse-arrays */
import {save} from '../../app-components/action/SaveAction';
import {autoSuggestInput, searchInput} from '../../autoSuggestions';
import Form from '../../app-components/form/Form';
import {fetch, submit, getUser} from '../../AppServices';
import {resolveDoctorName} from '../../app-components/careTeamUtility';
import {
  modifyDoctorNameForDropDown,
  resolveDoctorEmail,
  resolveDoctorMobile,
  resolveDoctornpi,
} from '../../app-components/DoctorUtility';
import {cancelButton} from '../../app-components/action/CloseAction';
import {resolvePatientName} from '../../app-components/patientUtility';
import {closeButton, getNewId} from './rpmUtils';

const modifyData = (data = []) => {
  if (Array.isArray(data) && data.length) {
    const returnData = data.map((items) => {
      const {member} = items || {};
      if (member) {
        return {
          ...items,
          member: {
            ...(member || {}),
            fullname: resolveDoctorName(items.member),
          },
        };
      }
      return {
        ...items,
      };
    });
    return returnData;
  } else {
    return [{_id: getNewId()}];
  }
};
const addOnFilter = () => {
  const {organization} = getUser() || {};
  return {
    organization,
  };
};
export default Form({
  reloadEvent: 'reloadTeamMember',
  type: 'standardShadow',
  fieldVariant: 'filled',
  reloadOnChangeEvent: 'reloadTeamMember',
  beforeSubmit: ({updates}) => {
    delete updates.care_program;
    delete updates._id;
    return {
      updates: {
        ...updates,
      },
    };
  },
  onSubmit: submit({
    model: 'CareTeam',
  }),
  closeView: 1,
  header: {
    title: 'Add Care Team',
    titleTextStyle: {
      color: 'rgba(56, 56, 56, 0.87)',
      fontSize: 20,
      marginLeft: 10,
      fontFamily: 'AvenirNext-Regular',
    },
    actions: [save({text: 'Save'}), cancelButton()],
  },
  computations: {
    self: {
      geting: {
        compute: (data = {}) => {
          const {patients, careProgram} = data;
          return fetch({
            uri: {
              props: {
                query: {
                  id: 'careTeamLists',
                  addOnFilter: {
                    patients,
                    careProgram,
                  },
                },
                model: 'CareTeam',
              },
            },
          }).then((res) => {
            const {data: responseData = []} = res || {};
            if (Array.isArray(responseData) && responseData.length) {
              let item = responseData[0];
              delete item.patients;
              delete item.careProgram;
              delete item.channel;
              item = {
                ...item,
                physician: modifyData(item?.physician || []),
                nurse: modifyData(item?.nurse || []),
                medicalAssistant: modifyData(item?.medicalAssistant || []),
              };
              return {
                set: {
                  ...item,
                },
              };
            } else {
              return {
                set: {
                  physician: modifyData(),
                  nurse: modifyData(),
                  medicalAssistant: modifyData(),
                  _id: getNewId(),
                },
              };
            }
          });
        },
        onChange: ['careProgram'],
      },
    },
  },

  mandatory: {
    patients: 1,
    // name: 1,
  },
  defaultValues: ({navigation}) => {
    const {state: {params: {item = {}} = {}} = {}} = navigation || {};
    const {organization} = item;
    const {organization: userOrganization} = getUser() || {};

    // if(Array.isArray(item?.careProgram)){
    const activeCareProgram = Array.isArray(item?.careProgram)
      ? item?.careProgram.filter((careItem) => careItem?.status === 'Active')
      : [];
    // }
    return {
      role: 'Patient',
      patients: item,
      care_program: activeCareProgram || [],
      organization: organization?.[0] || userOrganization,
    };
  },

  formGroups: [
    {
      columnsPerRow: 2,
      columns: [
        {
          type: 'text',
          field: (data) => resolvePatientName(data?.patients),
          label: 'Name',
          editable: false,
        },
        {
          type: 'text',
          field: 'role',
          label: 'Role',
          editable: false,
        },
      ],
    },
    {
      columnsPerRow: 2,
      columns: [
        autoSuggestInput({
          label: 'Select Program',
          placeholder: 'Select Program',
          field: 'careProgram',
          valueField: 'name',
          suggestionField: 'name',
          idField: '_id',
          options: ({data}) => {
            let {care_program} = data || {};
            let modifiedData =
              Array.isArray(care_program) &&
              care_program.map((item) => item.careProgram);
            return modifiedData || [];
          },
          visible: ({data}) => {
            let {care_program} = data || {};
            return Array.isArray(care_program) && care_program.length;
          },
        }),
        {
          type: 'text',
          field: 'name',
          label: 'Team Name',
          visible: ({data}) => {
            return false;
            const {careProgram} = data;
            return !!careProgram?._id;
          },
        },
      ],
    },
    {
      visible: ({data}) => {
        const {careProgram} = data;
        return !!careProgram?._id;
      },
      label: 'Physicians',
      expandable: true,
      expanded: true,
      groups: [
        {
          columns: [
            {
              type: 'nestedList',
              field: 'physician',
              // addInBottom: true,
              // footer: {
              //   addLabel: 'Add more',
              // },
              formGroups: [
                {
                  columnsPerRow: 6,
                  fieldVariant: 'filled',
                  groups: [
                    {
                      columns: [
                        searchInput(
                          {
                            label: ' Physicians Name',
                            field: 'member',
                            placeholder: 'Select Physicians',
                            query: 'doctorLists',
                            model: 'Doctor',
                            valueField: 'fullname',
                            suggestionField: 'fullname',
                            searchField: 'fullname',
                            editable: true,
                          },
                          {
                            addOnFilter,
                            modifyResult: modifyDoctorNameForDropDown,
                          },
                        ),
                        {
                          type: 'text',
                          field: 'role',
                          label: 'Role',
                          editable: true,
                          visible: ({data}) => {
                            if (data?.member) {
                              return true;
                            }
                            return false;
                          },
                        },
                        {
                          type: 'text',
                          label: 'Mobile no..',
                          visible: ({data}) => {
                            return !!data?.member;
                          },
                          field: ({member}) => {
                            return resolveDoctorMobile(member);
                          },
                          editable: false,
                        },
                        {
                          type: 'text',
                          label: 'Email',
                          visible: ({data}) => {
                            return !!data?.member;
                          },
                          editable: false,
                          field: ({member}) => {
                            return resolveDoctorEmail(member);
                          },
                        },
                        {
                          type: 'text',
                          label: 'NPI no.',
                          visible: ({data}) => {
                            return !!data?.member;
                          },
                          editable: false,
                          field: ({member}) => {
                            return resolveDoctornpi(member);
                          },
                        },
                        // {
                        //   width: 30,
                        //   type: 'custom',
                        //   render: (props) => closeButton(props),
                        // },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      visible: ({data}) => {
        const {careProgram} = data;
        return !!careProgram?._id;
      },
      label: 'Nurse',
      expandable: true,
      expanded: false,
      groups: [
        {
          columns: [
            {
              type: 'nestedList',
              field: 'nurse',
              addInBottom: true,
              footer: {
                addLabel: 'Add more',
              },
              formGroups: [
                {
                  columnsPerRow: 5,
                  fieldVariant: 'filled',

                  groups: [
                    {
                      columns: [
                        searchInput(
                          {
                            label: 'Nurse Name',
                            field: 'member',
                            placeholder: 'Select Nurse',
                            query: 'nurseList',
                            model: 'Nurse',
                            valueField: 'fullname',
                            suggestionField: 'fullname',
                            searchField: 'fullname',
                          },
                          {
                            addOnFilter,
                            modifyResult: modifyDoctorNameForDropDown,
                          },
                        ),
                        {
                          type: 'text',
                          field: 'role',
                          label: 'Role',
                          visible: ({data}) => {
                            return !!data?.member;
                          },
                        },

                        {
                          type: 'text',
                          // field: 'mobile',
                          label: 'Mobile no.',
                          visible: ({data}) => {
                            return !!data?.member;
                          },
                          editable: false,
                          field: ({member}) => {
                            return resolveDoctorMobile(member);
                          },
                        },
                        {
                          type: 'text',
                          // field: 'email',
                          label: 'Email',
                          visible: ({data}) => !!data?.member,
                          editable: false,
                          field: ({member}) => {
                            return resolveDoctorEmail(member);
                          },
                        },
                        {
                          type: 'text',
                          // field: 'npi',
                          label: 'NPI no.',
                          visible: ({data}) => {
                            if (resolveDoctornpi(data?.member)) {
                              return true;
                            }
                            return false;
                          },
                          editable: false,
                          field: ({member}) => {
                            return resolveDoctornpi(member);
                          },
                        },
                        {
                          width: 30,
                          type: 'custom',
                          render: (props) => closeButton(props),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      visible: ({data}) => {
        const {careProgram} = data;
        return !!careProgram?._id;
      },
      label: 'Medical Assistants',
      expandable: true,
      expanded: false,
      groups: [
        {
          columns: [
            {
              type: 'nestedList',
              field: 'medicalAssistant',
              addInBottom: true,

              // footer: {
              //   addLabel: 'Add more',
              // },
              formGroups: [
                {
                  columnsPerRow: 2,
                  fieldVariant: 'filled',

                  groups: [
                    {
                      columnsPerRow: 5,
                      columns: [
                        searchInput(
                          {
                            label: ' Medical Assistants Name',
                            field: 'member',
                            placeholder: 'Select Medical Assistants',
                            query: 'medicalAssistantList',
                            model: 'MedicalAssistant',
                            valueField: 'fullname',
                            suggestionField: 'fullname',
                            searchField: 'fullname',
                          },
                          {
                            addOnFilter,
                            modifyResult: modifyDoctorNameForDropDown,
                          },
                        ),
                        {
                          type: 'text',
                          field: 'role',
                          label: 'Role',
                          visible: ({data}) => !!data?.member,
                        },

                        {
                          type: 'text',
                          // field: 'mobile',
                          label: 'Mobile no.',
                          visible: ({data}) => !!data?.member,
                          editable: false,
                          field: ({member}) => {
                            return resolveDoctorMobile(member);
                          },
                        },
                        {
                          type: 'text',
                          // field: 'email',
                          label: 'Email',
                          visible: ({data}) => !!data?.member,
                          editable: false,
                          field: ({member}) => {
                            return resolveDoctorEmail(member);
                          },
                        },
                        {
                          type: 'text',
                          label: 'NPI no.',
                          visible: ({data}) => !!resolveDoctornpi(data?.member),
                          editable: false,
                          field: ({member}) => {
                            return resolveDoctornpi(member);
                          },
                        },
                        // {
                        //   width: 30,
                        //   type: 'custom',
                        //   render: (props) => closeButton(props),
                        // },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});
