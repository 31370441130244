import React from 'react';
import {withTranslation} from 'react-i18next';
import {View, Text} from '../../react-core-components';
import {getRenderComponent} from './Utility';

class StackItem extends React.Component {
  state = {};
  componentDidCatch(error, info) {
    this.setState({hasError: true, error: {error, info}});
  }
  render() {
    let {
      view,
      navigation,
      eventDispatcher,
      index,
      lastViewIndex,
      screenState,
      setScreenState,
      ScreenComponent,
      renderHeader,
      renderFooter,
      ...restProps
    } = this.props;
    const {hasError, error} = this.state;
    let {
      screen: Component,
      screenProps,
      header,
      footer,
      renderHeader: viewRenderHeader,
      renderFooter: viewRenderFooter,
      ...rest
    } = view;

    if (viewRenderHeader) {
      renderHeader = viewRenderHeader;
    }
    if (viewRenderFooter) {
      renderFooter = viewRenderFooter;
    }

    let screenComponentProps = {
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      currentViewIndex: index,
      lastViewIndex,
      ...screenProps,
      ...rest,
      ...restProps,
    };

    let children = null;
    if (ScreenComponent) {
      children = (
        <ScreenComponent {...screenComponentProps} screen={Component} />
      );
    } else {
      children = <Component {...screenComponentProps} />;
    }
    if (hasError) {
      children = (
        <View>
          <Text>Error in stack item :{error.message}</Text>
        </View>
      );
    }
    return (
      <>
        {getRenderComponent(renderHeader, {
          navigation,
          eventDispatcher,
          screenState,
          setScreenState,
          header,
          ...restProps,
        })}
        {children}
        {getRenderComponent(renderFooter, {
          screenState,
          setScreenState,
          navigation,
          eventDispatcher,
          footer,
          ...restProps,
        })}
      </>
    );
  }
}

export default withTranslation()(StackItem);
