import React, {useCallback} from 'react';
import {View, Text, TouchableOpacity} from '../../app-components';
import {GradientButton} from '../../app-components/buttons/Buttons';
import { crossImage } from '../../Lib/constants';
import {h22_SemiBold} from '../../theme/fonts';

function ImmediateTakeAwayLanguageModal(props) {
  const {navigation = {}} = props;
  const {state: {params = {}} = {}} = navigation;
  const {item = {}} = params;

  const handleImmediateTakeawayModal = (patientId, language) => {
      navigation.push({
        view: 'immediateTakeaways',
        params: {
          immediateTakeawaysDefaultValue: item?.immediateTakeaways,
          patientItem: patientId,
          language: language,
        },
        modal: true,
        modalProps: {
          autoHide: true,
          screenCenterStyle: {
            borderRadius: 10,
          },
          style: {},
        },
      });
    };

  const Click = useCallback((language) => {
      handleImmediateTakeawayModal(item._id, language);
  }, []);

  return (
    <View
      style={{
        backgroundColor: '#fff',
        width: 500,
        padding: 20,
      }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginRight: '5%',
        }}>
        <Text
          style={{
            marginBottom: 10,
            marginTop: 10,
            fontSize: '45px',
            color: '#275dad',
            ...h22_SemiBold,
          }}>
          Select Note Language
        </Text>
        <TouchableOpacity
          onPress={() => {
            navigation.pop();
          }}>
          <img
            alt="svgImg"
            style={{
              cursor: 'pointer',
              // marginBottom: 10,
              // marginTop: 10,
              height: '25px',
              width: '25px',
            }}
            src={crossImage}
          />
        </TouchableOpacity>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: '28.3%',
            marginRight: '5%',
          }}
          onPress={() => Click('en')}>
          <GradientButton label="English" />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: '28.3%',
            marginRight: '5%',
          }}
          onPress={() => Click('kha')}>
          <GradientButton label="Khasi" />
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default ImmediateTakeAwayLanguageModal;