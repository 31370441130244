import {cancelButton} from '../../app-components/action/CloseAction';
import {save} from '../../app-components/action/SaveAction';
import todoForm from './todoForm';

let header = {
  title: 'Add To-Do Task',
  actions: [cancelButton(), save()],
};

export default todoForm({header});
