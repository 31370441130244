import React from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  isMobile,
} from '../../../app-components';
import { resolvePatientName } from '../../../app-components/patientUtility';
import {getAge} from '../../../app-components/UtilityFunctions';
import {getImageUrl, getCurrency} from '../../../AppServices';
import {femaleSign, maleSign, redCloseIcon, userIcon} from '../../../images';
import {
  h12_Regular,
  h13_Medium,
  h13_Regular,
  h14_AvenirNext,
  h16_Regular,
} from '../../../theme/fonts';

const SidePanelHeader = (props) => {
  const {item, openChat = () => {}} = props;
  // let patientName =
  //   item.patient_id.name[0].given.join(' ') +
  //   ' ' +
  //   item.patient_id.name[0].family;
  let patientName = resolvePatientName(item.patient_id)
  let imageUrl = item?.patient_id?.profile_picture?.file;
  imageUrl = imageUrl && getImageUrl(imageUrl);
  return (
    <View
      style={{
        backgroundColor: '#f9f9f9',
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingTop: isMobile ? 35 : 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        position: 'relative',
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <View
          style={{
            alignItems: 'center',
          }}>
          <Image
            source={imageUrl || userIcon}
            style={{
              height: 50,
              width: 50,
              borderRadius: 30,
              // objectFit: 'contain'
            }}
          />
        </View>

        <View style={{padding: 10, paddingTop: 0}}>
          <Text
            style={{
              ...h16_Regular,
              lineHeight: 21,
              letterSpacing: 0.57,
              marginBottom: 5,
            }}>
            {patientName}
          </Text>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            {item?.patient_id?.gender === 'male' ? (
              <Image
                source={maleSign}
                style={{marginRight: 5}}
                height={15}
                width={15}
              />
            ) : item?.patient_id?.gender === 'female' ? (
              <Image
                source={femaleSign}
                style={{marginRight: 5}}
                height={15}
                width={15}
              />
            ) : null}

            <Text style={{...h13_Regular, lineHeight: 21, letterSpacing: 0.57}}>
              {item?.patient_id?.gender &&
                item?.patient_id?.gender[0].toUpperCase()}
              {item?.patient_id?.birthDate && ' | ' + getAge(item?.patient_id?.birthDate)}
            </Text>
          </View>
        </View>
        <View
          style={{
            backgroundColor:
              item?.appointment_type === 'follow_up'
                ? 'rgb(224, 227, 240)'
                : 'rgb(255, 240, 227)',
            width: 100,
            borderRadius: 15.5,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={{
              ...h12_Regular,
              padding: 5,
              color:
                item?.appointment_type === 'follow_up' ? '#275dad' : '#ff7b20',
            }}>
            {item?.appointment_type === 'follow_up' ? 'Follow Up' : 'New'}
          </Text>
        </View>
      </View>

      <View>
        <Text style={{...h14_AvenirNext, color: '#9a9a9a', textAlign: 'right'}}>
          {`${item?.booking_fee} ${getCurrency()}`}
        </Text>
        <Text style={{...h13_Medium, color: '#499825', textAlign: 'right'}}>
          {'Paid'}
        </Text>
      </View>
      {isMobile ? (
        <TouchableOpacity
          style={{
            height: 22,
            width: 22,
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer',
          }}
          onPress={() => {
            openChat();
          }}>
          <Image
            source={redCloseIcon}
            style={{
              height: 22,
              width: 22,
            }}
          />
        </TouchableOpacity>
      ) : (
        void 0
      )}
    </View>
  );
};

export default SidePanelHeader;
