import React, {useState} from 'react';
import Format from '../GenericComponent/ClinicSignupFormat';
import {
  View,
  TouchableOpacity,
  Text,
  isMobile,
  Dimensions,
} from '../../app-components';
import {
  UploadImage,
} from '../../app-components/input-components/Editors';
import {TextInput} from '../../app-components/input-components/Editors';
import {
  h11_Medium,
  
  h14_AvenirNext,
} from '../../theme/fonts';
import {GradientButton} from '../../app-components/buttons/Buttons';
import {
  validateEmail,
} from '../../AppServices';
import MobileInput from '../../app-components/input-components/MobileInputForm';

const {height, width} = Dimensions.get('window');

const ClinicAdminName = (props) => {
  const {navigation} = props || {};
  const {state: {params: {data = {}, type} = {}} = {}} = navigation || {};
  const [organizationEmail, setOrganizationEmail] = useState('');
  const [organizationMobile, setOrganizationMobile] = useState('');
  const [error, setError] = useState({});
  const [file, setFile] = useState(null);
  const setValue = ({value, ...props}) => {
    setOrganizationMobile(value);
  };
  return (
    <View
      style={{
        minWidth: isMobile ? width * 0.75 : 600,
        maxWidth: isMobile ? width * 0.75 : 600,
        height: isMobile ? height * 0.65 : 400,
        justifyContent: 'space-between',
      }}>
      <View>
        <View style={{flexDirection: isMobile ? 'column' : 'row'}}>
          <View style={{marginRight: 25}}>
            <Text style={{...h14_AvenirNext, marginTop: 20, marginBottom: 15}}>
              Clinic logo
            </Text>
            <UploadImage
              setValue={({value}) => {
                setFile([{file: value}]);
              }}
              value={file}
              data={file && file.length && file[0]}
              field="file"
              public_upload={true}
              imageWidth={isMobile ? 100 : 200}
              imageHeight={isMobile ? 100 : 200}
            />
          </View>
          <View>
            <Text style={{...h14_AvenirNext, marginTop: 20, marginBottom: 15}}>
              Clinic Email
            </Text>
            <TextInput
              value={organizationEmail}
              onChangeText={(value) => {
                setOrganizationEmail(value);
              }}
              variant="filled"
              placeholder={'Enter Email'}
            />
            {error && error?.type === 'email' ? (
              <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
            ) : (
              void 0
            )}
            <Text style={{...h14_AvenirNext, marginTop: 15, marginBottom: 15}}>
              Clinic Mobile
            </Text>
            <MobileInput
              value={organizationMobile}
              setValue={setValue}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  // sendOTP();
                }
              }}
              mobileInputStyle={{flex:1}}
            />
            {error && error?.type === 'mobile' ? (
              <Text style={{...h11_Medium, color: 'red'}}>{error.value}</Text>
            ) : (
              void 0
            )}
          </View>
        </View>
      </View>
      <View
        style={{alignItems: isMobile ? 'center' : 'flex-end', marginTop: 10}}>
        <TouchableOpacity
          onPress={() => {
            if (!organizationEmail.length) {
              setError({type: 'email', value: 'Please enter email address'});
            } else if (!validateEmail(organizationEmail)) {
              setError({type: 'email', value: 'Please enter valid email'});
            } else if (!organizationMobile.length) {
              setError({type: 'mobile', value: 'Please enter mobile number'});
            } else {
              navigation.push({
                view: 'clinic-admin-detail',
                params: {
                  data: {
                    ...data,
                    organizationEmail,
                    organizationMobile,
                    organizationImage: file && file.length && file[0]?.file||{},
                  },
                  type,
                },
              });
            }
          }}
          style={{width: 120, cursor: 'pointer'}}>
          <GradientButton label="Next" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (props) => (
  <Format {...props} title={'Enter Clinic Details'}>
    <ClinicAdminName {...props} />
  </Format>
);
