import React from 'react';
import {cancelButton} from '../../app-components/action/CloseAction';
import Form from '../../app-components/form/Form';
import {save} from '../../app-components/action/SaveAction';
import {View} from '../login/components';
import {closeButton} from './rpmUtils';
import {invoke} from '../../AppServices';
import {Snackbar} from '../../app-components';
import uuid from 'uuid/v4';
import {isEmpty} from 'lodash';
import { getAgeFromDob } from '../../app-components/UtilityFunctions';

let defaultHeader = {
  titleTextStyle: {
    color: 'rgba(56, 56, 56, 0.87)',
    fontSize: 20,
    marginLeft: 10,
    fontFamily: 'AvenirNext-Regular',
  },
  title: 'Test Result:',
  actions: [save(), cancelButton({label: ' Back '})],
};

const EditTestResultForm = (props) => {
  const {navigation = {}} = props;
  const {
    state: {
      params: {setTestResult, getClinicalHistoryData},
    },
  } = navigation;

  const saveTestResult = (data) => {
    const {
      navigation: {
        state: {
          params: { item: { _id, patient: { _id: patientId, gender, birthDate } } },
        },
      },
    } = props;
    const {
      data: {testResult = []},
    } = data;

    if (!isEmpty(testResult)) {
      testResult.forEach((item) => {
        delete item._id;
      });
    }

    invoke({
      id: 'updateTestResult',
      paramValue: {
        _id,
        testResult,
        patientId,
        patientData: {
          sex: gender,
          age: getAgeFromDob(birthDate),
          countryName: 'India',
        }
      },
    })
      .then((response) => {
        const { clinicalHistoryResult = {} } = response;
        if (Object.keys(clinicalHistoryResult).length) {
          Snackbar.show({
            text: 'Test result update successfully.',
            duration: Snackbar.LENGTH_LONG,
          });
          setTestResult(testResult);
          getClinicalHistoryData(clinicalHistoryResult?._id);
        }
      })
      .catch((error) => {
        Snackbar.show({
          text: 'Unable to update Test result.',
          duration: Snackbar.LENGTH_LONG,
        });
      });
  };

  const ModifiedForm = Form({
    ...props,
    type: 'standardShadow',
    closeView: 1,
    fieldVariant: 'filled',
    reloadEvent: 'reloadOrderDetails',
    reloadOnChangeEvent: 'reloadOrderDetails',
    onSubmit: (data) => {
      saveTestResult(data);
    },
    defaultValues: (props) => {
      const {
        navigation: {
          state: {
            params: {testResult = []},
          },
        },
      } = props;

      testResult.forEach((ele) => {
        ele._id = uuid();
      });

      return {
        testResult,
      };
    },
    header: defaultHeader,
    formGroups: [
      {
        label: 'Edit Test Result',
        direction: 'row',
        fieldVariant: 'filled',
        editable: true,
        groups: [
          {
            columns: [
              {
                type: 'nestedList',
                field: 'testResult',
                addInBottom: true,
                formGroupHeaderStyle: {marginLeft: 10},
                footer: {
                  addLabel: true ? 'ADD' : void 0,
                },
                formGroups: [
                  {
                    columnsPerRow: 5,
                    fieldVariant: 'filled',
                    groups: [
                      {
                        columns: [
                          {
                            type: 'text',
                            label: 'Test Name',
                            field: 'test_name',
                            editable: true,
                          },
                          {
                            type: 'number',
                            label: 'Test Value',
                            field: 'test_val',
                            editable: true,
                          },
                          {
                            type: 'text',
                            label: 'Unit',
                            field: 'unit',
                            editable: true,
                          },
                          {
                            type: 'text',
                            label: 'Reference Range',
                            field: 'ref_range',
                            editable: false,
                          },
                          {
                            width: 30,
                            render: (props) => {
                              const {index} = props;
                              if (index === 0 || !true) {
                                return <View />;
                              }
                              return closeButton(props);
                            },
                          },
                          {
                            type: 'custom',
                            width: '100%',
                            render: () => (
                              <View
                                style={{
                                  height: 10,
                                  borderBottomWidth: 1.5,
                                  borderBottomColor: '#f2eeed',
                                }}
                              />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });
  return <ModifiedForm props={props} />;
};

EditTestResultForm.defaultValues = {
  setTestResult: () => {},
};

export default EditTestResultForm;
