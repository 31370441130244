import React from 'react';
import ErrorMessage from './ErrorMessage';
import HelperText from './HelperText';

export default Component => {
  class InputWrapper extends React.Component {
    state = {};

    onFocus = e => {
      let {onFocus, setFocus} = this.props;
      setFocus && setFocus(true, e);
      onFocus && onFocus(e);
      this.setState({focus: true});
    };

    onBlur = e => {
      let {onBlur, setFocus} = this.props;
      setFocus && setFocus(false, e);
      onBlur && onBlur(e);
      this.setState({focus: false});
    };

    onChangeValue = (value, e) => {
      let {setValue, onChange, onChangeValue, onChangeText} = this.props;
      onChange && onChange(e);
      onChangeValue && onChangeValue(value, e);
      onChangeText && onChangeText(value);
      setValue && setValue({value, event: e});
    };

    onMouseEnter = e => {
      let {onMouseEnter} = this.props;
      this.setState({
        hover: true,
      });
      onMouseEnter && onMouseEnter(e);
    };

    onMouseLeave = e => {
      let {onMouseLeave} = this.props;
      this.setState({
        hover: false,
      });
      onMouseLeave && onMouseLeave(e);
    };

    render() {
      let {focus, hover} = this.state;
      let {
        placeholder,
        activePlaceholder,
        mandatory,
        skipMandatory,
        onFocus,
        onBlur,
        onChange,
        onChangeText,
        onMouseEnter,
        onMouseLeave,
        isActive = focus,
        ...restProps
      } = this.props;
      if (placeholder === undefined) {
        placeholder = activePlaceholder;
      } else if (isActive && activePlaceholder !== undefined) {
        placeholder = activePlaceholder;
      }
      if (!skipMandatory && mandatory) {
        if (placeholder) {
          placeholder = `${placeholder}*`;
        }
      }
      return (
        <>
          <Component
            {...restProps}
            placeholder={placeholder}
            isActive={isActive}
            hover={hover}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChangeValue={this.onChangeValue}
          />
          <ErrorMessage {...restProps} />
          <HelperText {...restProps} />
        </>
      );
    }
  }
  return InputWrapper;
};
