import React from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
  isMobile,
  Dimensions,
} from './app-components';
import {navigationBarStyle} from './theme/navigationBarTheme';
import {colors} from './theme/colors';
import {Avatar} from './npms/react-avatar';
import {WithModal} from './npms/react-with-modal';
import {
  canAccess,
  getLandingView,
  getUser,
  getProfileUrl,
  waitingRoom,
} from './AppServices';
import {ClassRoomNotification} from './screens/mayamd-notification';
import TabsFlow from './screens/DoctorSearch/TabsFlow';
import {userIcon, mayaMdLogo, SVMLogo, meghlayaGovtLogo1} from './images';
import navigationMenus from './NavigationMenu';
import {ToggleSwitchInput} from './app-components/input-components/Editors';
import {h12_Medium, h12_SemiBold, h14_Medium} from './theme/fonts';
import SideBar from './SideBar';
import ProfileDropdown from './Layout/ProfileDropdown';
import {startCase} from 'lodash';
import {patientWatingRoomCard} from './theme/shadows';
const {themeColor, textColor70} = colors;
let customHeader = {
  // 'front-page': HeaderCustom,
  // 'doctor-search': HeaderCustom,
};
const {height, width} = Dimensions.get('window');
export default class NavigationBar extends React.Component {
  componentDidMount() {
    window.onpopstate = this.onPopState;
  }
  onPopState = () => {
    let view = getLandingView();
    if (view) {
      this.props.navigation.reset(view);
    }
  };
  visibleTabs = ({view}) => {
    return canAccess({view});
  };
  render() {
    const {navigation, user} = this.props;
    let {
      tochableContainerTabStyle,
      rightHeaderStyle,
      flagImageStyle,
      // bottomChildrenStyle,
      containerStyle,
      activeHeaderTextStyle,
      // containerChildrenStyle,
      headerTextStyle,
      imageIconStyle,
      notificationStyle,
      containerTabStyle,
      bottomBorderStyle,
      modalTextStyle,
    } = navigationBarStyle;

    const routes = navigation.getActiveRoutes();
    let firstRoute = routes[0] || [];
    if (
      (firstRoute === 'reauth' || firstRoute === 'thankYouStripe',
      firstRoute === 'edit-booking',
      firstRoute === 'Dashboard')
    ) {
      return null;
    }
    const tabsFlowCondition =
      routes?.indexOf('doctor-profile') !== -1 ||
      routes?.indexOf('booking') !== -1 ||
      routes?.indexOf('after-booking-slot') !== -1 ||
      routes?.indexOf('patient-login') !== -1 ||
      routes?.indexOf('OTP') !== -1 ||
      routes?.indexOf('patient-details') !== -1 ||
      routes?.indexOf('patient-payment') !== -1 ||
      routes?.indexOf('booking-confirm') !== -1;
    if (!user) {
      let CustomHeader = customHeader[firstRoute];
      if (CustomHeader) {
        return (
          <View>
            <CustomHeader {...this.props} />
            {tabsFlowCondition ? <TabsFlow navigation={navigation} /> : null}
          </View>
        );
      } else {
        return null;
      }
    } else {
      if (
        firstRoute !== 'videoSession' &&
        routes?.length > 1 &&
        routes?.indexOf('videoSession') !== -1
      ) {
        return null;
      }
      if (
        firstRoute !== 'videoSessionMobile' &&
        routes?.length > 1 &&
        routes?.indexOf('videoSessionMobile') !== -1
      ) {
        return null;
      }
    }
    const {
      photo,
      userType,
      doctor = {},
      organization: {organization_logo, organization_name} = {},
    } = getUser() || {};
    if (userType === 'Patient') {
      return null;
    }
    return (
      <View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            ...containerStyle,
            // backgroundColor: 'red',
            justifyContent: 'space-between',
            paddingLeft: isMobile ? 10 : 30,
            paddingRight: isMobile ? 10 : 10,
          }}>
          {isMobile ? (
            <SideBar {...this.props} visibleTabs={this.visibleTabs} />
          ) : (
            void 0
          )}
          <>
            {isMobile ? (
              void 0
            ) : (
              <>
                <Image
                  source={mayaMdLogo}
                  style={{
                    height: 45,
                    objectFit: 'contain',
                  }}
                />

                <View style={{ flexDirection: 'row', marginLeft: '30%', alignItems: 'center', justifyContent: 'center' }}>
                  <Image
                    source={meghlayaGovtLogo1}
                    style={{
                      height: 55,
                      objectFit: 'contain',
                    }}
                  />
                  <Image
                    source={SVMLogo}
                    style={{
                      height: 40,
                      objectFit: 'contain',
                      marginLeft: '15%',
                    }}
                  />
                </View>
                <ScrollView
                  horizontal={true}
                  style={{flex: 1, flexDirection: 'row'}}
                  contentContainerStyle={{justifyContent: 'center'}}>
                  {navigationMenus.map((menu, index) => {
                    let {title: text, icon, activeIcon, navigate: link} = menu;
                    let active;
                    let textStyle;
                    const {
                      userType = '',
                      organization = {},
                      doctor = {},
                    } = getUser();
                    if (userType === 'ClinicAdmin') {
                      const {is_emergency = false} = organization;
                      if (!is_emergency) {
                        if (link?.view === 'emergency-request') {
                          return null;
                        }
                      }
                    }
                    if (userType === 'SuperAdmin') {
                      if (text === 'Appointment') {
                        text = 'Patients';
                      }
                    }
                    if (userType === 'Patient') {
                      if (text === 'Appointment') {
                        text = 'Past appointments';
                      }
                      if (text === 'Follow-Up') {
                        text = 'Folloup reqeusts';
                      }
                    }
                    if (userType === 'Doctor') {
                      const {waiting_room_available = false} = doctor;
                      if (!waiting_room_available) {
                        if (link?.view === 'instant-patient-queue') {
                          return null;
                        }
                      }
                    }
                    if (firstRoute) {
                      active = link.view === firstRoute;
                      this.active = firstRoute;
                    } else {
                      active = link.view === this.active;
                    }
                    if (active) {
                      icon = activeIcon || icon;
                      textStyle = activeHeaderTextStyle;
                    } else {
                      textStyle = headerTextStyle;
                    }
                    return (
                      <>
                        {this.visibleTabs({
                          view: link.view,
                          params: link.params,
                        }) && (
                          <View
                            style={{
                              ...containerTabStyle,
                              marginLeft: index == 0 ? 10 : 5,
                              marginRight: 5,
                              // borderBottomColor: active ? GrayColor : cardHighlight,
                            }}>
                            <TouchableOpacity
                              style={tochableContainerTabStyle}
                              onPress={() => {
                                navigation.replace(link);
                              }}>
                              {icon && (
                                <Image source={icon} style={imageIconStyle} />
                              )}
                              {text && (
                                <Text
                                  style={{
                                    ...textStyle,
                                  }}>
                                  {text}
                                </Text>
                              )}

                              {/* {renderItem && renderItem} */}
                            </TouchableOpacity>
                            {active && (
                              <View
                                style={{
                                  ...bottomBorderStyle,
                                  backgroundColor: active
                                    ? themeColor
                                    : 'white',
                                }}
                              />
                            )}
                          </View>
                        )}
                      </>
                    );
                  })}
                </ScrollView>
              </>
            )}
            <View style={rightHeaderStyle}>
              {userType === 'Doctor1' && !isMobile ? (
                <View style={{paddingRight: 10}}>
                  <ToggleSwitchInput
                    value={doctor.waiting_room_available}
                    showOuterLabel
                    onChangeValue={(value) => {
                      waitingRoom(value)
                        .then(() => {
                          if (
                            !value &&
                            routes &&
                            routes[0] &&
                            routes[0] === 'instant-patient-queue'
                          ) {
                            navigation.reset('analytics');
                          }
                          this.setState({});
                        })
                        .catch((err) => {
                          console.log('>@>Puru-NavigationBar.js~line-255', err);
                        });
                    }}
                  />
                  <Text
                    style={{
                      ...h12_SemiBold,
                      color: textColor70,
                      marginTop: 10,
                    }}>
                    Instant Consultation
                  </Text>
                </View>
              ) : (
                void 0
              )}

              {/* <ClassRoomNotification
                {...this.props}
                headerTextStyle={headerTextStyle}
              /> */}

              <WithModal
                autoHide={true}
                dropdownStyle={{
                  width: 120,
                  height: 145,
                }}
                renderModal={({frameStyle, hideModal}) => {
                  return (
                    <ProfileDropdown
                      frameStyle={frameStyle}
                      hideModal={hideModal}
                      {...this.props}
                      revertColor
                    />
                  );
                }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    padding: 5,
                    cursor: 'pointer',
                    // border: '2px solid aliceblue',
                    borderRadius: 5,
                    // boxShadow:"rgb(0 0 0 / 14%) 2px 3px 2px 2px"
                    // boxShadow: "rgb(0 0 0 / 14%) 0px 0px 1px 1px"
                    // ...patientWatingRoomCard,
                  }}>
                  <Image
                    source={getProfileUrl(photo?.thumbnail) || userIcon}
                    style={{
                      height: 40,
                      objectFit: 'contain',
                      borderRadius: 100,
                      width: 40,
                    }}
                  />
                  <View
                    style={{
                      marginLeft: 5,
                      paddingRight: 10,
                      // backgroundColor: 'red',

                      flex: 1,
                    }}>
                    <View style={{maxWidth: 150}}>
                      <Text
                        style={{...h14_Medium}}
                        numberOfLines={1}
                        title={user?.name}>
                        {user?.name}
                      </Text>
                    </View>
                    <Text style={{...h12_Medium, color: colors.themeColor}}>
                      {startCase(userType)}
                    </Text>
                  </View>
                </View>

                {/* <View
                  style={{
                    // flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  {photo ? (
                    <Image
                      source={getProfileUrl(photo.thumbnail)}
                      style={{
                        borderRadius: '50%',
                        height: 40,
                        width: 40,
                      }}
                    />
                  ) : (
                    <Avatar icon={userIcon} />
                  )}
                  <View
                    style={{paddingLeft: 5, overflow: 'hidden', maxWidth: 70}}>
                    {Array.isArray(user.name) ? (
                      <Text numberOfLines={1} style={{...headerTextStyle}}>
                        {user.name?.[0].given?.[0] || 'N/A'}
                      </Text>
                    ) : (
                      <Text numberOfLines={1} style={{...headerTextStyle}}>
                        {user.name || 'N/A'}
                      </Text>
                    )}
                  </View>
                </View> */}
              </WithModal>
            </View>
          </>
        </View>
        {tabsFlowCondition ? <TabsFlow navigation={navigation} /> : null}
      </View>
    );
  }
}
