import React from 'react';
import Table from '../../../app-components/table/Table';

const uri = () => {
  return {
    query: {
      id: 'villageSubCenterList',
      sort: {_id: -1},
    },
    model: 'villageSubCenter',
  };
};

export default Table({
  uri,
  reloadEvent: 'reloadVillage',
  reloadOnChangeEvent: 'reloadVillage',
  action: ({item}) => {
    return {
      type: 'link',
      link: {
        view: 'editVillageSubCenter',
        params: {item},
        modal: true,
        modalProps: {
          screenCenterStyle: {
            borderRadius: 10,
            width: '50%',
          },
          style: {},
        },
      },
    };
  },
  columns: [
    {
      header: 'Name',
      type: 'text',
      field: 'centerName',
    },
  ],
});
