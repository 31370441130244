import React from 'react';
import {View, Text} from '../../react-core-components';
import {WithInputWrapper} from '../../react-input-wrapper';
import RadioButton from './RadioButton';

class RadioGroup extends React.Component {
  render() {
    let {
      value,
      options,
      style,
      optionStyle,
      labelStyle,
      selectedLabelStyle,
      direction = 'row',
      labelDirection = 'right',
      onChangeValue,
      defaultValue,
      ...restProps
    } = this.props;
    if (!options || !options.length) {
      return null;
    }
    if (!value) {
      value = defaultValue;
    }
    return (
      <View
        style={{
          ...(direction === 'row'
            ? {flex: 1, flexWrap: 'wrap', flexDirection: 'row'}
            : {}),
          ...style,
        }}>
        {options.map((option) => {
          if (!option) {
            return null;
          }
          let optionValue = option;
          let optionLabel = option;
          if (typeof option !== 'string') {
            optionValue = option.value;
            optionLabel = option.label;
          }
          let selected = value !== undefined && value === optionValue;
          let labelComponent = optionLabel ? (
            <Text
              style={{
                ...labelStyle,
                ...(selected ? selectedLabelStyle : {}),
              }}>
              {optionLabel}
            </Text>
          ) : (
            void 0
          );
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                ...optionStyle,
              }}>
              {labelDirection === 'left' ? labelComponent : void 0}
              <RadioButton
                {...restProps}
                selected={selected}
                onChangeValue={(_value, e) => {
                  if (value !== optionValue) {
                    onChangeValue && onChangeValue(optionValue, e);
                  }
                }}
              />
              {labelDirection === 'right' ? labelComponent : void 0}
            </View>
          );
        })}
      </View>
    );
  }
}

RadioGroup.defaultProps = {
  optionStyle: {paddingRight: 8},
  labelStyle: {paddingLeft: 8},
};

RadioGroup = WithInputWrapper(RadioGroup);

export default RadioGroup;
