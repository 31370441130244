import React, {useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from '../../app-components';
import MobileInput from '../../app-components/input-components/MobileInput';
import {checkUserExist} from '../../AppServices';
import {TextInput} from '../../npms/react-text-input';
import {h16_Regular} from '../../theme/fonts';

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState({
    mobile: '',
    email: '',
  });
  let {navigation, isForgot, setForgot} = props;
  const sendOTP = async () => {
    const isMobileLengthError = data?.mobile?.length
      ? true
      : data?.email?.length
      ? true
      : false;
    if (!isMobileLengthError) {
      setError('Mobile or Email number is required');
    } else {
      setLoading(true);
      checkUserExist(
        data?.mobile?.length ? data.mobile : data?.email,
        'Patient',
        false,
        isForgot,
      )
        .then((res) => {
          navigation.replace('maya-auth', {
            mobile: data?.mobile?.length ? data.mobile : data?.email,
            // email: data?.email,
            type: 'Patient',
            from: isForgot ? 'forgotPassword' : 'login',
          });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setError('Mobile or Email not registered');
        });
    }
  };
  const onKeyPress = (e) => {
    const {nativeEvent: {key} = {}} = e || {};
    if (key === 'Enter') {
      sendOTP();
    }
  };
  const setValue = ({field, value}) => {
    if (data?.email?.length) {
      setData({email: '', [field]: value});
    } else {
      setData({...data, [field]: value});
    }
  };
  // console.log(data);
  return (
    <View style={{flex: 1, paddingLeft: 15, paddingRight: 15, marginTop: 20}}>
      <View style={{flex: 1}}>
        <TextInput
          value={data['email']}
          onChangeText={(value) => {
            if (data?.mobile?.length) {
              // console.log('>>>>>>>>>>>>>if');
              setData({mobile: '', email: value});
            } else {
              setData({...data, email: value});
            }
          }}
          variant="filled"
          placeholder={'Enter email or uniqueId'}
          onSubmitEditing={(event) => {
            if (event.key === 'Enter') {
              !loading && sendOTP && sendOTP();
            }
          }}
        />
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 8,
            marginBottom: 8,
            position: 'relative',
          }}>
          <Text style={{color: '#fff', ...h16_Regular}}>Or</Text>
          {/* <View
            style={{
              height: 1,
              minHeight: 1,
              flex: 1,
              backgroundColor: 'red',
              width: '100%',
              position: "absolute"
            }}></View> */}
        </View>

        <MobileInput
          value={data.mobile}
          setValue={setValue}
          fieldName="mobile"
          data={data}
          isEmail={true}
          onKeyPress={onKeyPress}
          error={error}
          errorStyle={{color: '#fff'}}
        />
      </View>
      <View style={{alignItems: 'flex-end', paddingTop: 8}}>
        {!isForgot ? (
          <TouchableOpacity onPress={() => setForgot(true)}>
            <Text style={{color: '#fff', textDecorationLine: 'underline'}}>
              Forgot Password
            </Text>
          </TouchableOpacity>
        ) : (
          void 0
        )}
      </View>
      <TouchableOpacity
        onPress={() => {
          sendOTP();
        }}
        style={{
          backgroundColor: '#00A7DA',
          flex: 1,
          minHeight: 45,
          marginTop: 20,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 10,
          cursor: 'pointer',
        }}>
        {loading ? (
          <ActivityIndicator size={'small'} color={'white'} />
        ) : (
          <Text style={{color: '#fff'}}>Next</Text>
        )}
      </TouchableOpacity>
    </View>
  );
};

const PatientSignUP = (props) => {
  const {navigation} = props;
  return <Login {...props} navigation={navigation} />;
};

export default PatientSignUP;
