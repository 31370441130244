import React from 'react';
import {
  ScrollView,
  View,
  Image,
  Text,
  TouchableOpacity,
  Dimensions,
} from '../app-components';
import {
  // HealthAssistant,
  doctorSearchMaya,
  appointments,
  // StewardLogo,
  // MotivHealthLogo,
  dashboardIcon,
  medicalInformation,
} from '../images';
// import {h16_Regular} from '../theme/fonts';
import './style.css';
import {useTranslation} from 'react-i18next';
// import { h12_Regular } from '../theme/fonts';

const BottomBar = (props) => {
  const {selectedMenu, setSelectedMenu, navigation} = props;
  const {t, i18n} = useTranslation();
  const {height, width} = Dimensions.get('window');
//   window.addEventListener("scroll", function(event) {
  
//     var top = this.scrollY,
//         left = this.scrollX;
//   console.log(">>>>>>>>>>>.",top,left);
//     // var horizontalScroll = document.querySelector(".horizontalScroll"),
//     //     verticalScroll = document.querySelector(".verticalScroll");
    
//     // horizontalScroll.innerHTML = "Scroll X: " + left + "px";
//     //   verticalScroll.innerHTML = "Scroll Y: " + top + "px";
  
// },true);
  return (
    <View
      style={{
        flexDirection: 'row',
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        backgroundColor: 'rgba(1, 59, 86, 0.35)',
        borderRadius: 8,
        padding: 5,
        alignItems: 'center',
      }}>
      <ScrollView style={{width: '100%', flexDirection: 'row'}}>
        {[
          {
            label: 'Dashboard',
            value: 'dashboard',
            isNavigate: 'Dashboard',
            icon: dashboardIcon,
          },
          {
            label: 'Appointments',
            value: 'appointments',
            icon: appointments,
            isNavigate: 'patient-waiting-room',
          },
          {
            label: 'Find a Provider',
            value: 'findAProvider',
            isNavigate: 'provider',
            icon: doctorSearchMaya,
          },
          {
            label: 'Check My Symptoms',
            value: 'healthAssistant',
            isNavigate: 'health-assistant',
            icon: medicalInformation,
          },
        ].map((item) => {
          return (
            <TouchableOpacity
              onPress={() => {
                if (item?.isNavigate) {
                  navigation.reset(item?.isNavigate);
                }
                setSelectedMenu(item.value);
              }}
              style={{
                backgroundColor:
                  selectedMenu === item.value ? 'rgba(255, 255, 255, 0.2)' : '',
                flex: 1,
                borderRadius: 8,
                // paddingLeft:15,
                minHeight: width * 0.08,
                // marginBottom: 10,
                // alignItems:"center",
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <View
                style={{
                  // flexDirection: 'row',
                  flex: 1,
                  justifyContent: 'center',
                  // paddingLeft: 35,
                  alignItems: 'center',
                }}>
                <Image
                  style={{
                    width: 16.5,
                    height: 16.5,
                    objectFit: 'contain',
                    marginRight: 20,
                    marginLeft: 20,
                  }}
                  source={item.icon}></Image>
                {/* {selectedMenu === item.value ? (
                  <Text style={{...h12_Regular, color: '#fff'}}>
                    {t(item.label)}
                  </Text>
                ) : (
                  void 0
                )} */}
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default BottomBar;
